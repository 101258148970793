import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'


import { Store } from 'redux'
import config from '../config'

const debug = process.env.REACT_APP_DEBUG && false;

const appCode = config.app.appCode;

const Root = ({ store }: {store: Store }) => {
  debug && console.log('Root says appcode is', appCode)
  return (
    <Provider store={store}>
      <Router>
        <Route path="/" component={ App}  />
      </Router>
    </Provider>
  )
} 

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root