import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

interface ConfirmationProps {
  name: string;
  message?: string;
  state: boolean;
  changeState: Dispatch<SetStateAction<boolean>>;
  actionFunction: () => void;
}

const Confirmation: React.FC<ConfirmationProps> = (props: ConfirmationProps) => {
  const { name, message = 'Are you sure you wish to do this?', state, changeState, actionFunction } = props;
  return (
    <>
      <Dialog open={state} onClose={() => changeState(false)}>
        <DialogTitle>{`${name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => changeState(false)}>Cancel</Button>
          <Button
            onClick={() => {
              actionFunction();
              changeState(false);
            }}
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Confirmation;
