enum VISIBILITY_FILTERS {
    ALL
}

enum ACTIONS {
  GET = 'GET',
  REQUEST_AUTHENTICATE = 'REQUEST_AUTHENTICATE',
  RECEIVE_AUTHENTICATE = ' RECEIVE_AUTHENTICATE',
  RECEIVE_AUTH_DENIED = 'RECEIVE_AUTH_DENIED',
  REQUEST_INIT = 'REQUEST_INIT',
  RECEIVE_INIT = 'RECEIVE_INIT',
  REQUEST_LOGOUT = 'REQUEST_LOGOUT',
  RECEIVE_LOGOUT = 'RECEIVE_LOGOUT',

  MQTT_MESSAGE_RECEIVED = 'MQTT_MESSAGE_RECEIVED',

  SAVE_OBJECT = 'SAVE_OBJECT',
  RECEIVE_SAVE_TRANSACTION_RESULT = 'APP_RECEIVE_SAVE_TRANSACTION_RESULT',

  DELETE_OBJECT = 'DELETE_OBJECT',
  RECEIVE_DELETE_TRANSACTION_RESULT = 'RECEIVE_DELETE_TRANSACTION_RESULT',

  DISMISS_USER_MESSAGE = 'DISMISS_USER_MESSAGE',
  REQUEST_NEW_DRAFT_OBJECT = "REQUEST_NEW_DRAFT_OBJECT",
  ERROR = "ERROR",
  RECEIVE_SAVE_AND_LINK_TRANSACTION_RESULT = "RECEIVE_SAVE_AND_LINK_TRANSACTION_RESULT",

  REQUEST_NEW_LIST_OBJECT = "REQUEST_NEW_LIST_OBJECT",
  REQUEST_SEARCH_BY_TYPE = "REQUEST_SEARCH_BY_TYPE",
  RECEIVE_SEARCH_BY_TYPE_RESULT = "RECEIVE_SEARCH_BY_TYPE_RESULT",

  REQUEST_UNLINK_OBJECTS = 'REQUEST_UNLINK_OBJECTS',
  RECEIVE_UNLINK_OBJECTS_RESULT = 'RECEIVE_UNLINK_OBJECTS_RESULT',
  REQUEST_LINK_OBJECTS = "REQUEST_LINK_OBJECTS",
  REQUEST_GET_ADJACENTS = "REQUEST_GET_ADJACENTS",
  RECEIVE_GET_ADJACENT_RESULT = "RECEIVE_GET_ADJACENT_RESULT",
  REQUEST_REFRESH_WITH_USER_TOKEN = "REQUEST_REFRESH_WITH_USER_TOKEN",
  RECIEIVE_REFRESH_WITH_USER_TOKEN = "RECIEIVE_REFRESH_WITH_USER_TOKEN",

  CANCEL_REDIRECT_AFTER_SUCCESS = 'CANCEL_REDIRECT_AFTER_SUCCES',
  GET_USER_GEOLOCATION = "GET_USER_GEOLOCATION",
  RECEIVE_USER_GEOLOCATION = "RECEIVE_USER_GEOLOCATION",
  REQUEST_GET_DEVICE_FROM_REGISTRY = "REQUEST_GET_DEVICE_FROM_REGISTRY",
  RECEIVE__GET_DEVICE_FROM_REGISTRY_RESULT = "RECEIVE__GET_DEVICE_FROM_REGISTRY_RESULT",
  MQTT_NETWORK_MESSAGE_RECEIVED = "MQTT_NETWORK_MESSAGE_RECEIVED",
  CLEAR_MESSAGE_PING = "CLEAR_MESSAGE_PING",
  CHANGE_APP_STATE = 'CHANGE_APP_STATE',
  REQUEST_ADD_OBJECTS_TO_STATE = 'REQUEST_ADD_OBJECTS_TO_STATE',
  RECEIVE_OBJECTS_IN_STATE = ' RECEIVE_OBJECTS_IN_STATE',
  REQUEST_GET_LARGE_OBJECT = "REQUEST_GET_LARGE_OBJECT",
  RECEIVE_GET_LARGE_OBJECT = "RECEIVE_GET_LARGE_OBJECT",
  REQUEST_UPLOAD_FILE = "REQUEST_UPLOAD_FILE",
  RECEIVE_FILEUPLOAD_RESULT = "RECEIVE_FILEUPLOAD_RESULT",
  RECEIVE_SWITCH_ROLES_RESULT = "RECEIVE_SWITCH_ROLES_RESULT",
  REQUEST_CHANGE_USER_ROLE = "REQUEST_CHANGE_USER_ROLE",
  RECEIVE_CHANGE_USER_ROLE_RESULT = "RECEIVE_CHANGE_USER_ROLE_RESULT",
  RECEIVE_CONTEXT_CUSTOMERS = "RECEIVE_CONTEXT_CUSTOMERS",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
}


export { VISIBILITY_FILTERS, ACTIONS }
