import { Auth } from '@aws-amplify/auth';
import { ErrData, ValidBusinessObject } from '@iotv/datamodel';
import { LogLevel } from '@iotv/iotv-v3-types';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { QueryOutput } from 'aws-sdk/clients/dynamodb';
import { Dispatch } from 'redux';
import AppDao from '../../data/AppDao';
import { AppLogger as Logger } from '../../data/AppLogger/Logger';
import { getPrimaryObject } from '../../data/daoFunctions/stateFns';
import { getRoleArbitrationInstanceAndCurrentGroup } from '../../roleArbitration';
import type { RoleArbitrationInstanceType, UserGroupsRolesType } from '../../types/AppTypes';
import { ACTIONS } from '../actionTypes';
import { getUserGroups, getUserGroupsFromSession, getUserSessionDetails } from '../sessionFns';
const logger = new Logger()
logger.logAtLevel = LogLevel.INFO
const debug = process.env.REACT_APP_DEBUG && false;

function requestAuthenticate(credentials: any) {
  return {
    type: ACTIONS.REQUEST_AUTHENTICATE,
    credentials
  }
}

function receiveAuthenticate(cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType, roleArbitrationInstance: RoleArbitrationInstanceType | undefined, currentGroup: string) {

  return {
    type: ACTIONS.RECEIVE_AUTHENTICATE,
    cognitoUser,
    userGroupRoles,
    roleArbitrationInstance,
    currentGroup
  }
}

function receiveAuthDenied(err: Error) {
  return {
    type: ACTIONS.RECEIVE_AUTH_DENIED,
    err
  }
}

function receiveInit(normalData: any) {
  return {
    type: ACTIONS.RECEIVE_INIT,
    normalData
  }
}

function receiveContextCustomers(customers: ErrData<QueryOutput>) {
  return {
    type: ACTIONS.RECEIVE_CONTEXT_CUSTOMERS,
    customers
  }
}

function requestLogout() {
  return {
    type: ACTIONS.REQUEST_LOGOUT
  }
}

function receiveLogout() {
  return {
    type: ACTIONS.RECEIVE_LOGOUT,
    isAuthenticated: false
  }
}

export function refreshWithUserToken() {
  debug && console.log('Received refresh request');

  return async (dispatch: Dispatch) => {
    // dispatch({ type: ACTIONS.REQUEST_REFRESH_WITH_USER_TOKEN, value: true }); 
    debug && console.log('Dispached refresh request confirmation')
    return Auth.currentSession()
      .then((cognitoSession) => addUserRolesFromSession(cognitoSession, dispatch))
      .then(({ cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup }) => {
        dispatch(receiveAuthenticate(cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup))
        return { ...getUserSessionDetails({ cognitoUser, userGroupRoles }), roleArbitrationInstance, currentGroup }
      }
      )

      .then(({ user, userGroupRoles, roleArbitrationInstance, currentGroup }) => {
        initializeData(user, userGroupRoles, dispatch)
        return user
      }).then((user) => {

        logger.info('login', user)
        debug && console.log('Flushing')
        logger.flush()
          .then((flushRes) => {
            debug && console.log('reauth, flush Res', { flushRes })
          })
      })
      .catch((e: any) => {
        debug && console.log('Err in refresh auth', e);
        console.log(`swallowing error on credentials refresh`, e.message)
        //dispatch(receiveAuthDenied(e))
      })

  }
}

const addUserRolesFromSession = async (session: CognitoUserSession, dispatch: Dispatch) => {
  const userGroupRoles = getUserGroupsFromSession(session)
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const { roleArbitrationInstance, currentGroup } = getRoleArbitrationInstanceAndCurrentGroup(userGroupRoles);

  dispatch(receiveAuthenticate(cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup));
  return { cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup }
}

const addUserRoles = async (cognitoUser: CognitoUser, dispatch: Dispatch) => {
  const userGroupRoles = getUserGroups(cognitoUser)
  const { roleArbitrationInstance, currentGroup } = getRoleArbitrationInstanceAndCurrentGroup(userGroupRoles);

  dispatch(receiveAuthenticate(cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup));
  return { cognitoUser, userGroupRoles, roleArbitrationInstance, currentGroup }
}

const initializeData = async (user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, dispatch: Dispatch) => {
  const { roleArbitrationInstance, currentGroup } = getRoleArbitrationInstanceAndCurrentGroup(userGroupRoles);

  AppDao.updateStateFromApiGateway(user, userGroupRoles, roleArbitrationInstance, currentGroup)
    .then((normalData) => {
      dispatch(receiveInit(normalData));
      return normalData
    })
    .catch((e: any) => {
      debug && console.log('Err in initialize data auth', e);

    }).then((normalData) => {

      if (normalData) {
        const customers: ErrData<QueryOutput> = {
          err: null, data: {
            Items: []
          }
        }
        for (let sk in normalData) {
          if (sk.startsWith('Customer')) {
            const primaryItem = getPrimaryObject(normalData, sk)
            if (primaryItem) {
              customers.data?.Items?.push(primaryItem)
            }
            debug && console.log('Adding customer edges in LoginActions to enable hierarchyBuild')
            Object.entries(normalData[sk]).forEach(([pk, pNode]) => {
              if (pk.startsWith('Customer') && pk !== sk && pNode) {
                customers.data?.Items?.push(pNode)
                debug && console.log('Adding customer edges pushed', pNode)
              }
            })

          }
        }
        debug && console.log(`LoginActions dispatching context customer from normal data`, customers)
        dispatch(receiveContextCustomers(customers))
      }

    });


  /*
  user && getParents(user, 'Customer', undefined, 300, undefined).then( (customers) => { 
    console.log('Customers', customers)
    if ( customers?.data ) {
      ( customers.data.Items as ValidBusinessObjectList )?.sort(customerSort)
    }
    dispatch(receiveContextCustomers(customers))
  })
  */
}


export function authenticateFn(credentials: any) {
  debug && console.log('returning a function with', credentials)

  return async (dispatch: Dispatch) => {
    dispatch(requestAuthenticate(credentials));
    const cognitoUser: CognitoUser = await Auth.signIn( credentials )
    if ( cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch( {
        type: ACTIONS.NEW_PASSWORD_REQUIRED,
        cognitoUser })
    } else { addUserRoles(cognitoUser, dispatch)
    .then(({ cognitoUser, userGroupRoles }) => getUserSessionDetails({ cognitoUser, userGroupRoles }))
    .then(({ user, userGroupRoles }) => {
      initializeData(user, userGroupRoles, dispatch)
      return user
    }).then((user) => {
      logger.info('login', user)
      debug && console.log('Flushing')
      logger.flush()
        .then((flushRes) => {
          debug && console.log('authenticateFn, flush Res', { flushRes })
        }).catch((e: any) => {
          debug && console.log('Err in authenthicFn', e);
          dispatch(receiveAuthDenied(e))
        })
    }).catch((e) => {
      console.log('caught err in authenticateFn')
      dispatch(receiveAuthDenied(e))
    })
  }}  
}


//Requires refactoring as largely copy of authenticate
export function setNewPassword( user: CognitoUser, password: string ) {

  return async (dispatch: Dispatch) => {
    dispatch(requestAuthenticate({ user, password }));
    const cognitoUser: CognitoUser = await Auth.completeNewPassword( user, password )
    // if ( cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //   dispatch( {
    //     type: ACTIONS.NEW_PASSWORD_REQUIRED,
    //     cognitoUser })
    // } else { 
  
    // }
  addUserRoles(cognitoUser, dispatch)
  .then(({ cognitoUser, userGroupRoles }) => getUserSessionDetails({ cognitoUser, userGroupRoles }))
  .then(({ user, userGroupRoles }) => {
    initializeData(user, userGroupRoles, dispatch)
    return user
  }).then((user) => {
    logger.info('login', user)
    debug && console.log('Flushing')
    logger.flush()
      .then((flushRes) => {
        debug && console.log('authenticateFn, flush Res', { flushRes })
      }).catch((e: any) => {
        debug && console.log('Err in authenthicFn', e);
        dispatch(receiveAuthDenied(e))
      })
  }).catch((e) => {
    console.log('caught err in authenticateFn')
    dispatch(receiveAuthDenied(e))
  })
}
}

export function logOut() {
  debug && console.log('logging out')
  return async (dispatch: Dispatch) => {
    dispatch(requestLogout());
    const opts = {

    }
    return Auth.signOut(opts)
      .then((response) => {
        debug && console.log('got logout response', response);
        dispatch(receiveLogout())
      })
  }
}

/*
  export async function switchRoles(region: string, identityId: string, roleArn: string, cognitoArn: string) {
    const user = await Auth.currentUserPoolUser();
    const cognitoidentity = new CognitoIdentity({ apiVersion: '2014-06-30', region });
    const params = {
      IdentityId: identityId,
      CustomRoleArn: roleArn,
      Logins: {
        [cognitoArn]: user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken(),
      },
    };
    return cognitoidentity
      .getCredentialsForIdentity(params)
      .promise()
      .then( (data ) => {
        return {
          accessKeyId: data.Credentials?.AccessKeyId,
          sessionToken: data.Credentials?.SessionToken,
          secretAccessKey: data.Credentials?.SecretKey,
          expireTime: data.Credentials?.Expiration,
          expired: false,
        };
      })
      .catch(err => {
        debug && console.log(err, err.stack);
        return null;
      });
  }

  export function requestSwitchRoles() {
    debug && console.log('Received switch roles request');
    return async (dispatch: Dispatch) => {
      // dispatch({ type: ACTIONS.REQUEST_REFRESH_WITH_USER_TOKEN, value: true }); 
      debug && console.log('Dispached refresh request confirmation')
      const region = config.aws.default_region
      const IdentityId = await getCognitoId() as string;
      const roleArn: string = 'arn:aws:fuck:fuck:fuck'
      const cognitoArn = config.aws.cognito.USER_POOL_ARN
      const res = await switchRoles(region, IdentityId, roleArn, cognitoArn);
      dispatch({
          type: ACTIONS.RECEIVE_SWITCH_ROLES_RESULT,
          value: res
      })
    }

}
*/