import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from './Invalid.module.css';

interface InvalidProps {
  title?: string;
  message?: string;
}

export const Invalid: React.FC<InvalidProps> = (props: InvalidProps) => {
  const { title = 'No Permission Access', message = 'You do not have permission for this page' } = props;
  const history = useHistory();

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.componentPadding}>
        <Typography variant='h5' component='h2' color='primary'>
          {title}
        </Typography>
        <Typography variant='subtitle1' component='h6' color='primary'>
          <i>{message}</i>
        </Typography>

        <>
          <br />
          <Button variant='contained' data-testid='url-redirect' onClick={() => history.goBack()}>
            Go Back
          </Button>
        </>
      </div>
    </div>
  );
};
