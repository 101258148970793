import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';

type AddObjectProps = {
  classes: any,
  type: string,
  addFunction: Function,
  label?: string
}

const AddObject = (props: AddObjectProps) => {

  const { classes, type, label, addFunction } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={<AddCircleIcon></AddCircleIcon>}
      onClick={() => addFunction()}
    >
      {label ?? `Add ${type} `}
    </Button>

  )
}

export default AddObject 