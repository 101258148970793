import { connect } from 'react-redux'
import { authenticateFn, logOut } from '../actions/Authentication/LoginActions'
import LoginForm from '../components/access/loginForm'
import { RootState } from '../reducers'
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const debug = process.env.REACT_APP_DEBUG && false;

type LoginFormProps = {isAuthenticated: boolean, didInvalidate: boolean | undefined, username?: string, password?: string, rememberMe?: boolean, location: any}

const mapStateToProps = (state: RootState, ownProps: LoginFormProps) => {
		debug && console.log('Login Actions mapStateToProps', state)
  return  Object.assign({}, state.AccessReducer )
  
  
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: LoginFormProps) => {
  return {
    authenticate: ( credentials: {username: string, password: string}) => {
		 debug && console.log('Login Container requested auth', ownProps)

      dispatch(authenticateFn( credentials ))
    },
    logout: () => dispatch(logOut())
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)