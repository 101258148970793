import { Assembler, flattenObject, getContainedKeys, getContainedPopulatedKeys, timestampToMS, ValidModelObject, smashObjects } from "@iotv/datamodel-core";
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import { slides } from "googleapis/build/src/apis/slides";
import React, { useEffect, useRef } from "react";
import { NavSections } from "../../navigation/NavSections";
import { AppLocalStorage } from "../../../data/LocalStorage/AppLocalStorage";
import {
    DiagnosticViewProps, TabSelectionProps, UserFunctions, ViewDefinition
} from "../../../types/AppTypes";
import { ObjectKeyPluckerTable } from "../../generic/KeyPlucker/ObjectKeyPluckerTable";
import { TabPanel } from "../../utils/TabPanelShim";
import { DatastoreObjectType, MapLike, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { getNiceDate } from "../../../data/TypeHelpers";
import { SimpleExport } from "../../io/simpleExport";
import { useStyles } from '../diagnosticStyles'
import { getViewDefintionFromObject, getViewDefintionFromMutantZombie } from "../../factories/AttributeDefinitions";
import { useContainerDimensions } from "../../..//hooks/useContainerDimenstions";

const debug = process.env.REACT_APP_DEBUG && false



export type MonitorTabProps = DiagnosticViewProps & TabSelectionProps & { selectedDevices: DatastoreObjectType[] }
    & { userFunctions: UserFunctions }

export const MonitorTab = ({ contextCustomer, userFunctions, value, index, deviceMessages, classes, selectedDevices }: MonitorTabProps) => {
    const [filterToSelected, setFilterToSelected] = React.useState<boolean>(true)
    const widthRef = useRef(null);
    const { width, height } = useContainerDimensions(widthRef)
    const localClasses = useStyles()

    debug && console.log('Monitor Tab has selected devices', selectedDevices)
    debug && console.log('Monitor Tab has filter to selected', filterToSelected)
    debug && console.log(`Monitor Tab got ${deviceMessages?.length ?? 0} messages and is looking for deviceIds`, selectedDevices.map((device) => device.sk.replace('Device:', '')))
    debug && ((deviceMessages?.length ?? 0) > 0) && console.log(`like this message`, deviceMessages?.[0])

    const filterDeviceMessages = (flatDeviceMesage: { deviceId?: string, timestamp?: number }) => filterToSelected ? selectedDevices.find((device) => device.sk.includes(flatDeviceMesage.deviceId ?? 'xxx')) : flatDeviceMesage

    const objects = deviceMessages?.map((message: ValidModelObject<"ThingShadowState">) => {
        const instance = Assembler.getInstance(message)
        return {
            ...flattenObject(instance ?? {})
        }
    }).filter(filterDeviceMessages)
        .sort((a, b) => (a.timestamp ?? 0) - (b.timestamp ?? 0)).reverse()
        .map((item) => ({ ...item, timestamp: item.timestamp ? getNiceDate(timestampToMS(item.timestamp) ?? 0) : undefined })) ?? [];



    debug && console.log(`Monitor Tab filtered, ${objects.length} messages with`, objects)



    const [userSelectedKeys] = React.useState<string[]>(AppLocalStorage.get(NavSections.DIAGNOSTIC, 'userSelectedKeysMonitorTab') ?? [])

    const containedKeys: string[] = []


    useEffect(() => {

    }, [])

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([])

    useEffect(() => {

    }, [objects.length])

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.currentTarget.checked;
        const clone = [...selectedKeys];
        if (checked) {

            clone.push(e.currentTarget.value)

        } else {
            const idx = selectedKeys.findIndex((key) => key === e.currentTarget.value);
            if (idx !== undefined ) {
                clone.splice(idx, 1)
            }
        }
        AppLocalStorage.set(NavSections.DIAGNOSTIC, 'userSelectedKeysMonitorTab', clone)
        setSelectedKeys(clone);
    }

    const smashedObject = { ...smashObjects(objects), type: 'Fucktuple' }
    const exportViewDefinition: ViewDefinition = getViewDefintionFromMutantZombie(smashedObject as ValidBusinessObject, selectedKeys)

    return <TabPanel {...{ value, index }}>
        <Grid container   { ...{ key: 'cont'} }>

            <Grid item {...{ xs: 12, key: 'item1' }}>
                <FormGroup>
                    <div>{selectedDevices.length > 0 ? `Selected devices: ${selectedDevices.map((item) => {
                        const vob = item as ValidBusinessObject;
                        return vob.deviceId ?? vob.name ?? vob.eui
                    })}` : 'No devices selected!'}</div>
                    <FormControlLabel control={<Checkbox {...{ checked: filterToSelected, onChange: (e) => setFilterToSelected(e.currentTarget.checked) }} />} label="Filter to Selected" />
                </FormGroup>


            </Grid>
            <Grid item {...{ xs: 12, key: 'item2'}}>
                <SimpleExport {...{ contextCustomer, userFunctions, viewDefinition: exportViewDefinition, vobs: objects as unknown as ValidBusinessObjectList, key: 'SimpleXPORT' }}></SimpleExport>
            </Grid>
            <Grid item xs={9} lg={10}  { ...{ key: 'item3'} }>
                <Card>
                    <CardContent>
                        <Typography {...{ variant: 'h5' }}>Monitor</Typography>
                        <ObjectKeyPluckerTable {...{ objects, keys: selectedKeys.length > 0 ? ['timestamp', ...selectedKeys] : undefined }} />


                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2} lg={2}  { ...{ key: 'item4'} }>
                <Card>
                    <CardContent>
                        <FormControl size='small' component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Select keys</FormLabel>
                            <FormControlLabel {...{
                                control: <Checkbox {...{ size: 'small', value: 'all', onChange: (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked ? setSelectedKeys(containedKeys) : setSelectedKeys([]) }} />,
                                label: 'All / None'
                            }} />
                            <FormGroup>
                                {containedKeys.map((k, i) => (
                                    <FormControlLabel {...{
                                        control: <Checkbox {...{ size: 'small', value: k, checked: selectedKeys.includes(k), onChange: handleCheckboxChange, name: `${i}_cb` }} />,
                                        label: k
                                    }}

                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>
    </TabPanel>
}