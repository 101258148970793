// export const applyPolygoStyle = ( map: google.maps.Map<Element> ) => {

//     map.data.setStyle((feature) => {
//         let color = "red";
    
//         if ( feature.getGeometry().getType() === 'Polygon' ) {
//           color =  'green'
//         }
//         if ( feature.getProperty('dataCount')) {
//           color = perc2color(feature.getProperty('dataCount') / 0.25)
//         }
//         return /** @type {!google.maps.Data.StyleOptions} */ {
//           fillColor: color,
//           strokeColor: color,
//           strokeWeight: 0,
//         };
//       });
    
// }

// https://gist.github.com/mlocati/7210513
export const  perc2color = (perc: number) => {
	var r, g, b = 0;
	if(perc < 50) {
		r = 255;
		g = Math.round(5.1 * perc);
	}
	else {
		g = 255;
		r = Math.round(510 - 5.10 * perc);
	}
	var h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}