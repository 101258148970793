import {
  AddressType,
  C021CustomerAccountType,
  c021ToLSCAccountType,
  c021ToLSCAccountTypeMap,
  Customer,
  CustomerAccountType,
  deviceEnabledTypes,
  GeofencePointsType,
  lscToC021AccountType,
  NodeEdgeMapper,
  ObjectHistory,
  PathDirection,
  ThingShadowState,
  ValidModelObject,
  ValidModelObjects,
} from '@iotv/datamodel';
import {
  AdjacentType,
  AppValueTypes,
  DatastoreObjectType,
  ErrData,
  setAContainsSomeB,
  StateSnapshot,
  ValidBusinessObject,
  ValidBusinessObjectList,
} from '@iotv/iotv-v3-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useTabHandler } from '../../../../hooks/useTabHandler';
import styles from '../../../../cosmetics/sharedCardStyles';
import { getImageUrl } from '../../../../data/aws/s3/UserBlobs';
import { isDeviceEnabledType, thingShadowStateToStateSnapshot } from '../../../../data/TypeHelpers';
import { useObjectHistory } from '../../../../hooks/useObjectHistory';
import {
  CustomerViewSearchParamsType,
  GetAdjacentResponse,
  KeyMappedComponents,
  NormalizedData,
  ObjectCardProps,
  UserTransactionType,
  ValidCustomerObject,
  ViewDefinition,
  ViewObject,
} from '../../../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../../../util/geo/GeoDataHelpers';
import ObservationCard from '../../../../components/cards/Observation/ObservationCard';
import PhotosCard from '../../../../components/cards/PhotosCard';
import { IncidentListCard } from '../../../../components/common/IncidentListCard';
import ObjectGraphCard from '../../../../components/generic/ObjectGraphCard';
import ObjectHistoryCard from '../../../../components/generic/ObjectHistoryCard';
import ObjectHistoryTableCard from '../../../../components/generic/ObjectHistoryTableCard';
import MapCard from '../../../../components/geo/MapCard';
import { SlaveTab } from '../../../../components/factories/SlaveTab';
import { EntityDashboard } from './tabs/Dashboard';
import { getCustomerHierarchy } from '../../data/queries/customerHierachy';
import { getCustomerTanks } from '../../data/queries/customerTanks';
import DetailCard from '../../../../components/cards/DetailCard';
import { getGeoCodeableAddress, getLocatableAddress } from '../../../../data/TypeHelpers';
import { getQueryParamsFromHistory } from '../../../../util/Routing/queryParams';
import { useHistory } from 'react-router-dom';
import { CommunityTanksCard } from './CommunityTanksCard';
import { addToNormalData } from '../../../../data/daoFunctions/stateFns';
import { NavSections } from '../../../..//components/navigation/NavSections';
import { v4 } from 'uuid';
import { DroughtManagementReportView } from './DroughtManagement/DroughtManagementReportView';
import { DroughtManagementPlan } from './DroughtManagement/Plan/DroughtManagementPlan';
import { AttributeMap } from 'aws-sdk/clients/dynamodb';
import DroughtManagementPlanTemplate from './DroughtManagement/Plan/DroughtManagementPlanTemplate';
import Stagehistory from './DroughtManagement/Plan/Stagehistory';
import AppDao from '../../../../data/AppDao';
import { getAdjacent } from '../../../../data/daoFunctions/daoFunctions';
import { DMPStage } from './DroughtManagement/DMPExtrasV2';

const debug = process.env.REACT_APP_DEBUG && true;

const useStyles = makeStyles((theme) => styles(theme));

const filterByType =
  (type: string) =>
    (vob: DatastoreObjectType): vob is ValidBusinessObject =>
      (vob as ValidBusinessObject).type === type;

const HOUSEHOLD = c021ToLSCAccountType('Household' as C021CustomerAccountType);
const COMMUNITY = c021ToLSCAccountType('Community' as C021CustomerAccountType);
const ISLAND_COUNTRY = c021ToLSCAccountType('Country / Island' as C021CustomerAccountType);

export default function CustomerCard(props: ObjectCardProps) {
  debug && console.log('Object card template got props', JSON.stringify(props.viewObject, null, 1));
  const classes = useStyles();

  const {
    viewObject: { matchedPrimary },
    contextCustomer,
    viewObject,
    user,
    userSelectedGroup,
    userGroupRoles,
    userGeolocation,
    transactionFunctions,
    userFunctions,
    searchParams,
    history,
  } = props;
  console.log("tryto find user", contextCustomer)

  const [objectHistory, setObjectHistory] = useObjectHistory(matchedPrimary);

  const routerHistory = useHistory();

  const [householdTanks, setHouseHoldTanks] = React.useState<ValidModelObjects<'Tank'>>([]);
  const [ICCTanks, setICCTanks] = React.useState<ValidModelObjects<'Tank'>>([]);
  const [normalCustomerData, setNormalCustomerData] = React.useState<NormalizedData>({});
  const [update, setUpdate] = React.useState<boolean>(false);
  const [nodeEdgeMapper, setNodeEdgeMapper] = React.useState<NodeEdgeMapper>();
  const [droughPlan, setDroughPlan] = React.useState<ValidBusinessObject>();
  const [nodesToDisplay, setNodesToDisplay] = React.useState<ValidBusinessObjectList>([])

  useEffect(() => {

    // FIX LATER gets provided an edge, so override the query to provide the contextCustomer as an object
    getCustomerHierarchy({ ...contextCustomer, pk: contextCustomer?.sk } as ValidModelObject<'Customer'>).then(({ err, data }) => {
      if (err == null && data != null) {
        const nodeEdgeMapper = new NodeEdgeMapper()
        const filterHierarchNodes: ValidBusinessObjectList = data.nodes.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
        const filterHierarchEdges: ValidBusinessObjectList = data.edges.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
        nodeEdgeMapper.loadItems(filterHierarchNodes)
        nodeEdgeMapper.loadItems(filterHierarchEdges)
        debug && console.log('CommunitiesView Hierarchy', { nodeEdgeMapper })
        var outout = nodeEdgeMapper.getChildNodes({ ...contextCustomer, pk: contextCustomer?.sk } as DatastoreObjectType) as ValidBusinessObjectList
        console.log("nodesToDisplay", contextCustomer)
        findsource(outout).then((res) => {
          setNodesToDisplay(res)
        }
        )
        setNodeEdgeMapper(nodeEdgeMapper)
      }
    }
    )
  }, [contextCustomer, contextCustomer?.sk, history])
  var foundNode: any

  const [customerIn, setcustomerIn] = React.useState<any>(foundNode ? foundNode as ValidModelObject<'Customer'> :
    matchedPrimary?.type === 'Customer' ? (matchedPrimary as ValidModelObject<'Customer'>) : undefined);
  if (matchedPrimary) {
    foundNode = nodesToDisplay.find(node => node.sk === matchedPrimary.sk);
  }

  useEffect(() => {
    setcustomerIn(foundNode ? foundNode as ValidModelObject<'Customer'> :
      matchedPrimary?.type === 'Customer' ? (matchedPrimary as ValidModelObject<'Customer'>) : undefined);
  }, [foundNode, matchedPrimary]);


  async function findsource(customerIn: ValidBusinessObjectList) {
    console.log("findplan2", customerIn)
    if (customerIn) {
      var newlist: string[] = []
      for (var i = 0; i < customerIn.length; i++) {
        var newstring = ""
        var getstage = fetchDroughtManagementPlanStageLocal(customerIn[i])
        await getstage.then((res) => {
          var stagename = getstagename(res, res?.activestage)
          newlist.push(stagename)
        }
        )
        // if (droughtManagementPlan) {
        //     var stagename = getstagename(droughtManagementPlan, droughtManagementPlan?.activestage)
        //     newlist.push({ ...customerIn[i], currentstagename: stagename })
        // }
      }
      var outputlist: ValidBusinessObjectList = []

      for (var i = 0; i < customerIn.length; i++) {
        outputlist.push({ ...customerIn[i], currentstagename: newlist[i] })
      }
      console.log("findplan4", outputlist)
      return outputlist
    }
    else {
      return customerIn
    }
  }

  const fetchDroughtManagementPlanStageLocal = async (communityObject: ValidBusinessObject) => {
    let planContext: ValidBusinessObject | undefined = communityObject;

    while (planContext !== undefined) {
      let plan: GetAdjacentResponse = await getAdjacent(planContext, 'DroughtManagementPlan', PathDirection.child);
      if (plan.data && plan.data.length > 0) {
        var planCasted = plan.data[0];
        if ((planCasted.stage) !== undefined) {
          return (planCasted as ValidModelObject<'DroughtManagementPlan'>)
          //setDroughtManagementPlan(planCasted as C021ValidModelObject<'DroughtManagementPlan'>);

        }
      }
      let next: GetAdjacentResponse = await getAdjacent(planContext, 'Customer', PathDirection.parent);

      if (next.data && next.data.length === 1) {
        planContext = next.data[0];
      } else {
        planContext = undefined;
        break;
      }
    }
  };

  function getstagename(source: ValidModelObject<'DroughtManagementPlan'> | undefined, input: number) {
    if (input == -1) {
      input = 0
    }
    return source?.stage[input]?.name ? `${source?.stage[input]?.stageNumber}) ${source?.stage[input]?.name} ` : `Stage ${source?.stage[input]?.stageNumber}`
  }

  const getRelatedItems = async (customer: ValidModelObject<'Customer'>) => {
    const normalCustomerData: NormalizedData = {};

    console.log(customer);

    const customerHierachyRes = await getCustomerHierarchy(customer);
    if (customerHierachyRes.data) {
      const customerHierachy = customerHierachyRes.data.nodes as ValidModelObjects<'Customer'>;
      console.log(customerHierachy);
      const ICCEntities: ValidModelObjects<'Customer'> = [];
      const householdEntities: ValidModelObjects<'Customer'> = [];
      customerHierachy.forEach((entity) => {
        if (entity.accountType === HOUSEHOLD) {
          householdEntities.push(entity);
        } else {
          ICCEntities.push(entity);
        }
      });

      const [householdTanks, ICCTanks] = await Promise.all([
        // Check wether end nodes are returned or there will be double counting
        getCustomerTanks(householdEntities).then(({ err, data }) => {
          if (data) {
            return data.filter((tankOrEdge) => tankOrEdge.type.startsWith('Tank'));
          } else return [];
        }),
        getCustomerTanks(ICCEntities).then(({ err, data }) => {
          if (data) {
            debug && console.log('CustomerCard pushing ICC Tank', data);
            return data.filter((tankOrEdge) => tankOrEdge.sk.startsWith('Tank'));
          } else return [];
        }),
      ]);

      customerHierachy.forEach((item) => addToNormalData(item, normalCustomerData));
      householdTanks.forEach((item) => addToNormalData(item, normalCustomerData));
      ICCTanks.forEach((item) => addToNormalData(item, normalCustomerData));
      setNormalCustomerData(normalCustomerData);
      setHouseHoldTanks(householdTanks as ValidModelObjects<'Tank'>);
      setICCTanks(ICCTanks as ValidModelObjects<'Tank'>);
      console.log({ householdTanks, ICCTanks });
    }

    console.log('CustomerCard getCustomerHierachy res', customerHierachyRes);
  };

  useEffect(() => {
    if (customerIn) {
      //TODO: Investigate further. (Sometimes loads extra tanks)Temp patch
      const obj = {
        ...customerIn,
        pk: customerIn.sk,
      };
      getRelatedItems(obj);
    }
  }, [customerIn?.sk]);

  useEffect(() => {
    if (customerIn && update === true) {
      setUpdate(false);
      const obj = {
        ...customerIn,
        pk: customerIn.sk,
      };
      getRelatedItems(obj);
    }
  }, [update]);

  const GP = Grid;

  const customerAccountTypeShim = c021ToLSCAccountTypeMap;

  const locatableAddressShim = matchedPrimary ? matchedPrimary.name : undefined;
  const locatableAddress = matchedPrimary ? getGeoCodeableAddress.call(matchedPrimary) ?? locatableAddressShim : undefined;
  debug && console.log('Customer Card locatable address', { locatableAddress, matchedPrimary });

  const action = UserTransactionType.CREATE; // temp

  type CustomerAttributeKeys = keyof AddressType;
  const viewDefinition: ViewDefinition & Omit<{ [key in CustomerAttributeKeys]: any }, 'dwelling_number'> = {
    name: {
      key: 'name',
      label: 'Name',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    street_number: {
      key: 'street_number',
      label: 'Street Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    suburb: {
      key: 'suburb',
      label: 'Suburb',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    city: {
      key: 'city',
      label: 'City',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    country: {
      key: 'country',
      label: 'Country',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    post_code: {
      key: 'post_code',
      label: 'Postcode',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    email: {
      key: 'email',
      label: 'Email',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    phoneNumber: {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: /\+\d+$/,
      failsValidationText: 'phone format +021...',
    },
    accountType: {
      key: 'accountType',
      label: 'Account Type',
      type: AppValueTypes.ENUM,
      enumKV: customerAccountTypeShim,
      editable: action === UserTransactionType.CREATE,
      valueSetter: (x: C021CustomerAccountType) => c021ToLSCAccountType(x),
      valueGetter: (x: CustomerAccountType) => lscToC021AccountType(x),
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
  };

  const tabsToDisplay: KeyMappedComponents = {
    Dashboard:
      matchedPrimary && objectHistory ? (
        <GP container {...{ key: 'Dashboard' }}>
          <EntityDashboard {...{ ...props, householdTanks, ICCTanks, objectHistory, customerIn }}></EntityDashboard>
        </GP>
      ) : null,
    // Details: (matchedPrimary) ? <GP container {...{ key: 'DetailsDiv' }}>
    //   <Grid item xs={12} lg={6} {...{ key: 'DetailsGrid' }}>
    //     <DetailCard { ...{ matchedPrimary, title: matchedPrimary.name, transactionFunctions, viewDefinition, actions: [ UserTransactionType.UPDATE], history}}></DetailCard>
    //   </Grid>
    // </GP> : null,
    Tanks: matchedPrimary ? (
      <GP container {...{ key: 'TanksDiv' }}>
        <CommunityTanksCard {...{ ICCTanks, householdTanks, normalCustomerData, setUpdate, ...props }}></CommunityTanksCard>
      </GP>
    ) : null,
    DroughtReport: matchedPrimary ? (
      <GP container {...{ key: 'LinksDiv' }}>
        {objectHistory?.dailyStateHistory && (
          <DroughtManagementReportView {...{ ...props, objectHistory: objectHistory as Required<ObjectHistory> }}></DroughtManagementReportView>
        )}
      </GP>
    ) : null,

    DroughtResponseHistory:
      matchedPrimary && objectHistory ? (
        <GP container {...{ key: 'LinksDiv' }}>
          {droughPlan ? <Stagehistory contextCustomer={matchedPrimary} source={droughPlan} finduser={props} /> : <>No Plan Made</>}
        </GP>
      ) : null,

    DroughtPlan: matchedPrimary ? (
      <GP container {...{ key: 'LinksDiv' }}>
        {/* {customerIn && <DroughtManagementPlan {...{ planContextCustomer: customerIn, ...props }}></DroughtManagementPlan>}{' '} */}
        {droughPlan ? <DroughtManagementPlanTemplate contextCustomer={matchedPrimary} source={droughPlan} finduser={props} /> : <>No Plan Made</>}
      </GP>
    ) : null,

    History:
      matchedPrimary && objectHistory && contextCustomer ? (
        <GP container {...{ key: 'HistoryDiv' }}>
          <Grid item xs={12} {...{ key: 'HistoryGrid' }}>
            <ObjectHistoryCard
              {...{
                classes,
                matchedPrimary: matchedPrimary,
                objectHistory,
                objectHistoryConfig: {
                  dailyStateHistory: true,
                  recentStateHistory: true,
                },
              }}
            ></ObjectHistoryCard>
          </Grid>
          <Grid item xs={12} {...{ key: 'HistoryTableGrid' }}>
            <ObjectHistoryTableCard
              {...{
                contextCustomer: contextCustomer as ValidCustomerObject,
                viewObject,
                contextObject: undefined,
                objectHistory,
                userFunctions,
                transactionFunctions,
                searchParams,
                history,
                actions: [],
                viewDefinition: {},
                filterMetricKeys: undefined,
                title: `${matchedPrimary.name}'s history`,
                userSelectedGroup,
              }}
            ></ObjectHistoryTableCard>
          </Grid>{' '}
        </GP>
      ) : null,

    Observations:
      matchedPrimary && isDeviceEnabledType(matchedPrimary) && objectHistory && contextCustomer ? (
        <GP container {...{ key: 'ObservationsDiv' }}>
          <Grid item xs={12} {...{ key: 'ObservationsGrid' }}>
            <ObservationCard
              {...{
                key: 'ObservationsCard',
                contextCustomer: contextCustomer as ValidCustomerObject,
                viewObject,
                contextObject: undefined,
                objectHistory,
                userFunctions,
                transactionFunctions,
                searchParams,
                history,
                actions: [],
                viewDefinition: {},
                filterMetricKeys: undefined,
                title: `${matchedPrimary.name}'s observations`,
                userSelectedGroup,
              }}
            ></ObservationCard>
          </Grid>{' '}
        </GP>
      ) : null,

    // Charts:
    //   objectHistory?.dailyStateHistory || matchedPrimary?.dailyStateHistory ? (
    //     <GP container {...{ key: 'ChartsDiv' }}>
    //       <Grid container>
    //         {objectHistory?.dailyStateHistory && objectHistory.dailyStateHistory.getLength() > 0 && (
    //           <Grid item xs={6}>
    //             <ObjectGraphCard
    //               {...{
    //                 key: `${objectHistory.sk}_dailyStateHistoryGraph`,
    //                 historyObject: objectHistory.dailyStateHistory,
    //                 classes,
    //                 title: 'Daily State History',
    //               }}
    //             ></ObjectGraphCard>
    //           </Grid>
    //         )}

    //         {objectHistory?.recentStateHistory && objectHistory.recentStateHistory.getLength() > 0 && (
    //           <Grid item xs={6}>
    //             <ObjectGraphCard
    //               {...{
    //                 key: `${objectHistory.sk}_recentStateHistoryGraph`,
    //                 historyObject: objectHistory.recentStateHistory,
    //                 classes,
    //                 title: 'Recent State History',
    //               }}
    //             ></ObjectGraphCard>
    //           </Grid>
    //         )}
    //       </Grid>
    //     </GP>
    //   ) : null,

    // Geo: (
    //   <GP container>
    //     <Grid item xs={12} lg={6} {...{ key: 'GeoGrid' }}>
    //       <MapCard {...{ position: undefined, editable: false, locatableAddress, viewObjects: [viewObject], polylines: {}, optionals: undefined }}></MapCard>
    //     </Grid>
    //   </GP>
    // ),

    Incidents:
      isDeviceEnabledType(matchedPrimary) || setAContainsSomeB([matchedPrimary?.type ?? 'ignore'], ['Device', 'Gateway']) ? (
        <GP container>
          <Grid item xs={12} lg={6} {...{ key: 'IncidentsGrid' }}>
            <IncidentListCard
              {...{ transactionFunctions, userGroupRoles, userFunctions, viewObject, contextCustomer, searchParams, history }}
            ></IncidentListCard>
          </Grid>
        </GP>
      ) : null,

    Media:
      matchedPrimary && isDeviceEnabledType(matchedPrimary) ? (
        <GP>
          <Grid item xs={12} {...{ key: 'PhotosGrid' }}>
            <PhotosCard
              {...{
                matchedPrimary: matchedPrimary,
                userFunctions,
                transactionFunctions,
                searchParams,
                history,
                actions: [],
                viewDefinition: {},
                title: `${matchedPrimary.name}'s photos`,
              }}
            ></PhotosCard>
          </Grid>
        </GP>
      ) : null,
  };

  const {
    state: [[tabValue, setTabValue]],
    handlers: { handleTabChange },
  } = useTabHandler(tabsToDisplay);

  if (searchParams?.tabName) {
    const openTabIndex = Object.entries(tabsToDisplay)
      .filter(([k, v]) => v)
      .map(([k, v]) => k)
      .indexOf(searchParams.tabName);
    if (openTabIndex !== -1 && tabValue !== openTabIndex) {
      setTabValue(openTabIndex);
    }
  }

  debug && console.log(`${matchedPrimary?.type} is deviceEnabledType ${matchedPrimary ? isDeviceEnabledType(matchedPrimary) : false}`, deviceEnabledTypes);
  useEffect(() => {
    if (matchedPrimary?.type === 'Tank' && viewObject.largeObject === undefined) {
      transactionFunctions.getLargeObject(matchedPrimary);
    }
  }, []);

  const [imageUrl, setImageUrl] = React.useState('');

  useEffect(() => {
    setObjectHistory(undefined);
    async function effectUrl() {
      const objectOrString = await getImageUrl(matchedPrimary);
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url);
        setImageUrl(url);
      }
    }

    if (matchedPrimary) {
      getAssignedPlan(matchedPrimary);
      effectUrl();
    }
  }, [matchedPrimary?.sk]);

  const {
    mapViewObject,
    lastestThingStateShadow,
    track,
    position,
    thingStatesMostRecentFirst,
  }: {
    mapViewObject: ViewObject | undefined;
    lastestThingStateShadow: ThingShadowState | undefined;
    track: GeofencePointsType[];
    position: GeofencePointsType | undefined;
    thingStatesMostRecentFirst: ThingShadowState[];
  } = isDeviceEnabledType(matchedPrimary)
      ? extractGeoDataFromViewObject(viewObject)
      : { mapViewObject: undefined, lastestThingStateShadow: undefined, track: [], position: undefined, thingStatesMostRecentFirst: [] };

  debug && console.log('ObjectCard geo', mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst);

  useEffect(() => {
    debug && console.log(`Object card use effect on lastestThingStateShadow at ${new Date()}`, lastestThingStateShadow);
    if (lastestThingStateShadow && objectHistory?.recentStateHistory) {
      const filterKeys = objectHistory.recentStateHistory.keys();
      const snapshot: StateSnapshot<any> = thingShadowStateToStateSnapshot(lastestThingStateShadow, filterKeys);
      const objectHistoryClone = new ObjectHistory(objectHistory);
      objectHistoryClone.recentStateHistory?.push(snapshot);
      setObjectHistory(objectHistoryClone);
    }
  }, [lastestThingStateShadow?.sk]);

  const thingShadowStates = viewObject.matchedRelatedBySk ? viewObject.matchedRelatedBySk.filter((item) => item.type === 'ThingShadowState') : [];
  debug && console.log('thingShadowStates', thingShadowStates);

  const actions: UserTransactionType[] = [UserTransactionType.CREATE];

  const getFilteredTabs = (customer: ValidBusinessObject, mappings: [string, JSX.Element | null][]): [string, JSX.Element | null][] => {
    let keepPlan = false;
    if (customer.accountType === CustomerAccountType.Y || customer.accountType === CustomerAccountType.Z) {
      keepPlan = true;
    } else {
      const res = nodeEdgeMapper?.getChildNodes(customer) as ValidBusinessObjectList;
      if (res) {
        if (res.length > 0 && res.every((cont) => cont.accountType === CustomerAccountType.Y)) {
          keepPlan = true;
        }
      }
    }
    let maps = mappings.filter(([tabName, element], i) => element !== null);
    return keepPlan ? maps : maps.filter(([tabName, element], i) => tabName !== 'DroughtPlan');
  };

  const getAssignedPlan = async (context: ValidBusinessObject) => {
    let mapperData = await getCustomerHierarchy(context as ValidModelObject<'Customer'>);
    const customerMapper = new NodeEdgeMapper();
    if (mapperData.err == null && mapperData.data != null) {
      const filterHierarchNodes: ValidBusinessObjectList = mapperData.data.nodes as ValidBusinessObjectList;
      const filterHierarchEdges: ValidBusinessObjectList = mapperData.data.edges as ValidBusinessObjectList;
      customerMapper.loadItems(filterHierarchNodes);
      customerMapper.loadItems(filterHierarchEdges);
    }
    setNodeEdgeMapper(customerMapper);

    let planContext: ValidBusinessObject | undefined = context;
    while (planContext !== undefined) {
      let plan: GetAdjacentResponse = await getAdjacent(planContext, 'DroughtManagementPlan', PathDirection.child);

      if (plan.data && plan.data.length > 0) {
        var planCasted = plan.data[0];
        if ((planCasted.stage as DMPStage[]) !== undefined) {
          setDroughPlan(planCasted);
        }
      }
      let next: GetAdjacentResponse = await getAdjacent(planContext, 'Customer', PathDirection.parent);

      if (next.data && next.data.length === 1) {
        planContext = next.data[0];
      } else {
        planContext = undefined;
        break;
      }
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={matchedPrimary?.name} subheader={matchedPrimary?.breed} />
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs {...{ value: tabValue, onChange: handleTabChange, variant: 'fullWidth' }}>
            {matchedPrimary &&
              getFilteredTabs(matchedPrimary, Object.entries(tabsToDisplay)).map(([tabName, element], i) => (
                <Tab
                  {...{
                    key: `tab_${i}`,
                    label: tabName,
                    disabled: false,
                    onClick: () => routerHistory.push(`${routerHistory.location.pathname}?tabName=${tabName}`),
                  }}
                />
              ))}
          </Tabs>
        </Box>

        {Object.values(tabsToDisplay)
          .filter((c) => c !== null)
          .map((component, index) => (
            <SlaveTab {...{ key: `tab${index}`, orderedComponents: [component as JSX.Element], index, value: tabValue }}></SlaveTab>
          ))}
      </CardContent>
    </Card>
  );
}

function getPosition(polyline: any) {
  throw new Error('Function not implemented.');
}
