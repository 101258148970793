import { ValidBusinessObject } from '@iotv/datamodel'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { AdjacentType } from '../../types/AppTypes'
import { NavSections } from './NavSections'


export function SimpleObjectLink( props: {object: ValidBusinessObject, adjacentType: AdjacentType
} )  {

    const {object } = props;

return <NavLink to = {`/${NavSections.THINGVIEW}/${object.type}/${object.id}`}>{object.name}</NavLink>

}