
import { Theme } from '@material-ui/core/styles';

const mapStyles = (theme: Theme) => ({
  gMapObjectCard: {
    width: '100%',
    height: '80%'
  },
  mapViewObjectCard: {
    width: '100%',
    height: '90%'
  },
  mapCardObjectCard: {
    width: '50vw',
    height: '50vw'
  },
});

export { mapStyles };

