
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Table, TableCell, TableRow, Typography } from "@material-ui/core";
import { DocumentClientQueryHookOutputV1 } from "../../../types/LabTypes";
import { AdjacentType, DatastoreObjectType, DeviceControlTransactionProps, getZombieInstanceFromPrimaryKey, IDeviceControlTransaction, ValidModelObjects } from "@iotv/datamodel-core";
import { TabPanel } from "../../utils/TabPanelShim";
import { ValidBusinessObject } from '@iotv/iotv-v3-types'
import {
    DiagnosticViewProps, ListDevicesRequest, TabSelectionProps
} from "../../../types/AppTypes";
import { useObjectByTypeAndName } from "../../../hooks/useObjectByTypeAndName";
import { useEffect } from "react";


export type SelectionTabProps = {
    deviceQueryHook: DocumentClientQueryHookOutputV1<ListDevicesRequest>
    , setReceivedDeviceControlTransactions: React.Dispatch<React.SetStateAction<IDeviceControlTransaction[]>>

} & Omit<DiagnosticViewProps, 'deviceMessages'> & TabSelectionProps



export const SelectionTab = ({ value, index, classes, deviceQueryHook, setReceivedDeviceControlTransactions }: SelectionTabProps) => {

    const {
        querySetterGetters: [
            [devices],
        ],
        selectionControls: [selectedDevices, setSelectedDevices],
    } = deviceQueryHook

    const {
        components: {
            objectTypeSelector,
            entitySelector,
        },
        state: {
            currentEntity: [searchedDevice, setSearchedDevice],
        }
    } = useObjectByTypeAndName({ contextObject: undefined, adjacencyType: AdjacentType.SUBJECT, currentObject: selectedDevices[0] as ValidBusinessObject | undefined, currentObjectTypeId: 'Device', validTypeIds: ['Device'] })


    useEffect(() => {
        if (searchedDevice) {
            setSelectedDevices([searchedDevice])
        }

    }, [searchedDevice?.sk])

    return <TabPanel {...{ value, index }}>
        <Grid container >
            <Grid {...{ item: true, xs: 6, key: 'deviceListSelect' }} >
                <Card className={classes.form}>
                    <CardContent>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Select device</FormLabel>
                            <FormGroup {...{ style: {} }}>
                                {devices.sort((a, b) => a.name < b.name ? -1 : 1).map((device, i) => <FormControlLabel {...{
                                    key: `device_${i}`,
                                    control: <Checkbox {...{

                                        checked: selectedDevices.find((object) => object.sk === device.sk) !== undefined,
                                        onChange: () => {
                                            setReceivedDeviceControlTransactions([]);
                                            setSelectedDevices([device as DatastoreObjectType])
                                            // setStatus(DeviceTransactionStatus.USER_REQUESTED)
                                        },
                                        name: `${i}_cb`

                                    }} />,
                                    label: device.name ?? device.deviceId ?? device.eui ?? "Anyones guess"
                                }} />)}


                            </FormGroup>
                        </FormControl>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item {...{ xs: 6, key: 'deviceSearch' }}>
                <Card>

                    <CardContent>
                        <Table {...{ size: 'small' }}>
                            <TableRow>
                                <TableCell>
                                    <Typography>Search Devices</Typography>
                                    {entitySelector}
                                </TableCell>
                            </TableRow>
                        </Table>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>


    </TabPanel>
}