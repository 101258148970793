import { MapLike } from "@iotv/iotv-v3-types";
import { camelToDisplay, getContainedPopulatedKeys } from '@iotv/datamodel-core'
import { Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";

export type ObjectKeyPluckerTableProps = {
    objects: MapLike<any>[], keys?: string[], showNestedObjects?: boolean
}

export const ObjectKeyPluckerTable = ( {  objects, keys, showNestedObjects = false }: ObjectKeyPluckerTableProps  ) => {



    const pluckedKeys = keys ?? getContainedPopulatedKeys(objects)

    return <Table { ...{ key: 'plucker'}}>
        <TableHead>
            <TableRow { ...{ key: `r_head`}}>
                { pluckedKeys.map( (k: string, i) => <TableCell { ...{ key: `c_${i}`}}>{camelToDisplay(k)}</TableCell>)}
            </TableRow>

        </TableHead>
        <TableBody>
                { objects.map( ( object, i ) => <TableRow { ...{ key: `r_${i}`}}>
                    { pluckedKeys.map( (k: string, i) => <TableCell { ...{ key: `c_${i}`}}>{!(object[k] instanceof Object) ? object[k] : showNestedObjects ? JSON.stringify( object[k], null, 1) : '[object]' }</TableCell>)}
                </TableRow>)}
            </TableBody>
    </Table>

}