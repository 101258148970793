import {
    Button, CircularProgress, Dialog, DialogContent, DialogTitle
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { put } from '../../../data/aws/s3/UserBlobs';

const debug = process.env.REACT_APP_DEBUG && false;

export type UploadIconProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    objectCount: number,
    setObjectCount: React.Dispatch<React.SetStateAction<number>>;
    // mode: AreaManagementMode,
}
export default function UploadIcon({ open, setOpen, objectCount, setObjectCount }: UploadIconProps) {
    const Input = styled('input')({
        display: 'none',
    });

    const handleClose = () => {
        setOpen(false);
    };

    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        debug && console.log(e.target.files);

        const fileList = e.target.files;

        const directoryString = `icons/`

        if (fileList) {
            for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
                const file = fileList[i];
                const progressCallback = (progressEvent: ProgressEvent) => {

                    const { loaded, total } = progressEvent;
                    setUploadProgress(loaded / total * 100);
                    if (loaded === total) {
                        setTimeout(() => setUploadProgress(0), 1000)
                    }
                }
                const uploadRes = await put(directoryString, file, progressCallback)
                debug && console.log('uploadRes', uploadRes);
                setObjectCount(objectCount + 1)
                //   if (typeof parentHooks?.addKey === 'function' ) {
                //     parentHooks.addKey(uploadRes)
                //   }
            }
        }
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{'Upload Icons'}</DialogTitle>
            <DialogContent>
                <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)} />
                    <Button variant="contained" component="span">
                        Upload
                    </Button>
                    {uploadProgress > 0 && <CircularProgress variant="determinate" value={uploadProgress} />}
                </label>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogContent>
        </Dialog>
    )
}