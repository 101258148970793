import { NodeEdgeMapper, ValidBusinessObject } from '@iotv/datamodel-core';
import { ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import { ExtendedTreeItem } from '../../../../generic/ObjectTree/Derivations/ExtendedTreeItem2';

const debug = process.env.REACT_APP_DEBUG && false;

/**
 * Generates a   sub-tree where the root of the tree is the community provided
 * @param node  the community to create a sub tree from
 * @returns A nested TreeItem JSX object for tree structure of communities from root community
 */
// add nodeEdgeMapper in input
const generateSubTree = (node: ValidBusinessObject, nodeEdgeMapper: NodeEdgeMapper) => {
    if (nodeEdgeMapper) {
        //Getting list of children from given community
        const childNodes = nodeEdgeMapper.getChildNodes(node)
        //debug && console.log(`generateSubTree wih ${node.name} got children ${childNodes.map((c) => (c as ValidBusinessObject).name)}`)
        //If the community has grandchildren, generate another subtree. Otherwise generate simple node
        return (childNodes.map((nodeIn) => {
            const node = nodeIn as ValidBusinessObject
            // const communityControls: JSX.Element = getCommunityControls(node)
            return (
                <ExtendedTreeItem {...{ key: node.sk, nodeId: node.sk, label: node.name, x: <></> }}>
                    {generateSubTree(node, nodeEdgeMapper)}
                </ExtendedTreeItem>
            )
        })
        )
    } else {
        debug && console.log('nodeEdgeMapper not yet initialized')
    }

}

// const getCommunityControls = (node: ValidBusinessObject) => {
//     return <Grid container  {...{ spacing: 1, wrap: 'nowrap' }} >
//         <Grid item {...{}}>
//             <Tooltip {...{ title: `Edit ${lscToC021AccountType(node.accountType)}` }}>
//                 <IconButton {...{ size: 'small', onClick: () => history.push(`${NavSections.THINGVIEW}/${node.type}/${node.id}`) }}><Edit></Edit></IconButton>
//             </Tooltip>
//             <Tooltip {...{ title: `Manage ${lscToC021AccountType(node.accountType)} entities, users, devices` }}>
//                 <IconButton {...{ size: 'small', onClick: () => history.push(`${NavSections.CUSTOMER_MANAGEMENT_VIEW}/UPDATE/${node.type}/${node.sk}`) }}><Business></Business></IconButton>
//             </Tooltip>
//         </Grid>
//     </Grid>
// }

/**
 * Generates Treeview for showing the communities in tree-like structure
 * @returns A nested TreeItem JSX object for tree structure of communities
 */
export const generateTree = (apexNodes: ValidBusinessObjectList, nodeEdgeMapper: NodeEdgeMapper) => {
    //Getting a list of all the 'super communities' i.e. communities with no parents     
    //Generating a root node in treeview for each super community
    const treeElement = (apexNodes.map(apexNodeIn => {
        const apexNode = { ...apexNodeIn, pk: apexNodeIn.sk } as ValidBusinessObject
        // const communityControls: JSX.Element = getCommunityControls(apexNode)
        return <TreeView {...{
            defaultCollapseIcon: <  ExpandMoreIcon />,
            defaultExpandIcon: < ChevronRightIcon />,
        }}>
            <ExtendedTreeItem {...{ key: apexNode.sk, nodeId: apexNode.sk, label: apexNode.name, x: <>{'no child component set yet'}</> }}>
                {generateSubTree(apexNode, nodeEdgeMapper)}
            </ExtendedTreeItem>
        </TreeView>


    }
    ))

    debug && console.log('treeElement', treeElement)
    return treeElement
}