import { BusinessObjectAssembler, Customer, CustomerProps, DatastoreObjectType, DeviceProps, DeviceProvisioningRecordType, DeviceProvisioningState, ErrData, getObjectHash, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AdjacentType, AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, Radio, TableCell, Typography, withStyles } from '@material-ui/core';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { useDocumentClientQuery } from '../../../../hooks/useDocumentClientQueryV1';
import { AssignDeviceToCustomerRequest, CustomerAllocationManagementProps, ListDevicesRequest, ListerlizerProps, ListerlizerRowControlFnProps, MessageOutputType, UnassignDeviceFromCustomerRequest, UserTransactionType, UserView, ViewDefinition, ViewKeyDefinitionType, ViewObject } from '../../../../types/AppTypes';
import { DocumentClientListerlizerQueryType, DocumentClientListerlizerWrapperProps } from '../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../factories/DocumentClientListerlizerWrapper';
import { NavSections } from '../../../navigation/NavSections';
import { v4 as uuidv4 } from 'uuid/';
import { useEuiContainsFilter } from './hooks/useEuiContainsFilter';


const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';
const componentClassName = 'CustomerManageAllocationTab'

const CustomerManageDeviceAllocationTab: React.FC<CustomerAllocationManagementProps> = ({ user: contextUser, parentCustomer, contextCustomer, transactionFunctions, userFunctions, history, refreshParent, relatedViewRefreshCount }) => {
  debug && console.log('CustomerManageAllocationTab got', { contextUser, parentCustomer, contextCustomer })

  const routerHistory = useHistory();
  const parentCustomerInstance = parentCustomer && new Customer(parentCustomer as CustomerProps);
  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const listDevicesQuery = async (fn: (params: any) => void, listDeviceRequest: Partial<ListDevicesRequest>) => {
    const { contextUser, contextCustomer } = listDeviceRequest
    const urlPart = parentCustomerInstance?.canHaveDeviceInventory() ? 'listInventoryDevices' : 'listAllocatedDevices'
    if (contextUser && contextCustomer) {
      try {
        debug && console.log('CustomerManageAllocationTab listDeviceRequest using request', { listDeviceRequest, urlPart })
        const response = await ApiGateway.post(`/system/Device/${urlPart}`, listDeviceRequest, apiRef);
        debug && console.log('CustomerManageAllocationTab listDeviceRequest query results', response)
        if (response.data?.Items) {
          if (!parentCustomerInstance?.canHaveDeviceInventory()) {
            response.data.Items = response.data.Items.filter((device: DocumentClient.AttributeMap) => device.provisioning instanceof Array && device.provisioning[0]?.allocationCustomer?.sk === listDeviceRequest.contextCustomer?.sk);
          }
        }
        fn(response)
      } catch (e) {
        debug && console.log('Error in listDeviceRequest query', e)
      }
    } else {
      debug && console.log('CustomerManageAllocationTab skipped  query on no context user or customer')
    }

  }

  const [availableDeviceEuis, setAvailableDeviceEuis] = React.useState<string[]>([])


  const getQueryParams = (euiContainsFilter: string | undefined = undefined): ListDevicesRequest | undefined => {
    let queryParams: ListDevicesRequest | undefined = undefined

    if (contextUser && parentCustomerInstance) {
      queryParams = {
        ExclusiveStartKey: undefined,
        contextCustomer: parentCustomerInstance as { type: 'Customer' } & ValidBusinessObject,
        contextUser: contextUser as ValidBusinessObject & { type: 'User' },
        filter: parentCustomerInstance.canHaveDeviceInventory() ? DeviceProvisioningState.INVENTORY : DeviceProvisioningState.ALLOCATED,
        euiContainsFilter
      }
    }

    return queryParams


  }


  const contextCustomerSk = contextCustomer?.sk
  const parentCustomerSk = parentCustomer?.sk

  const {
    state: [euiContainsFilter],
    component: EuiContainsFilter
  } = useEuiContainsFilter({ availableDeviceEuis })

  const queryParams = getQueryParams(euiContainsFilter)
  const queryHook = useDocumentClientQuery(listDevicesQuery, queryParams, [parentCustomerSk, contextCustomerSk, relatedViewRefreshCount, euiContainsFilter])

  const [
    [items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]
  ] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects, getSelector] = queryHook.selectionControls;
  const getLastAllocationCustomerSk = (device: ValidBusinessObject) => device.provisioning?.[0]?.allocationCustomer?.sk

  const getLastInventoryCustomerSk = (device: ValidBusinessObject) => device.provisioning?.[0]?.inventoryCustomer?.sk
  const lastAllocationCustomerValueGetter = (provisioning: DeviceProvisioningRecordType[]) => (provisioning?.[0]?.allocationCustomer as ValidBusinessObject)?.name

  const itemsHash = getObjectHash(items)
  useEffect(() => {
    setAvailableDeviceEuis(items.map((item) => item.id))
  }, [itemsHash])
  const forceUpdate = queryHook.forceUpdate

  type DeviceAttributeKeys = keyof DeviceProps
  const deviceListViewDefs: ViewDefinition & Omit<{ [key in DeviceAttributeKeys & 'name']: ViewKeyDefinitionType }, 'nothing'> = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    eui: { key: 'eui', label: 'EUI', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    customer: { key: 'provisioning', 'label': 'Allocated To', type: AppValueTypes.STRING, editable: false, valueGetter: lastAllocationCustomerValueGetter },

    billingGroup: { key: 'billingGroup', label: 'Billing Group', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    state: { key: 'state', label: 'Device Status', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  }

  const allocateDevice = async (device: ValidBusinessObject) => {
    if (contextUser?.type === 'User') {
      try {
        debug && console.log('getCustomerQuery', contextUser)
        const request: AssignDeviceToCustomerRequest = {
          contextUser: contextUser as ValidBusinessObject & { type: 'User' },
          contextCustomer: parentCustomer as ValidBusinessObject & { type: 'Customer' },
          assignmentCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
          device: device as ValidBusinessObject & { type: 'Device' },
        }
        const response = await ApiGateway.post(`/system/Device/assignDeviceToCustoemr`, request, apiRef);
        debug && console.log('allocateDevice results', response)
        if (response.data) {
          debug && console.log('row component will try and get re-rendereed list???', { refreshParent })
          forceUpdate()
          //setLastAllocatedDeviceSk(device.sk)
          refreshParent && refreshParent()
          //routerHistory.push(`${routerHistory.location.pathname}?tabName=Devices`)
        } else if (response.err) {
          displayMessage(response.err.message, 'MessageBar')
        } else {
          throw new Error(`Too many customers receied: ${JSON.stringify(response, null, 1)}`)
        }
      } catch (e) {
        debug && console.log('Error in CM query', e)
      }
    } else {
      debug && console.log('No context user')
    }
  }

  const allocateMultipleDevices = async () => {
    const promises = selectedObjects.map((device) => allocateDevice(device as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('Allocate multople res', res);
    setSelectedObjects([]);
    forceUpdate()
  }


  const viewObject: ViewObject = {
    matchedPrimary: contextCustomer,
    matchedRelatedByPk: [],
    matchedRelatedBySk: []
  }

  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<ListDevicesRequest> = queryParams ? {
    viewObject, viewDefinition: deviceListViewDefs,
    transactionFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Device',
      edgeFilter: undefined
    },
    maxItems: 100,
    sortKeyIn: 'name',
    contextObject: undefined,
    queryHook, queryParams,
    queryControls: () => [
      <ButtonGroup>
        {selectedObjects.length > 0 &&
          <Button
            size='small'
            onClick={() => allocateMultipleDevices()}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
          >
            Allocate Selected
          </Button>
        }

      </ButtonGroup>,
      EuiContainsFilter,
    ],
    rowControlOverrideFns: {
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell key={`${listRowProps.rowObject.sk}_goto`} {...{
              onClick: () => {
                const viewUrl = queryString.stringifyUrl({
                  url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Device/${listRowProps.rowObject.sk}`,
                  query: {
                    contextObjectSK: parentCustomer?.sk
                  }
                });
                routerHistory.push(viewUrl)
              }
            }}>
              <IconButton aria-label="delete" color="primary">
                <PlayArrowIcon {...{

                }} />
              </IconButton>
            </TableCell>

          ]
        }
      ],
      last: [(listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
        return [
          <TableCell key={`${listRowProps.rowObject.sk}_allocate`}  {...{}}><Button {...{ variant: "contained", color: "secondary", onClick: () => allocateDevice(listRowProps.rowObject) }} >Allocate</Button></TableCell>
        ]
      }]
    }
  } : undefined

  //      <div>        {JSON.stringify({ parentCustomer: parentCustomer?.name, contextCustomer: contextCustomer?.name })}</div>
  return (
    <>
      <Grid className={componentClassName} container spacing={3} >
        {<Grid item xs={12} lg={12}>
          <Card>
            <CardContent>

              <Typography variant="h4" component="h2" gutterBottom>
                {'Device Allocation'}
              </Typography>
              {documentClientListerlizerProps &&
                <DocumentClientListerlizerWrapper key={'deviceList2'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>
              }
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
    </>
  );
}

export default withStyles(styles)(CustomerManageDeviceAllocationTab)