import { DocumentClient } from "aws-sdk/clients/dynamodb";
import ApiGateway from "../../data/aws/api-gateway/ApiGateway";
import config from '@iotv/config'
import { ErrData, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { ListDevicesRequest, DevicePoolType } from "../../types/AppTypes";


const debug = process.env.REACT_APP_DEBUG && false;


const dyn = config.aws.dynamoDB

export   const listDevices = async (
    fn: (params: any) => void,
    contextRequest: ListDevicesRequest
  ) => {
    console.log("listDevices", contextRequest);
    //TODO: Move devicePoolType into parameter
    const devicePoolType: DevicePoolType = DevicePoolType.INVENTORY;

    const apiRef = "SystemManagement";
    const urlPart =
      devicePoolType === DevicePoolType.INVENTORY
        ? "listInventoryDevices"
        : devicePoolType === DevicePoolType.ALLOCATION
        ? "listAllocatedDevices"
        : null;
    if (urlPart) {
      try {
        const response = await ApiGateway.post(
          `/system/Device/${urlPart}`,
          contextRequest,
          apiRef
        );
        debug && console.log("Diagnostic View device query results", response);
        fn(response);
      } catch (e) {
        debug && console.log("Error in UM query", e);
      }
    }
  };

export const getDeviceTransactionQueryParams = (deviceSk: string ) => {
  const params: DocumentClient.QueryInput = {
        TableName: dyn.tableName,
        IndexName: dyn.tsiName,
        ExclusiveStartKey: undefined,
        ExpressionAttributeNames: {
            '#pk': dyn.pKey,
            '#sk': dyn.sKey
        },
        ExpressionAttributeValues: {
            ':pk': deviceSk,
            ':type': 'DeviceControlTransaction'
        },
        ScanIndexForward: false,
        Limit: 20,
        KeyConditionExpression: '#pk = :pk',
        FilterExpression: `begins_with( #sk, :type)`
    }
    return params;
}

export const getNetworkQueryResults = async ( setterFn: ( results: ErrData<DocumentClient.QueryOutput>) => void, params: DocumentClient.QueryInput ) => {
    const path = '/baseQuery';
    const apiRef = config.app.appName;
    const res: ErrData<DocumentClient.QueryOutput> = await ApiGateway.post( path,  params, apiRef )
    setterFn(res)
    return res;

}


export   const putItem = async (deviceControlTransaction: ValidBusinessObjectList) => {
    try {
      const response: ErrData<DocumentClient.QueryOutput> =
        await ApiGateway.post(
          "/putItems/",
          deviceControlTransaction,
          config.app.appName
        );

        // if (response.data?.Items) {
        //   console.log("insert successfully");
          
        // }

        return response;
        
      // debug && console.log("results", response);
    } catch (e) {
      debug && console.log("Error in sending device request", e);
    }
  };


