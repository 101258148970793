import { CustomerAccountType, DatastoreObjectType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AddressType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, FormControlLabel, Grid, Switch, TableCell, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/DeleteSweepRounded'
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { AdjacentType, CustomerContextTabProps, ListDatabaseUsersRequest, ListerlizerProps, ListerlizerRowControlFnProps, MessageOutputType, SearchParamsType, UserTransactionType, ViewDefinition, ViewObject } from '../../../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../factories/DocumentClientListerlizerWrapper';
import { useDocumentClientQuery } from '../../../../hooks/useDocumentClientQueryV1';
import { NavSections } from '../../../navigation/NavSections';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';


function CustomerUserListTab(props: CustomerContextTabProps): JSX.Element {
  debug && console.log('Customer User List tab got', props)
  const contextCustomer: ValidBusinessObject | undefined = props.contextCustomer;
  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const [showAdminOnly, setShowAdminOnly] = React.useState(false);
  const routerHistory = useHistory();

  const listUsers = async (fn: (params: any) => void, contextRequest: ListDatabaseUsersRequest) => {
    try {
      const response = await ApiGateway.post('/system/User/list', contextRequest, apiRef);
      debug && console.log('CustomerUserListTab query results', { contextRequest, response })
      fn(response);
    } catch (e) {
      debug && console.log('Error in UM query', e)
    }
  }


  const removeFromUserPool = async (user: ValidBusinessObject | undefined) => {
    if (user) {
      const response = await ApiGateway.post('/system/User/delete', user, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        displayMessage(`Removed ${user?.name} from userpool`, 'SnackBar')
      }
    }
  }

  const deleteMultipleUsers= async () => {
    const promises = selectedObjects.map((user) => removeFromUserPool(user as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('Delete multople res', res);
    setSelectedObjects([]);
    forceUpdate()
  }

  const queryParams: ListDatabaseUsersRequest = {
    ExclusiveStartKey: undefined,
    contextUser: props.user as ValidBusinessObject & { type: 'User' },
    contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
    listAdminsOnly: showAdminOnly
  }
  const contextCustomerSk = contextCustomer?.sk

  const queryHook = useDocumentClientQuery(listUsers, queryParams, [showAdminOnly, contextCustomerSk])

  const [[items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects] = queryHook.selectionControls;
  const forceUpdate = queryHook.forceUpdate

  debug && console.log('CustomerUserListTab items count', items.length)
  const [inventoryDevicesLastEvaluatedKey, setDevicesLastEvaluatedKey] = React.useState<DocumentClient.Key | undefined>(undefined)
  const [queryLimit, setQueryLimit] = React.useState<number | undefined>(undefined)

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }


  const viewObject: ViewObject = {
    matchedPrimary: contextCustomer,
    matchedRelatedByPk: [],
    matchedRelatedBySk: []
  }

  type CustomerAttributeKeys = keyof AddressType
  const userTableViewDefinition: ViewDefinition & Omit<{ [key in CustomerAttributeKeys]: any }, 'dwelling_number'> = {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    street_number: { key: 'street_number', label: 'Street Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    suburb: { key: 'suburb', label: 'Suburb', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    city: { key: 'city', label: 'City', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    country: { key: 'country', label: 'Country', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    post_code: { key: 'post_code', label: 'Postcode', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    email: { key: 'email', label: 'Email', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    phoneNumber: {
      key: 'phoneNumber', label: 'Phone Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined
      , validationRx: action === UserTransactionType.CREATE ? /\+\d+$/ : undefined
      , failsValidationText: 'phone format +021...'
    },
    accountType: { key: 'accountType', label: 'Account Type', type: AppValueTypes.ENUM, enumKV: CustomerAccountType, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },

  }

  const userListViewDefs = {
    name: userTableViewDefinition.name,
    email: userTableViewDefinition.email,
    city: userTableViewDefinition.city
  }

  const userListProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'User',
    },
    autoQuery: false,
    maxItems: 50,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: userListViewDefs,
    viewObject,
    contextObject: undefined,
  }

  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<ListDatabaseUsersRequest> = queryParams ? {
    ...userListProps, queryHook, queryParams,
    queryControls: () => [
        <ButtonGroup>
          {selectedObjects.length > 0 &&
            <Button
              size='small'
              onClick={() => deleteMultipleUsers()}
              startIcon={<DeleteIcon />}
              variant="outlined"
              color="primary"
            >
              Delete Selected
                   </Button>
          }
          <Button
            size='small'
            onClick={() => routerHistory.push(createUrl)}
            disabled={action === UserTransactionType.CREATE}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
          >
            User
                  </Button>


        </ButtonGroup>
      ],
    rowControlOverrideFns: {
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell {...{
              key: `${listRowProps.rowObject.sk}_goto`, onClick: () => {
                const viewUrl = queryString.stringifyUrl({
                  url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/User/${listRowProps.rowObject.sk}`,
                  query: {
                    contextObjectSK: contextCustomer.sk
                  }
                });
                routerHistory.push(viewUrl)
              }
            }}>
              <PlayArrowIcon {...{
              }} />
            </TableCell>

          ]
        }
      ]
    }
  } : undefined;

  const createUrl = queryString.stringifyUrl(
    {
      url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}/User/`,
      query: {
        objectTypeId: 'User',
        contextObjectSK: contextCustomer.sk,
        accountType: props.accountType,
      }
    }
  )

  return (
    <Grid className='customerManagementViewGrid' container spacing={3} >
      <Grid item xs={12} >
        <Card>
          <CardContent>
            <Typography variant="h4" component="h4" gutterBottom>
              {props.title ?? 'Users'}
            </Typography>
            <FormControlLabel {...{
              label: showAdminOnly ? 'Admins only' : 'All users',
              control: <Switch {...{ onChange: () => setShowAdminOnly(!showAdminOnly), value: showAdminOnly }}></Switch>
            }}
            ></FormControlLabel>
            {documentClientListerlizerProps &&
              <DocumentClientListerlizerWrapper key={'customerUserDocClientList'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

}
export default withStyles(styles)(CustomerUserListTab)