import { Payload, prettifyHex } from '@iotv/datamodel';
import { CardContent, Typography } from "@material-ui/core";
import { } from "@material-ui/icons";
import { Info } from '@mui/icons-material';
import { Card } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";


const debug = process.env.REACT_APP_DEBUG && false;

type RenderPayloadCellType = (params: GridRenderCellParams<any, any, any>) => React.ReactNode


export const PayloadDataGripCell: RenderPayloadCellType = ( p ) => {

    return typeof p.value === 'string' ?  <>
    <Typography { ...{ style: { alignItems: 'center', display: 'flex', flexDirection: 'row'}}}>
        <Info { ...{  color: 'info', style: { marginRight: 14}}}/>
        {prettifyHex(p.value)}

    </Typography>
    
</> : undefined}


type DecodedPayloadPopUpProps = {  payloadStr: string }

export const DecodedPayloadPopUp = ( {payloadStr}: DecodedPayloadPopUpProps) => {
    const unpacked = Payload.unpackPayload( payloadStr )

    const wrappedElement = <Card>
        <CardContent>
            { JSON.stringify( unpacked, null, 1)}
        </CardContent>
    </Card>

    return wrappedElement
}