import { Assembler, IncidentType, INotificationSubscription, NotificationSubscription, QueryTypes, SubscribedStatus, SubscribedStatusUnion, ValidModelObject } from "@iotv/datamodel"
import { AdjacentType, ValidBusinessObject } from "@iotv/iotv-v3-types"
import { Button, FormControl, FormGroup, Grid } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect } from "react"
import { NavSections } from "../../../components/navigation/NavSections"
import config from '../../../config'
import { useStyles } from "../../../cosmetics/InputStyles"
import ApiGateway from "../../../data/aws/api-gateway/ApiGateway"
import { useContextObjectSwitch, UseContextObjectSwitchReturnType } from "../../../hooks/useContextObjectSwitch"
import { useDocumentClientQuery } from "../../../hooks/useDocumentClientQueryV1"
import { DeviceManagementViewProps, ListLimitedAllTypeRequestBody } from "../../../types/AppTypes"
import { SubscriptionLineItem } from "../SubscriptionLineItem"
const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = config.app.appName


export const ConfigurationTab = ({ contextCustomer, contextUser, classes, userFunctions, ...others }: DeviceManagementViewProps) => {

    classes = useStyles()

    const {
        components: { contextObjectSelector, modeRadioButtons, },
        state: [contextObject, setContextObject]
    }: UseContextObjectSwitchReturnType = useContextObjectSwitch( { contextCustomer, user: contextUser, classes, navSection: NavSections.NOTIFICATION_MANANGEMENT_VIEW})


    const queryParams: ListLimitedAllTypeRequestBody = {
        ExclusiveStartKey: undefined,
        limit: 50,
        contextObject: contextUser,
        excludeRelatedToObject: undefined,
        includeEdges: false,
        includeNodes: true,
        adjacencyType: AdjacentType.CHILD,
        objectTypeId: 'NotificationSubscription',
        queryType: QueryTypes.LIST_LIMITED_ALL_TYPES
    };

    const query = async (fn: (params: any) => void, contextRequest: ListLimitedAllTypeRequestBody) => {
        debug && console.log('ConfigurationTab query request', contextRequest)
        try {
            const response = await ApiGateway.post(`/query/`, contextRequest, apiRef);
            if (response.data?.Items) {
                response.data.Items = response.data.Items.map((zombie: ValidBusinessObject) => Assembler.getInstance(zombie))
            }
            debug && console.log('DataTableView query results', response)
            fn(response);
        } catch (e) {
            debug && console.log('Error in UM query', e)
        }
    }

    const queryHook = useDocumentClientQuery(query, queryParams, [contextUser])
    const [
        [items, setItems],
        [limit, setLimit],
        [LastEvaluatedKeys, setLastEvaluatedKeys],
        [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

    const forceUpdate = queryHook.forceUpdate
    const updates = queryHook.updates

    useEffect( () => {
        debug && console.log('Configuration Tab used Effect on updates', updates)
    }, [ updates ])

    const handleAdd = () => {
        const newItem: Partial<INotificationSubscription<SubscribedStatusUnion>>& { type: string } = {
          deliveryPreferences: [],
          filter: {
            severities: [],
            statuses: [],
            onActions: [],
          },
          incidentEntityOrGroupingSk: undefined,
          incidentInitiatorSk: undefined,
          incidentProducingTypeId: undefined,
          incidentType: IncidentType.DEVICE_AWOL,
          incidentSubtype: undefined,
          subscriptionState: {
              status: SubscribedStatus.ACTIVE,
              reapplyAfterEvent: undefined,
              reapplyAfterDateMs: undefined
          },
          type: 'NotificationSubscription'

        }
        setItems([...items, newItem])
    }


    const subs = items.map((item) => Assembler.getInstance(item as ValidModelObject<'NotificationSubscription'>)).filter((item) => item) as  ( ValidModelObject<'NotificationSubscription'> & NotificationSubscription ) []

    return <Grid key = {'ConfigurationTabMainGrid'} container {...{  }}>
        { contextCustomer && contextUser && <>
            {subs.map((sub, i) => sub && <SubscriptionLineItem {...{ key: `SubscriptionLineItem_${sub.incidentProducingTypeId}_${sub.sk}`, contextObject, contextCustomer, contextUser, sub, forceUpdate, userFunctions }}></SubscriptionLineItem>)}

        </>
        }
        

            <Grid item {...{ xs: 3 } } >
                <FormGroup>
                    <FormControl>
                    <Grid item><Button {...{ size: 'small', startIcon: <AddIcon />, variant: 'outlined', color: 'primary', onClick: handleAdd }}>Add Subscription</Button></Grid>
                    </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item {...{ xs: 9 } }>
                    <FormGroup { ...{ row: true }}>
                    <FormControl>
                      { modeRadioButtons }
                    </FormControl>
                    <FormControl>
                      { contextObjectSelector }
                    </FormControl>
                </FormGroup>
            </Grid>



    </Grid>
}