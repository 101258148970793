import { getObjectHash, HistoryStore, ObjectHistory, ObservationHistory, toDatePickerFormat, toDatePickerFormat2, ValidModelType } from '@iotv/datamodel';
import { AppValueTypes, googleSheetRef, MapLike, SortOrder, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Button, Grid, IconButton, MenuItem, Slider, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { type } from 'os';
import React, { useEffect, useRef } from 'react';
import Toggle from 'react-toggle';
import { InputFieldArbitrator } from '../../factories/inputFields/InputFieldArbitrator';
import { useObjectMemo } from '../../..//hooks/useObjectMemo';
import { SimpleIOToggleWrapper } from '../../../components/io/simpleIOToggleWrapper';
import styles from '../../../cosmetics/sharedCardStyles';
import { ducktype, getNiceDate, getPseudoVBO } from '../../../data/TypeHelpers';
import { useObservations } from '../../../hooks/useObservations';
import { AdjacentType, ComponentMode, ListerlizerProps, ValidCustomerObject, ViewDefinition, ViewKeyDefinitionType, ViewObject } from '../../../types/AppTypes';
import { gapiIsPresent } from '../../../util/Scripts/isPresent';
import { DownloadButton } from '../../common/ViewObjectDownloadButton';
import { Listerlizer } from '../../factories/Listilizer';
import { IOType } from '../../io/simpleIO';
import { TableRowizerTableCell } from '../../factories/TableParts/TableRowizerTableCell';
import { metricItemsToVOBs } from './helperFns';
import { truncate } from 'fs';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';


const debug = process.env.REACT_APP_DEBUG && false;

const actualReadingPrefix = 'actual_';
const filter = createFilterOptions<string>();

const localStyle = {
    table: {},
    limitCardWidth: {
        scroll: true
    }
};
const useStyles = makeStyles((theme) => ({ ...localStyle, ...styles(theme) }));


type ObservationCardProps = Omit<ListerlizerProps, 'adjacentFilter' | 'selectorConfigParams' | 'sortKeyIn' | 'maxItems'> & {
    objectHistory: ObjectHistory | undefined
    title?: string,
    contextCustomer: ValidCustomerObject
    userSelectedGroup: string | undefined
}

const componentClassName = 'ObservationCard'

export default function ObservationCard(props: ObservationCardProps) {
    const classes = useStyles();
    const parentHasRendered = () => document.querySelector(`.${componentClassName}`) !== null;

    const { viewObject: viewObjectIn, objectHistory, contextCustomer, userSelectedGroup, transactionFunctions, userFunctions, history, title } = props

    //const initialKeys = objectHistory?.recentStateHistory ? new HistoryStore(objectHistory.recentStateHistory).keys() : undefined
    const initialKeys = undefined


    debug && console.log('ObservationCard', props)

    const {
        state: [observationHistory, setObservationHistory], errMessage, working,
        functions: { addObservation, save, deleteObject }
    } = useObservations(viewObjectIn?.matchedPrimary, initialKeys)

    const [combined, setCombined] = React.useState<boolean>(false)
    const [matchedActualsCount, setMatchedActualsCount] = React.useState<number | undefined>(undefined)

    const observationHistoryInstance = observationHistory && new ObservationHistory(observationHistory)

    const historyStore = observationHistoryInstance?.observedHistory
    debug && console.log('historyStore', historyStore)
    const historyStoreLastTimestamp = historyStore?.lastTimestamp();


    const historyStoreLength = historyStore?.getLength();
    const historyStoreKeysHash = historyStore?.keys().join('');

    /* EXPERIMENTAL, get the spreadsheet and sheet names from useGoogleIO */
    const fileSelectRef: React.MutableRefObject<googleSheetRef | undefined> = useRef<googleSheetRef | undefined>(observationHistory?.googleSheet)
    const fileSelectRefHash = getObjectHash(fileSelectRef?.current)
    // useEffect(() => {
    //     debug && console.log('re rendering on changed fileSelectRefHash')
    // }, [fileSelectRefHash])

    const saveCurried = () => {
        debug && console.log('Save curried has fileselectref', fileSelectRef)
        const observationHistoryClone = { ...observationHistory }
        observationHistoryClone.googleSheet = fileSelectRef.current
        const newObservationHistory = new ObservationHistory(observationHistoryClone)
        setObservationHistory(newObservationHistory);
        save(newObservationHistory);
    }

    const getEmptyObject = () => Object.fromEntries([...(historyStore?.keys() ?? []).map((k) => [k, '']), ['timestamp', Date.now()]])

    //debug && console.log('historyStoreLastTimestamp', historyStoreLastTimestamp)

    const {
        state: [stateObject, setStateObject],
        hash: tableObHash,
        updateKV,
    } = useObjectMemo(getEmptyObject())

    useEffect(() => {
        debug && console.log('re rendering on changed matchedPrimary sk')
    }, [observationHistoryInstance?.sk, objectHistory])

    useEffect(() => {
        debug && console.log('re rendering on changed history store, googleSheet', observationHistory?.googleSheet)
        fileSelectRef.current = observationHistory?.googleSheet
    }, [historyStoreLastTimestamp, historyStoreLength, historyStoreKeysHash, tableObHash])

    const observatinStateSnapshots = historyStore ? historyStore.getStateSnapshotArray(SortOrder.DESCENDING) : [];
    const pseudoTypeId = `${observationHistoryInstance?.type ?? 'Unkown'}_observation`;

    const observationMetricItems = metricItemsToVOBs({
        psuedoParentVob: observationHistory, pseudoTypeId, stateSnapshots: observatinStateSnapshots
    })

    const simpleIOProcessPlainObjects: {
        objectTypeId: string
    } = { objectTypeId: pseudoTypeId }

    const viewObject: ViewObject = {
        matchedPrimary: observationHistoryInstance, matchedRelatedByPk: [], matchedRelatedBySk: observationMetricItems,
    }

    const filterKeysToViewDefinition = (filterMetricKeys: string[] = []) => {
        const viewDefinition: ViewDefinition = {};
        filterMetricKeys.unshift('timestamp')
        filterMetricKeys.forEach((key) => {
            const viewDefinitionKey: ViewKeyDefinitionType = {
                key,
                editable: false,
                label: key.toUpperCase(),
                precision: undefined,
                stateFn: undefined,
                type: AppValueTypes.NUMBER,
                unit: undefined,
                validationRx: undefined,
                enumKV: undefined,
                failsValidationText: undefined
            };
            viewDefinition[key] = viewDefinitionKey
        })
        return viewDefinition;
    }

    const filterKeysByTypeToViewDefinition = (filterMetricKeys: string[] | undefined, arrayMap: { [k: string]: Array<any> } | undefined) => {
        const keys = arrayMap ? filterMetricKeys?.filter((key) => arrayMap[key] && arrayMap[key].every((item) => item === null || typeof item !== 'object')) : []

        return filterKeysToViewDefinition(keys)
    }

    const handleDeleteObservation = (key: string) => {
        const idx = observationMetricItems.findIndex((item) => item.sk === key)
        debug && console.log('Want to delete ', idx)

        if (historyStore) {
            const splicedHistoryStore = historyStore.splice(idx, 1)
            setObservationHistory(new ObservationHistory({ ...observationHistory, observedHistory: splicedHistoryStore }))
        }
    }

    const viewDefinition = filterKeysByTypeToViewDefinition(historyStore?.keys(), historyStore);
    const adjacentFilter = {
        adjacentType: AdjacentType.CHILD,
        objectType: pseudoTypeId as ValidModelType
    };
    const pk = observationHistoryInstance?.sk ?? 'unknownParentSk';
    const downloadButton = <DownloadButton {...{ key: `${pk}_observationHistoryCard_DownloadButton`, viewObject, viewDefinition, adjacentFilter }}></DownloadButton>
    const saveButton = <IconButton {...{ key: `${pk}_observationHistoryCard_SaveButton`, onClick: saveCurried, disabled: working }}><SaveIcon /></IconButton>

    const listerlizerProps: ListerlizerProps = {
        adjacentFilter,
        transactionFunctions,
        userFunctions,
        history,
        viewObject,
        contextObject: undefined,
        maxItems: 20,
        sortKeyIn: 'name',
        selectorConfigParams: {
            enabled: false,
            multipleSelect: false,
            controls: { add: false, find: false }
        },
        viewDefinition,
        functionOverrides: {
            hasValidationErrors: () => { },
            selectItem: handleDeleteObservation
        },
        injectedComponents: [saveButton, downloadButton]
    }

    const historyStoreKeysPlusTs = historyStore ? ['timestamp', ...historyStore.keys()] : []


    const handleAddKey = (keyName: string) => {

        if (keyName && historyStore) {
            const historyStoreClone = new HistoryStore({ ...historyStore })
            historyStoreClone.addKey(keyName)
            setObservationHistory(new ObservationHistory({ ...observationHistory, observedHistory: historyStoreClone }))
        } else {
            console.log(`No keyName or historyStore `)
        }
    }

    const keyRemoveRef = useRef<HTMLInputElement | null>(null)
    const handleRemoveKey = ( keyName: string) => {
        if (keyName && historyStore) {
            const historyStoreClone = new HistoryStore({ ...historyStore })
            historyStoreClone.removeKey(keyName)
            const observationHistoryClone = new ObservationHistory({ ...observationHistory, observedHistory: historyStoreClone })
            setObservationHistory(observationHistoryClone)
            debug && console.log(`removed ${keyName}`, observationHistoryClone)
        }
    }





    const handleAddObservation = () => {
        const { timestamp, ..._stateObject } = stateObject as MapLike<any> & { timestamp?: number };

        addObservation({
            timestamp: timestamp ?? Date.now(), state: _stateObject
        })

        setStateObject(getEmptyObject())
    }

    // useEffect( () => {
    //     debug && console.log('used effect on store keys', storeKeys )
    // }, [ storeKeys ])



    const handleCombinedOutputChange = () => {

        setCombined(!combined)

    }

    const controls = <Table  {...{ key: 'controlTable' }}>
        <TableBody>

            <TableRow {...{ key: 'keyControls' }}>
                <TableCell {...{ key: 'addControlCell' }}>

                    <Autocomplete {...{
                        selectOnFocus: true,
                        clearOnBlur: true,
                        handleHomeEndKeys: true,
                        freeSolo: true as unknown as undefined,
                        disablePortal: true,
                        id: 'knownKeysAutoComplete',
                        options: objectHistory?.recentStateHistory?.keys() ?? [],
                        sx: { width: 300 },
                        onChange: (e, v) => { console.log(`Autocomplete`, e, v); handleAddKey(v as string) },
                        size: 'small',
                        renderInput: (params) => <TextField {...{
                            variant: 'outlined', placeholder: 'new key',
                            onChange: () => {

                            },
                            ...params, label: 'Add key',
                            filterOptions: (options: string[], params: FilterOptionsState<string>) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push(inputValue)
                                }

                                return filtered;
                            }
                        }}
                        />
                    }}

                    />
                </TableCell>
                <TableCell  {...{ key: 'removeControlCell' }}>

                    <Autocomplete {...{
                        disablePortal: true,
                        id: 'deleteKeysAutoComplete',
                        options: historyStore?.keys() ?? [ 'fyuc'],
                        sx: { width: 300 },
                        onChange: (e, v) => { console.log(`Autocomplete`, e, v); handleRemoveKey(v as string) },
                        size: 'small',
                        renderInput: (params) => <TextField {...{
                            variant: 'outlined', placeholder: 'new key',
                            onChange: () => {

                            },
                            ...params, label: 'Remove key',
                            
                        }}
                        />
                    }}

                    />
                </TableCell>
                <TableCell>
                    <Toggle {...{
                        id: 'modeSwitch',
                        defaultChecked: combined,
                        icons: false,

                        onChange: handleCombinedOutputChange
                    }}>

                    </Toggle>
                    <label htmlFor='modeSwitch'>
                        <span>{!combined ? 'Export Observations only' : `Export Observations and ${matchedActualsCount !== undefined ? matchedActualsCount : ''} Actuals`}</span>
                    </label>
                </TableCell>
            </TableRow>

        </TableBody>

    </Table>

    const displayObject = { ...getPseudoVBO({ type: pseudoTypeId, id: '', name: '', ...stateObject }) }
    const stateObjectTable = <Table  {...{ key: 'stateObjectTable' }}>

        <TableBody>
            {stateObject && <TableRow {...{ key: 'stateObjectRow', style: { backgroundColor: 'orange' } }}>
                {historyStoreKeysPlusTs.map((key, rowNumber) => {
                    const viewKeyDefinition: ViewKeyDefinitionType = {
                        editable: true, key, type: ducktype(key, stateObject[key]), label: key,

                    }

                    return [
                        // <TableCell { ...{ key: 'tempKeyCell'}}>{stateObject[key]}</TableCell> ,
                        <InputFieldArbitrator {...{
                            keyToUse: key, tableOb: displayObject, mode: ComponentMode.EDIT, key
                            , viewKeyDefinition,
                            updateKV
                        }}></InputFieldArbitrator>
                    ]
                }
                )}
                <TableCell>
                    <IconButton edge="end" aria-label="delete" {...{ onClick: handleAddObservation }}>
                        <AddIcon />
                    </IconButton>
                </TableCell>
            </TableRow>}
        </TableBody>

    </Table>

    //debug && console.log('ObservationHistoryStateCard matchedBySk', listerlizerProps.viewObject.matchedRelatedBySk)

    const getExportVOBs = () => {
        const matchObserveredKeys = true
        let vobs: ValidBusinessObjectList = [];
        let keys: string[] = []
        if (!combined) {
            vobs = observationMetricItems
            keys = historyStore?.keys() ?? []
        } else if (objectHistory?.recentStateHistory && historyStore) {
            const historyStoreClone = historyStore.clone()
            const sortOrder = SortOrder.DESCENDING
            historyStoreClone.sort(sortOrder)
            const historyStoreCloneKeys = historyStoreClone.keys();
            debug && console.log(`historyStoreCloneKeys`, historyStoreCloneKeys)
            debug && console.log('clone is in order', historyStoreClone.validate(sortOrder));
            const recentStateHistory = new HistoryStore({ ...objectHistory.recentStateHistory })
            const [firstTs, lastTs] = [historyStoreClone.getFirstTimestamp(), historyStoreClone.getLastTimestamp()].sort()
            debug && console.log('first last ', { firstTs, lastTs })
            const max = Math.max(recentStateHistory.getIndexLastLessThanTimestamp(firstTs), 0)
            const min = Math.min(recentStateHistory.getIndexLastLessThanTimestamp(lastTs) + 1, recentStateHistory.getLength() - 1)
            const [start, end] = [min, max].sort()
            debug && console.log('trimming to ', { start, end })
            const trimmedRecentStateHistory = new HistoryStore(objectHistory.recentStateHistory).slice([start, end])
            trimmedRecentStateHistory.keys().forEach((k) => {
                // need to remove objects
                const stateArray = trimmedRecentStateHistory[k] as any[];
                const containsObject = stateArray.some((item: any) => typeof item === 'object' && item !== null)
                const keyMatchesObserved = historyStoreCloneKeys.includes(k) || !matchObserveredKeys
                debug && console.log(`export keys predicates for ${k}`, { containsObject, keyMatchesObserved, matchObserveredKeys })
                if (!containsObject && (keyMatchesObserved)) trimmedRecentStateHistory[`${actualReadingPrefix}${k}`] = trimmedRecentStateHistory[k]
                delete trimmedRecentStateHistory[k]
            })
            debug && console.log('trimmedRecentStateHistory', trimmedRecentStateHistory)
            const recentStateHistorySnapshots = trimmedRecentStateHistory.getStateSnapshotArray();
            if (matchedActualsCount !== recentStateHistorySnapshots.length) setMatchedActualsCount(recentStateHistorySnapshots.length);
            const observatinStateSnapshots = historyStoreClone.getStateSnapshotArray()

            const orderedSnapshots = [...observatinStateSnapshots, ...recentStateHistorySnapshots].sort((s1, s2) => s1.timestamp - s2.timestamp)
            keys = [...historyStoreCloneKeys, ...trimmedRecentStateHistory.keys()]
            vobs = metricItemsToVOBs({ psuedoParentVob: observationHistory, pseudoTypeId, stateSnapshots: orderedSnapshots })
            debug && console.log('vobs  count', vobs.length)
            debug && console.log('export keys', keys)
            debug && console.log('combined', combined)
        }

        return { keys, vobs };
    }

    const { keys, vobs: exportVobs } = getExportVOBs()
    debug && console.log(`fileSelectRef current `, fileSelectRef?.current)
    return (
        <Card >
            <CardHeader
                {...{ title: title ?? 'Observations' }}
            ></CardHeader>
            <CardContent className={classes.limitCardWidth}>
                <Grid container>
                    <Grid item {...{ xs: 7, }}>
                        {viewObject && gapiIsPresent() ? <SimpleIOToggleWrapper {...{

                            fileSelectRef: fileSelectRef,
                            key: 'SimpleIOToggleWrapper',
                            exportProps: {
                                ioType: IOType.EXPORT, userSelectedGroup,
                                contextCustomer: contextCustomer as ValidBusinessObject, viewDefinition: filterKeysToViewDefinition(keys), vobs: exportVobs, userFunctions,
                                parentHasRendered, setVBOsInParent: (something: any) => { }, transactionFunctions, processPlainObjects: simpleIOProcessPlainObjects
                            },
                            importProps: {
                                ioType: IOType.IMPORT, userSelectedGroup,
                                contextCustomer: contextCustomer as ValidBusinessObject, viewDefinition: filterKeysToViewDefinition(keys), vobs: observationMetricItems, userFunctions,
                                parentHasRendered, setVBOsInParent: (observations: ({ timestamp?: string } & ValidBusinessObject)[]) => {
                                    const historyStoreClone = new HistoryStore({ ...historyStore })
                                    observations.forEach((observation) => {
                                        const { timestamp, type, sk, pk, id, name, ...others } = observation
                                        const tempTimestamp = timestamp ? new Date(timestamp as string).valueOf() : Date.now()
                                        const existingTimestampIdx = observation.timestamp && historyStoreClone.timestamp?.indexOf(tempTimestamp)
                                        if (existingTimestampIdx !== -1) {
                                            // replace
                                        } else {
                                            console.log(`observation`, observation)
                                            historyStoreClone.push({ timestamp: tempTimestamp, state: others })
                                            setObservationHistory(new ObservationHistory({ ...observationHistory, observedHistory: historyStoreClone }))
                                        }
                                    })
                                }, transactionFunctions, processPlainObjects: simpleIOProcessPlainObjects
                            }
                        }

                        } /> : <Typography>Waiting for GAPI</Typography>}
                        {observationHistory && <Typography>{`last sheet: ${observationHistory?.googleSheet?.spreadsheetName}`}</Typography>}
                    </Grid>
                    <Grid item {...{ xs: 5 }}>
                        {controls}
                    </Grid>

                </Grid>
                <>
                    {stateObjectTable}

                </>
                <Listerlizer {...{ key: `${observationHistory?.sk}_observedHistoryTable`, ...listerlizerProps }}></Listerlizer>
            </CardContent>

        </Card>
    );
}