import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './DroughtModal.module.css';

const modalRoot = document.querySelector('#modal-root');

interface StageNameModal {
  close: () => void;
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const EditStageNameModal: React.FC<StageNameModal> = (props: StageNameModal) => {
  let { close, index, source, setSource } = props;

  const [name, setName] = useState<string>('');

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    return null;
  }

  const saveName = (newName: string) => {
    if (setSource) {
      let sourceCopy = { ...source };
      sourceCopy.stage[index].name = newName;
      setSource(sourceCopy);
      close();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Set Stage {index + 1}'s Name
              </Typography>
              <TextField label={'Name'} variant='outlined' fullWidth onChange={(e) => setName(e.target.value)} value={name || ''} size='small' />
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
              <Button variant='contained' onClick={close}>
                Cancel
              </Button>
              <Button
                variant='contained'
                disabled={name === ''}
                onClick={() => {
                  saveName(name);
                }}
              >
                Set New Name
              </Button>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default EditStageNameModal;
