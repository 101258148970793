
import { AdjacentFilter, AdjacentType, ViewDefinition, ViewKeyDefinitionType, ViewObject } from "../../types/AppTypes";
import { POJOType } from "../../types/UtilityTypes";
import { Parser } from 'json2csv';
import { ValidBusinessObject } from "@iotv/iotv-v3-types";
import { MapLike, ValidBusinessObjectList } from "@iotv/datamodel";


export const flattenViewObject = (viewObject: ViewObject, viewDefinition: ViewDefinition, adjacentFilter: AdjacentFilter, includeHeaders: boolean = true, headersAsDisplayValues: boolean = true) => {
    let res: any[][] = [];
    if ( includeHeaders ) {
        res=[(Object.values(viewDefinition).map( (viewKeyDefinition: ViewKeyDefinitionType) => headersAsDisplayValues ? viewKeyDefinition.label : viewKeyDefinition.key))]
    }
 
    const bodyObjects = ( adjacentFilter.adjacentType === AdjacentType.CHILD ? viewObject.matchedRelatedBySk : viewObject.matchedRelatedByPk )?.filter( (bodyObject) => bodyObject.type === adjacentFilter.objectType)
    const bodyRows: POJOType[][]= bodyObjects?.map( (bodyObject) => Object.values(viewDefinition).map( ( viewKeyDefinition ) => bodyObject[viewKeyDefinition.key] ) ) ?? [[]]
    if (bodyRows.length > 0) {
        res = res.concat(bodyRows)
    }
    return res;
}

export const inflateViewObject = ( items: ValidBusinessObjectList, requestedMatchedPrimary: ValidBusinessObject ) => {
    const viewObject: ViewObject = {
        matchedPrimary: undefined,
        matchedRelatedByPk: [],
        matchedRelatedBySk: []
    };

    const requestedItem = items.find( (item) => item.sk = requestedMatchedPrimary.sk) as ValidBusinessObject | undefined

    items.forEach( (item) => { 
        if (item.sk === requestedMatchedPrimary.sk ) {
            viewObject.matchedPrimary = item
        } else if ( item.pk === requestedMatchedPrimary.sk ) {
            viewObject.matchedRelatedBySk?.push(item);
        } else if ( item.sk === requestedMatchedPrimary.pk ) {
            viewObject.matchedRelatedByPk?.push(item)
        }
    })


    return viewObject;
}

export const getVOBListHash = ( vobList: ValidBusinessObjectList ) => vobList.map( (vob) => vob.sk ).join(':')

export const getViewObjectHash = ( viewObject: ViewObject) =>  [ viewObject.matchedPrimary?.sk ?? 'noMatchPrimary',
    ...(viewObject.matchedRelatedByPk?.map( (item) => item.sk) ?? []),
    ...(viewObject.matchedRelatedBySk?.map( (item) => item.sk) ?? [])
    ].join(':')

export const getViewObjectsHash = ( viewObjects: ViewObject[] ) => [ ...viewObjects.map( (viewObject) => getViewObjectHash(viewObject) ) ].join(':')

const isHashSafe = ( ob: object ) => {
    return (
        [ 'string', 'number', 'boolean' ].includes( typeof ob )
    )
}

export const getVOBAttributeHash = ( vob: MapLike<any>) => Object.values(vob).filter( isHashSafe ).join('#')

const parser = new Parser();

export const jsonToCsv = (jsonObjects: POJOType[]) => {
    let res: {err: any, data: string | null} = { err: null, data: null };
    try {
        jsonObjects && ( res.data = parser.parse(jsonObjects) );
    } catch (e: any) {
        res.err = e.message
    }
    return res;
} ;
