import { ValidBusinessObjectList, ValidModelObject } from '@iotv/datamodel';
import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import { Hidden } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TransactionFunctions, UserFunctions } from '../actions/AppActions';
import { getUserGeolocation } from '../actions/GMapActions';
import { logOut, refreshWithUserToken, setNewPassword } from '../actions/Authentication/LoginActions';
import NoMatch from '../components/error/NoMatch';
import { NavSections } from '../components/navigation/NavSections';
import { c021NavSections } from '../venture/c021/navigation/c021_NavSections';
import TestingComponent from '../components/TestingComponent';
import config from '../config';
import { styles as AppStyles } from '../cosmetics/AppTheme';
import { RootState } from '../reducers';
import theme from '../themes/defaultTheme';
import { AdditionalHistoryProps, ReactRouterMatch, RoleArbitrationInstanceType, UserGroupsRolesType } from '../types/AppTypes';
import { gapiIsPresent, mapiIsPresent } from '../util/Scripts/isPresent';
import Header from '../components/Header';
import C021Header from '../venture/c021/components/c021Header';
import DeviceProvisioningViewContainer from './DeviceProvisioningViewContainer';
//import CustomerManagementContainer from './containers/CustomerManagementContainer';
import CustomerManagementContainer from '../venture/c021/containers/CustomerManagementContainer';
import DashboardViewContainer from './DashboardViewContainer';
import DataTableContainer from './DataTableContainer';
import DeviceManagementContainer from './DeviceManagementContainer';
import DeviceControlContainer from './DeviceControlContainer';
import LoginContainer from './LoginContainer';
import MapViewContianer from './MapViewContainer';
import MessageContainer from './MessageContainer';
import NetworkMonitoringContainer from './NetworkMonitoringContainer';
import NotificationContainer from './NotficationContainer';
import PrivateRoute from './PrivateRoute';
import SurveyContainer from './SurveyContainer';
import ThingsViewContainer from './ThingsViewContainer';
import ThingViewContainer from './ThingViewContainer';
import UserManagementContainer from './UserManagementContainer';
import UserViewContainer from './UserViewContainer';
import DroughtManagementPlansContainer from '../venture/c021/containers/DroughtManagementPlansContainer';
import CommunityViewContainer from '../venture/c021/containers/CommunityViewContainer';
import CommunitiesViewContainer from '../venture/c021/containers/CommunitiesViewContainer';
import TanksInCommunityContainer from '../venture/c021/containers/TanksInCommunityContainer';
import DroughtManagementReportContainer from '../venture/c021/containers/DroughtManagementReportContainer';
import DroughtManagementPlanFormContainer from '../venture/c021/containers/DroughtManagementPlanFormContainer';
import RedirectViewContainer from '../venture/c021/containers/RedirectViewContainer';
import PasswordReset from '../components/access/passwordReset';
import SignUp from '../components/access/signUp';
import NewPassword from '../components/access/newPassword';
import { CognitoUser } from 'amazon-cognito-identity-js';
import RecipientManagerContainer from '../venture/c021/containers/RecipientManagerContainer';

const debug = process.env.REACT_APP_DEBUG && false;
const drawerWidth = 256;

export type MainAppProps = {
  isAuthenticated: boolean;
  didInvalidate: boolean;
  user: any;
  userCustomers: ValidBusinessObjectList;
  contextCustomer: ValidModelObject<'Customer'> | undefined;
  userGroupRoles: UserGroupsRolesType;
  logout: Function;
  doRefreshWithUserToken: () => Promise<void>;
  getUserGeolocation: () => Promise<void>;
  history: History & AdditionalHistoryProps;
  match: ReactRouterMatch;
  location: any;
  classes: any;
  fetching: boolean;
  pinger: boolean;
  useSpecializedViews: boolean;
  userFunctions: typeof UserFunctions;
  transactionFunctions: typeof TransactionFunctions;
  roleArbitrationInstance: RoleArbitrationInstanceType | undefined;
  userSelectedGroup: string | undefined;
  stateSize: number;
  cognitoUser: CognitoUser | undefined;
  setNewPassword: (user: CognitoUser, password: string) => Promise<void>;
} & RouteComponentProps;

const getStyles = () => {
  const stylesMap = {
    default: AppStyles,
  };

  const styles = Object.entries(stylesMap).find(([k, styles]) => process.env.REACT_APP_PROJECT_CODE?.startsWith(k))?.[1] ?? stylesMap.default;
  console.log(`App.tsx returning styles for ${process.env.REACT_APP_PROJECT_CODE}`);
  return styles;
};

export type MainAppState = {
  navDrawerOpen: boolean;
  googleMapScriptLoaded: boolean;
  navMain: React.ComponentType<any> | null;
  theme: Theme | undefined;
};

class App extends Component<MainAppProps, MainAppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      navDrawerOpen: false,
      googleMapScriptLoaded: false,
      navMain: null,
      theme: undefined,
    };
    this.appRef = React.createRef();
  }

  navDrawerOpen = true;

  appRef: React.RefObject<HTMLDivElement> = React.createRef();
  googleMapsUrl = config.google.mapsApiUri;
  googleChartsUrl = config.google.chartApiUri;
  googleApisUrl = config.google.apiUri;
  map: google.maps.Map | undefined = undefined;

  addMapsScript = () => {
    let googleMapScript: HTMLScriptElement | undefined = undefined;
    if (!mapiIsPresent()) {
      googleMapScript = Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        src: this.googleMapsUrl,
        async: true,
        defer: true,
        onload: () => {
          debug && console.log('Map was loaded by App Container');
          this.setState({ googleMapScriptLoaded: true });
        },
      }) as HTMLScriptElement;
      document.body.appendChild(googleMapScript);
    } else {
      googleMapScript = document.querySelector(`[src="${this.googleMapsUrl}"]`) as HTMLScriptElement;
      if (!{ googleMapScript }) console.log('GMap no script');
    }
    return googleMapScript;
  };

  addChartsScript = () => {
    let googleChartsScript = undefined;
    if (!document.querySelectorAll(`[src="${this.googleChartsUrl}"]`).length) {
      googleChartsScript = Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        src: this.googleChartsUrl,
        onload: () => {
          debug && console.log('Loading Googele Charts resource....');
          google.charts.load('current', { packages: ['corechart'] });
        },
      });
      document.body.appendChild(googleChartsScript);
    } else {
      googleChartsScript = document.querySelector(`[src="${this.googleChartsUrl}"]`);
      if (!{ googleMapScript: googleChartsScript }) console.log('GCharts no script');
    }
    return googleChartsScript;
  };

  addAPIsScript = () => {
    let googleChartsScript = undefined;
    if (!gapiIsPresent()) {
      googleChartsScript = Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        src: this.googleApisUrl,
        async: true,
        defer: true,
        onload: () => {
          debug && console.log('Loading Googele API resource....');
        },
      });
      document.body.appendChild(googleChartsScript);
    } else {
      googleChartsScript = document.querySelector(`[src="${this.googleChartsUrl}"]`);
      if (!{ googleMapScript: googleChartsScript }) console.log('GCharts no script');
    }
    return googleChartsScript;
  };

  componentDidMount() {
    this.addMapsScript();
    this.addChartsScript();
    this.addAPIsScript();
    debug && console.log('App Container requesting refresh with user token', { isAuth: this.props.isAuthenticated, user: this.props.user });
    this.props.doRefreshWithUserToken();
    this.props.getUserGeolocation();
    let d = config.app.appCode.substr(0, 4);
    let ventureNavMainPath = `../venture/${d}/navigation/${d}_NavMain`;
    const defaultNavMainPath = `../components/navigation/i001_NavMain`;

    // it appears import cannot use above variables
    import(`../venture/${d}/navigation/${d}_NavMain`)
      .then((module) => this.setState({ navMain: module.default }))
      .catch((e) => {
        console.log(e.message);
        console.log(`could not load any nav main with ${ventureNavMainPath}`);
        import(`../components/navigation/i001_NavMain`)
          .then((module) => this.setState({ navMain: module.default }))
          .catch((e) => {
            console.log(e.message);
            console.log(`could not load any nav main with ${defaultNavMainPath}`);
          });
      });

    import(`../venture/${d}/cosmetics/theme`)
      .then((module) => {
        this.setState({ theme: module.default });
        console.log(`with ../venture/${d}/cosmetics/theme set this.state.theme to`, module.default);
      })
      .catch((e) => {
        console.log(e.message);
        console.log(`could not load any theme with ${`../venture/${d}/cosmetics/theme`}`);
        this.setState({ theme: theme });
      });
  }

  handleDrawerToggle = () => {
    this.setState({ navDrawerOpen: this.state ? !this.state.navDrawerOpen : true });
  };

  render() {
    document.title = `Default App State has ${this.props.stateSize} items`;
    console.log('Theme FW App', theme);
    const exact = true;
    debug && console.log('App get', this.props);
    debug && console.log('App Ref', this.appRef);
    const {
      fetching,
      isAuthenticated,
      didInvalidate,
      user,
      userCustomers,
      contextCustomer,
      logout,
      classes,
      match,
      userGroupRoles,
      roleArbitrationInstance,
      userSelectedGroup,
      location,
      useSpecializedViews,
      userFunctions,
      transactionFunctions,
      cognitoUser,
      setNewPassword,
    } = this.props;
    const { navMain: NavMain, googleMapScriptLoaded } = this.state;

    // debug && console.log('App has lastViewedRoute', localStorage.getItem('lastViewedRoute' ) )
    debug && console.log('App has userSelectedGroup', userSelectedGroup);

    isAuthenticated && location.pathname !== `/${NavSections.LOGIN}` && localStorage.setItem('lastViewedRoute', location.pathname);

    return (
      <div>
        <ThemeProvider theme={this.state.theme ?? theme}>
          <div className={classes.root}>
            <CssBaseline />
            {NavMain && (
              <nav className={classes.drawer}>
                {/* <Hidden lgUp implementation="js"> */}
                {/* <Hidden smUp implementation="css"> */}
                <Hidden smUp implementation='css'>
                  <NavMain
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant={'temporary' as any}
                    open={this.state && this.state.navDrawerOpen}
                    onClose={this.handleDrawerToggle}
                    {...{
                      classes,
                      onDrawerToggle: this.handleDrawerToggle,
                      isAuthenticated,
                      useSpecializedViews,
                      userFunctions,
                      roleArbitrationInstance,
                      userSelectedGroup,
                    }}
                  />
                </Hidden>
              </nav>
            )}

            <div className={classes.app} ref={this.appRef}>
              {/* diallowed edit from c021 folows */}

              {config.app.appCode.startsWith('c021') ? (
                <C021Header
                  {...{
                    onDrawerToggle: this.handleDrawerToggle,
                    user,
                    userCustomers,
                    contextCustomer,
                    userGroupRoles,
                    userSelectedGroup,
                    logout,
                    userFunctions,
                    transactionFunctions,
                    pinger: this.props.pinger,
                  }}
                />
              ) : (
                <Header
                  {...{
                    fetching,
                    onDrawerToggle: this.handleDrawerToggle,
                    user,
                    userCustomers,
                    contextCustomer,
                    userGroupRoles,
                    userSelectedGroup,
                    logout,
                    userFunctions,
                    transactionFunctions,
                    pinger: this.props.pinger,
                  }}
                />
              )}

              <MessageContainer {...{ match }}></MessageContainer>
              <main className={classes.main}>
                <Switch>
                  <Route path={`/${NavSections.LOGIN}`}>
                    <LoginContainer {...{ theme, isAuthenticated, didInvalidate, location }} />
                  </Route>
                  <Route exact path={'/'}>
                    <LoginContainer {...{ theme, isAuthenticated, didInvalidate, location }} />
                  </Route>
                  <Route {...{ exact, path: `/${NavSections.UNAUTHENTICATED}/FORGOT` }}>
                    <PasswordReset {...{ isAuthenticated }} />
                  </Route>
                  {!isAuthenticated && (
                    <Route {...{ exact, path: `/${NavSections.UNAUTHENTICATED}/SIGNUP` }}>
                      <SignUp {...{ isAuthenticated }} />
                    </Route>
                  )}
                  { !isAuthenticated && <Route  { ...{ exact, path: `/${NavSections.UNAUTHENTICATED}/NEW_PASSWORD` } }><NewPassword {  ...{ isAuthenticated, cognitoUser, setNewPassword }}/></Route>}
                  { isAuthenticated && <Route  { ...{ exact, path: `/${NavSections.UNAUTHENTICATED}/NEW_PASSWORD` } }><Redirect { ...{ to: `/${NavSections.HOMEPAGE}`}}/></Route>}
                  <PrivateRoute {...{ exact, path: `/${NavSections.USERVIEW}`, isAuthenticated, Komponent: UserViewContainer }}> </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.THINGSVIEW}/`, isAuthenticated, Komponent: ThingsViewContainer, match }}> </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.DATATABLEVIEW}/:type?`, isAuthenticated, Komponent: DataTableContainer, match }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.NETWORKTABLEVIEW}/:type?`, isAuthenticated, Komponent: NetworkMonitoringContainer, match }}>
                    {' '}
                  </PrivateRoute>
                  {/* THINGSVIEW IS UNDER DEPRECATION TO JUST USE THINGVIEW */}
                  <PrivateRoute {...{ exact, path: `/${NavSections.THINGSVIEW}/:type`, isAuthenticated, Komponent: ThingsViewContainer, match }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.THINGVIEW}/:type`, isAuthenticated, Komponent: ThingsViewContainer, match }}> </PrivateRoute>
                  <PrivateRoute
                    {...{ exact, path: `/${NavSections.THINGVIEW}/:type/:id`, isAuthenticated, Komponent: ThingViewContainer, match, appRef: this.appRef }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.MAPVIEW}`, isAuthenticated, Komponent: MapViewContianer, match, googleMapScriptLoaded }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.SURVEYVIEW}`, isAuthenticated, Komponent: SurveyContainer, match, googleMapScriptLoaded }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute
                    {...{
                      exact,
                      path: `/${NavSections.NOTIFICATION_MANANGEMENT_VIEW}`,
                      isAuthenticated,
                      Komponent: NotificationContainer,
                      match,
                      googleMapScriptLoaded,
                    }}
                  >
                    {' '}
                  </PrivateRoute>

                  <PrivateRoute {...{ exact, path: `/${NavSections.ADMIN}`, isAuthenticated, Komponent: TestingComponent, match }}> </PrivateRoute>
                  <PrivateRoute
                    {...{ exact, path: `/${NavSections.USER_MANAGEMENT_VIEW}/:action?`, isAuthenticated, Komponent: UserManagementContainer, match, location }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute
                    {...{
                      exact,
                      path: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/:action?/:type?/:id?`,
                      isAuthenticated,
                      Komponent: CustomerManagementContainer,
                      match,
                      location,
                    }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${c021NavSections.RECIPIENT}`, isAuthenticated, Komponent: RecipientManagerContainer, match, location }}>
                    {' '}
                  </PrivateRoute>

                  <PrivateRoute
                    {...{
                      exact,
                      path: `/${NavSections.DEVICE_MANAGEMENT_VIEW}/:action?/:type?/:id?`,
                      isAuthenticated,
                      Komponent: DeviceManagementContainer,
                      match,
                      location,
                    }}
                  >
                    {' '}
                  </PrivateRoute>

                  <PrivateRoute
                    {...{ exact, path: `/${NavSections.DIAGNOSTIC}`, isAuthenticated, Komponent: DeviceControlContainer, match, googleMapScriptLoaded }}
                  >
                    {' '}
                  </PrivateRoute>

                  <PrivateRoute
                    {...{ exact, path: `/${c021NavSections.DROUGHTMANAGEMENTPLANVIEW}`, isAuthenticated, Komponent: DroughtManagementPlansContainer }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute
                    {...{ exact, path: `/${c021NavSections.DROUGHTMANAGEMENTPLANVIEW}/:id`, isAuthenticated, Komponent: DroughtManagementPlanFormContainer }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${c021NavSections.COMMUNITYVIEW}/:id`, isAuthenticated, Komponent: CommunityViewContainer }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${c021NavSections.COMMUNITYVIEW}/:id/tanks`, isAuthenticated, Komponent: TanksInCommunityContainer }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute
                    {...{ exact, path: `/${c021NavSections.COMMUNITYVIEW}/:id/report`, isAuthenticated, Komponent: DroughtManagementReportContainer }}
                  >
                    {' '}
                  </PrivateRoute>

                  <PrivateRoute {...{ exact, path: `/${c021NavSections.COMMUNITYVIEW}`, isAuthenticated, Komponent: CommunitiesViewContainer }}> </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${NavSections.PROVISIONVIEW}`, isAuthenticated, Komponent: DeviceProvisioningViewContainer }}>
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute
                    {...{ exact, path: `/${NavSections.DEVICEEUI_PROVISIONVIEW}/:deviceEui?`, isAuthenticated, Komponent: DeviceProvisioningViewContainer }}
                  >
                    {' '}
                  </PrivateRoute>
                  <PrivateRoute {...{ exact, path: `/${c021NavSections.REDIRECT}`, isAuthenticated, Komponent: RedirectViewContainer }}> </PrivateRoute>

                  <PrivateRoute {...{ exact, path: `/${NavSections.DASHBOARD}`, isAuthenticated, Komponent: DashboardViewContainer, match }}> </PrivateRoute>

                  <Route path='*'>
                    <NoMatch />
                  </Route>
                </Switch>
              </main>
              {false && (
                <footer className={classes.footer}>
                  <div> {match.params?.filter?.toString()}</div>
                </footer>
              )}
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    logout: () => dispatch(logOut()),
    setNewPassword: (user: CognitoUser, password: string) => dispatch(setNewPassword(user, password)),
    doRefreshWithUserToken: () => dispatch(refreshWithUserToken()),
    getUserGeolocation: () => dispatch(getUserGeolocation()),
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch),
  };
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  // errorMessage: state.errorMessage,
  isAuthenticated: state.AccessReducer.isAuthenticated,
  didInvalidate: state.AccessReducer.didInvalidate,
  cognitoUser: state.AccessReducer.cognitoUser,
  userGroupRoles: state.AppReducer.userGroupRoles,
  inputValue: ownProps.location.pathname.substring(1),
  user: state.AppReducer.user,
  userCustomers: state.AppReducer.userCustomers,
  contextCustomer: state.AppReducer.contextCustomer,
  fetching: state.AccessReducer.isFetching,
  pinger: state.AppReducer.pinger,
  useSpecializedViews: state.AppReducer.useSpecializedViews,
  roleArbitrationInstance: state.AppReducer.roleArbitrationInstance,
  userSelectedGroup: state.AppReducer.userSelectedGroup,
  stateSize: state.AppReducer.normalData ? Object.keys(state.AppReducer.normalData).length : 0,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(getStyles())(App)));
//export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
