import { Switch, FormGroup, FormControlLabel } from "@mui/material";
import { useState } from "react";

type SingleRowSwitchProps = {
    checked: boolean,
    onChange: any,
}

const SingleRowSwitch = (props: SingleRowSwitchProps) => {
//   const [checked, setChecked] = useState<boolean>(false);
    const { checked, onChange } = props;

  return (
    <FormGroup>
      <FormControlLabel
        label="compact view"
        // style={{ color: "white" }}
        control={
        //   <Switch checked={checked} onChange={(e) => setChecked(!checked)} />
            <Switch checked={checked} onChange={onChange} />
        }
      />
    </FormGroup>
  );
};

export default SingleRowSwitch;
