import { AppValueTypes } from '@iotv/iotv-v3-types';
import React from 'react';
import { UserDetailCardProps, UserTransactionType, ViewDefinition } from '../../types/AppTypes';
import DetailCard from '../cards/DetailCard';


const debug = process.env.REACT_APP_DEBUG && false;

export default function UserDetailCard( props: UserDetailCardProps ) {

   const { searchParams } = props;

  debug && console.log('User Details Card has props', props);


  const actions: UserTransactionType[] = [UserTransactionType.UPDATE]

  const viewDefinition: ViewDefinition = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    first_name: { key: 'first_name', label: 'First Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    last_name: { key: 'last_name', label: 'Last Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    data_of_birth: { key: 'date_of_birth', label: 'Date of Birth', type: AppValueTypes.DATE, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    
    email: { key: 'email', label: 'Email', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    phone_number: { key: 'phone_number', label: 'Phone Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    phone_mobile: { key: 'phone_mobile', label: 'Cell Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    // test_boolean: { key: 'test_boolean', label: 'Certified Rider', type: AppValueTypes.BOOLEAN, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    // number_of_horses: { key: 'horse_count', label: 'Number of Horses', type: AppValueTypes.NUMBER, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},

    // test_emum: { key: 'testEnum', label: 'Test Enum', type: AppValueTypes.ENUM, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, enumKV: { yes: 'Y', no: 'N', maybe: '~'}}
  }

  const { matchedPrimary, transactionFunctions, history } = props

  return <DetailCard { ...{matchedPrimary, viewDefinition, transactionFunctions, actions, searchParams, history} }></DetailCard>
}