import { ErrData, getObjectHash, ObjectHistory, roundDecimals, ValidBusinessObject } from "@iotv/datamodel";
import React from "react";
import { useEffect } from "react";
import { initializeObjectHistory } from "../data/TypeHelpers";
import ApiGateway from "../data/aws/api-gateway/ApiGateway";
import { ObjectHistoryFilterRequest } from "@iotv/iotv-v3-types";

const debug = process.env.REACT_APP_DEBUG && false;

export type UseObjectHistoryReturnType = [
  ObjectHistory | undefined, React.Dispatch<React.SetStateAction<ObjectHistory | undefined>>,
  ObjectHistoryFilterRequest | undefined, React.Dispatch<React.SetStateAction<ObjectHistoryFilterRequest | undefined>>
]

export function useObjectHistory(matchedPrimary: ValidBusinessObject | undefined, defaultObjectHistoryFilter?: ObjectHistoryFilterRequest ): UseObjectHistoryReturnType {
   
  const [ objectHistoryFilter, setObjectHistoryFilter ] = React.useState<ObjectHistoryFilterRequest | undefined>( defaultObjectHistoryFilter)



  const objectHistoryFilterHash = getObjectHash(objectHistoryFilter);
    useEffect( () => {
        let timeout: NodeJS.Timeout | undefined = undefined // may need to be in useHistory
        debug && console.log('useObjectHistory effect because', matchedPrimary?.sk)
        const getObjectHistory = async ( vob: ValidBusinessObject) => {
          const path = '/getObjectHistory';
          const t0 = Date.now()

          const res =  await ApiGateway.post(path, { sk: vob.sk, objectHistoryFilter}) as  ErrData<ValidBusinessObject>;
          debug && console.log('getObjectHistory res', res)
          if ( res.data  ) {
            const objectHistoryZombie = res.data;
            const objectHistory = new ObjectHistory(objectHistoryZombie)
            initializeObjectHistory(objectHistory)
            debug && console.log(`received objectHistory with keys: ${ Object.keys( objectHistory ).join(' ') } in ${ roundDecimals( ( Date.now() - t0 ) / 1000, 2 )}secs`)
            setObjectHistory(objectHistory)
          } else if (vob.dailyStateHistory || vob.recentStateHistory ) { // is this a hack? yes until decide whether AggregationGroup should have an ObjectHistory seperately
            setObjectHistory(new ObjectHistory(vob))
          }
        }
    
        if (matchedPrimary) {
          if ( objectHistoryFilterHash !== getObjectHash(defaultObjectHistoryFilter) ) { // attempt to only wait for user input after first immediate query
            debug && console.log('useObjectHistory is using timeout to wait for new user input, hopefully not on first query')
            if ( timeout ) {
              timeout = undefined
            }
            timeout = setTimeout( () => getObjectHistory(matchedPrimary), 500)
          } else {

            getObjectHistory(matchedPrimary)          }

     
        }


    
      }, [matchedPrimary?.sk, objectHistoryFilterHash])
    

      const [ objectHistory, setObjectHistory ]: [ ObjectHistory | undefined, React.Dispatch<React.SetStateAction<ObjectHistory | undefined>>] = React.useState<ObjectHistory | undefined>( undefined );

      return  [ objectHistory, setObjectHistory,  objectHistoryFilter, setObjectHistoryFilter  ];
}

export function useObjectHistoryOLD(matchedPrimary: ValidBusinessObject | undefined ) {
  useEffect( () => {
      debug && console.log('useObjectHistory effect because', matchedPrimary?.sk)
      const getObjectHistory = async ( vob: ValidBusinessObject) => {
        const path = '/get';
        const objectHistoryZombie = { pk: vob.sk, sk: `ObjectHistory_${vob.sk}`, type: 'ObjectHistory', id: '?' }
        const res =  await ApiGateway.post(path, [objectHistoryZombie]) as  ErrData<ValidBusinessObject>;
        if ( res.data && res.data instanceof Array && res.data.length > 0 ) {
          const objectHistoryZombie = res.data[0];
          const objectHistory = new ObjectHistory(objectHistoryZombie)
          debug && console.log('received objectHistory with keys', Object.keys( objectHistory ))
          setObjectHistory(objectHistory)
        } else if (vob.dailyStateHistory || vob.recentStateHistory ) { // is this a hack? yes until decide whether AggregationGroup should have an ObjectHistory seperately
          setObjectHistory(new ObjectHistory(vob))
        }
      }
  
      matchedPrimary && getObjectHistory(matchedPrimary)
  
    }, [matchedPrimary?.sk])
  
  
    const [ objectHistory, setObjectHistory ]: [ ObjectHistory | undefined, React.Dispatch<React.SetStateAction<ObjectHistory | undefined>>] = React.useState<ObjectHistory | undefined>( undefined );

    return  [ objectHistory, setObjectHistory ];
}