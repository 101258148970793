import { ACTIONS } from '../actions/actionTypes';
import { CognitoUser } from 'amazon-cognito-identity-js';
import config from '../config';
import { AppLocalStorage } from '../data/LocalStorage/AppLocalStorage';
import { NavSections } from '../components/navigation/NavSections';

const debug = process.env.REACT_APP_DEBUG && true;

type AccessStateShape = {
  // version: number,
  isFetching: boolean;
  isAuthenticated: boolean;
  cognitoUser: CognitoUser | undefined
  didInvalidate: boolean | undefined
}

console.log('root has aut', localStorage[`CognitoIdentityServiceProvider.${config.aws.cognito.APP_CLIENT_ID}.LastAuthUser`] )
//const possibleAuth = typeof localStorage[`CognitoIdentityServiceProvider.${config.aws.cognito.APP_CLIENT_ID}.LastAuthUser`] === 'string'
console.log('lastViewedRoute', AppLocalStorage.get( NavSections.HOME, 'lastViewedRoute') )

const initialState: AccessStateShape = {
    isFetching: false,
    isAuthenticated: false,
    cognitoUser: undefined,
    didInvalidate: undefined,
}

const AccessReducer = (state = initialState, action: any) => {
	//( action.type !== ACTIONS.CLEAR_MESSAGE_PING) && console.log('Access Reducer action', action);
  switch (action.type) {
    case ACTIONS.REQUEST_AUTHENTICATE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case ACTIONS.RECEIVE_AUTHENTICATE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        cognitoUser: action.cognitoUser,
        isAuthenticated: true,
      })
    case ACTIONS.RECEIVE_AUTH_DENIED: {
      debug && console.log('Auth denied', action)
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        isAuthenticated: action.code === 'NotAuthorizedException'
      })
    }
    case ACTIONS.NEW_PASSWORD_REQUIRED: {
      debug && console.log('NEW_PASSWORD_REQUIRED', action)
      const thisAction: {
        type: ACTIONS.NEW_PASSWORD_REQUIRED,
        cognitoUser: CognitoUser } = action
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        cognitoUser: thisAction.cognitoUser,
        isAuthenticated: false
      })
    }
    case ACTIONS.RECEIVE_LOGOUT: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        isAuthenticated: false,
      })
    }
    case ACTIONS.REQUEST_REFRESH_WITH_USER_TOKEN: {
      debug && console.log('refresh', action);
      return {...state};
    }
    case ACTIONS.RECIEIVE_REFRESH_WITH_USER_TOKEN: {
      debug && console.log('refresh', action);
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        cognitoUser: action.cognitoUser,
        isAuthenticated: true,
      })
    }

    
    default: {
      return state;
    }
   
  }
};

export default AccessReducer;


