import { BusinessObjectAssembler, ErrData, isValidBusinessObject, ThingShadowState, ValidBusinessObject, ValidBusinessObjectList, MapLike } from '@iotv/datamodel';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AppLocalStorage } from '../data/LocalStorage/AppLocalStorage';
import { normalData } from 'src/test/state/data';
import { v4 as uuidv4 } from 'uuid/';
import { ACTIONS } from '../actions/actionTypes';
import { NavSections } from '../components/navigation/NavSections';
import config from '../config';
import { addToNormalData, mergeNormalDatas, removeAllFromNormalData, removeFromNormalData } from '../data/daoFunctions/stateFns';
import { AppReduceerState, DeleteObjectRespose, GeolocationCoordinates, GetAdjacentResponse, GetLargeDataResponze, IUserMessageType, MessageOutputType, NormalizedData, ObjectAPIError, RoleArbitrationInstanceType, UnlinkResponse, UserTransactionType, UserView, NavigationItemType } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && true;
const redirectDebug = process.env.REACT_APP_DEBUG && true;
const Assembler = new BusinessObjectAssembler();
const initialState: AppReduceerState = {
  isFetching: false,
  cognitoUser: undefined,
  userGroupRoles: {
    groups: [],
    roles: [],
    preferredRole: undefined
  },
  userCustomers: [],
  contextCustomer: undefined,
  contextSecondaryObject: undefined,
  roleArbitrationInstance: undefined,
  userSelectedGroup: AppLocalStorage.get( NavSections.HOME, 'lastUserSelectedGroupName') ?? undefined,
  user: undefined,
  normalData: {},
  largeObjects: {},
  messages: {},
  errors: {},
  redirect: undefined,
  userGeolocation: undefined,
  pinger: false,
  useSpecializedViews: ['v015', 'v016', 'c021', 'v017'].includes(config.app.appCode.substring(0, 4)),
  defaultRoute: AppLocalStorage.get( NavSections.HOME, 'lastViewedRoute') ?? undefined,
}

const getUserFromNormalData = (cognitoUser?: CognitoUser, normalData?: NormalizedData) => {
  let user: UserView = undefined;
  if (cognitoUser && normalData) {
    const userKey = `User:${cognitoUser.getSignInUserSession()?.getAccessToken().payload.sub}`;
    user = normalData[userKey] && normalData[userKey][userKey];
  }
  debug && console.log('getUserFromNormalData', user)
  return user;
}

const getDefaultRouteFromNormalData = (normalData?: NormalizedData) => {
  // debug && console.log('getDefaultRouteFromNormalData', normalData)

  const map = new Map();
  const keys = ['Property', 'Field', 'Herd', 'Horse'];
  let path1 = '/THINGSVIEW/';
  let path2 = '';
  keys.map((key) => {
    map.set(key, 0);
  })

  for (let key in normalData) {
    if (normalData.hasOwnProperty(key)) {
      const mapKey = !key.includes('_') && key.split(':')[0];
      if (typeof map.get(mapKey) !== 'undefined') {
        map.set(mapKey, map.get(mapKey) + 1)
      }
    }
  }

  for (var i = 0; i < keys.length; i++) {
    if (map.get(keys[i]) > 1) {
      path2 = keys[i];
      break;
    }
  }

  // if (!path2) path2 = 'Horse';

  if (path2 === 'Horse') return '/TABLEVIEW/Horse';
  
  debug && console.log('getDefaultRouteFromNormalData', path1, path2)
  return path1 + path2;
}

export const filterByUserProperty = (normalData: NormalizedData, children: NavigationItemType[] | undefined) => {
  const map = new Map();

  const keys = ['Horse', 'Herd', 'Field', 'Farrier', 'Property', 'Vet', 'Trainer', 'Groom'];

  keys.map((key) => {
    map.set(key, 0)
  })

  for (let key in normalData) {
    if (normalData?.hasOwnProperty(key)) {
      
      const mapKey = !key.includes('_') && key.split(':')[0];

      if (typeof map.get(mapKey) !== 'undefined') {
        map.set(mapKey, map.get(mapKey) + 1)
      }
    }
  }

  const result = [];
  if (children) {
    for (var ind = 0; ind < children.length; ind++) {
      const key = children[ind].link?.split('/')[1];
      if (map.get(key) !== 0 && map.get(key) !== 1) {
        result.push(children[ind]);
      }
    }
  }

  debug && console.log('normalData', normalData)
  debug && console.log('filterByUserProperty', result)

  return result;
}

const addErrorToUpdate = (err: ObjectAPIError, update: { errors?: MapLike<any> }) => {
  if (!(err.id && err.object)) {
    debug && console.log('Badly formed errors on ', err);
  } else {
    if (!(update.errors)) update.errors = {};
    if (!(update.errors[err.object.sk])) {
      update.errors[err.object.sk] = { [err.id]: err }
    } else {
      update.errors[err.object.sk][err.id] = err
    }
  }
  debug && console.log('addErrorToUpate', update.errors)
}

const addMessageToUpdate = (object: ValidBusinessObject, update: { messages?: MapLike<any> }, label: string, content: string, type: MessageOutputType) => {
  let message: IUserMessageType = {
    content,
    id: uuidv4(),
    label,
    type
  }
  if (!(update.messages)) update.messages = {};
  update.messages[message.id] = message;
}

//const removeMessageFromUpdate = ( messa)
const AppReducer = (state: AppReduceerState = initialState, action: any): AppReduceerState => {
  (action.type !== ACTIONS.CLEAR_MESSAGE_PING) && console.log('App Reducer action', action);

  const update: AppReduceerState = { ...state };
  const redirect = update.redirect = undefined;
  if (redirect) console.log('set redirect undefined', redirect);
  redirectDebug && console.log('State has redirect', state.redirect)


  switch (action.type) {
    case ACTIONS.DISMISS_USER_MESSAGE: {
      let messageUpdate = { ...state.messages };
      let errorsUpdate = { ...state.errors };
      const messageId = action.value.id
      if (messageUpdate[messageId]) {
        delete messageUpdate[messageId];
      } else {
        Object.entries(errorsUpdate).forEach(([k, subMap]) => {
          if (subMap[messageId]) delete errorsUpdate[k][messageId];
        })
      }
      return Object.assign({}, update, { messages: messageUpdate });
    }

    case ACTIONS.RECEIVE_AUTHENTICATE: {
      Object.assign(update, { ...action });
      debug && console.log('RECEIVE_AUTHENTICATE updating state with', update)
      return Object.assign({}, update, {

      })
    }

    case ACTIONS.REQUEST_REFRESH_WITH_USER_TOKEN: {
      debug && console.log('refresh', action);
      return { ...state };
    }

    case ACTIONS.RECIEIVE_REFRESH_WITH_USER_TOKEN: {
      debug && console.log('refresh', action);
      return { ...state };
    }

    case ACTIONS.REQUEST_INIT:
      return Object.assign({}, update, {
        isFetching: true,
        didInvalidate: false
      })

    case ACTIONS.RECEIVE_INIT: {
      debug && console.log('merging', { source: action.normalData, target: update.normalData})
      mergeNormalDatas(action.normalData, update.normalData)

      const mergedUpdate = Object.assign({}, update, {
        isFetching: false,
        didInvalidate: false,
        // normalData: action.normalData,
        user: getUserFromNormalData(state?.cognitoUser, action.normalData),
        defaultRoute: getDefaultRouteFromNormalData(action.normalData)
      })
      
      debug && console.log('returning merged state', mergedUpdate )

      return mergedUpdate;
    }
     

    case ACTIONS.RECEIVE_CONTEXT_CUSTOMERS:
      debug && console.log(' ACTIONS.RECEIVE_CONTEXT_CUSTOMERS ', action)
      update.userCustomers = action.customers?.data?.Items;
      if (update.userCustomers instanceof Array) {
        if (update.userCustomers.length === 1) {
          update.contextCustomer = update.userCustomers[0];
        } else {
          const lastCustomer = update.userCustomers.find((item) => item.sk === AppLocalStorage.get( NavSections.HOME, 'lastContextCustomerSk'), undefined)
          update.contextCustomer = lastCustomer ?? update.userCustomers[0];
        }
      } debug && console.log(' ACTIONS.RECEIVE_CONTEXT_CUSTOMERS UPDATE ', update)
      return update;

    case ACTIONS.MQTT_MESSAGE_RECEIVED: {
      let incomingOb: any | undefined
      if (action.key === 'shadowAccepted') {
        const now = new Date()
        debug && console.log(`adding TSS to state at ${now.getMinutes()} ${now.getSeconds()} ${now.getMilliseconds()} from`, action.value)
        incomingOb = new ThingShadowState({ type: 'ThingShadowState', payload: { ...action.value }, id: new Date().valueOf().toString(), pk: action.value.thingSk });

      } else if (action.key === 'alertIssued') {
        incomingOb = Assembler.getInstance(action.value);
      }
      update.pinger = true;
      if (incomingOb && update.normalData && isValidBusinessObject(incomingOb)) {
        update.normalData[incomingOb.sk] = {};
        update.normalData[incomingOb.sk][incomingOb.pk] = incomingOb;
        debug && console.log(`Added ${incomingOb.type} to state`)
      } else {
        debug && console.log('get non VOB', incomingOb)
      }
      return update;
    }

    case ACTIONS.MQTT_NETWORK_MESSAGE_RECEIVED: {
      const objects = action.value;
      update.pinger = true;
      if (objects instanceof Array) {
        objects.forEach((object) => {
          if (update.normalData && isValidBusinessObject(object)) {
            update.normalData[object.sk] = {};
            update.normalData[object.sk][object.pk] = object;
            debug && console.log('Added to normal', object)
          } else {
            debug && console.log('Not valid', object)
          }
        })
      }
      return update;
    }

    case ACTIONS.CLEAR_MESSAGE_PING: {
      update.pinger = false;
      return update
    }

    case ACTIONS.RECEIVE_SAVE_TRANSACTION_RESULT: {
      if (action.value?.err) {
        update.messages = { ...state.messages }
        addErrorToUpdate(action.value.err, update);
      } else if ( action.value?.data  ) {

        console.log('Result', action.value)

        const newObject = action.value.data;
        addToNormalData(newObject, update.normalData)
        if (newObject.name) {
          const label = 'Save result', content = `${newObject.name} was saved successfully`, type = 'SnackBar'
          addMessageToUpdate(newObject, update, label, content, type)
        }
      } else {
        debug && console.log('System returned no save result, does the api datamodel contain this type?')
      }
      return update;
    }

    case ACTIONS.RECEIVE_GET_ADJACENT_RESULT: {
      const errData: GetAdjacentResponse = action.value;
      if (errData.err) {
        console.log('Error in GetAdjacent', errData.err)
      } else if (errData.data) {
        const typeCount: MapLike<number>= {};
        errData.data.forEach((item) => { 
          if ( !typeCount[item.type] ) {
            typeCount[item.type] = 1
          } else {
            typeCount[item.type] ++
          }
          addToNormalData(item, update.normalData);
        
        }) 
        debug && console.log('Added these types to state', typeCount)
      }
      debug && console.log('Updated Normal Data in RECEIVE_GET_ADJACENT_RESULT', update)
      return update;
    }

    case ACTIONS.REQUEST_ADD_OBJECTS_TO_STATE: {
      const vobs: ValidBusinessObjectList = action.value;
      if (vobs instanceof Array) {
        vobs.forEach((item) => addToNormalData(item, update.normalData))
      } else {
      }
    
      return update;
    }

    case ACTIONS.RECEIVE_GET_LARGE_OBJECT: {

      const errData: GetLargeDataResponze = action.value;
      if (errData.err) {
        console.log('Error in GetAdjacent', errData.err)
      } else if (errData.data) {
        debug && console.log('Received Large Object', errData.data)
        if (errData.data) {
          const largeObject = errData.data
          const largeObjectKey = largeObject.sk;
          update.largeObjects[largeObjectKey] = largeObject
        }
      }
      return update;
    }

    case ACTIONS.REQUEST_NEW_DRAFT_OBJECT: {
      if (action.newObject) {
        const edgeTypeId = 'someEdgeTypeId'

        const label = '', content = `You are drafting a new ${action.newObject.type}`, type = 'SnackBar'
        //addToNormalData(action.newObject, update.normalData)
        debug && console.log('No longer addubg draftObject to normal data')
        addMessageToUpdate(action.newObject, update, label, content, type);
        action.history.push(`/${NavSections.THINGVIEW}/${action.newObject.type}/${action.newObject.id}?contextObjectSK=${action.matchedPrimary.sk}&edgeTypeId=${edgeTypeId}&action=${UserTransactionType.CREATE_AND_LINK}`);
        return update;
      }

      return { ...state }
    }

    case ACTIONS.REQUEST_NEW_LIST_OBJECT: {
      if (action.newObject && action.newEdgeObject) {

        const label = '', content = `You are drafting a new ${action.newObject.type}`, type = 'SnackBar'
        addToNormalData(action.newObject, update.normalData)
        addToNormalData(action.newEdgeObject, update.normalData)
        addMessageToUpdate(action.newObject, update, label, content, type);
        return update;
      }

      return { ...state }
    }

    case ACTIONS.RECEIVE_SAVE_AND_LINK_TRANSACTION_RESULT: {
      if (action.value?.err) {
        update.messages = { ...state.messages }
        addErrorToUpdate(action.value.err, update);
      } else if (action.value?.data instanceof Array && isValidBusinessObject(action.value.data[1])) {
        const newObject = action.value.data[1]
        const label = 'Link result', content = `${action.value.data[1].name} was saved successfully`, type = 'SnackBar'
        addToNormalData(newObject, update.normalData)
        addMessageToUpdate(newObject, update, label, content, type)
      } else {
        console.log('Bad S&L result, action was', action)
        // throw new Error(
        //   'Problem in Link'
        // )
      }
      return update; //Object.assign( {}, state, update );
    }

    case ACTIONS.RECEIVE_DELETE_TRANSACTION_RESULT: {
      const response: DeleteObjectRespose = action.value;
      if (response.err) {
        update.messages = { ...state.messages }
        addErrorToUpdate(response.err, update);
      } else if (response.data && isValidBusinessObject(response.data.object)) {
        const oldObject = response.data.object;
        const label = 'Delate result', content = `${oldObject.name} was deleted successfully`, type = 'SnackBar';
        removeAllFromNormalData(oldObject, update.normalData);
        addMessageToUpdate(oldObject, update, label, content, type);
        const lastUrl = response.data.history?.location?.pathname;
        if (lastUrl?.includes(oldObject.id)) update.redirect = (`/${NavSections.THINGSVIEW}/${oldObject.type}`);
      } else {
        console.log('action was', action)
        throw new Error(
          'Problem in Delete'
        )
      }
      return update; //Object.assign( {}, state, update );
    }

    case ACTIONS.RECEIVE_UNLINK_OBJECTS_RESULT: {
      const response: UnlinkResponse = action.value;
      if (response.err) {
        update.messages = { ...state.messages }
        addErrorToUpdate(response.err, update);
      } else if (response.data && isValidBusinessObject(response.data.referenceItem) && isValidBusinessObject(response.data.removedItem)) {
        const { referenceItem, removedItem } = response.data;
        const label = 'Delate result', content = `${removedItem.name} was unlinked successfully from ${referenceItem.name}`, type = 'SnackBar';
        removeFromNormalData(removedItem, update.normalData);
        addMessageToUpdate(removedItem, update, label, content, type);
      } else {
        console.log('action was', action)
        throw new Error(
          'Problem in Delete'
        )
      }
      return update; //Object.assign( {}, state, update );
    }

    case ACTIONS.CANCEL_REDIRECT_AFTER_SUCCESS: {
      console.log('returning update with no redirect', update.redirect)
      return update;
    }

    case ACTIONS.GET_USER_GEOLOCATION: {
      return update;
    }

    case ACTIONS.RECEIVE_USER_GEOLOCATION: {
      update.userGeolocation = action.position as GeolocationCoordinates | undefined;
      return update;
    }

    case ACTIONS.ERROR: {
      console.log('Error action:', action)
      let update = { messages: { ...state.messages } };
      update.messages[action.value.id] = action.value
      //TODO General in app errors
      return Object.assign({}, state, update);
    }

    case ACTIONS.CHANGE_APP_STATE: {
      let update = action.value;
      //TODO General in app errors
      return Object.assign({}, state, update);
    }

    case ACTIONS.RECEIVE_CHANGE_USER_ROLE_RESULT: {
      const result = action.value as ErrData<RoleArbitrationInstanceType>;
      if (result.data) {
        update.roleArbitrationInstance = result.data;
        update.userSelectedGroup = result.data.groupName
        AppLocalStorage.set(NavSections.HOME, 'lastUserSelectedGroupName', update.userSelectedGroup)
      }
      //TODO General in app errors
      return Object.assign({}, state, update);
    }

    case ACTIONS.RECEIVE_LOGOUT: {
      initialState.normalData = {};
      initialState.largeObjects = {}
      initialState.userSelectedGroup = undefined
      debug && console.log('reloading initial state', initialState)

      return initialState
    }

    default: {
      //console.log('Reached Default with action:', action.type)
      return { ...state };
    }
  }
};
export default AppReducer;
