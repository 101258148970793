import { HistoryStore } from '@iotv/datamodel-core';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { getColour } from '../../CommunitiesView';

const debug = process.env.REACT_APP_DEBUG && false;

export const gridDefintion: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Community Name',
    flex: 1,
    minWidth: 220,
    headerAlign: 'center',
    align: 'center',
    type: 'string',
  },
  {
    field: 'numTankUsers',
    minWidth: 30,
    headerName: 'Users',
    // flex: 1,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderCell: (params: GridCellParams) => {
      const numTankUsers = params.row.numTankUsers;

      return <>{numTankUsers}</>;
    },
  },
  {
    field: 'numTanks',
    minWidth: 30,
    headerName: 'Tanks',
    // flex: 1,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderCell: (params: GridCellParams) => {
      return <>{params.row.numTanks}</>;
    },
  },
  {
    field: 'communityFillLevel',
    headerName: 'Community Fill Level',
    // flex: 1,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderCell: (params: GridCellParams) => {
      const fillPercentage = Math.round(params.row.current_filled_factor * 100);
      let colour = getColour(fillPercentage);

      return (
        <div style={{ backgroundColor: colour, width: '95%' }}>
          {typeof fillPercentage === 'number' && !isNaN(fillPercentage) ? `${fillPercentage}%` : 'Calculating...'}
        </div>
      );
    },
  },
  {
    field: 'runOutDate',
    headerName: 'Run Out Date',
    minWidth: 180,
    // flex: 1,
    headerAlign: 'center',
    align: 'center',
    type: 'date',
    renderCell: (params: GridCellParams) => {
      const community_days_left = params.row.community_days_left;

      const currentDate = new Date();
      const runOutDate = new Date(currentDate);
      runOutDate.setDate(runOutDate.getDate() + community_days_left);

      const altRunOutDate = new Date(currentDate);
      altRunOutDate.setDate(altRunOutDate.getDate() + 100);
      if (Math.round(params.row.current_filled_factor * 100) === 0) {
        return <>{currentDate.toLocaleDateString()}</>;
      }
      return <>{!isNaN(community_days_left) && community_days_left <= 100 ? runOutDate.toLocaleDateString() : `After ${altRunOutDate.toLocaleDateString()}`}</>;
    },
  },
  {
    field: 'daysLeft',
    headerName: 'Days Left',
    // flex: 1,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    minWidth: 140,
    renderCell: (params: GridCellParams) => {
      let daysLeft = params.row.community_days_left;
      let colour = '';

      if (daysLeft > 60) {
        colour = '#99ff99';
      } else if (daysLeft > 20 && daysLeft <= 60) {
        colour = '#ffff66';
      } else if (daysLeft > 7 && daysLeft <= 14) {
        colour = '#ffcc99';
      } else {
        colour = '#ff8080';
      }
      if (Math.round(params.row.current_filled_factor * 100) === 0) {
        return <div style={{ backgroundColor: '#ff8080', width: '95%' }}>0 Days</div>;
      }
      return (
        <div style={{ backgroundColor: colour, width: '95%' }}>
          {!isNaN(daysLeft) ? (daysLeft <= 100 ? `${daysLeft} Days` : `>100 Days`) : 'Calculating...'}
        </div>
      );
    },
  },
  {
    field: 'communityUsageYesterday',
    headerName: 'Community Usage Yesterday',
    // flex: 1,
    minWidth: 250,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderCell: (params: GridCellParams) => {
      const volumeUsedYesterday = params.row.volume_used_yesterday;

      return <>{!isNaN(volumeUsedYesterday) ? `${Math.round(volumeUsedYesterday)} L` : '-'}</>;
    },
  },
  {
    field: 'avgCommunityDailyUsage',
    headerName: 'Average Community Daily Usage (last 14 days)',
    // flex: 1,
    minWidth: 370,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderCell: (params: GridCellParams) => {
      // let message = ''
      // if (params.row.history) {
      //     if(params.row.history?.dailyStateHistory) {
      //         if(params.row.history?.dailyStateHistory?.community_usage_in_day){

      //             const communityDailyUsageHistory = params.row.history.dailyStateHistory.community_usage_in_day

      //             let averageUsage = 0

      //             if (communityDailyUsageHistory.length >= 14) {
      //                 let pastTwoWeeks = communityDailyUsageHistory.slice(-13)
      //                 averageUsage = pastTwoWeeks.reduce((acc : number, curr : number) => {
      //                     return acc += curr
      //                 }, 0) / 14
      //             } else {
      //                 averageUsage = communityDailyUsageHistory.reduce((acc : number, curr : number) => {
      //                     return acc += curr
      //                 }, 0) / communityDailyUsageHistory.length
      //             }

      //             message = `${Math.round(averageUsage)} L`
      //         } else {
      //             message = debug ? 'No Community Usage In Day!': 'Calculating...'
      //         }
      //     } else {
      //         message = debug ? 'No Daily State History!': 'Calculating...'
      //     }

      // } else {
      //     message = debug ? 'No Object History!': 'Calculating...'
      // }

      const averageUsageLastTwoWeeks = params.row.average_usage_last_14_days;

      return <>{!isNaN(averageUsageLastTwoWeeks) ? `${Math.round(averageUsageLastTwoWeeks)} L` : 'Calculating...'}</>;
    },
  },
];
