import { MapLike, ValidBusinessObject, ValidBusinessObjectList, ModelTypes, isValidBusinessObject, isValidModelTypeId } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Chip, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import Assembler from '../../data/Assembler';
import { getPseudoVBO } from '../../data/TypeHelpers';
import { AdjacentType, ListerlizerProps, ObjectCardProps, PartialAdminCreateUserRequest, UserTransactionType, ViewDefinition, ViewObject } from '../../types/AppTypes';
import { Listerlizer } from '../factories/Listilizer';
import { ImportObjectsFromGSheet } from './ImportFromGSheet';

const debug = process.env.REACT_APP_DEBUG && false;

type ValidNewUserType = PartialAdminCreateUserRequest & ValidBusinessObject

export type GImportProps = Omit<ObjectCardProps, 'viewObject'> & { match: { params: { action?: string, id?: string } }
, importObjectTypeId: string
, importFn: ( vbos: ValidBusinessObjectList ) => void
}

const componentClassName = 'GImport';
const secondaryRenderedMarker = 'GImportListerlizerCard'

const isValidImportedType = ( something: MapLike<any>, clazz: ModelTypes): something is ValidNewUserType => {
    // const classInstance = new clazz({});
    // console.log('classInstance', classInstance)
    //return isValidBusinessObject(something);
    return something !== undefined
}

export const GImport = ( props:  GImportProps  ) => {

    const [ newVBOs, setNewVBOs] = React.useState<ValidNewUserType[]>([]);
    const [ importViewDefinition, setImportViewDefinition ] = React.useState<ViewDefinition | undefined >(undefined) 
    const [hasValidationErrors, setHasValidationErrors] = React.useState(true);
    const [ error, setError ] = React.useState<string | undefined>( undefined );

    const importViewDefinitionHash = importViewDefinition ? Object.keys(importViewDefinition).join(':') : 'nothing'

    useEffect( () => {
        debug && console.log('GImport rerender', { importViewDefinitionHash, newVBOs })
    }, [ newVBOs.length, error, importViewDefinitionHash])

    const setVBOsInParent = ( vbos: ValidBusinessObjectList ) => {
      if (parentHasRendered()) {
        const valid: ValidNewUserType[] = [];
        const invalid: ValidBusinessObjectList = [];
        vbos.forEach( (vbo) => {
          const clazz = Assembler.getClass(vbo);
            if (clazz && isValidImportedType(vbo, clazz )) {
                valid.push(vbo)
            } else {
              invalid.push(vbo)
            } 
        } )
        if (invalid.length > 0) {
            setError( `Errors in ${invalid.map( (ibo) => ibo.name ?? ibo.id).join(', ')}`)
            console.log(`Errors in ${invalid.map( (ibo) => ibo.name ?? ibo.id).join(', ')}`)
        }
        setNewVBOs(valid)
      }
      
    }

    const parentHasRendered = () => {
      const rendered1 = document.querySelector(`.${componentClassName}`) !== undefined;
      debug && console.log('GImport has rendered', rendered1);
      const rendered2 = document.querySelector(`.${secondaryRenderedMarker}`) !== undefined;
      debug && console.log(' List has rendered', rendered2);
      return rendered1 && rendered2;
    }

    const setImportDefinitionInParent = ( viewDefinition: ViewDefinition) => {
      if ( parentHasRendered() === true && ( !(Object.keys(viewDefinition).every( (key) => Object.keys(importViewDefinition ?? {}).includes(key)))))  {
        setImportViewDefinition(viewDefinition);
        debug && console.log('Set view deinition for import to ',viewDefinition)
      } else {
        debug && console.log('Did not set view definition in parent', { importViewDefinition, viewDefinition})
      }
    }

    const { transactionFunctions, userFunctions, history, match: { params: { action } }, importObjectTypeId,  importFn} = props;

    const proxyParent = getPseudoVBO({ type: "Pertemperter", id: '123'})
    const viewObject: ViewObject = {
      matchedPrimary: proxyParent,
      matchedRelatedByPk: [],
      matchedRelatedBySk: newVBOs
    }
  

   

    const itemListProps: ListerlizerProps | undefined = isValidModelTypeId(importObjectTypeId) && importViewDefinition ? {
        transactionFunctions, userFunctions, history,
        adjacentFilter: {
          adjacentType: AdjacentType.CHILD,
          objectType: importObjectTypeId,
          edgeFilter: undefined
        },
        maxItems: 5,
        sortKeyIn: 'name',
        selectorConfigParams: {
          enabled: false,
          multipleSelect: false,
          controls: {
            add: false,
            find: false,
          }
        },
        viewDefinition: importViewDefinition,
        viewObject,
        contextObject: undefined,
        injectedComponents: [
          <ButtonGroup>
            <Button { ...{ variant: 'outlined', color: 'primary', onClick: () => setVBOsInParent([]) } }>Reset</Button>
            { newVBOs.length > 0 && <Button { ...{ variant: 'outlined', color: 'primary', onClick: () => { importFn( newVBOs)  } } }>Import</Button> }
          </ButtonGroup>
        
        ]
      } : undefined
    

    return  <Grid container { ...{ className: componentClassName }}>
        <Grid item xs = {12} >
        {error && <Card>
                <CardContent>
                    <Chip
                        label={error}
                        onDelete={() => { setError(undefined); }}
                        color="secondary"
                    ></Chip>
                </CardContent>

            </Card>}
        </Grid>
        <Grid item ><div>{`GImport ${newVBOs.length}`}</div></Grid>
        
        { parentHasRendered() && newVBOs.length === 0 && <Grid item xs = {12} >
            <ImportObjectsFromGSheet {...{...props, setVBOsInParent, setImportDefinitionInParent, parentHasRendered }}></ImportObjectsFromGSheet>
        </Grid>
        }
        { itemListProps && newVBOs.length > 0  && 
          <Grid item xs={12} >
          <Card>
            <CardContent { ...{className: secondaryRenderedMarker }}>
              <Typography variant="h4"  gutterBottom>
                Validated Import Objects
              </Typography>
              <Listerlizer key={'itemList'} {...{ ...itemListProps }}></Listerlizer>

            </CardContent>
          </Card>
          </Grid>
        }
        
        
    </Grid>
}