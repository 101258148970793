import React, { Component } from 'react';
import { Paper } from '@material-ui/core';

const debug = process.env.REACT_APP_DEBUG && false;

class TestingComponent extends Component {

    render() {
		debug && console.log('rendering Testing Component')
        return <Paper> 
            <div>
                TESTING VIEW
            </div>
        </Paper>
    }
}

export default TestingComponent;