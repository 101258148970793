import { Geofence, GeofencePointsType, GeofenceType, Position, ValidBusinessObject } from '@iotv/datamodel';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid/';
import config from '../../config';
import { getNiceDate } from '../../data/TypeHelpers';
import { GoogleMapProps, MapLikeT, PositionType, ViewObject } from '../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../util/geo/GeoDataHelpers';
import { getBounds } from './v2/mapfns/translation';
import { isPosition, matchObjectKeys } from '@iotv/iotv-v3-types';

const debug = process.env.REACT_APP_DEBUG && false;
const featureDebug = process.env.REACT_APP_DEBUG && false;
type EditPoint = { marker: google.maps.Marker, infoWindow: google.maps.InfoWindow }
type EditPointGeofence = {
  id: string,
  points: EditPoint[],
  name?: string,
  polygon?: google.maps.Polygon
}

type State = {
  items: any[];
  editPoints: EditPoint[];
  geofences: MapLikeT<EditPointGeofence>
  kmlUrl: string | undefined,
  layerStatus: string | undefined
};

const mapStateToProps = (state: State) => {
  debug && console.log('state', state)
  // const { bollocks } = state.RootReducer;
  return {}
}

class GoogleMap extends Component<GoogleMapProps, State>  {
  constructor(props: GoogleMapProps) {
    super(props);
    this.geofences = props.geofences;
    this.maxEditableGeofences = this.props.maxEditableGeofences ?? 1
  }


  state: State = {
    items: [],
    editPoints: [],
    geofences: {},
    kmlUrl: undefined,
    layerStatus: undefined
  }

  googleMapRef: string | React.RefObject<HTMLDivElement> = React.createRef()
  googleMapsUrl = config.google.apiUri;
  geocoder = new google.maps.Geocoder();
  map: google.maps.Map | undefined = undefined;
  markers: { [k: string]: google.maps.Marker } = {};
  polylines: { [k: string]: google.maps.Polyline } = {};
  infoWindows: { [k: string]: google.maps.InfoWindow } = {};
  geofences: MapLikeT<Geofence> | undefined = {}
  // For Bounds = must be cleared if updating
  points: GeofencePointsType[] = [];
  mapViewObjects: ViewObject[] = [];
  maxEditableGeofences: number;
  clickTimeout: NodeJS.Timeout | undefined = undefined;



  addMapsScript() {
    let googleMapScript = undefined;
    if (!document.querySelectorAll(`[src="${this.googleMapsUrl}"]`).length) {
      googleMapScript = Object.assign(
        document.createElement('script'), {
        type: 'text/javascript',
        src: this.googleMapsUrl,
        onload: () =>  {
          debug && console.log('Map was loaded by Google Map')
          this.doMapInitLogic()
        }
      })
      document.body.appendChild(googleMapScript);
    } else {
      googleMapScript = document.querySelector(`[src="${this.googleMapsUrl}"]`)
      this.doMapInitLogic()
      if (!{ googleMapScript }) console.log('GMap no script')
    }
    return googleMapScript;
  }

  componentDidMount() {
    debug && console.log('GMap did mount, editable is', this.props.editable)
    this.addMapsScript();
    this.adorn();

  }



  componentDidUpdate(prevProps: GoogleMapProps, prevState: State) {
    featureDebug && console.log('GMap did update', { prevState, state: this.state })
    this.update();
    this.disableGeofenceCreationIfAtLimit();
    // update on auto populated children
    const firstCurrentViewObject = this.props.viewObjects ? this.props.viewObjects[0] : undefined;
    const firstPreviousViewObject = prevProps.viewObjects ? prevProps.viewObjects[0] : undefined;
    const lastNumberOfChildren = firstPreviousViewObject ? firstPreviousViewObject.matchedRelatedBySk?.length ?? 0 : 0
    const currentNumberOfChildren = firstCurrentViewObject ? firstCurrentViewObject.matchedRelatedBySk?.length ?? 0 : 0
    debug && console.log('childre', { currentNumberOfChildren, lastNumberOfChildren })
    if (lastNumberOfChildren < currentNumberOfChildren) {
      debug && console.log('updating on more children')
      this.updatePositionFromViewObject();
      this.props.optionals?.zoom ?? this.fitBounds()
    }
   
    
  }

  componentWillUnmount() {
  }

  render() {
    //if (this.props.position) this.centerMap(this.props.position ? this.props.position : this.props.position);
    // debug && console.log('render has props polygons', this.props.polygons)
    // debug && console.log('render has props geofence', this.props.geofences)
    debug && console.log('render has props polylines', this.props.polylines)
    // debug && console.log('render has props viewObjects', this.props.viewObjects)
    return (
      <div
        id="google-map"
        ref={this.googleMapRef}
        style={{ width: '100%', height: '100%' }}
      >
      </div>
    )
  }

  doMapInitLogic() {
    this.map = this.createGoogleMap();

    if (this.props.editable) {
      this.map.addListener("click", (e: google.maps.MapMouseEvent) => {
        this.addEditPoint(e)
      });
      this.map.addListener("dblclick", (e) => {
        e.stop()
        debug && console.log('Map double click')
      });
    }
  }

  disableGeofenceCreationIfAtLimit = () => {
    if (Object.keys(this.state.geofences).length >= this.maxEditableGeofences) {
      debug && console.log('Disabling map click, at limit of geofences')
      this.map && google.maps.event.clearListeners(this.map, 'click')
    }
  }

  centerMap = (position: { lat: number, lng: number }) => {
    debug && console.log('Centering on ', position)
    if (this.map) this.map.setCenter(position);
    else debug && console.log('did not center', this.map)
  }

  addLocatableAddress() {
    if (this.props.locatableAddress) {
      const map = this.map;
      map && this.geocoder.geocode({ 'address': this.props.locatableAddress }, function (results, status) {
        if (status === 'OK') {
          map.setCenter(results[0].geometry.location);
        } else {
          debug && console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  }

  update() {
    const updatePoints = false
    this.updatePolyLines(updatePoints);
    this.updatePositionFromViewObject();
    this.props.editable && this.updateGeofences(this.geofences)
  }

  adorn() {
    const updatePoints = true
    this.points = [];
    this.mapViewObjects = [];


    this.props.editable && this.updateGeofences(this.geofences)
    this.updatePolyLines(updatePoints)
    this.updatePolygons(true)
    this.addLocatableAddress()
    debug && console.log('adorning Map with props', this.props)
    this.updatePositionFromViewObject()
    if (!this.props.optionals?.zoom) {
      debug && console.log('fitting bounds because zoom was', this.props.optionals?.zoom)
      this.fitBounds()
    }


  }




  updateGeofences(geofences: MapLikeT<Geofence> | undefined) {
    if (geofences) {
      Object.entries(geofences).forEach(([k, geofence]) => {
        const polygon = geofence.id && this.state.geofences[geofence.id] && this.state.geofences[geofence.id]?.polygon !== undefined ? this.state.geofences[geofence.id].polygon as google.maps.Polygon
          : this.addPolygon(geofence.points, k)
        polygon.addListener('dblclick', () => {
          debug && console.log('on move this in polygon dblclick was', polygon)
          const editPointGeofence: EditPointGeofence = this.geofenceToEditPointGeofence(geofence as Geofence & ValidBusinessObject, polygon)
          this.setMapState({ geofences: { ...this.state.geofences, [editPointGeofence.id]: editPointGeofence } })
          Object.entries(editPointGeofence.points).forEach(([_k, editPoint]) => this.addEditPointToMap(editPoint))
        })
        geofence.points.forEach((point: GeofencePointsType) => this.points.push(point))
      })
    }
  }

  geofenceToEditPointGeofence = (geofence: ValidBusinessObject & Geofence, polygon: google.maps.Polygon): EditPointGeofence => {
    const { id, name, points } = geofence
    return {
      id,
      name,
      polygon,
      points: points.map(({ lat, lng }: GeofencePointsType, i) => {
        const markerOptions: google.maps.MarkerOptions = {
          position: new google.maps.LatLng(lat, lng)
        }
        const infoWindowOptions: google.maps.InfoWindowOptions = {
          content: this.getInfoWindowContnet(i.toString())
        }
        return {
          marker: new google.maps.Marker(markerOptions),
          infoWindow: new google.maps.InfoWindow(infoWindowOptions),
        }
      })
    }
  }

  updatePolyLines = (updatePoints: boolean) => {
    const draggable = false;
    if (this.props.polylines) {
      Object.entries(this.props.polylines).forEach(([k, polyline], i) => {
        this.addPolyLine(polyline, i, draggable);
        updatePoints && polyline.forEach((point) => this.points.push(point))
      })

    }
  }

  updatePolygons = (updatePoints: boolean) => {
    if (this.props.polygons) {
      Object.entries(this.props.polygons).forEach(([k, polygon], i) => {
        this.addPolygon(polygon, i);
        updatePoints && polygon?.forEach((point) => this.points.push(point))
      })
    }
  }

  updatePositionFromViewObject = () => {
    const viewObjects = this.props.viewObjects;
    debug && console.log('updating from viewObjects', viewObjects)
    viewObjects?.forEach((viewObject) => {
      const { mapViewObject,  position: positionFromCollection } = extractGeoDataFromViewObject(viewObject); // extractGeoDataFromViewObject will have been called by parent component possibly
      if (positionFromCollection) {
        const niceTime = positionFromCollection.timestamp ? getNiceDate(positionFromCollection.timestamp) : undefined
        debug && console.log('Using position from collection ', positionFromCollection)
        const thingKey = mapViewObject.matchedPrimary?.sk || 'unknown';
        const title = mapViewObject.matchedPrimary?.name || 'unknown';
        const htmlContent = `${viewObject.matchedPrimary?.name || '?' }, ${niceTime} `
        this.addMarker(thingKey, positionFromCollection, title, htmlContent);
        this.fitBounds()
      }
    })
    if (viewObjects && viewObjects.length === 1 && this.props.position) {
      debug && console.log('Using position from props ', this.props.position)
      const thing = viewObjects[0].matchedPrimary
      const thingKey = thing?.sk || 'unknown';
      const title = thing?.name || 'unknown';
      if ( thing ) {
        const location: Position | undefined = thing.coordinates ?? thing.location ?? thing.position
        if (location && isPosition(location)) {
          const latLng = location;
          if ( !this.points.find( (point) => matchObjectKeys(latLng, point))) {
            this.points.push(latLng)
            debug && console.log('pushed', latLng)
            this.addMarker(thing.sk, latLng, thing.name, thing.name || '?');
          }



        }
      
      }
  
      this.fitBounds()
    } else if (viewObjects) {
      const initialPoints = this.points.length
      debug && console.log('Using position from some or all of the view Objects ', )
      viewObjects.forEach((viewObject) => {
        viewObject.matchedRelatedBySk?.forEach((child) => {
          const location: Position | undefined = child.coordinates ?? child.location ?? child.position
          if (location && isPosition(location)) {
            const latLng = location;
            if ( !this.points.find( (point) => matchObjectKeys(latLng, point))) {
              this.points.push(latLng)
              debug && console.log('pushed', latLng)
              this.addMarker(child.sk, latLng, child.name, child.name || '?');
            }



          }
        })
      })
      if ( initialPoints !== this.points.length ) {
        this.fitBounds()
      }
    }
  }

  extendBoundsForPoint = (bounds: google.maps.LatLngBounds, point: GeofencePointsType, meters: number) => {
    const gPoint: google.maps.LatLng = new google.maps.LatLng(point);
    var west = google.maps.geometry.spherical.computeOffset(gPoint, meters, 270);
    var east = google.maps.geometry.spherical.computeOffset(gPoint, meters, 90);
    bounds.extend(west);
    bounds.extend(east);
  }

  fitBounds = () => {
    if (this.points.length > 0) { // poistion and current point from polyline
      this.map?.fitBounds(getBounds(this.points.map( (point) => new google.maps.LatLng(point))));
    } else if (this.props.position) {
      this.map?.setCenter(this.props.position);
      //this.addMarker('defaultPosition', this.props.position, 'Something here', '<h1>Bang</h1>')
    }
  }



  getCurrentLocation = () => {
    let currentLoc = undefined
    // if (!navigator.geolocation) {
    // debug && console.log('Geolocation is not supported by your browser');
    // } else {
    //   currentLoc = navigator.geolocation.getCurrentPosition((data) => console.log((data)), (err) => console.log(err));
    // }
    // debug && console.log('CurrentLoc', currentLoc)
    return currentLoc;
  }

  createGoogleMap = () => {
    const current = (this.googleMapRef as React.RefObject<HTMLDivElement>).current
    const target = current as Element;
    const center = this.props.position || this.props.position || this.getCurrentLocation() || config.app.geo.initialCenter
    const zoom = this.props.optionals?.zoom ?? (this.props.position ? 12 : 6);
    let result = new google.maps.Map(target, {
      zoom,
      center,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDoubleClickZoom: true,
    })
    return result;
  }

  addPolygon(points: GeofencePointsType[], key: string | number) {
    debug && console.log('adding polygon', points)
    const polygon = new google.maps.Polygon({
      paths: points,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#000000",
      fillOpacity: 0.35
    });
    this.map && polygon.setMap(this.map)
    return polygon;
  }

  addPolyLine = (path: GeofencePointsType[], key: string | number, draggable: boolean) => {
    const polyline = this.polylines[key] ||  new google.maps.Polyline();
    polyline.setOptions({
      path: path,
      editable: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    polyline.setDraggable(draggable)
    polyline.setEditable(draggable)

    if ( !this.polylines[key] ){
      debug && console.log('Adding polyline', polyline)
      this.polylines[key] = polyline;
      this.map && polyline.setMap(this.map)
      const lastPoint = path[0];
      if ( lastPoint?.timestamp ) {
        const title = getNiceDate(lastPoint.timestamp)
        this.addMarker(lastPoint?.id ?? `lastPointMarker_${Math.round(Math.random() * 1000)}`, lastPoint, title, title);
      }
    }

   
  }

  removeMarkers = () => {
    Object.values(this.markers).forEach((marker) => marker.setMap(null));
    this.markers = {};
  }

  addMarker = (thingKey: string, position: PositionType, title: string, contentHTML: string) => {
    debug && console.log('Adding marker', { thingKey, position, title, contentHTML })
    const marker = this.markers[thingKey] || new google.maps.Marker();
    const options: google.maps.ReadonlyMarkerOptions = {
      title,
      position,
      map: this.map,
    }
    marker.setOptions(options);
    const infoWindowOptions: google.maps.InfoWindowOptions = {
      content: contentHTML
    }
    var infoWindow = this.infoWindows[thingKey] || new google.maps.InfoWindow(infoWindowOptions);
    this.markers[thingKey] = marker;
    this.infoWindows[thingKey] = infoWindow;
    debug && console.log('This Geo things', { markers: this.markers, infoWindows: this.infoWindows })
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
    });
    marker.addListener('dblclick', () => {
      debug && console.log('market doubleclick')
    });
    if (true) {
      marker.addListener('dragend', (e: google.maps.MapMouseEvent) => {
        e.stop()
        debug && console.log('market drag end')
        this.moveEditPoint(marker, e)
      });
    }
    //if we need to remove listeners on destroy : google.maps.event.clearInstanceListeners(marker)
    infoWindow.open(this.map, marker);
  }

  addEditPointToMap = (editPoint: EditPoint) => {
    debug && console.log('Adding editPoint', editPoint)
    const options: google.maps.ReadonlyMarkerOptions = {
      draggable: true,
      map: this.map,
    }
    const marker = editPoint.marker;
    marker.setOptions(options);

    marker.addListener('dblclick', () => {
      this.clickTimeout && clearTimeout(this.clickTimeout);
      this.convertEditPointsToPolygon(this.state.editPoints)
      debug && console.log('edit point doubleclick')
    });
    if (true) {
      marker.addListener('dragstart', (e: google.maps.MapMouseEvent) => {
        e.stop()
        debug && console.log('edit point drag end')
        this.moveEditPoint(marker, e)
      });
      marker.addListener('dragend', (e: google.maps.MapMouseEvent) => {
        e.stop()
        debug && console.log('edit point drag end')
        this.moveEditPoint(marker, e)
      });
      marker.addListener('click', (e: google.maps.MapMouseEvent ) => {
        this.clickTimeout = setTimeout(() => {
          debug && console.log('click timeout go');
          this.deleteEditPoint(marker, e)
        }, 500)
      });
      this.map && this.map.addListener('dblclick', (e) => {
      });
    }
    //if we need to remove listeners on destroy : google.maps.event.clearInstanceListeners(marker)
    editPoint.infoWindow.open(this.map, marker);
  }

  setMapState(ob: { [k in keyof State]?: State[k] }) {
    this.setState((state) => { console.log('Setting state with ', ob); return { ...state, ...ob } })
  }

  callSetEditedGeofences = () => {
    debug && console.log('callSetEditedGeofences on parent', this.state.geofences)
    if (this.state.geofences) {
      const geofenceBOs: GeofenceType[] = Object.values(this.state.geofences).map((geofence) => this.editPointGeofenceToGeofence(geofence));
      this.props.parentCardFns?.setEditedGeofences(geofenceBOs)
    }
  }
  editMode = true;
  editPoints: (GeofencePointsType & { markerTitle?: string })[] = []
  getMarkerTitle = (index: number) => index.toString()
  getInfoWindowContnet = (content: string) => `<div>${content}</div`
  reTitleMarkers = (geofence?: EditPointGeofence) => {
    const editPoints = [...this.state.editPoints];
    if (! (geofence)) {
      editPoints.forEach((editPoint, i) => {
        editPoint.marker.setTitle(this.getMarkerTitle(i));
        editPoint.infoWindow.setContent(editPoint.marker.getTitle() ?? '')
      })
    } else {
    }
  }

  addEditPoint = (e: google.maps.MapMouseEvent) => {
    if (this.editMode) {
      const latLng = e.latLng;
      const lat = latLng.lat();
      const lng = latLng.lng();
      debug && console.log('lat lng', { lat, lng });
      const markerAlpha = this.getMarkerTitle(this.state.editPoints.length);
      const marker = new google.maps.Marker({
        position: { lat, lng },
        title: markerAlpha
      });
      const infoWindowOptions: google.maps.InfoWindowOptions = {
        content: this.getInfoWindowContnet(marker.getTitle() ?? '')
      }
      var infoWindow = new google.maps.InfoWindow(infoWindowOptions);
      const editPoint: EditPoint = { marker, infoWindow };
      this.addEditPointToMap(editPoint);
      const editPoints = [...this.state.editPoints]
      editPoints.push(editPoint)
      this.setMapState({ editPoints })
      this.callSetEditedGeofences()
    } else {
      debug && console.log('not in edit mode');
    }
    debug && console.log('Edit points', this.editPoints);
    debug && console.log('State', this.state)
  };

  findEditPointIndex = (marker: google.maps.Marker) => this.state.editPoints.findIndex((point) => (point.marker === marker))
  findGeofenceForMarker = (marker: google.maps.Marker) => Object.entries(this.state.geofences).find(([_k, geofence]) => (geofence.points.find((editPoint) => editPoint.marker === marker)))

  moveEditPoint = (marker: google.maps.Marker, e: google.maps.MapMouseEvent) => {
    debug && console.log('Move Edit point')
    const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    const pointIndex = this.findEditPointIndex(marker);
    if (pointIndex !== -1) {
      const editPoints = [...this.state.editPoints]
      editPoints[pointIndex] = { ...editPoints[pointIndex], ...position }
      this.setMapState({ editPoints })
      debug && console.log('set state from moved in-edit points')
    }
    const geofenceEntry = this.findGeofenceForMarker(marker);
    debug && console.log('Geofence Enry on move', geofenceEntry)
    if (geofenceEntry) {
      const [k, geofence] = [...geofenceEntry];
      debug && console.log('set state from moved geofence points', this.compareGeofenceMarkers(geofence, this.state.geofences[k]))
      this.updatePolygonFromGeofence(geofence)
      //this.setMapState( { geofences: { ...this.state.geofences, [k]: geofence }})
      this.callSetEditedGeofences()
    }
  }

  updatePolygonFromGeofence = (geofence: EditPointGeofence) => {
    debug && console.log('updating polygon for', geofence)
    geofence.polygon?.setPath(geofence.points.filter((editPoint) => editPoint.marker.getPosition()).map((editPoint) => editPoint.marker.getPosition()) as google.maps.LatLng[])
  }

  convertEditPointsToPolygon = (points: EditPoint[]) => {
    debug && console.log('Convert to polygon')
    const polygonOptions: google.maps.PolygonOptions = {
      strokeColor: "#00FFFF",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00FFFF",
      fillOpacity: 0.01,
    }
    const polygon: google.maps.Polygon = new google.maps.Polygon(polygonOptions);
    const id = uuidv4();
    const newGeofence: EditPointGeofence = {
      points,
      id,
      name: 'Geofence ' + this.getMarkerTitle(Object.values(this.state.geofences).length),
      polygon
    }

    polygon.setPath(this.state.editPoints.filter((editPoint) => editPoint.marker.getPosition()).map((editPoint) => editPoint.marker.getPosition()) as google.maps.LatLng[])
    polygon.addListener('click', (e: google.maps.MapMouseEvent) => {
      debug && console.log('Polygon click')
    })


    this.map && polygon.setMap(this.map)
    this.setMapState(
      {
        geofences: {
          ...this.state.geofences,
          [newGeofence.id]: newGeofence
        },
        editPoints: []
      }
    )
    return polygon;

  }
  /*
    definedPolygonClick = ( e: google.maps.MouseEvent ) => {
      debug && console.log('definedPolygonClick', {...e})
      const latLng = {lat: e.latLng.lat(), lng: e.latLng.lng()};
      debug && console.log('Click LatLng', latLng);
  
      const distanceToPolygonLines = getDistanceToPolygonLines(this.state.editPoints, latLng).sort((path1, path2) => path1.distance < path2.distance ? -1 : 1);
      debug && console.log('distanceToPolygonLines', distanceToPolygonLines);
      const bestMatch = distanceToPolygonLines[0];
      let pointsClone = [...this.editPoints];
      pointsClone.splice(bestMatch.sector + 1, 0, latLng);
      this.editPoints = pointsClone;
    }
    */

  deleteEditPoint = (marker: google.maps.Marker, e: google.maps.MapMouseEvent) => {
    const pointIndex = this.findEditPointIndex(marker);
    const editPoints = [...this.state.editPoints];
    let geofences = { ...this.state.geofences };
    debug && console.log('deleteEditPoint', { pointIndex, title: marker.getTitle(), editPoints })
    if (pointIndex !== -1) {
      const deletedEditPoints = editPoints.splice(pointIndex, 1);
      deletedEditPoints.forEach((editPoint) => {
        editPoint.marker.setMap(null);
      })
      this.reTitleMarkers();
    }

    const geofenceEntry = this.findGeofenceForMarker(marker);
    debug && console.log('Geofence Enry on delete', geofenceEntry)

    if (geofenceEntry) {
      const [k, geofence] = [...geofenceEntry];
      const markerIndex = geofence.points.findIndex((editPoint) => editPoint.marker === marker)
      const deletedEditPoints = geofence.points.splice(markerIndex, 1)
      deletedEditPoints[0].marker.setMap(null);
      geofences = { ...geofences, [k]: geofence }

      this.updatePolygonFromGeofence(geofence)
    }
    this.setMapState({ editPoints, geofences });
  }

  /*
  handleApply = ( e: google.maps.MouseEvent ) => {
    debug && console.log('handle apply got ', e);
    if (this.editPoints[0] !== this.editPoints[this.editPoints.length - 1]) this.editPoints.push(this.editPoints[0]);
    const value = this.editPoints;
    // const key = getParentKey(accessKey) + levelSeparator + 'points';
    // const callback = (d, e) => console.log('Apply got result', { d, e});
    // functions.stateUpdateFn(key, value, callback);
  };
  */

  applyDisabled = (originalPoints: GeofencePointsType[], points: GeofencePointsType[]) => {
    debug && console.log('Geofence and local points', { originalPoints, points });
    debug && console.log('equal', { originalPoints, points });
    const shouldBeDisabled =
      points.length < 3
      ||
      !((originalPoints === points));
    debug && console.log('should be disabled', shouldBeDisabled);
    return !shouldBeDisabled;
  };

  editPointGeofenceToGeofence = (editPointGeofence: EditPointGeofence): GeofenceType => {
    const { id, name, points } = editPointGeofence
    const type = 'Geofence';
    const sk = `${type}:${id}`;
    const pk = sk;
    return {
      sk, pk, type, id, name,
      points: points.map((editPoint: EditPoint) => {
        const position = editPoint.marker.getPosition();
        return { lat: position?.lat(), lng: position?.lng() } as GeofencePointsType
      }).filter((point) => (point && point?.lat && point?.lng)) as GeofencePointsType[]
    }
  }

  compareGeofenceMarkers = (geofence1: EditPointGeofence, geofence2: EditPointGeofence) => {
    const same = geofence1.points.every((point, i) => {
      const correspondingPoint = geofence2?.points[i];
      debug && console.log({
        original: point.marker.getPosition()?.lat(), new: correspondingPoint?.marker.getPosition()?.lat()
      })
      return (point.marker?.getPosition()?.lat() === correspondingPoint?.marker?.getPosition()?.lat()) && (point.marker.getPosition()?.lng() === correspondingPoint?.marker.getPosition()?.lng())
    })
    return same
  }
}

export default connect(
  mapStateToProps
)(GoogleMap);
