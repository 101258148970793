import { TextField } from "@material-ui/core"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import React from "react"
import { DEBUG_OVERRIDE, NOT_LAMBDA } from "@iotv/datamodel-core"

const debug = process.env.REACT_APP_DEBUG && false;
const timeoutDebug = process.env.REACT_APP_DEBUG && true;

export type UseEuiContainsFilterProps = {
    availableDeviceEuis: string[]

    style?: React.CSSProperties 
}

export type UseEuiContainsFilterHookType = {
    state: [ string | undefined, React.Dispatch<React.SetStateAction<string | undefined>> | undefined ];
    component: JSX.Element;
}

export const useEuiContainsFilter = ( { availableDeviceEuis, style }: UseEuiContainsFilterProps ): UseEuiContainsFilterHookType => {
    const [ euiContainsFilter, setEuiContainsFilter ] = React.useState<string | undefined>( undefined ) 
    let userKeyTimeout: NodeJS.Timeout | undefined = undefined

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        
        stringify: (v: string) => v.toUpperCase(),
      });

    // For future reference making this controlled sets up a loop between delayed sending of keystroke and refresh on api results when the user quickly type in, causing an expensive infinite loop of queries!!!
    const EuiContainsFilter =  <Autocomplete { ...{
        freeSolo: false, id: 'selectEntity', style, size: 'small', options: availableDeviceEuis, 

        renderInput: (params) => <TextField {...{
            label: 'EUI contains',
            ...params ,
        } } />,      
        filterOptions,
        onInputChange: (e, v: string | null) => {
            v = v?.toUpperCase() ?? null
                timeoutDebug && console.log(`setting userKeyTimeout ${ userKeyTimeout } to undefined`)
                userKeyTimeout = undefined

            userKeyTimeout = setTimeout( () => {
                timeoutDebug && console.log(`setting euiContains filter ( currently ${ euiContainsFilter}) to ${v?.toUpperCase()}`)
                v  && setEuiContainsFilter( v )}, 500 )
            
        },
        
    }
    
    }></Autocomplete>


    const hookParts: UseEuiContainsFilterHookType = {
        state: [ euiContainsFilter, setEuiContainsFilter ],

        component: EuiContainsFilter
    }

    return hookParts
}