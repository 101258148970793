import { CognitoUser } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TransactionFunctions, UserFunctions } from '../actions/AppActions';
import { NotificationView } from '../components/Notification/NotificationView';
import { RootState } from '../reducers';
import { UserGroupsRolesType } from '../types/AppTypes';
const debug = process.env.REACT_APP_DEBUG && false;



const mapStateToProps = (state: RootState, ownProps: any) => {
  debug && console.log('map state to props', state.AppReducer)
  
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer.user,
    contextCustomer: state.AppReducer.contextCustomer,
    useSpecializedViews: state.AppReducer.useSpecializedViews
  
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {

    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView)