import { ThingShadowState } from '@iotv/datamodel';
import { CardHeader, Grid, Hidden, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { getImageUrl } from '../../data/aws/s3/UserBlobs';
import { getLocatableAddress, indexKeyToTypeAndId, isDeviceEnabledType, isLocatable } from '../../data/TypeHelpers';
import type { ObjectListItemCardProps, ValidBusinessObject, ViewObject } from '../../types/AppTypes';
import MapView from '../geo/MapView';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';
import ObjectShadowStateCard from './ObjectShadowStateCard';

const debug = process.env.REACT_APP_DEBUG && false;
const avatarSize = '90%';

const useStyles = makeStyles((theme) => { return {
  ...styles(theme), 
  avatarLarge: { height: avatarSize, width: avatarSize},
  objectNameLarge: { fontSize: 'xx-large'},
  variableHeightCard: { 
    height: '100%',
  },
  listCard: {
    width: '80vw'
  },
  mapCardContent: {
    height: '100%',
    width: '100%',
  },
  root: { 
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'red',
    } 
  },
}
  
});



export default function ObjectListItem( props: ObjectListItemCardProps | any ) {
  debug && console.log('Object card got props', props)
  const classes = useStyles();

  const viewObject: ViewObject = props.viewObject;



  useEffect( () => {
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.viewObject.matchedPrimary)
      if ( typeof objectOrString === 'string') {
        const url = objectOrString;
         debug && console.log('Use Effect has url', url)
        setImageUrl( url)
      }
   
    }

    effectUrl()

  }, [props.viewObject.matchedPrimary]);

  const [ imageUrl, setImageUrl ] = React.useState<string | undefined>(undefined)

  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);

  const thing: ValidBusinessObject  = viewObject.matchedPrimary ?? {} as ValidBusinessObject
  const { coordinates, location, position } = thing;

  const thingShadowStates: ThingShadowState[] = viewObject?.matchedRelatedBySk ? viewObject?.matchedRelatedBySk.filter( ( item: ValidBusinessObject ) => item.type === 'ThingShadowState').map( (item: ValidBusinessObject) => new ThingShadowState(item) ) : [];
  thingShadowStates.sort( ( tss1, tss2 ) => {
    const sortRes = tss1?.payload?.timestamp?.toString() && tss2?.payload?.timestamp?.toString() ? tss1?.payload?.timestamp?.toString() < tss2.payload?.timestamp?.toString() ? 1 : -1 : 0
   

    return sortRes;
  });

    const lastestThingStateShadow = thingShadowStates[0]
    const lastestThingStateShadowOb = lastestThingStateShadow ? new ThingShadowState(lastestThingStateShadow as ValidBusinessObject) : undefined;
    const latestPosition = lastestThingStateShadowOb?.payload?.state?.reported?.position ?? lastestThingStateShadowOb?.state?.reported?.position ?? coordinates ?? location ?? position ;
    const skAsTypeId = viewObject.matchedPrimary && indexKeyToTypeAndId(viewObject.matchedPrimary.sk);
  return ( 
    viewObject.matchedPrimary ?
      <NavLink filter = {`${NavSections.THINGVIEW}/${skAsTypeId?.type}/${skAsTypeId?.id}`} children = {
        <Card className = { classes.listCard }>
          <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4} lg={4}>
              <Card className = {classes.variableHeightCard}>
                <CardHeader title = {viewObject.matchedPrimary.name}></CardHeader>
                <CardContent>

                { imageUrl &&  <Avatar className={classes.avatar} alt={viewObject.matchedPrimary.name} src={imageUrl}/> 
                  }    
                
                </CardContent>
                   
              </Card>
              
            </Grid>     

            <Hidden mdDown >
              { (isDeviceEnabledType(viewObject.matchedPrimary) || isLocatable(viewObject.matchedPrimary)) && <Grid item  xs={4} lg={4}>
                <Card  className = { classes.variableHeightCard }>
                  <CardContent className = {classes.mapCardContent}>
                  <MapView { ...{ viewObjects: [viewObject], position: latestPosition, editable: false,  locatableAddress, optionals: { zoom: 8} } }></MapView>
                </CardContent>
                </Card>
              </Grid>}
            </Hidden>
            { isDeviceEnabledType(viewObject.matchedPrimary) &&               <Grid item  xs={4} lg={4}>
              <ObjectShadowStateCard className = {classes.subCard} matchedPrimary = {thingShadowStates[0]} parentObjects = {[viewObject.matchedRelatedByPk]} ></ObjectShadowStateCard>
              </Grid>}
          
          </Grid>
          </CardContent>
         
        </Card>
      }></NavLink>
        
       : null
 
  );
}