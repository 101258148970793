export enum NavSections {
  USERVIEW = 'USERVIEW',
  MAPVIEW = 'MAPVIEW',

  ADMIN = 'ADMIN',
  DATATABLEVIEW = 'DATATABLEVIEW',
  DEVICE_REGISTER = 'DEVICE_REGISTER',
  THINGVIEW = 'THINGVIEW',
  THINGSVIEW = 'THINGSVIEW',
  LOGIN = 'LOGIN',
  UNAUTHENTICATED = "NOAUTH",
  
  SYSADMIN = 'SYSADMIN',
  TABLEVIEW = 'TABLEVIEW',
  PROPERTIES = 'PROPERTIES',
  DASHBOARD = 'DASHBOARD',
  HOME = 'HOME',
  HOMEPAGE = 'HOMEPAGE',
  ROOT = '',
  HORSEVIEW = 'HORSEVIEW',
  NETWORKTABLEVIEW = 'NW',
  USER_MANAGEMENT_VIEW = 'USER_MANAGEMENT_VIEW',
  PROVISIONVIEW = 'PROVISIONVIEW',
  DEVICEEUI_PROVISIONVIEW = 'PROVISIONING',
  CUSTOMER_MANAGEMENT_VIEW = 'CUSTOMER_MANAGEMENT_VIEW',
  RULE_MANAGEMENT = 'RULE_MANAGEMENT',
  DEVICE_MANAGEMENT_VIEW = 'DEVICE_MANAGEMENT_VIEW',
  SERVICE_OFFERFING_MANAGEMENT_VIEW = 'SERVICE_OFFERFING_MANAGEMENT_VIEW',
  SURVEYVIEW = 'SURVEYVIEW',
  NOTIFICATION_MANANGEMENT_VIEW = 'NOTIFICATION_MANANGEMENT_VIEW',
  DIAGNOSTIC = 'DIAGNOSTIC',
  SLOT1 = 'SLOT1'
}
