import { ViewObject } from "../../../types/AppTypes";
import { color } from 'd3-color';
import { makeStyles, Theme, useTheme } from "@material-ui/core";

const debug = process.env.REACT_APP_DEBUG && false;

export const getFillGaugeSettings = (viewObject: ViewObject, calculatedStateFromPayload: any, theme: Theme) => {
    var color_of_silo = theme.palette.primary.main
    let circleColor: string | undefined = undefined
    let filled_prece: number = viewObject?.matchedPrimary?.current_filled_factor * 100
    debug && console.log('filled_prece', filled_prece)
  
    if (filled_prece >= 0 && filled_prece <= 30) {
      circleColor = theme.palette.error.main
    } else if (filled_prece > 30 && filled_prece <= 50) {
      circleColor = theme.palette.warning.main
    } else if (filled_prece > 50) {
      circleColor = theme.palette.success.main
    } else {
      circleColor = theme.palette.error.main
    }
    var gradientStops = [
      {
        key: '0%',
        stopColor: color(color_of_silo).darker(0.5).toString(),
        stopOpacity: 1,
        offset: '0%'
      },
      {
        key: '50%',
        stopColor: color_of_silo,
        stopOpacity: 0.75,
        offset: '50%'
      },
      {
        key: '100%',
        stopColor: color(color_of_silo).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: '100%'
      }
    ];
    return { gradientStops, color_of_silo, circleColor };
  }
  