import { Store } from 'redux';
import MainStore from '../stores/MainStore';
import config from '../config';
import { ACTIONS } from '../actions/actionTypes';

const shadowAcceptedRegex = new RegExp(config.aws.mqtt.topicRegex.shadowAccepted);
const alertIssuedRegex = new RegExp(config.aws.mqtt.topicRegex.alertIssued);
const userChannelRegex = new RegExp(config.aws.mqtt.topicRegex.userChannel);
const networkChannelRegex = new RegExp(config.aws.mqtt.topicRegex.networkChannel);
const deviceControlRegex = new RegExp(config.aws.mqtt.topicRegex.deviceControl)
const debug = process.env.REACT_APP_DEBUG && false;

class AmplifyIoTListener {
    store: Store;
    constructor(store: Store)  { 
        this.store = store;
    };

    updateFromMessage(message: {value: any[]}) {
        const topicKey = Reflect.ownKeys(message.value).find( (k) => typeof k == 'symbol');
        if ( topicKey ) {
          //@ts-ignore
          const topic = message.value[ topicKey ]
          debug && console.log('TOPIC: ', topic);
          debug && console.log('MESSGAGE',  message);
        
          if (shadowAcceptedRegex.test(topic)) {
            debug && console.log(`adding meessage from topic ${topic}`)
            const action = {type: ACTIONS.MQTT_MESSAGE_RECEIVED, key: config.aws.mqtt.topic.shadowAccepted, value: message.value}
            this.store.dispatch(action);
          }
          else if (alertIssuedRegex.test(topic)) {
            debug && console.log(`adding meessage from topic ${topic}`)
            const action = {type:  ACTIONS.MQTT_MESSAGE_RECEIVED, key: config.aws.mqtt.topic.alertIssued, value: message.value}
            this.store.dispatch(action);
          } else if (userChannelRegex.test(topic)) {
            debug && console.log(`adding meessage from topic ${topic}`)
            const action = {type:  ACTIONS.MQTT_MESSAGE_RECEIVED, key: config.aws.mqtt.topic.userChannel, value: message.value}
            this.store.dispatch(action);
          } else {
            console.log(`AmplifyIoTListener updateFromMessage rejected topic ${ topic }`)
          }
        }
        setTimeout(() => this.store.dispatch({ type: ACTIONS.CLEAR_MESSAGE_PING}), 500) 
      }

    callBackComponent(message: {value: any[]}, callBack: (data: any) => void) {
      const topicKey = Reflect.ownKeys(message.value).find( (k) => typeof k == 'symbol');
        if ( topicKey ) {
          //@ts-ignore
          const topic = message.value[ topicKey ]
          debug && console.log('TOPIC: ', topic);
          debug && console.log('MESSGAGE',  message);
        
       if (networkChannelRegex.test(topic) || deviceControlRegex.test(topic) || shadowAcceptedRegex.test(topic)) {
        callBack(message)
        setTimeout(() => this.store.dispatch({ type: ACTIONS.CLEAR_MESSAGE_PING}), 500)
       } else {
         console.log(`AmplifyIoTListener callBackComponent rejected topic ${ topic }`)
       }
      }
    }
}

export default new AmplifyIoTListener(MainStore);