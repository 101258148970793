import { CustomerAccountType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { GridColDef, GridCellParams, DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavSections } from '../../../components/navigation/NavSections';
import { getAdjacent2Wrapper } from '../../../data/daoFunctions/daoFunctions';
import { AdjacentType, ObjectCardProps } from '../../../types/AppTypes';
import { useStyles } from '../cosmetics/communityStyles';
import { c021NavSections } from '../navigation/c021_NavSections';
import { getAssignableCustomerPlans, getFreeCustomers } from './CountryCommunity/DroughtManagement/DroughtManagementPlanHelpers';

/**
 * Card responsible for display a list of all the Drought Management Plans associated with the current customer
 * @param props
 * @returns
 */
export const DroughtManagementPlanCard = (props: ObjectCardProps) => {
  const { transactionFunctions, userFunctions, searchParams, viewObject, contextCustomer, userGeolocation, history } = props;

  const styles = useStyles();
  const routerHistory = useHistory();
  const [droughtManagementPlans, setDroughtManagementPlans] = useState<ValidBusinessObject[]>();
  const [canMake, setCanMake] = useState<boolean>(false);

  let [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (contextCustomer) {
      if (contextCustomer.accountType === CustomerAccountType.V || contextCustomer.accountType === CustomerAccountType.X) {
        getDroughtManagementPlans(contextCustomer);
      }
    } else {
      setValid(false);
    }
  }, [contextCustomer]);

  const getDroughtManagementPlans = async (context: ValidBusinessObject) => {
    let plans = await getAssignableCustomerPlans(context);
    let freeCustomers = await getFreeCustomers(context);

    setCanMake(freeCustomers.length > 0);
    setDroughtManagementPlans(plans);
    setValid(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Plan Name',
      type: 'string',
      minWidth: 500,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'currentStage',
      headerName: 'Current Stage',
      type: 'string',
      minWidth: 300,
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'edit',
      headerName: '',
      minWidth: 200,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            variant='contained'
            className={styles.button}
            color='primary'
            onClick={() => routerHistory.push({ pathname: `${NavSections.THINGVIEW}/DroughtManagementPlan/${params.row.id}`, search: 'edit=true' })}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container className={styles.container} spacing={2}>
        <Grid item>
          <Typography variant='h5' component='h5'>
            Drought Management Plans
          </Typography>
        </Grid>
        {valid && (
          <Grid item>
            <Button
              {...{
                disabled: !canMake,
                variant: 'contained',
                onClick: () => {
                  contextCustomer && userFunctions.draftNewObject(contextCustomer, 'DroughtManagementPlan', history);
                },
                color: 'primary',
              }}
            >
              Create New Drought Management Plan
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            {droughtManagementPlans !== undefined ? (
              <DataGrid disableColumnMenu={true} autoHeight={true} rows={droughtManagementPlans} columns={columns} disableSelectionOnClick={true} />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={120} color='secondary' />
                <p>Loading</p>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
