import { getNiceDate, MapLike, ValidBusinessObject } from "@iotv/datamodel"
import { AppValueTypes } from "@iotv/iotv-v3-types"
import { makeStyles, Table, TableCell, TableHead, TableRow } from "@material-ui/core"
import React from "react"

import styles from "../../cosmetics/sharedCardStyles"
import { ComponentMode, ViewDefinition, ViewKeyDefinitionType } from "../../types/AppTypes"

const debug = process.env.REACT_APP_DEBUG && false;

export type ReadOnlyListProps = {
    matchedPrimary: ValidBusinessObject
    viewDefinition: ViewDefinition,
    objects: MapLike<any>[],
}

const mode = ComponentMode.READONLY
const updateKV = () => {}


const useStyles = makeStyles((theme) => (styles(theme)));


export const ReadOnlyList = ( { matchedPrimary, objects, viewDefinition }: ReadOnlyListProps) => {
    const classes = useStyles();

    debug && console.log('ReadOnlyList gets', { viewDefinition})

    const getHeaderCells = (viewDefinition: ViewDefinition) => {
        debug && console.log( 'Something is undfined', ( Object.entries(viewDefinition) === undefined ))
        return Object.values(viewDefinition).map( (viewKeyDefinition: ViewKeyDefinitionType) => <TableCell  { ...{className:  classes.tableCellNoPadding, align: 'left' } }>{viewKeyDefinition.label}</TableCell>)
    }
 
    const switchOnAttTypeView = (rowObject: MapLike<any>, viewKeyDefinition: ViewKeyDefinitionType ) => {

    const value = rowObject[viewKeyDefinition.key];
    switch (viewKeyDefinition.type) {
        // case AppValueTypes.ENUM: return <EnumComponent {...{ matchedPrimary: rowObject, viewKeyDefinition, mode, updateKV }}></EnumComponent>
        // case AppValueTypes.BOOLEAN: return <BooleanComponent {...{ matchedPrimary: rowObject, viewKeyDefinition, mode, updateKV }}></BooleanComponent>;
        case AppValueTypes.DATE: return value ? getNiceDate( new Date(value ).valueOf(), { dateStyle: 'short'}) : value;
        default: return viewKeyDefinition.valueGetter ? viewKeyDefinition.valueGetter(value) : value

    }
}


    const getAttributeCell = (matchedPrimary: ValidBusinessObject, rowObject: MapLike<any>, viewKeyDefinition: ViewKeyDefinitionType ) => {
        return  <TableCell key={`${matchedPrimary?.sk}_${rowObject.sk}_${viewKeyDefinition.key}-edit`} className={classes.tableCellNoPadding} align="left">
                {switchOnAttTypeView(rowObject, viewKeyDefinition)}
            </TableCell>
}

    const getObjectRow = ( object: MapLike<any> ) =>{

        return  <TableRow>
            { Object.entries(viewDefinition).map(([key, viewKeyDefinition], i) => getAttributeCell(matchedPrimary, object, viewKeyDefinition ))  }
        </TableRow>
      
    }

    return <Table>
        <TableHead>
            <TableRow>
                { getHeaderCells( viewDefinition )}
            </TableRow>
        </TableHead>
        { objects.map( ( object ) => getObjectRow(object))}
    </Table>

}