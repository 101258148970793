import theme from "../themes/defaultTheme";
import { green, indigo, lightGreen, pink, yellow, } from '@material-ui/core/colors';
import { Styles } from "@material-ui/styles/withStyles";

const cssBreakpointDebug = process.env.REACT_APP_DEBUG && false;


const debug = process.env.REACT_APP_DEBUG && false;

Object.assign(theme.typography, { ...theme.typography })

debug && console.log('Default App.tsx', theme)

export const styles: Styles<any, any, string> = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    color: 'rgba(0, 0, 0, 0.87)',
    // overflowY: 'hidden'
  },
  body: {
    overflowY: 'hidden'
  },
  drawer: {
    // [theme.breakpoints.up('lg')]: {
    //   [theme.breakpoints.up('xl')]: {
    //   width: drawerWidth,
    //   flexShrink: 0,
    // },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    // width: '90vw',
    height: '100vh',
    width: '100vw',
    // padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

if (cssBreakpointDebug) {
  const debugBreakpointsStyle = {
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      backgroundColor: pink[500],
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: yellow[500],
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: lightGreen[500],
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: green[500],
    },
    [theme.breakpoints.up('xl')]: {
      backgroundColor: indigo[600],
    },
  };
  styles.root = { ...styles.root, ...debugBreakpointsStyle }
}