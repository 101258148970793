import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThingShadowState } from '@iotv/datamodel';
import styles from '../../../cosmetics/sharedCardStyles';
import { Box, Paper, Typography } from '@material-ui/core';

const debug = process.env.REACT_APP_DEBUG && false;

type TankWaterAvailabilityCarProps = { matchedPrimary: ThingShadowState; }
const useStyles = makeStyles((theme) => ({ ...styles(theme) }));

export default function TankWaterAvailabilityCard(props: TankWaterAvailabilityCarProps | any) {
  const [waterAvailabilityLabel, setVaterAvailabilityLabel] = React.useState("")
  const [waterAvailabilityText, setVaterAvailabilityText] = React.useState("")
  const classes = useStyles();
  const { matchedPrimary } = props
  const tss = matchedPrimary as ThingShadowState
  debug && console.log('TankWaterAvailabilityCard tss', tss)
  React.useEffect(() => {
    // let waterAvailabilityLabel = tss?.payload?.state?.calculated?.tank_water_availability
    // let waterAvailabilityText = tss?.payload?.state?.calculated?.tank_water_availability_message
    let waterAvailabilityLabel = matchedPrimary?.tank_water_availability
    let waterAvailabilityText =  matchedPrimary?.tank_water_availability_message
    setVaterAvailabilityLabel(waterAvailabilityLabel)
    setVaterAvailabilityText(waterAvailabilityText)
    return () => { };
  },  [props]);

  return (
    <Paper elevation={3}
      className={(waterAvailabilityLabel == 'A' ? classes.waterHealthTabA
        : waterAvailabilityLabel == 'B' ? classes.waterHealthTabB
          : waterAvailabilityLabel == 'C' ? classes.waterHealthTabC
            : waterAvailabilityLabel == 'D' ? classes.waterHealthTabD 
            : waterAvailabilityLabel == 'E' ? classes.waterHealthTabE : classes.waterHealthTab
      )}
    >
      <Box >
        <Box
          display="flex"
          width='100%'
          height='50%'
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant='h2' component="h2">
            {waterAvailabilityLabel} </Typography>
        </Box>
        <Box
          display="flex"
          width='100%' height='50%'
          alignItems="center"
          justifyContent="center"
          textAlign='center'
        >{waterAvailabilityText}</Box>
      </Box>
    </Paper>
    // </Card>
  );
}

