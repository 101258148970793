import { HistoryStoreFilterRequest, ObjectHistoryFilterRequest } from "@iotv/iotv-v3-types";
import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import { camelToDisplay, ObjectHistory } from "@iotv/datamodel-core";

export type SetObjectHistoryFilterWidgetProps = {
    historyStoreKeyKeys: string[],
    objectHistoryFilter: ObjectHistoryFilterRequest | undefined,
    setObjectHistoryFilter: ( objectHistoryFilterRequest: ObjectHistoryFilterRequest | undefined ) => void
}

export const SetObjectHistoryFilterWidget = ({ historyStoreKeyKeys, objectHistoryFilter, setObjectHistoryFilter }: SetObjectHistoryFilterWidgetProps) => {

    return <>

     { historyStoreKeyKeys.map( (historyStoreKey ) => {
         
         return <SetHistoryObjectFilterWidget { ...{historyStoreKey, objectHistoryFilter, setObjectHistoryFilter}}></SetHistoryObjectFilterWidget>
     })}
    </>
}

export type SetHistoryObjectFilterWidgetProps = {
    historyStoreKey: string,
    objectHistoryFilter: ObjectHistoryFilterRequest | undefined,
    setObjectHistoryFilter: ( objectHistoryFilterRequest: ObjectHistoryFilterRequest | undefined ) => void
}

export const SetHistoryObjectFilterWidget = ( {historyStoreKey, objectHistoryFilter, setObjectHistoryFilter }:SetHistoryObjectFilterWidgetProps ) => {
        const historyStoreFilter = objectHistoryFilter?.[historyStoreKey]

        const handleSettingChange = ( filterAttKey: keyof HistoryStoreFilterRequest,  e: React.ChangeEvent<HTMLInputElement>) => {
            const v = parseInt(e.target.value)

            if ( !isNaN(v)) {
                const clone = { ...objectHistoryFilter };
                if ( filterAttKey === "lastInNMs" ) {
                    clone[historyStoreKey].lastN = undefined
                } else if (filterAttKey === "lastN" ) {
                    clone[historyStoreKey].lastInNMs= undefined
                }
                clone[historyStoreKey][filterAttKey] = v

                setObjectHistoryFilter(clone)
            }

        }

        const commonProps: TextFieldProps = {
            type: 'number',

        }

    return <>
        <>{camelToDisplay(historyStoreKey)}</>
        { historyStoreFilter && <>
            <>Last N</><TextField { ...{ ...commonProps, onChange: ( e: React.ChangeEvent<HTMLInputElement> ) => handleSettingChange( 'lastN', e ),  value: historyStoreFilter.lastN ?? 'Not Set'}}></TextField>
            <>Last Time Period</><TextField { ...{ ...commonProps,  onChange: ( e: React.ChangeEvent<HTMLInputElement> ) => handleSettingChange( 'lastInNMs', e ), value: historyStoreFilter.lastInNMs ?? 'Not Set'}}></TextField>
            <>SampleRate</><TextField { ...{ ...commonProps,  onChange: ( e: React.ChangeEvent<HTMLInputElement> ) => handleSettingChange( 'sampleRate', e ), value: historyStoreFilter.sampleRate ?? 'Not Set'}}></TextField>
        </> }

    </>
}