import { ValidBusinessObject, ValidBusinessObjectList } from "@iotv/datamodel";
import { Button, CircularProgress, FormControl, IconButton, TextField } from "@material-ui/core";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BackupIcon from '@material-ui/icons/Backup';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid/';
import { googleSheetRef } from "@iotv/iotv-v3-types";
import config from "../../config";
import { useGoogleAPIAuth } from '../../hooks/useGoogleAPIAuth';
import { useGoogleListSheets, UseGoogleListSheetsReturnType } from "../../hooks/useGoogleIO";
import { ImportObjectsFromGSheetProps, MessageOutputType, ViewDefinition } from "../../types/AppTypes";
import { gapiIsPresent } from "../../util/Scripts/isPresent";
import { ImportedSpreadsheetDataTable } from "./ImportedSpreadsheetDataTable";
import { exportVobs, HeaderSource, spreadsheetDataAsVBOs, spreadsheetDataToViewDefintion } from './sharedFns';

const debug = process.env.REACT_APP_DEBUG && false;

export enum IOType {
    IMPORT = 'IMPORT', EXPORT = 'EXPORT'
}

export type SimpleIOProps = { 
    ioType: IOType, contextCustomer: ValidBusinessObject | undefined, userSelectedGroup: string | undefined, viewDefinition: ViewDefinition, vobs: ValidBusinessObjectList, 
    setVBOsInParent: ( vobsOut: ValidBusinessObjectList ) => void, 
    
    parentHasRendered: () => boolean,
    /* if processPlainObjects is set, use the provided objectTypeId as the type of each item, and its row number as its id */
    processPlainObjects?: {
        objectTypeId: string
    },
    spreadsheetId?: string,
    fileSelectRef?: React.MutableRefObject<googleSheetRef | undefined>
    showSignOut?: boolean
    

    
} & Omit<ImportObjectsFromGSheetProps, 'viewObject' | 'userGroupRoles' | 'userSelectedGroup' | 'user' | 'setImportDefinitionInParent'> 

export const SimpleIO = ( { ioType, contextCustomer, userSelectedGroup, viewDefinition, vobs
    , userFunctions, setVBOsInParent, parentHasRendered, transactionFunctions, processPlainObjects, fileSelectRef, showSignOut = false }: SimpleIOProps ) => {

    const { state: [ [ signedIn, setSignedIn], [isInitialized, setIsInitialized] ], handlers: [ handleAuthClick, handleSignoutClick ] } = useGoogleAPIAuth(contextCustomer, gapiIsPresent())
    const { 
        state:  [ 
            [nameMatchText, setNameMatchText], 
            [matchedFiles, setMatchedFiles],
            [selectedFile, setSelectedFile],
            [availableSheets, setAvailableSheets],
            [selectedSheet, setSelectedSheet],
            [selectedRange, setSelectedRange],
            [spreadsheetData, setSpreadsheetData],
            [error, setError]
        ], 
        handlers: [ handleListFiles, getSpreadsheetData ] 
    }: UseGoogleListSheetsReturnType = useGoogleListSheets({ signedIn, fileSelectRef } ); 

    const [ working, setWorking ] = React.useState<boolean>(false)




    const [ importObjectType, setImportObjectType ] = React.useState<string | undefined>( processPlainObjects ? processPlainObjects.objectTypeId : undefined );
    const [ importViewDefinition, setImportViewDefinition ] = React.useState<ViewDefinition | undefined >(undefined) 

    const displayMessage = (content: string, type: MessageOutputType) => {
        userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
    }

    const [ importObjects, setImportObjects ] = React.useState<ValidBusinessObjectList>([]);

    const firstImporteRowHash= spreadsheetData.length > 0 ? spreadsheetData[1].join(':') : undefined; 
    const importViewDefinitionHash = importViewDefinition ? Object.keys(importViewDefinition).join(':') : 'nothing'
    
    useEffect( () => {
        const importedObjects = spreadsheetDataAsVBOs( { spreadsheetData, importObjectType, setImportObjectType });
        const newViewDefinition = spreadsheetDataToViewDefintion( { spreadsheetData  } );

        debug && console.log('change of spreadsheet data: importedObjects as vbos', {ioType, importViewDefinition, importObjects});
        setImportObjects(importedObjects )
        setImportViewDefinition(newViewDefinition)
    }, [firstImporteRowHash, importViewDefinitionHash])


    const rerenderOnFileRef = (nameMatchText && isInitialized)

    // useEffect( () => {
    //     debug && console.log('Simple IO used effect on  nameMatchText', nameMatchText)
    //     handleChangedMatchText( nameMatchText)
    // }, [  nameMatchText ])
    
    const clearImportedObjects = () => setSpreadsheetData([])

    const handleChangedMatchText = ( partTitle: string | undefined) =>{ 
        setSelectedFile(undefined)
        setAvailableSheets([])
        setSelectedSheet(undefined)
 
            if ( nameMatchText !== partTitle) { setNameMatchText(partTitle) 
                const matchedFile = matchedFiles.find( (file) => file?.name === partTitle);
                if (matchedFile ) {
                    setSelectedFile(matchedFile)
                } else {
                    debug && console.log('no match on', partTitle)
                }
            }

        

    }


    return  <span>

         { isInitialized && <FormControl>
         <Autocomplete
            freeSolo
            id="spreadsheet-name-select"
            options={matchedFiles.map( (file) => { return { title: file.name }} ).filter( (item) => item.title ) as { title: string }[] }
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            onChange= { (e, v: { title: string } | string | null)  => { 
                if ( typeof v === 'object' && v?.title) { 
                    handleChangedMatchText( v.title )
                }
                // setSelectedFile(undefined)
                // setAvailableSheets([])
                // setSelectedSheet(undefined)
                // if ( typeof v === 'object' && v?.title) { 
                //     setNameMatchText(v.title)
                //     const matchedFile = matchedFiles.find( (file) => file?.name === v.title);
                //     if (matchedFile ) {
                //         setSelectedFile(matchedFile)
                //     } else {
                //         debug && console.log('no match on', v.title)
                //     }
                // } 

            }}
            
            renderInput={(params) => <TextField 
                {...{
                    ...params, 
                    // value: 'nameMatchText' ?? config.app.appCode,  
                    onChange: ( e ) => { 
                        setAvailableSheets([])
                        setSelectedSheet(undefined)
                        setSelectedFile(undefined)
                        setNameMatchText(e.target.value);
                        const matchedFile = matchedFiles.find( (file) => file?.name === e.target.value);
                        if (matchedFile ) {
                            setSelectedFile(matchedFile)
                        } else {
                            debug && console.log('no match on', e.target.value)

                        }
                        }
                    } 
                }
                 label="Google file" size = 'small' variant="outlined" 
                 />}
    />
         </FormControl> }
         <FormControl>
         { availableSheets.length > 0 && <Autocomplete
            id='availableSheets-select'
            options={availableSheets.map( (sheet, i) => { return { title: sheet.properties?.title ?? `no title ${i}`  } } )}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            onChange= { (e, v: { title: string } | string | null)  => { 
                if ( typeof v === 'object' && v?.title) { 
                    const matchedSheet = availableSheets.find( (sheet) => sheet.properties?.title === v.title);
                    if (matchedSheet ) {
                        setSelectedSheet(matchedSheet)
                    } else {
                        debug && console.log('no match on', v.title)
                    }
                } 
            }}

            renderInput={(params) => <TextField { ...{
                ...params,
                value:  'select sheet'
                }
            } label="Sheet name" variant="outlined" size="small" />}
/>}
         </FormControl>
        
         <FormControl> {
                selectedFile && <a { ...{ target: "_blank", href: `https://docs.google.com/spreadsheets/d/${selectedFile.id}`}}>
                            <IconButton
                    { ...{ 
                     
                    }}
                >
                <EditIcon></EditIcon>
             </IconButton>
                </a>
                 
        
             }

         </FormControl>
         {  selectedFile && selectedSheet &&  <FormControl> {
                ioType === IOType.EXPORT ?  <IconButton
                { ...{
                    onClick: async () => {
                        debug && console.log('Export of ', {vobs, file: selectedFile, sheet: selectedSheet})
                        setWorking(true)
                        const exportRes = await exportVobs({vobs, viewDefinition, file: selectedFile, sheet: selectedSheet, headerSource: HeaderSource.KEY, processPlainObjects})
                        if ( exportRes.err) {
                            displayMessage( exportRes.err.message,'MessageBar')
                        } else {

                            displayMessage( exportRes.data?.updatedRows ? `${selectedFile.name} updated with ${exportRes.data.updatedRows} items` : 'no result from export','SnackBar')
                        }
                        setWorking(false)
                    }
                }}
             >
             <AddShoppingCartIcon />
             </IconButton> 
             : 
             <IconButton { ...{
                onClick: async () => {
                    debug && console.log('Import of ', { file: selectedFile, sheet: selectedSheet})

                    setWorking(true)
                    const exportRes = await getSpreadsheetData(selectedFile.id as string, `${selectedSheet.properties?.title}`)
                    if ( exportRes.err) {
                        displayMessage( exportRes.err.message,'MessageBar')
            
                    } else if ( exportRes.data) {
                        setSpreadsheetData(exportRes.data)
                        displayMessage( exportRes.data ? `${selectedFile.name} uploaded with ${exportRes.data.length} items` : 'no result from import','SnackBar')
                    }
                    setWorking(false)
                }
            }}>
                 <BackupIcon />
             </IconButton>
             }
            
         </FormControl>}
         <FormControl>
              <div { ...{ style: { justifyContent: 'space-around', padding: '8px'}}}>
              {working && <CircularProgress { ...{ color: "secondary", size: '2em' } }/>}
              </div>
       
         </FormControl>
         {
             showSignOut ? <FormControl>
             <Button
             variant="outlined"
             color="primary"

             onClick={() => signedIn ? handleSignoutClick() :  handleAuthClick()}
             disabled={!isInitialized}
          >  { isInitialized ? signedIn ? 'Sign out' : 'Authorize': 'Waiting'}</Button>
      </FormControl> : !isInitialized || !signedIn ? 
      <FormControl>
      <Button
      variant="outlined"
      color="primary"

      onClick={() => signedIn ? handleSignoutClick() :  handleAuthClick()}
      disabled={!isInitialized}
   >  { isInitialized ?  'Authorize': 'Waiting'}</Button>
</FormControl> : null
         }
         
         {ioType === IOType.IMPORT &&  importObjects.length > 0 && <ImportedSpreadsheetDataTable
            { ...{
                objects: importObjects, importObjectType, importViewDefinition, contextCustomer, userSelectedGroup, transactionFunctions, userFunctions, setVBOsInParent, clearImportedObjects, parentHasRendered 
            }}
        ></ImportedSpreadsheetDataTable>
         }

         </span>

}