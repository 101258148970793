import { ThingShadowState } from '@iotv/datamodel';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styles from '../../../../cosmetics/sharedCardStyles';
import { ViewDefinition } from '../../../../types/AppTypes';
import TankGroupEditDetailsCard from './TankGroupEditDetailsCard';
const MAX_DAYS_TO_EMPTY = 2 * 365;

const debug = process.env.REACT_APP_DEBUG && false;

type TankGroupCardProps = {
  matchedPrimary: ThingShadowState,
  viewDefinition: ViewDefinition,
}


const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "20%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end'
  },
  boxTab: {
    margin: 1,
    width: '100%',
    height: '100%',
  },
  removePadding: {
    padding: "0px"
  },
  waterHealthTab: {
    backgroundColor: 'green'// "primary"
  },
  typographyPaddingRight: {
    paddingRight:'2px'
  }
};


const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));

export default function TankGroupDetailsCard(props: TankGroupCardProps | any) {
  const classes = useStyles();
  const [editView, setEditView] = React.useState(false);

  const { matchedPrimary, viewObject, actions, transactionFunctions, viewDefinition, searchParams, history, title } = props
  debug && console.log('TankGroupDetailsCard props', props)
  // const latestPayload = viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.find((item: ValidBusinessObject) => item.type === 'ThingShadowState'); //assuming right order from db query
  // const { lastestThingStateShadow, thingStatesMostRecentFirst } = extractGeoDataFromViewObject(viewObject);
  // const tss = props?.viewObject?.matchedPrimary as ThingShadowState;

  let filledVolume = matchedPrimary?.current_filled_volume ? matchedPrimary?.current_filled_volume:0
  let volume_used_yesterday = matchedPrimary?.volume_used_yesterday ? matchedPrimary?.volume_used_yesterday : 0

  let runOutDate = new Date();
  let days_to_empty = (typeof matchedPrimary.days_to_empty === 'number') ? matchedPrimary.days_to_empty < MAX_DAYS_TO_EMPTY ? matchedPrimary.days_to_empty : MAX_DAYS_TO_EMPTY : MAX_DAYS_TO_EMPTY
  debug  && console.log({ days_to_empty})
  if (typeof  days_to_empty === 'number') runOutDate.setDate(runOutDate.getDate() + days_to_empty);
  else runOutDate.setDate(runOutDate.getDate())
  let predicted_run_out_date = runOutDate.toLocaleDateString('en-NZ')

  debug && console.log('TankGroupDetailsCard tss', matchedPrimary?.dailyStateHistory)
  debug && console.log('TankGroupDetailsCard map dailyStore', matchedPrimary?.dailyStateHistory.current_filled_volume[0])
  debug && console.log('TankGroupDetailsCard props', props)
  const formatNumber = (num: number) => { return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }

  const getNumberOfUser = (tankList: any) => {
    debug && console.log({ tankList })
    const noOfUser: number = tankList.reduce((acc: any, curr: any) => typeof curr.no_of_users === 'number' ? acc + curr.no_of_users : acc, 0)
    return noOfUser
  }

  return (
    <div>
      {!editView &&
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Grid container direction="column" >
                <Grid item xs={12} >
                  <Paper elevation={3} className={classes.boxTab}>
                    <Box
                      display="flex"
                      width='100%' height='50%'
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h6" component="h2">
                        {formatNumber(Math.round(filledVolume)) || '- '} Lts
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      width='100%' height='50%'
                      alignItems="center"
                      justifyContent="center"
                      textAlign='center'
                    >
                      Current Volume</Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={3} className={classes.boxTab}>
                    <Box
                      display="flex"
                      width='100%'
                      // height='50%'
                      height='auto'
                      alignItems="center"
                      justifyContent="center"
                    >

                      <Typography variant="h6" component="h2">
                        {predicted_run_out_date || '-'}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      width='100%'
                      // height='50%'
                      height='auto'  //'25%'
                      alignItems="center"
                      justifyContent="center"
                      textAlign='center'
                    >
                      Predicted run out date</Box>
                  </Paper>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" >
                <Grid item xs={12}>
                  <Paper elevation={3} className={classes.boxTab}>
                    <Box
                      display="flex"
                      width='100%' height='50%'
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h6" component="h2">
                        {formatNumber(Math.round(volume_used_yesterday)) || '- '} Lts
                          </Typography>
                    </Box>
                    <Box
                      display="flex"
                      width='100%' height='50%'
                      alignItems="center"
                      justifyContent="center"
                      textAlign='center'
                    >
                      Volume used yesterday</Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {/*   <Paper elevation={3} className={classes.boxTab}>
                      <TankRainCard className={classes.subCard} latestPayload={lastestThingStateShadow}  ></TankRainCard> 
                      </Paper>*/}

                  <Grid container direction="column" >
                    <Grid item xs={12} >
                      <Paper elevation={3} className={classes.boxTab}>
                        <Box
                          display="flex"
                          width='100%'
                          height='auto'  //'25%'
                          alignItems="center"
                          justifyContent="center"
                          textAlign='center'
                        >
                        <Typography variant='subtitle2' component="h2" className={classes.typographyPaddingRight}
                          >
                            {props.matchedRelatedBySk?.length || '-'}
                            {/* 3 */}
                        </Typography> Tanks in Community
                              </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper elevation={3} className={classes.boxTab}>
                        <Box
                          display="flex"
                          width='100%'
                          height='auto'  //'25%'
                          alignItems="center"
                          justifyContent="center"
                        >
                        <Typography variant="subtitle2" component="h2" className={classes.typographyPaddingRight}>
                            {getNumberOfUser(props.matchedRelatedBySk)}
                          </Typography>  Water users
                            </Box>
                      </Paper>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {/* </CardContent>
          </Card> */}

          {/* 
           */}
        </div>
      }
      {editView && <div>
        <TankGroupEditDetailsCard viewObject={viewObject} transactionFunctions={transactionFunctions} searchParams viewDefinition
          closeTankEdit={() => setEditView(false)}
        />
      </div>}
    </div>
  );
}
