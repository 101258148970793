import { ErrData, Gateway, Position } from '@iotv/datamodel';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import config from '../../../../config';
import AppDao from '../../../../data/AppDao';
import { GatewayLabel } from '../GatewayLabel';
import { addHTMLOverlay } from './addHTMLOverlay';
import { bounsdCellLatLngsToCoveringCells, googleBoundsToCellLatLngs } from './conversion';


const debug = process.env.REACT_APP_DEBUG && false;

const dyn = config.aws.dynamoDB


const getQueryParams = (token: string): DocumentClient.QueryInput => {
    const query: DocumentClient.QueryInput = {
        TableName: dyn.tableName,
        IndexName: dyn.dynamicKeyGSIName,
        ExclusiveStartKey: undefined,
        ExpressionAttributeNames: {
            '#type': dyn.dpKey,
            '#sk': dyn.dsKey,
        },
        ExpressionAttributeValues: {
            ':type': 'Gateway',
            ':sk': `${token}`
        },
        KeyConditionExpression: '#type = :type and begins_with(#sk, :sk)'



    }
    debug && console.log('Gateway Query', query)
    return query
}


export const getGatewaysByBounds = async ( map: google.maps.Map<Element>, setGateways: ( gateways: Gateway[]) => void ) => {
    const bounds = map.getBounds() ?? new google.maps.LatLngBounds()
    const boundsLatLng: { ne: Position, sw: Position } | undefined = googleBoundsToCellLatLngs( bounds ) 

    const boundsCoveringCells = boundsLatLng ? bounsdCellLatLngsToCoveringCells({  boundsLatLng, maxCells: 4, maxLevel: 10}) : []

    /* show refernence cells on map*/
    //boundsCoveringCells.forEach( ( cellId ) => map.data.addGeoJson(new S2Cell(cellId).toGEOJSON()))

    const cellTokens = boundsCoveringCells.map( ( cellId ) => cellId.toToken()).sort().map( (token) => token.substr(0, token.length - 2)).reduce( (acc, cur ) => {
        if ( !acc.includes(cur )) {
            acc.push(cur)
        }
        return acc
    }, [] as string[]) ;

    const cellTokenHash = cellTokens.join('');

    debug && console.log('Gateway Bounds cell tokens', cellTokenHash);

    const labelGateway = ( gateway: Gateway) => {
        const bounds: google.maps.LatLngBounds =  new google.maps.LatLngBounds();
        if ( gateway.location) {
            bounds.extend(gateway.location)
            const overlay = addHTMLOverlay(map, GatewayLabel({ gateway }), bounds )
        }

    }

    const setterFn = async ( response: ErrData<DocumentClient.QueryOutput> ) => {
        debug && console.log('Gateway Response', response);
        if ( response.data?.Items) {
            const gateways  = response.data.Items.map( (item) => new Gateway(item)) as Gateway[];
            setGateways(gateways)
            debug && console.log('Set gatewys', gateways.length)
            const geoJsonPromises =  gateways.filter( (gw) => gw.location ).map( async (gw) => { 
                const point = new google.maps.Data.Feature( { geometry: gw.location, id: gw.id } )
                return await new Promise( (resolve, reject ) => {
                    point.toGeoJson( ( feature: object ) => resolve( feature ))
                }) 
            }).filter( ( geoJson ) => geoJson);
            const geoJson = await Promise.all(geoJsonPromises)
            debug && console.log('geoJson to add', geoJson);
            debug && console.log('map', map)
            if ( map.data) {
               // map.data.addGeoJson(geoJson , {                })
               const featureCollection = {
                features: geoJson,
                type: 'FeatureCollection'
               }
               debug && console.log('add gateway data to map.data', featureCollection)
              //TODO this doesn't appear to be async but doesn't render without. plus errors if exist are captured with async
                const addRes = await map.data.addGeoJson(featureCollection);

                debug && console.log('added gateway feautures', addRes)
            //    applyPolygoStyle(map);

            } else {
                debug && console.log('No map.data')
            }
            debug && console.log('add geoJson to map data ', geoJson);
            gateways.forEach( (gateway) => labelGateway(gateway) )
        } else {
            debug && console.log('No Items')
        }
        debug && console.log('exit setterFn')
    };


    
    cellTokens.forEach( async (token: string) => {
        const query = getQueryParams(token)
        const results = await AppDao.getMainTableQueryResults(setterFn, query);
        debug && console.log('getS2PointsByBounds results', results)
    } )

    

   

}