import ApiGateway from '../../../../../data/aws/api-gateway/ApiGateway';
import { ValidBusinessObject, PathDirection, QueryTypes, ValidModelObject } from '@iotv/datamodel-core';
import { ErrData, TraverserQueryPathBody, TypeHierarchyRequest, TypeHierarchyResponse, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { DMPStage } from '../DroughtManagement/DMPExtrasV2';
import { getObjectHistory } from '../../../../../data/daoFunctions/historyFns';

const debug = process.env.REACT_APP_DEBUG && false;

/**
 * Fetches the communities related to the customer object
 * @param contextCustomer ValidBusinessObject for the current customer
 * @returns
 */
export const getCustomerHierarchy = async (contextCustomer: ValidModelObject<'Customer'> | undefined) => {
  let res: ErrData<TypeHierarchyResponse> = { err: null, data: null };
  if (contextCustomer) {
    const queryBody: TypeHierarchyRequest = {
      apexNode: contextCustomer,
      edgeParams: {
        objectTypeId: 'Customer',
      },
      maxIterations: 10,
    };

    const path = '/typeHierarchy/';
    res = await ApiGateway.post(path, queryBody);
  }

  return res;
};

/**
 * Fetching tanks linked to the provided community.
 * The community object is then updated a 'tanks' field to store a list of its tanks
 * @param communityObject Community object for the community to fetch tanks for
 */

/*
export const fetchCommunityTanks = async (communityObject : ValidBusinessObject) => {
   const simpleTypePath = [
       { type: 'Customer', direction: PathDirection.child },
       { type: 'Tank', direction: PathDirection.child },
     ]

   const queryBody: TraverserQueryPathBody = {
   type: QueryTypes.directedPath,
   simpleTypePath: simpleTypePath,
   normalize: false,

   items: [communityObject] // CHILD should be from Datastore.pathDirection
   };

   const path = '/query/';
   const { err, data } = await ApiGateway.post(path, queryBody);
   // return { err, data };
   if(err == null && data != null){
       communityObject.tanks = data.filter((data : ValidBusinessObject) => data.type === 'Tank')
   }
}
*/

/*

export const fetchDroughtManagementPlanStage = async (communityObject : ValidBusinessObject) => {
    const simpleTypePath = [
        { type: 'DroughtManagementPlan', direction: PathDirection.parent },
        //{ type: 'DroughtManagementPlanStage', direction: PathDirection.child },
      ]

    const queryBody: TraverserQueryPathBody = {
    type: QueryTypes.directedPath,
    simpleTypePath: simpleTypePath,
    normalize: false,

    items: [communityObject] // CHILD should be from Datastore.pathDirection
    };

    const path = '/query/';
    const { err, data } = (await ApiGateway.post(path, queryBody)) as ErrData<ValidBusinessObjectList>;

    debug && console.log('CommunitiesView fetchDroughtManagementPlanStage', { err, data })

    if(err == null && data != null){
        let communityCurrentStage: DMPStage | undefined = undefined
        const dmp = data.find( (item) => item.type === 'DroughtManagementPlan') as undefined | { stages: DMPStage[ ]}
        if ( dmp?.stages ) {
            communityCurrentStage = dmp.stages.find( ( stage ) => stage.stageNumber === communityObject.stageNumber)
            debug && console.log('CommunitiesView fetchDroughtManagementPlanStage setStage to ', communityCurrentStage)
        }
        // const planStages = data.filter((stage : ValidBusinessObject) => stage.type === 'DroughtManagementPlanStage')
        // const communityCurrentStage = planStages.find((stage : ValidBusinessObject)  => stage.stageNumber === communityObject.stageNumber)
        communityObject.droughtManagementPlanStage = communityCurrentStage
    }
}


*/

/*
export const fetchCommunityHistory = async (communityObject : ValidBusinessObject) => {
    const communityHistoryRes = await getObjectHistory(communityObject)

    if(communityHistoryRes.data){
        const communityHistory = communityHistoryRes.data
        communityObject.history = communityHistory
        debug && console.log('CommunitiesView fetchCommunityHistory put on communityObject.history', communityHistory)
    } else {
        debug && console.log('CommunitiesView fetchCommunityHistory probably got err', communityHistoryRes)
    }
}
*/
export const buildAdjacencyList = async (contextCustomer: ValidBusinessObject, communities: ValidBusinessObjectList) => {
  const communityItems = communities; // await Promise.all(communities.map(async community => {return (await getOne(community)).data}))
  console.log('communityItems', communityItems);
  console.log('communities', communities);

  const adjacencyPairs = communities
    .map((community) => {
      const parent = communityItems.find((comm) => (comm ? comm.sk === community.pk : undefined));
      const child = communityItems.find((comm) => (comm ? comm.sk === community.sk : undefined));

      if (parent && child) return [parent.name, child.name];
    })
    .filter((pair) => pair);
  contextCustomer && adjacencyPairs.push(['-', contextCustomer?.name]);
  return adjacencyPairs;
};
