import { camelToDisplay, CustomerAccountType, GeofencePointsType, HistoryStore, ObjectHistory, roundDecimals, ValidBusinessObject } from '@iotv/datamodel';
import { Address }   from '@iotv/datamodel';
import { deviceEnabledTypes, flattenObject } from '@iotv/datamodel';
import { ThingShadowState } from '@iotv/datamodel';
import {  LineChartXType, MapLike } from '../types/AppTypes';
import { AppValueTypes, HistoryObjectType, isHistoryObject, SortOrder, StateSnapshot } from '@iotv/iotv-v3-types'
import {  Alert  } from '@iotv/datamodel';
import config from '../config';



const debug = process.env.REACT_APP_DEBUG && false;

export const indexKeyToTypeAndId = (indexKey: string) => {
  const firstIdx = indexKey.indexOf(':');
  if (firstIdx !== -1) {
    const skAsTypeId = { type: indexKey.substr(0, firstIdx), id: indexKey.substr(firstIdx + 1 ) }
    debug && console.log('indexKeyToTypeAndId', skAsTypeId)
    return skAsTypeId;
  }
  else return undefined;
}

export const  extractAllStateAttributes = function(this: ValidBusinessObject | undefined) {
    const metrics = this?.payload?.state?.reported;
    const calculated = this?.payload?.state?.calculated;
    return Object.assign({}, metrics, calculated);
}

export const getPositionFromTSS = (item: ValidBusinessObject | undefined) => {
  const position = item?.payload?.state?.reported?.position || item?.state?.reported?.position;
  return position;
}

export const getTimestampFromTSS = (tss: ThingShadowState ) => {
  let timestamp = undefined;
  const timestampVal = tss.timestamp || tss.payload.timestamp;
  let timestampNum = undefined;
  if ( typeof timestampVal === 'string') {
    timestampNum = parseInt(timestampVal);
  } else if ( typeof timestampVal === 'number') {
    timestampNum = timestampVal
  }

  if (timestampNum ) {
    const length = Math.log(timestampNum) * Math.LOG10E + 1 | 0;
    if ( length === 10 ) {
      timestampNum *= 1000;

    } else if ( length !== 13) {
      debugger
    }
    timestamp = timestampNum
  }


  return timestamp;
}

export const thingShadowStateToStateSnapshot = ( tss: ThingShadowState, filteredKeys?: string[] ) => {
  const timestamp = getTimestampFromTSS(tss as ThingShadowState)
  const matchedKeyValues = tss ? flattenObject(tss, filteredKeys) : {};
  return {
    timestamp,
    state: {
      ...matchedKeyValues

    }
  } as StateSnapshot<any>
}

export const isDeviceEnabledType = (item: ValidBusinessObject | undefined ) => {
    return (item && Object.keys(deviceEnabledTypes).includes(item.type))
}

export const getDeviceEnabledTypes = () => Object.keys(deviceEnabledTypes);

export const filterActiveAlerts = ( alerts: (ValidBusinessObject & typeof Alert)[]) =>  alerts.filter( (alert) => {

  return [
    'ISSUED_NO_ACK',
  ].includes(alert._state )
})

export const filterDisplayAlerts = ( alerts: (ValidBusinessObject & typeof Alert)[]) =>  alerts.filter( (alert) => {
  return [
    'NO_ALERT',
    'ISSUED_NO_ACK',
    'ISSUED_ACKNOWLEDGED',
    'RESOLVED',
    'CANCELLED'
  ].includes(alert._state )
})



export const isLocatable = ( item: ValidBusinessObject | undefined) => {
  return item !== undefined && ['Farm', 'Location', 'Geofence', 'User'].includes(item.type);
}

export function getGeoCodeableAddress(this: ValidBusinessObject) { 
  debug && console.log('In getGeoCodeableAddress', this); 
  return Object.keys(Address).map( (key) => this[key]).filter( (entry) => entry).join(',') 
};

export const getLocatableAddress = (object: ValidBusinessObject | undefined ) => {
  return isLocatable( object ) ? getGeoCodeableAddress.call(object as ValidBusinessObject) : undefined;
}

export const extractPolylineFromHistoryStore = ( historyStrore: HistoryStore, contextSk: string ) => {
  const polyline: GeofencePointsType[]  = []
  debug && console.log('History store', historyStrore)
  if ( historyStrore && historyStrore.lat && historyStrore.lng ) {
    const stateSnapshots = historyStrore.getStateSnapshotArray(SortOrder.ASCENDING) as MapLike[];
    stateSnapshots.forEach( ( stateSnapshot, i ) => {
      if (stateSnapshot.state?.lat && stateSnapshot.state?.lng ) {
        polyline.push( {
          lat: stateSnapshot.state.lat ?? 0,
          lng: stateSnapshot.state.lng ?? 0,
          DOP: stateSnapshot.state?.DOP,
          heading: stateSnapshot.state.heading,
          speed: stateSnapshot.state.speed,
          annotation: `${i} at ${stateSnapshot.timestamp}`,
          id: `${contextSk}_${i}:${stateSnapshot.timestamp}`,
          timestamp: stateSnapshot.timestamp
        })
      }
     
    })
  } 
  debug && console.log('Polyline', polyline)
  return polyline
} 

export const extractGraphData = (thingStatesMostRecentFirst: ThingShadowState[]): HistoryObjectType => {
    const historyArrLength = thingStatesMostRecentFirst.length;
    debug && console.log(`Array length would be ${historyArrLength}`)
    const historyObject: HistoryObjectType = {index: Array(historyArrLength), timestamp:  Array(historyArrLength)}
    const invalidKeys: string[] = [];
    thingStatesMostRecentFirst.filter((item) => item.type = 'ThingShadowState').reverse().forEach((tss, tssIdx) => {
      const convertedTs = getTimestampFromTSS(tss);
      if ( convertedTs) {
        historyObject.timestamp[tssIdx] =convertedTs ;

        historyObject.index[tssIdx] = tssIdx;
        const metrics = extractAllStateAttributes.apply(tss as ValidBusinessObject);

        Object.entries(metrics).forEach(([metricKey, metricValue], i) => {
          if (typeof metricValue !== 'number' && !(invalidKeys.includes(metricKey))) {
           // console.log(`We are invalidating key ${metricKey} because ${metricValue} is not a number`)
            invalidKeys.push(metricKey);
          }
         
          if (!(historyObject[metricKey])) {
            historyObject[metricKey] = [];
            if (i > 0) {
              debug && console.log(`We not are filling ${metricKey} from ${getNiceDate(historyObject.timestamp[0])} to ${tssIdx} ${getNiceDate(historyObject.timestamp[tssIdx])}`)
              //historyObject[metricKey].fill(undefined, 0, i);
            }
          }
          if ( isNaN(metricValue as number) ) {
            if ( !(invalidKeys.includes(metricKey))) {
              debug && console.log(`We knowingly are about to push ${JSON.stringify(metricValue)} onto history object for ${metricKey}`)
            }
            
          }
          if ( historyObject[metricKey]  ) (historyObject[metricKey] as any[])[tssIdx] = metricValue;
        });
      } else {
         debug && console.log(`timestamp for ${tss} failed`)
      }
    });
     
    invalidKeys.forEach((k) => {
      debug && console.log(`We are removing ${k} because it was invalidated `)
      delete historyObject[k]
    });
    debug && console.log(`last time for graph is ${getNiceDate(historyObject.timestamp[historyObject.timestamp.length -1 ])}`)
    debug && console.log(`history length is ${historyObject.timestamp.length}, roll metric length is ${ (historyObject.rolls as number[])?.length}`)
    return historyObject
  }

  export const convertHistoryObjectToDataTable  = (historyObject: HistoryObjectType, keys: string[], xType: LineChartXType, slice?: number[]): google.visualization.DataTable => {
    const dataTable = new google.visualization.DataTable();
    if (xType === LineChartXType.INDEX)  {
      dataTable.addColumn('number', 'Index'); // x
    } else if (xType === LineChartXType.TIMESERIES) {
      dataTable.addColumn('datetime', 'Time'); // x
    } else {
      throw new Error('No XType for chart')
    }

    const existingKeys = keys.filter( (key) => historyObject[key])
  
    existingKeys.forEach( (k: string, columnIdx: number) =>  {
      dataTable.addColumn('number', k); // yi
    });

    const indices = historyObject.index;

    const invalidColumnIndexes: number[] = [];

    indices.forEach( (idx) => {
      if ( !(slice) || (idx >= slice[0] && idx <= slice[1]) ) {
        let rowData: any[] = [];
        if (xType === LineChartXType.INDEX)  {
          rowData.push(idx);
        } else if (xType === LineChartXType.TIMESERIES) {
          const convertedTimestamp = historyObject.timestamp[idx];
          
          
          const convertedDate = new Date(convertedTimestamp);

          rowData.push(convertedDate);
        } else {
          throw new Error('No XType for chart')
        }
       
        existingKeys.forEach( (k: string, columnIdx: number) => {
          const keyArray = historyObject[k] as any[];
          if (keyArray) {
            const data = keyArray[idx];
            if ( isNaN(data) ) {
              // debug && console.log(`We knowingly pushed ${data} onto ${k}: ${idx} @ ${getNiceDate(historyObject.timestamp[idx])}  `)
              // debug && console.log(`history for key length is ${keyArray.length} index was ${idx}, total history length ${historyObject.timestamp.length}`)
            }
            const nan = typeof keyArray[idx] === 'string' ||  typeof keyArray[idx] === 'object';
            const valueToPush = nan ? 0 : keyArray[idx]
            if ( nan && !invalidColumnIndexes.includes(columnIdx)) invalidColumnIndexes.push(columnIdx)
            rowData.push(valueToPush)
          } else {
            debug && console.log(`No key array ${k} on `, historyObject)
          }
         
        })
        dataTable.addRow(rowData);
        
      }
    
    })
    
    debug && console.log('invalidColumns', invalidColumnIndexes)
    //invalidColumnIndexes.forEach( (idx: number, i) => dataTable.removeColumn(idx - i))

    return dataTable
  }

  export const getNiceDate = (timestamp: number | string, format: Intl.DateTimeFormatOptions = {weekday: 'short', day: 'numeric',  month: 'short' }) => {
    let timestampNum = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
    let timestampMsSecs: number  = timestampNum < 9999999999 ? timestampNum * 1000 : timestampNum;
    const datetime = new Date(timestampMsSecs);
    const nowString = new Date().toLocaleDateString(config.locale , format);
    const thenStrting = datetime.toLocaleDateString(config.locale , format)
    const isToday = nowString === thenStrting;

    const dateSt = format.weekday!== 'short' && isToday ? 'Today' : thenStrting;
    const timeSt = datetime.toLocaleTimeString(config.locale, {hour: 'numeric', minute: 'numeric'});
    return `${dateSt} ${timeSt}`.replace(',','')
  }




  export const toDatePickerFormat = ( timestampMs: number ) => {
   const iso = new Date(timestampMs).toISOString();
   const firstSep = iso.indexOf(':');
   const cutIdx = firstSep + 3
   return iso.substr(0, cutIdx)
  }

  export const getValueLabel = (text: string) => camelToDisplay(text.split('_').join(' '))



  /* BTO Addition or adaption */

  export const extractGraphDataFromTss = (graphArray: any): HistoryObjectType => {
    graphArray = graphArray? graphArray : []; 
  
    const historyArrLength = graphArray.length; 
    // const historyArrLength = graphArray? graphArray.length:0; 
    const historyObject: HistoryObjectType = { index: Array(historyArrLength), timestamp: Array(historyArrLength) }
    const invalidKeys: string[] = [];
    graphArray.forEach((tss: any, tssIdx: any) => { 
      let convertedTs = getTimestampFromTSS(tss)  ;
      if (convertedTs) { 
          
  
        Object.entries(tss).forEach(([metricKey, metricValue], i) => {
          if (typeof metricValue !== 'number' && !(invalidKeys.includes(metricKey))) invalidKeys.push(metricKey); 
           if (!(historyObject[metricKey])) {
            historyObject[metricKey] = [];
            if (i > 0 && historyObject &&  historyObject[metricKey] instanceof Array) ( historyObject[metricKey] as any[] ).fill(undefined, 0, i); 
          }
          if ( historyObject[metricKey]  ) (historyObject[metricKey] as any[])[tssIdx] = metricValue;
        });
  
        historyObject.timestamp[tssIdx] = convertedTs;
        historyObject.index[tssIdx] = tssIdx; 
      }
      else {
        // debug && console.log(`timestamp for ${tss} failed`)
      }
    }); 
   
    invalidKeys.forEach((k) => {
      // debug && console.log(`We are removing ${k} because it was invalidated `)
      delete historyObject[k]
    });
    return historyObject
  }

  export type PsuedoVBORequestType = { id: string, type: string } & MapLike

  export const getPseudoVBO = ({ id, type, name, ...others }: PsuedoVBORequestType ): ValidBusinessObject => {
    const key = `${type}:${id}`;
    return {
      sk: key, pk: key, name: name ?? id, type, id, ...others
    }
  }

  export const customerSort = ( ob1: ValidBusinessObject, ob2: ValidBusinessObject ) => {
    const precenence = [ CustomerAccountType.V, CustomerAccountType.X, CustomerAccountType.Y, CustomerAccountType.Z]
    const ob1Idx = precenence.findIndex( ( accountType ) => accountType === ob1?.accountType ?? 'none' as CustomerAccountType) ?? 99
    const ob2Idx = precenence.findIndex( ( accountType ) => accountType === ob2?.accountType ?? 'none' as CustomerAccountType) ?? 99

    return ob1Idx - ob2Idx

  }

  export const initializeObjectHistory = ( objectHistory: ObjectHistory ) => {
    const nonHistoryStoreKeys: string[] = [ 'type', 'sk', 'pk', 'id', 'metadata', 'name']
    const t0 = Date.now()
    Object.entries(objectHistory).filter( ([k, v]) => !nonHistoryStoreKeys.includes(k)).forEach( ([k, v]) => {
       if ( !( v instanceof HistoryStore && isHistoryObject(v) )) {
        objectHistory[k] = new HistoryStore(v)
       }
    })

    debug && console.log(`initialized objectHistory with keys: ${ Object.keys( objectHistory ).join(' ') } in ${ roundDecimals( ( Date.now() - t0 ) / 1000, 2 )}secs`)
 

    return objectHistory
  }

  export const ducktype = (key: string, value: any): AppValueTypes => {
    let type = AppValueTypes.STRING;
    if (!isNaN(parseFloat(value))) {
        type = AppValueTypes.NUMBER
    } else if (([false, true] as any[]).includes(value)) {
        type = AppValueTypes.BOOLEAN // would need to change value
    }
    if (key === 'timestamp') {
        type = AppValueTypes.TIMESTAMP_MS // needs to be string fmt used in date
    }
    return type
}