import { ValidBusinessObject } from '@iotv/datamodel';
import { AreaManagementMode, DispatchFunctionsType } from '../../../../types/AppTypes';
import { deleteObject } from '../../../../data/daoFunctions/daoFunctions';

const debug = process.env.REACT_APP_DEBUG && false;

export const selectorUnlink = (
  mode: AreaManagementMode,
  selectedArea: ValidBusinessObject | undefined,
  currentLinkedArea: ValidBusinessObject | undefined,
  newPlace: ValidBusinessObject | undefined,
  transactionFunctions: DispatchFunctionsType | undefined
) => {
  debug && console.log('selectorUnlink', mode, selectedArea, currentLinkedArea, newPlace)
  // if (mode === AreaManagementMode.EDIT && selectedArea === undefined && currentLinkedArea && newPlace) {
  //     // parent go first
  //     transactionFunctions?.unlink(currentLinkedArea, newPlace)
  // }

  if (mode === AreaManagementMode.EDIT && currentLinkedArea && newPlace) {
    // parent go first
    transactionFunctions?.unlink(currentLinkedArea, newPlace)
  }
}

export const removeFromStateList = (state: ValidBusinessObject[] | undefined, obj: ValidBusinessObject | undefined, valueSetter: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>) => {
  var foundIndex = state?.findIndex((val) => val.pk === obj?.pk);
  debug && console.log('remove index', foundIndex);

  if (foundIndex !== -1 && foundIndex !== undefined && state) {
    state.splice(foundIndex, 1);
    valueSetter(state);

    debug && console.log('return list', state)
  }
}

export const deleteExistingObject = (existingObject: ValidBusinessObject, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  deleteObject(existingObject);
  setOpen(false);
}