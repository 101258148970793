import { withStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DeviceProvisionViewProps } from '../types/AppTypes';
import styles from '../cosmetics/sharedViewStyles';

import DeviceProvisioningCard from './DeviceProvisioningCard';

function DeviceProvisioningView( props: DeviceProvisionViewProps  ): JSX.Element {

  const debug = process.env.REACT_APP_DEBUG && false;

    const [ spacing ] = React.useState(2);
    const [ requestedInit, setRequestedInit] = React.useState(false);

    const routerHistory = useHistory()

    const { viewObject, searchParams, user, userSelectedGroup, userGroupRoles,  contextCustomer, transactionFunctions, userFunctions, history, match, adjacentFilter,optionals} = props;

    debug && console.log('DeviceProvisioningView Search Params', { searchParams, routerHistory, match })

    return (
      <div>
         {
                (viewObject && contextCustomer && <DeviceProvisioningCard { ...{viewObject, match, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, adjacentFilter ,userFunctions, history, optionals}}></DeviceProvisioningCard>)
              }

            

     </div>
    );
    
    
}
export default withStyles(styles)(DeviceProvisioningView)