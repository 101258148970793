import { Customer, DatastoreObjectType, DeviceProps, DeviceProvisioningRecordType, getObjectHash, roundDecimals, ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes, CoreApplicationObjectType } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, TableCell, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteSweepRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import QueueIcon from '@material-ui/icons/Queue';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { useDocumentClientQuery } from '../../../../hooks/useDocumentClientQueryV1';
import { AdjacentType, CustomerDeviceContextTabProps, DeleteDeviceRequest, DevicePoolType, ListDevicesRequest, ListerlizerProps, ListerlizerRowControlFnProps, MessageOutputType, UnassignDeviceFromCustomerRequest, UserTransactionType, ViewDefinition, ViewKeyDefinitionType, ViewObject } from '../../../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../factories/DocumentClientListerlizerWrapper';
import { NavSections } from '../../../navigation/NavSections';
import { useEuiContainsFilter } from './hooks/useEuiContainsFilter';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';
const componentClassName = 'CustomerDeviceListTab'

function CustomerDeviceListTab({ contextCustomer, parentCustomer, user, userFunctions, history, transactionFunctions, match: { params: { action } }
  , devicePoolType, refreshParent, relatedViewRefreshCount }: CustomerDeviceContextTabProps): JSX.Element {

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const [ availableDeviceEuis, setAvailableDeviceEuis ] = React.useState<string[]>([])

  const listDevices = async (fn: (params: any) => void, contextRequest: ListDevicesRequest) => {
    const urlPart = devicePoolType === DevicePoolType.INVENTORY ? 'listInventoryDevices' : devicePoolType === DevicePoolType.ALLOCATION ? 'listAllocatedDevices' : null;
    if (urlPart) {
      try {
        const response = await ApiGateway.post(`/system/Device/${urlPart}`, contextRequest, apiRef);
        debug && console.log(`CustomerDeviceListTab query results with devicePoolType ${devicePoolType }`, response)
        fn(response);
      } catch (e) {
        debug && console.log('Error in UM query', e)
      }
    }
  }

  const removeFromDatabase = async (device: ValidBusinessObject | undefined) => {
    if (device && contextCustomer) {
      const response = await ApiGateway.post('/system/Device/deleteDevice', { contextUser: user, contextCustomer, device, devicePoolType: DevicePoolType.INVENTORY } as DeleteDeviceRequest, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        displayMessage(`Removed ${device?.name} from datastore`, 'SnackBar')
      }
    }
    return Response;
  }

  const deleteMultipleDevices = async () => {
    const promises = selectedObjects.map((device) => removeFromDatabase(device as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('Delete multople res', res);
    setSelectedObjects([]);
    forceUpdate()
  }

  const getQueryParams = ( euiContainsFilter: string | undefined = undefined ): ListDevicesRequest | undefined => {
    let queryParams:  ListDevicesRequest | undefined = undefined 

    if ( user && contextCustomer  ) {
      queryParams =  { ExclusiveStartKey: undefined,
        contextUser: user as ValidBusinessObject & { type: 'User' },
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
        filter: undefined,
        euiContainsFilter 
      }
    } 

    return queryParams


  }

  const contextCustomerSk = contextCustomer?.sk
  const {
    state: [ euiContainsFilter ],
    component: EuiContainsFilter
  } = useEuiContainsFilter({ availableDeviceEuis })

  const queryParams = getQueryParams( euiContainsFilter )
  const queryHook = useDocumentClientQuery(listDevices, queryParams, [relatedViewRefreshCount, contextCustomerSk, euiContainsFilter ])

  const [[items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects] = queryHook.selectionControls;
  const forceUpdate = queryHook.forceUpdate

  const itemsHash = getObjectHash( items )
  useEffect( () => {
    setAvailableDeviceEuis( items.map( (item) => item.id ))
  }, [itemsHash ])

  const routerHistory = useHistory();
  const contextCustomerInstance = contextCustomer && new Customer(contextCustomer)

  const unAllocateDevice = async (device: ValidBusinessObject) => {
    const contextUser = user;
    if (contextUser?.type === 'User') {
      try {

        const request: UnassignDeviceFromCustomerRequest = {
          contextUser: contextUser as ValidBusinessObject & { type: 'User' },
          contextCustomer: parentCustomer as ValidBusinessObject & { type: 'Customer' },
          unAssignmentCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
          device: device as ValidBusinessObject & { type: 'Device' },
        }
        debug && console.log('unAllocateDevice request', request)
        const response = await ApiGateway.post(`/system/Device/unassignDeviceFromCustomer`, request, apiRef);
        debug && console.log('unAllocateDevice results', response)
        if (response.data) {
          debug && console.log('row component will try and get re-rendereed list???', { device })
          //setLastAllocatedDeviceSk(device.sk)
          //routerHistory.push(`${routerHistory.location.pathname}?tabName=Devices`)
          forceUpdate();
          refreshParent && refreshParent()
        } else if (response.err) {
          displayMessage(response.err.message, 'MessageBar')
        } else {
          throw new Error(`bad result received: ${JSON.stringify(response, null, 1)}`)
        }
      } catch (e) {
        debug && console.log('Error in CM query', e)
      }
    } else {
      debug && console.log('No context user')
    }
  }

  const unallocateMultipleDevices = async () => {
    const promises = selectedObjects.map((device) => unAllocateDevice(device as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('unallocate multople res', res);
    setSelectedObjects([]);
    forceUpdate()
  }


  const getLastAllocationCustomerSk = (device: ValidBusinessObject) => device.provisioning?.[0]?.allocationCustomer?.sk

  const getLastInventoryCustomerSk = (device: ValidBusinessObject) => device.provisioning?.[0]?.inventoryCustomer?.sk

  const isAllocatedToThisCustomer = (device: ValidBusinessObject) => getLastAllocationCustomerSk(device) === contextCustomer.sk
  const isInventoryOfThisCustomer = (device: ValidBusinessObject) => getLastInventoryCustomerSk(device) === contextCustomer.sk



  const viewObject: ViewObject = {
    matchedPrimary: contextCustomer,
    matchedRelatedByPk: [],
    matchedRelatedBySk: []
  }

  const lastAllocationCustomerValueGetter = (provisioning: DeviceProvisioningRecordType[]) => (provisioning?.[0]?.allocationCustomer as ValidBusinessObject)?.name

  type DeviceAttributeKeys = keyof DeviceProps
  const deviceListViewDefs: ViewDefinition & Omit<{ [key in DeviceAttributeKeys & 'name']: ViewKeyDefinitionType }, 'nothing'> = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, valueGetter: (s: string) => s.substring(0, 10)  },
    nw: { key: 'networkKey', label: 'nw', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    eui: { key: 'eui', label: 'EUI', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    isAWOL: { key: 'isAWOL', label: 'Is AWOL', type: AppValueTypes.BOOLEAN, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    lastSeen: { key: 'lastSeen', label: 'Last seen', type: AppValueTypes.DATE, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    lastSendPeriod: { key: 'lastSendPeriod', label: 'period', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, valueGetter: (v: number) => typeof v === 'number' ? `${roundDecimals(v / 60000, 0)} min` : 'Not set' },
    fCnt: { key: 'messageUplinkCount', label: 'fCnt', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, },

    customer: { key: 'provisioning', 'label': 'Allocated To', type: AppValueTypes.STRING, editable: false, valueGetter: lastAllocationCustomerValueGetter },
    billingGroup: { key: 'billingGroup', label: 'Billing Group', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    state: { key: 'state', label: 'Device Status', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
   _permittedAWOL: { key: '_permittedAWOL', label: 'Max AWOL', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, valueGetter: (v: number) => typeof v === 'number' ? `${roundDecimals(v / 60000, 0)} min` : 'Not set' },
    
  }



  const deviceListProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Device',
      edgeFilter: undefined
    },
    maxItems: 20,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: deviceListViewDefs,
    viewObject,
    contextObject: undefined,
  }



  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<ListDevicesRequest> = queryParams ? {
    ...deviceListProps, queryHook, queryParams,
    queryControls: () => [

      <ButtonGroup>
        {selectedObjects.length > 0 && contextCustomerInstance.canHaveDeviceInventory() &&
          <Button
            size='small'
            onClick={() => deleteMultipleDevices()}
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="primary"
          >
            Delete selected
          </Button>
        }
        {contextCustomerInstance.canHaveDeviceInventory() &&
          <Button
            size='small'
            onClick={() => routerHistory.push(createUrl)}
            disabled={action === UserTransactionType.CREATE}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
          >
            Device
          </Button>

        }
        {contextCustomerInstance.canBeAllocatedDevices() && selectedObjects.length > 0 &&
          <Button
            size='small'
            onClick={() => unallocateMultipleDevices()}
            startIcon={<QueueIcon />}
            variant="outlined"
            color="primary"
          >
            Unallocate selected
          </Button>

        }

      </ButtonGroup>,
       EuiContainsFilter,
    ],
    rowControlOverrideFns: {
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell {...{
              key: `${listRowProps.rowObject.sk}_goto`, onClick: () => {
                const viewUrl = queryString.stringifyUrl({
                  url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Device/${listRowProps.rowObject.sk}`,
                  query: {
                    contextObjectSK: contextCustomer.sk
                  }
                });
                routerHistory.push(viewUrl)
              }
            }}>
              <PlayArrowIcon {...{

              }} />
            </TableCell>

          ]
        }
      ],
      last: [(listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
        return contextCustomerInstance.canBeAllocatedDevices() ? [
          <TableCell {...{ key: `${listRowProps.rowObject.sk}_unallocate` }} >
            {isAllocatedToThisCustomer(listRowProps.rowObject)
              ? <Button {...{ variant: "contained", color: "primary", onClick: () => unAllocateDevice(listRowProps.rowObject) }} >Unallocate</Button>
              : <Button {...{ variant: "contained", color: 'secondary', onClick: () => routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${getLastAllocationCustomerSk(listRowProps.rowObject)}?tabName=Devices`,) }} >{`Find Owner`}</Button>}

          </TableCell>
        ] : [
          <TableCell {...{ key: `${listRowProps.rowObject.sk}_unallocate` }} >
            {getLastAllocationCustomerSk(listRowProps.rowObject) === undefined
              ? null
              : <Button {...{ variant: "contained", color: 'secondary', onClick: () => routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${getLastAllocationCustomerSk(listRowProps.rowObject)}?tabName=Devices`,) }} >{`Find Owner`}</Button>}

          </TableCell>
        ]
      }]
    }
  } : undefined

  const createUrl = queryString.stringifyUrl(
    {
      url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}/Device/`,
      query: {
        objectTypeId: 'Device',
        contextObjectSK: contextCustomer.sk,
        accountType: contextCustomerInstance.accountType
      }
    }
  )

  return (
    <>

      <Grid className={componentClassName} container spacing={3} >
        {<Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h4" component="h2" gutterBottom>
                    {'Devices'}
                  </Typography>

                </Grid>

              </Grid>
              {documentClientListerlizerProps &&
                <DocumentClientListerlizerWrapper key={'deviceList2'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>
              }

            </CardContent>
          </Card>
        </Grid>}
      </Grid>
    </>
  );

}
export default withStyles(styles)(CustomerDeviceListTab)