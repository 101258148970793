import { ErrData } from "@iotv/iotv-v3-types";
import { ValidBusinessObject } from "@iotv/iotv-v3-types";
import { ValidBusinessObjectList } from "@iotv/iotv-v3-types";
import ApiGateway from "../../../../data/aws/api-gateway/ApiGateway";
import { AddUserRequest } from "../../../../types/AppTypes";
import { NavSections } from "../../../navigation/NavSections";

const debug = process.env.REACT_APP_DEBUG && false;

type AddToMultipleUsersRequest = {
    contextUser: ValidBusinessObject & { type: 'User' },
    contextCustomer: ValidBusinessObject & { type: 'Customer' },
    users: ValidBusinessObjectList
}

const apiRef = 'SystemManagement';

export const addMultipleUsersToUserPool = async ({ contextUser, contextCustomer, users }: AddToMultipleUsersRequest) => {
    const result: ErrData<string> = { err: null, data: null }
    if (contextUser) {


        const promises: ErrData<ValidBusinessObject>[] = await Promise.all(users.map((oneuser) => {
            const { temporaryPassword, ...user } = oneuser as Partial<ValidBusinessObject> & { temporaryPassword: string, username: string };
            debug && console.log('Adding user with', {  contextUser: user, user, contextCustomer})
            const addUserRequest: AddUserRequest = { user, temporaryPassword, contextUser, contextCustomer, addToGroups: [], makeAdmin: false }
            return ApiGateway.post('/system/User/add', addUserRequest, apiRef);
        }))
        let successes: number = 0, fails: number = 0, errMessage = '';
        promises.forEach((userRes) => {
            if (userRes.err) {
                fails++;
                errMessage += `${fails !== 0 ? ', ' : ''}${userRes.err.message}`;

            } else {
                successes++;
            }
        })
        if (fails > 0) {
            const msg = `${successes} users processed successfully but ${fails} users were not processed. \n${errMessage}`
            result.err = new Error(msg)
        } else {
            result.data = `${successes} users processed successfully`
        }


    }
    return result
}