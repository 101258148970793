import {
  AdjacentType,
  getNiceDate,
  HistoryStore,
  Milliseconds,
  ObjectHistory,
  PathDirection,
  QueryTypes,
  roundDecimals,
  ThingShadowState,
  ValidBusinessObject,
} from '@iotv/datamodel';
import { Tank } from '@v017/datamodel';
// import { APITypes, TraverserQueryPathBody } from '@iotv/iotv-v3-types';
import { TraverserQueryPathBody } from '@iotv/iotv-v3-types';
import { ButtonGroup, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { color } from 'd3-color';
import React, { useRef } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import Assembler from '../../../data/Assembler';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { getAdjacent, getAdjacent2Wrapper, GetAdjacentRequest, link, save } from '../../../data/daoFunctions/daoFunctions';
import { useContainerDimensions } from '../../../hooks/useContainerDimenstions';
import { GetAdjacentResponse, ObjectCardProps, UserTransactionType } from '../../../types/AppTypes';
import { getChildParent } from '../Queries';
import { getColour } from './CommunitiesView';
import AddTank from './CountryCommunity/AddTank';
import TankDataPage from './DataPage/TankDataPage';
import { paperStyles, useStylesTank } from './styles/tankStyles';
import { populateWeather } from './tankFns_exAXJ';

const debug = true;

// export default function TankDetailCard(props: ObjectShadowStateCardProps | any) {
export default function TankDetailCard(
  props: ObjectCardProps & { user: ValidBusinessObject; contextCustomer: ValidBusinessObject; userHouseList?: any[]; objectHistory?: ObjectHistory }
) {
  const theme = useTheme();
  const { viewObject, transactionFunctions, contextCustomer, searchParams, userFunctions, userGroupRoles, user, objectHistory } = props;
  const classes = useStylesTank();
  const TankStyle = paperStyles();
  const [editView, setEditView] = React.useState(false);
  const [attachedHouse, setattachedHouse] = React.useState('');
  const [attachedHouseValue, setattachedHouseValue] = React.useState({} as ValidBusinessObject);
  const [houseList, sethouseList] = React.useState<any>(props.userHouseList);
  const [collectionAreaobjData, setCollectionArea] = React.useState<ValidBusinessObject | undefined>(Assembler.getInstance({ type: 'CollectionArea' }));
  const [dataPageView, setDataPageView] = React.useState(false);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [update, setUpdate] = React.useState<boolean>(false);
  const [parent, setParent] = React.useState<ValidBusinessObject>();

  const gaugeGridRef = useRef(null);
  const { width: gaugeGridWidth, height: gaugeGridHeight } = useContainerDimensions(gaugeGridRef);
  const radius = (gaugeGridWidth / 2) * 0.9;
  const tank = viewObject.matchedPrimary ? new Tank(viewObject.matchedPrimary) : undefined;

  React.useEffect(() => {
    async function getAdjacent(object: ValidBusinessObject, type: string, direction: PathDirection): Promise<GetAdjacentResponse> {
      debug && console.log('DAO TRANSACTION: Get Adjacent', { object, type, direction });
      debug && console.log('viewObject?.matchedPrimary', viewObject.matchedPrimary);
      const queryBody: TraverserQueryPathBody = {
        type: QueryTypes.simplePath,
        simpleTypePath: [{ type, direction }],
        normalize: false,
        items: [object], // CHILD should be from Datastore.pathDirection
      };
      debug && console.log('Get Adjacent query', queryBody);

      const path = '/query/';
      const { err, data } = await ApiGateway.post(path, queryBody);
      debug && console.log('return attached Tank House', { err, data });
      return { err, data };
    }
    getAdjacent(viewObject?.matchedPrimary as ValidBusinessObject, 'House', PathDirection.parent).then(({ err, data }) => {
      debug && console.log('err:', err);
      debug && console.log('house attached to tank:', data);
      if (err == null && data != null) {
        var house_list: any = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === 'House') {
            debug && console.log('data[i].name', data[i].name);
            setattachedHouse(data[i].name);
            setattachedHouseValue(data[i] as ValidBusinessObject);
          }
        }
      }
    });

    console.log('Pre get CollectionArea');
    if (viewObject.matchedPrimary) {
      const tankZombie = { ...viewObject.matchedPrimary, pk: viewObject.matchedPrimary.sk };
      const getTankCollectionArea: GetAdjacentRequest = {
        scopeDefinitingContextObject: tankZombie,
        adjacencyType: AdjacentType.CHILD,
        objectTypeId: 'CollectionArea',
        includeEdges: false,
      };
      getAdjacent2Wrapper(getTankCollectionArea).then(({ err, data }) => {
        debug && console.log('CollectionArea with tank:', { tank: viewObject.matchedPrimary, data });
        //data?.Items && setCollectionArea(data.Items[0])
        if (data?.Items) {
          if (data.Items[0]) {
            //Sets the collection area to the one found in database if it exists
            setCollectionArea(data.Items[0] as ValidBusinessObject);
          } else {
            //Creates a new collection area and links to tank if one cannot be found
            const newCollectionArea = Assembler.getInstance({ type: 'CollectionArea' });
            linkAndSaveNewCollectionArea(newCollectionArea as ValidBusinessObject);
            setCollectionArea(newCollectionArea);
          }
          setEditable(true);
        }
      });
    } else {
      console.log('Skipping get CollectionArea');
    }
  }, []);

  React.useEffect(() => {
    viewObject.matchedPrimary &&
      getChildParent(viewObject.matchedPrimary, 'Customer').then((res) => {
        if (res.length === 1) {
          getAdjacent(res[0], 'Customer', PathDirection.parent).then((parent) => {
            if (parent.err === null && parent.data !== null && parent.data.length === 1) {
              setParent(parent.data[0]);
            }
          });
        }
      });
  }, []);

  /**
   * Saves and links a new Collection Area object to the tank.
   * Used for older tanks which may not have been assigned a collection area when created due to older requirements
   * @param newCollectionAreaObj
   */
  const linkAndSaveNewCollectionArea = async (newCollectionAreaObj: ValidBusinessObject) => {
    const tankObject = viewObject.matchedPrimary;

    const saveCollectionArea = await save(newCollectionAreaObj);
    const linkCollectionArea = await link(tankObject as ValidBusinessObject, newCollectionAreaObj, undefined);
  };

  var action: string | undefined;
  if (searchParams !== undefined) {
    action = searchParams.action;
  } else {
    action = 'X';
  }

  React.useEffect(() => {
    if (action?.localeCompare(UserTransactionType.CREATE_AND_LINK) === 0) {
      setEditView(true);
    }
  }, []);

  // useEffect(() => {

  // }, [dataPageView, objectHistory])

  debug && console.log('TankDetailsCard objectHistory', objectHistory);

  debug && console.log('TankDetailsCard weather_data', tank?.weather_data);
  debug && console.log('attachedHouse', attachedHouse);
  debug && console.log('action.localeCompare(UserTransactionType.CREATE_AND_LINK)==0');

  const thingShadowStates: ThingShadowState[] = viewObject?.matchedRelatedBySk
    ? viewObject?.matchedRelatedBySk
      .filter((item: ValidBusinessObject) => item.type === 'ThingShadowState')
      .map((item: ValidBusinessObject) => new ThingShadowState(item))
    : [];
  const formatNumber = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const { daily_forecast, weather_forecast } = viewObject.matchedPrimary
    ? populateWeather(viewObject.matchedPrimary)
    : { daily_forecast: undefined, weather_forecast: undefined };

  const color_of_silo = theme.palette.primary.main;

  const gradientStops = [
    {
      key: '0%',
      stopColor: color(color_of_silo).darker(0.5).toString(),
      stopOpacity: 1,
      offset: '0%',
    },
    {
      key: '50%',
      stopColor: color_of_silo,
      stopOpacity: 0.75,
      offset: '50%',
    },
    {
      key: '100%',
      stopColor: color(color_of_silo).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: '100%',
    },
  ];
  console.log(color_of_silo);

  const format: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' };
  const predictedRunOutDate = tank?.days_to_empty
    ? new Date(tank.days_to_empty * Milliseconds.DAY + Date.now()).toLocaleDateString('en-NZ', format)
    : undefined;

  const fillPercentage = tank ? roundDecimals(((tank.tankLevel ?? 0) / (tank.height ?? 1)) * 100, 0) : 0;


  const get24HourRainfall = (store: HistoryStore) => {
    let currentTime = new Date();
    let index = store.timestamp.findIndex((time) => time > currentTime.getTime() - 24 * 60 * 60 * 1000);
    if (index < 1) {
      return 0;
    }
    let intial = 0;
    let rainSum = (store.volume_collected_period as number[])
      .slice(index)
      .filter((val) => val !== undefined)
      .reduce((accumulator, current) => accumulator + current, intial);

    return rainSum;
  };

  return (
    <>
      {!dataPageView && action?.localeCompare(UserTransactionType.CREATE_AND_LINK) !== 0 && (
        <Card className={classes.root}>
          {fillPercentage !== undefined && fillPercentage > 100 && (
            <Box>
              <Typography variant='h6'> {'>'}100% full, please increase tank height.</Typography>
            </Box>
          )}
          <Grid direction='row' container item xs={12}>
            <Grid item {...{ xs: 12, sm: 2, ref: gaugeGridRef }}>
              <Grid item xs={12}>
                {/* <ButtonBase {...{ style: { height: '100%', width: '100%' } }}> */}
                <LiquidFillGauge
                  style={{ margin: '0 auto' }}
                  width={radius * 2}
                  height={radius * 2}
                  value={fillPercentage}
                  percent='%'
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  textRenderer={(props: any) => {
                    const value = Math.round(props.value);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = (props.textSize * radius) / 2;
                    const valueStyle = {
                      fontSize: textPixels,
                    };
                    const percentStyle = {
                      fontSize: textPixels * 0.6,
                    };

                    return (
                      <tspan>
                        <tspan className='value' style={valueStyle}>
                          {value}
                        </tspan>
                        <tspan style={percentStyle}>{props.percent}</tspan>
                      </tspan>
                    );
                  }}
                  riseAnimation={true}
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: getColour(fillPercentage),
                  }}
                  waveStyle={{
                    fill: color_of_silo,
                  }}
                  textStyle={{
                    fill: color('#444').toString(),
                    fontFamily: 'Arial',
                  }}
                  waveTextStyle={{
                    fill: color('#262626').toString(),
                    fontFamily: 'Arial',
                  }}
                  onClick={() => {
                    //this.setState({ value: Math.random() * 100 });
                  }}
                />
                {/* </ButtonBase> */}
              </Grid>

              <Grid item xs={12}>
                <ButtonGroup>
                  <Button
                    variant='contained'
                    {...{ disabled: !editable }}
                    onClick={() => {
                      setEditView(true);
                    }}
                  >
                    Edit Tank Details
                  </Button>
                  {userGroupRoles?.groups.includes('VentureTeam') && (
                    <Button
                      variant='contained'
                      disabled={false}
                      onClick={() => {
                        setDataPageView(true);
                        // alert('Data Page clicked')
                      }}
                    >
                      View Data Page
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid item {...{ xs: 12, sm: 5 }} className={TankStyle.root}>
              <Grid container spacing={2}>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography variant='h6' align='center'>
                        Current Volume
                      </Typography>
                      <Typography align='center' gutterBottom>
                        {formatNumber(Math.round(tank?.current_filled_volume ?? 0)) || '- '} L
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography align='center' variant='h6'>
                        Volume used Yesterday
                      </Typography>
                      <Typography align='center' gutterBottom>
                        {objectHistory?.dailyStateHistory?.usage_in_day !== undefined
                          ? formatNumber(Math.round(objectHistory?.dailyStateHistory?.usage_in_day.slice(-1)[0] ?? 0))
                          : 0}{' '}
                        L
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography align='center' gutterBottom variant='h6'>
                        Days supply left
                      </Typography>
                      <Typography align='center' gutterBottom>
                        {viewObject.matchedPrimary?.days_to_empty ? viewObject.matchedPrimary.days_to_empty + ' days' : 'Calibrating Predictions'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography variant='h6' align='center'>
                        Run Out Date
                      </Typography>
                      <Typography align='center'>{predictedRunOutDate ? `${predictedRunOutDate}` : '-'}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography align='center' variant='h6'>
                        Date of last fill
                      </Typography>
                      <Typography align='center' gutterBottom>
                        {tank?.lastFillTimestamp ? getNiceDate(tank.lastFillTimestamp) : '-'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item direction='column' xs={6} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography variant='h6' align='center'>
                        Rainfall (24hr)
                      </Typography>
                      <Typography align='center'>
                        {/* {formatNumber(Math.round(objectHistory?.dailyStateHistory?.volume_collected_yesterday.slice(-1)[0] ?? 0))} mm */}
                        {objectHistory?.recentStateHistory && formatNumber(Math.round(get24HourRainfall(objectHistory.recentStateHistory)))} mm
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card className={TankStyle.weather}>
                  <CardContent>
                    <Typography variant='body2'>Predicted weather - next 7 days</Typography>
                    {viewObject.matchedPrimary?.weather_data ? (
                      <Box
                        display='flex'
                        flexWrap='nowrap'
                        //p={1}
                        //m={2}
                        bgcolor='background.paper'
                        css={{ maxWidth: 450 }}
                      >
                        {weather_forecast &&
                          weather_forecast.map((forecast) => (
                            <Box p={1} bgcolor='grey.300'>
                              <img className={TankStyle.rain} src={forecast.img} /> {forecast.day}
                            </Box>
                          ))}
                      </Box>
                    ) : (
                      <Box>
                        <Alert severity='warning'>Weather data not available!</Alert>
                      </Box>
                    )}

                    {/* <Typography variant='body2' gutterBottom>
                      House:
                    </Typography>

                    <TextField
                      id='outlined-size-small'
                      InputProps={{ readOnly: true }}
                      value={attachedHouse}
                      //onChange={(e) => setTotalVolume(e.target.value)}
                      //value={total_volume_of_silo}
                      //className={classes.spaceHouse}
                      variant='outlined'
                      size='small'
                    /> */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      {dataPageView && objectHistory && (
        <div>
          <TankDataPage
            viewObject={viewObject}
            classes
            objectHistory={objectHistory}
            recentStateHistory={objectHistory?.recentStateHistory}
            transactionFunctions={transactionFunctions}
            history
            searchParams //viewDefinition ={viewDefinition}
            returnTankCard={() => setDataPageView(false)}
          />
        </div>
      )}
      {editView && (
        <AddTank
          showScreen={setEditView}
          setUpdate={setUpdate}
          source={parent}
          userFunctions={userFunctions}
          contextUser={user}
          transactionFunctions={transactionFunctions}
          existing={viewObject.matchedPrimary}
        />
      )}
    </>
  );
}
