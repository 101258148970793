import { getZombieInstanceFromPrimaryKey, ObjectHistory } from '@iotv/datamodel-core'
import { AdjacentType, HistoryObjectType, ValidBusinessObject } from '@iotv/iotv-v3-types'
import { Card, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getAdjacent2Wrapper, getOne } from '../../../../../data/daoFunctions/daoFunctions'
import { LineChartXType } from '../../../../../types/AppTypes'
import { useStyles } from '../../../cosmetics/communityStyles'
import { RangeableBarGraph } from '../../charts/RangeableBarGraph'


const debug = process.env.REACT_APP_DEBUG && false;

export type CommunityDailyUsageGraphProps = {
    objectHistory: Partial<ObjectHistory>
}

export const CommunityDailyUsageGraph = ({ objectHistory }: CommunityDailyUsageGraphProps) => {
    
    const history = useHistory()
    const styles = useStyles()   
    

    const historyObject = objectHistory.dailyStateHistory
    
    debug && console.log('CommunityDailyUsageGraph historyObject', historyObject)

   

    const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
        let fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 7);
        const unixFromDate = (fromDate.valueOf() / 1000) * 1000
        let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate)
        lowIdx = lowIdx < 0 ? historyObject.index.length - 1 : lowIdx
    
        let maxIdx = historyObject.index.length - 1
        const wholeKeys = Object.keys(historyObject);
        const keys = wholeKeys.filter((key) => key === graphKey)
        const xType: LineChartXType = LineChartXType.TIMESERIES

        console.log('getGraph', keys)
    
        const rows = keys.map((key) => {
          return historyObject[key] &&
            <RangeableBarGraph {...{ historyObject, xType, keys: [key], lowIdx, maxIdx }}></RangeableBarGraph>
        }).filter((element) => element)
        return rows;
      }

    return (
        <Card>
            <Typography variant='h5' align='center'>
                Community Daily Usage
            </Typography>
            {historyObject && historyObject.index.length > 0 ? getGraph(historyObject as HistoryObjectType, [], 'community_usage_in_day') : <Typography variant='h6' align='center'>No Data available</Typography>}
        </Card>

    )

}