import config from "@iotv/config";
import { DimensionedArr, NormalAccumulator, DimensionedPeriodizer, HourlyYearBasedNormalAccumulator, DehydratedHourlyYearBasedCMV, DehydratedPeriodized, DailyDecadePeriodizer, HourlyYearPeriodizer, QuarterHourPeriodizer, DEBUG_OVERRIDE, NOT_LAMBDA} from "@iotv/datamodel-core";
import { AccumulatorClasses, ErrData, ErrDataPromise, PeriodizerClasses, ValidBusinessObject } from "@iotv/iotv-v3-types";
import { getObjectFromS3 } from "../api/s3";

const debug = DEBUG_OVERRIDE || ( NOT_LAMBDA && false )

/**
 * THESE ARE GET FUNCTIONS FROM @IOTV/DATASTORE WHICH APP SHOULD NOT ACCESS. IAM ROLES DEFINED IN COGNITO STACK LIMIT TO READ ONLY HENCE NO PUTS
 */

const bucketIdentifier = (config.aws.s3.largeObjects as {Name: string}).Name;


export const getDehydratedPeriodizer = async ( vob: ValidBusinessObject, className: PeriodizerClasses ) => {
    let res: ErrData<DehydratedPeriodized & ValidBusinessObject> = { err: null, data: null }
    const Key = `${className}:${vob.sk}`

    res = await getObjectFromS3<DehydratedPeriodized & ValidBusinessObject>({ Key, bucketIdentifier })
    return res 
}

export const getQuarterHourPeriodizer = async ( vob: ValidBusinessObject ): ErrDataPromise<QuarterHourPeriodizer> => {
    let res: ErrData<QuarterHourPeriodizer> = { err: null, data: null }
    const className = PeriodizerClasses.QuarterHourPeriodizer
    const getRes = await getDehydratedPeriodizer( vob, className )
    if ( getRes.data ) {
        const periodizerFromIngestion = new QuarterHourPeriodizer()
        periodizerFromIngestion.ingest( getRes.data )
        res.data = periodizerFromIngestion
    } else {
        res.err = getRes.err
    }
    return res
}

export const getHourlyYearPeriodizer = async ( vob: ValidBusinessObject ): ErrDataPromise<HourlyYearPeriodizer> => {
    let res: ErrData<HourlyYearPeriodizer> = { err: null, data: null }
    const className = PeriodizerClasses.HourlyYearPeriodizer
    const getRes = await getDehydratedPeriodizer( vob, className )
    if ( getRes.data ) {
        const periodizerFromIngestion = new HourlyYearPeriodizer()
        periodizerFromIngestion.ingest( getRes.data )
        res.data = periodizerFromIngestion
    } else {
        res.err = getRes.err
    }
    return res
}

export const getDailyDecadePeriodizer = async ( vob: ValidBusinessObject ): ErrDataPromise<DailyDecadePeriodizer> => {
    let res: ErrData<DailyDecadePeriodizer> = { err: null, data: null }
    const className = PeriodizerClasses.DailyDecadePeriodizer
    const getRes = await getDehydratedPeriodizer( vob, className )
    if ( getRes.data ) {
        const periodizerFromIngestion = new DailyDecadePeriodizer()
        periodizerFromIngestion.ingest( getRes.data )
        res.data = periodizerFromIngestion
    } else {
        res.err = getRes.err
    }
    return res
}



export const getDehydratedAccumulator = async ( vob: ValidBusinessObject, className: AccumulatorClasses ): ErrDataPromise<DehydratedHourlyYearBasedCMV> => {
    let res: ErrData<DehydratedHourlyYearBasedCMV> = { err: null, data: null }
    const Key = `${className}:${vob.sk}`

    if ( className === AccumulatorClasses.HourlyYearBasedNormalAccumulator ) {
       res = await getObjectFromS3<DehydratedHourlyYearBasedCMV>({ Key, bucketIdentifier })
    }
    return res 

}

