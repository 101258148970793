import { ConditionTypes, FilterTypes, Milliseconds, ObjectHistory, QueryTypes, ValidBusinessObject } from '@iotv/datamodel';
import { DBQueryBodyOptional, ErrData } from '@iotv/iotv-v3-types';
import config from '../../config';
import Assembler from '../Assembler';
import ApiGateway from '../aws/api-gateway/ApiGateway';
import { initializeObjectHistory } from '../TypeHelpers';


const debug = process.env.REACT_APP_DEBUG && false;

export function getHistoryQueryBody(objectTypeId: string, thing: ValidBusinessObject, limit: number = 5) {
    const hi = new Date();
    const lo = new Date();
    lo.setHours(hi.getHours() - 1);
    const loDate = '' + lo.valueOf() / 1000;
    const hiDate = '' + hi.valueOf() / 1000;
    const filter = {
        filterType: FilterTypes.simpleAnd,
        conditions: [
            {
                condition: ConditionTypes.between,
                key: 'timestamp',
                value: { lo: loDate, hi: hiDate }
            },
            {
                condition: ConditionTypes.equals,
                key: 'type',
                value: objectTypeId
            }
        ]
    };

    const queryBody: DBQueryBodyOptional = {
        type: QueryTypes.filteredGet,
        indexName: config.aws.dynamoDB.tsiName,
        hashKeyName: config.aws.dynamoDB.pKey,
        filter,
        items: [Assembler.getInstance({ type: thing.type, id: thing.id }) as ValidBusinessObject],
        exclusiveStartKey: undefined,
        limit,
        scanForward: false
        
    };
    return queryBody;
}


export const getThingHistory = async function(thing: ValidBusinessObject, objectTypeId: string, limit: number, apiRef?: string ):  Promise<ValidBusinessObject[]> {

     const queryBody: DBQueryBodyOptional = getHistoryQueryBody(objectTypeId, thing, limit);
     const path = '/query/';
     const res = await ApiGateway.post(path, queryBody, apiRef);
     const response = res.data;
     const history: ValidBusinessObject[] = response.Items;
     debug && console.log('HISTORY', history)


    
     return history;
    }


export const getObjectHistory = async ( vob: ValidBusinessObject) => {
    const path = '/getObjectHistory';
    const t0 = Date.now()
    const result: ErrData<ObjectHistory> = { err: null, data: null }

    const res =  await ApiGateway.post(path, vob) as  ErrData<ValidBusinessObject>;
    debug && console.log('getObjectHistory res', res)
    if ( res.data  ) {
      const objectHistoryZombie = res.data;
      const objectHistory = new ObjectHistory(objectHistoryZombie)
      initializeObjectHistory(objectHistory)
    result.data = objectHistory
  } else if ( res.err ) {
      result.err = res.err
  }
  return result


}