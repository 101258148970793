import { DatastoreObjectType, getObjectTypeSummary, ValidBusinessObject } from '@iotv/datamodel';
import { SortOrder } from '@iotv/iotv-v3-types';
import { ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, makeStyles, Radio, Table, TableBody, TableCell, TableFooter, TableHead, MenuItem, TableRow, TextField, IconButton, TablePagination, FormControl } from '@material-ui/core';
import { ChevronLeft, AddCircle } from '@material-ui/icons';
import { denseTheme } from '../../cosmetics/Themes/dense';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AttributeMap, DocumentClient } from 'aws-sdk/clients/dynamodb';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';

import { ComponentMode, ListerlizerRowControlFnProps, RowControlOverrideFnsType, ViewDefinition, ViewKeyDefinitionType } from '../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../types/LabTypes';
import { ListerlizerRow } from './ListerlizerRow';
import Assembler from '../../data/Assembler';

const debug = process.env.REACT_APP_DEBUG && false;


const useStyles = makeStyles((theme) => ({ ...styles(theme),
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
  },
}));

export function DocumentClientListerlizerWrapper<T>(props: DocumentClientListerlizerWrapperProps<T>) {


  const { viewObject, contextObject: scopeDefiningContextObject, viewDefinition, adjacentFilter, allowCreate = false, showItemSelector = true, queryHook, queryParams, sortKeyIn, history, queryControls, rowControlOverrideFns: rowControlOverrideFnsIn, transactionFunctions, } = { ...props };

  const [[items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects, getSelector] = queryHook.selectionControls;

  const [ newObjects, setNewObjects] = React.useState<ValidBusinessObjectList>([]);

  const [ currentViewObjects, setCurrentViewObjects] = React.useState< DatastoreObjectType[]> ([]);

  const [mode, setMode] = React.useState(ComponentMode.VIEW)
  const [maxItems, setMaxItems] = React.useState(props.maxItems ?? 4)
  const [page, setPage] = React.useState(0);
  const [sortKey, setSortKey] = React.useState(sortKeyIn);
  const [ sortDirection, setSortDirection ] = React.useState<SortOrder>( SortOrder.ASCENDING )
  const classes = useStyles(denseTheme);
  const itemsCount = items.length;
  const objectSkHash = items.map( (x) => x.sk ).join()
  const viewObjectSk = viewObject.matchedPrimary?.sk

  const filterCurrentViewObjects = () => {
    const sliceStart = page * maxItems
    const sliceEnd = page * maxItems + maxItems
    items && setCurrentViewObjects([...items].sort( (a: AttributeMap, b: AttributeMap) => sortDirection.valueOf() * (a[sortKey] < b[sortKey] ? -1 : 1 ) ).slice(sliceStart, sliceEnd) as  DatastoreObjectType[]);
  }

 

  useEffect(() => {
    filterCurrentViewObjects();
    setNewObjects([])
    
    debug && console.log('Set Current View Object to ', { page, maxItems, sortKey, sortDirection, itemsCount, firstItemSk: objectSkHash, viewObjectSk })
  }, [ page, maxItems, sortKey, sortDirection, itemsCount, objectSkHash, viewObjectSk ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    debug && console.log('Page Change,', newPage)
    setPage(newPage);
  }

  const handleChangeSortOrder = () => setSortDirection( sortDirection === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newItemsPerRow = parseInt(event.target.value, 10);
    debug && console.log('DocumentClientListerlizerWrapper newItemsPerRow ', newItemsPerRow)
    setMaxItems( newItemsPerRow );
    setPage(0);
  };

  const getPaginationCellTD = () => {
    return <TablePagination
      rowsPerPageOptions={[5, 10, 20, 25, 50, 100, 200]}
      component="td"
      count={items.length}
      rowsPerPage={maxItems}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  }

  const handleAddObject = ( objectTypeId: string ) => {
    const newObject = Assembler.getInstance({ type: objectTypeId })
    if ( newObject ) {
      const mutants = [...newObjects];
      mutants.push(newObject);
      setNewObjects(mutants);
    }
  }

  debug && console.log('DocumentClientListerlizerWrapper gets', { viewObject, scopeDefiningContextObject, queryParams })
  debug && console.log('DocumentClientListerlizerWrapper has items', getObjectTypeSummary(items) )
  debug && console.log('DocumentClientListerlizerWrapper has objectsSkHash', { objectSkHash })

  const getExclusiveStartkey = () => LastEvaluatedKeys[lastEvaluatedKeysLength - 1]

  const rowSelectionFirst = false
  const lastEvaluatedKeysLength = LastEvaluatedKeys.length;

  let rowControlOverrideFns: RowControlOverrideFnsType | undefined = rowControlOverrideFnsIn;

  const getSelectorControl = (listRowProps: ListerlizerRowControlFnProps) => [<TableCell { ...{ key: `${listRowProps.rowObject.sk}_selectorCell` }} > {getSelector(listRowProps)} </TableCell>]

  if (showItemSelector) {
    if (!rowControlOverrideFns) rowControlOverrideFns = {};
    if (!rowControlOverrideFns.first) rowControlOverrideFns.first = [];
    if (rowControlOverrideFns.first[0]?.name !== 'getSelectorControl') {
      rowControlOverrideFns.first.unshift(getSelectorControl)
    } 
  }

  const listerlizerRef: React.RefObject<HTMLDivElement> = React.createRef()

  const handleGetMoreResults = (event: unknown) => {
    setExclusiveStartKey(getExclusiveStartkey())
  };

  const handleGetPreviousResults = (event: unknown) => {
    const keys = [...LastEvaluatedKeys];
    keys.pop();
    const nextLastKey = keys[keys.length - 1]
    setLastEvaluatedKeys(keys);

    setExclusiveStartKey(nextLastKey)
  };


  const selectedAll = selectedObjects.length > 0 && selectedObjects.length === items.length;
  const getSelectAllPad = () => <TableCell {...{ key: `first_0`, size: 'small', className: classes.root }}>{<Radio {...{ checked: (selectedAll), onClick: (e) => { selectedAll ? setSelectedObjects([]) : setSelectedObjects(items as DatastoreObjectType[]) } }}></Radio>}</TableCell>

  const getHeaders = (viewDefinition: ViewDefinition, rowControlOverrideFns?: RowControlOverrideFnsType) => {
    const getPad = (firstLast: 'first' | 'last', idx: number) => <TableCell key={`${firstLast}_${idx}`}></TableCell>
    const headerCells = Object.values(viewDefinition).map((viewKeyDefinition: ViewKeyDefinitionType) => <TableCell
      key={`${viewKeyDefinition.key}_header`}
      onClick={() => setSortKey(viewKeyDefinition.key)}
      component="th" scope="row"> 
      <span { ...{ onClick: handleChangeSortOrder }}>{viewKeyDefinition.label} { sortDirection === SortOrder.ASCENDING ? <IconButton><KeyboardArrowUpIcon/></IconButton> : <IconButton><KeyboardArrowDownIcon/></IconButton>} </span>
    </TableCell>);
    const firstPadding = rowControlOverrideFns?.first?.map((fn, i) => showItemSelector && i === 0 ? getSelectAllPad() : getPad('first', i)) ?? [];
    const lastPadding = rowControlOverrideFns?.last?.map((fn, i) => getPad('last', i)) ?? [];
    return [...firstPadding, headerCells, ...lastPadding]
  }

  const queryLimitSteps = [ 5, 10, 20, 50, 100, 200, 1000 ]
  const queryLimitOptions = [ { v: -1, l: 'No Limit' }, ...queryLimitSteps.map( (step) => { return {v: step, l: step.toString()}}) ]
  const getQueryParameterTable = () => {
    return <Table { ...{ size: 'small', className: classes.root, key: 'queryParamsTable' }}>
       <TableHead { ...{  key: 'queryParamsTableHead' }}>
       { queryControls && <TableRow { ...{ key: 'filters', style: { } }}>
           { queryControls().map( (element, i) => <TableCell { ...{ colSpan: 4, style: { border: 'none' }, key: `queryControls_${i}`}}  >{element}</TableCell>) }
        </TableRow>}
       </TableHead>
      <TableBody { ...{  key: 'queryParamsTableBody' }}>
        <TableRow { ...{ key: 'queryParamsTableRow1' }}> 
          <TableCell { ...{ key: 'control-row-padding'} }>
            { allowCreate &&  <FormControl { ...{ style: { paddingTop: '15px'}} } ><AddCircle { ...{ onClick: () => handleAddObject( adjacentFilter.objectType )}}></AddCircle></FormControl> }
            <TextField { ...{ select: true, value: limit ?? -1, variant: 'outlined', size: 'small', margin: 'dense' , label: 'Query limit',  onChange: (e) => { 
                  const newLimit = parseInt(e.target.value); 
                  setLastEvaluatedKeys( [] );
                  setExclusiveStartKey( undefined );
                  !isNaN(newLimit) &&  setLimit( newLimit  === -1 ? undefined : newLimit) 

                } 
              } } >
            {queryLimitOptions.map((option, i) => (
            <MenuItem { ...{ key: i, value: option.v,  style: { fontSize: 'small'} } }>
              {option.l}
            </MenuItem>
          ))}
            </TextField>
          </TableCell>
          <TableCell { ...{ key: `less`}}>
              { LastEvaluatedKeys.length > 0 && <Button { ...{ onClick: handleGetPreviousResults, startIcon: <ChevronLeft {...{ color: 'secondary' }}></ChevronLeft>, variant: 'contained' } }>
                ...previous
              </Button> }
              </TableCell>
          <TableCell { ...{ key: `more`}}>

              {getExclusiveStartkey() && <Button { ...{ onClick: handleGetMoreResults, endIcon: <ChevronRightIcon {...{ color: 'secondary'  }}></ChevronRightIcon>, variant: 'contained' } }>
                 more...
                </Button>
              }
            </TableCell>

            { getPaginationCellTD() }

        </TableRow>
      </TableBody>
    </Table>
  }

  const listKey = `${viewObject.matchedPrimary?.sk}_${adjacentFilter.objectType}`;
  return (
    <div ref={listerlizerRef} style={{ overflowX: "scroll" }}>
      {/* <div> {JSON.stringify(LastEvaluatedKeys)}</div>
      <div> {`Limit: ${limit}`}</div> */}
      <div { ...{ key: `${listKey}_getQueryParameterTable`}}> {getQueryParameterTable()}</div>
     

      <Table size='small' key={`${listKey}_listTable2`} className={classes.table} style={{
        whiteSpace: "nowrap"
      }} aria-label="simple table" >
        {mode !== ComponentMode.EDIT && <TableHead>
          <TableRow key='tableHead-row'>
            {getHeaders(viewDefinition, rowControlOverrideFns)}
          </TableRow>
        </TableHead>
        }
        <TableBody key={`${viewObject.matchedPrimary?.sk}_${adjacentFilter.objectType}_${adjacentFilter.objectType}`}>
         {newObjects.map((rowObject: ValidBusinessObject, i: number) => rowObject).map((rowObject, i) => {
            const key = `${rowObject.sk}_new_${i}`
            const rowProps = {
              keyToUse: `${rowObject.sk}_${i}_new_ktu`,
              viewDefinition, adjacentFilter, matchedPrimary: viewObject.matchedPrimary as ValidBusinessObject,
              rowObject, mode: ComponentMode.EDIT, history, isSelected: false, optionals: { rowSelectionFirst }, rowControlOverrideFns, transactionFunctions
            }
            return <ListerlizerRow

              { ...{ key, ...rowProps }}></ListerlizerRow>
          }
          )}

          {currentViewObjects.map((rowObject: DocumentClient.AttributeMap, i: number) => rowObject as ValidBusinessObject).map((rowObject, i) => {
            const key = `${rowObject.sk}_${i}`
            const rowProps = {
              keyToUse: `${rowObject.sk}_${i}_ktu`,
              viewDefinition, adjacentFilter, matchedPrimary: viewObject.matchedPrimary as ValidBusinessObject,
              rowObject, mode, history, isSelected: false, optionals: { rowSelectionFirst }, rowControlOverrideFns, transactionFunctions
            }
            return <ListerlizerRow

              { ...{ key, ...rowProps }}></ListerlizerRow>
          }
          )}

        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>


    </div>

  )
}