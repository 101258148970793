import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import StageModal, { formatToDescription, renderIdList } from './DroughtPlanModals/StageModal';
import { NoneMade } from './ManagementGenerics';
import { StageMessage } from '../DMPExtrasV2';
import { getCustomerRecipiants } from './RecipientsSupport';

interface StageMessagePageProps {
  contextCustomer: ValidBusinessObject;
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const StageMessagePage: React.FC<StageMessagePageProps> = (props: StageMessagePageProps) => {
  let { contextCustomer, index, source, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [stageMessage, setStageMessage] = useState<StageMessage[]>([]);
  let [recipient, setRecipients] = useState<ValidBusinessObjectList>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setStageMessage(source.stage[index].stageMessages);
  }, [index, source]);

  useEffect(() => {
    if (contextCustomer) {
      updateRecipient();
    }
  }, [contextCustomer]);

  const addNewStageMessage = useCallback(() => {
    setCreate(true);
  }, []);

  const cancel = useCallback(() => {
    setCreate(false);
    setPosition(undefined);
  }, []);

  const updateRecipient = async () => {
    setRecipients(await getCustomerRecipiants(contextCustomer));
  };

  const editTrigger = (pos: number) => {
    setPosition(pos);
    addNewStageMessage();
  };

  const update = (newMessage: StageMessage | undefined, pos: number | undefined) => {
    if (newMessage === undefined) {
      if (pos !== undefined) {
        stageMessage.splice(pos, 1);
      }
    } else if (setSource !== undefined) {
      if (pos === undefined) {
        stageMessage!.push(newMessage);
      } else {
        stageMessage[pos] = newMessage;
      }

      let newStages = source.stage;
      newStages[index].stageMessages = stageMessage;
      setSource({ ...source, stage: newStages });
      updateRecipient();
      cancel();
    }
  };

  const renderIdListwithemail = (idList: string[], choice: ValidBusinessObjectList): string[][] => {
    let objList: string[][] = idList.map((id) => {
      console.log("ifincludeemail", choice.find((option) => option.id === id))
      var findone = choice.find((option) => option.id === id)
      return [findone?.name, findone?.email, findone?.phoneNumber];
    });
    return objList;
  };


  stageMessage?.map((stage, i) => {
    var emailphone = renderIdListwithemail(stage.recipientIDs, recipient)
    var sendemail: string[] = []
    var sendsms: string[] = []
    for (var i = 0; i < emailphone.length; i++) {
      sendemail.push(emailphone[i][1])
      sendsms.push(emailphone[i][2])
    }
    console.log("reception", emailphone, sendemail, sendsms)
  })

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          {setSource && (
            <Button variant='contained' onClick={addNewStageMessage}>
              <Add />
              <b>Stage Transition Message</b>
            </Button>
          )}
          {stageMessage && stageMessage.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>
                      <b>Message</b>
                    </TableCell>
                    <TableCell width={'200px'} align='left'>
                      <b>Recipients</b>
                    </TableCell>
                    <TableCell width={'100px'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stageMessage?.map((stage, i) => {
                    return (
                      <TableRow>
                        <TableCell align='left'>
                          {stage.messages.map((message) => {
                            return <p>- {message}</p>;
                          })}
                        </TableCell>
                        <TableCell align='left'>{recipient && renderIdList(stage.recipientIDs, recipient)}</TableCell>
                        <TableCell align='center'>
                          <Button variant='contained' onClick={() => editTrigger(i)}>
                            <Edit />
                            <b>Edit</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Stage Transition Message'} add={addNewStageMessage} />
          )}
        </Stack>
      </div>
      {create && <StageModal close={cancel} contextCustomer={contextCustomer} stage={index + 2} update={update} index={position} existing={stageMessage} />}
    </>
  );
};

export default StageMessagePage;
