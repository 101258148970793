import { AppValueTypes } from "@iotv/iotv-v3-types";
import { FormControl, FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import React from "react";
import styles from '../../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../../cosmetics/Themes/dense';
import { ComponentMode, InputComponentProps } from "../../../types/AppTypes";

const useStyles = makeStyles((theme) => (styles(theme)));



export const  BooleanComponent = ( props: InputComponentProps) => {
    const {matchedPrimary,  viewKeyDefinition: { key, label, editable, type}, mode, updateKV} = props;

    const classes = useStyles(denseTheme);
    let element = null;
    if (type === AppValueTypes.BOOLEAN) {
        const value = matchedPrimary[key] ?  matchedPrimary[key] : false;
        if ( editable !== true || mode === ComponentMode.VIEW || mode === ComponentMode.READONLY) {

            return <div>{ mode === ComponentMode.VIEW || mode === ComponentMode.READONLY ? '': `${label}: `}{ matchedPrimary[key] !== undefined ?  matchedPrimary[key] === true ? '\u2713' : '\u2716': '?'} </div> 
        } else if ( mode === ComponentMode.EDIT) {
            element =  <FormControl className = {classes.editFormControlFullWidth}>
            <FormControlLabel
                label = { label }
                 control={
                <Switch
                    checked={ value }
                    onChange={ (event) => updateKV( event as React.ChangeEvent<HTMLInputElement>,  key) } 
                    name={ label }
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                 /> }
            ></FormControlLabel>

        </FormControl>
        } 
        
    };
    return element
}