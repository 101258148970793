import React, { useState } from 'react';
import { KeyMappedComponents } from 'src/types/AppTypes';
import { Grid, Tabs, Tab, Box, Typography } from '@mui/material';
import { CommunityDailyUsageGraph } from './CommunityDailyUsageGraph';
import { CommunityFillLevelGraph } from './CommunityFillLevelGraph';
import { CommunityRainfallGraph } from './CommunityRainfallGraph';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const GraphContainer = (props: any) => {
  const { objectHistory } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    //   <Box sx={{ width: '100%' }}>
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example' variant='fullWidth'>
          <Tab label='Fill Level' {...a11yProps(0)} />
          <Tab label='Daily Usage' {...a11yProps(1)} />
          <Tab label='Rainfall' {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {objectHistory && <CommunityFillLevelGraph {...{ objectHistory }} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {objectHistory && <CommunityDailyUsageGraph {...{ objectHistory }} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {objectHistory && <CommunityRainfallGraph {...{ objectHistory }} />}
      </TabPanel>
    </>
    //   </Box>
  );
};
