import { Grid, withStyles } from '@material-ui/core';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import config from '../../config';
import styles from '../../cosmetics/sharedViewStyles';
import { SearchParamsType, ThingViewProps, UserTransactionType } from '../../types/AppTypes';

// here for example only

export type TileComponentProps = {
    someSetting: string
  }
  

export type TileConfig = {
    objectTypeId: string,
    tileClass: React.ComponentType<TileComponentProps> 
}


export type DashboardConfig = {
    someTopLevelSetting: any, 
    tiles: TileConfig[]
}



function DashboardView( props: ThingViewProps  ): JSX.Element {
    const { classes,  transactionFunctions, userFunctions, match, user, userGroupRoles, contextCustomer, history,  useSpecializedViews } = props;


    const importDashboardConfig = async ( ventureCode: string ) => {

        import(`../../venture/${ventureCode}/dashboardConfig`)
        .then((module) => setDaahsboardConfig(module.dashboardConfig as DashboardConfig))
    } 
    
    const [ dashboardConfig, setDaahsboardConfig ] = React.useState<DashboardConfig | undefined>( undefined )

    useEffect( () => {
        importDashboardConfig(config.app.appCode)
    }, [])

    useEffect( () => {

    }, [ dashboardConfig ])

   const searchParams: SearchParamsType | undefined = history.location?.search ? queryString.parse(history.location.search) as SearchParamsType : undefined
    const actions: UserTransactionType[] = [UserTransactionType.CREATE_AND_LINK]

 
    const DropDown = Grid 

   
    
    return (
      <div>
        <Grid container className={classes.root} spacing={2}>
          { dashboardConfig && dashboardConfig.tiles.map( (tile, i) => {
              return <DropDown { ...{ key: i, xs: 3, component: Grid, someSetting: tile.objectTypeId }}> <h1>{ `${tile.objectTypeId} Dropdown` }</h1> </DropDown>
          })}    
        </Grid>
        <Grid container>
        { dashboardConfig && dashboardConfig.tiles.map( (tile, i) => {
            const ComponentClass = tile.tileClass;
            return  <ComponentClass { ...{ key: i,  xs: 3, component: Grid, someSetting: 'X', children: <h2>{ `${tile.objectTypeId} Tile` }</h2>}}></ComponentClass>
          })}    
        </Grid>
      </div>
       
    );
    
}

export default withStyles(styles)(DashboardView)
