import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Action, responseActionTypes } from '../DMPExtrasV2';
import ActionsModal from './DroughtPlanModals/ActionsModal';
import { NoneMade } from './ManagementGenerics';

interface ActionPageProps {
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const ActionPage: React.FC<ActionPageProps> = (props: ActionPageProps) => {
  let { index, source, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [actionType, setActionType] = useState<string>('');
  let [actions, setActions] = useState<Action[]>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setActions(source.stage[index].actions);
  }, [index, source]);

  const addNewAction = (type: string) => {
    setCreate(true);
    setActionType(type);
  };

  const cancel = useCallback(() => {
    setCreate(false);
    setActionType('');
    setPosition(undefined);
  }, []);

  const editAction = (pos: number, type: string) => {
    setPosition(pos);
    addNewAction(type);
  };

  const update = (newAction: Action | undefined, position: number | undefined) => {
    if (newAction === undefined) {
      if (position !== undefined) {
        actions.splice(position, 1);
      }
    } else if (setSource !== undefined) {
      if (position === undefined) {
        actions?.push(newAction);
      } else {
        actions[position] = newAction;
      }

      let newStages = source.stage;
      newStages[index].actions = actions;
      setSource({ ...source, stage: newStages });
      cancel();
    }
  };

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
            {setSource &&
              responseActionTypes.map((option) => {
                return (
                  <Button variant='contained' onClick={() => addNewAction(option)}>
                    <Add />
                    <b>{option} Action</b>
                  </Button>
                );
              })}
          </Stack>
          {actions && actions.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={'100px'} align='left'>
                      <b>Type</b>
                    </TableCell>
                    <TableCell align='left'>
                      <b>Action</b>
                    </TableCell>
                    <TableCell width={'150px'} align='left'>
                      <b>Who</b>
                    </TableCell>
                    <TableCell width={'100px'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actions.map((action, i) => {
                    return (
                      <TableRow>
                        <TableCell align='left'>{action.type}</TableCell>
                        <TableCell align='left'>{action.action}</TableCell>
                        <TableCell align='left'>{action.person}</TableCell>
                        <TableCell align='center'>
                          <Button variant='contained' onClick={() => editAction(i, action.type)}>
                            <Edit />
                            <b>Edit</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Action'} />
          )}
        </Stack>
      </div>
      {create && actionType !== '' && <ActionsModal close={cancel} type={actionType} update={update} index={position} existing={actions} />}
    </>
  );
};

export default ActionPage;
