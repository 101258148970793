import React from 'react';
import type { ViewDefinition, ObjectDetailCardProps } from '../../types/AppTypes';
import {   UserTransactionType } from '../../types/AppTypes';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import DetailCard from '../cards/DetailCard';
import { getViewDefintionFromObject } from '../factories/AttributeDefinitions';


const debug = process.env.REACT_APP_DEBUG && false;





export default function ObjectDetailCard( props: ObjectDetailCardProps ) {

  const searchParams = props.searchParams

  debug && console.log('User Details Card has props', props)

  const actions: UserTransactionType[] = [UserTransactionType.UPDATE, UserTransactionType.CREATE_AND_LINK, UserTransactionType.DELETE]

  let viewDefinition: ViewDefinition = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    type: { key: 'type', label: 'Type', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  }

 

  const { matchedPrimary, transactionFunctions, history } = props

  matchedPrimary && Object.assign(viewDefinition, getViewDefintionFromObject(matchedPrimary))

  return <DetailCard { ...{matchedPrimary, viewDefinition, transactionFunctions, actions, searchParams, history} }></DetailCard>
}