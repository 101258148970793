import { GeofencePointsType } from "@iotv/datamodel";

 const debug = process.env.REACT_APP_DEBUG && false;

export const extendBoundsForPoint = (bounds: google.maps.LatLngBounds, point: google.maps.LatLng, meters: number) => {
    var west = google.maps.geometry.spherical.computeOffset(point, meters, 270);
    var east = google.maps.geometry.spherical.computeOffset(point, meters, 90);
    bounds.extend(west);
    bounds.extend(east);
  }

export const  getBounds = (points: google.maps.LatLng[]) => {
    const bounds = new google.maps.LatLngBounds();
    points.forEach((pos) => bounds.extend(pos));
    if (points.length === 1 || (points.length === 2 && points[0].lng === points[1].lng)) {
      extendBoundsForPoint(bounds, points[0], 1000);
    } else {
      debug && console.log('did not extend bounds with', points)
    }
    return bounds
  }