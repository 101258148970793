import { ErrData, ObservationHistory, IObservationHistory, ValidBusinessObject, HistoryStore } from "@iotv/datamodel";
import React from "react";
import { useEffect } from "react";
import { MapLike, StateSnapshot } from "@iotv/iotv-v3-types";
import ApiGateway from "../data/aws/api-gateway/ApiGateway";
import AppDao from '../data/AppDao'

const debug = process.env.REACT_APP_DEBUG && false;

export type UseObservationsType = { 
  state: [ ObservationHistory | undefined, React.Dispatch<React.SetStateAction<ObservationHistory | undefined>>],
  errMessage: string | undefined, working: boolean
  functions: {
    addObservation: ( stateSnapshot: StateSnapshot<any> ) => void 
    save: ( observationHistory: ObservationHistory) => void, deleteObject: () => void
  }
} 

export function useObservations(matchedPrimary: ValidBusinessObject | undefined, initialKeys?: string[] ): UseObservationsType {
    useEffect( () => {
        debug && console.log('useObservations effect because', matchedPrimary?.sk)
        const get = async ( vob: ValidBusinessObject) => {
          const path = '/get';
          const observationHistoryZombie: Partial<ValidBusinessObject & IObservationHistory> = { pk: vob.sk, sk: `ObservationHistory_${vob.sk}`, type: 'ObservationHistory', id: '?' }
          const res =  await ApiGateway.post(path, [observationHistoryZombie]) as  ErrData< Partial<IObservationHistory>>;
          debug && console.log('useObservations get res', res)
          if ( res.data && res.data instanceof Array && res.data.length > 0 ) {
            setObservationHistory(new ObservationHistory(res.data[0]))
            
          } else {
            const initialObservationHistory = new ObservationHistory({ ...observationHistoryZombie })
            if ( initialKeys ) {
              debug && console.log(`initial keys provided`, initialKeys)
              initialKeys.forEach( ( k ) => initialObservationHistory.observedHistory.addKey(k))
            } else {
              debug && console.log(`No initial keys provided`)
            }
            setObservationHistory( initialObservationHistory )
          }
          debug && console.log(`observationHistory is instance?`, observationHistory instanceof ObservationHistory )
        }
    
        matchedPrimary && get(matchedPrimary)
    
      }, [matchedPrimary?.sk])

      const initialKeysHash = initialKeys?.join('')

      useEffect( () => {
        if ( observationHistory && initialKeys && initialKeys.length > 0 ) {
          const historyStoreClone = new HistoryStore( observationHistory.observedHistory )

            debug && console.log(`initial keys provided`, initialKeys)
            initialKeys.forEach( ( k ) => historyStoreClone.addKey(k))

          setObservationHistory( new ObservationHistory( { ...observationHistory, observedHistory: historyStoreClone }) )
        }
      }, [ initialKeysHash ])

      const addObservation = ( stateSnapshot: StateSnapshot<any> ): void => {
        debug && console.log(`observationHistory is instance?`, observationHistory instanceof ObservationHistory )
        if ( observationHistory ) {
          observationHistory.observedHistory.push( stateSnapshot )
          setObservationHistory( new ObservationHistory(observationHistory))
        }
      }

      const save = async ( observationHistory: ObservationHistory) => {
        setWorking(true)
        const res = await AppDao.save( {...observationHistory, pk: `${matchedPrimary?.sk}`, sk: `ObservationHistory_${matchedPrimary?.sk}`, name: `${matchedPrimary?.name} @ ${ Date.now()}`} )
        console.log('ObservationHistory Save Res', res)
        if ( res.data ) {
          setObservationHistory( new ObservationHistory( res.data as ValidBusinessObject & IObservationHistory ) )
        } else  if ( res.err ) {
          setErrMessage( res.err.message )
        }
        setWorking(false)
      }

      const deleteObject = async () => {
        setWorking(true)
        const res = await AppDao.deleteObject( observationHistory )
        if ( res.data ) {
          setObservationHistory( undefined )
        } else  if ( res.err ) {
          setErrMessage( res.err.message )
        }
        setWorking(false)
      }
      
      const [ working, setWorking ]: [ boolean,  React.Dispatch<React.SetStateAction<boolean>>] = React.useState<boolean>(false)
    
      const [ errMessage, setErrMessage ]: [ string | undefined,  React.Dispatch<React.SetStateAction<string | undefined>>] = React.useState<string | undefined>(undefined)
      const [ observationHistory, setObservationHistory ]: [ ObservationHistory | undefined, React.Dispatch<React.SetStateAction<ObservationHistory | undefined>>] = React.useState<ObservationHistory | undefined>( undefined );

      const historyLength = () => {
        let length: number | undefined;
        if ( observationHistory instanceof ObservationHistory ) {
          debug && console.log(`observationHistory is instance`)
          if ( observationHistory.observedHistory instanceof HistoryStore ) {
            debug && console.log(`observedHistory is instance`)
            length = observationHistory.observedHistory.getLength()
          }
        } else if ( observationHistory ) {
          debug && console.log(`observationHistory is NOT instance`)
          length = (observationHistory  as MapLike<any>)?.observedHistory?.timestamp
        } else {
          debug && console.log(`observationHistory is undefined`)
        }
        return length
      }

      useEffect( () => {
        debug && console.log(`getLength observationHistory is instance?`, observationHistory instanceof ObservationHistory )
        if ( !observationHistory?.observedHistory.getLength ) {
          console.log(`No get length on`, observationHistory)
        }
      }, [ historyLength() ])

      return  { 
        state: [ observationHistory, setObservationHistory ],
        errMessage, working,
        functions: {
          addObservation, save, deleteObject
        }
      };
}