import styles from '../../cosmetics/sharedViewStyles';

import { Card, CardContent, CardHeader, Container, Grid, makeStyles, Tab, Tabs, Theme, Typography, withStyles } from '@material-ui/core';
import { TabPanel } from '../utils/TabPanelShim';

import { useHistory } from 'react-router-dom';
import { DeviceViewSearchParamstype, ManagementViewProps, ManagementViewSearchParamsType } from '../../types/AppTypes';
import React, { useEffect } from 'react';
import { getQueryParamsFromHistory } from '../../util/Routing/queryParams';
import queryString from 'query-string';
import { ValidBusinessObject, ValidModelObject, ValidModelType } from '@iotv/datamodel';
import { ConfigurationTab } from './tabs/ConfigurationTab';
import { IncidentsTab } from './tabs/IncidentsTab';
import { SingleIncidentTab } from './tabs/SingleIncidentTab';

export type NotificationViewProps =  ManagementViewProps

const debug = process.env.REACT_APP_DEBUG && false;
const componentClassName = 'NotificationView';
const componentParentHasRendered = () => document.querySelector(`.${componentClassName}`);

const localStyle = (theme: Theme) => ({


});
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle(theme) }));

const tabsToDisplay = [ 'Incidents', 'IncidentView', 'Notification' ].filter((tabName) =>
 tabName
);



export const NotificationView = ( props: NotificationViewProps ) => {
    const {  match: { params: { action, id: sk, type } }, user, contextCustomer, history} = props;
    const routerHistory = useHistory();
    const searchParams = getQueryParamsFromHistory<ManagementViewSearchParamsType>(history)
  
    const [tabValue, setTabValue] = React.useState(props.tabIndex ?? 0);
    const [selectedCustomer, setSelectedCustomer] = React.useState<ValidBusinessObject | undefined>(contextCustomer);

  
    if ( searchParams?.tabName ) {
      const openTabIndex = tabsToDisplay.indexOf(searchParams.tabName);
      if ( openTabIndex !== -1 && tabValue !== openTabIndex ) {
        setTabValue(openTabIndex);
       
      }
    }
  
    useEffect( () => {
  
    }, [ user?.sk ])
  
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      debug && console.log('tab change', newValue)
      //setTabValue(newValue);
      
      const newSearchParems = {...searchParams, tabName: tabsToDisplay[newValue] ?? tabsToDisplay[0]}
      const newUrl = queryString.stringifyUrl(
        {
          url: routerHistory.location.pathname,
          query: newSearchParems
        }
      )
      routerHistory.push(newUrl);
    };
  
    debug && console.log('match', {action, sk})
    const classes = useStyles();
    return <Container className= {componentClassName}>
      <Card className={classes.root}>
        <CardHeader></CardHeader>
        <CardContent>
        <Tabs  { ...{ value: tabValue, onChange: handleTabChange, variant: 'scrollable' }}>
                {tabsToDisplay.map((tabName, i) => <Tab label={tabName} {  ...{ key: `tab_${i}` }} />)}
              </Tabs>
              
             
              <TabPanel {...{ key: 'incidents', value: tabValue, index: 0 }}>
                { props.user && <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {selectedCustomer?.name}
                      </Typography>
                        <IncidentsTab { ...{ ...props, contextUser: user as  ValidModelObject<'User'>} }></IncidentsTab>
                    </CardContent>
                  </Card>
                </Grid>}
                
              </TabPanel>
              <TabPanel {...{ key: 'singleIncident', value: tabValue, index: 1 }}>
                { props.user && <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {selectedCustomer?.name}
                      </Typography>
                        <SingleIncidentTab { ...{ ...props, contextUser: user as  ValidModelObject<'User'>, searchParams} }></SingleIncidentTab>
                    </CardContent>
                  </Card>
                </Grid>}
                
              </TabPanel>
              <TabPanel {...{ key: 'notifications', value: tabValue, index: 2 }}>
                { props.user && <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {selectedCustomer?.name}
                      </Typography>
                        <ConfigurationTab { ...{ ...props, contextUser: user as  ValidModelObject<'User'>} }></ConfigurationTab>
                    </CardContent>
                  </Card>
                </Grid>}
                
              </TabPanel>
         
        </CardContent>
  
      </Card>
    </Container>
  
  
}