import { withStyles } from '@material-ui/core';
import { ObjectCardProps } from '../../../../types/AppTypes';
import styles from '../../../../cosmetics/sharedViewStyles';
import { RecipientCard } from '../Recipient/RecipientCard';

function RecipientManagementView(props: ObjectCardProps): JSX.Element {
  const { viewObject, contextCustomer, transactionFunctions, history, userFunctions, user } = props;
  return (
    <div>
      {
        <RecipientCard
          userGroupRoles={undefined}
          userSelectedGroup={undefined}
          {...{ viewObject, contextCustomer, transactionFunctions, history, userFunctions, user }}
        ></RecipientCard>
      }
    </div>
  );
}
export default withStyles(styles)(RecipientManagementView);
