import  { deviceEnabledTypes, ValidBusinessObjectList }  from '@iotv/datamodel';
import { NormalizedData, ValidBusinessObject, ViewObject } from '../../types/AppTypes';
import { PossibleBusinessObject, isValidBusinessObject } from '@iotv/datamodel';

const debug = process.env.REACT_APP_DEBUG && false;

function getAllThings( normalData: NormalizedData ) {
    let things: any[] = [];
    const deviceEnabledTypeKeys = Object.keys(deviceEnabledTypes);
    debug && console.log('Getting all things')
    if (normalData) {
        for (let sk in normalData) {
            if ( deviceEnabledTypeKeys.some( ( dek ) => sk.startsWith(dek) )  ) {
                things.push( getPrimaryObject(normalData, sk))
            }
        }
    }
    debug && console.log('returning things', things)
    return things;
}

const mergeNormalDatas = ( source: NormalizedData, target: NormalizedData) => {
  if ( source && target ) {
    Object.entries(source).forEach( ( [skKey, skPkEntry] ) => {
      if ( !(target[skKey])) {
        target[skKey] = skPkEntry;
      } else {
        Object.values(skPkEntry).forEach( ( item )  => {
          if (item) {
            addToNormalData(item, target)
          }
        })
      }
    })
  }
}

const flattenToArray = (normalData: NormalizedData) => {
  let arr: ValidBusinessObject[] = [];
  if (normalData) {
   Object.keys(normalData).map( (k: string) => getPrimaryObject(normalData, k)).filter( (primaryObject: ValidBusinessObject | undefined) => primaryObject as ValidBusinessObject).forEach((primaryObject ) => arr.push(primaryObject as ValidBusinessObject))
  }
  debug && console.log('Flattened normal data to', arr)
  return arr;
}

const addToNormalData = ( item: PossibleBusinessObject, normalizedData: NormalizedData) => {
  if (isValidBusinessObject(item) && normalizedData) {
    if (!(normalizedData[item.sk])) {
      normalizedData[item.sk] = {};
    }
    if (!(normalizedData[item.sk][item.pk])) {
      normalizedData[item.sk][item.pk] = item;
    } else {
      Object.assign(normalizedData[item.sk][item.pk] ?? {}, item)
    }
    if (debug /*item.pk.startsWith('Horse')*/) console.log(`Put ${item.type} or link at ${item.sk}, ${item.pk}`, normalizedData[item.sk][item.pk])
  };
}

// will remove exaxt one (for link removal)
const removeFromNormalData = ( item: PossibleBusinessObject, normalizedData: NormalizedData) => {
  let removed = undefined;
  if (isValidBusinessObject(item) && normalizedData) {
    if ((normalizedData[item.sk] && normalizedData[item.sk][item.pk])) {
      removed = normalizedData[item.sk][item.pk];
      delete normalizedData[item.sk][item.pk];
      // possible delete where pk  is sk??
    }
  };
  return removed;
}

const removeAllFromNormalData = ( item: PossibleBusinessObject, normalizedData: NormalizedData) => {
  let removed = undefined;
  if (isValidBusinessObject(item) && normalizedData) {
    if (normalizedData[item.sk]) {
      removed = normalizedData[item.sk];
      delete normalizedData[item.sk];
      // possible delete where pk  is sk??
    }
  };
  return removed;
}

const getPrimaryObject = ( normalData: NormalizedData, sk: string): ValidBusinessObject | undefined => {
    const matchedPrimaryMap = normalData ? normalData[sk] : undefined;
    let matchedPrimary = undefined;
    if ( matchedPrimaryMap ) {
      const entries = Object.entries(matchedPrimaryMap);
      debug && sk === 'Customer:48cf2adf-b39b-4139-ab9e-772a6880d4fe' &&  console.log('get Primary Object entries', entries)
      if ( entries.length === 1) {
        matchedPrimary = entries[0][1];
      } else if (entries.length > 1) {
        const realPrimaryEntry = entries.find( ([k, v]) => v && v?.sk === v?.pk) 
        const realPrimary = realPrimaryEntry ? realPrimaryEntry[1] : undefined;
        if ( realPrimary ) {
          matchedPrimary = realPrimary;
        } else {
          matchedPrimary = entries[0][1]; // there would be multiple child instances for multiple parents
        }
      }
    }
   return matchedPrimary;
  }

  const getPkRelatedObjects = ( normalData: NormalizedData, skIn: string) => {

    const parentKeys = normalData && normalData[skIn] &&  Object.keys(normalData[skIn]).filter( (k) => k !== skIn)

    let parentObjects: ValidBusinessObjectList  = [];
    if (normalData) {
      const skKeys = Object.keys(normalData);
      skKeys.forEach( ( skKey ) => {
        const primaryObject = getPrimaryObject(normalData, skKey);
        parentKeys?.includes(skKey) && primaryObject && parentObjects.push(primaryObject)
      })
    }
        
    debug && skIn.startsWith('User') && console.log('Parent Objects', parentObjects)   
    debug && normalData && skIn.startsWith('User') && console.log('Parent Keys used', normalData[skIn])   

   return parentObjects;
  }
  
  const getSkRelatedObjects = ( normalData: NormalizedData, parentSk: string) => {
    let children: ValidBusinessObjectList = []
    let parentEdges: ValidBusinessObjectList = []
    if (normalData) {
       Object.values(normalData).forEach( skMappedChildren => Object.values(skMappedChildren).forEach((child) => {
         child?.pk === parentSk && child.sk !== parentSk && children.push(child);
         child?.sk === parentSk && child.pk !== parentSk && parentEdges.push(child);    
       }))
    }
    debug && parentSk === 'User:c7b0f8ff-2628-4028-a708-f15aa369b128' &&  console.log('get Parent Edges entries', parentEdges)
    return { children, parentEdges }
    }
  
  
  const unionPrimariesAndEdges = ( parentEdges: ValidBusinessObjectList, primaryParents: ValidBusinessObjectList) => {

    

    const preferedObjects:  ValidBusinessObjectList = parentEdges.concat(primaryParents)
    
    debug && console.log('unionPrimariesAndEdges unioned', preferedObjects)
    return preferedObjects
  }
  
  const constructItem = (normalData: NormalizedData, sk?: string) => {
    debug && sk?.startsWith('User') && console.log('NORMAL DATA', normalData)
    
    let matchedRelatedByPk = undefined;
    let matchedRelatedBySk = undefined;
    let matchedPrimary = undefined;
    if (sk) {
      matchedPrimary = getPrimaryObject(normalData, sk) //protection against psuedo properties like _latest being created as matchedPrimaries
      const skRelatedObjects = getSkRelatedObjects(normalData, sk)
      if (sk && matchedPrimary && sk.startsWith(matchedPrimary.type)) {
        const matchedParentEdges = skRelatedObjects.parentEdges;
        const matchedParentPrimaries = getPkRelatedObjects(normalData, sk);
        matchedRelatedByPk = unionPrimariesAndEdges(matchedParentEdges, matchedParentPrimaries)

        matchedRelatedBySk = skRelatedObjects.children;
      } else {
         debug && console.log('construct item rejected ', {sk, type: matchedPrimary?.type})
        matchedPrimary = undefined;
      } 
    }
   
    return {
      matchedPrimary, matchedRelatedByPk, matchedRelatedBySk
    }
  }
  
  const constructAllItems = (normalData: NormalizedData, typeIds?: string[]) => {
    let res: ViewObject[] = [];
    if (normalData) {
      const sks = Object.keys(normalData).filter( (sk) => !(typeIds) || typeIds.find((typeId) => sk.startsWith(typeId)));
      res = sks.map( (sk) => constructItem( normalData, sk)).filter((viewObject) => viewObject.matchedPrimary)
    }
    return res;
  }

  const filterByTypeIds = (normalData: NormalizedData, typeIds: string[]) => {

  
    if (normalData && typeIds) {
      const sks = Object.keys(normalData);
      const filteredByType: NormalizedData = {};
      for (let i in sks) {
        const sk: string = sks[i];
        if (typeIds.find( (matchType: string) => sk.startsWith(matchType))) filteredByType[sk] = normalData[sk];
        else {
          const possibleParents = normalData[sk];
          const parentKeys = Object.keys(possibleParents);
          for (let i in parentKeys) {
            const pk = parentKeys[i];
            if (typeIds.find( (matchType: string) => pk.startsWith(matchType))) {
              filteredByType[sk] = normalData[sk];
              // debug && console.log('MATCHED AS PARENT', normalData[sk])
            } else {
              //debug && console.log('size of normalData was ', sks.length)
            }
          }
        }
      }
      debug && console.log(`Filtering on types, ${typeIds}`, filteredByType)
      return filteredByType;
    }  else {
      return normalData;
    }
   
  }
  


export { flattenToArray, getAllThings, getPrimaryObject, getPkRelatedObjects as getParentObjects, getSkRelatedObjects as getChildObjects, constructItem, constructAllItems, addToNormalData, mergeNormalDatas, removeFromNormalData, removeAllFromNormalData, filterByTypeIds };