import ReactDOM from 'react-dom';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styles from './Modal.module.css';
import { Grid, makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { ValidBusinessObject } from '@iotv/iotv-v3-types';

import { UserFunctions } from '../../../../../../types/AppTypes';

import { v4 as uuid } from 'uuid/';
import { validateEmail, validatePassword } from '../../../../data/validate';

const modalRoot = document.querySelector('#modal-root-2');
const apiRef = 'SystemManagement';

const editTankStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '27ch',
      width: '79vw',
    },
  },
  textField: {
    width: 'auto',
    height: 'auto',
    color: 'primary',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  delete: {
    margin: theme.spacing(1),
  },

  cancel: {
    margin: theme.spacing(1),
  },
  save: {
    margin: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => {
  return {
    ...styles,
    root: {},
    inkBar: {
      backgroundColor: 'yellow',
    },
    tabs: {
      backgroundColor: 'red',
    },
    addButton: {
      backgroundColor: '#D3D3D3',
      padding: 15,
      // margin: theme.spacing(1),
    },
    tab: {
      flexGrow: 1,
      display: 'block',
      position: 'fixed',
      bottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      width: '100%',
    },
    indicator: {
      top: '0px',
      backgroundColor: '#44bcd8',
    },

    bottomTabStyle: {
      bottom: '0',
    },
    dividerColor: {},
  };
});

const tabuseStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 300,
  },
  main: {
    margin: theme.spacing(2),
  },
}));

export interface AddUserProps {
  showScreen: Dispatch<SetStateAction<boolean>>;
  modifyingUser: ValidBusinessObject | undefined;
  updateUser: Dispatch<ValidBusinessObject>;
}

const AddUser: React.FC<AddUserProps> = (props: AddUserProps) => {
  let { showScreen, updateUser, modifyingUser } = props;
  const classes = useStyles();
  const tabStyle = tabuseStyles();

  const routerHistory = useHistory();
  const editTank = editTankStyles();

  let [valid, setValid] = useState<boolean>(false);

  let [name, setName] = useState<string>(modifyingUser?.name || '');
  let [username, setUsername] = useState<string>(modifyingUser?.username || '');
  let [email, setEmail] = useState<string>(modifyingUser?.email || '');
  let [phoneNumber, setPhoneNumber] = useState<number>(modifyingUser?.phoneNumber);
  let [password, setPassword] = useState<string>(modifyingUser?.password || '');

  useEffect(() => {
    if (name !== '' && username !== '' && validateEmail(email) && validatePassword(password)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, username, email, phoneNumber, password]);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    showScreen(false);
    return null;
  }

  const updateObject = () => {
    let id = modifyingUser ? modifyingUser.id : uuid();

    let user: ValidBusinessObject = {
      email,
      id,
      name,
      phoneNumber: phoneNumber?.toString(),
      type: 'User',
      username,
      pk: `User:${id}`,
      sk: `User:${id}`,
      password,
    };

    updateUser(user);
    showScreen(false);
  };

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <div>
            <h3 style={{ textIndent: 10 }}>Add New User</h3>
            <Grid container>
              <Grid item container className={styles.bottomPadding}>
                <form className={editTank.root} noValidate autoComplete='off' onSubmit={() => showScreen(false)}>
                  <Grid item container>
                    <Grid item container xs={6}>
                      <TextField
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        label='Name'
                        onChange={(e) => setName(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        value={name}
                        required
                        error={name === ''}
                      />
                      <TextField
                        label='Email'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        required
                        value={email}
                        error={!validateEmail(email)}
                      />
                      <TextField
                        label='Password'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        required
                        type='text'
                        value={password}
                        error={!validatePassword(password)}
                        helperText={
                          !validatePassword(password) &&
                          'Must Contain at least: 1 Uppercase, 1 Lowercase, 1 special character, and 1 number, and more than 6 characters'
                        }
                      />
                    </Grid>
                    <Grid item container xs={6}>
                      <TextField
                        label='Username'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setUsername(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        required
                        value={username}
                        margin='dense'
                        error={username === ''}
                      />
                      <TextField
                        label='Phone Number'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setPhoneNumber(Number(e.target.value))}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        type='number'
                        required
                        value={phoneNumber}
                        InputProps={{ startAdornment: <InputAdornment position='start'>+</InputAdornment> }}
                        error={phoneNumber === undefined}
                        helperText='  '
                      />
                    </Grid>
                  </Grid>

                  <div style={{ textIndent: 10 }} id='buttons'>
                    <Button variant='contained' color='error' onClick={() => showScreen(false)}>
                      Cancel
                    </Button>
                    <Button variant='outlined' color='success' disabled={!valid} onClick={() => updateObject()}>
                      {modifyingUser ? 'Update' : 'Add'}
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default AddUser;
