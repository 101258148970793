import { MapLike, ValidBusinessObject } from "@iotv/datamodel";
import React from "react";
import { IdType } from "@iotv/iotv-v3-types";

const debug = process.env.REACT_APP_DEBUG && false;

export type UseSelectedObjectsReturnType<T extends IdType = ValidBusinessObject > = {
    state: [
        [selectedObjects: T[], setSelectedObjects: React.Dispatch<React.SetStateAction<T[]>>] 
    ],
    handlers: {
        addToSelectedObjects: (pob: MapLike<any>) => void,
        removeFromSelectedObjects: (pob: MapLike<any>) => void,
        isInSelectedObjects: (pob: MapLike<any>) => boolean
    }
}

/**
 * In the process of removing the deplendency of this as being ValidBusinessObject, sk has changed to id. We ideally want this to work on any DocumentClient.AttributeMap and or { id: string }
 * @returns 
 */
export const useSelectedObjects = <T  extends IdType>(): UseSelectedObjectsReturnType<T> => {

    const [selectedObjects, setSelectedObjects] = React.useState<T[]>([])
   
 
    const isInSelectedObjects = (pob: MapLike<any>) => {
     const present = selectedObjects.find((item) => pob.id && item.id === pob.id) !== undefined;
     return present
    }
    const addToSelectedObjects = (pob: MapLike<any>) => {

      if ( !isInSelectedObjects(pob) ) {
        setSelectedObjects([...selectedObjects, pob as T ])
      }
    }
    const removeFromSelectedObjects = (pob: MapLike<any>) => {
      const idx = selectedObjects.findIndex((item) => item.id === pob.id);
      if (idx > -1) {
        const mutatedSelectedObjects = [...selectedObjects]
        mutatedSelectedObjects.splice(idx, 1)
        setSelectedObjects(mutatedSelectedObjects)
      }
    }

    return {
        state: [ [selectedObjects, setSelectedObjects] ],
        handlers: {
            addToSelectedObjects,
            removeFromSelectedObjects,
            isInSelectedObjects

        }
    }
}