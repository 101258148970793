import { Tank, ThingShadowState, ValidBusinessObject } from '@iotv/datamodel';
// import { UserTransactionType, LineChartXType } from '../types/AppTypes';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
// import Avatar from '@material-ui/core/Typography';
// import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import config from '../../../../config';
import styles from '../../../../cosmetics/sharedCardStyles';
import { LineChartXType } from '../../../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../../../util/geo/GeoDataHelpers';
import { RangeableLineChart } from '../charts/RangeableLineGraph';

const googleChartUrl = config.google.chartApiUri;

const debug = process.env.REACT_APP_DEBUG && false;

type TankGroupUsageGraphCardProps = {
  matchedPrimary: ThingShadowState;
  filterGraphKeys?: string[]
}

const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "100%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  // paper: {
  //   // padding:  spacing(2),
  //   textAlign: 'center',
  //   // color: palette.text.secondary,
  // }, 
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end'
  }
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);


export default function TankGroupUsageGraphCard(props: TankGroupUsageGraphCardProps | any) {
  const classes = useStyles();
  const [viewSelector, setViewSelector] = React.useState('litres');
  const [duration, setDuration] = React.useState(7)


  // const { matchedPrimary, parentObjects } = props
  const { viewObject, filterGraphKeys, transactionFunctions, userFunctions, searchParams, history, optionals } = props;
  // debug &&
   console.log('TankGroupUsageGraphCard props', props)
 
  const handleViewSelector = () => {
    // setViewSelector(!viewSelector)
    setViewSelector(viewSelector === 'litres' ? 'percentage' : 'litres')
  }

  debug && console.log('Tank Usage Graph TSS:', props?.viewObject?.matchedPrimary?.dailyStateHistory);
  const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst } = extractGeoDataFromViewObject(viewObject);
 debug && console.log({ thingStatesMostRecentFirst })
  const matchedPrimary = props?.viewObject?.matchedPrimary as ValidBusinessObject & Tank;
  const totalVolume = matchedPrimary.total_volume
  const historyObject: HistoryObjectType = props?.viewObject?.matchedPrimary?.dailyStateHistory;

  const getFormattedData = (graphArray: any) => {
    const formattedGroupDailyUsage: any = []
    const formattedDUGroupPercentage: any = []
    // const formattedDailyUsage: any = []
    // const formattedDUPercentage: any = []
    const actualHistoryObject = props?.viewObject?.matchedPrimary?.dailyStateHistory;
    let formatHistoryObject = graphArray 
    if (graphArray.volume_used_yesterday) {
      if (graphArray.volume_used_yesterday.length > 0) {
        graphArray.volume_used_yesterday.forEach((item: any) => {
          if (viewSelector === 'percentage') {
            let dailyPercentage = (typeof item == 'number') ? parseInt(((item / totalVolume) * 100).toFixed(2)) : parseInt(((item / totalVolume) * 100).toFixed(2))
            formattedDUGroupPercentage.push(dailyPercentage)
          } else {
            let cfv = (typeof item == 'number') ? parseInt(item.toFixed(2)) : parseInt(item.toFixed(2))
            formattedGroupDailyUsage.push(cfv)
          }
        });
      }
    }
    formatHistoryObject.formattedGroupDailyUsage = formattedGroupDailyUsage
    formatHistoryObject.formattedDUGroupPercentage = formattedDUGroupPercentage
    //}
    debug && console.log({ formattedGroupDailyUsage, formatHistoryObject, graphArray, historyObject })
    return formatHistoryObject
  }

  const googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`)
  debug && console.log({ historyObject })

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date() 
    // fromDate.setDate(fromDate.getDate() - duration);
    
    fromDate.setDate(fromDate.getDate() );
    const unixFromDate = (fromDate.valueOf() / 1000) * 1000
    const maxIdx = historyObject.index.length - 1 
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate)
    lowIdx = lowIdx < 0 ? 0 : lowIdx
    const formattedHistoryObject = getFormattedData(historyObject)
    const wholeKeys = Object.keys(historyObject);
    const keys = wholeKeys.filter((key) => key === graphKey)
    debug && console.log('keys:', keys)
    const xType: LineChartXType = LineChartXType.TIMESERIES
    const rows = keys.map((key) => {
      debug && console.log('ObjectGraphCard Key:', key)
      return historyObject[key] &&
        // <RangeableLineChart {...{ historyObject, xType, classes, keys: [key] }}></RangeableLineChart>
        <RangeableLineChart {...{ historyObject: formattedHistoryObject, xType, classes, keys: [key], lowIdx, maxIdx }}></RangeableLineChart>

    }).filter((element) => element)
    return rows;
  }

  const handlerDurationChange = (event: any) =>  setDuration(parseInt(event.target.value)) 

  return (
    <div>
      {viewSelector === 'percentage' ? (
        historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'formattedDUGroupPercentage')
      ) : (
          // historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'volume_used_yesterday')
          historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'formattedGroupDailyUsage')
          // historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'dailyUsage')
        )}

      <div className={classes.bottomCard}>
        <Grid container >
          <Grid item xs={6} >
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>(%)</Grid>
                  <Grid item>
                    <AntSwitch checked={viewSelector === 'litres' ? true : false} onChange={handleViewSelector} name="checkedC" />
                  </Grid>
                  <Grid item>(L)</Grid>
                </Grid>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <RadioGroup row aria-label="position" name="position"
                defaultValue={365}
                value={duration}
                onChange={handlerDurationChange}
              >
                <FormControlLabel
                  value={7}
                  control={<Radio color="primary" />}
                  label="7 days"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={30}
                  control={<Radio color="primary" />}
                  label="1 month"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={365}
                  control={<Radio color="primary" />}
                  label="1 year"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Box>
          </Grid>
        </Grid>
      </div>


    </div>
  );
}
