import { ValidBusinessObjectList, ValidModelType } from "@iotv/datamodel";
import { Button, ButtonGroup, Chip, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { getPseudoVBO } from "../../data/TypeHelpers";
import { useSelectedObjects, UseSelectedObjectsReturnType } from "../../hooks/useSelectedObjects";
import { AdjacentType, ListerlizerProps, ObjectCardProps, ViewDefinition } from "../../types/AppTypes";
import { Listerlizer } from "../factories/Listilizer";

const debug = process.env.REACT_APP_DEBUG && false;

type ImportedSpreadsheetDataTableProps = Omit<ObjectCardProps, 'viewObject' | 'userGroupRoles' | 'user' | 'history'> & {
    importObjectType: string | undefined,
    importViewDefinition: ViewDefinition | undefined,
    objects: ValidBusinessObjectList,
    setVBOsInParent: (vbos: ValidBusinessObjectList) => void,
    clearImportedObjects: () => void,
    parentHasRendered: () => void
}

export const ImportedSpreadsheetDataTable = ({ objects, importObjectType, importViewDefinition, contextCustomer, transactionFunctions, userFunctions, setVBOsInParent, clearImportedObjects, parentHasRendered }: ImportedSpreadsheetDataTableProps) => {

    const { state: [[selectedObjects, setSelectedObjects]], handlers: { addToSelectedObjects, removeFromSelectedObjects, isInSelectedObjects } }: UseSelectedObjectsReturnType = useSelectedObjects()

    const setSelectedVBOsInParent = () => {
        if (selectedObjects.length > 0) {
            setVBOsInParent(selectedObjects)
        } else {
            setVBOsInParent(objects);
        }
        setSelectedObjects([])
        if (clearImportedObjects) clearImportedObjects()

    }

    useEffect(() => {
        
    }, [objects.length, importObjectType, ])

    const getSpreadsheetDataListerlizerProps = (importViewDefinition: ViewDefinition): ListerlizerProps => ({
        transactionFunctions,
        userFunctions,
        autoQuery: false,
        optionals: { rowSelectionFirst: true },
        viewObject: {
            matchedPrimary: getPseudoVBO({ type: 'GoogleSpreadsheetRows', id: 'GoogleSpreadsheetRows' }),
            matchedRelatedByPk: [],
            matchedRelatedBySk: objects
        },
        contextObject: undefined,
        viewDefinition: importViewDefinition,
        adjacentFilter: {
            adjacentType: AdjacentType.CHILD,
            objectType: ( importObjectType ?? 'xxx' ) as ValidModelType
        },
        sortKeyIn: 'name',
        maxItems: 10,
        selectorConfigParams: {
            enabled: false,
            multipleSelect: false,
            controls: {
                add: false,
                find: false,
            }
        },
        functionOverrides: {
            selectItem: (sk: string) => {
                debug && console.log('got select', sk)
                const pseudoVBO = objects.find((object) => object.sk === sk)
                debug && console.log('got vbo', pseudoVBO)
                if (pseudoVBO) {
                    if (isInSelectedObjects(pseudoVBO)) {
                        removeFromSelectedObjects(pseudoVBO)
                    } else {
                        addToSelectedObjects(pseudoVBO)
                    }
                }

                debug && console.log('Selected Items', selectedObjects)
            },
            hasValidationErrors: (something: any) => { console.log('Validation', something) }
        },
        injectedComponents: [],
        checkedItemSks: selectedObjects.map((ob) => ob.sk)
    });


    return importViewDefinition && objects.length >= 0 ? <><Grid item xs={12} >
        {importObjectType !== 'Unknown' ?
            <ButtonGroup>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={!importObjectType}
                    onClick={setSelectedVBOsInParent}
                >{selectedObjects.length > 0 ? 'Import selected' : 'Import all'}</Button>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={!importObjectType}
                    onClick={() => { selectedObjects.length !== objects.length ? setSelectedObjects(objects) : setSelectedObjects([]) }}>
                    {selectedObjects.length !== objects.length ? 'Select all' : 'Deselect all'}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={objects.length === 0}
                    onClick={() => {  clearImportedObjects() }}>
                    { 'Clear'}
                </Button>
            </ButtonGroup>
            : <Chip
                label={'We cannot import these data as of Unkown type'}
                onDelete={() => { }}
                color="secondary"
            ></Chip>}
        </Grid>

        <Grid item { ...{ xs: 12 }}>

            <Listerlizer {...{ ...getSpreadsheetDataListerlizerProps(importViewDefinition) }}></Listerlizer>
       </Grid>
       </>

     : null
}
