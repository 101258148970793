import { Avatar, Box, Button, Checkbox, Container, createStyles, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Paper, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { AppLocalStorage } from '../../data/LocalStorage/AppLocalStorage';
import config from '../../config';
import { NavSections } from '../navigation/NavSections';
import { styles } from './loginStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CognitoUser } from 'amazon-cognito-identity-js';


const debug = process.env.REACT_APP_DEBUG && false;

type AuthProps = { username?: string, password?: string, rememberMe?: boolean, authenticate: Function, logout: Function, isAuthenticated: boolean };
export type LoginProps = { theme: any, location: any, didInvalidate: boolean | undefined, cognitoUser: CognitoUser | undefined } & AuthProps & WithStyles<typeof styles>

function LoginTab(props: LoginProps): JSX.Element {
    const [username, setUsername] = React.useState(AppLocalStorage.get(NavSections.LOGIN, 'username'));
    const [password, setPassword] = React.useState(AppLocalStorage.get(NavSections.LOGIN, 'password'));
    const [attemptedAutoLogin, setAttemptedAutoLogin] = React.useState(false);
    const [rememberMe, setRemembderMe] = React.useState(AppLocalStorage.get(NavSections.LOGIN, 'rememberMe'));
    const [requestInitCount, _setRequestInitCount] = React.useState(0);
    const [ message, setMessage ] = React.useState<string | undefined>(undefined)
    const [ error, setError ] = React.useState<string | undefined>(undefined)
    const history = useHistory()

    const authenticate = props.authenticate;
    const logout = props.logout;

    const { classes, didInvalidate, cognitoUser } = props;
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    debug && console.log('Login receoved', props)

    useEffect(() => {

        document.title = username ? config.app.appName + (config.app.stage !== 'prod' ? ` ${config.app.stage}` : '') : 'Please logon';

        if (!props.isAuthenticated && !attemptedAutoLogin && username && password && requestInitCount === 0) {
            setAttemptedAutoLogin(true)

            debug && console.log('Attempting Auto loging, count is', requestInitCount);
            // This is causing double init as gets triggered after normal login
            // authenticate({username, password, rememberMe});
        }

    }, [username, props.isAuthenticated, attemptedAutoLogin, password, authenticate, rememberMe, requestInitCount]);

    const altRediretSources = [`/${NavSections.LOGIN}`, `/`]
    const previousPath = AppLocalStorage.get(NavSections.HOME, 'lastViewedRoute')
    const filteredPreviousPath = previousPath && altRediretSources.includes(previousPath) ? undefined : previousPath
    const fromPath = props?.location?.state?.from?.pathname;
    const preferredPath = fromPath && !altRediretSources.includes(fromPath) ? fromPath : filteredPreviousPath;
    const redirect = preferredPath ? preferredPath : config.app.initialRedirect;

    if ( cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED' ) {
        debug && console.log( `redirectiing to ${ `/${NavSections.UNAUTHENTICATED}/NEW_PASSWORD`} because `, cognitoUser.challengeName )
       return  <Redirect to={{ pathname: `/${NavSections.UNAUTHENTICATED}/NEW_PASSWORD`}}></Redirect>
    }

    return (

        props.isAuthenticated && redirect ?

            <Redirect to={{ pathname: redirect }}></Redirect>

            :

            <Paper className={clsx(classes.paper)} >
                <div >

                    {props.isAuthenticated && <Grid container style={{ marginTop: '10px' }}>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={() => logout({ username, password, rememberMe })} >Logout</Button>
                    </Grid>}

                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>

                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <form className={classes.form} noValidate>
                                <TextField { ...{
                                    variant: "outlined", margin: "normal", required: true, fullWidth: true,
                                    id: "email", label: "Username", name: "email", autoComplete: "email", autoFocus: true,
                                    onChange: (e) => setUsername(e.target.value.toLowerCase())
                                }}
                                   
  
                                />
                                <TextField {...{
                                    variant: "outlined", margin: "normal", required: true, fullWidth: true,
                                    name: "password", label: "Password", type: showPassword ? 'text': "password", id: "password", autoComplete: "current-password",
                                    onChange: (e) => setPassword(e.target.value),
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton {...{
                                                    'aria-label': "toggle password visibility",
                                                    onClick: () => setShowPassword(!showPassword),

                                                }}

                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                }}


                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" checked={rememberMe} color="primary" onChange={(e) => setRemembderMe(e.target.checked)} />}
                                    label="Remember me"
                                />
                                { didInvalidate === true && <Alert severity="error">Access denied, can't find user for username / password combination</Alert>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        debug && console.log('Submit clicked', { username })
                                        if (rememberMe) {
                                            AppLocalStorage.set(NavSections.LOGIN, 'username', username ?? undefined)
                                            AppLocalStorage.set(NavSections.LOGIN, 'password', password ?? undefined)
                                            AppLocalStorage.set(NavSections.LOGIN, 'rememberMe', rememberMe)
                                        }
                                        authenticate({ username, password, rememberMe })
                                    }
                                    }
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#"
                                            to={`/${NavSections.UNAUTHENTICATED}/FORGOT`}
                                        >
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#"
                                            to={`/${NavSections.UNAUTHENTICATED}/SIGNUP`}
                                        >
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Box mt={8}>

                        </Box>
                    </Container>
                </div>
            </Paper>


    );

}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(LoginTab)