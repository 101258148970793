import { MapLike, ValidBusinessObject } from '@iotv/datamodel';
import { allowedTypesQuery, userTypeRoleQuery, userViaCustomersTypeRoleQuery } from '../../../../data/RoleQueries';
import { customerTanksQuery } from '../../../../data/RoleQueries/customerTanksQuery';
import { NormalizedData, RoleArbitrationInstance, RoleArbitrationInstanceType } from '../../../../types/AppTypes';
import { systemManagmentUserCustomersQuery } from '../../data/queries/customerHierachy';

const loginRAI = new RoleArbitrationInstance({
  groupName: 'User',
  queries: [userTypeRoleQuery],
  routes: [],
});

const userRAI = new RoleArbitrationInstance({
  groupName: 'User',
  queries: [
    ( user: ValidBusinessObject, normalizedData: NormalizedData ) => userViaCustomersTypeRoleQuery( user, 'Tank', normalizedData)
  ],
  routes: [
    { id: 'Views', type: 'category', access: 'Allow' },
    { id: 'MyTanks', type: 'link', access: 'Allow', parentId: 'Views' },
    { id: 'UserView', type: 'link', access: 'Allow', parentId: 'Views' },

    { id: 'Settings', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Settings' },

    { id: 'Userview', type: 'link', access: 'Allow', parentId: 'Settings' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Settings' },
  ],
}).inherit(loginRAI);

const aggregationGroupRAI = new RoleArbitrationInstance({
  groupName: 'User',
  queries: [allowedTypesQuery],
  routes: [],
});

// const systemManagementRAI = new RoleArbitrationInstance({
//     groupName: 'SystemManagement',
//     queries: [
//         networkAdminQuery
//     ],
//     routes: [
//         { id: 'Network', type: 'category', access: 'Allow' },
//         { id: '*', type: 'link', access: 'Allow', parentId: 'Network' },
//         { id: 'Settings', type: 'category', access: 'Allow' },
//         { id: '*', type: 'link', access: 'Allow', parentId: 'Settings' },
//     ]

// }).inherit([userRAI, loginRAI, aggregationGroupRAI]);

const communityWaterManagerRAI = new RoleArbitrationInstance({
  groupName: 'communityWaterManager',
  queries: [],
  routes: [
    { id: 'Views', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Views' },
    { id: 'Settings', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Settings' },
    { id: 'MyTanks', type: 'link', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'MyTanks' },
    { id: 'Userview', type: 'link', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Userview' },
    { id: 'Tank Groups', type: 'link', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Communities' },
    { id: 'Drought Management Plans', type: 'link', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Drought Management Plans' },
  ],
}).inherit([loginRAI, aggregationGroupRAI]);

const tankOwnerRAI = new RoleArbitrationInstance({
  groupName: 'tankOwner',
  queries: [],
  routes: [
    { id: 'Views', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Views' },
    { id: 'Settings', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Settings' },
    { id: 'Tank Groups', type: 'link', access: 'Deny', parentId: 'Views' },
  ],
}).inherit([loginRAI, aggregationGroupRAI]);

const systemManagementRAI = new RoleArbitrationInstance({
  groupName: 'SystemManagement',
  queries: [systemManagmentUserCustomersQuery],
  routes: [
    { id: 'Views', type: 'category', access: 'Allow' },
    { id: 'MyTanks', type: 'link', access: 'Deny', parentId: 'Views' },
    { id: 'UserView', type: 'link', access: 'Allow', parentId: 'Views' },
    { id: 'Communities', type: 'link', access: 'Allow', parentId: 'Views' },
    { id: 'Drought Management Plans', type: 'link', access: 'Allow', parentId: 'Views' },

    { id: 'Settings', type: 'category', access: 'Allow' },
    { id: 'Add House,Tank', type: 'link', access: 'Deny', parentId: 'Settings' },
    { id: 'Add Device', type: 'link', access: 'Allow', parentId: 'Settings' },

    { id: 'RuleManagement', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'RuleManagement' },

    { id: 'Network', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Network' },

    { id: 'IncidentManagement', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'IncidentManagement' },

    { id: 'SystemManagement', type: 'category', access: 'Allow' },
    { id: 'RecipientManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement' },
    { id: 'CustomerManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement' },
    { id: 'DeviceManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement' },

    { id: 'Survey', type: 'category', access: 'Deny' },
    // { id: '*', type: 'link', access: 'Deny', parentId: 'Survey' },

    { id: 'admin', type: 'category', access: 'Deny' },

    // { id: 'Survey', type: 'category', access: 'Allow' },
    // { id: '*', type: 'link', access: 'Allow', parentId: 'Survey' },
  ],
}).inherit([loginRAI]);

const ventureTeamRAI = new RoleArbitrationInstance({
  groupName: 'VentureTeam',
  queries: [],
  routes: [
    { id: 'Views', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'Views' },
  ],
}).inherit(systemManagementRAI);

const hyperUserRAI = new RoleArbitrationInstance({
  groupName: 'HyperUser',
  queries: [],
  routes: [
    { id: 'DeviceControl', type: 'link', access: 'Allow', parentId: 'SystemManagement' },
    { id: 'admin', type: 'category', access: 'Allow' },
    { id: '*', type: 'link', access: 'Allow', parentId: 'admin' },
  ],
}).inherit([systemManagementRAI, ventureTeamRAI]);

if (process.env.REACT_APP_LESS) {
  hyperUserRAI.queries = [userTypeRoleQuery];
}

export const roleArbitrator: MapLike<RoleArbitrationInstanceType> = {
  HyperUser: hyperUserRAI,
  SystemManagement: systemManagementRAI,
  communityWaterManager: communityWaterManagerRAI,
  tankOwner: tankOwnerRAI,
  User: userRAI,
};
