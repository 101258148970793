import { GeofencePointsType, isValidModelTypeId, ThingShadowState, ValidModelType } from '@iotv/datamodel';
import { Badge, Grid, MenuItem, Select } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import React, { useEffect } from 'react';
import { getDeviceFromRegistry } from '../../actions/SystemActions';
import styles from '../../cosmetics/sharedCardStyles';
import { getDeviceEnabledTypes, getLocatableAddress, isDeviceEnabledType } from '../../data/TypeHelpers';
import { AdjacentFilter, AdjacentType, DispatchFunctionsType, ListConfigParams, ObjectCardProps, ValidBusinessObject, ViewObject } from '../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../util/geo/GeoDataHelpers';
import UserMessage from '../error/UserMessage';
import SingleParentSelectorCard from '../generic/SingleParentSelectorCard';
import MapCard from '../geo/MapCard';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';
import { SimpleObjectLink } from '../navigation/simpleObjectLink';
import DeviceDetailCard from './DeviceDetailCard';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));


export default function NewDeviceCard( props: ObjectCardProps ) {
  debug && console.log('NewDeviceCard card got props', props)
  const {  userGroupRoles, userSelectedGroup, viewObject, contextCustomer, transactionFunctions: transactionFunctionsInbound, user, userFunctions, searchParams, history} : ObjectCardProps  = props;
  let parentThing: ValidBusinessObject | undefined = viewObject.matchedRelatedByPk ? viewObject?.matchedRelatedByPk[0] : undefined;
  const classes = useStyles();
  const [expanded, _setExpanded] = React.useState(false);
  const [ newDevice, setNewDevice ] = React.useState(undefined as ValidBusinessObject | undefined);
  const [ _thingParent, setThingParent ] = React.useState(undefined as ValidBusinessObject | undefined);
  const [ invalidDeviceId, setInvalidDeviceId ] = React.useState(false)
  const [ parentObjectTypeId, setParentObjectTypeId ] = React.useState('Tank' as ValidModelType)

  useEffect(() => {
    async function getDeviceFromRegistryLocal(id: string) {
      const deviceRes = await getDeviceFromRegistry(id);
      const deviceObject = deviceRes.data;
      debug && console.log('Received Device from Registry', {deviceObject});
      debug && console.log('About to save new Device from registry', {newDevice})
      if (deviceObject) {
        transactionFunctionsInbound.saveObject(deviceObject);
        setNewDevice(deviceObject);
        
      } else setInvalidDeviceId(true);
    }  
    
    if (viewObject.matchedPrimary?.id && viewObject.matchedRelatedByPk?.length === 0) {
      getDeviceFromRegistryLocal(viewObject.matchedPrimary?.id)
     
    }
    
  }, []);



  if (transactionFunctionsInbound === undefined) {
    debug && console.log('STOP TransactionFunctions is undediend');
  }


  

  const  linkObjectsToPrimary = ( newLinkObject: ValidBusinessObject | undefined,  existingObjectArr: ValidBusinessObject[]) => {
    debug && console.log('Reversed link on Parent object');
    let parentObject: ValidBusinessObject | undefined = undefined;
    let newLinkObjects: ValidBusinessObject[] = [];
    if (existingObjectArr.length === 1 ) {
      parentObject = existingObjectArr[0];
      newLinkObjects = newLinkObject ? [newLinkObject] : [];
      if ( newLinkObject && newLinkObject?.name === undefined ) newLinkObject.name = 'Device for ' + parentObject.name
    } else { 
       debug && console.log('Warn: could not reverse with', { newLinkObject, existingObjectArr})
    }
    setThingParent(parentObject);
    setNewDevice(newLinkObject);
    const adjacentFilter: AdjacentFilter = {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Device',
      edgeFilter: {
        edgeTypeId: 'thing_has_device'
      }

    }
   return transactionFunctionsInbound.linkObjectsToPrimary( parentObject, newLinkObjects, adjacentFilter)
  }

  

  const transactionFunctionsOutbound: DispatchFunctionsType= {
    ...transactionFunctionsInbound,
    linkObjectsToPrimary,
  }
  
  const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst }:
  { mapViewObject: ViewObject | undefined, lastestThingStateShadow: ThingShadowState | undefined, track: GeofencePointsType[], position: GeofencePointsType | undefined, thingStatesMostRecentFirst: ThingShadowState[]} 
  = isDeviceEnabledType(viewObject.matchedPrimary) ? extractGeoDataFromViewObject(viewObject) : {mapViewObject: undefined, lastestThingStateShadow: undefined, track: [], position: undefined, thingStatesMostRecentFirst: []}
  
   debug && console.log('ObjectCard geo',  mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst )


  const locatableAddress = getLocatableAddress(parentThing);

  const hasAlert = () => true;
  const badgeCount = () => 0;
  

  const adjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.PARENT,
    objectType: parentObjectTypeId,
    edgeFilter: undefined
  }
  const listConfigParams: ListConfigParams = {
    label: `Attach to ${parentObjectTypeId}`
  }

  const getSelectOptions = () => {
    const elements =  getDeviceEnabledTypes().map( (typeId, i)  =>  <MenuItem key = {i} value={typeId}>{typeId}</MenuItem> )
    debug && console.log('Role selection options', elements)
    return elements;
  }

  if (invalidDeviceId) return <UserMessage { ...{ children: undefined, message: {content: 'The requested device is not available to be registered', type: 'SnackBar', id: 'getDeviceId', label: 'getDevicelabel'}, onClose: () => {}}}></UserMessage>
  else if ( !newDevice ) return <UserMessage { ...{ children: undefined, message: {content: 'Validating Device with Registry', type: 'SnackBar', id: 'getDeviceId', label: 'getDevicelabel'}, onClose: () => {}}}></UserMessage>;
  else  return  ( 
     <Card className={classes.root}>
      <CardHeader
        avatar={
            <NavLink filter = {`${NavSections.THINGVIEW}/${newDevice.type}/${newDevice.id}`} children = {
              <Badge badgeContent={ badgeCount()} color="primary" invisible = { !(hasAlert())}>
            <Avatar aria-label="recipe" className={classes.avatar} >
             <SettingsInputAntennaIcon />
            </Avatar>
            </Badge>
            }></NavLink>
            
        }
       title= {newDevice.name}
      />
       <CardContent>
       { newDevice && <div>
        <Grid container spacing={3}>
       
         <Grid item xs={6}>
          <DeviceDetailCard { ...{ user, viewObject, userGroupRoles, userSelectedGroup, userFunctions, contextCustomer, matchedPrimary: newDevice, contextObject: contextCustomer,  transactionFunctions: transactionFunctionsInbound, searchParams, history}}></DeviceDetailCard> 
          </Grid>
         <Grid item xs={6}>
           <Select
              style={{
                color: 'inherit'
              }}
              value={ parentObjectTypeId }
              onChange={(e) => {
                const possibleTypeId = e.target.value 
                if ( typeof possibleTypeId === 'string' && isValidModelTypeId( possibleTypeId )) {
                  setParentObjectTypeId(possibleTypeId)} }
                }
            >
              {getSelectOptions()}
            </Select>
          <SingleParentSelectorCard { ...{ viewObject, contextObject: contextCustomer, adjacentFilter, listConfigParams, transactionFunctions: transactionFunctionsOutbound, userFunctions, searchParams, history, classes }}></SingleParentSelectorCard>
        </Grid>
        <Grid item xs={6}>
            { false && <MapCard { ...{position: undefined, editable: false, locatableAddress }} ></MapCard> }
        </Grid>

        </Grid>
        
       </div>
        }
       
       
        <Typography variant="body2" color="textSecondary" component="p">

         {viewObject.matchedPrimary?.description}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
         <Grid container>
          <Grid item xs={6}>
        <Card className = {classes.subCard} >{ viewObject.matchedRelatedByPk && viewObject.matchedRelatedByPk.map( (object) =>  <SimpleObjectLink {...{ object, adjacentType: AdjacentType.PARENT}}></SimpleObjectLink> ) } </Card>
          </Grid>
          <Grid item xs={6}>
           <Card className ={classes.subCard} >{ viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.map( (object) =>  <SimpleObjectLink {...{ object, adjacentType: AdjacentType.CHILD}}></SimpleObjectLink> ) } </Card>
          </Grid>
         </Grid>
        </CardContent>
      </Collapse>
    </Card>
  
   )}