import { Card, CardActions, CardContent, CardHeader, FormGroup, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { AdjacentFilter, AdjacentType, UserListCardProps, ValidBusinessObject } from '../../types/AppTypes';
import AlertControlTableRow from './UserAlertCardListItem';

const debug = process.env.REACT_APP_DEBUG && false;
const useStyles = makeStyles((theme) => (styles(theme)));

const adjacentFilter: AdjacentFilter = {
  adjacentType: AdjacentType.CHILD,
  objectType: 'Horse'
}

export default function UserAlertControlCard( props: UserListCardProps ) {
  
  const { transactionFunctions,  viewObject  } = props
    const classes = useStyles();
    const rowObjectsIn = viewObject.matchedRelatedBySk ? viewObject.matchedRelatedBySk.filter( (item) => item.type === adjacentFilter.objectType ) : []
  
    useEffect(() => {
     debug && console.log('Used effect' , { rowObjectsIn: rowObjectsIn.length, rowObjectLength })
     setRowObjects(rowObjectsIn)
  }, [ rowObjectsIn.length ])
  
    debug && console.log('Details Card has props', props)
    debug && console.log('User Details Card has saveObject', transactionFunctions?.saveObject)
    const nameLabel = 'Alert Controls';
  
    const [ rowObjects, setRowObjects ] = React.useState([] as ValidBusinessObject[]);
    const [ rowObjectLength, _setRowObjectsLength ]  = React.useState(rowObjectsIn.length)

    debug && console.log('Alert control rendered with ', { rowObjectsIn: rowObjectsIn.length, rowObjectLength })

    return (
      <Card className={classes.root}>
        <CardHeader
          title = {`${nameLabel}`}
        ></CardHeader>
         <CardContent>
         <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { rowObjects.map( (rowObject, i) => <AlertControlTableRow key={ `alertControlRow_${i}`} { ...{ viewObject, rowObject, transactionFunctions, classes }} ></AlertControlTableRow>  ) }
            </TableBody>
          </Table>
         </TableContainer>
        </CardContent>
        <CardActions disableSpacing>
          <FormGroup row>
           
          </FormGroup>
        
  
        </CardActions>
     
      </Card>
    );
  }
