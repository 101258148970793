import { ErrData, ErrDataPromise, ErrDataReducer, IOTV_LCD_DeviceMessage, SortOrder } from '@iotv/iotv-v3-types'
import { GetObjectFromS3Request, getObjectFromS3 } from '../../aws/s3/api'
import config from '@iotv/config'
import { flattenArray, getS3TimeKey, getZombieInstanceFromPrimaryKey, S3TimeKeyLevel, S3TimeKeyStrategy, sortByTimestamp } from '@iotv/datamodel-core'
import { DeviceEuiDates, getDeviceEuiDates } from './getDeviceEuiDates';

const debug = process.env.REACT_APP_DEBUG && false;

export type DeviceMessageQueryRequest = {
    startDate: Date,
    endDate: Date,
    deviceEuis: string[]
}

const bucketIdentifier = (config.aws.s3.networkData as {Name: string}).Name;

export const getDeviceMessagesFromS3 = async ( { deviceEuis, startDate, endDate }: DeviceMessageQueryRequest ): ErrDataPromise<IOTV_LCD_DeviceMessage[]> => {
    let res: ErrData<IOTV_LCD_DeviceMessage[]> = { err: null, data: null }

    // no doubt will need some protection on querying x devices over y days were x * y = someLimit
    const deviceEuiDates: DeviceEuiDates = getDeviceEuiDates( { deviceEuis, startDate, endDate })
    
    const s3Res = ErrDataReducer<IOTV_LCD_DeviceMessage[]>( await Promise.all( deviceEuiDates.map( async ( [deviceEui, date ]: [ deviceEui: string, date: Date ] ) => {
            const deviceZombie = getZombieInstanceFromPrimaryKey( `Device:${deviceEui}`)
            const Key = getS3TimeKey({ timestamp: date.valueOf(), object: deviceZombie!, s3TimeKeyLevel: S3TimeKeyLevel.DAY, s3TimeKeyStrategy: S3TimeKeyStrategy.TIME_OBJECT } )
            const res = await getObjectFromS3<IOTV_LCD_DeviceMessage[]>( { Key, bucketIdentifier })
            debug && console.log('getDeviceMessagesFromS3 s3Res', res)
            return res
        }) 
    ))
    debug && console.log('getDeviceMessagesFromS3 res', res)

    if ( s3Res.err ) {
        res.err = s3Res.err
    } 
    // we will allow some queries to fail by passing both error and data
    if ( s3Res.data ) {
        debug && console.log('getDeviceMessagesFromS3', s3Res.data)
        const flatMessages = flattenArray<IOTV_LCD_DeviceMessage>( s3Res.data ).sort( ( a, b ) => sortByTimestamp( a, b, SortOrder.DESCENDING ), )
        res.data = flatMessages
    }


    return res


}