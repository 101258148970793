import React from "react";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { googleSheetRef } from "@iotv/iotv-v3-types";
import { SimpleIO, SimpleIOProps } from "./simpleIO";

const debug = process.env.REACT_APP_DEBUG && false;

export enum IOType {
    IMPORT = 'IMPORT', EXPORT = 'EXPORT'
}

export type SimpleIOToggleWrapperProps = {
    fileSelectRef?: React.MutableRefObject<googleSheetRef | undefined>,
    importProps: SimpleIOProps,
    exportProps: SimpleIOProps

}

export const SimpleIOToggleWrapper = ({ importProps, exportProps, fileSelectRef }: SimpleIOToggleWrapperProps) => {

    const [ioType, setIoType] = React.useState<IOType>(IOType.EXPORT);

    const handleIoTypeChange = () => {
        if (ioType === IOType.EXPORT) {
            setIoType(IOType.IMPORT)
        } else {
            setIoType(IOType.EXPORT)
        }
    }

    const simpleIOProps: SimpleIOProps = ioType === IOType.EXPORT ? exportProps : importProps

    return <span { ...{ style: { padding: '12px', marginTop: '20px'}}}>

        <Toggle { ...{
            id: 'ioSwitch',
            defaultChecked: ioType === IOType.EXPORT,
            icons: false,

            onChange: handleIoTypeChange 
        } } />
        <span>  </span>

        <label htmlFor='ioSwitch'>
            <span>{ioType}</span>
        </label>

        <span> <SimpleIO {...{ ...simpleIOProps,  fileSelectRef }}></SimpleIO></span>


    </span>

}