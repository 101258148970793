import { ValidBusinessObject } from '@iotv/datamodel';
import { Storage, } from 'aws-amplify';
import { S3ProviderPutConfig, S3ProviderGetConfig, S3ProviderListConfig, S3ProviderRemoveConfig, S3ProviderListOutput} from '@aws-amplify/storage'
import { S3ImageMeta, S3ImageRecordAndURLMap } from '../../../types/AppTypes';

/**
 * Surely these should be async?
 */

function put(directoryString: string, file: File, progressCallback?: (progress: any) => any) {
    const config: S3ProviderPutConfig = {
        level: 'private',
        contentType: 'image/png',
        progressCallback
    }
    return  Storage.put(directoryString + file.name, file, config)
}


const getObject = (key: string):  Promise<Object | String> => {
    const config:  S3ProviderGetConfig = {
        level: 'private',
        download: false
    }
    return Storage.get(key, config);
}

const listObjects = (path: string): Promise<S3ProviderListOutput> => {
    const config: S3ProviderListConfig = {
        level: 'private',
        maxKeys: 100
    }
    return Storage.list( path, config)

}

const listObjectsAndUrls = async ( path: string ) => {
    const metaUrlList: S3ImageRecordAndURLMap = {}
    await listObjects(path).then( async ( imageMetas ) => {
        const promised = await Promise.all ( imageMetas.map( async ( imageMeta ) => {
            let url: Object | string = ''
            if ( imageMeta.key ) {
                url = await getObject( imageMeta.key )
            }

            return { url, ...imageMeta }
            })  
        )
        promised.forEach( ( item ) => { 
            if (  typeof item.url === 'string' ) {
                const key = item.key
                if ( key ) {
                    metaUrlList[key] = item 
                }
              
            }
        })
    })
    return metaUrlList
}
    
const deleteObject = (key: string) => {
    const config: S3ProviderRemoveConfig = {
        level: 'private'
    }
    return Storage.remove( key, config)

}


const getImageUrl = async ( businessObject: ValidBusinessObject | undefined) => {
    let presignedUrl = undefined
    if ( businessObject ) {
      const imageKey = businessObject.primaryS3Image?.key
      presignedUrl = await getObject(imageKey)
     
    }
    return presignedUrl
  } 

export { put, getObject, listObjects, listObjectsAndUrls, deleteObject, getImageUrl };

