import { camelToDisplay, HistoryStore, ObjectHistory, roundDecimals } from '@iotv/datamodel';
import { MetricStatsType } from '@iotv/iotv-v3-types';
import { Card, CardContent, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { getNiceDate } from '../../data/TypeHelpers';
import { ObjectHistoryCardProps } from '../../types/AppTypes';
import { SetObjectHistoryFilterWidget } from '../common/SetObjectHistoryFilterWidget';
import ObjectGraphCard from './ObjectGraphCard';



const debug = process.env.REACT_APP_DEBUG && false;

export default function ObjectHistoryCard(props: ObjectHistoryCardProps) {

  debug && console.log('ObjectHistoryCard has props', props)

  const { classes, objectHistory, objectHistoryConfig, objectHistoryFilter, setObjectHistoryFilter } = props;



  const getStatTable = (objectHistory: ObjectHistory, key: string) => {

    const cmVarObject = objectHistory[key];
    if ( cmVarObject && cmVarObject instanceof HistoryStore ) {
      const statistics = cmVarObject?.getStatistics();
      const statKeys: { [k in keyof MetricStatsType]: { displayName: string, fn?: (v: number | undefined) => number | string | undefined } } = {
        firstTimestamp: { displayName: 'First Record', fn: (tsMs: number | undefined) => tsMs ? getNiceDate(tsMs) : 'No timestamp' },
        lastTimeStamp: { displayName: 'Last Record', fn: (tsMs: number | undefined) => tsMs ? getNiceDate(tsMs) : 'No timestamp' },
        count: { displayName: 'Count' },
        mean: { displayName: 'Mean', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        varPop: { displayName: 'Population Variance', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        varSample: { displayName: 'Sample Variance', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        sdPop: { displayName: 'Population Std Dev', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        sdSample: { displayName: 'Sample Std Dev', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        max: { displayName: 'Max', fn: (x: number | undefined) => x && roundDecimals(x, 3) },
        min: { displayName: 'Min', fn: (x: number | undefined) => x && roundDecimals(x, 3) }
      }
  
  
      return statistics && Object.keys(statistics).length > 0 ? <Table  size='small' >
  
        <TableHead>
             <TableRow>
            {['Measure', ...Object.entries(statKeys).map(([k, v]) => v.displayName)].map((k) => <TableCell {...{ key: `${k}_header` }}>{k}</TableCell>)}
  
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(statistics).map(([k, v]: [string, MetricStatsType ]) => <TableRow {...{ key: k }}>
  
            <TableCell {...{ key: k }}>{k}</TableCell>
            {Object.entries(statKeys).map(([statK, statV]) => <TableCell {...{ key: `${k}_${statK}` }}>
              {statV.fn ? statV.fn(v[statK as keyof MetricStatsType]) : v[statK as keyof MetricStatsType]}
            </TableCell>)}
  
  
          </TableRow>)}
        </TableBody>
  
  
  
      </Table> : null
    } else return null;
   



  }

  return (
    <Grid container { ...{} }>
         <Grid item { ...{ xs: 12 } }>
    <Card { ...{ className: classes.root, } }>
      <CardHeader
        title='Statistics'
      ></CardHeader>
      <CardContent>
        { objectHistory && Object.entries( objectHistoryConfig ).map ( ([k, v]) => {
          if ( v === true && objectHistoryConfig?.[k] && (((objectHistory?.[k] ?? { x: false }) as any )instanceof HistoryStore )) {
            return <TableContainer component={Paper}>
            <Typography variant='h4'>{`${ camelToDisplay(k) } Stats `}</Typography>
            {getStatTable(objectHistory, k)}
          </TableContainer>
          } else return null 
        }) }


       

      </CardContent>

    </Card>
    </Grid>
     <Grid item { ...{ xs: 12 } }>
     { objectHistory && Object.entries( objectHistoryConfig ).map ( ([k, v]) => {
       if ( v === true && objectHistoryConfig?.[k] && (((objectHistory?.[k] ?? { x: false }) as any )instanceof HistoryStore )) {
         return <ObjectGraphCard {...{ key: `${objectHistory.sk}_${k}_graoh`, historyObject: objectHistory[k], classes, title: `${camelToDisplay(k)}` }} ></ObjectGraphCard>

       } else return null 
     }) }
       {objectHistory && objectHistoryConfig.dailyStateHistory && <Grid item xs={6}>
        </Grid>}


     </Grid>
     </Grid>
  )
}


