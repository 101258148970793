import { ValidBusinessObject } from '@iotv/datamodel';

const normalizer =  ( ob: ValidBusinessObject) => {
    let outputMap: {[sk: string]:  {[pk: string]: ValidBusinessObject | undefined}} = {};

    Object.entries(ob).forEach( ( [key, value] ) => {
        if (value instanceof Array) {
            const obs = value;
            obs.forEach( ( ob ) => {
                // if (ob.pk && ob.sk) outputMap = {...outputMap, ...normalizer(ob) }
                if (ob.pk && ob.sk) outputMap = Object.assign(outputMap, normalizer(ob) )
            });
            delete ob[key]
        }
       
    } )
    if (outputMap[ob.sk] === undefined ) outputMap[ob.sk] = {};
    outputMap[ob.sk][ob.pk] = ob;
    return outputMap;
}

export default normalizer