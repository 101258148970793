import { GeofenceRuleType, getObjectHash, MapLike, MetricRuleType, Payload, RulesetContent, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AdjacentType, AppValueTypes, TypeQueryBody } from '@iotv/iotv-v3-types';
import { Card, CardContent, CardHeader, Grid, makeStyles } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid/';
import config from '../../config';
import styles from '../../cosmetics/sharedCardStyles';
import { getPrimaryItemTypes } from '../../data/AppDao';
import ApiGateway from '../../data/aws/api-gateway/ApiGateway';
import { getPseudoVBO } from '../../data/TypeHelpers';
import { AdjacentFilter, ComponentMode, ListConfigParams, ObjectCardProps, ObjectListCardProps, TabilizerProps, UserTransactionType, ViewDefinition, ViewKeyDefinitionType } from '../../types/AppTypes';
import { getViewDefintionFromObject } from '../factories/AttributeDefinitions';
import { Tabilizer } from '../factories/Tabilizer';
import ObjectListCard from '../generic/ObjecListCard';
import { RulesetMeticRuleCard } from './RulesetMetricRuleCard';

const debug = process.env.REACT_APP_DEBUG && false
const effectDebug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));
const apiRef = config.app.appName;


type RulesetType = {
    definition: {
        GeofenceRule: GeofenceRuleType[],
        MetricRule: MetricRuleType[]
    }
} & ValidBusinessObject

const listGeofencesQuery = async (fn: (params: any) => void) => {
    const params: TypeQueryBody = {
        type: "SEARCH_BY_TYPE",
        contextObject: undefined,
        includeContextLinkedItems: false,
        items: [
            {
                pk: '', sk: '', id: '',
                type: 'Geofence'
            }
        ],
        limit: 40,
        scanForward: true
    }

    try {
        const response = await ApiGateway.post('/query/', params, apiRef)
        debug && console.log('Geofence  query results', response)
        if (response.data?.Items) {

            fn(response.data.Items)
        }
    } catch (e) {
        debug && console.log('Error in Geofence query', e)
    }
}

export const RulesetCard = (props: ObjectCardProps) => {
    const classes = useStyles();
    // dont think we like this, move to ruleset it
    props.viewObject.matchedPrimary &&  ( props.viewObject.matchedPrimary.possibleAppliesToTypes = getPrimaryItemTypes(config.app.appCode) )



    const { viewObject: { matchedPrimary }, contextCustomer } = props
    const rulesetIn = matchedPrimary as  RulesetType | undefined
    const metricRules = rulesetIn?.definition?.MetricRule
    const existingRulesetThingType: string = rulesetIn?.appliesToType

    const  [newMetricRuleId ] = React.useState( uuidv4());

    const  [newGeofenceRuleId ] = React.useState( uuidv4())

    const [ruleset, setRuleset] = React.useState<RulesetType | undefined>(undefined)
    const [rulesetThingtype, setRulesetThingType] = React.useState<string | undefined>(existingRulesetThingType)
    const [geofences, setGeofences] = React.useState<ValidBusinessObjectList>([])
    const [ metricKeysEnum, setMetricKeysEnum ] = React.useState<MapLike<string>>({})

    const objectHash = getObjectHash( ruleset )

    useEffect(() => {
        if (ruleset ) {
            const payloadDefinition = ruleset.appliesToType && Payload.getPayloadDefinitionBySupportedType(ruleset.appliesToType);
            effectDebug && console.log('appliesTo Type', ruleset.appliesToType)
            effectDebug && console.log('payload Def', payloadDefinition)
            const calcStateClass = payloadDefinition && payloadDefinition?.CalculatedState as { new(): MapLike<any> } ;
            const payloadInstanceClass = payloadDefinition && payloadDefinition?.UplinkPayloadInstance as { new(): MapLike<any> } ;
            const calcStateInstance = calcStateClass ? new calcStateClass() : {}
            const payloadInstance = payloadInstanceClass ? new payloadInstanceClass() : {}
            const metricKeysEnum = calcStateClass ? Object.fromEntries(Object.keys({...calcStateInstance, ...payloadInstance}).map((k) => [k, k])) : {}
            effectDebug && console.log('Set metricKeyEnum to', metricKeysEnum)
            setMetricKeysEnum(metricKeysEnum)
        }

    }, [ ruleset?.appliesToType])

    useEffect(() => {
        effectDebug && console.log('RulesetCard used effect on []')
        if (matchedPrimary?.definition?.GeofenceRule && matchedPrimary?.definition?.MetricRule) {
            setRuleset(matchedPrimary as RulesetType)
        } else {
            debug && console.log('Ruleset card did not set ruleset for', matchedPrimary)
        }
    }, [])

    useEffect(() => {
        effectDebug && console.log(`RulesetCard used effect on ${objectHash}`)
    }, [ objectHash ])

    useEffect(() => {
        const fn = (items: ValidBusinessObjectList) => {
            debug && console.log('RulesetCard useEffect', items)
            setGeofences(items);
        };
        listGeofencesQuery(fn)
    }, [])
    // useEffect(() => { }, [geofences.length])


    const numericMetricViewDefinition: ViewDefinition = {
        value: { key: 'value', label: 'Value', editable: true, precision: 3, stateFn: undefined, type: AppValueTypes.NUMBER, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
        , datapoints: { key: 'datapoints', label: 'Datapoints', editable: true, precision: 3, stateFn: undefined, type: AppValueTypes.NUMBER, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }

    }

    const rulesetVD: ViewDefinition = ruleset ? getViewDefintionFromObject( ruleset ) : {}
    const rulesetActions = [UserTransactionType.UPDATE, UserTransactionType.DELETE ]





    const handleAddMetricRule = () => {
        const localRuleset = { ...ruleset }
        if ( localRuleset.definition?.MetricRule ) {
            localRuleset.definition?.MetricRule.push( getPseudoVBO({ id: uuidv4(), type: 'MetricRule'}) as MetricRuleType & ValidBusinessObject)
        }
      
        setRuleset(localRuleset as RulesetType)
    }

    const handleTypeChange = (params: MapLike<any>) => {
        const event = params as React.ChangeEvent<HTMLInputElement>
        debug && console.log('as Event ', event.currentTarget.getAttribute('data-value'))
        const selectedType = event.currentTarget.getAttribute('data-value');
        if (selectedType) {
           setRulesetThingType(selectedType)
        }

    }


    const adjacentFilterGroup: AdjacentFilter = {
        adjacentType: AdjacentType.CHILD,
        objectType: 'Group',
        edgeFilter: undefined
    }



    const listConfigParams: ListConfigParams = {
        label: 'Applies to Group'
    }

    const { optionals, ...propsOther } = props
    const groupListProps: ObjectListCardProps = {
        ...propsOther,
        adjacentFilter: adjacentFilterGroup,
        contextObject: undefined,
        listConfigParams
    }

    const getGeofenceRuleEditor = (geofenceRule: GeofenceRuleType | undefined) => {
        let geofenceRuleInEdit: GeofenceRuleType;
        if (geofenceRule) {
            geofenceRuleInEdit = geofenceRule;
        } else {
            geofenceRuleInEdit = getPseudoVBO({ type: 'GeofenceRule', id: newGeofenceRuleId, name: 'new GeofenceRule', geofence: undefined, enabled: false, allowed: false }) as GeofenceRuleType;
        }

        const geofenceEnums: MapLike<string> = {};
        geofences.forEach((geofence) => geofenceEnums[geofence.name] = geofence.sk)

        const geofenceRuleEditorProps: TabilizerProps = {
            ...props,
            transactionFunctions: {
                ...props.transactionFunctions,
                saveObject: (geofenceRule: ValidBusinessObject) => {
                    debug && console.log('Geofence Rule card want to save', geofenceRule)
                    const objectToSave: RulesetType = { ...ruleset as RulesetType };
                    objectToSave.definition.GeofenceRule = [geofenceRule as Exclude<GeofenceRuleType, 'geofence'> & { geofence: string }]
                    if (objectToSave.definition.GeofenceRule[0]) {
                        const geofenceFromInputComponent = objectToSave.definition.GeofenceRule[0].geofence as unknown as string;
                        if (geofenceFromInputComponent) {
                            const [type, id] = geofenceFromInputComponent.split(':')
                            objectToSave.definition.GeofenceRule[0].geofence = getPseudoVBO({ type, id })
                            debug && console.log('Geofence to save', objectToSave)
                        }  else {
                            debug && console.log('No dgeofenceFromInputComponent', geofenceFromInputComponent)
                        }

                    } else {
                        debug && console.log('No definition.GeofernceRule', objectToSave)
                    }

                    props.transactionFunctions.saveObject(objectToSave as ValidBusinessObject)
                }
            },
            actions: [  ],
            functionOverrides: {
                mode: geofenceRule ? ComponentMode.VIEW : ComponentMode.EDIT,
                updateParentComponentItem: () => { },
                hasValidationErrors: () => { }
            },

            matchedPrimary: geofenceRuleInEdit,
            viewDefinition: {
                name: { key: 'name', label: 'Name', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.STRING, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , enabled: { key: 'enabled', label: 'Enabled', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.BOOLEAN, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , allowed: { key: 'allowed', label: 'Is Permitted', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.BOOLEAN, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , geofence: { key: 'geofence', label: 'Geofence', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.ENUM, unit: undefined, validationRx: undefined, enumKV: geofenceEnums, failsValidationText: undefined }

            } as ViewDefinition | { [k in keyof GeofenceRuleType]: ViewKeyDefinitionType }
        }
        return <Tabilizer key={'geofenceRuleTabilizer'}{...{ ...geofenceRuleEditorProps, }}></Tabilizer>
    }

    const rulesetSetInParent = ( vob: ValidBusinessObject ) => {
        setRuleset( vob as RulesetType )
    }

    

    return <Card className={classes.root}>
        <CardHeader>

        </CardHeader>
        <CardContent>
            <Grid container>

                <Grid item xs={12} lg={6}>
                    {ruleset && <Tabilizer {...{ ...props, matchedPrimary: ruleset,  viewDefinition: rulesetVD, actions: rulesetActions, functionOverrides: { mode: ComponentMode.EDIT, updateParentComponentItem: rulesetSetInParent, hasValidationErrors: () => {}}  }}></Tabilizer>}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {ruleset && <ObjectListCard {...{ ...groupListProps }}></ObjectListCard>}
                </Grid> 
                

                { [ RulesetContent.GEOFENCE, RulesetContent.GEOFENCE_AND_METRIC].includes(ruleset?.rulesetContent) &&<Grid item xs={12} lg={6}>
                    {getGeofenceRuleEditor(ruleset?.definition.GeofenceRule[0])}
                </Grid> }

                { [ RulesetContent.METRIC, RulesetContent.GEOFENCE_AND_METRIC].includes(ruleset?.rulesetContent) && <Grid item xs={12} lg={6}>
                    { ruleset?.definition.MetricRule.map( ( metricRule, i ) => {
                        const setArrayObjectNInParent = ( metricRule:  ValidBusinessObject ) => {
                            debug && console.log('setArrayObjectNInParent was called in RulesetCard', metricRule)
                            const rulesetClone = { ...ruleset }
                            const metricRules = rulesetClone.definition.MetricRule
                            metricRules.splice(i, 1, metricRule as MetricRuleType & ValidBusinessObject )
                            debug && console.log(`Metric card is setting ruleset metric ${i}`, rulesetClone)
                            setRuleset( rulesetClone )
                        }

                        const removeArrayObjectNInParent = () => {
                            const rulesetClone = { ...ruleset }
                            const metricRules = rulesetClone.definition.MetricRule
                            metricRules.splice(i, 1  )
                            debug && console.log(`Metric card is removing ruleset metric ${i}`)
                            setRuleset( rulesetClone )
                        }



                        const metricRuleWithKeys: MetricRuleType & ValidBusinessObject = getPseudoVBO( { ...metricRule, type: 'MetricRule', id: i.toString() } ) as  MetricRuleType & ValidBusinessObject
                        return <RulesetMeticRuleCard { ...{ ...props, metricRule: metricRuleWithKeys, metricKeysEnum , removeArrayObjectNInParent, setArrayObjectNInParent}}></RulesetMeticRuleCard> 
                    })  }
                   <AddRounded { ...{
                     onClick: handleAddMetricRule  
                   }}></AddRounded>

                </Grid> }
            </Grid>
        </CardContent>
    </Card>

}