import { CustomerAccountType, DatastoreObjectType, Gateway, roundDecimals, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AddressType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, FormControlLabel, Grid, Switch, TableCell, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/DeleteSweepRounded'
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { AdjacentType, CustomerContextTabProps, CustomerGatewayListTabProps, GatewayPoolType, ListDatabaseUsersRequest, ListerlizerProps, ListerlizerRowControlFnProps, ListGatewaysRequest, MessageOutputType, SearchParamsType, UserTransactionType, ViewDefinition, ViewObject } from '../../../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../factories/DocumentClientListerlizerWrapper';
import { useDocumentClientQuery } from '../../../../hooks/useDocumentClientQueryV1';
import { NavSections } from '../../../navigation/NavSections';
import { commonListViewDefinition, getViewDefinitionFromUserAttributes, pluckAndOrderFromViewDef } from '../../../factories/AttributeDefinitions';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';


function CustomerGatewayListTab(props: CustomerGatewayListTabProps): JSX.Element {
  debug && console.log('Customer Gateway List tab got', props)
  const contextCustomer: ValidBusinessObject | undefined = props.contextCustomer;
  const { userFunctions, history, transactionFunctions, match: { params: { action } }, gatewayPoolType} = props;
  const routerHistory = useHistory();
  const [gatewayInCreation, setGatewayInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [originalVersion, setOriginalVersion] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [selectedGateway, setSelecteGateway] = React.useState<ValidBusinessObject | undefined>(undefined)

 

  const listGateways = async (fn: (params: any) => void, contextRequest: ListGatewaysRequest) => {
    const urlPart = gatewayPoolType === GatewayPoolType.INVENTORY ? 'listInventoryGateways' : gatewayPoolType === GatewayPoolType.ALLOCATION ? 'listAllocatedGateways' : null;
    if (urlPart) {
      try {
        const response = await ApiGateway.post(`/system/Gateway/${urlPart}`, contextRequest, apiRef);
        debug && console.log('CustomerGatewayView query results', response)
        fn(response);
      } catch (e) {
        debug && console.log('Error in UM query', e)
      }
    }
  }

  const deleteGateway = async (gateway: ValidBusinessObject | undefined) => {
    if (gateway) {
      const response = await ApiGateway.post('/system/Gateway/delete', gateway, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        displayMessage(`Removed ${gateway?.name} from datastore`, 'SnackBar')
      }
    }
  }

  const deleteMultipleGateways= async () => {
    const promises = selectedObjects.map((gateway) => deleteGateway(gateway as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('Delete multople gateway res', res);
    setSelectedObjects([]);
    forceUpdate()
  }

  const queryParams: ListGatewaysRequest = {
    ExclusiveStartKey: undefined,
    contextUser: props.user as ValidBusinessObject & { type: 'User' },
    contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
    filter: undefined
  }
  const contextCustomerSk = contextCustomer?.sk

  const queryHook = useDocumentClientQuery(listGateways, queryParams, [ contextCustomerSk])

  const [[items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects] = queryHook.selectionControls;
  const forceUpdate = queryHook.forceUpdate

  debug && console.log('CustomerGatewayListTab items count', items.length)
  const [inventoryDevicesLastEvaluatedKey, setDevicesLastEvaluatedKey] = React.useState<DocumentClient.Key | undefined>(undefined)
  const [queryLimit, setQueryLimit] = React.useState<number | undefined>(undefined)

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }


  const viewObject: ViewObject = {
    matchedPrimary: contextCustomer,
    matchedRelatedByPk: [],
    matchedRelatedBySk: []
  }

  const gatewayUserAtts =  new Gateway({}).getUserAttributes()

  const autoViewDef = getViewDefinitionFromUserAttributes(gatewayUserAtts);
  const gatewayTableViewDefinition: ViewDefinition & { [key in keyof Gateway]?: any } =  {

    ...pluckAndOrderFromViewDef( [ 'name', 'nwID', 'eui',  'state', 'isAWOL', '_permittedAWOL', 'lastSeen', 'primaryGatewayUplinkCount', 'secondaryGatewayReceiveCount', 'billingGroup',  ], autoViewDef)
 
  }

  delete gatewayTableViewDefinition.description

  const listProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Gateway',
    },
    autoQuery: false,
    maxItems: 50,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: gatewayTableViewDefinition,
    viewObject,
    contextObject: undefined,
  }

  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<ListGatewaysRequest> =  contextCustomer?.sk && queryParams ? {
    ...listProps, queryHook, queryParams,
    queryControls: () => [
        <ButtonGroup>
          {selectedObjects.length > 0 &&
            <Button
              size='small'
              onClick={() => deleteMultipleGateways()}
              startIcon={<DeleteIcon />}
              variant="outlined"
              color="primary"
            >
              Delete Selected
                   </Button>
          }
          <Button
            size='small'
            onClick={() => routerHistory.push(createUrl)}
            disabled={action === UserTransactionType.CREATE}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
          >
            Gateway
                  </Button>


        </ButtonGroup>
      ],
    rowControlOverrideFns: {
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell {...{
              key: `${listRowProps.rowObject.sk}_goto`, onClick: () => {
                const viewUrl = queryString.stringifyUrl({
                  url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Gateway/${listRowProps.rowObject.sk}`,
                  query: {
                    contextObjectSK: contextCustomer.sk
                  }
                });
                routerHistory.push(viewUrl)
              }
            }}>
              <PlayArrowIcon {...{
              }} />
            </TableCell>

          ]
        }
      ]
    }
  } : undefined;

  const createUrl = queryString.stringifyUrl(
    {
      url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}/Gateway/`,
      query: {
        objectTypeId: 'Gateway',
        contextObjectSK: contextCustomer?.sk,
        accountType: contextCustomer?.accountType
      }
    }
  )

  return (
    <Grid className='customerManagementViewGrid' container spacing={3} >
      <Grid item xs={12} >
        <Card>
          <CardContent>
            <Typography variant="h4" component="h4" gutterBottom>
              { 'Gateways'}
            </Typography>
                       {documentClientListerlizerProps &&
              <DocumentClientListerlizerWrapper key={'customerGatewayDocClientList'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

}
export default withStyles(styles)(CustomerGatewayListTab)
