import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Loading.module.css';

interface MessageProp {
  message?: string;
}

export const StaticLoadingAnimation: React.FC<MessageProp> = (props: MessageProp, background: boolean = false) => {
  const { message = 'Loading, please wait' } = props;
  return (
    <div className={styles.center}>
      <CircularProgress size={120} color='secondary' />
      <p>{message}</p>
    </div>
  );
};

export const RelativeLoadingAnimation: React.FC<MessageProp> = (props: MessageProp, background: boolean = false) => {
  const { message = 'Loading, please wait' } = props;
  return (
    <div className={styles.relative}>
      <CircularProgress size={120} color='secondary' />
      <p>{message}</p>
    </div>
  );
};
