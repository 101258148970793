import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import TankGroupIndividualWaterAvailabilityCard from './TankGroupIndividualWaterAvailabilityCard';
const debug = process.env.REACT_APP_DEBUG && false;
const avatarSize = '90%';

const useStyles = makeStyles((theme) => {
    return {
        ...styles(theme),
        avatarLarge: { height: avatarSize, width: avatarSize },
        objectNameLarge: { fontSize: 'xx-large' },
        variableHeightCard: {
            height: '100%',
        },
        mapCardContent: {
            height: '100%',
            width: '100%',
        },
        root: {
            [theme.breakpoints.up('lg')]: {
                backgroundColor: 'red',
            }
        },
        buttonGroup: {
            width: "100%",
            textAlign: 'left'
        },
        dividerColor: {

        },
        indicator: {
            top: "0px",
            backgroundColor: '#44bcd8'
        },
    }

});


export default function GroupIndividualTankCard(props: any) {
    debug && console.log('GroupIndividualTankCard got props', props)
    const classes = useStyles();
    // const { searchParams, userFunctions, adjacentFilter, listConfigParams } = props

    // const actions: UserTransactionType[] = [UserTransactionType.UPDATE]

    // const viewDefinition: ViewDefinition = {
    //     name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    //     description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    // }

    // const others: MapLike = listConfigParams;
    const { tankItem } = props;
    debug && console.log('t tankItem t:', tankItem)
    // debug && 


    return (
        // viewObject.matchedPrimary ?
        // <NavLink filter={`${NavSections.THINGVIEW}/${viewObject.matchedPrimary?.type}/${viewObject.matchedPrimary?.id}`} children={
        // <div>
        <Grid container spacing={2} className={classes.c021GITIndividualCard}>
                <Grid item xs={4}>
                    <TankGroupIndividualWaterAvailabilityCard
                        tankItem={tankItem}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex"
                        width='100%'
                        height='100%'
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        bgcolor="background.paper">
                        <Typography variant="subtitle2" component="h2">
                            {tankItem?.tank_type || '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" alignItems="center"
                        // width='100%'
                        height='100%'
                        justifyContent="center"
                        textAlign="center"
                        bgcolor="background.paper">
                        <Typography variant="subtitle2" component="h2">
                            {tankItem?.name || '-'}
                        </Typography>
                    </Box>
                </Grid>

            </Grid>
        // {/* </div> */}
        //  // }></NavLink> 
        // // : null 

    );
}