import { NodeEdgeMapper, ValidBusinessObject, ValidBusinessObjectList, ValidModelObject } from '@iotv/datamodel';
import { Hidden, MenuItem, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { TransactionFunctions, UserFunctions } from '../actions/AppActions';
import config from '../config';
import { styles } from '../cosmetics/HeaderStyles';
import { getImageUrl } from '../data/aws/s3/UserBlobs';
import { UserGroupsRolesType, ValidCustomerObject } from '../types/AppTypes';
import { DialogWrapper } from './common/DiaglogWrapper';
import { NestedCustomerSelector } from './common/NestedCustomerSelector';
import { NavSections } from './navigation/NavSections';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Chip from '@mui/material/Chip';
import { AppLocalStorage } from '../data/LocalStorage/AppLocalStorage';



const debug = process.env.REACT_APP_DEBUG && false;



interface HeaderProps extends WithStyles<typeof styles> {
  onDrawerToggle: () => void,
  logout: Function,
  userFunctions: typeof UserFunctions,
  transactionFunctions: typeof TransactionFunctions,
  user: any,
  userGroupRoles: UserGroupsRolesType,
  userSelectedGroup: string | undefined,
  userCustomers: ValidBusinessObjectList,
  contextCustomer: ValidModelObject<'Customer'> | undefined,
  pinger: boolean,
  fetching: boolean,
}

function Header(props: HeaderProps) {
  const { fetching, classes, onDrawerToggle, user, userGroupRoles, userSelectedGroup, userFunctions, logout, transactionFunctions, pinger, userCustomers: userCustomersIn, contextCustomer } = props;
  debug && console.log('user customers', userCustomersIn)
  const getDefaultGroupIdx = () => {
    const matchedPreferredIdx = userGroupRoles.preferredRole !== undefined ? userGroupRoles.groups.findIndex((group) => userGroupRoles.preferredRole?.includes(group)) : 0
    return matchedPreferredIdx
  }

  const userCustomers = userCustomersIn.sort( (c1, c2) => (c1.name <  c2.name ? -1 : 1))

  const userSelectedGroupIdx = userGroupRoles.groups.findIndex((group) => group === userSelectedGroup);
  const appSelectedIndx = userSelectedGroupIdx > -1 ? userSelectedGroupIdx : getDefaultGroupIdx();
  debug && console.log('userSelectedGroup', userSelectedGroup)
  debug && console.log('appSelectedIndx', appSelectedIndx)
  debug && console.log('userGroupRoles', userGroupRoles)
  // may have to change below if cognito:groups not in precedence order
  const [userRoleIdx, setUserRoleIdx] = React.useState(appSelectedIndx);
  const routerHistory = useHistory()
  const [ nodeEdgeMapper, setNodeEdgeMapper ] = React.useState<NodeEdgeMapper | undefined>(undefined)
  const [ customerSelect, setCustomerSelect ] = React.useState<boolean>(false)

  const [heldCustomer , setHeldCustomer] = React.useState<ValidCustomerObject>()
  const holdCustomer = ( contextCustomer: ValidCustomerObject ) => {
    setHeldCustomer(contextCustomer)
  }
  const changeCustomer = () => {
    userFunctions.changeAppState({ contextCustomer: heldCustomer })
    heldCustomer && AppLocalStorage.set( NavSections.HOME, 'lastContextCustomerSk', heldCustomer.sk )

  }

  useEffect(() => {
    if ( userCustomers ) {
      const nodeEdgeMapper = new NodeEdgeMapper()
      /* currently the secondary query to put admisterable customers only returns edges */
      const temporaryShimPrimaries  = userCustomers.map( ( c ) => {
        if ( c.pk !== c.sk  && !userCustomers.find( ( uc ) => uc.sk === c.sk && uc.pk === c.sk )) {
          return { ...c, pk: c.sk }
        } else return undefined
      }).filter( ( c ) => c ) as ValidBusinessObjectList
      nodeEdgeMapper.loadItems(userCustomers)
      nodeEdgeMapper.loadItems( temporaryShimPrimaries )
      setNodeEdgeMapper(nodeEdgeMapper)
    }
  }, [userCustomers?.length] )

  const nodeEdgesHash = Object.keys(nodeEdgeMapper?.skMapped ?? { noNodes: true }).join('')

  useEffect(() => {
      debug && console.log('CommunitiesView usedEffect on nodeEdgeHash', nodeEdgesHash)
  }, [nodeEdgesHash])

  useEffect(() => {
      if (nodeEdgeMapper) {
          debug && console.log('CommunitiesView usedEffect on skMapeed', Object.keys(nodeEdgeMapper.skMapped).length)
      }

  }, [nodeEdgeMapper?.skMapped])


  useEffect(() => {
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.user)
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url)
        setImageUrl(url)
      }
      setUserRoleIdx(appSelectedIndx)
    }

    effectUrl()

  }, [props.user, appSelectedIndx]);



  const [imageUrl, setImageUrl] = React.useState('')

  const changeUserRole = (idx: number) => {

    const userSelectedGroup = userGroupRoles.groups[idx];
    debug && console.log('requestiong userRole', userSelectedGroup)
    transactionFunctions.changeUserRole(user, userGroupRoles, userSelectedGroup)
    // and then change the role
    debug && console.log('set userRoleIdx', idx)
  }

  const openCustomerSelect = () => { debug && console.log( `open customer select`); setCustomerSelect(true)}



  const getRoleSelectOptions = () => {
    debug && console.log('props.userGroupRoles', props.userGroupRoles)
    const elements =  props.userGroupRoles.groups.map( (roleName, i)  =>  <MenuItem key={`${i}`} value={i}>{roleName}</MenuItem> )
    debug && console.log('Role selection options', elements)
    return elements;
  }

  return (
    <React.Fragment>
      <AppBar className={classes.secondaryBar} color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            {  config.app.appCode === 'i001' && config.app.stage  === 'dev' &&
                        <Grid item md >
                          <Hidden smDown>
                          <div>
          <Typography>{config.app.appName}</Typography>

          </div>
                          </Hidden>
                      
                       </Grid>
            } 

            <Grid item>
              { props.user?.name ?
              <NavLink key = {'userLink'} to= {`/${NavSections.USERVIEW}/${props.user?.id}` } children = {props.user.name} 
              activeStyle={{

                color: "inherit",
                textDecoration: 'none',
              }}
            
            style={{
              textDecoration: 'none',
              color: 'inherit'
            }}
              />
              : `${fetching ? 'Authenticating...' : 'Not authenticated'}`
              }
                
              
            </Grid>
            <Grid item>
              { props.userGroupRoles.groups?.length > 1 &&
              <Select
              style={{
                color: 'inherit'
              }}
              value={ userRoleIdx }
              onChange={(e) => changeUserRole(e.target.value as number)}            
            >
              {getRoleSelectOptions()}
              </Select>
              }
         
            </Grid>
            { nodeEdgeMapper && <Grid><Chip { ...{ clickable: true, icon: <SettingsApplicationsIcon/>, color: 'primary', label:  contextCustomer?.name ?? 'waiting', onClick: openCustomerSelect } }></Chip></Grid>}

         
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <span>
                <IconButton color="inherit" disabled = {pinger === false}>
                  <CallReceivedIcon 
                  
                  >
                  </CallReceivedIcon>
                </IconButton>
                </span>
                
              </Tooltip>
            </Grid>
            <Grid item>
              <span>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon

                  ></NotificationsIcon>
                </IconButton>
              </Tooltip>
              </span>
              
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <Avatar src={imageUrl} alt="My Avatar" />
              </IconButton>
            </Grid>
            <Grid item >
              <IconButton color="inherit"  className={classes.menuButton} onClick = {() => logout()} >
                <ExitToAppIcon/>
              </IconButton>
 
            </Grid>

        </Toolbar>
      </AppBar>
      { nodeEdgeMapper &&   <DialogWrapper {...{
    open: customerSelect, setClosed: (save:boolean) => { console.log('Close'); save && changeCustomer(); setCustomerSelect(false) }, header: `Switch customer from ${contextCustomer?.name}`,

    wrappedElement: <NestedCustomerSelector { ...{ nodeEdgeMapper, setHoldContextCustomerFn: holdCustomer }}></NestedCustomerSelector>
  }}></DialogWrapper>}
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);
