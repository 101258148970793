import { AdjacentType, CustomerAccountType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { getAdjacent2Wrapper, GetAdjacentRequest } from '../../../data/daoFunctions/daoFunctions';

const debug = process.env.REACT_APP_DEBUG && false;

/**
 * Gets the neighbours as specified by the request
 * @param source the request definition
 * @returns neighbours in the choosen direction, not including itself
 */
const getNeightbours = async (source: GetAdjacentRequest): Promise<ValidBusinessObjectList> => {
  return await getAdjacent2Wrapper(source).then(({ err, data }) => {
    return data?.Items?.filter((res) => res !== source) as ValidBusinessObjectList;
  });
};

/**
 * Gets all Z-level users of the context user
 * @param contextUser the user (you) who is using the UI
 * @returns all z-level customers they manage
 */
export const getUserZCustomers = async (contextUser: ValidBusinessObject): Promise<ValidBusinessObjectList> => {
  const getUserCustomerRequet: GetAdjacentRequest = {
    scopeDefinitingContextObject: contextUser,
    adjacencyType: AdjacentType.CHILD,
    objectTypeId: 'Customer',
    includeEdges: false,
  };

  let zCustomerList: ValidBusinessObjectList = [];
  let otherCustomerList: GetAdjacentRequest[] = [getUserCustomerRequet];

  while (otherCustomerList.length !== 0) {
    let current = otherCustomerList[0];
    let res = (await getNeightbours(current)).filter((data) => data.accountType !== current.scopeDefinitingContextObject.accountType);
    res.forEach((data) => {
      if (data.accountType === CustomerAccountType.Z) {
        zCustomerList.push(data);
      } else {
        otherCustomerList.push({ ...getUserCustomerRequet, scopeDefinitingContextObject: data });
      }
    });
    otherCustomerList.shift();
  }
  return zCustomerList;
};

/**
 * Returns all customers of all levels under the user on the screen
 * @param contextUser The user on the UI
 * @param getEdge if you also want the edge definitions
 * @returns all customers (and edges if specified) under the current user
 */
export const getAllCustomers = async (
  contextUser: ValidBusinessObject,
  addSelf: boolean = false,
  getEdge: boolean = false
): Promise<ValidBusinessObjectList> => {
  const getUserCustomerRequet: GetAdjacentRequest = {
    scopeDefinitingContextObject: contextUser,
    adjacencyType: AdjacentType.CHILD,
    objectTypeId: 'Customer',
    includeEdges: getEdge,
  };

  let customerList: ValidBusinessObjectList = addSelf ? [contextUser] : [];
  let customerSearchingList: GetAdjacentRequest[] = [getUserCustomerRequet];

  while (customerSearchingList.length !== 0) {
    let current = customerSearchingList[0];
    let res = (await getNeightbours(current)).filter((data) => data.accountType !== current.scopeDefinitingContextObject.accountType);
    res.forEach((data) => {
      //check if the data exist
      if (!customerList.find((customer) => customer.id === data.id)) {
        customerList.push(data);
      }

      data.pk === data.sk && customerSearchingList.push({ ...getUserCustomerRequet, scopeDefinitingContextObject: data });
    });
    customerSearchingList.shift();
  }
  return customerList;
};

/**
 * Query that takes multiple sources to get its direct child of a specific type
 * @param parents list of sources that you wish to query from
 * @param childType the type of object under the parent
 * @param wantEdge optional parameter, allowing you to retrive the edges between the objects
 * @returns list of children (and the edges) from the parent of the type requested
 */
export const getParentsChildren = async (parents: ValidBusinessObjectList, childType: string, wantEdge: boolean = false): Promise<ValidBusinessObjectList> => {
  let requestList: GetAdjacentRequest[] = [];
  let childList: ValidBusinessObjectList = [];
  parents.forEach((parent) => {
    requestList.push({
      scopeDefinitingContextObject: parent,
      adjacencyType: AdjacentType.CHILD,
      objectTypeId: childType,
      includeEdges: wantEdge,
    });
  });

  while (requestList.length !== 0) {
    let current = requestList[0];
    let res = (await getNeightbours(current)).filter((data) => data !== current.scopeDefinitingContextObject); //filter itself out

    res.forEach((data) => {
      childList.push(data);
    });
    requestList.shift();
  }
  return childList;
};

/**
 * Query that takes a single inputs to get its direct child of a specific type
 * @param parent the source where the children is to be retrieved from
 * @param childType the type of the direct descendent
 * @returns all objects of the provided type which exist directely under the parent
 */
export const getParentChildren = async (parent: ValidBusinessObject, childType: string): Promise<ValidBusinessObjectList> => {
  const getParentChildrenRequest: GetAdjacentRequest = {
    scopeDefinitingContextObject: parent,
    adjacencyType: AdjacentType.CHILD,
    objectTypeId: childType,
    includeEdges: false,
  };

  return await getNeightbours(getParentChildrenRequest);
};

/**
 * Query that gets the parent of an object
 * @param child
 * @returns
 */
export const getChildParent = async (child: ValidBusinessObject, parentType: string): Promise<ValidBusinessObjectList> => {
  const getChildParentRequest: GetAdjacentRequest = {
    scopeDefinitingContextObject: child,
    adjacencyType: AdjacentType.PARENT,
    objectTypeId: parentType,
    includeEdges: false,
  };

  return await getNeightbours(getChildParentRequest);
};
