import React, { useState, useEffect }from 'react'
import { Button, Card, Container, Grid, makeStyles, Typography } from '@material-ui/core'

import { useStyles } from '../cosmetics/communityStyles'
import { Community } from '@c021/datamodel'
import { useHistory } from 'react-router-dom';
import { AdjacentType, getZombieInstanceFromPrimaryKey, ObjectHistory, ValidBusinessObject } from '@iotv/datamodel-core';
import { getAdjacent2Wrapper, getOne } from '../../../data/daoFunctions/daoFunctions';
import { CommunityDailyUsageGraph } from './CommunityDailyUsageGraph';
import { CommunityFillLevelGraph } from './CommunityFillLevelGraph';
import { GridColDef, DataGrid } from '@mui/x-data-grid';

export const DroughtManagementReportView = () => {
    const styles = useStyles()
    const history = useHistory()
    const [ community, setCommunity ] = useState<Community>()
    const [rows, setRows] = useState([])
    const [ objectHistory, setObjectHistory] = useState<ObjectHistory>()

    useEffect(() => { 
        fetchCommunity()
    }, [])

    /**
     * Function fetches the current community to display from its ID
     */
    const fetchCommunity = async () => {
        const communityID = history.location.pathname.replace('/COMMUNITYVIEW/', '').replace('/report', '')

        const zombie = getZombieInstanceFromPrimaryKey(`Customer:${communityID}`) as ValidBusinessObject;
        const community = await getOne(zombie)

        community.data ? setCommunity(community.data as Community) : console.log('oh noes')
        getObjectHistory(community.data as Community)
    }

    const getObjectHistory = async (object : ValidBusinessObject) => {
        const objectHistoryRes = await getAdjacent2Wrapper({
            adjacencyType: AdjacentType.CHILD,
            objectTypeId: 'ObjectHistory',
            scopeDefinitingContextObject: object,
            includeEdges: true
        })
        console.log(objectHistoryRes)

        if(objectHistoryRes.data?.Items){
            const objectHistory = objectHistoryRes.data.Items[0] as ObjectHistory

            setObjectHistory(objectHistory)
        }
    }


    const test = [{
        id: 1,
        date : '20/03/22',
        numTankUsers : 'everytone',
        numTanks : 142,
        communityFillLevel : '70%',
        totalHouseholdTanksFillLevel : 0,
        avgDailyUsageAtStart : 0,
        currentAvgDailyUsage : 0,
        dailyUsageYesterday : 0
    }]

    const columns : GridColDef[] = [
        {
            field : 'date',
            headerName : 'Date',
            flex : 1,
            headerAlign: 'center',
            align : 'center',
            cellClassName : 'testStyling',
            type : 'date'
        },
        {
            field : 'numTankUsers',
            headerName : '# tank users',
            flex : 1,
            minWidth: 150,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        {
            field : 'numTanks',
            headerName : '# tanks',
            flex : 1,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        {
            field : 'communityFillLevel',
            headerName : 'Community Fill Level',
            flex : 1,
            minWidth : 190,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        {
            field : 'totalHouseholdTanksFillLevel',
            headerName : 'Total Household Tanks Fill Level',
            flex : 1,
            minWidth : 280,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        // {
        //     field : 'runOutDate',
        //     headerName : 'Run Out Date',
        //     flex : 1,
        //     minWidth : 150,
        //     headerAlign: 'center',
        //     align : 'center',
        //     type : 'date'
        // },
        // {
        //     field : 'daysLeft',
        //     headerName : 'Days Left',
        //     flex : 1,
        //     headerAlign: 'center',
        //     align : 'center',
        //     type : 'number'
        // },
        {
            field : 'avgDailyUsageAtStart',
            headerName : 'Average Daily Usage at Start of Stage',
            flex : 1,
            minWidth : 300,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        {
            field : 'currentAvgDailyUsage',
            headerName : 'Current Average Daily Usage (since stage start)',
            flex : 1,
            minWidth : 370,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        {
            field : 'dailyUsageYesterday',
            headerName : 'Daily Usage Yesterday',
            flex : 1,
            minWidth : 220,
            headerAlign: 'center',
            align : 'center',
            type : 'number'
        },
        // {
        //     field : 'changeInAvgDailyUsage',
        //     headerName : 'Change In Average Daily Usage',
        //     flex : 1,
        //     minWidth : 270,
        //     headerAlign: 'center',
        //     align : 'center',
        //     type : 'number'
        // },
        // {
        //     field : 'droughtManagementGoal',
        //     headerName : 'Drought Management Goal',
        //     flex : 1,
        //     minWidth : 230,
        //     headerAlign: 'center',
        //     align : 'center',
        //     type : 'number'
        // }
    ]

    return (
        <div>
            <Grid container className={styles.container} spacing={2}>
                <Grid item>
                    <Typography variant='h5' component='h5'>
                        Community Drought Management Report
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant='contained' disabled={true} color='primary'>Download Report</Button>
                </Grid>
                    
                <Grid item xs={12} >
                    <Card className={styles.container}>
                        <Typography variant='h5' component='h5' align='center'>
                            {community?.name}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <Typography variant='h5' component='h5' align='center'>
                            Community Fill level graph
                        </Typography>
                        <CommunityFillLevelGraph/>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <Typography variant='h5' component='h5' align='center'>
                            Community Daily Usage graph
                        </Typography>
                        <CommunityDailyUsageGraph />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <DataGrid
                            disableSelectionOnClick={true}
                            disableColumnMenu={true}
                            autoHeight={true}
                            rows = {test}
                            columns = {columns}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}