import config from '@iotv/config';
import { CustomerAccountType, DeviceEnabledTypesMap, getDeviceEnabledTypes, ValidModelObject } from '@iotv/datamodel';
import { ErrDataReducer, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Grid, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Button, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import AppDao from '../../../../data/AppDao';
import { listObjectsAndUrls, put } from '../../../../data/aws/s3/UserBlobs';
import { link, save, unlink } from '../../../../data/daoFunctions/daoFunctions';
import { DispatchFunctionsType, Severity, UserFunctions } from '../../../../types/AppTypes';
import { addObjectToDB } from '../../Database/addToDb';
import { createCollectionAreaZombie, createTankZombie } from '../../Database/Zombies/index';
import { getAllCustomers, getParentChildren } from '../../Queries/index';
import { displayMessage } from '../Message/alert';
import AddHousehold from './AddHousehold';
import styles from './Modal.module.css';

const modalRoot = document.querySelector('#modal-root');

const editTankStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '27ch',
      width: '79vw',
    },
  },
  textField: {
    width: 'auto',
    height: 'auto',
    color: 'primary',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  delete: {
    margin: theme.spacing(1),
  },

  cancel: {
    margin: theme.spacing(1),
  },
  save: {
    margin: theme.spacing(1),
  },
}));

const calculateTankVolume = (height: number, diameter: number): number => {
  return Math.PI * (diameter / 2) ** 2 * height * 1000;
};

export interface AddModalProps {
  showScreen: Dispatch<SetStateAction<boolean>>;
  type?: string;
  source: ValidBusinessObject | undefined;
  userFunctions: UserFunctions;
  setUpdate: Dispatch<SetStateAction<boolean>>;
  transactionFunctions: DispatchFunctionsType;
  contextUser: ValidBusinessObject | undefined;
  existing?: ValidBusinessObject;
}

const AddTank: React.FC<AddModalProps> = (props: AddModalProps) => {
  let { showScreen, type = 'Community', source, setUpdate, contextUser, transactionFunctions, userFunctions, existing } = props;
  const routerHistory = useHistory();
  const editTank = editTankStyles();

  let [creating, setCreating] = useState<boolean>(false);
  let [valid, setValid] = useState<boolean>(false);

  let [createHousehold, setCreateHousehold] = useState<boolean>(false);
  let [allHouseholds, setAllHouseholds] = useState<ValidBusinessObjectList>();
  let [selectedHousehold, setSelectedHousehold] = useState<ValidBusinessObject>();

  let [success, setSuccess] = useState<boolean>(false);
  let [name, setName] = useState<string>(existing?.name);
  let [users, setUsers] = useState<number>(existing?.no_of_users);
  let [height, setHeight] = useState<number>(existing?.height);
  let [diameter, setDiameter] = useState<number>(existing?.diameter);
  let [numOfTanks, setNumOfTanks] = useState<number>(existing?.no_of_tanks);
  let [actualRoofArea, setActualRoofArea] = useState<number>();
  let [potentialRoofArea, setPotentialRoofArea] = useState<number>();
  let [notes, setNotes] = useState<string>(existing?.notes);
  let [lat, setLat] = useState<number>(existing?.coordinates?.lat);
  let [lng, setLng] = useState<number>(existing?.coordinates?.lng);

  let [deviceType, setDeviceType] = useState<number>(26);
  let [allDevices, setAllDevices] = useState<ValidBusinessObjectList>();
  let [device, setDevice] = useState<ValidBusinessObject | undefined>(undefined);
  let [subType, setSubtype] = useState<string>('');

  let [uploadImages, setUploadImages] = useState<FileList>();

  let [existingCollectionArea, setExistingCollectionArea] = useState<ValidBusinessObject | undefined>();
  let [existingDevice, setExistingDevice] = useState<ValidBusinessObject | undefined>();

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    fileList !== null && setUploadImages(fileList);
  };

  // get from this source's devices
  useEffect(() => {
    if (existing) {
      getCollectionArea(existing);
    } else {
      getHouseholds();
    }
    getDevices();
  }, [source, success, deviceType, subType]);

  async function getCollectionArea(tank: ValidBusinessObject) {
    getParentChildren(tank, 'Collection').then((res) => {
      if (res.length !== 0) {
        setExistingCollectionArea(res[0]);
        setActualRoofArea(res[0].roof_total_area);
        setPotentialRoofArea(res[0].effective_area);
      }
    });
  }

  /**
   * Function used to get all devices under the current customer.
   * Will need to be able to get all devices under this customer, and their customers, etc
   * Also need to filter out devices which are already assigned to a device
   */
  async function getDevices() {
    const apiRef = config.app.appName;
    let selectableDevices: ValidBusinessObjectList = [];
    // if (existing && device === undefined) {
    //   getParentChildren(existing, 'Device').then((solo) => {
        
    //     if (solo.length === 1) {
    //       let modified = { ...solo[0] };
          
    //       modified.id = `${modified.id} (${
    //         modified.deviceType === 26 ? (modified.deviceSubType === '' ? 'Water Pressure' : 'Pressure and Rain') : 'Radar Distance'
    //       })`;
    //       setDeviceType(modified.deviceType);
    //       setSubtype(modified.deviceSubType || '');
    //       selectableDevices = [modified];
    //       setDevice(modified);
    //       setExistingDevice(solo[0]);
    //     }
    //   });
    // }
    console.log("whatisthemodified", deviceType ,subType)
    const request = {
      contextCustomer: source as ValidModelObject<'Customer'>,
      deviceEUI: undefined,
      possibleDeviceEnabledTypeIds: ['Tank'],
      deviceType: deviceType,
      deviceSubType: subType !== '' ? subType : undefined,
    };
    AppDao.apiGateway.post('/device/getConnectableDevices/', request, apiRef).then((res) => {
      if (res.err === null) {
        let filteredList: ValidBusinessObjectList = [];
        
        if (subType === '') {
          filteredList = (res.data as ValidBusinessObjectList).filter((dev) => !dev.hasOwnProperty('deviceSubType') || dev.deviceSubType === '');
        } else {
          filteredList = (res.data as ValidBusinessObjectList).filter((dev) => dev?.deviceSubType === subType);
        }
        setAllDevices([selectableDevices, filteredList].flat());
        !device && selectableDevices.length === 0 && setDevice(undefined);

        displayMessage(`Found ${filteredList.length} devices`, 'SnackBar', userFunctions);
      } else {
        displayMessage(`Failed to retrive devices`, 'SnackBar', userFunctions, Severity.error);
      }
    });
  }

  async function getHouseholds() {
    if (source) {
      // get all customers this customer can see

      // let allCustomers = await getAllCustomers(source, true);
      getAllCustomers(source, true).then((returnedCustomers) => {
        if (returnedCustomers.length !== 0) {
          returnedCustomers.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          setAllHouseholds(returnedCustomers.filter((customer) => customer.accountType === CustomerAccountType.Z));
          if (success) {
            return;
          }
        }
      });
    }
  }

  /**
   * Helper method to check if the field are filled in sufficiently
   * TODO implement device linking(?) properly
   */
  useEffect(() => {
    let selectedHouseholdValid = type === 'Community' || selectedHousehold !== undefined;
    let nameValid = name !== '';
    let usersValid = users !== 0;
    let volumesValid = height !== 0 && diameter !== 0;
    let roofValid = actualRoofArea !== 0 && potentialRoofArea !== 0;
    // let deviceValid = device !== undefined;
    if (selectedHouseholdValid && nameValid && usersValid && volumesValid && roofValid) {
      // && deviceValid) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, users, height, diameter, actualRoofArea, potentialRoofArea, selectedHousehold]); //device,

  /**
   * Helper method to set the correct device
   * @param id Device id being selected
   */
  const setSelectedDevice = (id: string) => {
    setDevice(
      allDevices?.find((property) => {
        return property.id === id;
      })
    );
  };

  /**
   * Helper method to set the correct household
   * @param id Household id being selected
   */
  const setSelectHousehold = (id: string) => {
    setSelectedHousehold(
      allHouseholds?.find((household) => {
        return household.id === id;
      })
    );
  };

  /**
   * Save the data into the database
   * TODO need to be fixed as the data does not show on the screen, but is actually saved
   */
  const saveObjectToDB = async () => {
    setCreating(true);
    // create tank object
    if (existing) {
      //update tank fields
      existing.name = name;
      existing.diameter = diameter;
      existing.no_of_tanks = numOfTanks;
      existing.height = height;
      existing.no_of_users = users || 1;
      existing.notes = notes;
      existing.tank_type = type;
      existing.coordinates = { lat, lng };
      transactionFunctions.saveObject(existing);

      //update image
      if (uploadImages && uploadImages.length === 1) {
        const directoryString = `${existing.sk}/`;
        Array.from(uploadImages).forEach((image, i) => {
          put(directoryString, image).then(() => {
            if (i === 0) {
              listObjectsAndUrls(directoryString).then((res) => {
                if (existing) {
                  let objectified = Object.values(res);

                  let index = objectified.findIndex((img) => img.key === directoryString + image.name);
                  existing.primaryS3Image = objectified[index];
                  transactionFunctions.saveObject(existing);
                }
              });
            }
          });
        });
      }

      //update collection area
      if (existingCollectionArea) {
        existingCollectionArea.roof_total_area = actualRoofArea;
        existingCollectionArea.effective_area = potentialRoofArea;
        transactionFunctions.saveObject(existingCollectionArea);
      }

      //update device
      if (existingDevice === undefined && device !== undefined) {
        const saveRes = ErrDataReducer(await Promise.all([save(device)]));
        if (saveRes.err) {
          displayMessage(saveRes.err.message, 'MessageBar', userFunctions, Severity.error);
        } else {
          const edgeTypeId = undefined;
          Promise.all([link(existing, device, edgeTypeId)]).then(([thingLinkRes]) => {
            if (thingLinkRes.err) {
              displayMessage(`failed to link to tank ${thingLinkRes.err.message}`, 'MessageBar', userFunctions, Severity.error);
            } else {
              displayMessage('Created: ' + name, 'SnackBar', userFunctions);
              setUpdate(true);
              showScreen(false);
            }
          });
        }
      } else if (existingDevice !== undefined && device !== undefined && existingDevice.id !== device.id) {
        const saveRes = ErrDataReducer(await Promise.all([save(device)]));
        if (saveRes.err === null) {
          Promise.all([link(existing, device), unlink(existing, existingDevice)]).then(([thingLinkRes]) => {
            if (thingLinkRes.err) {
              displayMessage(`failed to link to tank ${thingLinkRes.err.message}`, 'MessageBar', userFunctions, Severity.error);
            } else {
              displayMessage('Created: ' + name, 'SnackBar', userFunctions);
              setUpdate(true);
              showScreen(false);
            }
          });
        } else {
          displayMessage(saveRes.err.message, 'MessageBar', userFunctions, Severity.error);
        }
      }
      //check if different
      // if (device && existingDevice && device?.eui !== existingDevice?.eui) {
      //   //delete old link and save the device to no longer be allocated
      //   transactionFunctions.unlink(device, existingDevice);
      //   //save new link
      // }

      setUpdate(true);
      showScreen(false);
    } else {
      const tankZombie = createTankZombie({ name, diameter, numOfTanks, height, users, notes, type, lat, lng });

      // create collection area...
      const collectionAreaZombie = createCollectionAreaZombie({ roof_total_area: actualRoofArea, effective_area: potentialRoofArea });

      // create device link...
      if (source) {
        addObjectToDB(tankZombie, selectedHousehold !== undefined ? selectedHousehold : source, undefined).then((tankObj) => {
          if (tankObj) {
            addObjectToDB(collectionAreaZombie, tankObj, undefined).then(async (collectionArea) => {
              if (collectionArea && device) {
                const saveRes = ErrDataReducer(await Promise.all([save(device)]));

                if (saveRes.err) {
                  displayMessage(saveRes.err.message, 'MessageBar', userFunctions, Severity.error);
                } else {
                  const edgeTypeId = undefined;
                  Promise.all([link(tankObj, device, edgeTypeId)]).then(([thingLinkRes]) => {
                    if (thingLinkRes.err) {
                      displayMessage(`failed to link to tank ${thingLinkRes.err.message}`, 'MessageBar', userFunctions, Severity.error);
                    } else {
                      if (uploadImages === undefined || uploadImages.length === 0) {
                        displayMessage('Created: ' + name, 'SnackBar', userFunctions);
                        setUpdate(true);
                        showScreen(false);
                      } else {
                        //upload images
                        const directoryString = `${tankObj.sk}/`;
                        Array.from(uploadImages).forEach((image, i) => {
                          put(directoryString, image).then(() => {
                            if (i === 0) {
                              listObjectsAndUrls(directoryString).then((res) => {
                                tankObj.primaryS3Image = Object.values(res)[0];
                                transactionFunctions.saveObject(tankObj);
                              });
                            }
                          });
                        });
                        displayMessage('Created: ' + name + ' with images', 'SnackBar', userFunctions);
                        setUpdate(true);
                        showScreen(false);
                      }
                    }
                  });
                }
              } else {
                displayMessage('Failed to create collection', 'SnackBar', userFunctions, Severity.error);
                setCreating(false);
              }
            });
          } else {
            displayMessage('Failed to create tank', 'SnackBar', userFunctions, Severity.error);
            setCreating(false);
          }
        });
      } else {
        displayMessage('Failed to begin creating tank', 'SnackBar', userFunctions, Severity.error);
        setCreating(true);
      }
    }
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    showScreen(false);
    return null;
  }

  const createNewHousehold = () => {
    setCreateHousehold(true);
    setSuccess(false);
  };

  return ReactDOM.createPortal(
    // <div className={classes.root}>
    <>
      <div className={styles.modalContainer}>
        <div className={styles.form}>
          <Card>
            <h3 style={{ textIndent: 25 }}>{existing ? `Edit ${existing.name}` : `Create New ${type} Tank`}</h3>
            <div>
              <Grid container>
                <Grid item container className={styles.bottomPadding}>
                  <form className={editTank.root} noValidate autoComplete='off' onSubmit={() => showScreen(false)}>
                    {type === 'Household' && (
                      <Grid item container>
                        <Grid item container xs={5}>
                          <TextField
                            label={allHouseholds === undefined ? 'Loading Households... Please Wait' : 'Select Household'}
                            type='number'
                            select
                            InputProps={{ inputProps: { min: 0 } }}
                            error={selectedHousehold === undefined}
                            variant='outlined'
                            className={editTank.textField}
                            disabled={allHouseholds === undefined || creating}
                            SelectProps={{
                              renderValue: () => <>{selectedHousehold?.name}</>,
                            }}
                            onChange={(e) => setSelectHousehold(e.target.value)}
                            value={selectedHousehold}
                            size='small'
                          >
                            {allHouseholds?.map((household) => (
                              <MenuItem key={household.id} value={household.id}>
                                {household.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item container xs={2} justifyContent='center'>
                          <h4 style={{ textAlign: 'center' }}>OR</h4>
                        </Grid>
                        <Grid item xs={5} className={styles.center}>
                          <Button disabled={creating} style={{ margin: 'auto' }} variant='contained' color='primary' onClick={() => createNewHousehold()}>
                            Create new Household
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container>
                      <Grid item container xs={6}>
                        <TextField
                          label='Tank Name'
                          onChange={(e) => setName(e.target.value)}
                          className={editTank.textField}
                          value={name}
                          variant='outlined'
                          size='small'
                          required
                          disabled={creating}
                          error={name === ''}
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='# Users'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 1 } }}
                          onChange={(e) => setUsers(Number(e.target.value) !== undefined ? Math.round(Number(e.target.value)) : 0)}
                          className={editTank.textField}
                          value={users}
                          variant='outlined'
                          size='small'
                          required
                          disabled={creating}
                          error={users === 0}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container>
                      <Grid item container xs={6}>
                        <TextField
                          label='Tank Height (m)'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 1 } }}
                          onChange={(e) => setHeight(Number(e.target.value))}
                          className={editTank.textField}
                          value={height}
                          variant='outlined'
                          disabled={creating}
                          size='small'
                          required
                          error={height === 0}
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='Tank Diameter (m)'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 1 } }}
                          onChange={(e) => setDiameter(Number(e.target.value))}
                          className={editTank.textField}
                          value={diameter}
                          variant='outlined'
                          disabled={creating}
                          size='small'
                          required
                          error={diameter === 0}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item container xs={6}>
                        <TextField
                          label='Tank Total Volume (l)'
                          InputProps={{ readOnly: true }}
                          className={editTank.textField}
                          disabled={true}
                          value={Math.round(calculateTankVolume(height, diameter)) || 0}
                          variant='outlined'
                          size='small'
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='Number of Tanks'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 0 } }}
                          disabled={creating}
                          variant='outlined'
                          className={editTank.textField}
                          onChange={(e) => setNumOfTanks(Number(e.target.value))}
                          value={numOfTanks}
                          size='small'
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item container xs={6}>
                        <TextField
                          label='Actual Roof Area (m&sup2;)'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 0 } }}
                          required
                          error={actualRoofArea === 0}
                          disabled={creating}
                          variant='outlined'
                          className={editTank.textField}
                          onChange={(e) => setActualRoofArea(Number(e.target.value))}
                          value={actualRoofArea}
                          size='small'
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='Potential Roof Area (m&sup2;)'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: 0 } }}
                          required
                          disabled={creating}
                          error={potentialRoofArea === 0}
                          variant='outlined'
                          className={editTank.textField}
                          onChange={(e) => setPotentialRoofArea(Number(e.target.value))}
                          value={potentialRoofArea}
                          size='small'
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='Latitude'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: -90, max: 90 } }}
                          onChange={(e) => {
                            let number = Number(e.target.value);
                            if (number > 90) {
                              number = 90;
                            } else if (number < -90) {
                              number = -90;
                            }
                            setLat(Number(number));
                          }}
                          className={editTank.textField}
                          value={lat}
                          variant='outlined'
                          disabled={creating}
                          size='small'
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <TextField
                          label='Longitude'
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputProps: { min: -180, max: 180 } }}
                          onChange={(e) => {
                            let number = Number(e.target.value);
                            if (number > 180) {
                              number = 180;
                            } else if (number < -180) {
                              number = -180;
                            }
                            setLng(Number(number));
                          }}
                          className={editTank.textField}
                          value={lng}
                          variant='outlined'
                          disabled={creating}
                          size='small'
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item container xs={6}>
                        <TextField
                          label='Notes'
                          id='filled-textarea'
                          className={editTank.textField}
                          rows={6}
                          disabled={creating}
                          placeholder='Notes'
                          multiline
                          value={notes}
                          InputLabelProps={{ shrink: true }}
                          variant='outlined'
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </Grid>
                      <Grid container xs={6}>
                        <FormControl>
                          <FormLabel id='demo-row-radio-buttons-group-label'>Device Type</FormLabel>
                          <RadioGroup
                            {...{
                              row: true,
                              onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                const [numPart, ...subParts] = value.split(' ');
                                var num = numPart
                                var sub = subParts.join(' ')
                                setDeviceType(parseInt(num));
                                setSubtype(sub);
                              },
                              value: deviceType,
                              name: 'radio-buttons-group',
                            }}
                          >
                            <FormControlLabel
                              disabled={creating} //  || existingDevice !== undefined}
                              value='26 Water Pressure'
                              control={<Radio checked={deviceType === 26 && subType === 'Water Pressure'} />}
                              label='Water Pressure'
                            />
                            <FormControlLabel
                              disabled={creating} // || existingDevice !== undefined}
                              value='28'
                              control={<Radio />}
                              label='Radar Distance'
                            />
                            <FormControlLabel
                              disabled={creating} // || existingDevice !== undefined}
                              value='26 PressureRain'
                              control={<Radio checked={deviceType === 26 && subType === 'PressureRain'} />}
                              label='Pressure and Rain'
                            />
                          </RadioGroup>
                        </FormControl>
                        <Grid item container>
                          <TextField
                            disabled={allDevices === undefined || allDevices.length === 0 || creating} // || existingDevice !== undefined}
                            label={allDevices === undefined ? 'Finding Devices...' : allDevices.length === 0 ? 'No devices' : 'Select Device'}
                            error={device === undefined}
                            type='number'
                            select
                            InputProps={{ inputProps: { min: 0 } }}
                            variant='outlined'
                            className={editTank.textField}
                            SelectProps={{
                              renderValue: () => <>{device?.id || ''}</>,
                            }}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                            value={device || ''}
                            size='small'
                          >
                            {allDevices?.map((device) => (
                              <MenuItem key={device.id} value={device.id}>
                                {device.eui || device.id}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item container>
                          <div>
                            <input accept='image/*' style={{ display: 'none' }} id='raised-button-file' type='file' onChange={(e) => handleFileUpload(e)} />
                            <label htmlFor='raised-button-file'>
                              <Button variant='contained' color='primary' component='span' disabled={creating}>
                                {uploadImages && uploadImages.length === 1 ? uploadImages[0].name : existing?.primaryS3Image ? 'Update Image' : 'Add An Image'}
                              </Button>
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div style={{ textIndent: 10 }}>
                      <Button variant='contained' color='error' disabled={creating} onClick={() => showScreen(false)}>
                        Cancel
                      </Button>
                      <Button variant='outlined' color='success' disabled={!valid || creating} onClick={() => saveObjectToDB()}>
                        {existing ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </div>
      {createHousehold && <AddHousehold {...{ showScreen: setCreateHousehold, type, source, contextUser, userFunctions, setSuccess }} />}
    </>,
    modalRoot
  );
};

export default AddTank;
function setUploadProgress(arg0: number): void {
  throw new Error('Function not implemented.');
}
