import { Grid, Typography, FormControl, TextField, Button } from '@mui/material';
import IconList from '../IconList';
import { useState } from 'react';
import { Place } from '../AddPlace';

export function AreaStylerComponent(values: any, handleChange: Function) {
    return (
        <>
            <Grid item>
                <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                    <TextField id="color" label="color" variant="standard" helperText='#hex number' size="small" value={values.color} onChange={handleChange('color')} />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                    <TextField id="opacity" label="opacity" variant="standard" helperText='between 0.0 to 1.0' size="small" type="number" InputProps={{
                        inputProps: {
                            max: 1.0, min: 0.0
                        }
                    }} value={values.opacity} onChange={handleChange('opacity')} />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                    <TextField id="lineWidth" label="line width" variant="standard" helperText='please put in number only, will auto generate unit px' size="small" type="number" value={values.lineWidth} onChange={handleChange('lineWidth')} />
                </FormControl>
            </Grid>
        </>
    )
}

export function PlaceStylerComponent(values: any, setValues: React.Dispatch<React.SetStateAction<any>>, handleChange: Function, listIconOpen: boolean, setListIconOpen: React.Dispatch<React.SetStateAction<boolean>>) {

    return (
        <>
            <Grid item>
                <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                    <TextField id="color" label="color" variant="standard" helperText='#hex number' size="small" value={values.color} onChange={handleChange('color')} />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                    <Button onClick={() => setListIconOpen(true)}>Icon</Button>
                    <IconList {...{ open: listIconOpen, setOpen: setListIconOpen, values, setValues }} />
                </FormControl>
            </Grid>
            <Grid item>
                {/* <Button>Upload</Button> */}
                {/* <FileSelectCard {...{ matchedPrimary: contextCustomer as ValidBusinessObject, userFunctions, transactionFunctions, viewDefinition, actions, history, title: 'Upload Icon' }}></FileSelectCard> */}

            </Grid>
        </>
    )
}