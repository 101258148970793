import { ValidModelObject } from '@iotv/datamodel';
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import styles from '../../cosmetics/sharedViewStyles';
import { ComponentMode, MessageOutputType, pseudoEvent, Severity, ThingsViewProps } from '../../types/AppTypes';
import { AppValueTypes, IOTV_LCD_DeviceMessage, MapLike } from '@iotv/iotv-v3-types';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { v4 } from 'uuid';
import { AppLocalStorage } from '../../data/LocalStorage/AppLocalStorage';
import { DeviceMessageQueryRequest, getDeviceMessagesFromS3 } from '../../data/NetworkSystem/DeviceMessage/getFromS3';
import { useMQTTChannelMessagesV2 } from '../../hooks/useMQTTChannelMessagesV2';
import { useSelectedObjects, UseSelectedObjectsReturnType } from '../../hooks/useSelectedObjects';
import { DialogWrapper } from '../common/DiaglogWrapper';
import { ContextQueryFCplusProps, ContextQueryModal } from '../factories/ContextQuery/contextQueryModal';
import { TypeByAttribute, TypeByBooleanAttribute } from '../factories/ContextQuery/QueryParamSelectors';
import { NavSections } from '../navigation/NavSections';
import { AttributeSelectableType, getDataGridProps } from './getDeviceSelectionDataGridProps';
import { LCDDeviceMessageTable } from './LCDDeviceMessageTable';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Badge } from '@mui/icons-material';
import { DateInput } from '../factories/inputFields/DateInput';

const debug = process.env.REACT_APP_DEBUG && false;

const projectCode = process.env.REACT_APP_PROJECT_CODE
const stage = process.env.REACT_APP_DEPLOYMENT_STAGE

enum DisplayMode { LIVE_DATA_ONLY = 'LIVE_DATA_ONLY', QUERY_ONLY = 'QUERY_ONLY', LIVE_AND_QUERY = 'LIVE_AND_QUERY' }
enum LiveDataFilter { SELECTED_ITEMS = 'SELECTED_ITEMS', ALL_NETWORK  = 'ALL_NETWORK'}

const queryDaysOptions = [1, 2, 3];

function NWDataTableView(props: ThingsViewProps): JSX.Element {
  const { classes, transactionFunctions, userFunctions, match, history, user, contextCustomer } = props;
  const [displayMode, setDisplayMode] = React.useState<DisplayMode>(DisplayMode.LIVE_DATA_ONLY)
  const [ liveDataFilter, setLiveDataFilter ] = React.useState<LiveDataFilter>(LiveDataFilter.SELECTED_ITEMS)
  const [querying, setQuerying] = React.useState<boolean>(false);
  const [deviceMessages, setDeviceMessages] = React.useState<IOTV_LCD_DeviceMessage[]>([]);
  const deviceMessagesRef = React.useRef<IOTV_LCD_DeviceMessage[]>(deviceMessages)
  const now = Date.now()
  const [queryDays, setQueryDays] = React.useState(queryDaysOptions[0]);
  const [deviceSelectorOpen, setDeviceSelectorOpen] = React.useState<boolean>(false)
  const parentTableUseSelectedObjects = useSelectedObjects<AttributeSelectableType>()
  const lastSearchedItemsRef = React.useRef<DocumentClient.AttributeMap[]>([]);
  const lastQueryComponentKeyRef = React.useRef<string | undefined>(undefined);
  const lastQueryParamsRef = React.useRef<any | undefined>(undefined);
  const { state: [[selectedObjects]] }: UseSelectedObjectsReturnType<AttributeSelectableType> = parentTableUseSelectedObjects
  // transofrm to use above
  const [selectedDeviceSks, setSelectedDeviceSks] = React.useState<string[]>(
    AppLocalStorage.get(NavSections.NETWORKTABLEVIEW, 'selectedDeviceSks') ?? ['Device:70B3D516B0000426'])

  const selectedDevicesHash = selectedObjects.map((o) => o.id).join()

  const displayMessage = (content: string, type: MessageOutputType, severity?: Severity, duration?: number) => {
    const id = v4();
    userFunctions.setUserMessage({ id, content, label: content, type, severity: severity });
    setTimeout(() => userFunctions.dismissUserMessage({ id, content, label: content, type, severity: severity }), duration ?? 3000);
  };

  const handleDeviceSelectionClose = (save: boolean) => {
    setDeviceSelectorOpen(false)
  }


  const addToDeviceMessages = (dm: IOTV_LCD_DeviceMessage) => {
    debug && console.log(`NW Table view child adding to device messages`, dm)
    setDeviceMessages([dm, ...deviceMessagesRef.current])
  }

  /**
   * topicGetters are curried as useState will invoke the function on initial render ( without params ) https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
   * @returns 
   */

  const networkDeviceTopicGetter = () => (xs: AttributeSelectableType[]): string[] => {
    return xs.map((x) => `${projectCode}/${stage}/network/device/${x.eui ?? x.id}`)
  }

  const allNetworkDeviceTopicGetter = () => (xs: AttributeSelectableType[]): string[] => [`${projectCode}/${stage}/network/device/#`]

  const [ topicGetter, setTopicGetter ] = React.useState< (xs: AttributeSelectableType[]) => string[]>(networkDeviceTopicGetter)



  const deviceMessagesLength = deviceMessages.length

  const { setterGetters: [receivedObjects] } = useMQTTChannelMessagesV2<AttributeSelectableType, IOTV_LCD_DeviceMessage>({ 
    user: user as ValidModelObject<'User'>, topicGetter, topicGenerationObjects: selectedObjects, addToParentItems: addToDeviceMessages 
  })

  useEffect(() => {
    debug && console.log('NW Table used effect on ', liveDataFilter)
    if ( liveDataFilter === LiveDataFilter.ALL_NETWORK ) {
      setTopicGetter( allNetworkDeviceTopicGetter )
    } else if ( liveDataFilter === LiveDataFilter.SELECTED_ITEMS ) {
      setTopicGetter( networkDeviceTopicGetter )
    }

  }, [ liveDataFilter ])

  useEffect(() => {
     deviceMessagesRef.current = deviceMessages
  }, [deviceMessagesLength])

  useEffect(() => {
    if (displayMode !== DisplayMode.LIVE_DATA_ONLY) {
      const getNetworkData = async (selectedDeviceSks: string[]) => {

        const startDate: Date = new Date(now)
        startDate.setDate(startDate.getDate() - (queryDays - 1))
        const params: DeviceMessageQueryRequest =
        {
          deviceEuis: selectedDeviceSks.map((sk) => sk.replace('Device:', '')),
          startDate,
          endDate: new Date(now)
        }
        if (selectedDeviceSks.length > 0) {

          setQuerying(true)

          const { err, data } = await getDeviceMessagesFromS3(params)
          if (data) {
            //remove existing data or get duplicate keys
            setDeviceMessages([ ...data])
          } else if (err) {
            displayMessage(err.message, 'MessageBar', Severity.error,)
          } else {
            displayMessage(`No records returned`, 'MessageBar', Severity.info,)
          }

          setQuerying(false)

        } else {
          debug && console.log('did not run NW Query')
        }
      }
      getNetworkData(selectedObjects.map((d) => d.sk));
    } else {
      console.log(`TEMOORARILY IN LIVE_DATA_ONLY`)
    }

  }, [selectedDevicesHash, displayMode, queryDays])

  const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayMode(e.target.value as DisplayMode)
  }

  const handleLiveDataFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debug && console.log('NW Table seeting liveDataFilter to', e.target.value)
    setLiveDataFilter(e.target.value as LiveDataFilter)
  }

  const handleQueryDayChange = (event: pseudoEvent) => {
    const days = event.currentTarget.value
    setQueryDays(days);
  };

  const componentParamPairsMap: MapLike<ContextQueryFCplusProps<any>> = {
    'byEui': {
      ParamSelector: TypeByAttribute, ParamSelectorProps: { type: 'Device', attKey: 'eui' },
    },
    'isAWOL': {
      ParamSelector: TypeByBooleanAttribute, ParamSelectorProps: { type: 'Device', attKey: 'isAWOL' }
    }
  }

  return (
    <div className={classes.networkTable} {...{ style: { overflowY: 'scroll' } }}>
      <Grid container {...{ className: classes.banner, spacing: 1, }}>
        <Grid item {...{ xs: 9 }} style={{}} >
          <FormGroup row {...{}}>
            <FormControl {...{ style: { marginRight: 14 } }}>
              <Button {...{
                variant: "outlined", color: "primary", size: 'large', onClick: () => !deviceSelectorOpen && setDeviceSelectorOpen(true),
                endIcon: <Badge {...{ badgeContent: selectedObjects.length, color: selectedObjects.length === 0 ? 'error' : 'primary', showZero: true }}>
                  {selectedObjects.length > 0 ? <SentimentSatisfiedAltIcon {...{}} /> : <SentimentVeryDissatisfiedIcon />}
                </Badge>
              }}>
                {`Device Selection ${selectedObjects.length > 0 ? `(${selectedObjects.length})` : ''}`}</Button>
            </FormControl>
            <FormControl>
              <RadioGroup {...{ value: displayMode, row: true, onChange: handleModeChange }}>
                <FormControlLabel {...{ key: 'ldo', value: DisplayMode.LIVE_DATA_ONLY, control: <Radio {...{}} />, label: 'Live' }} />
                <FormControlLabel {...{ key: 'qo', value: DisplayMode.QUERY_ONLY, control: <Radio {...{}} />, label: 'Recent' }} />
                <FormControlLabel {...{ key: 'laq', value: DisplayMode.LIVE_AND_QUERY, control: <Radio {...{}} />, label: 'Both' }} />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormControlLabel { ...{ label: 'Live Data:', labelPlacement: 'start', style: { paddingLeft: 28 },
                 control:  <RadioGroup {...{ value: liveDataFilter, row: true, onChange: handleLiveDataFilterChange, style: { paddingLeft: 14 } }}>
                  <FormControlLabel {...{ key: 'ldo', value: LiveDataFilter.SELECTED_ITEMS, control: <Radio {...{}} />, label: 'Selected' }} />
                  <FormControlLabel {...{ key: 'qo', value: LiveDataFilter.ALL_NETWORK, control: <Radio {...{}} />, label: 'All' }} />
              </RadioGroup>}}/>
             
            </FormControl>

            <FormControl { ...{ style: { paddingLeft: 28 }  }}>
              <InputLabel { ...{id: "daysSelectIL", style: { paddingLeft: 28 }  } }>Days</InputLabel>
              <Select
                labelId="daysSelectIL"
                id="daysSelectILSelect"
                value={queryDays}
                onChange={(event) => {
                  const localEvent: pseudoEvent = { currentTarget: { type: 'object', value: event.target.value } }
                  handleQueryDayChange(localEvent)
                }}
              >
                {queryDaysOptions.map((days: number) => <MenuItem value={days}>{days}</MenuItem>)}
              </Select>
             
            </FormControl>
           
          </FormGroup>
          
        </Grid>
        <Grid item {...{ xs: 3, align: 'right', alignItems: 'flex-end', }}>
          <FormGroup>
            <FormControl>
              {/* {<SimpleExport {...{ contextCustomer: contextCustomer as ValidBusinessObject, viewDefinition, vobs: currentViewObjects.map((viewObject) => viewObject.matchedPrimary).filter((item) => item) as ValidBusinessObjectList, userFunctions }}></SimpleExport>} */}

            </FormControl>
          </FormGroup>

        </Grid>
        <Grid item {...{ xs: 12, style: { overflowX: 'scroll' } }}  >
          <div {...{ style: { height: 1200 } }}>
            <LCDDeviceMessageTable {...{ deviceMessages: [...deviceMessages, ...receivedObjects], loading: querying }} />
          </div>

        </Grid>

      </Grid>
      <DialogWrapper {...{
        header: 'Device Selection', open: deviceSelectorOpen, setClosed: handleDeviceSelectionClose, wrappedElement: <ContextQueryModal<AttributeSelectableType> {...{
          componentParamPairsMap, parentTableUseSelectedObjects, getDataGridProps, lastSearchedItemsRef, lastQueryComponentKeyRef, lastQueryParamsRef

        }} />
      }} />
    </div>
  );

}

export default withStyles(styles)(NWDataTableView)