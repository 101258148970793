import { ErrData, ValidBusinessObject, edgeTypeMatrix } from '@iotv/datamodel';
import { AdjacentType, GetGetGraphTraversalRequest, GetGetGraphTraversalResponse, TypeHierarchyResponse } from '@iotv/iotv-v3-types';
import { NormalizedData, PossibleBusinessObject } from '../../types/AppTypes';
import ApiGateway from '../aws/api-gateway/ApiGateway';
import { addToNormalData } from '../daoFunctions/stateFns';

const debug = process.env.REACT_APP_DEBUG && false;

  /**
   * @function userViaCustomersTypeRoleQuery Returns the graph of user to first level related customers and then the related objects of customerRelatedObjectTypeId. Does not iterate down Customer hierarchy so user must by related directly to customer
   * @param user 
   * @param customerRelatedObjectTypeId 
   * @param normalizedData 
   * @returns 
   */

  const userViaCustomersTypeRoleQuery = async function (user: ValidBusinessObject, customerRelatedObjectTypeId: string, normalizedData: NormalizedData)  {
   
    const path = '/graphQuery';

    const request: GetGetGraphTraversalRequest = {
      contextObjects: [ user],
      path: [
        { objectTypeId: 'Customer', adjacencyType: AdjacentType.PARENT, edgeTypeId: edgeTypeMatrix.Customer.User.customer_has_users.edgeTypeId },
        { objectTypeId: customerRelatedObjectTypeId, adjacencyType: AdjacentType.CHILD, edgeTypeId: undefined }
      ]
    }

    const responseBody: ErrData<GetGetGraphTraversalResponse> = await ApiGateway.post(path, request) as  ErrData<GetGetGraphTraversalResponse>;

    debug && console.log('userViaCustomersTypeRoleQuery Res', responseBody);

    if ( responseBody.data ) {
      const { nodes, edges } = responseBody.data.hierarchy as TypeHierarchyResponse
      [ ...nodes, ...edges].forEach( ( item ) =>  addToNormalData( item as PossibleBusinessObject, normalizedData) )
    } else {
      console.log( `Err in userViaCustomersTypeRoleQuery ${ responseBody.err?.message }`)
    }

    return normalizedData;
  }

export { userViaCustomersTypeRoleQuery };
