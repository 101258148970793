import { MenuItem } from "@material-ui/core"
import { ENG_MONTHS } from '@iotv/datamodel-core'

const thirtyOnes = [ 0, 2, 4, 6, 8, 10, 12 ]
const thirties = [  3, 5, 7, 9, 11 ]
const feb = 1

const getDaysInMonth =  ( year: number, month: number ) => {
    const isLeap = year % 4 !== 0;
    if ( thirtyOnes.includes( month )) {
        return 31
    } else if ( thirties.includes( month )) {
        return 30
    } else {
        if ( isLeap ) {
            return 29
        } else return 28
    }

}

export const getYears = ( year: number, minus: number, plus: number ) => {
    const arr = new Array( minus + plus + 1 ).fill(0)
    
    return arr.reduce( ( acc, curr, i ) => {
    acc[i] = year - minus + i
    return acc 
}, arr)
}

export const getDays = ( year: number, month: number ) => {
    const numDays = getDaysInMonth( year, month )
    const arr: number[] = new Array( numDays ).fill(0)
    
    return arr.reduce( ( acc, curr, i ) => {
    
    acc[i] = i + 1
    return acc 
}, arr)
}

export const getHours = (  ) => {
    
    const arr: number[] = new Array( 24 ).fill(0)
    
    return arr.reduce( ( acc, curr, i ) => {
    
    acc[i] = i
    return acc 
}, arr)
}

export const getMinutes = (  ) => {
    
    const arr: number[] = new Array( 60 ).fill(0)
    
    return arr.reduce( ( acc, curr, i ) => {
    
    acc[i] = i
    return acc 
}, arr)
}



export const getYearInputMenuItems = (cur: number, minus: number, plus: number) => {
    const years = getYears(cur, minus, plus )
     return years.map((year: string) => (
        <MenuItem key={year} value={year}>
            {year}
        </MenuItem>
    ))
}

export const getMonthInputMenuItems = () => {
    const monthsEntries = Object.entries(ENG_MONTHS).filter(([k, v]) => typeof v !== 'number')
     return monthsEntries.map(([ x, y] ) => (
        <MenuItem key={x} value={y}>
            {y}
        </MenuItem>
    ))
}

export const getDayInputMenuItems = ( year: number, month: number) => {
    const days = getDays( year, month)
     return days.map(( day ) => (
        <MenuItem key={day} value={day}>
            {day}
        </MenuItem>
    ))
}

export const getHoursInputMenuItems = ( ) => {
    const hours = getHours()
     return hours.map(( hr ) => (
        <MenuItem key={hr} value={hr}>
            {hr}
        </MenuItem>
    ))
}

export const getMinutesInputMenuItems = ( ) => {
    const minutes = getMinutes()
     return minutes.map(( min ) => (
        <MenuItem key={min} value={min}>
            {min}
        </MenuItem>
    ))
}