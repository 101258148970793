import {
  DownLinkControlObjectType,
  ValidBusinessObject
} from "@iotv/datamodel";
import { DatastoreObjectType, DeviceConfig, DeviceConfigProps, DeviceProps, DeviceTransactionStatus, getZombieInstanceFromPrimaryKey, IDeviceControlTransaction, ValidModelObject, MultipleDeviceControlTransactionRequest } from "@iotv/datamodel-core";
import { DeviceTypes, MapLike } from '@iotv/iotv-v3-types';
import { Container, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect } from "react";
import AppDao from '../../data/AppDao';
import { useDocumentClientQuery } from "../../hooks/useDocumentClientQueryV1";
import { useTabHandler } from "../../hooks/useTabHandler";
import {
  DiagnosticViewProps, KeyMappedComponentFunctions, ListDevicesRequest
} from "../../types/AppTypes";
import { useStyles } from "./diagnosticStyles";
import { getDeviceTransactionQueryParams, getNetworkQueryResults, listDevices } from "./queries";
import { HistoryTab, MonitorTab as DefaultMonitorTab, SelectionTab } from './tabs';

import { ConfigurationTab as DefaultConfigurationTab,  } from './tabs'

// import { CalibrationTab as V015CalibrationTab } from "../../venture/v015/components/DeviceControl/tabs/CalibrationTab";
// import { ConfigurationTab as V015ConfigurationTab } from '../../venture/v015/components/DeviceControl/tabs/ConfigurationTab';
// import { DiganosticModeTab as V015DiagnosticTab } from '../../venture/v015/components/DeviceControl/tabs/DiagnoticModeTab';
// import { MonitorTab as V015MonitorTab } from '../../venture/v015/components/DeviceControl/tabs/MonitorTab/MonitorTab_v2';

import config from '@iotv/config';
import { isVOBofType } from "@iotv/iotv-v3-types";
import { getExact } from "../../data/daoFunctions/daoFunctions";
import { AppLocalStorage } from "../../data/LocalStorage/AppLocalStorage";
import { useMQTTChannelMessages } from "../../hooks/useMQTTChannelMessages";
import { SlaveTab } from "../factories/SlaveTabV2";
import { NavSections } from "../navigation/NavSections";
import { TransactionStateSteps } from "./TransactionStateSteps";

const debug = process.env.REACT_APP_DEBUG && true;
const suppressActualSending = debug && false
const componentClassName = 'DiagnosticView'
const { REACT_APP_PROJECT_CODE: fullProjectCode } = process.env
const projectCode = fullProjectCode?.substring(0,4) ?? 'i301'

const getSteps = () => {

  return [
    DeviceTransactionStatus.USER_REQUESTED,
    DeviceTransactionStatus.QUEUED,
    DeviceTransactionStatus.SENT,
    DeviceTransactionStatus.ACK,
    DeviceTransactionStatus.DEVICE_ACK,
    DeviceTransactionStatus.DEVICE_RESPONSE,
  ];
  //     DeviceTransactionStatus.JOIN_ACCEPT
  //     DeviceTransactionStatus.FAILED
};

type SwitchableTabs = 'ConfigurationTab' | 'DiagnosticModeTab' | 'CalibrationTab' | 'MonitorTab'
type TabMapType = { [k: number ] : {  [ k in SwitchableTabs]: ( props: any) => JSX.Element | null } }

const tabsMap: TabMapType = {
  [DeviceTypes.GenericSensor ]: {
    CalibrationTab: () => null,
    DiagnosticModeTab: () => null,
    ConfigurationTab: DefaultConfigurationTab,
    MonitorTab: DefaultMonitorTab
  },
  [DeviceTypes.IrrigatorControl]: {
    CalibrationTab:  () => null,
    DiagnosticModeTab:  () => null,
    ConfigurationTab: DefaultConfigurationTab,
    MonitorTab: DefaultMonitorTab
  },
  [DeviceTypes.HorseMovement]: {
    CalibrationTab:  () => null,
    DiagnosticModeTab:  () => null,
    ConfigurationTab:  () => null,
    MonitorTab:  () => null

  }
}

debug && console.log( `DeviceControlView has projectCode ${projectCode} from ${fullProjectCode} `)





const DiagnosticView = ({ match: { params: { action, id: sk, type } }, match, user, contextCustomer, deviceMessages, history, classes: classesIn, userFunctions, ...others }: DiagnosticViewProps) => {
  const classes = { ...classesIn, ...useStyles() };
  const common = { ...others, contextCustomer, user, history, match }

  const [ currentDeviceType, setCurrentDeviceType ] = React.useState<DeviceTypes | undefined>( undefined )
  const projectTabsMap = ( currentDeviceType && tabsMap[ currentDeviceType ]) ?? tabsMap[DeviceTypes.GenericSensor]
  const { CalibrationTab, DiagnosticModeTab, ConfigurationTab, MonitorTab  } = projectTabsMap

  const [refreshRequestCount, setRefreshRequestCount] = React.useState(0);

  const deviceQueryParams: ListDevicesRequest | undefined = {
    ExclusiveStartKey: undefined,
    contextUser: user as ValidBusinessObject & { type: "User" },
    contextCustomer: contextCustomer as ValidBusinessObject & {
      type: "Customer";
    },
    filter: undefined,
  };

  const relatedViewRefreshCount = refreshRequestCount;
  const contextCustomerSk = contextCustomer?.sk;
  const userSk = user?.sk;
  const deviceQueryHook = useDocumentClientQuery(listDevices, deviceQueryParams, [
    relatedViewRefreshCount, 
    contextCustomerSk,
    userSk
  ]);

  const {
    querySetterGetters: [
      //items gives the output from the query
      [devices, setDevices],
      [limit, setLimit],
      [LastEvaluatedKeys, setLastEvaluatedKeys],
      [ExclusiveStartKey, setExclusiveStartKey],
    ],
    selectionControls: [selectedDevices, setSelectedDevices, getSelector], // you could use selectedObjects, setSelectedObjects as below,
    forceUpdate,
    updates,
  } = deviceQueryHook;



  const selectedDeviceSk = selectedDevices[0]?.sk

  useEffect( () => {
    const selectedDevice = selectedDevices[0]
    if ( selectedDevice ) {
      setCurrentDeviceType( (selectedDevice as MapLike<any> ).deviceType )
    }
  }, [selectedDeviceSk] )

  useEffect( () => {
    if ( devices.length > 0 && selectedDevices.length === 0 ) {
      const lastSelectedDeviceSk = AppLocalStorage.get( NavSections.DIAGNOSTIC, 'lastSelectedDeviceSk')
      const lastSelectedDevice = devices.find(( item ) => item.sk === lastSelectedDeviceSk )
      if ( lastSelectedDevice ) {
        setSelectedDevices( [ lastSelectedDevice as DatastoreObjectType ])
      }

    }
  }, [ devices.length] )


  const topic = selectedDeviceSk ? config.aws.mqtt.deviceControlTransactionChannel.replace('[deviceSk]', selectedDeviceSk) : undefined
  const deviceTransactionObjectFilter = (x: any): x is IDeviceControlTransaction => isVOBofType('DeviceControlTransaction', x)

  const { setterGetters: [receivedDeviceControlTransactions, setReceivedDeviceControlTransactions] } = useMQTTChannelMessages(
    {
      user: user as ValidModelObject<'User'> | undefined, topic, objectFilter: deviceTransactionObjectFilter
    })

  const {
    querySetterGetters: [
      [currentDeviceTransactions], [_limit, setTransactionsLimit], [lastEvaluatedTransactionKeys], [_exclusiveStartKey, setTransactionsExclusiveStartKey]
    ],
  } = useDocumentClientQuery(getNetworkQueryResults, getDeviceTransactionQueryParams(selectedDeviceSk), [
    selectedDeviceSk, // as you guessed these get passed to the dependency array in the hook
  ]);

  const [deviceConfig, setDeviceConfig] = React.useState<DeviceConfig<any> | undefined>(undefined)
  const [status, setStatus] = React.useState<DeviceTransactionStatus | null>(null);
  const steps = getSteps();

  const handleSendTransactionButtonClick = async (desiredPayload: Partial<DownLinkControlObjectType> | undefined) => {
    if ( desiredPayload && user) {
      var deviceControlTransactions: MultipleDeviceControlTransactionRequest = {
        devices: selectedDevices as ValidModelObject<"Device">[],
        desiredPayload: {},
        requestInitiator: user
      };
      var convertedSelectedObj: ValidModelObject<"Device">;
      selectedDevices.map(async (selectedObject) => {
  
        convertedSelectedObj = JSON.parse( // this must be something to do with cloning
          JSON.stringify(selectedObject)
        );
  
        /**
         * under refactor, change of signatures, going to datamodel Payload or datamodel-core
         */
  
        const deviceType: DeviceTypes = (selectedObject as DeviceProps & DatastoreObjectType).deviceType as DeviceTypes
  
       
        deviceControlTransactions.desiredPayload = desiredPayload
  
        if (suppressActualSending) {
          console.log('We would send', deviceControlTransactions)
        } else {
          debug && console.log('We are sending', deviceControlTransactions)
          const putRes = AppDao.submitDeviceTransaction(deviceControlTransactions);

          /**
           * TODO
           *   setReceivedDeviceControlTransactions( [ newTransaction, ...receivedDeviceControlTransactions,])
           */

          console.log('RE INSTATE   setReceivedDeviceControlTransactions( [ newTransaction, ...receivedDeviceControlTransactions,])')
        }
  
        
      
  
      });
    }
   
  }

  const latestTransaction = receivedDeviceControlTransactions[0];
  const activeStep = latestTransaction
    ? getSteps().findIndex((step) => step === latestTransaction.status) ?? 0
    : -1;

  useEffect(() => {
    console.log("selectedDeviceSk", selectedDeviceSk)
    console.log("currentDeviceTransactions[0]", currentDeviceTransactions[0])
    if (currentDeviceTransactions[0]?.pk === selectedDeviceSk) {
      setStatus(currentDeviceTransactions[0]?.status);
    } else {
      setStatus(DeviceTransactionStatus.USER_REQUESTED);
    }
  }, [receivedDeviceControlTransactions.length, currentDeviceTransactions, selectedDeviceSk])

  const getDeviceConfig = async (selectedDeviceSk: string) => {
    const deviceConfigZombie = DeviceConfig.createForDevice(getZombieInstanceFromPrimaryKey(selectedDeviceSk) as ValidModelObject<"Device">)
    const getDeviceConfigRes = await getExact(deviceConfigZombie)

    if (getDeviceConfigRes.data?.Items?.[0]) {
      debug && console.log(`i301 DeviceControView getDeviceConfig setting deviceConfig fro ${selectedDeviceSk} to `, getDeviceConfigRes.data.Items[0])
      setDeviceConfig(new DeviceConfig(getDeviceConfigRes.data.Items[0] as Partial<DeviceConfigProps> & ValidBusinessObject))
    } else if (getDeviceConfigRes.err) {
      console.log(`err with getDeviceConfig`, getDeviceConfigRes.err.message)
    } else {
      console.log(`could not get device config with `, { deviceConfigZombie })
    }
  }

  useEffect(() => {
    if (selectedDeviceSk) {
      getDeviceConfig(selectedDeviceSk)
      AppLocalStorage.set( NavSections.DIAGNOSTIC, 'lastSelectedDeviceSk', selectedDeviceSk)
    }
  }, [ selectedDeviceSk,  receivedDeviceControlTransactions.length])

  const currentDeviceTransaction: IDeviceControlTransaction | undefined = receivedDeviceControlTransactions[0]

  const tabsToDisplay: KeyMappedComponentFunctions = { 
    Select: () => <SelectionTab {...{ key: `tab_a`, value: tabValue, index: 0, classes, deviceQueryHook, setReceivedDeviceControlTransactions, handleSendTransactionButtonClick, userFunctions, ...common }} />, 
    History: () => <HistoryTab {...{ key: `tab_b`, value: tabValue, index: 1, classes, selectedDevices, currentDeviceTransactions, userFunctions, ...common }} />, 
    Configure: () => <ConfigurationTab {...{ key: `tab_c`, value: tabValue, index: 2, classes, deviceConfig, currentDeviceTransaction, receivedNetworkObjects: receivedDeviceControlTransactions, setReceivedDeviceControlTransactions, handleSendTransactionButtonClick, userFunctions, selectedDevices, ...common }}/> , 
    Calibrate: () => <CalibrationTab {...{ key: `tab_e`, value: tabValue, index: 3, classes, deviceConfig,  currentDeviceTransaction,  deviceMessages, selectedDevices, userFunctions, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions, receivedDeviceControlTransactions, ...common }}/>, 
    DiagnosticMode: () => <DiagnosticModeTab {...{ key: `tab_f`, value: tabValue, index: 4, classes, deviceMessages,  currentDeviceTransaction,  selectedDevices, userFunctions, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions, ...common }}/>, 
    Monitor: () =>  <MonitorTab {...{ key: `tab_d`, value: tabValue, index: 5, classes, deviceConfig, deviceMessages,  currentDeviceTransaction,  selectedDevices, userFunctions, ...common }}/>, }

    const { state: [[tabValue, setTabValue]], handlers: { handleTabChange } } = useTabHandler( tabsToDisplay)

  return (
    <Container {...{ className: componentClassName, style: { maxWidth: 'unset' } }}>
      <Paper className={classes.paper} variant="outlined">
        <Grid className={classes.root} container spacing={3}>
          <Grid item {...{ xs: 12 }}>
            <h4>{ currentDeviceType }</h4>
            <Tabs {...{ value: tabValue, onChange: handleTabChange }} >
              {Object.keys(tabsToDisplay).map((tabName, i) => <Tab  {...{ key: `tab_${i}`, label: tabName, disabled: (i > 0 && selectedDevices.length === 0) }} />)}
            </Tabs>
            {Object.entries(tabsToDisplay).filter( ([k, c]) => c !== null ).map(([ k, component], index) => <SlaveTab {...{ key: `tab${index}`, orderedComponents: [ component ], index, value: tabValue }}></SlaveTab>)}

            {/* <SelectionTab {...{ key: `tab_a`, value: tabValue, index: 0, classes, deviceQueryHook, setReceivedDeviceControlTransactions, handleSendTransactionButtonClick, userFunctions, ...common }} />
            <HistoryTab {...{ key: `tab_b`, value: tabValue, index: 1, classes, selectedDevices, currentDeviceTransactions, userFunctions, ...common }} />
            <ConfigurationTab {...{ key: `tab_c`, value: tabValue, index: 2, classes, deviceConfig, currentDeviceTransaction, receivedNetworkObjects: receivedDeviceControlTransactions, setReceivedDeviceControlTransactions, handleSendTransactionButtonClick, userFunctions, selectedDevices, ...common }} />
            <CalibrationTab {...{ key: `tab_e`, value: tabValue, index: 3, classes, deviceConfig,  currentDeviceTransaction,  deviceMessages, selectedDevices, userFunctions, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions, receivedDeviceControlTransactions, ...common }} />
            <DiagnosticModeTab {...{ key: `tab_f`, value: tabValue, index: 4, classes, deviceMessages,  currentDeviceTransaction,  selectedDevices, userFunctions, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions, ...common }} />
            <MonitorTab {...{ key: `tab_d`, value: tabValue, index: 5, classes, deviceConfig, deviceMessages,  currentDeviceTransaction,  selectedDevices, userFunctions, ...common }} />
  */}
            {/* { Object.values( tabsToDisplay ).map( (tabPanel, i) => tabPanel({ value: tabValue, index: i,  classes, deviceQueryHook, setReceivedNetworkObjects, handleSendTransactionButtonClick}))} */}
          </Grid>
        </Grid>
        { [2].includes(tabValue) && <Grid key="status" item {...{ xs: 12 }}>
          <TransactionStateSteps { ...{ currentDeviceTransaction, classes}}></TransactionStateSteps>
          
        </Grid>}
      </Paper>
    </Container>
  );
};

export default DiagnosticView;


