import {
  Box,
  Button,
  Card,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DMPTrigger, statusOptions } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface generalTriggersModalProp {
  close: () => void;
  update: (input: DMPTrigger | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: DMPTrigger[];
  isFirst: boolean;
}

export const makeStatusTriggerDescription = (
  statusTrigger: string | undefined,
  inputThreshold: number | undefined,
  inputDuration: number,
  isFirst: boolean,
  unit: string = 'Litres'
): string => {
  return `${statusTrigger || '?'} is ${isFirst ? 'greater' : 'less'} than ${inputThreshold || '?'} ${unit === 'Litres' ? 'L' : '%'} for more than ${
    inputDuration || '?'
  } days`;
};

const GeneralTriggersModal: React.FC<generalTriggersModalProp> = (props: generalTriggersModalProp) => {
  let { close, update, index, existing, isFirst } = props;

  let [valid, setValid] = useState<boolean>(false);
  let [triggerDescription, setTriggerDescription] = useState<string>('');

  useEffect(() => {
    setValid(triggerDescription !== '');
  }, [triggerDescription]);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setTriggerDescription(source.triggerDescription);
    }
  }, [index, existing]);

  const addStage = (triggerDescription: string, type: string, pos: number | undefined) => {
    update({ type, triggerDescription }, pos);
    close();
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create Generic Trigger
              </Typography>
              <TextField
                rows={6}
                value={triggerDescription}
                label='Describe Trigger'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                minRows={4}
                multiline
                fullWidth
                style={{ width: '100%' }}
                onChange={(e) => setTriggerDescription(e.target.value)}
              />
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    if (triggerDescription !== undefined) {
                      addStage(triggerDescription, 'General', index);
                    }
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default GeneralTriggersModal;
