import { Card, CardHeader, CardContent, TableContainer, Paper, TableCell } from "@material-ui/core"
import { AdjacentFilter, DispatchFunctionsType, ListCardProps, ListerlizerRowControlFnProps, MapLike, SearchParamsType, UserFunctions, UserGroupsRolesType, UserTransactionType, ValidBusinessObject, ViewDefinition, ViewObject } from "../../types/AppTypes"
import { AdjacentType } from "@iotv/datamodel-core"
import { AppValueTypes, SortOrder } from "@iotv/iotv-v3-types"
import { Listerlizer } from "../factories/Listilizer"
import { NavSections } from "../navigation/NavSections"
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import queryString from 'query-string';
import { useHistory } from "react-router-dom"

export type IncidentListCardProps = {
    viewObject: ViewObject;
    contextCustomer: ValidBusinessObject | undefined;
    transactionFunctions: DispatchFunctionsType;
    userFunctions: UserFunctions;
    userGroupRoles?: UserGroupsRolesType | undefined;

    maxItems?: number;
    searchParams?: SearchParamsType | undefined;
    history: History;
    others?: MapLike | undefined;
}


export const IncidentListCard = (props: IncidentListCardProps) => {
    const { transactionFunctions, userGroupRoles, userFunctions, viewObject, contextCustomer, maxItems = 50, searchParams, history, others } = props
    const routerHistory = useHistory();
    return <Card >
        <CardHeader
            title={`Incidents`}
        ></CardHeader>
        <CardContent>

            <TableContainer component={Paper} {...{ style: { overflowY: 'visible' } }}>
                <Listerlizer {...{
                    viewObject, userGroupRoles, contextCustomer, transactionFunctions, userFunctions, searchParams, history
                    , adjacentFilter: { adjacentType: AdjacentType.CHILD, objectType: 'Incident' }, listConfigParams: { label: 'Incidents' }
                    , actions: [], maxItems, viewDefinition: {
                        name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
                        timestamp: { key: 'timestamp', label: 'Time', type: AppValueTypes.DATE, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
                        description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
                    }
                    , contextObject: viewObject.matchedPrimary
                    , sortKeyIn: 'timestamp', sortDirection: SortOrder.DESCENDING
                    , selectorConfigParams: {
                        enabled: true,
                        multipleSelect: true,
                        controls: {
                            add: false,
                            find: false,
                        }
                    }
                    , rowControlOverrideFns: {
                        first: [
                            (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
                                return [
                                  <TableCell {...{
                                    key: `${listRowProps.rowObject.sk}_goto`, onClick: () => {
                                      const viewUrl = queryString.stringifyUrl({
                                     //   ?contextObjectSK=Incident%3AIrrigator%3A67a831c7-2bfb-4a4e-a615-1f89002ea469%3ABUSINESS_RULE_VIOLATION%3ARuleset%3A4de7933f-db8d-43db-937d-609160153b65%3ALambda%3Ai3012-test-ThingAlerts-processEvent%3A1640716465896&tabName=IncidentView
                                        url: `/${NavSections.NOTIFICATION_MANANGEMENT_VIEW}`,
                                        query: {
                                          contextObjectSK: `${listRowProps.rowObject.sk}`
                                        }
                                      });
                                      routerHistory.push(viewUrl)
                                    }
                                  }}>
                                    <PlayArrowIcon {...{
                      
                                    }} />
                                  </TableCell>
                      
                                ]
                              }
                        ]
                    }
                    , optionals: {
                        rowSelectionFirst: true
                    }
                    // , functionOverrides: {
                    //   hasValidationErrors: () => {},
                    //   selectItem: (e: any ) => { console.log('Click', e)}
                    // }
                }} ></Listerlizer>
            </TableContainer>
        </CardContent>
    </Card>
}