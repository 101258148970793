import config from '@iotv/config';
import { AdjacentType, getQueryBody, getZombieInstanceFromPrimaryKey } from '@iotv/datamodel-core';
import { ExpressionFunctionType, LogicalOperatorType } from '@iotv/iotv-v3-types'
import React from "react";
import { ContextQueryParamsSelector } from "../contextQueryModal";
import SendIcon from '@mui/icons-material/Send';
import { Button, Radio, RadioGroup, TextField } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';

const dyn = config.aws.dynamoDB

export type TypeByAttributeParams = { type: string, attKey: string, value: string | number | boolean }

export const TypeByAttribute: ContextQueryParamsSelector<{ type: string, attKey: string }> = ({ setParams: setParamsInParent, lastQuery, clearSelectedObjects, lastQueryParamsRef, type, attKey }) => {
    console.log('TypeByAttribute gets', { type, attKey })
    //DEV DEFAUTLS
    const [params, setParams] = React.useState<TypeByAttributeParams | undefined>(lastQuery as TypeByAttributeParams ?? { type, attKey, value: 'XX' })

    const handleParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const params = { type, attKey, value }
        lastQueryParamsRef.current = params
        setParams(params)
    }

    const [attKeyPredicate, setAttKeyPredicate] = React.useState<ExpressionFunctionType>(ExpressionFunctionType.CONTAINS)

    const handleQueryGo = () => {
        if (params) {
            const { type, attKey, value } = params
            if (type && attKey && value) {
                const queryBody = getQueryBody({
                    adjacencyType: AdjacentType.SUBJECT,
                    contextObject: getZombieInstanceFromPrimaryKey(`${type}:xxx`),
                    ExclusiveStartKey: undefined,
                    filterGroups: [
                        {
                            filters: [
                                { key: attKey, value, predicate: attKeyPredicate },
                                { key: dyn.pKey, value: type, predicate: ExpressionFunctionType.BEGINS_WITH }
                            ],
                            setOperation: LogicalOperatorType.AND
                        }
                    ]
                })
                clearSelectedObjects()
                setParamsInParent(queryBody)
            }
        }
    }

    const handlePredicateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAttKeyPredicate(e.target.value as ExpressionFunctionType)
    }

    return <div>
        <TextField {...{ value: params?.value ?? '?', size: 'small', variant: 'outlined', onChange: handleParamChange }} />
        <Button {...{ onClick: handleQueryGo, children: 'Go', endIcon: <SendIcon /> }} />
        <RadioGroup {...{ value: attKeyPredicate, row: true, onChange: handlePredicateChange }}>
            <FormControlLabel {...{ key: 'bw', value: ExpressionFunctionType.BEGINS_WITH, control: <Radio {...{}} />, label: 'begins with' }} />
            <FormControlLabel {...{ key: 'co', value: ExpressionFunctionType.CONTAINS, control: <Radio {...{}} />, label: 'contains' }} />
        </RadioGroup>

    </div>
}