import { Logger } from "@iotv/logger"
import { ErrData, ErrDataPromise, LambdaInvocationLogs } from "@iotv/iotv-v3-types";
import { S3TimeKeyElementsType, getS3TimeKeyElements, STD_PROCESS_ENV } from "@iotv/datamodel-core";
import { s3PutWithCors } from "../aws/s3/api";
import { } from "@iotv/datamodel-core";
import S3 from "aws-sdk/clients/s3";

const { REACT_APP_PROJECT_CODE: projectCode = 'XXX', REACT_APP_DEPLOYMENT_STAGE: stage = 'XXX', } = process.env

const functionName = `${projectCode}-${stage}-ui`

/**
 * Credentials are reqiured when calling from app, so this extendds Logger and overrides putLogs so that s3 puts go through apps own s3 methods
 */
export class AppLogger extends Logger {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }

    putLogs = async (lambdaInvocationLogs: LambdaInvocationLogs): ErrDataPromise<S3.PutObjectOutput> => {
        let putResult: ErrData<S3.PutObjectOutput> = { err: null, data: null }
        const payloadStr = JSON.stringify(lambdaInvocationLogs)
        if (payloadStr.length > 1048576) {
            console.log(`Payload length is ${payloadStr.length} and will exceed max size, expect 1 validation error detected: Value at \'records.1.member.data\' failed to satisfy constraint: Member must have length less than or equal to 1048576, or ValidationException: Request Payload is too large.`)
        }
        putResult = await this.putToLogS3(lambdaInvocationLogs)
        return putResult
    }

    putToLogS3 = async (logBatch: LambdaInvocationLogs) => {
        const res: ErrData<S3.PutObjectOutput> = { err: null, data: null }
        try {
            const Expires = new Date()
            Expires.setDate(Expires.getDate() + 365)

            const {  timestamp, logs, maxLevel } = logBatch
            const [y, m, d, h, min, s, ms]: S3TimeKeyElementsType = getS3TimeKeyElements(new Date(timestamp))
            const Key = `${functionName}/${y}/${m}/${d}/${h}:${min}:${s}:${ms}`
            const Metadata ={
                projectCode,
                stage,
                functionName,
                maxLevel
            }
            const bucketIdentifier = `${projectCode}-${stage}-logs-main`
       

            const putRes: ErrData<S3.PutObjectOutput> = await s3PutWithCors( Key, bucketIdentifier, logs, Expires, Metadata)
        

            if (putRes.err) {
                res.err = putRes.err
                console.log('err in putToLogs3', putRes.err)
            } else {
                res.data = putRes.data
                console.log('s3 res in putToLogs3', putRes.data)
            }
        } catch (e: any) {
            res.err = e
        } finally {
            return res
        }

    }
}
