import { isGeofencePointsType } from '@iotv/datamodel';
import { Badge, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { deviceEnabledTypes } from '@iotv/datamodel';
import styles from '../../cosmetics/sharedCardStyles';
import { getImageUrl } from '../../data/aws/s3/UserBlobs';
import { getLocatableAddress } from '../../data/TypeHelpers';
import { AdjacentFilter, AdjacentType, ListConfigParams, UserCardProps, UserTransactionType, ViewDefinition } from '../../types/AppTypes';
import PhotosCard from '../cards/PhotosCard';
import AddressCard from '../common/AddressCard';
import UserAlertControlCard from '../common/UserAlertControlCard';
import ObjectListCard from '../generic/ObjecListCard';
import MapCard from '../geo/MapCard';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';
import { SimpleObjectLink } from '../navigation/simpleObjectLink';
import UserDetailCard from './UserDetailCard';


const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));



export function UserCard(props: UserCardProps) {
  debug && console.log('User Card has', props.userGroupRoles)
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.viewObject.matchedPrimary)
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url)
        setImageUrl(url)
      }

    }

    effectUrl()

  }, [props.viewObject.matchedPrimary]);

  const [imageUrl, setImageUrl] = React.useState('')


  const { matchedPrimary } = props.viewObject
  const { transactionFunctions, contextCustomer, user, userGroupRoles, userSelectedGroup, userFunctions, searchParams, viewObject, history, userGeolocation } = props;


  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);

  debug && console.log('UserCard userSelectedGroup', userSelectedGroup)

  const hasAlert = () => true;
  const alerts = [];

  const getUserGeolocation = () => {
    let position = undefined;
    if (userGeolocation) {

      let x = {
        lat: userGeolocation.latitude,
        lng: userGeolocation.longitude
      }
      if (isGeofencePointsType(x)) position = x;
    }
    return position;
  }

  const groupsAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'Group'

  }
  const groupsListConfigParams: ListConfigParams = {
    label: 'My Groups'
  }

  const geofenceAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'Geofence'

  }
  const geofenceListConfigParams: ListConfigParams = {
    label: 'My Fields'
  }

  const farmAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'Farm'

  }
  const farmListConfigParams: ListConfigParams = {
    label: 'My Farms'
  }

  const tanksAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'Tank'

  }
  const tanksListConfigParams: ListConfigParams = {
    label: 'My Tanks'
  }

  const rulesetAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'Ruleset'

  }
  const rulesetListConfigParams: ListConfigParams = {
    label: 'My Rulesets'
  }

  const actions: UserTransactionType[] = [
    UserTransactionType.CREATE
  ]

  const viewDefinition: ViewDefinition = {

  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <NavLink filter={matchedPrimary ? `${NavSections.THINGVIEW}/${matchedPrimary.type}/${matchedPrimary.id}` : NavSections.THINGVIEW} children={
            <Badge badgeContent={alerts.length} color="primary" invisible={!(hasAlert())}>
              <Avatar aria-label="recipe" className={classes.avatar} src={imageUrl} >
                R
              </Avatar>
            </Badge>
          }></NavLink>

        }
        title={matchedPrimary?.name}
        subheader={matchedPrimary?.breed}
      />
      <CardContent>
        {matchedPrimary && <Grid container spacing={3}>


          <Grid item xs={6}>
            <UserDetailCard {...{ matchedPrimary, transactionFunctions, searchParams, history }}></UserDetailCard>
          </Grid>
          <Grid item xs={6}>
            <AddressCard {...{ matchedPrimary, transactionFunctions, searchParams, history }}></AddressCard>
          </Grid>
          <Grid item xs={6}>
            <UserAlertControlCard {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, searchParams, history }} ></UserAlertControlCard>
          </Grid>
          <Grid item xs={6}>
            <ObjectListCard {...{ viewObject, contextObject: contextCustomer, userGroupRoles, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: geofenceAdjacentFilter, listConfigParams: geofenceListConfigParams }} ></ObjectListCard>
          </Grid>
          <Grid item xs={6}>
            <ObjectListCard {...{ viewObject, contextObject: contextCustomer, userGroupRoles, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: groupsAdjacentFilter, listConfigParams: groupsListConfigParams }} ></ObjectListCard>
          </Grid>
          <Grid item xs={6}>
            <ObjectListCard {...{ viewObject, contextObject: contextCustomer, userGroupRoles, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: rulesetAdjacentFilter, listConfigParams: rulesetListConfigParams }} ></ObjectListCard>
          </Grid>
          {Object.keys(deviceEnabledTypes).map((type: string) => <Grid item xs={6}>
            <ObjectListCard {...{
              viewObject, contextObject: contextCustomer, userGroupRoles, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: {
                adjacentType: AdjacentType.CHILD,
                edgeFilter: undefined,
                objectType: type

              }, listConfigParams: {
                label: `My ${type}`
              }
            }} ></ObjectListCard>
          </Grid>
          )}
          <Grid item xs={6}>
            <PhotosCard {...{ matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions, viewDefinition, title: `${matchedPrimary.name}'s photos` }} ></PhotosCard>
          </Grid>
          <Grid item xs={6}>
            <MapCard {...{ position: getUserGeolocation(), editable: false, locatableAddress }} ></MapCard>
          </Grid>
        </Grid>
        }



        <Typography variant="body2" color="textSecondary" component="p">

          {matchedPrimary?.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Card className={classes.subCard} >{viewObject.matchedRelatedByPk && viewObject.matchedRelatedByPk.map((object, i) => <SimpleObjectLink key={i} {...{ object, adjacentType: AdjacentType.PARENT }}></SimpleObjectLink>)} </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.subCard} >{viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.map((object, i) => <SimpleObjectLink key={i} {...{ object, adjacentType: AdjacentType.CHILD }}></SimpleObjectLink>)} </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}