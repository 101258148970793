import { HistoryStore, ObjectHistory, roundDecimals, ThingShadowState, ValidBusinessObject, ValidModelObject } from '@iotv/datamodel';
import { Tank } from '@v017/datamodel';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import { LineChartXType, ViewObject } from '../../../types/AppTypes';

import { RangeableLineChart } from './charts/RangeableLineGraph';

const googleChartUrl = config.google.chartApiUri;

const debug = process.env.REACT_APP_DEBUG && false;

type TankUsageGraphCardProps = {
  matchedPrimary: ValidModelObject<'Tank'>;
  filterGraphKeys?: string[];
  objectHistory: ObjectHistory | undefined;
};

const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    // textAlign :'center'
  },
  // paper: {
  //   // padding:  spacing(2),
  //   textAlign: 'center',
  //   // color: palette.text.secondary,
  // },
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end',
  },
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const v017ObjectHistoryKeys = {
  recentStateHistory: {
    tankLevel: 'tankLevel',
    current_filled_volume: 'current_filled_volume',
  },
  dailyStateHistory: {
    usage_in_day: 'usage_in_day',
    percentage_used_yesterday: 'percentage_used_yesterday',
  },
};

export default function TankUsageGraphCard(props: TankUsageGraphCardProps) {
  const classes = useStyles();
  const { objectHistory, filterGraphKeys, matchedPrimary } = props;
  console.log('TankUsageGraphCard from v017 temporary');

  // const UsageInDay: V017_Tank_UsageInDayType[] = viewObject.largeObject?.UsageInDay ?? matchedPrimary.UsageInDay ?? [];
  // const UsageInHour: V017_Tank_UsageInPeriodType[] = viewObject.largeObject?.UsageInHour ?? matchedPrimary.UsageInHour ?? [];
  // const historyObject = new HistoryStore();
  // UsageInDay.forEach((uid) => {
  //   const { timestamp, ...state } = uid
  //   historyObject.push({ timestamp: timestamp * 1000, state })
  // })
  const tank = new Tank(matchedPrimary);
  const totalVolume = tank._totalVolume;

  const historyObject = objectHistory?.dailyStateHistory;

  const [viewSelector, setViewSelector] = React.useState('litres');
  const [showViewSelector, setShowViewSelector] = React.useState(true);
  const [duration, setDuration] = React.useState(30);
  const [selectedHistoryObject, setselectedHistoryObject] = React.useState<HistoryStore | undefined>(historyObject);
  const [selectedUsageGraph, setSelectedUsageGraph] = useState<string | undefined>(v017ObjectHistoryKeys.dailyStateHistory.usage_in_day); // React.useState('usage_in_day')

  // debug && console.log('TankFillGraphCard props', props)
  // debug && console.log('Tank Usage Graph TSS:', viewObject.largeObject);
  // debug && console.log('viewObject.largeObject?', viewObject.largeObject);

  debug && console.log('TankUsagelGraphCard objectHistory', objectHistory);

  const googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`);

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - duration);
    const unixFromDate = (fromDate.valueOf() / 1000) * 1000;
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate) - 1;
    let maxIdx = historyObject.timestamp.length;
    const volumeArr = (historyObject[v017ObjectHistoryKeys.dailyStateHistory.usage_in_day] ?? []) as number[];
    historyObject[v017ObjectHistoryKeys.dailyStateHistory.percentage_used_yesterday] = volumeArr.map((v) => roundDecimals((100 * v) / totalVolume, 0));
    lowIdx = lowIdx < 0 ? 0 : lowIdx;
    const wholeKeys = Object.keys(historyObject);
    const keys = wholeKeys.filter((key) => key === graphKey);
    const xType: LineChartXType = LineChartXType.TIMESERIES;
    const rows = keys
      .map((key) => {
        return historyObject[key] && <RangeableLineChart {...{ historyObject, xType, classes, keys: [key], lowIdx, maxIdx }}></RangeableLineChart>;
      })
      .filter((element) => element);
    return rows;
  };

  const handleViewSelector = () => {
    if (viewSelector === 'litres') {
      setViewSelector('percentage');
      setSelectedUsageGraph(v017ObjectHistoryKeys.dailyStateHistory.percentage_used_yesterday);
    } else {
      setViewSelector('litres');
      setSelectedUsageGraph(v017ObjectHistoryKeys.dailyStateHistory.usage_in_day);
    }
  };
  const handlerDurationChange = (event: any) => {
    // debug &&
    console.log('event:', event.target.value);
    setDuration(parseInt(event.target.value));

    setselectedHistoryObject(historyObject);
    setShowViewSelector(true);
    if (viewSelector === 'percentage') setSelectedUsageGraph(v017ObjectHistoryKeys.dailyStateHistory.percentage_used_yesterday);
    else setSelectedUsageGraph(v017ObjectHistoryKeys.dailyStateHistory.usage_in_day);
  };
  return (
    <div>
      {selectedHistoryObject ? (
        googleChartsScript && getGraph(selectedHistoryObject, filterGraphKeys, selectedUsageGraph)
      ) : (
        <div>{`No selectedHistoryObject`}</div>
      )}

      <div className={classes.bottomCard}>
        <Grid container>
          <Grid item xs={6}>
            {showViewSelector && (
              <Box display='flex' justifyContent='center' bgcolor='background.paper'>
                <Typography component='div'>
                  <Grid component='label' container alignItems='center' spacing={1}>
                    <Grid item>(%)</Grid>
                    <Grid item>
                      <AntSwitch checked={viewSelector === 'litres' ? true : false} onChange={handleViewSelector} name='checkedC' />
                    </Grid>
                    <Grid item>(L)</Grid>
                  </Grid>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='center' bgcolor='background.paper'>
              <Grid component='label' container alignItems='center' spacing={1}>
                {/*  <Grid item>
                  <RadioGroup row aria-label="position" name="position" defaultValue="top"
                    value={duration}
                    onChange={handlerDurationChange}
                  >
                    <FormControlLabel
                      value={2}
                      control={<Radio color="primary" />}
                      label="2 days"
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio color="primary" />}
                      label="5 days"
                    />
                    <FormControlLabel
                      value={7}
                      control={<Radio color="primary" />}
                      label="7 days"
                    />
                    <FormControlLabel
                      value={30}
                      control={<Radio color="primary" />}
                      label="1 month"
                    />
                    <FormControlLabel
                      value={365}
                      control={<Radio color="primary" />}
                      label="1 year"
                    />
                  </RadioGroup> 
                </Grid>*/}

                <Box display='flex' justifyContent='center' bgcolor='background.paper'>
                  <FormControl className={classes.formControl}>
                    <Select value={duration} defaultValue={30} onChange={handlerDurationChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                      {/* <MenuItem value={1}>1 day (Hourly)</MenuItem> */}
                      <MenuItem value={2}>2 days</MenuItem>
                      <MenuItem value={5}>5 days</MenuItem>
                      <MenuItem value={7}>7 days</MenuItem>
                      <MenuItem value={30}>1 Month</MenuItem>
                      <MenuItem value={365}>1 year</MenuItem>
                      <MenuItem value={365 * 2}>2 years</MenuItem>
                      <MenuItem value={365 * 5}>5 years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* </CardContent>
        </Card> */}
      </div>
    </div>
  );
}
