import { Card, CardContent, CardHeader, Container, Grid, makeStyles, Tab, Tabs, Theme, Typography, withStyles } from '@material-ui/core';
import { TabPanel } from '../../utils/TabPanelShim';

import { useHistory } from 'react-router-dom';
import styles from '../../../cosmetics/sharedViewStyles';
import { DeviceManagementViewProps, DeviceViewSearchParamstype, UserTransactionType } from '../../../types/AppTypes';
import { Tabilizer } from '../../factories/Tabilizer';
import React, { useEffect } from 'react';
import { getQueryParamsFromHistory } from '../../../util/Routing/queryParams';
import queryString from 'query-string';
import { ValidBusinessObject, ValidModelObject } from '@iotv/datamodel';
import { DeviceGroupsTab } from './tabs/DeviceGroups';
import { GatewayGroupsTab } from './tabs/GatewayGroupsTab';

const debug = process.env.REACT_APP_DEBUG && false;
const componentClassName = 'DeviceManagementView';
const componentParentHasRendered = () => document.querySelector(`.${componentClassName}`);

const localStyle = (theme: Theme) => ({


});
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle(theme) }));

const tabsToDisplay = ['Gateways', 'Devices', ].filter((tabName) =>
 tabName
);


function DeviceManagementView( props : DeviceManagementViewProps): JSX.Element {
  const {  match: { params: { action, id: sk, type } }, user, contextCustomer, history} = props;
  const routerHistory = useHistory();
  const searchParams = getQueryParamsFromHistory<DeviceViewSearchParamstype>(history)

  const [tabValue, setTabValue] = React.useState(props.tabIndex ?? 0);


  if ( searchParams?.tabName ) {
    const openTabIndex = tabsToDisplay.indexOf(searchParams.tabName);
    if ( openTabIndex !== -1 && tabValue !== openTabIndex ) {
      setTabValue(openTabIndex);
     
    }
  }

  useEffect( () => {

  }, [ user, contextCustomer ])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    debug && console.log('tab change', newValue)
    //setTabValue(newValue);
    
    const newSearchParems = {...searchParams, tabName: tabsToDisplay[newValue] ?? tabsToDisplay[0]}
    const newUrl = queryString.stringifyUrl(
      {
        url: routerHistory.location.pathname,
        query: newSearchParems
      }
    )
    routerHistory.push(newUrl);
  };

  debug && console.log('match', {action, sk})
  const classes = useStyles();
  return <Container className= {componentClassName}>
    <Card className={classes.root}>
      <CardHeader></CardHeader>
      <CardContent>
      <Tabs {...{ value: tabValue, onChange: handleTabChange, variant: 'scrollable' }} >
              {tabsToDisplay.map((tabName, i) => <Tab label={tabName} {  ...{ key: `tab_${i}` }} />)}
            </Tabs>
            
            <TabPanel {...{ value: tabValue, index: 0 }}>
              { props.user && <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {`${contextCustomer?.name} Gateway Groups` }
                    </Typography>
                      <GatewayGroupsTab { ...{ ...props, contextUser: user as  ValidModelObject<'User'>} }></GatewayGroupsTab>
                  </CardContent>
                </Card>
              </Grid>}
              { debug && !props.user && <h1>no user</h1>} 
              
            </TabPanel>
            <TabPanel {...{ value: tabValue, index: 1 }}>
              { props.user && <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {`${contextCustomer?.name} Device Groups` }
                    </Typography>
                      <DeviceGroupsTab { ...{ ...props, contextUser: user as  ValidModelObject<'User'>} }></DeviceGroupsTab>
                  </CardContent>
                </Card>
              </Grid>}
              { debug && !props.user && <h1>no user</h1>} 
              
            </TabPanel>
       
      </CardContent>

    </Card>
  </Container>

}

export default withStyles(styles)(DeviceManagementView)