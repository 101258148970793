import config from '@iotv/config';
import { camelToDisplay, getObjectHash } from "@iotv/datamodel-core";
import { IdType, MapLike } from "@iotv/iotv-v3-types";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { DataGridProps } from "@mui/x-data-grid";
import { DocumentClient, QueryInput } from "aws-sdk/clients/dynamodb";
import React, { useEffect } from "react";
import { GetDataGridPropsRequest, GetDataGridSelectableObjectsRequest } from "src/types";
import ApiGateway from "../../../data/aws/api-gateway/ApiGateway";
import { DocumentClientQueryHookInputV2, useDocumentClientQuery } from "../../../hooks/useDocumentClientQueryV2";
import { UseSelectedObjectsReturnType } from "../../../hooks/useSelectedObjects";
import { DocumentClientQueryHookOutputV2 } from "../../../types/LabTypes";
import { StripedDataGrid } from "../DataGrid/styles/striped";

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = config.app.appName

export type ContextQueryParamsSelector<OtherProps extends MapLike<any>> = React.FunctionComponent<{
    /**
     * sets query parameters in owning parent
     */
    setParams: React.Dispatch<QueryInput>,
    lastQueryParamsRef: React.MutableRefObject<any | undefined>
    lastQuery: MapLike<any> | undefined
    clearSelectedObjects: () => void
} & OtherProps>

export type ContextQueryFCplusProps<T extends MapLike<any>> = {
    ParamSelector: ContextQueryParamsSelector<T>
    ParamSelectorProps: T
}

export type ContextQueryModalProps<ResultItemType extends IdType> = {
    /**
     * keyed map of pairs of functional component which renders a parameter choice component, the props for this functional component
     */
    componentParamPairsMap: MapLike<ContextQueryFCplusProps<any>>,
    /**
     * affects selected objects in owning paraent
     */
    parentTableUseSelectedObjects: UseSelectedObjectsReturnType<ResultItemType>,
    /**
     * defines how to construct the result table
     *
     */
    getDataGridProps: ({ items, loading }: GetDataGridSelectableObjectsRequest<ResultItemType> & GetDataGridPropsRequest<ResultItemType>) => DataGridProps | undefined
    /**
     * stores last searched items so that they can be rerendered when modal is reopened
     */
    lastSearchedItemsRef: React.MutableRefObject<DocumentClient.AttributeMap[]>,
    lastQueryComponentKeyRef: React.MutableRefObject<string | undefined>,
    lastQueryParamsRef: React.MutableRefObject<any | undefined>


}   
/**
 * Renders a modal selection component with left accordion being populated with series of parameter selection components, the right being populated with a reults table
 * The definition for the results table is injected and accepts getter setter state functions to allow selection of result items to be set in the parent component
 * @param param0 
 * @returns 
 */
export function ContextQueryModal<ResultItemType extends IdType>({ componentParamPairsMap, parentTableUseSelectedObjects, getDataGridProps, lastSearchedItemsRef, lastQueryComponentKeyRef, lastQueryParamsRef }: ContextQueryModalProps<ResultItemType>) {

    const { state: [[selectedObjects, setSelectedObjects]], handlers: { addToSelectedObjects, removeFromSelectedObjects, isInSelectedObjects } }: UseSelectedObjectsReturnType<ResultItemType> = parentTableUseSelectedObjects

    const [params, setParams] = React.useState<any | undefined>(undefined)
    const paramsHash = getObjectHash(params)
    const hookParams: DocumentClientQueryHookInputV2<DocumentClient.ItemList> = {
        query: (params) => ApiGateway.post(`/baseQuery/`, params, apiRef), queryParams: params, dependencies: [paramsHash]
    }

    useEffect(() => {
        console.log('ContextQueryModal used effect on params hash', paramsHash)

        // lastSearchedItemsRef.current = []
        // setItems([])
    }, [paramsHash])
    


    const [openQueryKey, setOpenQueryKey] = React.useState<string | undefined>( lastQueryComponentKeyRef?.current );

    const handlePanelChange = (openQueryKey: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if ( isExpanded ) {
            lastQueryComponentKeyRef.current = openQueryKey
        }
        setOpenQueryKey(isExpanded ? openQueryKey : undefined);
    }

    const {
        querySetterGetters: [
            [items, setItems],
            [limit, setLimit],
            [LastEvaluatedKeys, setLastEvaluatedKeys],
            [ExclusiveStartKey, setExclusiveStartKey]
        ],
        forceUpdate, updates, inFlight
    }: DocumentClientQueryHookOutputV2<DocumentClient.ItemList> = useDocumentClientQuery(hookParams)

    const itemsHash = items.slice( 0, 20 ).map( (i) => i.id ).join()

    useEffect( () => {
        console.log('ContextQueryModal used effect on itemsHash hash', itemsHash)
        // setSelectedObjects([])
    }, [itemsHash])

    useEffect( () => {
        setItems( lastSearchedItemsRef.current )
    }, []) 

    useEffect(() => {
        lastSearchedItemsRef.current = items;
      }, [ items.length ]);

    const dataGridProps: DataGridProps | undefined = getDataGridProps({ 
        items: items as ResultItemType[], loading: inFlight, 
        addToSelectedObjects, isInSelectedObjects, removeFromSelectedObjects,
        selectedObjects, setSelectedObjects
    })

    return <div {...{ style: { height: 1000 } }}>
        <Grid container >
            <Grid item {...{ xs: 3 }}>

                {Object.entries(componentParamPairsMap).map(([k, pair]) => {
                    const { ParamSelector, ParamSelectorProps } = pair
                    let lastQuery: MapLike<any> | undefined = k === openQueryKey ? lastQueryParamsRef.current : undefined
                    return <Accordion {...{ expanded: openQueryKey === k, onChange: handlePanelChange(k) }}>
                        <AccordionSummary
                            {...{
                                expandIcon: <ExpandMoreIcon />,
                                id: `${k}_header`
                            }}
                        >
                            <Typography {...{ sx: { width: '33%', flexShrink: 0 } }}>
                                {camelToDisplay(k)}
                            </Typography>
                            <Typography {...{ sx: { color: 'text.secondary' } }}></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ParamSelector {...{ setParams, lastQuery, lastQueryParamsRef, clearSelectedObjects: () => setSelectedObjects([]), ...ParamSelectorProps }} />
                        </AccordionDetails>
                    </Accordion>
                })}

            </Grid>
            <Grid item {...{ xs: 9, style: { minHeight: 1000 } }}>
                {dataGridProps && <StripedDataGrid {...dataGridProps} />}
            </Grid>
        </Grid>


    </div>
}