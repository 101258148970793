import { ValidBusinessObject, ValidBusinessObjectList } from "@iotv/datamodel";
import { Button, CircularProgress, FormControl, IconButton, TextField } from "@material-ui/core";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import React from "react";
import { v4 as uuidv4 } from 'uuid/';
import config from "../../config";
import { useGoogleAPIAuth } from '../../hooks/useGoogleAPIAuth';
import { useGoogleListSheets, UseGoogleListSheetsReturnType } from "../../hooks/useGoogleIO";
import { MessageOutputType, UserFunctions, ViewDefinition } from "../../types/AppTypes";
import { gapiIsPresent } from "../../util/Scripts/isPresent";
import { exportVobs, HeaderSource } from './sharedFns';

const debug = process.env.REACT_APP_DEBUG && false;
const height = 18 
export const SimpleExport = ( { contextCustomer, viewDefinition, vobs, userFunctions}: { contextCustomer: ValidBusinessObject | undefined, viewDefinition: ViewDefinition, vobs: ValidBusinessObjectList, userFunctions: UserFunctions }) => {

    const { state: [ [ signedIn, setSignedIn], [isInitialized, setIsInitialized] ], handlers: [ handleAuthClick, handleSignoutClick ] } = useGoogleAPIAuth(contextCustomer, gapiIsPresent())
    const { 
        state:  [ 
            [nameMatchText, setNameMatchText], 
            [matchedFiles, setMatchedFiles],
            [selectedFile, setSelectedFile],
            [availableSheets, setAvailableSheets],
            [selectedSheet, setSelectedSheet],
            [selectedRange, setSelectedRange]
        ], 
        handlers: [ handleListFiles ] 
    }: UseGoogleListSheetsReturnType = useGoogleListSheets({signedIn}); 

    const [ working, setWorking ] = React.useState<boolean>(false)


    const displayMessage = (content: string, type: MessageOutputType) => {
        userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
      }

      
    type ReactEventMayhem = ((
        event: React.ChangeEvent<{}>, 
        value: string | { title: string } | null, 
        reason: AutocompleteChangeReason, 
        details?: AutocompleteChangeDetails<{ title: string; }> | undefined) => void) | undefined
    

    return  <span>
         { isInitialized && <FormControl>
         <Autocomplete
            freeSolo
            id="spreadsheet-name-select"
            options={matchedFiles.map( (file) => { return { title: file.name }} ).filter( (item) => item.title ) as { title: string }[] }
            getOptionLabel={(option) => option.title}
            style={{ width: 300, padding:0  , margin:0}}
            // style={{ width: 300 }}
            size="small"
            onChange= { (e, v: { title: string } | string | null)  => { 
                setSelectedFile(undefined)
                setAvailableSheets([])
                setSelectedSheet(undefined)
                if ( typeof v === 'object' && v?.title) { 
                    setNameMatchText(v.title)
                    const matchedFile = matchedFiles.find( (file) => file?.name === v.title);
                    if (matchedFile ) {
                        setSelectedFile(matchedFile)
                    } else {
                        debug && console.log('no match on', v.title)
                    }
                } 

            }}
            
            renderInput={(params) => <TextField 
                {...{
                    ...params, 
                    value: nameMatchText ?? config.app.appCode,  
                    onChange: ( e ) => { 
                        setAvailableSheets([])
                        setSelectedSheet(undefined)
                        setSelectedFile(undefined)
                        setNameMatchText(e.target.value);
                        const matchedFile = matchedFiles.find( (file) => file?.name === e.target.value);
                        if (matchedFile ) {
                            setSelectedFile(matchedFile)
                        } else {
                            debug && console.log('no match on', e.target.value)

                        }
                        }
                    } 
                }
                //  value= {nameMatchText ?? config.app.appCode}
                //     onChange= {( e ) => { 
                //         setAvailableSheets([])
                //         setSelectedSheet(undefined)
                //         setSelectedFile(undefined)
                //         setNameMatchText(e.target.value);
                //         const matchedFile = matchedFiles.find( (file) => file?.name === e.target.value);
                //         if (matchedFile ) {
                //             setSelectedFile(matchedFile)
                //         } else {
                //             debug && console.log('no match on', e.target.value)

                //         }
                //         }}
                    
                 label="Google Sheet export" size = 'small' variant="outlined"                 
               
                  inputProps={{
                    ...params.inputProps, 
                    style: {
                      height,
                      padding: '0',
                    },
                }}
                 />}
    />
         </FormControl> }
         <FormControl>
         { availableSheets.length > 0 && <Autocomplete
            id='availableSheets-select'
            options={availableSheets.map( (sheet, i) => { return { title: sheet.properties?.title ?? `no title ${i}`  } } )}
            getOptionLabel={(option) => option.title}
            // style={{ width: 300 }}
            style={{ width: 300, padding:0 , margin:0}}

            onChange= { (e, v: { title: string } | string | null)  => { 
                if ( typeof v === 'object' && v?.title) { 
                    const matchedSheet = availableSheets.find( (sheet) => sheet.properties?.title === v.title);
                    if (matchedSheet ) {
                        setSelectedSheet(matchedSheet)
                    } else {
                        debug && console.log('no match on', v.title)
                    }
                } 
            }}

            renderInput={(params) => <TextField { ...{
                ...params,
                value:  'select sheet'
                }
            } label="Sheet name" variant="outlined" size="small" />}
/>}
         </FormControl>
        
         <FormControl> {
                selectedFile && <a { ...{ target: "_blank", href: `https://docs.google.com/spreadsheets/d/${selectedFile.id}`}}>
                            <IconButton
                    { ...{ 
                     
                    }}
                >
                <EditIcon></EditIcon>
             </IconButton>
                </a>
                 
        
             }

         </FormControl>
         {  vobs && selectedFile && selectedSheet &&  <FormControl>
             <IconButton
                { ...{
                    onClick: async () => {
                        debug && console.log('Export of ', {vobs, file: selectedFile, sheet: selectedSheet})
                        setWorking(true)
                        const exportRes = await exportVobs({vobs, viewDefinition, file: selectedFile, sheet: selectedSheet, headerSource: HeaderSource.KEY})
                        if ( exportRes.err) {
                            displayMessage( exportRes.err.message,'MessageBar')
                        } else {

                            displayMessage( exportRes.data?.updatedRows ? `${selectedFile.name} updated with ${exportRes.data.updatedRows} items` : 'no result from export','SnackBar')
                        }
                        setWorking(false)
                    }
                }}
             >
             <AddShoppingCartIcon />
             </IconButton>
         </FormControl>}
         <FormControl>
              <div { ...{ style: { justifyContent: 'space-around', padding: '8px'}}}>
              {working && <CircularProgress { ...{ color: "secondary", size: '2em' } }/>}
              </div>
       
         </FormControl>
         <FormControl>
                <Button
                variant="outlined"
                color="primary"
                size='small'
                onClick={() => signedIn ? handleSignoutClick() :  handleAuthClick()}
                disabled={!isInitialized}
             >  { isInitialized ? signedIn ? 'Sign out' : 'Authorize': 'Waiting'}</Button>
         </FormControl> 

         </span>

}
