import { getNiceDate, ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../cosmetics/Themes/dense';
import Assembler from '../../data/Assembler';
import { ComponentMode, pseudoEvent, ViewKeyDefinitionType } from '../../types/AppTypes';
import { BooleanComponent } from './inputFields/BooleanComponent';
import { EnumComponent } from './inputFields/EnumComponent';
import { InputFieldArbitrator } from './inputFields/InputFieldArbitrator';
import { TextComponent } from './inputFields/TextComponent';
import { TableRowizerTableCellForObject } from './TableRowizerTableCellForObject';


const useStyles = makeStyles((theme) => (styles(theme)));


type TabilizerRowProps = {
    keyToUse: string | number,
    matchedPrimary: ValidBusinessObject,
    viewKeyDefinition: ViewKeyDefinitionType,
    mode: ComponentMode,
    updateKV:  (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent , k: string ) => void,
    functionOverrides?: {
        hasValidationErrors: Function
      },
}

export function TabilizerTableRow(props: TabilizerRowProps) {
    
    const classes = useStyles(denseTheme);

 

    const {keyToUse, updateKV, matchedPrimary, viewKeyDefinition, mode, functionOverrides} = props;

  
    const tableOb = matchedPrimary;

    return (
        <TableRow key={keyToUse} >

            <InputFieldArbitrator {...{  keyToUse, tableOb: matchedPrimary, viewKeyDefinition, mode, updateKV, functionOverrides }}></InputFieldArbitrator>

      </TableRow>
    );
}