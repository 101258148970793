import { ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './DroughtModal.module.css';
import { ViewObject, ObjectCardProps } from '../../../../../../../types/AppTypes';
import { SendMessage } from '../sendmessage'
import { getCustomerRecipiants } from '../RecipientsSupport';

const modalRoot = document.querySelector('#modal-root');

interface StageNameModal {
    close: () => void;
    index: number | undefined;
    source: ValidBusinessObject;
    setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
    finduser?: ObjectCardProps;
    customer: ValidBusinessObject;
}

const EditStageNameModal: React.FC<StageNameModal> = (props: StageNameModal) => {
    let { close, index, source, setSource, finduser, customer } = props;
    const [reason, setreason] = useState<string>('');
    const [email, setemail] = useState(0)
    let [recipient, setRecipients] = useState<ValidBusinessObjectList>([]);
    let [sendhistory, setsendhistory] = useState<Sendhistorytype[]>();

    useEffect(() => {
        // Update the document title using the browser API
        console.log("tryuseffect", `You clicked ${email} times`)
    });

    useEffect(() => {
        if (customer) {
            updateRecipient();
        }
    }, [customer]);

    const updateRecipient = async () => {
        setRecipients(await getCustomerRecipiants(customer));
    };

    if (modalRoot === null) {
        console.log('Root for modal is not working.');
        return null;
    }

    type DMPHistory = {
        name: string,
        beforechange: string,
        afterchange: string,
        time: string
    };

    function historyTemplate(before: string, after: string, newreason: string) {
        return {
            name: finduser?.user?.name,
            beforechange: before,
            afterchange: after,
            changereason: newreason,
            time: (new Date()).toString()
        };
    };

    const activestage = (newreason: string) => {
        var getsendhisotylist = sendemail()
        console.log("getsendhisotylist", getsendhisotylist)
        var beforechange = source.activestage
        addNewhistory(getstagename(beforechange), getstagename(index ? index : 0), newreason, getsendhisotylist)
        close();
    };

    function getstagename(input: number) {
        return source.stage[input]?.name ? `${source.stage[input]?.stageNumber}) ${source.stage[input]?.name} ` : `Stage ${source.stage[input]?.stageNumber}`
    }

    function addNewhistory(before: string, after: string, newreason: string, getsendhisotylist: Sendhistorytype[]) {
        if (setSource !== undefined) {
            var history = historyTemplate(before, after, newreason)
            let stages: DMPHistory[] = source?.stagechangehistory || [];
            stages.push(history);
            let sendstage = source?.sendmessagehistory || [];
            sendstage.push(getsendhisotylist);
            setSource({ ...source, activestage: index, stagechangehistory: stages, sendmessagehistory: sendstage });
        }

        console.log("what is index", source)
    };

    const renderIdListwithemail = (idList: string[], choice: ValidBusinessObjectList) => {
        let objList = idList.map((id) => {
            console.log("ifincludeemail", choice.find((option) => option.id === id))
            var findone = choice.find((option) => option.id === id)
            return [findone?.name, findone?.email, findone?.phoneNumber];
        });
        return objList;
    };

    type Sendhistorytype = {
        content: string[],
        name: string[],
        type: string,
        time: string
    };

    function sendhistoryTemplate(content: string[], name: string[], type: string) {
        return {
            content: content,
            name: name,
            type: type,
            time: (new Date()).toString()
        };
    }

    function sendemail() {
        console.log("essasss", source.stage[index ? index : 0].stageMessages)
        var beforechange = getstagename(source.activestage)
        var afterchange = getstagename(index ? index : 0), newreason
        var time = new Date().toString()
        console.log("esss", beforechange, afterchange, time)
        var sendhilistorylist = []

        var output = "ISLAND-WIDE DROUGHT MANAGEMENT ALERT: From 11:59 pm tonight, the whole of the island moves to Drought Stage " + afterchange + " from Drought Stage " + beforechange + " (current Active Stage)."
        output += "<BR /><BR />"
        output += "Please follow the guidelines and use water wisely."

        if (source.stage[index ? index : 0].stageMessages.length > 0) {
            output += "<BR /><BR />"
            output += "Remember:"
            output += "<BR />"
            for (var i = 0; i < source.stage[index ? index : 0].stageMessages.length; i++) {
                var insideput = ""
                var outsideput = output
                for (var x = 0; x < source.stage[index ? index : 0].stageMessages[i].messages.length; x++) {
                    insideput += source.stage[index ? index : 0].stageMessages[i].messages[x] + "<BR />"
                    console.log("essasss", i, source.stage[index ? index : 0].stageMessages[i].messages[x])
                }
                console.log("whatismessage", source.stage[index ? index : 0].stageMessages[i])
                outsideput += insideput

                var emailphone = renderIdListwithemail(source.stage[index ? index : 0].stageMessages[i].recipientIDs, recipient)
                var sendemail: string[] = []
                var sendsms: string[] = []
                var sendname: string[] = []
                for (var y = 0; y < emailphone.length; y++) {
                    sendname.push(emailphone[y][0])
                    sendemail.push(emailphone[y][1])
                    sendsms.push("+" + emailphone[y][2])
                }

                var titleout = "Drought Response Stage Change Message from " + customer.name + " Water Management team"

                console.log("showresult", titleout, outsideput, sendemail, sendsms)
                var sendcontent = source.stage[index ? index : 0].stageMessages[i].messages
                var sendhistory = sendhistoryTemplate(sendcontent, sendname, "Change stage")
                sendhilistorylist.push(sendhistory)

                SendMessage(titleout, outsideput, sendemail, sendsms)
            }
        }
        return sendhilistorylist
    }
    console.log("whatissource", customer, recipient)

    return ReactDOM.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.form}>
                <Card>
                    <Box m={2}>
                        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
                            <Typography variant='h5' component='h5'>
                                Reason for change stage
                            </Typography>
                            <TextField label={'Reason'} variant='outlined' fullWidth onChange={(e) => setreason(e.target.value)} value={reason || ''} size='small' />
                        </Stack>
                    </Box>
                    <Box m={2}>
                        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                            <Button variant='contained' onClick={close}>
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                disabled={reason === ''}
                                onClick={() => {
                                    activestage(reason);
                                }}
                            >
                                Change stage
                            </Button>
                        </Stack>
                    </Box>
                </Card>
            </div>
        </div>,
        modalRoot
    );
};

export default EditStageNameModal;
