import { Box, Button, Card, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Goal, goalTypes, tankTypes } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface goalsModalProp {
  close: () => void;
  update: (input: Goal | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: Goal[];
}

export const makeGoalDescription = (goal: string | undefined, tankType: string | undefined, amount: number, type: string): string => {
  return `${goal || '?'} from ${tankType || '?'} tank by ${amount || '0'} ${type === 'Litres' ? 'Litres' : '%'}`;
};

const TargetGoalsModal: React.FC<goalsModalProp> = (props: goalsModalProp) => {
  let { close, update, index, existing } = props;

  let [goalOption, setGoalOption] = useState<string>();
  let [tankOption, setTankOption] = useState<string>();
  let [amount, setAmount] = useState<number>(0);
  let [type, setType] = useState<string>('Litres');

  let [description, setDescription] = useState<string>('');

  let [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setGoalOption(source.goal);
      setTankOption(source.tankType);
      source.timeframe && setAmount(source.timeframe);
      source.metric && setType(source.metric);
    }
  }, [index, existing]);

  useEffect(() => {
    setValid(goalOption !== '' && tankOption !== '' && amount !== 0);
  }, [goalOption, tankOption, amount]);

  useEffect(() => {
    setDescription(makeGoalDescription(goalOption, tankOption, amount, type));
  }, [goalOption, tankOption, amount, type]);

  const addStage = (goal: string, type: string, tankType: string, metric: string, timeframe: number, pos: number | undefined) => {
    update({ goal, type, tankType, metric, timeframe }, pos);
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    // showScreen(false);
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create Targeted Goal
              </Typography>
              <TextField
                // disabled={}}
                label={'Select Goal Type'}
                select
                variant='outlined'
                fullWidth
                onChange={(e) => setGoalOption(e.target.value)}
                value={goalOption || ''}
                size='small'
              >
                {goalTypes.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                // disabled={}}
                label={'Select Tank Type'}
                select
                variant='outlined'
                fullWidth
                onChange={(e) => setTankOption(e.target.value)}
                value={tankOption || ''}
                size='small'
              >
                {tankTypes.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                <Typography variant='h6' component='h6'>
                  Select Water Conservation Target
                </Typography>
                <TextField
                  id='duration'
                  value={amount}
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value) || 0);
                  }}
                  type='number'
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>by</InputAdornment>,
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    inputMode: 'numeric',
                    shrink: true,
                  }}
                />

                <RadioGroup
                  {...{
                    row: true,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => setType(value),
                    value: type,
                    name: 'radio-buttons-group',
                  }}
                >
                  <FormControlLabel value='Litres' control={<Radio />} label='Litres' />
                  <FormControlLabel value='Percent' control={<Radio />} label='Percent' />
                </RadioGroup>
              </Stack>
              <TextField
                rows={5}
                disabled
                placeholder='Action'
                value={description}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                minRows={3}
                multiline
                style={{ width: '100%' }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    if (goalOption !== undefined && tankOption !== undefined && amount) {
                      addStage(goalOption, 'Target', tankOption, type, amount, index);
                    }
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default TargetGoalsModal;
