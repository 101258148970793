import { DeviceControlTransaction, DeviceTransactionStatus, IDeviceControlTransaction } from "@iotv/datamodel-core";
import { Card, CardContent, Step, StepLabel, Stepper } from '@material-ui/core';
import { AppClasses } from "src/types/AppTypes";
import { useStyles } from "./diagnosticStyles";



const debug = process.env.REACT_APP_DEBUG && false;
const componentClassName = 'DiagnosticView'
const { REACT_APP_PROJECT_CODE: fullProjectCode } = process.env
const projectCode = fullProjectCode?.substring(0,4) ?? 'i301'

const getSteps = ( currentStatus?: DeviceTransactionStatus ) => {

  const steps = [
    DeviceTransactionStatus.USER_REQUESTED,
    DeviceTransactionStatus.QUEUED,
    DeviceTransactionStatus.SENT,
    DeviceTransactionStatus.ACK,
    DeviceTransactionStatus.DEVICE_ACK,
    DeviceTransactionStatus.DEVICE_RESPONSE
  ];
  //     DeviceTransactionStatus.JOIN_ACCEPT
  //     DeviceTransactionStatus.FAILED

  if ( currentStatus === DeviceTransactionStatus.DOWNLINK_CONFIG_ERROR) {
    steps.pop(); steps.pop()
    steps.push( DeviceTransactionStatus.DOWNLINK_CONFIG_ERROR)
  }
  //debug && console.log(`steps at status ${ currentStatus }`, steps)
  return steps
};

export type TransactionStateStepsProps = {
  currentDeviceTransaction: IDeviceControlTransaction | undefined,
  classes: AppClasses
}

export const TransactionStateSteps = ({ classes: classesIn, currentDeviceTransaction }: TransactionStateStepsProps) => {
  const classes = { ...classesIn, ...useStyles() };



  const steps = getSteps(currentDeviceTransaction?.status);



  const activeStep = currentDeviceTransaction
    ? getSteps(currentDeviceTransaction.status).findIndex((step) => step === currentDeviceTransaction.status) ?? 0
    : -1;



  return (

      <Stepper { ...{ activeStep, style: { padding: 0} }} alternativeLabel>
        {steps.map((label, i) => (
          <Step { ...{key: label, color: label === DeviceTransactionStatus.DOWNLINK_CONFIG_ERROR ? 'primary' : 'secondary'} }>
            <StepLabel>{label?.replace(/_/g, " ") ?? `No label for step ${i} of ${ JSON.stringify(steps)}`}</StepLabel>
          </Step>
        ))}

      </Stepper>


  );
};




