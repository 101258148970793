import { getNiceDate, Milliseconds } from "@iotv/datamodel-core"
import { GridRenderCellParams } from "@mui/x-data-grid"
import React from "react"
type RenderCellType<T> = (params: GridRenderCellParams<T, any, any>) => React.ReactNode

export type LastEventTimeProps = {  timestamp: number | undefined, autoUpdate: boolean  }
export const LastEventTime = ( { timestamp, autoUpdate }: LastEventTimeProps) => {

    const [ update, setUpdate ] = React.useState<number>(0)

    if ( autoUpdate ) {
        const timer = setTimeout( () => setUpdate( update + 1 ), Milliseconds.SECOND)
    }


    let timeStr: string = ''
    if ( timestamp ) {
        const niceDate: string = getNiceDate(timestamp)
        const now = Date.now()
        const diffSecs = Math.round((now - timestamp) / Milliseconds.SECOND)
        const diffMinutes = Math.floor( diffSecs / 60 )
        const diffHours = Math.floor( diffMinutes / 60 )
        const diffDays = Math.floor( diffHours / 24)

        const days = diffDays
        const hours = diffHours % 24
        const mins = diffMinutes % 60
        const secs = diffSecs % 60
    
    
       timeStr =  diffDays > 0 ? niceDate //`${days} day ${hours} hrs`
        : diffHours > 0 ? `${hours} hrs ${mins} min`
        : diffMinutes > 0 ? `${mins} min ${secs} sec`
        : `${secs} sec`
    }




    return <>{timeStr}</>
}


export const lastEventTime: RenderCellType<number | undefined> = ( p ) => <LastEventTime { ...{ timestamp: p.value, autoUpdate: true }}/>