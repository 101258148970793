import { Community, DroughtManagementPlanStage, Tank } from '@c021/datamodel';
import { getZombieInstanceFromPrimaryKey, PathDirection, QueryTypes, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { ErrData, TraverserQueryPathBody } from '@iotv/iotv-v3-types';
import { Box, Button, Card, Grid, Table, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import { color } from 'd3-color';
import React, { useEffect, useRef, useState } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import { useHistory, useLocation } from 'react-router';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { getOne } from '../../../data/daoFunctions/daoFunctions';
import { useContainerDimensions } from '../../../hooks/useContainerDimenstions';
import { useStyles } from '../cosmetics/communityStyles';
import { getAverageTankGeoLocation } from '../data/TypeHelpers';
import { CommunityDailyUsageGraph } from './CommunityDailyUsageGraph';
import { CommunityFillLevelGraph } from './CommunityFillLevelGraph';
import { DMPStage } from './CountryCommunity/DroughtManagement/DMPExtrasV2';
import MapView from './geo/MapView';

type DailyWeatherReport = {
  day: string;
  weatherIcon: string;
};

type ActionGoalPair = {
  name: string;
  value: string;
};

export const CommunityView = () => {
  const theme = useTheme();
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [community, setCommunity] = useState<Community>();
  const [communityTanks, setCommunityTanks] = useState<Tank[]>([]);
  const [communityWeather, setCommunityWeather] = useState<DailyWeatherReport[]>([]);
  const [householdTankVolume, setHouseholdTankVolume] = useState<number>(0);
  const [communityTankVolume, setCommunityTankVolume] = useState<number>(0);
  const [currentPlanStage, setCurrentPlanStage] = useState<DMPStage>();
  const [waterLeadTime, setWaterLeadTime] = useState(0);

  //Temp position data for IoTVentures office to test map.
  //TODO : Update to grab location for community. Maybe use average position of tanks?
  const locatableAddress = '456 Mount Eden Road, Mount Eden, Auckland 1024';
  const testPos = {
    lat: -36.8821424167,
    lng: 174.7625881833,
  };

  useEffect(() => {
    fetchCommunity();
  }, []);

  useEffect(() => {}, [community]);

  /**
   * Function fetches the current community to display from its ID
   */
  const fetchCommunity = async () => {
    const communityID = history.location.pathname.replace('/COMMUNITYVIEW/', '');

    const zombie = getZombieInstanceFromPrimaryKey(`Customer:${communityID}`) as ValidBusinessObject;
    const community = await getOne(zombie);

    community.data ? setCommunity(community.data as Community) : console.log('oh noes');

    fetchWeather(community.data as Community);
    fetchCommunityTanks(community.data as Community);
    fetchDroughtManagementPlanStage(community.data as Community);
  };

  /**
   * Function fetches the tanks linked to the current community so help populate some of the
   * tank related UI elements
   * @param communityObject Community object for current community
   */
  const fetchCommunityTanks = async (communityObject: Community) => {
    const simpleTypePath = [
      { type: 'Customer', direction: PathDirection.child },
      { type: 'Tank', direction: PathDirection.child },
    ];

    const queryBody: TraverserQueryPathBody = {
      type: QueryTypes.directedPath,
      simpleTypePath: simpleTypePath,
      normalize: false,

      items: [communityObject], // CHILD should be from Datastore.pathDirection
    };

    const path = '/query/';
    const { err, data } = await ApiGateway.post(path, queryBody);

    if (err == null && data != null) {
      console.log(data);
      const communityTanks = data.filter((item: ValidBusinessObject) => item.type === 'Tank');
      setHouseholdTankVolume(calculateTankTypeVolume('Household', communityTanks, communityObject.total_volume));
      setCommunityTankVolume(calculateTankTypeVolume('Community', communityTanks, communityObject.total_volume));

      setCommunityTanks(communityTanks as Tank[]);
    }
  };

  const fetchDroughtManagementPlanStage = async (communityObject: ValidBusinessObject) => {
    const simpleTypePath = [
      { type: 'DroughtManagementPlan', direction: PathDirection.parent },
      //{ type: 'DroughtManagementPlanStage', direction: PathDirection.child },
    ];

    const queryBody: TraverserQueryPathBody = {
      type: QueryTypes.directedPath,
      simpleTypePath: simpleTypePath,
      normalize: false,

      items: [communityObject], // CHILD should be from Datastore.pathDirection
    };

    const path = '/query/';
    const { err, data } = (await ApiGateway.post(path, queryBody)) as ErrData<ValidBusinessObjectList>;

    if (err == null && data != null) {
      let communityCurrentStage: DMPStage | undefined = undefined;
      const dmp = data.find((item) => item.type === 'DroughtManagementPlan') as undefined | ValidBusinessObject;
      console.log(dmp);
      if (dmp?.stages) {
        communityCurrentStage = dmp.stages.find((stage: DMPStage) => stage.stageNumber === communityObject.stageNumber);
      }

      console.log(communityCurrentStage);
      // const planStages = data.filter((stage : ValidBusinessObject) => stage.type === 'DroughtManagementPlanStage')
      // const communityCurrentStage = planStages.find((stage : ValidBusinessObject)  => stage.stageNumber === communityObject.stageNumber)
      setCurrentPlanStage(communityCurrentStage);

      if (dmp?.waterDeliveryLeadTime) {
        setWaterLeadTime(dmp.waterDeliveryLeadTime);
      }
    }
  };

  /**
   * Fetches weather data for current community from its position
   */
  const fetchWeather = (community: Community) => {
    // const communityPosition = getLocation()
    const dailyWeatherReport: DailyWeatherReport[] = [];

    const weatherData = community.weather_data;

    if (weatherData) {
      const dailyWeather = weatherData.daily;
      // const dailyWeatherReport : DailyWeatherReport[] = []

      dailyWeather?.forEach((day: any) => {
        const date = new Date(day.timestamp * 1000);
        let currentDay: DailyWeatherReport = {
          day: '',
          weatherIcon: '',
        };

        switch (date.getDay()) {
          case 0:
            currentDay.day = 'Sun';
            break;
          case 1:
            currentDay.day = 'Mon';
            break;
          case 2:
            currentDay.day = 'Tues';
            break;
          case 3:
            currentDay.day = 'Weds';
            break;
          case 4:
            currentDay.day = 'Thurs';
            break;
          case 5:
            currentDay.day = 'Fri';
            break;
          case 6:
            currentDay.day = 'Sat';
            break;
        }

        currentDay.weatherIcon = day.weather.icon;
        dailyWeatherReport.push(currentDay);
      });
    }

    setCommunityWeather([...dailyWeatherReport]);
  };

  const getLocation = () => {
    const location = getAverageTankGeoLocation(communityTanks);
    console.log(location);

    return location;
  };

  /**
   * Calculates the number of tank users for all of the tanks in the community
   * @returns A number representing the total number of tank users in the community
   */
  const numTankUsers = () => {
    const numTankUsers = communityTanks.reduce((acc: number, curr: Tank) => {
      return (acc += curr.no_of_users);
    }, 0);
    return numTankUsers;
  };

  const calculateForecastCollection = () => {
    const forecastCollection = communityTanks.reduce((acc: number, curr: Tank) => {
      if (curr.forecastCollectionInSevenDays) {
        return (acc += curr.forecastCollectionInSevenDays);
      }
      return acc;
    }, 0);
    return forecastCollection;
  };

  /**
   * Calculates the current volume for a given tank type
   * @param tankType The tank type (Community or Household)
   * @returns
   */
  const calculateTankTypeVolume = (tankType: string, tankList: ValidBusinessObject[], communityVolume: number) => {
    let totalVolume = 0;

    const tanks = tankList.filter((tank) => tank.tank_type === tankType);

    console.log(tanks);

    const currentFillVolume = tanks.reduce((acc, curr) => {
      const currentVol = curr.current_filled_volume ? curr.current_filled_volume : 0;

      return (acc += currentVol);
    }, 0);

    return (currentFillVolume / communityVolume) * 100;
  };

  var color_of_silo = theme.palette.primary.main;
  const circleColor = theme.palette.warning.main;

  const gradientStops = [
    {
      key: '0%',
      stopColor: color(color_of_silo).darker(0.5).toString(),
      stopOpacity: 1,
      offset: '0%',
    },
    {
      key: '50%',
      stopColor: color_of_silo,
      stopOpacity: 0.75,
      offset: '50%',
    },
    {
      key: '100%',
      stopColor: color(color_of_silo).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: '100%',
    },
  ];

  const gaugeGridRef = useRef(null);
  const { width: gaugeGridWidth, height: gaugeGridHeight } = useContainerDimensions(gaugeGridRef);
  const radius = (gaugeGridWidth / 2) * 0.9;

  const viewObjects = [
    {
      matchedPrimary: community,
      matchedRelatedByPk: [],
      matchedRelatedBySk: communityTanks,
    },
  ];

  const getRunOutDate = () => {
    const community_days_left = community?.community_days_left;

    const currentDate = new Date();
    const runOutDate = new Date(currentDate);
    runOutDate.setDate(runOutDate.getDate() + community_days_left);

    return runOutDate.toLocaleDateString();
  };

  const getLastDayForWaterReplacement = () => {
    const lastDate = new Date(getRunOutDate());
    lastDate.setDate(lastDate.getDate() + waterLeadTime);

    return lastDate.toLocaleDateString();
  };

  return (
    <div>
      <Grid container spacing={2} className={styles.container}>
        <Grid item xs={12}>
          <Card className={styles.header}>
            <Typography variant='h3'>{community?.name ? community.name : 'Name undefined'}</Typography>
          </Card>
        </Grid>
        <Grid item xs={7} className={styles.whole}>
          <Card className={styles.half}>
            <Grid item container xs={12} className={styles.smallPadding}>
              <Grid item container xs={4}>
                <Grid item {...{ xs: 12, ref: gaugeGridRef }} alignItems='center' style={{ marginBottom: '10px' }}>
                  <LiquidFillGauge
                    style={{ margin: '0 auto' }}
                    width={radius * 2.5}
                    height={radius * 2.5}
                    value={community?.current_filled_factor * 100}
                    percent='%'
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    textRenderer={(props: any) => {
                      const value = Math.round(props.value);
                      const radius = Math.min(props.height / 2, props.width / 2);
                      const textPixels = (props.textSize * radius) / 2;
                      const valueStyle = {
                        fontSize: textPixels,
                      };
                      const percentStyle = {
                        fontSize: textPixels * 0.6,
                      };

                      return (
                        <tspan>
                          <tspan className='value' style={valueStyle}>
                            {value}
                          </tspan>
                          <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                      );
                    }}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: circleColor,
                    }}
                    waveStyle={{
                      fill: color_of_silo,
                    }}
                    textStyle={{
                      fill: color('#444').toString(),
                      fontFamily: 'Arial',
                    }}
                    waveTextStyle={{
                      fill: color('#262626').toString(),
                      fontFamily: 'Arial',
                    }}
                  />
                  <Typography variant='h6' align='center'>
                    All Tanks
                  </Typography>
                </Grid>
                <Grid item {...{ xs: 6, ref: gaugeGridRef }} alignItems='center'>
                  <LiquidFillGauge
                    style={{ margin: '0 auto' }}
                    width={radius * 1.75}
                    height={radius * 1.75}
                    value={householdTankVolume}
                    percent='%'
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    textRenderer={(props: any) => {
                      const value = Math.round(props.value);
                      const radius = Math.min(props.height / 2, props.width / 2);
                      const textPixels = (props.textSize * radius) / 2;
                      const valueStyle = {
                        fontSize: textPixels,
                      };
                      const percentStyle = {
                        fontSize: textPixels * 0.6,
                      };

                      return (
                        <tspan>
                          <tspan className='value' style={valueStyle}>
                            {value}
                          </tspan>
                          <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                      );
                    }}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: circleColor,
                    }}
                    waveStyle={{
                      fill: color_of_silo,
                    }}
                    textStyle={{
                      fill: color('#444').toString(),
                      fontFamily: 'Arial',
                    }}
                    waveTextStyle={{
                      fill: color('#262626').toString(),
                      fontFamily: 'Arial',
                    }}
                  />
                  <Typography variant='subtitle1' align='center'>
                    Household tanks
                  </Typography>
                </Grid>
                <Grid item {...{ xs: 6, ref: gaugeGridRef }} alignItems='center'>
                  <LiquidFillGauge
                    style={{ margin: '0 auto' }}
                    width={radius * 1.75}
                    height={radius * 1.75}
                    value={communityTankVolume}
                    percent='%'
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    textRenderer={(props: any) => {
                      const value = Math.round(props.value);
                      const radius = Math.min(props.height / 2, props.width / 2);
                      const textPixels = (props.textSize * radius) / 2;
                      const valueStyle = {
                        fontSize: textPixels,
                      };
                      const percentStyle = {
                        fontSize: textPixels * 0.6,
                      };

                      return (
                        <tspan>
                          <tspan className='value' style={valueStyle}>
                            {value}
                          </tspan>
                          <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                      );
                    }}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: circleColor,
                    }}
                    waveStyle={{
                      fill: color_of_silo,
                    }}
                    textStyle={{
                      fill: color('#444').toString(),
                      fontFamily: 'Arial',
                    }}
                    waveTextStyle={{
                      fill: color('#262626').toString(),
                      fontFamily: 'Arial',
                    }}
                  />
                  <Typography variant='subtitle1' align='center'>
                    Community tanks
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={8}>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    Current Volume
                    <Typography>{community?.current_filled_volume ? Math.round(community.current_filled_volume) : 0} L</Typography>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    Volume Used Yesterday
                    <Typography>{community?.volume_used_yesterday ? Math.round(community.volume_used_yesterday) : 0} L</Typography>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    <div>Tanks In Community : {communityTanks.length}</div>
                    <div>Water Users in Community : {numTankUsers()}</div>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    Days Remaining
                    <Typography>{community?.community_days_left}</Typography>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    Run Out Date
                    <Typography>{getRunOutDate()}</Typography>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card className={styles.card}>
                    Forecast Collection in next 7 days
                    <Typography>{Math.round(calculateForecastCollection())} L</Typography>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={styles.card}>
                    Predicted Weather next 7 days
                    <Box display='flex' flexWrap='nowrap' bgcolor='background.paper' className={styles.weatherForecast}>
                      {communityWeather &&
                        (communityWeather.length > 0 ? (
                          communityWeather.map((forecast) => (
                            <Box bgcolor='grey.300' className={styles.weatherIcon}>
                              <img src={forecast.weatherIcon} />
                              <br />
                              {forecast.day}
                            </Box>
                          ))
                        ) : (
                          <Typography variant='h6' align='center'>
                            Weather data unavailable
                          </Typography>
                        ))}
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent='center'>
              <Grid item xs={5} className={styles.buttonContainer} container justifyContent='flex-end'>
                <Button className={styles.bottomButtons} variant='contained' color='primary' onClick={() => history.push(`${location.pathname}/tanks`)}>
                  See Tanks In Community
                </Button>
              </Grid>
              <Grid item container xs={5} className={styles.buttonContainer} justifyContent='center'>
                <Button className={styles.bottomButtons} variant='contained' color='primary' onClick={() => history.push(`${location.pathname}/report`)}>
                  See Drought Management Report
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box className={styles.header}>
                <Typography variant='h5' component='h5'>
                  Tanks In Community
                </Typography>
              </Box>
              <Box className={styles.mapC021TankGroupCard}>{communityTanks && <MapView {...{ position: undefined, editable: false, viewObjects }} />}</Box>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={5} className={styles.whole}>
          <Card className={styles.half}>
            <Grid item container xs={12} className={styles.smallPadding} spacing={2}>
              <Card className={styles.droughtManagmentPlanCard}>
                {
                  //Renders Drought management plan info if community has been assigned one
                  currentPlanStage ? (
                    <Grid item container xs={12} className={styles.smallPadding} spacing={2}>
                      <Grid item xs={6} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant='h6' component='h6'>
                          Drought Management Stage:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Card className={styles.smallPadding} style={{ textAlign: 'center' }}>
                          {console.log(currentPlanStage?.stageNumber)}
                          <Typography variant='h6' component='h6'>
                            Stage {currentPlanStage?.stageNumber}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card className={styles.smallPadding}>
                          <Typography variant='h6' component='h6'>
                            Date Entered Stage :
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card className={styles.smallPadding}>
                          <Typography variant='h6' component='h6'>
                            Triggered By :
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card className={styles.smallPadding}>
                          <Typography variant='subtitle1'>
                            Based on current run out date, water replacement will need to be secured by : {getLastDayForWaterReplacement()}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <Table>
                            <TableRow>
                              <TableCell>Stage Goals</TableCell>
                              <TableCell>Goal</TableCell>
                              <TableCell>Tracking</TableCell>
                            </TableRow>
                            {currentPlanStage.goals.map((goal) => {
                              return (
                                <TableRow>
                                  <TableCell>{goal.goal}</TableCell>
                                  <TableCell>{goal.metric}L</TableCell>
                                  <TableCell>
                                    {() => {
                                      switch (goal.goal) {
                                        case 'Reduce Daily Usage':
                                          return;
                                        case 'Reduce Weekly Usage':
                                          return;
                                        case 'Reduce Daily Usage Per occupant':
                                          return;
                                      }
                                    }}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </Table>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    //If no Drought Management plan assigned. Render card saying there is no plan assigned
                    <Grid item xs={12} className={styles.smallPadding}>
                      <Typography variant='h4' align='center'>
                        No Drought Management Plan Assigned
                      </Typography>
                      <Button variant='contained' color='primary' onClick={() => history.push(`/DROUGHTMANAGEMENTPLANVIEW`)}>
                        Assign Drought Management Plan
                      </Button>
                    </Grid>
                  )
                }
              </Card>

              <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                  Community Fill Level
                </Typography>
                <CommunityFillLevelGraph />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                  Community Daily Usage
                </Typography>
                <CommunityDailyUsageGraph />
              </Grid>
              {/* <Grid item xs={12}>
                                    <Typography variant='h6' component='h6'>
                                        Communications
                                    </Typography>
                                    <Card>

                                    <Table>
                                        <TableRow>
                                            <TableCell>Date Sent</TableCell>
                                            <TableCell>Sent to</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>04/03/2021</TableCell>
                                            <TableCell>Tank Owners</TableCell>
                                            <TableCell>Please stop watering gardens</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>04/03/2021</TableCell>
                                            <TableCell>Community Water Managers</TableCell>
                                            <TableCell>Te Kao has triggered Stage 2 Drought Management Response</TableCell>
                                        </TableRow>
                                    </Table>
                                    </Card>
                                </Grid>  */}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
