import { AdjacentType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AppDao from '../../../../../../../data/AppDao';
import RecipientModal from '../../../../Recipient/RecipientModal';
import { InternalManagement } from '../../DMPExtrasV2';
import { getCustomerRecipiants, recipientsLabel } from '../RecipientsSupport';
import styles from './DroughtModal.module.css';

const modalRoot = document.querySelector('#modal-root');

interface internalMessagesModalProp {
  close: () => void;
  contextCustomer: ValidBusinessObject;
  update: (input: InternalManagement | undefined, position: number | undefined, sendout: boolean | undefined) => void;
  index: number | undefined;
  currentactive: boolean;
  existing: InternalManagement[];
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

export const renderIdList = (idList: string[], choice: ValidBusinessObjectList): string => {
  let objList: string[] = idList.map((id) => {
    return choice.find((option) => option.id === id)?.name;
  });
  return objList.filter((elm) => elm).join(', ');
};

const InternalMessagesModal: React.FC<internalMessagesModalProp> = (props: internalMessagesModalProp) => {
  let { close, contextCustomer, update, index, currentactive, existing, setSource } = props;

  let [valid, setValid] = useState<boolean>(false);
  let [recipientModal, setRecipientModal] = useState<boolean>(false);
  let [recipients, setRecipients] = useState<ValidBusinessObjectList>();
  let [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);

  let [internalMessages, setInternalMessages] = useState<string>('');

  console.log("findactivestage", props)

  useEffect(() => {
    if (index !== undefined && existing.length > index && recipients !== undefined) {
      let source = existing[index];
      setInternalMessages(source.message);
      setSelectedRecipients(source.recipientIDs);
    }
  }, [index, existing, recipients]);

  useEffect(() => {
    if (contextCustomer) {
      updateRecipients();
    }
  }, [contextCustomer]);

  useEffect(() => {
    setValid(selectedRecipients.length > 0 && internalMessages !== '');
  }, [selectedRecipients, internalMessages]);

  const updateRecipients = async () => {
    setRecipients(await getCustomerRecipiants(contextCustomer));
  };

  const updateRecipiantList = async (customer: ValidBusinessObject) => {
    const res = await AppDao.queryByGraph({
      contextObjects: [customer],
      path: [{ objectTypeId: 'Recipient', adjacencyType: AdjacentType.CHILD }],
    });
    if (res.err === null && res.data !== null) {
      setRecipients((res.data.getChildNodes(customer) as ValidBusinessObjectList) || []);
    }
  };

  const create = (recipientIDs: string[], message: string, pos: number | undefined, sendout: boolean | undefined) => {
    update({ recipientIDs, message }, pos, sendout);
  };

  const updateList = async () => {
    updateRecipiantList(contextCustomer);
    updateRecipients();
    cancelAddRecipient();
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos, false);
    close();
  };

  const addRecipient = useCallback(() => {
    setRecipientModal(true);
  }, []);

  const cancelAddRecipient = useCallback(() => {
    setRecipientModal(false);
    setInternalMessages('');
    setSelectedRecipients([]);
  }, []);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }
  console.log("checkboolean", valid, currentactive, !(valid && currentactive))
  return ReactDOM.createPortal(
    <>
      <div className={styles.modalContainer}>
        <div className={styles.form}>
          <Card>
            <Box m={2}>
              <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
                <Typography variant='h5' component='h5'>
                  Create Community Engagement Messages
                </Typography>
                <div className={styles.full}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id='recipientLabel'>{recipientsLabel(recipients)}</InputLabel>

                      <Select
                        multiple
                        fullWidth
                        labelId={recipients === undefined ? 'Loading' : recipients.length === 0 ? 'No Recipients' : 'recipientLabel'}
                        // disabled={!(recipients.length > 0)}
                        disabled={recipients === undefined || recipients.length === 0}
                        input={<OutlinedInput label={recipientsLabel(recipients)} />}
                        onChange={(e) => {
                          let choice = e.target.value;
                          if (typeof choice === 'string') {
                            setSelectedRecipients([choice]);
                          } else {
                            setSelectedRecipients(choice);
                          }
                        }}
                        value={selectedRecipients || []}
                        size='small'
                        renderValue={() => <>{recipients && renderIdList(selectedRecipients, recipients)}</>}
                      >
                        {recipients?.map((contact) => (
                          <MenuItem key={contact.id} value={contact.id}>
                            <Checkbox checked={selectedRecipients.includes(contact.id)} />
                            <ListItemText primary={contact.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button fullWidth variant='contained' onClick={addRecipient}>
                      <Add /> Recipients
                    </Button>
                  </Stack>
                </div>
                <TextField
                  label={`Internal Management Message`}
                  value={internalMessages}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  minRows={9}
                  multiline
                  fullWidth
                  style={{ width: '100%' }}
                  onChange={(e) => setInternalMessages(e.target.value)}
                />
              </Stack>
            </Box>
            <Box m={2}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Button
                  variant='contained'
                  color='error'
                  disabled={index === undefined}
                  onClick={() => {
                    index !== undefined && deleteItem(index);
                  }}
                >
                  Delete
                </Button>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                  <Button variant='contained' onClick={close}>
                    Cancel
                  </Button>
                  <Button variant='contained' disabled={!valid} onClick={() => create(selectedRecipients, internalMessages, index, false)}>
                    {index === undefined ? 'Create' : 'Update'}
                  </Button>
                  <Button variant='contained' disabled={!(valid && currentactive)} title="Must active stage to send" onClick={() => create(selectedRecipients, internalMessages, index, true)}>
                    {currentactive ? 'Send' : 'Need active stage to send'}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Card>
        </div>
      </div>
      {recipientModal && <RecipientModal close={cancelAddRecipient} parent={contextCustomer} update={updateList} />}
    </>,
    modalRoot
  );
};

export default InternalMessagesModal;
