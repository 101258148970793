import { Tank, ThingShadowState, ValidBusinessObject } from '@iotv/datamodel';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
// import Avatar from '@material-ui/core/Typography';
// import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
// import { getValueLabel } from '../../data/TypeHelpers';
import Typography from '@material-ui/core/Typography';
import React from 'react';
// import { extractGraphData, extractGraphDataFromTss } from '../../data/TypeHelpers';
import config from '../../../../config';
import styles from '../../../../cosmetics/sharedCardStyles';
// import { extractGeoDataFromViewObject } from '../../util/geo/GeoDataHelpers'
import { LineChartXType } from '../../../../types/AppTypes';
import { RangeableLineChart } from '../charts/RangeableLineGraph';
const googleChartUrl = config.google.chartApiUri;
const debug = process.env.REACT_APP_DEBUG && false;

type TankFillGraphCardProps = {
  matchedPrimary: ThingShadowState;
  filterGraphKeys?: string[]
}
const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "100%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  // paper: {
  //   // padding:  spacing(2),
  //   textAlign: 'center',
  //   // color: palette.text.secondary,
  // }, 
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end'
  }
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);


export default function GroupTankFillGraphCard(props: TankFillGraphCardProps | any) {
  const [viewSelector, setViewSelector] = React.useState('litres');
  const classes = useStyles();
  const [duration, setDuration] = React.useState(7)
  // const getFormattedData = (graphArray: any) => {
  //   let formattedData: any = []
  //   console.log('getFormattedDatagetFormattedDatagetFormattedDatagetFormattedDatagetFormattedData')
  //   graphArray.forEach((item: any) => {
  //     item.current_filled_volume = (typeof item.current_filled_volume == 'number') ? parseInt(item.current_filled_volume.toFixed(2)) : parseInt(item.current_filled_volume)
  //     formattedData.push(item)
  //   });
  //   return formattedData

  // }
  //? const [historyObject, setHistoryObject] = React.useState<HistoryObjectType>(extractGraphDataFromTss(getFormattedData(props?.viewObject?.matchedPrimary.VolumeInPeriod), duration))
  const { viewObject, filterGraphKeys, transactionFunctions, userFunctions, searchParams, history, optionals } = props;
  debug && console.log('TankFillGraphCard props', props)

  const handleViewSelector = () => setViewSelector(viewSelector === 'litres' ? 'percentage' : 'litres')

  // const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst } = extractGeoDataFromViewObject(viewObject);
  const matchedPrimary = props?.viewObject?.matchedPrimary as ValidBusinessObject & Tank;
  const totalVolume = matchedPrimary.total_volume 
  const historyObject: HistoryObjectType = props?.viewObject?.matchedPrimary?.dailyStateHistory;

  React.useEffect(() => {
    // console.log('trsting testing')
    // setHistoryObject(extractGraphDataFromTss(getFormattedData(tss.VolumeInPeriod)))
    //? setHistoryObject(extractGraphDataFromTss(getFormattedData(props?.viewObject?.matchedPrimary.VolumeInPeriod), duration))
    // const historyObject: HistoryObjectType = extractGraphDataFromTss(getFormattedData(tss.VolumeInPeriod));
  }, [duration, props?.viewObject?.matchedPrimary.VolumeInPeriod])

  // const historyObject = new HistoryStore();
  // VolumeInPeriod.forEach( ( vip ) => {
  //   const { timestamp, ...state } = vip
  //   historyObject.push( {timestamp: timestamp * 1000, state} )
  // } )

  const getFormattedData = async (graphArray: any) => {
    const formattedGroupCFV: any = []
    const formattedGroupCFVPercentage: any = []
    const actualHistoryObject = props?.viewObject?.matchedPrimary?.dailyStateHistory;
    let formatHistoryObject = graphArray 
    if (graphArray.current_filled_volume.length > 0) {
      graphArray.current_filled_volume.forEach((item: any) => {
        if (viewSelector === 'percentage') {
          let cfvPercentage = (typeof item == 'number') ? parseInt(((item / totalVolume) * 100).toFixed(2)) : parseInt(((item / totalVolume) * 100).toFixed(2))
          formattedGroupCFVPercentage.push(cfvPercentage)
        } else {
          let cfv = (typeof item == 'number') ? parseInt(item.toFixed(2)) : parseInt(item.toFixed(2))
          formattedGroupCFV.push(cfv)
        }
      });
    }
    // formatHistoryObject.formattedCFV = formattedGroupCFV
    // formatHistoryObject.formattedCFVPercentage = formattedCFVPercentage

    formatHistoryObject.formattedGroupCFV = formattedGroupCFV
    formatHistoryObject.formattedGroupCFVPercentage = formattedGroupCFVPercentage
    //}
  debug &&  console.log({ formattedGroupCFV, formatHistoryObject, graphArray, historyObject })
    return formatHistoryObject
  }

  const googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`)

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date()
    fromDate.setDate(fromDate.getDate() - duration);
    const unixFromDate = (fromDate.valueOf() / 1000) * 1000
    const maxIdx = historyObject.index.length - 1  
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate)
    lowIdx = lowIdx < 0 ? 0 : lowIdx
    const formattedHistoryObject = getFormattedData(historyObject)
    const wholeKeys = Object.keys(historyObject);
    // if (graphKey){ wholeKeys.push(graphKey)}
    const keys = wholeKeys.filter((key) => key === graphKey)
    const xType: LineChartXType = LineChartXType.TIMESERIES
    const rows = keys.map((key) => {
      return historyObject[key] &&
        <RangeableLineChart {...{ historyObject, xType, classes, keys: [key], lowIdx, maxIdx }}></RangeableLineChart>
      // <RangeableLineChart {...{ historyObject :formattedHistoryObject, xType, classes, keys: [key] }}></RangeableLineChart>
    }).filter((element) => element)

    return rows;

  }
  const handlerDurationChange = (event: any) => setDuration(parseInt(event.target.value)) 

  return (
    <div>
      {viewSelector === 'percentage' ? ( 
        historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'formattedGroupCFVPercentage')
      ) : ( 
          historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'formattedGroupCFV')
        )}
 
      <div className={classes.bottomCard}>
         <Grid container
          // direction="column"
        >
          {/* <Grid item xs={12} > */}
          <Grid item xs={6} >
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>(%)</Grid>
                  <Grid item>
                    <AntSwitch checked={viewSelector === 'litres' ? true : false} onChange={handleViewSelector} name="checkedC" />
                  </Grid>
                  <Grid item>(L)</Grid>
                </Grid>
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12}> */}
          <Grid item xs={6}>
            {/* <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                  <Button variant="contained" onClick={() => { setDuration(7); console.log('clicked 7') }} >7 Days</Button>
                  <Button variant="contained" style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => { setDuration(30); console.log('clicked One month') }} >1 Month</Button>
                  <Button variant="contained" onClick={() => { setDuration(365); console.log('clicked One year') }} >1 Year</Button>

                </Box> */}
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <RadioGroup row aria-label="position" name="position"
                // defaultValue="top"
                defaultValue="top"
                value={duration}
                onChange={handlerDurationChange}
              // onChange={(value)=>console.log('event radio', value)}
              >

                <FormControlLabel
                  value={7}
                  control={<Radio color="primary" />}
                  label="7 days"
                  // labelPlacement="bottom"
                  labelPlacement="end"
                  // checked={duration ===7? true:false}
                />
                <FormControlLabel
                  value={30}
                  control={<Radio color="primary" />}
                  label="1 month"
                  // labelPlacement="bottom"
                  labelPlacement="end"
                //  checked={duration === 30 ? true : false}
                />
                <FormControlLabel
                  value={365}
                  control={<Radio color="primary" />}
                  label="1 year"
                  // labelPlacement="bottom"
                  labelPlacement="end"
                //  checked={duration === 365 ? true : false}
                />
              </RadioGroup>
            </Box>
            {/* <FormControlLabel value="end" control={<Radio color="primary" />} label="End" /> */}
          </Grid>
        </Grid>
        {/* </CardContent>
        </Card> */}
      </div>
 
 
    </div>
  );
}
