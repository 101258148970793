import { Position } from "@iotv/datamodel"
import { S2Cell, S2CellId, S2CellUnion, S2LatLng, S2RegionCoverer } from "nodes2ts";

export const googleBoundsToCellLatLngs = ( bounds: google.maps.LatLngBounds | undefined | null ): { ne: Position, sw: Position } | undefined => {
    let latLngs:  { ne: Position, sw: Position } | undefined = undefined;
    if (bounds) {
        latLngs = {
            ne: { 
                lat: bounds.getNorthEast().lat(), 
                lng: bounds.getNorthEast().lng(), 
               },
               sw: { 
                lat: bounds.getSouthWest().lat(), 
                lng: bounds.getSouthWest().lng(), 
               } 
            }
        }
        return latLngs
    }

        





export type  BoundsCellLatLngsToCoveringCellsParama = {
    boundsLatLng:  { ne: Position, sw: Position },
    maxLevel: number, 
    maxCells: number
}

export const bounsdCellLatLngsToCoveringCells =  ( { boundsLatLng, maxCells, maxLevel }: BoundsCellLatLngsToCoveringCellsParama) => {

    const s2LatLngNE = S2LatLng.fromDegrees( boundsLatLng.ne.lat, boundsLatLng.ne.lng);
    const cell1 = S2Cell.fromLatLng(s2LatLngNE);

    const s2LatLng2SW= S2LatLng.fromDegrees( boundsLatLng.sw.lat, boundsLatLng.sw.lng);
    const cell2 = S2Cell.fromLatLng(s2LatLng2SW);
   
    const s2Cells = [ cell1, cell2 ]
    return shapeCellIdsToCovererCellIds( { s2Cells, maxCells, maxLevel } )

};

export type GeoShapeCoveringCellsParams = {
    s2Cells: S2Cell[],
    maxLevel: number,
    maxCells: number
}

export const shapeCellIdsToCovererCellIds = ({ s2Cells, maxCells, maxLevel }: GeoShapeCoveringCellsParams): S2CellId[] => {
    const cellIds = s2Cells.map( (cell) => cell.id.id) 
    const union = new S2CellUnion()
    union.initFromIds(cellIds)
    const bounds = union.getRectBound();

    const unionCoverer = new S2RegionCoverer();
  
    unionCoverer.setMaxCells(maxCells)
    unionCoverer.setMaxLevel(maxLevel)
  
    const covererUnion =  unionCoverer.getCoveringUnion(bounds)

    //const unionCovererGeoJson = covererUnion.getCellIds().map( (cellId) => new S2Cell(cellId).toGEOJSON() )

    return covererUnion.getCellIds()
}