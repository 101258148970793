import { MapLike } from '@iotv/iotv-v3-types';
import { Avatar, Button, Container, CssBaseline, IconButton, InputAdornment, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { WithStyles } from '@material-ui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { UserView } from 'src/types/AppTypes';
import { NavSections } from '../navigation/NavSections';
import { styles } from './loginStyles';
const debug = process.env.REACT_APP_DEBUG && false;

type NotTypedInAmplifyCodeSendSuccessMessage = {
    CodeDeliveryDetails?:
    { AttributeName: "email", DeliveryMedium: "EMAIL", Destination: string }
}

enum NewPasswordStages {
    ASK_USER_TO_ENTER_NEW_PASSWORD,
    DONE
}



// Collect confirmation code and new password, then
const completeNewPassword = async (username: string, new_password: string, setMessage: (err: string | undefined) => void, setError: (err: string | undefined) => void, setResetStage: (stage: NewPasswordStages) => void, history: ReturnType<typeof useHistory>) => {
   
   /*
    try {
        const res: CognitoUser = await Auth.completeNewPassword(username,  new_password)
        console.log('completeNewPassword res', res)
        if (res ) {
            setMessage(`${res.getUsername()}, Your password has been changed`)

            setTimeout(() => history.push(`/${NavSections.THINGSVIEW}/FUCK`), 1500)
        } else {
            setError('Something unusual has happened')
            setResetStage(NewPasswordStages.DONE)
        }
    } catch (e: any) {
        console.log('Err in requestConfirmationCode', e.message)
        let errMsgMap: MapLike<string> = {
            default: `Sorry, that's not a password which matches can used in this system. Please try again`,
            'Invalid verification code provided, please try again.': 'Invalid verification code provided, please try again.',
            'Password does not conform to policy: Password must have uppercase characters': 'Password does not conform to policy: Password must have uppercase characters'
        }
        const [_, matchedError] = Object.entries(errMsgMap).find(([errMsg, ourMsg]) => (e.message as string || '').includes(errMsg)) ?? ['none', errMsgMap.default]
        setError(matchedError)
    }
    */
}

const isValidPassword = (password: string) => {
    const cognitoPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&/,><\\’:;|_~`])\S{6,99}$/
    const regex = new RegExp(cognitoPasswordRegex)
    return regex.test(password)
}

const isValidUsername = (username: string) => {
    const regex = new RegExp(/\S{4}/)
    return regex.test(username)
}

const redirect = `/${NavSections.LOGIN}`

export type NewPasswordProps = {
    isAuthenticated: boolean,
} & WithStyles<typeof styles> & { cognitoUser: CognitoUser | undefined,  setNewPassword: (user: CognitoUser, password: string) => Promise<void>}

const NewPassword = ({ isAuthenticated, classes, cognitoUser, setNewPassword }: NewPasswordProps) => {
    const history = useHistory()
    const [username, setUsername] = React.useState<string | undefined>(cognitoUser?.getUsername())
    const [password, setPassword] = React.useState<string | undefined>(undefined)
    const [email, setEmail] = React.useState<string | undefined>(undefined)
    const [message, setMessage] = React.useState<string | undefined>(undefined)
    const [error, setError] = React.useState<string | undefined>(undefined)
    const [resetStage, setResetStage] = React.useState<NewPasswordStages>(NewPasswordStages.ASK_USER_TO_ENTER_NEW_PASSWORD)
    const [showPassword, setShowPassword] = React.useState<boolean>(true);

    console.log('New Password', { cognitoUser, username })

    const renderResetStage = () => {
        switch (resetStage) {
            case NewPasswordStages.ASK_USER_TO_ENTER_NEW_PASSWORD: return renderNewPasswordForm();

        }
    }

    

    const renderNewPasswordForm = () => <form className={classes.form} noValidate>
        <TextField {...{
            key: 'username', variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "code", value: username,
            label: "Username", name: "username", contentEditable: false, autoFocus: false, disabled: true
            

        }} />

        <TextField {...{
            key: 'password', variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "password", type: showPassword ? "text" : "password", autoFocus: true,
            label: "New Password", name: "code", helperText: (password && !isValidPassword(password)) ? 'Must contain upper, lower case and special characters' : `Please enter your new password,`,
            onChange: (e) => setPassword(e.target.value),
            error: (!!password && !isValidPassword(password)),
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton {...{
                            'aria-label': "toggle password visibility",
                            onClick: () => setShowPassword(!showPassword),

                        }}

                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }
        }} />

        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="info">{message}</Alert>}
        <Button {...{
            type: "submit", disabled: !username || !isValidUsername(username) || !password || !isValidPassword(password),
            fullWidth: true, variant: "contained", color: "primary", className: classes.submit,
            onClick: (e: React.MouseEvent) => {
                e.preventDefault()
                if (cognitoUser && password) {
                    //completeNewPassword(username, password, setMessage, setError, setResetStage, history)
                    setNewPassword(cognitoUser, password)
                    setError(undefined)
                    setMessage(undefined)
                }
            }
        }}>
            Submit
        </Button>
    </form>

    return (isAuthenticated  && redirect)  ?
        <Redirect to={{ pathname: redirect }}></Redirect>
        :
        <Paper className={clsx(classes.paper)} >
            <div >
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Set New Password
                        </Typography>
                        {
                            renderResetStage()
                        }
                        <Link href="#"
                            to={`/${NavSections.LOGIN}`}
                        >
                            {"Back to login"}
                        </Link>
                    </div>
                </Container>
            </div>
        </Paper>
}

export default withStyles(styles)(NewPassword)