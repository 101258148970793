import { ErrData, PathDirection, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { getAdjacent } from '../../../../data/daoFunctions/daoFunctions';

export const getCustomerTanks = async (customers: ValidBusinessObjectList) => {
  let res: ErrData<ValidBusinessObjectList> = { err: null, data: null };

  // return needs to be an edge, except edge needs the tank_type value which has since been removed
  let tankListList = await customers.map(async (customer) => {
    let tanks = await getAdjacent(customer, 'Tank', PathDirection.child);
    if (tanks.err === null && tanks.data !== null) {
      return tanks.data?.map((obj) => {
        return { ...obj, pk: customer.pk };
      });
    } else {
      return [];
    }
  });

  let promiseRes = await (await Promise.all(tankListList)).flat();

  return { ...res, data: promiseRes };
};
