import { Input, Slider, TableCell, TableRow } from "@material-ui/core";
import { camelToDisplay } from "@iotv/datamodel-core";
import { CommonRowPropsGeneric } from "./types";


const debug = process.env.REACT_APP_DEBUG && false;

export type SimpleNumberRowGenericProps<T> = CommonRowPropsGeneric<T> & {
    v: number, currentValue: number, reportedValue: number | undefined
        , min: number, marks: boolean, max: number, step: number | null
}

export function SimpleNumberRowGeneric<T>({ payloadAttribute, setPayloadAttribute, setHasEdits, k, v, reportedValue, currentValue, limits, min, max, marks, step, keyWidth, inputWidth, i }: SimpleNumberRowGenericProps<T>): JSX.Element {
    const handleNumberChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, k: keyof T) => {
        const value = parseInt(e.target.value);
        const { attributeType, range: { min, max } } = limits

        debug && console.log(limits)
        if (value >= min && value <= max) {
            setPayloadAttribute(value, k )
        } else {
           debug && console.log('do something with err')
        }

    }

    return <TableRow {...{ key: `r_${i}` }}>
        <TableCell {...{ key: `key_${String(k)}`, style: { width: keyWidth } }}>{camelToDisplay(k as string)}</TableCell>
        <TableCell {...{ key: `reported`, style: { width: inputWidth } }}>{reportedValue}</TableCell>
        <TableCell {...{ key: `val`, style: { width: inputWidth, color: v === reportedValue ? 'inherit' : 'red'} }}>{v}</TableCell>
        <TableCell {...{ key: `input`, style: { width: inputWidth } }}>
            <Input {...{
                type: 'number', style: { width: inputWidth },
                value: payloadAttribute?.[k as keyof T] ?? 0,
                onChange: (e) => { setHasEdits(true); handleNumberChange(e, k) }
            }} />
        </TableCell>
        <TableCell {...{ key: `slider`, style: { width: inputWidth } }}>
            <Slider {...{
                value: currentValue ?? min,
                marks, min, max, step, color: v === reportedValue ? 'primary' : 'secondary',
                getAriaValueText: (v, i) => v.toString(),
                'aria-labelledby': "discrete-slider",
                valueLabelDisplay: "auto",
                onChangeCommitted: (e, sVal: number | number[]) => { e.preventDefault(); e.stopPropagation(); sVal = sVal instanceof Array ? sVal[0] : sVal; setHasEdits(true); setPayloadAttribute( sVal, k ); }
            }} />
        </TableCell>

    </TableRow>;
}
