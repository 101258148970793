import { BusinessObjectAssembler, CustomerAccountType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AddressType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import config from '../../../config';
import styles from '../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { AdjacentType, CustomerManagementViewProps, ListerlizerProps, MessageOutputType, UserTransactionType, UserView, ViewDefinition, ViewObject } from '../../../types/AppTypes';
import { Listerlizer } from '../../factories/Listilizer';
import { NavSections } from '../../navigation/NavSections';


const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';

const assembler = new BusinessObjectAssembler();


const customerTemplate = { type: 'Customer' }
const devcustomerTemplate = { type: 'Customer', name: 'Barton Stacey Gun Store', email: 'barton.stacey@loraiotlabs.com', accountType: 'X', phoneNumber: '021111111' }

const listCustomersQuery = async (fn: (params: any) => void, contextUser: UserView) => {
  if (contextUser) {
    try {
      debug && console.log('CM user', contextUser)
      const response = await ApiGateway.post('/system/Customer/list', { contextUser }, apiRef);
      debug && console.log('CM query results', response)
      if (response.data?.Items) {
        fn(response.data.Items)
      }
    } catch (e) {
      debug && console.log('Error in CM query', e)
    }
  }
 
}


function CustomerList(props: CustomerManagementViewProps): JSX.Element {
  debug && console.log('CustomerList got', props)

  const [selectedCustomer, setSelectedCustomer] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [originalVersion, setOriginalVersion] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [customerIncreation, setCustomerInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [editMode, setEditMode] = React.useState(false)
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);
  const [customers, setCustomers] = React.useState<ValidBusinessObjectList>([]);
  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const routerHistory = useHistory();

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  useEffect(() => {
    debug && console.log('used effect to list cutomer')
    listCustomersQuery(setCustomers, props.user)
    if (action === UserTransactionType.CREATE) {
      createNewCustomerProcess();
    } else {
      setCustomerInCreation(undefined)
    }
  }, [action, props.user]);


  const findCustomerBySk = (sk: string) => customers.find((user) => user.sk === sk);

  const isEdited = () => {
    const edited = selectedCustomer  && originalVersion && !Object.entries(selectedCustomer).filter( ([k, v1]) => typeof v1 !== 'object').every((([k, v1]) => v1 === originalVersion[k]));
    // debug && console.log('Is Edited', { edited, selectedCustomer, originalVersion})
    // note still needs to equate "" to undefined really
   return edited;
  }

  const createNewCustomerProcess = () => {
    setSelectedCustomer(undefined);
    setCustomerInCreation( config.app.appCode === 'i001' ? devcustomerTemplate : customerTemplate);
  }

  const proxyParent = assembler.getInstanceFromPrimaryKey('Customer:1')
  const viewObject: ViewObject = {
    matchedPrimary: proxyParent,
    matchedRelatedByPk: [],
    matchedRelatedBySk: customers
  }

  const customerAccountTypeShim = Object.fromEntries( (Object.values(CustomerAccountType).map( (v) => [v, v])))

  type CustomerAttributeKeys = keyof AddressType
  const userTableViewDefinition: ViewDefinition & Omit<{ [key in CustomerAttributeKeys]: any }, 'dwelling_number'> = {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    street_number: { key: 'street_number', label: 'Street Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    suburb: { key: 'suburb', label: 'Suburb', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    city: { key: 'city', label: 'City', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    country: { key: 'country', label: 'Country', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    post_code: { key: 'post_code', label: 'Postcode', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  
    email: { key: 'email', label: 'Email', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    phoneNumber: {
      key: 'phoneNumber', label: 'Phone Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined
      , validationRx: action === UserTransactionType.CREATE ?  /\+\d+$/ : undefined
      , failsValidationText: 'phone format +021...'
    },
    accountType: { key: 'accountType', label: 'Account Type', type: AppValueTypes.ENUM, enumKV: customerAccountTypeShim, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },

  }

  const customerListViewDefs = {
    name: userTableViewDefinition.name,
    accountType: userTableViewDefinition.accountType,
  }

  const customerListProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Customer',
      edgeFilter: undefined
    },
    autoQuery: false,
    maxItems: 5,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: customerListViewDefs,
    viewObject,
    contextObject: undefined, 
    functionOverrides: {
      selectItem: (sk: string) => {
        const customer = findCustomerBySk(sk);
        if (customer) {
          const viewUrl = queryString.stringifyUrl( {
            url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${customer.sk}`,
            query: {}
          });
          setOriginalVersion({...customer} as ValidBusinessObject)
          setSelectedCustomer(customer);
          setCustomerInCreation(undefined);
          customer && routerHistory.push(viewUrl)
        }
     
      },
      hasValidationErrors: () => {
        const parentHasRendered = (document.querySelector('.customerManagementViewGrid'))
        if (parentHasRendered) {
          const hasErrors = (document.querySelector('.Mui-error') !== null)
          setHasValidationErrors(hasErrors)
        }
      }

    },
    checkedItemSks: selectedCustomer?.sk ? [selectedCustomer?.sk] : [],
    injectedComponents: []
  }



  return (
      <Grid className='customerManagementViewGrid' container spacing={3} >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant = 'h4'>Managed Customers</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card>
            <CardContent>
              <Typography variant="h5" component="h5" gutterBottom>
                Customers
              </Typography>
              <Listerlizer key={'userList'} {...{ ...customerListProps }}></Listerlizer>
              <Grid container>
                <Grid item xs={1}>
                  <Button
                    size='small'
                    onClick={() => routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}?contextObjectSK=${'THIS IS TOPLEVEL LIST'}`)}
                    disabled={action === UserTransactionType.CREATE}
                    startIcon={<AddIcon />}
                    variant="outlined"
                    color="primary"
                  >
                    Customer
                  </Button>

                </Grid>
              </Grid>


            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );

}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(CustomerList)