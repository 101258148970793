import { FormControl, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { ModelTypes, ValidModelObject, ValidModelType } from "@iotv/datamodel"
import { SingleObjectAutoComplete } from "../components/generic/ObjectAutoComplete"
import { AdjacentType, ValidBusinessObject } from "@iotv/iotv-v3-types"
import React from "react"
import { useEffect } from "react"

const noOptions: string = 'No options available'
const requestSetOption: string = 'Please set option'

export type useObjectByTypeAndNameProps = { contextObject: ValidModelObject<ValidModelType> | undefined,  currentObject: ValidBusinessObject | undefined, currentObjectTypeId: ValidModelType | undefined, validTypeIds: ValidModelType[], adjacencyType: AdjacentType }
export type useObjectByTypeAndNameReturn = {
    components: {
        objectTypeSelector: JSX.Element,
        entitySelector: JSX.Element
    },
    state: {
        currentEntity: [ ValidModelObject<ValidModelType> | undefined, ( entity: ValidModelObject<ValidModelType> ) => ValidModelObject<ValidModelType> | undefined ],
        objectTypeId: [ ValidModelType | undefined, () => ValidModelType | undefined ]
    }
}

export const useObjectByTypeAndName = ( { contextObject, currentObject, currentObjectTypeId,  validTypeIds, adjacencyType }: useObjectByTypeAndNameProps ) => {
    const style: React.CSSProperties | undefined = { width: '15rem'}

    const [ currentEntity, setCurrentEntity ] = React.useState<ValidBusinessObject | undefined>( currentObject );
    const [ objectTypeId, setObjectTypeId ] = React.useState<ValidModelType | undefined>( currentObjectTypeId )

    useEffect( () => {

    }, [ contextObject?.sk, adjacencyType, objectTypeId ])


    const componentMap = {
      objectTypeSelector: <FormControl {...{ key: 'objectTypeId' }}>
          <Autocomplete {...{
              freeSolo: false, id: 'selectObjectTypeId', style, size: 'small', variant: "outlined", options: validTypeIds,
              getOptionLabel: (option: ValidModelType) => option ?? noOptions, value: objectTypeId ?? requestSetOption as ValidModelType,
              onChange: (e, v: string | null) => {
                  v && setObjectTypeId(v as ValidModelType)
              },
              renderInput: (params) => <TextField {...{
                  ...params, label: 'Entity Type'
              }} />
          }} />
      </FormControl>,
  
      entitySelector: <SingleObjectAutoComplete {...{ contextObject, adjacencyType, objectTypeId, currentEntity, setCurrentEntity, style }}></SingleObjectAutoComplete>
  
  }



  return {
      components: componentMap, 
      state: {
          currentEntity: [ currentEntity, setCurrentEntity ],
          objectTypeId: [ objectTypeId, setObjectTypeId ]
      }
  } as useObjectByTypeAndNameReturn
}
