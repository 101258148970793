import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Indication } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface indicationModalProp {
  close: () => void;
  update: (input: Indication | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: Indication[];
}

const IndicationModal: React.FC<indicationModalProp> = (props: indicationModalProp) => {
  let { close, update, index, existing } = props;

  let [message, setMessage] = useState<string>('');

  let [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setMessage(source.message);
    }
  }, [index, existing]);

  useEffect(() => {
    setValid(message !== '');
  }, [message]);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  const addStage = (message: string, pos: number | undefined) => {
    update({ message }, pos);
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create Indication
              </Typography>
              <div className={styles.full}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <TextField
                    rows={6}
                    value={message}
                    label='Indication Message'
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    minRows={4}
                    multiline
                    fullWidth
                    style={{ width: '100%' }}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Stack>
              </div>
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    addStage(message, index);
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default IndicationModal;
