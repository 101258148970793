import {
  CustomerAccountType,
  HistoryStore,
  ObjectHistory,
  PathDirection,
  QueryTypes,
  ValidBusinessObject,
  ValidModelObject,
  ValidModelObjects,
} from '@iotv/datamodel-core';
import { AdjacentType, ErrData, TraverserQueryPathBody, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';

import { TreeItem, TreeView } from '@mui/lab';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContextObjectType, ObjectCardProps } from 'src/types/AppTypes';
import { C021CustomerAccountType, c021ToLSCAccountType, Community, lscToC021AccountType, Tank } from '@c021/datamodel';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { getAdjacent2Wrapper, getOne } from '../../../data/daoFunctions/daoFunctions';
import { useStyles } from '../cosmetics/communityStyles';
import { c021NavSections } from '../navigation/c021_NavSections';
import { ExtendedTreeItem } from '../../../components/generic/ObjectTree/Derivations/ExtendedTreeItem2';
import { IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Business } from '@mui/icons-material';

import { NavSections } from '../../../components/navigation/NavSections';
import { DMPStage } from './CountryCommunity/DroughtManagement/DMPExtrasV2';
import { getObjectHistory } from '../../../data/daoFunctions/historyFns';
import { GridColDef, GridCellParams, DataGrid } from '@mui/x-data-grid';

const debug = process.env.REACT_APP_DEBUG && false;

export const getColour = (fillPercentage: number): string => {
  if (fillPercentage >= 75 && fillPercentage <= 100) {
    return '#99ff99';
  } else if (fillPercentage >= 50 && fillPercentage < 75) {
    return '#ffff66 ';
  } else if (fillPercentage >= 25 && fillPercentage < 50) {
    return '#ffcc99';
  } else {
    return '#ff8080';
  }
};

export const CommunitiesView = (props: ObjectCardProps) => {
  const styles = useStyles();
  const history = useHistory();

  const [customers, setCustomers] = useState<ValidBusinessObjectList>([]);
  const [communitiesToDisplay, setCommunitiesToDisplay] = useState<ValidBusinessObjectList>([]);
  const [endCommunities, setEndCommunities] = useState<ValidBusinessObjectList>([]);
  const [selectedParents, setSelectedParents] = useState<string[]>([]);
  const [edges, setEdges] = useState<Array<string[]>>([]);

  const { transactionFunctions, userFunctions, searchParams, viewObject, contextCustomer, userGeolocation } = props;

  /**
   * useEffect hook fetches all end communities associated with current customer (Community Water manager)
   * Then for each community, its tanks are fetched to fill in some of the fields in the table
   */
  useEffect(() => {
    // checks if the contextCustomer is a community and if so, immediately redirects to its community (THINGVIEW) page
    if (contextCustomer?.accountType === CustomerAccountType.Y) {
      history.push(`${NavSections.THINGVIEW}/Customer/${contextCustomer.id}`);
      return;
    }
    /**
     * Fetches the communities related to the customer object
     * @param contextCustomer ValidBusinessObject for the current customer
     * @returns
     */
    const fetchCommunities = async (contextCustomer: ValidModelObject<'Customer'> | undefined) => {
      let res: ErrData<ValidBusinessObjectList> = { err: null, data: null };
      if (contextCustomer) {
        const simpleTypePath = [
          { type: 'Customer', direction: PathDirection.child },
          { type: 'Customer', direction: PathDirection.child },
          { type: 'Customer', direction: PathDirection.child },
          { type: 'Customer', direction: PathDirection.child },
        ];

        const queryBody: TraverserQueryPathBody = {
          type: QueryTypes.directedPath,
          simpleTypePath: simpleTypePath,
          normalize: false,

          items: [contextCustomer], // CHILD should be from Datastore.pathDirection
        };

        const path = '/query/';
        res = await ApiGateway.post(path, queryBody);
      }

      return res;
    };

    fetchCommunities(contextCustomer as ValidModelObject<'Customer'> | undefined).then(async ({ err, data }) => {
      if (err == null && data != null) {
        const communities: ValidBusinessObjectList = data.filter(
          (item: ValidBusinessObject) =>
            (item.accountType === c021ToLSCAccountType('Community' as C021CustomerAccountType) ||
              item.accountType === c021ToLSCAccountType('Country / Island' as C021CustomerAccountType)) &&
            item.sk !== contextCustomer?.sk
        );
        // communities =

        setCustomers(communities);

        //First filters end communities by finding communities which do not have children (i.e. has children if commA has pair commA.sk === commB.pk)
        const endCommunities: ValidBusinessObjectList = communities.filter(
          (community) => community.accountType === c021ToLSCAccountType('Community' as C021CustomerAccountType)
        );

        debug && console.log(communities.filter((community) => communities.find((comm) => community.sk === comm.pk) === undefined));

        const adjacencyPairs = await buildAdjacencyList(communities);

        const parents = [
          ...new Set(
            adjacencyPairs.map((edge) => {
              if (edge) {
                return edge[0];
              } else return undefined;
            })
          ),
        ];

        setSelectedParents([...parents]);
        setEdges([...(adjacencyPairs as string[][])]);

        debug && console.log('CommunitiesView Hierarchy', { communities, parents, edges: adjacencyPairs });

        //Fetching tanks for each of the end communities
        const promises: Promise<any>[] = [];
        endCommunities.forEach((community) => {
          community.pk = community.sk;
          promises.push(fetchCommunityTanks(community));
          promises.push(fetchDroughtManagementPlanStage(community));
          promises.push(fetchCommunityHistory(community));
        });
        await Promise.all(promises);

        setCommunitiesToDisplay([...endCommunities]);
        setEndCommunities([...endCommunities]);
      }
    });

    /**
     * Fetching tanks linked to the provided community.
     * The community object is then updated a 'tanks' field to store a list of its tanks
     * @param communityObject Community object for the community to fetch tanks for
     */
    const fetchCommunityTanks = async (communityObject: ValidBusinessObject) => {
      const simpleTypePath = [
        { type: 'Customer', direction: PathDirection.child },
        { type: 'Tank', direction: PathDirection.child },
      ];

      const queryBody: TraverserQueryPathBody = {
        type: QueryTypes.directedPath,
        simpleTypePath: simpleTypePath,
        normalize: false,

        items: [communityObject], // CHILD should be from Datastore.pathDirection
      };

      const path = '/query/';
      const { err, data } = await ApiGateway.post(path, queryBody);
      // return { err, data };
      if (err == null && data != null) {
        communityObject.tanks = data.filter((data: ValidBusinessObject) => data.type === 'Tank');
      }
    };

    const fetchDroughtManagementPlanStage = async (communityObject: ValidBusinessObject) => {
      const simpleTypePath = [
        { type: 'DroughtManagementPlan', direction: PathDirection.parent },
        //{ type: 'DroughtManagementPlanStage', direction: PathDirection.child },
      ];

      const queryBody: TraverserQueryPathBody = {
        type: QueryTypes.directedPath,
        simpleTypePath: simpleTypePath,
        normalize: false,

        items: [communityObject], // CHILD should be from Datastore.pathDirection
      };

      const path = '/query/';
      const { err, data } = (await ApiGateway.post(path, queryBody)) as ErrData<ValidBusinessObjectList>;

      debug && console.log('CommunitiesView fetchDroughtManagementPlanStage', { err, data });

      if (err == null && data != null) {
        let communityCurrentStage: DMPStage | undefined = undefined;
        const dmp = data.find((item) => item.type === 'DroughtManagementPlan') as undefined | { stages: DMPStage[] };
        if (dmp?.stages) {
          communityCurrentStage = dmp.stages.find((stage) => stage.stageNumber === communityObject.stageNumber);
          debug && console.log('CommunitiesView fetchDroughtManagementPlanStage setStage to ', communityCurrentStage);
        }
        // const planStages = data.filter((stage : ValidBusinessObject) => stage.type === 'DroughtManagementPlanStage')
        // const communityCurrentStage = planStages.find((stage : ValidBusinessObject)  => stage.stageNumber === communityObject.stageNumber)
        communityObject.droughtManagementPlanStage = communityCurrentStage;
      }
    };

    const fetchCommunityHistory = async (communityObject: ValidBusinessObject) => {
      const communityHistoryRes = await getObjectHistory(communityObject);

      if (communityHistoryRes.data) {
        const communityHistory = communityHistoryRes.data;
        communityObject.history = communityHistory;
        debug && console.log('CommunitiesView fetchCommunityHistory put on communityObject.history', communityHistory);
      } else {
        debug && console.log('CommunitiesView fetchCommunityHistory probably got err', communityHistoryRes);
      }
    };

    const buildAdjacencyList = async (communities: ValidBusinessObjectList) => {
      const communityItems = communities; // await Promise.all(communities.map(async community => {return (await getOne(community)).data}))
      console.log('communityItems', communityItems);
      console.log('communities', communities);

      const adjacencyPairs = communities
        .map((community) => {
          const parent = communityItems.find((comm) => (comm ? comm.sk === community.pk : undefined));
          const child = communityItems.find((comm) => (comm ? comm.sk === community.sk : undefined));

          if (parent && child) return [parent.name, child.name];
        })
        .filter((pair) => pair);
      contextCustomer && adjacencyPairs.push(['-', contextCustomer?.name]);
      return adjacencyPairs;
    };
  }, [contextCustomer?.sk]);

  useEffect(() => {
    const endCommunitiesToDisplay = selectedParents
      .map((parent) => {
        const parentEdges = edges.filter((edge) => edge[0] === parent);

        const communities = endCommunities.filter((comm) => parentEdges.find((edge) => edge[1] === comm.name));

        return communities;
      })
      .flat();

    setCommunitiesToDisplay([...endCommunitiesToDisplay]);
  }, [selectedParents]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Community Name',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
    },
    {
      field: 'numTankUsers',
      headerName: '# tank users',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        const numTankUsers = params.row.numTankUsers;

        return <>{numTankUsers}</>;
      },
    },
    {
      field: 'numTanks',
      minWidth: 1,
      headerName: '# tanks',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.numTanks}</>;
      },
    },
    {
      field: 'planStage',
      headerName: 'Drought Management Stage',
      flex: 1,
      minWidth: 240,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        const droughtManagementPlanStage = params.row.droughtManagementPlanStage;
        return <>{droughtManagementPlanStage ? droughtManagementPlanStage.stageNumber : 'No Plan Assigned'}</>;
      },
    },
    {
      field: 'communityFillLevel',
      headerName: 'Community Fill Level',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        const fillPercentage = Math.round(params.row.current_filled_factor * 100);
        let colour = getColour(fillPercentage);

        return (
          <div style={{ backgroundColor: colour, width: '80%' }}>
            {typeof fillPercentage === 'number' && !isNaN(fillPercentage) ? `${fillPercentage}%` : 'Calculating...'}
          </div>
        );
      },
    },
    {
      field: 'runOutDate',
      headerName: 'Run Out Date',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      renderCell: (params: GridCellParams) => {
        const community_days_left = params.row.community_days_left;

        const currentDate = new Date();
        const runOutDate = new Date(currentDate);
        runOutDate.setDate(runOutDate.getDate() + community_days_left);

        return <>{runOutDate.toLocaleDateString()}</>;
      },
    },
    {
      field: 'daysLeft',
      headerName: 'Days Left',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        let daysLeft = params.row.community_days_left;
        let colour = '';

        if (daysLeft > 60) {
          colour = '#99ff99';
        } else if (daysLeft > 20 && daysLeft <= 60) {
          colour = '#ffff66';
        } else if (daysLeft > 7 && daysLeft <= 14) {
          colour = '#ffcc99';
        } else {
          colour = '#ff8080';
        }

        return <div style={{ backgroundColor: colour, width: '80%' }}>{typeof daysLeft === 'number' ? `${daysLeft} Days` : 'Calculating...'}</div>;
      },
    },
    {
      field: 'communityUsageYesterday',
      headerName: 'Community Usage Yesterday',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        const history = params.row?.history as { dailyStateHistory?: HistoryStore } | undefined;
        const volumeUsedYesterday = history?.dailyStateHistory?.volume_used_yesterday.slice(-1)[0];

        return <>{volumeUsedYesterday ? `${Math.round(volumeUsedYesterday)} L` : '-'}</>;
      },
    },
    {
      field: 'avgCommunityDailyUsage',
      headerName: 'Average Community Daily Usage (last 14 days)',
      flex: 1,
      // minWidth: 370,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        let message = '';
        if (params.row.history) {
          if (params.row.history?.dailyStateHistory) {
            if (params.row.history?.dailyStateHistory?.community_usage_in_day) {
              const communityDailyUsageHistory = params.row.history.dailyStateHistory.community_usage_in_day;

              let averageUsage = 0;

              if (communityDailyUsageHistory.length >= 14) {
                let pastTwoWeeks = communityDailyUsageHistory.slice(-13);
                averageUsage =
                  pastTwoWeeks.reduce((acc: number, curr: number) => {
                    return (acc += curr);
                  }, 0) / 14;
              } else {
                averageUsage =
                  communityDailyUsageHistory.reduce((acc: number, curr: number) => {
                    return (acc += curr);
                  }, 0) / communityDailyUsageHistory.length;
              }

              message = `${Math.round(averageUsage)} L`;
            } else {
              message = debug ? 'No Community Usage In Day!' : 'Calculating...';
            }
          } else {
            message = debug ? 'No Daily State History!' : 'Calculating...';
          }
        } else {
          message = debug ? 'No Object History!' : 'Calculating...';
        }

        return <>{message}</>;
      },
    },
  ];

  /**
   * Generates Treeview for showing the communities in tree-like structure
   * @returns A nested TreeItem JSX object for tree structure of communities
   */
  const generateTree = () => {
    //Getting a list of all the 'super communities' i.e. communities with no parents
    const superCommunityEdges = edges.filter((edge1) => !edges.find((edge2) => edge1[0] === edge2[1]));

    const superCommunities = superCommunityEdges.reduce((acc, curr) => {
      const exists = acc.find((community) => community === curr[0]);
      if (!exists) {
        return acc.concat(curr[0]);
      } else {
        return acc;
      }
    }, []);
    console.log('CommunitiesView superCommunities', superCommunities);

    //Generating a root node in treeview for each super community
    const treeElement = superCommunities.map((community) => {
      const communityControls: JSX.Element = getCommunityControls(community);
      return <ExtendedTreeItem {...{ nodeId: community, label: community, x: communityControls }}>{generateSubTree(community)}</ExtendedTreeItem>;
    });

    return treeElement;
  };

  const getCommunityControls = (communityName: string) => {
    const actualCommuntiyObject = customers.find((c) => c.name === communityName);
    console.log('actualCommuntiyObject', actualCommuntiyObject);

    return actualCommuntiyObject ? (
      <Grid container {...{ spacing: 1, wrap: 'nowrap' }}>
        <Grid item {...{}}>
          <Tooltip {...{ title: `Edit ${lscToC021AccountType(actualCommuntiyObject.accountType)}` }}>
            <IconButton
              {...{ size: 'small', onClick: () => history.push(`${NavSections.THINGVIEW}/${actualCommuntiyObject.type}/${actualCommuntiyObject.id}`) }}
            >
              <Edit></Edit>
            </IconButton>
          </Tooltip>
          <Tooltip {...{ title: `Manage ${lscToC021AccountType(actualCommuntiyObject.accountType)} entities, users, devices` }}>
            <IconButton
              {...{
                size: 'small',
                onClick: () => history.push(`${NavSections.CUSTOMER_MANAGEMENT_VIEW}/UPDATE/${actualCommuntiyObject.type}/${actualCommuntiyObject.sk}`),
              }}
            >
              <Business></Business>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    ) : (
      <></>
    );
  };

  /**
   * Generates a   sub-tree where the root of the tree is the community provided
   * @param community Name of the community to create a sub tree from
   * @returns A nested TreeItem JSX object for tree structure of communities from root community
   */
  const generateSubTree = (community: string) => {
    //Getting list of children from given community
    const childrenEdges = edges.filter((edge) => edge[0] === community);
    const children = childrenEdges.map((edge) => edge[1]).sort();

    //If the community has grandchildren, generate another subtree. Otherwise generate simple node
    return children.map((community) => {
      const communityEdge = edges.find((edge) => edge[1] === community);
      const communityControls: JSX.Element = getCommunityControls(communityEdge?.[1] ?? community);
      if (communityEdge) {
        return <ExtendedTreeItem {...{ nodeId: community, label: community, x: communityControls }}>{generateSubTree(community)}</ExtendedTreeItem>;
      }
      return (
        <ExtendedTreeItem
          {...{
            x: communityControls,
            nodeId: community,
            label: community,
            onClick: (e) => console.log(e.target),
          }}
        />
      );
    });
  };

  /**
   * Event handler for when a tree node is toggled. Selects/deselects all relevant parents depending on
   * toggle status
   * @param event
   * @param nodes
   */
  const handleChange = (event: any, nodes: string[]) => {
    let selectedNodes: string[] = [];

    //Find toggled node in selectedParents list
    let toggledNode = selectedParents.filter((parent) => !nodes.find((node) => node === parent));

    //If being selected
    if (toggledNode.length === 0) {
      toggledNode = nodes.filter((node) => !selectedParents.find((parent) => node === parent));
      //Gets a list of children who are also parents which need to be toggled when selected/unselected
      const childrenToToggle = getChildrenToToggle(toggledNode[0]);
      selectedNodes = [...nodes].concat(childrenToToggle);
    } else {
      //else when being unselected
      const childrenToToggle = getChildrenToToggle(toggledNode[0]);
      selectedNodes = [...nodes].filter((node) => !childrenToToggle.find((child) => child === node));
    }
    setSelectedParents([...selectedNodes]);
  };

  /**
   * Recursively gets a list of all the parent nodes down the tree from a given root node.
   * @param nodeId String for nodeId of root node
   * @returns Array of nodeId for parent nodes down the tree from root node
   */
  const getChildrenToToggle = (nodeId: string) => {
    let childrenToToggle: string[] = [];

    const nodeParentEdges = edges.filter((edge) => edge[0] === nodeId);
    const children = nodeParentEdges.map((edge) => edge[1]);

    children.forEach((child) => {
      const grandChildren = edges.filter((edge) => edge[0] === child);

      if (grandChildren.length > 0) {
        childrenToToggle.push(child);
        childrenToToggle = childrenToToggle.concat(getChildrenToToggle(child));
      } else {
        return childrenToToggle;
      }
    });

    return childrenToToggle;
  };

  return (
    <Card>
      <Grid container className={styles.container} spacing={2}>
        <Grid item>
          <Typography variant='h5' component='h5'>
            Communities
          </Typography>

          {edges.length > 0 && selectedParents && (
            <TreeView
              defaultExpanded={[...new Set(edges.map((edge) => edge[0]))]}
              defaultCollapseIcon={<CheckBoxIcon />}
              defaultExpandIcon={<CheckBoxOutlineBlankIcon />}
              onNodeToggle={handleChange}
              expanded={selectedParents}
            >
              {generateTree()}
            </TreeView>
          )}
        </Grid>
        <Grid item xs={12} alignItems='center'>
          <Card>
            <CardContent>
              <DataGrid
                onRowClick={(e) => history.push(`/${NavSections.THINGVIEW}/Customer/${e.row.id}`)}
                disableColumnMenu={true}
                autoHeight={true}
                rows={communitiesToDisplay}
                columns={columns}
                disableSelectionOnClick={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};
