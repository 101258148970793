import { withStyles, WithStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const c021HeaderStyles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
      background : '#FFFFFF'
    },
    logo:{
      maxWidth:100,
      //flexGrow: 1
      //width:70,
      //height:50
    },

    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.common.white,
      },
    },


    button: {

    },

    logoRoot: {
      // border: '1px solid red',
      display: 'flex',
      marginBottom: "12px",
      marginTop: "12px",
      marginLeft: "15px",
      '&:hover':{
        cursor : 'pointer'
      }
    },
    ewbLogo: {
      width: 120,
    },
    iotWaterLogo: {
      width: 80,
      // paddingLeft: '100px'
      // height: 38,
    },
  tempLogo: {
    display: 'flex',
    // flexDirection: 'column',
    height: 70,
  },
  });