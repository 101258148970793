import { IconButton, List, ListItem, ListItemSecondaryAction, TextField, TextFieldProps } from "@material-ui/core"
import React, { useEffect } from "react"
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { PossibleBusinessObject } from "@iotv/iotv-v3-types";


export type EditableListProps<T> = {

    currentList: T[]
    setCurrentList: ( items: T[]) => void
    listItemClass?: (item: T) => JSX.Element;
    typeConstructor: () =>  T

}
export const EditableList = <T extends any> ( { currentList, setCurrentList, listItemClass, typeConstructor }: EditableListProps<T> ) => {

    const [ localList, setLocalList ] = React.useState<T[]>([...currentList])

    const handleRemove = (i: number) => {
        const mutant = [...localList ];
        mutant.splice(i, 1 )
        setCurrentList(mutant)
    }


    const handleSave = ( i: number ) => {
        const mutant = [...localList ];

        setCurrentList(mutant)
    }

    const handleEdit = ( e: React.ChangeEvent<HTMLInputElement>, i: number ) => {
        const mutant = [...localList ];
        mutant[i] = e.target.value as T;
        setLocalList(mutant)
    }



    return <List { ...{ dense: true}}>
        { localList.map( ( item: T, i ) => {

            return <ListItem { ...{ component: TextField, value: item, key: ( item as PossibleBusinessObject )?.sk ?? i, onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleEdit(e, i)}}>
                
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="save" { ...{ disabled: ( item === currentList[i]), onClick: ( ) => handleSave( i)}}>
                      <SaveIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" { ...{ onClick: () => handleRemove(i)}}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>

            </ListItem>
        }) }
        <ListItem { ...{ onClick: () => { setCurrentList( [...currentList, typeConstructor()]);} } }>
            <AddIcon />
        </ListItem>

    </List>
}