import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Container, makeStyles, Theme, withStyles, } from '@material-ui/core';
import styles from '../../../../../cosmetics/sharedViewStyles';
import { CustomerManagementViewProps, CustomerViewSearchParamsType, UserTransactionType } from '../../../../../types/AppTypes';

import CustomerView from './CustomerCustomers/CustomerView'
import CustomerList from '../../../../../components/SytemManagement/Users/UserCustomerList';
import CustomerUserView from '../../../../../components/SytemManagement/Customers/CustomerUsers/CustomerUserView';
import { useHistory } from 'react-router-dom';
import { NavSections } from '../../../../../components/navigation/NavSections';
import CustomerDeviceView from '../../../../../components/SytemManagement/Customers/CustomerDevices/CustomerDeviceView';
import { getQueryParamsFromHistory } from '../../../../../util/Routing/queryParams';

import CustomerGatewayView from '../../../../../components/SytemManagement/Customers/CustomerGateways/CustomerGatewayView';


const debug = process.env.REACT_APP_DEBUG && false;
const componentClassName = 'CustomerManagementView';
const componentParentHasRendered = () => document.querySelector(`.${componentClassName}`);

const localStyle = (theme: Theme) => ({

  customerManagementContainer: {
        maxWidth: 'unset'
  }

});
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle(theme) }));



function CustomerManagementView( props : CustomerManagementViewProps): JSX.Element {
  const {  match: { params: { action, id: sk, type } }, user, contextCustomer, history} = props;
  const routerHistory = useHistory();

  const query = getQueryParamsFromHistory(history)

  debug && console.log('CustomerManagementView get url stuff', JSON.stringify({ match: props.match, query }, null, 1))

  useEffect( () => {
    debug && console.log('CustomerManagementView useEffect on action', action)

  }, [action ])

  useEffect( () => {
    debug && console.log('CustomerManagementView useEffect on customer sk', action)
    if (contextCustomer) {
      debug && console.log('not doing odd redirect')
      // const searchParams = getQueryParamsFromHistory<CustomerViewSearchParamsType>(history)
      // const url = queryString.stringifyUrl({ url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${action}/${'Customer'}/${contextCustomer.sk}` , query: searchParams ?? {}})
      // setTimeout( () => routerHistory.push(url), 500 );
    }
    
  }, [contextCustomer?.sk, user?.sk])

  const switchOnTypeAndAction = (type: string | undefined, action: UserTransactionType | undefined) => {
    let derivedType: string | undefined = type;
    if (contextCustomer) {
      if (!type) {
        derivedType = 'Customer';
        const id = contextCustomer.sk;
        routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/${derivedType}/${id}`)
      }
    }

     switch(derivedType) {
       case 'Customer': 
         switch(action) {
           case UserTransactionType.UPDATE: return  <CustomerView {...{ ...props }}></CustomerView>;
           case UserTransactionType.CREATE: return  <CustomerView {...{ ...props }}></CustomerView>;
           default: return  <CustomerList {...{ ...props }}></CustomerList>; 
         }
        case 'User': 
        switch(action) {
          case UserTransactionType.UPDATE: return  <CustomerUserView {...{ ...props }}></CustomerUserView>;
          case UserTransactionType.CREATE: return  <CustomerUserView {...{ ...props }}></CustomerUserView>;
          default: return  <CustomerList {...{ ...props }}></CustomerList>; 
        }
        case 'Device': 
        switch(action) {
          case UserTransactionType.UPDATE: return  <CustomerDeviceView {...{ ...props }}></CustomerDeviceView>;
          case UserTransactionType.CREATE: return  <CustomerDeviceView {...{ ...props }}></CustomerDeviceView>;
          default: return  <CustomerList {...{ ...props }}></CustomerList>; 
        }
        case 'Gateway': 
        switch(action) {
          case UserTransactionType.UPDATE: return  <CustomerGatewayView {...{ ...props }}></CustomerGatewayView>;
          case UserTransactionType.CREATE: return  <CustomerGatewayView {...{ ...props }}></CustomerGatewayView>;
          default: return  <CustomerList {...{ ...props }}></CustomerList>; 
        }
       default: return  <CustomerList {...{ ...props }}></CustomerList>; 
     }
  }

  debug && console.log('match', {action, sk})
  const classes = useStyles();

  console.log('Classes', classes)

  return <Container className= { classes.customerManagementContainer } >
    <Card className={classes.customerManagementContainer}>
      <CardHeader></CardHeader>
      <CardContent>
        {
          switchOnTypeAndAction(type, action as UserTransactionType | undefined)
        }

       
      </CardContent>

    </Card>
  </Container>

}

export default withStyles(styles)(CustomerManagementView)