import { edgeTypeMatrix } from "@iotv/datamodel"
import { AdjacentType } from "@iotv/iotv-v3-types"
import React from "react"
import { ComparisonType, DeviceManagementViewProps, GetQueryFilterConfig, LogicalOperatorType, ObjectTreeConfig } from "../../../../types/AppTypes"
import { ObjectFuculator } from "../../../generic/ObjectFuculator/ObjectFuculator"
import config from '../../../../config'

const dyn = config.aws.dynamoDB

export const DeviceGroupsTab = ( { contextCustomer, contextUser, ...others}: DeviceManagementViewProps) => {

    const deviceAggregationGroupFilter: GetQueryFilterConfig = { filters: [{ key: 'supportedType', value: 'Device', predicate: ComparisonType.EQUALS }], setOperation: LogicalOperatorType.AND }

    const objectTrees: ObjectTreeConfig[] = [
        {  mainAdjacencyQuery: { objectTypeId: 'AggregationGroup', adjacencyType: AdjacentType.CHILD,  filter: deviceAggregationGroupFilter }, branchAdjacencyQueries: [ { objectTypeId: 'Device', adjacencyType: AdjacentType.CHILD} ]},
        {  mainAdjacencyQuery: { objectTypeId: 'Device', adjacencyType: AdjacentType.CHILD,  } },
    ]

    const contextObject = contextCustomer

    return contextObject ? <ObjectFuculator { ...{ contextObject, objectTrees, ...others}}></ObjectFuculator> : null
}