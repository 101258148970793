import config from "../../../../config";

const debug = process.env.REACT_APP_DEBUG && false;

export type GoogleMapConstructorProps = {
    googleMapRef : React.MutableRefObject<HTMLDivElement | null | undefined>, options: google.maps.MapOptions | undefined
}



export const createGoogleMap = ( params: GoogleMapConstructorProps ) => {
  let map: google.maps.Map<Element> | undefined = undefined
  if ( params.googleMapRef ) {
    const current = params.googleMapRef.current
    const target = current as Element;
    const center = config.app.geo.initialCenter
    const zoom =  6;
    map = new google.maps.Map(target, {
      zoom,
      center,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDoubleClickZoom: true,
      ...params.options
    })
  } else {
    debug && console.log('createGoogleMap, no googleMapRes')
  }
  
    return map;
  }