import type { AttributeKeyDefinition, AttributeKeyRangeDefinition, MapLike } from "@iotv/iotv-v3-types";
import { Button, Card, CardContent, Input, MenuItem, Slider, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from "@material-ui/core";
import { IrrigationControllerPayload as Payload2 } from '@v020/datamodel';
import React, { useEffect } from "react";
import { DeviceProps, getObjectHash, objectsMatch } from "@iotv/datamodel-core";
import { ConfigTabProps } from "../../../types";
import { SimpleNumberRowGeneric } from "../SimpleNumberRowGeneric";


const debug = process.env.REACT_APP_DEBUG && false;

export type IrrigatorDeviceConfigCardProps = Pick<ConfigTabProps<any>, 'selectedDevices' | 'handleSendTransactionButtonClick' | 'setReceivedDeviceControlTransactions' | 'deviceConfig'>

const verify = 0x5A


type DownLinkControlObjectType = Payload2.DownLinkControlObjectType
type DeviceConfigControlObjectType = Pick<DownLinkControlObjectType, 'appCycleTime' | 'adr' | 'sysCycleTime' | 'clearErrorCodes' | 'dr' | 'reboot' | 'sendConfig'>

type DisplayedAtts = Pick<Payload2.DownLinkControlObjectType, "appCycleTime" | "sysCycleTime">

export const IrrigatorControlGeneralConfigCard = ({ handleSendTransactionButtonClick, setReceivedDeviceControlTransactions, selectedDevices, deviceConfig }: IrrigatorDeviceConfigCardProps) => {

    const [hasEdits, setHasEdits] = React.useState<boolean>(false)

    // Here be gotchas, currently we only have provision for one initial value, and we would like to support multiple sends
    const firstDevice = selectedDevices[0] as Partial<DeviceProps> | undefined

    const initial: Partial<DeviceConfigControlObjectType> = {
        // adr: 1,
        appCycleTime: 5,
        // dr: 2,
        sysCycleTime: 60,
    };

    Object.entries(initial).forEach(([k, v]) => {
        if (firstDevice?.downlinkControlRequestState?.[k]) {
            v = firstDevice?.downlinkControlRequestState?.[k]
        }
    })

    const [desiredPayload, setDesiredPayload] = React.useState<Partial<DownLinkControlObjectType | undefined>>(undefined)
    const [reportedSettings, setReportedSettings] = React.useState<Pick<DownLinkControlObjectType, 'appCycleTime' | 'sysCycleTime'> | undefined>(undefined)

    const deviceConfigHash = getObjectHash(deviceConfig)

    useEffect(() => {

        const reported = deviceConfig?.requested
        debug && console.log('General Config card used effect on ', { deviceConfig, reported })
        const { appCycleTime, sysCycleTime } = reported ?? {} as MapLike<number>
        // AppCycle and SysCycle currently not in reported so using requested as proxy
        setReportedSettings({ appCycleTime, sysCycleTime })

    }, [deviceConfigHash])

    if (desiredPayload === undefined) { setDesiredPayload(initial) }


        const handleDownlinkReqConfig = () => handleSendTransactionButtonClick(
            {
                sendConfig: true,
            })

    const handleDownlinkClearErrors = () => handleSendTransactionButtonClick(
        {
            clearErrorCodes: true,
        })

    const handleDownlinkReboot = () => handleSendTransactionButtonClick(
        {
            reboot: true,
        })

    const handleDownlinkRestore = () => handleSendTransactionButtonClick(
        {
            restoreDefaults: true
        })


    // limits = Payload.DownlinkPayloadAttributeRangeLimits
    // this is not quite right as DownlinkPayloadAttributes is the raw object not the user control object
    //const validate = Payload.validateObject(desiredPayload ?? {}, limits)

    debug && console.log('desiredPayload', { desiredPayload })



    const pluckedEntries = Object.entries( desiredPayload ?? initial ?? {}).filter(([k, v]) => typeof v === 'number') as [[keyof DisplayedAtts, number]]

    return <Card>
        <CardContent>
            <TableContainer {...{ style: { padding: '0.5em',  },  }}>
                <Table { ...{ style: { width: '100%' } } }>
                    <TableHead>
                        <TableRow>
                            <TableCell>Setting</TableCell><TableCell>Current</TableCell><TableCell>New</TableCell><TableCell>Set</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>

                        {pluckedEntries.map(([k, v]: [ keyof DisplayedAtts, number], i) => {
                            const limits: AttributeKeyDefinition<AttributeKeyRangeDefinition> = Payload2.DownlinkPayloadAttributeRangeLimits[k] as AttributeKeyDefinition<AttributeKeyRangeDefinition>
                            const { attributeType, range: { min, max } } =  limits;
                            const inputWidth = '5em'
                            const keyWidth = '10em'
                            const marks = max - min < 10;
                            const step = marks ? (1) : 1                        
                            const payloadAttribute = (({ appCycleTime, sysCycleTime }) => ({ appCycleTime, sysCycleTime }) )(desiredPayload ?? {} as MapLike<number>) as  DisplayedAtts
                            const reportedValue = reportedSettings?.[k]
                            const currentValue = payloadAttribute?.[k] as number ?? 0
                            const setPayloadAttribute = (v: number, k: keyof DisplayedAtts) => {
                                const clone = { ...desiredPayload, [k]: v }
                               
                                setDesiredPayload(clone)
                                debug && console.log(`General Config set ${k} to ${v} and payload to`, clone)
    
                            }
                            return <SimpleNumberRowGeneric<DisplayedAtts> { ...{key: k, i, keyWidth, k, inputWidth, v, payloadAttribute, reportedValue, currentValue, limits, min, marks, max, step, setPayloadAttribute, setHasEdits } } /> 

                        })}
                        <TableRow>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Button {...{
                                    variant: "contained", color: "primary", size: 'small',
                                    disabled: selectedDevices.length === 0 || ( reportedSettings && objectsMatch( reportedSettings, desiredPayload ?? {}) ),
                                    onClick: () => handleSendTransactionButtonClick(desiredPayload)
                                }}
                                >
                                    Send
                                </Button>
                                <Button {...{
                                    variant: "contained", color: "primary", size: 'small',
                                    disabled: objectsMatch( reportedSettings ?? {}, desiredPayload ?? {}) ,
                                    onClick: () => { setDesiredPayload(reportedSettings ?? initial); setHasEdits(false) }
                                }}
                                >
                                    Reset
                                </Button>

                            </TableCell>


                        </TableRow>
                        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
                        <TableRow {...{ style: {} }}>
                            <TableCell>
                                <Button {...{
                                    variant: "contained", color: "primary", size: 'small',
                                    disabled: selectedDevices.length === 0,
                                    onClick: () => handleDownlinkReboot()
                                }}
                                >
                                    Reboot
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button {...{
                                    variant: "contained", color: "primary",  size: 'small',
                                    disabled: selectedDevices.length === 0,
                                    onClick: () => handleDownlinkClearErrors()
                                }}
                                >
                                    Clear Errors
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button {...{
                                    variant: "contained", color: "primary", size: 'small',
                                    disabled: selectedDevices.length === 0,
                                    onClick: () => handleDownlinkRestore()
                                }}
                                >
                                    Restore
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button {...{
                                    variant: "contained", color: "primary", size: 'small',
                                    disabled: selectedDevices.length === 0,
                                    onClick: () => handleDownlinkReqConfig()
                                }}
                                >
                                    Config
                                </Button>
                            </TableCell>
                           
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </CardContent>

    </Card>
}

