import { CognitoUser } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TransactionFunctions, UserFunctions } from '../actions/AppActions';
import UserManagementView from '../components/SytemManagement/Users/UserManagementView';
import { RootState } from '../reducers';
import { UserGroupsRolesType } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const mapStateToProps = (state: RootState, ownProps: any) => {
	debug && console.log('map state to props',  state.AppReducer.contextCustomer)
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer.user,
    contextCustomer:  state.AppReducer.contextCustomer,
    messages: state.AppReducer.messages,
    errors: state.AppReducer.errors,
    match: ownProps.match,

  
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
		   debug && console.log('User Management View requested init', ownProps.location && JSON.stringify(ownProps.location, null, 1))

    },
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementView)