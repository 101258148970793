import { getNiceDate, NamedPoint, ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, makeStyles, Radio, TableCell, TableRow } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../../cosmetics/sharedCardStyles';
import Assembler from '../../data/Assembler';
import { AdjacentType, ComponentMode, ListerlizerRowControlFnProps, ListerlizerRowControlFnType, ListerlizerrRowProps, pseudoEvent, ViewKeyDefinitionType } from '../../types/AppTypes';
import { completeAssign } from '../../util/AssignSetterGetters';
import { NavSections } from '../navigation/NavSections';
import { BooleanComponent } from './inputFields/BooleanComponent';
import { EnumComponent } from './inputFields/EnumComponent';
import { TextComponent } from './inputFields/TextComponent';

const debug = process.env.REACT_APP_DEBUG && false;
const keys_debug = process.env.REACT_APP_DEBUG && false;
const instance_debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => (styles(theme)));

export function ListerlizerRow(props: ListerlizerrRowProps) {
    const { transactionFunctions, functionOverrides, rowControlOverrideFns, matchedPrimary, rowObject, viewDefinition, history, mode, isSelected, optionals } = props;
    const [rowMode, setRowMode] = React.useState(mode);
    const [localRowObject, setLocalRowObject] = React.useState(rowObject);
    const parentObject = matchedPrimary;
    const rowSelectionFirst = (optionals?.rowSelectionFirst === true);

    const rowObjectHash = Object.values(localRowObject).map( (val) => typeof val === 'string' ? val.length : typeof val === 'number' || typeof val === 'boolean' ? val : '' ).join('');

    instance_debug && console.log('Listerlizer gets a row object intance', rowObject instanceof NamedPoint)

    useEffect(() => {
        debug && console.log('Listerlizer Row used effect', rowObject.sk)
        setLocalRowObject(rowObject)
    }, [rowObject.sk]);

    useEffect(() => {
        debug && console.log('Listerlizer Row mode used effect', rowMode )
    }, [rowMode])


    useEffect(() => {
        debug && console.log('Listerlizer Row used effect on hash', rowObjectHash )
    }, [rowObjectHash])

    const classes = useStyles();


    const updateKV = (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent, k: string) => {
        debug && console.log('in updateKV', { event, k });
        const target = event.currentTarget;
        const { type, checked, value } = target;
        let parsedValue: any = value;
        switch (type) {
            case 'number': {
                const intermediateParsedValue = parseFloat(value) || parseInt(value);
                parsedValue = isNaN(intermediateParsedValue) ? undefined : intermediateParsedValue;
                break;
            }
            case 'checkbox': parsedValue = (checked === true); break;

            default: break;
        }
        const clonedInstance = Assembler.getInstance(localRowObject);
        let clonedMutant: ValidBusinessObject | undefined = undefined
        if (clonedInstance ) {
            clonedInstance[k] = parsedValue;
            debug && console.log('Cloned clonedInstance', clonedInstance.name)
            clonedMutant = Object.assign({}, clonedInstance );
            debug && console.log('Cloned clonedMutant', clonedMutant.name)
        }

        // const completelyAssigned = completeAssign({}, localRowObject, {[k]: parsedValue } )
        // debug && console.log('completelyAssigned', completelyAssigned)
        // debug && console.log('completelyAssigned name', completelyAssigned?.name)

        const update = clonedMutant ?? Object.assign({},  localRowObject, {[k]: parsedValue }); // { ...localRowObject, [k]: parsedValue };
        setLocalRowObject(update);
        debug && console.log('isEQueal', isEdited());
        instance_debug && console.log('localRowOject in state is NamedPoint?', { isNP: localRowObject instanceof NamedPoint, typeof: typeof localRowObject})

    }

    const isEdited = () => !Object.entries(localRowObject).every((([k, v1]) => v1 === rowObject[k]));

    const switchOnAttTypeEdit = (matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        const key = `${matchedPrimary.sk}_${viewKeyDefinition.key}_EditComponent`
        switch (viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return <EnumComponent {...{ matchedPrimary, viewKeyDefinition, mode, updateKV, key}}></EnumComponent>;
            case AppValueTypes.BOOLEAN: return <BooleanComponent {...{ matchedPrimary, viewKeyDefinition, mode, updateKV, key }}></BooleanComponent>;
            default: return <TextComponent {...{ matchedPrimary, viewKeyDefinition, mode, updateKV, functionOverrides, key }}></TextComponent>
        }
    }

    const switchOnAttTypeView = (matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        instance_debug && console.log('rowObject is NamedPoint?', { isNP: matchedPrimary instanceof NamedPoint, typeof: typeof matchedPrimary})
        const value = matchedPrimary[viewKeyDefinition.key];
        switch (viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return <EnumComponent {...{ matchedPrimary, viewKeyDefinition, mode, updateKV }}></EnumComponent>
            case AppValueTypes.BOOLEAN: return <BooleanComponent {...{ matchedPrimary, viewKeyDefinition, mode, updateKV }}></BooleanComponent>;
            case AppValueTypes.DATE: return value ? getNiceDate( new Date(value ).valueOf(), { dateStyle: 'short'}) : value;
            default: return viewKeyDefinition.valueGetter ? viewKeyDefinition.valueGetter(value) : value

        }
    }


    const switchOnMode = (matchedPrimary: ValidBusinessObject, rowObject: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        const clonedInstance = Assembler.getInstance(rowObject) ?? rowObject;
        instance_debug && console.log('display clonedInstance is NamedPoint?', clonedInstance instanceof NamedPoint)
        instance_debug && console.log('display clonedInstance should have name', clonedInstance.name)
        switch (rowMode) {
            case ComponentMode.VIEW: return [
                
                <TableCell {...{ key: `${rowObject.sk}_${viewKeyDefinition.key}-view`, align: "left" }} > {switchOnAttTypeView(clonedInstance, viewKeyDefinition, mode)}</TableCell>
            ]
            case ComponentMode.EDIT: return [
                <TableCell key={`${matchedPrimary?.sk}_${rowObject.sk}_${viewKeyDefinition.key}-edit`} className={classes.tableCellNoPadding} align="left">
                    {switchOnAttTypeEdit(clonedInstance, viewKeyDefinition, mode)}
                </TableCell>

            ]
            default: return <div></div>

        }
    }

    const getRowEditControls: ListerlizerRowControlFnType = ({ contextObject, rowObject, viewDefinition, rowMode, transactionFunctions, history }: ListerlizerRowControlFnProps) => {
        const keyPrefix = `${contextObject?.sk}_${rowObject?.sk}`
        const [parentObject, linkedObject] = props.adjacentFilter.adjacentType === AdjacentType.PARENT ? [rowObject, contextObject] : [contextObject, rowObject]
        return [
            <ButtonGroup key={`${keyPrefix}_buttonGroup`}
                size = 'small'
                orientation="horizontal"
                color="primary"
                aria-label="vertical outlined primary button group"
            >
                <Button
                    key={`${keyPrefix}_Button_1`}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() =>  { history ?  transactionFunctions.deleteObject(rowObject, history): console.log('WARN No history on delete!!') } }
                    startIcon={<DeleteForeverIcon />}
                >
                    Delete
                </Button>
                <Button
                    key={`${keyPrefix}}_Button_2`}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => transactionFunctions.unlink(parentObject, linkedObject, rowObject.egdeTypeId)}
                    startIcon={<LinkOffIcon />}
                >
                    Unlink
                </Button>
                <Button
                    key={`${keyPrefix}}_Button_3`}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => transactionFunctions.saveAndLinkObject(contextObject.sk, localRowObject, props.adjacentFilter)}
                    startIcon={<SaveIcon />}
                    disabled={!isEdited()}
                >
                    Save
                </Button>
            </ButtonGroup>

        ]
    }



    const getListerlizerRows = () => Object.entries(viewDefinition).map(([key, viewKeyDefinition], i) => switchOnMode(parentObject, localRowObject, viewKeyDefinition, mode))

    const getEditOrActionCell = (rowProps: ListerlizerRowControlFnProps) => {
        if (mode === ComponentMode.EDIT) {
            return getRowEditControls(rowProps)
        } else {
            return rowControlOverrideFns ? [] : [
                <TableCell {...{ key: `_rightArrow` }}>
                    {functionOverrides?.selectItem

                        ? <Radio size='small' checked={isSelected} onClick={() => functionOverrides?.selectItem(localRowObject.sk)}></Radio>

                        : <NavLink key={`_navLink`} to={`/${NavSections.THINGVIEW}/${rowObject.type}/${rowObject.id}`}>
                            <ArrowRightIcon
                                key={`_rightIcon`}
                            >
                            </ArrowRightIcon>
                        </NavLink>
                    }
                </TableCell>]



        }

    }
    debug && console.log('Listerlizer rowSelectionFirst', rowSelectionFirst);
    const rowProps: ListerlizerRowControlFnProps = { contextObject: parentObject, rowObject: localRowObject, viewDefinition, rowMode, setRowMode, transactionFunctions, history };
    const firstRowControls: JSX.Element[] = [];
    const lastRowControls: JSX.Element[] = [];
    rowControlOverrideFns?.first?.forEach((fc) => fc(rowProps).forEach((el) => firstRowControls.push(el)));
    rowControlOverrideFns?.last?.forEach((fc) => fc(rowProps).forEach((el) => lastRowControls.push(el)));


    const arbitratedControls = {
        first: rowSelectionFirst ? [...firstRowControls, ...getEditOrActionCell(rowProps)] : [...firstRowControls, ...getListerlizerRows()],
        last: rowSelectionFirst ? [...getListerlizerRows(), ...lastRowControls] : [...getEditOrActionCell(rowProps), ...lastRowControls]
    }
    keys_debug && console.log('Listerlizer key', rowObject.sk);
    keys_debug && console.log(firstRowControls)
    //{ ...{ key: `${rowObject.sk}_row` }}
    return (
        <TableRow { ...{ style: { }}} >

            {[...arbitratedControls.first, ...arbitratedControls.last]}


        </TableRow>

    );
}