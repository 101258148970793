import { getZombieInstanceFromPrimaryKey, ObjectHistory } from '@iotv/datamodel-core'
import { AdjacentType, HistoryObjectType, ValidBusinessObject } from '@iotv/iotv-v3-types'
import { Card, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getObject } from 'src/data/aws/s3/UserBlobs'
import { getObjectHistory } from '../../../data/daoFunctions/historyFns'
import { getAdjacent2Wrapper, getOne } from '../../../data/daoFunctions/daoFunctions'
import { LineChartXType } from '../../../types/AppTypes'
import { useStyles } from '../cosmetics/communityStyles'
import { RangeableLineChart } from './charts/RangeableLineGraph'


export const CommunityFillLevelGraph = () => {
    
    const history = useHistory()
    const styles = useStyles()   
    
    const [ objectHistory, setObjectHistory ] = useState<ObjectHistory>()
    const [ historyObject, setHistoryObject ] = useState<HistoryObjectType>()
    
    useEffect(() => {
        const getStuff = async () => {
            const communityID = history.location.pathname.replace('/COMMUNITYVIEW/', '').replace('/report', '')
            const zombie = getZombieInstanceFromPrimaryKey(`Customer:${communityID}`) as ValidBusinessObject;
            const community = await getOne(zombie)
            console.log(community)

            getObjectHistoryLocal(community.data as ValidBusinessObject)
        }

        getStuff()
    }, [])

    const getObjectHistoryLocal = async (object : ValidBusinessObject) => {
        const objectHistoryRes = await getObjectHistory( object )
        if(objectHistoryRes.data ){
            const objectHistory = objectHistoryRes.data

            console.log(objectHistory)
            let history : HistoryObjectType = {
                index : [] as number[],
                timestamp : [] as number[],
                current_filled_volume : [] as number[]
            }

            if(objectHistory){
                history = {
                    index : objectHistory.dailyStateHistory?.index as number [],
                    timestamp : objectHistory.dailyStateHistory?.timestamp as number [],
                    formattedGroupCFV : objectHistory.dailyStateHistory?.current_filled_volume as number[]
                }
            }

            console.log(objectHistory)
            setObjectHistory(objectHistory as ObjectHistory)
            setHistoryObject(history)

        }
    }

    const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
        let fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 7);
        const unixFromDate = (fromDate.valueOf() / 1000) * 1000
        let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate)
        lowIdx = lowIdx < 0 ? historyObject.index.length - 1 : lowIdx
    
        let maxIdx = historyObject.index.length - 1
        const wholeKeys = Object.keys(historyObject);
        const keys = wholeKeys.filter((key) => key === graphKey)
        const xType: LineChartXType = LineChartXType.TIMESERIES

        console.log(historyObject)
    
        const rows = keys.map((key) => {
          return historyObject[key] &&
            <RangeableLineChart {...{ historyObject, xType, keys: [key], lowIdx, maxIdx }}></RangeableLineChart>
        }).filter((element) => element)
        return rows;
      }

    return (
        <Card>
            {historyObject && historyObject.index.length > 0 ? getGraph(historyObject as HistoryObjectType, [], 'formattedGroupCFV') : <Typography variant='h6' align='center'>No Data available</Typography>}
        </Card>

    )

}