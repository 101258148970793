import { makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { GoogleMap } from '.';
import { mapStyles } from '../../../cosmetics/MapCardStyles';
import styles from '../../../cosmetics/sharedViewStyles';
import { MapCardProps, NormalizedData } from '../../../types/AppTypes';
import { GoogleMapPropsV2 } from './GoogleMap';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => {
  return {
      ...mapStyles(theme),
  }
});


function MapViewBase( props:  MapCardProps & { classes: any, normalData?: NormalizedData, googleMapScriptLoaded?: boolean } & GoogleMapPropsV2 ): JSX.Element {
    const localClasses = useStyles();
    const { classes, geoImplementingObject, normalData = {}, viewObjects, polygons, locatableAddress, googleMapScriptLoaded } = props;
   
    debug && console.log('Map view got', props)
    debug && console.log('MapView Editable', props.editable, geoImplementingObject)

    return (

            <div  className = { localClasses.mapViewObjectCard}>
                    { googleMapScriptLoaded && <GoogleMap { ...{ } } ></GoogleMap> }
               
            </div>

    );
    
}

const MapView =  withStyles(styles)(MapViewBase)

export { MapView };

