import { Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TreeView from '@material-ui/lab/TreeView';
import { MessageOutputType, ObjectTreeRootProps, UserTransactionType, ValidTypeLinkage, ViewObject } from '../../../types/AppTypes';
import { getViewObjectsHash } from '../../../util/AppData/viewObject';
import { ObjectTreeItem } from './ObjectTreeItem';
import { AddItem } from './AddItem';
import { ValidBusinessObject } from '@iotv/datamodel';
import { createAndLink, CreateAndLinkRequest, deleteObject } from '../../../data/daoFunctions/daoFunctions';
import { v4 } from 'uuid';
import { UserFunctions } from '../../../actions/AppActions';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  textField: {
    
    // paddingLeft: '1rem',
    margin: '1rem'
  },
  smaller: {
    fontSize: 10
  },
  test: {
    color: 'orange', fontSize: 15, //marginLeft: '1rem'
  },
  radio: {
   fontSize: 10,
  }

});

const displayMessage = (content: string, type: MessageOutputType) => {
  UserFunctions.setUserMessage({ id: v4(), content, label: content, type })
}


export const ObjectTreeRoot = ( { contextObject, viewObjects: viewObjectsIn, mainAdjacencyQuery, branchAdjacencyQueries, setSelectedInParent,  ...others }: ObjectTreeRootProps ) => {
  
    const classes = useStyles();

    const [ viewObjects, setViewObjects ] = React.useState<ViewObject[]>(viewObjectsIn)
    const viewObjectsInHash = getViewObjectsHash(viewObjectsIn);
    const viewObjectsHash = getViewObjectsHash(viewObjects);

    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);

    const [ filterRegex, setFilterRegex ] = React.useState<string | undefined>( undefined );
    const [ filteredViewObjects, setFilteredViewObjects ] = React.useState<ViewObject[]>(viewObjects)
    const [ validRegex, setValidRegex ] = React.useState<boolean>(true)
    const [ mode, setMode ] = React.useState<UserTransactionType>(UserTransactionType.LINK)

    const newObjectNodeId = `${'hmmTree'}_new`

  //   const validTypes: ValidTypeLinkage[] = ( branchAdjacencyQueries && branchAdjacencyQueries.length >0 ) ? branchAdjacencyQueries.map( (query) => {
  //     const { objectTypeId, adjacencyType, edgeTypeId, filter } = query; // hence AdjanceyQuery is mappable to 
  //     return { objectTypeId, adjacencyType, edgeTypeId, filter }
  // }): [ ];  

  const validTypes: ValidTypeLinkage[] = [ mainAdjacencyQuery ];  


  debug && console.log('ObjectTreeRoot gets viewObjecs', viewObjects.length)
  let doubleClickTimeout: NodeJS.Timeout | undefined = undefined;

  useEffect(() => {
    debug && console.log('Object Tree used effect on viewObjectsInHash change', { viewObjectsHash, viewObjectInsHash: viewObjectsInHash })
    setViewObjects(viewObjectsIn)

  }, [ viewObjectsInHash ])


    useEffect(() => {
      debug && console.log('Object Tree used effect on viewObjectsHash change', { viewObjectsHash, viewObjectInsHash: viewObjectsInHash })
      setFilteredViewObjects(viewObjects)

    }, [ viewObjectsHash  ])

    useEffect(() => {
      debug && console.log('Object Tree used effect on mode change')
    }, [ mode ])

    const handleToggle = (event: any, nodeIds: string[]) => {
      setExpanded(nodeIds);
    };
  
    const handleNodeSelect = (event: any, nodeIds: string[]) => {
      if ( doubleClickTimeout ) {
        doubleClickTimeout = undefined
      } else {
        doubleClickTimeout = setTimeout( () => {
          setSelected(nodeIds);
          setSelectedInParent(nodeIds.filter( (nodeId ) => nodeId !== newObjectNodeId ))
          debug && console.log('ObjectTreeRoot selected nodeIds', nodeIds)
        }, 500)
      }
     
    };

    const handleFilterRegexChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
      const value: string | undefined = event.target.value
      console.log('did regex ', value.length)
      if (value.length > 0) {
        try {
          const regex = new RegExp(value, 'i');
          setValidRegex(true);
          setFilteredViewObjects( viewObjects.filter( (viewObject) => viewObject.matchedPrimary !== undefined && regex.test( viewObject.matchedPrimary.name)) )
        } catch (err) {
          setValidRegex(false);
        }      
      }     
    }

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = (event.target as HTMLInputElement).value as UserTransactionType;
    setMode(newMode);
    if ( newMode === UserTransactionType.LINK ) {
      setSelectedInParent(selected.filter( (nodeId ) => nodeId !== newObjectNodeId ))
    } else {
      setSelectedInParent([])
    }
  };

  const handleSave = async ( newObject: ValidBusinessObject | undefined ) => {
    if ( newObject && contextObject ) {
      const params: CreateAndLinkRequest = {
        existingObject:  contextObject, newObject,
      }
      if ( newObject.type === mainAdjacencyQuery.objectTypeId && mainAdjacencyQuery.filter ) {
        const queryRequiredAttribute = mainAdjacencyQuery.filter.filters[0]
        if ( queryRequiredAttribute ) {
          newObject[queryRequiredAttribute.key] = queryRequiredAttribute.value;
        }
      }

      const linkRes = await createAndLink( params )
      if (linkRes.data && linkRes.data instanceof Array ) {
        const mutatedViewObjects = [ ...viewObjects ];
        if ( !mutatedViewObjects.find( (item) => item.matchedPrimary?.sk === newObject.sk)) {
          mutatedViewObjects.push( { matchedPrimary: newObject, matchedRelatedByPk: [], matchedRelatedBySk:[] })
          setViewObjects(mutatedViewObjects)
        }
      }
    } else {
      debug && console.log('No new obehct')
    }
   
  }

  const handleDeleteFromHere = async (e: any, deleteItem: ValidBusinessObject ) => {
    e.preventDefault(); console.log('End icon click ', deleteItem)

        const deleteRes = await deleteObject(deleteItem);
        if (deleteRes.data) {
            debug && console.log('ObjectTreeItem deleted res', deleteRes.data)

                const mutatedViewObjects = [...viewObjects]
                const idx = mutatedViewObjects.findIndex((item) => item.matchedPrimary?.sk === deleteItem.sk)
                if (idx !== -1) {
                    debug && console.log('ObjectTreeItem existing to be deleted idx', idx)
                    mutatedViewObjects.splice(idx, 1);
                    setViewObjects(mutatedViewObjects)
                } else {
                    debug && console.log('ObjectTreeItem nothing to delete', { mutatedRelateds: mutatedViewObjects, idx, matchedPrimary: deleteItem })
                }

          } else if (deleteRes.err) {
                displayMessage(`failed to delete ${deleteItem.name} because ${deleteRes.err.message}`, 'MessageBar')
            }
      }


  const getModeRadioButtons = () => {

    const getRadio = () => <Radio { ...{ size: 'small',  classes: {
      root: classes.radio
    },}}></Radio>

    return (
      <FormControl component="fieldset" size='small' >
        <FormLabel component="legend" classes = { {root: classes.radio}}  >Mode</FormLabel>
        <RadioGroup row  aria-label="mode" name="mode" value={mode} onChange={handleModeChange} className= {classes.smaller}>
          <FormControlLabel value= {UserTransactionType.LINK} classes = { {label: classes.radio}} control={getRadio()} label="Link"  />
          <FormControlLabel value= {UserTransactionType.UNLINK} classes = { {label: classes.radio}} control={getRadio()} label="Unlink"  />
          <FormControlLabel value= {UserTransactionType.DELETE} classes = { {label: classes.radio}} control={getRadio()} label="Delete" />
          <FormControlLabel value= {UserTransactionType.CREATE_AND_LINK} classes = { {label: classes.radio}} control={getRadio()} label="Add" />
        </RadioGroup>
      </FormControl>
    );
  }


    return (
      <>
        { getModeRadioButtons()}
        { viewObjects.length > 20 ? <TextField { ...{
          onChange: handleFilterRegexChange, variant: 'outlined', size: 'small', className: classes.textField, label: "Regex Filter", error: !validRegex, helperText: (validRegex ? '' : 'invalid regex'),
          InputProps: {
            classes: {
              input: classes.smaller,
            },
          },
          InputLabelProps: {
            classes: {
              root: classes.test
            },
            shrink: true
          },
          
        }}>{filterRegex}</TextField> : <TextField { ...{ style: { visibility: 'hidden' }}}></TextField>}

        <TreeView { ...{
          className: classes.root,
          defaultExpanded: ['5'],
          defaultCollapseIcon: <ArrowDropDownIcon />,
          defaultExpandIcon: <ArrowRightIcon />,
          defaultEndIcon: <div style={{ width: 24 }} />,
          onNodeSelect: handleNodeSelect,
          ...others
        }}
       
      >
       { filteredViewObjects.map( ( viewObject: ViewObject, i) => {
        const handleDeleteInParent = ( e: any ) => viewObject.matchedPrimary && handleDeleteFromHere(e, viewObject.matchedPrimary )
        return <ObjectTreeItem { ...{ contextObject, viewObject, mainAdjacencyQuery, branchAdjacencyQueries, level: 1, key: `0_${viewObject.matchedPrimary?.sk ?? i}`, mode, handleDeleteInParent }}></ObjectTreeItem> 
       }) }
        
       {mode === UserTransactionType.CREATE_AND_LINK && <AddItem { ...{ contextObject, validTypes , nodeId: newObjectNodeId, handleSave, mode, level: 1, }}></AddItem>}
      </TreeView>
      </>
      
    );
  
}