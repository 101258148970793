import { AdjacentType, ValidBusinessObject } from '@iotv/datamodel';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import React, { useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import AppDao from '../../data/AppDao';
import { MapLike, SearchByTypeResponse, SelectorConfigParams, TransactionResponse } from '../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;


type OptionType = { label: string; value: string } ;


type SelectorProps = { setListLinkSelectedItems: Function, getListLinkSelectedItems: ValidBusinessObject[], adjacencyType: AdjacentType
  , pageContextObject: ValidBusinessObject, scopeDefiningContextObject: ValidBusinessObject | undefined, typeId: string, exclusiveStartKey: DocumentClient.Key | undefined
  , selectorConfigParams: SelectorConfigParams, setLastEvaluatedKey?: ( key: DocumentClient.Key | undefined ) => void
  setError?: ( err: string | undefined ) => void, setLoading?: ( loading: boolean ) => void
}

function useSearch(pageContextObject: ValidBusinessObject, scopeDefiningContextObject: ValidBusinessObject | undefined, adjacencyType: AdjacentType, includeContextLinkedItems: boolean, typeId: string
  , exclusiveStartKey: DocumentClient.Key | undefined, setLastEvaluatedKey?: ( key: DocumentClient.Key | undefined) => void, setError?: ( err: string | undefined ) => void, setLoading?: ( loading: boolean ) => void 
  ) {
  const [err, setErr] = React.useState(null as any);
  const [items, setItems] = React.useState(undefined as ValidBusinessObject[] | undefined)
  const [count, setCount] = React.useState(0);



  async function executeSearch() {
    let errData: SearchByTypeResponse | null = { err: null, data: null};
    
    try {
      setLoading && setLoading(true);
      if ( scopeDefiningContextObject ) {
        debug && console.log('Executing Limited List search')
        let excludeRelatedToObject: ValidBusinessObject | undefined = includeContextLinkedItems ? undefined : pageContextObject;
        const contextAdjacentRes =   await AppDao.getAdjacent2( excludeRelatedToObject, scopeDefiningContextObject, adjacencyType, typeId, exclusiveStartKey, 30, undefined);
        debug && console.log('contextAdjacentRes', contextAdjacentRes)
        errData = contextAdjacentRes as TransactionResponse<any, { Items: ValidBusinessObject[]; Count: number; ScannedCount: number; LastEvaluatedKey: MapLike; }>

      } else {
        errData = await AppDao.searchByType(pageContextObject, includeContextLinkedItems, typeId, exclusiveStartKey );

        debug && console.log('Executing All Type search')
      }
      
      if (errData.data?.Items) {
        const lek = errData.data.LastEvaluatedKey
        setItems(errData.data.Items);
        setCount(errData.data.Count);
        setLastEvaluatedKey  && setLastEvaluatedKey(lek);
      } else {
        setItems([]);
        setCount(0);
        setLastEvaluatedKey && setLastEvaluatedKey(undefined);
      }
      setErr(errData.err);
      
    } catch (e: any) {
      setError && setError(e);
    } finally {
      setLoading && setLoading(false);
    }
  }
  
  useEffect(() => {
    executeSearch();
  }, [scopeDefiningContextObject?.sk, exclusiveStartKey]);

  return {items, err, count };
}



export const Selector = (props: SelectorProps) => {
  const { pageContextObject: pageContextObjectIn, scopeDefiningContextObject, adjacencyType, typeId, exclusiveStartKey, setListLinkSelectedItems, selectorConfigParams, setLastEvaluatedKey, setError, setLoading } = props;
  const includeContextLinkedItems = selectorConfigParams.includeContextLinkedItems ?? false;
  let pageContextObject = pageContextObjectIn;
  if ( includeContextLinkedItems === true) {
    pageContextObject = selectorConfigParams?.selectorContextItem ?? pageContextObjectIn;
  }
  let options: OptionType[] = [{value: 'waiting', label: 'waiting'}]
  const [selectedOption, _setSelectedOption] = React.useState<ValueType<OptionType[], true>>();
 
  const res = useSearch(pageContextObject, scopeDefiningContextObject, adjacencyType, includeContextLinkedItems, typeId, exclusiveStartKey, setLastEvaluatedKey, setError, setLoading)
  const { items } = res;


  if (items ) {
    options = items.map( (item) =>{ return {label: item.name, value: item.sk}} )
    //setSelectedOption(options[0])
  }



  debug && console.log('Search results:', res)

  const handleChange = (option: ValueType<OptionType, boolean>) => {
    debug && console.log('Select handle change got', option);
    let childObjects: ValidBusinessObject[] = []
    if (option instanceof Array) {
      const childObjectSks = option ? Object.values(option as OptionType[]).map( (opt) => opt.value) : [];
      childObjects = items ? items.filter( (childObject) => childObjectSks.includes(childObject.sk)) : [];
    } else if ( items && option ) {
      debug && console.log('Not Multiple Select', {items, options})
      const matchedOption = items.find( (childObject) => childObject.sk === (option as any).value)
      childObjects =  matchedOption ? [matchedOption] : [];
    }

    

    setListLinkSelectedItems(childObjects)
    //setSelectedOption(option);
  };


  return <Select 
  
 
  { ...{
    value: selectedOption as ValueType<OptionType, false>,

    onChange: (option, actionMeta) => handleChange(option),
    isMulti: selectorConfigParams.multipleSelect as false | undefined, // suspect underlying type issue
    options: options, 

    
    
   }}
/>

};