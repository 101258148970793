import { Tank, ValidModelObjects } from '@c021/datamodel';
import { CustomerAccountType, roundDecimals, ValidModelObject } from '@iotv/datamodel-core';
import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { GridSortItem, GridColDef, GridCellParams, DataGrid } from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DispatchFunctionsType, NormalizedData, ObjectCardProps } from '../../../..//types/AppTypes';
import { getParentObjects } from '../../../../data/daoFunctions/stateFns';
import { useStyles } from '../../cosmetics/communityStyles';
import { getColour } from '../CommunitiesView';
import AddTank from './AddTank';

type CustomerTankPair = {
  id: string;
  customer: ValidBusinessObject;
  tank: Tank;
  tankCommunity: ValidBusinessObject;
};

const debug = process.env.REACT_APP_DEBUG && false;

export type CommunityTanksCardProps = {
  householdTanks: ValidModelObjects<'Tank'>;
  ICCTanks: ValidModelObjects<'Tank'>;
  normalCustomerData: NormalizedData;
  setUpdate: Dispatch<SetStateAction<boolean>>;
  transactionFunctions: DispatchFunctionsType;
  user: ValidBusinessObject | undefined;
} & ObjectCardProps;

export const CommunityTanksCard = ({
  householdTanks,
  ICCTanks,
  viewObject: { matchedPrimary },
  normalCustomerData,
  transactionFunctions,
  setUpdate,
  userFunctions,
  user,
  history,
}: CommunityTanksCardProps) => {
  debug && console.log('CommunityTanksCard has pair', { householdTanks, ICCTanks, normalCustomerData });
  const styles = useStyles();
  const routerHistory = useHistory();

  const customerIn: ValidModelObject<'Customer'> | undefined =
    matchedPrimary?.type === 'Customer' ? (matchedPrimary as ValidModelObject<'Customer'>) : undefined;
  const [addTank, setAddTank] = useState<boolean>(false);
  const [addTankType, setAddTankType] = useState<string>('');

  const [filter, setFilter] = useState('');
  const [customerTankPairs, setCustomerTankPairs] = useState<CustomerTankPair[]>([]);
  const [pairsToDisplay, setPairsToDisplay] = useState<CustomerTankPair[]>([]);
  const [entity, setEntity] = useState<ValidModelObject<'Customer'> | undefined>(customerIn);

  const filterPairs = (pairs: CustomerTankPair[]) => {
    const filteredPairs = filter
      ? filter === 'Household'
        ? pairs.filter((pair) => pair.tank.tank_type === filter)
        : pairs.filter((pair) => {
          const parentObjects = getParentObjects(normalCustomerData, pair.tank.sk);

          const notHousehold = parentObjects?.[0]?.accountType !== CustomerAccountType.Z;
          return notHousehold;
        })
      : customerTankPairs;
    debug && console.log('CommunityTanksCard filtered', { filter, filteredPairs, customerTankPairs });
    return filteredPairs;
  };

  const normalDataHash =
    Object.keys(normalCustomerData ?? {})
      .filter((k) => k.startsWith('Customer'))
      .join() +
    ICCTanks.length +
    householdTanks.length;

  useEffect(() => {
    if (entity) {
      setCustomerTankPairWrapper(entity);
    }
  }, [normalDataHash]);

  useEffect(() => {
    if (customerIn) {
      setEntity(customerIn);
    }
  }, [customerIn?.sk]);

  const getFirstParentCustomerOfType = (tank: ValidBusinessObject, parentType: CustomerAccountType, normalData: NormalizedData) => {
    let match: ValidBusinessObject | undefined = undefined;
    let lastResult: ValidBusinessObject | undefined = tank;
    const maxIterations = 3;
    let i = 0;
    do {
      const parents = getParentObjects(normalCustomerData, lastResult.sk);
      lastResult = undefined;
      for (let item of parents) {
        if (item.type === 'Customer' && item.accountType === parentType) {
          match = item;
        }
        lastResult = item;
      }
      i++;
    } while (lastResult !== undefined && match === undefined);
    return match;
  };

  const setCustomerTankPairWrapper = (customer: ValidModelObject<'Customer'>) => {
    if (customer) {
      const tanks = [...householdTanks, ...ICCTanks];
      const customerTankPairs: CustomerTankPair[] = [];

      tanks.forEach((tank: ValidBusinessObject): void => {
        const consumerCustomer = getFirstParentCustomerOfType(tank, CustomerAccountType.Z, normalCustomerData);
        const communityCustomer = getFirstParentCustomerOfType(tank, CustomerAccountType.Y, normalCustomerData);
        const countryCustomer = getFirstParentCustomerOfType(tank, CustomerAccountType.X, normalCustomerData);
        const customer = consumerCustomer ?? communityCustomer ?? countryCustomer;
        const tankCommunity = communityCustomer ?? countryCustomer ?? customer;

        let tanker: Tank = new Tank(tank);
        tanker.tank_type = tank.tank_type;
        if (customer && tankCommunity) {
          const pair = {
            id: tank.id,
            customer,
            tank: tanker,
            tankCommunity,
            // tank_type: tank.tank_type,
          };
          console.log("whatistheprops", pair)
          customerTankPairs.push(pair);
        } else {
          debug && console.log('CommunityTanksCard did not add', { tank, consumerCustomer, communityCustomer, countryCustomer });
        }
      });
      debug && console.log('CommunityTanksCard setting pairs', { normalDataHash, customer, customerTankPairs, normalCustomerData });
      setCustomerTankPairs([...customerTankPairs]);
      setPairsToDisplay([...customerTankPairs]);
    }
  };

  const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
    {
      field: 'tankName',
      sort: 'asc',
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      minWidth: 300,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.customer?.name ? params.row.customer.name : 'No customer attached'}</>;
      },
    },
    {
      field: 'tankName',
      headerName: 'Name',
      flex: 1,
      minWidth: 300,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.name}</>;
      },
    },
    {
      field: 'tankType',
      headerName: 'Tank Type',
      // flex: 1,
      minWidth: 125,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.tank_type}</>;
      },
    },
    // {
    //   field: 'community',
    //   headerName: 'Community',
    //   flex: 1,
    //   minWidth: 300,
    //   headerAlign: 'center',
    //   align: 'center',
    //   type: 'string',
    //   renderCell: () => {
    //     return <>{matchedPrimary?.name}</>;
    //   },
    // },

    {
      field: 'fillLevel',
      headerName: 'Fill Level',
      // flex: 1,
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {

        const fillPercentage = roundDecimals(((params.row.tank.tankLevel ?? 0) / (params.row.tank.height ?? 1)) * 100, 0);
        let colour = getColour(fillPercentage);

        return (
          <div style={{ backgroundColor: colour, width: '95%' }}>
            {typeof fillPercentage === 'number' && !isNaN(fillPercentage) ? `${fillPercentage}%` : 'Calculating...'}
          </div>
        );
      },
    },
    {
      field: 'runOutDate',
      headerName: 'Run Out Date',
      // flex: 1,
      minWidth: 145,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      renderCell: (params: GridCellParams) => {
        const daysLeft = params.row.tank.days_to_empty;

        const currentDate = new Date();
        const runOutDate = new Date(currentDate);
        runOutDate.setDate(runOutDate.getDate() + daysLeft);

        const altRunOutDate = new Date(currentDate);
        altRunOutDate.setDate(altRunOutDate.getDate() + 100);

        return <>{!isNaN(daysLeft) && daysLeft <= 100 ? runOutDate.toLocaleDateString() : `After ${altRunOutDate.toLocaleDateString()}`}</>;
      },
    },
    {
      field: 'daysLeft',
      headerName: 'Days Left',
      // flex: 1,
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        let daysLeft = params.row.tank.days_to_empty;
        let colour = '';

        if (daysLeft > 60) {
          colour = '#99ff99';
        } else if (daysLeft > 20 && daysLeft <= 60) {
          colour = '#ffff66';
        } else if (daysLeft > 7 && daysLeft <= 14) {
          colour = '#ffcc99';
        } else {
          colour = '#ff8080';
        }

        return (
          <div style={{ backgroundColor: colour, width: '95%' }}>
            {!isNaN(daysLeft) ? (daysLeft <= 100 ? `${daysLeft} Days` : `> 100 Days`) : 'Calculating...'}
          </div>
        );
      },
    },
    {
      field: 'numTankUsers',
      headerName: 'Users',
      // flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.no_of_users}</>;
      },
    },
    {
      field: 'yesterdaysUsage',
      headerName: "Yesterday's Use",
      // flex: 1,
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <> {!isNaN(params.row.tank.volume_used_yesterday) ? `${Math.round(params.row.tank.volume_used_yesterday)} L` : 'Calculating...'}</>;
      },
    },
    // { TO be included in later release
    //     field : 'avgDailyUsage',
    //     headerName : 'Average Daily Usage (at start of stage)',
    //     flex : 1,
    //     minWidth : 305,
    //     headerAlign: 'center',
    //     align : 'center',
    //     type : 'number',
    // },
    {
      field: 'currentAvgDailyUsage',
      headerName: 'Average Daily Use',
      // flex: 1,
      minWidth: 175,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{!isNaN(params.row.tank.averageDailyUsage) ? `${Math.round(params.row.tank.averageDailyUsage)} L` : 'Calculating...'}</>;
      },
    },
    {
      field: 'currentAvgDailyUsagePerPerson',
      headerName: 'Average User Daily Use',
      // flex: 1,
      minWidth: 205,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {!isNaN(params.row.tank.averageDailyUsage) && !isNaN(params.row.tank.no_of_users)
              ? `${Math.round(params.row.tank.averageDailyUsage / params.row.tank.no_of_users)} L`
              : 'Calculating...'}
          </>
        );
      },
    },
    {
      field: 'deviceBatteryVoltage',
      headerName: 'Battery',
      // flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      type: 'number',

      renderCell: (params: GridCellParams) => {
        return <>{!isNaN(params.row.tank.deviceBatteryVoltage) ? `${roundDecimals(params.row.tank.deviceBatteryVoltage, 2)} V` : 'Calculating...'}</>;
      },
    },
    {
      field: 'numOfTanks',
      headerName: 'Tanks',
      // flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      type: 'number',

      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.no_of_tanks ? params.row.tank.no_of_tanks : '1'}</>;
      },
    },
    // {
    //     field : 'changeInDailyUsage',
    //     headerName : 'Change in Daily Usage',
    //     flex : 1,
    //     minWidth : 210,
    //     headerAlign: 'center',
    //     align : 'center',
    //     type : 'number'
    // },
  ];
  //{pathname :`${c021NavSections.DROUGHTMANAGEMENTPLANVIEW}/${params.row.id}`, search : 'edit=true'}

  const handleCreateCommunityTank = () => {
    matchedPrimary && setAddTankType('Community');
    matchedPrimary && setAddTank(true); //routerHistory.push({ pathname: `/${NavSections.PROVISIONVIEW}` });
  };

  const handleCreateHouseholdTank = () => {
    matchedPrimary && setAddTankType('Household');
    matchedPrimary && setAddTank(true); //routerHistory.push({ pathname: `/${NavSections.PROVISIONVIEW}` });
  };

  return (
    <>
      <Grid container className={styles.container} spacing={2}>
        <Grid container className={styles.container} spacing={2}>
          <Grid item>
            <Button {...{ disabled: !matchedPrimary, variant: 'contained', onClick: handleCreateCommunityTank, color: 'primary' }}>+ New Community Tank</Button>
          </Grid>
          <Grid item>
            <Button {...{ disabled: !matchedPrimary, variant: 'contained', onClick: handleCreateHouseholdTank, color: 'primary' }}>+ New Household Tank</Button>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant='h5' component='h5'>
            Tanks In Community
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('Community')} color='primary'>{`Community Tanks (${ICCTanks.length})`}</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('Household')} color='primary'>{`Household Tanks  (${householdTanks.length})`}</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('')} color='primary'>{`All Tanks  (${householdTanks.length + ICCTanks.length})`}</Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <DataGrid
              {...{
                onRowClick: (e) => routerHistory.push(`/THINGVIEW/Tank/${e.row.id}`),
                disableSelectionOnClick: true,
                disableColumnMenu: true,
                autoHeight: true,
                rows: filterPairs(customerTankPairs) /*pairsToDisplay*/,
                columns,
                // initialState: [ { field: 'tankName', sort: 'asc'}]
              }}
              sortModel={sortModel}
            //onSortModelChange={(model) => setSortModel(model) }
            />
          </Card>
        </Grid>
      </Grid>
      {addTank && (
        <AddTank
          showScreen={setAddTank}
          type={addTankType}
          setUpdate={setUpdate}
          source={matchedPrimary}
          userFunctions={userFunctions}
          contextUser={user}
          transactionFunctions={transactionFunctions}
        />
      )}
    </>
  );
};
