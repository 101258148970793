import { MapLike } from "@iotv/datamodel"

// added to datamodel utils

export const safeJSON = {

    parse: ( obstr: string  | undefined | null ): MapLike<any> | undefined => {
        let res: MapLike<any> | undefined = undefined
        if ( typeof obstr === 'string' ) {
            try {
                res = JSON.parse(obstr)
            } catch (e) {
                console.log('safeJSON caught parse error on probably not JSON string', obstr)
            }
    
        }
      
        return res
    }
}