import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Tabilizer } from '../factories/Tabilizer';
import { UserTransactionType, DetailCardProps } from '../../types/AppTypes';
import { TableContainer, Paper } from '@material-ui/core';
import styles from '../../cosmetics/sharedCardStyles';

const useStyles = makeStyles((theme) => (styles(theme)));

const debug = process.env.REACT_APP_DEBUG && false;


export default function DetailCard( props: DetailCardProps ) {

  const classes = useStyles();

  debug && console.log('Details Card has props', props)

 

  const { matchedPrimary, actions, transactionFunctions, viewDefinition, searchParams, history, title } = props

  debug && console.log('User Details Card has saveObject', transactionFunctions?.saveObject)

  const nameLabel = title || `${matchedPrimary.name} facts` || `New ${matchedPrimary.type}`

  return (
    <Card className={classes.root}>
      <CardHeader
        title = {`${nameLabel}`}
      ></CardHeader>
       <CardContent>
       <TableContainer component={Paper}>
        <Tabilizer key ={`${matchedPrimary.sk}_details`} {...{ matchedPrimary, transactionFunctions, actions, viewDefinition, searchParams, history }}></Tabilizer>
       </TableContainer>
      </CardContent>
     
   
    </Card>
  );
}