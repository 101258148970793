import { Gateway, GeoPoint, HistoryStore, MapLike, Position } from '@iotv/datamodel'
import { MetricStatsType, DynamicIndexObjectType } from '@iotv/iotv-v3-types';
import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import { gatewayRayColors } from '../displayConfigs/styles';

const debug = process.env.REACT_APP_DEBUG && false;

type GatewayToPointDataDisplayProps = {
    gateway: Gateway, receivingPoint: DynamicIndexObjectType & ValidBusinessObject, receptionStatistics: MapLike<MetricStatsType>, location?: Position
}

export const addGatewayToPointOverlay = ( map: google.maps.Map<Element>, gateways: Gateway[], s2Points: GeoPoint[]) => {
    const gatewayMapByEUI = Object.fromEntries( gateways.sort( (item1, item2) => item1.id > item2.id ? 1: 0).map( (item) => [`Gateway:${item.gateway_ids?.eui ?? item.id}`, item ]))
    debug && console.log('gatewayMapByEUI', gatewayMapByEUI)
    //currently NetworkData lambda maps stats by Gateway + eui, but Gateways are idetnified by Gateway:id
    s2Points.forEach( ( s2Point, s2I ) => {
        Object.entries(s2Point.gatewayStatistics).forEach( ( [ gwEuiKey, stats ]) => {
            const normalizedGwEuiKey = gwEuiKey.toUpperCase().replace('GATEWAY', 'Gateway');
            const historyStore = new HistoryStore(stats)
            const gwStats = historyStore.getStatistics();
            const gwRssiStats = gwStats.rssi;
        if ( gwRssiStats ) {

            const matchedGateway = gatewayMapByEUI[normalizedGwEuiKey]
            const matchedGatewayIdx =  Object.keys(gatewayMapByEUI).findIndex( ( k ) => k === normalizedGwEuiKey)
            if ( matchedGateway?.location ) {
                const { sk, pk, dsk, dpk, name, id, type } = s2Point;
                
                
                const gatewayToPointDataDisplayProps: GatewayToPointDataDisplayProps= {
                    gateway: matchedGateway,
                    receivingPoint: { sk, pk, dsk, dpk, name, id, type, location: s2Point.getLatLon() },
                    receptionStatistics: gwStats,
                    location: undefined

                }
                if (s2Point.bbox) {
                    const [ lng1, lat1, lng2, lat2 ] = s2Point.bbox
                    const s2PointLocation: Position = { lat: (lat1 + lat2)/2, lng: (lng1 + lng2)/2}
                    gatewayToPointDataDisplayProps.location = s2PointLocation

                    if (gatewayToPointDataDisplayProps.location && gatewayToPointDataDisplayProps.gateway.location) {


                        const [ lng1, lat1, lng2, lat2 ] = s2Point.bbox
                        const outerCoords = [
                            gatewayToPointDataDisplayProps.gateway.location,
                            { lat: lat1, lng: lng1 }, // south east
                            { lat: lat2, lng: lng2 }, // north east
                          ];
                        
                          const gatewayRSIMean = gatewayToPointDataDisplayProps.receptionStatistics.rssi?.mean 
                          console.log('gatewayRSIMean', gatewayRSIMean)
                          if (! gatewayRSIMean ) {
                            console.log('No gatewayRSIMean', { receptionStatus:  gatewayToPointDataDisplayProps.receptionStatistics, key: `Gateway:${matchedGateway?.id}`})
                          }
                          
                          // rssi range -120 > -80
                          const minRssi = -120
                          const maxRssi = - 70

                          const rssiNormal = ( ( gatewayRSIMean - minRssi ) / ( maxRssi - minRssi ) ) * 255
                          const rssiFillHex = ( Math.max(0, rssiNormal ).toString(16) ).substr(0, 2)
                          console.log('Rssi calc', { rssiNormal, rssiFillHex})
                          
                          const opactityHex = 'FF'

                          const rssiFillColor = gatewayRSIMean ? `#${'00'}${'FF'}${rssiFillHex}${opactityHex}` : '#000000'

                          const feature: google.maps.Data.Feature = new google.maps.Data.Feature({
                            properties: {
                                receptionStatistics: gatewayToPointDataDisplayProps.receptionStatistics,
                                style: {
                                    // fillColor: matchedGatewayIdx !== undefined ? gatewayRayColors[ matchedGatewayIdx % gatewayRayColors.length ] : '#000000',
                                    fillColor: rssiFillColor,
                                    strokeWeight: 0,
                                    strokeColor: rssiFillColor
                                    //fillOpacity:  (gwRssiStats.mean + 130 ) / 60
                                }
                            },
                            geometry: new google.maps.Data.Polygon([
                              outerCoords,
                            ]),
                          })

                          map.data.add(feature);
                          debug && console.log('added line ', [
                            gatewayToPointDataDisplayProps.location, gatewayToPointDataDisplayProps.gateway.location
                        ])
                    } else {
                        debug && console.log('did not add ', [
                            gatewayToPointDataDisplayProps.location, gatewayToPointDataDisplayProps.gateway.location
                        ])
                    }
                } else {
                    console.log('No bbox')
                }



 
            } else {
                debug && console.log(`no matchedGateway?.location for ${normalizedGwEuiKey}` , gatewayMapByEUI)
            }
          

        } else {
            debug && console.log('no gwRssiStats',gwStats);
           const output = [ normalizedGwEuiKey, {}]
        }
        })
    } )


}