import { Position } from "@iotv/datamodel"
import React from "react";
import { useEffect } from "react"
import { bounsdCellLatLngsToCoveringCells, googleBoundsToCellLatLngs } from "../mapfns/conversion"


//Parked

const debug = process.env.REACT_APP_DEBUG && false;

export const useBoundsChange = ( map: google.maps.Map<Element> | undefined) => {

    const bounds = map ? map.getBounds() : undefined

    const boundsLatLng: { ne: Position, sw: Position } | undefined = bounds ? googleBoundsToCellLatLngs( bounds ) : undefined;

   
    const boundsCoveringCells = boundsLatLng ? bounsdCellLatLngsToCoveringCells({  boundsLatLng, maxCells: 4, maxLevel: 10}) : []

    const cellTokens = boundsCoveringCells.map( ( cellId ) => cellId.toToken()).sort().map( (token) => token.substr(0, token.length - 1)).reduce( (acc, cur ) => {
        if ( !acc.includes(cur )) {
            acc.push(cur)
        }
        return acc
    }, [] as string[]) ;

    const cellTokenHash = cellTokens.join('');

    debug && console.log('Use Bounds Change cell tokens ', cellTokens);


    useEffect( () => {
        debug && console.log('useBoundsChange used effect');
    }, [ cellTokenHash, map ])

    const [ boundsHash, setBoundsHash ] = React.useState<string>();

    return [ boundsHash, setBoundsHash ] 
}