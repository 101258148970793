import { DatastoreObjectType, DeviceProvisioningState, GatewayProvisioningState, Geofence, GeofencePointsType, GeofenceType, MapLike as MapLikeT, Milliseconds, ObjectHistory, ObjectHistoryConfig, PathDirection, PossibleBusinessObject as TPossibleBusinessObject, QueryTypes, ValidBusinessObject as TValidBusinessObject, ValidModelObject, ValidModelObjects, ValidModelType, WeatherFormat } from '@iotv/datamodel';
import { CustomerAccountType } from '@iotv/datamodel';
import type { ErrData, HistoryObjectType, ObjectHistoryFilterRequest, SortOrder } from '@iotv/iotv-v3-types';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { DrawerProps, PaperClassKey, WithStyles } from '@material-ui/core';
import { TreeViewProps, TreeViewPropsBase } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/styles';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { AnyAction, Dispatch } from 'redux';
import { ACTIONS } from '../actions/actionTypes';

const debug = process.env.REACT_APP_DEBUG && false;


export type MapLike = { [k: string]: any }

export type { MapLikeT };

export type AppClasses = ClassNameMap<any> & Partial<ClassNameMap<PaperClassKey>>

export type AdditionalHistoryProps = {
  location?: {
    hash: string;
    pathname: string;
    search: string;
    state?: any;
  };
  goBack: Function
}

export type ReactRouterMatch = { params: { filter: string, type?: string, id?: string }, isExact: boolean, path: string, url: string }

export type ReactRouterMatchDeviceEui = { params: { deviceEui?: string }, isExact: boolean, path: string, url: string }


export type AuthProps = { authenticate: Function, logout: Function };



export type LoginProps = { isAuthenticated: boolean, username?: string, password?: string, rememberMe?: boolean, didInvalidate: boolean, location: any } & AuthProps

export type ValidCustomerObject = ValidBusinessObject & { type: 'Customer' }

export type AppReduceerState = {
  isFetching: boolean,
  user: UserView,
  cognitoUser: CognitoUser | undefined,
  userCustomers: (ValidCustomerObject)[]
  contextCustomer: ValidCustomerObject | undefined,
  contextSecondaryObject: ValidBusinessObject | undefined,
  userGroupRoles: UserGroupsRolesType,
  roleArbitrationInstance: RoleArbitrationInstanceType | undefined,
  userSelectedGroup: string | undefined,
  normalData: NormalizedData,
  largeObjects: LargeObjectData,
  messages?: MessageMap<IUserMessageType>,
  errors?: ErrorMessageMap
  redirect?: string,
  userGeolocation?: GeolocationCoordinates,
  pinger: boolean,
  useSpecializedViews: boolean,
  defaultRoute: string | undefined;

}

export type UserChangeableState = Partial<{ [k in keyof Pick<AppReduceerState, 'useSpecializedViews' | 'contextCustomer'| 'contextSecondaryObject'>]: AppReduceerState[k] }>

export type GeolocationCoordinates = {
  accuracy: number | null,
  altitude: number | null,
  altitudeAccuracy: number | null
  heading: number | null
  latitude: number | null,
  longitude: number | null,
  speed: number | null
}

export enum AdjacentType {
  PARENT = 'matchedRelatedByPk',
  CHILD = 'matchedRelatedBySk',
  SUBJECT = 'matchedPrimary'
}

export type ViewObject = {
  matchedPrimary: ValidBusinessObject | undefined;
  matchedRelatedByPk: ValidBusinessObject[] | undefined;
  matchedRelatedBySk: ValidBusinessObject[] | undefined;
  largeObject?: ValidBusinessObject | undefined
}

export type EdgeFilter = {
  edgeTypeId: string
}

/* otherObjectType required for AdjacentType.Parent to allow auto generation of lists */
export type AdjacentFilter = {
  adjacentType: AdjacentType,
  objectType: ValidModelType,
  otherObjectType?: string,
  edgeFilter?: EdgeFilter
}

export type BaseViewProps = {
  contextCustomer: ValidBusinessObject | undefined
  history: History,
  optionals?: ThingViewOptionals
  searchParams?: SearchParamsType,
  transactionFunctions: DispatchFunctionsType,
  user: ValidBusinessObject | undefined,
  userFunctions: UserFunctions,
  userGeolocation?: GeolocationCoordinates,
  userGroupRoles: UserGroupsRolesType | undefined,
  userSelectedGroup: string | undefined
}

type CommonThingViewsProps = BaseViewProps & {
  appRef: React.RefObject<HTMLDivElement>
  childFirstGeofences: (ValidBusinessObject & GeofenceType)[],
  classes: AppClasses,
  history: History & AdditionalHistoryProps;
  match: ReactRouterMatch,

  redirect?: string,
  useSpecializedViews: boolean
}

export type ThingViewProps = CommonThingViewsProps & {
  viewObject: ViewObject
}

export type ThingsViewProps = CommonThingViewsProps & {
  viewObjects: ViewObject[],
  optionals?: ThingsViewOptionals,
}

export type ThingsViewOptionals = {
  rowNumber?: number
  useSpecializedViews?: boolean
}

export type ThingViewOptionals = {
  childFirstGeofences?: ValidBusinessObject[] & GeofenceType[];
  useSpecializedViews?: boolean

}

export type ObjectCardProps = BaseViewProps & {
  history: History,
  optionals?: ThingViewOptionals
  searchParams?: SearchParamsType,
  viewObject: ViewObject;
}

export type Location = { id: string, lat: number | string, lng: number | string };

export type TableViewProps = ThingsViewProps & { LargerThanMobile: boolean };

export type EditCardProps = ObjectCardProps & { edited?: boolean, contextObject: ValidBusinessObject };

export type FileUploadCardProps = {
  matchedPrimary: ValidBusinessObject;
  actions: UserTransactionType[];
  transactionFunctions: DispatchFunctionsType;
  userFunctions: UserFunctions,
  viewDefinition: ViewDefinition;
  searchParams?: SearchParamsType;
  title?: string;
  history: History,
  parentHooks?: { [k: string]: Function }
}

export type UserListCardProps = ObjectCardProps


export type ObjectDetailCardProps = {
  matchedPrimary: ValidBusinessObject;
  transactionFunctions: DispatchFunctionsType;
  searchParams?: SearchParamsType,
  history: History
}

export type DetailCardProps = ObjectDetailCardProps & {
  actions: UserTransactionType[];
  viewDefinition: ViewDefinition;
  title?: string;
}


export type UserDetailCardProps = ObjectDetailCardProps;


export type NetworkObjectType = 'DeviceMessage' | 'GatewayData'

export type DeviceMessageTableRowProps = {
  viewObject: ViewObject;
  transactionFunctions: DispatchFunctionsType;
  searchParams?: SearchParamsType,
  history: History
  optionals?: ThingsViewOptionals,
  networkObjectType: NetworkObjectType
}

export type LinkListConfig = {
  adjacentFilter: AdjacentFilter, listConfigParams: ListConfigParams
}

export type ListConfigParams = {
  label: string
}

export type SelectorConfigParams = {
  enabled: boolean,
  multipleSelect: boolean,
  controls: {
    add: boolean,
    find: boolean,
  }
  includeContextLinkedItems?: boolean,
  selectorContextItem?: ValidBusinessObject
}

export type BaseListCardProps = {
  viewObject: ViewObject;
  contextObject: ValidBusinessObject | undefined,
  userGroupRoles?: UserGroupsRolesType,
  adjacentFilter: AdjacentFilter,
  transactionFunctions: DispatchFunctionsType;
  userFunctions: UserFunctions,
  searchParams?: SearchParamsType,
  history: History
}
export type ObjectListCardProps = BaseListCardProps & {
  listConfigParams: ListConfigParams,
}

export type SingleParentSelectorCardProps = ObjectListCardProps & { classes: AppClasses }

export type ListCardProps = BaseListCardProps & {
  actions: UserTransactionType[];
  viewDefinition: ViewDefinition;
  maxItems: number,
  others?: MapLike;
}

export type UserCardProps = ObjectCardProps;

export type AlertCardProps = ObjectCardProps;

export type TankCardProps = ObjectCardProps;

export type ObjectListItemCardProps = {
  viewObject: ViewObject;
  transactionFunctions: DispatchFunctionsType
}

export type ObjectHistoryControlProps = {
  objectHistory: ObjectHistory | undefined
  objectHistoryFilter?: ObjectHistoryFilterRequest,
  setObjectHistoryFilter?: (objectHistoryFilter: ObjectHistoryFilterRequest | undefined) => void
}

export type ObjectHistoryCardProps = ObjectHistoryControlProps & {
  matchedPrimary: ValidBusinessObject
  objectHistoryConfig: { [k: string]: boolean },
  classes: AppClasses,

}


export type UserAtts = {
  userId: string,
  userName: string,
}

export type UserViewProps = ThingViewProps;

export type UserRolesType = string[];

export type MessageViewProps = {
  classes: AppClasses,
  messages?: MessageMap<IUserMessageType>,
  errors?: ErrorMessageMap,
  userFunctions: UserFunctions,
  viewObject: ViewObject,
  match: ReactRouterMatch
};

export type DeviceProvisionViewProps = ObjectCardProps & {
  match: ReactRouterMatchDeviceEui,
  adjacentFilter: AdjacentFilter;
}

export type DeviceProvisionCardProps = DeviceProvisionViewProps & { contextCustomer: ValidBusinessObject }

export type TableAndListCommonProps = {
  history?: History;
  viewDefinition: ViewDefinition,
  transactionFunctions: DispatchFunctionsType,
  searchParams?: SearchParamsType,
}

export type TabilizerProps = TableAndListCommonProps & {
  matchedPrimary: ValidBusinessObject,
  actions: UserTransactionType[],
  injectedComponents?: JSX.Element[];
  functionOverrides?: {
    updateParentComponentItem: Function,
    mode: ComponentMode | undefined,
    hasValidationErrors: Function
  },
}


export type ListerlizerProps = TableAndListCommonProps & {
  viewObject: ViewObject,
  contextObject: ValidBusinessObject | undefined,
  adjacentFilter: AdjacentFilter,
  autoQuery?: boolean,
  userFunctions: UserFunctions,
  sortKeyIn: string,
  sortDirectionIn?: SortOrder,
  history?: History,
  selectorConfigParams: SelectorConfigParams
  maxItems: number,
  functionOverrides?: {
    selectItem: Function,
    hasValidationErrors: Function
  },
  rowControlOverrideFns?: RowControlOverrideFnsType
  checkedItemSks?: string[]
  injectedComponents?: JSX.Element[];
  optionals?: {
    rowSelectionFirst?: boolean
  }
}

export type RowControlOverrideFnsType = {
  first?: ListerlizerRowControlFnType[],
  last?: ListerlizerRowControlFnType[]
}

export type ListerlizerrRowProps = {
  matchedPrimary: ValidBusinessObject,
  rowObject: ValidBusinessObject,
  mode: ComponentMode,
  isSelected?: boolean
  rowControlOverrideFns?: RowControlOverrideFnsType
} & Pick<ListerlizerProps, 'functionOverrides' | 'optionals' | 'adjacentFilter' | 'viewDefinition' | 'transactionFunctions' | 'history'>


export type ListerlizerRowControlFnProps = {
  contextObject: ValidBusinessObject,
  rowObject: ValidBusinessObject,
  viewDefinition: ViewDefinition,
  rowMode: ComponentMode,
  setRowMode: (newValue: ComponentMode) => void
  transactionFunctions: DispatchFunctionsType,
  history?: History
}

export type ListerlizerRowControlFnType = (params: ListerlizerRowControlFnProps) => JSX.Element[];



export type LineChartOptionsMandatory = {
  title: string,
  height: number,
  id: string,
}

export type PieChartOptionsMandatory = LineChartOptionsMandatory;

export type ComboChartOptionsMandatory = {
  title: string;
  height: number;
  id: string;
  series?: object;
}

export type BarChartOptionsMandatory = Omit<LineChartOptionsMandatory, "title">;

export enum LineChartXType {
  TIMESERIES, INDEX
}

export type DurationTupleType = [
  number, Milliseconds
]

export type TimeSliceButtonValues = DurationTupleType[]

export enum ChartType {
  Column = "Column",
  Line = "Line",
  Combo = "Combo",
}

export type RangeableLineChartProps = {
  historyObject: HistoryObjectType, keys: string[], xType: LineChartXType, classes: AppClasses, timeSliceButtonValues?: TimeSliceButtonValues, title?: string
}

export type ComboChartProps = {
  historyObject: HistoryObjectType;
  keys: string[];
  xType: LineChartXType;
  classes: AppClasses;
  daily: boolean,
  timeSliceButtonValues?: TimeSliceButtonValues;
  title?: string;
  chartType?: ChartType;
  weatherData?: Array<WeatherFormat> | undefined;
  additionalOptions: google.visualization.ComboChartOptions
};

export type NormalizedData = undefined | { [sk: string]: { [pk: string]: ValidBusinessObject | undefined } };

export type LargeObjectData = { [sk: string]: ValidBusinessObject }

export type UserView = ValidBusinessObject | undefined

export type PossibleBusinessObject = TPossibleBusinessObject;

export type ValidBusinessObject = TValidBusinessObject;


export type LinkRequest = {
  objectA: ValidBusinessObject,
  objectB: ValidBusinessObject,
  edgeTypeId?: string,
  edgeAttributes?: { [k: string]: any }
};

export type SearchParamsType = {
  contextObjectSK?: string,

  objectTypeId?: string,
  objectUUID?: string,

  edgeTypeId?: string,
  tabName?: string,
  action?: UserTransactionType
}

export type PositionType = { lat: number, lng: number }

export type MapViewProps = {
  thingPosition?: PositionType
}


export type MapCardProps = GoogleMapProps & {
  timestamp?: string;
  title?: string;
  editable?: boolean,
  maxEditableGeofences?: number,
  transactionFunctions?: DispatchFunctionsType,
  geoImplementingObject?: GeofenceType
  optionals?: MapCardPropsOptionals
}

export type MapCardPropsOptionals = MapLike & {
  zoom?: number,
  styles?: {
    mapCardObjectCard: {
      height?: number | string,
      width?: number | string,
    }
  }
}


export type GoogleMapProps = {
  position: { lat: number, lng: number } | undefined;
  polygons?: MapLikeT<GeofencePointsType[]>,
  geofences?: MapLikeT<Geofence>
  polylines?: MapLikeT<GeofencePointsType[]>
  viewObjects?: ViewObject[],
  locatableAddress?: string,
  editable?: boolean,
  maxEditableGeofences?: number,
  parentCardFns?: {
    setEditedGeofences: (geofences: GeofenceType[]) => any
  }
  optionals?: MapLike
};

export interface NavMainProps extends Omit<DrawerProps, 'classes'>, WithStyles<any> {
  isAuthenticated: boolean,
  useSpecializedViews: boolean,
  roleArbitrationInstance: RoleArbitrationInstanceType | undefined,
  userSelectedGroup: string | undefined,
  userFunctions: MapLikeT<Function>
  contextCustomer?: ValidModelObject<'Customer'>
  onDrawerToggle: () => void
  normalData?: NormalizedData;
  user?: any;
}



export type GoogleSpreadsheetRowData = string[];
export type GoogleSpreadsheetData = GoogleSpreadsheetRowData[];

export type ImportObjectsFromGSheetProps = {
  setVBOsInParent: (vbos: ValidBusinessObjectList) => void,
  setImportDefinitionInParent: (viewDefinition: ViewDefinition) => void,
  parentHasRendered: () => boolean,


} & Omit<ObjectCardProps, 'history' | 'viewObject'>

export enum Unit {
  Date, Meter, Units
}

export enum ObjectViewType {
  Card, ListItem,
  TableItem
}






export enum UserTransactionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  LINK = 'LINK',
  UNLINK = 'UNLINK',
  REGISTER = 'REGISTER',
  CREATE_AND_LINK = "CREATE_AND_LINK",
  IMPORT = "IMPORT"
}

export type AppValueTypesKeys = keyof typeof AppValueTypes;



export type ViewKeyDefinitionType = {
  key: string, type: AppValueTypes, label: string,
  editable: boolean, unit?: Unit, precision?: number, stateFn?: ((params: MapLike) => void), valueGetter?: (actualValue: any) => any, valueSetter?: (newValue: any) => any
  validationRx?: RegExp,
  failsValidationText?: string
  enumKV?: { [k: string]: any },
  embeddedObjectViewDefinition?: ViewDefinition
}

export type ViewDefinition = {
  [k: string]: ViewKeyDefinitionType
}

export enum ComponentMode {
  VIEW, EDIT, READONLY
}

export interface IAPIError {
  [k: string]: any
}

export interface ObjectAPIError extends IAPIError {
  object: ValidBusinessObject,
  errors: {
    [attKey: string]: string
  }
}

export type S3ImageMeta = {
  eTag?: string
  key: string
  lastModified?: Date
  size?: number
}

export type S3ImageRecordAndURL = Partial<S3ImageMeta> & {
  url: string | Object
}

export type S3ImageRecordAndURLMap = {
  [k: string]: S3ImageRecordAndURL
}

export type TransactionResponse<E extends IAPIError, D extends any> = { err: E | null, data: D | null }


export type TransactionFunctionsType = {
  saveObject: (object: ValidBusinessObject) => (dispatch: Dispatch<AnyAction>) => Promise<void>,
  saveAndLinkObject: (existingObjectSk: string, newObject: ValidBusinessObject, adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike) => (dispatch: Dispatch<AnyAction>) => Promise<void>,
  deleteObject: (object: ValidBusinessObject, history: History) => (dispatch: Dispatch<AnyAction>) => Promise<void>
  searchByType: (contextObject: ValidBusinessObject, includeContextLinkedItems: boolean, typeId: string, lastEvaluatedKey: string | undefined) => (dispatch: Dispatch) => Promise<void>
  unlink: (zombieA: ValidBusinessObject, zombieB: ValidBusinessObject, edgeTypeId?: string, edgeAttributes?: MapLike) => (dispatch: Dispatch) => Promise<void>
  linkObjectsToPrimary: (parentObject: ValidBusinessObject | undefined, childObjects: ValidBusinessObject[], adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike) => (dispatch: Dispatch) => Promise<void>
  getAdjacent: (object: ValidBusinessObject, type: string, direction: PathDirection, edgeTypId?: string) => (dispatch: Dispatch) => Promise<void>
  getLargeObject: (object: ValidBusinessObject) => (dispatch: Dispatch) => Promise<void>
  requestUploadFile: (directoryString: string, file: File) => (dispatch: Dispatch) => Promise<void>
  // getDeviceFromRegistry: (deviceId: string)=> (dispatch: Dispatch) => Promise<TransactionResponse<any, ValidBusinessObject>>
  changeUserRole: (user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, userSelectedGroup: string) => (dispatch: Dispatch) => Promise<void>
}


export type DispatchFunctionsType = {
  saveObject: (object: ValidBusinessObject) => void
  saveAndLinkObject: (existingObjectSk: string, newObject: ValidBusinessObject, adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike) => void
  deleteObject: (object: ValidBusinessObject, history: History) => void
  searchByType: (contextObject: ValidBusinessObject, includeContextLinkedItems: boolean, typeId: string, lastEvaluatedKey: string | undefined) => void
  unlink: (zombieA: ValidBusinessObject, zombieB: ValidBusinessObject, edgeTypeId?: string, edgeAttributes?: MapLike) => void
  linkObjectsToPrimary: (parentObject: ValidBusinessObject | undefined, childObjects: ValidBusinessObject[], adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike) => (dispatch: Dispatch) => void
  getAdjacent: (object: ValidBusinessObject, type: string, direction: PathDirection, edgeTypId?: string) => void
  getLargeObject: (object: ValidBusinessObject) => (dispatch: Dispatch) => void
  requestUploadFile: (directoryString: string, file: File) => (dispatch: Dispatch) => void
  // getDeviceFromRegistry: (deviceId: string) => (dispatch: Dispatch) =>  void
  changeUserRole: (user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, userSelectedGroup: string) => void
}

export type UserFunctions = {
  setUserMessage: (message: IUserMessageType) => { type: ACTIONS; value: IUserMessageType; }
  dismissUserMessage: (message: IUserMessageType) => { type: ACTIONS; value: IUserMessageType; }
  cancelRedirect: (url: string) => { type: ACTIONS }
  draftNewObject: (matchedPrimary: ValidBusinessObject, type: string, history: History) => { type: ACTIONS, matchedPrimary?: ValidBusinessObject, newObject?: PossibleBusinessObject, history?: History }
  newListObject: (matchedPrimary: ValidBusinessObject, adjacentFilter: AdjacentFilter, history: History) => { type: ACTIONS, matchedPrimary?: ValidBusinessObject, newObject?: PossibleBusinessObject, newEdgeObject?: PossibleBusinessObject, history?: History }
  changeAppState: (changeableState: UserChangeableState) => { type: ACTIONS; value: UserChangeableState; }
  addObjectsToState: (validBusinessObjects: ValidBusinessObjectList) => { type: ACTIONS; value: ValidBusinessObjectList; }
}

export type UserFunctionsType = UserFunctions

export type UserFunctionsDispatchType = {
  setUserMessage: (message: IUserMessageType) => (dispatch: Dispatch) => void
  dismissUserMessage: (message: IUserMessageType) => (dispatch: Dispatch) => void
  cancelRedirect: (url: string) => (dispatch: Dispatch) => void
  draftNewObject: (matchedPrimary: ValidBusinessObject, type: string,) => (dispatch: Dispatch) => void
  newListObject: (matchedPrimary: ValidBusinessObject, adjacentFilter: AdjacentFilter,) => (dispatch: Dispatch) => void
  changeAppState: (changeableState: UserChangeableState) => (dispatch: Dispatch) => void
  addObjectsToState: (validBusinessObjects: ValidBusinessObjectList) => (dispatch: Dispatch) => void
}

export type DeleteObjectRespose = TransactionResponse<any, { object: ValidBusinessObject, history: History & { push: Function, location?: { pathname: string } } }>

export type SearchByTypeResponse = TransactionResponse<any, { Items: ValidBusinessObject[], Count: number, ScannedCount: number, LastEvaluatedKey: MapLike }>

export type UnlinkResponse = TransactionResponse<any, null | {
  referenceItem: ValidBusinessObject;
  removedItem: ValidBusinessObject;
}>;

export type APILinkResponse = ErrData<[ValidBusinessObject, ValidBusinessObject]>

export type LinkObjectResponse = TransactionResponse<{ message: string, zombieA: ValidBusinessObject, zombieB: ValidBusinessObject }, [ValidBusinessObject, ValidBusinessObject]>

export type GetAdjacentResponse = TransactionResponse<any, ValidBusinessObject[]>

export type GetLargeDataResponze = TransactionResponse<any, ValidBusinessObject>

export type SaveResponce = TransactionResponse<MapLike, ValidBusinessObject>

export type MessageOutputType = 'SnackBar' | 'MessageBar';

export interface IUserMessageType {
  label: string,
  content: string,
  id: string,
  type: MessageOutputType,
  severity?: Severity
}



export type MessageMap<T extends IUserMessageType> = {
  [k: string]: T
}

export type ErrorMessageMap = {
  [sk: string]: MessageMap<UserErrorMessageType>
}

export interface UserErrorMessageType extends IUserMessageType {
  errors: ObjectAPIError
}

export enum Severity {
  success = "success", info = "info", warning = "warning", error = "error"
}

export type SeverityType = keyof typeof Severity | undefined;

export type UserAndRoles = {
  cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType
}

export type UserGroupsRolesType = {
  groups: string[];
  roles: string[];
  preferredRole: string | undefined
}

export type RouteDefinitionType = {
  id: string;
  type: 'category' | 'link'
  access: 'Allow' | 'Deny'
  parentId?: string
}

export type NavigationItemType = {
  id: string,
  name: string,
  type: 'category' | 'link',
  children?: NavigationItemType[],
  icon?: JSX.Element,
  link?: string,
  active?: boolean
}

export type RoleArbitrationInstanceType = {
  groupName: string,
  routes: RouteDefinitionType[]
  queries: StartUpQuery[]
}

export type StartUpQuery = (user: ValidBusinessObject, normalizedData: NormalizedData) => Promise<NormalizedData>

export class RoleArbitrationInstance {
  groupName: string;
  routes: RouteDefinitionType[]
  queries: StartUpQuery[]

  constructor(definition: RoleArbitrationInstanceType) {
    this.groupName = definition.groupName;
    this.routes = definition.routes
    this.queries = definition.queries
  }

  inherit = (roleArbitrationDefinition: RoleArbitrationInstanceType | RoleArbitrationInstanceType[]) => {
    const roleArbitrationDefinitions = roleArbitrationDefinition instanceof Array ? roleArbitrationDefinition : [roleArbitrationDefinition];
    roleArbitrationDefinitions.forEach((roleArbitrationDefinition) => {
      roleArbitrationDefinition.queries.forEach((query) => {
        if (!(this.queries.find((thisquery) => query === thisquery))) {
          this.queries.push(query)
        }
      })
      roleArbitrationDefinition.routes.forEach((route) => {
        if (!(this.routes.find((thisRoute) =>
          (route.id !== '*' && thisRoute.id === route.id) || (route.id === '*' && thisRoute.parentId === route.parentId)
        ))) {
          if (debug && this.groupName === 'HyperUser') { console.log('Pushing route', route) }
          this.routes.push(route)
        }
      })
    })
    return this;
  }
}

export type PartialAdminCreateUserRequest = {
  name: string, username: string, email: string, phoneNumber: string, temporaryPassword: string
}

export type pseudoEvent = {
  currentTarget: {
    type: string,
    checked?: string,
    value: any
  }
}

export type InputComponentProps = {
  matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode, updateKV: (event: pseudoEvent | React.ChangeEvent<HTMLInputElement>, k: string) => void

} & {
  functionOverrides?: {
    updateParentComponentItem?: Function,
    mode?: ComponentMode | undefined,
    hasValidationErrors?: Function
  },
}

export type DiagnosticViewProps = ManagementViewProps & { deviceMessages: ValidModelObjects<'ThingShadowState'> | undefined };


export type ManagementViewProps = BaseViewProps & { classes: AppClasses, match: { params: { action?: string, type?: string, id?: string } }, user: UserView, contextCustomer?: ValidBusinessObject & { type: 'Customer' }, contextUser?: ValidBusinessObject & { type: 'User' }, tabIndex?: number, userFunctions: UserFunctions }
export type DeviceManagementViewProps = ManagementViewProps;
export type CustomerManagementViewProps = ManagementViewProps;

export type ObjectTreeProps = { contextObject: ValidBusinessObject, mainAdjacencyQuery: AdjacencyQuery, branchAdjacencyQueries?: AdjacencyQuery[], setSelectedInParent: (nodeIds: string[]) => void, userFunctions: UserFunctions };
export type ObjectTreeSeederProps = { contextObject: ValidBusinessObject, mainAdjacencyQuery: AdjacencyQuery, branchAdjacencyQueries?: AdjacencyQuery[], setSelectedInParent: (nodeIds: string[]) => void } & TreeViewPropsBase & { multiSelect?: true }
export type ObjectTreeRootProps = { contextObject: ValidBusinessObject, viewObjects: ViewObject[], mainAdjacencyQuery: AdjacencyQuery, branchAdjacencyQueries?: AdjacencyQuery[], setSelectedInParent: (nodeIds: string[]) => void } & TreeViewPropsBase & { multiSelect?: true }
export type AdjacencyQuery = { objectTypeId: string, adjacencyType: AdjacentType, edgeTypeId?: string, filter?: GetQueryFilterConfig };
export type ObjectTreeItemProps = { contextObject: ValidBusinessObject, viewObject: ViewObject, level: number, mainAdjacencyQuery: AdjacencyQuery, branchAdjacencyQueries?: AdjacencyQuery[], mode: UserTransactionType, handleDeleteInParent: (e: any) => void, handleUnlinkInParent?: (e: any) => void }

export type ManagementViewSearchParamsType = SearchParamsType & { tabName?: string }
export type CustomerViewSearchParamsType = ManagementViewSearchParamsType & { accountType: string };
export type DeviceViewSearchParamstype = ManagementViewSearchParamsType;
export type GatewayViewSearchParamstype = ManagementViewSearchParamsType;

export type ObjectTreeConfig = { mainAdjacencyQuery: AdjacencyQuery, branchAdjacencyQueries?: AdjacencyQuery[] }
export type ObjectFuculatorProps = { contextObject: ValidBusinessObject, objectTrees?: ObjectTreeConfig[], userFunctions: UserFunctions }

export type ValidTypeLinkage = AdjacencyQuery;
export type AddObjectItemTypeChooserProps = { validTypes: ValidTypeLinkage[], nodeId: string, handleIconClick: (e: any) => void, selectedValidTypeLinkage: ValidTypeLinkage, setSelectedValidTypeLinkage: (validTypeLink: ValidTypeLinkage) => void };
export type AddObjectItemProps = {
  contextObject: ValidBusinessObject, validTypes: ValidTypeLinkage[], nodeId: string, level: number, adjacentType?: AdjacentType, adjacencyQueries?: AdjacencyQuery[], mode: UserTransactionType
  , handleSave: (newObject: ValidBusinessObject | undefined, adjacentType: AdjacentType, edgeTypeId?: string) => void
}


export type CustomerContextTabProps = ManagementViewProps & { contextCustomer: ValidModelObject<'Customer'>, parentCustomer?: ValidBusinessObject, accountType: CustomerAccountType, accountRelationship: AdjacentType, title: string };

export type CustomerDeviceContextTabProps = CustomerContextTabProps & { devicePoolType: DevicePoolType, refreshParent: () => void, relatedViewRefreshCount: number }

export type CustomerGatewayListTabProps = CustomerGatewayViewProps & { gatewayPoolType: GatewayPoolType, refreshParent: () => void, relatedViewRefreshCount: number }

export type AddUserRequest = { contextUser: ValidBusinessObject, contextCustomer?: ValidModelObject<'Customer'>, user: Partial<ValidBusinessObject>, temporaryPassword: string,  forceChange?: boolean, addToGroups: string[], makeAdmin: boolean  }

export type UserManagementViewProps = ManagementViewProps & { contextCustomer?: ValidModelObject<'Customer'>, user?: ValidBusinessObject; match: { params: { action?: string, id?: string } } };

export type CustomerDeviceViewProps = ManagementViewProps & { contextCustomer?: ValidModelObject<'Customer'>, user?: ValidBusinessObject; match: { params: { action?: string, id?: string } } };
export type CustomerGatewayViewProps = ManagementViewProps & { contextCustomer?: ValidModelObject<'Customer'>, user?: ValidBusinessObject; match: { params: { action?: string, id?: string } } };



export type CustomerAllocationManagementProps = ManagementViewProps & {
  parentCustomer?: ValidBusinessObject, contextCustomer?: ValidBusinessObject, user?: ValidBusinessObject;
  match: { params: { action?: string, id?: string } }; refreshParent: () => void, relatedViewRefreshCount: number
};

export type ListDatabaseUsersRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  limit?: number,
  listAdminsOnly?: boolean,
  ExclusiveStartKey: DocumentClient.Key | undefined

}


export type GetDatabaseUserRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  requestedUser: ValidBusinessObject & { type: 'User' },

}


export type UpdateDatabaseUserRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  userToUpdate: ValidBusinessObject & { type: 'User' },
  makeAdministrator?: boolean

}

export type ListDevicesRequest = {
  contextUser: ValidModelObject<'User'>,
  contextCustomer: ValidModelObject<'Customer'>,
  limit?: number,
  filter: DeviceProvisioningState | undefined,
  euiContainsFilter?: string,
  ExclusiveStartKey: DocumentClient.Key | undefined
}


export type ListGatewaysRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidModelObject<'Customer'>,
  limit?: number,
  filter: GatewayProvisioningState | undefined,
  ExclusiveStartKey: DocumentClient.Key | undefined
}



export type UpdateDeviceRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  device: ValidBusinessObject & { type: 'Device' },
  devicePoolType: DevicePoolType
}

export type GetDeviceRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  device: ValidBusinessObject & { type: 'Device' },
  devicePoolType: DevicePoolType
}

export type DeleteDeviceRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  device: ValidBusinessObject & { type: 'Device' },
}


export type AddDeviceToInventoryRequest = {
  contextUser: ValidBusinessObject;
  device: ValidBusinessObject & { type: 'Device' };
  contextCustomer?: ValidBusinessObject;
  billingGroup?: string,
  thingGroup?: string
};


export type UpdateGatewayRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  gateway: ValidBusinessObject & { type: 'Gateway' },
  gatewayPoolType: GatewayPoolType
}

export type GetGatewayRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  gateway: ValidBusinessObject & { type: 'Gateway' },
  gatewayPoolType: GatewayPoolType
}

export type DeleteGatewayRequest = {
  contextUser: ValidBusinessObject & { type: 'User' },
  contextCustomer: ValidBusinessObject & { type: 'Customer' },
  gateway: ValidBusinessObject & { type: 'Gateway' },
}


export type AddGatewayToInventoryRequest = {
  contextUser: ValidBusinessObject;
  gateway: ValidBusinessObject & { type: 'Gateway' };
  contextCustomer?: ValidBusinessObject;
  billingGroup?: string,
  thingGroup?: string
};


export type AssignDeviceToCustomerRequest = {
  contextUser: ValidBusinessObject & { type: 'User' };
  device: ValidBusinessObject & { type: 'Device' };
  contextCustomer: ValidBusinessObject & { type: 'Customer' };
  assignmentCustomer: ValidBusinessObject & { type: 'Customer' };
  billingGroup?: string,
  thingGroup?: string
};

export type UnassignDeviceFromCustomerRequest = {
  contextUser: ValidBusinessObject & { type: 'User' };
  device: ValidBusinessObject & { type: 'Device' };
  contextCustomer: ValidBusinessObject & { type: 'Customer' };
  unAssignmentCustomer: ValidBusinessObject & { type: 'Customer' };
  billingGroup?: string,
  thingGroup?: string
};

export type AssignGatewayToCustomerRequest = {
  contextUser: ValidBusinessObject & { type: 'User' };
  gateway: ValidBusinessObject & { type: 'Gateway' };
  contextCustomer: ValidBusinessObject & { type: 'Customer' };
  assignmentCustomer: ValidBusinessObject & { type: 'Customer' };
  billingGroup?: string,
  thingGroup?: string
};

export type UnassignGatewayFromCustomerRequest = {
  contextUser: ValidBusinessObject & { type: 'User' };
  gateway: ValidBusinessObject & { type: 'Gateway' };
  contextCustomer: ValidBusinessObject & { type: 'Customer' };
  unAssignmentCustomer: ValidBusinessObject & { type: 'Customer' };
  billingGroup?: string,
  thingGroup?: string
};



export type IotBillingGroup = {
  billingGroupArn: string,
  billingGroupName: string,
}

export type IotThingType = {
  thingName: string,
  thingArn: string,
  thingTypeName?: string,
}

export type ChangeBillingGroupParams = {
  old: IotBillingGroup
  new: IotBillingGroup,
  thing: IotThingType

}


export type QueryInputizerPropsRequired = { [k in 'TableName' | 'Limit' | 'ExpressionAttributeNames' | 'ExpressionAttributeValues' | 'KeyConditionExpression']: NonNullable<DocumentClient.QueryInput[k]> }
export type QueryInputizerProps = DocumentClient.QueryInput & QueryInputizerPropsRequired & { IndexName: string | undefined }


export enum ExpressionFunctionType {
  BEGINS_WITH = 'begins_with',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not_contains',
  EXISTS = "EXISTS"
}

export const isExpressionFunctionType = (possibleEnumValue: ExpressionFunctionType | ComparisonType) => Object.values(ExpressionFunctionType).includes(possibleEnumValue as ExpressionFunctionType);



export enum ComparisonType {
  EQUALS = '=',
  NOT_EQUALS = '!='
}

export const isComparisonType = (possibleEnumValue: ExpressionFunctionType | ComparisonType) => Object.values(ComparisonType).includes(possibleEnumValue as ComparisonType);

export enum LogicalOperatorType {
  AND = 'and',
  OR = 'or'
}


export const isNullValueFilterPartType = (filterPartType: BaseFilterType): filterPartType is NullValueFilterPartType => filterPartType.predicate === ExpressionFunctionType.EXISTS


export type BaseFilterType = {
  key: string,
  value: string | number | undefined
  predicate: ExpressionFunctionType | ComparisonType
}

export type FilterPartType = BaseFilterType & {
  value: string | number,
}

export type NullValueFilterPartType = BaseFilterType & {
  value?: undefined,
  predicate: ExpressionFunctionType.EXISTS

}

export type AllFilterPartTypes = FilterPartType | NullValueFilterPartType


export type GetQueryFilterConfig = {
  filters: BaseFilterType[]
  setOperation: LogicalOperatorType
}

export type GetQueryBodyRequest2 = {
  contextObject?: DatastoreObjectType,
  adjacencyType: AdjacentType,
  objectTypeId?: string // only if AdjacentType.SUBJECT --- needs conditional type
  limit?: number,
  filterGroups?: GetQueryFilterConfig[]
  ExclusiveStartKey: DocumentClient.Key | undefined
}

export type ListLimitedAllTypeRequestBody = GetQueryBodyRequest2 & {
  queryType: QueryTypes.LIST_LIMITED_ALL_TYPES,
  objectTypeId: string,
  includeEdges?: boolean,
  includeNodes?: boolean,
  excludeRelatedToObject?: ValidBusinessObject,

}


export enum DevicePoolType {
  INVENTORY = 'INVENTORY',
  ALLOCATION = 'ALLOCATION'
}

export enum GatewayPoolType {
  INVENTORY = 'INVENTORY',
  ALLOCATION = 'ALLOCATION'
}

export enum ContextObjectType {
  USER = 'User', CUSTOMER = 'Customer', NO_CONTEXT = 'NO_CONTEXT', USER_SELECTED = 'USER_SELECTED'
}

export interface IExternallyPaginatedListComponent {
  LastEvaluatedKeys: DocumentClient.Key[],
  setLastEvaluatedKeys: React.Dispatch<React.SetStateAction<DocumentClient.Key[]>>,

}

export type KeyMappedComponentFunctions = MapLikeT<( props?: any ) => JSX.Element | null>

export type KeyMappedComponents = MapLikeT<JSX.Element | null>

export type TabSelectionProps = {
  value: number, index: number
}

export enum AreaManagementMode {
  ADD = "ADD",
  EDIT = "EDIT",
}
