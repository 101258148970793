// import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// import CheckIcon from '@material-ui/icons/Check';
import { ThingShadowState } from '@iotv/datamodel';
import { Box, Divider, Grid, Typography, CardHeader, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import NavLink from '../../../components/navigation/NavLink';
import { NavSections } from '../../../components/navigation/NavSections';
import styles from '../../../cosmetics/sharedCardStyles';
import type { TankCardProps, ThingViewOptionals, ValidBusinessObject, ViewObject } from '../../../types/AppTypes';
import TankPercentageDisplayCard from './TankPercentageDisplayCard';
import TankRainCard from './TankRainCard';
import { color } from 'd3-color';
import TankWaterAvailabilityCard from './TankWaterAvailabilityCard';
import { getFillGaugeSettings } from './helperFunctions'

const debug = process.env.REACT_APP_DEBUG && false;
const avatarSize = '90%';


const useStyles = makeStyles((theme) => {
  return {
    ...styles(theme),
    avatarLarge: { height: avatarSize, width: avatarSize },
    objectNameLarge: { fontSize: 'xx-large' },
    variableHeightCard: {
      height: '100%',
    },
    mapCardContent: {
      height: '100%',
      width: '100%',
    },
    root: {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'red',
      }
    },
    buttonGroup: {
      width: "100%",
      textAlign: 'left'
    },
    dividerColor: {

    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      width: "100%",
      color: theme.palette.text.secondary,
    },
    border: {
      border: "1px solid red"
    }
  }

});

export default function TankListItem(props: TankCardProps | any) {
  debug && console.log('Tank card got props', props)
  const theme = useTheme()
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);


  const { viewObject, optionals }: { viewObject: ViewObject, optionals: ThingViewOptionals } = props;
const {transactionFunctions}=props
  debug &&  console.log('tanklist viewObject:', viewObject) 
  const thingShadowStates: ThingShadowState[] = viewObject?.matchedRelatedBySk ? viewObject?.matchedRelatedBySk.filter((item: ValidBusinessObject) => item.type === 'ThingShadowState').map((item: ValidBusinessObject) => new ThingShadowState(item)) : [];
  thingShadowStates.sort((tss1, tss2) => {
    const sortRes = tss1?.payload?.timestamp?.toString() && tss2?.payload?.timestamp?.toString() ? tss1?.payload?.timestamp?.toString() < tss2.payload?.timestamp?.toString() ? 1 : -1 : 0
    return sortRes;
  });


  React.useEffect(() => {
     debug && console.log('viewObject.list item matchedPrimary',viewObject.matchedPrimary);
    
    if (viewObject.matchedPrimary?.type === 'Tank' && viewObject.largeObject === undefined) {
     transactionFunctions.getLargeObject(viewObject.matchedPrimary)      
    }
 }, []);

  const { gradientStops, color_of_silo, circleColor } = getFillGaugeSettings( viewObject, {}, theme)


  debug &&   console.log('thingShadowStates[0]', thingShadowStates)
  return (
    viewObject.matchedPrimary ?
      <NavLink filter={`${NavSections.THINGVIEW}/${viewObject.matchedPrimary?.type}/${viewObject.matchedPrimary?.id}`} children={
        <Box display="flex" justifyContent="flex-start">
          <Card>
            <CardHeader
                  avatar={
                    <LiquidFillGauge
                      style={{ margin: '0 auto'}}
                      width={80}
                      height={80}
                      value={viewObject.matchedPrimary?.current_filled_factor * 100}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      textRenderer={(props: any) => {
                        const value = Math.round(props.value);
                        const radius = Math.min(props.height / 2, props.width / 2);
                        const textPixels = (props.textSize * radius / 2);
                        const valueStyle = {
                          fontSize: textPixels
                        };
                        const percentStyle = {
                          fontSize: textPixels * 0.6
                        };

                        return (
                          <tspan>
                            <tspan className="value" style={valueStyle}>{value}</tspan>
                            <tspan style={percentStyle}>{props.percent}</tspan>
                          </tspan>
                        );
                      }}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: circleColor
                      }}
                      waveStyle={{
                        fill: color_of_silo
                      }}
                      textStyle={{
                        fill: color('#444').toString(),
                        fontFamily: 'Arial'
                      }}
                      waveTextStyle={{
                        fill: color('#262626').toString(),
                        fontFamily: 'Arial'
                      }}
                    />
                  }
                  subheader={viewObject.matchedPrimary?.name}/>
            <CardContent>
              {//Renders message if tank is over capacity
              viewObject.matchedPrimary?.current_filled_factor * 100 > 100 &&
              <Box>
                <Typography variant="caption"> {'>'}100% full, please increase tank height.</Typography>
              </Box>
              }
              <Grid container spacing={1}>
                  <Grid container item direction="column" spacing={1}>
                    <Grid container item spacing={2} >
                      <Grid item xs={12}>
                        <TankWaterAvailabilityCard matchedPrimary={viewObject.matchedPrimary} parentObjects={[viewObject.matchedRelatedByPk]} />
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <TankRainCard className={classes.subCard} latestPayload={thingShadowStates[0]} thingSS={thingShadowStates} parentObjects={[viewObject.matchedRelatedByPk]} ></TankRainCard>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      }></NavLink>
      : null
  );
}