import { CustomerAccountType, PossibleBusinessObject, ValidModelObject } from '@iotv/datamodel';
import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import { Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Button, Card, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid/';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { AddUserRequest, Severity, UserFunctions } from '../../../../types/AppTypes';
import { validateEmail, validatePassword } from '../../data/validate';
import { displayMessage } from '../Message/alert';
import styles from './Modal.module.css';

const modalRoot = document.querySelector('#modal-root-2');
const apiRef = 'SystemManagement';

const editTankStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '27ch',
      width: '79vw',
    },
  },
  textField: {
    width: 'auto',
    height: 'auto',
    color: 'primary',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  delete: {
    margin: theme.spacing(1),
  },

  cancel: {
    margin: theme.spacing(1),
  },
  save: {
    margin: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => {
  return {
    ...styles,
    root: {},
    inkBar: {
      backgroundColor: 'yellow',
    },
    tabs: {
      backgroundColor: 'red',
    },
    addButton: {
      backgroundColor: '#D3D3D3',
      padding: 15,
      // margin: theme.spacing(1),
    },
    tab: {
      flexGrow: 1,
      display: 'block',
      position: 'fixed',
      bottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      width: '100%',
    },
    indicator: {
      top: '0px',
      backgroundColor: '#44bcd8',
    },

    bottomTabStyle: {
      bottom: '0',
    },
    dividerColor: {},
  };
});

const tabuseStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 300,
  },
  main: {
    margin: theme.spacing(2),
  },
}));

export interface AddModalProps {
  showScreen: Dispatch<SetStateAction<boolean>>;
  type: string;
  source: ValidBusinessObject | undefined;
  contextUser: ValidBusinessObject | undefined;
  userFunctions: UserFunctions;
  setSuccess: Dispatch<SetStateAction<boolean>>;
}

const AddHousehold: React.FC<AddModalProps> = (props: AddModalProps) => {
  let { showScreen, type, source, userFunctions, contextUser, setSuccess } = props;

  const classes = useStyles();
  const tabStyle = tabuseStyles();

  const routerHistory = useHistory();
  const editTank = editTankStyles();

  let [creating, setCreating] = useState<boolean>(false);
  let [valid, setValid] = useState<boolean>(false);

  let [housename, setHousename] = useState<string>('');
  let [streetName, setStreetName] = useState<string>('');
  let [suburb, setSuburb] = useState<string>('');
  let [city, setCity] = useState<string>('');
  let [country, setCountry] = useState<string>('');
  let [lat, setLat] = useState<number>();
  let [lng, setLng] = useState<number>();

  let [userFilled, setUserFilled] = useState<boolean>(false);
  let [name, setUserName] = useState<string>('');
  let [username, setUsername] = useState<string>('');
  let [email, setEmail] = useState<string>('');
  let [phoneNumber, setPhoneNumber] = useState<number>();
  let [password, setPassword] = useState<string>('');
  let [showPassword, setShowPassword] = useState<boolean>(false);

  /**
   * Check if the form is valid
   */
  useEffect(() => {
    let positionValid = lat !== undefined && lng !== undefined;
    let nameValid = housename !== '' && streetName !== '' && suburb !== '' && city !== '' && country !== '';

    setValid(positionValid || nameValid);
  }, [housename, streetName, suburb, city, country, lat, lng]);

  useEffect(() => {
    if (name !== '' && username !== '' && validateEmail(email) && validatePassword(password)) {
      setUserFilled(true);
    } else {
      setUserFilled(false);
    }
  }, [name, username, email, phoneNumber, password]);

  /**
   * Saving the current modal
   */
  const saveToDB = () => {
    const householdZombie: PossibleBusinessObject = {
      address: streetName || 'N/A',
      suburb: suburb || 'N/A',
      city: city || 'N/A',
      country: country || 'N/A',
      location: { lat: lat || 0, lng: lng || 0 },
      accountType: CustomerAccountType.Z,
      name: housename,
      type: 'Customer',
    };

    if (source && contextUser) {
      // very dirty
      setCreating(true);
      ApiGateway.post('/system/Customer/add', { contextUser, newCustomer: householdZombie, parentCustomer: { ...source, pk: source.sk } }, apiRef).then(
        async (houseObj) => {
          if (houseObj.data !== null) {
            if (contextUser && userFilled) {
              let id = uuid();
              let user: Partial<ValidBusinessObject> = {
                email,
                id,
                name,
                phoneNumber: `+${phoneNumber?.toString()}`,
                type: 'User',
                username,
                pk: `User:${id}`,
                sk: `User:${id}`,
              };
              let contextCustomer = { id: houseObj.data.id, pk: `Customer:${houseObj.data.id}`, sk: `Customer:${houseObj.data.id}`, type: 'Customer' };
              const addUserRequest: AddUserRequest = {
                user,
                temporaryPassword: password,
                contextUser,
                contextCustomer: contextCustomer as ValidModelObject<'Customer'>,
                addToGroups: ['User'],
                makeAdmin: true,
              };
              const response = await ApiGateway.post('/system/User/add', addUserRequest, apiRef);
              if (response.err) {
                ApiGateway.post('/system/Customer/delete', { contextUser, customer: houseObj.data }, apiRef);
                displayMessage(`Failed to create user, ${response.err.stack}`, 'SnackBar', userFunctions, Severity.error);
              } else {
                displayMessage(`Created user ${name} for ${housename}`, 'SnackBar', userFunctions);
                setSuccess(true);
                showScreen(false);
              }
            } else {
              displayMessage(`Created ${housename}`, 'SnackBar', userFunctions);
              setSuccess(true);
              showScreen(false);
            }
          } else {
            displayMessage('Failed:', 'SnackBar', userFunctions, Severity.error);
          }
          setCreating(false);
        }
      );
    }
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    showScreen(false);
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <h3 style={{ textIndent: 10 }}>Create New Household</h3>
          <div>
            <h3 style={{ textIndent: 10 }}>Household</h3>
            <Grid container>
              <Grid item container className={styles.bottomPadding}>
                <form className={editTank.root} noValidate autoComplete='off'>
                  <Grid item container>
                    <TextField
                      label='Household Name'
                      onChange={(e) => setHousename(e.target.value)}
                      className={editTank.textField}
                      variant='outlined'
                      size='small'
                      required
                      disabled={creating}
                      error={housename === ''}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item container xs={5}>
                      <TextField
                        label='Street Name and Number'
                        onChange={(e) => setStreetName(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        disabled={creating}
                      />
                      <TextField
                        label='Suburb'
                        onChange={(e) => setSuburb(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        disabled={creating}
                      />
                      <TextField
                        label='City'
                        onChange={(e) => setCity(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        disabled={creating}
                      />
                      <TextField
                        label='Country'
                        onChange={(e) => setCountry(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        disabled={creating}
                      />
                    </Grid>
                    <Grid item container xs={2} justifyContent='center'>
                      <h4 style={{ alignSelf: 'center', justifySelf: 'center' }}>OR</h4>
                    </Grid>
                    <Grid item container xs={5}>
                      <br />
                      <TextField
                        label='Latitude'
                        type='number'
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => setLat(Number(e.target.value))}
                        className={editTank.textField}
                        value={lat}
                        variant='outlined'
                        disabled={creating}
                        size='small'
                      />
                      <TextField
                        label='Longitude'
                        type='number'
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => setLng(Number(e.target.value))}
                        className={editTank.textField}
                        value={lng}
                        variant='outlined'
                        disabled={creating}
                        size='small'
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
          <div>
            <h3 style={{ textIndent: 10 }}>User Detail</h3>

            <Grid container>
              <Grid item container className={styles.bottomPadding}>
                <form className={editTank.root} noValidate autoComplete='off' onSubmit={() => showScreen(false)}>
                  <Grid item container>
                    <Grid item container xs={6}>
                      <TextField
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        required
                        label='Householder'
                        onChange={(e) => setUserName(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        value={name}
                        disabled={creating}
                      />
                      <TextField
                        label='Email'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        required
                        value={email}
                        disabled={creating}
                        // error={!validateEmail(email)}
                      />
                      <TextField
                        label='Password'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        type={showPassword ? 'text' : 'password'}
                        disabled={creating}
                        value={password}
                        error={password !== '' && !validatePassword(password)}
                        helperText={
                          !validatePassword(password) &&
                          'Must Contain at least: 1 Uppercase, 1 Lowercase, 1 special character, and 1 number, and more than 6 characters'
                        }
                      />
                    </Grid>
                    <Grid item container xs={6}>
                      <TextField
                        label='Username'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setUsername(e.target.value)}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        required
                        value={username}
                        disabled={creating}
                        margin='dense'
                      />
                      <TextField
                        label='Phone Number'
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setPhoneNumber(Number(e.target.value))}
                        className={editTank.textField}
                        variant='outlined'
                        size='small'
                        type='number'
                        required
                        disabled={creating}
                        value={phoneNumber}
                        InputProps={{ startAdornment: <InputAdornment position='start'>+</InputAdornment> }}
                        helperText='  '
                      />
                      <FormGroup style={{ width: '100%' }}>
                        <FormControlLabel
                          style={{ alignSelf: 'center', justifySelf: 'center' }}
                          control={<Checkbox onChange={(e) => setShowPassword(!showPassword)} />}
                          disabled={creating}
                          label='Show Password'
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <div style={{ textIndent: 10 }} id='buttons'>
                    <Button variant='contained' color='error' disabled={creating} onClick={() => showScreen(false)}>
                      Cancel
                    </Button>
                    <Button variant='outlined' color='success' disabled={!valid || creating} onClick={() => saveToDB()}>
                      Create
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default AddHousehold;
