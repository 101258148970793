import { ValidBusinessObject, ValidModelObjects } from '@iotv/datamodel';
import React, { useEffect } from 'react';
import { getObjectHash, ValidModelObject } from '@iotv/datamodel-core';
import { subscribeToMQTTChannel, subscribeToNetworkChannel } from '../data/daoFunctions/daoFunctions';
import { IdType } from '@iotv/iotv-v3-types';


const debug = process.env.REACT_APP_DEBUG && false;

type AmplifyPubSubUnsubscribeType = () => any;

type SubscriptionMinimal = { unsubscribe: AmplifyPubSubUnsubscribeType }


export type UseMQTTChannelV2Props<X extends IdType, T> = {
  user: ValidModelObject<'User'> | undefined,
  topicGetter: (xs: X[]) => string[],
  topicGenerationObjects: X[],
  addToParentItems: ( dm: T ) => void
}

export type UseMQTTChannelHookV2Type<T> = {
  setterGetters: [receiveObjects: T[], setReceivedObjects: React.Dispatch<React.SetStateAction<T[]>>]
}



export const useMQTTChannelMessagesV2 = <X extends IdType, T>({ user, topicGetter, topicGenerationObjects, addToParentItems }: UseMQTTChannelV2Props<X, T>): UseMQTTChannelHookV2Type<T> => {
  const userId: string | undefined = user?.id
  const [receivedObjects, setReceivedObjects] = React.useState<T[]>([])
  const topicGeneratingObjectsHash = topicGenerationObjects.map((x) => x.id).join()

  useEffect(() => {
    const subscriptions: { [topic: string]: SubscriptionMinimal } = {}

    const asyncUnsub = async ( subscription: SubscriptionMinimal ) => {
      const unsubRes = await subscription.unsubscribe()
      debug && console.log( `Unsub res`, unsubRes)

    }

    const subscribe = async (user: ValidBusinessObject, topic: string) => {
      debug && console.log('useMQTTChannel subscribing to', topic)
      const subscription = subscribeToMQTTChannel(user, topic, (data: any) => {

          const receivedObject = data.value
          addToParentItems( receivedObject )
          debug && console.log('useMQTTChannel Calling setting received data', { receivedObject, })

          
        
      })
      if (subscription) {
        subscriptions[topic] = subscription
      }
    }

    const topics = topicGetter(topicGenerationObjects)
    debug && console.log('useMQTTChannel used effect on', { userId, topicGeneratingObjectsHash })

    if ( user && topics) {
      topics.forEach((topic) => subscribe(user, topic))
    }

    return function cleanup() {
      debug && console.log('useMQTTChannel is unsubscribing from ', Object.keys(subscriptions))
      Object.values( subscriptions ).forEach( ( sub ) => asyncUnsub( sub ))   
    }
  }, [userId, topicGeneratingObjectsHash, topicGetter ])


  return {
    setterGetters: [receivedObjects, setReceivedObjects]
  }


}