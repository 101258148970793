import { createStyles, Theme } from "@material-ui/core";

const lightColor = 'rgba(255, 255, 255, 0.7)';

export const styles = (theme: Theme) =>
createStyles({
  secondaryBar: {
    zIndex: 5,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 0,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});