import {
  AddressType, BusinessObjectAssembler,
  C021CustomerAccountType,
  c021ToLSCAccountType,
  c021ToLSCAccountTypeMap,
  Customer, CustomerAccountType, ErrData,
  getZombieInstanceFromPrimaryKey, lscToC021AccountType,
  reKeyItem,
  toCamelCase,
  ValidBusinessObject,
  ValidBusinessObjectList,
  ValidModelObject
} from '@iotv/datamodel';
import { AppValueTypes, MapLike } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, makeStyles, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid/';
import { createAndLink, link } from '../../../../../..//data/daoFunctions/daoFunctions';
import { Tabilizer } from '../../../../../../components/factories/Tabilizer';
import { GImport } from '../../../../../../components/io/GImport';
import { NavSections } from '../../../../../../components/navigation/NavSections';
import CustomerDeviceListTab from '../../../../../../components/SytemManagement/Customers/CustomerDevices/CustomerDeviceListTab';
import CustomerManageDeviceAllocationTab from '../../../../../../components/SytemManagement/Customers/CustomerDevices/CustomerManageDeviceAllocationTab';
import CustomerGatewayListTab from '../../../../../../components/SytemManagement/Customers/CustomerGateways/CustomerGatewayListTab';
import CustomerManageGatewayAllocationTab from '../../../../../../components/SytemManagement/Customers/CustomerGateways/CustomerManageGatewayAllocationTab';
import CustomerUserListTab from '../../../../../../components/SytemManagement/Customers/CustomerUsers/CustomerUserListTab';
import { NavBreadcrumbs } from '../../../../../../components/utils/Breadcrumbs';
import { TabPanel } from '../../../../../../components/utils/TabPanelShim';
import config from '../../../../../../config';
import styles from '../../../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../../../data/aws/api-gateway/ApiGateway';
import {
  AddUserRequest,
  AdjacentType,
  ComponentMode,
  CustomerManagementViewProps,
  CustomerViewSearchParamsType,
  DevicePoolType,
  GatewayPoolType,
  MessageOutputType, Severity,
  TabilizerProps,
  UserTransactionType,
  UserView,
  ViewDefinition
} from '../../../../../../types/AppTypes';
import { getQueryParamsFromHistory } from '../../../../../../util/Routing/queryParams';
import CommunityCreation from './CommunityCreation';
import CustomerContextTab from './CustomerContextTab';
import HouseholdCreation from './HouseholdCreation';
import { addToDevicesInventory } from './sharedFns/addMultipleDevices';


const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';

const assembler = new BusinessObjectAssembler();

const componentClassName = 'CustomerView';
const customerTemplate = { type: 'Customer' };
const devcustomerTemplate = {
  type: 'Customer',
  name: 'Master Blasters',
  email: 'master.blasters@loraiotlabs.com',
  accountType: 'X',
  phoneNumber: '+021111111',
};

const getMandatoryThingType = (detTypeId: string | undefined) => {
  const mandatoryThingMap: MapLike<string | undefined> = {
    Tank: 'House',
    default: undefined,
  };
  return detTypeId ? mandatoryThingMap[detTypeId] ?? mandatoryThingMap.default : undefined;
};

const getCustomerQuery = async (fn: (params: any) => void, contextUser: UserView, requestedCustomerSk: string) => {
  if (contextUser) {
    const contextCustomer = assembler.getZombieFromPrimaryKey(requestedCustomerSk);
    try {
      debug && console.log('CM user', contextUser);
      const response = await ApiGateway.post(`/system/Customer/get`, { contextUser, contextCustomer }, apiRef);
      debug && console.log('CM query results', response);
      if (response) {
        fn(response);
      } else {
        throw new Error(`Too many customers receied: ${JSON.stringify(response, null, 1)}`);
      }
    } catch (e) {
      debug && console.log('Error in CM query', e);
    }
  }
};

const getCustomerHierachyQuery = async (fn: (params: any) => void, contextUser: UserView, contextCustomer: ValidBusinessObject | undefined) => {
  if (contextCustomer && contextUser) {
    debug && console.log('Doing hierarchy query');
    try {
      const response: ErrData<ValidBusinessObjectList> = await ApiGateway.post(`/system/Customer/parents`, { contextUser, contextCustomer }, apiRef);
      if (response.data) {
        debug && console.log('hierarchy query res', response.data);
        const items = response.data.map((item) => Object.assign(item, { pk: item.sk })); // hierachry returns edges
        fn(items);
      } else {
        throw new Error(`Problem in HierarchQuery: ${JSON.stringify(response, null, 1)}`);
      }
    } catch (e) {
      debug && console.log('Error in Hierachy query', e);
    }
  }
};

const createUserObjectsForCustomerZ = async (contextUser: ValidBusinessObject, contextCustomer: ValidBusinessObject, createdCustomer: ValidBusinessObject) => {
  const res: ErrData<ValidBusinessObjectList> = { err: null, data: null };
  const createdObjects: ValidBusinessObjectList = [];
  const temporaryPasswordNumbers = new Date().getFullYear();
  const temporaryPassword = `@${toCamelCase(createdCustomer.name)}${temporaryPasswordNumbers}`;
  const userZombie: ValidBusinessObject = reKeyItem(Object.assign({}, createdCustomer, {}), uuidv4(), 'User');
  delete userZombie.accountType;
  const addUserRequest: AddUserRequest = { user: userZombie, temporaryPassword, contextUser, contextCustomer: createdCustomer as ValidModelObject<'Customer'>, addToGroups: ['User'], makeAdmin: true };
  userZombie.username = userZombie.name.replace(/ +/g, '.');
  userZombie.email = createdCustomer.email ?? `${userZombie.username}.autoGenerated@${userZombie.username}.com`;
  const createUserRes: ErrData<ValidBusinessObject> = await ApiGateway.post('/system/User/add', addUserRequest, apiRef);
  if (createUserRes.data) {
    const createdUser = createUserRes.data;
    createdObjects.push(createdUser);
    const mandatoryThingTypeId = getMandatoryThingType('Tank');
    if (mandatoryThingTypeId) {
      const mandatoryThingZombie: ValidBusinessObject = reKeyItem(Object.assign({}, createdCustomer, {}), uuidv4(), mandatoryThingTypeId);
      delete mandatoryThingZombie.accountType;
      const mandatoryThingLinkRes = await createAndLink({
        newObject: mandatoryThingZombie,
        existingObject: createdCustomer,
      });
      if (mandatoryThingLinkRes.data) {
        const createdMandatoryThing = mandatoryThingLinkRes.data[1];
        if (createdMandatoryThing) {
          createdObjects.push(createdMandatoryThing);
          const customerThingLink = await link(createdCustomer, createdMandatoryThing);
          if (customerThingLink.err) {
          }
        } else {
          console.log(`No linked object returned`, '');
        }
      } else {
      }
    } else {
    }
  }

  res.err = new Error(JSON.stringify(createUserRes, null, 1));

  return res;
};

function CustomerView(props: CustomerManagementViewProps): JSX.Element {
  // const [requestedCustomerSk, setRequestedCustomerSK] = React.useState<string | undefined>(props.match.params.id);
  const [parentCustomer, setParentCustomer] = React.useState<ValidBusinessObject | undefined>(undefined);
  const [parentCustomers, setParentCustomers] = React.useState<ValidBusinessObjectList>([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<ValidBusinessObject | undefined>(undefined);
  const [originalVersion, setOriginalVersion] = React.useState<ValidBusinessObject | undefined>(undefined);
  const [customerIncreation, setCustomerInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined);
  const [createUserObjects, setCreateUserObjects] = React.useState<boolean>(true);
  const [editMode, setEditMode] = React.useState(false);
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);
  const {
    userFunctions,
    history,
    transactionFunctions,
    match: {
      params: { action },
    },
  } = props;
  // const altHistory = history as unknown as { location: { search: string } };
  // const searchParams: CustomerViewSearchParamsType & { accountType: string} | undefined = altHistory?.location?.search ? queryString.parse(altHistory.location.search) as CustomerViewSearchParamsType : undefined
  const searchParams = getQueryParamsFromHistory<CustomerViewSearchParamsType>(history);
  const routerHistory = useHistory();
  const [tabValue, setTabValue] = React.useState(props.tabIndex ?? 0);

  const [refreshRequestCount, setRefreshRequestCount] = React.useState(0);

  const requestedCustomerSk: string | undefined = props.match.params.id;

  debug && console.log('CustomerView got', props);
  debug && console.log('CustomerView searchParams', searchParams);

  const displayMessage = (content: string, type: MessageOutputType, severity?: Severity) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type, severity });
  };

  const setParentVariableFromHierarch = (items: ValidBusinessObjectList, indexOfParent: number) => {
    setParentCustomers(items);
    setParentCustomer(items[indexOfParent]);
    debug && console.log('set parents with ', items);
  };

  const userSk = props.user?.sk;
  const contextObjectSK = searchParams?.contextObjectSK;
  const selectedCustomerSk = selectedCustomer?.sk;

  useEffect(() => {
    if (action === UserTransactionType.CREATE && searchParams?.contextObjectSK) {
      debug && console.log('CustomerView using effect for new customer process', searchParams);
      getCustomerHierachyQuery((items) => setParentVariableFromHierarch(items, 0), props.user, getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK));
      createNewCustomerProcess(searchParams);
    } else if (requestedCustomerSk) {
      getCustomerQuery(
        ({ err, data }) => {
          if (data) {
            setSelectedCustomer(data);
            setOriginalVersion({ ...data });
          } else {
            displayMessage(err.message, 'MessageBar');
          }
        },
        props.user,
        requestedCustomerSk
      );
      setCustomerInCreation(undefined);
      getCustomerHierachyQuery((items) => setParentVariableFromHierarch(items, 1), props.user, getZombieInstanceFromPrimaryKey(requestedCustomerSk));
      debug && console.log('CustomerView using effect to get cutomer', requestedCustomerSk);
    } else {
      debug && console.log('CustomerView used effect to no effect', { action, searchParams, requestedCustomerSk, selectedCustomerSk });
    }
  }, [action, userSk, requestedCustomerSk, contextObjectSK]);

  useEffect(() => {
    debug && console.log('CustomerView useEffect on refreshRequestCount', refreshRequestCount);
  }, [refreshRequestCount, selectedCustomerSk, contextObjectSK]);

  const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }));

  const addToDatabase = async () => {
    if (customerIncreation && parentCustomer) {
      debug && console.log('Add request', { parentCustomer, customerIncreation });
      const response = await ApiGateway.post('/system/Customer/add', { contextUser: props.user, newCustomer: customerIncreation, parentCustomer }, apiRef);
      debug && displayMessage(`DEBUG ${JSON.stringify(response, null, 1)}`, 'MessageBar');
      const returnedCustomer = response.data as ValidBusinessObject | null;
      if (returnedCustomer?.accountType === CustomerAccountType.Z && createUserObjects && props.user) {
        const createUserObjectRes = await createUserObjectsForCustomerZ(props.user, parentCustomer, returnedCustomer);
        if (createUserObjectRes.err) {
          displayMessage(createUserObjectRes.err.message, 'MessageBar');
        } else if (createUserObjectRes.data) {
          const message = `We created ${createUserObjectRes.data.map((item) => `${item.type} ${item.name}`)} for ${customerIncreation} with id ${
            customerIncreation.id
          } `;
          displayMessage(message, 'MessageBar');
        }
      }
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar');
      } else {
        // routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${parentCustomer.sk}?tabName=${'Customers'}`);
        routerHistory.goBack();
        displayMessage(`Added ${customerIncreation.name} to database`, 'SnackBar');
        setParentCustomer(undefined);
        setCustomerInCreation(undefined);
      }
    } else {
      displayMessage('No parent custoemr or new customer', 'MessageBar');
      debug && console.log('Did not add to databae', { customerIncreation, parentCustomer });
    }
  };

  const removeFromDatabase = async (customer: ValidBusinessObject | undefined) => {
    if (customer) {
      const response = await ApiGateway.post('/system/Customer/delete', { contextUser: props.user, customer }, apiRef);
      debug && console.log('Add response', response);
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar');
      } else if (requestedCustomerSk) {
        getCustomerQuery(({ err, data }) => setSelectedCustomer(data), props.user, requestedCustomerSk);
        setSelectedCustomer(undefined);
        routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}`);
        displayMessage(`Removed ${customer?.name} from datastore`, 'SnackBar');
      }
    }
  };

  const updateToDatabase = async (customer: ValidBusinessObject | undefined) => {
    if (customer) {
      const response = await ApiGateway.post('/system/Customer/update', { contextUser: props.user, customer }, apiRef);
      debug && console.log('Add response', response);

      if (response.err) {
        displayMessage(response.err.message, 'MessageBar');
      } else if (requestedCustomerSk) {
        getCustomerQuery(
          ({ err, data }) => {
            if (data) {
              setSelectedCustomer(data);
              setOriginalVersion({ ...data });
            } else {
              displayMessage(err.message, 'MessageBar');
            }
          },
          props.user,
          requestedCustomerSk
        );

        displayMessage(`Updated ${customer?.name} in datastore`, 'SnackBar');
      }
    }
  };

  const isEdited = () => {
    const edited =
      selectedCustomer &&
      originalVersion &&
      !Object.entries(selectedCustomer)
        .filter(([k, v1]) => typeof v1 !== 'object')
        .every(([k, v1]) => v1 === originalVersion[k]);
    // debug && console.log('Is Edited', { edited, selectedCustomer, originalVersion})
    // note still needs to equate "" to undefined really
    return edited;
  };

  const createNewCustomerProcess = (searchParams: CustomerViewSearchParamsType) => {
    setSelectedCustomer(undefined);
    const template = config.app.appCode === 'i001' ? devcustomerTemplate : customerTemplate;
    const templateWithAccountType = { ...template, accountType: searchParams.accountType, type: searchParams.objectTypeId };
    setCustomerInCreation(templateWithAccountType);
  };

  const proxyParent = assembler.getInstanceFromPrimaryKey('Customer:1');

  const customerAccountTypeShim = c021ToLSCAccountTypeMap;
  type CustomerAttributeKeys = keyof AddressType;
  const customerTableViewDefinition: ViewDefinition & Omit<{ [key in CustomerAttributeKeys]: any }, 'dwelling_number'> = {
    name: {
      key: 'name',
      label: 'Name',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    // type: { key: 'type', label: 'Type', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    accountType: {
      key: 'accountType',
      label: 'Entity Type',
      type: AppValueTypes.ENUM,
      enumKV: customerAccountTypeShim,
      editable: action === UserTransactionType.CREATE,
      valueSetter: (x: C021CustomerAccountType) => c021ToLSCAccountType(x),
      valueGetter: (x: CustomerAccountType) => lscToC021AccountType(x),
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },

    street_number: {
      key: 'street_number',
      label: 'Street Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    suburb: {
      key: 'suburb',
      label: 'Suburb',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    city: {
      key: 'city',
      label: 'City',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    country: {
      key: 'country',
      label: 'Country',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    post_code: {
      key: 'post_code',
      label: 'Postcode',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },

    email: {
      key: 'email',
      label: 'Email',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    phoneNumber: {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: action === UserTransactionType.CREATE ? /\+\d+$/ : undefined,
      failsValidationText: 'phone format +021...',
    },

    googleClientId: {
      key: 'googleClientId',
      label: 'Google Client ID',
      type: AppValueTypes.STRING,
      enumKV: undefined,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    googleAPIKey: {
      key: 'googleAPIKey',
      label: 'Google API Key',
      type: AppValueTypes.STRING,
      enumKV: undefined,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
  };

  const componentParentHasRendered = () => document.querySelector(`.${componentClassName}`);

  const wasEdited = isEdited();
  debug && console.log('Was Edited', wasEdited);

  const customerTableProps: TabilizerProps = {
    ...props,
    matchedPrimary: (selectedCustomer ?? customerIncreation ?? proxyParent) as ValidBusinessObject,
    actions: [action as UserTransactionType],
    viewDefinition: customerTableViewDefinition,
    functionOverrides: {
      updateParentComponentItem: (item: ValidBusinessObject) => {
        if (componentParentHasRendered()) {
          debug && console.log('Tabilizer setting CustomerView with has rendered', componentParentHasRendered());
          if (selectedCustomer) setSelectedCustomer(item);
          else if (customerIncreation) setCustomerInCreation(item);
        } else {
          debug && console.log('Tabilizer not setting CustomerView with has rendered', componentParentHasRendered());
        }
      },
      mode: customerIncreation || editMode ? ComponentMode.EDIT : ComponentMode.VIEW,
      hasValidationErrors: () => {
        if (componentParentHasRendered()) {
          debug && console.log('Tabilizer setting CustomerView with has rendered on validation', componentParentHasRendered());
          const hasErrors = document.querySelector('.Mui-error') !== null;
          debug && console.log('Setting has VErrors', hasErrors);
          setHasValidationErrors(hasErrors);
        } else {
          debug && console.log('parent not rendered');
        }
      },
    },

    injectedComponents:
      action === UserTransactionType.CREATE
        ? [
            <ButtonGroup key='addUSerBG'>
              <Button
                size='small'
                onClick={() => addToDatabase()}
                disabled={hasValidationErrors || !parentCustomers}
                startIcon={<GroupAddIcon />}
                variant='outlined'
                color='primary'
              >
                Create
              </Button>
              <Button
                size='small'
                onClick={() => setCustomerInCreation({ type: 'Customer' })}
                disabled={customerIncreation && Object.values(customerIncreation).find((val) => val !== 'Customer' && val !== undefined) === undefined}
                startIcon={<ClearIcon />}
                variant='outlined'
                color='primary'
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  setCustomerInCreation(undefined);
                  setEditMode(false);
                  routerHistory.goBack();
                }}
                size='small'
                startIcon={<CancelIcon />}
                variant='outlined'
              ></Button>
            </ButtonGroup>,
          ]
        : [
            <ButtonGroup key='newUserBG' disabled={editMode}></ButtonGroup>,
            <ButtonGroup key='editUserBG'>
              <Button
                size='small'
                onClick={() => removeFromDatabase(selectedCustomer)}
                disabled={!selectedCustomer}
                startIcon={<DeleteIcon />}
                variant='outlined'
                color='primary'
              >
                Delete
              </Button>
              <Button
                size='small'
                onClick={() => {
                  setEditMode(true);
                }}
                disabled={!selectedCustomer || editMode}
                startIcon={<EditIcon />}
                variant='outlined'
                color='primary'
              >
                Edit
              </Button>
              {selectedCustomer && (
                <Button
                  size='small'
                  onClick={() => {
                    updateToDatabase(selectedCustomer);
                  }}
                  disabled={!editMode || wasEdited !== true}
                  startIcon={<SaveIcon />}
                  variant='outlined'
                  color='primary'
                >
                  Save
                </Button>
              )}
              <Button
                size='small'
                onClick={() => {
                  setSelectedCustomer(undefined);
                  setEditMode(false);
                  setOriginalVersion(undefined);
                  routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}`);
                }}
                disabled={!editMode && customerIncreation && Object.values(customerIncreation).find((val) => val !== 'Customer' && val !== undefined)}
                startIcon={<CancelIcon />}
                variant='outlined'
              ></Button>
            </ButtonGroup>,
          ],
  };

  debug && console.log('Customer in creation before render is ', customerIncreation);
  debug &&
    console.log('clear control', customerIncreation && Object.values(customerIncreation).find((val) => val !== 'Customer' && val !== undefined) === undefined);
  debug && console.log('has ValidationErrors ', document.querySelector('.Mui-error'));

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const customerObject = selectedCustomer ? new Customer(selectedCustomer) : undefined;

  const canHaveSubCustomerTypeX = customerObject?.canHaveSubCustomerTypeX();
  const canHaveSubCustomerTypeY = customerObject?.canHaveSubCustomerTypeY();
  const canHaveSubCustomerTypeZ = customerObject?.canHaveSubCustomerTypeZ();
  const canHaveSubCustomers = canHaveSubCustomerTypeX || canHaveSubCustomerTypeY || canHaveSubCustomerTypeZ;
  const canHaveUsers = customerObject?.canHaveUsers();
  const canHaveDeviceInventory = customerObject?.canHaveDeviceInventory();
  const canHaveDeviceAllocation = customerObject?.canBeAllocatedDevices();
  const canManageGateways = customerObject?.canManageGateways();
  const canHaveGatewayAllocation = customerObject?.canBeAllocatedGateways();
  const userCanViewAllocatableDevices = () => parentCustomers.length > 1;
  const userCanViewAllocatableGateways = () => parentCustomers.length > 1;
  const [lastAllocatedDeviceSk, setLastAllocatedDeviceSk] = React.useState<string | undefined>(undefined);

  /**
   * Provide the string of the type's children
   * @param type of source
   * @returns string describing the children
   */
  const sourceCustomers = (type: CustomerAccountType) => {
    switch (type) {
      case CustomerAccountType.V:
        return 'Islands/Regions';
      case CustomerAccountType.X:
        return 'Communities';
      case CustomerAccountType.Y:
        return 'Households';
      case CustomerAccountType.Z:
        return '';
    }
  };

  const c021NameShim: MapLike<string> = {
    Customers: `${sourceCustomers(selectedCustomer?.accountType)}`,
  };

  const tabsToDisplay = ['Customers', 'Users', 'Devices', 'Device Allocation', 'Import Devices', 'Gateways'].filter(
    // 'Details' used to be the first option
    //, , 'Gateway Allocation'
    (tabName) =>
      (canHaveSubCustomers || tabName !== 'Customers') &&
      (canHaveUsers || tabName !== 'Users') &&
      (canHaveDeviceInventory || canHaveDeviceAllocation || tabName !== 'Devices') &&
      ((canHaveDeviceAllocation && userCanViewAllocatableDevices) || tabName !== 'Device Allocation') &&
      (canHaveDeviceInventory || tabName !== 'Import Devices')
    // && ((canHaveGatewayAllocation && userCanViewAllocatableGateways) || tabName !== 'Gateway Allocation')
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    debug && console.log('tab change', newValue);
    //setTabValue(newValue);

    const newSearchParems = { ...searchParams, tabName: tabsToDisplay[newValue] ?? tabsToDisplay[0] };
    const newUrl = queryString.stringifyUrl({
      url: routerHistory.location.pathname,
      query: newSearchParems,
    });
    routerHistory.push(newUrl);
  };

  if (searchParams?.tabName) {
    const openTabIndex = tabsToDisplay.indexOf(searchParams.tabName);
    if (openTabIndex !== -1 && tabValue !== openTabIndex) {
      setTabValue(openTabIndex);
    }
  }
  const contextCustomerInstance = parentCustomers.length > 0 ? new Customer(parentCustomers[0]) : undefined;
  const canBeMadeAdmin = contextCustomerInstance?.accountType !== CustomerAccountType.Z;

  debug && console.log('Parent Csutomer for Breadcrumbs', { selectedCustomer, parentCustomers });
  const itemToUrlFn = (item: ValidBusinessObject) => `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/${item.type}/${item.sk}`;
  // <div>        {JSON.stringify({parentCustomer: parentCustomer?.name, selectedCustomer: selectedCustomer?.name, customerIncreation})}</div>

  const refreshParent = () => setRefreshRequestCount(refreshRequestCount + 1);

  const devicesImportFn = async (vbos: ValidBusinessObjectList) => {
    if (props.user && contextCustomerInstance) {
      const addDevicesRes = await addToDevicesInventory({
        user: props.user as ValidBusinessObject & { type: 'User' },
        contextCustomer: contextCustomerInstance as ValidBusinessObject & { type: 'Customer' },
        devices: vbos.filter((vbo) => vbo.type === 'Device' && vbo.eui),
      });
      if (addDevicesRes.err) {
        displayMessage(addDevicesRes.err.message, 'MessageBar');
      } else {
        addDevicesRes.data && displayMessage(addDevicesRes.data, 'SnackBar');
      }

      refreshParent();
      routerHistory.goBack();
    }
  };

  if (searchParams?.accountType === CustomerAccountType.Y) {
    return (
      <>
        {/* contextUser: props.user, newCustomer: customerIncreation, parentCustomer */}
        <CommunityCreation contextUser={props.user} contextCustomer={parentCustomer} userFunctions={userFunctions} />
      </>
    );
  } else if (searchParams?.accountType === CustomerAccountType.Z) {
    return (
      <>
        <HouseholdCreation contextUser={props.user} contextCustomer={parentCustomer} userFunctions={userFunctions} />
      </>
    );
  } else {
    return (
      <Grid className={componentClassName} container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {
                <NavBreadcrumbs
                  {...{ items: parentCustomers, itemToUrlFn, current: selectedCustomer, homeUrl: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/` }}
                ></NavBreadcrumbs>
              }

              <Tabs value={tabValue} onChange={handleTabChange}>
                {tabsToDisplay.map((tabName, i) => (
                  <Tab label={c021NameShim[tabName] ?? tabName} {...{ key: `tab_${i}`, ...a11yProps(i) }} />
                ))}
              </Tabs>
              <TabPanel {...{ value: tabValue, index: 0 }}>
                {/* CONTAINS CUSTOMER DETAILS, UNCOMMENT WHEN WANTED */}
                {/* {action === UserTransactionType.UPDATE && selectedCustomer && props.user && (
                  <Grid item xs={12} lg={6}>
                    <Card>
                      <CardContent>
                        <Typography variant='h4' component='h2' gutterBottom>
                          {selectedCustomer.name}
                        </Typography>
                        {selectedCustomer && <Tabilizer key={'customerInputTable'} {...{ ...customerTableProps }}></Tabilizer>}

                        <Tooltip {...{ title: 'Details and Plans' }}>
                          <Button
                            {...{
                              variant: 'outlined',
                              onClick: () => {
                                const viewUrl = queryString.stringifyUrl({
                                  url: `/${NavSections.THINGVIEW}/Customer/${selectedCustomer.id}`,
                                  // query: {
                                  //   contextObjectSK: contextCustomer.sk
                                  // }
                                });
                                routerHistory.push(viewUrl);
                              },
                            }}
                          >
                            View Dashboard
                          </Button>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  </Grid>
                )} */}
                {selectedCustomer && <Grid item xs={12} lg={6}></Grid>}
                {action === UserTransactionType.CREATE && customerIncreation && props.user && (
                  <Grid item xs={12} lg={6}>
                    <Card>
                      <CardContent>
                        <Typography variant='h4' component='h2' gutterBottom>
                          New Customer
                        </Typography>
                        {customerIncreation && <Tabilizer key={'customerInputTable'} {...{ ...customerTableProps }}></Tabilizer>}
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {selectedCustomer && <Grid item xs={12} lg={6}></Grid>}
              </TabPanel>

              {true && action && canHaveSubCustomers && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Customers') }}>
                  {canHaveSubCustomerTypeX && (
                    <CustomerContextTab
                      {...{
                        key: 'subX',
                        ...props,
                        contextCustomer: selectedCustomer as ValidModelObject<'Customer'>,
                        accountRelationship: AdjacentType.CHILD,
                        accountType: CustomerAccountType.X,
                        title: `Regions in ${selectedCustomer?.name}`,
                      }}
                    ></CustomerContextTab>
                  )}

                  {canHaveSubCustomerTypeY && selectedCustomer?.accountType !== CustomerAccountType.Y && (
                    <CustomerContextTab
                      {...{
                        key: 'subY',
                        ...props,
                        contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>,
                        accountRelationship: AdjacentType.CHILD,
                        accountType: CustomerAccountType.Y,
                        title: `Communities in ${selectedCustomer?.name}`,
                      }}
                    ></CustomerContextTab>
                  )}
                  {canHaveSubCustomerTypeZ && (
                    <CustomerContextTab
                      {...{
                        key: 'subZ',
                        ...props,
                        contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>,
                        accountRelationship: AdjacentType.CHILD,
                        accountType: CustomerAccountType.Z,
                        title: `Households in ${selectedCustomer?.name}`,
                      }}
                    ></CustomerContextTab>
                  )}
                </TabPanel>
              )}
              {true && action && canHaveUsers && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Users') }}>
                  <CustomerUserListTab
                    {...{
                      ...props,
                      contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>,
                      accountRelationship: AdjacentType.CHILD,
                      accountType: CustomerAccountType.X,
                      title: 'Users',
                    }}
                  ></CustomerUserListTab>
                </TabPanel>
              )}
              {true && action && canHaveDeviceInventory && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Devices') }}>
                  <CustomerDeviceListTab
                    {...{
                      ...props,
                      devicePoolType: DevicePoolType.INVENTORY,
                      contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>,
                      accountRelationship: AdjacentType.CHILD,
                      accountType: CustomerAccountType.X,
                      title: 'Inventory',
                      refreshParent,
                      relatedViewRefreshCount: refreshRequestCount,
                    }}
                  ></CustomerDeviceListTab>
                </TabPanel>
              )}
              {true && action && canHaveDeviceAllocation && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Devices') }}>
                  <CustomerDeviceListTab
                    {...{
                      ...props,
                      devicePoolType: DevicePoolType.ALLOCATION,
                      contextCustomer: selectedCustomer as ValidModelObject<'Customer'>,
                      parentCustomer,
                      accountRelationship: AdjacentType.CHILD,
                      accountType: CustomerAccountType.X,
                      title: 'Allocation',
                      refreshParent,
                      relatedViewRefreshCount: refreshRequestCount,
                    }}
                  ></CustomerDeviceListTab>
                </TabPanel>
              )}
              {true && action && canHaveDeviceAllocation && userCanViewAllocatableDevices() && props.user && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Device Allocation') }}>
                  <CustomerManageDeviceAllocationTab
                    {...{ ...props, parentCustomer, contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>, refreshParent, relatedViewRefreshCount: refreshRequestCount }}
                  ></CustomerManageDeviceAllocationTab>
                </TabPanel>
              )}
              {true && action && canHaveDeviceInventory && props.user && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Import Devices') }}>
                  <GImport {...{ ...props, importObjectTypeId: 'Device', importFn: devicesImportFn }}></GImport>
                </TabPanel>
              )}
              {true && action && canHaveGatewayAllocation && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Gateways') }}>
                  <CustomerGatewayListTab
                    {...{
                      ...props,
                      gatewayPoolType: GatewayPoolType.ALLOCATION,
                      contextCustomer: selectedCustomer as ValidModelObject<'Customer'>,
                      parentCustomer,
                      accountRelationship: AdjacentType.CHILD,
                      accountType: CustomerAccountType.X,
                      title: 'Gateways',
                      refreshParent,
                      relatedViewRefreshCount: refreshRequestCount,
                    }}
                  ></CustomerGatewayListTab>
                </TabPanel>
              )}
              {true && action && canHaveGatewayAllocation && userCanViewAllocatableGateways() && props.user && (
                <TabPanel {...{ value: tabValue, index: tabsToDisplay.indexOf('Gateway Allocation') }}>
                  <CustomerManageGatewayAllocationTab
                    {...{ ...props, parentCustomer, contextCustomer: selectedCustomer  as ValidModelObject<'Customer'>, refreshParent, relatedViewRefreshCount: refreshRequestCount }}
                  ></CustomerManageGatewayAllocationTab>
                </TabPanel>
              )}
              {false && (
                <Button {...{ variant: 'outlined', onClick: () => setRefreshRequestCount(refreshRequestCount + 1) }}>
                  {' '}
                  {`Refresh ${refreshRequestCount} `}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(CustomerView);
