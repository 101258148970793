import { ValidBusinessObject, ValidModelObjects } from '@iotv/datamodel';
import React, { useEffect } from 'react';
import { ValidModelObject } from '@iotv/datamodel-core';
import { subscribeToMQTTChannel, subscribeToNetworkChannel } from '../data/daoFunctions/daoFunctions';


const debug = process.env.REACT_APP_DEBUG && false;
const effectDebug = process.env.REACT_APP_DEBUG && false;

type AmplifyPubSubUnsubscribeType = () => any;

export type ValidNetworkMessageObjectTypeId = 'GatewayData' | 'DeviceMessage' | 'DeviceControlTransaction'

export type UseMQTTChannelProps<T> = {
  user: ValidModelObject<'User'> | undefined,
  topic: string | undefined,
  objectFilter: ( x: T ) => x is T,
}

export type UseMQTTChannelHookType<T> = {
  setterGetters: [ receiveObjects: T[] , setReceivedObjects: React.Dispatch<React.SetStateAction<T[]>> ]
}

export const useMQTTChannelMessages = <T>({ user, topic, objectFilter }: UseMQTTChannelProps<T>): UseMQTTChannelHookType<T> => {
  const userId: string | undefined = user?.id
  const [receivedObjects, setReceivedObjects] = React.useState<T[]>([])
  const transformedReceivedObejctsRef = React.useRef(receivedObjects); // May not be needed?
  const setTransformedNetworkObjects = (transformedViewObjects: T[]) => {
    transformedReceivedObejctsRef.current = transformedViewObjects; // keep updated
    setReceivedObjects(transformedViewObjects);
  };
 
  useEffect(() => {
    debug && console.log('useMQTTChannel used effect on', { userId, topic })
    let mqttTopicSubscription: { unsubscribe: AmplifyPubSubUnsubscribeType } | undefined = undefined;
    const subscribe = async (user: ValidBusinessObject, topic: string ) => {
      debug && console.log('Calling subscribe useMQTTChannel', { user, topic } )

        mqttTopicSubscription = subscribeToMQTTChannel(user, topic, (data: any) => {
          debug && console.log('useMQTTChannel Calling back functional component with Network Data', data)
          if (  objectFilter(data.value )) {
            const reveivedObject = data.value
            const transformedReceivedObjectsClone = [...transformedReceivedObejctsRef.current];
            transformedReceivedObjectsClone.unshift(reveivedObject)
            debug && console.log('transformedViewObjectsClone', transformedReceivedObjectsClone)
            setTransformedNetworkObjects(transformedReceivedObjectsClone)

          } else {
            debug && console.log(`data value is an array of zero length ingnoring as we want ValidBusinesObject`, data.value)
          }
        })
      
    }
    if ( userId && user &&  topic ) {
      subscribe(user, topic );
      return function cleanup() {
        debug && console.log('useMQTTChannel is unsubscribing')
        const asyncUnsub = async () => {
          const unsubRes = await mqttTopicSubscription?.unsubscribe()
          debug && console.log('unsubscribing from useMQTTChannel topic', {topic, unsubRes})
  
        }
        asyncUnsub()
      }
    }
   
   
  }, [ userId, topic ])


  return {
    setterGetters: [receivedObjects, setReceivedObjects] 
  }


}