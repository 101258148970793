import { useHistory } from 'react-router';
import { useStyles } from '../../../cosmetics/communityStyles';
// import { Trigger } from '@c021/datamodel';
import { ObjectCardProps } from '../../../../../types/AppTypes';
import { Button, Card, MenuItem, Stack, TextField, Typography } from '@mui/material';
// import { Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { getAssignableCustomers, getCustomerTree, getFreeCustomers } from './DroughtManagementPlanHelpers';
import { AdjacentType, CustomerAccountType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import { Invalid } from '../../Message/error';
import { StaticLoadingAnimation } from '../../../cosmetics/Loading';
import DroughtManagementPlanTemplate from './Plan/DroughtManagementPlanTemplate';
import { link, unlink } from '../../../../../data/daoFunctions/daoFunctions';
import AppDao from '../../../../../data/AppDao';
import { addObjectToDB } from '../../../Database/addToDb';
import { emptyStageTemplate } from './DMPExtrasV2';

const debug = process.env.REACT_APP_DEBUG && false;

export type DroughtManagementPlanFormProps = ObjectCardProps & {};

export const DroughtManagementPlanV2 = (props: DroughtManagementPlanFormProps) => {
  const styles = useStyles();
  const history = useHistory();
  const {
    transactionFunctions,
    userFunctions,
    searchParams,
    viewObject,
    contextCustomer,
    userGeolocation,
    viewObject: { matchedPrimary },
  } = props;

  const isMounted = useRef(false);

  let [valid, setValid] = useState<boolean>(false);
  let [customerOptions, setCustomerOptions] = useState<ValidBusinessObjectList>();
  let [selectedCustomer, setSelectedCustomer] = useState<ValidBusinessObject>();
  let [source, setSource] = useState<ValidBusinessObject>(matchedPrimary!);

  useEffect(() => {
    if (contextCustomer) {
      let type = contextCustomer.accountType;
      if (type === CustomerAccountType.V || type === CustomerAccountType.X) {
        getCustomers(contextCustomer);
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }
  }, [contextCustomer]);

  useEffect(() => {
    if (matchedPrimary && customerOptions) {
      if (customerOptions?.length === 1) {
        setSelectedCustomer(customerOptions[0]);
        matchedPrimary?.name === undefined && firstSave(matchedPrimary!, customerOptions[0]);
      }
      getAssignedCustomer(matchedPrimary, customerOptions);
    }
  }, [customerOptions]);

  // use effect after mounted
  useEffect(() => {
    if (isMounted.current && source) {
      save(source);
    } else {
      isMounted.current = true;
    }
  }, [source]);

  const getAssignedCustomer = async (plan: ValidBusinessObject, options: ValidBusinessObjectList) => {
    const res = await AppDao.queryByGraph({
      contextObjects: options,
      path: [{ objectTypeId: 'DroughtManagementPlan', adjacencyType: AdjacentType.CHILD }],
    });
    if (res.data) {
      let mapper = res.data;
      let source = mapper.getParentNodes(plan) as ValidBusinessObjectList;
      if (source.length === 1) {
        setSelectedCustomer(source[0]);
      }
    }
  };

  const getCustomers = async (context: ValidBusinessObject) => {
    setCustomerOptions(matchedPrimary?.name === undefined ? await getFreeCustomers(context) : await getAssignableCustomers(context));
  };

  const setCustomer = (id: string) => {
    let found = customerOptions?.find((customer) => customer.id === id);
    if (found !== undefined) {
      if (selectedCustomer !== undefined) {
        if (selectedCustomer.id !== found.id) {
          updatePlanOwner(selectedCustomer, found, source);
        }
      } else {
        matchedPrimary?.name === undefined && firstSave(source, found);
      }
      setSelectedCustomer(found);
    }
  };

  const firstSave = (initial: ValidBusinessObject, parent: ValidBusinessObject) => {
    let modified = { ...initial, name: `${parent.name}'s Plan`, currentStage: 1, stage: [emptyStageTemplate(1), emptyStageTemplate(2)] };
    setSource(modified);
    addObjectToDB(modified, parent).then((res) => {
      console.log('Initial Save', res);
    });
  };

  const updatePlanOwner = (previous: ValidBusinessObject, current: ValidBusinessObject, plan: ValidBusinessObject) => {
    unlink(previous, plan);
    link(current, plan);
  };

  const save = (newVersion: ValidBusinessObject) => {
    AppDao.save(newVersion).then((res) => {
      console.log('Saved with res', res);
    });
  };

  if (valid) {
    if (customerOptions !== undefined) {
      return (
        <div style={{ width: '100vw' }}>
          <Card className={styles.container} variant='outlined'>
            <Typography variant='h5' component='h5'>
              Drought Management Plan
            </Typography>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
              <Typography variant='h6' component='h6'>
                Plan Made For:
              </Typography>
              <TextField
                label={customerOptions.length === 0 ? 'No Appropriate Customer' : 'Select Customer'}
                select
                disabled={customerOptions.length < 2 || selectedCustomer !== undefined}
                variant='outlined'
                style={{ width: '300px' }}
                onChange={(e) => setCustomer(e.target.value)}
                value={selectedCustomer || ''}
                size='small'
                SelectProps={{
                  renderValue: () => <>{selectedCustomer?.name}</>,
                }}
              >
                {customerOptions.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Stack>
            <br />
            {selectedCustomer && <DroughtManagementPlanTemplate source={source} setSource={setSource} contextCustomer={selectedCustomer} finduser = {props}  />}
          </Card>
        </div>
      );
    } else {
      return <StaticLoadingAnimation />;
    }
  } else {
    return <Invalid />;
  }
};
