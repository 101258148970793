//https://mui.com/components/tree-view/#contentcomponent-prop
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import TreeItem, {
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

const CustomContentRoot = styled('div')(({ theme }) => ({
  WebkitTapHighlightColor: 'transparent',
  '&:hover, &.Mui-disabled, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover':
    {
      backgroundColor: 'transparent',
    },
  [`& .MuiTreeItem-contentBar`]: {
    position: 'absolute',
    width: '100%',
    height: 24,
    left: 0,
    '&:hover &': {
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled &': {
      opacity: theme.palette.action.disabledOpacity,
      backgroundColor: 'transparent',
    },
    '&.Mui-focused &': {
      backgroundColor: theme.palette.action.focus,
    },
    '&.Mui-selected &': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity,
      ),
    },
    '&.Mui-selected:hover &': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    '&.Mui-selected.Mui-focused &': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
      ),
    },
  },
}));



export const ExtendedTreeItem = ({x,  ...props }: TreeItemProps & { x: JSX.Element}) => {
    const CustomContent = React.forwardRef(function CustomContent(
        props: TreeItemContentProps,
        ref,
      ) {
        const {
          className,
          classes,
          label,
          nodeId,
          icon: iconProp,
          expansionIcon,
          displayIcon,
        } = props;
      
        const {
          disabled,
          expanded,
          selected,
          focused,
          handleExpansion,
          handleSelection,
          preventSelection,
        } = useTreeItem(nodeId);
      
        const icon = iconProp || expansionIcon || displayIcon;
      
        const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          preventSelection(event);
        };
      
        const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          handleExpansion(event);
          handleSelection(event);
        };
      
        return (
          <CustomContentRoot
            className={clsx(className, classes.root, {
              'Mui-expanded': expanded,
              'Mui-selected': selected,
              'Mui-focused': focused,
              'Mui-disabled': disabled,
            })}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
          >
            <div className="MuiTreeItem-contentBar" />
            <div className={classes.iconContainer}>{icon}</div>
            <Typography component="div" className={classes.label}>
              {label}
            </Typography>
            { x }
          </CustomContentRoot>
        );
      });

   return <TreeItem ContentComponent={CustomContent} {...props} />
};

