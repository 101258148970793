import { createStyles, makeStyles, Theme } from "@material-ui/core";

export  const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    flexGrow: 1,
  },
  margin: {
    height: theme.spacing(3),
  },
  card: {
    minWidth: 200,
  },
  form: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
})
);