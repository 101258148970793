
import { renderToString } from 'react-dom/server';
import createHtmlOverlay from '../extensions/HtmlOverlay';

const debug = process.env.REACT_APP_DEBUG && false;

export const addHTMLOverlay = (map: google.maps.Map, element: JSX.Element, bounds: google.maps.LatLngBounds ) => {

const infoDiv = renderToString(element)


    const infoOverlay: google.maps.OverlayView = createHtmlOverlay({

        html: infoDiv , bounds } )
        map && infoOverlay.setMap(map)
  
        debug && console.log('set html overlay', map, infoOverlay)

    infoOverlay.addListener('onclick', () => {
        debug && console.log('Got info overlay click')
        infoOverlay.setMap(null);
    })
   
    return infoOverlay
}