// import { ThingShadowState } from '@iotv/datamodel'
// import { TableContainer, Paper, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { HistoryStore, ObjectHistory, roundDecimals, ValidModelObject } from '@iotv/datamodel';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Tank } from '@v017/datamodel';
import React, { useEffect } from 'react';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import { LineChartXType } from '../../../types/AppTypes';
import { RangeableLineChart } from './charts/RangeableLineGraph';
// import { RangeableLineChart } from '../../charts/RangeableLineGraph';

const googleChartUrl = config.google.chartApiUri;
const debug = process.env.REACT_APP_DEBUG && false;



type TankFillGraphCardProps = {
  matchedPrimary: ValidModelObject<'Tank'>
  filterGraphKeys?: string[]
  objectHistory?: ObjectHistory
}
const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "100%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  // paper: {
  //   // padding:  spacing(2),
  //   textAlign: 'center',
  //   // color: palette.text.secondary,
  // }, 
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end'
  }
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const v017ObjectHistoryKeys = {
  recentStateHistory:{ 
    volume_used_yesterday: 'volume_used_yesterday',
    percentage_used_yesterday: 'percentage_used_yesterday',
    current_filled_volume: 'current_filled_volume',
    current_filled_percentage: 'current_filled_percentage',
  } 
}

export default function TankFillGraphCard(props: TankFillGraphCardProps) {
  console.log('TankFillGraphCard from v017 temporary')
  const classes = useStyles();
  const [viewSelector, setViewSelector] = React.useState('litres');
  const { matchedPrimary, objectHistory, filterGraphKeys } = props;
  const [duration, setDuration] = React.useState(30)
  const [ historyObject, setHistoryObject ] = React.useState<HistoryStore | undefined>( objectHistory?.recentStateHistory)

  const tank = new Tank(matchedPrimary)
  const totalVolume = tank._totalVolume
  debug && console.log('TankFillGraphCard objectHistory', objectHistory)

  const handleViewSelector = () => {
    // setViewSelector(!viewSelector)
    setViewSelector(viewSelector === 'litres' ? 'percentage' : 'litres')
  }




  useEffect(() => {
  }, [duration] )


  const googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`)

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date()
    fromDate.setDate(fromDate.getDate() - duration);
    const unixFromDate = (fromDate.valueOf() / 1000) * 1000
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate) -1
    let maxIdx = historyObject.timestamp.length
    lowIdx = lowIdx < 0 ? 0 : lowIdx
    debug && console.log('rangeA max index', lowIdx, maxIdx)
    const volumeArr = (historyObject[v017ObjectHistoryKeys.recentStateHistory.current_filled_volume] ?? []) as number[]
    historyObject[v017ObjectHistoryKeys.recentStateHistory.current_filled_percentage] = volumeArr.map( (v) => roundDecimals( (100 * v / totalVolume ), 0))

    const wholeKeys = Object.keys(historyObject);
    const keys = wholeKeys.filter((key) => key === graphKey)
    const xType: LineChartXType = LineChartXType.TIMESERIES

    const rows = keys.map((key) => {
      return historyObject[key] &&
        <RangeableLineChart {...{ historyObject, xType, classes, keys: [key], lowIdx, maxIdx }}></RangeableLineChart>
    }).filter((element) => element)
    return rows;
  }
  const handlerDurationChange = (event: any) => {
    const duration = parseInt(event.target.value)
    setDuration(duration)
    if ( duration > 30 ) {
      setHistoryObject(objectHistory?.dailyStateHistory)
    } else {
      setHistoryObject(objectHistory?.recentStateHistory)
    }

  }

  return (
    <div>
      {viewSelector === 'percentage' ? (
        historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, v017ObjectHistoryKeys.recentStateHistory.current_filled_percentage)
      ) : (
        historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, v017ObjectHistoryKeys.recentStateHistory.current_filled_volume)
      )}

      {/* {historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'current_filled_volume')} */}
      <div className={classes.bottomCard}>
        {/* <Card className={classes.root}   >
          <CardContent> */}
        <Grid container >
          <Grid item xs={6} >
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>(%)</Grid>
                  <Grid item>
                    <AntSwitch checked={viewSelector === 'litres' ? true : false} onChange={handleViewSelector} name="checkedC" />
                  </Grid>
                  <Grid item>(L)</Grid>
                </Grid>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" bgcolor="background.paper">
                   <FormControl className={classes.formControl}>
                    <Select
                      value={duration}
                      defaultValue={30}
                      onChange={handlerDurationChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={2}>2 days</MenuItem>
                      <MenuItem value={5}>5 days</MenuItem>
                      <MenuItem value={7}>7 days</MenuItem>
                      <MenuItem value={30}>1 Month</MenuItem>
                      <MenuItem value={365}>1 year</MenuItem>
                      <MenuItem value={365 * 2}>2 years</MenuItem>
                      <MenuItem value={365 * 5}>5 years</MenuItem>
                    </Select>
                  </FormControl>
                {/* </Box> 
              </Grid>*/}
            </Box>
          </Grid>
        </Grid>
        {/* </CardContent>
        </Card> */}
      </div>



    </div>
  );
}

