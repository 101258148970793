import { ErrData, ObjectHistory, ValidBusinessObject } from "@iotv/datamodel";
import React from "react";
import { useEffect } from "react";
import { initClient } from "../components/io/sharedFns";

const debug = process.env.REACT_APP_DEBUG && false;

export const useGoogleAPIAuth = (contextCustomer: ValidBusinessObject | undefined, scriptPresent: boolean) => {
    const [signedIn, setSignedIn] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false)



    const handleAuthClick = () => {
        debug && console.log('useGoogleSheets signing in')
          gapi.auth2.getAuthInstance().signIn();
    }

    const handleSignoutClick = () => {
        debug && console.log('useGoogleSheets signing out')
        gapi.auth2.getAuthInstance().signOut();
    }


    const handleGoogleLogInError = ( reason: any ) => {
        debug && console.log('Google login  err', reason )
        // displayMessage(reason.result.error.message, 'MessageBar')
    }

    const handleGoogleLoginsSuccess = ( res: void ) => {
        debug && console.log('Google login success');
        setIsInitialized(true);

    }

    useEffect(() => {
        debug && console.log(`script present ${scriptPresent}, signedIn ${signedIn}`)
        if (scriptPresent) {
            if ( !signedIn) {
                debug && console.log(`init google api`)
                const init = async () => initClient(contextCustomer, setSignedIn).then( handleGoogleLoginsSuccess, handleGoogleLogInError )
                init();
            } else {
                setIsInitialized(true)
                debug && console.log(`skipped init google api`)
            }
    
        }
      
    }, [scriptPresent])

    return { 
        state: [[ signedIn, setSignedIn], [isInitialized, setIsInitialized] ], 
        handlers: [ handleAuthClick, handleSignoutClick ] }

}