import { ErrData, ErrDataPromise } from "@iotv/datamodel";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import React, { useEffect, useReducer } from "react";
import { DocumentClientQueryHookOutputV2 } from "../types/LabTypes";

const debug = process.env.REACT_APP_DEBUG && false;

export type DocumentClientQueryHookInputV2<T> = {
  query: ( params: DocumentClient.QueryInput) => ErrDataPromise<DocumentClient.QueryOutput>, queryParams: DocumentClient.QueryInput | undefined, dependencies: (string | undefined)[]
}

export function useDocumentClientQuery<T>({ query, queryParams, dependencies }: DocumentClientQueryHookInputV2<T>): DocumentClientQueryHookOutputV2<T> {

  const [updates, forceUpdate] = useReducer(x => { debug && console.log(`force update`, x + 1); return x + 1 }, 0);
  const [limit, setLimit] = React.useState<number | undefined>(undefined)
  const [ inFlight, setInFlight ] = React.useState<boolean>(false)

  const [items, setItems]: [DocumentClient.AttributeMap[], React.Dispatch<React.SetStateAction<DocumentClient.AttributeMap[]>>] = React.useState<DocumentClient.AttributeMap[]>([]);
  const [LastEvaluatedKeys, setLastEvaluatedKeys]: [DocumentClient.Key[], React.Dispatch<React.SetStateAction<DocumentClient.Key[]>>] = React.useState<DocumentClient.Key[]>([]);
  const [ExclusiveStartKey, setExclusiveStartKey]: [DocumentClient.Key | undefined, React.Dispatch<React.SetStateAction<DocumentClient.Key | undefined>>] = React.useState<DocumentClient.Key | undefined>(undefined);

  const excuteQuery = async ( query: ( params: DocumentClient.QueryInput) => ErrDataPromise<DocumentClient.QueryOutput>, params: DocumentClient.QueryInput, setterFn: (result: ErrData<DocumentClient.QueryOutput>) => void) => {
    setInFlight(true)
    const queryRes = await query( params )
    setInFlight(false)
    setterFn( queryRes)
  }

  const setterFn = (result: ErrData<DocumentClient.QueryOutput>) => {
    debug && console.log('setter fn was  called with', result)
    if (result.data) {
      const data = result.data;
      if (data.Items) {
        setItems(data.Items)
      };
      if (data.LastEvaluatedKey) {
        const keys = [...LastEvaluatedKeys];
        keys.push(data.LastEvaluatedKey);
        setLastEvaluatedKeys(keys)
      }
    } else if ( result.err ) {
      console.log('useDocumentClientQuery has err', result.err.message)
    }
  }

  useEffect(() => {
    debug && console.log('useObjecuseDocumentClientQuery effect because', { ...dependencies, updates, limit, ExclusiveStartKey })
    if (queryParams ) {
    
      const mutatedParams = { ...queryParams, ExclusiveStartKey, Limit: limit }
      debug && console.log('useObjecuseDocumentClientQuery calling with mutatedParams ', { query, setterFn, mutatedParams })
      excuteQuery( query, queryParams, setterFn )
    }


  }, [ updates, limit, ExclusiveStartKey, dependencies[0], dependencies[1], dependencies[2], dependencies[3] ])


  return {
    querySetterGetters: [
      [items, setItems],
      [limit, setLimit],
      [LastEvaluatedKeys, setLastEvaluatedKeys],
      [ExclusiveStartKey, setExclusiveStartKey]
    ],
    forceUpdate, updates,
    inFlight
  }


}