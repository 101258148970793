import { Box, createStyles, FormControl, makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import { ENG_MONTHS } from "@iotv/datamodel-core";
import styles from '../../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../../cosmetics/Themes/dense';
import { InputComponentProps } from "../../../types/AppTypes";
import { getDayInputMenuItems, getHoursInputMenuItems, getMinutesInputMenuItems, getMonthInputMenuItems, getYearInputMenuItems } from "./dateHelperFns";

const debug = process.env.REACT_APP_DEBUG && false;
const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    ...createStyles({
    }),
    smaller: {
        fontSize: 12
    },
}));



export const DateInput = (props: InputComponentProps) => {
    const { matchedPrimary, viewKeyDefinition, updateKV, functionOverrides } = props;
    const classes = useStyles(denseTheme);
    const stdOptions: Partial<TextFieldProps> = {
        size: 'small',
        margin: 'dense',
        variant: 'standard',
        type: 'number',
        InputProps: {
            classes: {
                input: classes.smaller,
            },
        },
        InputLabelProps: { shrink: true },
    }
    //debug && console.log('Validation res', validationError)
    const inputType = viewKeyDefinition.type
    const currentValue = matchedPrimary[viewKeyDefinition.key]
    const isValidMs = typeof currentValue === 'number'
    const currentSetDate = isValidMs ? new Date(currentValue) : new Date('1970-01-01')

    let [year, month, day, hour, min] = [currentSetDate.getFullYear(), currentSetDate.getMonth(), currentSetDate.getDate(), currentSetDate.getHours(), currentSetDate.getMinutes()]

    const keyBase = `${matchedPrimary.sk ?? 'newObject'}_${viewKeyDefinition.key}`

    return <Box { ...{ component: 'div'}}><FormControl    {...{ className: classes.root, key: `${keyBase}_fcontrol`,  }}>

        <TextField {...{
            select: true,
            onChange: (event) => {
                const derivedValue = event.target.value;
                year = parseInt(derivedValue)
                currentSetDate.setFullYear(year)
                const pseudoEvent = {
                    currentTarget: {
                        type: 'number', value: currentSetDate.valueOf().toString()
                    },

                }
                updateKV(pseudoEvent as React.ChangeEvent<HTMLInputElement>, viewKeyDefinition.key);
            },
            key: `${keyBase},_inputYear`,
            value: year,
            label: 'Y',
            disabled: viewKeyDefinition.editable === false,
            ...stdOptions

        }}

        >{getYearInputMenuItems(year, 1, 1)}</TextField>
    </FormControl><FormControl>
        <TextField {...{
            select: true,
            onChange: (event) => {
                const derivedValue = event.target.value;
                month = Object.values(ENG_MONTHS).findIndex((v) => v === derivedValue)
                currentSetDate.setMonth(month)
                const pseudoEvent = {
                    currentTarget: {
                        type: 'number', value: currentSetDate.valueOf().toString()
                    },

                }
                updateKV(pseudoEvent as React.ChangeEvent<HTMLInputElement>, viewKeyDefinition.key);

            },
            key: `${keyBase},_inputMonth`,
            value: ENG_MONTHS[month],
            label: 'M',
            disabled: viewKeyDefinition.editable === false,
            ...stdOptions

        }}

        >{getMonthInputMenuItems()}</TextField>
    </FormControl><FormControl>
        <TextField {...{
            select: true,
            onChange: (event) => {
                const derivedValue = event.target.value;
                day = parseInt(derivedValue)
                currentSetDate.setDate(day)
                const pseudoEvent = {
                    currentTarget: {
                        type: 'number', value: currentSetDate.valueOf().toString()
                    },

                }
                updateKV(pseudoEvent as React.ChangeEvent<HTMLInputElement>, viewKeyDefinition.key);
            },
            key: `${keyBase},_inputDay`,
            value: day,
            label: 'D',
            disabled: viewKeyDefinition.editable === false,
            ...stdOptions

        }}

        >{getDayInputMenuItems( year, month)}</TextField>
                    </FormControl><FormControl>
        <TextField {...{
            select: true,
            onChange: (event) => {
                const derivedValue = event.target.value;
                hour = parseInt(derivedValue)
                currentSetDate.setHours(hour)
                const pseudoEvent = {
                    currentTarget: {
                        type: 'number', value: currentSetDate.valueOf().toString()
                    },

                }
                updateKV(pseudoEvent as React.ChangeEvent<HTMLInputElement>, viewKeyDefinition.key);
            },
            key: `${keyBase},_inputHour`,
            value: hour,
            label: 'H',
            disabled: viewKeyDefinition.editable === false,
            ...stdOptions

        }}

        >{getHoursInputMenuItems()}</TextField>
    </FormControl><FormControl>
                <TextField {...{
            select: true,
            onChange: (event) => {
                const derivedValue = event.target.value;
                const minute = parseInt(derivedValue)
                currentSetDate.setMinutes(minute)
                const pseudoEvent = {
                    currentTarget: {
                        type: 'number', value: currentSetDate.valueOf().toString()
                    },

                }
                updateKV(pseudoEvent as React.ChangeEvent<HTMLInputElement>, viewKeyDefinition.key);
            },
            key: `${keyBase},_inputMinute`,

            type: inputType,
            value: min,
            label: 'M',
            disabled: viewKeyDefinition.editable === false,
            ...stdOptions

        }}

        >{getMinutesInputMenuItems()}</TextField>
    </FormControl>
    </Box>
};


