import { ValidBusinessObject } from '@iotv/datamodel';
import config from '@iotv/config';
import { NormalizedData } from '../../types/AppTypes';
import { getThingAlerts, getThingImageUrl, getThingShadowStates, subscribeToThingAlertTopic, subscribeToThingTopic, subscribeToUserChannel } from '../daoFunctions/daoFunctions';
import { getThingHistory } from '../daoFunctions/historyFns';
import { addToNormalData } from '../daoFunctions/stateFns';



const debug = process.env.REACT_APP_DEBUG && false;
/**
 * @function userThingsQuery Gets the latest ThingShadowState and Alerts ( which are actually deprecated ) for each of the users DeviceEnabaledTypes via AppState's normalized data, and sets up MQTT topic subscriptions to each
 * @param user 
 * @param normalizedData 
 * @param things 
 * @returns 
 */
const userThingsQuery = async function ( user: ValidBusinessObject, normalizedData: NormalizedData, things: ValidBusinessObject[])  {

     if (user && !process.env.REACT_APP_LESS) {
        if (things.length > 0) {
          debug && console.log('User things', things)

          const queryPromises = [
             ...things.map((thing) => getThingImageUrl(thing)),
          ];

          if ( config.app.appCode !== 'i001x') {
            const tssQueries = [
              getThingShadowStates(things, normalizedData),
              getThingAlerts(things, normalizedData),
              // ...things.map((thing) => getThingHistory(thing, 'ThingShadowState', 100).then((items: ValidBusinessObject[]) => items.forEach((item) => addToNormalData(item, normalizedData)))),
              //...things.map((thing) => getThingHistory(thing, 'Alert', 10).then((items: ValidBusinessObject[]) => items.filter( (_item, i) => i < 20  ).forEach((item) => addToNormalData(item, normalizedData)))),
  
            ]
            tssQueries.forEach( ( query ) => queryPromises.push(query))


            }
          await Promise.all(queryPromises);

          const pubSubPromises = [
           ...things.map((thing) => {
             debug && console.log('subscribing to thing topic', thing.id)
             return subscribeToThingTopic(thing);
           }),
           ...things.map((thing) => {
             return subscribeToThingAlertTopic(thing);
           }),
           //getGeofences(user, normalizedData),
           subscribeToUserChannel(user),
          
         ];
         await Promise.all(pubSubPromises);

        }
      }
      else {
      }
    debug && console.log('User query returning', normalizedData)
    return normalizedData;
  }

export { userThingsQuery };
