
const debug = process.env.REACT_APP_DEBUG && false;

const createHtmlOverlays = ({ OverlayView = google.maps.OverlayView, ...args} )   => {
    debug && console.log('args are', args)
    class HtmlOverlay extends google.maps.OverlayView {
        private bounds: google.maps.LatLngBounds;
        private html: string;
        private div?: HTMLElement;
      
        constructor(bounds: google.maps.LatLngBounds, html: string) {
          super();
      
          this.bounds = bounds;
          this.html = html;
          debug && console.log('params are', {bounds, html})
    
        }
        onAdd() {
            this.div = document.createElement("div");
            this.div.style.borderStyle = "none";
            this.div.style.borderWidth = "0px";
            this.div.style.position = "absolute";
          
            // Create the img element and attach it to the div.
            this.div.innerHTML= this.html
          
            // Add the element to the "overlayLayer" pane.
            const panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.div);
          }
    
          draw() {
            // We use the south-west and north-east
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            const overlayProjection = this.getProjection();
          
            // Retrieve the south-west and north-east coordinates of this overlay
            // in LatLngs and convert them to pixel coordinates.
            // We'll use these coordinates to resize the div.
            const sw = overlayProjection.fromLatLngToDivPixel(
              this.bounds.getSouthWest()
            );
            const ne = overlayProjection.fromLatLngToDivPixel(
              this.bounds.getNorthEast()
            );
          
            // Resize the image's div to fit the indicated dimensions.
            if (this.div) {
              this.div.style.left = sw.x + "px";
              this.div.style.top = ne.y + "px";
              this.div.style.width = ne.x - sw.x + "px";
              this.div.style.height = sw.y - ne.y + "px";
            }
    

          }

          onRemove() {
            if (this.div) {
              (this.div.parentNode as HTMLElement).removeChild(this.div);
              delete this.div;
            }
          }
          
    };
    return new HtmlOverlay(args.bounds, args.html)

}
  
export default createHtmlOverlays