import { NotificationSubscritpionFilterRequest, sendNotificationRequests } from '@iotv/messages'
import { ReactAppProcessEnv, StandardProceessEnv } from '@iotv/iotv-v3-types'
import React, { useEffect } from 'react'
import { DeliveryType } from '@iotv/datamodel-core'
import { Auth } from '@aws-amplify/auth'
import { ICredentials } from '@aws-amplify/core'
import { AdditionalSendConfig } from '../../../../../../../../../i001-fw/iotv/messages/target/iotv/messages/src/NotifyDirect/sqsFunctions'
const debugOverride = process.env.DEBUG_OVERRIDE === 'true';
const debug = (!process.env.AWS_SESSION_TOKEN && true) || debugOverride;


export type DevMessagesProps = {
    message: string
}

const { REACT_APP_PROJECT_CODE: projectCode, REACT_APP_DEPLOYMENT_STAGE: stage, REACT_APP_AWS_ACCOUNT_ID: accountId, REACT_APP_AWS_DEFAULT_REGION: region } = process.env as NodeJS.ProcessEnv & ReactAppProcessEnv

export const SendMessage = async (title: string, message: string, emaillist: string[], smslist: string[]) => {
    const credentials: ICredentials = await Auth.currentUserCredentials()

    var SMSmessage = message.replace(/<br \/>/gi, '\n');
    const params: NotificationSubscritpionFilterRequest & AdditionalSendConfig = {
        queueUrlOverride: `https://sqs.ap-southeast-2.amazonaws.com/${accountId}/${projectCode}-${stage}-notification-emit-queue`,
        deliveryConfigs: [
            {
                deliveryType: DeliveryType.EMAIL,
                recipients: [
                    {
                        "pk": "User:b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "sk": "User:b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "email": "russ.evans@firekart.com",
                        "id": "b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "IdentityId": "ap-southeast-2:c5f74202-f2bd-4651-b055-77a802b28e58",
                        "name": "Russ Evans Firekart",
                        "phoneNumber": "+64211915580",
                        "phone_number": "+64211915580",
                        "type": "User",
                        "username": "russ.evans@firekart.com"
                    }
                ],
                destinations: emaillist,
                referenceKey: 'NotificationTestParent:ABC'
            }
        ],
        messageData: {
            content: message,
            subject: title,
            html: undefined
        },
        credentials
    }
    const params2: NotificationSubscritpionFilterRequest & AdditionalSendConfig = {
        queueUrlOverride: `https://sqs.ap-southeast-2.amazonaws.com/${accountId}/${projectCode}-${stage}-notification-emit-queue`,
        deliveryConfigs: [
            {
                deliveryType: DeliveryType.SMS,
                recipients: [
                    {
                        "pk": "User:b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "sk": "User:b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "email": "russ.evans@firekart.com",
                        "id": "b187158e-b093-4ef4-9f80-23cca27bc24b",
                        "IdentityId": "ap-southeast-2:c5f74202-f2bd-4651-b055-77a802b28e58",
                        "name": "Russ Evans Firekart",
                        "phoneNumber": "+64211915580",
                        "phone_number": "+64211915580",
                        "type": "User",
                        "username": "russ.evans@firekart.com"
                    }
                ],
                destinations: smslist,
                referenceKey: 'NotificationTestParent:ABC'
            }
        ],
        messageData: {
            content: SMSmessage,
            subject: title,
            html: undefined
        },
        credentials
    }

    console.log('creds', credentials)
    console.log("sendoutout", emaillist, smslist, message)
    console.log("sendoutout2", SMSmessage)

    const res = await sendNotificationRequests(params)
    const res2 = await sendNotificationRequests(params2)

    console.log("sendoutout3", res,res2)

}


