/**
 * Check if a string fits an email format
 * @param stringToTest string in question
 * @returns result of test
 */
export const validateEmail = (stringToTest: string): boolean => {
  var re = /\S+@\S+\.\S+/;
  return re.test(stringToTest);
};

/**
 * Checks if the password string is valid
 * @returns if the password is accepted or not
 */
export const validatePassword = (word: string): boolean => {
  const lower = /[a-z]/g;
  const upper = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const special = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/g;
  if (word.match(lower) && word.match(upper) && word.match(numbers) && word.match(special) && word.length > 5) {
    return true;
  }
  return false;
};
