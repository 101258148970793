import {  getZombieInstanceFromPrimaryKey, MapLike, ValidBusinessObject } from "@iotv/datamodel"
import { Button, Grid, GridSize, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { v4 } from 'uuid'
import { link } from "../../../data/daoFunctions/daoFunctions"
import { MessageOutputType, ObjectFuculatorProps, ObjectTreeConfig, ObjectTreeProps } from "../../../types/AppTypes"
import { ObjectTree } from "../ObjectTree/ObjectTree"


type MultipleSelectionSet = { [k: string]: string[] }

const useStyles = makeStyles({
      addButton: {
        marginTop: '1rem', maxWidth: '90%'
        },
     smaller: { 
         fontSize: 10, textAlign: 'right', paddingRight: '2rem', color: 'grey'
     }
  
  
  });

export const ObjectFuculator = ( { contextObject, objectTrees = [], userFunctions, ...others}: ObjectFuculatorProps) => {
    const classes = useStyles()
    const numberOfTrees = objectTrees.length;
    const widthGridSize: GridSize = Math.ceil( 12 / numberOfTrees) as GridSize

    const getSelectedObjectsStructure = ( objectTrees: ObjectTreeConfig[]): MultipleSelectionSet => {
        return Object.fromEntries( objectTrees.map( (objectTree, i) => [ `${objectTree.mainAdjacencyQuery.objectTypeId}_${i}`, [] as string[]]))
    }
    const displayMessage = (content: string, type: MessageOutputType) => {
        userFunctions.setUserMessage({ id: v4(), content, label: content, type })
      }


    const [ multiSelection, setMultiSeletion ] = React.useState<MultipleSelectionSet>(getSelectedObjectsStructure( objectTrees ));

    const handleLinkObjects = async ( action: ValidActionType ) => {
        const childObjects = action.source.nodeIds.map( (nodeId) => getZombieInstanceFromPrimaryKey(nodeId))
        const parentObject = getZombieInstanceFromPrimaryKey(action.target.nodeIds[0]);
        if ( childObjects && parentObject) {
          const edgeTypeId = undefined;
          const promises = await Promise.all( childObjects.filter( (childObject) => childObject ).map( async (childObject) => { 
            const linkRes = await link( parentObject, childObject as ValidBusinessObject, edgeTypeId )
            return linkRes
          } ) )

          const errs = promises.map( ( linkRes ) => linkRes.err?.message ?? undefined ).filter( (errMsg) => errMsg )

          const message = errs.length > 0 ? errs.join(', ') : `linked ${ action.source.nodeIds.length } objects`
          displayMessage(message,  errs.length > 0 ? 'MessageBar' : 'SnackBar' )
        
        } 
     }
   
    type ValidActionType = { source: { k: string, i: number, nodeIds: string[]} , target: { k: string, i: number, nodeIds: string[]}}

    const getValidLinkActions = () => {
        const targets = Object.entries(multiSelection).map( ([k, nodeIds], i) => ({ k, i, nodeIds}) ).filter( ({ k, i, nodeIds} ) => nodeIds.length === 1 )
        const sources = Object.entries(multiSelection).map( ([k, nodeIds], i) => ({ k, i, nodeIds}) ).filter( ({ k, i, nodeIds} ) => nodeIds.length > 0 )

        const actions: ValidActionType[] = [];

        targets.forEach( (target) => { 
            sources.forEach( (source) => {
                if (target.k !== source.k) {
                    actions.push( { target, source })
                }
            })
         })

        return actions;
    }

    const validLinkActions = getValidLinkActions();
    const validActionsHash = validLinkActions.map( (action) => `${action.source.k}:${action.source.i}:${action.target.k}:${action.target.i}`)


    useEffect( () => {

    }, [ validActionsHash ])


    const getButtons = () => {

        const buttonsPerSet: MapLike<JSX.Element[]> = {};
        
        validLinkActions.forEach( ( action ) => {
            const targetVobSk = action.target.nodeIds[0];
            const targetVobType = targetVobSk.match(/.*?(?=:)/i);
            const sourceVobTypes = action.source.nodeIds.reduce( ( cur, nodeId, i ) => {
            const type = nodeId.match(/.*?(?=:)/i);
            if ( type && !cur.includes(type[0])) {
                cur.push(type[0])
            }
            return cur
            }, [] as string[] ).join(', ')
           
            const msg = `Link ${action.source.nodeIds.length} ${sourceVobTypes}${ action.source.nodeIds.length > 1 ? 's' : ''} from list ${action.source.i} to ${targetVobType} in list ${action.target.i}`
            if ( !buttonsPerSet[ action.source.k] ) {
                buttonsPerSet[ action.source.k] = []
            }
            buttonsPerSet[ action.source.k].push(<Button { ...{
                className: classes.addButton, fullWidth: true,
                variant: 'contained', color: 'primary',
                onClick: () => handleLinkObjects( action )
                }
                }      
            >{ msg } </Button> ) }
        )
        return buttonsPerSet
}

    const buttons = getButtons()

    return <Grid container { ...{ key: 'objectFuculatorA'}}>
        { contextObject && <>
            { 
                objectTrees.map( (objectTree, i) => {
                    const objectTreeKey = `${objectTree.mainAdjacencyQuery.objectTypeId}:${objectTree.branchAdjacencyQueries?.map( (q) => `${q.objectTypeId}:${q.adjacencyType.valueOf()}:${q.edgeTypeId}`).join(':')}`
                    const objectTreeProps: ObjectTreeProps = { 
                        contextObject,
                        ...objectTree,
                        userFunctions,
                        setSelectedInParent: ( nodeIds: string[] ) => { 
                            const selectionClone = {...multiSelection}
                            selectionClone[`${objectTree.mainAdjacencyQuery.objectTypeId}_${i}`] = nodeIds
                           
           
                            setMultiSeletion( selectionClone )
                        },
                    } 
                    return <Grid  item { ...{ key: objectTreeKey, xs: widthGridSize}}>
                            <div { ...{ className: classes.smaller }}>{ `selected items: ${ (multiSelection[`${objectTree.mainAdjacencyQuery.objectTypeId}_${i}`]).length }`}</div>
                            <ObjectTree { ...{ ...objectTreeProps } }></ObjectTree>
                            { buttons[`${objectTree.mainAdjacencyQuery.objectTypeId}_${i}`] ?? null}
                            { }
                        </Grid>
                })
            }
        
       
        </>
         }

    </Grid>
}