import { isValidBusinessObject, MapLike, ValidBusinessObject, ValidBusinessObjectList, ValidModelType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Chip, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../cosmetics/sharedCardStyles';
import { getPseudoVBO } from '../../data/TypeHelpers';
import { useGoogleAPIAuth } from '../../hooks/useGoogleAPIAuth';
import { useGoogleListSheets, UseGoogleListSheetsReturnType, UseGoogleListSheetsState } from '../../hooks/useGoogleIO';
import { useSelectedObjects, UseSelectedObjectsReturnType  } from '../../hooks/useSelectedObjects';
import { AdjacentType, GoogleSpreadsheetData, ImportObjectsFromGSheetProps, ListerlizerProps, MessageOutputType, ObjectCardProps, ViewDefinition } from '../../types/AppTypes';
import { gapiIsPresent } from '../../util/Scripts/isPresent';
import { Listerlizer } from '../factories/Listilizer';
import { ImportedSpreadsheetDataTable } from './ImportedSpreadsheetDataTable';
import { spreadsheetDataAsVBOs, spreadsheetDataToViewDefintion } from './sharedFns';

const debug = process.env.REACT_APP_DEBUG && false;

const localStyle = (theme: Theme) => ({});

const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle(theme) }));

type GSheetError = { code: number, message: string, status: string }



export const ImportObjectsFromGSheet = ({ contextCustomer, userSelectedGroup, transactionFunctions, userFunctions, setVBOsInParent, setImportDefinitionInParent, parentHasRendered }: ImportObjectsFromGSheetProps) => {
    const classes = useStyles();

    const { state: [ [ signedIn, setSignedIn], [isInitialized, setIsInitialized] ], handlers: [ handleAuthClick, handleSignoutClick ] } = useGoogleAPIAuth(contextCustomer, gapiIsPresent())
    const { 
        state:  [ 
            [nameMatchText, setNameMatchText], 
            [matchedFiles, setMatchedFiles],
            [selectedFile, setSelectedFile],
            [availableSheets, setAvailableSheets],
            [selectedSheet, setSelectedSheet],
            [selectedRange, setSelectedRange]
        ], 
        handlers: [ handleListFiles ] 
    }: UseGoogleListSheetsReturnType = useGoogleListSheets( {signedIn});
    const [error, setError] = React.useState<GSheetError | undefined>(undefined)

    const [spreadsheetData, setSpreadsheetData] = React.useState<GoogleSpreadsheetData>([])

    const [importObjectType, setImportObjectType] = React.useState<string | undefined>();

    const { state: [ [ selectedObjects, setSelectedObjects ] ], handlers: { addToSelectedObjects, removeFromSelectedObjects, isInSelectedObjects } }: UseSelectedObjectsReturnType = useSelectedObjects()
   
    const displayMessage = (content: string, type: MessageOutputType) => {
        userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
      }
    

    const handleGoogleError = ( reason: any ) => {
        console.log('Google err', reason )
        displayMessage(reason.result.error.message, 'MessageBar')
    }

    const handleGoogleGetSpreadsheetSheetsSuccess = ( res: gapi.client.Response<gapi.client.sheets.Spreadsheet> ) => {
        const sheets: gapi.client.sheets.Sheet[] = res.result.sheets ?? []
        setAvailableSheets(sheets)
        debug && console.log('got spreashseet sheets', sheets)
    }

    const getSpreadsheetSheets = async (spreadsheetId: string) => {
        const query = {
            spreadsheetId
        };
        const res = await gapi.client.sheets.spreadsheets.get(query).then(
            handleGoogleGetSpreadsheetSheetsSuccess, handleGoogleError
        )
    }

    const getSpreadsheetData = async (spreadsheetId: string, range: string) => {
        const target = {
            spreadsheetId,
            range,
        };

        try {
            const res = await gapi.client.sheets.spreadsheets.values.get(target);
           const values = res.result.values ?? [];
            setSpreadsheetData(values)
            return res;
        } catch (error: any) {
            console.log('Caught Error', error)
            if (error.result.error) {
                setError(error.result.error)
            }
        }
    }

    useEffect(() => {
        debug && console.log('ImportFromGSheet update on signInStatus')
    }, [signedIn, isInitialized])

     useEffect(() => {
        if (selectedFile?.id) {
            getSpreadsheetSheets(selectedFile.id);
            setSelectedSheet(undefined)
        }
    }, [selectedFile?.id])

    useEffect(() => {
        if (selectedFile?.id && selectedRange) {
            getSpreadsheetData(selectedFile.id, selectedRange)
        }
    }, [selectedSheet?.properties?.sheetId, selectedRange])

    const handleReload = () => {
        setNameMatchText(undefined)
        setAvailableSheets([]);
        setImportObjectType(undefined);
        setMatchedFiles([]);
        setSelectedFile(undefined);
        setSelectedRange(undefined);
        setSelectedSheet(undefined)
        setSpreadsheetData([]);
        setSelectedObjects([])
    }

    const handleReset = () => {
        setSelectedRange(undefined);
        setSpreadsheetData([]);
        setSelectedObjects([])
    }

    const clearImportedObjects = () => setSpreadsheetData([])

    const getUrlFromFile = (file: gapi.client.drive.File) => file.webViewLink

    const spreadsheetsAsVBOs = () => matchedFiles.map((file) => getPseudoVBO({ ...{...file, uri: getUrlFromFile(file) } as MapLike<any>, type: 'GoogleSpreadsheet' } as ValidBusinessObject))

    const spreadsheetsListerlizerProps: ListerlizerProps = {
        transactionFunctions,
        userFunctions,
        viewObject: {
            matchedPrimary: getPseudoVBO({ type: 'UserSheets', id: 'userSheets' }),
            matchedRelatedByPk: [],
            matchedRelatedBySk: spreadsheetsAsVBOs()
        },
        contextObject: undefined, 
        viewDefinition: {
            name: { key: 'name', type: AppValueTypes.STRING, label: 'Spreadsheet Name', editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined, enumKV: undefined }
        },
        adjacentFilter: {
            adjacentType: AdjacentType.CHILD,
            objectType: 'GoogleSpreadsheet' as ValidModelType
        },
        sortKeyIn: 'name',
        maxItems: 8,
        selectorConfigParams: {
            enabled: false,
            multipleSelect: false,
            controls: {
                add: false,
                find: false,
            }
        },
        functionOverrides: {
            selectItem: (sk: string) => {
                setSelectedFile(matchedFiles.find((file) => file.id === sk.replace('GoogleSpreadsheet:', '')));
            },
            hasValidationErrors: () => { }
        },
        injectedComponents: [],
        checkedItemSks: selectedFile?.id ? [`GoogleSpreadsheet:${selectedFile.id}`] : [],
        optionals: {rowSelectionFirst: true}
    }

    const avalilabeSheetsListerlizerProps: ListerlizerProps = {
        transactionFunctions,
        userFunctions,
        viewObject: {
            matchedPrimary: getPseudoVBO({ type: 'GoogleSpreadsheet', id: selectedFile?.id ?? 'xxxx' }),
            matchedRelatedByPk: [],
            matchedRelatedBySk: availableSheets.map((sheet) => getPseudoVBO({ id: sheet.properties?.sheetId?.toString() ?? 'yyyy', type: 'GoogleSpreadsheetSheet', ...sheet, idx: sheet.properties?.index ?? 0, name: sheet.properties?.title ?? 'No title' }))
        },
        contextObject: undefined, 
        viewDefinition: {
            name: { key: 'name', type: AppValueTypes.STRING, label: 'Sheet Name', editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined, enumKV: undefined },
            idx: { key: 'idx', type: AppValueTypes.NUMBER, label: 'Sheet Index', editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined, enumKV: undefined }

        },
        adjacentFilter: {
            adjacentType: AdjacentType.CHILD,
            objectType: 'GoogleSpreadsheetSheet' as ValidModelType
        },
        sortKeyIn: 'name',
        maxItems: 8,
        selectorConfigParams: {
            enabled: false,
            multipleSelect: false,
            controls: {
                add: false,
                find: false,
            }
        },
        functionOverrides: {
            selectItem: (sk: string) => {
                const match = sk.replace('GoogleSpreadsheetSheet:', '');
                const matchedSheet = availableSheets.find((sheet) => {

                    const sheetId = sheet.properties?.sheetId !== undefined ? sheet.properties.sheetId.toString() : '';
                    debug && console.log('sheet id in match', { sheet, sheetId })
                    return sheetId === match
                });
                debug && console.log('Matched Sheet', { matchedSheet, match, availableSheets })
                if (matchedSheet) {
                    setSelectedSheet(matchedSheet);
                    setImportObjectType(undefined)
                    setSpreadsheetData([])
                    setSelectedRange(`${matchedSheet.properties?.title}!A1:Z100`)
                }

            },
            hasValidationErrors: () => { }
        },
        injectedComponents: [],
        checkedItemSks: selectedSheet?.properties?.sheetId !== undefined ? [`GoogleSpreadsheetSheet:${selectedSheet.properties.sheetId}`] : [],
        optionals: {rowSelectionFirst: true}
    }

    const objects = spreadsheetDataAsVBOs( { spreadsheetData, importObjectType, setImportObjectType })

  

    const importViewDefinition = spreadsheetDataToViewDefintion({spreadsheetData})

    setImportDefinitionInParent(importViewDefinition)

    return <>
 <Grid item xs= {12}>
        <Card>
                <CardContent>
                <span><Typography {...{ variant: 'h6' }}>Google Sheets Import</Typography></span>
                <span>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={signedIn ? handleSignoutClick : handleAuthClick}
                        disabled={!isInitialized}
                    >

                        {signedIn ? 'Sign out' : 'Authorize'}</Button>
                    {signedIn && <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleListFiles()}
                    > List
                        </Button>}
                    {spreadsheetData && <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleReset}
                    > Reload
                        </Button>}
                    </span>
                </CardContent>

            </Card>
        </Grid>
        { (isInitialized && signedIn) ? <Grid container spacing={3}>
       
       <Grid item xs={12}>
           
            {error && <Card>
                <CardContent>
                    <Chip
                        label={error.message}
                        onDelete={() => { setError(undefined); }}
                        color="secondary"
                    ></Chip>
                </CardContent>

            </Card>}
        </Grid>
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <FormControl className={classes.editFormControlFullWidth}>
                            <InputLabel htmlFor="nameTextSearch">Search Spreadsheets</InputLabel>
                            <Input
                                id="nameTextSearch"
                                type='text'
                                value={nameMatchText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNameMatchText(event.currentTarget.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label='clear search text'
                                            onClick={ () => setNameMatchText('')}
                                            edge="end"
                                        >
                                            {nameMatchText ? <Cancel /> : ''}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardContent>
                        <FormControl className={classes.editFormControlFullWidth}>
                            <TextField
                                size={'small'}
                                margin={'dense'}
                                InputLabelProps={{ shrink: true }}
                                type='text'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedRange(event.currentTarget.value)}
                                label={'Selected Range'}
                                variant="standard"
                                value={selectedRange}
                            ></TextField>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>

        <Grid item xs={12} lg={6} >
            <Card>
                <CardContent>
                    <Typography {...{ variant: 'h6' }}> Matched Spreadsheets</Typography>
                    <Listerlizer {...{ ...spreadsheetsListerlizerProps }}></Listerlizer>
                </CardContent>
            </Card>
        </Grid>
        {availableSheets.length > 0 && <Grid item xs={12} lg={6}>
            <Card>
                <Typography {...{ variant: 'h6' }}> Available Sheets</Typography>
                <Listerlizer {...{ ...avalilabeSheetsListerlizerProps }}></Listerlizer>
            </Card>
            { selectedFile && <Typography><a { ...{ target: "_blank", href: `https://docs.google.com/spreadsheets/d/${selectedFile.id}`}}>link</a></Typography>}
        </Grid>}
        {spreadsheetData.length > 0 && <ImportedSpreadsheetDataTable
            { ...{
                objects, clearImportedObjects, importObjectType, importViewDefinition, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, setVBOsInParent, setImportDefinitionInParent, parentHasRendered 
            }}
        ></ImportedSpreadsheetDataTable>
         }
    </Grid> : null }
    </>

}