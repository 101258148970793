import { Grid, makeStyles, Typography, TextField, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import styles from './Creation.module.css';
import React, { useState, useEffect } from 'react';
import { validateEmail, validatePassword } from '../../../../data/validate';
import { ValidBusinessObject } from '@iotv/datamodel-core';
import { createCommunityZombie } from '../../../../Database/Zombies/CommunityZombie';
import { displayMessage } from '../../../Message/alert';
import { UserFunctions } from '../../../../../../types/AppTypes';
import { addObjectToDB } from '../../../../../../venture/c021/Database/addToDb';
import { Severity } from '../../../../../../types/AppTypes';

const editTankStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '27ch',
      width: '79vw',
    },
  },
  textField: {
    width: 'auto',
    height: 'auto',
    color: 'primary',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  main: {
    margin: theme.spacing(2),
  },
  delete: {
    margin: theme.spacing(1),
  },

  cancel: {
    margin: theme.spacing(1),
  },
  save: {
    margin: theme.spacing(1),
  },
}));

interface CommunityCreationProps {
  contextUser: ValidBusinessObject | undefined;
  contextCustomer: ValidBusinessObject | undefined;
  userFunctions: UserFunctions;
}

const livelyhoodOptions: string[] = [`Tourism`, `Fishing`, `Crafts`, `Others`];

export const CommunityCreation = (props: CommunityCreationProps) => {
  const { contextUser, contextCustomer, userFunctions } = props;
  const routerHistory = useHistory();

  const editTank = editTankStyles();

  let [creating, setCreating] = useState<boolean>(false);
  let [valid, setValid] = useState<boolean>(false);

  let [cName, setCName] = useState<string>('');
  let [population, setPopulation] = useState<number>();
  let [householdNum, setHouseholdNum] = useState<number>(1);
  let [averageOccu, setAverageOccu] = useState<number>(1);
  let [area, setArea] = useState<number>();
  let [livelihood, setLivelihood] = useState<string>('');

  useEffect(() => {
    setValid(cName !== '' && population !== undefined && area !== undefined && livelihood !== '');
  }, [cName, population, area, livelihood]);

  const addToDB = () => {
    setCreating(true);
    // create community object
    const communityZombie = createCommunityZombie({ name: cName, livelihood, area, averageOccupant: averageOccu, numOfHouseholds: householdNum, population });

    // // create device link...
    if (contextCustomer) {
      addObjectToDB(communityZombie, contextCustomer, 'customer_has_subCustomer_typeY').then((communityObj) => {
        if (communityObj) {
          displayMessage('Created Community: ' + cName, 'SnackBar', userFunctions);
          routerHistory.goBack();
        } else {
          displayMessage('Failed to create tank', 'SnackBar', userFunctions, Severity.error);
          setCreating(false);
        }
      });
    } else {
      displayMessage('Failed to begin creating tank', 'SnackBar', userFunctions, Severity.error);
      setCreating(true);
    }
  };

  return (
    <>
      <h3 style={{ textIndent: 25 }}>Create/Edit Community</h3>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3 style={{ textIndent: 25 }}>Details</h3>
            <form className={editTank.root} noValidate autoComplete='off'>
              <Grid item container xs={12}>
                <Grid item container xs={6}>
                  <TextField
                    label='Community Name'
                    onChange={(e) => setCName(e.target.value)}
                    className={editTank.textField}
                    value={cName}
                    variant='outlined'
                    size='small'
                    disabled={creating}
                  />
                </Grid>
                <Grid item container xs={6}>
                  <TextField
                    label='Population'
                    onChange={(e) => setPopulation(Number(e.target.value) !== undefined ? Math.round(Number(e.target.value)) : 0)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    // value={population}
                    disabled={creating}
                    type='number'
                    InputLabelProps={{shrink : true}}
                    placeholder='0'
                  />
                </Grid>
                <Grid item container xs={6}>
                  <TextField
                    label='# Of Households'
                    type='number'
                    onChange={(e) => setHouseholdNum(Number(e.target.value) !== undefined ? Math.round(Number(e.target.value)) : 0)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    // value={householdNum}
                    disabled={creating}
                    InputLabelProps={{shrink : true}}
                    placeholder='0'
                  />
                </Grid>
                <Grid item container xs={6}>
                  <TextField
                    label='Household Average Occupants'
                    type='number'
                    onChange={(e) => setAverageOccu(Number(e.target.value) !== undefined ? Number(e.target.value) : 0)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    // value={averageOccu}
                    disabled={creating}
                    InputLabelProps={{shrink : true}}
                    placeholder='0'
                  />
                </Grid>
                <Grid item container xs={6}>
                  <TextField
                    label='Land Area (m&sup2;)'
                    type='number'
                    onChange={(e) => setArea(Number(e.target.value) !== undefined ? Number(e.target.value) : 0)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    value={area}
                    disabled={creating}
                    InputLabelProps={{shrink : true}}
                    placeholder='0'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label='Livelihood (Select)'
                    value={livelihood}
                    variant='outlined'
                    disabled={creating}
                    onChange={(e) => {
                      setLivelihood(e.target.value);
                    }}
                    style={{ width: '98%' }}
                    SelectProps={{
                      renderValue: () => <>{livelihood}</>,
                    }}
                    size='small'

                  >
                    {livelyhoodOptions?.map((job, i) => (
                      <MenuItem key={i} value={job}>
                        {job}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {/* <Grid item xs={6}>
            <h3 style={{ textIndent: 25 }}>Tanks In Community</h3>
          </Grid> */}
          <Grid item xs={12}>
            <div style={{ textIndent: 10 }}>
              <Button variant='contained' color='error' disabled={creating} onClick={() => routerHistory.goBack()}>
                Cancel
              </Button>
              <Button variant='outlined' color='success' disabled={!valid || creating} onClick={() => addToDB()}>
                Create
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CommunityCreation;
