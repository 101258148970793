import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core"

import { useState } from "react";
import { useHistory } from "react-router";

export type ActionConfirmationProps = {
    open: boolean,
    setOpen: ( open: boolean ) => void,
    header: string,
    message: string,
    onConfirmation: () => void;
    redirectUrl?: string
}

export const ActionConfirmation = ( { open, setOpen, header, message,  onConfirmation, redirectUrl }: ActionConfirmationProps ) => {

    const routerHistory = useHistory()
    const handleClickOpen = () => {

    };
    const handleCancel = () => {
       setOpen(false)
    };
    const handleConfirm = () => {
        onConfirmation(); setOpen(false);  redirectUrl && routerHistory.push(redirectUrl) ;

    };
    return  <Dialog
    open={open}
    onClose={handleConfirm}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      { header }
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        { message }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel}>Cancel</Button>
      <Button onClick={handleConfirm} autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
} 