import { ValidBusinessObject } from '@iotv/datamodel';
import { TypeQueryBody } from '@iotv/iotv-v3-types';
import config from '../../config';
import { NormalizedData } from '../../types/AppTypes';
import ApiGateway from '../aws/api-gateway/ApiGateway';
import { addToNormalData } from '../daoFunctions/stateFns';

const debug = process.env.REACT_APP_DEBUG && false;
/**
 * @function allowedTypesQuery gets ALL of the types listed irrespective of their relationship to the user
 * @param user 
 * @param normalizedData 
 * @returns 
 */
const allowedTypesQuery = async function ( user: ValidBusinessObject, normalizedData: NormalizedData)  {

    const types = process.env.REACT_APP_LESS ? [] :[ 'AggregationGroup', 'Group', 'Geofence', 'Gateway' ] ;
    const apiRef = config.app.appName;

    debug && console.log('in allowed types with', process.env.REACT_APP_LESS)

   const promises = process.env.REACT_APP_LESS ? [] : types.map( (type) => {
       const params: TypeQueryBody = {
        type: "SEARCH_BY_TYPE",
        contextObject: undefined,
        includeContextLinkedItems: false,
        items: [
         {
          pk: '', sk:'', id: '',
          type
         }
        ],
        limit: 40,
        scanForward: true
       }
    return ApiGateway.post('/query/', params, apiRef ) 
   })

   const results = await Promise.all(promises);

    results.forEach( (res, i) => {
    debug && console.log('AllowedTypes res', res)
    res.data?.Items?.forEach( (vbo: ValidBusinessObject) => {
        addToNormalData(vbo, normalizedData)
    })
   res.err && console.log('AllowedTypes query err', res.err)
   debug && console.log('AllowedTypes res', res)
   })

  
   
 return normalizedData;

}

export { allowedTypesQuery };

