import { ValidBusinessObjectList } from '@iotv/datamodel-core';

export type DMPStage = {
  name?: string;
  stageNumber: number; // probably will not be needed
  goals: Goal[];
  actions: Action[];
  stageMessages: StageMessage[];
  internalManagementMessages: InternalManagement[];
  stageTriggers: DMPTrigger[];
  indication: Indication[];
};

export type Goal = {
  type: string;
  goal: string;
  tankType?: string;
  metric?: string;
  timeframe?: number;
};

export type Action = {
  type: string;
  action: string;
  person: string;
};

export type StageMessage = {
  recipientIDs: string[];
  messages: string[];
};

export type InternalManagement = {
  recipientIDs: string[];
  message: string;
};

export type DMPTrigger = {
  type: string;
  triggerDescription: string;
  metric?: number;
  timeframe?: number;
  triggerState?: boolean;
  unit?: string;
};

export type Indication = {
  message: string;
};

export const emptyStageTemplate = (val: number): DMPStage => {
  return {
    stageNumber: val,
    goals: [],
    actions: [],
    stageMessages: [],
    internalManagementMessages: [],
    stageTriggers: [],
    indication: [],
  };
};

export const triggerOptions: string[] = ['General', 'Condition', 'Status'];

export const conditionOptions = ['Actual average daily rainfall', 'Forecasted average daily rainfall'];

export const statusOptions: string[] = ['Average Fill of Residential Tanks', 'Average Fill of Community Tanks', 'Total Volume Stored']; //, 'Average Residential run out date is'];

export const preBuiltStageMessage: string[] = [
  'Use water sources other than reticulated water and community tank water when possible ',
  'Limit outdoor water use (including sports, ceremonial activities, and for tourism purposes)',
  'Outdoor water use is now banned (including sports, ceremonial activities, and for tourism)',
];

export const responseActionTypes = ['Awareness', 'Management', 'Regulatory'];

export const goalType = ['General', 'Target'];

export const goalTypes = [
  'Reduce water usage/peak demands',
  'Reduce overall daily water consumption',
  'Reduce overall weekly water consumption',
  'Reduce daily water consumption per person',
  'Reduce weekly water consumption per person',
];

export const tankTypes = ['Household', 'Community', 'Household & Community'];
