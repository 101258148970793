

import { combineReducers } from 'redux';
import AccessReducer from './AccessReducer';
import AppReducer from './AppReducer';
//import RootReducer from './RootReducer';


export const rootReducer = combineReducers({ AccessReducer, AppReducer });

export type RootState = ReturnType<typeof rootReducer>