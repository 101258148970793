import { Assembler, getAcceptableDynamoDBKey, IIncident, Incident, IncidentStatus, IncidentType, mode, SubscriptionState, ValidModelObject } from "@iotv/datamodel"
import { AdjacentType, AppValueTypes, ValidBusinessObject } from "@iotv/iotv-v3-types"
import { FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TableCell } from "@material-ui/core"
import { QueryInput } from "aws-sdk/clients/dynamodb"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import config from '../../../config'
import ApiGateway from "../../../data/aws/api-gateway/ApiGateway"
import { useDocumentClientQuery } from "../../../hooks/useDocumentClientQueryV1"
import { ComparisonType, DeviceManagementViewProps, ExpressionFunctionType, GetQueryBodyRequest2, GetQueryFilterConfig, ListerlizerProps, ListerlizerRowControlFnProps, LogicalOperatorType, UserTransactionType, ViewDefinition } from "../../../types/AppTypes"
import { DocumentClientListerlizerWrapperProps } from "../../../types/LabTypes"
import { getQueryBody } from "../../../util/AppData/queryInputizer"
import { getViewDefinitionFromUserAttributes, pluckAndOrderFromViewDef } from "../../factories/AttributeDefinitions"
import { DocumentClientListerlizerWrapper } from "../../factories/DocumentClientListerlizerWrapper"
import { NavSections } from "../../navigation/NavSections"
import queryString from 'query-string'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useStyles } from "../../../cosmetics/InputStyles"


const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = config.app.appName
const dyn = config.aws.dynamoDB

enum QueryFilterSubscription {
    SUBSCRIPPTION = 'SUBSCRIPTION', ALL = 'ALL'
}



export const IncidentsTab = ({ contextCustomer, contextUser, transactionFunctions, userFunctions, history, ...others }: DeviceManagementViewProps) => {

    const routerHistory = useHistory();
    const classes = useStyles()



    const getRequest = (contextUser: ValidModelObject<'User'> ): GetQueryBodyRequest2 => {
        const request: GetQueryBodyRequest2 = {
            ExclusiveStartKey: undefined,
            contextObject: undefined,
            objectTypeId: 'Incident',
            adjacencyType: AdjacentType.SUBJECT,
            filterGroups: [
                {
                    filters: [
                        //  { key: `_status`, value: 'OPEN', predicate: ComparisonType.EQUALS },
                        //  { key: `name`, value: 'DEVICE_AWOL', predicate: ExpressionFunctionType.BEGINS_WITH }
                    ], setOperation: LogicalOperatorType.AND
                }
            ]


        }

        if ( queryFilterSubscription === QueryFilterSubscription.SUBSCRIPPTION && request?.filterGroups ) {
            const subscribedFilter = { key: `notificationRecords.${getAcceptableDynamoDBKey(contextUser?.sk)}`, value: undefined, predicate: ExpressionFunctionType.EXISTS };
            (request.filterGroups[0] as GetQueryFilterConfig).filters.push( subscribedFilter )
        }
        if ( queryFilterState && request?.filterGroups ) {
            const stateFilter = { key: `_status`, value: queryFilterState , predicate: ComparisonType.EQUALS };
            (request.filterGroups[0] as GetQueryFilterConfig).filters.push( stateFilter )
        }
        if ( queryFilterIncidentType && request?.filterGroups ) {
            const stateFilter = { key: `_incidentType`, value: queryFilterIncidentType , predicate: ComparisonType.EQUALS };
            (request.filterGroups[0] as GetQueryFilterConfig).filters.push( stateFilter )
        }


        return request
    };

    const [queryFilterSubscription, setQueryFilterSubscription] = React.useState<QueryFilterSubscription>(QueryFilterSubscription.SUBSCRIPPTION)
    const [queryFilterState, setQueryFilterState] = React.useState<IncidentStatus>(IncidentStatus.OPEN)
    const [queryFilterIncidentType, setQueryFilterIncidentType] = React.useState<IncidentType | undefined>( undefined )
    const queryParams = contextUser && getQueryBody(getRequest(contextUser));

    const orderIncident = ( A: IIncident, B: IIncident ) =>  (A?.timestamp ?? '0' ) >  (B?.timestamp ?? '0') ? 1 : -1

    const query = async (fn: (params: any) => void, contextRequest: QueryInput) => {
        debug && console.log('IncidentTab query request', contextRequest)
        if (queryParams) {
            try {
                const response = await ApiGateway.post(`/baseQuery/`, contextRequest, apiRef);
                if (response.data?.Items) {
                    response.data.Items = response.data.Items.sort( orderIncident ).map((zombie: ValidBusinessObject) => Assembler.getInstance(zombie))
                }
                debug && console.log('IncidentsTab query results', response)
                fn(response);
            } catch (e) {
                debug && console.log('Error in UM query', e)
            }
        }

    }



    const queryHook = useDocumentClientQuery(query, queryParams, [contextUser, queryFilterSubscription, queryFilterState, queryFilterIncidentType])
    const [
        [items, setItems],
        [limit, setLimit],
        [LastEvaluatedKeys, setLastEvaluatedKeys],
        [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

    const forceUpdate = queryHook.forceUpdate
   // const updates = queryHook.updates

    debug && console.log('Incident tabs has items', items.length)

    const resetLastEvaluatedKeys = () => {
        setLastEvaluatedKeys([]);
        setExclusiveStartKey(undefined);
    }

    const handleQueryModeSubscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        resetLastEvaluatedKeys();
        setQueryFilterSubscription(e.target.value as QueryFilterSubscription)
    }

    const handleQueryModeStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        resetLastEvaluatedKeys();
        setQueryFilterState(e.target.value as IncidentStatus)
    }

    const handleQueryModeIncidentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        resetLastEvaluatedKeys();
        setQueryFilterIncidentType(e.target.value as IncidentType | undefined)
    }


    // useEffect(() => {
    //     debug && console.log('Configuration Tab used Effect on updates', updates)
    // }, [updates])

    const incidentInstance = new Incident({})
    const autoVeiwDefinition = getViewDefinitionFromUserAttributes(incidentInstance.getUserAttributes())

    // const displayFn =( actualValue: string | undefined ) => actualValue ? toDatePickerFormat( parseInt(actualValue))  : undefined
    const displayFn = (actualValue: string | undefined) => actualValue ? parseInt(actualValue) : undefined
    const valueFn = (newValue: number | undefined) => newValue ? new Date(newValue).valueOf().toString() : undefined

    const viewDefinition: ViewDefinition = {
        ...pluckAndOrderFromViewDef(['name', '_incidentType', '_status', '_severity'], autoVeiwDefinition),
        timestamp: { key: 'timestampOpen', label: 'Opened', type: AppValueTypes.DATE, editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined },
        // timestamp1: { key: 'timestamp', valueGetter: displayFn, label: 'DB timestamp', type: AppValueTypes.NUMBER, editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined }

    };

    const listProps: ListerlizerProps = {
        transactionFunctions, userFunctions, history,
        adjacentFilter: {
            adjacentType: AdjacentType.CHILD,
            objectType: 'Device',
            edgeFilter: undefined
        },
        maxItems: 20,
        sortKeyIn: 'name',
        selectorConfigParams: {
            enabled: false,
            multipleSelect: false,
            controls: {
                add: false,
                find: false,
            }
        },
        viewDefinition,
        viewObject: { matchedPrimary: undefined, matchedRelatedByPk: [], matchedRelatedBySk: [] },
        contextObject: undefined,
    }

    const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<QueryInput> = queryParams ? {
        ...listProps, queryHook, queryParams,
        rowControlOverrideFns: {
            first: [
                (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
                    return [
                        <TableCell {...{
                            key: `${listRowProps.rowObject.sk}_goto`, onClick: () => {
                                const viewUrl = queryString.stringifyUrl({
                                    url: `/${NavSections.NOTIFICATION_MANANGEMENT_VIEW}`,
                                    query: {
                                        contextObjectSK: listRowProps.rowObject.sk, tabName: 'IncidentView'

                                    }
                                });
                                routerHistory.push(viewUrl)
                            }
                        }}>
                            <PlayArrowIcon {...{
                            }} />
                        </TableCell>

                    ]
                }
            ]
        }

    } : undefined;

    const getRadio = () => <Radio {...{
        size: 'small', classes: {
            root: classes.radio
        },
    }}></Radio>


    return <Grid key={'IncidentTabbMainGrid'} container {...{}}>
        <Grid item {...{ xs: 12, key: 'controls' }}>
            <RadioGroup row { ...{ key: 'a'} } >
            <RadioGroup row aria-label="mode" { ...{ key: 'a1', name: 'mode', value: queryFilterSubscription, onChange: handleQueryModeSubscriptionChange, className: classes.smaller } }>
                <FormControlLabel { ...{ key: 'a1_1', value: QueryFilterSubscription.ALL, classes: { label: classes.radio }, control: getRadio(), label: 'All' } } />
                <FormControlLabel { ...{ key: 'a1_2', value: QueryFilterSubscription.SUBSCRIPPTION, classes: { label: classes.radio }, control: getRadio(), label: 'Subscribed' } } />
            </RadioGroup>
            <RadioGroup row aria-label="mode" { ...{ key: 'a2', name: 'mode', value: queryFilterState, onChange: handleQueryModeStateChange, className: classes.smaller } }>
                <FormControlLabel { ...{ key: 'a2_1', value: IncidentStatus.OPEN, classes: { label: classes.radio }, control: getRadio(), label: 'OPEN' } } />
                <FormControlLabel { ...{ key: 'a2_2', value: IncidentStatus.CLOSED, classes: { label: classes.radio }, control: getRadio(), label: 'CLOSED' } } />
            </RadioGroup>
            <RadioGroup row aria-label="mode" { ...{ key: 'a3', name: 'mode', value: queryFilterIncidentType, onChange: handleQueryModeIncidentTypeChange, className: classes.smaller } }>
                { [[undefined, 'All'], ...Object.entries( IncidentType )].map( ( [k, v]) =>   <FormControlLabel { ...{ key: `${k}`, value: k, classes: { label: classes.radio }, control: getRadio(), label: v } } />)}
              
                
            </RadioGroup>
            </RadioGroup>
      
        </Grid>
        {contextCustomer && contextUser && <>
            {documentClientListerlizerProps &&
                <Grid item {...{ xs: 12, key: 'incidents' }}>

                    <DocumentClientListerlizerWrapper key={'deviceList2'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>

                </Grid>
            }

        </>
        }
        <Grid item {...{ xs: 12, style: { marginTop: '1rem' } }} >
            <Grid>
                <FormGroup>
                    <FormControl>

                    </FormControl>
                </FormGroup>
            </Grid>

        </Grid>

    </Grid>
}