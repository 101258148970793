import { Grid, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from '../cosmetics/sharedViewStyles';
import { IUserMessageType, MessageViewProps, UserErrorMessageType } from '../types/AppTypes';
import ErrorMessage from './error/ErrorMessage';
import UserMessage from './error/UserMessage';

const debug = process.env.REACT_APP_DEBUG && false;

function MessageView( props: MessageViewProps  ): JSX.Element {

    const [ spacing ] = React.useState(2);
    const { classes,  userFunctions, errors, messages, viewObject} = props;
    
    useEffect(() => {

    });

    debug && console.log('MessageView git ', props)

    const showErrors = () => {
      const sk =viewObject?.matchedPrimary?.sk; // SK is always the User in MEssagae container so can't get viewObject from THingsContainer
      debug && console.log('Look For Errors ', {sk, errors})
      let element = null;
      if (errors ) {
        const objectErrors: UserErrorMessageType[] = [];
         Object.values(errors).forEach( (objectErrorMap) => Object.values(objectErrorMap).forEach( (err) => objectErrors.push(err) ) );
        element = objectErrors.map( (message) => getErrorMessageItem(message))
      } else {
        debug && console.log('No errors to show ', messages)
      }
      return element
    }

    const showMessages = () => {
      let element = null
      if (messages) {
          element = Object.values(messages).filter( (messageField) => messageField.type  ).map( (message) =>  getMessageItem(message))
      }
      debug && console.log('show messages tried to show ', { messages, element })
      return element;
    }

    const messagesOrErrors = ( messages  && Object.keys(messages).length > 0 ) ||  ( errors  && Object.keys(errors).length > 0 )
    
    const getMessageItem = (message: IUserMessageType) => <UserMessage key = {message.id}  message = {message as IUserMessageType} onClose = {() => userFunctions.dismissUserMessage(message as IUserMessageType) }> </UserMessage> 

    const getErrorMessageItem = (error: UserErrorMessageType) => <ErrorMessage key = {error.id} message = {error as UserErrorMessageType} onClose = {() => userFunctions.dismissUserMessage(error as UserErrorMessageType) }> </ErrorMessage> 


  return  (
      <Grid container className={classes.root} { ... { style: {  position: 'sticky', top: 75, zIndex: messagesOrErrors ? 200: -1}}}>
        { debug && false && <Grid item>{JSON.stringify(errors, null, 1)}</Grid> }
        <Grid key = {'1'} item xs={12}>
          <Grid  container  spacing={spacing as any}>
              <Grid key={'set key'} item className={classes.item}>
                {
                  errors && showErrors()
                }
                {
                  messages && showMessages()
                }
          </Grid>
        </Grid>
        </Grid>
    </Grid>
    );
    
}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(MessageView)