import { ErrData, ErrDataPromise, MapLike, PathDirection, UserAttributeDef, UserAttributeDefinitionType, UserAttributeDefs, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import React, { useEffect } from 'react';
import { treeCosmeticsByType } from '../../../cosmetics/tree/treeCosmeticsByType'
import { AdjacencyQuery, AdjacentType, ComparisonType, ExpressionFunctionType, GetQueryBodyRequest2, LogicalOperatorType, MessageOutputType, ObjectTreeItemProps, UserTransactionType, ValidTypeLinkage, ViewObject } from '../../../types/AppTypes'
import { StyledTreeItemConfig } from '../../../types/ComponentProps';

import { createAndLink, CreateAndLinkRequest, deleteObject, getAdjacent2Wrapper, GetAdjacentRequest, unlink } from '../../../data/daoFunctions/daoFunctions'
import config from '../../../config';
import { StyledTreeItem, } from './StyledTreeItem'
import Assembler from '../../../data/Assembler';
import { UserFunctions } from '../../../actions/AppActions';
import { v4 } from 'uuid';
import { AddItem } from './AddItem';
import { getViewObjectHash } from '../../../util/AppData/viewObject';
import { DocumentClient, QueryOutput } from 'aws-sdk/clients/dynamodb';
import { useHistory } from 'react-router-dom';
import { NavSections } from '../../navigation/NavSections';
import { getQueryBody } from '../../../util/AppData/queryInputizer';
import AppDao from '../../../data/AppDao';
const debug = process.env.REACT_APP_DEBUG && false;
const dyn = config.aws.dynamoDB

export const ObjectTreeItem = (props: ObjectTreeItemProps) => {
    const { contextObject, viewObject, mainAdjacencyQuery, branchAdjacencyQueries, level, mode, handleDeleteInParent, handleUnlinkInParent } = props;

    const [matchedRelatedBySk, setMatchedRelatedBySk] = React.useState<ValidBusinessObjectList>(viewObject.matchedRelatedBySk ?? [])
    const [matchedRelatedByPk, setMatchedRelatedByPk] = React.useState<ValidBusinessObjectList>(viewObject.matchedRelatedByPk ?? [])
    const matchedByHash = getViewObjectHash({ matchedPrimary: viewObject.matchedPrimary, matchedRelatedBySk, matchedRelatedByPk });
   // const [ doubleClickTimeout, setDoubleClickTimout ] = React.useState<NodeJS.Timeout | undefined >( undefined )
    //const newObjectTypeId = (  ( adjacencyQueries && adjacencyQueries.length >0 ) ? adjacencyQueries[0].objectTypeId :  viewObject.matchedPrimary?.type ?? 'Unknown'  )
    const newObjectNodeId = `${viewObject.matchedPrimary?.sk}_new`;

    const routerHistory = useHistory();

    const validTypes: ValidTypeLinkage[] = [ ...branchAdjacencyQueries ?? [], mainAdjacencyQuery]

    useEffect(() => {
        debug && console.log('ObjectTreeItem used effect of changed matchedBys')
    }, [matchedByHash])

    const displayMessage = (content: string, type: MessageOutputType) => {
        UserFunctions.setUserMessage({ id: v4(), content, label: content, type })
    }

    const getAdjacents = async (vob: ValidBusinessObject) => {
        debug && console.log('getAdjacents in ObjectTreeItem')
        const vobob = Assembler.getInstance(vob);
        if (vobob) {
            let queries: AdjacencyQuery[] = []
            if (branchAdjacencyQueries) {
                queries = branchAdjacencyQueries
            } else {
                const edgeUserAttributes: UserAttributeDefinitionType[] = ['Array', 'RelatedType']

                if (true) {
                    queries = Object.entries(vobob.getUserAttributes() as UserAttributeDefs)
                        .filter(([k, attDef]) => attDef && edgeUserAttributes.includes(attDef.type))
                        .filter(([k, attDef]) => mainAdjacencyQuery.adjacencyType === undefined || (mainAdjacencyQuery.adjacencyType === AdjacentType.CHILD && attDef?.direction === PathDirection.child) || (mainAdjacencyQuery.adjacencyType === AdjacentType.PARENT && attDef?.direction === PathDirection.parent))
                        .map(([k, attDef]) => {
                            const objectTypeId = attDef?.objectTypeId ?? k;
                            const adjacencyType = attDef?.direction === PathDirection.parent ? AdjacentType.PARENT : AdjacentType.CHILD;
                            return { objectTypeId, adjacencyType, filter: undefined }
                        });
                }
            }

            const skadjacentPromises: Promise<ErrData<DocumentClient.QueryOutput>>[]  = [];
            const pkadjacentPromises: Promise<ErrData<DocumentClient.QueryOutput>>[]  = [];

            queries.forEach(async ({ objectTypeId, adjacencyType, edgeTypeId }) => {
                if (objectTypeId && (!matchedRelatedBySk || !matchedRelatedBySk.some((item) => item.type === objectTypeId))) {
                    debug && console.log('Getting adjacenct', { contextObject, objectTypeId})

                    const request: GetQueryBodyRequest2 = {
                        adjacencyType:  mainAdjacencyQuery.adjacencyType ?? AdjacentType.CHILD,
                        ExclusiveStartKey: undefined,
                        contextObject: vob,
                        filterGroups: [
                          { filters: [
                           
                            { key: dyn.sKey, value: objectTypeId, predicate: ExpressionFunctionType.BEGINS_WITH },

                          ],
                          setOperation: LogicalOperatorType.AND
                        }
                        ],
                        limit: 50
                     
                    }
                    
                    const params = getQueryBody(request)
                    debug && console.log('Query params', params)

                    if (adjacencyType === AdjacentType.CHILD) skadjacentPromises.push(AppDao.getMainTableQueryResultsWithReturn( params ))  
                    else if (adjacencyType === AdjacentType.PARENT)  pkadjacentPromises.push(AppDao.getMainTableQueryResultsWithReturn( params )) 
                }
            })
            debug && console.log('Awaiting', { skadjacentPromises, pkadjacentPromises })
            const skadjacentResults = await Promise.all(skadjacentPromises);
            const pkadjacentResults = await Promise.all(pkadjacentPromises);
            const skitems: ValidBusinessObjectList = []
            const pkitems: ValidBusinessObjectList = []

            skadjacentResults.forEach( (res) => {
                if ( res.data?.Items ) {
                    res.data.Items.filter( (item ) => item.sk !== viewObject.matchedPrimary?.sk ).forEach( (item) =>  skitems.push(item as ValidBusinessObject))
                }
            })

            pkadjacentResults.forEach( (res) => {
                if ( res.data?.Items ) {
                    res.data.Items.filter( (item ) => item.sk !== viewObject.matchedPrimary?.sk ).forEach( (item) => pkitems.push(item as ValidBusinessObject))
                }
            })

            setMatchedRelatedBySk( skitems )
            setMatchedRelatedByPk( pkitems )
        }


    }

    let element: JSX.Element | null = null;


    let doubleClickTimeout: NodeJS.Timeout | undefined = undefined;
    const handleIconClick = (e: React.MouseEvent) => {
        // e.preventDefault()
        debug && console.log('Event is ', e)

        if ( doubleClickTimeout ) {
            e.preventDefault(); e.stopPropagation();
            debug && console.log('Clearing doubleClickTimeout', doubleClickTimeout)
            clearTimeout(doubleClickTimeout);
           
            if ( viewObject.matchedPrimary ) {

                const urlMap: MapLike<string> = {
                    Device: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Device/${viewObject.matchedPrimary.sk}?contextObjectSK=${contextObject.sk}`,
                    default: `/${NavSections.THINGVIEW}/${viewObject.matchedPrimary.type}/${viewObject.matchedPrimary.id}`
                }
                routerHistory.push( urlMap[viewObject.matchedPrimary.type ] ?? urlMap.default )
            }
          
        } else {
            debug && console.log('Setting doubleClickTimeout')
            doubleClickTimeout = setTimeout( () => {
                debug && console.log('in doubleclickTimeOut')
                if (viewObject.matchedPrimary && (!viewObject.matchedRelatedBySk || viewObject.matchedRelatedBySk.length === 0)) {
                    getAdjacents(viewObject.matchedPrimary)
                }
            }, 500 )
    
        }
    }

    const handleDeleteFromHere = async (e: React.MouseEvent, deleteItem: ValidBusinessObject, adjacentType: AdjacentType, edgeTypeId?: string) => {
        e.preventDefault(); console.log('End icon click ', deleteItem)

            const deleteRes = await deleteObject(deleteItem);
            if (deleteRes.data) {
                debug && console.log('ObjectTreeItem deleted res', deleteRes.data)
                if (adjacentType === AdjacentType.CHILD) {
                    const mutatedRelateds = [...matchedRelatedBySk]
                    const idx = mutatedRelateds.findIndex((item) => item.sk === deleteItem.sk)
                    if (idx !== -1) {
                        debug && console.log('ObjectTreeItem existing to be deleted idx', idx)
                        mutatedRelateds.splice(idx, 1);
                        setMatchedRelatedBySk(mutatedRelateds)
                    } else {
                        debug && console.log('ObjectTreeItem nothing to delete', { mutatedRelateds, idx, matchedPrimary: deleteItem })
                    }
                } else if (adjacentType === AdjacentType.PARENT) {
                    const mutatedRelateds = [...matchedRelatedByPk]
                    const idx = mutatedRelateds.findIndex((item) => item.sk === deleteItem.sk)
                    if (idx !== -1) {
                        mutatedRelateds.splice(idx, 1);
                        setMatchedRelatedByPk(mutatedRelateds)
                    }
                    displayMessage(`deleted ${deleteItem.name}`, 'SnackBar')

                } else if (deleteRes.err) {
                    displayMessage(`failed to delete ${deleteItem.name} because ${deleteRes.err.message}`, 'MessageBar')
                }
            
        }
    }

    const handleUnlinkFromHere = async (e: React.MouseEvent, unlinkObject: ValidBusinessObject, adjacentType: AdjacentType, edgeTypeId?: string) => {
        console.log('Child item needs to be deleted from here', unlinkObject)
        if ( viewObject.matchedPrimary ) {
            const unlinkRes = await unlink( viewObject.matchedPrimary, unlinkObject )
            if ( unlinkRes.data ) {
                console.log('UNLINK RES', unlinkRes.data )
                const removedItem = unlinkRes.data.removedItem; 
                if (adjacentType === AdjacentType.CHILD) {
                    const mutatedRelateds = [...matchedRelatedBySk]
                    const idx = mutatedRelateds.findIndex((item) => item.sk === removedItem.sk)
                    if (idx !== -1) {
                        debug && console.log('ObjectTreeItem existing to be deleted idx', idx)
                        mutatedRelateds.splice(idx, 1);
                        setMatchedRelatedBySk(mutatedRelateds)
                        displayMessage(`unlinked ${unlinkObject.name}`, 'SnackBar')
                    } else {
                        debug && console.log('ObjectTreeItem nothing to delete', { mutatedRelateds, idx, matchedPrimary: removedItem })
                    }
                } else if (adjacentType === AdjacentType.PARENT) {
                    const mutatedRelateds = [...matchedRelatedByPk]
                    const idx = mutatedRelateds.findIndex((item) => item.sk === removedItem.sk)
                    if (idx !== -1) {
                        debug && console.log('ObjectTreeItem existing to be deleted idx', idx)
                        mutatedRelateds.splice(idx, 1);
                        setMatchedRelatedByPk(mutatedRelateds)
                        displayMessage(`unlinked ${unlinkObject.name}`, 'SnackBar')
                    } else {
                        debug && console.log('ObjectTreeItem nothing to delete', { mutatedRelateds, idx, matchedPrimary: removedItem })
                    }
                }

            } else if (unlinkRes.err) {
                displayMessage(`failed to unlink ${unlinkObject.name} because ${unlinkRes.err.message}`, 'MessageBar')
            }
        }
       
    }

    const handleSave = async (newObject: ValidBusinessObject | undefined, adjacentType: AdjacentType, edgeTypeId?: string) => {
        debug && console.log(' ObjectTreeItem handleSave with ', newObject)
        if (newObject && viewObject.matchedPrimary) {
            if ( newObject.type === mainAdjacencyQuery.objectTypeId && mainAdjacencyQuery.filter ) {
                const queryRequiredAttribute = mainAdjacencyQuery.filter.filters[0]
                if ( queryRequiredAttribute ) {
                  newObject[queryRequiredAttribute.key] = queryRequiredAttribute.value;
                }
              }

            const params: CreateAndLinkRequest = {
                existingObject: viewObject.matchedPrimary, newObject,
                edgeTypeId,
                direction: adjacentType === AdjacentType.CHILD ? PathDirection.child : PathDirection.parent
            }
            const linkRes = await createAndLink(params)

            debug && console.log('ObjectTreeItem handleSave linkres ', linkRes.data)
            if (linkRes.data && linkRes.data instanceof Array) {
                debug && console.log(' linnk res ', linkRes.data)
                const newLinkedObject = linkRes.data[1]
                if (newLinkedObject && adjacentType === AdjacentType.CHILD) {
                    const mutatedRelateds = [...matchedRelatedBySk]
                    if (!mutatedRelateds.find((item) => item.sk === newLinkedObject.sk)) {
                        mutatedRelateds.push(newLinkedObject);
                        setMatchedRelatedBySk(mutatedRelateds)
                    }
                } else if (newLinkedObject && adjacentType === AdjacentType.PARENT) {
                    const mutatedRelateds = [...matchedRelatedByPk]
                    if (!mutatedRelateds.find((item) => item.sk === newLinkedObject.sk)) {
                        mutatedRelateds.push(newLinkedObject);
                        setMatchedRelatedByPk(mutatedRelateds)
                    }
                } else {
                    debug && console.log(' handle save adjacent type wasnt child', adjacentType)
                }

            }
        } else {
            debug && console.log('No new obehct')
        }

    }

    if (viewObject.matchedPrimary) {
        const treeItemProps: StyledTreeItemConfig = treeCosmeticsByType(viewObject.matchedPrimary);
        const keyBase = `${level}_${treeItemProps.nodeId ?? viewObject.matchedPrimary.sk}`
        const handleEndIconClick =  mode === UserTransactionType.DELETE ? handleDeleteInParent : mode === UserTransactionType.UNLINK ? handleUnlinkInParent : (e: any) => console.log('Nothing to do in mode', mode);
        element = <StyledTreeItem {...{ ...treeItemProps, handleIconClick, handleEndIconClick, mode }}>
            {matchedRelatedBySk && matchedRelatedBySk.map((item) => {
                const nextViewObject: ViewObject = {
                    matchedPrimary: item, matchedRelatedByPk: [], matchedRelatedBySk: []
                }
                const handleDeleteSk = (e: any) => handleDeleteFromHere( e, item, AdjacentType.CHILD, undefined )
                const handleUnlinkInParent = (e: any) => handleUnlinkFromHere( e, item, AdjacentType.CHILD, undefined )
                return <ObjectTreeItem {...{ contextObject, viewObject: nextViewObject, level: level + 1, key: `${keyBase}:${item.sk}`
                , handleIconClick, handleDeleteInParent: handleDeleteSk, handleUnlinkInParent, handleSave, mainAdjacencyQuery, branchAdjacencyQueries, mode }}  ></ObjectTreeItem>

            }
            )}
            {mode === UserTransactionType.CREATE_AND_LINK && <AddItem {...{ contextObject: viewObject.matchedPrimary, validTypes, nodeId: newObjectNodeId, handleSave, mode, level: 1 }}></AddItem>}

        </StyledTreeItem>
    }

    return element
}
