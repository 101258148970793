import { getAcceptableDynamoDBKey, getNiceDate, getZombieInstanceFromPrimaryKey, Incident, IncidentHistoryRecord, NotificationRecord } from "@iotv/datamodel"
import { AppValueTypes, ValidBusinessObject } from "@iotv/iotv-v3-types"
import { FormControl, FormGroup, Grid, Table, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { v4 } from 'uuid'
import config from '../../../config'
import { getExact, getOne } from "../../../data/daoFunctions/daoFunctions"
import { AdjacentType, ComponentMode, DeviceManagementViewProps, ListerlizerProps, MessageOutputType, TabilizerProps, UserTransactionType, ViewDefinition, ViewKeyDefinitionType } from "../../../types/AppTypes"
import { getViewDefinitionFromUserAttributes, pluckAndOrderFromViewDef } from "../../factories/AttributeDefinitions"
import { ReadOnlyList, ReadOnlyListProps } from "../../factories/ReadOnlyList"
import { Tabilizer } from "../../factories/Tabilizer"

const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = config.app.appName
const dyn = config.aws.dynamoDB

const getIncidentZombieFromSk = (sk: string ): ValidBusinessObject => {
  const keyparts = sk.split(':');
  const type = keyparts[0];
  const thingSk = keyparts.slice(1, 3).join(':');
  const id = sk;
  // using the thingSk may or may not have been part of an unfinished improvement to indexing, but previously Incidents have pk = sk
  // return { pk: sk, sk, type, id}
  return { pk: thingSk, sk, type, id}

} 

export const SingleIncidentTab = ({ contextCustomer, contextUser, transactionFunctions, userFunctions, history, searchParams, ...others }: DeviceManagementViewProps) => {

    const routerHistory = useHistory();
    const requestedObjectSk = searchParams?.contextObjectSK
    const displayMessage = (content: string, type: MessageOutputType) => {
      userFunctions.setUserMessage({ id: v4(), content, label: content, type })
    }


    const [ incident, setIncident ] = React.useState<Incident | undefined>( undefined );

    const getIncident = async () => { 
      if ( requestedObjectSk ) {
        const zombie = getIncidentZombieFromSk(requestedObjectSk);
        debug && console.log('getting one with', zombie)
        const getRes = await getExact(zombie);
        debug && console.log('get single incident res', getRes)
        if ( getRes.data?.Items?.[0]) {
          setIncident( new Incident( getRes.data.Items[0] ))
        } else {
          displayMessage('Could not get item', 'MessageBar')
        }
      } else {
        debug && console.log('No requestedObjectSk', requestedObjectSk )
        debug && console.log('SearchParams were', searchParams )
      }
    }
 

    useEffect( () => {
        debug && console.log('SingleIncidentTab Tab used Effect on requestedObjectSk, contextUser')
        getIncident()
    }, [ requestedObjectSk, contextUser  ])

    useEffect( () => {
      
    }, [ incident?.sk ])


    const viewDefinition: ViewDefinition | undefined = incident && {
      ...pluckAndOrderFromViewDef([ 'name', '_incidentType', '_status', '_severity', 'iniitiator', 'timestamp' ], getViewDefinitionFromUserAttributes(incident.getUserAttributes())),
      timestamp: { key: 'timestampOpen', label: 'Opened', type: AppValueTypes.DATE, editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined },
     // timestamp1: { key: 'timestamp', valueGetter: displayFn, label: 'DB timestamp', type: AppValueTypes.NUMBER, editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined }
      summary: { key: 'summary', label: 'Summary', type: AppValueTypes.STRING, editable: false, precision: undefined, stateFn: undefined, unit: undefined, validationRx: undefined },
  };

    const tableProps:  TabilizerProps | undefined = viewDefinition && incident && {
      transactionFunctions,
      matchedPrimary: incident,
      actions: [UserTransactionType.UPDATE],
      viewDefinition,
      
      functionOverrides: {
        mode: ComponentMode.VIEW, updateParentComponentItem: () => {}, hasValidationErrors: () => {}
       
      },
    }

  
    const historyListProps: ReadOnlyListProps | undefined = incident?.history && {
      matchedPrimary: incident,
      objects: incident.history,
      viewDefinition: {
        timestamp: { key: 'timestamp', type: AppValueTypes.DATE, editable: false, label: 'Time', valueGetter: ( actual ) => getNiceDate(actual)},
        event: { key: 'event', type: AppValueTypes.STRING, editable: false, label: 'Event', },
        initiator: { key: 'initiator', type: AppValueTypes.STRING, editable: false, label: 'Initiator', valueGetter: ( actual ) => actual.name},
        summary: { key: 'summary', type: AppValueTypes.STRING, editable: false, label: 'Summary', },

      } as ViewDefinition & { [ k in keyof IncidentHistoryRecord ]: ViewKeyDefinitionType}

    }

    const acceptableDynamoDBKey = contextUser && getAcceptableDynamoDBKey(contextUser.sk)

    const notificationRecordsListProps: ReadOnlyListProps | undefined = incident?.notificationRecords  && {
      matchedPrimary: incident,
      objects: Object.values(incident.notificationRecords).reduce( ( acc, cur  ) => { acc = acc.concat(cur); return acc }, []),
      viewDefinition: {
        recipient: { key: 'recipient', type: AppValueTypes.STRING, editable: false, label: 'Recipient', valueGetter: ( actual ) => actual.name },
        deliveryType: { key: 'deliveryType', type: AppValueTypes.STRING, editable: false, label: 'Delivery Type'},
        timestampMs:  { key: 'timestampMs', type: AppValueTypes.DATE, editable: false, label: 'Time', valueGetter: ( actual ) => getNiceDate(actual)},
        escalationFrom:  { key: 'escalationFrom', type: AppValueTypes.STRING, editable: false, label: 'Escalation From'},
        // timestamp: { key: 'timestamp', type: AppValueTypes.DATE, editable: false, label: 'Time', valueGetter: ( actual ) => getNiceDate(actual)},
        // event: { key: 'event', type: AppValueTypes.STRING, editable: false, label: 'Event', },
        // initiator: { key: 'initiator', type: AppValueTypes.STRING, editable: false, label: 'Initiator', valueGetter: ( actual ) => actual.name},
        // summary: { key: 'summary', type: AppValueTypes.STRING, editable: false, label: 'Summary', },

      } as ViewDefinition & { [ k in keyof NotificationRecord ]: ViewKeyDefinitionType}

    }




    return <Grid key = {'IncidentTabbMainGrid'} container {...{  }}>
        {contextCustomer && contextUser && tableProps && <>
          <Grid container>
             <Grid item { ...{ xs: 6 }}>
               <Typography { ...{ variant: 'h5' } }>Incident</Typography>
          <Tabilizer key={'userInputTable'} {...{ ...tableProps }}></Tabilizer>
          </Grid>
          <Grid item { ...{ xs: 6 }}>
          {  incident?.notificationRecords && notificationRecordsListProps && <>
            <Typography { ...{ variant: 'h5' } }>Notifications</Typography>
            <ReadOnlyList { ...{ key: 'notificationRecords', ...notificationRecordsListProps }}></ReadOnlyList> 
          </> }

          </Grid>
          </Grid>
         
          <Grid item {...{ xs: 12,  style: { marginTop: '1rem'}}} >
            <Grid>
                { incident?.history && historyListProps && <>
                  <Typography { ...{ variant: 'h5' } }>Events</Typography>
                  <ReadOnlyList { ...{ key: 'historyLost', ...historyListProps }}></ReadOnlyList>
                </>  }

            </Grid>

        </Grid>
        </>
        }
       

    </Grid>
}