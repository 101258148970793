
import { lscToC021AccountType, ValidModelObject as C021ValidModelObject, } from '@c021/datamodel';
import { CustomerAccountType, NodeEdgeMapper, ValidBusinessObject, ValidModelObject } from '@iotv/datamodel-core';
import { DatastoreObjectType, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Business, Edit } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TreeView } from '@mui/lab';
import { IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AttributeMap } from 'aws-sdk/clients/dynamodb';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ExtendedTreeItem } from '../../../../../components/generic/ObjectTree/Derivations/ExtendedTreeItem2';
import { NavSections } from '../../../../../components/navigation/NavSections';
import { useStyles } from '../../../cosmetics/communityStyles';
import { gridDefintion } from './gridDefintion';
import { getCustomerHierarchy } from './queries';
import { GetAdjacentResponse, ObjectCardProps } from '../../../../../types/AppTypes';
import { getAdjacent, getOne } from '../../../../../data/daoFunctions/daoFunctions';
import { DMPStage } from '../DroughtManagement/DMPExtrasV2';
import {
    getZombieInstanceFromPrimaryKey,
    ObjectHistory,
    PathDirection,
    QueryTypes,
} from '@iotv/datamodel';

const debug = process.env.REACT_APP_DEBUG && false;

export const CommunitiesView = (props: ObjectCardProps) => {
    const styles = useStyles()
    const history = useHistory()

    const [nodeEdgeMapper, setNodeEdgeMapper] = useState<NodeEdgeMapper | undefined>(undefined)

    const [nodesToDisplay, setNodesToDisplay] = useState<ValidBusinessObjectList>([])

    const [selectedParentSks, setSelectedParentSks] = useState<string[]>([]) // strings to match nodeIds of TreeView

    const [droughtManagementPlan, setDroughtManagementPlan] = useState<C021ValidModelObject<'DroughtManagementPlan'> | undefined>(undefined);

    const { transactionFunctions, userFunctions, searchParams, viewObject, contextCustomer, userGeolocation, viewObject: { matchedPrimary } } = props;


    /**
     * useEffect hook fetches all end communities associated with current customer (Community Water manager)
     * Then for each community, its tanks are fetched to fill in some of the fields in the table
     */

    useEffect(() => {
        // check if the customer should be redirected
        if (contextCustomer?.accountType === CustomerAccountType.Y) {
            history.push(`${NavSections.THINGVIEW}/Customer/${contextCustomer?.id}`);
            return;
        } else if (contextCustomer?.accountType === CustomerAccountType.Z) {
            history.push(`${NavSections.THINGSVIEW}/Tank`);
            return;
        }

        // FIX LATER gets provided an edge, so override the query to provide the contextCustomer as an object
        getCustomerHierarchy({ ...contextCustomer, pk: contextCustomer?.sk } as ValidModelObject<'Customer'>).then(({ err, data }) => {
            if (err == null && data != null) {
                const nodeEdgeMapper = new NodeEdgeMapper()
                const filterHierarchNodes: ValidBusinessObjectList = data.nodes.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
                const filterHierarchEdges: ValidBusinessObjectList = data.edges.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
                nodeEdgeMapper.loadItems(filterHierarchNodes)
                nodeEdgeMapper.loadItems(filterHierarchEdges)
                debug && console.log('CommunitiesView Hierarchy', { nodeEdgeMapper })
                var outout = nodeEdgeMapper.getChildNodes({ ...contextCustomer, pk: contextCustomer?.sk } as DatastoreObjectType) as ValidBusinessObjectList
                findsource(outout).then((res) => {
                    setNodesToDisplay(res)
                }
                )
                setNodeEdgeMapper(nodeEdgeMapper)
            }
        }
        )
    }, [contextCustomer, contextCustomer?.sk, history])

    async function findsource(customerIn: ValidBusinessObjectList) {
        console.log("findplan2", customerIn)
        if (customerIn) {
            var newlist: string[] = []
            for (var i = 0; i < customerIn.length; i++) {
                var newstring = ""
                var getstage = fetchDroughtManagementPlanStageLocal(customerIn[i])
                await getstage.then((res) => {
                    var stagename = getstagename(res, res?.activestage)
                    newlist.push(stagename)
                }
                )
                // if (droughtManagementPlan) {
                //     var stagename = getstagename(droughtManagementPlan, droughtManagementPlan?.activestage)
                //     newlist.push({ ...customerIn[i], currentstagename: stagename })
                // }
            }
            var outputlist: ValidBusinessObjectList = []

            for (var i = 0; i < customerIn.length; i++) {
                outputlist.push({ ...customerIn[i], currentstagename: newlist[i] })
            }
            console.log("findplan4", outputlist)
            return outputlist
        }
        else {
            return customerIn
        }
    }

    function getstagename(source: C021ValidModelObject<'DroughtManagementPlan'> | undefined, input: number) {
        if (input == -1) {
            input = 0
        }
        return source?.stage[input]?.name ? `${source?.stage[input]?.stageNumber}) ${source?.stage[input]?.name} ` : `Stage ${source?.stage[input]?.stageNumber}`
    }

    const fetchDroughtManagementPlanStageLocal = async (communityObject: ValidBusinessObject) => {
        let planContext: ValidBusinessObject | undefined = communityObject;

        while (planContext !== undefined) {
            let plan: GetAdjacentResponse = await getAdjacent(planContext, 'DroughtManagementPlan', PathDirection.child);
            if (plan.data && plan.data.length > 0) {
                var planCasted = plan.data[0];
                if ((planCasted.stage) !== undefined) {
                    return (planCasted as C021ValidModelObject<'DroughtManagementPlan'>)
                    //setDroughtManagementPlan(planCasted as C021ValidModelObject<'DroughtManagementPlan'>);

                }
            }
            let next: GetAdjacentResponse = await getAdjacent(planContext, 'Customer', PathDirection.parent);

            if (next.data && next.data.length === 1) {
                planContext = next.data[0];
            } else {
                planContext = undefined;
                break;
            }
        }
    };


    const nodeEdgesHash = Object.keys(nodeEdgeMapper?.skMapped ?? { noNodes: true }).join('')

    useEffect(() => {
        debug && console.log('CommunitiesView usedEffect on nodeEdgeHash', nodeEdgesHash)
    }, [nodeEdgesHash])

    useEffect(() => {
        if (nodeEdgeMapper) {
            debug && console.log('CommunitiesView usedEffect on skMapeed', Object.keys(nodeEdgeMapper.skMapped).length)
        }

    }, [nodeEdgeMapper?.skMapped])



    /**
     * Generates Treeview for showing the communities in tree-like structure
     * @returns A nested TreeItem JSX object for tree structure of communities
     */
    const generateTree = (apexNodes: ValidBusinessObjectList) => {
        //Getting a list of all the 'super communities' i.e. communities with no parents     
        //Generating a root node in treeview for each super community
        const treeElement = (apexNodes.map(apexNodeIn => {
            const apexNode = { ...apexNodeIn, pk: apexNodeIn.sk } as ValidBusinessObject
            const communityControls: JSX.Element = getCommunityControls(apexNode)
            return (
                <ExtendedTreeItem {...{ key: apexNode.sk, nodeId: apexNode.sk, label: apexNode.name, x: communityControls }}>
                    {generateSubTree(apexNode)}
                </ExtendedTreeItem>
            )
        }
        ))
        return treeElement
    }

    /**
     * Generates a   sub-tree where the root of the tree is the community provided
     * @param node  the community to create a sub tree from
     * @returns A nested TreeItem JSX object for tree structure of communities from root community
     */
    const generateSubTree = (node: ValidBusinessObject) => {
        if (nodeEdgeMapper) {
            //Getting list of children from given community
            const childNodes = nodeEdgeMapper.getChildNodes(node)
            //debug && console.log(`generateSubTree wih ${node.name} got children ${childNodes.map((c) => (c as ValidBusinessObject).name)}`)
            //If the community has grandchildren, generate another subtree. Otherwise generate simple node
            return (childNodes.map((nodeIn) => {
                const node = nodeIn as ValidBusinessObject
                const communityControls: JSX.Element = getCommunityControls(node)
                return (
                    <ExtendedTreeItem {...{ key: node.sk, nodeId: node.sk, label: node.name, x: communityControls }}>
                        {generateSubTree(node)}
                    </ExtendedTreeItem>
                )
            })
            )
        } else {
            debug && console.log('nodeEdgeMapper not yet initialized')
        }

    }

    const getCommunityControls = (node: ValidBusinessObject) => {
        return <Grid container  {...{ spacing: 1, wrap: 'nowrap' }} >
            <Grid item {...{}}>
                <Tooltip {...{ title: `Edit ${lscToC021AccountType(node.accountType)}` }}>
                    <IconButton {...{ size: 'small', onClick: () => history.push(`${NavSections.THINGVIEW}/${node.type}/${node.id}`) }}><Edit></Edit></IconButton>
                </Tooltip>
                <Tooltip {...{ title: `Manage ${lscToC021AccountType(node.accountType)} entities, users, devices` }}>
                    <IconButton {...{ size: 'small', onClick: () => history.push(`${NavSections.CUSTOMER_MANAGEMENT_VIEW}/UPDATE/${node.type}/${node.sk}`) }}><Business></Business></IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    }

    /**
     * Event handler for when a tree node is toggled. Selects/deselects all relevant parents depending on
     * toggle status
     * @param event 
     * @param nodeIds 
     */
    const handleChange = (event: any, nodeIds: string[]) => {
        if (nodeEdgeMapper) {
            let selectedNodeIds: string[] = []


            //Find toggled node in selectedParents list
            let toggledNodeId = selectedParentSks.filter((parent) => !nodeIds.find(node => node === parent))

            //If being selected
            if (toggledNodeId.length === 0) {
                toggledNodeId = nodeIds.filter(node => !selectedParentSks.find(parent => node === parent))
                //Gets a list of children who are also parents which need to be toggled when selected/unselected
                const childrenToToggle = getChildrenToToggle(toggledNodeId[0])
                selectedNodeIds = [...nodeIds].concat(childrenToToggle)

            } else { //else when being unselected
                const childrenToToggle = getChildrenToToggle(toggledNodeId[0])
                selectedNodeIds = [...nodeIds].filter(node => !childrenToToggle.find(child => child === node))
            }
            setSelectedParentSks([...selectedNodeIds])

            const selectedParentNodes = selectedNodeIds.map((sk) => nodeEdgeMapper.getPrimaryItem(sk)).filter((item) => item) as ValidBusinessObjectList
            const selectedParentNodesPlusFirstDecendents = selectedParentNodes.reduce((a, c) => {
                const exists = (x: ValidBusinessObject) => a.find((y) => x.sk === y.sk)
                if (!exists(c)) {
                    a.push(c)
                }
                nodeEdgeMapper.getChildNodes(c).forEach((ccIn) => {
                    const cc = ccIn as ValidBusinessObject
                    if (!exists(cc)) {
                        a.push(cc)
                    }
                })
                return a
            }, [] as ValidBusinessObjectList)
            setNodesToDisplay(selectedParentNodesPlusFirstDecendents)
        }

    }

    /**
     * Recursively gets a list of all the parent nodes down the tree from a given root node.
     * @param nodeSk String for nodeId of root node
     * @returns Array of nodeId for parent nodes down the tree from root node
     */
    const getChildrenToToggle = (nodeSk: string): string[] => {
        //debug && console.log(`getChildrenToToggle got ${nodeSk}, selectedParentSks is ${ selectedParentSks.join(' ')}`)
        let childrenToToggle: string[] = []
        if (nodeEdgeMapper) {
            const node = nodeEdgeMapper.getPrimaryItem(nodeSk)
            if (node) {
                const childNodes = nodeEdgeMapper.getChildNodes(node)
                debug && console.log(`getChildrenToToggle gotChildNodes ${childNodes.map((n) => (n as ValidBusinessObject).name).join(' ')}`)
                childNodes.forEach(childNode => {
                    const grandChildNodes = nodeEdgeMapper.getChildNodes(childNode)
                    if (grandChildNodes.length > 0) {
                        childrenToToggle.push(childNode.sk)
                        childrenToToggle = childrenToToggle.concat(getChildrenToToggle(childNode.sk))
                    }
                })
            }

        }
        return childrenToToggle
    }
    console.log("nodesToDisplay",nodesToDisplay[1])
    return (
        <Card>
            <Grid container className={styles.container} spacing={2}>
                <Grid item>
                    <Typography variant='h5' component='h5'>
                        Communities

                    </Typography>

                    {/* {selectedParentSks && contextCustomer &&
                        <TreeView {...{
                            defaultExpanded: [contextCustomer.sk], //TDOD
                            defaultCollapseIcon: <  CheckBoxIcon />,
                            defaultExpandIcon: < CheckBoxOutlineBlankIcon />,
                            onNodeToggle: handleChange,
                            expanded: selectedParentSks
                        }}

                        >
                            {generateTree([contextCustomer])}
                        </TreeView>
                    } */}
                </Grid>
                <Grid item xs={12} alignItems='center'>
                    <Card>
                        <CardContent>
                            <DataGrid

                                onRowClick={e => history.push(`/${NavSections.THINGVIEW}/Customer/${e.row.id}`)}
                                disableColumnMenu={true}
                                autoHeight={true}
                                rows={nodesToDisplay}
                                columns={gridDefintion}
                                disableSelectionOnClick={true}
                            />
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </Card>
    )
}