import React, { useEffect } from 'react';
import { createGoogleMap } from './mapfns/createGoogleMap';

const debug = process.env.REACT_APP_DEBUG && false;

export type GoogleMapV2Features = {
  myMapKMLs?: { id: string, type: 'KMLFileLink', name?: string }[]
}

export type GoogleMapPropsV2 = {
  setMapInParent?: (map: google.maps.Map<Element>) => void,
  controls?: any[],
}

export const GoogleMap = ({ setMapInParent, controls }: GoogleMapPropsV2) => {
  const googleMapRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const [map, setMap] = React.useState<google.maps.Map<Element> | undefined>(undefined)


  useEffect(() => {
    map && typeof setMapInParent === 'function' && setMapInParent(map)
  }, [map])

  const mapId = 'google-map-v2'
  const mapDiv = <div {...{
    id: mapId,
    ref: googleMapRef,
    style: { width: '100%', height: '100%' },

  }} />

  const parentHasRendered = document.querySelector(`div#${mapId}`)
  const mapOptions = {};

  if (parentHasRendered && mapDiv) {
    if (!map) {
      const map = createGoogleMap({ googleMapRef, options: mapOptions })
      if (map) {
        // if (controls) {
        //   map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controls[0]);
        // }
        
        setMap(map)

      }

    } else {
    }
  }



  return mapDiv
}