import {
    Dialog,
    FormControl,
    TextField,
    Grid,
    OutlinedInput,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    Typography,
    FormHelperText,
    Card,
    CardContent,
    Button,
    InputLabel,
    Input,
    // ImageList,
    ImageListItem,
    ImageListItemBar,
    // IconButton
} from "@mui/material";

import {
    GridListTile,
    GridListTileBar,
    IconButton,
    ImageList,
} from "@material-ui/core";

import StarBorderIcon from '@mui/icons-material/StarBorder';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from "uuid";
import { DispatchFunctionsType, AdjacentType, AreaManagementMode, S3ImageRecordAndURLMap, S3ImageRecordAndURL } from '../../../types/AppTypes';
import { useEffect, useState } from "react";
import UploadIcon from './UploadIcon';
import { listObjectsAndUrls, deleteObject } from '../../../data/aws/s3/UserBlobs';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../cosmetics/sharedCardStyles";
import FavoriteIcon from "@material-ui/icons/Favorite";



const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));

export type MyIconsProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    values: any,
    setValues: React.Dispatch<React.SetStateAction<any>>;
}

export default function IconList({ open, setOpen, values, setValues }: MyIconsProps) {
    const classes = useStyles();

    const Input = styled('input')({
        display: 'none',
    });

    const [objectCount, setObjectCount] = useState(0);
    const [objectsAndUrls, setObjectsAndUrls] = useState<S3ImageRecordAndURLMap>({})
    const [selectedPhoto, setSelectedPhoto] = useState<S3ImageRecordAndURL>()

    debug && console.log('initial selectedPhoto', selectedPhoto)

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const directoryString = 'icons';
        listObjectsAndUrls(directoryString).then((res) => {
            debug && console.log('get icons from s3 bucket icons', res)
            setObjectsAndUrls(res)
            setObjectCount(Object.values(res).length)
        })
    }, [objectCount])

    const selectIcon = (objectAndUrl: S3ImageRecordAndURL) => {
        setSelectedPhoto(objectAndUrl);
        debug && console.log("selectImage", objectAndUrl.url);
        setValues({ ...values, icon: objectAndUrl.url})
        
    }

    const setDefaultIcon = () => {
        setSelectedPhoto(undefined)
        setValues({ ...values, icon: ''})
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{'Icons'}</DialogTitle>
            <DialogContent>

                <ImageList rowHeight={160} /*className={classes.gridList}*/ cols={3}>
                    {objectsAndUrls && Object.values(objectsAndUrls).filter((objectAndUrl) => (typeof objectAndUrl.url === 'string' && typeof objectAndUrl.key === 'string'))
                        .map((objectAndUrl: S3ImageRecordAndURL) =>

                            <GridListTile key={objectAndUrl.key as string} cols={1}>
                                <img
                                    src={objectAndUrl.url as string}
                                    alt={objectAndUrl.key}
                                />
                                
                                <GridListTileBar
                                    actionIcon={
                                        <IconButton
                                            onClick={() => selectIcon(objectAndUrl)}
                                            className={
                                                objectAndUrl.url === selectedPhoto
                                                    ? classes.photoGridFavIconDefault
                                                    : classes.photoGridFavIcon
                                            }
                                        >
                                            <FavoriteIcon />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>

                        )}
                </ImageList>
                <Button onClick={() => setDefaultIcon()}>Set As Default</Button>               
                <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogContent>
        </Dialog>
    )
}