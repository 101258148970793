import { debug } from '@iotv/datamodel-core'
import { ErrData, ErrDataPromise, IOTV_LCD_DeviceMessage } from '@iotv/iotv-v3-types'
import { DeviceMessageQueryRequest } from './getFromS3'

export type DeviceEuiDates =  [ deviceEui: string, date: Date ][]

export const getDeviceEuiDates = ( { deviceEuis, startDate, endDate }: DeviceMessageQueryRequest ): DeviceEuiDates => {


    // no doubt will need some protection on querying x devices over y days were x * y = someLimit
    const deviceEuiDates: [ deviceEui: string, date: Date ][] = []
    const endDateUtc = new Date(endDate)
    endDateUtc.setUTCHours(0, 0, 0, 0)

    const iteratorDate = new Date( startDate )
    iteratorDate.setUTCHours(0, 0, 0, 0)
    deviceEuis.forEach( ( deviceEui ) => {
        const utcWholeDays: Date[] = [ startDate ]

        do {
            iteratorDate.setUTCDate( iteratorDate.getUTCDate() + 1)
            console.log('iteratorDate', iteratorDate.toString())
            utcWholeDays.push( new Date(iteratorDate ))
        } while ( iteratorDate < endDateUtc )

        utcWholeDays.forEach( (date) => deviceEuiDates.push([ deviceEui, date ]))

        debug && console.log('getDeviceMessagesFromS3',{ deviceEuiDates, utcWholeDays})
    })

    return deviceEuiDates
}