import { AdjacentType, ErrDataPromise, isValidModelTypeId, mode, ModelClassNames, ModelTypes, QueryTypes, removeEmptyArrays, ValidBusinessObject, ValidBusinessObjectList, ValidModelType } from '@iotv/datamodel';
import { Button, Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, TableCell, TableContainer, TextField, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import { Autocomplete } from '@material-ui/lab';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import config from '../../../config';
import styles from '../../../cosmetics/sharedViewStyles';
import Assembler from '../../../data/Assembler';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { deleteObject, link, save } from '../../../data/daoFunctions/daoFunctions';
import { useDocumentClientQuery } from '../../../hooks/useDocumentClientQueryV1';
import { AdjacentFilter, ComponentMode, ContextObjectType, DeleteObjectRespose, IUserMessageType, ListerlizerRowControlFnProps, ListLimitedAllTypeRequestBody, MessageOutputType, SaveResponce, SearchParamsType, ThingsViewProps, UserTransactionType, ValidCustomerObject, ViewDefinition, ViewObject } from '../../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../../types/LabTypes';
import { commonListViewDefinition, genericListViewDefinition, getViewDefinitionFromUserAttributes } from '../../factories/AttributeDefinitions';
import { DocumentClientListerlizerWrapper } from '../../factories/DocumentClientListerlizerWrapper';
import { IOType, SimpleIOProps } from '../../io/simpleIO';
import { SimpleIOToggleWrapper } from '../../io/simpleIOToggleWrapper';
import { modelTypes } from '../../../util/Model/modelTypes'
import { NavSections } from '../../navigation/NavSections';
import { AppValueTypes, ErrData, GraphType, MapLike } from '@iotv/iotv-v3-types';
import { AppLocalStorage } from '../../../data/LocalStorage/AppLocalStorage';
import { SingleObjectAutoComplete } from '../ObjectAutoComplete';
import { useObjectByTypeAndName } from '../../../hooks/useObjectByTypeAndName';


const debug = process.env.REACT_APP_DEBUG && false;
const effectDebug = process.env.REACT_APP_DEBUG && false;
const apiRef = config.app.appName
const componentClassName = 'DataTableView'

const getViewDefinition = (modelClass: ModelTypes | undefined) => modelClass ? getViewDefinitionFromUserAttributes(new modelClass({}).getUserAttributes()) : genericListViewDefinition;

function DataTableView(props: ThingsViewProps): JSX.Element {
  const { classes, viewObjects, contextCustomer, transactionFunctions, userFunctions, user, userGroupRoles, userSelectedGroup, match, history, redirect } = props;
  const query = async (fn: (params: any) => void, contextRequest: ListLimitedAllTypeRequestBody) => {
    debug && console.log('DataTableView query request', contextRequest)
    try {
      const response = await ApiGateway.post(`/query/`, contextRequest, apiRef);
      if (response.data?.Items) {
        response.data.Items = response.data.Items.map((zombie: ValidBusinessObject) => Assembler.getInstance(zombie))
      }
      debug && console.log('DataTableView query results', response)
      fn(response);
    } catch (e) {
      debug && console.log('Error in UM query', e)
    }
  }

  const routerHistory = useHistory()
  const searchParams: SearchParamsType | undefined = history.location?.search ? queryString.parse(history.location.search) as SearchParamsType : undefined
  const typeByLocation = match.params.type;
  const modelClass = Assembler.getClass(typeByLocation ?? 'nothing ');
  const isValidModelType = modelClass !== undefined;
  const derivedType = isValidModelType ? typeByLocation as ValidModelType : (AppLocalStorage.get(NavSections.DATATABLEVIEW, 'objectTypeId') ?? 'User');

  const [objectTypeId, setObjectTypeId] = React.useState<ValidModelType | undefined>(derivedType)
  const [contextObject, setContextObject] = React.useState<ValidBusinessObject | undefined>(undefined)

  const [queryGraphType, setQueryGraphType] = React.useState<GraphType>(AppLocalStorage.get(NavSections.DATATABLEVIEW, 'queryGraphType') ?? GraphType.NODE)
  const [adjacencyType, setAdjacenyType] = React.useState<AdjacentType>(AdjacentType.CHILD)
  const [viewDefinition, setViewDefinition] = React.useState<ViewDefinition>(getViewDefinition(modelClass))

  const [linkOnSave, setLinkOnSave] = React.useState<boolean>(AppLocalStorage.get(NavSections.DATATABLEVIEW, 'linkOnSave') ?? true)
  const [contextObjectType, setContextObjectType] = React.useState<ContextObjectType>(AppLocalStorage.get(NavSections.DATATABLEVIEW, 'contextObjectType') ?? ContextObjectType.NO_CONTEXT)

  const handleContextObjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueMap: MapLike<ValidBusinessObject | undefined> = {
      [ContextObjectType.CUSTOMER]: contextCustomer,
      [ContextObjectType.USER]: user,
      [ContextObjectType.NO_CONTEXT]: undefined,
      [ContextObjectType.USER_SELECTED]: userSelectedContextObject
    }
    const selection = e.currentTarget.value as ContextObjectType;
    setContextObjectType(selection);
    setContextObject(valueMap[selection]);

    AppLocalStorage.set(NavSections.DATATABLEVIEW, 'contextObjectType', selection)
  }

  const handleQueryGraphTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const graphType: GraphType = e.currentTarget.value as GraphType
    setQueryGraphType(graphType)
  }

  const handleAdjacencyTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const adjacencyType: AdjacentType = e.currentTarget.value as AdjacentType
    if ( adjacencyType === AdjacentType.SUBJECT ) {
      setContextObjectType( ContextObjectType.NO_CONTEXT )
    }
    setAdjacenyType(adjacencyType)
  }
  const currentObject = undefined
  const currentObjectTypeId = undefined
  const validTypeIds = modelTypes;


  const {
    components: componentMap,
    state: {
      currentEntity: [userSelectedContextObject, setCurrentEntity],
      // objectTypeId: [ objectTypeId, setObjectTypeId ]
    }
  } = useObjectByTypeAndName({ contextObject, currentObject, currentObjectTypeId, validTypeIds, adjacencyType: AdjacentType.SUBJECT })




  useEffect(() => {
    if (contextObject?.type === 'Customer') setContextObject(contextCustomer)
  }, [contextCustomer?.sk])

  useEffect(() => {
    debug && console.log('used effect on objectTypeId', objectTypeId)
    debug && console.log('match params are', match.params);
    setObjectTypeId(objectTypeId)
    const modelClass = Assembler.getClass(objectTypeId ?? 'nothing ');
    setViewDefinition(getViewDefinition(modelClass))
  }, [objectTypeId])

  useEffect(() => {
    if (contextObjectType === ContextObjectType.USER_SELECTED) {
      setContextObject(userSelectedContextObject);
      debug && console.log('Set userSelectedContextObject to ', userSelectedContextObject)
    }

  }, [userSelectedContextObject?.sk, adjacencyType])




  const viewObject: ViewObject = { matchedPrimary: contextCustomer, matchedRelatedByPk: [], matchedRelatedBySk: [] }
  const queryParams: ListLimitedAllTypeRequestBody = {
    ExclusiveStartKey: undefined,
    limit: 50,
    contextObject: contextObject as ValidBusinessObject,
    excludeRelatedToObject: undefined,
    includeEdges: queryGraphType === GraphType.EDGE,
    includeNodes: queryGraphType !== GraphType.EDGE,
    adjacencyType: contextObjectType === ContextObjectType.NO_CONTEXT ? AdjacentType.SUBJECT : adjacencyType,
    objectTypeId: objectTypeId ?? '',
    queryType: QueryTypes.LIST_LIMITED_ALL_TYPES
  };


  const queryHook = useDocumentClientQuery(query, queryParams, [objectTypeId, queryGraphType, adjacencyType ])
  debug && console.log('Datatable query', queryParams)

  const [[items, setItems],
    [limit, setLimit],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects] = queryHook.selectionControls;

  if (selectedObjects.length > 0) {
    debug && console.log('First selected', selectedObjects[0])
  }

  const forceUpdate = () => { 
    setLastEvaluatedKeys([] )
    setItems([])
    setExclusiveStartKey(undefined)
    queryHook.forceUpdate() 
  }
  const handleRowObjectSave = async (ListRowProps: ListerlizerRowControlFnProps) => {
    const mutant = { ...ListRowProps.rowObject, pk: ListRowProps.rowObject.sk };
    const message: Partial<IUserMessageType> = { content: undefined, type: 'MessageBar', id: v4(), label: 'Warning', }
    const saveRes: ErrData<ValidBusinessObject | undefined> = await save(mutant);
    if (saveRes.data) {
      const savedObject = saveRes.data;
      if (linkOnSave && contextObject) {
        const linkRes = await link(contextObject as ValidBusinessObject, savedObject);
        if (linkRes.data) {
          Object.assign(message, { type: 'SnackBar', content: `Saved and Linked ${ListRowProps.rowObject.name} to ${(contextObject as ValidBusinessObject).name}` });
        } else {
          Object.assign(message, { content: `Failed to link ${ListRowProps.rowObject.name}, ${saveRes.err?.message}` })
        }

      } else {
        Object.assign(message, { type: 'SnackBar', content: `Saved ${ListRowProps.rowObject.name}` });
      }
      ListRowProps.setRowMode(ComponentMode.VIEW)
    } else {
      Object.assign(message, { content: `Failed to save ${ListRowProps.rowObject.name}, ${saveRes.err?.message}` })
    }
    message.content && userFunctions.setUserMessage(message as IUserMessageType)
    forceUpdate()
  }

  const handleSaveSelected = async () => {
    const promises = await Promise.all(selectedObjects.map(async (item) => {
      removeEmptyArrays(item)
      const saveRes: ErrData<ValidBusinessObject | undefined> = await save(item as ValidCustomerObject)
      if (linkOnSave && saveRes.data && contextObject) {
        const savedObject = saveRes.data;
        const linkRes = await link(contextObject, savedObject)
      }
      return saveRes;
    }))

    const errs = promises.filter((promiseRes) => promiseRes.err)
    const datas = promises.filter((promiseRes) => promiseRes.data)

    if (errs.length > 0) {
      userFunctions.setUserMessage({ type: 'MessageBar', content: `Failed to save ${errs.length} items, ${errs.map((err) => err?.err?.message).join(', ')}`, label: 'Warning', id: v4() })
    }
    if (datas.length > 0) {
      userFunctions.setUserMessage({ type: 'SnackBar', content: `Saved ${datas.length} items`, label: 'Yay', id: v4() });
    }
  }

  const handleRowObjectDelete = async (ListRowProps: ListerlizerRowControlFnProps) => {
    const mutant = { ...ListRowProps.rowObject, pk: ListRowProps.rowObject.sk }
    const deleteRes: ErrData<ValidBusinessObject> = await deleteObject(mutant);
    if (deleteRes.data) {
      userFunctions.setUserMessage({ type: 'SnackBar', content: `Deleted ${ListRowProps.rowObject.name}`, label: 'Yay', id: v4() });
    } else {
      userFunctions.setUserMessage({ type: 'MessageBar', content: `Failed to save ${ListRowProps.rowObject.name}, ${deleteRes.err?.message}`, label: 'Warning', id: v4() })
    }
    forceUpdate()
  }

  const handleOpenObject = (ListRowProps: ListerlizerRowControlFnProps) => {
    routerHistory.push(`/${NavSections.THINGVIEW}/${ListRowProps.rowObject.type}/${ListRowProps.rowObject.id}`)
  }

  const handleLinkOnSaveChange = () => {
    AppLocalStorage.set(NavSections.DATATABLEVIEW, 'linkOnSave', !linkOnSave)
    setLinkOnSave(!linkOnSave)
  }




  const getRadio = () => <Radio {...{
    size: 'small', classes: {
      root: classes.radio
    },
  }}></Radio>


  const getObjectTypeSelector = () => <Grid item {...{ xs: 2 }}>
    {
      typeByLocation ? <div>{objectTypeId}</div> : <FormControl>
        <Autocomplete {
          ...{
            freeSolo: false,
            value: objectTypeId,
            id: "objectType-selector",
            options: modelTypes,
            getOptionLabel: (option: string) => option,
            style: {},
            renderInput: (params) => <TextField {...{ ...params, label: "Object Type", variant: "outlined", size: 'small', style: { width: '16rem' } }} />,
            onChange: (e, v: { title: string } | string | null) => {
              debug && console.log('type change', { e, v })
              AppLocalStorage.set(NavSections.DATATABLEVIEW, 'objectTypeId', v ? v as string : undefined)
              setObjectTypeId(v as ModelClassNames ?? undefined)
            }
          }
        }

        />
      </FormControl>
    }
  </Grid>


const getModeRadioButtons = () => {
  return (
    <>
    <Grid { ...{ xs: 2}}>
          <FormControl component="fieldset" size='small' >
      <FormLabel component="legend" classes={{ root: classes.radio }}  >Context Object</FormLabel>
      <RadioGroup row aria-label="mode" name="mode" value={contextObjectType} onChange={handleContextObjectChange} className={classes.smaller}>
        <FormControlLabel value={ContextObjectType.USER} classes={{ label: classes.radio }} control={getRadio()} label="User" />
        <FormControlLabel value={ContextObjectType.CUSTOMER} classes={{ label: classes.radio }} control={getRadio()} label="Customer" />
        <FormControlLabel value={ContextObjectType.NO_CONTEXT} classes={{ label: classes.radio }} control={getRadio()} label="None" />
        <FormControlLabel value={ContextObjectType.USER_SELECTED} classes={{ label: classes.radio }} control={getRadio()} label="User Selected" />
      </RadioGroup>
    </FormControl>
    </Grid>
    {
      contextObjectType === ContextObjectType.USER_SELECTED && getContextObjectSelector()
    }
    </>
  );
}

const getNodeEdegeRadioButtons = () => {
  return (
    <Grid { ...{ xs: 2 }}>    
    <FormControl component="fieldset" size='small' >
      <FormLabel component="legend" classes={{ root: classes.radio }}  >Graph Type</FormLabel>
      <RadioGroup row aria-label="mode" name="mode"  value={queryGraphType} onChange={handleQueryGraphTypeChange} className={classes.smaller}>
        <FormControlLabel value={GraphType.NODE} classes={{ label: classes.radio }} control={getRadio()} label="Nodes" />
        <FormControlLabel value={GraphType.EDGE} classes={{ label: classes.radio }} control={getRadio()} label="Edges" />

      </RadioGroup>
    </FormControl>
    </Grid>

  );
}

const getAdjacencyTypeRadioButtons = () => {
  return (
    <Grid { ...{ xs: 2 }}>
          <FormControl component="fieldset" size='small' >
      <FormLabel component="legend" classes={{ root: classes.radio }}  >Adjacency Type</FormLabel>
      <RadioGroup row aria-label="mode" name="mode" value={adjacencyType} onChange={handleAdjacencyTypeChange} className={classes.smaller}>
        <FormControlLabel value={AdjacentType.CHILD} classes={{ label: classes.radio }} control={getRadio()} label="Child" />
        <FormControlLabel value={AdjacentType.PARENT} classes={{ label: classes.radio }} control={getRadio()} label="Parent" />
        <FormControlLabel value={AdjacentType.SUBJECT} classes={{ label: classes.radio }} control={getRadio()} label="Subject" />
      </RadioGroup>
    </FormControl>
    </Grid>
  );
}

  const getContextObjectSelector = () =>  contextObjectType === ContextObjectType.USER_SELECTED ? <Grid item {...{ xs: 2 }}>
    {[
      componentMap.objectTypeSelector,
      componentMap.entitySelector,

    ]}
      </Grid> : null

const getSelectedItemsControls = () => <Grid item {...{ xs: 1 }} >

{items.length > 0 && <FormControl><Button {...{ onClick: () => clearSelectedObjcets(), variant: 'outlined', size: 'small' }}>Clear</Button></FormControl>}
{items.length === 0 && <FormControl><Button {...{ onClick: () => forceUpdate(), variant: 'outlined', size: 'small' }}>Requery</Button></FormControl>}

</Grid>

const getLinkToggle = () => <Grid item {...{ xs: 2 }} >
{
  typeByLocation ? <div></div> : <FormControl {...{ size: 'small' }} ><FormControlLabel
    key='contextObject-switch'
    control={<Switch {
      ...{
        size: 'small',
        checked: linkOnSave,
        onChange: handleLinkOnSaveChange,
        name: "contextObjectSwtch",
        inputProps: { 'aria-label': 'secondary checkbox' }
      }
    }

    />}
    label={linkOnSave ? 'link on save' : 'no link on save'}
  /></FormControl>
}
</Grid>

  const queryControls = () => [
    <Grid container {...{ spacing: 2, }}>
      {[ getModeRadioButtons(), getObjectTypeSelector(), getAdjacencyTypeRadioButtons(),  getNodeEdegeRadioButtons(), getLinkToggle(), getSelectedItemsControls() ]}
    </Grid>
  ]

  const adjacentFilter: AdjacentFilter | undefined = typeof objectTypeId === 'string' && isValidModelTypeId(objectTypeId) ? {
    adjacentType: AdjacentType.CHILD, objectType: objectTypeId, edgeFilter: undefined
  } : undefined

  const docClientListProps: DocumentClientListerlizerWrapperProps<ListLimitedAllTypeRequestBody> | undefined = queryParams && adjacentFilter ? {
    ...props, contextObject, viewObject, viewDefinition, adjacentFilter,
    queryHook, queryParams,
    sortKeyIn: 'name', maxItems: queryParams.limit ?? 50,
    allowCreate: true,
    queryControls,
    rowControlOverrideFns: {
      last: [],
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell {...{
              key: `${listRowProps.rowObject.sk}_edit`, onClick: (maybe: any) => {
                debug && console.log('Click has ', { maybe, listRowProps, })
                const newMode = listRowProps.rowMode === ComponentMode.EDIT ? ComponentMode.VIEW : ComponentMode.EDIT;
                debug && console.log('set row mode ', { newMode, currentMode: listRowProps.rowMode, })
                listRowProps.setRowMode(newMode);
              }
            }}>
              {listRowProps.rowMode === ComponentMode.EDIT ? <CancelIcon></CancelIcon> : <EditIcon></EditIcon>}
              {listRowProps.rowMode === ComponentMode.EDIT ?
                <SaveIcon {...{
                  onClick: (e: React.MouseEvent) => {
                    e.preventDefault(); e.stopPropagation();
                    handleRowObjectSave(listRowProps)
                  }
                }}></SaveIcon>
                :
                <DeleteIcon {...{
                  onClick: (e: React.MouseEvent) => {
                    e.preventDefault(); e.stopPropagation();
                    handleRowObjectDelete(listRowProps)
                  }
                }}></DeleteIcon>
              }
              {listRowProps.rowMode !== ComponentMode.EDIT && <ListIcon {...{
                onClick: (e: React.MouseEvent) => {
                  e.preventDefault(); e.stopPropagation();
                  handleOpenObject(listRowProps)
                }
              }}></ListIcon>}
            </TableCell>
          ]
        }
      ],
    }
  } : undefined;

  const transformGStringsViaViewDefinition = (pbos: ValidBusinessObjectList) => pbos.map((pbo) => {
    Object.values(viewDefinition).forEach((vkd) => {
      if (vkd.type === AppValueTypes.NUMBER) {
        if (typeof pbo[vkd.key] !== 'number') {
          const parsedNumber = parseFloat(pbo[vkd.key]);
          if (!isNaN(parsedNumber)) {
            pbo[vkd.key] = parsedNumber;
            debug && console.log('parsed to', parsedNumber)
          }
        }
      }
    }
    );
    return pbo;
  })

  const importProps: SimpleIOProps = {
    ioType: IOType.IMPORT, vobs: [],
    setVBOsInParent: (vbos: ValidBusinessObjectList) => { setItems([...items, ...transformGStringsViaViewDefinition(vbos)]) },
    contextCustomer, userSelectedGroup,
    transactionFunctions, userFunctions,
    viewDefinition,
    parentHasRendered: () => document.querySelector(`.${componentClassName}`) !== null
  }

  const exportProps: SimpleIOProps = {
    ioType: IOType.EXPORT, vobs: selectedObjects as ValidBusinessObjectList,
    setVBOsInParent: (vbos: ValidBusinessObjectList) => { setItems([...items, ...vbos]) },
    contextCustomer, userSelectedGroup,
    transactionFunctions, userFunctions,
    viewDefinition,
    parentHasRendered: () => document.querySelector(`.${componentClassName}`) !== null
  }

  const showSaveAll = selectedObjects.length > 0

  const clearSelectedObjcets = () => {
    setItems([]);
    setSelectedObjects([])
  }

  return <Card>
    <CardContent>
      <div>
        <SimpleIOToggleWrapper {...{ exportProps, importProps }}></SimpleIOToggleWrapper>
        {showSaveAll && <FormControl><Button {...{ onClick: handleSaveSelected, variant: 'outlined' }}>Save Selected</Button></FormControl>}

      </div>
      <TableContainer> {
        docClientListProps && user && <DocumentClientListerlizerWrapper {...{ ...docClientListProps }}></DocumentClientListerlizerWrapper>
      }
      </TableContainer>
    </CardContent>
  </Card>
}

export default withStyles(styles)(DataTableView)