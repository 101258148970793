import { deviceEnabledTypes, GeofencePointsType, ObjectHistory, ThingShadowState } from '@iotv/datamodel';
import { setAContainsSomeB, StateSnapshot } from '@iotv/iotv-v3-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useTabHandler } from '../../hooks/useTabHandler';
import styles from '../../cosmetics/sharedCardStyles';
import { getImageUrl } from '../../data/aws/s3/UserBlobs';
import { isDeviceEnabledType, thingShadowStateToStateSnapshot } from '../../data/TypeHelpers';
import { useObjectHistory } from '../../hooks/useObjectHistory';
import { KeyMappedComponentFunctions, KeyMappedComponents, ObjectCardProps, UserTransactionType, ValidCustomerObject, ViewObject } from '../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../util/geo/GeoDataHelpers';
import ObservationCard from '../cards/Observation/ObservationCard';
import PhotosCard from '../cards/PhotosCard';
import { IncidentListCard } from '../common/IncidentListCard';
import ObjectGraphCard from '../generic/ObjectGraphCard';
import ObjectHistoryCard from '../generic/ObjectHistoryCard';
import ObjectHistoryTableCard from '../generic/ObjectHistoryTableCard';
import MapCard from '../geo/MapCard';
import { SlaveTab } from '../factories/SlaveTabV2';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));


export default function ObjectCardTemplate(props: ObjectCardProps) {
  debug && console.log('Object card template got props', JSON.stringify(props.viewObject, null, 1))
  const classes = useStyles();

  const { viewObject: { matchedPrimary }, contextCustomer,  viewObject, user, userSelectedGroup, userGroupRoles, userGeolocation, transactionFunctions, userFunctions, searchParams, history } = props
  const [objectHistory, setObjectHistory] = useObjectHistory(matchedPrimary)
  // const [[incidentHistory, set
  const GP = Grid

  const tabsToDisplay: KeyMappedComponentFunctions = {
    Details: () => (matchedPrimary) ? <GP container { ...{ key: 'DetailsDiv'}}>
      <Grid item xs={12} lg={6} { ...{ key: 'DetailsGrid'}}>
        SOME DETAILS
      </Grid>
    </GP> : null,

    Links: () => (matchedPrimary) ? <GP container { ...{ key: 'LinksDiv'}}>SOME LINKS </GP> : null,

    History: () => (matchedPrimary && objectHistory && contextCustomer) ? <GP container { ...{ key: 'HistoryDiv'}}>
      <Grid item xs={12} { ...{ key: 'HistoryGrid'} }>
        <ObjectHistoryCard {...{
          classes, matchedPrimary: matchedPrimary, objectHistory, objectHistoryConfig: {
            dailyStateHistory: true, recentStateHistory: true
          }
        }} ></ObjectHistoryCard>
      </Grid>
      <Grid item xs={12} { ...{ key: 'HistoryTableGrid'} }>
        <ObjectHistoryTableCard {...{
          contextCustomer: contextCustomer as ValidCustomerObject,
          viewObject, contextObject: undefined, objectHistory
          , userFunctions, transactionFunctions, searchParams, history
          , actions: [], viewDefinition: {}, filterMetricKeys: undefined
          , title: `${matchedPrimary.name}'s history`
          , userSelectedGroup
        }} ></ObjectHistoryTableCard>
      </Grid> </GP> : null,
    
    Observations: () => (matchedPrimary && isDeviceEnabledType(matchedPrimary) && objectHistory && contextCustomer) ? <GP container { ...{ key: 'ObservationsDiv'}}>
    <Grid item xs={12} { ...{ key: 'ObservationsGrid'} }>
      <ObservationCard {...{ key: 'ObservationsCard',
        contextCustomer: contextCustomer as ValidCustomerObject,
        viewObject, contextObject: undefined, objectHistory
        , userFunctions, transactionFunctions, searchParams, history
        , actions: [], viewDefinition: {}, filterMetricKeys: undefined
        , title: `${matchedPrimary.name}'s observations`
        , userSelectedGroup
      }} ></ObservationCard>
    </Grid> </GP> : null,

    Charts: () =>  (objectHistory?.dailyStateHistory || matchedPrimary?.dailyStateHistory) ? <GP container { ...{ key: 'ChartsDiv'}}>
      <Grid item xs={12} lg={6} { ...{ key: 'ChartsGrid'} }>
        <ObjectGraphCard {...{ historyObject: objectHistory?.dailyStateHistory ?? matchedPrimary?.dailyStateHistory, classes, title: 'Daily State History' }} ></ObjectGraphCard>
      </Grid>
      <Grid item xs={12} lg={6} { ...{ key: 'GraphGrid'} }>
        <ObjectGraphCard {...{ historyObject: objectHistory?.recentStateHistory ?? matchedPrimary?.recentStateHistory, classes, title: 'Recent State History' }} ></ObjectGraphCard>
      </Grid>
      </GP> : null,

    Geo:  () => <GP container><Grid item xs={12} lg={6} { ...{ key: 'GeoGrid'} }>
      <MapCard {...{ position: undefined, editable: false, locatableAddress: undefined, viewObjects: [viewObject], polylines: {}, optionals: undefined }} ></MapCard>
    </Grid></GP>,

    Incidents: () => (isDeviceEnabledType(matchedPrimary) || setAContainsSomeB( [ matchedPrimary?.type ?? 'ignore' ], ['Device', 'Gateway'])) ? <GP container><Grid item xs={12} lg={6} { ...{ key: 'IncidentsGrid'} }>
      <IncidentListCard {...{ transactionFunctions, userGroupRoles, userFunctions, viewObject, contextCustomer, searchParams, history }}></IncidentListCard>
    </Grid></GP> : null,

    Media: () => (matchedPrimary && isDeviceEnabledType(matchedPrimary)) ? <GP><Grid item xs={12}  { ...{ key: 'PhotosGrid'} }>
      <PhotosCard {...{ matchedPrimary: matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions:[], viewDefinition: {}, title: `${matchedPrimary.name}'s photos` }} ></PhotosCard>
    </Grid></GP> : null
  }

  const { state: [[tabValue, setTabValue]], handlers: { handleTabChange } } = useTabHandler( tabsToDisplay)




  debug && console.log(`${matchedPrimary?.type} is deviceEnabledType ${ matchedPrimary ? isDeviceEnabledType(matchedPrimary): false}`, deviceEnabledTypes)
  useEffect(() => {
    if (matchedPrimary?.type === 'Tank' && viewObject.largeObject === undefined) {
      transactionFunctions.getLargeObject(matchedPrimary)
    }
  }, []);


  const [imageUrl, setImageUrl] = React.useState('')

  useEffect(() => {
    setObjectHistory(undefined)
    async function effectUrl() {
      const objectOrString = await getImageUrl(matchedPrimary)
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url)
        setImageUrl(url)
      }

    }
    matchedPrimary && effectUrl()
  }, [matchedPrimary?.sk]);


  const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst }:
  { mapViewObject: ViewObject | undefined, lastestThingStateShadow: ThingShadowState | undefined, track: GeofencePointsType[], position: GeofencePointsType | undefined, thingStatesMostRecentFirst: ThingShadowState[] }
  = isDeviceEnabledType(matchedPrimary) ? extractGeoDataFromViewObject(viewObject) : { mapViewObject: undefined, lastestThingStateShadow: undefined, track: [], position: undefined, thingStatesMostRecentFirst: [] }

debug && console.log('ObjectCard geo', mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst)


  useEffect( () => {
    debug && console.log(`Object card use effect on lastestThingStateShadow at ${new Date()}`, lastestThingStateShadow)
    if (lastestThingStateShadow && objectHistory?.recentStateHistory) {
      const filterKeys = objectHistory.recentStateHistory.keys();
      const snapshot: StateSnapshot<any> = thingShadowStateToStateSnapshot(lastestThingStateShadow, filterKeys)
      const objectHistoryClone = new ObjectHistory(objectHistory)
      objectHistoryClone.recentStateHistory?.push(snapshot)
      setObjectHistory(objectHistoryClone)
    }

  }, [lastestThingStateShadow?.sk])

  const thingShadowStates = viewObject.matchedRelatedBySk ? viewObject.matchedRelatedBySk.filter((item) => item.type === 'ThingShadowState') : []
  debug && console.log('thingShadowStates', thingShadowStates)

  const actions: UserTransactionType[] = [
    UserTransactionType.CREATE
  ]

  return (
    <Card className={classes.root}>
      <CardHeader
       
        title={matchedPrimary?.name}
        subheader={matchedPrimary?.breed}
      />
      <CardContent>
      <Tabs {...{ value: tabValue, onChange: handleTabChange, variant: 'scrollable' }} >
          {Object.entries(tabsToDisplay).filter(([tabName, element], i) => element !== null ).map(([tabName, element], i) => <Tab  {...{ key: `tab_${i}`, label: tabName, disabled: false }} />)}
        </Tabs>

        {Object.values(tabsToDisplay).filter( (c) => c !== null ).map((component, index) => <SlaveTab {...{ key: `tab${index}`, orderedComponents: [ component ], index, value: tabValue }}></SlaveTab>)}


      </CardContent>
     
    </Card>
  );
}

function getPosition(polyline: any) {
  throw new Error('Function not implemented.');
}
