// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import UserDetailCard from './UserDetailCard';
// import Typography from '@material-ui/core/Typography';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { UserCardProps, AdjacentType, AdjacentFilter, ListConfigParams } from '../types/AppTypes';
// import { Grid, Badge } from '@material-ui/core';
// import NavLink from 'navigation/NavLink';
// import { NavSections } from 'navigation/NavSections';
// import styles from '../cosmetics/sharedCardStyles';
// import clg from '../images/clg.jpeg';
// import { SimpleObjectLink } from 'navigation/simpleObjectLink';
// import ObjectListCard from 'generic/ObjecListCard';
// import AddressCard from 'common/AddressCard';
// import UserAlertControlCard from 'common/UserAlertControlCard';
// import { getLocatableAddress } from '../data/TypeHelpers';
// import MapCard from 'geo/MapCard';
// import { isGeofencePointsType } from '@iotv/datamodel';

// const debug = process.env.REACT_APP_DEBUG && false;

// const useStyles = makeStyles((theme) => styles(theme));



// export  function UserCard( props: UserCardProps  ) {
//   debug && console.log('User Card has', props)
//   const classes = useStyles();
//   const [expanded, setExpanded] = React.useState(false);

//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };



//   const { matchedPrimary } = props.viewObject 
//   const { transactionFunctions, userFunctions, searchParams, viewObject, history, userGeolocation } = props;
//   const imageUrl ={clg}

//   const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);



//   const hasAlert = () => true;
//   const alerts = [];

//   const getUserGeolocation = () => { 
//     let position = undefined;
//     if (userGeolocation) {

//       let x  = {
//         lat: userGeolocation.latitude,
//         lng: userGeolocation.longitude
//       }
//       if (isGeofencePointsType(x)) position = x;
//     }
//     return position;
//   }

//   const groupsAdjacentFilter: AdjacentFilter = {
//     adjacentType: AdjacentType.CHILD,
//     edgeFilter: undefined,
//     objectType: 'Group'

//   }
//   const groupsListConfigParams: ListConfigParams = {
//     label: 'My Groups'
//   }

//   const geofenceAdjacentFilter: AdjacentFilter = {
//     adjacentType: AdjacentType.CHILD,
//     edgeFilter: undefined,
//     objectType: 'Geofence'

//   }
//   const geofenceListConfigParams: ListConfigParams = {
//     label: 'My Fields'
//   }

//   const tankAdjacentFilter: AdjacentFilter = {
//     adjacentType: AdjacentType.CHILD,
//     edgeFilter: undefined,
//     objectType: 'Tank'

//   }
//   const tankListConfigParams: ListConfigParams = {
//     label: 'Tanks'
//   }

//   return (
//     <Card className={classes.root}>
//       <CardHeader
//         avatar={
//             <NavLink filter = {matchedPrimary ? `${NavSections.THINGVIEW}/${matchedPrimary.type}/${matchedPrimary.id}` : NavSections.THINGVIEW} children = {
//               <Badge badgeContent={alerts.length} color="primary" invisible = { !(hasAlert())}>
//             <Avatar aria-label="recipe" className={classes.avatar} src= {matchedPrimary?.presignedUrl || imageUrl } >
//               R
//             </Avatar>
//             </Badge>
//             }></NavLink>

//         }
//         action={
//           <IconButton aria-label="settings">
//             <MoreVertIcon />
//           </IconButton>
//         }
//         title= {matchedPrimary?.name}
//         subheader={matchedPrimary?.breed}
//       />
//        <CardContent>
//          { matchedPrimary &&    <Grid container spacing={3}>


//           <Grid item xs={6}>
//             <UserDetailCard { ...{matchedPrimary, transactionFunctions, searchParams, history }}></UserDetailCard>
//            </Grid>
//            <Grid item xs={6}>
//             <AddressCard { ...{matchedPrimary, transactionFunctions, searchParams, history }}></AddressCard>
//            </Grid>
//            <Grid item xs={6}>
//              <UserAlertControlCard {...{viewObject, transactionFunctions, userFunctions, searchParams, history }} ></UserAlertControlCard>
//            </Grid>

//            <Grid item xs={6}>
//              <ObjectListCard {...{viewObject, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: groupsAdjacentFilter, listConfigParams: groupsListConfigParams }} ></ObjectListCard>
//            </Grid>
//            <Grid item xs={6}>
//              <ObjectListCard {...{viewObject, transactionFunctions, userFunctions, searchParams, history, adjacentFilter: tankAdjacentFilter, listConfigParams: tankListConfigParams }} ></ObjectListCard>
//            </Grid>
//            <Grid item xs={6}>

//            </Grid>
//            <Grid item xs={6}>
//              <MapCard { ...{position: getUserGeolocation(), locatableAddress }} ></MapCard>
//         </Grid>
//          </Grid>
//          }



//         <Typography variant="body2" color="textSecondary" component="p">

//          {matchedPrimary?.description}
//         </Typography>
//       </CardContent>
//       <CardActions disableSpacing>
//         <IconButton aria-label="add to favorites">
//           <FavoriteIcon />
//         </IconButton>
//         <IconButton aria-label="share">
//           <ShareIcon />
//         </IconButton>
//         <IconButton
//           className={clsx(classes.expand, {
//             [classes.expandOpen]: expanded,
//           })}
//           onClick={handleExpandClick}
//           aria-expanded={expanded}
//           aria-label="show more"
//         >
//           <ExpandMoreIcon />
//         </IconButton>
//       </CardActions>
//       <Collapse in={expanded} timeout="auto" unmountOnExit>
//         <CardContent>
//         <Grid container>
//           <Grid item xs={6}>
//         <Card className = {classes.subCard} >{ viewObject.matchedRelatedByPk && viewObject.matchedRelatedByPk.map( (object) =>  <SimpleObjectLink {...{ object, adjacentType: AdjacentType.PARENT}}></SimpleObjectLink> ) } </Card>
//           </Grid>
//           <Grid item xs={6}>
//            <Card className ={classes.subCard} >{ viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.map( (object) =>  <SimpleObjectLink {...{ object, adjacentType: AdjacentType.CHILD}}></SimpleObjectLink> ) } </Card>
//           </Grid>
//          </Grid>
//         </CardContent>
//       </Collapse>
//     </Card>
//   );
// }

import { Box, Button, Divider, FormControl, Grid, List, ListItem, ListItemText, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import styles from '../../../cosmetics/sharedCardStyles';
import { getLocatableAddress } from '../../../data/TypeHelpers';

import { UserCardProps } from '../../../types/AppTypes';
import AddressCard from './AddressCard';
const debug = process.env.REACT_APP_DEBUG && false;

// const useStyles = makeStyles((theme) => styles(theme));
const useStyles = makeStyles((theme) => {
  return {
    ...styles(theme),
    root: {
      marginTop: 10
    },
    cardStyle: {
      marginTop: 10
    },
    actionHeader: {
      paddingTop: 2,
      paddingRight: 5,
      paddingBottom: 0
    }
  }
});
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

function generateTankUserList(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
// export default function UserCard(props: UserCardProps) {
export function UserCard(props: UserCardProps) {
  // debug && console.log('User Card has', props)
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [editIconColor, setEditIconColor] = React.useState('grey')
  const [editUser, setEditUser] = React.useState(false)
  const [communityShareSelector, setCommunityShareSelector] = React.useState(true)
  const [nationalShareSelector, setNationalShareSelector] = React.useState(true)

  const phaseOneShow = false
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };



  const { matchedPrimary, matchedRelatedByPk, matchedRelatedBySk } = props.viewObject
  const { transactionFunctions, userFunctions, searchParams, viewObject, history, userGeolocation } = props;

  // debug && 
  console.log('User Card matchedPrimary', matchedPrimary)

  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);



  const hasAlert = () => true;
  const alerts = [];

  // const getUserGeolocation = () => {
  //   let position = undefined;
  //   if (userGeolocation) {

  //     let x = {
  //       lat: userGeolocation.latitude,
  //       lng: userGeolocation.longitude
  //     }
  //     if (isGeofencePointsType(x)) position = x;
  //   }
  //   return position;
  // }

  // const tankAdjacentFilter: AdjacentFilter = {
  //   adjacentType: AdjacentType.CHILD,
  //   edgeFilter: undefined,
  //   objectType: 'Tank' 
  // }
  // const tankListConfigParams: ListConfigParams = {
  //   label: 'My aaTTTTTTaTanks'
  // }

  // const geofenceAdjacentFilter: AdjacentFilter = {
  //   adjacentType: AdjacentType.CHILD,
  //   edgeFilter: undefined,
  //   objectType: 'Geofence'

  // }
  // const geofenceListConfigParams: ListConfigParams = {
  //   label: 'My Fields'
  // }

  // const farmAdjacentFilter: AdjacentFilter = {
  //   adjacentType: AdjacentType.CHILD,
  //   edgeFilter: undefined,
  //   objectType: 'Farm'

  // }
  // const farmListConfigParams: ListConfigParams = {
  //   label: 'My Farms'
  // }

  const handleEditUser = () => {
    // setEditIconColor(editUser?'green')
    setEditIconColor(editIconColor == 'green' ? 'grey' : 'green')
    setEditUser(!editUser)
  }
  const handleCommunityShareSelector = () => {
    setCommunityShareSelector(!communityShareSelector)
  }
  const handleNationalShareSelector = () => {
    setNationalShareSelector(!nationalShareSelector)
  }

  return (<div>
    <Card className={classes.root}>
      <Box display="flex"
        // justifyContent="center"
        m={1}
        p={1}
        bgcolor="background.paper">
        <Avatar   >
          <AccountCircleSharpIcon />
        </Avatar>
        <Typography variant="subtitle1" >
          {viewObject.matchedPrimary?.name}
        </Typography>
      </Box>
    </Card>
    <Card className={classes.root}>
      <CardHeader className={classes.actionHeader}
        action={
          <IconButton aria-label="settings">
            <EditIcon fontSize='small' style={{ color: editIconColor }}
              onClick={() => handleEditUser()}
            />
          </IconButton>
        }
      />
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl style={{ minWidth: '100%' }}>

              <TextField
                id="standard-read-only-input"
                label="Name:"
                defaultValue="  "
                value={matchedPrimary?.name}
                InputProps={{
                  readOnly: true,
                }}
              /></FormControl>



          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <TextField
                id="standard-read-only-input"
                label="Username:"
                disabled = {true}
                defaultValue=" "
                value={matchedPrimary?.username}
                InputProps={{
                  readOnly: true,
                }}
              /></FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <TextField
                id="standard-read-only-input"
                label="Email:"
                value={matchedPrimary?.email}
                defaultValue="  "
                InputProps={{
                  readOnly: true,
                }}
              /></FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <TextField
                id="standard-read-only-input"
                label="Contact Number"
                defaultValue="  "//{matchedPrimary?.phone_number}
                value={matchedPrimary?.phone_number}
              // InputProps={{
              //   readOnly: true,
              // }}
              /></FormControl>
          </Grid>

          {editUser &&
            <React.Fragment  >
              <Grid item xs={12}>
                <FormControl style={{ minWidth: '100%' }}>
                  <TextField
                    id="standard-multiline-static"
                    label="Address"
                    multiline
                    rows={5}
                    defaultValue="Default Value"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  fullWidth={true}
                  // startIcon={<DeleteIcon />}
                  onClick={() => {
                    handleEditUser()
                  }}
                >
                  Cancel
      </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  fullWidth={true}
                // onClick={() => {
                //   props.closeTankEdit()
                // }}
                // onC
                // startIcon={<DeleteIcon />}
                >
                  Save
      </Button>
              </Grid>

            </React.Fragment>
          }

        </Grid>
      </Box>
    </Card>


    {matchedPrimary &&
      <Card className={classes.root}><Box display="flex"
        // justifyContent="center"
        m={1}
        p={1}
        bgcolor="background.paper">
        <AddressCard {...{ matchedPrimary, transactionFunctions, searchParams, history }}></AddressCard>
      </Box>
      </Card>
    }

    <Card className={classes.root}>
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Grid container spacing={3}>
          <Grid item xs={1} alignItems={'center'}>
            <AntSwitch checked={communityShareSelector} onChange={handleCommunityShareSelector} name="checkedC" />
          </Grid>
          <Grid item xs={11} alignItems={'center'}>
            <Typography align={'center'}>Share data with Community Water Management Committee</Typography>
          </Grid>
        </Grid>   </Box>
      <Divider />
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Grid container spacing={3}>
          <Grid item xs={1} alignItems={'center'}>
            <AntSwitch checked={nationalShareSelector} onChange={handleNationalShareSelector} name="checkedC" />
          </Grid>
          <Grid item xs={11} alignItems={'center'}>
            <Typography align={'center'}>Share data with National Agencies</Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>

    {phaseOneShow &&
      <Card className={classes.root}>
        <Box display="flex" m={1} p={1} bgcolor="background.paper">
          <Grid container spacing={2}>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title}>
                Tank Users
          </Typography>
              <List
              // dense={dense}
              >
                {generateTankUserList(
                  <ListItem>
                    <ListItemText
                      primary="Tank User Name"
                    // secondary={secondary ? 'Secondary text' : null}
                    /> <CancelIcon />
                  </ListItem>,
                )}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Card>

    }

  </div>
  );
}