import { connect } from 'react-redux'
import DeviceProvisioningView from '../components/DeviceProvisioningView'
import { RootState } from '../reducers'
import { AnyAction } from 'redux';
import { bindActionCreators } from 'redux'
import { UserFunctions, TransactionFunctions } from '../actions/AppActions'
import { ThunkDispatch } from 'redux-thunk';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { constructItem } from '../data/daoFunctions/stateFns';
import { ValidBusinessObject } from '@iotv/datamodel';
import { UserGroupsRolesType, UserRolesType } from '../types/AppTypes';
const debug = process.env.REACT_APP_DEBUG && false;



const mapStateToProps = (state: RootState, ownProps: any) => {
  debug && console.log('map state to props', state)
  
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    user: state.AppReducer.user,
    viewObject: constructItem(state.AppReducer.normalData, state.AppReducer.user?.sk),
    messages: state.AppReducer.messages,
    userGeolocation: state.AppReducer.userGeolocation,
    useSpecializedViews: state.AppReducer.useSpecializedViews,
    contextCustomer: state.AppReducer.contextCustomer as ValidBusinessObject | undefined
    
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
		   debug && console.log('UserView requested init', ownProps)
    },
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceProvisioningView)