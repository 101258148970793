import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container : {
        padding : '20px'
    },

    button : {
        minWidth : '200px',
        minHeight: '30px',
    },

    header : {
        textAlign : 'center',
        verticalAlign : 'center',
        padding : '15px'
    },
    smallPadding : {
        padding : '2%',
    },
    border : {
        border : '1px solid black'
    },
    topOnlyBorder : {
        borderTop : '1px solid black'
    },
    topRightBorder : {
        borderTop : '1px solid black',
        borderRight : '1px solid black'
    },
    formBorder : {
        border : '1px solid black'
    },
    card : {
        height : 'inherit',
        margin : '5px',
        textAlign : 'center',
        minHeight : '100px',
        padding : '7px'
        // boxShadow : '2px 3px grey'
    },
    half : {
        height : '100%'
    },
    fullWidth : {
        width : '100%'
    },
    whole : {
        display: "flex", flexDirection: "column",
        // border : "1px solid red"
    },
    background : {
        backgroundColor: '#2c9be5',
        color : 'white',
        paddingLeft : '10px',
        // borderTop : '1px solid black',
        borderBottom : '1px solid black',
        width : '100%'
    },
    name : {
        width : '35%',
    },
    padding : {
        margin : '10px',
        width : '50px'
    },
    mapC021TankGroupCard: {
        width: '95%',
        height: '95%',
        border: '1px solid black',
        borderRadius: '10px',
        overflow : 'hidden',
        margin : 'auto',
        minHeight : '250px'
    },
    bottomButtons : {
        width : '100%', 
        minHeight : '60px',
        margin : 'auto'
    },
    weatherForecast : {
        width : '100%',
        margin : 'auto',
        overflowX : 'auto',
        justifyContent : 'center',
        paddingTop : '10px'
        
    },
    weatherIcon : {
        paddingBottom : '5px',
        width : '13%',
        // minWidth : '80px',
    },
    droughtManagmentPlanCard : {
        margin : 'auto',
        width : '100%',
        padding: '20px'
    },
    buttonContainer : {
        margin : 'auto',
        paddingBottom : '20px',
        paddingTop : '20px'
    },
    communityGraph : {
        minHeight : '350px'
    },
    planButtonContainer : {
        // display : 'flex',
        // float : 'right',
        // justify-content: space-between
        // border : '1px solid red'
        padding : '15px'
    },
    gaugeContainer : {
        padding : '2%',
        magin : 'auto',
        width : '100%'
    },
}))