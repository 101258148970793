import Assembler from '../../../data/Assembler';
import { ValidBusinessObject } from '@iotv/datamodel';

const defaultPrefix = 'tank_level_sensor_';

export const deviceIds = [
    '70B3D516B000025C',
'70B3D516B000025D',
'70B3D516B000025E',
'70B3D516B000025F',
'70B3D516B0000260',
'70B3D516B0000261',
'70B3D516B0000262',
'70B3D516B0000263',
'70B3D516B0000264',
'70B3D516B0000265',
'70B3D516B0000266',
'70B3D516B0000267',
'70B3D516B0000268',
'70B3D516B0000269',
'70B3D516B000026A',
'70B3D516B000026B',
'70B3D516B000026C',
'70B3D516B000026D',
'70B3D516B000026E',
'70B3D516B000026F',
'70B3D516B0000270',
'70B3D516B0000271',
'70B3D516B0000272',
'70B3D516B0000273',
'70B3D516B0000274',
'70B3D516B0000275',
'70B3D516B0000276',
'70B3D516B0000277',
'70B3D516B0000278',
'70B3D516B0000279',
'70B3D516B000027A',
'70B3D516B000027B',
'70B3D516B000027C',
'70B3D516B000027D',
'70B3D516B000027E',
'70B3D516B000027F',
'70B3D516B0000280',
'70B3D516B0000281',
'70B3D516B0000282',
'70B3D516B0000283',
]

export const isValidDeviceId = (deviceId: string, prefix: string) => deviceIds.includes(deviceId.replace(prefix, ''))


export const getDeviceObject = (deviceId: string, prefix: string =  defaultPrefix) => {
    let res: ValidBusinessObject | undefined = undefined
    if (isValidDeviceId(deviceId, prefix)) {
        res = Assembler.getInstance({ type: 'Device', id: deviceId});
    }
    return res;
}

export const getRandomDeviceId = () => {
    const maxIdx = deviceIds.length -1;
    return `${defaultPrefix}${deviceIds[Math.floor(Math.random() * maxIdx)]}`;
}