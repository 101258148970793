
import { ValidBusinessObject, getNiceDate } from "@iotv/datamodel-core";
import { StateSnapshot } from "@iotv/iotv-v3-types";

export type MetricItemsToVOBsRequest = {
    psuedoParentVob?: ValidBusinessObject,
    pseudoTypeId: string,
    stateSnapshots: StateSnapshot<any>[]
}

export const metricItemsToVOBs = ( { psuedoParentVob, pseudoTypeId, stateSnapshots}: MetricItemsToVOBsRequest ): ValidBusinessObject[] => stateSnapshots.map((stateSnapshot, i) => {
    const { state: { ...atts }, timestamp } = stateSnapshot

    const id = `${psuedoParentVob?.id ?? 'unknownId'}_${i}_${timestamp}`;
    const pk = psuedoParentVob?.sk ?? 'unknownParentSk';
    const sk = `${psuedoParentVob}:${id}`
    const type = pseudoTypeId
    const intlnz = new Intl.DateTimeFormat( 'en-NZ', { 
        year: 'numeric',
        weekday: 'short', day: 'numeric', month: 'short', timeZone: 'NZ', hour: 'numeric', minute: 'numeric'

   })

   const nzts = timestamp ? intlnz.format( new Date( timestamp )) : undefined

    return {
        type, sk, pk, id, ...atts, timestamp: nzts
    } as ValidBusinessObject
})