
import { getObjectHash, MetricRuleType } from '@iotv/datamodel';
import { ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes, MapLike } from '@iotv/iotv-v3-types';
import { Card, CardContent, CardHeader, Grid, IconButton, makeStyles } from '@material-ui/core';
import { DeleteOutlineOutlined } from '@material-ui/icons'
import React, { useEffect } from 'react';
import { getPseudoVBO } from 'src/data/TypeHelpers';
import config from '../../config';
import styles from '../../cosmetics/sharedCardStyles';
import { getPrimaryItemTypes } from '../../data/AppDao';
import { ComponentMode, ObjectCardProps, TabilizerProps, UserTransactionType, ViewDefinition, ViewKeyDefinitionType } from '../../types/AppTypes';
import { Tabilizer } from '../factories/Tabilizer';

const debug = process.env.REACT_APP_DEBUG && false
const effectDebug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));



export type RulesetMetricRuleCardProps = ObjectCardProps & {
    metricKeysEnum: MapLike<string>
    metricRule: MetricRuleType & ValidBusinessObject
    setArrayObjectNInParent: ( vob: ValidBusinessObject ) => void
    removeArrayObjectNInParent: () => void
}


export const RulesetMeticRuleCard = (props: RulesetMetricRuleCardProps) => {
    const classes = useStyles();
    const appDeviceEnabledTypes = getPrimaryItemTypes(config.app.appCode)
    const { viewObject: { matchedPrimary }, contextCustomer, setArrayObjectNInParent, removeArrayObjectNInParent} = props

    debug && console.log('appDeviceEnabledTypes', appDeviceEnabledTypes)

    const numericMetricViewDefinition: ViewDefinition = {
        value: { key: 'value', label: 'Value', editable: true, precision: 3, stateFn: undefined, type: AppValueTypes.NUMBER, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
        , datapoints: { key: 'datapoints', label: 'Datapoints', editable: true, precision: 3, stateFn: undefined, type: AppValueTypes.NUMBER, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }

    }

    const changeHash = getObjectHash(props.metricRule)

    useEffect( () => {
        effectDebug && console.log('RulesetMeticRuleCard used effect on', changeHash)
    }, [ changeHash ])

    const stateFn = ( x: any ) => console.log('X is', x) 

    const getMetricRuleEditor = ({metricRule, metricKeysEnum}: RulesetMetricRuleCardProps) => {

        const metricRuleEditorProps: TabilizerProps = {
            ...props,
            transactionFunctions: {
                ...props.transactionFunctions,

            },
            actions: [UserTransactionType.UPDATE],
            functionOverrides: {
                mode: ComponentMode.EDIT,
                updateParentComponentItem:  ( metricRule: MetricRuleType & ValidBusinessObject ) => {
                    debug && console.log('updateParentComponentItem was called in getMetricRuleEditor', metricRule)
                    setArrayObjectNInParent(metricRule) },
                hasValidationErrors: () => { }
            },
            injectedComponents: [],

            matchedPrimary: metricRule,
            viewDefinition: {
                name: { key: 'name', label: 'Name', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.STRING, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , enabled: { key: 'enabled', label: 'Enabled', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.BOOLEAN, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , key: { key: 'key', label: 'Metric Key', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.ENUM, unit: undefined, validationRx: undefined, enumKV: metricKeysEnum, failsValidationText: undefined }
                , max: { key: 'max', label: 'Maximum', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.OBJECT, embeddedObjectViewDefinition: numericMetricViewDefinition, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , min: { key: 'min', label: 'Minimum', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.OBJECT, embeddedObjectViewDefinition: numericMetricViewDefinition, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }
                , stdDev: { key: 'stdDev', label: 'SD', editable: true, precision: undefined, stateFn: undefined, type: AppValueTypes.OBJECT, embeddedObjectViewDefinition: numericMetricViewDefinition, unit: undefined, validationRx: undefined, enumKV: undefined, failsValidationText: undefined }


            } as ViewDefinition | { [k in keyof MetricRuleType]: ViewKeyDefinitionType }
        }
        return <Tabilizer key={'metricRuleTabilizer'} {...{ ...metricRuleEditorProps, }}></Tabilizer>
    }




    return <Card className={classes.root}>
        <CardHeader>

        </CardHeader>
        <CardContent>

                <Grid item xs={12} lg={6}>
                    {getMetricRuleEditor( { ...props })}
                </Grid>
                <Grid>
                    <IconButton {
                        ...{

                            onClick: removeArrayObjectNInParent
                        }
                    }> <DeleteOutlineOutlined/></IconButton>
                </Grid>
        </CardContent>           
    </Card>

}