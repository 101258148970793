import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import config from './config';
import Root from './containers/Root';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './stores/MainStore';




console.log('ENVS', process.env)

Amplify.configure({
  Auth: {
      mandatorySignIn: false,
      region: config.aws.cognito.REGION,
      userPoolId: config.aws.cognito.USER_POOL_ID,
      identityPoolId: config.aws.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.aws.cognito.APP_CLIENT_ID
  },
  API: {
      endpoints: [
          {
              name: config.app.appName,
              endpoint: `https://${config.aws.apiGateway.userGatewayUrl}`,
              region: config.aws.default_region,
              /*
              custom_header: async () => { 
                //https://docs.amplify.aws/lib/restapi/authz/q/platform/js#request-headers
                //return { Authorization : 'token' } 
                // Alternatively, with Cognito User Pools use this:
                //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
              return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
              }
              */
       
          },
          {
            name: 'NWGateway',
            endpoint: `https://${config.aws.apiGateway.networkGatewayUrl}`,
            region: config.aws.default_region,
            /*
            custom_header: async () => { 
              //https://docs.amplify.aws/lib/restapi/authz/q/platform/js#request-headers
              //return { Authorization : 'token' } 
              // Alternatively, with Cognito User Pools use this:
              //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
            */
          },
          {
            name: 'SystemManagement',
            endpoint: `https://${config.aws.apiGateway.systemManagementUrl}`,
            region: config.aws.default_region
          },
      ]
  },
  Storage: {
      AWSS3: {
          bucket: ( config.aws.s3.userBlobs as { Name?: string} ).Name,
          region: config.aws.default_region
      }
  }

});

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: config.aws.default_region,
  //'wss://a3l9r1jc6h0qk1-ats.iot.ap-southeast-2.amazonaws.com/mqtt',
  aws_pubsub_endpoint: `wss://${config.aws.iot.endpointAddress}/mqtt` 
}));

Amplify.Logger.LOG_LEVEL = config.aws.amplify.logLevel;

//STRCT MODE WILL DOUBLE RENDER WHEN IN DEVELOPMENT : https://medium.com/@andreasheissenberger/react-components-render-twice-any-way-to-fix-this-91cf23961625
// SUppressed as confusing logging and debug 
// ReactDOM.render(
//   <React.StrictMode>
//       <Root store={store} />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
 
      <Root store={store} />,
 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
