import { CustomerAccountType, DatastoreObjectType, NodeEdgeMapper, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@mui/icons-material';
import { Box, Card, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppDao from '../../../../data/AppDao';
import { AdjacentType, ObjectCardProps } from '../../../../types/AppTypes';
import { getAssignableCustomers } from '../CountryCommunity/DroughtManagement/DroughtManagementPlanHelpers';
import { Invalid } from '../Message/error';
import styles from './RecipientCard.module.css';
import RecipientModal, { recipientObject } from './RecipientModal';
const debug = false;

const filterByType =
  (type: string) =>
  (vob: DatastoreObjectType): vob is ValidBusinessObject =>
    (vob as ValidBusinessObject).type === type;

export function RecipientCard(props: ObjectCardProps) {
  const { transactionFunctions, userFunctions, searchParams, viewObject, history, contextCustomer, userGeolocation } = props;

  let [valid, setValid] = useState<boolean>(false);

  let [addRecipient, setAddRecipient] = useState<boolean>(false);
  let [customerGroups, setCustomerGroups] = useState<ValidBusinessObjectList>();
  let [selectedCustomer, setSelectedCustomer] = useState<ValidBusinessObject>();
  let [nodeEdgeMapper, setNodeEdgeMapper] = useState<NodeEdgeMapper>();
  let [recipientLists, setRecipientLists] = useState<ValidBusinessObjectList>();
  let [editParent, setEditParent] = useState<ValidBusinessObject>();
  let [existing, setExisting] = useState<ValidBusinessObject>();

  useEffect(() => {
    if (contextCustomer) {
      let type = contextCustomer.accountType;
      if (type === CustomerAccountType.V || type === CustomerAccountType.X) {
        getCustomers(contextCustomer);
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }
  }, [contextCustomer]);

  useEffect(() => {
    if (customerGroups) {
      updateRecipiantList(customerGroups);
    }
  }, [customerGroups]);

  useEffect(() => {
    if (nodeEdgeMapper && customerGroups) {
      let recip = nodeEdgeMapper.getContainedItems().filter(filterByType('Recipient'));
      setRecipientLists(recip);
    }
  }, [nodeEdgeMapper, customerGroups]);

  const updateRecipiantList = async (customerGroups: ValidBusinessObjectList) => {
    const res = await AppDao.queryByGraph({
      contextObjects: customerGroups,
      path: [{ objectTypeId: 'Recipient', adjacencyType: AdjacentType.CHILD }],
    });
    if (res.err === null && res.data !== null) {
      setNodeEdgeMapper(res.data);
    }
  };

  const setCustomer = (id: string) => {
    if (customerGroups) {
      setSelectedCustomer(customerGroups.find((customer) => customer.id === id));
    }
  };

  const getCustomers = async (context: ValidBusinessObject) => {
    setCustomerGroups(await getAssignableCustomers(context));
  };

  const editRecipient = (parent: ValidBusinessObject, recipient: ValidBusinessObject) => {
    setEditParent(parent);
    setExisting(recipient);
  };

  const addModal = useCallback(() => {
    setAddRecipient(true);
  }, []);

  const closeModal = useCallback(() => {
    setAddRecipient(false);
    setEditParent(undefined);
    setExisting(undefined);
  }, []);

  const updateList = () => {
    customerGroups && updateRecipiantList(customerGroups);
    closeModal();
  };

  if (valid) {
    return (
      <>
        <div>
          <div className={styles.full}>
            <Card>
              <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
                <Box m={2}>
                  <Typography variant='h6' component='h6'>
                    Recipient Card
                  </Typography>
                </Box>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <Box m={2}>
                    <TextField
                      label={customerGroups === undefined ? 'Loading' : 'Select Recipient Target'}
                      disabled={customerGroups === undefined}
                      select
                      variant='outlined'
                      fullWidth
                      style={{ width: '300px' }}
                      onChange={(e) => setCustomer(e.target.value)}
                      value={selectedCustomer}
                      size='small'
                    >
                      {customerGroups?.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                  <Box m={2}>
                    <Button variant='contained' disabled={selectedCustomer === undefined} onClick={addModal}>
                      <Add /> Recipient
                    </Button>
                  </Box>
                </Stack>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        <b>Name</b>
                      </TableCell>
                      <TableCell align='center'>
                        <b>Email</b>
                      </TableCell>
                      <TableCell align='center'>
                        <b>Phone Number</b>
                      </TableCell>
                      <TableCell align='center'>
                        <b>Organization</b>
                      </TableCell>
                      <TableCell style={{ width: '150px' }} align='center'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recipientLists &&
                      nodeEdgeMapper !== undefined &&
                      recipientLists.map((contacts) => {
                        let parent =
                          customerGroups && customerGroups.length === 1
                            ? customerGroups[0]
                            : (nodeEdgeMapper?.getParentNodes(contacts)[0] as ValidBusinessObject);
                        return (
                          <TableRow>
                            <TableCell align='center'>{contacts.name}</TableCell>
                            <TableCell align='center'>{contacts.email}</TableCell>
                            <TableCell align='center'>{contacts.phoneNumber}</TableCell>
                            <TableCell align='center'>{parent.name}</TableCell>
                            <TableCell align='center'>
                              <Button variant='contained' onClick={() => editRecipient(parent, contacts)}>
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Stack>
            </Card>
          </div>
        </div>
        {addRecipient && selectedCustomer && <RecipientModal close={closeModal} parent={selectedCustomer} update={updateList} />}
        {editParent && existing && <RecipientModal close={closeModal} parent={editParent} update={updateList} existing={existing} />}
      </>
    );
  } else {
    return <Invalid />;
  }
}
