import { ValidModelObject } from '@iotv/datamodel-core';
import { ErrData, PathDirection, QueryTypes, TraverserQueryPathBody, TypeHierarchyRequest, TypeHierarchyResponse, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { NormalizedData } from '../../types/AppTypes';
import ApiGateway from "../../data/aws/api-gateway/ApiGateway";
import { QueryOutput } from 'aws-sdk/clients/dynamodb';
import { getParents } from '../..//data/daoFunctions/daoFunctions';
import { addToNormalData } from '../../data/daoFunctions/stateFns';

const debug = process.env.REACT_APP_DEBUG && false;

/**
 * Fetches the communities related to the customer object
 * @param contextCustomer ValidBusinessObject for the current customer
 * @returns 
 */

 export const getCustomerHierarchy = async (contextCustomer : ValidModelObject<'Customer'> | undefined) => {
    let res: ErrData<TypeHierarchyResponse> = { err: null, data: null}
    if ( contextCustomer ) {    
        const queryBody: TypeHierarchyRequest = {
            apexNode: contextCustomer ,
            edgeParams: {
                objectTypeId: 'Customer'
            },
            maxIterations: 10
        };

        const path = '/typeHierarchy/';
        res = await ApiGateway.post(path, queryBody);
    }

    return res;
}

/**
 * Function to retrieve system management users administerable customers without needing them to be direct user 
 * 
 * @param user 
 * @param normalizedData 
 * @returns 
 */

export const systemManagmentUserCustomersQuery = async function (user: ValidBusinessObject, normalizedData: NormalizedData) {

    if (user) {
        const parentsRes = await getParents(user, 'Customer') as unknown as ErrData<QueryOutput>

        if (parentsRes.data) {
            const parentCustomers = (parentsRes.data.Items ?? []) as ValidBusinessObjectList
            debug && console.log(`systemManagmentUserCustomersQuery got parents for user ${user.name}`, parentCustomers.map( (c) => c.name ) )

            const simpleTypePath = [
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child }
            ]
    
            const queryBody: TraverserQueryPathBody = {
                type: QueryTypes.directedPath,
                simpleTypePath: simpleTypePath,
                normalize: false,

    
                items: parentCustomers 
            };
    
            const path = '/query/';
             const administerableCustomersRes: ErrData<ValidBusinessObjectList> = await ApiGateway.post(path, queryBody);
            if ( administerableCustomersRes.data ) {
                const administerableCustomers = administerableCustomersRes.data.map( ( c ) => {
                    const { edgeTypeId, edgeAttributes, ...others } = c
                    return { ...others, /* pk: others.sk */}
                })
                debug && console.log(`systemManagmentUserCustomersQuery got administerableCustomers for user ${user.name}`, administerableCustomers.map( (c) => (c as ValidBusinessObject).name ) )
                administerableCustomers.forEach( (c) => addToNormalData( c, normalizedData) )
            } else {
                console.log(`systemManagmentUserCustomersQuery for administerable customers got Error for  user ${user.name}`, administerableCustomersRes.err?.message )
            }
        } else {
            console.log(`systemManagmentUserCustomersQuery for parent customers got Error for user ${user.name}`, parentsRes.err?.message )

        }
        }

    

    return normalizedData;
}

/**
 * @function userDirectCustomersQuery gets users directly connected customers. This was the original query to get contextCustomers
 * @param user 
 * @param normalizedData 
 * @returns 
 */
export const userDirectCustomersQuery = async function (user: ValidBusinessObject, normalizedData: NormalizedData)  {
       const customers = await getParents(user, 'Customer') 
       debug && console.log('CUSTOMERS', customers)
      customers.data?.Items?.forEach( (customer) => {
        debug && console.log('Adding customer to state', customer)
        addToNormalData(customer as ValidBusinessObject, normalizedData)

        debug && console.log('Confirm put', (normalizedData && normalizedData[user.sk][customer.pk as string]))
      })
      
    return normalizedData;

}