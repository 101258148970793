import { getObjectHash, ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, Switch, TableCell, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import { ComponentMode, ViewKeyDefinitionType } from '../../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false; 

const useStyles = makeStyles((theme) => (styles(theme)));

type TabilizerRowProps = {
    keyToUse: string | number,
    matchedPrimary: ValidBusinessObject,
    viewKeyDefinition: ViewKeyDefinitionType,
    mode: ComponentMode,
    updateKV:  (event: React.ChangeEvent<HTMLInputElement> , k: string ) => void
}

export function TableRowizerTableCell(props: TabilizerRowProps) {
    debug && console.log('TableRowizerTableCell props:',props)
    const classes = useStyles();

    const getEnumComponent = ( matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode ) => {
        let element = null;
        if (viewKeyDefinition.enumKV) {
            if ( mode === ComponentMode.EDIT) {
                let accessKey = `${matchedPrimary.sk}:${viewKeyDefinition.key}`;
                element =  <FormControl className = {classes.editFormControlFullWidth}>
                <InputLabel id={accessKey}>{ viewKeyDefinition.label }</InputLabel>
                <Select
                    labelId={ `${accessKey}_label` }
                    id={ `${accessKey}_select` }
                    value={matchedPrimary[viewKeyDefinition.key]}
                    onChange={ (event) => updateKV( event as React.ChangeEvent<HTMLInputElement>,  viewKeyDefinition.key) } 
                >
                    { Object.entries(viewKeyDefinition.enumKV).map( ([k, v], i) => {
                        return <MenuItem key = {`item_${i}`} value={k}>{v}</MenuItem>;
                    })}
                </Select>
            </FormControl>
            } else if ( mode === ComponentMode.VIEW || mode === ComponentMode.READONLY) {
                return viewKeyDefinition.enumKV[matchedPrimary[viewKeyDefinition.key]]
            }
            
        };
        return element
    }

    const getDateComponent = ( matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode ) => {
        let element: JSX.Element | null | string = null;
        let timeMultiplier = 1;
        let value = matchedPrimary[viewKeyDefinition.key] 
        if ( value ) {
            debug && console.log('converting Date value:', {DateType: viewKeyDefinition.type, value, timeMultiplier})
            if (viewKeyDefinition.type === AppValueTypes.TIMESTAMP_MS) {
                // stay at 1
            } else if (viewKeyDefinition.type === AppValueTypes.TIMESTAMP_SEC ) {
                timeMultiplier = 1000;
            }
            else if (viewKeyDefinition.type === AppValueTypes.TIMESTAMP_STRING_SEC ) {
                value = parseInt(value);
                timeMultiplier = 1000;
            }
           
            const displayValue = value * timeMultiplier
          
            if ( mode === ComponentMode.EDIT ) {
                element = <div>not impleemented</div>
            } else if ( mode === ComponentMode.VIEW || mode === ComponentMode.READONLY) {
                const displayDate = new Date(displayValue);
                const nowString = new Date().toLocaleDateString(config.locale);
                const displayDateStr = displayDate.toLocaleDateString(config.locale);
                const actualDisplayDateStr = (nowString === displayDateStr) ? 'Today' : displayDateStr;
                element = actualDisplayDateStr + ' ' + displayDate.toLocaleTimeString(config.locale) 
            }
        }
      
        return element

    }

    const getStringComponent = ( matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode ) => {
        let valueOut = matchedPrimary[viewKeyDefinition.key];
        if (viewKeyDefinition.precision && typeof valueOut === 'string' ) {
            valueOut = valueOut.substr(0, viewKeyDefinition.precision)
        }
        return valueOut
    }

    const getNumberComponent = ( matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode ) => {
        let valueOut = matchedPrimary[viewKeyDefinition.key];
        if (viewKeyDefinition.precision && typeof valueOut === 'number' ) {
            const p = Math.pow( 10, viewKeyDefinition.precision );
            valueOut = Math.round( valueOut * p ) / p;
        }
        return valueOut
    }


    const getBooleanComponent = ( matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode ) => {
        let element = null;
        if (viewKeyDefinition.type === AppValueTypes.BOOLEAN) {
            const value = matchedPrimary[viewKeyDefinition.key] ?  matchedPrimary[viewKeyDefinition.key] : false;
            if ( mode === ComponentMode.EDIT) {
                element =  <FormControl className = {classes.editFormControlFullWidth}>
                <FormControlLabel
                    label = { viewKeyDefinition.label }
                     control={
                    <Switch
                        checked={ value }
                        onChange={ (event) => updateKV( event as React.ChangeEvent<HTMLInputElement>,  viewKeyDefinition.key)}
                        name={ viewKeyDefinition.label }
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                     /> }
                ></FormControlLabel>

            </FormControl>
            } else if ( mode === ComponentMode.VIEW || mode === ComponentMode.READONLY) {
                return matchedPrimary[viewKeyDefinition.key] !== undefined ?  matchedPrimary[viewKeyDefinition.key].toString() : 'Not set';
            }
            
        };
        return element
    }

    const switchOnAttTypeEdit = (matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
         debug && console.log('Swith on Edit', mode )
        switch(viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return getEnumComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.BOOLEAN: return getBooleanComponent(matchedPrimary, viewKeyDefinition, mode);
            default: {
                const regex = new RegExp(viewKeyDefinition?.validationRx || /\*/ );
               return <FormControl className = {classes.editFormControlFullWidth}>
                    <TextField
                        size={'small'}
                        margin={'dense'}
                        type= { viewKeyDefinition.type } value= { matchedPrimary[viewKeyDefinition.key] }
                        onChange={ (event) => {
                            updateKV( event as React.ChangeEvent<HTMLInputElement>,  viewKeyDefinition.key);
                        } }
                        label = { viewKeyDefinition.label }
                        error = { undefined }
                        helperText= { undefined}
                        variant="standard"
                        disabled =  { viewKeyDefinition.editable === false }
                    ></TextField> 
                    
                </FormControl>
            } 
        }
    }

    const switchOnAttTypeView = (matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        debug && console.log('Switch on Att Type', {viewKeyDefinition})
        switch(viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return getEnumComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.BOOLEAN: return getBooleanComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.TIMESTAMP_MS: return getDateComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.TIMESTAMP_SEC: return getDateComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.TIMESTAMP_STRING_SEC: return getDateComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.STRING: return getStringComponent(matchedPrimary, viewKeyDefinition, mode);
            case AppValueTypes.NUMBER: return getNumberComponent(matchedPrimary, viewKeyDefinition, mode);
            default: return matchedPrimary[viewKeyDefinition.key]
        }
    }
    
    const switchCellAlignment = ( viewKeyDefinition: ViewKeyDefinitionType ) => {
        switch (viewKeyDefinition.type ) {
            case AppValueTypes.NUMBER: return 'right';
            default: return 'left';
        }
    }

    const switchOnMode = (matchedPrimary: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        switch(mode) {
            case ComponentMode.VIEW: return <TableCell key={matchedPrimary.id + keyToUse} className={classes.messageCell} style={{
                whiteSpace: "nowrap", alignContent: switchCellAlignment(viewKeyDefinition) }} > {switchOnAttTypeView(matchedPrimary, viewKeyDefinition, mode)}</TableCell>            
            case ComponentMode.EDIT: return <TableCell key = {matchedPrimary.id + keyToUse} className= {classes.tableCellNoPadding } align="left">
                    {switchOnAttTypeEdit(matchedPrimary, viewKeyDefinition, mode)} </TableCell>
             default: return <div key={matchedPrimary.id + 'nothing'}></div>        
        }
    }

    const {keyToUse, updateKV, matchedPrimary, viewKeyDefinition, mode } = props;

    // const displayVal =  matchedPrimary[viewKeyDefinition.key]

    // const objectHash = getObjectHash(matchedPrimary)

    // console.log('displayVal', displayVal)

    // useEffect(() => {
    //     debug && console.log('TableRowizerUsedEffect on displayVal', displayVal)
    // }, [ displayVal ])

    // useEffect(() => {
    //     debug && console.log('TableRowizerUsedEffect on objectHash', objectHash)
    // }, [ objectHash ])

  
    const tableOb = matchedPrimary;

    return switchOnMode(tableOb, viewKeyDefinition, mode) 

}