import { getZombieInstanceFromPrimaryKey, Milliseconds, ValidModelType } from '@iotv/datamodel';
import { Button, Card, FormControlLabel, Grid, TextField, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Cancel, Save } from "@material-ui/icons";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid/';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import { AdjacentFilter, AdjacentType, MessageOutputType, Severity, UserTransactionType, ValidBusinessObject } from '../../../types/AppTypes';
import { HouseCardProps } from '../types/c021_types';
// import UserMessage from '../../error/ErrorMessage'; 

var throttle = require('lodash/throttle');

const googleChartUrl = config.google.chartApiUri;
const debug = true;
const autocompleteService = { current: null };
interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
    terns: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}
const useStyles = makeStyles((theme) => {
  return {
    ...styles(theme),
    root: {
      // flexGrow: 1,
      // // backgroundColor: theme.palette.background.paper,
      // position: "fixed",
      // bottom: "0"
      // , width: "100%"
    },
    inkBar: {
      backgroundColor: 'yellow'
    },
    tabs: {
      backgroundColor: 'red'
    },
    tab: {
      flexGrow: 1,
      // backgroundColor: theme.palette.background.paper,
      //  backgroundColor: '#2123d8',
      display: "block",
      position: "fixed",
      bottom: "0",
      // color: '#44bcd8',

      // width: "auto",
      // marginLeft: "0",
      // marginRight:"0"
      paddingLeft: "0",
      paddingRight: "0",
      width: "100%"
    },
    indicator: {
      top: "0px",
      backgroundColor: '#44bcd8'
    },

    bottomTabStyle: {
      bottom: '0',
      // position: "fixed",
    },
    dividerColor: {

    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
  }
});

const editHouseStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //display: 'flex'
    },
  },
  textField: {
    width: "auto",
    height: "auto",
    color: "primary",
  },
  delete: {
    margin: theme.spacing(1),
    background: "#FE6B8B",
  },

  cancel: {
    margin: theme.spacing(1),
    //backgroundColor: "#e6778a",
  },
  save: {
    margin: theme.spacing(1),
    //backgroundColor: "#58f5bb",
  },
}));


const tabuseStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  main: {
    margin: theme.spacing(2),
  }
}));

/** //? Google Auto Complete Start
function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

*/ //? Google Auto Complete End

export default function HouseCard(props: HouseCardProps, filterGraphKeys?: string[]) {
  const history_redirect = useHistory();
  debug && console.log('HouseCard card got props', props)
  const classes = useStyles();
  const editHouse = editHouseStyles();
  const tabStyle = tabuseStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [houseObject, setHouseObject] = React.useState(props.viewObject.matchedPrimary)
  const [geoLocation, setGeLocation] = React.useState({});

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);
  const autoCompleteRef = React.useRef(null);

  const [ latLngMode, setlatLngMode ] = useState<boolean>(false)
  const [ latError, setLatError ] = useState<boolean>(true)
  const [ lngError, setLngError ] = useState<boolean>(true)

  
  //State variables to keep track of whether house object fields and the object itself is valid
  const [houseError, setHouseError] = React.useState<boolean>(false)
  const [houseNameError, setHouseNameError] = React.useState<boolean>(false)
  const [houseStreetError, setHouseStreetError] = React.useState<boolean>(false)
  const [houseSuburbError, setHouseSuburbError] = React.useState<boolean>(false)
  const [houseCityError, setHouseCityError] = React.useState<boolean>(false)
  const [houseCountryError, setHouseCountryError] = React.useState<boolean>(false)

  const { viewObject, transactionFunctions, userFunctions, searchParams, optionals, adjacentFilter } = props;


  /** //? Google Auto Complete Start
   
  
  
    // if (typeof window !== 'undefined' && !loaded.current) {
    //   if (!document.querySelector('#google-maps')) {
    //     loadScript(
    //       // 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwODe0o8TULgW0rPYQVj3thmxU1vqRXr4&libraries=places',
    //       'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLx6yzrlmjQTQHnV9rKzqZS6k-qhsZE6M&libraries=places',
    //       document.querySelector('head'),
    //       'google-maps',
    //     );
    //   }
  
    //   loaded.current = true;
    // }
  
   */ //? Google Auto Complete End

  const updateKV = (k: string, v: any) => {
    const updateHouseObject = { ...houseObject, [k]: v };
    debug && console.log('updateHouseObject', updateHouseObject)
    setHouseObject(updateHouseObject as ValidBusinessObject)
  }

  const displayMessage = (content: string, type: MessageOutputType, severity:Severity, timeoutMs: number = 3000) => {
    const id = uuidv4() 
   userFunctions.setUserMessage({ id , content,  label: content, type ,severity:severity }) 
   setTimeout( () =>  userFunctions.dismissUserMessage({ id , content,  label: content, type ,severity:severity }), timeoutMs)
 
  } 
    

  // useEffect(() => {
  //   if (viewObject.matchedPrimary?.type === 'House' && viewObject.largeObject === undefined) {
  //     transactionFunctions.getLargeObject(viewObject.matchedPrimary)
  //   }

  // }, []);

  if (transactionFunctions === undefined) {
    debug && console.log('STOP TransactionFunctions is undediend');
  }

  // // debug && console.log(`Tank has largeObject populated? ${viewObject.largeObject !== undefined}`)

  // const alertCount = viewObject.matchedRelatedBySk ? filterActiveAlerts(viewObject.matchedRelatedBySk.filter((item) => item.type === 'Alert') as (ValidBusinessObject & typeof Alert)[]).length : 0

  // let viewDefinition: ViewDefinition = {
  //   name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  //   description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  //   type: { key: 'type', label: 'Type', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  // }

  React.useEffect(() => {
    houseObject?.name ? setHouseNameError(false) : setHouseNameError(true);

    houseObject?.street_number ? setHouseStreetError(false) : setHouseStreetError(true);

    houseObject?.suburb ? setHouseSuburbError(false) : setHouseSuburbError(true);

    houseObject?.city ? setHouseCityError(false) : setHouseCityError(true);

    houseObject?.country ? setHouseCountryError(false) : setHouseCountryError(true);

    if(houseObject?.coordinates){

      houseObject?.coordinates.lat ? setLatError(false) : setLatError(true)
      
      houseObject?.coordinates.lng ? setLngError(false) : setLngError(true)
    }

  }, [houseObject])

  React.useEffect(() => {
    !latLngMode ? 
      (!houseNameError && !houseStreetError && !houseSuburbError && !houseCityError && !houseCountryError) ? setHouseError(false) : setHouseError(true) :
      (!houseNameError && !latError && ! lngError) ? setHouseError(false) : setHouseError(true)
  }, [houseNameError, houseStreetError, houseSuburbError, houseCityError, houseCountryError, latError, lngError])

  // const validateHouse = (): boolean => {
  //   let houseValidated: boolean = true
  //   console.log('update', { houseObject });
  //   if (houseObject) {
  //     if (houseObject.name) setHouseNameError(false)
  //     else {
  //       setHouseNameError(true)
  //       houseValidated = false
  //     }
  //     if (houseObject.street_number) setHouseStreetError(false)
  //     else {
  //       setHouseStreetError(true)
  //       houseValidated = false
  //     }
  //     if (houseObject.suburb) setHouseSuburbError(false)
  //     else {
  //       setHouseSuburbError(true)
  //       houseValidated = false
  //     }
  //     if (houseObject.city) setHouseCityError(false)
  //     else {
  //       setHouseCityError(true)
  //       houseValidated = false
  //     }
  //     if (houseObject.country) setHouseCountryError(false)
  //     else {
  //       setHouseCountryError(true)
  //       houseValidated = false
  //     }
  //   } else {
  //     setHouseNameError(true)
  //     setHouseStreetError(true)
  //     setHouseSuburbError(true)
  //     setHouseCityError(true)
  //     setHouseCountryError(true)
  //     houseValidated = false
  //   }

  //   return houseValidated
  // }

  const saveHouseDetails = async () => {
    if (!houseError) { 

      if(!latLngMode){
        const getGeolocation = (address: string) => {
          return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': address }, (results, status) => {
              if (status === 'OK') {
                debug && console.log('address results', results)
                var saveHouseobject = { ...houseObject, ['coordinates']: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() } };
                debug &&  console.log('houseObject when save', saveHouseobject)
                setHouseObject(saveHouseobject as ValidBusinessObject)
                const action = searchParams?.action || UserTransactionType.UPDATE;
                  //if(houseObject?.coordinates!=undefined && houseObject.coordinates.lat!=undefined){
                    if (searchParams && action === UserTransactionType.CREATE_AND_LINK) {
                      const adjacentFilter: AdjacentFilter = {
                        adjacentType: AdjacentType.CHILD,
                        // objectType: searchParams.objectTypeId ?? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK as string)?.type ?? 'unknown'
                        objectType: (searchParams.objectTypeId ?? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK as string)?.type ?? 'unknown') as ValidModelType
                        
                      }
                      searchParams?.contextObjectSK && transactionFunctions.saveAndLinkObject(searchParams.contextObjectSK, saveHouseobject as ValidBusinessObject, adjacentFilter);
                      history_redirect.goBack();
                    }
                    else {
                      debug &&   console.log({ saveHouseobject })
                      return  transactionFunctions.saveObject(saveHouseobject as ValidBusinessObject);
                    }
                    //resolve(results[0].geometry.location);
                  } else { 
                    
                    displayMessage('Please input a valid address', 'MessageBar', Severity.error) 
                    // displayErrorMessage('Could not retrieve the geo-location for the address', 'MessageBar') 
                    console.log({status});
                    reject(status);
                  }
                });
              });
        };
            
        await getGeolocation(houseObject?.street_number + ' ' + houseObject?.suburb + ' ' + houseObject?.city + ' ' + houseObject?.country) 
      } else {
        const action = searchParams?.action || UserTransactionType.UPDATE;
        if (searchParams && action === UserTransactionType.CREATE_AND_LINK) {
          const adjacentFilter: AdjacentFilter = {
            adjacentType: AdjacentType.CHILD,
            // objectType: searchParams.objectTypeId ?? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK as string)?.type ?? 'unknown'
            objectType: (searchParams.objectTypeId ?? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK as string)?.type ?? 'unknown') as ValidModelType
          }

          searchParams?.contextObjectSK && transactionFunctions.saveAndLinkObject(searchParams.contextObjectSK, houseObject as ValidBusinessObject, adjacentFilter);
          displayMessage('Saving House and rediecting when complete', 'MessageBar',Severity.error,  Milliseconds.SECOND * 2 )
          setTimeout( () => history_redirect.goBack(), Milliseconds.SECOND * 2 );
        }
      }
    } else {
      displayMessage('Please fill in the required fields', 'MessageBar',Severity.error)
    }

    //let res_geo=await addLocatableAddress(houseObject?.street_number + ' '+houseObject?.suburb +' '+ houseObject?.city + ' '+ houseObject?.country)
    //let res_geo=await addLocatableAddress('17 Wairiki Road Mount Eden Auckland New Zealand') 

    //debug && console.log('res_geo',res_geo)


  }

  const cancelHouseDetails = () => {
    history_redirect.goBack()
  };




  /** //? Google Auto Complete Start
  const fetchOptions = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );


  // React.useEffect(() => {
  //   console.log({ value, inputValue });

  //   let active = true;

  //   if (!autocompleteService.current && (window as any).google) {
  //     if ((window as any).google.maps.places) {
  //       autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
  //     }
  //   }
  //   if (!autocompleteService.current) {
  //     return undefined;
  //   }

  //   if (inputValue === '') {
  //     setOptions(value ? [value] : []);
  //     return undefined;
  //   }

  //   fetchOptions({ input: inputValue }, (results?: PlaceType[]) => {
  //     if (active) {
  //       let newOptions = [] as PlaceType[];

  //       if (value) {
  //         newOptions = [value];
  //         updateHouseObject(value)
  //       }

  //       if (results) {
  //         newOptions = [...newOptions, ...results];
  //       }

  //       setOptions(newOptions);
  //     }
  //   });
  //   console.log({ value, inputValue });

  //   return () => {
  //     active = false;
  //   };
  // }, [value, inputValue, fetch]);

  // const updateHouseObject = (addressObject: any) => {
  //   // console.log('getplaces',addressObject.getPlace());
    

  //   if (addressObject.structured_formatting.main_text) updateKV('street_number', addressObject.structured_formatting.main_text)
  //   if (addressObject.terms[2].value) updateKV('suburb', addressObject.terms[2].value)
  //   if (addressObject.terms[3].value) updateKV('city', addressObject.terms[3].value)
  //   if (addressObject.terms[4].value) updateKV('country', addressObject.terms[4].value)
  // }

  // useEffect(() => {
  //         loadScript(
  //       // 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwODe0o8TULgW0rPYQVj3thmxU1vqRXr4&libraries=places',
  //       'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLx6yzrlmjQTQHnV9rKzqZS6k-qhsZE6M&libraries=places',
  //       document.querySelector('head'),
  //       'google-maps',  () => handleScriptLoad(setQuery, autoCompleteRef)
  //     );
  //   // loadScript(
  //   //   `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  //   //   () => handleScriptLoad(setQuery, autoCompleteRef)
  //   // );
  // }, []);


  */ //? Google Auto Complete End

  const updateCoordinates = (coordinate : number, type : string) => {
    let houseCoordinates = houseObject?.coordinates
    let updatedHouseObject = { ...houseObject};

    if (!houseObject?.coordinates){
      updatedHouseObject = {...updatedHouseObject, ['coordinates'] : {lat : 0, lng : 0}}      
    }

    switch (type) {
      case 'lat':
        updatedHouseObject.coordinates = {...updatedHouseObject.coordinates, lat : coordinate}
        break
      case 'lng' :
        updatedHouseObject.coordinates = {...updatedHouseObject.coordinates, lng : coordinate}
        break
    }

    setHouseObject(updatedHouseObject as ValidBusinessObject)
  }

  return (<div
    className={classes.root}
  >
    {/* <HeaderTextTypography variant="subtitle1" align="center">{viewObject.matchedPrimary?.name}</HeaderTextTypography>
    <Divider variant="middle" classes={{ root: classes.dividerColor }} /> */}
    <div className={tabStyle.main}>
      <Grid> 
        <Typography variant="h6" component="h6">
          Create New House
        </Typography>
        <Grid container>
          <form className={editHouse.root} noValidate autoComplete="off">
            <div>
              <TextField
                label="House Name"
                id="outlined-size-small"
                onChange={(e) => updateKV('name', e.target.value)}
                className={editHouse.textField}
                value={houseObject?.name}
                variant="outlined"
                size="small"
                required
                error={houseNameError}
                />


            </div>
            {!latLngMode ? 
              <div>
                <div>
                  <TextField
                    label="Street Name and Number"
                    id="outlined-size-small"
                    onChange={(e) => updateKV('street_number', e.target.value)}
                    className={editHouse.textField}
                    value={houseObject?.street_number}
                    variant="outlined"
                    size="small"
                    required
                    error={houseStreetError}
                    />



                  {/* <Autocomplete
                    id="google-map-demo"
                    ref={autoCompleteRef}
                    style={{ width: 300 }}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(event: any, newValue: PlaceType | null) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      setValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Add a location" variant="outlined" fullWidth />
                      )}
                      renderOption={(option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                          option.structured_formatting.main_text,
                          matches.map((match: any) => [match.offset, match.offset + match.length]),
                          );
                          
                          return (
                            <Grid container alignItems="center">
                            <Grid item>
                            <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                              {part.text}
                              </span>
                              ))}
                              <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                              </Typography>
                              </Grid>
                              </Grid>
                              );
                            }}
                          /> */}
                </div>
                <div>
                  <TextField
                    label="Suburb"
                    id="outlined-size-small"
                    onChange={(e) => updateKV('suburb', e.target.value)}
                    value={houseObject?.suburb}
                    className={editHouse.textField}
                    variant="outlined"
                    size="small"
                    required
                    error={houseSuburbError}
                    />
                </div>

                <div>
                  <TextField
                    label="City"
                    id="outlined-size-small"
                    onChange={(e) => updateKV('city', e.target.value)}
                    value={houseObject?.city}
                    className={editHouse.textField}
                    variant="outlined"
                    size="small"
                    required
                    error={houseCityError}
                    />
                </div>

                <div>
                  <TextField
                    label="Country"
                    id="outlined-size-small"
                    onChange={(e) => updateKV('country', e.target.value)}
                    value={houseObject?.country}
                    className={editHouse.textField}
                    variant="outlined"
                    size="small"
                    required
                    error={houseCountryError}
                    />
                </div>
              </div>
            : 
            <div>
              <div>
                  <TextField
                    label="Latitude"
                    id="outlined-size-small"
                    onChange={(e) => updateCoordinates(parseFloat(e.target.value), 'lat')}
                    type='number'
                    className={editHouse.textField}
                    variant="outlined"
                    size="small"
                    required
                    error={latError}
                    inputProps={{
                      step: 0.0001
                    }}
                    />
                </div>
                <div>
                  <TextField
                    label="Longitude"
                    id="outlined-size-small"
                    onChange={(e) => updateCoordinates(parseFloat(e.target.value) as number, 'lng')}
                    type='number'
                    className={editHouse.textField}
                    variant="outlined"
                    size="small"
                    required
                    error={lngError}
                    inputProps={{
                      step: 0.0001
                    }}
                    />
                </div>
            </div>

          }
            <div>
              <Button
                //variant="contained"
                variant="outlined"
                //style={{ background: "#ff8566" }}
                onClick={() => cancelHouseDetails()}
                startIcon={<Cancel />}

                //size={'small'}
                >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                //style={{ background: "#1aff8c" }}
                onClick={() => saveHouseDetails()}
                startIcon={<Save />}
                //size={'small'}
                >
                Save
              </Button>
            </div>
            <FormControlLabel control={<Switch checked={latLngMode} onChange={e => setlatLngMode(!latLngMode)}/>} label="Switch to Lat Long mode" />
s
            {/*<div className={warning.root}>
                <Alert variant="outlined" severity="warning">
                Warning:This will permenantly delete the silo and unlink the
                  device
                </Alert> 
              </div>*/}
          </form>
        </Grid>

        
        </Grid>
    </div>
  </div>

  );
}
