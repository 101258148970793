import { GeofencePointsType, IsLocatableByPosition, isValidModelTypeId, ObjectHistory, PathDirection, ThingShadowState, UserAttributeDef, UserAttributeDefinitionType, ValidModelType } from '@iotv/datamodel';
import { StateSnapshot } from '@iotv/iotv-v3-types';
import { Box, Divider, Grid, Tab, Tabs } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import ObjectListCard from '../../../../components/generic/ObjecListCard';
import Assembler from '../../../../data/Assembler';
import { getImageUrl } from '../../../../data/aws/s3/UserBlobs';
// import { getImageUrl } from '../data/aws/s3/UserBlobs';
import { extractPolylineFromHistoryStore, getLocatableAddress, isDeviceEnabledType, thingShadowStateToStateSnapshot } from '../../../../data/TypeHelpers';
import { getLatLngCenter } from '../../data/TypeHelpers';
import { useObjectHistory } from '../../../../hooks/useObjectHistory';
import { AdjacentFilter, AdjacentType, MapCardPropsOptionals, ObjectCardProps, PossibleBusinessObject, UserTransactionType, ViewDefinition, ViewObject } from '../../../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../../../util/geo/GeoDataHelpers';
import styles from '../../cosmetics/sharedCardStyles';
import MapView from '../geo/MapView'; // '../../geo/MapView';
import GroupIndividualTankCard from './GroupIndividualTankCard';
import SevenDayCollectionCard from './SevenDayCollectionCard';
import TankGroupDetailsCard from './TankGroupDetailsCard';
import TankGroupFillGraphCard from './TankGroupFillGraphCard';
import TankGroupPercentageDisplayCard from './TankGroupPercentageDisplayCard';
import TankGroupUsageGraphCard from './TankGroupUsageGraphCard';
import TankGroupWaterAvailabilityCard from './TankGroupWaterAvailabilityCard';




const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  const classes = useStyles();
  return (
    <Tab
      component="a"
      // className={classes.tab}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

// export default function ObjectCard(props: ObjectCardProps) {
export default function TankGroupCard(props: ObjectCardProps | any) {

  debug && console.log('Object card got props', JSON.stringify(props.viewObject, null, 1))
  const classes = useStyles();
  const [expanded, _setExpanded] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const { viewObject, userGroupRoles, contextCustomer, userSelectedGroup, transactionFunctions, userFunctions, searchParams, history }: ObjectCardProps = props;
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => setTabValue(newValue);

  const getTabs = () => {
    return <Tabs
      variant="fullWidth"
      value={tabValue}
      onChange={handleTabChange}
      aria-label="full width tabs example"
      classes={{
        indicator: classes.tabIndicator,
      }}
    >
      <LinkTab label="Group Summary"   {...a11yProps(0)} />
      <Divider orientation="vertical" flexItem style={{ backgroundColor: '#a6a6a6 ' }} />
      <LinkTab label="Fill level graph"   {...a11yProps(1)} />
      <Divider orientation="vertical" flexItem style={{ backgroundColor: '#a6a6a6 ' }} />
      <LinkTab label="Usage graph"   {...a11yProps(2)} />
    </Tabs>
  }
  useEffect(() => {
    if (viewObject.matchedPrimary?.type === 'Tank' && viewObject.largeObject === undefined) {
      transactionFunctions.getLargeObject(viewObject.matchedPrimary)
    }
  }, []);

  const [objectHistory, setObjectHistory] = useObjectHistory(viewObject.matchedPrimary) 

  useEffect(() => {
    setObjectHistory(undefined)
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.viewObject.matchedPrimary)
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url)
        setImageUrl(url)
      }

    }
    props.viewObject.matchedPrimary && effectUrl()
  }, [props.viewObject.matchedPrimary?.sk]);


  const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst }:
    { mapViewObject: ViewObject | undefined, lastestThingStateShadow: ThingShadowState | undefined, track: GeofencePointsType[], position: GeofencePointsType | undefined, thingStatesMostRecentFirst: ThingShadowState[] }
    = isDeviceEnabledType(viewObject.matchedPrimary) ? extractGeoDataFromViewObject(viewObject) : { mapViewObject: undefined, lastestThingStateShadow: undefined, track: [], position: undefined, thingStatesMostRecentFirst: [] }

  debug && console.log('ObjectCard geo', mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst)


  useEffect(() => {
    debug && console.log(`Object card use effect on lastestThingStateShadow at ${new Date()}`, lastestThingStateShadow)
    if (lastestThingStateShadow && objectHistory?.recentStateHistory) {
      const filterKeys = objectHistory.recentStateHistory.keys();
      const snapshot: StateSnapshot<any> = thingShadowStateToStateSnapshot(lastestThingStateShadow, filterKeys)
      const objectHistoryClone = new ObjectHistory(objectHistory)
      objectHistoryClone.recentStateHistory?.push(snapshot)
      setObjectHistory(objectHistoryClone)
    }

  }, [lastestThingStateShadow?.sk])

  const thingShadowStates = viewObject.matchedRelatedBySk ? viewObject.matchedRelatedBySk.filter((item: { type: string; }) => item.type === 'ThingShadowState') : []
  debug && console.log('thingShadowStates', thingShadowStates)

  const [imageUrl, setImageUrl] = React.useState('')

  const validRelatedTypes = ['Array', 'RelatedType'] as UserAttributeDefinitionType[];
  const isValidRelatedType = (attKey: string, attDef: UserAttributeDef) => /* Assembler.getClass(attDef?.objectTypeId ?? attKey)  && */ attDef && validRelatedTypes.includes(attDef.type);

  const getListsForObject = (possibleBusinessObject: PossibleBusinessObject) => {
    const object = Assembler.getInstance(possibleBusinessObject);
    let elements: JSX.Element[] = [];
    if (object) {
      elements = Object.entries(object.getUserAttributes()).map((([k, attDef]) => getListForObjectAttribute(object, k, attDef as UserAttributeDef))).filter((element) => !!element) as JSX.Element[];

    } else {
      console.log(`WARN: ${possibleBusinessObject.sk} is not a vob`, possibleBusinessObject)
    }

    return elements
  }

  const getListForObjectAttribute = (contectObject: PossibleBusinessObject, attKey: string, attDef: UserAttributeDef) => {
    let element = null;
    //debug && console.log(`about to creatre Lists for ${contectObject.type}:${attKey}:${attDef?.edgeTypeId ?? 'no edgeId'}:${attDef?.direction ?? 'no direction'}` )


    if (attKey && isValidRelatedType(attKey, attDef)) {
      const adjacentType: AdjacentType = attDef?.direction === PathDirection.parent ? AdjacentType.PARENT : AdjacentType.CHILD;
      let objectType: ValidModelType | undefined;
      let otherObjectType: string | undefined;

      const derivedTypeId = attDef?.objectTypeId ?? attKey;

      if (!isValidModelTypeId(derivedTypeId)) {
        console.log(`WARN: ${derivedTypeId} is not a valid model typeId`)
      }


      switch (adjacentType) {
        case AdjacentType.PARENT:
          objectType = derivedTypeId as ValidModelType
          otherObjectType = contectObject.type
          break;

        default: objectType = derivedTypeId as ValidModelType
          break;
      }

      const adjacentFilter: AdjacentFilter = {
        adjacentType,
        edgeFilter: undefined,
        objectType,
        otherObjectType,

      }

      if (attDef?.edgeTypeId) {
        adjacentFilter.edgeFilter = {
          edgeTypeId: attDef.edgeTypeId
        }
      }

      debug && console.log(`created adjacemtFilter for ${contectObject.type}:${attKey}:${attDef?.edgeTypeId ?? 'no edgeId'}:${attDef?.direction ?? 'no direction'}`, adjacentFilter)

      const listConfigParams = {
        label: typeof attDef?.label === 'string' ? attDef.label : `${attKey}s`
      }
      element = <Grid item xs={6} key={`${attKey}`}>
        <ObjectListCard {...{ viewObject, userGroupRoles, contextObject: contextCustomer, userSelectedGroup, transactionFunctions, userFunctions, searchParams, history, adjacentFilter, listConfigParams }} ></ObjectListCard>
      </Grid>
    } else {
      //debug && console.log(`invalid related type`, { contectObject, attKey, attDef, objectKeys: Object.keys(contectObject.getUserAttributes()) })
    }

    return element;
  }




  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);


  const hasAlert = () => true;
  const badgeCount = () => 0;
  // const getPosition = (polylines: GeofencePointsType[]): GeofencePointsType | undefined => {
  //   let derivedPosition: GeofencePointsType | undefined = undefined;

  //   if (IsLocatableByPosition(viewObject.matchedPrimary)) {
  //     derivedPosition = viewObject.matchedPrimary.getLatLon() ?? viewObject.matchedPrimary?.coordinates;
  //   } else {
  //     debug && console.log('not locatable by position', viewObject.matchedPrimary)
  //     derivedPosition = viewObject.matchedPrimary?.coordinates ?? position
  //   }

  //   if (polyline.length > 0) {
  //     const last = polyline.length - 1;
  //     const sample = polyline[last];
  //     derivedPosition = { lat: sample.lat, lng: sample.lng }
  //   }

  //   debug && console.log('derived position as', derivedPosition)

  //   return derivedPosition;

  // };



  const getPosition = (): GeofencePointsType | undefined => {
  const post=   getLatLngCenter(viewObject)
  console.log({post});
  // return post
    let derivedPosition: GeofencePointsType | undefined = undefined;

    if (IsLocatableByPosition(viewObject.matchedPrimary)) {
      derivedPosition = viewObject.matchedPrimary.getLatLon() ?? viewObject.matchedPrimary?.coordinates;
    }
    else { derivedPosition = viewObject.matchedPrimary?.coordinates ?? position }
    return derivedPosition;
  };

  const actions: UserTransactionType[] = [
    UserTransactionType.CREATE
  ]

  const viewDefinition: ViewDefinition = {

  }

  const mapCardPropsOptionals: MapCardPropsOptionals = {
    zoom: 16,
    styles: {
      mapCardObjectCard: {
        height: '40vw',
        width: '40vw',
      }
    }
  }

  const polyline: GeofencePointsType[] = objectHistory?.recentStateHistory ? extractPolylineFromHistoryStore(objectHistory.recentStateHistory, viewObject.matchedPrimary?.sk ?? 'unknonw') : []

  const polylines = { [viewObject.matchedPrimary?.sk ?? 'Unkownn']: polyline }



  return (
    <React.Fragment  >
      {tabValue == 0 &&
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TankGroupPercentageDisplayCard className={classes.subCard}
                  matchedPrimary={viewObject.matchedPrimary}
                  parentObjects={[viewObject.matchedRelatedByPk]} ></TankGroupPercentageDisplayCard>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="column" spacing={1}  >
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center"
                      textAlign="center"
                      bgcolor="background.paper">
                      <Typography variant="h5" component="h2">
                        {viewObject.matchedPrimary?.name || 'No name known'}
                      </Typography>
                    </Box>
                    <Divider variant="middle" classes={{ root: classes.dividerColor }} />
                  </Grid>
                  <Grid container spacing={1} >
                    <Grid item xs={6}>
                      <TankGroupWaterAvailabilityCard
                        matchedPrimary={viewObject.matchedPrimary}
                        parentObjects={[viewObject.matchedRelatedByPk]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <Card>  <TankRainCard  className={classes.subCard} latestPayload={thingShadowStates[0]} parentObjects={[viewObject.matchedRelatedByPk]}   ></TankRainCard>  </Card> */}
                      <SevenDayCollectionCard {...{ matchedPrimary: viewObject.matchedPrimary, viewObject }} ></SevenDayCollectionCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardContent>
            {viewObject.matchedPrimary && <div>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.mapC021TankGroupCard}>
                  <MapView {...{ position: getPosition(), editable: false, locatableAddress, viewObjects: [viewObject] }} ></MapView>
                </Grid>
                <Grid item xs={12}  >
                  <TankGroupDetailsCard   {...{ matchedPrimary: viewObject.matchedPrimary, matchedRelatedBySk: viewObject.matchedRelatedBySk, viewObject, transactionFunctions, viewDefinition, userFunctions, searchParams, history }} />
                </Grid>
                {viewObject.matchedRelatedBySk &&
                  <Grid item xs={12}>
                    <Card className={classes.c021GITCard}>
                      <CardContent>
                        <Box display="flex" justifyContent="center"
                          textAlign="center"
                          bgcolor="background.paper">
                          {/* <Typography variant="subtitle2" component="h4"> */}
                          <Typography variant="h6" component="h4">
                            Tanks in Community
              </Typography>
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Box display="flex"
                              width='100%'
                              height='100%'
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              bgcolor="background.paper">
                              <Typography variant="subtitle2" component="h2">Water Availability </Typography> </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box display="flex"
                              width='100%'
                              height='100%'
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              bgcolor="background.paper">
                              <Typography variant="subtitle2" component="h2">Tank Type</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box display="flex" alignItems="center"
                              // width='100%'
                              height='100%'
                              justifyContent="center"
                              textAlign="center"
                              bgcolor="background.paper">
                              <Typography variant="subtitle2" component="h2">Tank Name</Typography>
                            </Box>
                          </Grid>

                          <Divider variant="middle" classes={{ root: classes.dividerColor }} />

                          {viewObject.matchedRelatedBySk.map((child: any) => <GroupIndividualTankCard tankItem={child} />)}
                        </Grid>
                      </CardContent>
                    </Card>

                  </Grid>
                }
                <Box component="span" display="none">  {getListsForObject(viewObject.matchedPrimary)}   </Box>
              </Grid>

            </div>
            }

          </CardContent>
        </Card>
      }
      {tabValue == 2 &&
        <Card className={classes.root}>

          <CardContent>
            <TankGroupFillGraphCard
              {...{ viewObject, transactionFunctions, userFunctions, searchParams, history }}
            />
            {/* {historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys,'current_filled_volume')} */}
          </CardContent>
        </Card>
      }
      {tabValue == 4 &&
        <Card className={classes.root}>

          <CardContent>
            <TankGroupUsageGraphCard
              {...{ viewObject, transactionFunctions, userFunctions, searchParams, history }}
            />
          </CardContent>
        </Card>
      }
      <Card className={classes.bottomTab} style={{ position: 'fixed' }} >
        {getTabs()}
      </Card>
      {/* } */}
    </React.Fragment>
  );
}