import { ObjectHistory } from '@iotv/datamodel-core';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { Card, Typography } from '@material-ui/core';
import { LineChartXType } from '../../../../../types/AppTypes';
import { RangeableBarGraph } from '../../charts/RangeableBarGraph';

const debug = process.env.REACT_APP_DEBUG && false;

export type CommunityRainfallGraphProps = {
  objectHistory: Partial<ObjectHistory>;
};

export const CommunityRainfallGraph = ({ objectHistory }: CommunityRainfallGraphProps) => {
  const historyObject = objectHistory.dailyStateHistory;

  debug && console.log('CommunityRainfallGraph historyObject', historyObject);

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    const unixFromDate = (fromDate.valueOf() / 1000) * 1000;
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate);
    lowIdx = lowIdx < 0 ? historyObject.index.length - 1 : lowIdx;

    let maxIdx = historyObject.index.length - 1;
    const wholeKeys = Object.keys(historyObject);
    var keys = wholeKeys.filter((key) => key === graphKey);
    if(keys.length == 0){
      keys = wholeKeys.filter((key) => key === "community_potential_rainfall_collection");
    }
    const xType: LineChartXType = LineChartXType.TIMESERIES;

    console.log('getGraph', graphKey);

    const rows = keys
      .map((key) => {
        return historyObject[key] && <RangeableBarGraph {...{ historyObject, xType, keys: [key], lowIdx, maxIdx }}></RangeableBarGraph>;
      })
      .filter((element) => element);
    return rows;
  };

  return (
    <Card>
      <Typography variant='h5' align='center'>
        Rainfall
      </Typography>
      {historyObject && historyObject.index.length > 0 ? (
        getGraph(historyObject as HistoryObjectType, [], 'community_rainfall_collection')
      ) : (
        <Typography variant='h6' align='center'>
          No Data available
        </Typography>
      )}
    </Card>
  );
};
