import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {

    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    position: 'relative'
  },
}));

const debug = process.env.REACT_APP_DEBUG && false;

type SimplePopperProps = {containerRef: React.RefObject<HTMLDivElement>, anchorRef: React.RefObject<HTMLDivElement>, otherChildrenRef: React.RefObject<HTMLDivElement>, children: JSX.Element, open: boolean };

export default function SimplePopper(props: SimplePopperProps) {
  const {containerRef, anchorRef, otherChildrenRef, children } = props;
 
  debug && console.log('Pooper got', props)

  const classes = useStyles();
  const [anchorEl, _setAnchorEl] = React.useState(anchorRef.current);


  const isOpen = true;// Boolean(anchorEl) && open;
  const id = isOpen ? 'simple-popper' : undefined;

  debug && console.log('SimplePopper ref width', otherChildrenRef.current?.clientWidth)
  const width =  containerRef.current?.clientWidth  ? containerRef.current?.clientWidth / 2 : '10em'

  return (

      <Popper  className={classes.paper} id={id} open={isOpen} anchorEl={anchorEl} container={ () => containerRef.current}
      placement = 'left'
      style={{ width, zIndex: 2  } }
      >
        {children}
        
      </Popper>

  );
}