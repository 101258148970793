import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import React, { useEffect } from 'react';
import { AddObjectItemProps, ComponentMode, ValidTypeLinkage } from '../../../types/AppTypes';
import { AddItemTypeChooser } from './AddItemTypeChooser';
import { NewObjectItem } from './NewObjectItem';

const debug = process.env.REACT_APP_DEBUG && false;


type ValidModes = ComponentMode.VIEW | ComponentMode.EDIT

export const AddItem = ( props: AddObjectItemProps ) => {


    const { validTypes, nodeId, handleSave, level, mode  } = props;
    const [ selectedValidTypeLinkage, setSelectedValidTypeLinkage ] = React.useState<ValidTypeLinkage>(validTypes[0])
    const [ addMode, setAddMode ] = React.useState<ValidModes>(ComponentMode.VIEW)

    useEffect( () => {

    }, [ addMode ])
    
    const handleIconClick = ( e: any ) => { 
        debug && console.log('Add item icon click');
        setAddMode( addMode === ComponentMode.VIEW ? ComponentMode.EDIT : ComponentMode.VIEW)

    };
  
    const curriedHandleSave = ( newObject: ValidBusinessObject | undefined ) => {
        setAddMode(ComponentMode.VIEW)
        return handleSave( newObject, selectedValidTypeLinkage.adjacencyType, selectedValidTypeLinkage.edgeTypeId )
    }

    if ( addMode === ComponentMode.VIEW ) {
        return <AddItemTypeChooser { ...{
            validTypes,
            nodeId,
            handleIconClick,
            selectedValidTypeLinkage,
            setSelectedValidTypeLinkage
        }}></AddItemTypeChooser>

    } else {
        // return  <TreeItem { ...{nodeId,  label: "index.js" } } />
      return <NewObjectItem { ...{ ...props, handleSave: curriedHandleSave, validType: selectedValidTypeLinkage, setViewMode: () => setAddMode(ComponentMode.VIEW)}}></NewObjectItem>
    }

    
}