import { Grid } from "@material-ui/core";
import React from "react";
import { TabSelectionProps } from "../../types/AppTypes";
import { TabPanel } from "../utils/TabPanelShim";


const debug = !process.env.AWS_SESSION_TOKEN && false;

export type SlaveTabProps = TabSelectionProps & { orderedComponents: (() => JSX.Element | null )[]}

export const SlaveTab = ({ orderedComponents, value, index }: SlaveTabProps) => {


    let renderedComponents: (JSX.Element | null)[] | null = null
    if ( value === index ) {
        renderedComponents =  orderedComponents.map( ( component ) => component() )
    } else {
        debug && console.log('not rendeing for ', { value, index })
    }


return <TabPanel {...{ value, index }}>
    
    <Grid container>
        { renderedComponents || <div>Nothing</div> }
        

    </Grid>
</TabPanel>
}