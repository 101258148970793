import { AppLocalStorage } from "../../../../../data/LocalStorage/AppLocalStorage";
import { NavSections } from "../../../../navigation/NavSections";
import { GoogleMapIdleEvent } from "../eventTypes";

const debug = process.env.REACT_APP_DEBUG && false;

let boundsChangeTimeout: NodeJS.Timeout | undefined = undefined

export const addBoundsChangeIdleListener = ( map: google.maps.Map<Element>, boundChangeFn: ( event: GoogleMapIdleEvent) => void ) => {

    google.maps.event.addListener(map, 'idle', () => {
      boundsChangeTimeout &&  clearTimeout(boundsChangeTimeout)
      const timeoutFn = ( event: any ) => {
       
        var bounds = map.getBounds();
        debug && console.log('Bounds in map on idle is ', { bounds, event })
        bounds && AppLocalStorage.set( NavSections.SURVEYVIEW, 'lastViewedBounds', bounds )
        boundChangeFn(event)
      }
      boundsChangeTimeout = setTimeout( timeoutFn, 250 )
    });
}

export const addBoundsChangeZoomListener = ( map: google.maps.Map<Element>, boundChangeFn: ( event: GoogleMapIdleEvent) => void ) => {

    google.maps.event.addListener(map, 'zoom_changed', () => {
      debug && console.log('zoom changed, we did nothing')
    });
}