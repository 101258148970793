import { ValidBusinessObject, WeatherData, WeatherFormat } from "@iotv/datamodel-core";

export const populateWeather = ( tank: ValidBusinessObject & { weather_data?: WeatherData } ) => {
    var forecast_weather_data: WeatherData | undefined = tank.weather_data;

  
    var daily_forecast: WeatherFormat[] = [];
    if (forecast_weather_data?.daily) {
      daily_forecast = forecast_weather_data.daily
    }
    var weather_forecast = [];
  
    for (let i = 1; i < daily_forecast.length; i++) {
      let unixTime = daily_forecast[i].timestamp;
      let date = new Date(unixTime * 1000);
      let today = new Date()
      // console.log(today.getDay());
      if (date.getDay() !== undefined) {
        var day_name: string = '';
        if (date.getDay() === 0) {
          day_name = 'Sun'
        } else if (date.getDay() === 1) {
          day_name = 'Mon'
        } else if (date.getDay() === 2) {
          day_name = 'Tue'
        } else if (date.getDay() === 3) {
          day_name = 'Wed'
        } else if (date.getDay() === 4) {
          day_name = 'Thu'
        } else if (date.getDay() === 5) {
          day_name = 'Fri'
        } else if (date.getDay() === 6) {
          day_name = 'Sat'
        }
        var weather_cal: { day: string; img: string } = { day: '', img: '' };
        weather_cal.day = day_name
        weather_cal.img = daily_forecast[i]?.weather?.icon ?? ''
        weather_forecast.push(weather_cal);
      }
    }
    return { weather_forecast, daily_forecast }
  }

  