import { NodeEdgeMapper, ValidBusinessObject, ValidBusinessObjectList } from "@iotv/datamodel-core";
import { Typography } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { ValidCustomerObject } from "../../types/AppTypes";
const debug = process.env.REACT_APP_DEBUG && false;

export type NestedCustomerSelectorProps = {
    nodeEdgeMapper: NodeEdgeMapper,
    setHoldContextCustomerFn: (contextCustomer: ValidCustomerObject) => void
}

export const NestedCustomerSelector = ({ nodeEdgeMapper, setHoldContextCustomerFn: setContextCustomerFn }: NestedCustomerSelectorProps) => {

    const apexNodes = nodeEdgeMapper.getApexNodes()

    const nodeEdgesHash = Object.keys(nodeEdgeMapper?.skMapped ?? { noNodes: true }).join('')

    useEffect(() => {
        debug && console.log('NestedCustomerSelector usedEffect on nodeEdgeHash', nodeEdgesHash)
    }, [nodeEdgesHash])
  
    useEffect(() => {
        if (nodeEdgeMapper) {
            debug && console.log('NestedCustomerSelector usedEffect on skMapeed', Object.keys(nodeEdgeMapper.skMapped).length)
        }
  
    }, [nodeEdgeMapper?.skMapped])

    debug && console.log('NestedCustomerSelector has ', { apexNodes, nodeEdgeMapper })

    const generateSubTree = (nodes: ValidBusinessObjectList) => {
        if (nodeEdgeMapper) {
            //Getting list of children from given community


            return (nodes.map((nodeIn) => {

                const node = nodeIn
                const childNodes = nodeEdgeMapper.getChildNodes(node)
                debug && console.log(`generateSubTree wih ${node.name} got children ${childNodes.map((c) => (c as ValidBusinessObject).name)}`)

                const communityControls: JSX.Element = <Typography>SOME CONTROLS</Typography> //getCommunityControls(node) // use with ExtendedTreeItem
                return (
                    <TreeItem {...{ key: node.sk, nodeId: node.sk, label: node.name, onClick: () => setContextCustomerFn(node as ValidCustomerObject) }} >
                        {generateSubTree(childNodes as ValidBusinessObjectList)}
                  </TreeItem>
                )
            })
            )
        } else {
            debug && console.log('nodeEdgeMapper not yet initialized')
        }

    }

    debug && console.log('NestedCustomerSelector nodeEdgeMapper has',  nodeEdgeMapper.getContainedItems())

    return <TreeView {...{
        defaultCollapseIcon: <  ExpandMoreIcon />,
        defaultExpandIcon: < ChevronRightIcon />,
    }}
    >
        { generateSubTree( apexNodes as ValidBusinessObjectList )}
    </TreeView>


}