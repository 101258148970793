import { DataGrid, DataGridProps, GridColDef, GridEventListener, GridToolbar, useGridApiRef, } from '@mui/x-data-grid';
import { IOTV_LCD_DeviceMessage, IOTV_LCD_RX_Data, LinkType } from '@iotv/iotv-v3-types'
import { getNiceDate } from '../../data/TypeHelpers';
import { StripedDataGrid } from '../factories/DataGrid/styles/striped';
import { prettifyHex } from '@iotv/datamodel-core';
import { reboot, trueIsGood, trueIsBad, trueIsYes, updown } from '../factories/DataGrid/cellFormatters';
import { NWName } from '../factories/DataGrid/cellFormatters/valueFormatters';
import { LSC_DataGridColumnMap, LSC_DataGridRowProxy } from 'src/types';
import { DecodedPayloadPopUp, PayloadDataGripCell } from '../factories/DataGrid/cellFormatters/payload';
import { useRef, useState, useEffect } from 'react';
import { DialogWrapper } from '../common/DiaglogWrapper';
import { JsxElement } from 'typescript';
import { wrapLinkVOB } from '../factories/DataGrid/cellFormatters/hyperlink';
import { lastEventTime } from '../common/LastEventTime';


export type LCDDeviceMessageTableProps = {
    deviceMessages: IOTV_LCD_DeviceMessage[]
    loading: boolean
}

export type DisplayAttributes = IOTV_LCD_DeviceMessage & IOTV_LCD_DeviceMessage['primaryGatewayRXData'] & IOTV_LCD_DeviceMessage['nmMonitoringAttributes']




const columnMap: LSC_DataGridColumnMap<DisplayAttributes> = {
    linkType: {
        headerName: 'U/D',
        align: 'center',
        renderCell: updown,
        width: 20
    },
    timestamp: {
        headerName: 'Time',
        renderCell: lastEventTime,
        // valueFormatter: (p) => typeof p.value === 'number' ? getNiceDate(p.value) : undefined,
        minWidth: 150
    },
    nasSourceIp: {
        headerName: 'NAS IP',
        width: 120

    },
    app_id: {
        headerName: 'AS',
        width: 100

    },
    nwID: {
        headerName: 'NW',
        valueFormatter: NWName,
        width: 80
    },
    gatewayEui: {
        headerName: 'GW',
        minWidth: 160,
        renderCell: (p) => wrapLinkVOB(p, 'gatewaySk')
    },
    deviceEui: {
        headerName: 'DEV',
        minWidth: 160,
        renderCell: (p) => wrapLinkVOB(p, 'deviceSk')
    },
    downlinkAck: {
        headerName: 'ACK',
        width: 50,
        align: 'center',
        renderCell: trueIsYes
        
    },
    fCnt: {
        headerName: 'CNT',
        width: 75
    },
    rssi: {
        headerName: 'RSSI',
        width: 75
    },
    snr: {
        headerName: 'SNR',
        width: 75
    },
    bw: {
        headerName: 'BW',
        width: 75
    },
    adr: {
        headerName: 'ADR',
        width: 75
    },
    dr: {
        headerName: 'DR',
        width: 75
    },
    frequency: {
        headerName: 'FQ',
        valueFormatter: (p) => typeof p.value === 'number' ? p.value / 1000000 : undefined,
    },
    channel: {
        headerName: 'CHANNEL',
        width: 75
    },
    codeRate: {
        headerName: 'CODE RATE',
    },
    battery: {        
        headerName: 'BAT (v)',
    },
    batteryCharging: {
        headerName: 'BAT CHG?',
        align: 'center',
        renderCell: trueIsGood
    },
    batteryErr: {
        headerName: 'BAT ERR?',
        align: 'center',
        renderCell: trueIsBad
    },
    linkCheckErr: {
        headerName: 'LINK ERR?',
        align: 'center',
        renderCell: trueIsBad
    },
    reboot: {
        headerName: 'REBOOT?',
        align: 'center',
        renderCell: reboot
    },
    payloadStr: {
        headerName: 'PAYLOAD',
        width: 400,
        renderCell: ( p ) =>  PayloadDataGripCell( p )
        //valueFormatter: (p) => typeof p.value === 'string' ? prettifyHex(p.value) : undefined,
        
    }
}

type HelperAtts = { deviceSk: string, gatewaySk: string}

const rowToGridRow = (deviceMessage: IOTV_LCD_DeviceMessage): LSC_DataGridRowProxy<DisplayAttributes & HelperAtts> => {
    const { deviceEui, timestamp, primaryGatewayRXData, nmMonitoringAttributes } = deviceMessage
    const { rssi, snr, channel, gatewayEui, gatewayIdName } = primaryGatewayRXData ?? {} as IOTV_LCD_RX_Data
    const {  battery, batteryCharging, batteryErr, downlinkAck, linkCheckErr, reboot} = nmMonitoringAttributes

    return {
        id: `${deviceEui}_${timestamp}`,
        ...deviceMessage,
        linkType: deviceMessage.linkType ?? LinkType.UP,
        rssi, snr, channel, gatewayEui, gatewayIdName, battery, batteryCharging, batteryErr, downlinkAck, linkCheckErr, reboot,
        deviceSk: `Device:${deviceEui}`, gatewaySk: `Gateway:${gatewayEui}`
    }
}

export const LCDDeviceMessageTable = ({ deviceMessages, loading }: LCDDeviceMessageTableProps) => { 

    const [wRef, setWRef] = useState<JSX.Element | null >(null)

    useEffect( () => {
        console.log('DataGrid used effect on changed wRef',)
    }, [ wRef ])

    const getx = ( wrappedElement: JSX.Element, header: string ) => <DialogWrapper { ...{
            header, open: true, setClosed: () =>( setWRef(null)), wrappedElement, fullWidth: true, maxWidth: 'xl'
        }}
    />;

    const handleCellClick: GridEventListener<"cellClick"> = ( e ) => {
         console.log('DataGrid on cell clcik', e)
         const matchedKey = 'payloadStr'
        
         if (e.field  === matchedKey) {
            const payloadStr = e.value 
            const deviceId = e.row['deviceEui'] ?? ''
            const header = ` decoded payload for Device ${ deviceId } ${ prettifyHex(payloadStr) }`
            setWRef( getx( <DecodedPayloadPopUp { ...{ payloadStr }}/>, header) )
         }
      
    }
    

    const dataGridProps: DataGridProps = {
        onCellClick: handleCellClick,   
        density: 'compact',
        columns: Object.entries(columnMap).map(([k, v]) => ({ field: k, ...v })),
        rows: deviceMessages.map(rowToGridRow),
        getRowClassName: (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
        loading

    }

    return <>
        <StripedDataGrid {...{ style: { fontSize: 'inherit'}, ...dataGridProps }} />
        { wRef  }
    </>
}