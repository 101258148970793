import { AdjacentType, BusinessObjectAssembler, CustomerAccountType, ErrData, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { UserView } from '../../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && true;
const apiRef = 'SystemManagement';

const assembler = new BusinessObjectAssembler();

export const getCustomerQuery = async (fn: (params: any) => void, contextUser: UserView, requestedCustomerSk: string) => {
  if (contextUser) {
    const contextCustomer = assembler.getZombieFromPrimaryKey(requestedCustomerSk)
    try {
      debug && console.log('CM user', contextUser)
      const response = await ApiGateway.post(`/system/Customer/get`, { contextUser, contextCustomer }, apiRef);
      debug && console.log('CM query results', response)
      if (response) {
        fn(response)
      } else {
        throw new Error(`Too many customers receied: ${JSON.stringify(response, null, 1)}`)
      }
    } catch (e) {
      debug && console.log('Error in CM query', e)
    }
  }
}

export const getCustomerHierachyQuery = async (fn: (params: any) => void, contextUser: UserView, contextCustomer: ValidBusinessObject | undefined) => {
  if (contextCustomer && contextUser) {
    debug && console.log('Doing hierarchy query')
    try {
      const response: ErrData<ValidBusinessObjectList> = await ApiGateway.post(`/system/Customer/parents`, { contextUser, contextCustomer }, apiRef);
      if (response.data) {
        debug && console.log('hierarchy query res', response.data)
        const items = response.data.map( (item) => Object.assign( item, {pk: item.sk})) // hierachry returns edges
        fn(items)
      } else {
        throw new Error(`Problem in HierarchQuery: ${JSON.stringify(response, null, 1)}`)
      }
    } catch (e) {
      debug && console.log('Error in Hierachy query', e)
    }


  }
}

export type GetCustomerContextRequest = { contextUser?: ValidBusinessObject; customer: ValidBusinessObject; accountType: CustomerAccountType; accountRelationship: AdjacentType, ExclusiveStartKey: {sk: string, pk: string}| undefined };

export const listCustomersQuery = async (fn: (params: any) => void, contextRequest: GetCustomerContextRequest) => {
  if (contextRequest.contextUser) {
    try {
      const response = await ApiGateway.post('/system/Customer/context', contextRequest, apiRef);
      debug && console.log('listCustomersQuery query results', { contextRequest, response })
      fn(response)
    } catch (e) {
      debug && console.log('Error in CCT query', e)
    }
  }
 
}
