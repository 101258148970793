import { Card, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@material-ui/core";
import { DayOfHourlyStructure, HourlyYearBasedAccumulatorCMVKeyAccessor, HourlyYearBasedNormalAccumulator, MonthNumber, MonthOfHourlyStructure, ValidCMVFields, MonthNumberEnum, flattenArray, HourlyStructure, CMVarAggregationObject, roundDecimals, camelToDisplay } from "@iotv/datamodel-core";
import { CMVarAggregationType } from "@iotv/iotv-v3-types";
import { Sparklines, SparklinesBars } from 'react-sparklines';


export type AccumulatorTableProps = {
    accumulator: HourlyYearBasedNormalAccumulator
}

const styles = (theme: Theme) => ({
    cmvTableCell: {
      fontSize: '8pt',
    },
  });   

const aggregateCMVs = (cmvs: CMVarAggregationType[]) => {
    const cmv = new CMVarAggregationObject()
    cmvs.forEach((v) => {
            cmv.mean = ((cmv.mean * cmv.cn) + (v.mean * v.cn)) / (cmv.cn + v.cn)
            cmv.cn += v.cn
            cmv.firstTimestamp = cmv.firstTimestamp ? v.firstTimestamp ? Math.min(cmv.firstTimestamp, v.firstTimestamp) : cmv.firstTimestamp : v.firstTimestamp
            cmv.lastTimeStamp = cmv.lastTimeStamp ? v.lastTimeStamp ? Math.max(cmv.lastTimeStamp, v.lastTimeStamp) : cmv.lastTimeStamp : v.lastTimeStamp
            cmv.max = Math.max(cmv.max ?? 0, v.max ?? 0)
            cmv.min = Math.min(cmv.min ?? 0, v.min ?? 0)
            cmv.sqDiffFromMean += v.sqDiffFromMean // THIS IS HIGHLY LIKELY TO BE BOLLOCKS

        })

    return cmv
}

const dayCMVs = (hours: HourlyStructure<ValidCMVFields>): ValidCMVFields[] => Object.values(hours).reduce((a, c) => {
    a.push(c);
    return a
}, [] as ValidCMVFields[])

const monthCMVs = (days: DayOfHourlyStructure<ValidCMVFields>) => Object.values(days).reduce((a, c) => {
    a = [...a, ...dayCMVs(c)]
    return a;
}, [] as ValidCMVFields[])

const dayCMVsForK = (hours: HourlyStructure<ValidCMVFields>, k: string): CMVarAggregationType[] => Object.values(hours).reduce((a, c) => {
    c?.[k] && a.push(c[k]);
    return a
}, [] as CMVarAggregationType[])

const monthCMVsForK = (days: DayOfHourlyStructure<ValidCMVFields>, k: string) => Object.values(days).reduce((a, c) => {
    a = [...a, ...dayCMVsForK(c, k)]
    return a;
}, [] as CMVarAggregationType[])


export type AccumulatorMonthKeySummaryCellProps = {
    cmvKey: string,
    days: DayOfHourlyStructure<ValidCMVFields>
}
const AccumulatorMonthKeySummaryCell = ({ days, cmvKey }: AccumulatorMonthKeySummaryCellProps) => {
    const useStyles = makeStyles((theme) => ({ ...styles(theme) }));
    const classes = useStyles()
    const monthCMVMap: CMVarAggregationObject = aggregateCMVs( monthCMVsForK(days, cmvKey)  )
    const stats = { mean: Math.round(monthCMVMap.mean), sd: monthCMVMap.sdSample, count: monthCMVMap.cn, max: monthCMVMap.max && Math.round(monthCMVMap.max), min: monthCMVMap.min && Math.round(monthCMVMap.min) }
    const vals = Object.values(days).map((hours) => {
        return Object.values(hours).reduce((a, cmvMap) => {
            if (cmvMap?.[cmvKey]) {
                a += cmvMap[cmvKey].mean
            }
            return a
        }, 0)
    })

    return <div {...{ style: { display: 'flex',  flexDirection :'row', paddingRight: '1em', paddingTop: '1em'}  }}>
                <div  { ...{ style: { paddingRight: '1em'} } }>
            <Table { ...{ style: {  }}}>
                <TableBody>

                    { Object.entries(stats).map( ( [k, v]) => <TableRow><TableCell { ...{ className: classes.cmvTableCell }}>{camelToDisplay(k)}</TableCell><TableCell { ...{ className: classes.cmvTableCell } }>{v && roundDecimals(v, 2) }</TableCell></TableRow> ) } 
                </TableBody>
            </Table>
        </div>
        <div { ...{ style: { width: 200} } } >
        <Sparklines {...{ data: vals, height: 100 }}>
            <SparklinesBars />
        </Sparklines>
        </div >

    </div>
}

export type AccumulatorMonthRowProps = { keys: string[], monthNumber: MonthNumber, days: DayOfHourlyStructure<ValidCMVFields> }

const AccumlatorMonthRow = ({ keys, monthNumber, days }: AccumulatorMonthRowProps) => {



    return <TableRow {...{ style: {  borderStyle: 'solid', borderWidth: '1px' }}}>
        <TableCell {...{ width: 100, style: { paddingLeft: '0.5em', paddingRight: '1em'}, key: 'leftKey', children: <div>{Object.values(MonthNumberEnum)[monthNumber]}</div> }} />
        {keys.map((k, i) => <TableCell {...{ width: 300 }}> <AccumulatorMonthKeySummaryCell {...{ cmvKey: k, days, month: monthNumber }}></AccumulatorMonthKeySummaryCell> </TableCell>)}
    </TableRow>
}

export const AccumulatorTable = ({ accumulator }: AccumulatorTableProps) => {

    const cmvKeys = Object.keys(accumulator.keyMap ?? {})

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell {...{ width: 20 }}></TableCell>
                {cmvKeys.map((k) => <TableCell {...{ children: <div>{camelToDisplay(k)}</div> }} />)}
            </TableRow>
        </TableHead>
        <TableBody>


            {accumulator.accumulator && Object.entries(accumulator.accumulator).map(([monthNumberStr, days], i) => <AccumlatorMonthRow {...{ monthNumber: i as MonthNumber, days, keys: cmvKeys }} />)}

        </TableBody>
    </Table>
}