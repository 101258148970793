import { BusinessObjectAssembler, ErrData, ErrDataPromise, isValidBusinessObject, MapLike, PathDirection, QueryTypes, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { DatastoreObjectType, GetGetGraphTraversalRequest, GetGetGraphTraversalResponse } from '@iotv/iotv-v3-types';
import { PubSub } from 'aws-amplify';
import { QueryOutput } from 'aws-sdk/clients/dynamodb';
import { DocumentClient } from 'aws-sdk/lib/dynamodb/document_client';
import { v4 } from 'uuid';
import { UserFunctions } from '../../actions/AppActions';
import config from '../../config';
import AmplifyIoTListener from '../../listeners/amplifyIoTListener';
import { AdjacentType, ComparisonType, GetAdjacentResponse, LinkObjectResponse, LinkRequest, ListLimitedAllTypeRequestBody, LogicalOperatorType, MessageOutputType, NormalizedData, SearchByTypeResponse, UnlinkResponse } from '../../types/AppTypes';
import AppDao from '../AppDao';
import ApiGateway from '../aws/api-gateway/ApiGateway';
import { getCognitoId } from '../aws/DebugAuth';
import { getObject } from '../aws/s3/UserBlobs';
import { addToNormalData } from './stateFns';


const debug = process.env.REACT_APP_DEBUG && false;
const showQueries = debug && false;

const dyn = config.aws.dynamoDB

const displayMessage = (content: string, type: MessageOutputType) => {
  UserFunctions.setUserMessage({ id: v4(), content, label: content, type })
}

const assembler = new BusinessObjectAssembler()
const iotListener: typeof AmplifyIoTListener = AmplifyIoTListener;
export async function save(zombie: ValidBusinessObject) {
  debug && console.log('DAO TRANSACTION: pre get intance for object', JSON.stringify(zombie));
  const businessObject = assembler.getInstance(zombie);
  debug && console.log('DAO TRANSACTION: saving object', JSON.stringify(businessObject));
  const path = '/save/' + businessObject?.type;
  return await ApiGateway.post(path, businessObject);
}

export async function deleteObject(zombie: ValidBusinessObject): Promise<ErrData<ValidBusinessObject>> {
  const businessObject = assembler.getInstance(zombie);
  debug && console.log('DAO TRANSACTION: deleting object', JSON.stringify(businessObject));
  const path = '/delete/' + businessObject?.type;
  return await ApiGateway.post(path, businessObject);
}

/**
 * 
 * @param zombieA The object that will be the ORIGIN (source) of the edge 
 * @param zombieB The object that will be the TARGET of the edge
 * @param edgeTypeId Optional typeId for edge
 * @param edgeAttributes Optional attributes for edge
 * @returns Promise<LinkObjectResponse>
 */
export async function link(zombieA: ValidBusinessObject, zombieB: ValidBusinessObject, edgeTypeId?: string, edgeAttributes: MapLike<any> | undefined = undefined): Promise<LinkObjectResponse> {
  debug && console.log(`DAO TRANSACTION: linking objects ${zombieA?.name}, ${zombieB?.name}`, { zombieA, zombieB });
  const businessObjectA = assembler.getInstance(zombieA);
  const businessObjectB = assembler.getInstance(zombieB);

  const path = '/link/' + businessObjectA?.type + '/' + businessObjectB?.type;
  if (isValidBusinessObject(businessObjectA) && isValidBusinessObject(businessObjectB)) {
    const body: LinkRequest = {
      objectA: businessObjectA,
      objectB: businessObjectB,
    };
    if (edgeTypeId) {
      body.edgeTypeId = edgeTypeId;
      if (edgeAttributes) body.edgeAttributes = edgeAttributes;
    }
    return await ApiGateway.post(path, body);
  }
  else return { err: { message: `not valid input`, zombieA, zombieB }, data: null }

}

export async function unlink(zombieA: ValidBusinessObject, zombieB: ValidBusinessObject, edgeTypeId = undefined, edgeAttributes = undefined): Promise<UnlinkResponse> {
  const businessObjectA = assembler.getInstance(zombieA);
  const businessObjectB = assembler.getInstance(zombieB);
  debug && console.log(`DAO TRANSACTION: unlinking objects ${businessObjectA?.name}, ${businessObjectB?.name}`);
  const path = '/unlink/' + businessObjectA?.type + '/' + businessObjectB?.type;
  if (isValidBusinessObject(businessObjectA) && isValidBusinessObject(businessObjectB)) {
    const body: LinkRequest = {
      objectA: businessObjectA,
      objectB: businessObjectB,
    };
    if (edgeTypeId) {
      body.edgeTypeId = edgeTypeId;
      if (edgeAttributes) body.edgeAttributes = edgeAttributes;
    }
    const unlinkRes = await ApiGateway.post(path, body);
    debug && console.log('DAO Unlink Res', unlinkRes)
    return unlinkRes;
  }
  else return { err: { message: 'not valid input', zombieA, zombieB }, data: null }
}

export type CreateAndLinkRequest = {
  existingObject: ValidBusinessObject, newObject: ValidBusinessObject, edgeTypeId?: string, edgeAttrbutes?: MapLike<any>, direction?: PathDirection
}

export const createAndLink = async ({ existingObject, newObject, edgeTypeId, edgeAttrbutes, direction = PathDirection.child }: CreateAndLinkRequest): Promise<LinkObjectResponse> => {

  const [parentObject, childObject] = direction === PathDirection.child ? [existingObject, newObject] : [newObject, existingObject]
  let res: LinkObjectResponse = { err: null, data: null }
  if (childObject && parentObject) {
    let saveRes = await save(newObject);
    if (saveRes.data) {
      const linkRes = await link(parentObject, childObject, edgeTypeId, edgeAttrbutes)
      if (linkRes.err) {
        debug && console.log('link err', linkRes)
        displayMessage(linkRes.err.message, 'MessageBar')

      } else if (linkRes.data instanceof Array) {
        debug && console.log('link res', linkRes)
        displayMessage(`Linked ${childObject.eui} to ${parentObject?.name ?? parentObject.type}`, 'SnackBar')
      }
      res = linkRes;

    } else if (saveRes.err) {
      displayMessage(saveRes.err.message, 'MessageBar')
      res = saveRes;
    }
  }
  return res
}


export async function searchByType(contextObject: ValidBusinessObject, includeContextLinkedItems: boolean, typeId: string, lastEvaluatedKey: string | undefined): Promise<SearchByTypeResponse> {


  debug && console.log(`DAO TRANSACTION: searching for type ${typeId} with lastEvaluated key ${lastEvaluatedKey}`);
  const path = '/query/';
  const body = {
    type: QueryTypes.searchByType,
    contextObject,
    includeContextLinkedItems,
    items: [{ type: typeId }],
    limit: 40,
    scanForward: true,
    exclusiveStartKey: lastEvaluatedKey
  };
  debug && console.log('Search By Type query', JSON.stringify(body, null, 1));

  const response = await ApiGateway.post(path, body);
  debug && console.log('search by type res', response);

  return response; // res should be {Items, Count, ScannedCount, LastEvaluatedKey}
}

export async function getAdjacent(object: ValidBusinessObject, type: string, direction: PathDirection, edgeTypeId?: string): Promise<GetAdjacentResponse> {
  debug && console.log('DAO TRANSACTION: Get Adjacent', { object, type, direction });
  const res: GetAdjacentResponse = { err: null, data: null }

  const adjacencyType = direction === PathDirection.parent ? AdjacentType.PARENT : AdjacentType.CHILD

  const params: GetGetGraphTraversalRequest = {
    contextObjects: [object],
    path: [
      { objectTypeId: type, adjacencyType, edgeTypeId },
    ]
  }
  const viaGraphRes = await getGraphQueryResponse(params)  
  try {

    if ( viaGraphRes.data ) {
      res.data = viaGraphRes.data.lastNodesAndEdges.nodes as ValidBusinessObjectList
    } else {
      if ( res.err || res.data === undefined) {
        console.log(`err in getAdjacent with params`, params)
      }
      res.err = viaGraphRes.err
    }
  } catch ( e: any ) {

    console.log( `err in getAdjacent with parmas`, { params, dataOut: viaGraphRes.data})
    throw e
  }


  
  debug && console.log('get adjacent result', res)
  return res;
}

export type GetAdjacentRequest = { scopeDefinitingContextObject: ValidBusinessObject, excludeRelatedToObject?: ValidBusinessObject | undefined, includeEdges?: boolean, adjacencyType: AdjacentType, objectTypeId: string, ExclusiveStartKey?: DocumentClient.Key | undefined, limit?: number | undefined, edgeTypeId?: string | undefined }

export const getAdjacent2Wrapper = async (getAdjacentRequest: GetAdjacentRequest) => {
  const { excludeRelatedToObject, scopeDefinitingContextObject, adjacencyType, objectTypeId, ExclusiveStartKey, limit, edgeTypeId, includeEdges } = getAdjacentRequest;
  return await getAdjacent2(excludeRelatedToObject, scopeDefinitingContextObject, adjacencyType, objectTypeId, ExclusiveStartKey, limit, edgeTypeId, includeEdges)
}

export const getAdjacent2 = async (excludeRelatedToObject: ValidBusinessObject | undefined, scopeDefinitingContextObject: ValidBusinessObject, adjacencyType: AdjacentType, objectTypeId: string, ExclusiveStartKey: DocumentClient.Key | undefined = undefined, limit: number | undefined = 200, edgeTypeId?: string | undefined, includeEdges?: boolean): Promise<ErrData<DocumentClient.QueryOutput>> => {
  debug && console.log('DAO TRANSACTION: getAdjacents 2', { pageContextObject: excludeRelatedToObject, objectTypeId, scopeDefinitingContextObject })

  const queryBody: ListLimitedAllTypeRequestBody = {
    queryType: QueryTypes.LIST_LIMITED_ALL_TYPES,
    contextObject: scopeDefinitingContextObject,
    objectTypeId,
    adjacencyType,
    includeNodes: true,
    includeEdges,
    ExclusiveStartKey,
    limit,
    excludeRelatedToObject,
    filterGroups: []
  }

  if (edgeTypeId) {
    queryBody.filterGroups?.push(
      {
        filters: [
          { key: 'edgeTypeId', value: edgeTypeId, predicate: ComparisonType.EQUALS }
        ],
        setOperation: LogicalOperatorType.AND
      }
    )
  }
  const path = '/query/';
  //debug && console.log('History Query', queryBody);
  const res: ErrData<DocumentClient.QueryOutput> = await ApiGateway.post(path, queryBody);
  debug && console.log('ADJACENTS 2 RES', res)
  const parentsAndEdges = res.data;
  debug && console.log('Parents of type', parentsAndEdges)
  return res;
}

export const getOne = async (item: ValidBusinessObject) => {
  const path = '/getOne';
  const res: ErrData<ValidBusinessObject> = await ApiGateway.post(path, item) as ErrData<ValidBusinessObject>;
  return res
}


export const getExact = async (vob: DatastoreObjectType): Promise<ErrData<DocumentClient.QueryOutput>> => {
  const apiRef = config.app.appName;
  const query: DocumentClient.QueryInput = {
    TableName: dyn.tableName,
    IndexName: dyn.priName,
    ExpressionAttributeNames: {
      '#pk': dyn.pKey, '#sk': dyn.sKey
    },
    ExpressionAttributeValues: {
      ':pk': vob.pk, ':sk': vob.sk
    },
    KeyConditionExpression: '#pk = :pk and #sk = :sk'
  }
  const path = '/baseQuery/';
  const res = await ApiGateway.post(path, query, apiRef);
  console.log('Get EXAXT res', res);
  return res

}

export async function getMultipleAdjacentTypes(object: ValidBusinessObject, types: string[], direction: PathDirection): Promise<GetAdjacentResponse> {
  const promised = await Promise.all(types.map((type) => AppDao.getAdjacent(object, type, direction)));
  const res = promised.reduce((acc, cur, i) => {
    const accMessage = (acc.err?.message ?? '') + (`${i}-${types[i]} ${cur.err?.message}` ?? '');
    const err = accMessage.length > 0 ? new Error(accMessage) : null;
    const data = acc.data ? cur.data ? acc.data.concat(cur.data) : acc.data : cur.data
    return { err, data }
  }, { err: null, data: null })
  return res;
}

export async function getLargeObject(businessObject: ValidBusinessObject) {

  const path = '/getLargeObject';
  const res: ErrData<ValidBusinessObject> = await ApiGateway.post(path, businessObject) as ErrData<ValidBusinessObject>;

  return res;
}

export const getGraphQueryResponse = async (request: GetGetGraphTraversalRequest) => {
  const path = '/graphQuery';
  const responseBody: ErrData<GetGetGraphTraversalResponse> = await ApiGateway.post(path, request) as ErrData<GetGetGraphTraversalResponse>;
  return responseBody
}

export const getParents = async (contextObject: ValidBusinessObject, objectTypeId: string, ExclusiveStartKey: DocumentClient.Key | undefined = undefined, limit: number | undefined = 200, edgeTypeId?: string | undefined,): ErrDataPromise<QueryOutput> => {
  debug && console.log('DAO TRANSACTION: getParents', { contextObject, objectTypeId })
  // const queryBody: TraverserQueryPathBody = {
  //   type: QueryTypes.simplePath,
  //   simpleTypePath: [ {type, direction: PathDirection.parent}],
  //   items: [user],
  //   normalize: false
  // }

  const queryBody: ListLimitedAllTypeRequestBody = {
    queryType: QueryTypes.LIST_LIMITED_ALL_TYPES,
    contextObject,
    objectTypeId,
    adjacencyType: AdjacentType.PARENT,
    includeNodes: true,
    ExclusiveStartKey,
    limit,
    excludeRelatedToObject: undefined,
    filterGroups: []

  }

  if (edgeTypeId) {
    queryBody.filterGroups?.push(
      {
        filters: [
          { key: 'edgeTypeId', value: edgeTypeId, predicate: ComparisonType.EQUALS }
        ],
        setOperation: LogicalOperatorType.AND
      }
    )
  }

  const path = '/query/';
  //debug && console.log('History Query', queryBody);
  const res = await ApiGateway.post(path, queryBody);
  debug && console.log('RES FOR GET PARENTS', res)
  const parentsAndEdges = res?.data;

  debug && console.log('Parents of type', parentsAndEdges)
  return res;
}

export async function getThingShadowStates(things: ValidBusinessObject[], normalizedData: NormalizedData) {
  debug && console.log('DAO TRANSACTION: getThingShadowStates')
  const latestShadowStateZombies = things.map((thing) => { return { pk: thing.sk, sk: thing.sk + '_latestShadowState' } });
  debug && console.log('latestShadowStateZombies', latestShadowStateZombies);
  await getRelated(latestShadowStateZombies, normalizedData);
}

export async function getThingAlerts(things: ValidBusinessObject[], normalizedData: NormalizedData) {
  debug && console.log('DAO TRANSACTION: getThingAlerts')
  const latestAlertZombies = things.map((thing) => { return { pk: thing.sk, sk: thing.sk + '_latestAlert' } });
  debug && console.log('latestAlertZombies', latestAlertZombies);
  await getRelated(latestAlertZombies, normalizedData);
}

export async function getRelated(zombies: { pk: string; sk: string; }[], normalizedData: NormalizedData) {
  debug && console.log('DAO TRANSACTION: getRelated')
  const path = '/get/';
  const res = await ApiGateway.post(path, zombies);
  debug && console.log('get Alert or TSS', { zombies, res })
  const items = res.data;
  debug && console.log('Items', items);
  if (normalizedData && items) {
    items.forEach((shadow: ValidBusinessObject) => {
      addToNormalData(shadow, normalizedData);
    });
  }
}

const requestPolicyAttach = async (error: { error: any }, user?: ValidBusinessObject) => {
  const IdentityId = await getCognitoId();
  console.log('IdentityId', IdentityId)

  debug && console.log('DAO TRANSACTION: requestPolicyAttach')
  console.log(error);
  if (user && error && error.error && error.error.errorCode === 8) {
    debug && console.log('Attempting to trigger request policy attach ');
    user.IdentityId = IdentityId;
    const res = await save(user)
    debug && console.log(res)
  }
}

export const subscribeToThingTopic = (thing: ValidBusinessObject) => {
  debug && console.log('DAO TRANSACTION: subscribeToThingTopic')
  const topic = config.aws.mqtt.appDeviceMessageConfirmedTopice.replace('thingId', thing.id);
  if (!process.env.REACT_APP_NO_MQTT) {
    debug && console.log('App subscribing to ', topic);
    return PubSub.subscribe(topic).subscribe({
      next: data => iotListener.updateFromMessage(data),
      error: error => requestPolicyAttach(error),
      complete: () => console.log(`subscribed to ${topic}`)
    });
  } else return undefined;
}

export const subscribeToNetworkChannel = (user: ValidBusinessObject, callback: (data: any) => void) => {
  debug && console.log('DAO TRANSACTION: subscribeToNetworkChannel')
  const topic = config.aws.mqtt.networkChannel.replace('[app_id]', '#');
  if (!process.env.REACT_APP_NO_MQTT) {
    debug && console.log('App subscribing to ', topic);
    return PubSub.subscribe(topic).subscribe({
      next: data => iotListener.callBackComponent(data, callback),
      error: error => requestPolicyAttach(error, user),
      complete: () => console.log(`subscribed to ${topic}`)
    });
  } else return undefined;

}

export const subscribeToMQTTChannel = (user: ValidBusinessObject, topic: string, callback: (data: any) => void) => {
  debug && console.log(`DAO TRANSACTION subscribeToMQTTChannel: ${topic}`)
  if (!process.env.REACT_APP_NO_MQTT) {
    debug && console.log('App subscribing subscribeToMQTTChannel to ', topic);
    return PubSub.subscribe(topic).subscribe({
      next: (data) => {
        debug && console.log('subscribeToMQTTChannel callback processing ', data);
        return iotListener.callBackComponent(data, callback)
      },
      error: (error) => {
        console.log(`Err subscribing to subscribeToMQTTChannel ${topic}`, error)
        requestPolicyAttach(error, user)
      },
      complete: () => console.log(`subscribed to ${topic}`)
    });
  } else return undefined;

}

export const subscribeToThingAlertTopic = (thing: ValidBusinessObject) => {
  debug && console.log('DAO TRANSACTION: subscribeToThingAlertTopic')
  const topic = config.aws.mqtt.alertTopic.replace('thingId', thing.id);
  if (!process.env.REACT_APP_NO_MQTT) {
    debug && console.log('App subscribing to ', topic);
    return PubSub.subscribe(topic).subscribe({
      next: data => iotListener.updateFromMessage(data),
      error: error => requestPolicyAttach(error),
      complete: () => console.log(`subscribed to ${topic}`)
    });
  } else return undefined;

}

export const subscribeToUserChannel = (user: ValidBusinessObject) => {
  debug && console.log('DAO TRANSACTION: subscribeToUserChannel')
  const topic = config.aws.mqtt.userChannel.replace('[userId]', user.id);
  if (!process.env.REACT_APP_NO_MQTT) {
    debug && console.log('App subscribing to ', topic);
    return PubSub.subscribe(topic).subscribe({
      next: data => iotListener.updateFromMessage(data),
      error: (error) => {
        requestPolicyAttach(error, user)
      }
    });
  } else return undefined;

}



export async function getThingImageUrl(thing: ValidBusinessObject) {
  debug && console.log('DAO TRANSACTION: getThingImageUrl')
  const imageKey = thing.name + '.jpeg';
  if (imageKey) {
    const presignedUrl = await getObject(imageKey);
    debug && console.log('URL,', presignedUrl);
    thing.presignedUrl = presignedUrl;
  }

}
