import { connect } from 'react-redux'
import ThingsView from '../components/ThingsView';
import { RootState } from '../reducers'
import { AnyAction, bindActionCreators } from 'redux';
import { UserFunctions, TransactionFunctions } from '../actions/AppActions'
import { ThunkDispatch } from 'redux-thunk';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { NormalizedData, UserGroupsRolesType } from '../types/AppTypes';
import { constructAllItems, filterByTypeIds, flattenToArray } from '../data/daoFunctions/stateFns'
import { ValidBusinessObject } from '@iotv/datamodel';
import { GeofenceType } from '@iotv/datamodel';
const debug = process.env.REACT_APP_DEBUG && false;

const filterByMatch = (normalData: NormalizedData, match?: {params: {type: string }}) => {
  const matchType = match?.params.type;
  if (normalData && matchType) {
    const sks = Object.keys(normalData);
    const filteredByType: NormalizedData = {};
    for (let i in sks) {
      const sk: string = sks[i];
      if (sk.startsWith(matchType)) filteredByType[sk] = normalData[sk];
      else {
        const possibleParents = normalData[sk];
        const parentKeys = Object.keys(possibleParents);
        for (let i in parentKeys) {
          const pk = parentKeys[i];
          if (pk.startsWith(matchType)) {
            filteredByType[sk] = normalData[sk];
            // debug && console.log('MATCHED AS PARENT', normalData[sk])
          } else {
            debug && console.log('size of normalData was ', sks.length)
          }
        }
      }
    }
    debug && console.log(`Filtering on type, ${matchType}`, filteredByType)
    normalData = filteredByType;
  }  
  return normalData;
}

const mapStateToProps = (state: RootState, ownProps: any) => {
    debug && console.log('ThingsView normalData', state.AppReducer.normalData );
    const typeFilter = ['Geofence'];
    const childFirstGeofences: (ValidBusinessObject & GeofenceType)[] = flattenToArray(filterByTypeIds(state.AppReducer.normalData, typeFilter)) as (ValidBusinessObject & GeofenceType)[];
    // const latestThingShadowStates: (ValidBusinessObject & GeofenceType)[] = flattenToArray(filterByTypeIds(state.AppReducer.normalData, ['ThingShadowState'])) as (ValidBusinessObject & GeofenceType)[];
    // debug && console.log('ThingsView latestThingShadowStates', latestThingShadowStates );
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer.user,
    contextCustomer: state.AppReducer.contextCustomer,
    viewObjects: constructAllItems(filterByMatch(state.AppReducer.normalData, ownProps.match)).filter( (viewObject) => !(ownProps.match.params.type) || viewObject.matchedPrimary?.type === ownProps.match.params.type),
    childFirstGeofences,
    useSpecializedViews: state.AppReducer.useSpecializedViews,
    redirect: state.AppReducer.redirect,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
		debug && console.log('Things View requested init', ownProps)
    },
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingsView)
