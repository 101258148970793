import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { GetDeviceRequest } from '../../../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = 'SystemManagement';


export const getDeviceQuery = async (fn: (params: any) => void, getDeviceRequest: GetDeviceRequest) => {
  try {
    const response = await ApiGateway.post('/system/Device/getDevice', getDeviceRequest, apiRef);
    debug && console.log('getDeviceQuery query results', response)
    if (response.data?.Item) {
      const mutatedItem = { ...response.data.Item /*possibly Metadata*/ }
      fn(mutatedItem)
    }
  } catch (e) {
    debug && console.log('Error in Device query', e)
  }
}