import { GeofencePointsType, GPS_Data, isGeofencePointsType, MapLike, ThingShadowState, ValidBusinessObject } from '@iotv/datamodel';
import { getTimestampFromTSS } from '../../data/TypeHelpers';
import { ViewObject } from '../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;


export const isntSomeBollocksPosition = (tss: ThingShadowState) => {

  const DOP = (tss?.payload?.state?.reported?.position as GPS_Data)?.DOP || 0
  return DOP <= 10;
}

export const geofencePointsTypeFromTss = (tss: ThingShadowState | undefined, annotation?: string): GeofencePointsType | undefined => {
  if (tss) {
    const positionData: GeofencePointsType | GPS_Data | undefined = tss.getLatLon();
    const timestamp = getTimestampFromTSS(tss);
    if (isGeofencePointsType(positionData)) {
      return {
        ...positionData,
        timestamp,
        annotation,
        id: `${tss.sk}_point_${timestamp}`
  
      }
    } else return undefined
  }
 else return undefined;
}

export const extractGeoDataFromViewObject = ( viewObject: ViewObject ) => {
    const thingShadowStates = viewObject?.matchedRelatedBySk ? viewObject?.matchedRelatedBySk.filter( ( item: ValidBusinessObject ) => item.type === 'ThingShadowState').map( (item: ValidBusinessObject) => new ThingShadowState(item) ) : [];
    thingShadowStates.sort( ( tss1, tss2 ) => {
      const sortRes = tss1?.payload?.timestamp?.toString() && tss2?.payload?.timestamp?.toString() ? tss1?.payload?.timestamp?.toString() < tss2.payload?.timestamp?.toString() ? 1 : -1 : 0
      return sortRes;
    });
    
     
    const track = thingShadowStates.filter( (tss) =>isntSomeBollocksPosition(tss) ).map( (tss) => geofencePointsTypeFromTss(tss) ).filter( (pointData) => isGeofencePointsType(pointData) ).reverse() as GeofencePointsType[];
  
    const lastestThingStateShadowOb = thingShadowStates[0] ? new ThingShadowState(thingShadowStates[0] as ValidBusinessObject) : undefined;
    const lastPositionedTss = thingShadowStates.find( (tss) => tss.getLatLon() )
    const position = geofencePointsTypeFromTss(lastPositionedTss)
  
    const mapViewObject: ViewObject = { matchedPrimary: viewObject.matchedPrimary, matchedRelatedByPk: [], matchedRelatedBySk: lastestThingStateShadowOb ? [lastestThingStateShadowOb as ThingShadowState & ValidBusinessObject] : [] }
    debug && console.log(' geo data ', {track, position})

    return { mapViewObject, track, position, lastestThingStateShadow: lastestThingStateShadowOb, thingStatesMostRecentFirst: thingShadowStates }
}


export const extractPathFromOrderedThingStateShadowZombies = ( tssZombies: MapLike<any>[]) => {
  const track = tssZombies.map( (tss) => tss?.payload?.state?.reported?.position).filter( (latLon) => isGeofencePointsType(latLon)) as GeofencePointsType[];
  return track;
}
