import { ValidBusinessObject } from '@iotv/datamodel';
import React, { useEffect } from 'react';
import { treeCosmeticsByType } from '../../../cosmetics/tree/treeCosmeticsByType';
import Assembler from '../../../data/Assembler';
import { AdjacencyQuery, AdjacentType, UserTransactionType, ValidTypeLinkage } from '../../../types/AppTypes';
import { StyledTreeItemConfig } from '../../../types/ComponentProps';
import { NewObjectItemEdit } from './NewObjectItemEdit';


const debug = process.env.REACT_APP_DEBUG && false;

export type NewObjectItemProps = {contextObject: ValidBusinessObject, validType: ValidTypeLinkage, level: number, adjacentType?: AdjacentType, adjacencyQueries?: AdjacencyQuery[], mode: UserTransactionType, nodeId: string
    ,   handleSave: ( newObject: ValidBusinessObject | undefined ) => void, setViewMode: () => void}

export const NewObjectItem = ( props: NewObjectItemProps ) => {


    const { contextObject, validType, handleSave, setViewMode, level, mode, nodeId } = props;

  
    const initializeNewObject = () => {
        debug && console.log('Initialize in NewObjectItemProps', );
        return Assembler.getInstance({ type: validType.objectTypeId} ) as ValidBusinessObject
    }

    const [ newObject, setNewObject ] = React.useState<ValidBusinessObject>( initializeNewObject())
    
    useEffect( () => {

    }, [ newObject.type])


    const handleIconClick = ( e: any ) => {};
    const handleDelete = (e: React.MouseEvent<Element, MouseEvent>) => {};


    const treeItemProps: StyledTreeItemConfig = treeCosmeticsByType( newObject );
    const keyBase = `${level}_${treeItemProps.nodeId ?? newObject.sk}`

    debug && console.log('NewObjectitem tree props', treeItemProps)

    return <NewObjectItemEdit { ...{ ...treeItemProps, handleIconClick, handleDelete, handleSave, mode, nodeId, contextObject, newObject, setNewObject, setViewMode }}>
       
    </NewObjectItemEdit>
}