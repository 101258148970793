import {
  camelToDisplay,
  DeviceTransactionStatus,
  MapLike
} from "@iotv/datamodel-core";
import { ValidBusinessObject } from "@iotv/iotv-v3-types";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper
} from "@material-ui/core";
// import { SelectChangeEvent } from '@mui/material/Select';
import React from "react";
import { getNiceDate } from "../../../data/TypeHelpers";
import { ObjectKeyPluckerTable } from "../../../components/generic/KeyPlucker/ObjectKeyPluckerTable";
import { TabPanel } from "../../../components/utils/TabPanelShim";
import { ConfigTabProps } from "../types";
import { GeneralConfigCard } from "./configContolCards";
import { DeviceOptionsCard } from "./configContolCards";





export const ConfigurationTab = ({
  value,
  index,
  deviceConfig,
  selectedDevices,
  receivedNetworkObjects,
  handleSendTransactionButtonClick,
  setReceivedDeviceControlTransactions

}: ConfigTabProps<any>) => {
  const transactionTypeMap: MapLike<({}: any) => JSX.Element | undefined> = {
    General: GeneralConfigCard,
    DeviceOptions: DeviceOptionsCard,
  };


  const [selectedTransactionType, setSelectedTransactionType] = React.useState<
    string | undefined
  >(undefined);



  return (
    <TabPanel {...{ value, index }}>
      <Grid {...{ container: true }}>
        <Grid item {...{ xs: 12 }}>
          <div>
            {selectedDevices.length > 0
              ? `Selected devices: ${selectedDevices.map((item) => {
                  const vob = item as ValidBusinessObject;
                  return vob.deviceId ?? vob.name ?? vob.eui;
                })}`
              : "No devices selected!"}
          </div>
        </Grid>
        <Grid item {...{ key: "transactionTypeMap", xs: 12, lg: 3 }}>
          <Card>
            <CardContent>
              <FormControl component="fieldset">
                <FormLabel {...{ component: "legend", color: "primary" }}>
                  Control Type
                </FormLabel>
                <RadioGroup
                  {...{
                    "aria-label": "controlType",
                    name: "row-radio-buttons-group",
                    row: false,
                  }}
                >
                  {Object.entries(transactionTypeMap).map(([k, v], i) => {
                    return (
                      <FormControlLabel
                        key={`${k}_${i}`}
                        value={k}
                        control={
                          <Radio
                            {...{
                              size: "small",
                              checked: k === selectedTransactionType,
                              onChange: (e) =>
                                e.currentTarget.checked
                                  ? setSelectedTransactionType(k)
                                  : setSelectedTransactionType(undefined),
                            }}
                          />
                        }
                        label={camelToDisplay(k)}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <FormLabel {...{ component: "legend", color: "primary" }}>
                State
              </FormLabel>
              <ObjectKeyPluckerTable
                {...{
                  objects: receivedNetworkObjects.map( ( ob ) => ({ ...ob, timestamp: ob.timestamp ? getNiceDate(ob.timestamp) : ob.timestamp }) ),
                  keys: ["status", "timestamp"],
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid {...{ key: "ConfigCard", item: true, xs: 12, lg: 9 }}>
          {selectedTransactionType === "General" ? (
            <GeneralConfigCard
              {...{ selectedDevices, deviceConfig, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions }}
            />
          ) : null}
          {selectedTransactionType === "DeviceOptions" ? (
            <DeviceOptionsCard {...{ selectedDevices, deviceConfig, handleSendTransactionButtonClick, setReceivedDeviceControlTransactions }}/>
          ) : null}
        </Grid>
       
      </Grid>
    </TabPanel>
  );
};
