import { TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ComponentMode, DispatchFunctionsType, SearchParamsType, ThingsViewOptionals, UserTransactionType, ViewDefinition, ViewObject } from '../../types/AppTypes';
import { TableRowizerTableCell } from './TableParts/TableRowizerTableCell';

const debug = process.env.REACT_APP_DEBUG && false;


type ObjectTableRowizerProps = { viewObject: ViewObject, viewDefinition: ViewDefinition, mode: ComponentMode, actions: UserTransactionType[], transactionFunctions: DispatchFunctionsType, searchParams?: SearchParamsType, optionals?: ThingsViewOptionals} 

const selectInitialMode = (searchParams: SearchParamsType | undefined) => searchParams && searchParams.action === UserTransactionType.CREATE_AND_LINK ? ComponentMode.EDIT : ComponentMode.VIEW;


export function ObjectTableRowizer (props: ObjectTableRowizerProps) {
    const { viewObject,  viewDefinition, actions, searchParams, optionals, mode: modeIn } = {...props};
    const matchedPrimary = viewObject?.matchedPrimary || { type: 'Unknown', id: 'unknown', sk: 'Unknown', pk :'Unknown'};
    const [ mode, _setMode ] = React.useState(  modeIn ?? selectInitialMode(searchParams))
    const [ version, setVersion ] = React.useState(0)


    const [ tableOb, setTableOb ] = React.useState(matchedPrimary)

    const attributeDefinitions = Object.values(viewDefinition);

  
  useEffect(() => { setTableOb(matchedPrimary) }, [props]);
  



    const updateKV = (event: React.ChangeEvent<HTMLInputElement> , k: string ) => {
      debug && console.log('in updateKV', { event, k});
      const target = event.currentTarget;
      const { type, checked, value } = target;
      let parsedValue: any = value;
      switch (type) {
        case 'number': parsedValue = parseInt(value); break;
        case 'checkbox': parsedValue = (checked === true); break;
     
        default:  break;
        }
      const update = {...tableOb, [k]: parsedValue };
      setTableOb(update);
      setVersion(version + 1);
      debug && console.log('set table ob to', update)
  }


    const rowKey = optionals?.rowNumber ? `${ optionals?.rowNumber }_${matchedPrimary.id}` : matchedPrimary.id;
  debug && console.log('key for row', rowKey)

  debug && console.log('DeviceMessageTableRowizer props:', props)
  // debug && console.log('DeviceMessageTableRowizer attributeDefinitions:', attributeDefinitions)
  // debug && console.log('DeviceMessageTableRowizer viewKeyDefinition:', viewKeyDefinition)
    return (
      <TableRow key= { rowKey }>
         {attributeDefinitions.map( (attDef, i) => <TableRowizerTableCell { ...{keyToUse: rowKey,  viewKeyDefinition: attDef,  matchedPrimary: tableOb,  mode, actions, updateKV } }></TableRowizerTableCell>)}           
      </TableRow>
       
    )
}