import { useEffect } from "react"
import { GoogleMapV2Features } from "../GoogleMap";
import { addKMLLayer } from "./addKMLLayer";

export const useGoogleMyMapsKMLLayer = (map: google.maps.Map<Element> | undefined, features?: GoogleMapV2Features) => {

    const kmlIdHash = features?.myMapKMLs && features.myMapKMLs.map( ( item ) => item.id).join('')
    const myMapsUrl = (fileId: string) => `https://www.google.com/maps/d/kml?mid=${fileId}`;
    useEffect(() => {
        const kmlUrl = kmlIdHash && myMapsUrl(kmlIdHash)
        if (map && kmlUrl) {
            addKMLLayer(kmlUrl, map)
        }

    }, [kmlIdHash])

  

}