import {useState, useEffect} from 'react'
import { Card, Typography, Box} from '@mui/material'

// import { useStyles } from '../../cosmetics/communityStyles';


import { ValidModelObject } from '@iotv/datamodel';

type WeatherCardProps = {
    customerIn:ValidModelObject<'Customer'> | undefined;

}

type DailyWeatherReport = {
  day: string;
  weatherIcon: string;
};

export const WeatherCard = ( props:WeatherCardProps ) => {

    useEffect(() => {
        const customerIn = props.customerIn

        if (customerIn) {
          fetchWeather(customerIn);
          }
      }, [props.customerIn?.sk]);
    
      const [communityWeather, setCommunityWeather] = useState<DailyWeatherReport[]>([]);
  
      /**
     * Fetches weather data for current community from its position
     */
    const fetchWeather = (community: ValidModelObject<'Customer'>) => {
      // const communityPosition = getLocation()
      const dailyWeatherReport: DailyWeatherReport[] = [];
  
      const weatherData = community.weather_data?.weatherData
  
      if (weatherData) {
        const dailyWeather = weatherData.daily;
  
        dailyWeather?.forEach((day: any) => {
          const date = new Date(day.timestamp * 1000);
          let currentDay: DailyWeatherReport = {
            day: '',
            weatherIcon: '',
          };
  
          switch (date.getDay()) {
            case 0:
              currentDay.day = 'Sun';
              break;
            case 1:
              currentDay.day = 'Mon';
              break;
            case 2:
              currentDay.day = 'Tues';
              break;
            case 3:
              currentDay.day = 'Weds';
              break;
            case 4:
              currentDay.day = 'Thurs';
              break;
            case 5:
              currentDay.day = 'Fri';
              break;
            case 6:
              currentDay.day = 'Sat';
              break;
          }
  
          currentDay.weatherIcon = day.weather.icon;
          dailyWeatherReport.push(currentDay);
        });
      }
  
      setCommunityWeather([...dailyWeatherReport]);
    };

    return (
        <Box display='flex' flexWrap='nowrap' bgcolor='background.paper'>
          {communityWeather &&
            (communityWeather.length > 0 ? (
              communityWeather.map((forecast) => (
                <Box bgcolor='grey.300' sx={{ paddingInline : '5px', textAlign: 'center', color: 'black'}}>
                  {forecast.day}                  
                  <br />
                  <img src={forecast.weatherIcon} />
                </Box>
              ))
            ) : (
              <Typography variant='h6' align='center'>
                Weather data unavailable
              </Typography>
            ))}
        </Box>
    )
}