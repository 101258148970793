import { useState, useEffect } from 'react';
import { Grid, Card, Typography, Box } from '@mui/material';

import { useStyles } from '../../cosmetics/communityStyles';

import { ValidBusinessObject, ValidModelObject, ObjectHistory } from '@iotv/datamodel';
import { Tank, ValidModelObjects } from '@c021/datamodel';

type CommunitySummaryProps = {
  householdTanks: ValidModelObjects<'Tank'>;
  ICCTanks: ValidModelObjects<'Tank'>;
  customerIn: ValidModelObject<'Customer'> | undefined;
  objectHistory: Partial<ObjectHistory>;
};

type DailyWeatherReport = {
  day: string;
  weatherIcon: string;
};

export const CommunitySummary = (props: CommunitySummaryProps) => {
  useEffect(() => {
    if (props.customerIn) {
      const allTanks = [...props.householdTanks, ...props.ICCTanks];
      const totalVolme = allTanks.reduce((acc, curr) => {
        if (curr) {
          const tank = new Tank(curr);
          acc = acc + tank._totalVolume;
        }
        return acc;
      }, 0 as number);

      //const totalVolme = allTanks.reduce( ( acc, curr ) => { if ( typeof curr.total_volume === 'number' ) { acc = acc + curr.total_volume }; return acc}, 0 as number)
      const totalCurrentVolume = allTanks.reduce((acc, curr) => {
        if (typeof curr.current_filled_volume === 'number') {
          acc = acc + curr.current_filled_volume;
        }
        return acc;
      }, 0 as number);
      setEntity(props.customerIn);

      setCommunityTanks([...props.householdTanks, ...props.ICCTanks]);
    }
  }, [props.customerIn?.sk, props.householdTanks.length, props.ICCTanks.length]);

  const styles = useStyles();

  const [entity, setEntity] = useState<ValidModelObject<'Customer'> | undefined>(props.customerIn);
  const [communityTanks, setCommunityTanks] = useState<ValidModelObjects<'Tank'>>([]);

  /**
   * Calculates the number of tank users for all of the tanks in the community
   * @returns A number representing the total number of tank users in the community
   */
  const numTankUsers = () => {
    const numTankUsers = communityTanks.reduce((acc: number, curr) => {
      return (acc += !isNaN(curr.no_of_users) ? curr.no_of_users : 0);
    }, 0);
    return numTankUsers;
  };

  const calculateForecastCollection = () => {
    const forecastCollection = communityTanks.reduce((acc: number, curr) => {
      if (curr.forecastCollectionInSevenDays) {
        return (acc += curr.forecastCollectionInSevenDays);
      }
      return acc;
    }, 0);
    return Math.round(forecastCollection);
  };

  const getRunOutDate = () => {
    const community_days_left = entity?.community_days_left;

    const currentDate = new Date();
    const runOutDate = new Date(currentDate);
    runOutDate.setDate(runOutDate.getDate() + community_days_left);

    return runOutDate.toLocaleDateString();
  };

  const formatNumber = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <Typography variant='h6'>Current Volume</Typography>
          <Typography>{entity?.current_filled_volume ? formatNumber(Math.round(entity.current_filled_volume)) : 0} L</Typography>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <Typography variant='h6'>Volume Used Yesterday</Typography>
          <Typography>
            {props.objectHistory?.dailyStateHistory?.community_usage_in_day.slice(-1)[0]
              ? formatNumber(Math.round(props.objectHistory?.dailyStateHistory?.community_usage_in_day.slice(-1)[0]))
              : 0}{' '}
            L
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <div>
            <strong>Tanks In Community</strong> : {communityTanks.length}
          </div>
          <div>
            <strong>Water Users in Community</strong> : {numTankUsers()}
          </div>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <Typography variant='h6'>Days Remaining</Typography>
          <Typography>{entity?.community_days_left}</Typography>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <Typography variant='h6'>Run Out Date</Typography>
          <Typography>{getRunOutDate()}</Typography>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={styles.card}>
          <Typography variant='h6'>Forecast Collection for next 7 days</Typography>
          <Typography>{formatNumber(Math.round(calculateForecastCollection() * 100) / 100)} L</Typography>
        </Card>
      </Grid>
    </Grid>
  );
};
