import { Assembler, BusinessObjectAssembler, Customer, CustomerProps, DatastoreObjectType, ErrData, Gateway, GatewayProps, GatewayProvisioningRecordType, getZombieInstanceFromPrimaryKey, Milliseconds, ValidBusinessObject, ValidBusinessObjectList, ValidModelType } from '@iotv/datamodel';
import { AdjacentType, AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import config from '../../../../config';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { getNiceDate, getPseudoVBO } from '../../../../data/TypeHelpers';
import { ComponentMode, CustomerGatewayViewProps, DeleteGatewayRequest, GatewayPoolType, GetGatewayRequest, ListerlizerProps, MessageOutputType, SearchParamsType, TabilizerProps, Unit, UpdateGatewayRequest, UserTransactionType, UserView, ViewDefinition } from '../../../../types/AppTypes';
import { getVOBAttributeHash } from '../../../../util/AppData/viewObject';
import { getQueryParamsFromHistory } from '../../../../util/Routing/queryParams';
import { getViewDefinitionFromUserAttributes } from '../../../factories/AttributeDefinitions';
import { Listerlizer } from '../../../factories/Listilizer';
import { Tabilizer } from '../../../factories/Tabilizer';
import { NavSections } from '../../../navigation/NavSections';
import { NavBreadcrumbs } from '../../../utils/Breadcrumbs';

const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = 'SystemManagement';
const componentClassName = 'CustomerGatewayView'
const parentHasRendered = () => (document.querySelector(`.${componentClassName}`))
const assembler = new BusinessObjectAssembler();

const gatewayTemplate = { type: 'Gateway', _permittedAWOL: Milliseconds.HOUR }

const getGatewayQuery = async (fn: (params: any) => void, getGatewayRequest: GetGatewayRequest) => {
  try {
    const response = await ApiGateway.post('/system/Gateway/getGateway', getGatewayRequest, apiRef);
    debug && console.log('getGatewayQuery query results', response)
    if (response.data?.Item) {
      const mutatedItem = { ...response.data.Item /*possibly Metadata*/ }
      fn(mutatedItem)
    }
  } catch (e) {
    debug && console.log('Error in Gateway query', e)
  }
}




const getCustomerQuery = async (fn: (params: any) => void, contextUser: UserView, requestedCustomerSk: string) => {
  if (contextUser) {
    const contextCustomer = assembler.getZombieFromPrimaryKey(requestedCustomerSk)
    try {
      debug && console.log('getCustomerQuery', contextUser)
      const response = await ApiGateway.post(`/system/Customer/get`, { contextUser, contextCustomer }, apiRef);
      debug && console.log('getCustomerQuery results', response)
      if (response.data) {
        fn(response.data)
      } else {
        throw new Error(`Too many customers receied: ${JSON.stringify(response, null, 1)}`)
      }
    } catch (e) {
      debug && console.log('Error in CM query', e)
    }
  } else {
    debug && console.log('No context user')
  }
}

const getCustomerHierachyQuery = async (fn: (params: any) => void, contextUser: UserView, contextCustomer: ValidBusinessObject | undefined) => {
  if (contextCustomer) {
    debug && console.log('Doing hierarchy query')
    try {
      const response: ErrData<ValidBusinessObjectList> = await ApiGateway.post(`/system/Customer/parents`, { contextUser, contextCustomer }, apiRef);
      if (response.data) {
        debug && console.log('hierarchy query res', response.data)
        fn(response.data)
      } else {
        throw new Error(`Problem in HierarchQuery: ${JSON.stringify(response, null, 1)}`)
      }
    } catch (e) {
      debug && console.log('Error in Hierachy query', e)
    }


  }
}

function CustomerGatewayView(props: CustomerGatewayViewProps): JSX.Element {
  //debug && console.log('User Management View got', props)

  const contextUser = props.user;
  const [parentCustomers, setParentCustomers] = React.useState<ValidBusinessObjectList>([]);
  const [gatewayInCreation, setGatewayInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [contextCustomer, setContextCustomer] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined);
  //const contextCustomer = searchParams?.contextObjectSK ? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK) : undefined;
  const [originalVersion, setOriginalVersion] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [selectedGateway, setSelectedGateway] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [editMode, setEditMode] = React.useState(false)
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);

  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const routerHistory = useHistory();
  const searchParams = getQueryParamsFromHistory<SearchParamsType>(props.history)

  const requestedGatewaySk: string | undefined = props.match.params.id
  const contextCustomerSk: string | undefined = searchParams?.contextObjectSK;

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const cancelRedirectUrl = queryString.stringifyUrl({
    url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomerSk}`,
    query: {
      tabName: 'Gateways'
    }
  })



  useEffect(() => {
    debug && console.log('used effect to init context customer', { contextCustomerSk, action })
    if (contextCustomerSk) {
      getCustomerQuery(setContextCustomer, props.user, contextCustomerSk);
    }
  }, [contextCustomerSk]);

  useEffect(() => {
    debug && console.log('used effect to init Gateway')
    if (action === UserTransactionType.CREATE) {
      createNewGatewayProcess();
      // getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);
    } else {
      getGatewayQueryWrapper()
      //  getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);
    }
  }, [action, props.user, requestedGatewaySk, contextCustomer?.sk]);

  useEffect(() => {
    if (contextCustomer) {
      debug && console.log('used effect to get parent hierarchy')
      const updateFn = (customerList: ValidBusinessObjectList) => {
        // const thisContextCustomer = customerList.find( (item) => item.sk === contextCustomer.sk);
        // if (thisContextCustomer) Object.assign(contextCustomer, thisContextCustomer);
        setParentCustomers(customerList)
      }
      if (contextCustomer.sk) {
        getCustomerHierachyQuery(updateFn, props.user, contextCustomer as DatastoreObjectType & ValidBusinessObject);
      }
    }
  }, [contextCustomer?.sk]);

  // useEffect(() => {

  // }, [editMode])

  const getGatewayQueryWrapper = () => {
    if (props.user?.type === 'User' && contextCustomer?.type === 'Customer' && requestedGatewaySk) {
      const customerClassInstance = new Customer(contextCustomer as CustomerProps)
      debug && console.log(`${customerClassInstance.name} can have inventory? ${customerClassInstance.canManageGateways()}`)
      const requestedGateway = getZombieInstanceFromPrimaryKey(requestedGatewaySk) as ValidBusinessObject & { type: 'Gateway' };
      const contextRequest: GetGatewayRequest = {
        contextUser: props.user as ValidBusinessObject & { type: 'User' },
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
        gateway: requestedGateway,
        gatewayPoolType: customerClassInstance.canManageGateways() ? GatewayPoolType.INVENTORY : GatewayPoolType.ALLOCATION
      }
      debug && console.log('getGatewayQueryWrapper query params', contextRequest)
      getGatewayQuery((gateway) => { setSelectedGateway(gateway); setOriginalVersion(gateway) }, contextRequest)
    } else {
      debug && console.log('did not run list customer query', { selectedGateway, contextCustomer, requestedGatewaySk })
    }
  }

  const updateToDatabase = async (gateway: ValidBusinessObject | undefined) => {

    if (gateway && contextCustomer && props.user?.type === 'User') {

      const updateRequest: UpdateGatewayRequest = { contextUser, contextCustomer, gateway: selectedGateway, gatewayPoolType: GatewayPoolType.INVENTORY } as UpdateGatewayRequest
      const response = await ApiGateway.post('/system/Gateway/updateGateway', updateRequest, apiRef);
      debug && console.log('Update response', { updateRequest, response })
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else if (requestedGatewaySk) {
        getGatewayQueryWrapper();

        displayMessage(`Updated ${gateway?.name} in datastore`, 'SnackBar')
      }
    }
  }

  const removeFromDatabase = async (gateway: ValidBusinessObject | undefined) => {
    if (gateway && contextCustomer) {
      const response = await ApiGateway.post('/system/Gateway/deleteGateway', { contextUser, contextCustomer, gateway: selectedGateway, gatewayPoolType: GatewayPoolType.INVENTORY } as DeleteGatewayRequest, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else if (requestedGatewaySk) {
        routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomer.sk}?tabName=${'Gateways'}`);
        displayMessage(`Removed ${gateway?.name} from datastore`, 'SnackBar')
      }
    }
  }


  const addToGatewayInventory = async () => {
    if (gatewayInCreation && contextCustomer) {
      const customerClassInstance = new Customer(contextCustomer as CustomerProps)
      if (customerClassInstance.canManageGateways()) {
        const response = await ApiGateway.post('/system/Gateway/addToInventory', { contextUser: props.user, gateway: gatewayInCreation, contextCustomer }, apiRef);
        debug && console.log('Add response', response)
        if (response.err) {
          displayMessage(response.err.message, 'MessageBar')
        } else {
          routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomer.sk}?tabName=${'Gateways'}`);
          displayMessage(`Added ${gatewayInCreation.name} to database`, 'SnackBar')
          setGatewayInCreation(undefined);
        }
      } else {
        displayMessage(`${customerClassInstance.name || 'Customer'} is not allowed gateway inventory`, 'MessageBar')
      }

    } else {
      displayMessage('No inventory customer or new gateway', 'MessageBar')
    }


  }

  const isEdited = () => {
    const edited = selectedGateway && originalVersion && !Object.entries(selectedGateway).filter(([k, v1]) => typeof v1 !== 'object').every((([k, v1]) => v1 === originalVersion[k]));
    // debug && console.log('Is Edited', { edited, selectedCustomer, originalVersion})
    // note still needs to equate "" to undefined really
    return edited;
  }

  const wasEdited = isEdited()


  const createNewGatewayProcess = () => {
    setSelectedGateway(undefined);
    setGatewayInCreation( Assembler.getInstance( { ...gatewayTemplate } ) );
  }

  const proxyParent = contextCustomer ?? assembler.getInstanceFromPrimaryKey('Customer:1')
  const itemToUrlFn = (item: ValidBusinessObject) => `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/${item.type}/${item.sk}`
  const gatewayClassInstance = selectedGateway || gatewayInCreation ? new Gateway(selectedGateway as Partial<GatewayProps> || gatewayInCreation as Partial<GatewayProps>) : undefined
  type GatewayRequestKeys = keyof GatewayProps

  const autoViewDef = gatewayClassInstance ? getViewDefinitionFromUserAttributes(gatewayClassInstance.getUserAttributes()) : {};
  const gatewayTableViewDefinition: ViewDefinition & { [key in GatewayRequestKeys]?: any } = gatewayClassInstance ? {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: new RegExp(/\S+/), failsValidationText: 'Gateway must be named' },
   ...autoViewDef
  } : {}

   if ( debug ) {
     const tableObhash =  getVOBAttributeHash(gatewayInCreation ?? {});
     console.log('TABLE HASH ', tableObhash)
   }

  const gatewayTableProps: TabilizerProps = {
    ...props,
    matchedPrimary: (selectedGateway ?? gatewayInCreation ?? proxyParent) as ValidBusinessObject,
    actions: [action as UserTransactionType],
    viewDefinition: gatewayTableViewDefinition,
    
    functionOverrides: {
      updateParentComponentItem: (item: ValidBusinessObject) => {
        if (selectedGateway) setSelectedGateway(item);
        else if (gatewayInCreation) setGatewayInCreation(item)
      },
      mode:  (gatewayInCreation || editMode) ? ComponentMode.EDIT : ComponentMode.VIEW,
      hasValidationErrors: () => {

        if (parentHasRendered()) {
          const hasErrors = (document.querySelector('.Mui-error') !== null);
          setHasValidationErrors(hasErrors)
        } else {
          debug && console.log('parent not rendered')
        }
      }
    },




    injectedComponents: action === UserTransactionType.CREATE
      ? [
        <ButtonGroup key='addGatewayBG'>
          <Button
            size="small"
            onClick={() => addToGatewayInventory()}
            disabled={hasValidationErrors}
            startIcon={<GroupAddIcon />}
            variant="outlined"
            color="primary"
          >
            Add to Inventory
            </Button>
          <Button
            size="small"
            onClick={() => createNewGatewayProcess()}
            disabled={gatewayInCreation && (Object.values(gatewayInCreation).find((val) => val !== 'Gateway' && val !== undefined)) === undefined}
            startIcon={<ClearIcon />}
            variant="outlined"
            color="primary"
          >
            Clear
            </Button>
          <Button onClick={() => { setGatewayInCreation(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
            size="small"
            startIcon={<CancelIcon />}
            variant="outlined"

          >
          </Button>
        </ButtonGroup>
      ]
      : [
        <ButtonGroup key='newUserBG' disabled={editMode}>

        </ButtonGroup>,
        <ButtonGroup key='editUserBG'>
          <Button
            size="small"
            onClick={() => removeFromDatabase(selectedGateway)}
            disabled={!selectedGateway}
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="primary"
          >
            Delete
            </Button>
          <Button
            size="small"
            onClick={() => { debug && console.log( 'BEfore click edit mode was', editMode); setEditMode( !editMode ) }}
            disabled= {!selectedGateway || editMode}
            startIcon={<EditIcon />}
            variant="outlined"
            color="primary"
          >
            Edit
            </Button>

          {selectedGateway && <Button
            size="small"
            onClick={() => { updateToDatabase(selectedGateway) }}
            disabled={!editMode || wasEdited !== true}
            startIcon={<SaveIcon />}
            variant="outlined"
            color="primary"
          >
            Save
            </Button>}
          <Button
            size="small"
            onClick={() => { setSelectedGateway(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
            disabled={!editMode && gatewayInCreation && (Object.values(gatewayInCreation).find((val) => val !== 'User' && val !== undefined))}
            startIcon={<CancelIcon />}
            variant="outlined"

          >
          </Button>
        </ButtonGroup>
      ]
  }

  debug && console.log('Customer Gateway View state ', { selectedGateway, gatewayInCreation, contextCustomer, requestedUserSk: requestedGatewaySk })

  const pseudoTypeId = 'ProvisioningRecord';
  const gatewayProvisioningRecordListProps: ListerlizerProps = {
    ...props,
    autoQuery: false,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: pseudoTypeId as ValidModelType,
    },
    viewDefinition: {
      provisionDate: { key: 'provisionDate', label: 'Date', type: AppValueTypes.STRING, editable: false, unit: Unit.Date, precision: undefined, stateFn: undefined, validationRx: undefined },
      inventoryCustomer: { key: 'inventoryCustomer', label: 'Assignor Customer', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
      allocationCustomer: { key: 'allocationCustomer', label: 'Assignee Customer', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined }

    },
    viewObject: {
      matchedPrimary: selectedGateway, matchedRelatedByPk: [], matchedRelatedBySk: selectedGateway?.provisioning instanceof Array
        ? selectedGateway.provisioning.map((item: GatewayProvisioningRecordType) =>
          getPseudoVBO({
            type: pseudoTypeId, name: `PR_${item.provisionDate}`, id: `PR_${item.provisionDate}`, ...item,
            inventoryCustomer: (item.inventoryCustomer as ValidBusinessObject)?.name,
            allocationCustomer: (item.allocationCustomer as ValidBusinessObject)?.name,
            provisioningTime: item.provisionDate,
            provisionDate: item.provisionDate ? getNiceDate(item.provisionDate) : undefined
          })) : undefined
    },
    maxItems: 50,
    sortKeyIn: 'provisionDate',
    contextObject: undefined,
    selectorConfigParams: {
      controls: { add: false, find: false },
      enabled: false,
      multipleSelect: false
    },
    optionals: {

    },
    injectedComponents: []


  }

  return (<>
    {((action === UserTransactionType.UPDATE && selectedGateway) || action === UserTransactionType.CREATE) && <>
      { contextCustomer && <NavBreadcrumbs {...{ items: parentCustomers ?? [contextCustomer], itemToUrlFn, current: selectedGateway }}></NavBreadcrumbs>}

      <Grid className={componentClassName} container spacing={3} >

        <Grid item xs={12} lg={6}>

          <Card>
            <CardContent>
              <Typography variant="h6" component="h4" gutterBottom>
                {selectedGateway?.eui ?? 'Gateway'}
              </Typography>
              {(selectedGateway || gatewayInCreation) && <Tabilizer key={'gatewayInputTable'} {...{ ...gatewayTableProps }}></Tabilizer>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>

              <Typography variant="h6" component="h4" gutterBottom>
                {selectedGateway?.provisioning && 'Provisioning History'}
              </Typography>



              <Listerlizer {...{ key: 'provisioningRecordTable', ...gatewayProvisioningRecordListProps }}></Listerlizer>
            </CardContent>
          </Card>
        </Grid>



      </Grid>
    </>}</>
  );

}



export default withStyles(styles)(CustomerGatewayView)