import { Assembler, isValidBusinessObject, QueryTypes, ValidModelObject, ValidModelObjects, ValidModelType } from "@iotv/datamodel"
import { AdjacentType, ValidBusinessObject, ValidBusinessObjectList } from "@iotv/iotv-v3-types"
import { FormControl, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect } from "react"
import config from '../../config'
import { useStyles } from "../../cosmetics/InputStyles"
import { denseTheme } from "../../cosmetics/Themes/dense"
import ApiGateway from "../../data/aws/api-gateway/ApiGateway"
import { useDocumentClientQuery } from "../../hooks/useDocumentClientQueryV1"
import { ListLimitedAllTypeRequestBody } from "../../types/AppTypes"
import { getVOBListHash } from "../../util/AppData/viewObject"

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = config.app.appName;
const noOptions = 'No options available'
const requestSetOption: string = 'Please set option'

type PossibleListLimitedAllTypeRequestBody =  Omit<ListLimitedAllTypeRequestBody, 'objectTypeId'> & { objectTypeId: ValidModelType | undefined } 

type SingleObjectAutoCompleteProps<S extends ValidModelType, T extends ValidModelType> = { contextObject: ValidModelObject<S> | undefined, adjacencyType: AdjacentType,  objectTypeId: ValidModelType | undefined, 
    currentEntity: ValidModelObject<T> | undefined, setCurrentEntity: (vob: ValidModelObject<T> | undefined) => void , style?: React.CSSProperties  
}
export const SingleObjectAutoComplete = <S extends ValidModelType, T extends ValidModelType>({ contextObject: contextObjectIn, adjacencyType, objectTypeId: objectTypeIdIn, currentEntity, setCurrentEntity, style}: SingleObjectAutoCompleteProps<S, T>) => {
    const allClasses = useStyles(denseTheme)
    const { radio, expand, smaller, expandOpen , ...classes } = allClasses; 
    const [objectTypeId, setObjectTypeId] = React.useState<ValidModelType | undefined>( undefined )
    const [contextObject, setContextObject] = React.useState<ValidModelObject<S> | undefined>(contextObjectIn);
    

    useEffect(() => {
        debug && console.log('SingleObjectAutoComplete used effect on objectTypeId', {objectTypeId, objectTypeIdIn })
        setObjectTypeId( objectTypeIdIn )
    }, [ objectTypeIdIn ])


    useEffect(() => {
        debug && console.log('SingleObjectAutoComplete used effect on currentEntity', {currentEntity})
    }, [ currentEntity?.sk ])


    const queryParams: PossibleListLimitedAllTypeRequestBody = {
        ExclusiveStartKey: undefined,
        limit: 50,
        contextObject: contextObject,
        excludeRelatedToObject: undefined,
        includeEdges: false,
        includeNodes: true,
        adjacencyType,
        objectTypeId: objectTypeId,
        queryType: QueryTypes.LIST_LIMITED_ALL_TYPES
    };

    const query = async (fn: (params: any) => void, contextRequest: PossibleListLimitedAllTypeRequestBody) => {

        if ( contextRequest.objectTypeId ) {
            debug && console.log('SingleObjectAutoComplete query request', contextRequest)
            try {
                const response = await ApiGateway.post(`/query/`, contextRequest, apiRef);
                if (response.data?.Items) {
                    response.data.Items = response.data.Items.map((zombie: ValidBusinessObject) => Assembler.getInstance(zombie))
                }
                debug && console.log('SingleObjectAutoComplete query results', response)
                fn(response);
            } catch (e) {
                debug && console.log('Error in UM query', e)
            }
        } else {
            debug && console.log('SingleObjectAutoComplete ignored query request', contextRequest)
        }
      
    }

    const queryHook = useDocumentClientQuery(query, queryParams, [contextObject, objectTypeId])
    const [
        [items, setItems],
        [limit, setLimit],
        [LastEvaluatedKeys, setLastEvaluatedKeys],
        [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

    const notifiableEntitiesHash = getVOBListHash(items as ValidBusinessObjectList)

    useEffect(() => {
        if (contextObject?.type === 'Customer') setContextObject(contextObject)
    }, [contextObject?.sk])

    useEffect(() => {
        console.log('SingleObjectAutoComplete Used effect on VOB Hash')
    }, [notifiableEntitiesHash])

    const componentMap = {
        entity: <FormControl classes = { classes} >
            {  <Autocomplete {...{
                freeSolo: false, id: 'selectEntity', style, size: 'small', options: items as ValidModelObjects<T>, 
                value: currentEntity ?? { type: 'nothing '} as ValidModelObject<T> ,
                getOptionSelected: ( option: ValidModelObject<T> ) => option?.sk === currentEntity?.sk,
                getOptionLabel: (option: ValidModelObject<T>) => option.name ?? option.sk ?? ( items.length > 0 ? requestSetOption :  noOptions ),
                onChange: (e, v: ValidModelObject<T> | string | null) => {
                    v && isValidBusinessObject(v as ValidModelObject<T>) && setCurrentEntity(v as ValidModelObject<T>)
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: objectTypeId,
                }} />
            }} /> }
            
        </FormControl>,
 
    }
    return componentMap.entity
}

type MultipleObjectAutoCompleteProps<S extends ValidModelType, T extends ValidModelType> = { contextObject: ValidModelObject<S> | undefined, adjacencyType: AdjacentType, objectTypeId: ValidModelType, 
    currentEntities: ValidModelObjects<T>, setCurrentEntities: (vobs: ValidModelObjects<T> ) => void , style?: React.CSSProperties
    multiple?: boolean, label?: string
    
}
export const MultipleObjectAutoComplete = <S extends ValidModelType, T extends ValidModelType >({ contextObject: contextObjectIn, adjacencyType, objectTypeId: objectTypeIdIn, currentEntities, setCurrentEntities, multiple = true, style, label}: MultipleObjectAutoCompleteProps<S, T>) => {
    
    const allClasses = useStyles(denseTheme)
    const { radio, expand, smaller, expandOpen , ...classes } = allClasses; 
    const [objectTypeId, setObjectTypeId] = React.useState<ValidModelType | undefined>( objectTypeIdIn )
    const [contextObject, setContextObject] = React.useState<ValidModelObject<S> | undefined>(contextObjectIn);


    useEffect(() => {
        debug && console.log('MultipleObjectAutoComplete used effect on objectTypeId', {objectTypeId, objectTypeIdIn })
        setObjectTypeId( objectTypeIdIn )
    }, [ objectTypeIdIn ])

    const queryParams: ListLimitedAllTypeRequestBody = {
        ExclusiveStartKey: undefined,
        limit: 50,
        contextObject: contextObject as ValidBusinessObject,
        excludeRelatedToObject: undefined,
        includeEdges: false,
        includeNodes: true,
        adjacencyType,
        objectTypeId: objectTypeId ?? '',
        queryType: QueryTypes.LIST_LIMITED_ALL_TYPES
    };

    const query = async (fn: (params: any) => void, contextRequest: ListLimitedAllTypeRequestBody) => {
        debug && console.log('MultipleObjectAutoComplete query request', contextRequest)
        try {
            const response = await ApiGateway.post(`/query/`, contextRequest, apiRef);
            if (response.data?.Items) {
                response.data.Items = response.data.Items.map((zombie: ValidBusinessObject) => Assembler.getInstance(zombie))
            }
            debug && console.log('MultipleObjectAutoComplete query results', response)
            fn(response);
        } catch (e) {
            debug && console.log('Error in UM query', e)
        }
    }

    const queryHook = useDocumentClientQuery(query, queryParams, [contextObject, objectTypeId])
    const [
        [items, setItems],
        [limit, setLimit],
        [LastEvaluatedKeys, setLastEvaluatedKeys],
        [ExclusiveStartKey, setExclusiveStartKey]] = queryHook.querySetterGetters;

    const notifiableEntitiesHash = getVOBListHash(items as ValidBusinessObjectList)

    useEffect(() => {
        if (contextObject?.type === 'Customer') setContextObject(contextObject)
    }, [contextObject?.sk])

    useEffect(() => {
        console.log('Used effect on VOB Hash')


    }, [notifiableEntitiesHash])

    const componentMap = {
        entity: <FormControl classes = { classes} >
            <Autocomplete {...{
                value: currentEntities,
                freeSolo: false, id: 'selectEntity', style, size: 'small', options: items as ValidModelObjects<T>,  multiple, filterSelectedOptions: true,
                getOptionLabel: (option: ValidModelObject<T> ) => option.name ?? noOptions,
                onChange: (e, v: ValidModelObjects<T> | ValidModelObject<T> | null) => {
                    const update = v instanceof Array ? v : [ v ];
                    setCurrentEntities( update.filter(( v) => v !== null) as ValidModelObjects<T> )
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: label ?? objectTypeId,
                }} />
            }} />
        </FormControl>,
 
    }
    return componentMap.entity
}
