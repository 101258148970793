import React, { useEffect } from 'react';
import { convertHistoryObjectToDataTable } from '../../data/TypeHelpers';
// import { convertHistoryObjectToDataTable, getValueLabel } from '../../../data/TypeHelpers';
import { LineChartOptionsMandatory, LineChartXType } from '../../../../types/AppTypes';
import { HistoryObjectType } from '@iotv/iotv-v3-types'
import { Slider, SliderTypeMap, Button } from '@material-ui/core';
import LineChart from '../../../../dataVisualization/Google/LineChart';
// import config from '../../../config';
// import { count } from 'console';

const debug = process.env.REACT_APP_DEBUG && false;

type RangeableLineChartProps = {
  historyObject: HistoryObjectType, keys: string[], xType: LineChartXType, classes: any, lowIdx: number, maxIdx: number
}

// export const RangeableLineChart = (props: RangeableLineChartProps) => {
export const RangeableLineChart = (props: any) => {
  const { historyObject, keys, xType, classes } = props;
  const maxIdx = historyObject.index.length - 1;
  let lowIdx = props.lowIdx 
  debug && console.log("props:", props)
  debug && console.log({ lowIdx, maxIdx })
  const initialIdxRange = [props.lowIdx, props.maxIdx];
  const [slice, setSlice] = React.useState(initialIdxRange as number | number[]);

  useEffect(() => {
  }, [slice, historyObject.index.length]);

 debug && console.log({ slice, initialIdxRange })
  debug && slice instanceof Array && console.log(`length: ${historyObject.index.length} start: ${slice[0]} end: ${slice[1]} sliceLength: ${slice[1] - slice[0]} `)
  const validSlice = slice instanceof Array ? slice : [0, slice]


  const getGraphTitle = () => {
    // debug &&  console.log('keuyo',keys[0])
    switch (keys[0]) {
      case "current_filled_volume":
      return "Tank Fill Volume"
      case "current_filled_percentage":
      return "Tank Fill Percentage"
      case "usage_in_day":
        return 'Daily Usage'
      case "usage_in_day_percentage":
        return 'Daily Usage Percentage'
      case "formattedCFV":
        return 'Fill Level'
      case "formattedCFVPercentage":
        return 'Fill Level'
        case "formattedGroupCFV":
          return 'Community Fill Volume'        
        case "formattedGroupCFVPercentage":
          return 'Community Fill Percentage'
        case "community_usage_in_day":
          return 'Community Daily Usage'          
        case "formattedDUGroupPercentage":
          return 'Community Daily Usage Percentage'
          case "volume_collected_period":
            return "Cummulative Volume Collected (mm)"
      default:
        return keys.join('_')
    }
  }
  const options: google.visualization.LineChartOptions & LineChartOptionsMandatory = {
    // id: keys.includes("usage_in_day") || keys.includes("usage_in_day_percentage") ? 'Tank Usage' : keys.join('_'),
    // title: keys.includes("usage_in_day") || keys.includes("usage_in_day_percentage") ? 'Tank Usage' : keys.map((key) => getValueLabel(key)).join(', '),
    id: getGraphTitle(),
    title: getGraphTitle(),
    height: 300,
    legend: 'none'
  }


  const getKeysArray = () => {
    let keysArray = []
    const title = getGraphTitle()
    keysArray.push(title)
    debug &&  console.log('keysArray', keysArray)
    return keysArray
  }
  const keys2 = getKeysArray()
  debug && console.log({ keys, keys2 })
  debug && console.log({historyObject, keys, xType, initialIdxRange});
  
  const data: google.visualization.DataTable = convertHistoryObjectToDataTable(historyObject, keys, xType, initialIdxRange);
  const chartProps = {
    options,
    data, 
    name: getGraphTitle()
  }
  
  debug && console.log('keys:', keys[0])
  return <LineChart {...chartProps}> </LineChart>
}
