import config from "../../config";

const debug = process.env.REACT_APP_DEBUG && false;

export const gapiIsPresent = (): boolean => {
    const scriptPresent = document.querySelector(`script[gapi_processed='true']`) !== null;
    return scriptPresent
}


export const mapiIsPresent = (): boolean => {
    const mapsApiScriptPresent = document.querySelector(`[src="${config.google.mapsApiUri}"]`) !== null;
    const gMapsStaticPresent  = document.querySelector(`[src^="https://maps.googleapis.com/maps-api-v3/"]`) !== null

    const allPresent = mapsApiScriptPresent && gMapsStaticPresent
    debug && console.log( mapsApiScriptPresent, gMapsStaticPresent, allPresent)
    return allPresent;

}