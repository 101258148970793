import React from 'react';
import config from '../config';
import {
  ComponentMode,
  DispatchFunctionsType,
  ObjectViewType,
  SearchParamsType,
  ThingsViewOptionals,
  ThingViewOptionals,
  UserFunctions,
  UserGroupsRolesType,
  UserTransactionType,
  ValidBusinessObject,
  ViewObject,
} from '../types/AppTypes';
import TankGroupCard from '../venture/c021/components/group/TankGroupCard';
import TankGroupListItem from '../venture/c021/components/group/TankGroupListItem';
import TankCard from '../venture/c021/components/TankCard';
import TankListItem from '../venture/c021/components/TankListItem';
import { UserCard as C021UserCard } from '../venture/c021/components/UserCard';
import C021HouseCard from '../venture/c021/components/HouseCard';
// import UserCard from './c021/UserCard';
import CustomerCard from '../venture/c021/components/CountryCommunity/CustomerCard';

import { DroughtManagementPlanV2 } from '../venture/c021/components/CountryCommunity/DroughtManagement/DroughtManagementPlanV2';

import NewDeviceCard from './cards/NewDeviceCard';
import GeofenceEditor from './common/Geofence';
import { RulesetCard } from './common/RulesetCard';
import ObjectCard from './generic/ObjectCard';
import ObjectCardTemplate from './templates/ObjectCardTemplate';
import ObjectListItem from './generic/ObjectListItem';
import ObjectListSingleLineItem from './generic/ObjectListSingleLineItem';
import ObjectTableRow from './generic/ObjectTableRow';

import { getQueryParamsFromHistory } from 'src/util/Routing/queryParams';

const debug = process.env.REACT_APP_DEBUG && false;

const specializedViewType = ['Ruleset', 'Template'];

type GetThingViewOfTypeProps = {
  viewObject: ViewObject;
  contextCustomer: ValidBusinessObject | undefined;
  transactionFunctions: DispatchFunctionsType;
  userFunctions: UserFunctions;
  objectViewType: ObjectViewType;
  history: History;
  searchParams?: SearchParamsType;
  user: ValidBusinessObject | undefined;
  userGroupRoles: UserGroupsRolesType | undefined;
  userSelectedGroup?: string;
  optionals?: ThingViewOptionals | ThingsViewOptionals;
  LargerThanMobile?: boolean;
};

const getThingViewOfType = ({
  viewObject,
  contextCustomer,
  transactionFunctions,
  userFunctions,
  objectViewType,
  history,
  searchParams,
  user,
  userGroupRoles,
  userSelectedGroup,
  optionals,
  LargerThanMobile,
}: GetThingViewOfTypeProps): JSX.Element | undefined => {
  let view = undefined;

  const createObject = searchParams?.action ?? 'false';
  debug && console.log('searchParams', searchParams);

  debug && console.log('getThingViewOfType has ', { appCode: config.app.appCode, type: viewObject.matchedPrimary?.type, objectViewType });
  const objectTypeId = viewObject.matchedPrimary?.type;

  if (transactionFunctions === undefined) {
    debug && console.log('STOP TransactionFunctions is undediend');
  }

  if (objectTypeId && (optionals?.useSpecializedViews || specializedViewType.includes(objectTypeId))) {
    //} && createObject !== UserTransactionType.CREATE_AND_LINK) {
    debug && console.log('finding specitalized view with', { type: viewObject.matchedPrimary?.type, objectViewType });

    if (objectTypeId === 'Ruleset') {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <RulesetCard
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></RulesetCard>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'Tank' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <TankCard
              {...{ viewObject, user, transactionFunctions, userSelectedGroup, contextCustomer, userGroupRoles, userFunctions, history, searchParams }}
            ></TankCard>
          );
          break;
        // case ObjectViewType.ListItem: view = <ObjectListItem { ...{viewObject, transactionFunctions, userFunctions,  history, searchParams }} ></ObjectListItem>; break;
        case ObjectViewType.ListItem:
          view = <TankListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></TankListItem>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'User' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <C021UserCard
              {...{ user, userGroupRoles, contextCustomer, userSelectedGroup, viewObject, transactionFunctions, userFunctions, history, searchParams }}
            ></C021UserCard>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'House' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = <C021HouseCard {...{ viewObject, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}></C021HouseCard>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'AggregationGroup' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        // case ObjectViewType.Card: view = view = <ObjectCard  {...{ viewObject,user, transactionFunctions,contextCustomer,userGroupRoles, userFunctions, history, searchParams }}></ObjectCard>; break;
        case ObjectViewType.Card:
          view = view = <TankGroupCard {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></TankGroupCard>;
          break;
        case ObjectViewType.ListItem:
          view = <TankGroupListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></TankGroupListItem>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'Customer' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        // case ObjectViewType.Card: view = view = <ObjectCard  {...{ viewObject,user, transactionFunctions,contextCustomer,userGroupRoles, userFunctions, history, searchParams }}></ObjectCard>; break;
        case ObjectViewType.Card:
          view = view = (
            <CustomerCard
              {...{ viewObject, contextCustomer, userSelectedGroup, transactionFunctions, user, userGroupRoles, userFunctions, history, searchParams }}
            ></CustomerCard>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } else if (objectTypeId === 'DroughtManagementPlan' && config.app.appCode.startsWith('c021')) {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <DroughtManagementPlanV2
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></DroughtManagementPlanV2>
          );
          break;
        // case ObjectViewType.ListItem: view = <ObjectListItem { ...{viewObject, transactionFunctions, userFunctions,  history, searchParams }} ></ObjectListItem>; break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } else if (viewObject?.matchedPrimary?.type === 'Template') {
      switch (objectViewType) {
        // case ObjectViewType.Card: view = view = <ObjectCard  {...{ viewObject,user, transactionFunctions,contextCustomer,userGroupRoles, userFunctions, history, searchParams }}></ObjectCard>; break;
        case ObjectViewType.Card:
          view = view = (
            <ObjectCardTemplate
              {...{ viewObject, contextCustomer, userSelectedGroup, transactionFunctions, user, userGroupRoles, userFunctions, history, searchParams }}
            ></ObjectCardTemplate>
          );
          break;
        case ObjectViewType.ListItem:
          view = (
            <ObjectCardTemplate
              {...{ viewObject, contextCustomer, userSelectedGroup, transactionFunctions, user, userGroupRoles, userFunctions, history, searchParams }}
            ></ObjectCardTemplate>
          );
          break;
      }
    } else if (objectTypeId === 'Tank') {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <TankCard
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></TankCard>
          );
          break;
        // case ObjectViewType.ListItem: view = <ObjectListItem { ...{viewObject, transactionFunctions, userFunctions,  history, searchParams }} ></ObjectListItem>; break;
        case ObjectViewType.ListItem:
          view = <TankListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></TankListItem>;
          break;
      }
    } else if (objectTypeId === 'Device' && searchParams?.action === UserTransactionType.REGISTER) {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <NewDeviceCard
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></NewDeviceCard>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } else if (objectTypeId === 'Geofence') {
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <GeofenceEditor
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></GeofenceEditor>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    } // repeated for now
    else if (objectTypeId) {
      debug && console.log('rendering generic for', objectTypeId);
      switch (objectViewType) {
        case ObjectViewType.Card:
          view = view = (
            <ObjectCard
              {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
            ></ObjectCard>
          );
          break;
        case ObjectViewType.ListItem:
          view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
          break;
      }
    }
  } else if (objectTypeId) {
    debug && console.log('rendering generic for', { type: viewObject.matchedPrimary?.type, objectViewType });
    debug && console.log('enter here!');
    switch (objectViewType) {
      case ObjectViewType.Card:
        view = view = (
          <ObjectCard
            {...{ viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, searchParams }}
          ></ObjectCard>
        );
        break;
      case ObjectViewType.ListItem:
        view = <ObjectListItem {...{ viewObject, transactionFunctions, userFunctions, history, searchParams }}></ObjectListItem>;
        break;
      case ObjectViewType.TableItem:
        view = <ObjectTableRow {...{ viewObject, transactionFunctions, mode: ComponentMode.VIEW }}></ObjectTableRow>;
    }
  } else {
    debug && console.log('Not a business object, no MatchedPrimary or no type', viewObject);
  }
  return view;
};

export { getThingViewOfType };
