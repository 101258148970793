import { AdjacentType, BusinessObjectAssembler, ErrData, ErrDataPromise, getDeviceEnabledTypes, IBusinessObjectAssembler, MultipleDeviceControlTransactionRequest, NodeEdgeMapper, OpenWeatherData, PathDirection, ValidBusinessObject } from '@iotv/datamodel';
import { DatastoreObjectTypes, GetGetGraphTraversalRequest, GetGetGraphTraversalResponse, MapLike, NamedFields } from '@iotv/iotv-v3-types';
import { DocumentClient } from 'aws-sdk/lib/dynamodb/document_client';
import { GetWeatherRequest } from '../types/Weather';
import config from '../config';
import { GetAdjacentResponse, NormalizedData, RoleArbitrationInstanceType, SaveResponce, SearchByTypeResponse, UnlinkResponse, UserGroupsRolesType } from '../types/AppTypes';
import ApiGateway from './aws/api-gateway/ApiGateway';
import { showCognitoUserId } from './aws/DebugAuth';
import { put } from './aws/s3/UserBlobs';
import { deleteObject, getAdjacent, getAdjacent2, getLargeObject, getMultipleAdjacentTypes, link, save, searchByType, unlink } from './daoFunctions/daoFunctions';
import { getThingHistory } from './daoFunctions/historyFns';
import { addToNormalData, getAllThings, mergeNormalDatas } from './daoFunctions/stateFns';
import { userThingsQuery } from './RoleQueries/userThingsQuery';

const debug = process.env.REACT_APP_DEBUG && false;

const { REACT_APP_DEPLOYMENT_STAGE } = process.env

export const projectPrimaryItemTypes = ( appCode: string ): string[] => {
  const types: MapLike< string[]> = {
    v015: [ ],
    v016: [ ],
    c021: [ ],
    v017: [ 'House' ],
    v020: [ 'Field', 'House' ],     
    i001: process.env.REACT_APP_LESS ? [] : [ 'House' ],
    i301: [ ]
  }
  return types[appCode.substring(0, 4)] ?? [];
}



// prep for refactor
export const getPrimaryItemTypes = (appCode: string): string[] => {

  return [ 'Ruleset', 'Geofence', ...projectPrimaryItemTypes( appCode ), ...Object.keys(getDeviceEnabledTypes( appCode.substring(0, 4) )) ]
}

debug && console.log('processEnv', process.env)

class AppDao {
  apiGateway: typeof ApiGateway;
  assembler:  IBusinessObjectAssembler;
  save: (zombie: any) => Promise<SaveResponce>;
  link: (zombieA: any, zombieB: any, edgeTypeId?: any, edgeAttributes?: any) => Promise<any>;
  unlink: (zombieA: any, zombieB: any, edgeTypeId?: any, edgeAttributes?: any) => Promise<UnlinkResponse>;
  deleteObject: (zombie: any) => Promise<ErrData<ValidBusinessObject>>;
  searchByType: (contextObject: any, includeContextLinkedItems: boolean, typeId: any, lastEvaluatedKey: any) => Promise<SearchByTypeResponse>;
  getAdjacent: (object: any, type: any, direction: PathDirection, edgeTypeId?: string) => Promise<GetAdjacentResponse>;
  getAdjacent2: (excludeRelatedToObject: ValidBusinessObject | undefined, scopeDefinitingContextObject: ValidBusinessObject, adjacencyType: AdjacentType, objectTypeId: string, ExclusiveStartKey: DocumentClient.Key | undefined, limit: number | undefined, edgeTypeId?: string | undefined,) => ErrDataPromise<DocumentClient.QueryOutput>;
  getMultipleAdjacentTypes: ( object: ValidBusinessObject, types: string[], direction: PathDirection) => Promise<GetAdjacentResponse>;
  getThingHistory: (thing: ValidBusinessObject, objectTypeId: string, limit: number) => Promise<ValidBusinessObject[]>;
  getLargeObject: (businesObject: ValidBusinessObject) => ErrDataPromise<ValidBusinessObject>


  constructor() {
    this.apiGateway = ApiGateway;
    this.assembler = new BusinessObjectAssembler();
    this.save = save.bind(this);
    this.link = link.bind(this);
    this.unlink = unlink.bind(this);
    this.deleteObject = deleteObject.bind(this);
    this.searchByType = searchByType.bind(this);
    this.getAdjacent = getAdjacent.bind(this);
    this.getAdjacent2 = getAdjacent2.bind(this);
    this.getMultipleAdjacentTypes = getMultipleAdjacentTypes.bind(this)
    this.getThingHistory = getThingHistory.bind(this);
    this.getLargeObject = getLargeObject.bind(this);
  };





  async updateStateFromApiGateway(user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, roleArbitrationInstance: RoleArbitrationInstanceType | undefined, currentGroup: string) {
    debug && console.log('DAO TRANSACTION: updateStateFromApiGateway', {
      roleArbitrationInstance, currentGroup, user
    })



    let normalizedData: NormalizedData = {}
    if (true) showCognitoUserId();

    if (!process.env.REACT_APP_LESS && user) {
      debug && console.log(`About to query for user`)
      const primaryItemTypes = getPrimaryItemTypes(config.app.appCode);
      const thingsRes = await this.getMultipleAdjacentTypes(user, primaryItemTypes, PathDirection.child);
      debug && console.log('thingsRes REsult', { primaryItemTypes, thingsRes})
      if (thingsRes.data) {
        thingsRes.data.forEach((data) => addToNormalData(data, normalizedData))
      }
    } else {
      debug && console.log( `not getting standard adjacent types as REACT_APP_LESS is set as ${process.env.REACT_APP_LESS}`)
    }


    const normalizedQueryPromises: Promise<NormalizedData>[] = []
    if (user) {
      debug && console.log('Queries for user', roleArbitrationInstance?.queries)
      roleArbitrationInstance?.queries.forEach((query) => {
        normalizedQueryPromises.push(query(user, {}))
      })
    } else {
      debug && console.log('NO USER')
    }

    debug && console.log('EXITED USER PROMISES')
    const normalizedQueryResults = await Promise.all(normalizedQueryPromises);

    debug && console.log('Number of initial query promises', normalizedQueryPromises.length)

    normalizedQueryResults.forEach((queryResult, i) => {
      debug && console.log('Assiging', queryResult)
      //i === 1  && console.log('Confirm  where ', queryResult && queryResult)
      if (queryResult) mergeNormalDatas(queryResult, normalizedData)
    })

    if (user && userGroupRoles.groups.includes('User') && !process.env.REACT_APP_LESS) {
      const things = getAllThings(normalizedData)
      const thingsRes = await userThingsQuery(user, {}, things);
      //debug && console.log('Things Res', thingsRes);
      mergeNormalDatas(thingsRes, normalizedData)
    } else {
      console.log(`Not doing userThingsQuery`, { user, userGroupRoles, override: process.env.REACT_APP_LESS})
    }

    return normalizedData;
  }



  async uploadToS3(directoryString: string, file: File) {
    await put(directoryString, file)
    return Promise.resolve({ err: null, data: 'some presigned url' })
  }


  getMainTableQueryResults = async (fn: (params: any) => void, query: DocumentClient.QueryInput) => {
    const apiRef = config.app.appName;
    try {
      const path = '/baseQuery/';
      const response: ErrData<DocumentClient.QueryOutput> = await ApiGateway.post(path, query, apiRef);

      debug && console.log('getMainTableQueryResults query results', { contextRequest: query, response })
      fn(response)
    } catch (e) {
      debug && console.log('Error in getNetworkQueryResults query', e)
    }
  }

  getMainTableQueryResultsWithReturn = async (query: DocumentClient.QueryInput): Promise<ErrData<DocumentClient.QueryOutput>> => {
    const apiRef = config.app.appName;

      const path = '/baseQuery/';
      return await ApiGateway.post(path, query, apiRef);

  }

  submitDeviceTransaction = async ( dcts: MultipleDeviceControlTransactionRequest ) => {
    const path = `/deviceControl`;
    const res = await ApiGateway.post( path, dcts, 'NWGateway')
    debug && console.log('res', res)
  }

  getWeatherForecastByLatLng = async ( request: GetWeatherRequest ) => {
    const apiRef = config.app.appName;
    const path = `/weather/forecast`;
    const res: ErrData< NamedFields< OpenWeatherData>> = await ApiGateway.post( path, request, apiRef )
    debug &&  console.log('weather res', res)
    return res
  }


  queryByGraph = async function (request: GetGetGraphTraversalRequest): ErrDataPromise<NodeEdgeMapper> {
    let res: ErrData<NodeEdgeMapper> = { err: null, data: null }
    const path = '/graphQuery';
  
    const queryRes: ErrData<GetGetGraphTraversalResponse> = await ApiGateway.post(path, request) as ErrData<GetGetGraphTraversalResponse>;
  
    if ( queryRes.data ) {
      res.data = new NodeEdgeMapper()
      res.data.loadItems( [ ...queryRes.data.hierarchy.edges, ...queryRes.data.hierarchy.nodes ] as DatastoreObjectTypes )
    } else {
      res.err = queryRes.err
    }
    
    return res

  }
  

};

export default new AppDao();
