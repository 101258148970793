import { PathDirection, ValidBusinessObject } from '@iotv/datamodel';
import {
    AreaStyler,
    PlaceStyler
} from '@iotv/datamodel-core';
import {
    Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createAndLink } from "../../../data/daoFunctions/daoFunctions";
import { AreaManagementMode, DispatchFunctionsType, UserFunctions, UserTransactionType } from '../../../types/AppTypes';
import { updateStateList } from '../../Survey/SurveyView';
import { AreaStylerComponent, PlaceStylerComponent } from './components/CommonStyle';
import { deleteExistingObject, removeFromStateList } from './helperFunctions/helperFunctions';

const debug = process.env.REACT_APP_DEBUG && false;

export type AddStylerProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    transactionFunctions: DispatchFunctionsType;
    contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined;
    areas: ValidBusinessObject[] | undefined;
    userFunctions: UserFunctions;
    actions: UserTransactionType[];
    history: History;

    mode: AreaManagementMode,
    styler: ValidBusinessObject | undefined,
    areaStyler: ValidBusinessObject[] | undefined,
    placeStyler: ValidBusinessObject[] | undefined,
    setAreaStyler: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>;
    setPlaceStyler: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>;
};

export interface Styler {
    name: string | undefined,
    id: string | undefined,
    description: string | undefined,
    color: string | undefined,
    opacity: string | undefined,
    lineWidth: string | undefined,
    icon: string | undefined,
}


export default function AddStyler({ open, setOpen, transactionFunctions, contextCustomer, areas, userFunctions, actions, history, mode, styler, areaStyler, placeStyler, setAreaStyler, setPlaceStyler
}: AddStylerProps) {
    const handleClose = (value: string) => {
        setOpen(false);
    };

    const [radioValue, setRadioValue] = useState('Area');

    const [listIconOpen, setListIconOpen] = useState<boolean>(false);

    const [values, setValues] = useState<Styler>({
        name: "Styler" + Math.floor(Math.random() * 100),
        id: uuidv4(),
        description: undefined,
        color: undefined,
        opacity: undefined,
        lineWidth: undefined,
        icon: undefined
        // parentArea: undefined,
    });

    useEffect(() => {
        if (styler) {
            setValues({
                name: styler.name,
                id: styler.id,
                description: styler.description,
                color: styler.color,
                opacity: styler.opacity,
                lineWidth: styler.lineWidth,
                icon: styler.icon
            })

            if (styler.type === 'AreaStyler') {
                setRadioValue('Area')
            } else {
                setRadioValue('Place')
            }
        }
    }, [styler?.pk])

    const handleChange =
        (prop: keyof Styler) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const saveStyler = (newStyler: ValidBusinessObject, existingObject: ValidBusinessObject, direction: PathDirection) => {
        debug && console.log('saveStyler', newStyler, existingObject, direction)

        createAndLink({ existingObject: existingObject, newObject: newStyler, direction }).then((res) => {
            debug && console.log('what is inside?', res)

        }).catch((err) => debug && console.log('can not save styler', err))

        if (mode === AreaManagementMode.EDIT) {
            if (radioValue === 'Area') {
                updateStateList(areaStyler, styler, newStyler, setAreaStyler)
            } else {
                updateStateList(placeStyler, styler, newStyler, setPlaceStyler)
            }

        }
    }

    const convertToValidBusinessObject = (values: Styler, type: string): ValidBusinessObject => {
        const pk = type + ':' + values.id;
        const sk = pk;
        let newStyler = {}

        if (radioValue === 'Area') {
            newStyler = new AreaStyler(values);
        }

        if (radioValue === 'Place') {
            newStyler = new PlaceStyler(values);
        }

        const validBusinessObject = JSON.parse(JSON.stringify(newStyler));
        validBusinessObject.pk = pk;
        validBusinessObject.sk = sk;
        validBusinessObject.type = type;

        debug && console.log('convertToValidBusinessObject', validBusinessObject)

        return validBusinessObject;
    }

    const viewDefinition = {};


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add Styler</DialogTitle>
            <DialogContent>

                <FormControl>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Style</FormLabel> */}
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={radioValue}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="Area" control={<Radio />} label="Area" />
                        <FormControlLabel value="Place" control={<Radio />} label="Place" />

                    </RadioGroup>
                </FormControl>

                <Grid container>
                    <Grid item xs={2}>
                        <Typography sx={{ marginTop: '15px' }}>Name</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <TextField id="name" label="name" variant="standard" size="small" value={values.name} onChange={handleChange('name')} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '25px' }}>Description</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                            <TextField id="description" label="description" variant="standard" size="small" value={values.description} onChange={handleChange('description')} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        {radioValue === 'Area' ? (
                            <>
                                <Grid container>
                                    <Grid item>
                                        <Typography sx={{ marginTop: '25px' }}>Style</Typography>
                                    </Grid>
                                    {AreaStylerComponent(values, handleChange)}
                                    {/* <Grid item>
                                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                            <TextField id="color" label="color" variant="standard" helperText='#hex number' size="small" value={values.color} onChange={handleChange('color')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                            <TextField id="opacity" label="opacity" variant="standard" helperText='between 0.0 to 1.0' size="small" type="number" InputProps={{
                                                inputProps: {
                                                    max: 1.0, min: 0.0
                                                }
                                            }} value={values.opacity} onChange={handleChange('opacity')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                            <TextField id="lineWidth" label="line width" variant="standard" helperText='please put in number only, will auto generate unit px' size="small" type="number" value={values.lineWidth} onChange={handleChange('lineWidth')} />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>

                            </>) : (
                            <>
                                <Grid container>
                                    <Grid item>
                                        <Typography sx={{ marginTop: '25px' }}>Style</Typography>
                                    </Grid>
                                    {/* <Grid item>
                                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                            <TextField id="color" label="color" variant="standard" helperText='#hex number' size="small" value={values.color} onChange={handleChange('color')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                            <TextField id="icon" label="icon" variant="standard" size="small" value={values.icon} onChange={handleChange('icon')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                       
                                    </Grid> */}
                                    {PlaceStylerComponent(values, setValues, handleChange, listIconOpen, setListIconOpen)}

                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid container>
                    <Button onClick={() => saveStyler(convertToValidBusinessObject(values, radioValue + 'Styler'), contextCustomer as ValidBusinessObject, PathDirection.child)}>Save</Button>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    {/* {mode === AreaManagementMode.EDIT && styler && <DeleteButton {...{ existingObject: styler , setOpen }}></DeleteButton>} */}

                    {mode === AreaManagementMode.EDIT && styler && <Button onClick={() => {
                        deleteExistingObject(styler, setOpen)

                        removeFromStateList(areaStyler, styler, setAreaStyler)
                        removeFromStateList(placeStyler, styler, setPlaceStyler)

                    }}>Delete</Button>}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}