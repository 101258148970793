import { ErrData, ErrDataPromise, getObjectHash } from "@iotv/datamodel";
import React, { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import { googleSheetRef } from "@iotv/iotv-v3-types";
import config from "../config";

const debug = process.env.REACT_APP_DEBUG && false;

export type UseGoogleListSheetsState = [
    [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>],
    [gapi.client.drive.File[], React.Dispatch<React.SetStateAction<gapi.client.drive.File[]>>],
    [gapi.client.drive.File | undefined, React.Dispatch<React.SetStateAction<gapi.client.drive.File | undefined>>],
    [gapi.client.sheets.Sheet[], React.Dispatch<React.SetStateAction<gapi.client.sheets.Sheet[]>>],
    [gapi.client.sheets.Sheet | undefined, React.Dispatch<React.SetStateAction<gapi.client.sheets.Sheet | undefined>>],
    [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>],
    [SpreadsheetRowData[], React.Dispatch<React.SetStateAction<SpreadsheetRowData[]>>],
    [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>],


]

type SpreadsheetRowData = string[]

export type UseGoogleListSheetsReturnType = {
    state: UseGoogleListSheetsState,
    handlers: [
        handleListFiles: () => void,
        getSpreadsheetData: (spreadsheetId: string, range: string) => ErrDataPromise<SpreadsheetRowData[]>
    ]
}


export type UseGoogleListSheetsRequestType = {
    signedIn: boolean | Dispatch<SetStateAction<boolean>>,
    fileSelectRef?:  React.MutableRefObject<googleSheetRef | undefined> 

}


export const useGoogleListSheets = ({ signedIn, fileSelectRef} : UseGoogleListSheetsRequestType ): UseGoogleListSheetsReturnType => {
    const listFiles = async (name: string): Promise<gapi.client.Response<gapi.client.drive.FileList>> => gapi.client.drive?.files.list({
        pageSize: 10,
        fields: "nextPageToken, files(id, name)",
        q: ` mimeType = 'application/vnd.google-apps.spreadsheet' and name contains '${name}' `
    })

    const [nameMatchText, setNameMatchText]: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] = React.useState<string | undefined>( undefined )
    const [matchedFiles, setMatchedFiles]: [gapi.client.drive.File[], React.Dispatch<React.SetStateAction<gapi.client.drive.File[]>>] = React.useState<gapi.client.drive.File[]>([])
    const [selectedFile, setSelectedFile] = React.useState<gapi.client.drive.File | undefined>(undefined)
    const [availableSheets, setAvailableSheets] = React.useState<gapi.client.sheets.Sheet[]>([])
    const [selectedSheet, setSelectedSheet] = React.useState<gapi.client.sheets.Sheet | undefined>(undefined)
    const [selectedRange, setSelectedRange] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [spreadsheetData, setSpreadsheetData] = React.useState<SpreadsheetRowData[]>([])



    const handleListFiles = async (searchText: string = config.app.appCode) => {
        if (gapi.client.drive?.files) {
            const listRes = await listFiles(searchText);
            if (listRes.result?.files) {
                setMatchedFiles(listRes.result.files)
            }
            debug && console.log('listRes ', listRes)
        } else {
            debug && console.log('no gapi.client.drive yet')
        }

    }

    const fileSelectRefHash = getObjectHash( fileSelectRef?.current )
    debug && console.log('useGoogleIo has fileSelectRef', fileSelectRef?.current)
    // useEffect(() => {
        
    //     if (fileSelectRef?.current ) {
    //         debug && console.log('useGoogleIo used effect on changed fileSelectRef', fileSelectRef?.current)
    //         setNameMatchText(fileSelectRef.current.spreadsheetName)
    //     }
    // }, [ fileSelectRefHash])

    useEffect(() => {
        fileSelectRef && (fileSelectRef.current = {
            sheetName: selectedSheet?.properties?.title,
            spreadsheetName: selectedFile?.name
        })
    }, [ selectedFile?.id, selectedSheet?.properties?.title ])

    useEffect(() => {
        signedIn && handleListFiles(nameMatchText)
    }, [nameMatchText?.length, signedIn])

    useEffect(() => {
        if (selectedFile?.id) {
            getSpreadsheetSheets(selectedFile.id);
            setSelectedSheet(undefined)
        }
    }, [selectedFile?.id])

    const handleGoogleError = (reason: any) => {
        console.log('Google err', reason)
        // displayMessage(reason.result.error.message, 'MessageBar')
    }

    const handleGoogleGetSpreadsheetSheetsSuccess = (res: gapi.client.Response<gapi.client.sheets.Spreadsheet>) => {
        const sheets: gapi.client.sheets.Sheet[] = res.result.sheets ?? []
        setAvailableSheets(sheets)
        debug && console.log('got spreashseet sheets', sheets)
    }

    const getSpreadsheetSheets = async (spreadsheetId: string) => {
        const query = {
            spreadsheetId
        };
        const res = await gapi.client.sheets.spreadsheets.get(query).then(
            handleGoogleGetSpreadsheetSheetsSuccess, handleGoogleError
        )
    }

    const getSpreadsheetData = async (spreadsheetId: string, range: string) => {
        const result: ErrData<SpreadsheetRowData[]> = { err: null, data: null };
        const target = {
            spreadsheetId,
            range,
        };

        try {
            const res: gapi.client.Response<gapi.client.sheets.ValueRange> = await gapi.client.sheets.spreadsheets.values.get(target);
            const values = res.result.values ?? [];
            setSpreadsheetData(values)
            result.data = values;
        } catch (error: any) {
            console.log('Caught Error', error)
            if (error.result.error) {
                setError(error.result.error)
                result.err = new Error(error.result.error.message)
            }

        }
        debug && console.log('G import res', result)
        return result;
    }

    return {
        state: [
            [nameMatchText, setNameMatchText],
            [matchedFiles, setMatchedFiles],
            [selectedFile, setSelectedFile],
            [availableSheets, setAvailableSheets],
            [selectedSheet, setSelectedSheet],
            [selectedRange, setSelectedRange],
            [spreadsheetData, setSpreadsheetData],
            [error, setError]
        ], handlers: [handleListFiles, getSpreadsheetData]
    }
}