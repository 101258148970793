import { camelToDisplay, Milliseconds } from '@iotv/datamodel';
import { Button, Slider } from '@material-ui/core';
import React, { useEffect } from 'react';
import config from '../../config';
import { convertHistoryObjectToDataTable, getValueLabel } from '../../data/TypeHelpers';
import LineChart from '../../dataVisualization/Google/LineChart';
import { LineChartOptionsMandatory, RangeableLineChartProps } from '../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;
   


export const RangeableLineChart = (props: RangeableLineChartProps) => {
    const {historyObject, keys, xType, classes, timeSliceButtonValues } = props;
    const maxIdx = historyObject.index.length -1;
    const initialIdxRange = [0, maxIdx];



    const [slice, setSlice] = React.useState(initialIdxRange as number | number[]);

    useEffect(() => {
      if (slice instanceof Array ) {
        const sliceLength = slice[1] - slice[0] + 1;
        const datapoints = historyObject.index.length;
        if (datapoints > sliceLength) {
          const diff = datapoints - sliceLength ;
           debug && console.log('Need to update slice ends', { incoming: historyObject.index.length, slice, sliceLength, diff})
          setSlice([ slice[0], slice[1] + diff])
  
        }
      }
      
    }, [slice,  historyObject.index.length]);


    //debug && slice instanceof Array && console.log(`length: ${historyObject.index.length} start: ${slice[0]} end: ${slice[1]} sliceLength: ${slice[1] - slice[0]} `)
    const validSlice = slice instanceof Array ? slice : [0, slice]
    const data: google.visualization.DataTable = convertHistoryObjectToDataTable(historyObject, keys, xType, validSlice);

    const options: google.visualization.LineChartOptions & LineChartOptionsMandatory = {
      id: keys.join('_'),
      title: keys.map((key) => typeof key === 'string' ? camelToDisplay(key) : 'Err  Key type' ).join(', ') ,
      height: 300
    }

    const setTimeSlice = (agoMs: number) => {
      const thenMs = Date.now() - agoMs;
      const lowIdx = historyObject.timestamp.findIndex( (ts) => ts > thenMs) 
       debug && console.log('lowIdx', lowIdx)
      setSlice([lowIdx, historyObject.index.length]);
    }

    const chartProps = {
      options,
      data,
      name: keys.join('_')
    }

    // this looks like a MUI bug it doesn't work on the demo page.
    const valuetext = (idx: number, idx2: any) => {
        
        const label = new Date(historyObject.timestamp[idx] * 1000).toLocaleDateString(config.locale);
        //debug && console.log('value text has', {idx, idx2, label})
        return label;
      }

    const sliderProps = {
        max: maxIdx,
        min: 0,
        value: slice,
        onChange: (_event: any, newValue: number | number[]) =>  setSlice(newValue),
        valueLabelDisplay: 'auto' as "auto" | "on" | "off" | undefined,
        'aria-labelledby': 'range-slider',
        getAriaValueText: ( idx1: number, idx2: number ) => valuetext(idx1, idx2)
    }

    const getSliceSelectButton = (count: number, unit: Milliseconds, key: number) =>  <Button key={key} onClick = {() => { setTimeSlice( count * unit.valueOf())}}>{`${count} ${Milliseconds[unit]}`}</Button>



    return  <div>
    <LineChart {...chartProps}> </LineChart>,
    <Slider className = {classes.LineChartSlider} {...sliderProps } ></Slider>
    { timeSliceButtonValues?.map( ([count, unit], i) => getSliceSelectButton(count, unit, i) ) }
    </div>
  }
