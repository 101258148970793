import React, { useEffect } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import styles from '../cosmetics/sharedViewStyles'
import { ThingViewProps, ObjectViewType, SearchParamsType, UserTransactionType, ThingViewOptionals, ViewObject, ValidCustomerObject, AdjacentType, MessageOutputType } from '../types/AppTypes';
import { getThingViewOfType } from './thingViewArbitrator';
import queryString from 'query-string'
import { Redirect } from 'react-router-dom';
import { getZombieInstanceFromPrimaryKey, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import Assembler from '../data/Assembler';
import { getAdjacent2Wrapper, GetAdjacentRequest, getOne } from '../data/daoFunctions/daoFunctions';
import { modelTypes } from '../util/Model/modelTypes';
import { v4 as uuidv4 } from 'uuid/';
import { getViewObjectHash, inflateViewObject } from '../util/AppData/viewObject';

const debug = process.env.REACT_APP_DEBUG && false;
const effectDebug = process.env.REACT_APP_DEBUG && false;
const redirectdebug = process.env.REACT_APP_DEBUG && false;

function ThingView(props: ThingViewProps): JSX.Element {
  const { classes, contextCustomer, user, userGroupRoles, userSelectedGroup, viewObject, childFirstGeofences, transactionFunctions, userFunctions, history, match, redirect, useSpecializedViews } = props;

  const largeObjectPresent = viewObject.largeObject !== undefined;
  debug && console.log('ThingView has largeObject', largeObjectPresent);
  debug && console.log('ThingView has match', match);
  debug && console.log('ThingView userSelectedGroup', userSelectedGroup)
  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const getNotInStateObject = async (params: GetAdjacentRequest) => {
    const itemRes = await getOne(params.scopeDefinitingContextObject);
    if (itemRes.data) {
      let items = [itemRes.data] as ValidBusinessObjectList;
      const adjacentRes = await getAdjacent2Wrapper(params)
      debug && console.log('getNotInStateObject got', adjacentRes)
      if (adjacentRes.data?.Items) {
        items = [...items, ...adjacentRes.data.Items as ValidBusinessObjectList];
      } else if (adjacentRes.err) {
        displayMessage(adjacentRes.err.message, 'MessageBar')
      }
      const viewObject = inflateViewObject(items, params.scopeDefinitingContextObject)
      if (viewObject.matchedPrimary) {
        viewObject.matchedPrimary = Assembler.getInstance(viewObject.matchedPrimary)
      }
      setPossibleViewObject(viewObject);
      userFunctions.addObjectsToState(items)
    }
  }

  const viewObjectHash = getViewObjectHash(viewObject);



  const searchParams: SearchParamsType | undefined = history.location?.search ? queryString.parse(history.location.search) as SearchParamsType : undefined

  const [possibleViewObject, setPossibleViewObject] = React.useState<ViewObject | undefined>(viewObject);

  useEffect(() => {
    effectDebug && console.log('ThingView  used effect on viewObjectHash', viewObjectHash)
    const { type, id } = match.params
    const isNewObjectAction = searchParams?.action && [UserTransactionType.CREATE, UserTransactionType.CREATE_AND_LINK, UserTransactionType.REGISTER].includes(searchParams.action)
    debug && console.log('ThingView isNewObjectAction', isNewObjectAction)
    if (isNewObjectAction) {

      if (possibleViewObject?.matchedPrimary === undefined) {
        let matchedPrimary: ValidBusinessObject | undefined = undefined;

        if (searchParams?.objectTypeId && searchParams?.objectUUID) {
          matchedPrimary = Assembler.getInstance({ type: searchParams.objectTypeId, id: searchParams.objectUUID }) as ValidBusinessObject;
          debug && console.log('ThingView With No Matched Primary but objectTypeId and objectUUID, Assember returned', matchedPrimary)
        } else if (type && id) {

          matchedPrimary = Assembler.getInstance({ type, id }) as ValidBusinessObject;
          debug && console.log('ThingView With No Matched Primary and objectTypeId and objectUUID, Assember returned', matchedPrimary)
        }
        setPossibleViewObject({ matchedPrimary, matchedRelatedByPk: [], matchedRelatedBySk: [] })
      } else if ((type && id) && (type !== possibleViewObject?.matchedPrimary?.type || id !== possibleViewObject?.matchedPrimary?.type)) {
        debug && console.log(`ThingView creating new matched primary on CREATE_AND_LINK from match`)
        const newInstance = Assembler.getInstance({ type, id }) as ValidBusinessObject;
        transactionFunctions.saveObject = () => { console.log('Saving and Linking via mutated fn provided by thingViewArbitrator') }
        setPossibleViewObject({ matchedPrimary: newInstance, matchedRelatedByPk: [], matchedRelatedBySk: [] })
      }


    } else if (possibleViewObject?.matchedPrimary && viewObject.matchedPrimary) {
      debug && console.log('ThingView With a possibleViewObject Matched Primary, ', possibleViewObject)
      const matchedPrimary = Assembler.getInstance(viewObject.matchedPrimary as ValidBusinessObject);
      setPossibleViewObject({ ...viewObject, matchedPrimary })
      debug && console.log('Assember returned', matchedPrimary)
    } else if (possibleViewObject?.matchedPrimary && !viewObject.matchedPrimary) {
      if ((type && id) && (type !== possibleViewObject?.matchedPrimary?.type || id !== possibleViewObject?.matchedPrimary?.type)) {
        debug && console.log(`ThingView creating zombie from match type and id  on non-CREATE_AND_LINK `)
        const newInstance = getZombieInstanceFromPrimaryKey( `${type}:${id}`) as ValidBusinessObject
        const params: GetAdjacentRequest = {
          adjacencyType: AdjacentType.SUBJECT,
          objectTypeId: type, scopeDefinitingContextObject: newInstance,
          includeEdges: true
        }
        getNotInStateObject(params);
       // setPossibleViewObject({ matchedPrimary: newInstance, matchedRelatedByPk: [], matchedRelatedBySk: [] })
      } else {
        console.log('ThingView Reached known error state where no viewObject.matchedPrimary ( which may appear defined in console )', { possibleViewObject, viewObject, type, id})
      }

      console.log('ThingView Reached known error state where no viewObject.matchedPrimary', { possibleViewObject, viewObject })
      // const matchedPrimary = Assembler.getInstance(viewObject.matchedPrimary as ValidBusinessObject);
      // setPossibleViewObject( { ...viewObject, matchedPrimary})
      // debug && console.log('Assember returned', matchedPrimary)
    } else {
      const { type, id } = match.params;
      if (type && id && modelTypes.includes(type)) {
        const sk = `${match.params.type}:${match.params.id}`
        debug && console.log('getting something that is not in state', sk);
        const params: GetAdjacentRequest = {
          adjacencyType: AdjacentType.PARENT,
          objectTypeId: 'User', scopeDefinitingContextObject: { type, id, sk, pk: sk },
          includeEdges: true
        }
        getNotInStateObject(params);
      } else if (type === 'Template' && process.env.REACT_APP_DEPLOYMENT_STAGE === 'dev') {
        setPossibleViewObject({ matchedPrimary: Assembler.getInstance({ type: 'Template', id, name: 'Your Template' }) as ValidBusinessObject, matchedRelatedByPk: [], matchedRelatedBySk: [] })
      }
    }
  }, [viewObjectHash, largeObjectPresent])

  const optionals: ThingViewOptionals = {
    childFirstGeofences,
    useSpecializedViews
  }
  debug && history && console.log('ThingView using', { searchParams, possibleViewObject })
  debug && console.log('ThingView actual viewObject', { viewObject })
  redirectdebug && console.log('Thing View got redirect', redirect)

  if (redirect) {
    return <Redirect to={redirect}></Redirect>
  } else {
    return (

      <Grid container className={classes.root} spacing={0}>
        <Grid key={'set key'} item className={classes.item}>
          {
            (possibleViewObject && getThingViewOfType({ viewObject: possibleViewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, objectViewType: ObjectViewType.Card, history, searchParams, optionals }))
          }
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ThingView)
