import { MapLike } from '@iotv/iotv-v3-types';
import { Avatar, Button, Container, CssBaseline, IconButton, InputAdornment, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { WithStyles } from '@material-ui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { NavSections } from '../navigation/NavSections';
import { styles } from './loginStyles';
const debug = process.env.REACT_APP_DEBUG && false;

type NotTypedInAmplifyCodeSendSuccessMessage = {
    CodeDeliveryDetails?:
    { AttributeName: "email", DeliveryMedium: "EMAIL", Destination: string }
}

enum ResetStages {
    ASK_USER_TO_REQUEST_CODE,
    ASK_USER_TO_ENTER_RECEIVED_CODE
}

// Send confirmation code to user's email
const requestConfirmationCode = async (username: string, setMessage: (err: string | undefined) => void, setError: (err: string | undefined) => void, setEmail: (err: string | undefined) => void, setResetStage: (stage: ResetStages) => void) => {
    try {
        const res = await Auth.forgotPassword(username)
        if (res) {
            const successMsg = res as NotTypedInAmplifyCodeSendSuccessMessage
            if (successMsg.CodeDeliveryDetails?.Destination) {
                setMessage(`An access code has been sent to your email address ${successMsg.CodeDeliveryDetails?.Destination}`)
                setEmail(successMsg.CodeDeliveryDetails?.Destination)
                setResetStage(ResetStages.ASK_USER_TO_ENTER_RECEIVED_CODE)
            } else {
                setMessage(JSON.stringify(res))
            }
        }
    } catch (e: any) {
        console.log('Err in requestConfirmationCode', e.message)
        let errMsgMap: MapLike<string> = {
            default: `Sorry, that's not a username which matches the pattern used in this system. Please try again`,
            'Username/client id combination not found': `We are having trouble finding ${username}, please check that you have typed it correctly`,
            'Attempt limit exceeded, please try after some time': 'Computer says "No". You have exceeded the number of reset attempts, please try again later.'
        }
        const [_, matchedError] = Object.entries(errMsgMap).find(([errMsg, ourMsg]) => (e.message as string || '').includes(errMsg)) ?? ['none', errMsgMap.default]
        setError(matchedError)
    }
}

// Collect confirmation code and new password, then
const submitNewPassword = async (username: string, code: string, new_password: string, setMessage: (err: string | undefined) => void, setError: (err: string | undefined) => void, setResetStage: (stage: ResetStages) => void, history: ReturnType<typeof useHistory>) => {
    try {
        const res = await Auth.forgotPasswordSubmit(username, code, new_password)
        console.log('requestConfirmationCode res', res)
        if (res === 'SUCCESS') {
            setMessage(`Your password has been changed`)

            setTimeout(() => history.push(`/${NavSections.LOGIN}`), 1500)
        } else {
            setError('Something unusual has happened')
            setResetStage(ResetStages.ASK_USER_TO_REQUEST_CODE)
        }
    } catch (e: any) {
        console.log('Err in requestConfirmationCode', e.message)
        let errMsgMap: MapLike<string> = {
            default: `Sorry, that's not a password which matches can used in this system. Please try again`,
            'Invalid verification code provided, please try again.': 'Invalid verification code provided, please try again.',
            'Password does not conform to policy: Password must have uppercase characters': 'Password does not conform to policy: Password must have uppercase characters'
        }
        const [_, matchedError] = Object.entries(errMsgMap).find(([errMsg, ourMsg]) => (e.message as string || '').includes(errMsg)) ?? ['none', errMsgMap.default]
        setError(matchedError)
    }
}

const isValidPassword = (password: string) => {
    const cognitoPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&/,><\\’:;|_~`])\S{6,99}$/
    const regex = new RegExp(cognitoPasswordRegex)
    return regex.test(password)
}

const isValidUsername = (username: string) => {
    const regex = new RegExp(/\S{4}/)
    return regex.test(username)
}

const redirect = `/${NavSections.LOGIN}`

export type UnAuthenticatedProps = {
    isAuthenticated: boolean,
} & WithStyles<typeof styles>

const PasswordReset = ({ isAuthenticated, classes }: UnAuthenticatedProps) => {
    const history = useHistory()
    const [username, setUsername] = React.useState<string | undefined>(undefined)
    const [password, setPassword] = React.useState<string | undefined>(undefined)
    const [code, setCode] = React.useState<string | undefined>(undefined)
    const [email, setEmail] = React.useState<string | undefined>(undefined)
    const [message, setMessage] = React.useState<string | undefined>(undefined)
    const [error, setError] = React.useState<string | undefined>(undefined)
    const [resetStage, setResetStage] = React.useState<ResetStages>(ResetStages.ASK_USER_TO_REQUEST_CODE)
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const renderResetStage = () => {
        switch (resetStage) {
            case ResetStages.ASK_USER_TO_REQUEST_CODE: return renderRequestCode();
            case ResetStages.ASK_USER_TO_ENTER_RECEIVED_CODE: return renderEnterCode()
        }
    }

    const renderRequestCode = () => <form className={classes.form} noValidate>
        <TextField {...{
            key: 'username', variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "username",
            label: "Username", name: "email", autoComplete: "email", autoFocus: true,
            helperText: (username !== undefined && !isValidUsername(username)) ? `Usenames are 4 or more characters ${username}` : 'Please enter your username, then click Request Validation Code',
            onChange: (e) => setUsername(e.target.value.toLowerCase()),
        }}/>
        
        {error && <Alert {...{ severity: "error", onClose: () => { setError(undefined) } }}>{error}</Alert>}
        {message && <Alert {...{ severity: "info", onClose: () => { setMessage(undefined) } }}>{message}</Alert>}
        <Button {...{
            type: "submit", disabled: (!username || !isValidUsername(username)) || error !== undefined,
            fullWidth: true, variant: "contained", color: "primary", className: classes.submit,
            onClick: (e: React.MouseEvent) => {
                e.preventDefault()
                if (username) {
                    requestConfirmationCode(username, setMessage, setError, setEmail, setResetStage)
                    setError(undefined)
                    setMessage(undefined)
                }
            }
        }}>
            Request Validation Code
        </Button>
    </form>

    const renderEnterCode = () => <form className={classes.form} noValidate>
        <TextField {...{
            key: 'code', variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "code", defaultValue: '',
            label: "Validation Code", name: "code", helperText: `Please enter the code sent to email, ${email}`, autoFocus: true,
            onChange: (e) => setCode(e.target.value),

        }} />

        <TextField {...{
            key: 'password', variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "password", type: showPassword ? "text" : "password", autoFocus: true,
            label: "New Password", name: "code", helperText: (password && !isValidPassword(password)) ? 'Must contain upper, lower case and special characters' : `Please enter your new password,`,
            onChange: (e) => setPassword(e.target.value),
            error: (!!password && !isValidPassword(password)),
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton {...{
                            'aria-label': "toggle password visibility",
                            onClick: () => setShowPassword(!showPassword),

                        }}

                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }
        }} />

        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="info">{message}</Alert>}
        <Button {...{
            type: "submit", disabled: !username || !isValidUsername(username) || !password || !isValidPassword(password),
            fullWidth: true, variant: "contained", color: "primary", className: classes.submit,
            onClick: (e: React.MouseEvent) => {
                e.preventDefault()
                if (username && code && password) {
                    submitNewPassword(username, code, password, setMessage, setError, setResetStage, history)
                    setError(undefined)
                    setMessage(undefined)
                }
            }
        }}>
            Submit
        </Button>
    </form>

    return isAuthenticated && redirect ?
        <Redirect to={{ pathname: redirect }}></Redirect>
        :
        <Paper className={clsx(classes.paper)} >
            <div >
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Password Reset
                        </Typography>
                        {
                            renderResetStage()
                        }
                        <Link href="#"
                            to={`/${NavSections.LOGIN}`}
                        >
                            {"Back to login"}
                        </Link>
                    </div>
                </Container>
            </div>
        </Paper>
}

export default withStyles(styles)(PasswordReset)