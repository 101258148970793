import { Customer, CustomerProps, debug, ErrData, ValidBusinessObjectList } from "@iotv/datamodel";
import { ValidBusinessObject } from "@iotv/datamodel";
import ApiGateway from "../../../../../../../data/aws/api-gateway/ApiGateway";
import { MessageOutputType, UserTransactionType } from "../../../../../../../types/AppTypes";
import { NavSections } from "../../../../../../../components/navigation/NavSections";

const apiRef = 'SystemManagement';


type AddToMultipleDeviceInventoryRequest = {
    user: ValidBusinessObject & { type: 'User' },
    contextCustomer: ValidBusinessObject & { type: 'Customer' },
    devices: ValidBusinessObjectList
}

export const  addToDevicesInventory = async ( {user, contextCustomer, devices}: AddToMultipleDeviceInventoryRequest) => {
    const result: ErrData<string> = { err: null, data: null}
    if (contextCustomer) {
      const customerClassInstance = new Customer(contextCustomer as CustomerProps)
      if ( customerClassInstance.canHaveDeviceInventory() ) {
        const promises: ErrData<ValidBusinessObject>[] = []
        let successes: number = 0, fails: number = 0, errMessage = '';
        for ( let device of devices  ) {
            const deviceRes = await ApiGateway.post('/system/Device/addToInventory', { contextUser: user, device, contextCustomer }, apiRef);
            promises.push(deviceRes)
        }

        promises.forEach( (deviceRes ) => {
           if (deviceRes.err) {
            fails++;
            errMessage += `${fails !== 0 ? ', ' : ''}${deviceRes.err.message}`;

           } else {
             successes++;
           }
        })
        if ( fails > 0 ) {
          const msg = `${successes} devices processed successfully but ${fails} devices were not processed. \n${errMessage}`
          result.err = new Error( msg )
        } else {
          result.data = `${successes} devices processed successfully`
        }
       
        
      }
     
    } 
    return result
  }