import {
  C021CustomerAccountType,
  c021ToLSCAccountType,
  Community,
  DroughtManagementPlan,
  DroughtManagementPlanStage,
  Tank,
  ValidModelObject as C021ValidModelObject,
  ValidModelObjects,
  ValidModelType,
} from '@c021/datamodel';
import {
  getZombieInstanceFromPrimaryKey,
  NodeEdgeMapper,
  ObjectHistory,
  PathDirection,
  QueryTypes,
  ValidBusinessObject,
  ValidModelObject,
} from '@iotv/datamodel';
import { Position, TraverserQueryPathBody, ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { Box, Button, Card, Grid, List, ListItem, ListItemText, Table, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import { color } from 'd3-color';
import React, { useEffect, useRef, useState } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import { useHistory, useLocation } from 'react-router';
import { GetAdjacentResponse, ObjectCardProps } from '../../../../../types/AppTypes';
import ApiGateway from '../../../../../data/aws/api-gateway/ApiGateway';
import { getAdjacent, getOne } from '../../../../../data/daoFunctions/daoFunctions';
import { useContainerDimensions } from '../../../../../hooks/useContainerDimenstions';
import { useStyles } from '../../../cosmetics/communityStyles';
import { getAverageTankGeoLocation, getGeoCodeableAddress } from '../../../data/TypeHelpers';
import { CommunityDailyUsageGraph } from '../graphs/CommunityDailyUsageGraph';
import { CommunityFillLevelGraph } from '../graphs/CommunityFillLevelGraph';
import MapView from '../../geo/MapView';
import { fetchDroughtManagementPlan } from '../../../data/queries/customerDroughtManagementPlan';
import { DMPStage } from '../DroughtManagement/DMPExtrasV2';
import { getColour } from '../../CommunitiesView';
import { GraphContainer } from '../graphs/GraphContainer';

import { CommunityTankGauges } from '../CommunityTankGauges';
import { CommunitySummary } from '../CommunitySummary';
import { WeatherCard } from '../WeatherCard';
import { getCustomerHierarchy } from '../CommunitiesView/queries';

const debug = process.env.REACT_APP_DEBUG && false;

const HOUSEHOLD = c021ToLSCAccountType('Household' as C021CustomerAccountType);
const COMMUNITY = c021ToLSCAccountType('Community' as C021CustomerAccountType);
const ISLAND_COUNTRY = c021ToLSCAccountType('Country / Island' as C021CustomerAccountType);

export type EntityDashboardProps = {
  objectHistory: Partial<ObjectHistory>;
  householdTanks: ValidModelObjects<'Tank'>;
  ICCTanks: ValidModelObjects<'Tank'>;
  customerIn: any
} & ObjectCardProps;

export const EntityDashboard = ({ viewObject: { matchedPrimary }, householdTanks, ICCTanks, objectHistory, userFunctions, customerIn }: EntityDashboardProps) => {
  const theme = useTheme();
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  debug && console.log('EntityDashboard got ', { customerIn, householdTanks, ICCTanks });

  const [entity, setEntity] = useState<ValidModelObject<'Customer'> | undefined>(customerIn);
  const [communityTanks, setCommunityTanks] = useState<ValidModelObjects<'Tank'>>([]);
  const [currentPlanStage, setCurrentPlanStage] = useState<DMPStage>();
  const [droughtManagementPlan, setDroughtManagementPlan] = useState<C021ValidModelObject<'DroughtManagementPlan'> | undefined>(undefined);


  /**
   *
   */
  useEffect(() => {
    if (customerIn) {
      const allTanks = [...householdTanks, ...ICCTanks];
      const totalVolme = allTanks.reduce((acc, curr) => {
        if (curr) {
          const tank = new Tank(curr);
          acc = acc + tank._totalVolume;
        }
        return acc;
      }, 0 as number);

      //const totalVolme = allTanks.reduce( ( acc, curr ) => { if ( typeof curr.total_volume === 'number' ) { acc = acc + curr.total_volume }; return acc}, 0 as number)
      const totalCurrentVolume = allTanks.reduce((acc, curr) => {
        if (typeof curr.current_filled_volume === 'number') {
          acc = acc + curr.current_filled_volume;
        }
        return acc;
      }, 0 as number);
      debug && console.log(`Total community tank volume`, { totalVolme, totalCurrentVolume });
      setEntity(customerIn);
      // fetchWeather(customerIn);
      //fetchCommunityTanks(customerIn)
      fetchDroughtManagementPlanStageLocal(customerIn);

      setCommunityTanks([...householdTanks, ...ICCTanks]);
    }
  }, [customerIn?.sk, householdTanks.length, ICCTanks.length]);

  const fetchDroughtManagementPlanStageLocal = async (communityObject: ValidModelObject<'Customer'>) => {
    let mapperData = await getCustomerHierarchy(communityObject);
    const customerMapper = new NodeEdgeMapper();
    if (mapperData.err == null && mapperData.data != null) {
      const filterHierarchNodes: ValidBusinessObjectList = mapperData.data.nodes as ValidBusinessObjectList;
      const filterHierarchEdges: ValidBusinessObjectList = mapperData.data.edges as ValidBusinessObjectList;
      customerMapper.loadItems(filterHierarchNodes);
      customerMapper.loadItems(filterHierarchEdges);
    }
    let planContext: ValidBusinessObject | undefined = communityObject;
    while (planContext !== undefined) {
      let plan: GetAdjacentResponse = await getAdjacent(planContext, 'DroughtManagementPlan', PathDirection.child);

      if (plan.data && plan.data.length > 0) {
        var planCasted = plan.data[0];
        if ((planCasted.stage as DMPStage[]) !== undefined) {
          setDroughtManagementPlan(planCasted as C021ValidModelObject<'DroughtManagementPlan'>);
          setCurrentPlanStage(planCasted.stage[planCasted.activestage]);
        }
      }
      let next: GetAdjacentResponse = await getAdjacent(planContext, 'Customer', PathDirection.parent);

      if (next.data && next.data.length === 1) {
        planContext = next.data[0];
      } else {
        planContext = undefined;
        break;
      }
    }
  };

  const locatableAddress = matchedPrimary ? getGeoCodeableAddress.call(matchedPrimary) : undefined;
  const getLocation = () => {
    const location = getAverageTankGeoLocation(communityTanks as { coordinates?: Position }[]);
    console.log(location);

    return location;
  };

  /**
   * Calculates the number of tank users for all of the tanks in the community
   * @returns A number representing the total number of tank users in the community
   */
  const numTankUsers = () => {
    const numTankUsers = communityTanks.reduce((acc: number, curr) => {
      return (acc += !isNaN(curr.no_of_users) ? curr.no_of_users : 0);
    }, 0);
    return numTankUsers;
  };

  const viewObjects = [
    {
      matchedPrimary: entity,
      matchedRelatedByPk: [],
      matchedRelatedBySk: communityTanks,
    },
  ];

  const getLastDayForWaterReplacement = () => {
    const community_days_left = entity?.community_days_left;

    const currentDate = new Date();
    const lastDate = new Date(currentDate);
    lastDate.setDate(lastDate.getDate() + community_days_left + parseInt(droughtManagementPlan?.waterDeliveryLeadTime ?? 0));

    return lastDate.toLocaleDateString();
  };

  const renderGoalMetric = (goal: any) => {
    let usageArray = [];
    let average;

    switch (goal.goal) {
      case 'Reduce Daily Usage':
        console.log('hi');
        usageArray = objectHistory?.dailyStateHistory?.average_usage_in_day_household?.slice(goal.timeframe * -1) ?? [];

        average =
          usageArray.reduce((acc: number, curr: number) => {
            return (acc += curr);
          }, 0) / goal.timeframe;

        return `${Math.round(average)} L` ?? 'Calculating..';
      case 'Reduce Weekly Usage':
        usageArray = objectHistory?.weeklyStateHistory?.community_usage_in_week?.slice(goal.timeframe * -1) ?? [];

        average =
          usageArray.reduce((acc: number, curr: number) => {
            return (acc += curr);
          }, 0) / goal.timeframe;
        return `${Math.round(average)} L` ?? 'Calculating..';
      case 'Reduce Daily Usage Per occupant':
        usageArray = objectHistory?.dailyStateHistory?.average_usage_in_day_household?.slice(goal.timeframe * -1) ?? [];

        average =
          usageArray.reduce((acc: number, curr: number) => {
            return (acc += curr);
          }, 0) / goal.timeframe;

        let averagePerOccupant = average / numTankUsers();

        return `${Math.round(averagePerOccupant)} L` ?? 'Calculating..';
    }
  };
  console.log("droughtManagementPlan", droughtManagementPlan)

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CommunityTankGauges householdTanks={householdTanks} ICCTanks={ICCTanks} customerIn={customerIn} />
        </Grid>
        <Grid item xs={4}>
          <GraphContainer objectHistory={objectHistory} />
        </Grid>
        <Grid item container xs={5}>
          <Grid item xs={12}>
            <Box className={styles.mapC021TankGroupCard}>{communityTanks && <MapView {...{ position: undefined, editable: false, viewObjects }} />}</Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <CommunitySummary householdTanks={householdTanks} ICCTanks={ICCTanks} customerIn={customerIn} objectHistory={objectHistory}></CommunitySummary>
        </Grid>

        <Grid item xs={7}>
          <Grid item xs={12}>
            <Card className={styles.droughtManagmentPlanCard}>
              <Typography variant='h6' align='center'>
                {customerIn?.name} Drought Management Plan
              </Typography>
              {
                //Renders Drought management plan info if community has been assigned one
                currentPlanStage && droughtManagementPlan ? (
                  <Grid container xs={12}>
                    <Grid item container xs={3}>
                      <Grid item xs={12}>
                        <strong>Drought Management Stage: </strong> {droughtManagementPlan!.activestage + 1}
                      </Grid>
                    </Grid>

                    <Grid item container xs={9}>
                      <Grid item xs={12}>
                        <Table>
                          <TableRow>
                            <TableCell>Stage Goals</TableCell>
                            <TableCell>Goal</TableCell>
                            <TableCell>Tracking</TableCell>
                          </TableRow>
                          {currentPlanStage.goals.map((goal) => {
                            return (
                              <TableRow>
                                <TableCell>{goal.goal}</TableCell>
                                <TableCell>
                                  {goal.timeframe} {goal.metric}
                                </TableCell>
                                <TableCell>TBD</TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  //If no Drought Management plan assigned. Render card saying there is no plan assigned
                  <Grid item xs={12} className={styles.smallPadding}>
                    <Typography variant='h4' align='center'>
                      No Drought Management Plan Assigned
                    </Typography>
                  </Grid>
                )
              }
            </Card>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
                                    <Typography variant='h6' component='h6'>
                                        Communications
                                    </Typography>
                                    <Card>

                                    <Table>
                                        <TableRow>
                                            <TableCell>Date Sent</TableCell>
                                            <TableCell>Sent to</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>04/03/2021</TableCell>
                                            <TableCell>Tank Owners</TableCell>
                                            <TableCell>Please stop watering gardens</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>04/03/2021</TableCell>
                                            <TableCell>Community Water Managers</TableCell>
                                            <TableCell>Te Kao has triggered Stage 2 Drought Management Response</TableCell>
                                        </TableRow>
                                    </Table>
                                    </Card>
                                </Grid>  */}
      </Grid>

      {/* </Grid> */}
    </div>
  );
};
