import React, { useEffect } from "react";
import { getQuarterHourPeriodizer } from "../../data/aws/s3/AccumulationPeriodization";
import { DimensionedPeriodizer, getZombieInstanceFromPrimaryKey, Milliseconds } from "@iotv/datamodel-core";
import { ObjectCardProps } from "../../types/AppTypes";

export const ObjectDataPeriodizationCard = ( {}: ObjectCardProps ) => {

    useEffect( () => {
        const vob = getZombieInstanceFromPrimaryKey('Horse:7a8c6d91-9932-4ac5-9f83-73a46ab4f065')!
        getQuarterHourPeriodizer( vob ).then( ( { err, data } ) => setPeriodizer( data ?? undefined  ) )
    }, [])

    const [ periodizer, setPeriodizer ] = React.useState<DimensionedPeriodizer<any, any> | undefined > (undefined)

    return  periodizer ? <div>{ JSON.stringify(periodizer.toSnapShotByTimestampRange( [Date.now() - Milliseconds.DAY, Date.now()])) }</div> : <div>Waiting</div>
} 