import { DeviceTypes, Networks } from "@iotv/iotv-v3-types";
import { GridValueFormatterParams } from "@mui/x-data-grid";

type ValueFomatterType = (params: GridValueFormatterParams<any>) => any

export const NWName: ValueFomatterType = ( p ) => {
    const nwId: Networks = p.value
    switch ( nwId ) {
        case Networks.TTN_Enterprise: return 'TTNE'
        case Networks.Chirpstack: return 'CS'
        case Networks.TTN_Community: return 'TTNC'
        case Networks.ThingPark: return 'TP'
        default: return nwId
    }

}

export const DeviceTypeFormatter: ValueFomatterType = ( p ) => {
  return DeviceTypes[p.value]
}