import { MapLike, ValidModelObjects } from '@iotv/datamodel';

import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserFunctions } from '../actions/AppActions';
import LSCDeviceControlView from '../components/DeviceControl/DeviceControlView';
import { filterByTypeIds, flattenToArray } from '../data/daoFunctions/stateFns';
import { RootState } from '../reducers';
import { DiagnosticViewProps } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;
const {  REACT_APP_PROJECT_CODE: projectCode = 'i301' } = process.env
// export type DiagnosticViewState = {
//   deviceMessages: ValidModelObjects<'ThingShadowState'>
// }

const deviceControlViewMap: MapLike< ( props: DiagnosticViewProps ) => JSX.Element> = {
  default: LSCDeviceControlView,
}

const DeviceControlView = deviceControlViewMap[ projectCode.substring(0, 4) ] ?? deviceControlViewMap.default

const diagnosticStateToProps = (state: RootState, ownProps: any) => {
    // debug && console.log('DiagnosticContainer own props', ownProps)
    // const deviceEnabledTypeKeys = Object.keys(deviceEnabledTypes);
    // const typeFilter = ['Geofence', ...deviceEnabledTypeKeys];

    const deviceMessagesVO = filterByTypeIds(state.AppReducer.normalData ?? {}, ['ThingShadowState'])
    const deviceMessages = flattenToArray(deviceMessagesVO) as ValidModelObjects<'ThingShadowState'>

    return {
        // isAuthenticated: state.AccessReducer.isAuthenticated,
        // cognitoUser: state.AccessReducer.cognitoUser,
        // userGroupRoles: state.AppReducer.userGroupRoles,
        user: state.AppReducer?.user,
        userSelectedGroup: state.AppReducer.userSelectedGroup,
        contextCustomer: state.AppReducer.contextCustomer,
        deviceMessages,
     
      
      }
}

const diagnosticDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
    return {
        // init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
        //      debug && console.log('Map View requested init', ownProps)
        // }
        userFunctions: bindActionCreators(UserFunctions, dispatch),
      }
}

export default connect(diagnosticStateToProps, diagnosticDispatchToProps)(DeviceControlView)