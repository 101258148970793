import { useRef, useState, useEffect } from 'react'
import { Card, Grid, Typography, useTheme } from '@mui/material'
import LiquidFillGauge from 'react-liquid-gauge';
import { useContainerDimensions } from '../../../../hooks/useContainerDimenstions';
import { getColour } from '../CommunitiesView';
import { color } from 'd3-color';

import { ValidBusinessObject, ValidModelObject } from '@iotv/datamodel';

import { Tank, ValidModelObjects, } from '@c021/datamodel';
import { useStyles } from '../../cosmetics/communityStyles';

type CommunityTankGaugesProps = {
    householdTanks: ValidModelObjects<'Tank'>;
    ICCTanks: ValidModelObjects<'Tank'>;
    customerIn: ValidModelObject<'Customer'> | undefined
}

export const CommunityTankGauges = (props: CommunityTankGaugesProps) => {

    const [entity, setEntity] = useState<ValidModelObject<'Customer'> | undefined>(props.customerIn);
    const [householdTankVolume, setHouseholdTankVolume] = useState<number>(0);
    const [communityTankVolume, setCommunityTankVolume] = useState<number>(0);

    

    useEffect(() => {
        if (props.customerIn) {
            const allTanks = [...props.householdTanks, ...props.ICCTanks];
            const totalVolme = allTanks.reduce((acc, curr) => {
                if (curr) {
                    const tank = new Tank(curr);
                    acc = acc + tank._totalVolume;
                }
                return acc;
            }, 0 as number);
            setEntity(props.customerIn);
            //fetchCommunityTanks(props.customerIn)

            setHouseholdTankVolume(calculateTankTypeVolume(props.householdTanks, totalVolme));
            setCommunityTankVolume(calculateTankTypeVolume(props.ICCTanks, totalVolme));
        }
    }, [props.customerIn?.current_filled_factor, props.customerIn?.sk, props.householdTanks.length, props.ICCTanks.length]);

    /**
    * Calculates the current volume for a given tank type
    * @param tankType The tank type (Community or Household)
    * @returns
    */
    const calculateTankTypeVolume = (tankList: ValidBusinessObject[], communityVolume: number) => {
        let totalVolume = 0;

        const currentFillVolume = tankList.reduce((acc, curr) => {
            return (acc += typeof curr.current_filled_volume === 'number' ? curr.current_filled_volume : 0);
        }, 0);

        const calculated = (currentFillVolume / communityVolume) * 100;

        return isNaN(calculated) ? 0 : calculated;
    };

    const theme = useTheme();
    const styles = useStyles();

    const gaugeGridRef = useRef(null);
    const { width: gaugeGridWidth, height: gaugeGridHeight } = useContainerDimensions(gaugeGridRef);
    const radius = (gaugeGridWidth / 2) * 0.9;
    var color_of_silo = theme.palette.primary.main;


    const gradientStops = [
        {
            key: '0%',
            stopColor: color(color_of_silo).darker(0.5).toString(),
            stopOpacity: 1,
            offset: '0%',
        },
        {
            key: '50%',
            stopColor: color_of_silo,
            stopOpacity: 0.75,
            offset: '50%',
        },
        {
            key: '100%',
            stopColor: color(color_of_silo).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: '100%',
        },
    ];

    return (
        <Card className={styles.gaugeContainer}>
            <Grid container xs={12} alignItems='center' justifyContent='center' direction='column'>
                <Grid container item xs={12} alignItems='center' justifyContent='center' direction='column'>
                    <Grid item {...{ xs: 8, ref: gaugeGridRef }} alignItems='center' style={{ marginBottom: '10px' }}>
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={radius * 2.5}
                            height={radius * 2.5}
                            value={entity?.current_filled_factor * 100}
                            percent='%'
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={(props: any) => {
                                const value = Math.round(props.value);
                                const radius = Math.min(props.height / 2, props.width / 2);
                                const textPixels = (props.textSize * radius) / 2;
                                const valueStyle = {
                                    fontSize: textPixels,
                                };
                                const percentStyle = {
                                    fontSize: textPixels * 0.6,
                                };

                                return (
                                    <tspan>
                                        <tspan className='value' style={valueStyle}>
                                            {value}
                                        </tspan>
                                        <tspan style={percentStyle}>{props.percent}</tspan>
                                    </tspan>
                                );
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                                fill: getColour(entity?.current_filled_factor * 100),
                            }}
                            waveStyle={{
                                fill: color_of_silo,
                            }}
                            textStyle={{
                                fill: color('#444').toString(),
                                fontFamily: 'Arial',
                            }}
                            waveTextStyle={{
                                fill: color('#262626').toString(),
                                fontFamily: 'Arial',
                            }}
                        />
                        <Typography variant='h6' align='center'>
                            All Tanks
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} alignItems='center' justifyContent='center' direction='row'>
                    <Grid item {...{ xs: 5, ref: gaugeGridRef }} alignItems='center'>
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={radius * 1.75}
                            height={radius * 1.75}
                            value={householdTankVolume}
                            percent='%'
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={(props: any) => {
                                const value = Math.round(props.value);
                                const radius = Math.min(props.height / 2, props.width / 2);
                                const textPixels = (props.textSize * radius) / 2;
                                const valueStyle = {
                                    fontSize: textPixels,
                                };
                                const percentStyle = {
                                    fontSize: textPixels * 0.6,
                                };

                                return (
                                    <tspan>
                                        <tspan className='value' style={valueStyle}>
                                            {value}
                                        </tspan>
                                        <tspan style={percentStyle}>{props.percent}</tspan>
                                    </tspan>
                                );
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                                fill: getColour(householdTankVolume),
                            }}
                            waveStyle={{
                                fill: color_of_silo,
                            }}
                            textStyle={{
                                fill: color('#444').toString(),
                                fontFamily: 'Arial',
                            }}
                            waveTextStyle={{
                                fill: color('#262626').toString(),
                                fontFamily: 'Arial',
                            }}
                        />
                        <Typography variant='subtitle1' align='center'>
                            Household tanks
                        </Typography>
                    </Grid>
                    <Grid item {...{ xs: 5, ref: gaugeGridRef }} alignItems='center'>
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={radius * 1.75}
                            height={radius * 1.75}
                            value={communityTankVolume}
                            percent='%'
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={(props: any) => {
                                const value = Math.round(props.value);
                                const radius = Math.min(props.height / 2, props.width / 2);
                                const textPixels = (props.textSize * radius) / 2;
                                const valueStyle = {
                                    fontSize: textPixels,
                                };
                                const percentStyle = {
                                    fontSize: textPixels * 0.6,
                                };

                                return (
                                    <tspan>
                                        <tspan className='value' style={valueStyle}>
                                            {value}
                                        </tspan>
                                        <tspan style={percentStyle}>{props.percent}</tspan>
                                    </tspan>
                                );
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                                fill: getColour(communityTankVolume),
                            }}
                            waveStyle={{
                                fill: color_of_silo,
                            }}
                            textStyle={{
                                fill: color('#444').toString(),
                                fontFamily: 'Arial',
                            }}
                            waveTextStyle={{
                                fill: color('#262626').toString(),
                                fontFamily: 'Arial',
                            }}
                        />
                        <Typography variant='subtitle1' align='center'>
                            Community tanks
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    )
}