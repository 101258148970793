import { DataGridProps } from "@mui/x-data-grid";
import { GetDataGridPropsRequest, GetDataGridSelectableObjectsRequest, LSC_DataGridColumnMap, LSC_DataGridRowProxy } from "src/types";
import { DeviceProps, ValidBusinessObject, ValidModelObject } from "@iotv/datamodel-core";
import { radioSelector, SelectAll } from "../factories/DataGrid";
import { DeviceTypeFormatter } from "../factories/DataGrid/cellFormatters";
import { IdType } from "@iotv/iotv-v3-types";

const debug = process.env.REACT_APP_DEBUG && false;

export type AttributeSelectableType = DeviceProps & { id: string, sk: string, pk: string, type: string }
type DisplayAttributes = Pick<AttributeSelectableType, 'eui' | 'deviceType'  | 'id' | 'deviceId'>
type AdditionalColumns = { select: any }

export type RequiredDataGridInputs = GetDataGridSelectableObjectsRequest<AttributeSelectableType> & GetDataGridPropsRequest<AttributeSelectableType>

export function getDataGridProps<T extends IdType >( { items, loading, selectedObjects, setSelectedObjects, addToSelectedObjects, isInSelectedObjects, removeFromSelectedObjects }: RequiredDataGridInputs): DataGridProps | undefined  {
    let dataGridProps: DataGridProps | undefined = undefined
    
    const togglePresenceInSelectedObjects = ( v: boolean, id: string ) => {
        const focusItem = items.find( ( item ) => item.id === id )
        if ( focusItem ) {
            ( v ? addToSelectedObjects( focusItem ) : removeFromSelectedObjects( focusItem ))
        } 
    }



    const columnMap: LSC_DataGridColumnMap<AttributeSelectableType & AdditionalColumns> = {
        select: {
            valueGetter: (gridCellParams) => isInSelectedObjects( { id: gridCellParams.row.eui }),
            renderCell: (gridCellParams) => radioSelector({ gridCellParams, valueSetter: togglePresenceInSelectedObjects }),
            renderHeader: ( gridHeaderParams ) => <SelectAll { ...{ items, selectedObjects, setSelectedObjects } }/>
        },

        eui: {
            valueGetter: ( gridCellParams ) => { const { eui, id } = gridCellParams.row; return eui ?? id },
            minWidth: 250
        },
        deviceType: {
            valueFormatter: DeviceTypeFormatter,
            minWidth: 150
        },
        deviceId: {
            minWidth: 250
        }
    }

    const rowToGridRow = (deviceMessage: AttributeSelectableType ): LSC_DataGridRowProxy<DisplayAttributes> => {
        const { id, eui, deviceType, deviceId }: DisplayAttributes = deviceMessage
        return {
            id: `${eui ?? id}`,
            eui, deviceType, deviceId
        }
    }

    dataGridProps = {
            density: 'compact',
            // error: (e: Error) => console.log('DataGridError', e.message), // apparently not a fn but error object
            columns: Object.entries(columnMap).map(([k, v]) => ({ field: k, ...v })),
            rows: items.map(rowToGridRow),
            getRowClassName: (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
            loading
    
        }

    return dataGridProps
}