import { PossibleBusinessObject } from '@iotv/datamodel-core';

export interface CollectionProps {
  effective_area?: number;
  roof_total_area?: number;
}

export const createCollectionAreaZombie = (props: CollectionProps): PossibleBusinessObject => {
  const { effective_area = 0, roof_total_area = 0 } = props;
  return {
    type: 'CollectionArea',
    effective_area: effective_area,
    roof_total_area: roof_total_area,
  };
};
