
import { useHistory } from "react-router-dom";
import { getQueryParamsFromHistory, getQueryParamsFromRouterHistory } from "../util/Routing/queryParams";
import queryString from 'query-string'
import React from "react";
import { KeyMappedComponentFunctions, KeyMappedComponents } from "src/types/AppTypes";

const debug = process.env.REACT_APP_DEBUG && false;

export type UseTabHanderHookReturnType = {
    state: [[number , React.Dispatch<React.SetStateAction<number>>]];
    handlers: {
        handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    };
}

export const useTabHandler = <T extends { tabName: string }>( tabsToDisplay: KeyMappedComponentFunctions | KeyMappedComponents,): UseTabHanderHookReturnType => {
    const history = useHistory();
    const searchParams = getQueryParamsFromRouterHistory<T>(history)
    const tabKeys = Object.keys(tabsToDisplay)

    const [tabValue, setTabValue] = React.useState(Math.max (tabKeys.findIndex((tabName) => tabName === searchParams?.tabName) , 0 ));
    const handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void = (event: React.ChangeEvent<{}>, newValue: number) => {
        debug && console.log('handleTabCahnge', { event, newValue })
        const newSearchParems = { ...searchParams, tabName: tabKeys[newValue] ?? tabKeys[0] }
        /* THIS CAUSES ENTIRE APP TO RERENDER 
        const newUrl = queryString.stringifyUrl(
            {
                url: routerHistory.location.pathname,
                query: newSearchParems
            }
        )
        //routerHistory.push(newUrl);'
        */
        setTabValue(newValue)
    };

    return {
        state: [[tabValue, setTabValue]],
        handlers: {
            handleTabChange
        }
    }

}

