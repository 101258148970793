import { GridRenderCellParams } from "@mui/x-data-grid"
import { PotentialObjectType, ValidBusinessObject } from "@iotv/iotv-v3-types"
import { getZombieInstanceFromPrimaryKey } from "@iotv/datamodel-core"
import { Link } from "react-router-dom"

type RenderCellType = (params: GridRenderCellParams<any, any, any>) => React.ReactNode

/**
 * Wraps a hyperlink aound cell contents
 */
export const wrapLink = (to: string, cellRenderFn?: RenderCellType  ) => ( p: GridRenderCellParams ) => <Link { ...{to }}>{cellRenderFn?.(p) ?? p.value} </Link> 

/**
 * Assumes row id or provided skKey is an sk of an object and wraps with a hypelink to THINGVIEW for that object
 */
export const wrapLinkVOB = ( params: GridRenderCellParams<any, any, any>, skKey: string = 'id', cellRenderFn?: RenderCellType) => {
    const vob: PotentialObjectType | undefined = getZombieInstanceFromPrimaryKey( params.row[skKey])

     const to: string | undefined = vob ? `/THINGVIEW/${vob.type}/${vob.id}` : undefined

    return to ? wrapLink( to, cellRenderFn)(params) : cellRenderFn ? cellRenderFn(params ) : params.value
}