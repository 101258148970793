import { Button } from '@mui/material';
import { ValidBusinessObject } from '@iotv/datamodel';
import { deleteObject } from '../../../../data/daoFunctions/daoFunctions';
import { removeFromStateList } from '../helperFunctions/helperFunctions';


export default function DeleteButton(existingObject: ValidBusinessObject, setOpen: React.Dispatch<React.SetStateAction<boolean>>, objs?: ValidBusinessObject[], setObjs?: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>) {
    const deletePlace = (existingObject: ValidBusinessObject) => {
        deleteObject(existingObject);
        setOpen(false);
    }

    return (
        <Button onClick={() => { deletePlace(existingObject); setObjs && removeFromStateList(objs, existingObject, setObjs) }}>Delete</Button>
    )
}