import { makeStyles, createStyles, Theme } from "@material-ui/core";


export const InputOverrides =  (theme: Theme) => ( {
    root: {
        '& .MuiTableCell-root': {
            padding: 0, borderStyle: 'none', 
        },
        
        '& .MuiInput-underline:before': {
          borderStyle: 'none'
        },
        '& .MuiInput-underline:after': {
        //   borderBottomColor: 'inherit',
        },
        '& .MuiInputBase-root': {
            fontSize: 15
        },
        '& .MuiInput-underline' : {
           borderStyle: 'none'
        
        },
        '& .MuiSelect-select': {
            fontSize: 15, 
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
          color: theme.palette.primary.light,
         
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.light
        },
        '& .MuiSvgIcon-root': {
          color: 0xFFFFFF
        },
        '& .MuiFormControl-root': {
          width: '100%'
        }
        // '& .MuiFilledInput-input':  {
        //   padding: 0
        //   }
       
      },
      radio: {
        fontSize: 12,
       },
       smaller: {
        fontSize: 12
      },

})

export const useStyles = makeStyles((theme) => ( {
    ...createStyles({ ...InputOverrides(theme), 
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        }
    }) 
} ) );