import { camelToDisplay, DownLinkControlObjectType } from "@iotv/datamodel";
import { Input, Slider, TableCell, TableRow } from "@material-ui/core";
import { CommonRowProps } from "./types";
const debug = process.env.REACT_APP_DEBUG && false;

export type SimpleNumberRowProps = CommonRowProps & {
    v: number, currentValue: number
        , min: number, marks: boolean, max: number, step: number | null
}

export function SimpleNumberRow({ desiredPayload, setDesiredPayload, setHasEdits, k, v, currentValue, limits, min, max, marks, step, keyWidth, inputWidth, i }: SimpleNumberRowProps): JSX.Element {
    const handleNumberChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, k: keyof Partial<DownLinkControlObjectType>) => {
        const value = parseInt(e.target.value);
        const { attributeType, range: { min, max } } = limits

        debug && console.log('limits',  limits)
        if (value >= min && value <= max) {
            setDesiredPayload({ ...desiredPayload, [k]: value })
        } else {
           debug && console.log('do something with err')
        }

    }

    return <TableRow {...{ key: `r_${i}` }}>
        <TableCell {...{ key: `key`, style: { width: keyWidth } }}>{camelToDisplay(k)}</TableCell>
        <TableCell {...{ key: `val`, style: { width: inputWidth } }}>{v}</TableCell>
        <TableCell {...{ key: `input`, style: { width: inputWidth } }}>
            <Input {...{
                type: 'number', style: { width: inputWidth },
                value: desiredPayload?.[k as keyof typeof desiredPayload] ?? 0,
                onChange: (e) => { setHasEdits(true); handleNumberChange(e, k) },
            }} />
        </TableCell>
        <TableCell {...{ key: `slider`, style: { width: inputWidth } }}>
            <Slider {...{
                value: currentValue ?? min,
                marks, min, max, step, color: 'secondary',
                getAriaValueText: (v, i) => v.toString(),
                'aria-labelledby': "discrete-slider",
                valueLabelDisplay: "auto",
                onChangeCommitted: (e, sVal: number | number[]) => { e.preventDefault(); e.stopPropagation(); sVal = sVal instanceof Array ? sVal[0] : sVal; setHasEdits(true); setDesiredPayload({ ...desiredPayload, [k]: sVal }); }
            }} />
        </TableCell>

    </TableRow>;
}
