import {  Assembler, EventDependentSubscriptionState, getZombieInstanceFromPrimaryKey, IncidentType, INotificationSubscription, NotificationDeliveryConfig, NotificationSubscription, PathDirection, PostponedSubscriptionState, Severity, SubscribedStatusUnion, ValidModelObject, ValidModelType } from "@iotv/datamodel";
import { deviceEnabledTypes } from "@iotv/datamodel";
import { AdjacentType, IOTV_Notifiable_EventType, isValidBusinessObject, ValidBusinessObject } from "@iotv/iotv-v3-types";
import { Button, Collapse, FormControl, FormGroup, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect } from "react";
import { IUserMessageType, MessageOutputType, UserFunctions, Severity as AppSeverity} from "../../types";
import { useStyles } from "../../cosmetics/InputStyles";
import { denseTheme } from "../../cosmetics/Themes/dense";
import { createAndLink, CreateAndLinkRequest, deleteObject, getExact, getOne } from "../../data/daoFunctions/daoFunctions";
import { SingleObjectAutoComplete } from "../generic/ObjectAutoComplete";
import { DeliveryOptions } from "./DeliveryOption";
import { SubscriptionState } from './SubscriptionState';
import { v4 } from "uuid";
const debug = process.env.REACT_APP_DEBUG && false;

const validDeviceEnabledTypeIds = Object.keys(deviceEnabledTypes) as ValidModelType[];
const notifiableType: ValidModelType[] = [ ...validDeviceEnabledTypeIds , 'Group', 'Customer', 'AggregationGroup'];
const noOptions: string = 'No options available'
const requestSetOption: string = 'Please set option'

const instanceClassName = 'SubscriptionLineItem';
type SubscriptionLineItemProps = { contextObject: ValidBusinessObject | undefined, contextCustomer: ValidModelObject<'Customer'>, contextUser: ValidModelObject<'User'>, sub: ValidModelObject<'NotificationSubscription'> & NotificationSubscription & INotificationSubscription<SubscribedStatusUnion>, forceUpdate: () => void, userFunctions: UserFunctions  }
export const SubscriptionLineItem = ({ contextObject, contextCustomer, contextUser, sub: subIn, forceUpdate, userFunctions}: SubscriptionLineItemProps) => {

    debug && console.log('SubscriptionLineItem gets', { forceUpdate })

    const classes = useStyles(denseTheme)
    const [sub, setSub] = React.useState<INotificationSubscription<SubscribedStatusUnion> | undefined>(subIn);
    const referenceSubHash = [ sub?.incidentEntityOrGroupingSk, sub?.incidentType, sub?.subscriptionState.status, (sub?.subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs,  (sub?.subscriptionState as EventDependentSubscriptionState )?.reapplyAfterEvent, sub?.deliveryPreferences.map( ( item ) => item.recipients.length).join() ].join()


    const defaultIncidentEntityOrGrouping: ValidModelObject<ValidModelType> | undefined = sub?.incidentEntityOrGroupingSk ? { ...getZombieInstanceFromPrimaryKey(sub?.incidentEntityOrGroupingSk), name: sub?.incidentEntityOrGroupingName } as ValidModelObject<ValidModelType> : undefined

    const [objectTypeId, setObjectTypeId] = React.useState<ValidModelType | undefined>(defaultIncidentEntityOrGrouping?.type as ValidModelType || undefined)


    const [notificationSubscription, setNotificationSubscription] = React.useState<INotificationSubscription<SubscribedStatusUnion> | undefined>(sub)
    const [incidentEntitiyOrGrouping, setIncidentEntityOrGrouping] = React.useState<ValidModelObject<ValidModelType> | undefined>(defaultIncidentEntityOrGrouping)
    const [incidentType, setIncidentType] = React.useState<IncidentType | undefined>(notificationSubscription?.incidentType)
    const [ incidentSubtypeDefiningObject, setIncidentSubtypeDefiningObject ] = React.useState<ValidBusinessObject | undefined >(undefined)

    const [severities, setSeverities] = React.useState<Severity[]>(notificationSubscription?.filter.severities ?? [])
    const [eventTypes, setEventTypes] = React.useState<IOTV_Notifiable_EventType[]>(notificationSubscription?.filter.onActions ?? [])
    const [subscriptionState, setSubscriptionState] = React.useState<Partial<SubscribedStatusUnion>>(notificationSubscription?.subscriptionState ?? {})
    const [deliveryOptions, setDeliveryOptions] = React.useState<NotificationDeliveryConfig[]>(notificationSubscription?.deliveryPreferences ?? [])

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [edited, setEdited] = React.useState<boolean>(false);
    const currentSubHash = [ incidentEntitiyOrGrouping?.sk, incidentType, subscriptionState.status, (subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs,  (subscriptionState as EventDependentSubscriptionState )?.reapplyAfterEvent, deliveryOptions.map( ( item ) => item.recipients.length).join() ].join()

    const getObject = async (objectSk: string, setterFn: ( ob: ValidBusinessObject ) => void) => {
        const ob = Assembler.getZombieFromPrimaryKey(objectSk);
        if (ob) {
            getOne( ob ).then( ( { err, data }) => data && setterFn(data) )
        }
     
    }

    useEffect( ( ) => {
        setEdited( currentSubHash !== referenceSubHash  )
    }, [ currentSubHash ])

    useEffect( () => {
        if ( notificationSubscription?.incidentSubtype ) {
            getObject( notificationSubscription.incidentSubtype, setIncidentSubtypeDefiningObject )
        }

    }, [ notificationSubscription?.incidentSubtype ])


    const displayMessage = ( content: string, type: MessageOutputType, severity: AppSeverity | undefined) => {
        const msg: IUserMessageType = {
            content, id: v4(), label: '!!', type, severity
        }
        userFunctions.setUserMessage( msg )
    }
  

    const handleSave = async () => {
        if (objectTypeId && incidentEntitiyOrGrouping && subscriptionState.status !== undefined) {
            const update: INotificationSubscription<SubscribedStatusUnion> = {
                deliveryPreferences: deliveryOptions,
                filter: {
                    severities, statuses: [], onActions: eventTypes,
                },
                incidentEntityOrGroupingSk: incidentEntitiyOrGrouping?.sk,
                incidentInitiatorSk: undefined,
                incidentProducingTypeId: objectTypeId,
                incidentType: incidentType,
                incidentSubtype: incidentSubtypeDefiningObject?.sk, // needs to be populated in case of business rule violation
                subscriptionState: subscriptionState as SubscribedStatusUnion
            }
            const { sk, pk, id, type } = subIn;
            const notificationSubscription = new NotificationSubscription({ ...update, sk, pk, id, type });
            notificationSubscription.name = notificationSubscription.dsk
            notificationSubscription.incidentEntityOrGroupingName = incidentEntitiyOrGrouping.name



            const params: CreateAndLinkRequest = {
                existingObject: contextUser,
                newObject: notificationSubscription,
                direction: PathDirection.child,
                edgeAttrbutes: undefined,
                edgeTypeId: 'User_has_NotificationSubscription' //edgeTypeMatrix.User.NotificationSubscription.User_has_NotificationSubscription.edgeTypeId
            }

            debug && console.log(`ON SAVE`, { incidentSubtypeDefiningObject, notificationSubscription})

            const saveRes = await createAndLink(params)
            if (!saveRes.err) {
                debug && console.log('Save res data', saveRes.data)
                debug && console.log('SubscriptionLineItem about to force update on save')
                setSub(notificationSubscription)
                forceUpdate()
            } else {
                debug && console.log('Save res err', saveRes.err)
            }
        } else {
            debug && console.log('something not defined', {objectTypeId , incidentEntitiyOrGrouping, status: subscriptionState.status})
        }

    }

    const handleDelete = async ( ) => {
        const deleteRes = await deleteObject( subIn );
        if ( !deleteRes.err ) {
            debug && console.log('SubscriptionLineItem about to force update on delete')
            forceUpdate()
        } else {
            debug && console.log('Delete res err', deleteRes.err)
            debug && console.log('Input was ', JSON.stringify(subIn, null, 1))
        }
    }

    const handleFormChange = ( e: React.ChangeEvent<HTMLInputElement>  ) => {
        debug && console.log('Form change')
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getIncidentSubtypeObject = async ( sk: string) => {
        const res = await getExact( getZombieInstanceFromPrimaryKey( sk )!) 
        if ( res.data ) {
            const vob = res.data.Items?.[0];
           
            if ( isValidBusinessObject( vob )) {
                displayMessage( `setting business rule with ${ vob?.id}`, 'SnackBar', AppSeverity.error )
                setIncidentSubtypeDefiningObject( vob  )
            }

         
        } else if (res.err) {
            displayMessage(  res.err.message, 'MessageBar', AppSeverity.error )
        }
    }

    useEffect(() => {

        if ( sub?.incidentSubtype ) {
            
            getIncidentSubtypeObject(sub.incidentSubtype)
        }
    }, [(sub as {sk?: string })?.sk])

    useEffect(() => {
        debug && console.log('SubscriptionLineItem used effect on incidentEntitiyOrGrouping', incidentEntitiyOrGrouping)
    }, [ incidentEntitiyOrGrouping?.sk ])

    const style: React.CSSProperties | undefined = { width: '15rem'}

    const componentMap = {
        objectTypeId: <FormControl {...{ key: 'objectTypeId' }}>
            <Autocomplete {...{
                freeSolo: false, id: 'selectObjectTypeId', style, size: 'small', variant: "outlined", options: notifiableType,
                getOptionLabel: (option: ValidModelType) => option ?? noOptions, value: objectTypeId ?? requestSetOption as ValidModelType,
                onChange: (e, v: string | null) => {
                    v && setObjectTypeId(v as ValidModelType)
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: 'Entity Type'
                }} />
            }} />
        </FormControl>,

        entity: <SingleObjectAutoComplete {...{ contextObject, contextUser, objectTypeId
            , adjacencyType: contextObject ? AdjacentType.CHILD : AdjacentType.SUBJECT
            , currentEntity: incidentEntitiyOrGrouping, setCurrentEntity: setIncidentEntityOrGrouping, style }}></SingleObjectAutoComplete>,

        businessRuleChoice: <SingleObjectAutoComplete { ...{
            contextObject: undefined, contextUser, objectTypeId: 'Ruleset', adjacencyType: AdjacentType.SUBJECT
            , currentEntity: incidentSubtypeDefiningObject, setCurrentEntity: setIncidentSubtypeDefiningObject, style 
        }}/>,

        incidentType: <FormControl {...{ key: 'incidentType' }}>
            <Autocomplete {...{
                freeSolo: false, id: 'selectIncidentType', style, size: 'small', variant: "outlined", options: Object.values(IncidentType) as string[],
                getOptionLabel: (v: string) => v ?? noOptions, value: incidentType ?? noOptions,
                onChange: (e, v: IncidentType | string | null) => {
                    setIncidentType(v as IncidentType)
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: "Incident Type"
                }} />
            }} />
        </FormControl>,
        severity: <FormControl {...{ key: 'severity' }}>
            <Autocomplete {...{
                freeSolo: false, id: 'selectSeverity', style, size: 'small', variant: "outlined", options: Object.values(Severity) as string[], multiple: true, filterSelectedOptions: true,
                getOptionLabel: (v: string) => v ?? noOptions, value: severities ?? noOptions,
                onChange: (e, v: Severity[] | string[] | null) => {
                    setSeverities(v as Severity[])
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: "Severity",
                }} />
            }} />
        </FormControl>,
        eventType: <FormControl {...{ key: 'eventType' }}>
            <Autocomplete {...{
                freeSolo: false, id: 'select', style, size: 'small', variant: "outlined", options: Object.values(IOTV_Notifiable_EventType) as string[], multiple: true, filterSelectedOptions: true,
                getOptionLabel: (v: string) => v ?? noOptions, value: eventTypes ?? noOptions,
                onChange: (e, v: IOTV_Notifiable_EventType[] | string[] | null) => {
                    setEventTypes(v as IOTV_Notifiable_EventType[])
                },
                renderInput: (params) => <TextField {...{
                    ...params, label: "Event"
                }} />
            }} />
        </FormControl>
    }

    return <Grid  {...{ key: `${instanceClassName}_A`, onChange: handleFormChange, style: { 
            marginTop: '1rem', paddingTop: '0.5rem', borderTopStyle: 'solid', borderColor: 'gray', borderWidth: '1px',
            marginBottom: '1rem', paddingBottom: '0.5rem', borderBottomStyle: 'solid',
        } }} className={classes.root} container >

        <Grid item {...{ xs: 3, key: `${instanceClassName}_B ` }}>
            <Typography {...{ key: 'title ' }}>Notification</Typography>
            <Grid item {...{ key: `${instanceClassName}_C` }} >{componentMap.objectTypeId} </Grid><Grid item {...{ key: `${instanceClassName}_D` }} >{componentMap.entity}</Grid><Grid item {...{ key: `${instanceClassName}_E` }} >{componentMap.incidentType}</Grid>
            { incidentType === IncidentType.BUSINESS_RULE_VIOLATION  && componentMap.businessRuleChoice }
        </Grid>
        <Grid item {...{ key: `${instanceClassName}_K`, xs: 3 }} >
            {[
                <Typography {...{ key: `${instanceClassName}_L` }}>Subscription State</Typography>,
                <SubscriptionState {...{ key: `${instanceClassName}_L1`, contextObject: contextObject, contextUser, subscriptionState, setSubscriptionState, style}}></SubscriptionState>
            ]}
        </Grid>
        <Grid item {...{ key: `${instanceClassName}_F`, xs: 3 }} >
            {[
                <Typography {...{ key: 'G ' }}>Filters</Typography>,
                componentMap.severity, componentMap.eventType
            ]}
        </Grid>

        <Grid item {...{ key: `${instanceClassName}_H`, xs: 12, style: { marginTop: '0.5rem'}}} >

                <Typography {...{ key: `${instanceClassName}_I` }} { ...{ style: { float: 'left'}}} >Delivery</Typography>
                <IconButton
                    { ...{ size: 'small'}}
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>


            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {[

                    <DeliveryOptions {...{ key: `${instanceClassName}_J`, contextObject: contextCustomer, contextUser, deliveryOptions, setDeliveryOptions, style }} ></DeliveryOptions>
                ]}
            </Collapse>
        </Grid>

        <Grid item {...{ key: `${instanceClassName}_N`, xs: 12 }}>
            <FormGroup { ...{ row: true }}>
                <FormControl>
                    <Grid>
                    <Button {...{  startIcon: <SaveIcon />, variant: 'outlined', color: 'primary', onClick: handleSave, disabled: /*!edited */ false }}>Save</Button>
                    </Grid>
                 
                </FormControl>
                <FormControl>
                    <Grid>
                    <Button {...{  startIcon: <DeleteIcon />, variant: 'outlined', color: 'primary', onClick: handleDelete, disabled: false }}>Delete</Button>
                    </Grid>
                 
                </FormControl>
            </FormGroup>
        </Grid>
     </Grid>
}




