import { EventDependentSubscriptionState, isEventDependentSubscriptionState, isPostponedSubscriptionState, PostponedSubscriptionState, SubscribedStatus, SubscribedStatusUnion, ValidModelObject } from "@iotv/datamodel";
import { IOTV_Thing_EventType, ValidBusinessObject } from "@iotv/iotv-v3-types";
import { FormControl, FormGroup, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { toDatePickerFormat } from "../../data/TypeHelpers";
const debug = process.env.REACT_APP_DEBUG && false;

export type SubscriptionStateProps = {
    contextObject: ValidBusinessObject | undefined, contextUser: ValidModelObject<'User'>,
    subscriptionState: Partial<SubscribedStatusUnion>,
    setSubscriptionState: (subscriptionStatus: SubscribedStatusUnion) => void, style?: React.CSSProperties
}

export const SubscriptionState = ({ contextObject: contextCustomer, contextUser, subscriptionState, setSubscriptionState, style }: SubscriptionStateProps) => {

    debug && console.log( 'SubscriptionState got', subscriptionState   )
    debug && console.log('casted val', ( subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs)

    const subscriptionStateHash = ( subscriptionState?.status ?? '' )+ ( subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs + ( subscriptionState as EventDependentSubscriptionState )?.reapplyAfterEvent
   
    debug && console.log('Subscription hash', subscriptionStateHash)

    useEffect(() => {
        debug && console.log('SubscriptionState used effect on ', subscriptionStateHash)
    }, [subscriptionStateHash] )

    const subscriptionStateCastTimePostponable = ( subscriptionState as PostponedSubscriptionState );
    const subscriptionStateCastEventDependent = ( subscriptionState as EventDependentSubscriptionState )
    const isTimePostponable = isPostponedSubscriptionState(subscriptionState)
    const isEventDependent = isEventDependentSubscriptionState(subscriptionState)

    return <Grid container>
                <FormGroup>
                <FormControl {...{ key: 'A', }}>
                        <Autocomplete {...{
                            freeSolo: false, id: 'selectSubscriptionState', style, size: 'small', variant: "outlined", options: Object.values(SubscribedStatus) as string[], multiple: false, filterSelectedOptions: true,
                            getOptionLabel: (v: string) => v, value: subscriptionState.status,
                            onChange: (e, v: SubscribedStatus | string | null) => {
                               v && setSubscriptionState( { ...subscriptionState, status: v as SubscribedStatus, reapplyAfterEvent: undefined, reapplyAfterDateMs: undefined })
                            },
                            renderInput: (params) => <TextField {...{ 
                                ...params, label: "Status",
                            }} />
                        }} />
                    </FormControl>
                    <FormControl {...{ key: 'B', }}>
                    { subscriptionState.status === SubscribedStatus.POSTPONED && !isEventDependentSubscriptionState(subscriptionState) && <TextField { ...{ type: 'datetime-local', 
                        value: ( subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs ? toDatePickerFormat( ( subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs ) : new Date().toString(), 
                        defaultValue: toDatePickerFormat( new Date().valueOf()),
                        onChange: ( e ) => { 
                            const reapplyAfterDateMs = new Date( e.target.value ).valueOf();
                            debug && console.log('Date Picker', {
                                dtlTime: toDatePickerFormat( reapplyAfterDateMs ),
                                date: e.target.value, reapplyAfterDateMs, currentVal: ( subscriptionState as PostponedSubscriptionState )?.reapplyAfterDateMs  } ); 
                            setSubscriptionState( { ...subscriptionState, status: SubscribedStatus.POSTPONED, reapplyAfterDateMs } ) 
                        },
                        label: 'Posponed until',
                        InputLabelProps: {
                            shrink: true,
                          }    
                        }
                    }>
                    </TextField>} 
                    { subscriptionState.status === SubscribedStatus.POSTPONED && !isPostponedSubscriptionState(subscriptionState) &&  <Autocomplete {...{
                            freeSolo: false, id: 'selectSubscriptionState', style,  size: 'small', variant: "outlined", options: Object.values(IOTV_Thing_EventType) as string[], multiple: false, filterSelectedOptions: true,
                            getOptionLabel: (v: string) => v, value: ( subscriptionState as EventDependentSubscriptionState  ).reapplyAfterEvent,
                            onChange: (e, v: IOTV_Thing_EventType | string | null) => {
                               v && setSubscriptionState( { ...subscriptionState,  status: SubscribedStatus.POSTPONED, reapplyAfterEvent: v as IOTV_Thing_EventType})
                            },
                            renderInput: (params) => <TextField {...{
                                ...params, label: "Status",
                            }} />
                        }} />

                    }



                    </FormControl>

                
                </FormGroup>




    </Grid>

}