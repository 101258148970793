import React, { useEffect }  from 'react';
import { Paper,  withStyles, Grid } from '@material-ui/core';
import {UserCard as c021_1UserCard} from '../venture/c021/components/UserCard';

import {UserCard as i001_UserCard} from './i001/UserCard';

import ObjectCard from '../components/generic/ObjectCard'
import styles from '../cosmetics/sharedViewStyles';
import { UserViewProps, ObjectCardProps  } from '../types/AppTypes';
import config from '../config';

const debug = process.env.REACT_APP_DEBUG && false;

function UserView( props: UserViewProps  ): JSX.Element {

     

    const [ spacing ] = React.useState(2);


    const { classes, viewObject, user, userSelectedGroup, userGroupRoles, contextCustomer,  transactionFunctions, userFunctions, history, userGeolocation, useSpecializedViews} = props;

    const userCards: { [k:string] :  (props: ObjectCardProps) => JSX.Element } = {

      c021: c021_1UserCard,
      i001: i001_UserCard,
      i3012: i001_UserCard,

    }

    const UserCard: (props: ObjectCardProps) => JSX.Element = useSpecializedViews ? userCards[config.app.appCode] : ObjectCard;
    

    debug && console.log('UserView roles', userGroupRoles)
    debug && console.log('UserView userSelectedGroup', userSelectedGroup)
    return (
      <Grid container className={classes.root} spacing={2}>
      <Grid key = {'1'} item xs={12}>
        <Grid  container  spacing={spacing as any}>
            <Grid key={'set key'} item className={classes.item}>
              {
                (viewObject && <UserCard { ...{viewObject, user, userGroupRoles, userSelectedGroup, contextCustomer, transactionFunctions, userFunctions, history, userGeolocation }}></UserCard>) ||
                <Paper className={classes.paper}>
                
                </Paper>
              }
        </Grid>
      </Grid>
      </Grid>
    </Grid>
    );
    
}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(UserView)
