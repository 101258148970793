import { MapLike, ValidBusinessObject } from "@iotv/datamodel";

import Label from '@material-ui/icons/Label';
import GroupIcon from '@material-ui/icons/Apps';
import DeviceIcon from '@material-ui/icons/PhonelinkRing';
import CustomerIcon from '@material-ui/icons/Business';
import { StyledTreeItemConfig } from "../../types/ComponentProps";


const base: StyledTreeItemConfig = {
    nodeId: '-1',
    labelText: "Unknown",
    labelIcon: Label,
    labelInfo:  'No Info',
    color: '#e3742f',
    bgColor: '#fcefe3'
}

const others: MapLike<StyledTreeItemConfig> = {
    Group: { ...base, labelIcon: GroupIcon }
}

const byType: MapLike<StyledTreeItemConfig> = {
    default: base,
    Group: { ...base, labelIcon: GroupIcon },
    Device: { ...base, labelIcon: DeviceIcon },
    Customer: { ...base, labelIcon: CustomerIcon}
    //...others
    // Cow: CowIcon
    // Horse: HorseIcon 
}


export const treeCosmeticsByType = ( vob?: ValidBusinessObject  ) => {
    let props: StyledTreeItemConfig = base;
    if ( vob ) {
        if ( byType[vob.type]) {
            Object.assign(props, byType[vob.type]);
        }
        props.nodeId = vob.sk;
        props.labelInfo = vob.type;
        props.labelText = vob.name ? ( vob.name as string ).substr(0, 30) : '?';
        //props.labelInfo = vob.description ?? vob.type
    }
  

    return props
}