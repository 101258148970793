import { API } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import config from '../../../config'

//dev
import  { DebugAuth } from '../DebugAuth';

const debug = process.env.REACT_APP_DEBUG && false;

class ApiGateway {


    post = async function apiPost(path: string, body: any, apiRef: string = config.app.appName) {
        debug && console.log('API Post', { path, body, apiRef })
        if (config.aws.amplify.logLevel === 'DEBUG') DebugAuth();  
        const idToken = (await Auth.currentSession()).getIdToken();
        //const accessToken = (await Auth.currentSession()).getAccessToken();
        const userInfo = await Auth.currentUserInfo()
        debug && console.log('User Info', userInfo)

        debug && (apiRef === 'NWGateway') && console.log('Authorization', idToken.getJwtToken());
        let result = await API.post(apiRef, path, {
           // headers: { Authorization: `${idToken.getJwtToken()}`, 'Content-Type': 'application/json' },
           //headers: {  'Content-Type': 'application/json' },
           body: body instanceof Array ? body : { ...body, requestingUser: userInfo }
        }).catch( (e: any) => { console.log('API.post caught', e)});
        debug && console.log(`${path} res`, { body, result})
        if (result) {
            return result.body ?? result;
        }
      
    };
    
    get = async function apiGetMethod(path: string, queryStringParameters: any) {
        if (config.aws.amplify.logLevel === 'DEBUG') DebugAuth();  
        
        debug && console.log('API GET', { path, queryStringParameters })
        //const idToken = (await Auth.currentSession()).getIdToken();
        let result = await API.get(config.app.appName, path, {
            queryStringParameters: queryStringParameters,
            // headers: { Authorization: `${idToken.getJwtToken()}`, 'Content-Type': 'application/json'},
        }).catch( (e: any) => console.log(e));
        return result;
    };

}

export default new ApiGateway();