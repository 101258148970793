import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThingShadowState } from '@iotv/datamodel'
import styles from '../../../../cosmetics/sharedCardStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';

const debug = process.env.REACT_APP_DEBUG && false;

type ObjectShadowStateCardProps = {
  matchedPrimary: ThingShadowState;
}
const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "50%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  typography: {
    // flexGrow: 1,
    // align: "center"
    alignCenter: true
  }
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));



export default function SevenDayCollectionCard(props: ObjectShadowStateCardProps | any) {
  const classes = useStyles();
  const [nextRainTopMessage, setNextRainTopMessage] = React.useState('');
  const [nextRainBottomMessage, setNextRainBottomMessage] = React.useState('Data not Available');
  const [rainIcon, setRainIcon] = React.useState('http://openweathermap.org/img/wn/09d.png')
  // const [rainIcon, setRainIcon] = React.useState('/RainCloud.png')

  const forecastCollectionInSevenDays = props?.matchedPrimary?.forecastCollectionInSevenDays ? Math.round(props?.matchedPrimary?.forecastCollectionInSevenDays) : 0
  debug && console.log('SevenDayCollectionCard props latestPayload', props)
  const formatNumber = (num: number) => { return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
  return (
    // {/* <div> <Grid item xs={12}> */}
    <Paper elevation={3} className={classes.boxTab}>
      <Box
        display="flex"
        width='100%' height='50%'
        alignItems="center"
        justifyContent="center"
      >

        <Typography variant="h6" component="h2" align="center" className={classes.typography} >
          {formatNumber(Math.round(forecastCollectionInSevenDays)) || '- '} Lts
                          </Typography>
      </Box>
      <Box
        display="flex"
        width='100%' height='50%'
        alignItems="center"
        justifyContent="center"
        textAlign='center'
      >
        7-day Forecast Collection</Box>
    </Paper>
    // {/* </Grid>  </div> */}
  );
}