import { } from "@material-ui/icons";
import { Radio } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IdType, ValidBusinessObjectList } from "@iotv/iotv-v3-types";

const debug = process.env.REACT_APP_DEBUG && false;

type RenderSelectorCellParams = {
    gridCellParams: GridRenderCellParams<any, any, any>
    valueSetter: ( v: boolean, id: string ) => void

}

type RenderSelectorCellType = ( params: RenderSelectorCellParams) => React.ReactNode

export const radioSelector: RenderSelectorCellType = ({ gridCellParams, valueSetter}: RenderSelectorCellParams) => {
    return  <Radio {...{
    checked: gridCellParams.value,
    onClick: () => { valueSetter( !gridCellParams.value, gridCellParams.id as string)}

}} /> 
}



type SelectAllProps<T extends IdType> = {
    items: T[],
    selectedObjects: { id: string }[]
    setSelectedObjects: React.Dispatch<React.SetStateAction<T[]>>
}

export function SelectAll <T extends IdType>({ items, selectedObjects, setSelectedObjects}: SelectAllProps<T> ){
    const handleToggle = () => {
        items.length === selectedObjects.length ? setSelectedObjects([]) : setSelectedObjects(items)
    }

    return <Radio { ...{
        checked: items.length === selectedObjects.length, // approximately. Dont want to iterate to compare
        onClick: handleToggle
    }}/>
}
