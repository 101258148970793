import { flattenObject, ThingShadowState } from '@iotv/datamodel';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { getValueLabel, thingShadowStateToStateSnapshot } from '../../data/TypeHelpers';

const debug = process.env.REACT_APP_DEBUG && false;

const localStyle = {
  table: {}
};
const useStyles = makeStyles((theme) => ({ ...localStyle, ...styles(theme)}));


type ObjectShadowStateCardProps = {
    matchedPrimary: ThingShadowState;
    filterMetricKeys? : string[];
    title?: string
}

export default function ObjectShadowStateCard( props: ObjectShadowStateCardProps | any ) {
  const classes = useStyles();
  const { matchedPrimary, parentObjects, filterMetricKeys, title } = props

  debug && console.log('ObjectShadowStateCard', props)

  const tss = matchedPrimary as ThingShadowState;

  const getMetricRows = (object: ThingShadowState ) => {

    const metrics = flattenObject(object);
    return Object.entries(metrics).filter( ([k, v]) => filterMetricKeys === undefined || filterMetricKeys.includes(k) ).map( ( [k, v]) => <TableRow key={k}>
    <TableCell component="th" scope="row">
      { getValueLabel(k)}
    </TableCell>
    <TableCell align="right">  {v && v.toString()}</TableCell>
  </TableRow>) 
  }

  return (
    <Card className={classes.root}>
        <CardHeader
        title =  {title ?? 'Metrics'}
      ></CardHeader>
       <CardContent>
       <TableContainer component={Paper}>
      <Table  size='small'  className={classes.table} aria-label="simple table">
        <TableBody>
            
            { tss && getMetricRows(tss)}
            
            { parentObjects && <TableRow key={4}>
              <TableCell component="th" scope="row">
              { parentObjects[0] && parentObjects[0].name}
              </TableCell>
                <TableCell align="right">  {} </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
      </CardContent>
   
    </Card>
  );
}