import { Collapse, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Draggable from 'react-draggable';
import { mapStyles } from '../../cosmetics/MapCardStyles';
import styles from '../../cosmetics/sharedViewStyles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpandMore } from '@material-ui/icons';



const debug = process.env.REACT_APP_DEBUG && false;


export type CollapsibleDraggableProps = {
    children: React.ReactElement
}


function CollapsibleDraggable({ children }: CollapsibleDraggableProps): JSX.Element {

    const [minimized, setMinimized] = React.useState<boolean>(true);
    const [top, setTop] = React.useState<number>(110);
    const [left, setLeft] = React.useState<number>(10);

    const handleToggle = () => { debug && console.log('clci'); setMinimized(!minimized) }


    return <Draggable {...{ positionOffset: { x: left, y: top }, defaultPosition: { x: 0, y: 0 } }}>
        <div {...{ style: { position: 'absolute', top, left, } }}>


            {minimized ? <Collapse {...{
                collapsedHeight: '2.5rem', children
            }} /> : children}

            <div {...{ style: { position: 'absolute', top: 10, left: '90%' }, onClick: handleToggle}}>
                {minimized ? <ExpandMoreIcon/> : <ExpandLessIcon />}
            </div>
        </div>
    </Draggable>


}



export { CollapsibleDraggable };

