import { ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { InternalManagement } from '../DMPExtrasV2';
import InternalMessagesModal, { renderIdList } from './DroughtPlanModals/InternalMessagesModal';
import { NoneMade } from './ManagementGenerics';
import { getCustomerRecipiants } from './RecipientsSupport';
import { SendMessage } from './sendmessage'

interface InternalManagementPageProps {
  contextCustomer: ValidBusinessObject;
  index: number;
  source: ValidBusinessObject;
  currentactive: boolean;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const InternalManagementPage: React.FC<InternalManagementPageProps> = (props: InternalManagementPageProps) => {
  let { contextCustomer, index, source, currentactive, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [internalManagementMessages, setInternalManagementMessages] = useState<InternalManagement[]>([]);
  let [recipient, setRecipients] = useState<ValidBusinessObjectList>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setInternalManagementMessages(source.stage[index].internalManagementMessages);
  }, [index, source]);

  useEffect(() => {
    if (contextCustomer) {
      updateRecipient();
    }
  }, [contextCustomer]);

  const addNewInternal = useCallback(() => {
    setCreate(true);
  }, []);

  const cancel = useCallback(() => {
    setCreate(false);
    setPosition(undefined);
  }, []);

  const updateRecipient = async () => {
    setRecipients(await getCustomerRecipiants(contextCustomer));
  };

  const editTrigger = (pos: number) => {
    setPosition(pos);
    addNewInternal();
  };

  function sendhistoryTemplate(content: string[], name: string[], type: string) {
    return {
      content: content,
      name: name,
      type: type,
      time: (new Date()).toString()
    };
  }

  const renderIdListwithemail = (idList: string[], choice: ValidBusinessObjectList) => {
    let objList = idList.map((id) => {
      console.log("ifincludeemail", choice.find((option) => option.id === id))
      var findone = choice.find((option) => option.id === id)
      return [findone?.name, findone?.email, findone?.phoneNumber];
    });
    return objList;
  };

  const update = (newMessage: InternalManagement | undefined, pos: number | undefined, sendout: boolean | undefined) => {

    if (newMessage === undefined) {
      if (pos !== undefined) {
        internalManagementMessages.splice(pos, 1);
      }
    } else if (setSource !== undefined) {
      if (pos === undefined) {
        internalManagementMessages!.push(newMessage);
      } else {
        internalManagementMessages[pos] = newMessage;
      }

      if (sendout) {
        var sendcontent = [newMessage.message]
        var sendemail: string[] = []
        var sendsms: string[] = []
        var sendname: string[] = []
        var emailphone = renderIdListwithemail(newMessage.recipientIDs, recipient)

        for (var y = 0; y < emailphone.length; y++) {
          sendname.push(emailphone[y][0])
          sendemail.push(emailphone[y][1])
          sendsms.push("+" + emailphone[y][2])
        }
        var titleout = "Island Water Management - Community Engagement"
        SendMessage(titleout, newMessage.message, sendemail, sendsms)

        var sendhistory = sendhistoryTemplate(sendcontent, sendname, "Community engagement message")
        let sendstage = source?.sendmessagehistory || [];
        console.log("different", sendstage)
        sendstage.push([sendhistory]);

        let newStages = source.stage;
        newStages[index].internalManagementMessages = internalManagementMessages;
        setSource({ ...source, stage: newStages, sendmessagehistory: sendstage });
        updateRecipient();
        cancel();
      } else {
        let newStages = source.stage;
        newStages[index].internalManagementMessages = internalManagementMessages;
        setSource({ ...source, stage: newStages });
        updateRecipient();
        cancel();
      }
    }
  };

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          {setSource && (
            <Button variant='contained' onClick={addNewInternal}>
              <Add />
              <b>Community Engagement Message</b>
            </Button>
          )}
          {internalManagementMessages && internalManagementMessages.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>
                      <b>Message</b>
                    </TableCell>
                    <TableCell width={'200px'} align='left'>
                      <b>Recipients</b>
                    </TableCell>
                    <TableCell width={'100px'} align='center' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {internalManagementMessages.map((message, i) => {
                    return (
                      <TableRow>
                        <TableCell align='left'>{message.message}</TableCell>
                        <TableCell align='left'>{recipient && renderIdList(message.recipientIDs, recipient)}</TableCell>
                        <TableCell align='center'>
                          <Button variant='contained' onClick={() => editTrigger(i)}>
                            <Edit />
                            <b>Edit</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Internal management message'} add={addNewInternal} />
          )}
        </Stack>
      </div>
      {create && (
        <InternalMessagesModal close={cancel} contextCustomer={contextCustomer} update={update} index={position} currentactive={currentactive} existing={internalManagementMessages} setSource={setSource} />
      )}
    </>
  );
};

export default InternalManagementPage;
