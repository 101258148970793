import { Grid } from "@material-ui/core";
import React from "react";
import { TabSelectionProps } from "../../types/AppTypes";
import { TabPanel } from "../utils/TabPanelShim";




export type SlaveTabProps = TabSelectionProps & { orderedComponents: JSX.Element[]}

export const SlaveTab = ({ orderedComponents, value, index }: SlaveTabProps) => {



return <TabPanel {...{ value, index }}>
    <Grid container>
        { orderedComponents.map( ( component ) => component )}
   

    </Grid>
</TabPanel>
}