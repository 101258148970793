import { ThingShadowState } from '@iotv/datamodel';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import NavLink from '../../../../components/navigation/NavLink';
import { NavSections } from '../../../../components/navigation/NavSections';
import styles from '../../../../cosmetics/sharedCardStyles';
import type { TankCardProps, ThingViewOptionals, ValidBusinessObject, ViewObject } from '../../../../types/AppTypes';
import SevenDayCollectionCard from './SevenDayCollectionCard';
import TankGroupPercentageDisplayCard from './TankGroupPercentageDisplayCard';
import TankGroupWaterAvailabilityCard from './TankGroupWaterAvailabilityCard';


const debug = process.env.REACT_APP_DEBUG && false;
const avatarSize = '90%';

const useStyles = makeStyles((theme) => {
  return {
    ...styles(theme),
    avatarLarge: { height: avatarSize, width: avatarSize },
    objectNameLarge: { fontSize: 'xx-large' },
    variableHeightCard: {
      height: '100%',
    },
    mapCardContent: {
      height: '100%',
      width: '100%',
    },
    root: {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'red',
      }
    },
    buttonGroup: {
      width: "100%",
      textAlign: 'left'
    },
    dividerColor: {

    }
  }

});


export default function TankGroupListItem(props: TankCardProps | any) {
  debug &&  console.log('Tank group card got props', props)
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);


  const { viewObject, optionals }: { viewObject: ViewObject, optionals: ThingViewOptionals } = props;
  const matchedPrimary = viewObject.matchedPrimary
  debug && console.log('tanklist viewObject:', viewObject)

  const imageUrl = () => {
    const name: string = ['Aurora', 'Baby', 'Beyonce', 'Blade', 'Elgin', 'Indy', 'Nashi'][Math.floor(Math.random() * 7)];
    return Math.random() > 0.1 ? `https://v015-dev-userblobs.s3-ap-southeast-2.amazonaws.com/${name}.jpeg` : undefined;
  } 
  const thingShadowStates: ThingShadowState[] = viewObject?.matchedRelatedBySk ? viewObject?.matchedRelatedBySk.filter((item: ValidBusinessObject) => item.type === 'ThingShadowState').map((item: ValidBusinessObject) => new ThingShadowState(item)) : [];
  thingShadowStates.sort((tss1, tss2) => {
    const sortRes = tss1?.payload?.timestamp?.toString() && tss2?.payload?.timestamp?.toString() ? tss1?.payload?.timestamp?.toString() < tss2.payload?.timestamp?.toString() ? 1 : -1 : 0
    return sortRes;
  });
  debug && console.log('thingShadowStates[0]', thingShadowStates[0])
  // const alertCount = viewObject.matchedRelatedBySk ? filterActiveAlerts(viewObject.matchedRelatedBySk.filter((item) => item.type === 'Alert') as (ValidBusinessObject & Alert)[]).length : 0
  return (
    viewObject.matchedPrimary ?
      <NavLink filter={`${NavSections.THINGVIEW}/${viewObject.matchedPrimary?.type}/${viewObject.matchedPrimary?.id}`} children={
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TankGroupPercentageDisplayCard className={classes.subCard} matchedPrimary={matchedPrimary} parentObjects={[viewObject.matchedRelatedByPk]} ></TankGroupPercentageDisplayCard>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="column" spacing={1}  >
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center"
                      textAlign="center"
                      bgcolor="background.paper">
                      <Typography variant="h6" component="h2">
                        {viewObject.matchedPrimary?.name || 'No name known'}
                      </Typography>
                    </Box>
                    <Divider variant="middle" classes={{ root: classes.dividerColor }} />
                  </Grid>
                  <Grid container spacing={1} >
                    <Grid item xs={6}>
                      <TankGroupWaterAvailabilityCard matchedPrimary={matchedPrimary} parentObjects={[viewObject.matchedRelatedByPk]} />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <Card>   <TankRainCard className={classes.subCard} latestPayload={thingShadowStates[0]} parentObjects={[viewObject.matchedRelatedByPk]} ></TankRainCard> </Card> */}
                      <SevenDayCollectionCard {...{ matchedPrimary: viewObject.matchedPrimary, viewObject }} ></SevenDayCollectionCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
    
        </Card>
      }></NavLink>
      : null
  );
}
