import { ValidBusinessObject, ValidBusinessObjectList } from "@iotv/datamodel";
import { Breadcrumbs, Link, makeStyles, Typography } from "@material-ui/core";
import * as Icons from '@material-ui/icons';
import queryString from 'query-string';
import React from "react";
import { useHistory } from "react-router-dom";
const debug = process.env.REACT_APP_DEBUG && false;


export type NavBreadcrumbsProps = { items: ValidBusinessObjectList, itemToUrlFn: (item: ValidBusinessObject) => string, current: ValidBusinessObject | undefined, homeUrl?: string  }

export const NavBreadcrumbs =  ( {items, itemToUrlFn, current, homeUrl}: NavBreadcrumbsProps ) => { 

    const routerHistory = useHistory();
    const useStyles = makeStyles((theme) => ({
        link: {
            display: 'flex',
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
    }));

    const classes = useStyles();

    debug && console.log('breadcrumbsFromReversOrderedObjects got ', items);
    const mutableItems = [...items];
    const icons = [ <Icons.BusinessCenter className={classes.icon} />, <Icons.BusinessSharp className={classes.icon} />,  <Icons.Business className={classes.icon} />,]
    return <Breadcrumbs aria-label="breadcrumb">
        { homeUrl && <Link { ...{ key: `link_home`, color: 'inherit', className: classes.link, onClick: () => routerHistory.push(homeUrl) } }><Icons.Home className={classes.icon} /> back </Link>}
        {mutableItems.length > 0 && mutableItems.reverse().filter( (item) => item.sk !== current?.sk ).map((item, i) => {

            const viewUrl = queryString.stringifyUrl({
                url: itemToUrlFn(item),
                query: {}
            })

            return <Link  {...{ key: `link_${i}`, color: 'inherit', className: classes.link, onClick: () => routerHistory.push(viewUrl) }}>
                {icons[i]}
                {item.name}
            </Link>
        })}
        {current && <Typography color="textPrimary">{current.name}</Typography>}
    </Breadcrumbs>




}