import { WeatherData } from "@iotv/datamodel-core";

const debug = process.env.REACT_APP_DEBUG && false;


export const getDaysForRain = (weatherData: WeatherData) => {
  const getDaysForRainDebug = process.env.REACT_APP_DEBUG && false;
  let currentUnixTime = Math.floor((+ new Date()) / 1000)
  let nextRainResult: { topMessage: string, bottomMessage: string, rainIcon: string } | null = null
  let nextRainDay = (weatherData.daily ?? []).find((day) => day.timestamp > currentUnixTime && (day.pop ?? 0) > 0)
  debug && getDaysForRainDebug && console.log('nextRainDay:', nextRainDay)
  if (nextRainDay) {
    let daysToRain = Math.floor((nextRainDay.timestamp - currentUnixTime) / 86400)  //86400000
   debug && getDaysForRainDebug && console.log('currentUnixTime:', currentUnixTime)
   debug && getDaysForRainDebug && console.log('daysToRain:', daysToRain)
    let nextRainTopMessage = daysToRain <= 0 ? 'Today' : `${daysToRain} day(s)`
    let nextRainBottomMessage = daysToRain <= 0 ? 'Forecast rain' : `Until next rain`
    let nextRainIcon: string = nextRainDay?.weather?.icon ?? 'http://openweathermap.org/img/wn/01d.png'
    nextRainResult = { topMessage: nextRainTopMessage, bottomMessage: nextRainBottomMessage, rainIcon: nextRainIcon }
    return nextRainResult
  }
  return nextRainResult
};


export const getNumberofDaysForRain = (weatherData: WeatherData) => {
  const getDaysForRainDebug = process.env.REACT_APP_DEBUG && false;
  let currentUnixTime = Math.floor((+ new Date()) / 1000)
  let nextRainResult: { topMessage: string, bottomMessage: string, rainIcon: string } | null = null
  let nextRainDay = weatherData.daily?.find((day) => day.timestamp > currentUnixTime && (day.pop ?? 0) > 0)
  debug && getDaysForRainDebug && console.log('nextRainDay:', nextRainDay)
  if (nextRainDay) {
    let daysToRain = Math.floor((nextRainDay.timestamp - currentUnixTime) / 86400)  //86400000
   debug && getDaysForRainDebug && console.log('currentUnixTime:', currentUnixTime)
   debug && getDaysForRainDebug && console.log('daysToRain:', daysToRain)
    let nextRainTopMessage = daysToRain <= 0 ? 'Today' : `${daysToRain} day(s)`
    let nextRainBottomMessage = daysToRain <= 0 ? 'Forecast rain' : `Until next rain`
    let nextRainIcon: string = nextRainDay?.weather?.icon ?? 'http://openweathermap.org/img/wn/01d.png'
    nextRainResult = { topMessage: nextRainTopMessage, bottomMessage: nextRainBottomMessage, rainIcon: nextRainIcon }
    return nextRainResult
  }
  return nextRainResult
};
