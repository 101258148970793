import { PossibleBusinessObject, UserAttributeDefs } from '@iotv/datamodel';
import { AppValueTypes, MapLike } from '@iotv/iotv-v3-types';
import Assembler from '../../data/Assembler';
import { ducktype, getValueLabel } from '../../data/TypeHelpers';
import type { ViewDefinition, ViewKeyDefinitionType } from '../../types/AppTypes';


const attributableAttKeys = ['String' , 'Number' , 'Date' , 'Boolean', 'Enum' ];


const getAttributeType = ( attDefType: string ) => {
  switch (attDefType) {
    case 'String': return AppValueTypes.STRING;
    case 'Number': return AppValueTypes.NUMBER;
    case 'Boolean': return AppValueTypes.BOOLEAN;
    case 'Date': return AppValueTypes.DATE;
    case 'Enum': return AppValueTypes.ENUM;
    default: return undefined;
  }
}

export const getViewDefintionFromObject = (possibleBusinessObject: PossibleBusinessObject) => {
  let definitions: ViewDefinition = {};
 
  const object = Assembler.getInstance(possibleBusinessObject);
  if (object) {
    const attDefs: UserAttributeDefs = object.getUserAttributes();
    Object.assign(definitions, getViewDefinitionFromUserAttributes(attDefs))
  }

  return definitions;
}

export const getViewDefintionFromMutantZombie = ( mutantZombie: MapLike<any>, keys?: string[] ): ViewDefinition => {

  const getViewKeyDefinition = ( [ key, value ]: [ string, any ]) => {
    const viewKeyDefinition: ViewKeyDefinitionType = {
      editable: true, key, type: ducktype(key, value ), label: key  }
        return viewKeyDefinition
  }

  const viewDefinition: ViewDefinition = {}
  Object.entries( mutantZombie ).forEach( ( [k, v]) => { if ( !keys || keys.includes(k)) { viewDefinition[k] = getViewKeyDefinition([k,v]) } } )
  return viewDefinition
  
}


export const getViewDefinitionFromUserAttributes = ( attDefs: UserAttributeDefs) => {
    let definitions: ViewDefinition = {};
    Object.entries(attDefs).filter( ([attKey, attDef] ) => attDef && attributableAttKeys.includes(attDef.type) ).forEach( ([attKey, attDef]) => {
        if (attDef) {
          const attributeType = getAttributeType(attDef.type);
          if (attributeType) {
            const viewKeyDefinition: ViewKeyDefinitionType = {
              key: attKey, 
              label: typeof attDef.label === 'string' ? attDef.label : getValueLabel(attKey),
              type: attributeType,
              editable: attDef.editable ?? true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined,
              valueGetter: attDef.displayFn as ( actualValue: any ) => any, valueSetter: attDef.valueFn as ( newValue: any ) => any
            }
            if (attributeType === AppValueTypes.ENUM && attDef.enumKv) {
              viewKeyDefinition.enumKV = attDef.enumKv
            }
            definitions[attKey] = viewKeyDefinition;
          }
        }
      })
    return definitions;
}

export const commonListViewDefinition: ViewDefinition = {
  name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  id: { key: 'id', label: 'SystemId', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
 }

 export const genericListViewDefinition: ViewDefinition = {
  ...commonListViewDefinition,
  type: { key: 'type', label: 'Type', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  sk: { key: 'sk', label: 'sk', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  pk: { key: 'pk', label: 'pk', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
 }


 export const pluckAndOrderFromViewDef = ( orderedKeys: string[], viewDefinition: ViewDefinition ): ViewDefinition => Object.fromEntries( orderedKeys.map( ( k) => [ k, viewDefinition[k]]).filter(( [ k, v] ) => v ))
 