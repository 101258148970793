import { GridListTile, GridListTileBar, IconButton, ImageList } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { listObjectsAndUrls } from '../../data/aws/s3/UserBlobs';
import { FileUploadCardProps, S3ImageMeta, S3ImageRecordAndURL, S3ImageRecordAndURLMap, ViewDefinition } from '../../types/AppTypes';
import FileSelectCard from './FileSelectCard';


const useStyles = makeStyles((theme) => (styles(theme)));

const debug = process.env.REACT_APP_DEBUG && false;


export default function PhotosCard( props: FileUploadCardProps ) {

  const classes = useStyles();

  const [objectCount, setObjectCount] = React.useState(0);
   const [objectsAndUrls, setObjectsAndUrls] = React.useState<S3ImageRecordAndURLMap>({})


 

  useEffect( () => {
    async function effectList() {

      setObjectsAndUrls( await listObjectsAndUrls(props.matchedPrimary.sk) );
      setObjectCount( Object.values(objectsAndUrls).length)
    }

    effectList()

  }, [props.matchedPrimary.sk, objectCount]);

  debug && console.log('Details Card has props', props)

 

  const { matchedPrimary, actions, transactionFunctions, userFunctions, searchParams, history, title } = props

  debug && console.log('User Details Card has saveObject', transactionFunctions?.saveObject)

  const nameLabel = title || `${matchedPrimary.name} facts` || `New ${matchedPrimary.type}`

  const saveImageAsDefault = ( objectAndUrl: S3ImageRecordAndURL ) => {
    matchedPrimary.primaryS3Image = objectAndUrl;
    transactionFunctions.saveObject(matchedPrimary);
  }

  const viewDefinition: ViewDefinition = {

  }

  const parentHooks = {
    addKey : ( {key}: S3ImageMeta ) => {
      setObjectCount(objectCount + 1)
    }
  }
  // <FavoriteIcon  onClick = {() => saveImageAsDefault(s3Key)} />


  return (
    <Card className={classes.root}>
      <CardHeader
        title = {`${nameLabel}`}
        
      ></CardHeader>
       <CardContent>
        <ImageList rowHeight={160} className={classes.gridList} cols={3}>
        { objectsAndUrls && Object.values( objectsAndUrls ).filter( (objectAndUrl) => (typeof objectAndUrl.url === 'string'  && typeof objectAndUrl.key === 'string'))
          .map( (objectAndUrl: S3ImageRecordAndURL) => <GridListTile  key={objectAndUrl.key as string} cols={ 1 }> 
                <img  src={objectAndUrl.url as string} alt={matchedPrimary.name} />
                <GridListTileBar
                   actionIcon={
                <IconButton 
                  onClick = {() => saveImageAsDefault(objectAndUrl)}
                  className = { objectAndUrl.key === matchedPrimary.primaryS3Image?.key ? classes.photoGridFavIconDefault : classes.photoGridFavIcon}>
                  <FavoriteIcon />
                </IconButton>
              }
            />
                 </GridListTile>,

        )}
      </ImageList>
     
         <Grid item xs={'auto'}>
           <Grid item xs={'auto'}>
           
           </Grid>
           <Grid item xs={'auto'}>
             <FileSelectCard { ...{matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions, viewDefinition, title: 'Upload Photo', parentHooks}} ></FileSelectCard>
           </Grid>
         </Grid>
       
      </CardContent>
    </Card>
  );
}