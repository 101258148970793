import { makeStyles, Typography, TextField, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button, Card, Paper, TableContainer, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import styles from './Creation.module.css';
import React, { useState, useEffect } from 'react';
import { validateEmail, validatePassword } from '../../../../data/validate';
import { CustomerAccountType, PossibleBusinessObject, ValidBusinessObject, ValidBusinessObjectList, ValidModelObject } from '@iotv/datamodel-core';
import { displayMessage } from '../../../Message/alert';
import { AddUserRequest, UserFunctions } from '../../../../../../types/AppTypes';
import { addObjectToDB } from '../../../../../../venture/c021/Database/addToDb';
import { Severity } from '../../../../../../types/AppTypes';
import { Edit, Delete } from '@mui/icons-material';
import AddUser from './AddUser';
import ApiGateway from '../../../../../../data/aws/api-gateway/ApiGateway';

const editTankStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '27ch',
      width: '79vw',
    },
  },
  textField: {
    width: 'auto',
    height: 'auto',
    color: 'primary',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  delete: {
    margin: theme.spacing(1),
  },

  cancel: {
    margin: theme.spacing(1),
  },
  save: {
    margin: theme.spacing(1),
  },
}));
interface HouseholdCreationProps {
  contextUser: ValidBusinessObject | undefined;
  contextCustomer: ValidBusinessObject | undefined;
  userFunctions: UserFunctions;
}

const apiRef = 'SystemManagement';

export const HouseholdCreation = (props: HouseholdCreationProps) => {
  const { contextUser, contextCustomer, userFunctions } = props;

  const routerHistory = useHistory();

  const editTank = editTankStyles();

  let [creating, setCreating] = useState<boolean>(false);
  let [valid, setValid] = useState<boolean>(false);

  let [housename, setHousename] = useState<string>('');
  let [streetName, setStreetName] = useState<string>('');
  let [suburb, setSuburb] = useState<string>('');
  let [city, setCity] = useState<string>('');
  let [country, setCountry] = useState<string>('');
  let [lat, setLat] = useState<number>();
  let [lng, setLng] = useState<number>();

  let [addUser, setAddUser] = useState<boolean>(false);
  let [editUser, setEditUser] = useState<ValidBusinessObject>();
  let [userList, setUserList] = useState<ValidBusinessObject[]>();

  /**
   * Check if the form is valid
   */
  useEffect(() => {
    let positionValid = lat !== undefined && lng !== undefined;
    let nameValid = housename !== '' && streetName !== '' && suburb !== '' && city !== '' && country !== '';

    setValid(positionValid || nameValid);
  }, [housename, streetName, suburb, city, country, lat, lng]);

  /**
   * Saving the current modal
   */
  const saveToDB = () => {
    const householdZombie: PossibleBusinessObject = {
      address: streetName || 'N/A',
      suburb: suburb || 'N/A',
      city: city || 'N/A',
      country: country || 'N/A',
      location: { lat: lat || 0, lng: lng || 0 },
      accountType: CustomerAccountType.Z,
      name: housename,
      type: 'Customer',
    };

    if (contextCustomer) {
      setCreating(true);
      ApiGateway.post('/system/Customer/add', { contextUser, newCustomer: householdZombie, parentCustomer: contextCustomer }, apiRef).then(async (houseObj) => {
        if (houseObj.data !== undefined) {
          if (userList && contextUser) {
            let contextCustomer = { id: houseObj.data.id, pk: `Customer:${houseObj.data.id}`, sk: `Customer:${houseObj.data.id}`, type: 'Customer' };
            // add a user for each
            for (let i = 0; i < userList.length; i++) {
              let user = userList[i];
              let newUser: Partial<ValidBusinessObject> = {
                email: user.email,
                id: user.id,
                name: user.name,
                phoneNumber: `+${user.phoneNumber?.toString()}`,
                type: 'User',
                username: user.username,
                pk: `User:${user.id}`,
                sk: `User:${user.id}`,
              };

              const addUserRequest: AddUserRequest = { user: newUser, temporaryPassword: user.password, contextUser, contextCustomer: contextCustomer as ValidModelObject<'Customer'>, addToGroups: ['User'], makeAdmin: true };
              let res = await ApiGateway.post('/system/User/add', addUserRequest, apiRef);
              if (res.err) {
                // delete new customer
                ApiGateway.post('/system/Customer/delete', { contextUser, customer: houseObj.data }, apiRef);
                displayMessage(`Failed to create user: ${user.name}`, 'SnackBar', userFunctions, Severity.error);
                setCreating(false);
                return;
              } else {
                displayMessage(`Successful created ${user.name} for ${housename}`, 'SnackBar', userFunctions);
                routerHistory.goBack();
              }
            }
          } else {
            displayMessage(`Success: household ${housename} created`, 'SnackBar', userFunctions);
            routerHistory.goBack();
          }
        } else {
          displayMessage('Failed:', 'SnackBar', userFunctions, Severity.error);
          setCreating(false);
        }
      });
    }
  };

  const updateUser = (modifiedUser: ValidBusinessObject) => {
    let temp: ValidBusinessObject[] = [];
    userList?.forEach((user) => {
      if (user.id !== modifiedUser.id) {
        temp.push(user);
      }
    });
    temp.push(modifiedUser);

    setUserList(temp);
  };

  return (
    <>
      <h3 style={{ textIndent: 25 }}>Create/Edit Household</h3>
      <Card>
        <Grid container spacing={2} columns={17}>
          <Grid item xs={17}>
            <h3 style={{ textIndent: 25 }}>Details</h3>
            <form className={editTank.root} noValidate autoComplete='off'>
              <Grid item xs={17}>
                <TextField
                  label='Household Name'
                  // fullWidth
                  onChange={(e) => setHousename(e.target.value)}
                  // className={editTank.textField}
                  style={{ width: '99%' }}
                  variant='outlined'
                  size='small'
                  required
                  disabled={creating}
                  error={housename === ''}
                />
              </Grid>
              <Grid item container xs={17}>
                <Grid item container xs={8}>
                  <TextField
                    label='Street Name and Number'
                    onChange={(e) => setStreetName(e.target.value)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    disabled={creating}
                  />
                  <TextField
                    label='Suburb'
                    onChange={(e) => setSuburb(e.target.value)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    disabled={creating}
                  />
                  <TextField
                    label='City'
                    onChange={(e) => setCity(e.target.value)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    disabled={creating}
                  />
                  <TextField
                    label='Country'
                    onChange={(e) => setCountry(e.target.value)}
                    className={editTank.textField}
                    variant='outlined'
                    size='small'
                    disabled={creating}
                  />
                </Grid>
                <Grid item container xs={1} justifyContent='center'>
                  <h4 style={{ alignSelf: 'center', justifySelf: 'center' }}>OR</h4>
                </Grid>
                <Grid item container xs={8}>
                  <br />
                  <TextField
                    label='Latitude'
                    type='number'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => setLat(Number(e.target.value))}
                    className={editTank.textField}
                    value={lat}
                    variant='outlined'
                    disabled={creating}
                    size='small'
                  />
                  <TextField
                    label='Longitude'
                    type='number'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => setLng(Number(e.target.value))}
                    className={editTank.textField}
                    value={lng}
                    variant='outlined'
                    disabled={creating}
                    size='small'
                  />
                </Grid>
              </Grid>
              <Grid item container xs={17}>
                <h3 style={{ textIndent: 25 }}>Household Users</h3>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align='right'>Username</TableCell>
                        <TableCell align='right'>Email</TableCell>
                        <TableCell align='right'>Phone</TableCell>
                        <TableCell align='right'>Password</TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList ? (
                        userList.map((user) => (
                          <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component='th' scope='row'>
                              {user.name}
                            </TableCell>
                            <TableCell align='right'>{user.username}</TableCell>
                            <TableCell align='right'>{user.email}</TableCell>
                            <TableCell align='right'>+{user.phoneNumber}</TableCell>
                            <TableCell align='right'>{user.password}</TableCell>
                            <TableCell align='right'>
                              <Edit
                                onClick={() => {
                                  setEditUser(user);
                                  setAddUser(true);
                                }}
                              />
                              <Delete
                                onClick={() => {
                                  setUserList(userList?.filter((us) => us.id !== user.id));
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={'none'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align={'right'} component='th' scope='row'>
                            No Users Made
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item container xs={17}>
                <div style={{ textIndent: 10 }}>
                  <Button
                    variant='outlined'
                    color='success'
                    disabled={creating || userList?.length === 1}
                    onClick={() => {
                      setEditUser(undefined);
                      setAddUser(true);
                    }}
                  >
                    Add User
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textIndent: 10 }}>
              <Button variant='contained' color='error' disabled={creating} onClick={() => routerHistory.goBack()}>
                Cancel
              </Button>
              <Button variant='outlined' color='success' disabled={!valid || creating} onClick={() => saveToDB()}>
                Create
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
      {addUser && <AddUser showScreen={setAddUser} updateUser={updateUser} modifyingUser={editUser} />}
    </>
  );
};

export default HouseholdCreation;

// routerHistory.goBack()
