import { PathDirection, ValidBusinessObject } from '@iotv/datamodel';
import { isPosition } from '@iotv/datamodel-core';
import {
  Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createAndLink, deleteObject, getAdjacent2Wrapper, getExact } from "../../../data/daoFunctions/daoFunctions";
import { AdjacentType, AreaManagementMode, DispatchFunctionsType, Location } from '../../../types/AppTypes';
import { convertLocationToPolygon, updateStateList } from '../../Survey/SurveyView';
import PointInArea from './components/PointInArea';
import StyleInForm from './components/StyleInForm';
import { removeFromStateList, selectorUnlink } from './helperFunctions/helperFunctions';

const debug = process.env.REACT_APP_DEBUG && false;

export interface Area {
  name: string | undefined;
  id: string;
  description: string | undefined;
  color: string | undefined;
  opacity: string | undefined;
  lineWidth: string | undefined;
  locations: Location[],
  lastPoint: Location | undefined;
}

export type AddAreaProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  transactionFunctions: DispatchFunctionsType | undefined;
  contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined,
  areas: ValidBusinessObject[] | undefined;
  setAreas: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>;
  areaStyler: ValidBusinessObject[] | undefined;
  mode: AreaManagementMode,
  area?: ValidBusinessObject,
  addAreas: any[],
  polygons: any[],
  setPolygons: React.Dispatch<React.SetStateAction<{
    [k: string]: google.maps.Polygon;
  }[]>>
  setAddAreas: React.Dispatch<React.SetStateAction<any[]>>,
};



const convertToValidBusinessObject = (values: Area, type: string): ValidBusinessObject => {
  const pk = type + ':' + values.id;
  const sk = pk;

  const validBusinessObject = JSON.parse(JSON.stringify(values));
  validBusinessObject.pk = pk;
  validBusinessObject.sk = sk;
  validBusinessObject.type = type;

  return validBusinessObject;
}

export default function AddArea({ open, setOpen, transactionFunctions, contextCustomer, areas, setAreas, areaStyler, mode, area, addAreas, polygons, setPolygons, setAddAreas }: AddAreaProps) {
  debug && console.log('props', areas, area)
  const [selectedStyler, setSelectedStyler] = useState<ValidBusinessObject | undefined>(undefined);
  const [selectedArea, setSelectedArea] = useState<ValidBusinessObject | undefined>(undefined);
  const [currentLinkedArea, setCurrentLinkedArea] = useState<ValidBusinessObject | undefined>(undefined);
  const [currentLinkedStyler, setCurrentLinkedStyler] = useState<ValidBusinessObject | undefined>(undefined);


  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleSelect = (event: SelectChangeEvent) => {
    if (event.target.value === "") {
      debug && console.log("time to unlink", event.target.value)
    }

    // if "" selectedArea, will be undefined
    setSelectedArea(areas?.filter((area) => area.pk === event.target.value)[0]);
  }

  const [values, setValues] = useState<Area>({
    name: "Area" + Math.floor(Math.random() * 100),
    id: uuidv4(),
    description: undefined,
    color: undefined,
    opacity: undefined,
    lineWidth: undefined,
    locations: [],
    lastPoint: undefined,
  });

  useEffect(() => {
    debug && console.log("re render because input area change", area, mode)

    if (area) {
      setValues({
        name: area.name,
        id: area.id,
        description: area.description,
        color: area.color,
        opacity: area.opacity,
        lineWidth: area.lineWidth,
        locations: area.locations,
        lastPoint: area.lastPoint,
      })
    }

    getAdjacent2Wrapper({ scopeDefinitingContextObject: area as ValidBusinessObject, objectTypeId: "Area", adjacencyType: AdjacentType.PARENT, includeEdges: false })
      .then((res) => {

        if (area) {
          const temp = res.data?.Items?.filter((item) => item.pk !== area.pk)
          debug && console.log("let me see see", res, temp)
          if (temp && temp.length > 0) {
            setSelectedArea(temp[0] as ValidBusinessObject)
            setCurrentLinkedArea(temp[0] as ValidBusinessObject)
          }
        }

      })

    getAdjacent2Wrapper({ scopeDefinitingContextObject: area as ValidBusinessObject, objectTypeId: 'AreaStyler', adjacencyType: AdjacentType.CHILD, includeEdges: false })
      .then((res) => {
        debug && console.log("trying to get styler for plaace", res)
        if (res?.data?.Items) {
          setSelectedStyler(res?.data?.Items[0] as ValidBusinessObject)
          setCurrentLinkedStyler(res?.data?.Items[0] as ValidBusinessObject)
        }
      })

  }, [area?.pk])


  useEffect(() => {
    debug && console.log('create area from clicking', addAreas);
    if (mode === AreaManagementMode.ADD) {
      if (addAreas && addAreas.length > 0) {
        let initialArea = {
          name: "Area" + Math.floor(Math.random() * 100),
          id: uuidv4(),
          description: undefined,
          color: undefined,
          opacity: undefined,
          lineWidth: undefined,
          locations: addAreas,
          lastPoint: undefined,
        }

        setValues(initialArea);
      }
    }


  }, [addAreas?.length])


  const handleChange =
    (prop: keyof Area) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const saveArea = (newArea: ValidBusinessObject, existingObject: ValidBusinessObject, direction: PathDirection) => {
    debug && console.log('saveArea', newArea, existingObject, direction)

    let validArea = true;

    for (var i = 0; i < newArea.locations.length; i++) {
      validArea = isPosition(newArea.locations[i])
      debug && console.log('validArea', validArea)
      if (validArea === false) return;
    }

    if (validArea) {
      if (newArea.locations.length > 0) {
        newArea.lastPoint = newArea.locations[0];
      }
    }

    if (validArea) {

      createAndLink({ existingObject: existingObject, newObject: newArea, direction }).then((res) => {
        debug && console.log('what is inside?', res)
        debug && console.log('mode', mode)


        if (selectedArea) {
          debug && console.log('selectedArea', selectedArea)
          transactionFunctions?.saveAndLinkObject(selectedArea.sk, newArea, { adjacentType: AdjacentType.CHILD, objectType: "Area" })
          // setCurrentLinkedArea(selectedArea)
        }

        selectorUnlink(mode, selectedArea, currentLinkedArea, newArea, transactionFunctions)

        debug && console.log('selectedArea2', selectedArea)
        debug && console.log('currentLinkedArea', currentLinkedArea)
        // setCurrentLinkedArea(selectedArea)

        if (selectedStyler) {
          transactionFunctions?.saveAndLinkObject(newArea.sk, selectedStyler, { adjacentType: AdjacentType.CHILD, objectType: "AreaStyler" })
        }
        selectorUnlink(mode, selectedStyler, newArea, currentLinkedStyler, transactionFunctions)

        if (mode === AreaManagementMode.ADD) {
          getExact(newArea).then((res) => {
            debug && console.log('add new area into datalayer', res)

            if (res.data?.Items && res.data.Items.length > 0) {
              convertLocationToPolygon(res.data.Items, polygons, setPolygons)
              if (res.data.Items[0]) {
                areas?.push(res.data.Items[0] as ValidBusinessObject)
                setAreas(areas)
              }
            }
          })
        }


        if (mode === AreaManagementMode.EDIT) {
          // var foundIndex = areas?.findIndex((val) => val.pk === area?.pk)
          // if (foundIndex !== undefined && areas) {
          //   areas[foundIndex] = newArea;
          //   setAreas(areas)
          // }

          updateStateList(areas, area, newArea, setAreas)
        }

      })
    }

    // setAddAreas([])
  }

  const deleteArea = (existingObj: ValidBusinessObject) => {
    deleteObject(existingObj);
    setOpen(false)
  }

  const formLabel = 4;
  const formContent = 8;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{mode === AreaManagementMode.ADD ? "Add Area" : "Edit Area"}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={2}>
            <Typography sx={{ marginTop: '15px' }}>Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField id="name" label="name" variant="standard" size="small" value={values.name} onChange={handleChange('name')} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography sx={{ marginTop: '25px' }}>Description</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
              <TextField id="description" label="description" variant="standard" size="small" value={values.description} onChange={handleChange('description')} />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Typography sx={{ marginTop: '15px' }}>Parent Area</Typography>
          </Grid>
          <Grid item>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">Area</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedArea?.sk ?? ""}
                onChange={handleSelect}
                label="Area"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {areas?.filter((val) => val.pk !== area?.pk).map((menuItem) => (
                  <MenuItem value={menuItem.sk}>
                    {menuItem.name}
                  </MenuItem>
                ))}

                {/* {areas?.map((area) => (
                  <MenuItem value={area.sk}>
                    {area.name}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        {StyleInForm({ values, setValues, areaStyler, handleChange, selectedStyler, setSelectedStyler })}

        {PointInArea({ values, setValues })}

        <Grid container>
          <Button onClick={() => saveArea(convertToValidBusinessObject(values, 'Area'), contextCustomer as ValidBusinessObject, PathDirection.child)}>Save</Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          {/* <Button>Delete</Button> */}
          {mode === AreaManagementMode.EDIT && area ? (
            <Button onClick={() => {
              deleteArea(area)
              removeFromStateList(areas, area, setAreas)

              const foundIndex = polygons.findIndex((polygon) => polygon.features[0].properties.pk === area.pk)
              debug && console.log('markers foundIndex', foundIndex)

              if (foundIndex !== -1) {

                polygons.splice(foundIndex, 1)
                setPolygons(polygons)

                debug && console.log('markers length', polygons.length)
              }
            }
            }>Delete</Button>
          ) : (<></>)}
        </Grid>

      </DialogContent>
    </Dialog>
  );
}
