import { PossibleBusinessObject } from '@iotv/datamodel-core';
import C021TankUseType from '@c021/datamodel';

export interface TankProps {
  name: string;
  diameter: number;
  numOfTanks: number;
  height: number;
  users: number;
  notes: string;
  type: string;
  lat: number | undefined;
  lng: number | undefined;
}

export const createTankZombie = (props: TankProps): PossibleBusinessObject => {
  const { name, diameter, numOfTanks, height, users = 1, notes, type, lat = 0, lng = 0 } = props;
  return {
    type: 'Tank',
    name: name,
    diameter: diameter,
    no_of_tanks: numOfTanks,
    height: height,
    no_of_users: users,
    notes: notes,
    tank_type: type,
    coordinates: { lat: lat, lng: lng },
  };
};
