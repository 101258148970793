import { createStyles, FormControl, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import styles from '../../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../../cosmetics/Themes/dense';
import { InputComponentProps } from "../../../types/AppTypes";

const debug = process.env.REACT_APP_DEBUG && false;
const useStyles = makeStyles((theme) => ( {...styles(theme),
        ...createStyles({
            }),
        smaller: {
            fontSize: 15
        },   
    }));

export const  TextComponent = ( props: InputComponentProps) => {
    const {matchedPrimary,  viewKeyDefinition, updateKV, functionOverrides} = props;
    const classes = useStyles(denseTheme);
    const regex = new RegExp(viewKeyDefinition?.validationRx || '' );
    const validationError = !regex.test(matchedPrimary[viewKeyDefinition.key]);
    //debug && console.log('Validation res', validationError)
    const inputType = viewKeyDefinition.type
    const derivedValue = viewKeyDefinition.valueGetter ? viewKeyDefinition.valueGetter(matchedPrimary[viewKeyDefinition.key]) : ( matchedPrimary[viewKeyDefinition.key] ?? '' )
    const keyBase = `${matchedPrimary.sk ?? 'newObject'}_${viewKeyDefinition.key}`

    return <FormControl key= {`${keyBase}_fcontrol`}  className = {classes.root} { ...{ }}>
    <TextField { ...{
         InputProps: {
          classes: {
            input: classes.smaller,
          },
        },
       InputLabelProps: { shrink: true },
       onChange: (event) => { 
            const derivedValue = viewKeyDefinition.valueSetter ? viewKeyDefinition.valueSetter( event.target.value ) : event.target.value;
            event.target.value = derivedValue;
            debug && console.log(`on text input change`, {
              viewKeyDefinition, derivedValue, targetVal: event.target.value
            })
            updateKV( event as React.ChangeEvent<HTMLInputElement>,  viewKeyDefinition.key); 
            if (functionOverrides?.hasValidationErrors)  {
                functionOverrides?.hasValidationErrors()
            }
            if (viewKeyDefinition.stateFn) {
                viewKeyDefinition.stateFn( event )
            }
        },
        size: 'small' ,
        key : `${keyBase},_input`,
        margin : 'dense',
        type: inputType, 
        value: derivedValue,
        label: viewKeyDefinition.label,
        error: validationError,
        helperText: validationError && (viewKeyDefinition.failsValidationText ?? 'Fails validation'),
        variant: 'standard',
        disabled : viewKeyDefinition.editable === false ,
    
    }}

    ></TextField> 
     </FormControl>
        
    };
    
