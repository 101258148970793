import { MapLike } from '@iotv/datamodel'
import queryString from 'query-string'
import { useHistory } from 'react-router'


export type HistoryWithSearch = History & { location: { search: string } } 

export const getQueryParamsFromHistory =  <T extends MapLike<any> >(history: History) => (history as HistoryWithSearch)?.location?.search ? queryString.parse((history as HistoryWithSearch).location.search) as T : undefined

export type RouterHistory = ReturnType< typeof useHistory>

export const getQueryParamsFromRouterHistory =  <T extends MapLike<any> >(history: RouterHistory) => (history)?.location?.search ? queryString.parse((history ).location.search) as T : undefined