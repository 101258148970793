import config from '@iotv/config';
import { AdjacentType, getQueryBody, getZombieInstanceFromPrimaryKey } from '@iotv/datamodel-core';
import { ComparisonType, ExpressionFunctionType, LogicalOperatorType } from '@iotv/iotv-v3-types'
import React from "react";
import { ContextQueryParamsSelector } from "../contextQueryModal";
import SendIcon from '@mui/icons-material/Send';
import { Button, Radio, RadioGroup, TextField } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';

const dyn = config.aws.dynamoDB

export type TypeByBooleanAttributeParams = { type: string, attKey: string, value: boolean }

export const TypeByBooleanAttribute: ContextQueryParamsSelector<{ type: string, attKey: string }> = ({ setParams: setParamsInParent, lastQuery, clearSelectedObjects, lastQueryParamsRef, type, attKey }) => {
    console.log('TypeByAttribute gets', { type, attKey })
    //DEV DEFAUTLS
    const [params, setParams] = React.useState<TypeByBooleanAttributeParams | undefined>(lastQuery as TypeByBooleanAttributeParams ?? { type, attKey, value: true })

    const handleParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleParamChange', e.target.value)
        const value: boolean = (e.target.value === 'true')
        const params = { type, attKey, value }
        lastQueryParamsRef.current = params
        console.log('handleParamChange setting to', params)
        setParams(params)
    }

    const handleQueryGo = () => {
        if (params) {
            const { type, attKey, value } = params
            if (type && attKey) {
                const queryBody = getQueryBody({
                    adjacencyType: AdjacentType.SUBJECT,
                    contextObject: getZombieInstanceFromPrimaryKey(`${type}:xxx`),
                    ExclusiveStartKey: undefined,
                    filterGroups: [
                        {
                            filters: [
                                { key: attKey, value, predicate: ComparisonType.EQUALS },
                                { key: dyn.pKey, value: type, predicate: ExpressionFunctionType.BEGINS_WITH }
                            ],
                            setOperation: LogicalOperatorType.AND
                        }
                    ]
                })
                clearSelectedObjects()
                setParamsInParent(queryBody)
            }
        }
    }

    return <div {...{}}>
        <RadioGroup {...{ value: params?.value, row: true, onChange: handleParamChange }}>
            <FormControlLabel {...{ key: 'bw', value: true, control: <Radio {...{}} />, label: 'True' }} />
            <FormControlLabel {...{ key: 'co', value: false, control: <Radio {...{}} />, label: 'False' }} />
        </RadioGroup>
        <Button {...{ onClick: handleQueryGo, children: 'Go', endIcon: <SendIcon /> }} />

    </div>
}