import { PathDirection, ValidBusinessObject } from '@iotv/datamodel';
import {
    Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createAndLink, getAdjacent2Wrapper } from "../../../data/daoFunctions/daoFunctions";
import { AdjacentType, AreaManagementMode, DispatchFunctionsType } from '../../../types/AppTypes';
import { updateStateList } from '../../Survey/SurveyView';
import DeleteButton from './components/DeleteButton';

const debug = process.env.REACT_APP_DEBUG && false;

export interface PlaceType {
    name: string,
    id: string;
}

export type AddPlaceTypeProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    transactionFunctions: DispatchFunctionsType | undefined;
    contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined,
    stylers: ValidBusinessObject[] | undefined;
    mode: AreaManagementMode,
    placetype: ValidBusinessObject | undefined;
    placeTypes: ValidBusinessObject[] | undefined;
    setPlaceTypes: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>
}

export default function AddPlaceType({ open, setOpen, mode, stylers, contextCustomer, transactionFunctions, placetype, placeTypes, setPlaceTypes }: AddPlaceTypeProps) {

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const [values, setValues] = useState<PlaceType>({
        name: 'type',
        id: uuidv4(),
    });

    const [selectedStyler, setSelectedStyler] = useState<ValidBusinessObject | undefined>(undefined);
    const [currentLinkedStyler, setCurrentLinkedStyler] = useState<ValidBusinessObject | undefined>(undefined);

    useEffect(() => {
        if (placetype) {
            setValues({
                name: placetype.name,
                id: placetype.id
            })

            getAdjacent2Wrapper({ scopeDefinitingContextObject: placetype as ValidBusinessObject, objectTypeId: 'PlaceStyler', adjacencyType: AdjacentType.CHILD, includeEdges: false })
            .then((res) => {
                debug && console.log("trying to get styler for placetype", res)
                if (res?.data?.Items) {
                    setSelectedStyler(res?.data?.Items[0] as ValidBusinessObject)
                    setCurrentLinkedStyler(res?.data?.Items[0] as ValidBusinessObject)
                }
            })
        }
    }, [placetype?.pk])

    const handleChange =
        (prop: keyof PlaceType) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };



    const handleSelect = (event: SelectChangeEvent) => {
        if (event.target.value === "") {
            debug && console.log("time to unlink", event.target.value)
        }

        setSelectedStyler(stylers?.filter((styler) => styler.pk === event.target.value)[0]);

    }

    const convertToValidBusinessObject = (values: PlaceType, type: string): ValidBusinessObject => {
        const pk = type + ':' + values.id;
        const sk = pk;

        const validBusinessObject = JSON.parse(JSON.stringify(values));
        validBusinessObject.pk = pk;
        validBusinessObject.sk = sk;
        validBusinessObject.type = type;

        return validBusinessObject;
    }



    const savePlaceType = (newPlaceType: ValidBusinessObject, existingObject: ValidBusinessObject, direction: PathDirection) => {
        debug && console.log('saveStyler', newPlaceType, existingObject, direction)

        createAndLink({ existingObject: existingObject, newObject: newPlaceType, direction }).then((res) => {
            debug && console.log('what is inside?', res)

            if (selectedStyler) {
                transactionFunctions?.saveAndLinkObject(newPlaceType.sk, selectedStyler, { adjacentType: AdjacentType.CHILD, objectType: "PlaceType" })
            }

            // selectorUnlink(mode, selectedStyler, currentLinkedStyler, newPlaceType, transactionFunctions)

        }).catch((err) => debug && console.log('can not save styler', err))

        if (mode === AreaManagementMode.EDIT) {
            // var foundIndex = placeTypes?.findIndex((val) => val.pk === placetype?.pk)
            // if (foundIndex !== undefined && placeTypes) {
            //     placeTypes[foundIndex] = newPlaceType;

            //     setPlaceTypes(placeTypes)
            // }
            updateStateList(placeTypes, placetype, newPlaceType, setPlaceTypes)
        }

    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{mode === AreaManagementMode.ADD ? "Add PlaceType" : "Edit PlaceType"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography sx={{ marginTop: '15px' }}>Type</Typography>
                    </Grid>
                    <Grid item>
                        <TextField id="type" label="type" variant="standard" size="small" value={values.name} onChange={handleChange('name')} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '15px' }}>Styler</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Place Styler</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedStyler?.sk ?? ""}
                                onChange={handleSelect}
                                label="Area"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {stylers?.map((menuItem) => (
                                    <MenuItem value={menuItem.sk}>
                                        {menuItem.name}
                                    </MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid container>
                    <Button onClick={() => savePlaceType(convertToValidBusinessObject(values, 'PlaceType'), contextCustomer as ValidBusinessObject, PathDirection.child)}>Save</Button>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    {mode === AreaManagementMode.EDIT && placetype && DeleteButton(placetype, setOpen, placeTypes, setPlaceTypes) 
                    // && removeFromStateList(placeTypes, placetype, setPlaceTypes)
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}