import { CommonBusinessObject, ValidBusinessObject, ValidBusinessObjectList, ValidModelType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import { AdminListGroupsForUserResponse, GroupType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../cosmetics/sharedViewStyles';
import { getPseudoVBO } from '../../../../data/TypeHelpers';
import { AdjacentType, ListerlizerProps, MessageOutputType, ObjectCardProps, ViewDefinition, ViewObject } from '../../../../types/AppTypes';
import { Listerlizer } from '../../../factories/Listilizer';
import { addUserToGroup, listAllGroupsQuery, listGroupsForUser, removeUserFromGroup } from './groupQueries';

const debug = process.env.REACT_APP_DEBUG && false;


const cognitoGroupsToVBO = ((cognitoGroup: GroupType) => getPseudoVBO({ type: 'CognitoGroup', id: cognitoGroup.GroupName ?? uuidv4(), ...cognitoGroup, }))



type AdditionalNewUserOptionsProps = {
  selectedGroups: ValidBusinessObjectList,
  setSelectedGroups: React.Dispatch<React.SetStateAction<ValidBusinessObjectList>>
}

function NewUserOptions(props: ObjectCardProps & AdditionalNewUserOptionsProps & { match: { params: { action?: string, id?: string } } }): JSX.Element {

  debug && console.log('CongitoGroupsList got', props)


  const [usersVboGroups, setUsersVboGroups] = React.useState<ValidBusinessObjectList>([]);
  const [vboGroups, setVboGroups] = React.useState<ValidBusinessObjectList>([]);
  const { viewObject: viewObjectIn, userFunctions, history, transactionFunctions, selectedGroups, setSelectedGroups } = props;


  const getGroupBySk = (sk: string) => vboGroups.find((group) => group.sk === sk)

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const addConvertedGroupsToSelectedGroups = ((data: AdminListGroupsForUserResponse) => {
    const tempVboGroups = data.Groups?.map((group: GroupType) => cognitoGroupsToVBO(group));
    debug && console.log('CG  user has groups', tempVboGroups)
    setUsersVboGroups(tempVboGroups ?? [])
    if (tempVboGroups) setSelectedGroups(tempVboGroups)
  })

  useEffect(() => {
    debug && console.log('used effect to get users groups')
    const fn = addConvertedGroupsToSelectedGroups;
    viewObject.matchedPrimary && listGroupsForUser(fn, viewObject.matchedPrimary.username)
  }, [vboGroups.length, usersVboGroups.length, viewObjectIn?.matchedPrimary?.username]);

  useEffect(() => {
    debug && console.log('used effect to get all groups')
    const fn = ((data: AdminListGroupsForUserResponse) => {
      const tempVboGroups = data.Groups?.map((group: GroupType) => cognitoGroupsToVBO(group));
      debug && console.log('CG all user groups', tempVboGroups)
      setVboGroups(tempVboGroups ?? [])
    })
    listAllGroupsQuery(fn)
  }, []);

  const user = viewObjectIn.matchedPrimary

  const viewObject: ViewObject = {
    matchedPrimary: user,
    matchedRelatedByPk: [],
    matchedRelatedBySk: vboGroups
  }

  type CongitoVBOKeys = keyof (GroupType & CommonBusinessObject)
  const userTableViewDefinition: ViewDefinition & { [key in CongitoVBOKeys]: any } = {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    Description: { key: 'Description', label: 'Description', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },

  }

  const groupListProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'CognitoGroup' as ValidModelType,
      edgeFilter: undefined
    },
    
    maxItems: 25,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: userTableViewDefinition,
    viewObject,
    contextObject: undefined,
    functionOverrides: {
      selectItem: (sk: string) => {
        let newSelection = [...selectedGroups]
        const currentIdx = newSelection.findIndex((pvbo) => pvbo.sk === sk)
        debug && console.log('CG selectItem', currentIdx)
        if (currentIdx === -1) {
          const addedGroup = getGroupBySk(sk)
          debug && console.log('CG groop added to selection', addedGroup)
          if ( addedGroup ) newSelection.push( addedGroup )
        } else {
          const removedGroup = getGroupBySk(sk)
          debug && console.log('CG groop removed from slection', removedGroup)
          if ( removedGroup ) newSelection.splice( currentIdx, 1  )
        }
        setSelectedGroups(newSelection);
      },
      hasValidationErrors: () => { }
    },
    checkedItemSks: selectedGroups.map((pvbo: ValidBusinessObject) => pvbo.sk),
    injectedComponents: [ <></>]
  }



  debug && console.log('CG groupListProps', groupListProps)

  return (
    <Card>

      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          Add to Groups 
         </Typography>
        <Listerlizer key={'groupList'} {...{ ...groupListProps }}></Listerlizer>

      </CardContent>

    </Card>




  );

}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(NewUserOptions)