import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Action } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface actionsModalProp {
  close: () => void;
  type: string;
  update: (input: Action | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: Action[];
}

const ActionsModal: React.FC<actionsModalProp> = (props: actionsModalProp) => {
  let { close, type, update, index, existing } = props;

  let [describeAction, setDescribeAction] = useState<string>('');
  let [describeActionUser, setDescribeActionUser] = useState<string>('');

  let [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setDescribeAction(source.action);
      setDescribeActionUser(source.person);
    }
  }, [index, existing]);

  useEffect(() => {
    setValid(describeAction !== '' && describeActionUser !== '');
  }, [describeAction, describeActionUser]);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  const addStage = (type: string, action: string, person: string, pos: number | undefined) => {
    update({ type, action, person }, pos);
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create {type} Action
              </Typography>
              <div className={styles.full}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <TextField
                    rows={6}
                    value={describeAction}
                    label='Describe Action'
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    minRows={4}
                    multiline
                    fullWidth
                    style={{ width: '100%' }}
                    onChange={(e) => setDescribeAction(e.target.value)}
                  />
                  <TextField
                    rows={6}
                    label='Describe who will carry out the Action'
                    value={describeActionUser}
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    minRows={4}
                    multiline
                    fullWidth
                    style={{ width: '100%' }}
                    onChange={(e) => setDescribeActionUser(e.target.value)}
                  />
                </Stack>
              </div>
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    addStage(type, describeAction, describeActionUser, index);
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default ActionsModal;
