import { Button, Container, createStyles, Paper, Theme, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { AppClasses } from '../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const styles = (theme: Theme) => createStyles(
    {
        paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      }
    });

function NoMatch( props: { classes: AppClasses }) {
    const location = useLocation();
    const history = useHistory();
    const { classes } = props;
    return (
    <Paper className = { clsx(classes.paper) } >

        <Container>
            <h3>
            No match for <code>{location.pathname}</code>

            </h3>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick = {(e) => {
                    e.preventDefault()
		debug && console.log('Submit clicked', { history})
                    history.goBack()
                    }
                }
            >OK</Button>
        </Container>
    </Paper>
     
    );
  }
  
  export default withStyles(styles)(NoMatch)