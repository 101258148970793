import { Container, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";
import styles from '../../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../../cosmetics/Themes/dense';
import { ComponentMode, InputComponentProps, pseudoEvent } from "../../../types/AppTypes";
import { TextComponent } from "./TextComponent";

const useStyles = makeStyles((theme) => (styles(theme)));

const initialEntry = { k: 'no-op', v: 'Select value'}

export const  EnumComponent = ( props: InputComponentProps) => {
    const {matchedPrimary,  viewKeyDefinition, mode, updateKV} = props;

    const classes = useStyles(denseTheme);
    let element = <Container><div></div></Container>
    if (viewKeyDefinition.enumKV ) {
        if ( mode === ComponentMode.EDIT && viewKeyDefinition.editable !== false) {
            let accessKey = `${matchedPrimary.sk}:${viewKeyDefinition.key}`;
            element =  <FormControl className = {classes.editFormControlFullWidth}>
            <InputLabel id={accessKey}>{ viewKeyDefinition.label }</InputLabel>
            <Select
                labelId={ `${accessKey}_label` }
                id={ `${accessKey}_select` }
                value={matchedPrimary[viewKeyDefinition.key] ?? initialEntry.k}
                onChange={ 
                    (event) => {
                        const pseudoEvent: pseudoEvent = { currentTarget: { type: 'object', value: event.target.value}}
                        if ( event.target.value !== initialEntry.k ) {
                            updateKV( pseudoEvent,  viewKeyDefinition.key) 
                            if (viewKeyDefinition.stateFn) {
                                viewKeyDefinition.stateFn( event )
                            }
                        }
                        
                    }
                } 
            >
                { 
                    [
                        <MenuItem key = {`item_${initialEntry.k}`} value={initialEntry.k}>{initialEntry.v}</MenuItem>,
                        ...Object.entries(viewKeyDefinition.enumKV).map( ([k, v], i) => {
                        return <MenuItem key = {`item_${i}`} value={v}>{k}</MenuItem>;
                        })
                    ]
                }
            </Select>
        </FormControl>
        } else if ( mode === ComponentMode.VIEW || mode === ComponentMode.READONLY ) {
            const enumEntry = Object.entries(viewKeyDefinition.enumKV).find( ([k, v]) => v === matchedPrimary[viewKeyDefinition.key] || v === matchedPrimary[viewKeyDefinition.key]?.sk)
            return <div>{ enumEntry && enumEntry[0] } </div>
        } else if ( viewKeyDefinition.editable  === false) {
            const enumEntry = Object.entries(viewKeyDefinition.enumKV).find( ([k, v]) => v === matchedPrimary[viewKeyDefinition.key] || v === matchedPrimary[viewKeyDefinition.key]?.sk)
            const value = enumEntry && enumEntry[0]
            return <TextComponent {...{...props, matchedPrimary: { ...matchedPrimary, [viewKeyDefinition.key]: value }}}></TextComponent>
        }
        
    };
    return element
}