import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';

interface prompt {
  text: string;
  add?: () => void;
}

export const NoneMade = (props: prompt): JSX.Element => {
  let { text, add } = props;
  return (
    <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Typography variant='h6' component='h6'>
          No {text}'s Created
        </Typography>
        <Typography variant='h6' component='h6'>
          Please click on {add === undefined ? 'a' : 'the'}
          <Button disabled={add === undefined} onClick={add}>
            <Add />
            <b>{text}</b>
          </Button>
          button to create a {text}
        </Typography>
      </Stack>
    </Box>
  );
};
