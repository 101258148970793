import { isPosition, PathDirection, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import {
    Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { createAndLink, getAdjacent2Wrapper, getExact } from "../../../data/daoFunctions/daoFunctions";
import { AdjacentType, AreaManagementMode, DispatchFunctionsType } from '../../../types/AppTypes';
import { convertPlacesToMarkers, updateStateList } from '../../Survey/SurveyView';
import PlaceStyleInForm from './components/PlaceStyleInForm';
import { deleteExistingObject, removeFromStateList, selectorUnlink } from './helperFunctions/helperFunctions';


const debug = process.env.REACT_APP_DEBUG && false;

export interface Place {
    id: string,
    name: string | undefined,
    description: string | undefined;
    placeType: string | undefined;
    location: { lat: number | string, lng: number | string };
    color: string | undefined;
    icon: any;
}

export type AddPlaceProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    transactionFunctions: DispatchFunctionsType | undefined;
    contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined,
    areas: ValidBusinessObject[] | undefined,
    placeStyler: ValidBusinessObject[] | undefined,
    mode: AreaManagementMode,
    place?: ValidBusinessObject,
    placeTypes: ValidBusinessObject[] | undefined,
    addMarkers: any[],
    setAddMarkers: React.Dispatch<React.SetStateAction<any[]>>,
    markers: any[],
    setMarkers: React.Dispatch<React.SetStateAction<any[]>>,
    places: ValidBusinessObjectList | undefined,
    setPlaces: React.Dispatch<React.SetStateAction<ValidBusinessObjectList | undefined>>
    map?: google.maps.Map<Element>,
};

const convertToValidBusinessObject = (values: any, type: string): ValidBusinessObject => {
    const pk = type + ':' + values.id;
    const sk = pk;

    const validBusinessObject = JSON.parse(JSON.stringify(values));
    validBusinessObject.pk = pk;
    validBusinessObject.sk = sk;
    validBusinessObject.type = type;

    return validBusinessObject;
}

export default function AddPlace({ open, setOpen, transactionFunctions, contextCustomer, areas, placeStyler, mode, place, placeTypes, addMarkers, setAddMarkers, markers, setMarkers, places, setPlaces, map }: AddPlaceProps) {

    debug && console.log('what is the plafe ', mode, place, markers, places)


    let initialPlace = {
        name: "Place" + Math.floor(Math.random() * 100),
        id: uuidv4(),
        description: undefined,
        placeType: undefined, 
        location: {
            lat: -36.8509,
            lng: 174.7645
        },
        color: '#3366cc',
        icon: ''
    }

    debug && console.log('initialPlace', initialPlace)
    const [values, setValues] = useState<Place>(initialPlace);

    const [selectedArea, setSelectedArea] = useState<ValidBusinessObject | undefined>(undefined);
    const [currentLinkedArea, setCurrentLinkedArea] = useState<ValidBusinessObject | undefined>(undefined);

    const [selectedStyler, setSelectedStyler] = useState<ValidBusinessObject | undefined>(undefined); // or the styler linked to the current place, in edit mode
    const [currentLinkedStyler, setCurrentLinkedStyler] = useState<ValidBusinessObject | undefined>(undefined)

    const [selectedPlaceType, setSelectedPlaceType] = useState<ValidBusinessObject | undefined>(undefined);
    const [currentLinkedPlaceType, setCurrentLinkedPlaceType] = useState<ValidBusinessObject | undefined>(undefined);




    useEffect(() => {
        debug && console.log('re renderig edit place', place?.sk, place)
        if (place) {
            setValues({
                name: place.name,
                id: place.id,
                description: place.description,
                placeType: place.placeType,
                location: place.location,
                color: place.color,
                icon: place.icon
            })
            // setValues(place)

            getAdjacent2Wrapper({ scopeDefinitingContextObject: place as ValidBusinessObject, objectTypeId: 'Area', adjacencyType: AdjacentType.PARENT, includeEdges: false })
                .then((res) => {
                    debug && console.log('let me seee seee', res?.data?.Items)
                    if (res?.data?.Items) {
                        setSelectedArea(res?.data?.Items[0] as ValidBusinessObject)
                        setCurrentLinkedArea(res?.data?.Items[0] as ValidBusinessObject)
                    }
                })

            getAdjacent2Wrapper({ scopeDefinitingContextObject: place as ValidBusinessObject, objectTypeId: 'PlaceStyler', adjacencyType: AdjacentType.CHILD, includeEdges: false })
                .then((res) => {
                    debug && console.log("trying to get styler for plaace", res)
                    if (res?.data?.Items) {
                        setSelectedStyler(res?.data?.Items[0] as ValidBusinessObject)
                        setCurrentLinkedStyler(res?.data?.Items[0] as ValidBusinessObject)
                    }
                })
            getAdjacent2Wrapper({ scopeDefinitingContextObject: place as ValidBusinessObject, objectTypeId: 'PlaceType', adjacencyType: AdjacentType.CHILD, includeEdges: false })
                .then((res) => {
                    debug && console.log("trying to get place type", res?.data?.Items)
                    if (res?.data?.Items) {
                        setSelectedPlaceType(res?.data?.Items[0] as ValidBusinessObject)
                        setCurrentLinkedPlaceType(res?.data?.Items[0] as ValidBusinessObject)
                    }

                })
        }


    }, [place?.pk])

    useEffect(() => {
        debug && console.log('create place from clicking', addMarkers);
        if (mode === AreaManagementMode.ADD) {
            if (addMarkers && addMarkers.length > 0) {
                let initialPlace = {
                    name: "Place" + Math.floor(Math.random() * 100),
                    id: uuidv4(),
                    description: undefined,
                    placeType: undefined,
                    location: {
                        lat: addMarkers[0],
                        lng: addMarkers[1]
                    },
                    color: '#3366cc',
                    icon: ''
                }

                setValues(initialPlace);
            }
        }


    }, [addMarkers?.length])


    const handleSelect = (event: SelectChangeEvent) => {

        if (event.target.value === "") {
            debug && console.log("time to unlink", event.target.value)
        }
        setSelectedArea(areas?.filter((area) => area.pk === event.target.value)[0]);
    }


    const handlePlaceType = (event: SelectChangeEvent) => {
        if (event.target.value === "") {
            debug && console.log("time to unlink", event.target.value)
        }
        debug && console.log('link place type', placeTypes?.filter((placetype) => placetype.pk === event.target.value)[0])
        setSelectedPlaceType(placeTypes?.filter((placetype) => placetype.pk === event.target.value)[0])
        // setSelectedPlaceType()
    }

    const [latText, setLatText] = useState('');
    const [lngText, setLngText] = useState('');


    const handleClose = () => {
        setOpen(false);
    };

    const handleChange =
        (prop: keyof Place) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleLocationChange = (prop: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        debug && console.log('event', event.target.value);

        if (prop === 'lat') {
            const tempLng = values.location.lng;
            setValues({ ...values, location: { lat: event.target.value === '-' ? event.target.value : parseFloat(event.target.value), lng: tempLng } })
            values.location.lat = parseFloat(event.target.value);
            Math.abs(values.location.lat as number) <= 90 ? setLatText('') : setLatText('Invalid Lat');
        }

        if (prop === 'lng') {
            const tempLat = values.location.lat;

            setValues({ ...values, location: { lng: event.target.value === '-' ? event.target.value : parseFloat(event.target.value), lat: tempLat } })
            Math.abs(values.location.lng as number) <= 180 ? setLngText('') : setLngText('Invalid Lon')
        }

    }

    const savePlace = (newPlace: ValidBusinessObject, existingObject: ValidBusinessObject, direction: PathDirection) => {
        debug && console.log('savePlace', newPlace, existingObject, direction)

        let validArea = isPosition(newPlace.location);


        if (validArea) {

            createAndLink({ existingObject: existingObject, newObject: newPlace, direction }).then((res) => {

                if (selectedArea) {
                    transactionFunctions?.saveAndLinkObject(selectedArea.sk, newPlace, { adjacentType: AdjacentType.CHILD, objectType: "Place" })
                }

                selectorUnlink(mode, selectedArea, currentLinkedArea, newPlace, transactionFunctions)
                debug && console.log('selectedStyler', selectedStyler)

                if (selectedStyler) {
                    transactionFunctions?.saveAndLinkObject(newPlace.sk, selectedStyler, { adjacentType: AdjacentType.CHILD, objectType: 'PlaceStyler' })
                }

                selectorUnlink(mode, selectedStyler, newPlace, currentLinkedStyler, transactionFunctions)

                if (selectedPlaceType) {
                    transactionFunctions?.saveAndLinkObject(newPlace.sk, selectedPlaceType, { adjacentType: AdjacentType.CHILD, objectType: 'PlaceType' })
                }

                selectorUnlink(mode, selectedPlaceType, newPlace, currentLinkedPlaceType, transactionFunctions)

                if (mode === AreaManagementMode.ADD) {
                    getExact(newPlace).then((res) => {
                        debug && console.log('add new place into datalayer', res)
                        if (res.data?.Items && res.data?.Items.length > 0) {
                            convertPlacesToMarkers(res.data?.Items, markers, setMarkers)
                            if (res.data?.Items[0]) {
                                places?.push(res.data?.Items[0] as ValidBusinessObject)
                                setPlaces(places)
                            }
                        }
                    })
                }

                if (mode === AreaManagementMode.EDIT) {
                    // var foundIndex = places?.findIndex((val) => val.pk === place?.pk)
                    // debug && console.log('foundIndex', foundIndex)
                    // if (foundIndex !== undefined && places) {
                    //     places[foundIndex] = newPlace;
                    //     debug && console.log('places', places)
                    //     setPlaces(places)
                    // }

                    updateStateList(places, place, newPlace, setPlaces)
                    
                }


            })


        }

        setAddMarkers([])

    }

    // const deletePlace = (existingObject: ValidBusinessObject) => {
    //     deleteObject(existingObject);
    //     setOpen(false);
    // }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{mode === AreaManagementMode.ADD ? 'Add Place' : 'Edit Place'}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={2}>
                        <Typography sx={{ marginTop: '15px' }}>Name</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <TextField id="name" label="name" variant="standard" size="small" value={values.name} onChange={handleChange('name')} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={2}>
                        <Typography sx={{ marginTop: '25px' }}>Description</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                            <TextField id="description" label="description" variant="standard" size="small" value={values.description} onChange={handleChange('description')} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '25px' }}>Place Type</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Place Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedPlaceType?.sk ?? ""}
                                onChange={handlePlaceType}
                                label="Place Type"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {placeTypes?.map((menuItem) => (
                                    <MenuItem value={menuItem.sk}>
                                        {menuItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '15px' }}>Location</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                            <TextField required id="lat" label="lat" variant='standard' size='small' type='number' helperText={latText} error={latText.length > 0 ? true : false}
                                value={values.location.lat}
                                onChange={(event) => handleLocationChange('lat', event)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                            <TextField required id="lng" label="lon" variant='standard' size='small' type='number' helperText={lngText} error={lngText.length > 0 ? true : false} value={values.location.lng} onChange={(event) => handleLocationChange('lng', event)} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '15px' }}>Parent Area</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Area</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedArea?.sk ?? ""}
                                onChange={handleSelect}
                                label="Area"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {areas?.map((area) => (
                                    <MenuItem value={area.sk}>
                                        {area.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                </Grid>

                <PlaceStyleInForm {...{ contextCustomer, placeStyler, selectedStyler, setSelectedStyler, values, setValues, handleChange }} />

                <Grid container>
                    <Grid item>
                        <Button onClick={() => savePlace(convertToValidBusinessObject(values, 'Place'), contextCustomer as ValidBusinessObject, PathDirection.child)}>Save</Button>

                        <Button onClick={() => { 
                            setOpen(false) 
                            setAddMarkers([]) 
                            }}>Cancel</Button>

                        {mode === AreaManagementMode.EDIT && place && <Button onClick={() => {  
                            deleteExistingObject(place, setOpen)

                            removeFromStateList(places, place, setPlaces)

                            const foundIndex = markers.findIndex((marker) => marker.features[0].properties.pk === place.pk)
                            debug && console.log('markers foundIndex', foundIndex)

                            if (foundIndex !== -1) {

                                markers.splice(foundIndex, 1)
                                setMarkers(markers)

                                debug && console.log('markers length', markers.length)
                            }
                            
                        }}>Delete</Button>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}