import {Auth} from '@aws-amplify/auth';
const debug = process.env.REACT_APP_DEBUG && false;
   
const showCognitoId = () => {
    Auth.currentCredentials().then(
        credentials => {
		debug && console.log('Identity id:', credentials.identityId);
        
        });
};

const getCognitoId = async () => await new Promise( (resolve, reject) => {
    Auth.currentCredentials().then(
        credentials => {
		debug && console.log('Identity id:', credentials.identityId);
            resolve(credentials.identityId);
        });
});


async function DebugAuth() {
    const session = await Auth.currentSession();
		debug && console.log('session: $sess\n', session);


    try {
		debug && console.log('get current user');
        const cognitoUser = await Auth.currentAuthenticatedUser();
		debug && console.log('get currnet session');
        const currentSession = await Auth.currentSession();

        await cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: Error, session: { idToken: any; refreshToken: any; accessToken: any; }) => {
		debug && console.log('session', err, session);
            const { idToken, refreshToken, accessToken } = session;
		debug && console.log('idToken: ', idToken);
		debug && console.log('accessToken: ', accessToken);
		debug && console.log('refreshToken: ', refreshToken);
        });
    } catch (e) {
		debug && console.log('Unable to refresh Token', e);
    }

    const credentials = await Auth.currentCredentials();
		debug && console.log('Identity id:', credentials.identityId);
}

export   { DebugAuth, showCognitoId as showCognitoUserId, getCognitoId };
