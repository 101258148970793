import { HistoryStore, Milliseconds, ThingShadowState } from '@iotv/datamodel';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { Card, CardContent, CardHeader, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import config from '../../config';
import { extractGraphData } from '../../data/TypeHelpers';
import { LineChartXType, TimeSliceButtonValues } from '../../types/AppTypes';
import { RangeableLineChart } from '../charts/RangeableLineGraph';





const debug = process.env.REACT_APP_DEBUG && false;

const googleChartUrl = config.google.chartApiUri;

const defaultTimeSliceButtonValues: TimeSliceButtonValues = [ 
  [10, Milliseconds.MINUTE],
  [1, Milliseconds.HOUR],
  [1, Milliseconds.DAY],
  [7, Milliseconds.DAY],

]


type ObjectGraphCardProps = {

  thingStatesMostRecentFirst?: ThingShadowState[],
  historyObject?: HistoryObjectType
  filterGraphKeys?: string[]
  classes: any,
  timeSliceButtonValues?: TimeSliceButtonValues
  title?: string

}

export default function ObjectGraphCard( props: ObjectGraphCardProps ) {

  debug && console.log('ObjectGraphCard has props', props)
  const xType: LineChartXType = LineChartXType.TIMESERIES

  const getChart = ( historyObject: HistoryStore,  xType: LineChartXType, classes: any, elementKey: string, keys: string[], timeSliceButtonValues?: TimeSliceButtonValues | undefined )  => {
   
    return <TableRow key={`${elementKey}`}>
        <TableCell>
         <RangeableLineChart { ...{ historyObject, xType, classes, keys, timeSliceButtonValues } }></RangeableLineChart>
        </TableCell>
    </TableRow>
  }

  const [ singleChart, setSingleChart ] = React.useState(true)


  const { thingStatesMostRecentFirst, filterGraphKeys, classes, timeSliceButtonValues = defaultTimeSliceButtonValues, title } = props;


  const historyObject: HistoryStore =  thingStatesMostRecentFirst ? new HistoryStore(extractGraphData(thingStatesMostRecentFirst)) :  (props.historyObject instanceof HistoryStore) ? props.historyObject:  props.historyObject ? new HistoryStore(props.historyObject): new HistoryStore() ;

  const historyStoreKeyFilter = historyObject.keys().filter( (k) => historyObject[k].every( ( v: any ) => typeof v === 'number' || v === undefined || v === null ));

  const getGraphRows = (historyObject: HistoryStore, filterKeys?: string[], singleChart?: boolean ) => {
    const keys = filterKeys || Object.keys(historyObject).filter( ( key ) =>  historyStoreKeyFilter.includes(key) );

    const combineKeysInSingleChart = (singleChart);

    let rows: (JSX.Element | undefined)[] = []

    if ( combineKeysInSingleChart ) {
      rows = [ getChart(historyObject, xType, classes, 'combinedChart', keys, timeSliceButtonValues) ]
    } else {
      rows = keys.map( (key ) => {
   
        return historyObject[key] && getChart(historyObject, xType, classes, key, [key], timeSliceButtonValues)
      })
    }
    return rows;
  } 

  const  googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`)
  debug && console.log('Charts script prosent?', googleChartsScript)
  return (
    <Card className={classes.root}>
        <CardHeader
        title = { title ?? 'Graphs' }
      ></CardHeader>
       <CardContent>
       <FormControlLabel
                  control={<Switch { ...{ onChange: () => setSingleChart(!singleChart), checked: singleChart  } }></Switch> }
                  label = 'combine charts'
        ></FormControlLabel>
       
       <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
            
            { historyObject  && googleChartsScript && getGraphRows(historyObject, filterGraphKeys, singleChart)}
            
            
        </TableBody>
      </Table>
    </TableContainer>
      </CardContent>
   
    </Card>
  )
}


