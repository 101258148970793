import { ValidBusinessObject, ValidModelObject } from '@iotv/datamodel-core';
import { ViewObject, ObjectCardProps } from '../../../../../../types/AppTypes';
import { Add, AssistantDirection, Delete, Edit, AutoAwesome } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Radio, RadioGroup, FormControlLabel, FormControl, Stack, Button, Tabs, Tab, Table, TableBody, TableRow, TableCell } from '@mui/material';
import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import Confirmation from '../../../Message/Confirmation';
import {
  Action,
  DMPStage,
  DMPTrigger,
  emptyStageTemplate,
  Goal,
  goalType,
  Indication,
  responseActionTypes,
  StageMessage,
  triggerOptions,
} from '../DMPExtrasV2';
import ActionPage from './ActionPage';
import styles from './DroughtManagementPlanTemplate.module.css';
import { makeConditionTriggerDescription } from './DroughtPlanModals/ConditionTriggersModal';
import EditStageNameModal from './DroughtPlanModals/EditStageNameModal';
import WatchhistoryModal from './DroughtPlanModals/WatchhistoryModal';
import EditactiovestageModal from './DroughtPlanModals/Editactiovestage';
import { makeGoalDescription } from './DroughtPlanModals/GenericGoalsModal';
import { makeStatusTriggerDescription } from './DroughtPlanModals/StatusTriggersModal';
import GoalPage from './GoalPage';
import IndicationPage from './IndicationPage';
import InternalManagementPage from './InternalManagementPage';
import StageMessagePage from './StageMessagePage';
import TriggersPage from './TriggersPage';
import HistoryIcon from '@mui/icons-material/History';

interface droughtManagementPlanTemplateProp {
  contextCustomer: ValidBusinessObject;
  source: ValidBusinessObject;
  finduser: ObjectCardProps;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const DroughtManagementPlanTemplate: React.FC<droughtManagementPlanTemplateProp> = (props: droughtManagementPlanTemplateProp) => {
  let { contextCustomer, source, finduser, setSource } = props;

  let [view, setView] = useState<string>('Plan');
  let [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [planValue, setPlanValue] = useState<string>('1');
  const [value, setValue] = useState<string>('Triggers');
  let [index, setIndex] = useState<number>();
  const [editName, setEditName] = useState<boolean>(false);
  const [watchhistory, setwatchhistory] = useState<boolean>(false);
  const [editactivestage, setactivestage] = useState<boolean>(false);

  const handlePlanChange = (event: React.SyntheticEvent, newValue: number) => {
    setPlanValue(newValue.toString());
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: number = parseInt(event.target.value) as number;
    if (isNaN(value)) {
      setIndex(undefined);
    } else {
      setIndex(value - 1);
    }

    setView((event.target as HTMLInputElement).value);
  };

  const addNewStage = () => {
    if (setSource !== undefined) {
      let stages: DMPStage[] = source?.stage || [];
      stages.push(emptyStageTemplate(stages.length + 1));
      setSource({ ...source, stage: stages });
    }
  };

  const openEditName = () => {
    setEditName(true);
  };

  const closeEditName = () => {
    setEditName(false);
  };

  const openwatchhistory = () => {
    setwatchhistory(true);
  };

  const closewatchhistory = () => {
    setwatchhistory(false);
  };

  const openactivestage = () => {
    setactivestage(true);
  };

  const closeactivestage = () => {
    setactivestage(false);
  };

  const deleteStage = () => {
    if (setSource !== undefined) {
      resetScreen();
      let stages: DMPStage[] = source.stage;
      stages.pop();
      setSource({ ...source, stage: stages });
    }
  };

  const resetScreen = useCallback(() => {
    setView('Plan');
    setIndex(0);
    setPlanValue('1');
    setValue('Goals');
  }, []);

  // const activestage = () => {
  //   var beforechange = source.activestage
  //   addNewhistory(getstagename(beforechange), getstagename(index ? index : 0))
  // };

  // function getstagename(input: number) {
  //   return source.stage[input]?.name ? `${source.stage[input]?.stageNumber}) ${source.stage[input]?.name} ` : `Stage ${source.stage[input]?.stageNumber}`
  // }

  // function addNewhistory(before: string, after: string) {
  //   if (setSource !== undefined) {
  //     var history = historyTemplate(before, after)
  //     console.log("what is index", history)
  //     let stages: DMPHistory[] = source?.stagechangehistory || [];
  //     stages.push(history);
  //     setSource({ ...source, activestage: index, stagechangehistory: stages });
  //   }
  //   console.log("what is index", source)
  // };

  // type DMPHistory = {
  //   name: string,
  //   beforechange: string,
  //   afterchange: string,
  //   time: string
  // };

  // function historyTemplate(before: string, after: string) {
  //   return {
  //     name: finduser.user?.name,
  //     beforechange: before,
  //     afterchange: after,
  //     time: (new Date()).toString()
  //   };
  // };
  console.log("whatiscontextCustomer", source)

  return (
    <div>
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
        <div className={styles.column}>
          <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
            <Button onClick={openwatchhistory} variant='outlined' fullWidth >
              <HistoryIcon /> Stage Change History
            </Button>
          </Stack>
          <p></p>
          <Card>
            <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2}>

              <Box sx={{ width: 200, bgcolor: 'info.main', color: 'primary.contrastText', p: 2 }}>
                Active Stage : <br></br>{source.stage[source.activestage]?.name ? `${source.stage[source.activestage]?.stageNumber}) ${source.stage[source.activestage]?.name} ` : `Stage ${source.stage[source.activestage]?.stageNumber}`}
              </Box>
              <Box m={2}>
                <FormControl>
                  <RadioGroup defaultValue='Plan' name='radio-buttons-group' onChange={handleRadioChange}>
                    <FormControlLabel value='Plan' control={<Radio />} label='Plan' />
                    {source.stage?.map((stage: DMPStage) => (
                      <FormControlLabel
                        value={stage.stageNumber}
                        control={<Radio />}
                        label={stage.name ? `${stage.stageNumber}) ${stage.name} ` : `Stage ${stage.stageNumber}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              {setSource && (
                <Button disabled={source.stage?.length === 5 || false} onClick={addNewStage} variant='contained' fullWidth>
                  <Add /> Stage
                </Button>
              )}

              <Button disabled={index === undefined || setSource === undefined} onClick={openEditName} variant='contained' fullWidth>
                <Edit /> Name
              </Button>

              {setSource && (
                <>
                  <Button
                    disabled={index === undefined || isNaN(index) || source.stage?.length !== index + 1 || index < 2}
                    onClick={() => setConfirmDelete(true)}
                    variant='contained'
                    fullWidth
                  >
                    <Delete /> Delete
                  </Button>

                  <Button
                    disabled={index === undefined || isNaN(index) || source.activestage === index}
                    onClick={() => openactivestage()}
                    variant='contained'
                    fullWidth
                  >
                    <AutoAwesome /> &nbsp; Make Active
                  </Button>



                  <Confirmation
                    name={`Delete Stage ${index! + 1}`}
                    message={`Are you sure you wish to delete Stage ${index! + 1}? This change will be permanent and irreversible.`}
                    state={confirmDelete}
                    changeState={setConfirmDelete}
                    actionFunction={deleteStage}
                  />
                </>
              )}
            </Stack>
          </Card>
        </div>
        <div className={styles.table}>
          {view === 'Plan' ? (
            <Box sx={{ border: '1px solid red' }}>
              <TabContext value={planValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handlePlanChange} variant='fullWidth'>
                    {source.stage.map((stage: DMPStage) => {
                      return <Tab label={stage.name ? stage.name : `Stage ${stage.stageNumber}`} value={stage.stageNumber.toString()} />;
                    })}
                  </TabList>
                </Box>
                <Table>
                  <TableBody>
                    {/* Triggers */}
                    {triggerOptions.map((type, i) => {
                      let filtered = source?.stage[parseInt(planValue) - 1].stageTriggers.filter((triggers: DMPTrigger) => triggers.type === type);
                      return (
                        <TableRow>
                          <TableCell align='left'>
                            <b>{type} Triggers</b>
                          </TableCell>
                          <TableCell align='left'>
                            {filtered.length > 0 ? (
                              filtered
                                .map((trigger: DMPTrigger) => {
                                  switch (type) {
                                    case 'Condition':
                                      return (
                                        trigger.timeframe !== undefined &&
                                        makeConditionTriggerDescription(trigger.triggerDescription, trigger.metric, trigger.timeframe)
                                      );
                                    case 'Status':
                                      return (
                                        trigger.timeframe &&
                                        makeStatusTriggerDescription(
                                          trigger.triggerDescription,
                                          trigger.metric,
                                          trigger.timeframe,
                                          parseInt(planValue) === 1,
                                          trigger.unit
                                        )
                                      );
                                    case 'General':
                                      return trigger.triggerDescription;
                                    default:
                                      return 'Something Wrong Has Happened. Please Contact Support';
                                  }
                                })
                                .join('. ')
                            ) : (
                              <b>{type} Trigger Not Created</b>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* Goals */}
                    {goalType.map((type, i) => {
                      let filtered = source?.stage[parseInt(planValue) - 1].goals.filter((goal: Goal) => goal.type === type);

                      return (
                        <TableRow>
                          <TableCell width={'250px'} align='left'>
                            <b>{type} Goals</b>
                          </TableCell>
                          <TableCell align='left'>
                            {filtered.length > 0 ? (
                              filtered
                                .map((goal: Goal) => {
                                  if (goal.timeframe && goal.metric) {
                                    return makeGoalDescription(goal.goal, goal.tankType, goal.timeframe, goal.metric);
                                  } else {
                                    return goal.goal;
                                  }
                                })
                                .join('. ')
                            ) : (
                              <b>Goals Not Created</b>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* Actions */}
                    {responseActionTypes.map((type) => {
                      let filtered = source?.stage[parseInt(planValue) - 1].actions.filter((action: Action) => action.type === type);
                      return (
                        <TableRow>
                          <TableCell align='left'>
                            <b>{type} Actions</b>
                          </TableCell>
                          <TableCell align='left'>
                            {filtered.length > 0 ? (
                              filtered
                                .map((action: Action) => {
                                  return `${action.action}`;
                                })
                                .join('. ')
                            ) : (
                              <b>{type} Action Not Created</b>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* Stage Transition Message */}
                    <TableRow>
                      <TableCell align='left'>
                        <b>Stage Transition Message</b>
                      </TableCell>
                      <TableCell align='left'>
                        {source.stage[parseInt(planValue) - 1].stageMessages.length > 0 ? (
                          source?.stage[parseInt(planValue) - 1].stageMessages
                            .map((stageMessage: StageMessage) => {
                              return stageMessage.messages.join(', ');
                            })
                            .join('. ')
                        ) : (
                          <b>Stage Transition Message Not Created</b>
                        )}
                      </TableCell>
                    </TableRow>
                    {/* Indication */}
                    <TableRow>
                      <TableCell align='left'>
                        <b>Indication</b>
                      </TableCell>
                      <TableCell align='left'>
                        {source.stage[parseInt(planValue) - 1].indication.length > 0 ? (
                          source?.stage[parseInt(planValue) - 1].indication
                            .map((indication: Indication) => {
                              return indication.message;
                            })
                            .join('. ')
                        ) : (
                          <b>Indication Not Created</b>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TabContext>
            </Box>
          ) : (
            index !== undefined && (
              <div>
                <Box sx={{ border: '1px solid red' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} variant='fullWidth'>
                        <Tab label='Triggers' value={'Triggers'} />
                        <Tab label='Goals' value={'Goals'} />
                        <Tab label='Actions' value={'Actions'} />
                        <Tab label='Indication' value={'Indication'} />
                        <Tab label='Stage Transition Message' value={'Stage'} />
                        <Tab label='Community Engagement Messages' value={'Internal'} />
                      </TabList>
                    </Box>
                    <TabPanel value={'Triggers'}>
                      <TriggersPage index={index} source={source} setSource={setSource} />
                    </TabPanel>
                    <TabPanel value={'Goals'}>
                      <GoalPage index={index} source={source} setSource={setSource} />
                    </TabPanel>
                    <TabPanel value={'Actions'}>
                      <ActionPage index={index} source={source} setSource={setSource} />
                    </TabPanel>
                    <TabPanel value={'Indication'}>
                      <IndicationPage contextCustomer={contextCustomer} index={index} source={source} setSource={setSource} />
                    </TabPanel>
                    <TabPanel value={'Stage'}>
                      <StageMessagePage contextCustomer={contextCustomer} index={index} source={source} setSource={setSource} />
                    </TabPanel>
                    <TabPanel value={'Internal'}>
                      <InternalManagementPage contextCustomer={contextCustomer} index={index} source={source} currentactive={source.activestage === index} setSource={setSource} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            )
          )}
        </div>
      </Stack>
      {editName && index !== undefined && <EditStageNameModal close={closeEditName} index={index} source={source} setSource={setSource} />}
      {watchhistory && <WatchhistoryModal close={closewatchhistory} source={source} />}
      {editactivestage && <EditactiovestageModal close={closeactivestage} index={index} source={source} setSource={setSource} finduser={finduser} customer={contextCustomer} />}
    </div>
  );
};

export default DroughtManagementPlanTemplate;
