import { ValidBusinessObject } from '@iotv/datamodel';
import { Card, CardContent, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { Place } from '../AddPlace';
import { PlaceStylerComponent } from './CommonStyle';

const debug = process.env.REACT_APP_DEBUG && false;
export type PlaceStyleInFormProps = {
    contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined;
    placeStyler: ValidBusinessObject[] | undefined;
    selectedStyler: ValidBusinessObject | undefined;
    setSelectedStyler: React.Dispatch<React.SetStateAction<ValidBusinessObject | undefined>>;
    values: Place,
    setValues: React.Dispatch<React.SetStateAction<Place>>,
    handleChange: Function,
}

export default function PlaceStyleInForm({ contextCustomer, placeStyler, selectedStyler, setSelectedStyler, values, setValues, handleChange }: PlaceStyleInFormProps) {
    const [radioValue, setRadioValue] = useState('manually');
    // const [selectedStyler, setSelectedStyler] = useState(''); // or the styler linked to the current place, in edit mode


    debug && console.log('what is in the stylers', placeStyler)

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleStylerChange = (event: SelectChangeEvent) => {
        const pk = event.target.value;
        
        setSelectedStyler(placeStyler?.filter((val) => val.pk === pk)[0]);
    }

    const [listIconOpen, setListIconOpen] = useState<boolean>(false)

    return (
        <Card>
            <CardContent>
                <FormControl>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Style</FormLabel> */}
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={radioValue}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="manually" control={<Radio />} label="Manually Enter" />
                        <FormControlLabel value="styler" control={<Radio />} label="Select Styler" />

                    </RadioGroup>
                </FormControl>
                <Grid container>
                    <Grid item>
                        <Typography sx={{ marginTop: '25px' }}>Style</Typography>
                    </Grid>
                    {radioValue === 'manually' ? (<>
                        {/* <Grid item>
                            <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                <TextField id="color" label="color" variant="standard" size="small" onChange={handleChange('color')} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                                <TextField id="icon" label="icon" variant="standard" size="small" onChange={handleChange('icon')} />
                            </FormControl>
                        </Grid> */}
                        {PlaceStylerComponent(values, setValues, handleChange, listIconOpen, setListIconOpen)}

                    </>) : (<>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Styler</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedStyler ? selectedStyler.pk : ''}
                                onChange={handleStylerChange}
                                label="Styler"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {placeStyler?.map((styler) => (
                                    <MenuItem value={styler.pk}>
                                        {styler.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </>)}


                </Grid>
            </CardContent>

        </Card>

    )
}