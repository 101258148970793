import { ValidBusinessObject } from '@iotv/iotv-v3-types';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import AppDao  from '../../../data/AppDao';
import { AdjacentType, ComparisonType, ExpressionFunctionType, GetQueryBodyRequest2, LogicalOperatorType, ObjectTreeSeederProps, ViewObject } from '../../../types/AppTypes';
import { ObjectTreeRoot } from '../../generic/ObjectTree/ObjectTreeRoot';
import config from '../../../config'
import { getQueryBody } from '../../../util/AppData/queryInputizer';
import { QueryOutput } from 'aws-sdk/clients/dynamodb';
import { ErrData } from '@iotv/iotv-v3-types';

const debug = process.env.REACT_APP_DEBUG && false;

const dyn = config.aws.dynamoDB

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: '30vw',
  },

  treeHolder: { 
    height: '50vh',
    flexGrow: 1,
    maxWidth: '30vw',
    overflowY: 'scroll'
  }
});

// this still contains viewObjects from state
export const ObjectTreeSeeder = ( { contextObject, mainAdjacencyQuery, branchAdjacencyQueries, ...others}: ObjectTreeSeederProps) => {
    
    const classes = useStyles()

    const getSeedObjects = async () => {
        if ( contextObject ) {
            const request: GetQueryBodyRequest2 = {
                adjacencyType:  mainAdjacencyQuery.adjacencyType ?? AdjacentType.CHILD,
                ExclusiveStartKey: undefined,
                contextObject,
                filterGroups: [
                  { filters: [
                    { key: dyn.sKey, value: mainAdjacencyQuery.objectTypeId, predicate: ExpressionFunctionType.BEGINS_WITH },
                
                  ],
                  setOperation: LogicalOperatorType.AND
                }
                ],
                limit: 50
             
            }
            if ( mainAdjacencyQuery.filter ) {
              request.filterGroups?.push(    mainAdjacencyQuery.filter)
            }

            const params = getQueryBody(request)

            const setterFn = ( response: ErrData<QueryOutput>) => {
              debug && console.log(`${mainAdjacencyQuery.objectTypeId} reuslts`, response.data?.Items?.length)
    
              if ( response.data?.Items ) {
                  const newViewObjects = response.data?.Items.map( (item) => ({ matchedPrimary: item as ValidBusinessObject, matchedRelatedByPk: [], matchedRelatedBySk: [] } ) as ViewObject )
                  setViewObjects( [ ...viewObjects, ...newViewObjects ])
              }
            }

            const adjacentRes = await AppDao.getMainTableQueryResults(setterFn, params )

          }
       
    }

    useEffect( () => {
        if (contextObject) {
            getSeedObjects()
        }
    }, [ contextObject ])


    const [ viewObjects, setViewObjects ] = React.useState<ViewObject[]>([]);
    debug && console.log('ObjectTreeSeeder, viewObjects',  viewObjects)

      return <div  { ...{  className: classes.treeHolder }}>
         <ObjectTreeRoot { ...{  contextObject, viewObjects, mainAdjacencyQuery, branchAdjacencyQueries, className: classes.root, ...others }}></ObjectTreeRoot>
      </div>
        
}
