import { Geofence, GeofenceType, ValidBusinessObject } from '@iotv/datamodel';
import { Avatar, Card, CardContent, CardHeader, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { getImageUrl } from '../../data/aws/s3/UserBlobs';
import { getLocatableAddress } from '../../data/TypeHelpers';
import { MapCardProps, ObjectCardProps } from '../../types/AppTypes';
import ObjectDetailCard from '../generic/ObjectDetailCard';
import MapCard from '../geo/MapCard';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';

const debug = process.env.REACT_APP_DEBUG && false;

type GeofenceEditorProps = ObjectCardProps
const useStyles = makeStyles((theme) => styles(theme));

export default function GeofenceEditor( props: GeofenceEditorProps  ) {
    const classes = useStyles();
    const { viewObject, transactionFunctions, searchParams, history } = props
    useEffect( () => {
        async function effectUrl() {
          const objectOrString = await getImageUrl(props.viewObject.matchedPrimary)
          if ( typeof objectOrString === 'string') {
            const url = objectOrString;
             debug && console.log('Use Effect has url', url)
            setImageUrl( url)
          }
       
        }
      
        effectUrl()
      
      }, [props.viewObject.matchedPrimary]);
      
      const [ imageUrl, setImageUrl ] = React.useState('');
      /*
      const getPosition = (): GeofencePointsType | undefined => { 
        let derivedPosition: GeofencePointsType | undefined = undefined;
    
        if (IsLocatableByPosition(viewObject.matchedPrimary)) {
          derivedPosition = viewObject.matchedPrimary.getLatLon();
        } else {
          derivedPosition = position
        }
        return derivedPosition 
      }
      */

  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);

  const geoImplementingObject: ValidBusinessObject & GeofenceType | undefined = viewObject.matchedPrimary?.points ? viewObject.matchedPrimary as GeofenceType : undefined

  const mapCardProps: MapCardProps = {
      position: undefined,
      editable: true, 
      //polygons: viewObject.matchedPrimary?.points ? {[viewObject.matchedPrimary.sk]: viewObject.matchedPrimary.points } : undefined
  }

    return ( 
        <Card className={classes.root}>
          <CardHeader
            avatar={
                <NavLink filter = {`${NavSections.THINGVIEW}/${viewObject.matchedPrimary?.type}/${viewObject.matchedPrimary?.id}`} children = {
                  
                <Avatar aria-label="recipe" className={classes.avatar} src= { imageUrl } >
                  {viewObject.matchedPrimary ? viewObject.matchedPrimary.name ? (viewObject.matchedPrimary.name as string).substr(0,1).toUpperCase() : `${viewObject.matchedPrimary.type} ?` : '??' }
                </Avatar>

                }></NavLink>
                
            }
            title= {viewObject.matchedPrimary?.name}
            subheader={viewObject.matchedPrimary?.breed}
          />
           <CardContent>
           { viewObject.matchedPrimary && <div>
            <Grid container spacing={3}>
             <Grid item xs={6}>
              <ObjectDetailCard { ...{matchedPrimary: viewObject.matchedPrimary,  transactionFunctions, searchParams, history}}></ObjectDetailCard> 
              </Grid>
            {
             <Grid item xs={6}>
             <MapCard { ...{transactionFunctions, locatableAddress, geoImplementingObject, viewObjects: [viewObject] , ...mapCardProps, geofences: { [viewObject.matchedPrimary.sk]: viewObject.matchedPrimary as Geofence} } } ></MapCard>
             </Grid>
            }

    
    
            

            </Grid>
            
           </div>
            }
           
           
         
          </CardContent>
          
          
        </Card>
      );
    
}