import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { ObjectTreeProps, ObjectTreeSeederProps } from '../../../types/AppTypes';
import { ObjectTreeSeeder } from './ObjectTreeSeeder';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles({
  root: {
    height: '90%',
    flexGrow: 1,
    maxWidth: '30vw',
  },

  treeHolder: { 
    height: '50vh',
    flexGrow: 1,
    maxWidth: '30vw',
    overflowY: 'auto'
  }
});

// this still contains viewObjects from state
export const ObjectTree = ( { contextObject, mainAdjacencyQuery, branchAdjacencyQueries, userFunctions, ...others }: ObjectTreeProps) => {
    
    const classes = useStyles()

    let element: JSX.Element | null = null;
    
    useEffect( () => {
        if (contextObject) {
    
        }
    }, [ contextObject ])

    if ( contextObject ) {
        const params: ObjectTreeSeederProps= {
            contextObject,
            mainAdjacencyQuery,
            branchAdjacencyQueries,
            multiSelect: true,
            ...others
        };

        element = params.contextObject && <ObjectTreeSeeder { ...{  ...params, className: classes.root }}></ObjectTreeSeeder>
    }

    

      return <div  { ...{  className: classes.treeHolder }}>
         { element }
      </div>
        

      
}