import { getNiceDate, ValidBusinessObject } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import styles from '../../../cosmetics/sharedCardStyles';
import { denseTheme } from '../../../cosmetics/Themes/dense';
import Assembler from '../../../data/Assembler';
import { ComponentMode, pseudoEvent, ViewKeyDefinitionType } from '../../../types/AppTypes';
import { BooleanComponent } from './BooleanComponent';
import { EnumComponent } from './EnumComponent';
import { TextComponent } from './TextComponent';
import { TableRowizerTableCellForObject } from '../TableRowizerTableCellForObject';
import { DateInput } from './DateInput';


const useStyles = makeStyles((theme) => (styles(theme)));


export type InputFieldArbitratorProps = {
    keyToUse: string | number,
    tableOb: ValidBusinessObject,
    viewKeyDefinition: ViewKeyDefinitionType,
    mode: ComponentMode,
    updateKV:  (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent , k: string ) => void,
    functionOverrides?: {
        hasValidationErrors: Function
      },
}


    

    

   export const InputFieldArbitrator = ( { keyToUse, tableOb, viewKeyDefinition, mode, updateKV, functionOverrides }: InputFieldArbitratorProps): JSX.Element => {
        const classes = useStyles(denseTheme);


    const switchOnAttTypeEdit = (tableOb: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        const key = `${tableOb.sk ?? 'newObject'}_${viewKeyDefinition.key}_EditComponent`
        switch(viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return <EnumComponent { ...{ key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV }}></EnumComponent>;
            case AppValueTypes.BOOLEAN: return <BooleanComponent { ...{ key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV } }></BooleanComponent> 
            case AppValueTypes.OBJECT: {
                const embededObject = tableOb[viewKeyDefinition.key]
                return embededObject && viewKeyDefinition.embeddedObjectViewDefinition ? <TableRowizerTableCellForObject {...{ key, keyOnParent: viewKeyDefinition.key, matchedPrimary: embededObject, viewDefinition: viewKeyDefinition.embeddedObjectViewDefinition, mode, updateKV }}></TableRowizerTableCellForObject> : null
            };
            case AppValueTypes.TIMESTAMP_MS: return <DateInput {
                ...{
                    key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV, functionOverrides
                }
            }></DateInput>
            default: return <TextComponent {...{ key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV, functionOverrides }}></TextComponent>
        }
    }

    const switchOnAttTypeView = (tableOb: ValidBusinessObject, viewKeyDefinition: ViewKeyDefinitionType, mode: ComponentMode) => {
        const key = `${tableOb.sk ?? 'newObject'}_${viewKeyDefinition.key}_ViewComponent`
        const value = tableOb[viewKeyDefinition.key]
        const derivedValue = viewKeyDefinition.valueGetter ? viewKeyDefinition.valueGetter(tableOb[viewKeyDefinition.key]) : tableOb[viewKeyDefinition.key]
        switch(viewKeyDefinition.type) {
            case AppValueTypes.ENUM: return  <EnumComponent { ...{ key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV }}></EnumComponent>;
            case AppValueTypes.BOOLEAN: return <BooleanComponent { ...{ key, matchedPrimary: tableOb, viewKeyDefinition, mode, updateKV } }></BooleanComponent> ;
            //case AppValueTypes.OBJECT: return <div>{JSON.stringify(matchedPrimary)}</div>
            case AppValueTypes.OBJECT: {
                const embededObject = tableOb[viewKeyDefinition.key]
                return embededObject && viewKeyDefinition.embeddedObjectViewDefinition ? <TableRowizerTableCellForObject {...{ key, keyOnParent: viewKeyDefinition.key, matchedPrimary: embededObject, viewDefinition: viewKeyDefinition.embeddedObjectViewDefinition, mode, updateKV }}></TableRowizerTableCellForObject> : null
            };
            case AppValueTypes.DATE: return value ? getNiceDate( new Date(value ).valueOf(), { dateStyle: 'short'}) : value;

            default: return derivedValue
        }
    }
        tableOb =  ( tableOb.type ?   Assembler.getInstance(tableOb) : tableOb ) ?? tableOb
        switch(mode) {
            case ComponentMode.VIEW: return <>
                  <TableCell { ...{ key: `${tableOb.sk}_${viewKeyDefinition.key}`, component: "th", scope: "row" }}> {viewKeyDefinition.label} </TableCell>
                  <TableCell { ...{ key: `${tableOb.sk}_${viewKeyDefinition.key}_2`, align: "right" } }> {switchOnAttTypeView(tableOb, viewKeyDefinition, mode)}</TableCell>
            </>
            case ComponentMode.EDIT: return <>
                <TableCell { ...{ key: `${tableOb.sk}_${viewKeyDefinition.key}`, className: classes.tableCellNoPadding, align:"left", style: { width: '100%'} } }>
                    {switchOnAttTypeEdit(tableOb, viewKeyDefinition, mode)}
                </TableCell>
              
            </>
            default: return <div></div>
        
        }
    }


