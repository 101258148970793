import React, { useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, makeStyles, TableFooter, TableHead, ButtonGroup, TablePagination, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { ValidBusinessObject, PathDirection } from '@iotv/datamodel';
import styles from '../../../../cosmetics/sharedCardStyles';
import { ViewDefinition, ComponentMode, ViewKeyDefinitionType, AdjacentType, ListerlizerProps } from '../../../../types/AppTypes';
import { ListerlizerRow } from './ListerlizerRow';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LinkIcon from '@material-ui/icons/Link';
import { Selector } from '../../../../components/select/Selector';
import SimplePopper from '../../../../components/utils/SimplePopper';
import { ValidBusinessObjectList } from '@iotv/iotv-v3-types';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { SortOrder } from '@iotv/iotv-v3-types';
import { useRef } from 'react'
import { BorderStyle } from '@material-ui/icons';

const debug = process.env.REACT_APP_DEBUG && false;


const useStyles = makeStyles((theme) => (styles(theme)));

export function Listerlizer(props: ListerlizerProps) {

  const rowSelectionFirst = props.optionals?.rowSelectionFirst ?? false;
  const { viewObject, contextObject: scopeDefiningContextObject, viewDefinition, adjacentFilter, autoQuery = true, transactionFunctions, functionOverrides, checkedItemSks, userFunctions, sortKeyIn, history, selectorConfigParams, injectedComponents } = { ...props };
console.log({viewDefinition})
  const [mode, setMode] = React.useState(ComponentMode.VIEW)
  const [version, setVersion] = React.useState(0);
  const [showSearch, setShowSearch] = React.useState(false);
  const [selectedObjects, setSelectedObjects] = React.useState([] as ValidBusinessObjectList)
  const [maxItems, setMaxItems] = React.useState(props.maxItems ?? 20)
  const [page, setPage] = React.useState(0);
  const [currentViewObjects, setCurrentViewObjects] = React.useState<ValidBusinessObjectList>([]);
  const [sortKey, setSortKey] = React.useState(sortKeyIn)
  const [sortDirection, setSortDirection] = React.useState<SortOrder>(SortOrder.ASCENDING)
  const [lastEvaluatedKey, _setLastEvaluatedKey] = React.useState<DocumentClient.Key | undefined>(undefined);
  const classes = useStyles();


  const typeToMatch = adjacentFilter.adjacentType === AdjacentType.PARENT ? adjacentFilter.otherObjectType : adjacentFilter.objectType

  const rowObjects: ValidBusinessObject[] = viewObject[adjacentFilter.adjacentType]?.filter((item: ValidBusinessObject) => item.type === typeToMatch)
    .filter((item: ValidBusinessObject) => (!adjacentFilter.edgeFilter) || item.edgeTypeId === adjacentFilter.edgeFilter.edgeTypeId)
    .map((item: ValidBusinessObject) => adjacentFilter.adjacentType === AdjacentType.PARENT ? viewObject.matchedRelatedByPk?.find((otherItem) => otherItem.sk === item.pk) ?? item : item)
    .sort((a: ValidBusinessObject, b: ValidBusinessObject) => sortDirection.valueOf() * (a[sortKey] < b[sortKey] ? -1 : 1))

  // debug && console.log('Listerlizer gets', { viewObject, scopeDefiningContextObject })
  debug && console.log(`${adjacentFilter.objectType} Listerlizer gets ${rowObjects.length}  objects with type filter ${typeToMatch}`)
  debug && console.log(`${adjacentFilter.objectType} Listerlizer was given viewObject`, viewObject)
  const firstItemSk = rowObjects && rowObjects.length > 0 ? rowObjects[0].sk : undefined;
  const scopeDefiningContextObjectSk = scopeDefiningContextObject?.sk
  const matchedPrimarySk = viewObject.matchedPrimary?.sk;
  const rowObjectCount = rowObjects?.length ?? 0;
  const checkedItemCount = checkedItemSks?.length;

  useEffect(() => {
    if (rowObjectCount === 0 && autoQuery) {
      let direction;
      switch (adjacentFilter.adjacentType) {
        case AdjacentType.CHILD: direction = PathDirection.child; break;
        case AdjacentType.PARENT: direction = PathDirection.parent; break;
        case AdjacentType.SUBJECT: console.log('WTF? ', adjacentFilter); direction = PathDirection.child; break;
        default: direction = PathDirection.child;
      }
      transactionFunctions.getAdjacent(viewObject.matchedPrimary as ValidBusinessObject, adjacentFilter.objectType, direction, adjacentFilter?.edgeFilter?.edgeTypeId);
      debug && console.log('Listerlizer used effect to get adjacents on initial render with zero rowObjects')
    } else {
      debug && console.log(`Listerlizer used effect and knowingly did not get adjacents on initial render with ${rowObjects.length} rowObjects and autoQuery: ${autoQuery}`)
    }
  }, [scopeDefiningContextObjectSk, matchedPrimarySk ]);



  useEffect(() => {
    const sliceStart = page * maxItems
    const sliceEnd = page * maxItems + maxItems
    rowObjects && setCurrentViewObjects(rowObjects.slice(sliceStart, sliceEnd));
    debug && console.log('Set Current View Object to ', { page, lenght: rowObjects.length, sliceStart, sliceEnd, currentViewObjects })
  }, [page, rowObjectCount, checkedItemCount, firstItemSk, scopeDefiningContextObjectSk]);




  const containerRef = useRef(null);
  const anchorRef = useRef(null);
  const otherChildrenRef = useRef(null)

  const handleChangePage = (event: unknown, newPage: number) => {
    debug && console.log('Page Change,', newPage)
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxItems(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPaginationRow = () => {
    return <TablePagination
      rowsPerPageOptions={[5, 10, 20, 25, 50, 100, 200]}
      component="td"
      count={rowObjects.length}
      rowsPerPage={maxItems}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  }




  const toggleEditMode = (event: { target: { name: any; checked: any; }; }) => {
    setMode(event.target.checked ? ComponentMode.EDIT : ComponentMode.VIEW);
    setVersion(version + 1);
  };


  const linkSelectedObjects = () => {
    transactionFunctions.linkObjectsToPrimary(viewObject.matchedPrimary as ValidBusinessObject, selectedObjects, adjacentFilter);
    setMode(ComponentMode.VIEW)
    setSelectedObjects([]);
  }


  const getHeaders = (viewDefinition: ViewDefinition) => {
    const headerCells = Object.values(viewDefinition).map((viewKeyDefinition: ViewKeyDefinitionType) => <TableCell
      key={`${viewKeyDefinition.key}_header`}
      onClick={() => setSortKey(viewKeyDefinition.key)}
      component="th" scope="row"> {viewKeyDefinition.label}
    </TableCell>);
    const padding = <TableCell key={`header_padding`}></TableCell>

    return rowSelectionFirst ? [padding, headerCells] : [headerCells, padding]
  }




  const getNewObjectButton = () => {



    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddCircleIcon></AddCircleIcon>}
        onClick={() => { history ? userFunctions.newListObject(viewObject.matchedPrimary as ValidBusinessObject, adjacentFilter, history) : console.log('WARN no history on add new object') } }
      >
        {`Add`}
      </Button>

    )
  }

  const getSearchButton = () => {
    return <Button
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={<SearchIcon></SearchIcon>}
      onClick={() => setShowSearch(!showSearch)}
      disabled={showSearch}
    >
      {`Find`}
    </Button>
  }

  const getLinkButton = () => {
    return <Button
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={<LinkIcon />}
      onClick={() => linkSelectedObjects()}

    >
      {`Link`}
    </Button>
  }


  const setListLinkSelectedItems = (childObjects: ValidBusinessObject[]) => {
    debug && console.log('Request Link got', { childObjects })
    setSelectedObjects(childObjects)
    debug && console.log('CHILD OBJECTS VIA SK', childObjects)
    //childObjects &&  transactionFunctions.linkObjectsToPrimary(viewObject.matchedPrimary as ValidBusinessObject, childObjects); 
  }

  const selectedObjectPresent = () => {
    return selectedObjects.length > 0
  }

  const getControlRow = (rowObject: ValidBusinessObject, typeId: string, lastEvaluatedKey: DocumentClient.Key | undefined) => {

    return <TableRow ref = { containerRef }>
      <TableCell ref = {otherChildrenRef } key={'control-row-padding'} style = { { padding: 0}}  >
        <ButtonGroup >
          {selectorConfigParams.controls.add && getNewObjectButton()}
          {selectorConfigParams.controls.find && getSearchButton()}
          {selectedObjectPresent() && getLinkButton()}

        </ButtonGroup>

      </TableCell>
      <TableCell ref={anchorRef}></TableCell>

    </TableRow>
  }

  const listKey = `${viewObject.matchedPrimary?.sk}_${adjacentFilter.objectType}`; 
  return (
    <div style={{ overflowX: "scroll", overflowY: 'visible' }}>
        { mode === ComponentMode.EDIT && showSearch &&
        <SimplePopper {...{
          containerRef, anchorRef, otherChildrenRef, style: {},
          children:
            <Selector {...{ zIndex: 190, setListLinkSelectedItems, getListLinkSelectedItems: selectedObjects, pageContextObject: viewObject.matchedPrimary as ValidBusinessObject, scopeDefiningContextObject, typeId: adjacentFilter.objectType, lastEvaluatedKey, selectorConfigParams, adjacencyType: AdjacentType.CHILD, exclusiveStartKey: undefined }}></Selector>
          , open: showSearch, disablePortal: true, 
        }}
        />}
      <Table size='small' key={`${listKey}_listTable1`} style={{ overflowX: "scroll" }}>
        <TableBody>
          {mode === ComponentMode.EDIT && getControlRow(viewObject.matchedPrimary as ValidBusinessObject, adjacentFilter.objectType, lastEvaluatedKey)}
 
        </TableBody>
      </Table>
    
      <Table size='small' key={`${listKey}_listTable2`} className={classes.table} style={{
        whiteSpace: "nowrap"
      }} aria-label="simple table" >
        {mode !== ComponentMode.EDIT && <TableHead>
          <TableRow key='tableHead-row'>
            {getHeaders(viewDefinition)}
          </TableRow>
        </TableHead>
        }
        <TableBody key={`${viewObject.matchedPrimary?.sk}_${adjacentFilter.objectType}_${adjacentFilter.objectType}`}>
          {currentViewObjects.map((rowObject: ValidBusinessObject, i: number) => <ListerlizerRow
            key={`${viewObject.matchedPrimary?.sk}_${adjacentFilter.objectType}_${i}`} 
            {...{ viewDefinition, adjacentFilter, matchedPrimary: viewObject.matchedPrimary as ValidBusinessObject, rowObject, mode, transactionFunctions, history, functionOverrides, isSelected: (checkedItemSks?.includes(rowObject.sk)), optionals: props.optionals }}
          />)}

        </TableBody>
        <TableFooter> 
          <TableRow key={`${listKey}-tableFoot-row2`}>
            {rowObjectCount > maxItems && getPaginationRow()}
          </TableRow>

        </TableFooter>
      </Table>


    </div>

  )
}
