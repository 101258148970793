export const addDrawingLayer = (map: google.maps.Map<Element>) => {

    const drawingModes =  [
      google.maps.drawing.OverlayType.MARKER,
      // google.maps.drawing.OverlayType.CIRCLE,
      google.maps.drawing.OverlayType.POLYGON,
      // google.maps.drawing.OverlayType.POLYLINE,
      // google.maps.drawing.OverlayType.RECTANGLE,
    ]

    const drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
      circleOptions: {
        fillColor: "#ffff00",
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes
      }
    }
    const drawingManager = new google.maps.drawing.DrawingManager(drawingManagerOptions)
    drawingManager.setMap(map)
  

    const dataLayer = new google.maps.Data()
    dataLayer.setMap(map);
    dataLayer.setControls([
      // "LineString", 
      'Point', 'Polygon'
    ])

    return { drawingManager, dataLayer };
  }