import { AdjacentType, MapLike, ValidBusinessObject } from "@iotv/iotv-v3-types";
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import React from "react";
import { modelTypes } from "../util/Model/modelTypes";
import { NavSections } from "../components/navigation/NavSections";
import { AppLocalStorage } from "../data/LocalStorage/AppLocalStorage";
import { AppClasses, ContextObjectType } from "../types/AppTypes";
import { useObjectByTypeAndName } from "./useObjectByTypeAndName";
import { LocalStorageType } from "../data/LocalStorage/localStorageType";


export type UseContextObjectSwitchProps = {
    classes: AppClasses,
    navSection: keyof LocalStorageType,
    contextCustomer: ValidBusinessObject | undefined,
    user: ValidBusinessObject | undefined
}

export type UseContextObjectSwitchReturnType = {
    components: {
        contextObjectSelector: JSX.Element | null,
        modeRadioButtons: JSX.Element | null
    },
    state: [ contextObject: ValidBusinessObject | undefined, setContextObject: React.Dispatch<React.SetStateAction<ValidBusinessObject | undefined>>]
}

export const useContextObjectSwitch = ( props: UseContextObjectSwitchProps) => {
    const { classes, navSection, contextCustomer, user } = props

    const [contextObject, setContextObject] = React.useState<ValidBusinessObject | undefined>(undefined)

    const [contextObjectType, setContextObjectType] = React.useState<ContextObjectType>(AppLocalStorage.get(navSection, 'contextObjectType' as never) ?? ContextObjectType.NO_CONTEXT)

    const currentObject = undefined
    const currentObjectTypeId = undefined
    const validTypeIds = modelTypes;

    const handleContextObjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueMap: MapLike<ValidBusinessObject | undefined> = {
          [ContextObjectType.CUSTOMER]: contextCustomer,
          [ContextObjectType.USER]: user,
          [ContextObjectType.NO_CONTEXT]: undefined,
          [ContextObjectType.USER_SELECTED]: userSelectedContextObject
        }
        const selection = e.currentTarget.value as ContextObjectType;
        setContextObjectType(selection);
        setContextObject(valueMap[selection]);
    
        AppLocalStorage.set(NavSections.DATATABLEVIEW, 'contextObjectType', selection)
      }

      const {
        components: componentMap,
        state: {
          currentEntity: [userSelectedContextObject, setCurrentEntity],
          // objectTypeId: [ objectTypeId, setObjectTypeId ]
        }
      } = useObjectByTypeAndName({ contextObject, currentObject, currentObjectTypeId, validTypeIds, adjacencyType: AdjacentType.SUBJECT })
    

      const getRadio = () => <Radio {...{
        size: 'small', classes: {
          root: classes.radio
        },
      }}></Radio>

      const getContextObjectSelector = () =>  contextObjectType === ContextObjectType.USER_SELECTED ? <Grid item {...{ xs: 2 }}>
      {[
        componentMap.objectTypeSelector,
        componentMap.entitySelector,
  
      ]}
        </Grid> : null

      const getModeRadioButtons = () => {
        return (
          <>
          <Grid { ...{ xs: 2}}>
                <FormControl component="fieldset" size='small' >
            <FormLabel component="legend" classes={{ root: classes.radio }}  >Context Object</FormLabel>
            <RadioGroup row aria-label="mode" name="mode" value={contextObjectType} onChange={handleContextObjectChange} className={classes.smaller}>
              <FormControlLabel value={ContextObjectType.USER} classes={{ label: classes.radio }} control={getRadio()} label="User" />
              <FormControlLabel value={ContextObjectType.CUSTOMER} classes={{ label: classes.radio }} control={getRadio()} label="Customer" />
              <FormControlLabel value={ContextObjectType.NO_CONTEXT} classes={{ label: classes.radio }} control={getRadio()} label="None" />
              <FormControlLabel value={ContextObjectType.USER_SELECTED} classes={{ label: classes.radio }} control={getRadio()} label="User Selected" />
            </RadioGroup>
          </FormControl>
          </Grid>
          {
            contextObjectType === ContextObjectType.USER_SELECTED && getContextObjectSelector()
          }
          </>
        );
      }

      

      const useContextObjectSwitchReturn: UseContextObjectSwitchReturnType = {
          components: { contextObjectSelector: getContextObjectSelector(), modeRadioButtons: getModeRadioButtons(), },
          state: [contextObject, setContextObject]
      }

      return useContextObjectSwitchReturn

}