import { Assembler, getZombieInstanceFromPrimaryKey, ObjectHistory, PathDirection } from '@iotv/datamodel-core';
import { AdjacentType, QueryTypes, TraverserQueryPathBody, ValidBusinessObject } from '@iotv/iotv-v3-types';
import { Card, Grid, Typography , makeStyles, TextField, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Table, TableRow, TableCell, Button, Switch, Tooltip, FormControl, InputLabel, IconButton, Box, Chip, OutlinedInput} from '@material-ui/core'
import { Cancel, Delete, Save } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { useStyles } from '../cosmetics/communityStyles'
// import { Trigger } from '@c021/datamodel';
import { DroughtManagementPlan, Community, DroughtManagementPlanStage } from '@c021/datamodel';
import { AdjacentFilter } from 'src/types/AppTypes';
import { getAdjacent2Wrapper } from '../../../data/daoFunctions/daoFunctions';
import { ActionConfirmation } from '../../../components/common/ActionConfirmation';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';

type Stage = {
    stageNumber : number,
    stageTriggers : {
        conditionTriggers : Trigger[],
        statusTriggers : Trigger[]
    },
    responseActions : ResponseAction[],
    goals : Goal[]
}

type Trigger = {
    name : string,
    metric : number,
    timeframe : number
}

type ResponseAction = {
    id : string,
    type : string,
    description : string,
    who : string,
    completedBy : number
}

type Goal = {
    id : string,
    goal : string,
    tankType : string,
    metric : number,
    timeframe : number
}

const emptyStageTemplate = {
    stageNumber : 1,
    stageTriggers : {
        conditionTriggers : [],
        statusTriggers : []
    },
    responseActions : [],
    goals : []
}

const conditionTriggers = [
    "Actual Daily Rainfall",
    "Forecasted Rainfall"
]

const statusTriggers = [
    "Average Fill of Residential Tanks",
    "Average Fill of Community Tanks",
    "Community Days left is",
    "testing"
]

const responseActionTypes = [
    "Awareness",
    "Regulations",
    "Management"
]

const goalTypes = [
    "Reduce Daily Usage",
    "Reduce Weekly Usage",
    "Reduce Daily Usage Per occupant"
]

const tankTypes = [
    "Household",
    "Community"
]

export const DroughtManagementPlanForm = (props : any) => {
    const styles = useStyles()
    const history = useHistory()
    const { transactionFunctions, userFunctions, searchParams, viewObject, contextCustomer,userGeolocation } = props;

    const [ planObject, setPlanObject ] = useState<DroughtManagementPlan>(viewObject.matchedPrimary)

    const [ planName, setPlanName ] = useState<string>("")
    const [ waterLeadTime, setWaterLeadTime ] = useState<number>(0)

    const [ availableCommunities, setAvailableCommunities] = useState<Community[]>([])
    const [ assignedCommunities, setAssignedCommunities ] = useState<Community[]>([])

    const [ planStages, setPlanStages ] = useState<Stage[]>([emptyStageTemplate])
    const [ currentSelectedStage, setCurrentSelectedStage ] = useState<Stage>(planStages[0])

    const [ planNameError, setPlanNameError ] = useState<boolean>(true)
    const [ waterLeadTimeError, setWaterLeadTimeError ] = useState<boolean>(true)

    const [ validPlan, setValidPlan ] = useState<boolean>(false)
    const [ validStage, setValidStage ] = useState<boolean>(false)

    const [ editingPlan, setEditingPlan ] = useState<boolean>(history.location.search === '?edit=true')

    const [ deleteUnlocked, setDeleteUnlocked ] = React.useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false)
    

    //State for new DMP
    const [ conditionTriggerObjs, setConditionTriggerObjs] = useState<Trigger[]>([])
    const [ statusTriggerObjs, setStatusTriggerObjs ] = useState<Trigger[]>([])

    const [ responseActionObjs, setResponseActionObjs ] = useState<ResponseAction[]>([])
    const [ goalObjs, setGoalObjs ] = useState<Goal[]>([])

    const [ conditionTriggerError, setConditionTriggerError ] = useState<boolean>(true)
    const [ statusTriggerError, setStatusTriggerError ] = useState<boolean>(true)
    const [ responseActionError, setResponseActionError ] = useState<boolean>(true)
    const [ goalError, setGoalError ] = useState<boolean>(true)

    /**
     * Hook used to perform initial setup for form, whether that be creating a new plan or editing
     * existing plan. If new plan, just fetches the end communities available to assign a plan to.
     * If editing, also fetches the plan data and loads it into relevant fields
     */
    useEffect(() => {

        /**
         * Fetches a list of end communities linked to the user in some way (includes transitive links)
         * @param object Customer object
         * @returns 
         */
        const fetchCommunities = async (object : ValidBusinessObject) => {
            const simpleTypePath = [
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
                { type: 'Customer', direction: PathDirection.child },
              ]

            const queryBody: TraverserQueryPathBody = {
            type: QueryTypes.directedPath,
            simpleTypePath: simpleTypePath,
            normalize: false,
    
            items: [object] // CHILD should be from Datastore.pathDirection
            };


            const path = '/query/';
            const { err, data } = await ApiGateway.post(path, queryBody);
            return { err, data };
        }

        fetchCommunities(contextCustomer).then(
            ({err, data}) => {
                if(err == null && data != null){
                    const communities : Array<Community> = data.filter((item : ValidBusinessObject) => item.accountType === 'Service Consumer / Reseller')
                    console.log(communities)

                    //First filters end communities by finding communities which do not have children (i.e. has children if commA has pair commA.sk === commB.pk)
                    //Then fitlers again to find end communities which haven't been assigned any DroughtManagementPlans
                    const endCommunities : Array<Community> = communities
                        .filter((community : Community) => communities.find((comm : Community) => community.sk === comm.pk) === undefined)
                        .filter((community : Community) => {return(community.hasDroughtManagementPlan === false || community.hasDroughtManagementPlan === undefined)})

                    setAvailableCommunities(endCommunities)

                    return endCommunities
                }
            }
        ).then((endCommunities) => {

            /**
             * If editing existing plan, then fetch details for plan and populate the relevant fields
             */
            if(editingPlan){
                const getCurrentPlan = async () => {
                    //Fetching all DroughtManagementPlans related to user
                    const currentPlanRes = await getAdjacent2Wrapper({
                        adjacencyType: AdjacentType.CHILD,
                        objectTypeId: 'DroughtManagementPlan',
                        scopeDefinitingContextObject: contextCustomer,
                        includeEdges: false
                    })
                    
                    const planId = history.location.pathname.replace('/DROUGHTMANAGEMENTPLANVIEW/', '')
                    
                    if(currentPlanRes.data?.Items){
                        //Filtering plans to find current plan
                        const currentPlan = currentPlanRes.data.Items.filter(plan => plan.sk === `DroughtManagementPlan:${planId}`)
                        console.log(currentPlan)
                        const currentPlanObj = currentPlan[0] as DroughtManagementPlan


                        //Parallel execution for promises used to fetch plan stages and communities assigned to current plan
                        Promise.all([getAssignCommunities(currentPlanObj)])
                        setPlanName(currentPlanObj.name as string)
                        setWaterLeadTime(currentPlanObj.waterDeliveryLeadTime as number)
                        setPlanObject(currentPlanObj)
                        setPlanStages(currentPlanObj.stages)
                        changePlanStage(1, currentPlanObj.stages)
                    }
                }
                
                /**
                 * Function used to fetch all the communities currently assigned the given drought management plan
                 * @param currentPlan Plan object for the current drought management plan
                 */
                const getAssignCommunities = async (currentPlan : DroughtManagementPlan) => {
                    const currentPlanCommunitiesRes = await getAdjacent2Wrapper({
                        adjacencyType: AdjacentType.CHILD,
                        objectTypeId: 'Customer',
                        scopeDefinitingContextObject: currentPlan,
                        includeEdges: false
                    })
                    
                    if(currentPlanCommunitiesRes.data?.Items){
                        const communities : Community[] = currentPlanCommunitiesRes.data.Items as Community[]

                        //If plan is being edited, assigned communities are also available communities
                        setAvailableCommunities([...endCommunities as Community[], ...communities])
                        setAssignedCommunities([...communities])
                    }
                }
                
                getCurrentPlan()
            }

        })
    }, [])
          
    /**
     * Hook used to check if a plan name and water lead time has been set
     */
    useEffect(() => {
        planName ? setPlanNameError(false) : setPlanNameError(true)
        waterLeadTime > 0 ? setWaterLeadTimeError(false) : setWaterLeadTimeError(true)
    }, [planObject])

    /**
     * Hook used to update planStage object in planStages array when the current object has been edited
     */
    useEffect(() => {
        const updatedPlanStages = [...planStages]
        const currentStageIndex = updatedPlanStages.findIndex(plan => plan.stageNumber === currentSelectedStage.stageNumber)
        updatedPlanStages[currentStageIndex] = currentSelectedStage

        setPlanStages([...updatedPlanStages])
    }, [currentSelectedStage])

    /**
     * Hooks to check each validity of each 'section' and update stage object when changes made
     */
    useEffect(() => {

        if(conditionTriggerObjs.length === 0){
            setConditionTriggerError(true)
        } else {
            conditionTriggerObjs.every(obj => {
                const conditionTriggerError = checkConditionTriggerError(obj.name, 'metric') || checkConditionTriggerError(obj.name, 'timeframe')
                setConditionTriggerError(conditionTriggerError)
                return !conditionTriggerError
            })
        }

        const newStageTriggers = {
            conditionTriggers : [...conditionTriggerObjs],
            statusTriggers : [...statusTriggerObjs]
        }

        setCurrentSelectedStage({...currentSelectedStage, 'stageTriggers' : newStageTriggers})
    
    }, [conditionTriggerObjs])

    useEffect(() => {
        if(statusTriggerObjs.length === 0){
            setStatusTriggerError(true)
        } else {
            statusTriggerObjs.every(obj => {
                const statusTriggerError = checkStatusTriggerError(obj.name, 'metric') || checkStatusTriggerError(obj.name, 'timeframe')
                setStatusTriggerError(statusTriggerError)
                return !statusTriggerError
            })
        }
            
        const newStageTriggers = {
            conditionTriggers : [...conditionTriggerObjs],
            statusTriggers : [...statusTriggerObjs]
        }

        setCurrentSelectedStage({...currentSelectedStage, 'stageTriggers' : newStageTriggers})
    
    }, [statusTriggerObjs])

    useEffect(() => {
        if(responseActionObjs.length === 0){
            setResponseActionError(true)
        } else {
            responseActionObjs.every(obj => {
                const responseActionError = checkResponseActionError(obj.id, 'type') || checkResponseActionError(obj.id, 'description') || checkResponseActionError(obj.id, 'who') || checkResponseActionError(obj.id, 'completedBy')
                setResponseActionError(responseActionError)
                return !responseActionError
            })
        }

        setCurrentSelectedStage({...currentSelectedStage, 'responseActions' : [...responseActionObjs]})
    
    }, [responseActionObjs])

    useEffect(() => {
        if(goalObjs.length === 0){
            setGoalError(true)
        } else {
            goalObjs.every(obj => {
                const goalObjError = checkGoalError(obj.id, 'goal') || checkGoalError(obj.id, 'tankType') || checkGoalError(obj.id, 'metric') || checkGoalError(obj.id, 'timeframe')
                setGoalError(goalObjError)
                return !goalObjError
            })
        }

        setCurrentSelectedStage({...currentSelectedStage, 'goals' : [...goalObjs]})
    
    }, [goalObjs])

    /**
     * Hook to check validity of current stage.
     */
    useEffect(() => {
        console.log(conditionTriggerError || statusTriggerError || responseActionError || goalError)

        if(!(conditionTriggerError || statusTriggerError || responseActionError || goalError)){
            setValidStage(true)
        } else {
            setValidStage(false)
        }
    }, [conditionTriggerError, statusTriggerError, responseActionError, goalError ])

    /**
     * Hook used to check validity of current plan. Plan is valid is name, water lead time are set and current visible stage is valid
     * and plan assigned to at least 1 community
     */
    useEffect(() => {
            planName && waterLeadTime > 0 && validStage && assignedCommunities.length > 0 ? setValidPlan(true) : setValidPlan(false)
    }, [planObject, validStage, assignedCommunities])

    /**
     * Handles creation of new stage. 
     * Adds a new stage to array, updates total stages in plan and changes current stage to new stage 
     */
    const addNewStage = () => {
        const maxStage = planStages.reduce((acc, curr) => Math.max(acc, curr.stageNumber), 0)
        const newStage = {...emptyStageTemplate}
        newStage.stageNumber = maxStage + 1

        if(maxStage < 7){
            setPlanStages([...planStages, newStage])
            changePlanStage(newStage.stageNumber, [...planStages, newStage])
        } else {
            console.log('Cannot add more than 7 stages')
        }
    }

    const updateKV = (k : string, v : any) => {
        const updatedPlanObject = {...planObject, [k] : v}

        switch(k){
            case 'name':
                setPlanName(v)
                break
            case 'waterDeliveryLeadTime':
                setWaterLeadTime(v)
                break
        }
        setPlanObject(updatedPlanObject as DroughtManagementPlan)
    }

    /**
     * Handles deletion of current stage.
     * Presents user with confirm box first, if confirmed, current stage removed and relevant
     * UI elements updated
     */
    const deleteCurrentStage = () => {

        if(window.confirm("Are you sure you want to remove the current Stage?")){
            const updatedPlanStages : Stage[] = []
            
            planStages.forEach(stage => {
                if(stage.stageNumber !== currentSelectedStage.stageNumber){
                    updatedPlanStages.push({...stage})
                }
            })
            
            updatedPlanStages.forEach(stage => {
                if(stage.stageNumber > currentSelectedStage.stageNumber){
                    stage.stageNumber -= 1
                }
            })
            
            setPlanStages([...updatedPlanStages])
            
            let newStageNumber = 1
            
            const replacementStage = updatedPlanStages.find(stage => stage.stageNumber === currentSelectedStage.stageNumber)
            if(replacementStage) {
                console.log('hi')
                newStageNumber = replacementStage.stageNumber
            } else {
                console.log('bye')
                newStageNumber = updatedPlanStages[updatedPlanStages.length-1].stageNumber
            }
            
            changePlanStage(newStageNumber, [...updatedPlanStages])
        }
    }
     
    /**
     * Saves the current plan and performs all the linking required.
     * If editing plan, all communities are unlinked, then the relevant communities are linked so links
     * are saved correctly
     */
    const savePlan = async () => {

        //Linking customer to drought management plan
        const promises : any[] = []
        const adjacentFilter: AdjacentFilter = {
            adjacentType: AdjacentType.CHILD,
            objectType: 'DroughtManagementPlan'
        }

        const fullPlanObject = {...planObject, 'stages' : planStages}

        promises.push(transactionFunctions.saveAndLinkObject(props.contextCustomer.sk, fullPlanObject, adjacentFilter))

        // If editing plan, check if community already has plan assigned, if it does then unlink it.
        if(editingPlan){                
            availableCommunities.forEach(async (community) => {
                if(community.hasDroughtManagementPlan){
                    community.hasDroughtManagementPlan = false
                    await transactionFunctions.saveObject(community)
                    await transactionFunctions.unlink(planObject, community)
                }
            })
        }
            
        //Linking drought management plan to each assigned community
        assignedCommunities.forEach(async (community) => {
            const adjacentFilter: AdjacentFilter = {
                adjacentType: AdjacentType.CHILD,
                objectType: 'Community'
            }
            
            community.hasDroughtManagementPlan = true
            promises.push(transactionFunctions.saveAndLinkObject(fullPlanObject.sk, community, adjacentFilter))
            
            //Figure out what plan stage each assigned community belongs to
            planStages.sort((stage1, stage2) => stage1.stageNumber - stage2.stageNumber)
            let communityStage = planStages[0] //Defaults to first stage
            
            const communityHistoryRes = await getAdjacent2Wrapper({
                adjacencyType: AdjacentType.CHILD,
                objectTypeId: 'ObjectHistory',
                scopeDefinitingContextObject: community,
                includeEdges: true
            })
                
            //Compares community history data to the stage metrics
            if(communityHistoryRes.data?.Items){
                const communityHistory = communityHistoryRes.data.Items[0] as ObjectHistory
                planStages.forEach(stage => {
                    const stageTriggers = [...stage.stageTriggers.conditionTriggers, ...stage.stageTriggers.statusTriggers]

                    for(const trigger of stageTriggers){
                        // let communityMetric = 0
                        let metric = trigger.metric || 0

                        switch (trigger.name){
                            case "Actual Daily Rainfall":
                                break

                            case "Forecasted Rainfall":
                                break
                                
                            case  "Average Fill of Residential Tanks":
                                const averageHouseholdFillLevel = calculateAverageFillLevelFromHistory(communityHistory, 'Household', trigger.timeframe)
                                if (metric > averageHouseholdFillLevel){
                                    communityStage = stage
                                }
                                break

                            case "Average Fill of Community Tanks":
                                const averageCommunityFillLevel = calculateAverageFillLevelFromHistory(communityHistory, 'Community', trigger.timeframe)
                                if (metric > averageCommunityFillLevel){
                                    communityStage = stage
                                }
                                break

                            case "Community Days left is":
                                const communityDaysLeft = community.community_days_left
                                if (metric >  communityDaysLeft){
                                    communityStage = stage
                                }
                                break 
                        }
                    }
                })                    
            }

            community.stageNumber = communityStage.stageNumber
            transactionFunctions.saveObject(community)
        })
            
        await Promise.all(promises)
            
        history.push('/DROUGHTMANAGEMENTPLANVIEW')
    }

    const calculateAverageFillLevelFromHistory = (communityHistory : ObjectHistory, tankType : string, numDays : number) => {
        let usageArray = []

        switch (tankType) {
            case 'Household' :
                usageArray = communityHistory.dailyStateHistory?.average_household_fill_level.slice(-1 * numDays)
                break

            case 'Community' :
                usageArray = communityHistory.dailyStateHistory?.average_community_fill_level.slice(-1 * numDays)
                break
        }

        let average = usageArray.reduce((acc : number, curr : number) => {
            return acc += curr
        }, 0) / numDays

        return average
    }

    /**
     * Updates the UI so that previously filled things are in place
     * @param stageNumber Stage number of plan to change to
     */
    const changePlanStage = (stageNumber : number, stages : Stage[]) => {
        const planStage = stages.find(stage => stage.stageNumber === stageNumber) as Stage

        setCurrentSelectedStage(planStage)
        setConditionTriggerObjs(planStage.stageTriggers.conditionTriggers)
        setStatusTriggerObjs(planStage.stageTriggers.statusTriggers)
        setResponseActionObjs(planStage.responseActions)
        setGoalObjs(planStage.goals)
    }

    const updateAssignedCommunities = (event : any) => {
        const {
            target: { value },
        } = event;

        console.log(value)

        const newlyAssignedCommunities = value.map((name : any) => availableCommunities.find(community => community.name === name))
        console.log(newlyAssignedCommunities)

        setAssignedCommunities([...newlyAssignedCommunities])
    }

    const updateSelectedConditionTriggers = (event : any) => {
        const {
            target: { value },
        } = event;

        const selectedConditionTriggers = value.map((trigger : string) => {
            const existingTrigger = conditionTriggerObjs.find(obj => obj.name === trigger)

            if(!existingTrigger){
                return({
                    name : trigger,
                    metric : 0,
                    timeframe : 0
                })
            } else {
                return existingTrigger
            }
        })

        setConditionTriggerObjs([...selectedConditionTriggers])
    }

    const updateConditionTriggerObj = (value : number, triggerName : string, fieldName : string) => {
        const updatedConditionTriggerObjs = [...conditionTriggerObjs]

        const triggerObj = updatedConditionTriggerObjs.find(obj => obj.name === triggerName)
        if(triggerObj){

            switch(fieldName){
                case 'metric':
                    triggerObj.metric = value
                    break
                case 'timeframe':
                    triggerObj.timeframe = value
                    break
                }
                
            setConditionTriggerObjs([...updatedConditionTriggerObjs])
        }
    }

    const updateSelectedStatusTriggers = (event : any) => {
        const {
            target: { value },
        } = event;

        const selectedStatusTriggers = value.map((trigger : string) => {
            const existingTrigger = statusTriggerObjs.find(obj => obj.name === trigger)

            if(!existingTrigger){
                return({
                    name : trigger,
                    metric : 0,
                    timeframe : 0
                })
            } else {
                return existingTrigger
            }
        })

        setStatusTriggerObjs([...selectedStatusTriggers])
    }

    const updateStatusTriggerObj = (value : number, triggerName : string, fieldName : string) => {
        const updatedStatusObjs = [...statusTriggerObjs]

        const triggerObj = updatedStatusObjs.find(obj => obj.name === triggerName)
        if(triggerObj){

            switch(fieldName){
                case 'metric':
                    triggerObj.metric = value
                    break
                case 'timeframe':
                    triggerObj.timeframe = value
                    break
                }
                
            setStatusTriggerObjs([...updatedStatusObjs])
        }
    }

    const addNewAction = (event : any) => {
        const newAction : ResponseAction = {
            id : uuidv4(),
            type : '',
            description : '',
            who : '',
            completedBy : 0
        }
        
        setResponseActionObjs([...responseActionObjs, newAction])
    }

    const updateResponseAction = (actionID : string, fieldName : string, value : any) => {
        const updatedActionObjs = [...responseActionObjs]

        const actionObj = updatedActionObjs.find(obj => obj.id === actionID)

        if(actionObj){
            switch(fieldName){
                case 'type':
                    actionObj.type = value as string
                    break
                case 'description':
                    actionObj.description = value as string
                    break
                case 'who':
                    actionObj.who = value as string
                    break
                case 'completedBy':
                    actionObj.completedBy = value as number
                    break
            }
        }

        setResponseActionObjs([...updatedActionObjs])
    }

    const addNewGoal = (event : any) => {
        const newAction : Goal = {
            id : uuidv4(),
            goal : '',
            tankType : '',
            metric : 0,
            timeframe : 0
        }

        const newGoals = [...goalObjs]
        setGoalObjs([...newGoals, newAction])
    }

    const updateGoal = (goalID : string, fieldName : string, value : any) => {
        const updatedGoalObjs = [...goalObjs]

        const goalObj = updatedGoalObjs.find(obj => obj.id === goalID)

        if(goalObj){
            switch(fieldName){
                case 'goal':
                    goalObj.goal = value as string
                    break
                case 'tankType':
                    goalObj.tankType = value as string
                    break
                case 'metric':
                    goalObj.metric = value as number
                    break
                case 'timeframe':
                    goalObj.timeframe = value as number
                    break
            }
        }

        setGoalObjs([...updatedGoalObjs])
    }

    const checkConditionTriggerError = (triggerName : string, fieldName : string) => {
        const triggerObj = conditionTriggerObjs.find(obj => obj.name === triggerName)
        if(triggerObj){
            switch(fieldName){
                case 'metric':
                    return triggerObj.metric <= 0
                case 'timeframe':
                    return triggerObj.timeframe <= 0
                }
        }

        return true
    }

    const checkStatusTriggerError = (triggerName : string, fieldName : string) => {
        const triggerObj = statusTriggerObjs.find(obj => obj.name === triggerName)
        if(triggerObj){
            switch(fieldName){
                case 'metric':
                    return triggerObj.metric <= 0
                case 'timeframe':
                    return triggerObj.timeframe <= 0
                }
        }
        return true
    }

    const checkResponseActionError = (actionID : string, fieldName : string) => {
        const responseActionObj = responseActionObjs.find(obj => obj.id === actionID)
        if(responseActionObj){
            switch(fieldName){
                case 'type':
                    return responseActionObj.type === ''
                case 'description':
                    return responseActionObj.description === ''
                case 'who':
                    return responseActionObj.who === ''
                case 'completedBy':
                    return responseActionObj.completedBy <= 0
                }
        }

        return true
    }

    const checkGoalError = (goalID : string, fieldName : string) => {
        const goalObj = goalObjs.find(obj => obj.id === goalID)
        if(goalObj){
            switch(fieldName){
                case 'goal':
                    return goalObj.goal === ''
                case 'tankType':
                    return goalObj.tankType === ''
                case 'metric':
                    return goalObj.metric <= 0
                case 'timeframe':
                    return goalObj.timeframe <= 0
                }
        }

        return true
    }

    
    return (
        <div>
            <Card className={styles.container} variant='outlined'>
                <Grid container className={styles.formBorder}>
                    <Grid item xs={12}>
                        <Typography variant='h5' component='h5' align='center' gutterBottom={true}>
                            Drought Management Plan (Maybe add tooltip for valid plan)
                        </Typography>
                    </Grid>
                    <Grid item container xs={6} className={styles.topOnlyBorder}>
                        <TextField
                            variant='standard'
                            size='small'
                            error={planNameError}
                            fullWidth
                            value={planName}
                            placeholder='Enter Drought Management Plan Name'
                            onChange={e => updateKV('name', e.target.value)}
                            label='Drought Management Plan Name'
                        />
                    </Grid>
                    <Grid item container xs={6} className={styles.topOnlyBorder}>
                        <TextField
                            variant='standard'
                            size='small'
                            error={waterLeadTimeError}
                            type="Number"
                            fullWidth
                            value={waterLeadTime}
                            placeholder='Enter Water Delivery Lead Time'
                            onChange={e => updateKV('waterDeliveryLeadTime', e.target.value)}
                            label='Water Delivery Lead Time'
                        />
                    </Grid>
                    <Grid item container xs={12} className={styles.topOnlyBorder}>
                        Current Stages in Plan : {planStages.map(stage => stage.stageNumber).join()}
                    </Grid>
                    <Grid item xs={6} className={styles.topRightBorder}>
                        <div className={styles.background}>
                            <Typography variant='subtitle1' component='h6' align='left'>
                                Stage
                            </Typography>
                        </div>
                        <Typography>
                            Select Stage
                        </Typography>
                        <Tooltip title="Add new stage">
                            <IconButton onClick={addNewStage} color='primary' disabled={!validStage || planStages.length === 7} ><AddIcon /></IconButton>
                        </Tooltip>
                        <Select
                            disabled={!validStage}
                            value={currentSelectedStage.stageNumber}
                            onChange={e =>  changePlanStage(e.target.value as number, planStages)}
                            variant='standard'
                            className={styles.padding}
                            autoWidth={true}
                            >
                            {planStages.map((stage) => (
                                <MenuItem
                                key={stage.stageNumber}
                                value={stage.stageNumber}
                                >
                                {stage.stageNumber}
                                </MenuItem>
                            ))}
                        </Select>
                        <Tooltip title="Delete Current Stage">
                            <IconButton onClick={deleteCurrentStage} disabled={planStages.length === 1} color='secondary' title="Removes stage from current plan. Confirm and save plan to save changes"><DeleteIcon /></IconButton>              
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={styles.topOnlyBorder}>
                        <div className={styles.background}>
                            <Typography variant='subtitle1' component='h6' align='left'>
                                Communities
                            </Typography>
                        </div>
                        <Typography>
                            Select communities to apply plan to
                        </Typography>
                        <Select
                            onChange={updateAssignedCommunities}
                            value={assignedCommunities.map(community => community.name) || []}
                            multiple
                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected : any) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map((community : any) => (
                                    <Chip key={community} label={community} />
                                    ))}
                                </Box>
                                )}
                        >
                            {availableCommunities.map(community => (
                                <MenuItem
                                    key={community.name}
                                    value={community.name}
                                >
                                {community.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6} className={styles.topRightBorder}>
                        <div className={styles.background}>  
                            <Typography variant='subtitle1' component='h6' align='left'>
                                Triggers
                            </Typography>
                        </div>
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Conditions Triggers</InputLabel>
                        <Select
                            onChange={updateSelectedConditionTriggers}
                            value={conditionTriggerObjs.map(conditionTriggerObjs => conditionTriggerObjs.name) || []}
                            multiple
                            renderValue={(selected : any) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map((trigger : any) => (
                                    <Chip key={trigger} label={trigger} />
                                    ))}
                                </Box>
                                )}
                        >
                            {conditionTriggers.map(condition => (
                                <MenuItem
                                    key={condition}
                                    value={condition}
                                >
                                {condition}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>


                        <Table size='small'>
                            <TableRow>
                                <TableCell align='center'>ㅤ</TableCell> {/** This line contains an invisible character */}
                                <TableCell align='center'>Threshold</TableCell>
                                <TableCell align='center'>Duration</TableCell>
                            </TableRow>
                            {conditionTriggerObjs.map(trigger => {
                                return (
                                    <TableRow>
                                        <TableCell align='center'>{trigger.name}</TableCell>
                                        <TableCell align='center'><TextField type='Number' value={trigger.metric} onChange={e => updateConditionTriggerObj(parseInt(e.target.value), trigger.name, 'metric')} InputProps={{ inputProps: { min: 0 } }} error={checkConditionTriggerError(trigger.name, 'metric')}/></TableCell>
                                        <TableCell align='center'><TextField type='Number' value={trigger.timeframe} onChange={e => updateConditionTriggerObj(parseInt(e.target.value), trigger.name, 'timeframe')} InputProps={{ inputProps: { min: 0 } }} error={checkConditionTriggerError(trigger.name, 'timeframe')}/></TableCell>
                                    </TableRow>
                                )
                            })}
                        </Table>

                        <FormControl fullWidth>
                        <InputLabel>Select Status Triggers</InputLabel>
                        <Select
                            onChange={updateSelectedStatusTriggers}
                            value={statusTriggerObjs.map(trigger => trigger.name) || []}
                            multiple
                            renderValue={(selected : any) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {selected.map((trigger : any) => (
                                    <Chip key={trigger} label={trigger} />
                                    ))}
                                </Box>
                                )}
                        >
                            {statusTriggers.map(status => (
                                <MenuItem
                                    key={status}
                                    value={status}
                                >
                                {status}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>

                        <Table size='small'>
                            <TableRow>
                                <TableCell align='center'>ㅤ</TableCell> {/** This line contains an invisible character */}
                                <TableCell align='center'>Threshold</TableCell>
                                <TableCell align='center'>Duration</TableCell>
                            </TableRow>
                            {statusTriggerObjs.map(trigger => {
                                return (
                                    <TableRow>
                                        <TableCell align='center'>{trigger.name}</TableCell>
                                        <TableCell align='center'><TextField type='Number' value={trigger.metric} onChange={e => updateStatusTriggerObj(parseInt(e.target.value), trigger.name, 'metric')} InputProps={{ inputProps: { min: 0 } }} error={checkStatusTriggerError(trigger.name, 'metric')}/></TableCell>
                                        <TableCell align='center'><TextField type='Number' value={trigger.timeframe} onChange={e => updateStatusTriggerObj(parseInt(e.target.value), trigger.name, 'timeframe')} InputProps={{ inputProps: { min: 0 } }} error={checkStatusTriggerError(trigger.name, 'timeframe')}/></TableCell>
                                    </TableRow>
                                )
                            })}
                        </Table>
                    </Grid>
                    <Grid item xs={6} className={styles.topOnlyBorder}>
                        <div className={styles.background}>  
                            <Typography variant='subtitle1' component='h6' align='left'>
                                Response Actions
                            </Typography>
                        </div>

                        <Button variant='outlined' color='primary' onClick={addNewAction}>Add new Response Action</Button>
                        <Table size='small'>
                            <TableRow>
                                <TableCell align='center'>Type</TableCell>
                                <TableCell align='center'>Description</TableCell>
                                <TableCell align='center'>Who</TableCell>
                                <TableCell align='center'>Completed By</TableCell>
                            </TableRow>
                            {responseActionObjs.map(action => {
                                return (
                                    <TableRow>
                                        <TableCell align='center'>
                                        <Select
                                            onChange={e => updateResponseAction(action.id, 'type', e.target.value)}
                                            value={action.type}
                                            fullWidth
                                            error={checkResponseActionError(action.id, 'type')}
                                        >
                                            {responseActionTypes.map(type => (
                                                <MenuItem
                                                    key={type}
                                                    value={type}
                                                >
                                                {type}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        </TableCell>
                                        <TableCell align='center'><TextField multiline value={action.description} onChange={e => updateResponseAction(action.id, 'description', e.target.value)} error={checkResponseActionError(action.id, 'description')}/></TableCell>
                                        <TableCell align='center'><TextField multiline value={action.who} onChange={e => updateResponseAction(action.id, 'who', e.target.value)} error={checkResponseActionError(action.id, 'who')}/></TableCell>
                                        <TableCell align='center'><TextField type='Number' value={action.completedBy} onChange={e => updateResponseAction(action.id, 'completedBy', e.target.value)} InputProps={{ inputProps: { min: 0 } }} error={checkResponseActionError(action.id, 'completedBy')}/></TableCell>
                                    </TableRow>
                                )
                            })}
                        </Table>
                    </Grid>
                    <Grid item xs={6} className={styles.topRightBorder}>
                        <div className={styles.background}>

                            <Typography variant='subtitle1' component='h6' align='left'>
                                Goals
                            </Typography>
                        </div>
                            <Button variant='outlined' color='primary' onClick={addNewGoal}>Add new Goal</Button>
                            <Table size='small'>
                                <TableRow>
                                    <TableCell align='center'>Goal</TableCell>
                                    <TableCell align='center'>Tank Type</TableCell>
                                    <TableCell align='center'>Threshold</TableCell>
                                    <TableCell align='center'>Duration</TableCell>
                                </TableRow>
                                {goalObjs.map(goal => {
                                    return (
                                        <TableRow>

                                            <TableCell align='center'>
                                                <Select
                                                    onChange={e => updateGoal(goal.id, 'goal', e.target.value)}
                                                    value={goal.goal}
                                                    fullWidth
                                                    error={checkGoalError(goal.id, 'goal')}
                                                >
                                                    {goalTypes.map(type => (
                                                        <MenuItem
                                                            key={type}
                                                            value={type}
                                                        >
                                                        {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Select
                                                    onChange={e => updateGoal(goal.id, 'tankType', e.target.value)}
                                                    value={goal.tankType}
                                                    fullWidth
                                                    error={checkGoalError(goal.id, 'tankType')}
                                                >
                                                    {tankTypes.map(type => (
                                                        <MenuItem
                                                            key={type}
                                                            value={type}
                                                        >
                                                        {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell align='center'><TextField type='Number' value={goal.metric} onChange={e => updateGoal(goal.id, 'metric', e.target.value)} InputProps={{ inputProps: { min: 0 } }} error={checkGoalError(goal.id, 'metric')}/></TableCell>
                                            <TableCell align='center'><TextField type='Number' value={goal.timeframe} onChange={e => updateGoal(goal.id, 'timeframe', e.target.value)} InputProps={{ inputProps: { min: 0 } }} error={checkGoalError(goal.id, 'timeframe')}/></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                    </Grid>
                    <Grid item xs={6} className={styles.topOnlyBorder}>
                        <div className={styles.background}>  
                            <Typography variant='subtitle1' component='h6' align='left'>
                                Automatic Communications
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container xs={12} className={styles.topOnlyBorder} justifyContent="flex-end">
                        <Grid item container xs={3} className={styles.planButtonContainer} justifyContent="space-evenly">
                            <Button variant='contained' startIcon={<Save/>} onClick={savePlan} color='primary' disabled={!validPlan}>Save</Button>
                            <Button variant='contained' startIcon={<Cancel/>} onClick={() => history.goBack()} color='primary'>Cancel</Button>
                                { editingPlan && <ActionConfirmation { ...{ 
                                    open: deleteDialogOpen, onConfirmation: async () => {
                                        assignedCommunities.forEach(community => {
                                            community.hasDroughtManagementPlan = false
                                            transactionFunctions.saveObject(community)
                                        })
                                        await transactionFunctions.deleteObject(planObject as ValidBusinessObject, history)
                                    history.push('/DROUGHTMANAGEMENTPLANVIEW')
                                },
                                redirectUrl: '',
                                header: 'Warning: this step will delete application data',
                                message: 'Are you absolutely sure you want to delete this Drought Management Plan and its data?',
                                setOpen: setDeleteDialogOpen
                                }}/>}
                                <FormControlLabel {...{
                                    key: 'tableFooter-actionCell-B',
                                    control: <Switch {...{
                                    checked: deleteUnlocked,
                                    onChange:  (e: React.ChangeEvent<HTMLInputElement>) => { setDeleteUnlocked(e.target.checked === true) },
                                    name: "Unlock",
                                    inputProps: { 'aria-label': 'secondary checkbox' }
                                    }}
                                    />,
                                    label: deleteUnlocked ? '' : 'Switch to delete'
                                }}/>
                                { deleteUnlocked && <Tooltip title='Deletes this Drought Management Plan and all of its data'><Button
                                variant="contained"
                                onClick={() => setDeleteDialogOpen(true)}
                                startIcon={<Delete />}
                                >
                                Delete
                                </Button></Tooltip>}
                            </Grid>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}
