import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Goal, goalType } from '../DMPExtrasV2';
import TargetGoalsModal from './DroughtPlanModals/TargetedGoalsModal';
import GenericGoalsModal, { makeGoalDescription } from './DroughtPlanModals/GenericGoalsModal';
import { NoneMade } from './ManagementGenerics';

interface GoalPageProps {
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const GoalPage: React.FC<GoalPageProps> = (props: GoalPageProps) => {
  let { index, source, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [goals, setGoals] = useState<Goal[]>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);
  let [type, setType] = useState<string>('');

  useEffect(() => {
    setGoals(source.stage[index].goals);
  }, [index, source]);

  const editGoal = (pos: number, type: string) => {
    setPosition(pos);
    addNewGoal(type);
  };

  const addNewGoal = (type: string) => {
    setCreate(true);
    setType(type);
  };

  const cancel = useCallback(() => {
    setCreate(false);
    setType('');
    setPosition(undefined);
  }, []);

  const update = (newGoal: Goal | undefined, position: number | undefined) => {
    if (newGoal === undefined) {
      if (position !== undefined) {
        goals.splice(position, 1);
      }
    } else if (setSource !== undefined) {
      if (position === undefined) {
        goals!.push(newGoal);
      } else {
        goals[position] = newGoal;
      }

      let newStages = source.stage;
      newStages[index].goals = goals;
      setSource({ ...source, stage: newStages });
      cancel();
    }
  };

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
            {setSource &&
              goalType.map((type) => {
                return (
                  <Button variant='contained' onClick={() => addNewGoal(type)}>
                    <Add />
                    <b>{type} Goal</b>
                  </Button>
                );
              })}
          </Stack>
          {goals && goals.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>
                      <b>Goals</b>
                    </TableCell>
                    <TableCell width={'100px'} align='left'>
                      <b>Current Progress</b>
                    </TableCell>
                    <TableCell width={'100px'} align='center'>
                      <b>Target</b>
                    </TableCell>
                    <TableCell width={'100px'} align='center' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goals.map((goal, i) => {
                    if (goal.type === 'Target') {
                      return (
                        <TableRow>
                          <TableCell align='left'>
                            {goal.timeframe && goal.metric && makeGoalDescription(goal.goal, goal.tankType, goal.timeframe, goal.metric)}
                          </TableCell>
                          <TableCell align='left'>TBD</TableCell>
                          <TableCell align='center'>{`${goal.timeframe} ${goal.metric === 'Litres' ? 'L' : '%'}`}</TableCell>
                          <TableCell align='center'>
                            <Button variant='contained' onClick={() => editGoal(i, goal.type)}>
                              <Edit />
                              <b>Edit</b>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow>
                          <TableCell align='left'>{goal.goal}</TableCell>
                          <TableCell align='left'>N/A</TableCell>
                          <TableCell align='center'>N/A</TableCell>
                          <TableCell align='center'>
                            <Button variant='contained' onClick={() => editGoal(i, goal.type)}>
                              <Edit />
                              <b>Edit</b>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Goal'} />
          )}
        </Stack>
      </div>
      {create && type === 'Target' && <TargetGoalsModal close={cancel} update={update} index={position} existing={goals} />}
      {create && type === 'General' && <GenericGoalsModal close={cancel} update={update} index={position} existing={goals} />}
    </>
  );
};

export default GoalPage;
