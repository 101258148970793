import { BusinessObjectAssembler, Severity, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PasswordIcon from '@material-ui/icons/VpnKey';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import config from '../../../config';
import styles from '../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { AddUserRequest, AdjacentType, ComponentMode, ListDatabaseUsersRequest, ListerlizerProps, MessageOutputType, ObjectCardProps, PartialAdminCreateUserRequest, TabilizerProps, UserManagementViewProps, UserTransactionType, ViewDefinition, ViewObject } from '../../../types/AppTypes';
import { Listerlizer } from '../../factories/Listilizer';
import { Tabilizer } from '../../factories/Tabilizer';
import { NavSections } from '../../navigation/NavSections';
import CognitoGroupList from '../Customers/CustomerUsers/CongitoGroupList';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';

const assembler = new BusinessObjectAssembler();

const userTemplate = { type: 'User' }
const devUserTemplate = { type: 'User', name: 'Barton Stacey', username: 'barton.stacey', email: 'barton.stacey@loraiotlabs.com', temporaryPassword: '_bmr721Subaru@', phoneNumber: '021111111' }

const listUsersQuery = async (fn: (params: any) => void) => {

  try {
    const response = await ApiGateway.post('/system/User/listUserpool', {}, apiRef);
    debug && console.log('UM query results', response)
    if (response.data?.items) {
      fn(response.data.items)
    }
  } catch (e) {
    debug && console.log('Error in UM query', e)
  }
}






function UserManagement(props: UserManagementViewProps ): JSX.Element {     
  //debug && console.log('User Management View got', props)
  const contextUser = props.user;
  const contextCustomer = props.contextCustomer;

  const [selectedUser, setSelectedUser] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [userIncreation, setUserInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [editMode, setEditMode] = React.useState(false)
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);
  const [users, setUsers] = React.useState<ValidBusinessObjectList>([]);
  const [ usersLastEvaluatedKey, setUsersLastEvaluatedKey ] = React.useState<DocumentClient.Key | undefined >(undefined)
  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const routerHistory = useHistory();

  const displayMessage = (content: string, type: MessageOutputType  ) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type})
  }

  const listUsers = async (fn: (params: any) => void, contextRequest: ListDatabaseUsersRequest) => {

    try {
      const response = await ApiGateway.post('/system/User/list', contextRequest, apiRef);
      debug && console.log('UM query results', response)
      if (response.data ) {
        fn(response.data.Items ?? []);
        setUsersLastEvaluatedKey(response.data.LastEvaluatedKey)
      }
    } catch (e) {
      debug && console.log('Error in UM query', e)
    }
  }

  useEffect(() => {
    debug && console.log('used effect to list users')
    listUsersQuery(setUsers)
    if (action === UserTransactionType.CREATE) {
      createNewUserProcess();
    } else {
      setUserInCreation(undefined)
    }
  }, [action]);

  const listUsersWrapper = (limit: number, ExclusiveStartKey: DocumentClient.Key) => {
    if ( props.user?.type === 'User' && contextCustomer?.type === 'Customer') { 
      const contextRequest: ListDatabaseUsersRequest = {
        ExclusiveStartKey: undefined,
        contextUser: props.user as ValidBusinessObject & { type: 'User'},
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer'},
        limit
      }
    listUsers(setUsers, contextRequest)
    } else {
        debug && console.log('did not run list customer query')
    }
  }

  const addToUserPool = async () => {
    if ( contextUser ) {
      const forceChange: boolean = true;
      const { temporaryPassword, ...user } = userIncreation as Partial<ValidBusinessObject> & { temporaryPassword: string, username: string };
      user.username = user.username.toLowerCase();
      const addUserRequest: AddUserRequest = { user, temporaryPassword, contextUser, forceChange, addToGroups: [], makeAdmin: false }
      const response = await ApiGateway.post('/system/User/add', addUserRequest, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar', )
      } else {
        listUsersQuery(setUsers);
        setUserInCreation(undefined);
        routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}`);
        displayMessage(`Added ${user?.name} to userpool`, 'SnackBar')
      }
    }
    

  }

  const removeFromUserPool = async (user: ValidBusinessObject | undefined) => {
    if (user) {
      const response = await ApiGateway.post('/system/User/delete', user, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        listUsersQuery(setUsers);
        setSelectedUser(undefined);
        routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}`);
        displayMessage(`Removed ${user?.name} from userpool`, 'SnackBar')
      }
    }
  }

  const setUserPassword = async (selectedUser: ValidBusinessObject | undefined) => {
    if (selectedUser?.temporaryPassword) {
      const response = await ApiGateway.post('/system/User/setUserPassword', { Username: selectedUser.username, Password: selectedUser.temporaryPassword }, apiRef);
      debug && console.log('UM change password response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {

        displayMessage(`Changed password for ${selectedUser?.name}`, 'SnackBar')
      }

    }
  }


  const createNewUserProcess = () => {
    setSelectedUser(undefined);
    setUserInCreation(config.app.appCode === 'i001' ? devUserTemplate : userTemplate);
  }

  const proxyParent = assembler.getInstanceFromPrimaryKey('Customer:1')
  const viewObject: ViewObject = {
    matchedPrimary: proxyParent,
    matchedRelatedByPk: [],
    matchedRelatedBySk: users
  }

  type UserRequestKeys = keyof PartialAdminCreateUserRequest
  const userTableViewDefinition: ViewDefinition & { [key in UserRequestKeys]: any } = {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    username: { key: 'username', label: 'Username', type: AppValueTypes.STRING, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    email: { key: 'email', label: 'Email', type: AppValueTypes.STRING, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    phoneNumber: {
      key: 'phoneNumber', label: 'Phone Number', type: AppValueTypes.STRING, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined
      , validationRx: action === UserTransactionType.CREATE ?  /\+\d+$/ : undefined
      , failsValidationText: 'phone format +021...'
    },

    temporaryPassword: {
      key: 'temporaryPassword', label: 'Temprorary Password', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined
      , stateFn: undefined, validationRx: action === UserTransactionType.CREATE ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/ : undefined
      , failsValidationText: 'must contain 1 lowercase, 1 uppercase alphabetical character, 1 number, 1 special, more than 6 characters'
    },
  }

  const userListProps: ListerlizerProps = {
    transactionFunctions, userFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'User',
      edgeFilter: undefined
    },
    maxItems: 5,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      }
    },
    viewDefinition: userTableViewDefinition,
    viewObject,
    contextObject: undefined, 
    functionOverrides: {
      selectItem: (sk: string) => {
        setSelectedUser(users.find((user) => user.sk === sk));
        setUserInCreation(undefined);
        routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}`)
      },
      hasValidationErrors: () => {
        const parentHasRendered = (document.querySelector('.userManagementViewGrid'))
        if (parentHasRendered) {
          const hasErrors = (document.querySelector('.Mui-error') !== null)
          setHasValidationErrors(hasErrors)
        }
      }

    },
    checkedItemSks: selectedUser?.sk ? [selectedUser?.sk] : [],
    injectedComponents: []
  }


  const userTableProps: TabilizerProps = {
    ...props,
    matchedPrimary: (selectedUser ?? userIncreation ?? proxyParent) as ValidBusinessObject,
    actions: [action as UserTransactionType],
    viewDefinition: userTableViewDefinition,
    functionOverrides: {
      updateParentComponentItem: (item: ValidBusinessObject) => {
        if (selectedUser) setSelectedUser(item);
        else if (userIncreation) setUserInCreation(item)
      },
      mode: (userIncreation || editMode) ? ComponentMode.EDIT : ComponentMode.VIEW,
      hasValidationErrors: () => {
        const parentHasRendered = (document.querySelector('.userManagementViewGrid'))
        if (parentHasRendered) {
          const hasErrors = (document.querySelector('.Mui-error') !== null);
          debug && console.log('Setting has VErrors', hasErrors)
          setHasValidationErrors(hasErrors)
        } else {
          debug && console.log('parent not rendered')
        }
      }
    },

    injectedComponents: action === UserTransactionType.CREATE
      ? [
        <ButtonGroup key='addUSerBG'>
          <Button
            size="small"
            onClick={() => addToUserPool()}
            disabled={hasValidationErrors}
            startIcon={<GroupAddIcon />}
            variant="outlined"
            color="primary"
          >
            Add to userpool
            </Button>
          <Button
            size="small"
            onClick={() => setUserInCreation({ type: 'User' })}
            disabled={userIncreation && (Object.values(userIncreation).find((val) => val !== 'User' && val !== undefined)) === undefined}
            startIcon={<ClearIcon />}
            variant="outlined"
            color="primary"
          >
            Clear
            </Button>
          <Button onClick={() => { setUserInCreation(undefined); setEditMode(false); routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}`) }}
            size="small"
            startIcon={<CancelIcon />}
            variant="outlined"

          >
          </Button>
        </ButtonGroup>
      ]
      : [
        <ButtonGroup key='newUserBG' disabled={editMode}>

        </ButtonGroup>,
        <ButtonGroup key='editUserBG'>
          <Button
            size="small"
            onClick={() => removeFromUserPool(selectedUser)}
            disabled={!selectedUser}
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="primary"
          >
            Delete
            </Button>
          <Button
            size="small"
            onClick={() => { setEditMode(true) }}
            disabled={!selectedUser || editMode}
            startIcon={<EditIcon />}
            variant="outlined"
            color="primary"
          >
            Edit
            </Button>
          {selectedUser && <Button
            size="small"
            onClick={() => { setUserPassword(selectedUser) }}
            disabled={!editMode}
            startIcon={<PasswordIcon />}
            variant="outlined"
            color="primary"
          >
            Password
            </Button>}
          <Button
            size="small"
            onClick={() => { setSelectedUser(undefined); setEditMode(false); routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}`) }}
            disabled={!editMode && userIncreation && (Object.values(userIncreation).find((val) => val !== 'User' && val !== undefined))}
            startIcon={<CancelIcon />}
            variant="outlined"

          >
          </Button>
        </ButtonGroup>
      ]
  }

  debug && console.log('User in creation before render is ', userIncreation)
  debug && console.log('clear control', userIncreation && Object.values(userIncreation).find((val) => val !== 'User' && val !== undefined) === undefined)
  debug && console.log('has ValidationErrors ', document.querySelector('.Mui-error'))
  return (
      <Grid className='userManagementViewGrid' container spacing={3} >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={1}>
                  <Button
                    size='small'
                    onClick={() => routerHistory.push(`/${NavSections.USER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}`)}
                    disabled={action === UserTransactionType.CREATE}
                    startIcon={<AddIcon />}
                    variant="outlined"
                    color="primary"
                  >
                    User
                  </Button>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card>
            <CardContent>
              <Typography variant="h4" component="h4" gutterBottom>
                Userpool
              </Typography>
              <Listerlizer key={'userList'} {...{ ...userListProps }}></Listerlizer>

            </CardContent>
          </Card>
        </Grid>
        {((action === UserTransactionType.UPDATE && selectedUser) || action === UserTransactionType.CREATE) && <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="h2" gutterBottom>
                User
            </Typography>
              {users.length > 0 && <Tabilizer key={'userInputTable'} {...{ ...userTableProps }}></Tabilizer>}
            </CardContent>
          </Card>
        </Grid>}
        {selectedUser && <Grid item xs={12} lg={6}>
          <CognitoGroupList {...{ ...props, viewObject: { matchedPrimary: selectedUser, matchedRelatedByPk: [], matchedRelatedBySk: [] } }}>
          </CognitoGroupList>
        </Grid>}
      </Grid>
  );

}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(UserManagement)