import React, { useEffect } from "react";
import { getDehydratedAccumulator,  } from "../../data/aws/s3/AccumulationPeriodization";
import { DimensionedPeriodizer, getZombieInstanceFromPrimaryKey, HourlyYearBasedNormalAccumulator, Milliseconds, MonthOfHourlyStructure, ValidBusinessObject } from "@iotv/datamodel-core";
import { ObjectCardProps } from "../../types/AppTypes";
import { AccumulatorClasses, isValidBusinessObject } from "@iotv/iotv-v3-types";
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { AccumulatorTable } from "../common/AccumulatorTable";

export const ObjectDataAccumulationCard = ( { viewObject }: ObjectCardProps ) => {

    useEffect( () => {
        const vob = viewObject.matchedPrimary
        if ( vob ) {
            getDehydratedAccumulator( vob, AccumulatorClasses.HourlyYearBasedNormalAccumulator ).then( ( { err, data } ) => {
                if ( data ) {
                    const accumulator = new HourlyYearBasedNormalAccumulator()
                    accumulator.ingest( data )
                    setAccumulator( accumulator )
                }
            })
        }

    }, [ viewObject.matchedPrimary?.sk ])

    const [ accumulator, setAccumulator ] = React.useState<HourlyYearBasedNormalAccumulator | undefined > (undefined)

    return  accumulator ? <Card>
        <CardContent>
            <Grid container>
                <Grid item { ...{ xs: 12 }}>
                   <AccumulatorTable { ...{ accumulator }}/>
                </Grid>
            </Grid>
            {/* <div>{ JSON.stringify(accumulator) }</div>  */}
        </CardContent>
        </Card>
        : <div>Waiting</div>
} 