import { Assembler, BusinessObjectAssembler, Customer, CustomerAccountType, ErrData, getZombieInstanceFromPrimaryKey, ValidBusinessObject, ValidBusinessObjectList, ValidModelObject } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PasswordIcon from '@material-ui/icons/VpnKey';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import config from '../../../../config';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { AddUserRequest, ComponentMode, GetDatabaseUserRequest, MessageOutputType, PartialAdminCreateUserRequest, SearchParamsType, TabilizerProps, UpdateDatabaseUserRequest, UserManagementViewProps, UserTransactionType, UserView, ViewDefinition } from '../../../../types/AppTypes';
import { getQueryParamsFromHistory } from '../../../../util/Routing/queryParams';
import { Tabilizer } from '../../../factories/Tabilizer';
import { NavSections } from '../../../navigation/NavSections';
import { NavBreadcrumbs } from '../../../utils/Breadcrumbs';
import CognitoGroupList from './CongitoGroupList';
import { getCustomerHierachyQuery, getUserQuery, updateUserQuery } from './userQueries';
import NewUserOptions from './NewUserOptions';
import { Alert, FormControlLabel, Slider, Switch } from '@mui/material';
import crypto from 'crypto'

const debug = process.env.REACT_APP_DEBUG && true;

const apiRef = 'SystemManagement';
const componentClassName = 'CustomerUserView'
const parentHasRendered = () => (document.querySelector(`.${componentClassName}`))
const userTemplate = { type: 'User' }
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/ 

const generatePassword = () => {
  const length = 30
  const wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
  let password: string | undefined = undefined;
  do {
   password = Array.from(crypto.randomFillSync( Buffer.alloc(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('')

  } while ( !passwordRegex.test(password))
  return password
}
 



function CustomerUserView(props: UserManagementViewProps): JSX.Element {
  //debug && console.log('User Management View got', props)

  const searchParams = getQueryParamsFromHistory<SearchParamsType>(props.history)
  const contextUser = props.user;
  const [parentCustomers, setParentCustomers] = React.useState<ValidBusinessObjectList>([]);
  const contextCustomer = searchParams?.contextObjectSK ? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK) : undefined;
  const [selectedUser, setSelectedUser] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [userIncreation, setUserInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [editMode, setEditMode] = React.useState(false)
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);

  // New User groups
  const [selectedGroups, setSelectedGroups] = React.useState<ValidBusinessObjectList>([])
  const [newUserIsAdmin, setNewUserIsAdmin] = React.useState<boolean>(false)

  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const routerHistory = useHistory();

  const requestedUserSk: string | undefined = props.match.params.id
  const emptyViewObject = { matchedPrimary: selectedUser, matchedRelatedByPk: [], matchedRelatedBySk: [] }

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const cancelRedirectUrl = queryString.stringifyUrl({
    url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomer?.sk}`,
    query: {
      tabName: 'Users'
    }
  })

  useEffect(() => {
    debug && console.log('used effect to init user')
    if (action === UserTransactionType.CREATE) {
      createNewUserProcess();
      // getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);
    } else {
      getUserQueryWrapper()
      //  getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);
    }
  }, [action, props.user, requestedUserSk]);

  useEffect(() => {
    if (contextCustomer)
      debug && console.log('used effect to get parent hierarchy')
    getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);

  }, [contextCustomer?.sk]);

  const getUserQueryWrapper = () => {
    if (props.user?.type === 'User' && contextCustomer?.type === 'Customer' && requestedUserSk) {
      const requestedUser = getZombieInstanceFromPrimaryKey(requestedUserSk) as ValidBusinessObject & { type: 'User' };
      const contextRequest: GetDatabaseUserRequest = {
        contextUser: props.user as ValidBusinessObject & { type: 'User' },
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
        requestedUser
      }
      getUserQuery(setSelectedUser, contextRequest)
    } else {
      debug && console.log('did not run list customer query', { selectedUser, contextCustomer, requestedUserSk })
    }
  }

  const addToUserPool = async () => {
    if (contextUser) {
      const { temporaryPassword, ...user } = userIncreation as Partial<ValidBusinessObject> & { temporaryPassword: string };
      const addUserRequest: AddUserRequest = { user, temporaryPassword, contextUser, contextCustomer: contextCustomer as ValidModelObject<'Customer'>, addToGroups: selectedGroups.map( ( g ) => g.name ), makeAdmin: newUserIsAdmin };
      user.username = user.username.toLowerCase();
      const response = await ApiGateway.post('/system/User/add', addUserRequest, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        // getUserQueryWrapper();

        setUserInCreation(undefined);
        routerHistory.push(cancelRedirectUrl);
        displayMessage(`Added ${user?.name} to userpool`, 'SnackBar')
      }
    }
  }

  const removeFromUserPool = async (user: ValidBusinessObject | undefined) => {
    if (user) {
      const response = await ApiGateway.post('/system/User/delete', user, apiRef);
      debug && console.log('Remove response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        getUserQueryWrapper();
        setSelectedUser(undefined);
        routerHistory.push(cancelRedirectUrl)
        displayMessage(`Removed ${user?.name} from userpool`, 'SnackBar')
      }
    }
  }

  const setUserPassword = async (selectedUser: ValidBusinessObject | undefined) => {
    if (selectedUser?.temporaryPassword) {
      const response = await ApiGateway.post('/system/User/setUserPassword', { Username: selectedUser.username, Password: selectedUser.temporaryPassword }, apiRef);
      debug && console.log('UM change password response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else {
        displayMessage(`Changed password for ${selectedUser?.name}`, 'SnackBar')
      }
    }
  }

  const handleMakeAdministratorClick = () => {
    const { isAdminForCustomer, ...userToUpdate } = selectedUser as UserView & { isAdminForCustomer: boolean };
    const makeAdministrator = !isAdminForCustomer;
    updateUserQuery(setSelectedUser, { contextCustomer, contextUser, userToUpdate, makeAdministrator } as UpdateDatabaseUserRequest)
  }

  const createNewUserProcess = () => {
    setSelectedUser(undefined);
    setUserInCreation(Assembler.getInstance({ ...userTemplate, temporaryPassword:  generatePassword()} ));
  }

  const itemToUrlFn = (item: ValidBusinessObject) => `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/${item.type}/${item.sk}`


  type UserRequestKeys = keyof PartialAdminCreateUserRequest
  const userTableViewDefinition: ViewDefinition & { [key in UserRequestKeys]: any } = {

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    username: { key: 'username', label: 'Username', type: AppValueTypes.STRING, editable: action === UserTransactionType.CREATE, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    email: { key: 'email', label: 'Email', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    phoneNumber: {
      key: 'phoneNumber', label: 'Phone Number', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined
      , validationRx: action === UserTransactionType.CREATE ? /\+\d+$/ : undefined
      , failsValidationText: 'phone format +64 21...'
    },
    isAdminForCustomer: { key: 'isAdminForCustomer', label: 'Adminstrator', type: AppValueTypes.BOOLEAN, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },

    temporaryPassword: {
      key: 'temporaryPassword', label:  userIncreation ? 'Temprorary Password' : 'Change Password (optional)', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined
      , stateFn: undefined, validationRx: action === UserTransactionType.CREATE || editMode === true ? passwordRegex : undefined
      , failsValidationText: 'must contain 1 lowercase, 1 uppercase alphabetical character, 1 number, 1 special, more than 6 characters'
    },
  }

  const contextCustomerInstance = parentCustomers.length > 0 ? new Customer(parentCustomers[0]) : undefined;
  const canBeMadeAdmin = contextCustomerInstance?.accountType !== CustomerAccountType.Z && !userIncreation;
  if (!canBeMadeAdmin) {
    delete userTableViewDefinition.isAdminForCustomer
  }




  const userTableProps: TabilizerProps = {
    ...props,
    matchedPrimary: (selectedUser ?? userIncreation ) as ValidBusinessObject,
    actions: [action as UserTransactionType],
    viewDefinition: userTableViewDefinition,
    functionOverrides: {
      updateParentComponentItem: (item: ValidBusinessObject) => {
        if (selectedUser) setSelectedUser(item);
        else if (userIncreation) setUserInCreation(Object.assign(userIncreation, item))
      },
      mode: (userIncreation || editMode) ? ComponentMode.EDIT : ComponentMode.VIEW,
      hasValidationErrors: () => {

        if (parentHasRendered()) {
          const hasErrors = (document.querySelector('.Mui-error') !== null);
          setHasValidationErrors(hasErrors)
        } else {
          debug && console.log('parent not rendered')
        }
      }
    },


    injectedComponents: []
  }

  const controlComponents = action === UserTransactionType.CREATE
    ? [
      <ButtonGroup key='addUSerBG'>
        <Button
          size="small"
          onClick={() => addToUserPool()}
          disabled={hasValidationErrors}
          startIcon={<GroupAddIcon />}
          variant="outlined"
          color="primary"
        >
          Add to userpool
        </Button>
        <Button
          size="small"
          onClick={() => createNewUserProcess()}
          disabled={userIncreation && (Object.values(userIncreation).find((val) => val !== 'User' && val !== undefined)) === undefined}
          startIcon={<ClearIcon />}
          variant="outlined"
          color="primary"
        >
          Clear
        </Button>
        <Button onClick={() => { setUserInCreation(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
          size="small"
          startIcon={<CancelIcon />}
          variant="outlined"

        >
        </Button>
      </ButtonGroup>
    ]
    : [
      <ButtonGroup key='newUserBG' disabled={editMode}>

      </ButtonGroup>,
      <ButtonGroup key='editUserBG'>
        <Button
          size="small"
          onClick={() => removeFromUserPool(selectedUser)}
          disabled={!selectedUser}
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="primary"
        >
          Delete
        </Button>
        <Button
          size="small"
          onClick={() => { setEditMode(true) }}
          disabled={!selectedUser || editMode}
          startIcon={<EditIcon />}
          variant="outlined"
          color="primary"
        >
          Edit
        </Button>
        {selectedUser && editMode && <Button
          size="small"
          onClick={() => { setUserPassword(selectedUser) }}
          disabled={!editMode || hasValidationErrors}
          startIcon={<PasswordIcon />}
          variant="outlined"
          color="primary"
        >
          {!editMode || hasValidationErrors ? 'Enter new valid password to change' : 'Change Password'}
        </Button>}
        {selectedUser && contextCustomer && contextUser && canBeMadeAdmin && editMode && <Button
          size="small"
          onClick={handleMakeAdministratorClick}
          disabled={!editMode}
          startIcon={<SupervisorAccountIcon />}
          variant="outlined"
          color="primary"
        >
          {selectedUser.isAdminForCustomer ? 'Remove Admin rights' : 'Add Admin rights'}
        </Button>}
        <Button
          size="small"
          onClick={() => { setSelectedUser(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
          disabled={!editMode && userIncreation && (Object.values(userIncreation).find((val) => val !== 'User' && val !== undefined))}
          startIcon={<CancelIcon />}
          variant="outlined"

        >
        </Button>
      </ButtonGroup>
    ]


  debug && console.log('Customer User View state ', { selectedUser, userIncreation, userTemplate, contextCustomer, requestedUserSk })

  return (
    <Grid className='CustomerUserView' container spacing={3} >
      {((action === UserTransactionType.UPDATE && selectedUser) || action === UserTransactionType.CREATE) && <Grid item {...{ sm: 12, md: 6 }}>
        <Card>
          <CardContent>
            {contextCustomer && <NavBreadcrumbs {...{ items: parentCustomers ?? [contextCustomer], itemToUrlFn, current: undefined }}></NavBreadcrumbs>}

            <Typography variant="h4" component="h2" gutterBottom>
              User
            </Typography>
            {(selectedUser || userIncreation) && <Grid container >
              <Grid item {...{ sm: 12 }} >
                <Card {...{ children: <CardContent><Tabilizer key={'userInputTable'} {...{ ...userTableProps }}></Tabilizer></CardContent> }}></Card>

              </Grid>

            </Grid>

            }
          </CardContent>
        </Card>
      </Grid>}
      {selectedUser && <Grid item {...{ sm: 12, md: 6 }}>
        <CognitoGroupList {...{ ...props, viewObject: emptyViewObject }}>
        </CognitoGroupList>
      </Grid>}
      {userIncreation && <> <Grid item {...{ sm: 12, md: 6 }} >
        <NewUserOptions {...{ ...props, selectedGroups, setSelectedGroups, newUserIsAdmin, setNewUserIsAdmin, viewObject: emptyViewObject }}></NewUserOptions>
        <Card><CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              {`Make Administrator`}
            </Typography>
            <FormControlLabel {...{
              control: <Switch {...{
                checked: newUserIsAdmin,
                onChange: () => setNewUserIsAdmin(!newUserIsAdmin),
                name: 'Edit',
                inputProps: { 'aria-label': 'secondary checkbox' }
              }}

              />,
              label: newUserIsAdmin ? 'Yes' : 'No'
            }}
            />
             { newUserIsAdmin ?  <Alert severity="warning">
            {`This user will be able to access adminsitration functions`}
          </Alert> : <></>}
          </CardContent></Card>
      </Grid>
      </>
      }
      <Grid item {...{ sm: 12, }}>
        {controlComponents}
      </Grid>
    </Grid>
  );

}



export default withStyles(styles)(CustomerUserView)