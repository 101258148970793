import {
  Box,
  Button,
  Card,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { conditionOptions, DMPTrigger } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface conditionTriggersModalProp {
  close: () => void;
  update: (input: DMPTrigger | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: DMPTrigger[];
}

export const makeConditionTriggerDescription = (conditionTrigger: string | undefined, inputThreshold: number | undefined, inputDuration: number): string => {
  return `${conditionTrigger || '?'} is less than ${inputThreshold || '?'} mm for more than ${inputDuration || '?'} days`;
};

const ConditionTriggersModal: React.FC<conditionTriggersModalProp> = (props: conditionTriggersModalProp) => {
  let { close, update, index, existing } = props;

  let [valid, setValid] = useState<boolean>(false);
  let [conditionTrigger, setConditionTrigger] = useState<string>('');
  let [inputThreshold, setInputThreshold] = useState<number>(0);
  let [inputDuration, setInputDuration] = useState<number>(0);

  let [description, setDescription] = useState<string>('');

  useEffect(() => {
    setDescription(makeConditionTriggerDescription(conditionTrigger, inputThreshold, inputDuration));

    setValid(conditionTrigger !== '' && inputThreshold > 0 && inputDuration > 0);
  }, [conditionTrigger, inputThreshold, inputDuration]);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setConditionTrigger(source.triggerDescription);
      source.metric && setInputThreshold(source.metric);
      source.timeframe && setInputDuration(source.timeframe);
    }
  }, [index, existing]);

  const addStage = (triggerDescription: string, metric: number, timeframe: number, pos: number | undefined) => {
    update({ type: 'Condition', triggerDescription, metric, timeframe, triggerState: false }, pos);
    close();
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create a Condition Trigger
              </Typography>
              <TextField
                // disabled={}}
                label={'Select Condition Trigger'}
                select
                variant='outlined'
                fullWidth
                onChange={(e) => setConditionTrigger(e.target.value)}
                value={conditionTrigger || ''}
                size='small'
              >
                {conditionOptions.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <div className={styles.full}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <i>Input threshold: </i>{' '}
                    <TextField
                      value={inputThreshold !== 0 ? inputThreshold : ''}
                      variant='outlined'
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>is less than</InputAdornment>,
                        endAdornment: <InputAdornment position='start'>mm</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        inputMode: 'numeric',
                        shrink: true,
                      }}
                      style={{ width: '220px' }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (value < 0) {
                          value = 0;
                        }
                        setInputThreshold(value);
                      }}
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <i>Input Duration: </i>
                    <TextField
                      value={inputDuration !== 0 ? inputDuration : ''}
                      variant='outlined'
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>for more than</InputAdornment>,
                        endAdornment: <InputAdornment position='start'>days</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        inputMode: 'numeric',
                        shrink: true,
                      }}
                      style={{ width: '220px' }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (value < 0) {
                          value = 0;
                        }
                        setInputDuration(value);
                      }}
                    />
                  </Stack>
                </Stack>
              </div>
              <TextField
                disabled
                label={'Condition Description'}
                value={description}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                minRows={1}
                multiline
                fullWidth
                style={{ width: '100%' }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    addStage(conditionTrigger, inputThreshold, inputDuration, index);
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default ConditionTriggersModal;
