import { Gateway, GatewayProps, MapLike } from "@iotv/datamodel"
import { Table, TableHead, TableRow, TableCell } from "@material-ui/core"
import React from "react"
import { TableRowizer } from "../../factories/TableParts/TableRowizer"


type GatewayLabelProps = {
    gateway: Gateway
}

const displayAtts: (keyof GatewayProps)[] = ['lastSeen', 'networkKey']

export const GatewayLabel = ({ gateway }: GatewayLabelProps) => {


     return <Table { ...{ size: 'small', style: { zIndex: 200, backgroundColor: 'white', opacity: 0.99}}}>
         <TableHead>
             <TableRow>
                 <TableCell { ...{ colSpan: 1}}>{gateway.eui}</TableCell>
                 
             </TableRow>
         </TableHead>
            <TableRow>
            <TableCell>{ gateway.nwID }</TableCell> 
          
         </TableRow>

     </Table>

}