import { MapLike, ValidBusinessObject } from '@iotv/datamodel';
import React from 'react';
import { ComponentMode, pseudoEvent, TabilizerProps, TransactionFunctionsType, UserTransactionType, ViewDefinition } from '../../types/AppTypes';
import { Tabilizer } from './Tabilizer';

const debug = process.env.REACT_APP_DEBUG && false; 


type TableRowizerTableCellForObjectProps = {
    keyOnParent: string,
    matchedPrimary: ValidBusinessObject,
    viewDefinition: ViewDefinition,
    mode: ComponentMode,
    updateKV:  (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent, k: string ) => void
}

export function TableRowizerTableCellForObject(props: TableRowizerTableCellForObjectProps) {
    debug && console.log('TableRowizerTableCellForObject props:',props)
 

    const { updateKV , keyOnParent
        , matchedPrimary, viewDefinition, mode} = props;

    const objectTablizerProps: TabilizerProps = {
        
        viewDefinition,
        actions: [ UserTransactionType.UPDATE ],
        functionOverrides: {
            mode,
            hasValidationErrors: () => {},
            updateParentComponentItem: ( update: MapLike<any> ) => {
                debug && console.log('TableRowizerTableCellForObject got update', update)
                //const parentUpdateValue = { ...matchedPrimary, [keyOnParent]: update}
                const event: pseudoEvent = { 
                    currentTarget: {
                        type: 'object',
                        value: update
                    }
                }
                updateKV(event, keyOnParent)
                
            }
        },
        matchedPrimary,
        transactionFunctions: {} as TransactionFunctionsType,

        injectedComponents: []


    }

    const table = <Tabilizer {...{...objectTablizerProps}}></Tabilizer>

    switch ( mode ) {
        case ComponentMode.EDIT : return <div {...{ style: { paddingLeft: '2em'} } } ><div {...{ style: { textAlign: 'left'}}}>{ keyOnParent }</div>{table}</div>;
        default: return table
    }
}