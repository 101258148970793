import { ErrData, ValidBusinessObject } from '@iotv/datamodel';
import { NormalizedData } from '../../types/AppTypes';
import ApiGateway from '../aws/api-gateway/ApiGateway';
import normalizer from '../daoFunctions/Normalizer';

const debug = process.env.REACT_APP_DEBUG && true;

  const userTypeRoleQuery = async function (user: ValidBusinessObject, normalizedData: NormalizedData)  {
   
    const path = '/getOne';
    const responseBody: ErrData<ValidBusinessObject> = await ApiGateway.post(path, user) as  ErrData<ValidBusinessObject>;


    debug && console.log('userTypeRoleQuery Res', responseBody);
    // now { err, data } TODO deal with err
    const item = responseBody.data;
    if (item?.pk && item.sk) {
      normalizedData = normalizer(item); 
    }


    return normalizedData;
  }

export { userTypeRoleQuery };
