import { GeoPoint } from '@iotv/datamodel';
import { Button, Grid, makeStyles, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { mapStyles } from '../../../cosmetics/MapCardStyles';
import styles from '../../../cosmetics/sharedViewStyles';
import { googleBoundsToCellLatLngs } from './mapfns/conversion';
import { getS2PointsByBounds } from './mapfns/getS2Points';





const debug = process.env.REACT_APP_DEBUG && false;

const componentClassName = 'DeveMapInfo';


const useStyles = makeStyles((theme) => {
  return {
      ...mapStyles(theme),
      ...styles(theme)
  }
});

export type DeveMapInfoProps ={  map: google.maps.Map<Element>,
  
}



function DevMapInfoBase( { map  }:  DeveMapInfoProps  ): JSX.Element {
  const localClasses = useStyles();

    const [ force, setForce ] = React.useState<number>(0);

    const [ datalayerGeoJson, setDatalayerGeoJson ] = React.useState<object | undefined> (undefined)

    let datalayerFeatures: number = 0;
    
    map.data.forEach( (feature) => datalayerFeatures ++ )

     useEffect( () => {
       debug && console.log('DevMapInfoBase used effect', datalayerFeatures)
     }, [datalayerFeatures, force])
        debug && console.log('DevMapInfoBase render', datalayerFeatures)

     const bounds = googleBoundsToCellLatLngs(map.getBounds()) ?? { bounds: 'no bounds'};

    return  <div { ...{ className: clsx( localClasses.controlPanel, componentClassName), style: { left: '40vw'}}}>
    
      <Typography>Dev Info</Typography>
      <Grid container>
        <Grid item { ...{ xs: 12}}>
            <Table { ...{ size: 'small'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Attribue</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                     <TableRow>
                        <TableCell>Bounds</TableCell>
                        <TableCell><textarea { ...{ style: {width: '10em'}, id: 'boundsCell'}}>{JSON.stringify(bounds, null, 1)}</textarea> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Datalayer Features </TableCell>
                        <TableCell>{datalayerFeatures}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>map </TableCell>
                        <TableCell { ...{ id: 'infoTarget'}}></TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                <TableRow>
                      <TableCell>
                        <Button { ...{variant: 'outlined', onClick: ()=> getS2PointsByBounds(map, ( s2Points: GeoPoint[] ) => {} ) } }>Get s2 Points</Button>
                      </TableCell>
                    
                    </TableRow>
                  <TableRow>
                      <TableCell>
                        <Button { ...{variant: 'outlined', onClick: ()=> setForce(force + 1)} }>Update</Button>
                      </TableCell>
                    
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Button { ...{variant: 'outlined', onClick: ()=> { map.data && map.data.toGeoJson( (feature: object) => setDatalayerGeoJson( feature )) } } } >Get Datalayer</Button>
                      </TableCell>
                    
                    </TableRow>
                  {document.querySelector('textarea#geoJsonString') !== null && <TableRow>
                    <TableCell>
                      <Button { ...{variant: 'outlined', onClick: () => { 
                        const el = document.querySelector('#geoJsonString') ;
                        if (el) {
                          const textarea = el as HTMLTextAreaElement;
                          textarea.select();
                          document.execCommand('copy', true)
                          debug && console.log('copied')
                        }
                      } 
                    }  } >Copy GeoJSON</Button>
                    </TableCell>
                  
                  </TableRow>}
                </TableFooter>
            </Table>
            { datalayerGeoJson && <textarea { ...{ style: {width: '100%'}, id: 'geoJsonString'}}>{JSON.stringify(datalayerGeoJson, null, 1)}</textarea> }
        </Grid>
      </Grid>
    </div>


    
}

const DeveMapInfo =  withStyles(styles)(DevMapInfoBase)

export { DeveMapInfo };
