import { ValidBusinessObject, ValidModelObject } from '@iotv/datamodel-core';
import { ViewObject, ObjectCardProps } from '../../../../../../types/AppTypes';
import { Add, AssistantDirection, Delete, Edit, AutoAwesome } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Radio, RadioGroup, FormControlLabel, FormControl, Stack, Button, Tabs, Tab } from '@mui/material';
import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface droughtManagementPlanTemplateProp {
    contextCustomer: ValidBusinessObject;
    source: ValidBusinessObject;
    finduser: ObjectCardProps;
    setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const modalRoot = document.querySelector('#modal-root');

const Stagehistory: React.FC<droughtManagementPlanTemplateProp> = (props: droughtManagementPlanTemplateProp) => {
    let { contextCustomer, source, finduser, setSource } = props;
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Choose wisely');


    console.log("histroy", source)

    const [name, setName] = useState<string>('');
    console.log('getthename', source)
    if (modalRoot === null) {
        console.log('Root for modal is not working.');
        return null;
    }

    function createData(
        changedata: string,
        name: string,
        beforechange: string,
        afterchange: string,
        changereason: string
    ) {
        return { changedata, name, beforechange, afterchange, changereason };
    }

    type rowdata = {
        changedata: string,
        name: string,
        beforechange: string,
        afterchange: string,
        changereason: string,
    };

    var rows: rowdata[] = [];

    type DMPHistory = {
        name: string,
        beforechange: string,
        afterchange: string,
        time: string
        changereason: string
    };

    source.stagechangehistory?.map((stage: DMPHistory) => (
        rows.unshift(createData(stage.time.split('GMT')[0], stage.name, stage.beforechange, stage.afterchange, stage.changereason ? stage.changereason : "No reason"))
    ))

    type Sendhistorytype = {
        content: string[],
        name: string[],
        type: string,
        time: string
    };

    type messagerowdata = {
        changedata: string,
        type: string,
        content: Sendhistorytype[],
    };

    function createmessageData(
        changedata: string,
        type: string,
        content: Sendhistorytype[],
    ) {
        return { changedata, type, content };
    }

    var historyrow: messagerowdata[] = [];

    console.log("history112", historyrow)

    function messagehistorycolor(type: string) {
        var output = "Cornsilk"
        if (type === "Community engagement message") {
            output = "Cornsilk"
        } else if (type === "Change stage") {
            output = "AliceBlue"
        }

        return output
    }


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setHelperText(' ');
        setError(false);
    };

    function findvalue(message: Sendhistorytype[]) {

        if (value === "stage" && message[0].type === "Change stage") {
            return message
        } else if (value === "community" && message[0].type === "Community engagement message") {
            return message
        }
        return false
    }


    if (value == "") {
        source.sendmessagehistory?.forEach((message: Sendhistorytype[]) => {
            if (message && message.length > 0) {
                historyrow.unshift(createmessageData(message[0].time.split('GMT')[0], message[0].type, message));
            }
        })
    }

if (value != "all") {
    var afterfilter = source.sendmessagehistory?.filter(findvalue)
    if (Array.isArray(afterfilter)) {
        afterfilter.map((message: Sendhistorytype[]) => (
            historyrow.unshift(createmessageData(message[0].time.split('GMT')[0], message[0].type, message))
        ))
    }
} else {
    if (Array.isArray(source.sendmessagehistory)) {
        source.sendmessagehistory.map((message: Sendhistorytype[]) => (
            historyrow.unshift(createmessageData(message[0].time.split('GMT')[0], message[0].type, message))
        ))
    }
}



    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h1 >{"Stage change history"}</h1>
                    <Card>
                        <div>
                            <Table aria-label="a dense table">
                                <TableHead style={{ backgroundColor: "AliceBlue" }}>
                                    <TableRow>
                                        <TableCell>Stage change time</TableCell>
                                        <TableCell align="right">Name</TableCell>
                                        <TableCell align="right">From stage</TableCell>
                                        <TableCell align="right">To stage</TableCell>
                                        <TableCell align="right">Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {rows.map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{ backgroundColor: "AliceBlue" }}

                                        >
                                            <TableCell component="th" scope="row">
                                                {row.changedata}
                                            </TableCell>
                                            <TableCell align="right">{row.name}</TableCell>
                                            <TableCell align="right">{row.beforechange}</TableCell>
                                            <TableCell align="right">{row.afterchange}</TableCell>
                                            <TableCell align="right">{row.changereason}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={6}>

                    <div>
                        <h1 >{"Message history"}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="all"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="stage" control={<Radio />} label="Change stage" />
                                <FormControlLabel value="community" control={<Radio />} label="Community engagement message" />
                            </RadioGroup>
                        </FormControl>
                        <div>
                            <Table aria-label="a dense table">

                                {historyrow.map((row) => (
                                    <div>
                                        <Card style={{ backgroundColor: messagehistorycolor(row.type) }}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={4} sx={{ fontWeight: 'bold' }}>
                                                    <p >{row.changedata}</p>
                                                </Grid>
                                                <Grid item xs={8} sx={{ fontWeight: 'bold' }}>
                                                    <p>Message type: {row.type}</p>
                                                </Grid>
                                            </Grid>
                                            {row.content.map((cont, index) => (
                                                <Card style={{ backgroundColor: messagehistorycolor(row.type) }}>
                                                    <div>

                                                        <Accordion
                                                            expanded={expanded === row.changedata + index}
                                                            onChange={handleChange(row.changedata + index)}
                                                            style={{ backgroundColor: messagehistorycolor(row.type) }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1bh-content"
                                                                id="panel1bh-header"
                                                            >
                                                                <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 'bold' }}>
                                                                    Message {index + 1}
                                                                </Typography>
                                                                <Typography sx={{ color: "text.secondary", fontWeight: 'medium' }}>
                                                                    Receiver: {cont.name.map((name) => (name + ", "))}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    {cont.content.map((content) => (
                                                                        <div>
                                                                            {content}
                                                                        </div>

                                                                    ))}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                </Card>

                                            ))}
                                        </Card>
                                        <p></p>
                                    </div>

                                ))}

                            </Table>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Stagehistory;
