import { useEffect } from "react"
import { useHistory } from "react-router-dom";
import { NavSections } from "../../../components/navigation/NavSections";
import { c021NavSections } from "../navigation/c021_NavSections";

const RedirectView = (props : any) => {

    useEffect(() => {

      console.log(props.contextCustomer)

      if(props.contextCustomer){

        let redirectURL = `${NavSections.THINGSVIEW}/Tank`

        switch(props.contextCustomer.accountType){
          case "Venture":
            redirectURL = `${c021NavSections.COMMUNITYVIEW}`
            break
          case "Reseller":
            redirectURL = `${c021NavSections.COMMUNITYVIEW}`
            break
          case "Service Consumer / Reseller":
            redirectURL = `${c021NavSections.COMMUNITYVIEW}`
            break
          case "Service Consumer":
            redirectURL = `${NavSections.THINGSVIEW}/Tank`
            break
        }

        router.push(redirectURL)
      }

    }, [props.contextCustomer])
    const router = useHistory();

    console.log("REDIRECTINGGGG")

      return (
        <>    
        </>
      );
    
    
}

export default RedirectView