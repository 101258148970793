import { BusinessObjectAssembler, ErrData, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { GetDatabaseUserRequest, UpdateDatabaseUserRequest, UserView } from '../../../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = 'SystemManagement';

export const getUserQuery = async (fn: (params: any) => void,  getUserRequest: GetDatabaseUserRequest) => {
  try {
    const response = await ApiGateway.post('/system/User/get', getUserRequest, apiRef);
    debug && console.log('getUser query results', response)
    if (response.data?.Item) {
      const mutatedItem = {...response.data.Item, isAdminForCustomer: response.data?.Metadata?.isAdminForCustomer}
      fn(mutatedItem)
    }
  } catch (e) {
    debug && console.log('Error in getUser query', e)
  }
}

export const updateUserQuery = async (fn: (params: any) => void,  updateUserRequest: UpdateDatabaseUserRequest) => {
  try {
    const response = await ApiGateway.post('/system/User/update', updateUserRequest, apiRef);
    debug && console.log('updateUserQuery results', { updateUserRequest, response })
    if (response.data?.Item) {
      const mutatedItem = {...response.data.Item, isAdminForCustomer: response.data?.Metadata?.isAdminForCustomer}
      fn(mutatedItem)
    }
  } catch (e) {
    debug && console.log('Error in updateUserQuery query', e)
  }
}

export const getCustomerHierachyQuery = async (fn: (params: any) => void, contextUser: UserView, contextCustomer: ValidBusinessObject | undefined) => {
  if (contextCustomer) {
    debug && console.log('Doing hierarchy query')
    try {
      const response: ErrData<ValidBusinessObjectList> = await ApiGateway.post(`/system/Customer/parents`, { contextUser, contextCustomer }, apiRef);
      if (response.data) {
        debug && console.log('hierarchy query res', response.data)
        fn(response.data)
      } else {
        throw new Error(`Problem in HierarchQuery: ${JSON.stringify(response, null, 1)}`)
      }
    } catch (e) {
      debug && console.log('Error in Hierachy query', e)
    }


  }
}