import config from '@iotv/config';
import {
  camelToDisplay,
  DeviceEnabledTypesMap, ErrData,
  getDeviceEnabledTypes,
  GetThingConnectableDevicesRequest,
  GetThingConnectableDevicesResponse,
  getZombieInstanceFromPrimaryKey,
  isValidBusinessObject,
  Milliseconds, ValidBusinessObjectList,
  ValidModelObject
} from '@iotv/datamodel';
import { AppValueTypes, DeviceTypes, ErrDataReducer, MapLike } from '@iotv/iotv-v3-types';
import {
  Badge,
  Button,
  CardContent, FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Cancel, Delete, Save } from '@material-ui/icons';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Styles } from '@mui/styles';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import React, { useEffect } from 'react';
// import { ThingShadowState } from '@iotv/datamodel';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid/';
//import styles from '../cosmetics/sharedCardStyles';
import AppDao, { } from '../data/AppDao';
import Assembler from '../data/Assembler';
import { getAdjacent2Wrapper, GetAdjacentRequest, link, save } from '../data/daoFunctions/daoFunctions';
import {
  AdjacentType, ComponentMode, DeviceProvisionCardProps, MessageOutputType,
  Severity,
  UserTransactionType,
  ValidBusinessObject,
  ViewDefinition
} from '../types/AppTypes';
import { DialogWrapper } from './common/DiaglogWrapper';
import { getViewDefintionFromObject } from './factories/AttributeDefinitions';
import { Tabilizer } from './factories/Tabilizer';
import { NavSections } from './navigation/NavSections';

const styles =  (theme: Theme) => ({

  deviceAllocationTGrid: {
    padding: 100,
    backgroundColor: theme.palette.primary.light,
    '& .MuiTypography-root': {
      color: theme.palette.background.default
    },
  },

  deviceAllocationTable: {
    padding: 100,
    backgroundColor: theme.palette.background.default,
    '& .MuiTypography-root': {
      color: theme.palette.primary.dark
    },
    '& .MuiTableCell-root': {
      padding: '1em', borderStyle: 'none', 
  },
  },
 } )

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = config.app.appName;

const selectCommon: TextFieldProps = {
  select: true,
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
};

const deviceEnabledTypes: DeviceEnabledTypesMap = getDeviceEnabledTypes(process.env.REACT_APP_PROJECT_CODE ?? 'i301');

const getMandatoryThingType = (detTypeId: string | undefined) => {
  const mandatoryThingMap: MapLike<string | undefined> = {
    Tank: 'House',
    default: undefined,
  };
  return detTypeId ? mandatoryThingMap[detTypeId] ?? mandatoryThingMap.default : undefined;
};

export default function DeviceProvisioningCard(props: DeviceProvisionCardProps, filterGraphKeys?: string[]) {
  const { user, viewObject, transactionFunctions, userFunctions, userSelectedGroup, searchParams, match, adjacentFilter, contextCustomer, history, optionals } =
    props;

  const history_redirect = useHistory();
  //debug && console.log('DeviceProvisioningCard got props', props)
  const routerHistory = useHistory();
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();
  /*
  const { components: {
    contextObjectSelector,
    modeRadioButtons,
  },
    state: [contextObject, setContextObject]
  }: UseContextObjectSwitchReturnType = useContextObjectSwitch({ classes, contextCustomer, user, navSection: NavSections.DEVICEEUI_PROVISIONVIEW })
  */
  debug && console.log('deviceEnabledTypes', deviceEnabledTypes);

  const providedDeviceEui = match.params?.deviceEui;
  const providedDeviceZombie = providedDeviceEui ? getZombieInstanceFromPrimaryKey(`Device:${providedDeviceEui}`) : undefined;

  const [deviceEnabledType, setDeviceEnabledType] = React.useState<string | undefined>(Object.keys(deviceEnabledTypes)?.[0]);
  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypes[] | undefined>(Object.values(deviceEnabledTypes)?.[0].deviceTypes);
  const [selectedDeviceType, setSelectedDeviceType] = React.useState<DeviceTypes | undefined>(Object.values(deviceEnabledTypes)?.[0]?.deviceTypes?.[0])
  const [deviceid, setDeviceId] = React.useState<string | undefined>(undefined);
  const [hasMandatoryThing, setHasMandatoryThing] = React.useState<boolean>();
  const [thingInCreation, setThingInCreation] = React.useState<ValidBusinessObject | undefined>(undefined);
  const [sometimeMandatoryThingInCreation, setSometimesMandatoryThingInCreation] = React.useState<ValidBusinessObject | undefined>(undefined);
  const [sometimesMandatoryThing, setSometimesMandatoryThing] = React.useState<ValidBusinessObject | undefined>(undefined); // eg house in tank
  const [selectedMandatoryThingSk, setSelectedMandatoryThingSk] = React.useState<string | undefined>(undefined);

  const [sometimesMandatoryThings, setSometimeMandatoryThings] = React.useState<ValidBusinessObjectList>([]);
  const [thingsList, setThingsList] = React.useState<ValidBusinessObjectList>([]);
  const [ExclusiveStartKey, setExclusiveStartKey] = React.useState<DocumentClient.Key | undefined>(undefined);
  const [selectedThingSk, setSelectedThingSk] = React.useState<string | undefined>(undefined);
  const [deviceToThingEdge, setDeviceToThingEdge] = React.useState<ValidBusinessObject | undefined>();
  const [gettingValidDevices, setGettingValidDevices] = React.useState<boolean>(false);
  const [validDeviceList, setValidDeviceList] = React.useState<ValidBusinessObjectList>([]);

  const userSk = user?.sk;
  const contextCustomerSk = contextCustomer?.sk;

  debug && console.log(`DeviceProvisioningCard`, contextCustomer);



  const displayMessage = (content: string, type: MessageOutputType, severity?: Severity, duration?: number) => {
    const id = uuidv4();
    userFunctions.setUserMessage({ id, content, label: content, type, severity: severity });
    setTimeout(() => userFunctions.dismissUserMessage({ id, content, label: content, type, severity: severity }), duration ?? 3000);
  };

  const getValidDevicesQuery = async (setterfn: (result: DocumentClient.QueryOutput) => void, request: GetThingConnectableDevicesRequest) => {
    if (deviceEnabledType && user) {
      setGettingValidDevices(true);
      const res: ErrData<GetThingConnectableDevicesResponse> = await AppDao.apiGateway.post('/device/getConnectableDevices/', request, apiRef)
      debug && console.log('getValidDevicesQuery request with deviceType got res with request', {res, request })
      if (res.data) {
        debug && console.log('getValidDevicesQuery', { params: request, result: res.data })
        const connectableDevices = res.data
        displayMessage(`We found ${connectableDevices.length} devices that can be connected`, 'MessageBar', Severity.info, Milliseconds.SECOND * 5)
        setValidDeviceList(connectableDevices)
      } else if (res.err?.message) {
        displayMessage(res.err.message, 'MessageBar', Severity.error, Milliseconds.SECOND * 5)
      }

      setGettingValidDevices(false);
    } else {
      debug && console.log('Could not make query request up');
    }
  };




  useEffect(() => {
    const setterFn = (result: DocumentClient.QueryOutput) => {
      if (result.Items) {
        setValidDeviceList(result.Items as ValidBusinessObjectList);
      }
    };

    debug &&
      console.log(`DeviceProvisioningCard usedEffect to get mandatoryType and setValidDeviceList on`, {
        contextObjectSk: contextCustomer?.sk,
        userSk: user?.sk,
        providedDeviceEui,
        contextCustomerSk,
        deviceEnabledType,
        selectedDeviceType
      });

     
    const mandatoryTypeId = mandatoryThingTypeId;
    if (contextCustomer) {
      if (contextCustomer && deviceEnabledType && mandatoryTypeId) {
        const getMandatoryThingRelatedToContext: GetAdjacentRequest = {
          scopeDefinitingContextObject: contextCustomer,
          adjacencyType: AdjacentType.CHILD,
          objectTypeId: mandatoryTypeId,
          includeEdges: false,
        };
        getAdjacent2Wrapper(getMandatoryThingRelatedToContext).then(({ err, data }) => {
          debug && console.log('getMandatoryThingRelatedToContext', { data, err, getMandatoryThingRelatedToContext });
          data?.Items && setSometimeMandatoryThings(data.Items as ValidBusinessObjectList);
        });
      }

      debug && console.log( 'getValidDevicesQuery request with deviceType', selectedDeviceType)

      getValidDevicesQuery(setterFn, { contextCustomer: contextCustomer as ValidModelObject<'Customer'>, deviceEUI: providedDeviceZombie?.id, possibleDeviceEnabledTypeIds: deviceEnabledType ? [deviceEnabledType] : undefined, deviceType: selectedDeviceType });
    }
  }, [contextCustomer?.sk, user?.sk, providedDeviceEui, contextCustomerSk, deviceEnabledType, selectedDeviceType]);

  useEffect(() => {
    const getRelatedThings = async (deviceEnabledType: string, referenceObject: ValidBusinessObject) => {
      const relatedThingsParams: GetAdjacentRequest = {
        adjacencyType: AdjacentType.CHILD,
        includeEdges: false,
        objectTypeId: deviceEnabledType,
        scopeDefinitingContextObject: referenceObject,
        ExclusiveStartKey: undefined,
      };

      const relatedThingsRes = await getAdjacent2Wrapper(relatedThingsParams);
      debug && console.log('relatedThingsParam', { relatedThingsParams, relatedThingsRes });
      if (relatedThingsRes.data?.Items) {
        const things = relatedThingsRes.data.Items;
        setThingsList(things as ValidBusinessObjectList);
        setExclusiveStartKey(relatedThingsRes.data.LastEvaluatedKey);
      } else {
        displayMessage(`${relatedThingsRes.err?.message ?? '???'}`, 'MessageBar');
      }
    };

    if (deviceEnabledType && contextCustomerSk) {
      const mandatoryThingZombie = selectedMandatoryThingSk ? Assembler.getInstanceFromPrimaryKey(selectedMandatoryThingSk) : undefined;

      const referenceObject: ValidBusinessObject = mandatoryThingZombie ?? contextCustomer ?? (user as ValidBusinessObject);
      getRelatedThings(deviceEnabledType, referenceObject);
    } else {
      debug && console.log('No device enabled type');
    }
  }, [deviceEnabledType, contextCustomerSk]);

  const thingListHash = thingsList.map((t) => t.id).join();
  const sometimesManadatoryThingsListHash = sometimesMandatoryThings.map((t) => t.id).join();

  useEffect(() => {
    debug && console.log('DeviceProvisioningCard usedEffect on things hashes');
  }, [thingListHash, sometimesManadatoryThingsListHash]);


  const handleChangeDeviceEnabledType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== 'no-op') {
      const deviceEnabledType = e.target.value
   
      if ( deviceEnabledType ) {
       
        const deviceTypes = deviceEnabledTypes[deviceEnabledType].deviceTypes
        setDeviceEnabledType(deviceEnabledType)
        setDeviceTypes( deviceTypes );
        setSelectedDeviceType( deviceTypes[0])
      }
    }

  }

  const saveProvisionDetails = async () => {
    debug && console.log('deviceid', JSON.stringify(deviceid));
    debug && console.log('selectedThingSk', selectedThingSk);
    const deviceObject = validDeviceList.find((item) => item.id === deviceid);

    const thingZombie = thingsList.find((x) => x.sk === selectedThingSk);
    debug && console.log('objects to save', { deviceObject, thingZombie });
    if (deviceObject && thingZombie) {
      const saveRes = ErrDataReducer(await Promise.all([save(deviceObject), save(thingZombie)]));

      if (saveRes.err) {
        displayMessage(saveRes.err.message, 'MessageBar', Severity.error);
      } else {
        const edgeTypeId = undefined;
        const [thingLinkRes, customerLinkRes] = await Promise.all([link(thingZombie, deviceObject, edgeTypeId), link(contextCustomer, thingZombie)]);
        if (customerLinkRes.err) {
          displayMessage(`failed to link to customer ${customerLinkRes.err.message}`, 'MessageBar', Severity.error);
        }

        if (thingLinkRes.err) {
          debug && console.log('thing link err', thingLinkRes);
          displayMessage(thingLinkRes.err.message, 'MessageBar', Severity.error);
        } else if (thingLinkRes.data instanceof Array) {
          debug && console.log('thing link res', thingLinkRes);
          const [tankRes, deviceEdgeRes] = thingLinkRes.data;
          userFunctions.addObjectsToState(thingLinkRes.data);
          setDeviceToThingEdge(deviceEdgeRes);
          displayMessage(`Linked ${deviceObject.eui} to ${thingZombie?.name ?? deviceEnabledType}`, 'SnackBar');
          if (sometimesMandatoryThing) {
            const madSaveRes = await save(sometimesMandatoryThing);
            if (madSaveRes.err) {
              displayMessage(`Failed to save ${sometimesMandatoryThing.name}: ${madSaveRes.err.name}`, 'MessageBar', Severity.error);
            } else {
              const [madThingToCustomerLinkRes, madThingLinkRes] = await Promise.all([
                link(contextCustomer, sometimesMandatoryThing),
                link(sometimesMandatoryThing, thingZombie),
              ]);
              if (madThingLinkRes.err) {
                debug && console.log('mandatory think link err', madThingLinkRes.err.message);
                displayMessage(madThingLinkRes.err.message, 'MessageBar', Severity.error);
              } else {
                displayMessage(`Linked ${thingZombie.name} to ${sometimesMandatoryThing.name}`, 'SnackBar');
              }
            }
          }
        }
      }
    }
  };

  const cancelViewEditMandatoryThings = () => {
    history_redirect.goBack();
  };

  const changeMandatoryThing = (mandatoryThingSk: string) => {
    debug && console.log('Change mandatory thing event', mandatoryThingSk);
    const mandatoryThingObject = getZombieInstanceFromPrimaryKey(mandatoryThingSk);
    if (isValidBusinessObject(mandatoryThingObject)) {
      setSometimesMandatoryThing(mandatoryThingObject);
      setSelectedMandatoryThingSk(mandatoryThingObject.sk);
    }
  };

  const getViewDefintionFromContextThing = (contextThing: ValidBusinessObject) => {
    const vd = getViewDefintionFromObject(contextThing);

    const base: ViewDefinition = {
      name: { key: 'name', type: AppValueTypes.STRING, editable: true, label: 'Name' },
    };

    const filteredVd = Object.fromEntries(
      Object.entries(vd).filter(([k, v]) => {
        let pass: boolean = false;
        if (v.editable === true) {
          pass = true;
        }
        return pass;
      })
    );

    return Object.assign(base, filteredVd);
  };

  const mandatoryThingTypeId = getMandatoryThingType(deviceEnabledType);
  const isValidDevice = validDeviceList.find((item) => item.eui === deviceid);

  return (
    <Card className={'nothing'/*classes.root*/}>
      <CardContent>
        <Grid>
          <Typography variant='h6' component='h6'>
            {`${providedDeviceEui ? `Provision Device ${providedDeviceEui}` : 'Setting Up Tank and Device '} `}
          </Typography>
          <Card>
            <Grid container {...{ className: classes.deviceAllocationTGrid }}>
              <Grid item>
                <Typography {...{ variant: 'h4' }}>{`Set up ${deviceEnabledType}${mandatoryThingTypeId ? ` and ${mandatoryThingTypeId}` : ``} for ${contextCustomer.name
                  }`}</Typography>
              </Grid>

              {/* debug && user?.name.startsWith('RusXXs') && <Grid item {...{ xs: 12, wrap: 'nowrap' } }>
                    <FormGroup { ...{ row: true }}>
                    <FormControl>
                      { modeRadioButtons }
                    </FormControl>
                    <FormControl>
                      { contextObjectSelector }
                    </FormControl>
                </FormGroup>
  </Grid> */}
              <Grid item {...{ xs: 12 }}>
                <Table {...{ className: classes.deviceAllocationTable }}>
                  <TableBody {...{ key: 'deviceEnabledTypeSelectRow' }}>
                    {Object.keys(deviceEnabledTypes).length > 1 && (
                      <TableRow>
                        <TableCell {...{ key: 'deviceEnabledTypeSelectRow_c1' }}>
                          <TextField
                            {...{
                              ...selectCommon,
                              id: 'outlined-basic',
                              label: 'Device Enabled Type',
                              value: deviceEnabledType ?? 'no-op',
                              onChange: handleChangeDeviceEnabledType,
                            }}
                          >
                            {Object.keys(deviceEnabledTypes).length === 0
                              ? [
                                <MenuItem key={'no dets'} value={'nothing'}>
                                  No Device Enabled Types found
                                </MenuItem>,
                              ]
                              : [
                                <MenuItem key={'no-op'} value={'no-op'}>
                                  choose Device Enabled Type
                                </MenuItem>,
                                (Object.keys(deviceEnabledTypes).map((det: string) => (
                                  <MenuItem key={det} value={det}>
                                    {det}
                                  </MenuItem>
                                ))),
                              ]}
                          </TextField>
                        </TableCell>
                      </TableRow>

                    )}
                    {
                      deviceTypes && deviceTypes.length > 1 && selectedDeviceType ? <TableRow>
                        <TableCell>
                          <RadioGroup {...{
                            row: true,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string ) => setSelectedDeviceType( parseInt( value )),
                            value: selectedDeviceType,
                            name: "radio-buttons-group"
                          }}

                          >
                            {deviceTypes.map((deviceType) => <FormControlLabel {...{ value: deviceType, control: <Radio />, label: camelToDisplay(DeviceTypes[deviceType]) }} />)}

                          </RadioGroup>
                        </TableCell>
                      </TableRow> : null
                    }

                    {mandatoryThingTypeId && (
                      <TableRow {...{ key: 'hasSMT_row' }}>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              {...{
                                label: hasMandatoryThing ? `has ${mandatoryThingTypeId}` : `no ${mandatoryThingTypeId}`,
                                control: <Switch {...{ checked: hasMandatoryThing, onChange: () => setHasMandatoryThing(!hasMandatoryThing) }} />,
                              }}
                            />
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    )}
                    {hasMandatoryThing && mandatoryThingTypeId && (
                      <TableRow {...{ key: 'r1' }}>
                        <TableCell {...{ key: 'r1c1' }}>
                          <TextField
                            {...{
                              ...selectCommon,
                              id: 'outlined-basic',
                              label: mandatoryThingTypeId,
                              value: selectedMandatoryThingSk ?? 'no-op',
                              onChange: (e) => e.target.value !== 'no-op' && changeMandatoryThing(e.target.value),
                            }}
                          >
                            {sometimesMandatoryThings.length === 0
                              ? [<MenuItem key={'nothing'} value={'nothing'}>{`No ${mandatoryThingTypeId}s found`}</MenuItem>]
                              : [
                                <MenuItem key={'no-op'} value={'no-op'}>
                                  {mandatoryThingTypeId}
                                </MenuItem>,
                                sometimesMandatoryThings.map((thing: ValidBusinessObject) => (
                                  <MenuItem key={thing.id} value={thing.sk}>
                                    {thing.name}
                                  </MenuItem>
                                )),
                              ]}
                          </TextField>
                        </TableCell>
                        <TableCell {...{ key: 'r1c2' }}>
                          <div>
                            {!selectedMandatoryThingSk && mandatoryThingTypeId ? (
                              <>
                                <Button
                                  {...{
                                    onClick: () => {
                                      const newSometimesMandatoryThing = Assembler.getInstance({ type: mandatoryThingTypeId as string });
                                      setSometimesMandatoryThingInCreation(newSometimesMandatoryThing);
                                      debug && console.log('setSometimesMandatoryThingInCreation to ', newSometimesMandatoryThing);
                                    },
                                  }}
                                  variant='contained'
                                  color='primary'
                                >
                                  {`Create New ${mandatoryThingTypeId}`}
                                </Button>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  {...{
                                    onClick: () => {
                                      setSelectedMandatoryThingSk(undefined);
                                      setSometimesMandatoryThing(undefined);
                                    },
                                  }}
                                >
                                  <Delete></Delete>
                                </IconButton>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow {...{ key: 'r2' }}>
                      <TableCell {...{ key: 'r2c1' }}>
                        <TextField
                          {...{
                            ...selectCommon,
                            id: 'thing_select',
                            label: deviceEnabledType,
                            value: selectedThingSk ? selectedThingSk : 'nothing',
                            onChange: (e) => e.target.value !== 'nothing' && setSelectedThingSk(e.target.value),
                            disabled: thingsList.length === 0,
                            error: !selectedThingSk,
                          }}
                        >
                          {[
                            thingsList.length === 0 ? (
                              [<MenuItem key={'nothing'} value={'nothing'}>{`No ${deviceEnabledType} found`}</MenuItem>]
                            ) : (
                              <MenuItem key={'no-op'} value={'no-op'}>
                                {`Choose ${deviceEnabledType}`}
                              </MenuItem>
                            ),
                            thingsList.map((thing: ValidBusinessObject) => (
                              <MenuItem key={thing.id} value={thing.sk}>
                                {thing.name}
                              </MenuItem>
                            )),
                          ]}
                        </TextField>
                      </TableCell>
                      <TableCell {...{ key: 'r2c2' }}>
                        {deviceEnabledType && !selectedThingSk ? (
                          <>
                            <Button
                              {...{ onClick: () => setThingInCreation(Assembler.getInstance({ type: deviceEnabledType })) }}
                              variant='contained'
                              disabled={mandatoryThingTypeId && hasMandatoryThing ? (selectedMandatoryThingSk ? false : true) : false}
                              color='primary'
                            >
                              {`Create New ${deviceEnabledType}`}
                            </Button>
                          </>
                        ) : (
                          <>
                            <IconButton
                              {...{
                                onClick: () => {
                                  setSelectedThingSk(undefined);
                                },
                              }}
                            >
                              <Delete></Delete>
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow {...{ key: 'r3' }}>
                      <TableCell {...{ key: 'r3c1' }}>
                        <TextField
                          {...{
                            ...selectCommon,
                            id: 'device_select',
                            label: selectedThingSk ? 'Select Device EUI' : undefined,
                            value: deviceid ? deviceid : 'nothing',
                            onChange: (e) => {
                              e.target.value !== 'nothing' && setDeviceId(e.target.value);
                            },
                            disabled:
                              mandatoryThingTypeId && hasMandatoryThing
                                ? selectedMandatoryThingSk && selectedThingSk
                                  ? false
                                  : true
                                : selectedThingSk === undefined,
                            error: !selectedThingSk || validDeviceList.length === 0,
                            helperText: validDeviceList.length === 0 ? 'No devices available' : '',
                          }}
                        >
                          {validDeviceList.length === 0 ? (
                            <MenuItem key={'nothing'} value={'nothing'}>{`No ${'Devices'} found`}</MenuItem>
                          ) : (
                            <MenuItem key={'no-op'} value={'no-op'}>
                              {`Choose ${'Device EUI'}`}
                            </MenuItem>
                          )}
                          {validDeviceList.map((device) => (
                            <MenuItem key={device.id} value={device.id}>
                              {device.id}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <Badge {...{ badgeContent: validDeviceList.length, color: validDeviceList.length === 0 ? 'error' : 'primary', showZero: true }}>
                          {validDeviceList.length === 0 ? <SentimentVeryDissatisfiedIcon /> : <SentimentSatisfiedAltIcon />}
                        </Badge>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Button
                          variant='outlined'
                          //style={{ background: "#ff8566" }}
                          onClick={() => cancelViewEditMandatoryThings()}
                          startIcon={<Cancel />}
                        //size={'small'}
                        >
                          Cancel
                        </Button>
                        {thingsList.length > 0 && (
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={!selectedThingSk}
                            //style={{ background: "#1aff8c" }}
                            onClick={() => saveProvisionDetails()}
                            startIcon={<Save />}
                          //size={'small'}
                          >
                            Save
                          </Button>
                        )}
                        {deviceToThingEdge && selectedThingSk && (
                          <Button
                            variant='contained'
                            disabled={!selectedThingSk || deviceid === undefined}
                            style={{ background: 'blue', color: 'white' }}
                            onClick={() =>
                              routerHistory.push(
                                `/${NavSections.THINGVIEW}/${deviceEnabledType}/${selectedThingSk.replace(`${deviceEnabledType}:` ?? 'xxx', '')}`
                              )
                            }
                            startIcon={<Save />}
                          //size={'small'}
                          >
                            {`View ${deviceEnabledType}`}
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{gettingValidDevices && <CircularProgress />}</TableCell>
                    </TableRow>
                    {debug && false && (
                      <>
                        <TableRow>
                          <TableCell>{`Things ${thingsList.length}`}</TableCell>
                          <TableCell>{thingListHash} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`SMTs ${sometimesMandatoryThings.length}`}</TableCell>
                          <TableCell>{sometimesManadatoryThingsListHash} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Devices ${validDeviceList.length}`}</TableCell>
                          <TableCell>{contextCustomer.name} </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </CardContent>
      {thingInCreation && (
        <DialogWrapper
          {...{
            open: thingInCreation !== undefined,
            setClosed: () => setThingInCreation(undefined),
            header: `Create ${deviceEnabledType} for ${contextCustomer.name}`,

            wrappedElement: (
              <Tabilizer
                {...{
                  ...props,
                  transactionFunctions: {
                    ...transactionFunctions,
                    saveObject: (vob: ValidBusinessObject) => {
                      debug && console.log('Save thing in creation function got', vob);
                      setThingsList([...thingsList, vob]);
                      setThingInCreation(undefined);
                      setSelectedThingSk(vob.sk);
                    },
                  },
                  actions: [UserTransactionType.CREATE],
                  matchedPrimary: thingInCreation,
                  viewDefinition: getViewDefintionFromContextThing(thingInCreation),
                  functionOverrides: {
                    mode: ComponentMode.EDIT,
                    externalMode: ComponentMode.EDIT,
                    updateParentComponentItem: () => { },
                    hasValidationErrors: () => false,
                  },
                }}
              ></Tabilizer>
            ),
          }}
        ></DialogWrapper>
      )}

      {sometimeMandatoryThingInCreation && (
        <DialogWrapper
          {...{
            open: sometimeMandatoryThingInCreation !== undefined,
            setClosed: () => setSometimesMandatoryThingInCreation(undefined),
            header: `Create ${mandatoryThingTypeId} for ${contextCustomer.name}`,

            wrappedElement: (
              <Tabilizer
                {...{
                  ...props,
                  transactionFunctions: {
                    ...transactionFunctions,
                    saveObject: (vob: ValidBusinessObject) => {
                      setSometimesMandatoryThing(vob);
                      setSometimeMandatoryThings([...sometimesMandatoryThings, vob]);
                      setSometimesMandatoryThingInCreation(undefined);
                      setSelectedMandatoryThingSk(vob.sk);
                    },
                  },
                  actions: [UserTransactionType.CREATE ],
                  matchedPrimary: sometimeMandatoryThingInCreation,
                  functionOverrides: {
                    mode: ComponentMode.EDIT,
                    externalMode: ComponentMode.EDIT,
                    updateParentComponentItem: () => { },
                    hasValidationErrors: () => false,
                  },

                  viewDefinition: getViewDefintionFromContextThing(sometimeMandatoryThingInCreation),
                }}
              ></Tabilizer>
            ),
          }}
        ></DialogWrapper>
      )}
    </Card>
  );
}
