import { Button, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { DispatchFunctionsType, ValidBusinessObject, ViewObject } from '../../types/AppTypes';



const debug = process.env.REACT_APP_DEBUG && false;


type AlertControlTableRowProps = { viewObject: ViewObject, rowObject: ValidBusinessObject, transactionFunctions: DispatchFunctionsType, classes: any }

export default function AlertControlTableRow(props: AlertControlTableRowProps)  {
    const { viewObject, rowObject, transactionFunctions, classes } = props;
    const [currentDelay, setCurrentDelay] = React.useState(0);
    const [currentDelayMultiplier, setCurrentDelayMultiplier] = React.useState(3600);
    const [openPostponer, setOpenPostponer] = React.useState(false);

    useEffect(() => {
      debug && console.log('Alert control row used effect')
  }, [])




    if (viewObject.matchedPrimary && ! (viewObject.matchedPrimary.subscribed)) viewObject.matchedPrimary.subscribed = {}
    const subscribed = viewObject.matchedPrimary?.subscribed;
   
    const isSubscribed = ( objectId: string ) => {
      const subscribedVal = subscribed[objectId];
      return ( subscribedVal && subscribedVal !== false);
    }

    const getDelay = (delay: number, multiplier: number) => {
      let d = new Date();
      d.setSeconds(d.getSeconds() + delay * multiplier);
      return d.valueOf();
  };

    const isPostponed = ( objectId: string ) => {
      const subscribedVal = subscribed[objectId];
      return ( subscribedVal && subscribedVal.state === 'postponed' && subscribedVal.timestamp > new Date().valueOf());
    }

    const getPostponedTime = ( objectId: string ) => {
      let date = undefined
      const timestamp = subscribed[objectId]?.timestamp;
      if (timestamp) date = new Date(timestamp);
      return date?.toLocaleDateString();
    }

    

    const setSubscribed = (objectId: string, subscribedBool: Boolean) => {
      debug && console.log('Set subscried', { objectId, subscribedBool})
      if (viewObject.matchedPrimary) {
        const user = viewObject.matchedPrimary;
        user.subscribed[objectId] = subscribedBool;
        transactionFunctions.saveObject(user);
      }
    }

    const savePostponeSettings = (objectId: string) => {
      const timestamp = getDelay( currentDelay, currentDelayMultiplier);
      if (viewObject.matchedPrimary) {
        const user = viewObject.matchedPrimary;
        user.subscribed[objectId] =   {state: 'postponed', timestamp }
        transactionFunctions.saveObject(user);
      }
    
    }

    const setDelay = (objectId: string, delayValue: number) => {
       debug && console.log('Set delay to ', delayValue)
      setCurrentDelay(delayValue)
    }

    const setDelayUnits = (objectId: string, delayValue: number) => {
       debug && console.log('Set delay to ', delayValue)
      setCurrentDelayMultiplier(delayValue)
    }

    const getDelayMenuItems = () => {
      const delayOptions = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
      ]
      const menuItems = delayOptions.map( ( option: number ) => <MenuItem value={option }>{ option === 0 ? 'No Delay' : option}</MenuItem> );
      return menuItems
    }

    const getDelayUnitMenuItems  = () => {
      const delayUnitOptions  = 
       { 'Seconds': 1, 'Minutes': 60, 'Hours': 3600, 'Days': 86400, 'Week': 604800}
      const menuItems = Object.entries(delayUnitOptions).map( ( [label, value]: [string, number] ) => <MenuItem value = {value} >{ label}</MenuItem> );
      return menuItems
    }

    const handlePostponeButtonChange = (objectId: string) => {
      if ( !(openPostponer)) setOpenPostponer(true);
      if ( isPostponed(objectId)) {
        setCurrentDelay(0);
        setOpenPostponer(false);
        savePostponeSettings(objectId);
      }

    }
  
    return <TableRow>
              <TableCell>{rowObject.name} </TableCell>
              <TableCell>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch
                    checked={ isSubscribed(rowObject.id) }
                    onChange={(e) => setSubscribed(rowObject.id, e.target.checked === true)}
                    name= {isSubscribed(rowObject.id) ? 'Receive Alerts' : 'Alerts Disabled' }
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
  
                  />}
                  label= {isSubscribed(rowObject.id) ? isPostponed(rowObject.id) ? `Postponed to ${getPostponedTime(rowObject.id)}` : 'Receive Alerts' : 'Alerts Disabled'}
                />
                  { isSubscribed(rowObject.id) && (isPostponed(rowObject.id) || !(openPostponer)) && <Button 
                    key = 'openPostponer'
                    color="primary"
                    onClick = { (e) => handlePostponeButtonChange(rowObject.id)}
                    >
                      {isPostponed(rowObject.id) ? 'Cancel Postponement' : 'Postpone' }
                      
                    </Button>}
  
                  { openPostponer && isSubscribed(rowObject.id) && !(isPostponed(rowObject.id)) && [
  
                       <FormControl className={classes.formControl}>
                        <InputLabel id="delay_value_helper-label">N</InputLabel>
                        <Select
                          labelId="select-delay-label"
                          id="select-delay-label-helper"
                          value={ currentDelay }
                          onChange={(e) => setDelay(rowObject.id, e.target.value as number)}
                        >
                          { getDelayMenuItems() }
                        </Select>
                        </FormControl>,
  
                        <FormControl className={classes.formControl}>
                        <InputLabel id="delay_unit-helper-label">Units</InputLabel>
                        <Select
                          labelId="delayUnit-label"
                          id="delayUnit-helper"
                          value={ currentDelayMultiplier }
                          onChange={(e) => setDelayUnits(rowObject.id, e.target.value as number)}
                        >
                          { getDelayUnitMenuItems() }
                        </Select>
                      </FormControl>
  
                 ,
  
                    <Button 
                      key = 'savePostponement'
                      color="primary"
                      onClick = { (e) => savePostponeSettings(rowObject.id)}
                      
                      >
                        Save
                        
                      </Button>,
                      <Button 
                      key = 'closePostponer'
                      color="primary"
                      onClick = { () => setOpenPostponer(false)}
                      >
                        Cancel
                        
                    </Button>
  
                   
                  ]  
              }
  
            </FormGroup>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
  }