import { ErrData, MapLike, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AppValueTypes, ErrDataPromise, setAContainsAllB } from '@iotv/iotv-v3-types';
import config from '../../config';
import Assembler from '../../data/Assembler';
import { getPseudoVBO } from '../../data/TypeHelpers';
import { GoogleSpreadsheetData, ViewDefinition, ViewKeyDefinitionType } from '../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

export const initClient = async (contextCustomer: ValidBusinessObject | undefined, setSignedIn: (signedIn: boolean) => void) => {

    const updateSigninStatus = async (isSignedIn: boolean) => {
        debug && console.log('updateSigninStatus', isSignedIn)
        if (isSignedIn) {
            setSignedIn(true);

        } else {
            setSignedIn(false);
            gapi.auth2.getAuthInstance().signIn();
        }
    }

    const CLIENT_ID = contextCustomer?.googleClientId ?? config.google.client_id
    const API_KEY = contextCustomer?.googleAPIKey ?? config.google.apiKey;
    var DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4', "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
    var SCOPES = 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.metadata.readonly';

    debug && console.log('Google creds for customer', { API_KEY, CLIENT_ID })

    try {
        await gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        });

        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    } catch (e) {
        console.error('Error initiing. ', e);
    }
}

export enum HeaderSource {
    LABEL = 'key',
    KEY = 'key'
}

export type GSheetExportRequest = {
    viewDefinition: ViewDefinition, vobs: ValidBusinessObjectList,     /* if processPlainObjects is set, use the provided objectTypeId as the type of each item, and its row number as its id */
    processPlainObjects?: {
        objectTypeId: string
    },
    file: gapi.client.drive.File, sheet: gapi.client.sheets.Sheet, headerSource: HeaderSource
}

export const exportVobs = async ({ vobs, viewDefinition, file, sheet, headerSource = HeaderSource.KEY, processPlainObjects }: GSheetExportRequest) => {
    const result: ErrData<gapi.client.sheets.UpdateValuesResponse> = { err: null, data: null };
    debug && console.log('exporting ', { vobs, file, sheet })

    if (!viewDefinition.id && !processPlainObjects) {
        const id: ViewKeyDefinitionType = {
            key: 'id', label: 'id', editable: false, precision: undefined, stateFn: undefined, type: AppValueTypes.STRING, unit: undefined, validationRx: undefined
        }
        viewDefinition.id = id;
        debug && console.log('Added ID to view Defintion', viewDefinition)
    }

    const a1Range = `${sheet.properties?.title}!A1`

    if (file.id) {
        const params = {
            // The ID of the spreadsheet to update.
            spreadsheetId: file.id,


            // The A1 notation of the values to update.
            range: a1Range,  // TODO: Update placeholder value.

            // How the input data should be interpreted.
            valueInputOption: 'USER_ENTERED',  // TODO: Update placeholder value.
        };


        const headerRow = Object.entries(viewDefinition).map(([k, def]) => {
            return def[headerSource]
        })

        const rows = vobs.map((vob) => Assembler.getInstance(vob) ?? vob).map((vob) => Object.entries(viewDefinition).map(([k, def]) => vob[def.key] ?? null))

        const values = [
            headerRow,
            ...rows
        ]

        const clearRequest: gapi.client.sheets.ClearValuesRequest = {}

        const clearRes = await new Promise((resolve, reject) => {
            debug && console.log('CLEARING SHEET')
            const request = gapi.client.sheets.spreadsheets.values.clear({
                range: 'A1:Z5000', //a1 Range does not work
                spreadsheetId: params.spreadsheetId,
                resource: clearRequest
            });
            request.then(function (response) {
                // TODO: Change code below to process the `response` object:
                debug && console.log('clearing sheet res: ', response.result);
                resolve({ err: null, data: response.result })
            }, function (reason) {
                console.error('error clearing sheet: ' + reason.result.error.message);
                resolve({ err: new Error(reason.result.error.message), data: null })
            });
        })

        const valueRangeBody: gapi.client.sheets.ValueRange = {
            range: a1Range,
            majorDimension: 'ROWS',
            values
        }




        const updateRes: ErrData<gapi.client.sheets.UpdateValuesResponse> = await new Promise((resolve, reject) => {
            const request = gapi.client.sheets.spreadsheets.values.update(params, valueRangeBody);
            request.then(function (response) {
                // TODO: Change code below to process the `response` object:
                debug && console.log(response.result);
                resolve({ err: null, data: response.result })
            }, function (reason) {
                console.error('error: ' + reason.result.error.message);
                resolve({ err: new Error(reason.result.error.message), data: null })
            });
        })

        result.err = updateRes.err;
        result.data = updateRes.data;

    } else {
        debug && console.log('No spreadsheet id', file)
    }

    return result;
}

type RowToObjectParams = {
    rowNumber: number, rowArr: any[], headerRow: string[], importObjectType: string | undefined
    setImportObjectType: React.Dispatch<React.SetStateAction<string | undefined>>
}

const rowToObject = ({ rowNumber, rowArr, headerRow, importObjectType, setImportObjectType }: RowToObjectParams): ValidBusinessObject | undefined => {
    let res: ValidBusinessObject | {} | undefined = undefined
    if (setAContainsAllB(headerRow, ['type', 'id '])) {

        const proxy: MapLike<any> = {};
        headerRow.forEach((key: string, i) => {
            proxy[key] = rowArr[i];
            if (key === 'type' && !importObjectType) {
                debug && console.log('setting import type to', rowArr[i])
                setImportObjectType(rowArr[i])
            }
        })
        res = getPseudoVBO(proxy as ValidBusinessObject)
    } else {
        const proxy: MapLike<any> = { type: importObjectType ?? 'Unknown', id: rowNumber }
        headerRow.forEach((key: string, i) => {
            proxy[key] = rowArr[i];
            if (!importObjectType) {
                debug && console.log(`setting import type to ${proxy.type}`)
                setImportObjectType(proxy.type)
            }

        })
        res = getPseudoVBO(proxy as ValidBusinessObject)
    }

    return res as ValidBusinessObject | undefined;
}

type SpreadsheetDataAsVBOsParams = {
    spreadsheetData: GoogleSpreadsheetData, importObjectType: string | undefined
    setImportObjectType: React.Dispatch<React.SetStateAction<string | undefined>>
    /* if processPlainObjects is set, use the provided objectTypeId as the type of each item, and its row number as its id */
    processPlainObjects?: {
        objectTypeId: string
    }
}

export const spreadsheetDataAsVBOs = ({ spreadsheetData, importObjectType, setImportObjectType, processPlainObjects }: SpreadsheetDataAsVBOsParams): ValidBusinessObjectList => {
    let res: ValidBusinessObjectList = [];
    if (spreadsheetData.length > 0) {
        const [headerRow, ...rows] = spreadsheetData;


        res = rows.map((rowArr, rowNumber) => rowToObject({ rowNumber, rowArr, headerRow, importObjectType, setImportObjectType })).filter((vbo) => vbo) as ValidBusinessObjectList
    }
    debug && console.log('sheetDataAsVBOs res', res)
    return res
}

export type SpreadsheetDataToViewDefintionRequest = {
    spreadsheetData: GoogleSpreadsheetData,

}

export const spreadsheetDataToViewDefintion = ({ spreadsheetData }: SpreadsheetDataToViewDefintionRequest): ViewDefinition => {
    let res: ViewDefinition = {};
    if (spreadsheetData.length > 0) {
        const [headerRow, ...rows] = spreadsheetData;

        headerRow.forEach((headerCellContent: string) => {
            res[headerCellContent] = {
                key: headerCellContent,
                editable: false,
                label: headerCellContent,
                precision: undefined,
                stateFn: undefined,
                type: AppValueTypes.STRING,
                unit: undefined,
                validationRx: undefined

            }
        })
    }
    debug && console.log('sheetDataToViewDefintion res', res)
    return res
}

export type UIGooglSheetListRangeRequest = {
    spreadsheetId: string, range: string
}

// aims to be compatible with google-services version so can be injected into @datamodel methods
export const listRange = async ( target: UIGooglSheetListRangeRequest): ErrDataPromise<{ values?: any[][] | null | undefined }>  => {
    const res: ErrData<{ values: any[][] }> = { err: null, data: null }

    try {
        const getRes = await gapi.client.sheets.spreadsheets.values.get(target);
        console.log('listRange', JSON.stringify(listRange, null, 1))
        if ( getRes.result.values ) {
           
            res.data = { values: getRes.result.values }
        } else {
           console.log('No catch but no res data values', getRes.result)
        }
    } catch (error: any) {
        console.log('Caught Error', error)
        res.err = new Error( error.result.error.message )
    }

    return res;
}

