import { Address } from '@iotv/datamodel';
import React from 'react';
import DetailCard from '../../../components/cards/DetailCard';
import { getViewDefinitionFromUserAttributes } from '../../../components/factories/AttributeDefinitions';
import { ObjectDetailCardProps, UserTransactionType, ViewDefinition } from '../../../types/AppTypes';


const debug = process.env.REACT_APP_DEBUG && false;

export default function AddressCard( props: ObjectDetailCardProps ) {

   const { searchParams } = props;

  debug && console.log('User Details Card has props', props);

  const title = 'My Address';

  const actions: UserTransactionType[] = [UserTransactionType.UPDATE]

  const viewDefinition: ViewDefinition = {
    ...getViewDefinitionFromUserAttributes(Address),
    // test_emum: { key: 'testEnum', label: 'Test Enum', type: AppValueTypes.ENUM, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, enumKV: { yes: 'Y', no: 'N', maybe: '~'}}
  }

  const { matchedPrimary, transactionFunctions, history } = props



  return <DetailCard { ...{matchedPrimary, title, viewDefinition, transactionFunctions, actions, searchParams, history} }></DetailCard>
}