import { BusinessObjectAssembler, Customer, CustomerProps, DatastoreObjectType, GatewayProps, GatewayProvisioningState, ErrData, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { AdjacentType, AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, Radio, TableCell, Typography, withStyles } from '@material-ui/core';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { useDocumentClientQuery } from '../../../../hooks/useDocumentClientQueryV1';
import { AssignGatewayToCustomerRequest, CustomerAllocationManagementProps, ListGatewaysRequest, ListerlizerProps, ListerlizerRowControlFnProps, MessageOutputType, UnassignGatewayFromCustomerRequest, UserTransactionType, UserView, ViewDefinition, ViewKeyDefinitionType, ViewObject } from '../../../../types/AppTypes';
import { DocumentClientListerlizerQueryType, DocumentClientListerlizerWrapperProps } from '../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../factories/DocumentClientListerlizerWrapper';
import { NavSections } from '../../../navigation/NavSections';
import { v4 as uuidv4 } from 'uuid/';


const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';
const componentClassName = 'CustomerManageAllocationTab'

const CustomerManageGatewayAllocationTab: React.FC<CustomerAllocationManagementProps> = ({ user: contextUser, parentCustomer, contextCustomer, transactionFunctions, userFunctions, history, refreshParent, relatedViewRefreshCount }) => {
  debug && console.log('CustomerManageAllocationTab got', { contextUser, parentCustomer, contextCustomer })

  const routerHistory = useHistory();
  const parentCustomerInstance = parentCustomer && new Customer(parentCustomer as CustomerProps);
  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const listGatewaysQuery = async (fn: (params: any) => void, listGatewayRequest: Partial<ListGatewaysRequest>) => {
    const { contextUser, contextCustomer } = listGatewayRequest
    const urlPart = parentCustomerInstance?.canManageGateways() ? 'listInventoryGateways' : 'listAllocatedGateways'
    if (contextUser && contextCustomer) {
      try {
        debug && console.log('CustomerManageAllocationTab listGatewayRequest using request', { listGatewayRequest, urlPart })
        const response = await ApiGateway.post(`/system/Gateway/${urlPart}`, listGatewayRequest, apiRef);
        debug && console.log('CustomerManageAllocationTab listGatewayRequest query results', response)
          if (response.data?.Items) {
            if (!parentCustomerInstance?.canManageGateways() ) {
              response.data.Items = response.data.Items.filter( ( gateway: DocumentClient.AttributeMap ) =>  gateway.provisioning instanceof Array && gateway.provisioning[0]?.allocationCustomer?.sk === listGatewayRequest.contextCustomer?.sk );
            } 
          }
          fn(response)
      } catch (e) {
        debug && console.log('Error in listGatewayRequest query', e)
      }
    } else {
      debug && console.log('CustomerManageAllocationTab skipped  query on no context user or customer')
    }
  
  }

  const queryParams: Partial<ListGatewaysRequest> = parentCustomerInstance && contextUser ? {
    ExclusiveStartKey: undefined,
    contextCustomer: parentCustomerInstance as { type: 'Customer' } & ValidBusinessObject,
    contextUser: contextUser as ValidBusinessObject & { type: 'User' },
    filter: parentCustomerInstance.canManageGateways() ? GatewayProvisioningState.INVENTORY : GatewayProvisioningState.ALLOCATED,
  } : { ExclusiveStartKey: undefined, contextUser: undefined, contextCustomer: undefined, filter: undefined }

  const contextCustomerSk = contextCustomer?.sk
  const parentCustomerSk = parentCustomer?.sk

  const queryHook = useDocumentClientQuery(listGatewaysQuery, queryParams, [parentCustomerSk, contextCustomerSk, relatedViewRefreshCount])

  const [
    [items, setItems],
    [ limit, setLimit ],
    [LastEvaluatedKeys, setLastEvaluatedKeys],
    [ExclusiveStartKey, setExclusiveStartKey]
  ] = queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects, getSelector] = queryHook.selectionControls;


  const forceUpdate = queryHook.forceUpdate

  type GatewayAttributeKeys = keyof GatewayProps
  const gatewayListViewDefs: ViewDefinition & Omit<{ [key in GatewayAttributeKeys & 'name']: ViewKeyDefinitionType }, 'nothing'> = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    eui: { key: 'eui', label: 'EUI', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    billingGroup: { key: 'billingGroup', label: 'Billing Group', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
    state: { key: 'state', label: 'Gateway Status', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
  }

  const allocateGateway = async (gateway: ValidBusinessObject) => {
    if (contextUser?.type === 'User') {
      try {
        debug && console.log('getCustomerQuery', contextUser)
        const request: AssignGatewayToCustomerRequest = {
          contextUser: contextUser as ValidBusinessObject & { type: 'User' },
          contextCustomer: parentCustomer as ValidBusinessObject & { type: 'Customer' },
          assignmentCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
          gateway: gateway as ValidBusinessObject & { type: 'Gateway' },
        }
        const response = await ApiGateway.post(`/system/Gateway/assignGatewayToCustoemr`, request, apiRef);
        debug && console.log('allocateGateway results', response)
        if (response.data) {
          debug && console.log('row component will try and get re-rendereed list???', { refreshParent })
          forceUpdate()
          //setLastAllocatedGatewaySk(gateway.sk)
          refreshParent && refreshParent()
          //routerHistory.push(`${routerHistory.location.pathname}?tabName=Gateways`)
        } else if (response.err) {
          displayMessage(response.err.message, 'MessageBar')
        } else {
          throw new Error(`Too many customers receied: ${JSON.stringify(response, null, 1)}`)
        }
      } catch (e) {
        debug && console.log('Error in CM query', e)
      }
    } else {
      debug && console.log('No context user')
    }
  }

  const allocateMultipleGateways = async () => {
    const promises = selectedObjects.map((gateway) => allocateGateway(gateway as DatastoreObjectType & { type: string, id: string }));
    const res = await Promise.all(promises)
    debug && console.log('Allocate multople res', res);
    setSelectedObjects([]);
    forceUpdate()
  }


  const viewObject: ViewObject = {
    matchedPrimary: contextCustomer,
    matchedRelatedByPk: [],
    matchedRelatedBySk: []
  }

  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<Partial<ListGatewaysRequest>> = queryParams ? {
    viewObject, viewDefinition: gatewayListViewDefs,
    transactionFunctions, history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Gateway',
      edgeFilter: undefined
    },
    maxItems: 5,
    sortKeyIn: 'name',
    contextObject: undefined,
    queryHook, queryParams,
    queryControls: () => [
      <ButtonGroup>
        {selectedObjects.length > 0 &&
          <Button
            size='small'
            onClick={() => allocateMultipleGateways()}
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
          >
            Allocate Selected
                   </Button>
        }
       
      </ButtonGroup>
  ],
    rowControlOverrideFns: {
      first: [
        (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
          return [
            <TableCell key ={ `${listRowProps.rowObject.sk}_goto` } { ...{  onClick: () => {
              const viewUrl = queryString.stringifyUrl({
                url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Gateway/${listRowProps.rowObject.sk}`,
                query: {
                  contextObjectSK: parentCustomer?.sk
                }
              });
              routerHistory.push(viewUrl)
            } }}> 
              <IconButton aria-label="delete" color="primary">
                <PlayArrowIcon {...{
                 
                }} />
              </IconButton>
            </TableCell>

          ]
        }
      ],
      last: [(listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
        return [
          <TableCell  key = {  `${listRowProps.rowObject.sk}_allocate` }  { ...{ }}><Button {...{ variant: "contained", color: "secondary", onClick: () => allocateGateway(listRowProps.rowObject) }} >Allocate</Button></TableCell>
        ]
      }]
    }
  } : undefined

  //      <div>        {JSON.stringify({ parentCustomer: parentCustomer?.name, contextCustomer: contextCustomer?.name })}</div>
  return (
    <>
      <div>        {selectedObjects.length}</div>
      <Grid className={componentClassName} container spacing={3} >
        {<Grid item xs={12} lg={12}>
          <Card>
            <CardContent>

              <Typography variant="h4" component="h2" gutterBottom>
                {'Device Allocation'}
              </Typography>
              {documentClientListerlizerProps &&
                <DocumentClientListerlizerWrapper key={'gatewayList2'} {...{ ...documentClientListerlizerProps }}></DocumentClientListerlizerWrapper>
              }
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
    </>
  );
}

export default withStyles(styles)(CustomerManageGatewayAllocationTab)