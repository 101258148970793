import { ValidBusinessObject } from '@iotv/datamodel';
import { getParents } from '../data/daoFunctions/daoFunctions';
import { getDeviceEnabledTypes } from '../data/TypeHelpers';
import { getDeviceObject } from '../test/database/DeviceRegisterMock/deviceIds';
import { TransactionResponse } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

//A mock clearly
export const getDeviceFromRegistry =  (deviceId: string): Promise<TransactionResponse<any, ValidBusinessObject>> => {
  

      const deviceRes:  Promise<TransactionResponse<any, ValidBusinessObject>> = new Promise( async (resolve, reject) => {
         debug && console.log('getting device honestly')
        let deviceOb = getDeviceObject(deviceId);
        if (deviceOb !== undefined) {
          const existingRes  =  await Promise.all( getDeviceEnabledTypes().map( (typeId) => getParents(deviceOb as ValidBusinessObject, typeId )));
          existingRes.forEach( (existing) => {
            if ( existing.data ) {
              existing.data?.Items?.forEach( (item: { pk?: string; }) => {
                // if (item.id === deviceId) {
                //   console.log('device exists', item);
                //   deviceOb = undefined;
                // }
                getDeviceEnabledTypes().forEach( (typeId) => {
                  if ( item.pk && item.pk.startsWith(typeId)) {
                     debug && console.log('device already connected to ', item)
                    deviceOb = undefined;
                  }
                }) 
              })
            }
            
          })
        }
       
        setTimeout( () => resolve({err: null, data: deviceOb ? deviceOb : null }), 2000)
      });

      
      return deviceRes
}
  
 


