import { GridRenderCellParams } from "@mui/x-data-grid"
import { 
    ArrowUpward, ArrowDownward, 
    ThumbUp, ThumbDown, RestartAlt, ErrorOutline

} from  '@mui/icons-material';
import {  } from "@material-ui/icons";
import { LinkType } from "@iotv/iotv-v3-types";

type RenderCellType = (params: GridRenderCellParams<any, any, any>) => React.ReactNode

export const trueIsYes: RenderCellType = ( p ) => p.value ? <ThumbUp { ...{ color: 'success'}}/> : <div/>
export const trueIsGood: RenderCellType = ( p ) => p.value ? <ThumbUp { ...{ color: 'success'}}/> : <div/>
export const trueIsBad: RenderCellType = ( p ) => p.value ? <ErrorOutline { ...{ color: 'warning'}}/> : <div/>
export const trueIsGoodFalseIsBad: RenderCellType = ( p ) => p.value ? <ThumbUp { ...{ color: 'success'}}/> : <ThumbDown { ...{ color: 'warning'}}/>
export const reboot: RenderCellType = ( p ) => p.value ?  <RestartAlt { ...{ color: 'warning'}}/> : <div/>
export const updown: RenderCellType = ( p ) => p.value === LinkType.UP ? <ArrowUpward { ...{ color: 'success'}}/> : <ArrowDownward { ...{ color: 'warning'}}/>