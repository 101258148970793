//adapted from https://gist.github.com/emoran/076b526d6bd4297544f46a8d3dba7965
import { Place } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { flattenObject } from "@iotv/datamodel-core";

const debug = process.env.REACT_APP_DEBUG && false;

export const markerHash = (addMarkers: any[]) => {
  return '' + addMarkers?.reduce((pre, cur) => pre + cur, '')
}

export const placeHash = (places: any[]) => {
  return '' + places?.reduce((pre, cur) => pre + JSON.stringify(flattenObject(cur)), '')
}

export const polygonHash = (addAreas: any[]) => {
  return '' + addAreas.reduce((pre, cur) => pre + JSON.stringify(cur), '')
}

export const addDramToDataLayer = ( dataLayer:  google.maps.Data, drawingManager: google.maps.drawing.DrawingManager, addMarkers: any[], setAddMarkers: React.Dispatch<React.SetStateAction<any[]>>, addAreas: any[], setAddAreas: React.Dispatch<React.SetStateAction<any[]>>, map: google.maps.Map<Element>) => {

    google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
      debug && console.log('drawing manager', event)

      // once a marker is placed, get the position and push to the SurveyView to set add form open
      // store markers in an array, once user click on cancel or save, remove it from the arr
      // create array hash, [].map((val) => '' + val)

        switch (event.type) {
          case google.maps.drawing.OverlayType.MARKER:

            setAddMarkers([event.overlay.position.lat(), event.overlay.position.lng()])

            event.overlay.setMap(null)
            break;
          // case google.maps.drawing.OverlayType.RECTANGLE:
          //   var b = event.overlay.getBounds(),
          //     p = [b.getSouthWest(), {
          //         lat: b.getSouthWest().lat(),
          //         lng: b.getNorthEast().lng()
          //       },
          //       b.getNorthEast(), {
          //         lng: b.getSouthWest().lng(),
          //         lat: b.getNorthEast().lat()
          //       }
          //     ]
          //     dataLayer.add(new google.maps.Data.Feature({
          //     geometry: new google.maps.Data.Polygon([p])
          //   }));
          //   break;
          case google.maps.drawing.OverlayType.POLYGON:

            const addAreas = event.overlay.getPath().getArray().map((loc: any) => {
              const newObj = {id: uuidv4(),  lat: loc.lat(), lng: loc.lng()}

              return newObj;
            })
            setAddAreas(addAreas)

            event.overlay.setMap(null)

            break;
          // case google.maps.drawing.OverlayType.POLYLINE:
          //   dataLayer.add(new google.maps.Data.Feature({
          //     geometry: new google.maps.Data.LineString(event.overlay.getPath().getArray())
          //   }));
          //   break;
          // case google.maps.drawing.OverlayType.CIRCLE:
          //   dataLayer.add(new google.maps.Data.Feature({
          //     properties: {
          //       radius: event.overlay.getRadius()
          //     },
          //     geometry: new google.maps.Data.Point(event.overlay.getCenter())
          //   }));
          //   break;
        }
            debug && console.log(`added ${event.type} to datalayer`)
      });

      const saveButton = document.getElementById('save');
    
      saveButton && google.maps.event.addDomListener(saveButton, 'click', function() {
        dataLayer.toGeoJson(function(obj) {
            const geoJsonElement = document.getElementById('geojson');
            geoJsonElement && ( geoJsonElement.innerHTML = JSON.stringify(obj) );
        });
      })



    bindDataLayerListeners( dataLayer );
}

// Apply listeners to refresh the GeoJson display on a given data layer.
function bindDataLayerListeners(dataLayer: google.maps.Data ) {
    dataLayer.addListener('addfeature', savePolygon);
    dataLayer.addListener('removefeature', savePolygon);
    dataLayer.addListener('setgeometry', savePolygon);
}


function savePolygon(dataLayer: google.maps.Data) {
    if (dataLayer) {
        debug && console.log('savePolygon', dataLayer)
    } else {
        debug && console.log('', dataLayer)
    }
  

}