import AdmZip from "adm-zip";
import axios, { AxiosRequestConfig } from "axios";
import { kml } from '@tmcw/togeojson'

const debug = process.env.REACT_APP_DEBUG && false;

const pretendGetKmlUrl = (url: string) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(url), 20)
    })
}

const hangleKMLLayerStatusChange = () => function (this: google.maps.KmlLayer) {
    debug && console.log('KML Status change', this.getStatus());
    return this.getStatus
}

const handleViewportChange = function (this: google.maps.KmlLayer) {
    debug && console.log('Viewport change', this.getDefaultViewport())
}

export const addKMLLayer = async (kmlUrl: string, map: google.maps.Map<Element>) => {
    const convertKmlToData = false

    debug && console.log('rendering kml from ', kmlUrl)
    const kmlLayerOptions: google.maps.KmlLayerOptions = {
        url: kmlUrl as string, preserveViewport: false, zIndex: 1000

    }
    const kmlLayer = new google.maps.KmlLayer(kmlLayerOptions);

    kmlLayer.addListener('status_changed', () => function (this: google.maps.KmlLayer) {
        debug && console.log('KML Status change', this.getStatus());
        return this.getStatus
    })
    kmlLayer.addListener('defaultviewport_changed', handleViewportChange)

    debug && console.log('KML Layer', kmlLayer)
    kmlLayer.setMap(map)

    try {
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            decompress: false
        }

        const response = await axios.get(kmlUrl, config);
        debug && console.log(response);

        if (response.data) {
            const blob: Blob = response.data;
            const zip = new AdmZip(Buffer.from(await blob.arrayBuffer()))

            var zipEntries = zip.getEntries();
            // this is where the icons are filtered out
            const kmlDocEntry = zipEntries.find((zip) => zip.entryName === 'doc.kml');
            if (kmlDocEntry) {
                const kmlString = kmlDocEntry.getData().toString('utf-8');
               // debug && console.log('kml', kmlString)

                if ( convertKmlToData ) {
                    const xml = new DOMParser().parseFromString(kmlString, "text/xml")
                    const geoCollection = kml(xml, { styles: true });
                    //debug && console.log('geoCollection', geoCollection);
                    map.data.addGeoJson(geoCollection)
                }
              
            }
        }

    } catch (error) {
        console.error(error);
    }

}
