import React, { useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, makeStyles, TableFooter, FormGroup, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Switch from '@material-ui/core/Switch';
import { getZombieInstanceFromPrimaryKey, ValidBusinessObject, ValidModelType } from '@iotv/datamodel';
import styles from '../../cosmetics/sharedCardStyles';
import { ComponentMode, UserTransactionType, TabilizerProps, pseudoEvent, AdjacentFilter, AdjacentType } from '../../types/AppTypes';
import { TabilizerTableRow } from './TabilizerTableRow';
import Assembler from '../../data/Assembler';
import { getObjectHash } from '@iotv/datamodel-core';

const debug = process.env.REACT_APP_DEBUG && false;
const effectDebug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => (styles(theme)));




export function Tabilizer(props: TabilizerProps) {
  const { matchedPrimary, viewDefinition, actions, transactionFunctions, searchParams, injectedComponents, functionOverrides, history } = { ...props };

  const selectInitialMode = () => {
    debug && console.log('selecting inital mode with ', { action: searchParams?.action, externalMode: functionOverrides?.mode })
    return searchParams && searchParams.action === UserTransactionType.CREATE_AND_LINK ? ComponentMode.EDIT : functionOverrides?.mode ? functionOverrides.mode : functionOverrides?.mode ? functionOverrides.mode : ComponentMode.VIEW;
  }

  const matchedPrimarySk = matchedPrimary.sk;

  debug && console.log('Tabilizer', { matchedPrimary, viewDefinition })

  useEffect(() => {
    if ( tableInitialObHash === undefined ) setTableInitialObHash( getObjectHash(matchedPrimary))
  }, [( matchedPrimary !== undefined )] )

  useEffect(() => {
    debug && console.log('Tabilizer used effect on initial load')
    setMode(selectInitialMode());
  }, [])

  useEffect(() => {
    debug && console.log('Tabilizer used effect on functionalOverride.mode', functionOverrides?.mode)
    functionOverrides?.mode && setMode(functionOverrides.mode)
  }, [functionOverrides?.mode])

  useEffect(() => {
    effectDebug && console.log('Tabilizer used effect on ', { matchedPrimarySk, mode: functionOverrides?.mode })
    setTableOb(matchedPrimary);

  }, [matchedPrimarySk, functionOverrides?.mode]);
  const [mode, setMode] = React.useState(selectInitialMode())
  const [version, setVersion] = React.useState(0)

  const [tableObHash, setTableObHash] = React.useState<string>(getObjectHash(matchedPrimary))
  const [tableInitialObHash, setTableInitialObHash] = React.useState<string | undefined>(undefined)
  const [tableOb, setTableOb] = React.useState(matchedPrimary)
  const [lock, setLock] = React.useState(false);
  const classes = useStyles();
  const attributeDefinitions = Object.values(viewDefinition);
  const handleChange = (event: { target: { name: any; checked: any; }; }) => {
    debug && console.log('Tabilizer used handleChange on version', version)
    setMode(event.target.checked ? ComponentMode.EDIT : ComponentMode.VIEW);
    setVersion(version + 1);
  };




  const saveThisObject = () => {
    const action = searchParams?.action || UserTransactionType.UPDATE;
    /* Metric Rule and Geofence Rule are embedded and may arrive with action === UserTransactionType.CREATE_AND_LINK hence hack pending proper CREATE OBJECT process */
    debug && console.log('TableObType', tableOb.type)
    if (searchParams && action === UserTransactionType.CREATE_AND_LINK && (!['MetricRule', 'GeofenceRule'].includes(tableOb.type))) {
      const adjacentFilter: AdjacentFilter = {
        adjacentType: AdjacentType.CHILD,
        objectType: (searchParams.objectTypeId ?? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK as string)?.type ?? 'unknown') as ValidModelType
      }
      searchParams?.contextObjectSK && transactionFunctions.saveAndLinkObject(searchParams.contextObjectSK, tableOb, adjacentFilter);
    }
    else {
      transactionFunctions.saveObject(tableOb);

    }
    setMode(ComponentMode.VIEW)
  }



  const updateKV = (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent, k: string) => {
    debug && console.log('in updateKV', { event, k });
    const target = event.currentTarget;
    const { type, checked, value } = target;
    debug && console.log('in updateKV after decomp', { type, checked, value });


    let parsedValue: any = value;
    switch (type) {
      case 'number': {
        const intermediateParsedValue = parseFloat(value) || parseInt(value);
        parsedValue = isNaN(intermediateParsedValue) ? undefined : intermediateParsedValue;
        break;
      }
      case 'checkbox': parsedValue = (checked === true); break;

      default: break;
    }

    const clonedInstance = (tableOb.type ? Assembler.getInstance(tableOb) : tableOb) ?? tableOb;
    let clonedMutant: ValidBusinessObject | undefined = undefined
    if (clonedInstance) {
      clonedInstance[k] = parsedValue;
      debug && console.log('Cloned clonedInstance', clonedInstance)
      clonedMutant = Object.assign({}, clonedInstance);
      debug && console.log('Cloned clonedMutant', clonedMutant)
    }

    // const completelyAssigned = completeAssign({}, localRowObject, {[k]: parsedValue } )
    // debug && console.log('completelyAssigned', completelyAssigned)
    // debug && console.log('completelyAssigned name', completelyAssigned?.name)

    const update = clonedMutant ?? Object.assign({}, tableOb, { [k]: parsedValue }); // { ...localRowObject, [k]: parsedValue };

    setTableOb(update);
    functionOverrides?.updateParentComponentItem(update)
    setTableObHash(getObjectHash(update))
    //setVersion(version + 1);
    debug && console.log('set table ob to', update)
  }



  const isEdited = () => getObjectHash(tableOb) !== tableInitialObHash;

  const userCan = (action: UserTransactionType) => actions.includes(action);

  return (
    <div>
      <Table className={classes.table} size={'small'} aria-label="simple table" onChange={() => functionOverrides?.hasValidationErrors()}>
        <TableBody key='doWeReallyNeedAKeyHere'>
          {attributeDefinitions.map((attDef, i) => <TabilizerTableRow key={`${matchedPrimary.sk ?? 'newObject'}_${attDef.key}_${i}`}
            {...{ keyToUse: attDef.key + i, viewKeyDefinition: attDef, matchedPrimary: tableOb, mode, actions, updateKV }}></TabilizerTableRow>)}
        </TableBody>
        {

        }
        <TableFooter key='doWeReallyNeedAKeyHere2'>
          <TableRow key={'tableFooter-row'}>
            <TableCell key={'tableFooter-actionCell'} colSpan={2}>
              {
                injectedComponents ??

                (
                  (userCan(UserTransactionType.UPDATE) || userCan(UserTransactionType.CREATE )) && [
                    <FormControlLabel
                      key='tableFooter-actionCell-A'
                      control={<Switch key={`${matchedPrimary.sk}_editSwitch`}
                        checked={mode === ComponentMode.EDIT}
                        onChange={handleChange}
                        name="Edit"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />}
                      label={mode === ComponentMode.EDIT ? '' : 'Edit'}
                    />

                    ,
                    mode === ComponentMode.EDIT &&
                    <Button
                      key={`${matchedPrimary.sk}_saveSwith`}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() => saveThisObject()}
                      startIcon={<SaveIcon />}
                      disabled={!isEdited()}
                    >
                      Save
                    </Button>

                  ])
              }

            </TableCell>
            <TableCell>
              {
                userCan(UserTransactionType.DELETE) &&
                <FormGroup row>
                  {<FormControlLabel {...{
                    key: 'tableFooter-actionCell-B',
                    control: <Switch {...{
                      checked: lock,
                      onChange: (e) => { setLock(e.target.checked === true) },
                      name: "Unlock",
                      inputProps: { 'aria-label': 'secondary checkbox' }
                    }}
                    />,
                    label: lock ? '' : 'Switch to delete'
                  }}

                  />

                  }
                  {lock && <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => history ? transactionFunctions.deleteObject(matchedPrimary, history) : console.log('no history object on delete click')}
                    startIcon={<DeleteForeverIcon />}
                    disabled={!lock}
                  >
                    {!lock ? 'Switch to delete' : 'Delete'}
                  </Button>
                  }

                </FormGroup>
              }


            </TableCell>
          </TableRow>

        </TableFooter>
      </Table>

    </div>

  )
}