import config from '../config'
import { NavigationItemType, RoleArbitrationInstanceType, UserGroupsRolesType } from '../types/AppTypes'
import { roleArbitrator as FWRoleArbitrator } from './FW/roleArbitrator'

import { roleArbitrator as c021RoleArbitrator } from '../venture/c021/roleArbitration/c021/roleArbitrator'
import { NavSections } from '../components/navigation/NavSections'
import { AppLocalStorage } from '../data/LocalStorage/AppLocalStorage'

const debug = process.env.REACT_APP_DEBUG && false;

export const roleArbitrator = {
    ...FWRoleArbitrator
}

if (config.app.appCode.startsWith('c021')) Object.assign(roleArbitrator, c021RoleArbitrator)

debug && console.log('Using combined roleArbitrator', roleArbitrator)

const knownRolesInPreferenceOrder = ['HyperUser', 'VentureTeam', 'SystemManagement', 'User']

export const getRoleArbitrationInstanceType = ( userGroupRole: UserGroupsRolesType, currentGroup: string | undefined ): RoleArbitrationInstanceType | undefined => {
    debug && console.log('getRoleArbitrationInstanceType', { userGroupRole, currentGroup})
    return  currentGroup ? roleArbitrator[currentGroup] : roleArbitrator.User;
}

export const getRoleArbitrationInstanceAndCurrentGroup  = (userGroupRoles: UserGroupsRolesType) => {
    const getDefaultGroupIdx = () => {
      const matchedPreferredIdxs = userGroupRoles.groups.map( ( group ) => knownRolesInPreferenceOrder.indexOf(group)).filter( ( i ) => i > -1).sort( ( a, b ) => a - b )
      return matchedPreferredIdxs[0] 
    }
  
    const currentGroup =knownRolesInPreferenceOrder[getDefaultGroupIdx()] ?? userGroupRoles.groups[0];

    const storedLastRole = AppLocalStorage.get( NavSections.HOME, 'lastUserSelectedGroupName')

    if (config.app.appCode === 'c021') knownRolesInPreferenceOrder.splice( 3, 0, 'communityWaterManager', 'tankOwner')
    
    if ( storedLastRole ) knownRolesInPreferenceOrder.unshift(storedLastRole)

    debug && console.log('getRoleArbitrationInstanceAndCurrentGroup returning', {  currentGroup, idx: getDefaultGroupIdx(), possibleGroups: userGroupRoles.groups})
    const roleArbitrationInstance = getRoleArbitrationInstanceType(userGroupRoles, currentGroup)
    return { currentGroup, roleArbitrationInstance }
  }


export   const filterByRoleArbitrationInstance = (navigationItem: NavigationItemType, roleArbitrationInstance: RoleArbitrationInstanceType, parentId?: string ) => {
  const denied =  roleArbitrationInstance ? roleArbitrationInstance.routes.find( (route) => {
     return route.type === navigationItem.type && ( route.id === navigationItem.id || route.id === '*' ) && route.access === 'Deny'
  }) : true;
  const allowed = roleArbitrationInstance ? roleArbitrationInstance.routes.find( (route) => {
   return route.type === navigationItem.type && ( (route.id === navigationItem.id || route.id === '*') && (route.type === 'category' || route.parentId === parentId)) && route.access === 'Allow'
}) : false;

  return allowed && !denied;
}
