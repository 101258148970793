import { PossibleBusinessObject, ValidBusinessObject } from '@iotv/datamodel-core';
import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AppDao from '../../../../data/AppDao';
import { addObjectToDB } from '../../Database/addToDb';
import styles from './RecipientModal.module.css';

const modalRoot = document.querySelector('#modal-root-2');

export interface recipientObject {
  name: string;
  email: string;
  phoneNumber: string;
}

interface recipientModalProp {
  close: () => void;
  parent: ValidBusinessObject;
  update: () => void;
  existing?: ValidBusinessObject;
}

const RecipientModal: React.FC<recipientModalProp> = (props: recipientModalProp) => {
  let { close, parent, update, existing } = props;
  let [saving, setSaving] = useState<boolean>(false);
  let [valid, setValid] = useState<boolean>(false);

  let [name, setName] = useState<string>('');
  let [email, setEmail] = useState<string>('');
  let [phoneNumber, setPhoneNumber] = useState<string>('');

  useEffect(() => {
    if (existing) {
      setName(existing.name);
      setEmail(existing.email);
      setPhoneNumber(existing.phoneNumber);
    }
  }, [existing]);

  useEffect(() => {
    setValid(name !== '' && email !== '' && phoneNumber !== '');
  }, [name, email, phoneNumber]);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  const createUser = async () => {
    setSaving(true);

    let newRecipient: PossibleBusinessObject = {
      name,
      email,
      phoneNumber,
      type: 'Recipient',
    };
    if (existing) {
      AppDao.save({ ...existing, ...newRecipient }).then(() => {
        update();
      });
    } else {
      addObjectToDB(newRecipient, parent).then((res) => {
        if (res !== undefined) {
          update();
        }
      });
    }
  };

  return ReactDOM.createPortal(
    <div>
      <div className={styles.modalContainer}>
        <div className={styles.form}>
          <Card>
            <Box m={2}>
              <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                <Typography variant='h5' component='h5'>
                  {existing ? 'Update' : 'Create'} Recipient for {parent.name}
                </Typography>
                <TextField
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  disabled={saving}
                  autoComplete='off'
                  fullWidth
                  label='Name'
                  variant='outlined'
                />
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  disabled={saving}
                  autoComplete='off'
                  fullWidth
                  label='Email'
                  variant='outlined'
                />
                <TextField
                  onChange={(e) => {
                    setPhoneNumber(e.target.value.replace(/\D/g, ''));
                  }}
                  value={phoneNumber}
                  disabled={saving}
                  autoComplete='off'
                  fullWidth
                  label='Phone Number'
                  variant='outlined'
                />
              </Stack>
            </Box>
            <Box m={2}>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' disabled={saving} onClick={close}>
                  Cancel
                </Button>
                <Button disabled={!valid || saving} variant='contained' onClick={createUser}>
                  {existing ? 'Update' : 'Create'}
                </Button>
              </Stack>
            </Box>
          </Card>
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default RecipientModal;
