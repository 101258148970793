import { MapLike } from "@iotv/datamodel"
import { Table, TableHead, TableRow, TableCell } from "@material-ui/core"
import React from "react"
import { TableRowizer } from "../../factories/TableParts/TableRowizer"


type PointInfoTableProps = {
    feature: google.maps.Data.Feature
}

export const PointInfoTable = ({ feature }: PointInfoTableProps) => {

     const gwStatistics = feature.getProperty('statistics') as MapLike<MapLike<any>>

     return <Table { ...{ size: 'small', style: { zIndex: 200, backgroundColor: 'white', opacity: 0.99}}}>
         <TableHead>
             <TableRow>
                 <TableCell>Gateway</TableCell>
                 <TableCell>Count</TableCell>
                 <TableCell>Mean</TableCell>
                 <TableCell>SD Sample</TableCell>
             </TableRow>
         </TableHead>
         { Object.entries(gwStatistics).map( ( [gwId, stats]: [string, MapLike<any>] ) => <TableRow>
            <TableCell>{gwId} </TableCell>
            <TableCell>{ stats.count }</TableCell> 
            <TableCell>{ stats.mean }</TableCell> 
            <TableCell>{ stats.sdSample }</TableCell> 
          
         </TableRow>
         )}
     </Table>

}