import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React, { SyntheticEvent, useEffect } from 'react';
import { Severity, SeverityType, UserErrorMessageType } from '../../types/AppTypes';


const debug = process.env.REACT_APP_DEBUG && false;

type UserMessageProps = {message?: UserErrorMessageType, children: any, onClose: any, severity: SeverityType}

function UserErrorMessageItem(props: UserMessageProps) {
  return <MuiAlert elevation={6} variant="filled"  {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

    

export default function UserMessage(props: Omit<UserMessageProps, 'severity'>) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const { onClose: providedClose } = props;

  useEffect(() =>   {
    debug && console.log('We tried to set open')
    setOpen(true);
  }, []);
  

  const handleClose = (event: SyntheticEvent, reason: string) => {
    if (reason === 'clickaway') {
      providedClose();
      return;
    }
    setOpen(false);
    providedClose();
  };

  const { message, onClose } = props;

  debug && console.log('UserMessage git ', props)
  let content = message?.content;
  let severity = undefined; // Severity.error;

  if (  message?.errors ) {
    severity = Severity.error;
    content = Object.entries(message.errors).map( ([k, msg]) => `${msg}\n`).join('');

  }

  let messageItem = <UserErrorMessageItem severity= { severity } onClose = { () => onClose() } > {content} </UserErrorMessageItem>;
  if ( message?.type === 'SnackBar') messageItem =   <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>{messageItem}</Snackbar>

  return (
    <div className={classes.root}>
     {messageItem}
    </div>
  );
}
