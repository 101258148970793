import React from 'react';
import { Card, CardContent, CardHeader, Container, makeStyles, Theme, withStyles, } from '@material-ui/core';
import styles from '../../../cosmetics/sharedViewStyles';
import { MessageOutputType, ObjectCardProps, UserManagementViewProps, UserTransactionType } from '../../../types/AppTypes';
import { v4 as uuidv4 } from 'uuid/';
import UserManagement from './UserManagement'
import { GImport } from '../../io/GImport';
import { addMultipleUsersToUserPool } from './sharedFns/addMultipleUsers'
import { ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel';
import { useHistory } from 'react-router-dom';

const debug = process.env.REACT_APP_DEBUG && false;

const localStyle = (theme: Theme) => ({


});
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle(theme) }));

function UserManagementView(props: UserManagementViewProps ): JSX.Element {
  const { match: { params: { action } }, contextCustomer, userFunctions } = props;
  const classes = useStyles();
  const routerHistory = useHistory();

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const userImportFn =  async ( vbos: ValidBusinessObjectList) => {
    if (props.user && contextCustomer ) {
      debug && console.log('userImportFn', { user: props.user, contextCustomer})
      const addUsersRes = await addMultipleUsersToUserPool( { 
        contextUser: props.user as ValidBusinessObject & { type: 'User' }, 
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer'}, 
        users: vbos.filter( (vbo) => vbo.type === 'User' && vbo.temporaryPassword)
      });
      if ( addUsersRes.err) {
        displayMessage( addUsersRes.err.message, 'MessageBar')
      } else {
        addUsersRes.data && displayMessage( addUsersRes.data, 'SnackBar')
      }
      
      // refreshParent(); 
      // routerHistory.goBack();
    } else {
      debug && console.log('User Import Fn did not import',  { user: props.user, contextCustomer, location: routerHistory.location})
    }
  }

  return <Container className='userManagementViewGrid'>
    <Card className={classes.root}>
      <CardHeader></CardHeader>
      <CardContent>
        {!action || [UserTransactionType.CREATE, UserTransactionType.UPDATE].includes(action as UserTransactionType) ?
          <UserManagement {...{ ...props }}></UserManagement> : <div></div>
        }
        {action || [UserTransactionType.IMPORT].includes(action as UserTransactionType) ?
           <GImport {...{ ...props, importObjectTypeId: 'User', importFn: userImportFn }}></GImport>  : <div></div>
        }
      </CardContent>

    </Card>
  </Container>

}

export default withStyles(styles)(UserManagementView)