import { GeofencePointsType, HistoryStore, ValidBusinessObject } from '@iotv/datamodel';
import { Address } from '@iotv/datamodel';
import { deviceEnabledTypes, flattenObject } from '@iotv/datamodel';
import { ThingShadowState } from '@iotv/datamodel';
import { LineChartXType, MapLike } from '../../../types/AppTypes';
import { HistoryObjectType, Position, SortOrder, StateSnapshot } from '@iotv/iotv-v3-types'
import { Alert } from '@iotv/datamodel';
import config from '../../../config';
import { Tank } from '@c021/datamodel'



const debug = process.env.REACT_APP_DEBUG && false;

export const indexKeyToTypeAndId = (indexKey: string) => {
  const firstIdx = indexKey.indexOf(':');
  if (firstIdx !== -1) {
    const skAsTypeId = { type: indexKey.substr(0, firstIdx), id: indexKey.substr(firstIdx + 1) }
    debug && console.log('indexKeyToTypeAndId', skAsTypeId)
    return skAsTypeId;
  }
  else return undefined;
}

export const extractAllStateAttributes = function (this: ValidBusinessObject | undefined) {
  const metrics = this?.payload?.state?.reported;
  const calculated = this?.payload?.state?.calculated;
  return Object.assign({}, metrics, calculated);
}

export const getPositionFromTSS = (item: ValidBusinessObject | undefined) => {
  const position = item?.payload?.state?.reported?.position || item?.state?.reported?.position;
  return position;
}

export const getTimestampFromTSS = (tss: ThingShadowState) => {
  let timestamp = undefined;
  const timestampVal = tss.timestamp || tss.payload.timestamp;
  let timestampNum = undefined;
  if (typeof timestampVal === 'string') {
    timestampNum = parseInt(timestampVal);
  } else if (typeof timestampVal === 'number') {
    timestampNum = timestampVal
  }

  if (timestampNum) {
    const length = Math.log(timestampNum) * Math.LOG10E + 1 | 0;
    if (length === 10) {
      timestampNum *= 1000;

    } else if (length !== 13) {
      debugger
    }
    timestamp = timestampNum
  }


  return timestamp;
}

export const thingShadowStateToStateSnapshot = (tss: ThingShadowState, filteredKeys?: string[]) => {
  const timestamp = getTimestampFromTSS(tss as ThingShadowState)
  const matchedKeyValues = tss ? flattenObject(tss, filteredKeys) : {};
  return {
    timestamp,
    state: {
      ...matchedKeyValues

    }
  } as StateSnapshot<any>
}

export const isDeviceEnabledType = (item: ValidBusinessObject | undefined) => {
  return (item && Object.keys(deviceEnabledTypes).includes(item.type))
}

export const getDeviceEnabledTypes = () => Object.keys(deviceEnabledTypes);

export const filterActiveAlerts = (alerts: (ValidBusinessObject & typeof Alert)[]) => alerts.filter((alert) => {

  return [
    'ISSUED_NO_ACK',
  ].includes(alert._state)
})

export const filterDisplayAlerts = (alerts: (ValidBusinessObject & typeof Alert)[]) => alerts.filter((alert) => {
  return [
    'NO_ALERT',
    'ISSUED_NO_ACK',
    'ISSUED_ACKNOWLEDGED',
    'RESOLVED',
    'CANCELLED'
  ].includes(alert._state)
})



export const isLocatable = (item: ValidBusinessObject | undefined) => {
  return item !== undefined && ['Farm', 'Location', 'Geofence', 'User'].includes(item.type);
}

export function getGeoCodeableAddress(this: ValidBusinessObject) {
  debug && console.log('In getGeoCodeableAddress', this);
  return Object.keys(Address).map((key) => this[key]).filter((entry) => entry).join(',')
};

export const getLocatableAddress = (object: ValidBusinessObject | undefined) => {
  return isLocatable(object) ? getGeoCodeableAddress.call(object as ValidBusinessObject) : undefined;
}

export const extractPolylineFromHistoryStore = (historyStrore: HistoryStore, contextSk: string) => {
  const polyline: GeofencePointsType[] = []
  debug && console.log('History store', historyStrore)
  if (historyStrore && historyStrore.lat && historyStrore.lng) {
    const stateSnapshots = historyStrore.getStateSnapshotArray(SortOrder.ASCENDING) as MapLike[];
    stateSnapshots.forEach((stateSnapshot, i) => {
      if (stateSnapshot.state?.lat && stateSnapshot.state?.lng) {
        polyline.push({
          lat: stateSnapshot.state.lat ?? 0,
          lng: stateSnapshot.state.lng ?? 0,
          DOP: stateSnapshot.state?.DOP,
          heading: stateSnapshot.state.heading,
          speed: stateSnapshot.state.speed,
          annotation: `${i} at ${stateSnapshot.timestamp}`,
          id: `${contextSk}_${i}:${stateSnapshot.timestamp}`,
          timestamp: stateSnapshot.timestamp
        })
      }

    })
  }
  debug && console.log('Polyline', polyline)
  return polyline
}

export const extractGraphData = (thingStatesMostRecentFirst: ThingShadowState[]): HistoryObjectType => {
  const historyArrLength = thingStatesMostRecentFirst.length;
  debug && console.log(`Array length would be ${historyArrLength}`)
  const historyObject: HistoryObjectType = { index: Array(historyArrLength), timestamp: Array(historyArrLength) }
  const invalidKeys: string[] = [];
  thingStatesMostRecentFirst.filter((item) => item.type = 'ThingShadowState').reverse().forEach((tss, tssIdx) => {
    const convertedTs = getTimestampFromTSS(tss);
    if (convertedTs) {
      historyObject.timestamp[tssIdx] = convertedTs;

      historyObject.index[tssIdx] = tssIdx;
      const metrics = extractAllStateAttributes.apply(tss as ValidBusinessObject);

      Object.entries(metrics).forEach(([metricKey, metricValue], i) => {
        if (typeof metricValue !== 'number' && !(invalidKeys.includes(metricKey))) {
          // console.log(`We are invalidating key ${metricKey} because ${metricValue} is not a number`)
          invalidKeys.push(metricKey);
        }

        if (!(historyObject[metricKey])) {
          historyObject[metricKey] = [];
          if (i > 0) {
            debug && console.log(`We not are filling ${metricKey} from ${getNiceDate(historyObject.timestamp[0])} to ${tssIdx} ${getNiceDate(historyObject.timestamp[tssIdx])}`)
            //historyObject[metricKey].fill(undefined, 0, i);
          }
        }
        if (isNaN(metricValue as number)) {
          if (!(invalidKeys.includes(metricKey))) {
            debug && console.log(`We knowingly are about to push ${JSON.stringify(metricValue)} onto history object for ${metricKey}`)
          }

        }
        if (historyObject[metricKey]) (historyObject[metricKey] as any[])[tssIdx] = metricValue;
      });
    } else {
      debug && console.log(`timestamp for ${tss} failed`)
    }
  });

  invalidKeys.forEach((k) => {
    debug && console.log(`We are removing ${k} because it was invalidated `)
    delete historyObject[k]
  });
  debug && console.log(`last time for graph is ${getNiceDate(historyObject.timestamp[historyObject.timestamp.length - 1])}`)
  debug && console.log(`history length is ${historyObject.timestamp.length}, roll metric length is ${(historyObject.rolls as number[])?.length}`)
  return historyObject
}

export const convertHistoryObjectToDataTable = (historyObject: HistoryObjectType, keys: string[], xType: LineChartXType, slice?: number[]): google.visualization.DataTable => {
  const dataTable = new google.visualization.DataTable();
  const dateFormat = getDateFormat(keys)

  if (xType === LineChartXType.INDEX) {
    dataTable.addColumn('number', 'Index'); // x
  } else if (xType === LineChartXType.TIMESERIES) {
    // dataTable.addColumn('timeofday', 'Value'); // x
    dataTable.addColumn(dateFormat, 'Value');
  } else {
    throw new Error('No XType for chart')
  }

  const existingKeys = keys.filter((key) => historyObject[key])

  existingKeys.forEach((k: string, columnIdx: number) => {
    const legend = getLegend(k)
    // dataTable.addColumn('number', k); // yi
    dataTable.addColumn('number', legend); // yi
  });

  const indices = historyObject.index;
  const invalidColumnIndexes: number[] = [];
// console.log({historyObject});

  indices.forEach((idx) => {
    if (!(slice) || (idx >= slice[0] && idx <= slice[1])) {
      let rowData: any[] = [];
      if (xType === LineChartXType.INDEX) {
        rowData.push(idx);
      } else if (xType === LineChartXType.TIMESERIES) {
        const convertedTimestamp = historyObject.timestamp[idx];
        const convertedDate = new Date(convertedTimestamp);

        if (dateFormat === 'date') {
          const yesterday = new Date(convertedDate.setDate(convertedDate.getDate() - 1))
          rowData.push(yesterday);
        } else { 
          rowData.push(convertedDate);
        }
      } else {
        throw new Error('No XType for chart')
      }

      existingKeys.forEach((k: string, columnIdx: number) => {
        const keyArray = historyObject[k] as any[];
        if (keyArray) {
          const data = keyArray[idx];
          if (isNaN(data)) {
          }
          const nan = typeof keyArray[idx] === 'string' || typeof keyArray[idx] === 'object';
          const valueToPush = nan ? 0 : keyArray[idx]
          if (nan && !invalidColumnIndexes.includes(columnIdx)) invalidColumnIndexes.push(columnIdx)
          debug && console.log('valueToPush', valueToPush)
          rowData.push(Math.round(valueToPush))
          // rowData.push(valueToPush)

        } else {
          debug && console.log(`No key array ${k} on `, historyObject)
        }

      })
      dataTable.addRow(rowData);

    }

  })
  // debug &&  console.log('dataTable', dataTable)
  debug && console.log('invalidColumns', invalidColumnIndexes)
  //invalidColumnIndexes.forEach( (idx: number, i) => dataTable.removeColumn(idx - i))

  return dataTable
}


export const getNiceDate = (timestamp: number | string, format: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' }) => {
  let timestampNum = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
  let timestampMsSecs: number = timestampNum < 9999999999 ? timestampNum * 1000 : timestampNum;
  const datetime = new Date(timestampMsSecs);
  const nowString = new Date().toLocaleDateString(config.locale, format);
  const thenStrting = datetime.toLocaleDateString(config.locale, format)
  const isToday = nowString === thenStrting;

  const dateSt = format.weekday !== 'short' && isToday ? 'Today' : thenStrting;
  const timeSt = datetime.toLocaleTimeString(config.locale, { hour: 'numeric', minute: 'numeric' });
  return `${dateSt} ${timeSt}`.replace(',', '')
}

export const getValueLabel = (text: string) => text.toLowerCase()
  .split('_')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');



/* BTO Addition or adaption */

export const extractGraphDataFromTss = (graphArray: any): HistoryObjectType => {
  graphArray = graphArray ? graphArray : [];

  const historyArrLength = graphArray.length;
  // const historyArrLength = graphArray? graphArray.length:0; 
  const historyObject: HistoryObjectType = { index: Array(historyArrLength), timestamp: Array(historyArrLength) }
  const invalidKeys: string[] = [];
  graphArray.forEach((tss: any, tssIdx: any) => {
    let convertedTs = getTimestampFromTSS(tss);
    if (convertedTs) {


      Object.entries(tss).forEach(([metricKey, metricValue], i) => {
        if (typeof metricValue !== 'number' && !(invalidKeys.includes(metricKey))) invalidKeys.push(metricKey);
        if (!(historyObject[metricKey])) {
          historyObject[metricKey] = [];
          if (i > 0 && historyObject && historyObject[metricKey] instanceof Array) (historyObject[metricKey] as any[]).fill(undefined, 0, i);
        }
        if (historyObject[metricKey]) (historyObject[metricKey] as any[])[tssIdx] = metricValue;
      });

      historyObject.timestamp[tssIdx] = convertedTs;
      historyObject.index[tssIdx] = tssIdx;
    }
    else {
      // debug && console.log(`timestamp for ${tss} failed`)
    }
  });

  invalidKeys.forEach((k) => {
    // debug && console.log(`We are removing ${k} because it was invalidated `)
    delete historyObject[k]
  });
  return historyObject
}

export type PsuedoVBORequestType = { id: string, type: string } & MapLike

export const getPseudoVBO = ({ id, type, name, ...others }: PsuedoVBORequestType): ValidBusinessObject => {
  const key = `${type}:${id}`;
  return {
    sk: key, pk: key, name: name ?? id, type, id, ...others
  }
}


const getLegend = (key: string) => {
  switch (key) {
    case "current_filled_volume":
      return "Tank Fill Volume"
    case "current_filled_percentage":
      return "Tank Fill Percentage"
    case "usage_in_day":
      return 'Daily Usage Volume'
    case "usage_in_day_percentage":
      return 'Daily Usage Percentage'
    case "formattedCFV":
      return 'Fill Level'
    case "formattedCFVPercentage":
      return 'Fill Level'
    case "formattedGroupCFV":
      return 'Community Fill Volume' 
    case "formattedGroupCFVPercentage":
      return 'Community Fill Percentage'
    case "community_usage_in_day":
      return 'Community Daily Usage'
    case "formattedDUGroupPercentage":
      return 'Community Daily Usage Percentage'
      case "potential_collection_rain_period":
        return 'Rainfall on roof (L)'

      case "actualCollectedVolume":
        return 'Rain collected in tank (L)'

      case "periodicCount":
        return 'Rainfall in period (mm)'
        case "volume_collected_period":
          return "Volume Collected Period"
      case "lifetimeCount":
        return 'Total rainfall since device on (mm)'

    default:
      return key
  }
}

const getDateFormat = (keys: string[]) => {
  const key = keys[0]
  switch (key) {
    case "current_filled_volume":
      return 'datetime'
    case "current_filled_percentage":
      return 'datetime'
    case "usage_in_day":
      return 'date'
    case "usage_in_day_percentage":
      return 'date'
    case "formattedCFV":
      return 'datetime'
    case "formattedCFVPercentage":
      return 'datetime'
    case "formattedGroupCFV":
      return 'datetime'
    case "formattedGroupCFVPercentage":
      return 'datetime'
    case "community_usage_in_day":
      return 'date'
    case "formattedDUGroupPercentage":
      return 'date'

    default:
      return 'datetime'
  }
}

const rad2degr = (rad: number):number => { return rad * 180 / Math.PI; }
const degr2rad = (degr: number) => { return degr * Math.PI / 180; }

export const getAverageTankGeoLocation = (tanks: { coordinates?: Position }[]) : GeofencePointsType| undefined => {
  if (tanks.length === 1) {
    return tanks[0].coordinates;
  }
  
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  tanks.forEach(tank => {
    const coordinates = tank.coordinates

    if (coordinates != null) { 
      const lat  = degr2rad(coordinates.lat);
      const lng = degr2rad(coordinates.lng);
      // sum of cartesian coordinates
      sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }
  })
  let avgX = sumX / tanks.length;
  let avgY = sumY / tanks.length;
  let avgZ = sumZ / tanks.length;

  let lng = Math.atan2(avgY, avgX);
  let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  let lat = Math.atan2(avgZ, hyp);
  
  const centreLat = rad2degr(lat)
  const centreLng = rad2degr(lng)

  // return ({rad2degr(lat), rad2degr(lng)});
  return ({lat:centreLat, lng:centreLng});
}

// export const getLatLngCenter = (viewObject: any): number[] | undefined => {
  export const getLatLngCenter = (viewObject: any):GeofencePointsType| undefined => {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;
  
    console.log('getLatLngCenter viewObjects', [viewObject.matchedRelatedBySk]);
  
    if (viewObject.matchedRelatedBySk != null) {
      for (var i = 0; i < viewObject.matchedRelatedBySk.length; i++) {
        const coordinates = viewObject.matchedRelatedBySk[i]?.coordinates
        if (coordinates != null) { 
          const lat  = degr2rad(coordinates.lat);
          const lng = degr2rad(coordinates.lng);
          // sum of cartesian coordinates
          sumX += Math.cos(lat) * Math.cos(lng);
          sumY += Math.cos(lat) * Math.sin(lng);
          sumZ += Math.sin(lat);
        }
  
        var avgX = sumX / viewObject.matchedRelatedBySk.length;
        let avgY = sumY / viewObject.matchedRelatedBySk.length;
        let avgZ = sumZ / viewObject.matchedRelatedBySk.length;
  
        // convert average x, y, z coordinate to latitude and longtitude
        let lng = Math.atan2(avgY, avgX);
        let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
        let lat = Math.atan2(avgZ, hyp);
  const centreLat = rad2degr(lat)
  const centreLng = rad2degr(lng)
  
        // return ({rad2degr(lat), rad2degr(lng)});
        return ({lat:centreLat, lng:centreLng});
      }
    }
    else {
      return undefined
    }
  
  
    // for (var i=0; i<latLngInDegr.length; i++) {
    //     var lat = degr2rad(latLngInDegr[i][LATIDX]);
    //     var lng = degr2rad(latLngInDegr[i][LNGIDX]);
    //     // sum of cartesian coordinates
    //     sumX += Math.cos(lat) * Math.cos(lng);
    //     sumY += Math.cos(lat) * Math.sin(lng);
    //     sumZ += Math.sin(lat);
    // }
  
    // var avgX = sumX / latLngInDegr.length;
    // var avgY = sumY / latLngInDegr.length;
    // var avgZ = sumZ / latLngInDegr.length;
  
    // convert average x, y, z coordinate to latitude and longtitude
    // var lng = Math.atan2(avgY, avgX);
    // var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    // var lat = Math.atan2(avgZ, hyp);
  
  }