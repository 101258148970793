import { pseudoEvent } from "src/types/AppTypes";
import { Assembler, ValidBusinessObject, getObjectHash } from "@iotv/datamodel-core";
import { MapLike } from '@iotv/iotv-v3-types'
import React from "react";

const debug = process.env.REACT_APP_DEBUG && false;

export type UseObjectMemoType = {
    state: [ MapLike<any> | undefined,  React.Dispatch<React.SetStateAction<MapLike<any> | undefined>>  ];
    hash: string;
    updateKV: (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent, k: string) => void;
}

export const useObjectMemo = ( initialMemoObject: MapLike<any> | undefined  ): UseObjectMemoType => {

    const [ stateObject, setStateObject ] = React.useState<MapLike<any> | undefined >( initialMemoObject );
    const [tableObHash, setTableObHash] = React.useState<string>(getObjectHash(initialMemoObject))

    const updateKV = (event: React.ChangeEvent<HTMLInputElement> | pseudoEvent, k: string) => {
        debug && console.log('in updateKV', { event, k });
        const target = event.currentTarget;
        const { type, checked, value } = target;
        debug && console.log('in updateKV after decomp', { type, checked, value });
    
    
        let parsedValue: any = value;
        switch (type) {
          case 'number': {
            const intermediateParsedValue = parseFloat(value) || parseInt(value);
            parsedValue = isNaN(intermediateParsedValue) ? undefined : intermediateParsedValue;
            break;
          }
          case 'checkbox': parsedValue = (checked === true); break;
    
          default: break;
        }
    
        const clonedInstance = { ...stateObject }
        let clonedMutant: MapLike<any> | undefined = undefined
          clonedInstance[k] = parsedValue;
          clonedMutant = Object.assign({}, clonedInstance);
          debug && console.log('Cloned clonedMutant', clonedMutant)
        
    
        // const completelyAssigned = completeAssign({}, localRowObject, {[k]: parsedValue } )
        // debug && console.log('completelyAssigned', completelyAssigned)
        // debug && console.log('completelyAssigned name', completelyAssigned?.name)
    
        const update = clonedMutant ?? Object.assign({}, stateObject, { [k]: parsedValue }); // { ...localRowObject, [k]: parsedValue };
    
        setStateObject(update);
        setTableObHash(getObjectHash(update))
        //setVersion(version + 1);
        debug && console.log('set table ob to', update)

      

      }
      return { 
        state:  [ stateObject, setStateObject ],
        hash: tableObHash,
        updateKV
    }
}