import { MapLike } from '@iotv/iotv-v3-types'
import { allowedTypesQuery, userTypeRoleQuery, systemManagmentUserCustomersQuery, userViaCustomersTypeRoleQuery } from '../../data/RoleQueries'
import { NormalizedData, RoleArbitrationInstance, RoleArbitrationInstanceType, ValidBusinessObject } from '../../types/AppTypes'

const loginRAI = new RoleArbitrationInstance({
    groupName: 'User',
    queries: [
        userTypeRoleQuery,

    ],
    routes: [

    ]

})

const userRAI = new RoleArbitrationInstance({
    groupName: 'User',
    queries: [
        ( user: ValidBusinessObject, normalizedData: NormalizedData ) => userViaCustomersTypeRoleQuery( user, 'Silo', normalizedData)
    ],
    routes: [
        { id: 'Views', type: 'category', access: 'Allow'},
        { id: 'Userview', type: 'link', access: 'Allow', parentId: 'Views'},
        { id: 'Network',  type: 'category', access: 'Deny'},
        { id: 'Groups',  type: 'link', access: 'Deny'},
    ]

}).inherit(loginRAI)

const aggregatopmGroupRAI = new RoleArbitrationInstance({
    groupName: 'User',
    queries: [
        allowedTypesQuery
    ],
    routes: [

    ]
})
const systemManamgementRAI = new RoleArbitrationInstance({
    groupName: 'SystemManagement',
    queries: [
        systemManagmentUserCustomersQuery,
         (user, normalizedData ) => userViaCustomersTypeRoleQuery( user, 'Irrigator', normalizedData ),
         (user, normalizedData ) => userViaCustomersTypeRoleQuery( user, 'Horse', normalizedData ),
         (user, normalizedData ) => userViaCustomersTypeRoleQuery( user, 'Tank', normalizedData ),
         (user, normalizedData ) => userViaCustomersTypeRoleQuery( user, 'InternalZone', normalizedData ),
    ],
    routes: [
        { id: 'Views', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'Views'},
        { id: 'Groups',  type: 'link', access: 'Allow'},
        { id: 'Network', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'Network'},
        { id: 'IncidentManagement', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'IncidentManagement'},
        { id: 'SystemManagement', type: 'category', access: 'Allow'},
        { id: 'CustomerManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement'},
        { id: 'DeviceManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement'},
        { id: 'UserManagement', type: 'link', access: 'Deny', parentId: 'SystemManagement'},
        { id: 'Diagnostic', type: 'link', access: 'Allow', parentId: 'SystemManagement'},
        { id: 'Survey', type: 'category', access: 'Allow'},
        { id: 'AreaManagment', type: 'link', access: 'Allow', parentId: 'Survey'},

    ]

}).inherit([userRAI, aggregatopmGroupRAI]);

const ventureTeamRAI = new RoleArbitrationInstance({
    groupName: 'VentureTeam',
    queries: [

    ],
    routes: [
        { id: 'RuleManagement', type: 'category', access: 'Allow'},
        { id: 'Rules', type: 'link', access: 'Allow', parentId: 'RuleManagement'},
        { id: 'Survey', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'Survey'},        
        

    ]

}).inherit(systemManamgementRAI)

const hyperUserRAI = new RoleArbitrationInstance({
    groupName: 'HyperUser',
    queries: [

    ],
    routes: [
        // { id: 'Views', type: 'category', access: 'Allow'},
        // { id: '*', type: 'link', access: 'Allow', parentId: 'Views'},
        // { id: 'SystemManagement', type: 'category', access: 'Allow'},
        // { id: '*', type: 'link', access: 'Allow', parentId: 'SystemManagement'},
        { id: 'UserManagement', type: 'link', access: 'Allow', parentId: 'SystemManagement'},
        { id: 'Survey', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'Survey'},
        { id: 'admin', type: 'category', access: 'Allow'},
        { id: '*', type: 'link', access: 'Allow', parentId: 'admin'},      
        
    ]

}).inherit([systemManamgementRAI, ventureTeamRAI]);

if (process.env.REACT_APP_LESS) {
    hyperUserRAI.queries = [
        userTypeRoleQuery,
    ]
}

export const roleArbitrator: MapLike<RoleArbitrationInstanceType> = {
    User: userRAI,
    VentureTeam: ventureTeamRAI,
    SystemManagement: systemManamgementRAI,
    HyperUser: hyperUserRAI
}
