import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from "@material-ui/core";
import { DocumentClient } from "aws-sdk/lib/dynamodb/document_client";
import { ObjectKeyPluckerTable } from "../../generic/KeyPlucker/ObjectKeyPluckerTable";
import { TabPanel } from "../../utils/TabPanelShim";
import {
  DiagnosticViewProps, TabSelectionProps, UserFunctions, ValidBusinessObject
} from "../../../types/AppTypes";
import React, { useEffect } from "react";
import { NavSections } from "../../navigation/NavSections";

import { AppLocalStorage } from "../../../data/LocalStorage/AppLocalStorage";
import { ValidModelObject, flattenObject, timestampToMS, getContainedPopulatedKeys, DeviceControlTransactionProps, IDeviceControlTransaction } from "@iotv/datamodel-core";
import { MapLike } from '@iotv/iotv-v3-types'
import { ConfigTabProps } from "../types";


export type HistoryTabProps = Omit<DiagnosticViewProps, 'deviceMessages'> & TabSelectionProps 
& {   currentDeviceTransactions:  DocumentClient.AttributeMap[] } & Pick<ConfigTabProps<any>, 'selectedDevices'>
& { userFunctions: UserFunctions }

const debug = process.env.REACT_APP_DEBUG && false;


const unwantedKeys: string[] = [ 'verify']

export const HistoryTab = ({ value, index, currentDeviceTransactions, selectedDevices, classes}: HistoryTabProps) => {

  const objects = currentDeviceTransactions?.map((item) => {
      const { status, timestamp, initialRequest } = item as IDeviceControlTransaction & MapLike<any>
      return { ...flattenObject({ ...initialRequest?.desiredPayload } ?? {}), status, timestamp } }) 
      .map( (item) => { unwantedKeys.forEach( (k) =>  delete item[k as keyof typeof item]); return item  } )
      .sort( ( a, b ) =>( a.timestamp ?? 0 ) - (b.timestamp ?? 0 ))
      .reverse()
      .map( (item) => ({ ...item, timestamp: item.timestamp ? new Date(timestampToMS(item.timestamp) as number).toLocaleString() : undefined})) ?? []

const selectedDevice = selectedDevices[0] // originally intended to deal with mutliple selections
const [ userSelectedKeys ] = React.useState<string[]>( AppLocalStorage.get(NavSections.DIAGNOSTIC, 'userSelectedKeysHistoryTab') ?? [])
const [ showAllKeys, setShowAllKeys ] = React.useState<boolean>( false )
const [ containedKeys, setContainedKeys ] = React.useState<string[]>([])

useEffect( () => {
    const populatedKeys = getContainedPopulatedKeys(objects ?? []).filter( ( k: string ) => !['timestamp', ...unwantedKeys].includes(k))
    setContainedKeys( populatedKeys )
    if ( !showAllKeys ) {
        const filteredUserSelectedKeys = userSelectedKeys.filter( (k) => k !== 'timestamp')
        setSelectedKeys( populatedKeys.filter( (k) => filteredUserSelectedKeys?.includes(k)) )
    }

}, [objects.length])



debug && console.log( 'userSelectedKeys',  userSelectedKeys  )
debug && console.log( 'containedKeys',  containedKeys  )
const [selectedKeys, setSelectedKeys] = React.useState<string[]>([])

const handleToggleShowAllKeys = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setShowAllKeys( e.target.checked )
    console.log('toggle all', { checked: e.target.checked, showAllKeys, selectedKeys })
    e.target.checked ? setSelectedKeys( containedKeys ) : setSelectedKeys([]) 
}

const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    const clone = [...selectedKeys];
    if (checked) {

        clone.push(e.currentTarget.value)

    } else {
        const idx = selectedKeys.findIndex((key) => key === e.currentTarget.value);
        if (idx !== undefined ) {
            clone.splice(idx, 1)
        }
    }
    if ( !showAllKeys ) {
        AppLocalStorage.set(NavSections.DIAGNOSTIC, 'userSelectedKeysHistoryTab', clone)
        setSelectedKeys(clone);
    }

}

return <TabPanel {...{ value, index }}>
    <Grid container>
    <Grid item xs={9} lg={10}>
        <Card>
            <CardContent>
                <Typography {...{ variant: 'h5' }}>{`${(selectedDevice as ValidBusinessObject)?.name ?? selectedDevice?.sk ?? '?' } History`}</Typography>
                <ObjectKeyPluckerTable {...{ objects, keys: selectedKeys.length > 0 ? ['timestamp', ...selectedKeys ] : undefined }} />


            </CardContent>
        </Card>
    </Grid>
    <Grid item xs={2} lg={2}>
        <Card>
            <CardContent>
                <FormControl size='small' component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Select keys</FormLabel>
                    <FormGroup>
                    <FormControlLabel { ...{ 
                           control: <Checkbox { ...{ 

                            size: 'small', value: showAllKeys, checked: showAllKeys,  onChange: ( e: React.ChangeEvent<HTMLInputElement>) =>  handleToggleShowAllKeys(e)
                        } }/>,
                        label: 'All / None'
                    }}/>
                        {containedKeys.map((k, i) => (
                            <FormControlLabel { ...{

                                control: <Checkbox { ...{ size: 'small', value: k, checked: selectedKeys.includes(k), onChange: handleCheckboxChange, name: `${i}_cb`}  }/>,         
                                label: k
                            }}
                                
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </CardContent>
        </Card>
    </Grid>
    </Grid>
</TabPanel>
}