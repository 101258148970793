import { Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import UnlinkIcon from '@material-ui/icons/LinkOff';
import TreeItem from '@material-ui/lab/TreeItem';
import { CSSProperties } from "@material-ui/styles";
import React, { useEffect } from "react";
import { UserTransactionType } from "../../../types/AppTypes";
import { StyledTreeItemProps } from "../../../types/ComponentProps";
import { useTreeItemStyles } from "./treeStyle copy";


const debug = process.env.REACT_APP_DEBUG && false;



export const StyledTreeItem = (props: StyledTreeItemProps) => {
  const classes = useTreeItemStyles();
  const { nodeId, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, handleIconClick, handleEndIconClick, mode, ...other } = props;

  useEffect(() => {
    debug && console.log('StyledTreeItem used effect on mode change')

  }, [mode])


  return (

    <TreeItem
      {...{

        nodeId,
        style: {
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        } as CSSProperties,
        onLabelClick: handleIconClick
      }}

      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          {mode === UserTransactionType.DELETE.valueOf() ? <DeleteIcon
            {...{
              onClick: handleEndIconClick
            }}
          ></DeleteIcon> : undefined}
           {mode === UserTransactionType.UNLINK.valueOf() ? <UnlinkIcon
            {...{
              onClick: handleEndIconClick
            }}
          ></UnlinkIcon> : undefined}
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }

      endIcon={mode === UserTransactionType.DELETE.valueOf() ? <DeleteIcon
        {...{
          onClick: handleEndIconClick
        }}
      ></DeleteIcon> : undefined}

      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}



      {...other}
    />
  );
}

