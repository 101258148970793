import { AppValueTypes } from '@iotv/iotv-v3-types';
import React from 'react';
import { DetailCardProps, ObjectCardProps, UserTransactionType, ViewDefinition } from '../../types/AppTypes';
import DetailCard from './DetailCard';



const debug = process.env.REACT_APP_DEBUG && false;



export default function DeviceDetailCard( props: ObjectCardProps ) {

  const searchParams = props.searchParams

  debug && console.log('User Details Card has props', props)

  const actions: UserTransactionType[] = [UserTransactionType.UPDATE, UserTransactionType.CREATE_AND_LINK, UserTransactionType.DELETE]

  const viewDefinition: ViewDefinition = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    type: { key: 'type', label: 'Type', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    id: { key: 'id', label: 'Device Unique Identifier', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
 
    description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    brand: { key: 'brand', label: 'Brand', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
 
  }


  const { viewObject: { matchedPrimary }, transactionFunctions, history } = props

  return matchedPrimary ? <DetailCard { ...{matchedPrimary, viewDefinition, transactionFunctions, actions, searchParams, history} }></DetailCard> : null
}