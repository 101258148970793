import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Card, CardActions, CardContent, CardHeader, FormGroup, Paper, TableContainer } from '@material-ui/core';
import React from 'react';
import type { MapLike, SelectorConfigParams, SingleParentSelectorCardProps, ViewDefinition } from '../../types/AppTypes';
import { Listerlizer } from '../factories/Listilizer';



const debug = process.env.REACT_APP_DEBUG && false;



export default function SingleParentSelectorCard( props: SingleParentSelectorCardProps) {

    const {viewObject, transactionFunctions, history, searchParams, userFunctions, adjacentFilter, listConfigParams, classes } = props
  
    debug && console.log('Object Device List Card has props', props)
    
    const viewDefinition: ViewDefinition = {
      name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
      description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    }
  
    const others: MapLike = listConfigParams;
    const nameLabel = others?.label || adjacentFilter.objectType;
    const sortKeyIn = Object.keys(viewDefinition)[0];

    const selectorConfigParams: SelectorConfigParams = {
        enabled: true,
        multipleSelect: false,
        controls: {
            add: false,
            find: true,
        }
    
      }
    
      const maxItems = 2;
  
    return <Card className={classes.root}>
      <CardHeader
        title = {`${nameLabel}`}
      ></CardHeader>
       <CardContent>
       <TableContainer component={Paper}>
        <Listerlizer {...{ viewObject, contextObject: undefined, adjacentFilter, maxItems, transactionFunctions, userFunctions, viewDefinition, searchParams, sortKeyIn, history, selectorConfigParams}}></Listerlizer>
       </TableContainer>
      </CardContent>
      <CardActions disableSpacing>
          <FormGroup row>
         
        </FormGroup>
      

      </CardActions>
   
    </Card>
  }