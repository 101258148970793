import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThingShadowState } from '@iotv/datamodel'
import styles from '../../../../cosmetics/sharedCardStyles'; 

const debug = process.env.REACT_APP_DEBUG && false;

type ObjectShadowStateCardProps = {
  matchedPrimary: ThingShadowState;
}

interface SVGStyles {
  [key: string]: any
}

// var progressContainer: SVGStyles = {};
// var progressContent: SVGStyles = {};

// progressContainer.stroke = "#2b2326"
// progressContainer.strokeWidth = "2px"
// progressContainer.fill = '#392f32'


// progressContent.stroke = '#badf4c'
// progressContent.strokeWidth = '2px'
// progressContent.fill = '#c2e362'


const useStyles = makeStyles((theme) => ({ ...styles(theme) }));



export default function TankGroupPercentageDisplayCard(props: ObjectShadowStateCardProps | any) {
  const [progressValue, setProgressValue] = React.useState("")
  const [progressText, setProgressText] = React.useState("")
  const [pgrsContRectHgt, setPgrsContRectHgt] = React.useState("0");
  const [pgrsContRectY, setPgrsContRectY] = React.useState("0");
  const [pgrsContTopEllipseCY, setPgrsContTopEllipseCY] = React.useState("0");
  const [pgrsContentColor, setPgrsContentColor] = React.useState("");
  const [pgrsContainerColor, setPgrsContainerColor] = React.useState("");

  const classes = useStyles();
  const { matchedPrimary, parentObjects } = props
  debug && console.log('TankGroupPercentageDisplayCard props', props) 

  React.useEffect(() => {
    let currentFilledVolume = matchedPrimary?.current_filled_volume
    let totalVolume =  matchedPrimary?.total_volume
    debug && console.log({ currentFilledVolume, totalVolume})
    let progressValue: number = Math.round((currentFilledVolume / totalVolume) * 100);
    // let progressValue: number = 30;
    debug && console.log('TankGroupPercentageDisplayCard progressValue', progressValue)
    debug && console.log('TankGroupPercentageDisplayCard progressValue typeof', typeof progressValue)
    let inverseProgressValue: number = (100 - progressValue)

    if (progressValue > 0 && progressValue <= 100) {
      setPgrsContentColor('#0066FF')
      inverseProgressValue = (inverseProgressValue - 5)
      setPgrsContainerColor('#B0B0B0')
      setProgressText(progressValue.toString() + '%')
      setPgrsContTopEllipseCY(((-inverseProgressValue * -0.8 + 20).toString()).concat('%'));
      setPgrsContRectHgt(((70 - inverseProgressValue * 0.8).toString()).concat('%'));
    } else {
      if (progressValue == 0) setProgressText(progressValue.toString() + '%')
      if (progressValue > 100) setProgressText('>100%')
      if (!matchedPrimary) setProgressText('Error')
      // progressValue == 0 ? setProgressText(progressValue.toString() + '%') : setProgressText('Error')
      inverseProgressValue = 100
      setPgrsContentColor('#787878')
      setPgrsContainerColor('#B0B0B0')

      setPgrsContTopEllipseCY('90%');
      setPgrsContRectHgt(((80 - inverseProgressValue * 0.8).toString()).concat('%'));
    }
    setPgrsContRectY(((inverseProgressValue * 0.8 + 20).toString()).concat('%'));
    return () => { };
  });

  return (
    <div className="playground">
      <section className="progress-wrapper">
        <svg height="100%" width="100%">
          <g className="progressContainer"
            stroke="#392F32"//{pgrsContainerColor}
            // stroke={pgrsContainerColor}
            strokeWidth="2" fill={pgrsContainerColor} >
            <rect x="0" y="15%" width="100%" height="75%"></rect>
            <ellipse cx="50%" cy="15%" rx="50%" ry="15" className="top" />
            <ellipse cx="50%" cy="90%" rx="50%" ry="15" className="bottom" />
          </g>
          <g className="progressContent"
            // stroke="#00662F"
            // stroke={pgrsContentColor}
            strokeWidth="1" fill={pgrsContentColor}>
            <rect x="0" y={pgrsContRectY} width="100%" height={pgrsContRectHgt} />
            <ellipse cx="50%" cy="90%" rx="50%" ry="15" className="bottom" />
            <ellipse cx="50%" cy={pgrsContTopEllipseCY} rx="50%" ry="15" className="top" z-index="2" />
          </g>
          <g className="progressContainer"
            // stroke={pgrsContainerColor}
            stroke="#392F32"
            strokeWidth="2px" fill={pgrsContainerColor}  >
            <ellipse cx="50%" cy="15%" rx="50%" ry="15" className="top" fill="none" />
          </g>

          <text className="percentage" x="50%" y="50%"
            textAnchor="middle" /* align center */
            dominantBaseline="middle"> {progressText} </text>
          {/* <text className="percentage" x="40%" y="80"> {progressText} </text> */}
        </svg>

      </section>
    </div>
    // </CardContent>
    // {/* </Card> */}
  );
}