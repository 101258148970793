import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, DialogProps, Button} from "@material-ui/core"
import { useState } from "react";
import { useHistory } from "react-router";
import { ValidBusinessObject } from "src/types/AppTypes";

export type DialogWrapperProps = {
    header: string,
    open: boolean,
    wrappedElement: JSX.Element,
    setClosed: (save:boolean) => void,
}

export const DialogWrapper = ( {header, open,  setClosed, wrappedElement, fullWidth = true, maxWidth = 'lg' }: DialogWrapperProps & Partial<DialogProps> ) => {
    const routerHistory = useHistory()
    const handleClickOpen = () => {
    };

    const handleCancel = () => {
       setClosed(false)
    };
    const handleSave = () => {
      setClosed(true)
   };
    const handleConfirm = () => {
        // onConfirmation(); setOpen(false);  redirectUrl && routerHistory.push(redirectUrl) ;

    };
    return  <Dialog { ...{ 
      fullWidth, maxWidth, 
    }}
    open={open}
    onClose={handleConfirm}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      { header }
    </DialogTitle>
    <DialogContent>
      { wrappedElement}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} autoFocus >Cancel</Button>
      {/* TODO need to change when provision screen is no longer needed RE: Did RE do this, it is sucky*/}
      {header.substring(0,6) === 'Switch' && <Button onClick={handleSave} autoFocus >Save</Button>} 
    </DialogActions>
  </Dialog>
} 