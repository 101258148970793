

import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  root: {
    border: 2,
    borderRadius: 3,
    // color: "blue",
    // height: '100vh',
    Width: "100vw",
    // padding: '0 30px',
  },
  item: {
    width: "95%",
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  mapHolder: {
    height: "400px",
    width: "800px",
  },
  networkTable: {
    padding: 10, backgroundColor: theme.palette.background.paper
  },
  fcDaysHistory:{
    // display: "flex",
  // flexDirection:'row'
  },
  appPaneGrid: {
    height: '100vh'
  },

  controlPanel: {  backgroundColor: 'white', opacity: 0.9, padding: '0.5rem', }


});

export default styles;