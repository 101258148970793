import React, { useState, useEffect }from 'react'
import { Button, Card, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useStyles } from '../../../cosmetics/communityStyles'
import { Community } from '@c021/datamodel'
import { useHistory } from 'react-router-dom';
import { AdjacentType, getZombieInstanceFromPrimaryKey, ObjectHistory, ValidBusinessObject, ValidModelObject, ValidModelObjects } from '@iotv/datamodel-core';
// import { getAdjacent2Wrapper, getOne } from '../../../data/daoFunctions/daoFunctions';
import { CommunityDailyUsageGraph } from '../graphs/CommunityDailyUsageGraph';
import { CommunityFillLevelGraph } from '../graphs/CommunityFillLevelGraph';
import { ObjectCardProps } from 'src/types/AppTypes';
import { StateSnapshot } from '@iotv/iotv-v3-types';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';


export type DroughtManagementReportViewProps = {
    objectHistory: Required<ObjectHistory>
} & ObjectCardProps

export const DroughtManagementReportView = (props: DroughtManagementReportViewProps) => {
    const styles = useStyles()
    const history = useHistory()
    // const [rows, setRows] = useState([])

    const customerIn: ValidModelObject<'Customer'> | undefined = props.viewObject.matchedPrimary?.type === 'Customer' ? props.viewObject.matchedPrimary as ValidModelObject<'Customer'> : undefined
    const objectHistory = props.objectHistory



    const test = [{
        id: 1,
        date : '20/03/22',
        numTankUsers : 'everytone',
        numTanks : 142,
        communityFillLevel : '70%',
        totalHouseholdTanksFillLevel : 0,
        avgDailyUsageAtStart : 0,
        currentAvgDailyUsage : 0,
        dailyUsageYesterday : 0
    }]

    console.log(customerIn)


    const dateEnteredStage: Date | undefined = customerIn?.currentStageAttributes?.dateEnteredStage ? new Date(customerIn?.currentStageAttributes.dateEnteredStage) : undefined
    const snapshots = objectHistory.dailyStateHistory?.getStateSnapshotArray() ?? []

    const snapshotsSinceStageStart:  StateSnapshot<any>[]  = dateEnteredStage ? snapshots.filter(snapshot => new Date(snapshot.timestamp) >= dateEnteredStage) : []
    console.log(snapshotsSinceStageStart)

    const dateEnteredStageSnapshot: StateSnapshot<any> | undefined = dateEnteredStage ? snapshots.find(snapshot => new Date(snapshot.timestamp) >= dateEnteredStage) : undefined
    const averageUsageAtStart = dateEnteredStageSnapshot?.state.average_usage_in_day_community + dateEnteredStageSnapshot?.state.average_usage_in_day_household


    console.log(snapshots)

    const rows = snapshotsSinceStageStart.map((snapshot, i) => {
        return ({
            id : i,
            timestamp : snapshot.timestamp,
            numTanks : snapshot.state.numTanks,
            numUsers : snapshot.state.numTankUsers,
            communityFillLevel : snapshot.state.current_filled_factor,

            averageUsage : snapshot.state.average_usage_in_day_household + snapshot.state.average_usage_in_day_community ,
            averageHouseholdFillLevel : snapshot.state.household_fill_factor,
            averageCommunityFillLevel : snapshot.state.community_fill_factor,


        })
    })

    console.log(rows)

    const columns : GridColDef[] = [
        {
            field : 'timestamp',
            headerName : 'Date',
            flex : 1,
            headerAlign: 'center',
            align : 'center',
            cellClassName : 'testStyling',
            type : 'date',
            renderCell : (params : GridCellParams) => {
                return new Date(params.row.timestamp).toLocaleDateString()
            }
        },
        {
            field : 'numTankUsers',
            headerName : '# tank users',
            flex : 1,
            minWidth: 150,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return params.row.numUsers
            }
        },
        {
            field : 'numTanks',
            headerName : '# tanks',
            flex : 1,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return params.row.numTanks
            }
        },
        {
            field : 'communityFillLevel',
            headerName : 'Community Fill Level',
            flex : 1,
            minWidth : 190,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return `${Math.round(params.row.communityFillLevel * 100)}%`

            }
        },
        {
            field : 'householdTanksFillLevel',
            headerName : 'Household Tanks Fill Level',
            flex : 1,
            minWidth : 280,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return `${Math.round(params.row.averageHouseholdFillLevel * 100)}%`

            }
        },
        {
            field : 'communityTanksFillLevel',
            headerName : 'Community Tanks Fill Level',
            flex : 1,
            minWidth : 280,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return `${Math.round(params.row.averageCommunityFillLevel * 100)}%`

            }
        },
        {
            field : 'avgDailyUsageAtStart',
            headerName : 'Average Daily Usage at Start of Stage',
            flex : 1,
            minWidth : 300,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return `${Math.round(averageUsageAtStart)} L`

            }
        },
        {
            field : 'currentAvgDailyUsage',
            headerName : 'Current Average Daily Usage',
            flex : 1,
            minWidth : 370,
            headerAlign: 'center',
            align : 'center',
            type : 'number',
            renderCell : (params : GridCellParams) => {
                return `${Math.round(params.row.averageUsage)} L`

            }
        },
    ]

    return (
        <Card>
            <Grid container className={styles.container} spacing={2}>
                <Grid item>
                    <Typography variant='h5' component='h5'>
                        Community Drought Management Report
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant='contained' disabled={true} color='primary'>Download Report</Button>
                </Grid>
                    
                <Grid item xs={12} >
                    <Card className={styles.container}>
                        <Typography variant='h5' component='h5' align='center'>
                            {customerIn?.name}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CommunityFillLevelGraph {...{objectHistory}}/>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CommunityDailyUsageGraph {...{objectHistory}}/>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <DataGrid
                            disableSelectionOnClick={true}
                            disableColumnMenu={true}
                            autoHeight={true}
                            rows = {rows}
                            columns = {columns}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Card>
    )
}