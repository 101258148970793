import React from 'react';
import { Button } from "@material-ui/core";
import { AdjacentFilter, ViewDefinition, ViewObject } from "../../types/AppTypes";
import { flattenViewObject, jsonToCsv } from "../../util/AppData/viewObject";

const debug = process.env.REACT_APP_DEBUG && false;

type DownloadButtonProps = {
    viewObject: ViewObject, viewDefinition: ViewDefinition, adjacentFilter: AdjacentFilter, includeHeaders?: boolean, headersAsDisplayValues?: boolean, label?: string
}

export const DownloadButton = (props: DownloadButtonProps) => {
    const { viewObject, viewDefinition, adjacentFilter, includeHeaders = true, headersAsDisplayValues = true  } = props;
    const flatViewObject = flattenViewObject(viewObject, viewDefinition, adjacentFilter, includeHeaders, headersAsDisplayValues );
    const csvResult = jsonToCsv(flatViewObject);
    debug && console.log('Download button got', { viewObject, viewDefinition, flatViewObject })
    const downloadButton = csvResult.data ? <Button variant = 'outlined' href={`data:text/json;charset=utf-8,${encodeURIComponent(csvResult.data)}`} download={`${adjacentFilter.objectType}`} >{ props.label ?? 'Download'}</Button> : null;

    return downloadButton;
}