import { BusinessObjectAssembler, CustomerAccountType, DatastoreObjectType, ValidBusinessObject } from '@iotv/datamodel';
import { AddressType } from '@iotv/datamodel';
import { AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, TableCell, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteSweepRounded';
import EditIcon from '@material-ui/icons/Edit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../../../data/aws/api-gateway/ApiGateway';
import { useDocumentClientQuery } from '../../../../../../hooks/useDocumentClientQueryV1';
import {
  AdjacentType,
  CustomerContextTabProps,
  ListerlizerProps,
  ListerlizerRowControlFnProps,
  MessageOutputType,
  UserTransactionType,
  ViewDefinition,
  ViewObject,
} from '../../../../../../types/AppTypes';
import { DocumentClientListerlizerWrapperProps } from '../../../../../../types/LabTypes';
import { DocumentClientListerlizerWrapper } from '../../../../../../components/factories/DocumentClientListerlizerWrapper';
import { NavSections } from '../../../../../../components/navigation/NavSections';
import { c021ToLSCAccountType, lscToC021AccountType, C021CustomerAccountType, lscToC0211AccountTypeMap, c021ToLSCAccountTypeMap } from '@c021/datamodel';
import { Tooltip } from '@mui/material';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';
const componentClassName = 'CustomerContextView';

const assembler = new BusinessObjectAssembler();

type GetCustomerContextRequest = {
  contextUser?: ValidBusinessObject;
  customer: ValidBusinessObject;
  accountType: CustomerAccountType;
  accountRelationship: AdjacentType;
  ExclusiveStartKey: { sk: string; pk: string } | undefined;
};

const listCustomersQuery = async (fn: (params: any) => void, contextRequest: GetCustomerContextRequest) => {
  if (contextRequest.contextUser) {
    try {
      const response = await ApiGateway.post('/system/Customer/context', contextRequest, apiRef);
      debug && console.log('listCustomersQuery query results', { contextRequest, response });
      fn(response);
    } catch (e) {
      debug && console.log('Error in CCT query', e);
    }
  }
};

function CustomerContextTab({
  user,
  userFunctions,
  contextCustomer,
  accountType,
  accountRelationship,
  history,
  transactionFunctions,
  match: {
    params: { action },
  },
  title,
}: CustomerContextTabProps): JSX.Element {
  const routerHistory = useHistory();

  const queryParams: GetCustomerContextRequest = {
    ExclusiveStartKey: undefined,
    accountRelationship: accountRelationship,
    accountType: accountType,
    contextUser: user,
    customer: contextCustomer,
  };

  const contextCustomerSk = contextCustomer?.sk;

  const queryHook = useDocumentClientQuery(listCustomersQuery, queryParams, [contextCustomerSk]);

  const [[items, setItems], [limit, setLimit], [LastEvaluatedKeys, setLastEvaluatedKeys], [ExclusiveStartKey, setExclusiveStartKey]] =
    queryHook.querySetterGetters;

  const [selectedObjects, setSelectedObjects] = queryHook.selectionControls;
  const forceUpdate = queryHook.forceUpdate;

  const [inventoryDevicesLastEvaluatedKey, setDevicesLastEvaluatedKey] = React.useState<DocumentClient.Key | undefined>(undefined);
  const [queryLimit, setQueryLimit] = React.useState<number | undefined>(undefined);

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type });
  };

  const removeFromDatabase = async (customer: ValidBusinessObject | undefined) => {
    if (customer) {
      const response = await ApiGateway.post('/system/Customer/delete', { contextUser: user, customer }, apiRef);
      debug && console.log('Add response', response);
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar');
      } else {
        displayMessage(`Removed ${customer?.name} from datastore`, 'SnackBar');
      }
    }
  };

  const deleteMultipleCustomers = async () => {
    const promises = selectedObjects.map((customer) => removeFromDatabase(customer as DatastoreObjectType & { type: string; id: string }));
    const res = await Promise.all(promises);
    debug && console.log('Delete multople res', res);
    setSelectedObjects([]);
    forceUpdate();
  };

  const proxyParent = contextCustomer ?? assembler.getInstanceFromPrimaryKey('Customer:1');
  const viewObject: ViewObject = {
    matchedPrimary: proxyParent,
    matchedRelatedByPk: [],
    matchedRelatedBySk: [],
  };

  const customerAccountTypeShim = c021ToLSCAccountTypeMap;

  type CustomerAttributeKeys = keyof AddressType;
  const userTableViewDefinition: ViewDefinition & Omit<{ [key in CustomerAttributeKeys]: any }, 'dwelling_number'> = {
    name: {
      key: 'name',
      label: 'Name',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    street_number: {
      key: 'street_number',
      label: 'Street Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    suburb: {
      key: 'suburb',
      label: 'Suburb',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    city: {
      key: 'city',
      label: 'City',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    country: {
      key: 'country',
      label: 'Country',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    post_code: {
      key: 'post_code',
      label: 'Postcode',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    email: {
      key: 'email',
      label: 'Email',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    phoneNumber: {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: action === UserTransactionType.CREATE ? /\+\d+$/ : undefined,
      failsValidationText: 'phone format +021...',
    },
    accountType: {
      key: 'accountType',
      label: 'Account Type',
      type: AppValueTypes.ENUM,
      enumKV: customerAccountTypeShim,
      editable: action === UserTransactionType.CREATE,
      valueSetter: (x: C021CustomerAccountType) => c021ToLSCAccountType(x),
      valueGetter: (x: CustomerAccountType) => lscToC021AccountType(x),
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
  };

  const customerListViewDefs = {
    name: userTableViewDefinition.name,
    accountType: userTableViewDefinition.accountType,
  };

  const customerListProps: ListerlizerProps = {
    transactionFunctions,
    userFunctions,
    history,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: 'Customer',
      edgeFilter: undefined,
    },
    maxItems: 25,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: {
        add: false,
        find: false,
      },
    },
    viewDefinition: customerListViewDefs,
    viewObject,
    contextObject: undefined,
  };

  /**
   * Give context to group collections
   * @param type
   * @returns
   */
  const addCustomerText = (type: CustomerAccountType) => {
    switch (type) {
      case CustomerAccountType.V:
        return '';
      case CustomerAccountType.X:
        return 'Region';
      case CustomerAccountType.Y:
        return 'Community';
      case CustomerAccountType.Z:
        return 'Household';
    }
  };

  const documentClientListerlizerProps: undefined | DocumentClientListerlizerWrapperProps<GetCustomerContextRequest> = queryParams
    ? {
        ...customerListProps,
        queryHook,
        queryParams,
        queryControls: () => [
          <ButtonGroup>
            {selectedObjects.length > 0 && (
              <Button size='small' onClick={() => deleteMultipleCustomers()} startIcon={<DeleteIcon />} variant='outlined' color='primary'>
                Delete Selected
              </Button>
            )}
            <Button
              size='small'
              onClick={() => routerHistory.push(createUrl)}
              disabled={action === UserTransactionType.CREATE}
              startIcon={<AddIcon />}
              variant='outlined'
              color='primary'
            >
              {addCustomerText(accountType)}
            </Button>
          </ButtonGroup>,
        ],
        rowControlOverrideFns: {
          first: [
            (listRowProps: ListerlizerRowControlFnProps): JSX.Element[] => {
              return [
                <TableCell {...{ key: `${listRowProps.rowObject.sk}_goto` }}>
                  <Tooltip {...{ title: 'Details and Plans' }}>
                    <IconButton
                      {...{
                        onClick: () => {
                          const viewUrl = queryString.stringifyUrl({
                            url: `/${NavSections.THINGVIEW}/Customer/${listRowProps.rowObject.id}`,
                            query: {
                              contextObjectSK: contextCustomer.sk,
                            },
                          });
                          routerHistory.push(viewUrl);
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip {...{ title: 'Structure and Allocation' }}>
                    <IconButton
                      {...{
                        onClick: () => {
                          const viewUrl = queryString.stringifyUrl({
                            url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${listRowProps.rowObject.sk}`,
                            query: {
                              contextObjectSK: contextCustomer.sk,
                            },
                          });
                          routerHistory.push(viewUrl);
                        },
                      }}
                    >
                      {' '}
                      <PlayArrowIcon {...{}} />
                    </IconButton>
                  </Tooltip>
                </TableCell>,
              ];
            },
          ],
        },
      }
    : undefined;

  const createUrl = queryString.stringifyUrl({
    url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.CREATE}/Customer`,
    query: {
      objectTypeId: 'Customer',
      contextObjectSK: contextCustomer.sk,
      accountType,
      tabName: 'Details',
    },
  });

  return (
    <Grid className={componentClassName} container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent {...{ key: 'customerContextDocClientListCard' }}>
            <Typography variant='h4' component='h4' gutterBottom>
              {title ?? 'Customers'}
            </Typography>
            {documentClientListerlizerProps && (
              <DocumentClientListerlizerWrapper
                key={'customerContextDocClientList'}
                {...{ ...documentClientListerlizerProps }}
              ></DocumentClientListerlizerWrapper>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styles)(CustomerContextTab);
