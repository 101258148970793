import { CognitoUser } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TransactionFunctions, UserFunctions } from '../actions/AppActions';
import MessageView from '../components/MessageView';
import { constructItem } from '../data/daoFunctions/stateFns';
import { RootState } from '../reducers';
import { UserGroupsRolesType } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const mapStateToProps = (state: RootState, ownProps: any) => {
	debug && console.log('map state to props', state)
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer.user,
    viewObject: constructItem(state.AppReducer.normalData, state.AppReducer.user?.sk),
    messages: state.AppReducer.messages,
    errors: state.AppReducer.errors
  
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
		   debug && console.log('Message View requested init', ownProps)

    },
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageView)