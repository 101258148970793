import { AdjacentType, MapLike } from "@iotv/iotv-v3-types";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
//import config from '../../../../config'
import config from '../../config';
import { BaseFilterType, ComparisonType, ExpressionFunctionType, FilterPartType, GetQueryBodyRequest2, isComparisonType, isExpressionFunctionType, isNullValueFilterPartType, NullValueFilterPartType, QueryInputizerProps } from '../../types/AppTypes';

const debug = !process.env.AWS_SESSION_TOKEN && true;
const dyn = config.aws.dynamoDB;



const getKeyNameSub = (key: string) => `#${key}`
const getKeyValueSub = (key: string) => `:${key}`
const matchArrayPart = /\[\d+\]+/;
const replaceArrayPart = /\[\d+\]/g;
const replaceDotPart = /\./g;
const nothing = '';
const underscpre = '_';
const dot = '.'

const getFilterKeySubs = (filterGroupIdx: number, key: string) => {
    const keyNamePrefix = nothing; //`fg${filterGroupIdx}_`;
    const dotAccessParts = key.split(dot);
    const keyNameSubsAndArray: { keyNameSub: string, arrayPart: string | null }[]= dotAccessParts.map( (keyPart) => {
        const arrayPartRes = keyPart.match(matchArrayPart)
        const arrayPart = arrayPartRes && arrayPartRes[0];
        const noArrayKeyyPart = keyPart.replace(replaceArrayPart, nothing);
        return   { keyNameSub: getKeyNameSub(`${keyNamePrefix}${noArrayKeyyPart}`), arrayPart };
    });
    const keyValueSub = getKeyValueSub(`${keyNamePrefix}${key}`).replace(replaceDotPart, underscpre).replace(replaceArrayPart, nothing);
    const noArrayDotAccessParts = dotAccessParts.map( (dotAccessPart) => dotAccessPart.replace(matchArrayPart, nothing))
    return {
        dotAccessParts: noArrayDotAccessParts,
        keyNameSubs: keyNameSubsAndArray.map( ({ keyNameSub }) => keyNameSub ),
        predicateKeyName:  keyNameSubsAndArray.map(({ keyNameSub, arrayPart}) => `${keyNameSub}${arrayPart ?? nothing}` ).join(dot),
        keyValueSub
    }
}

const getFilterAddition = (keyNameSub: string , filter: BaseFilterType, keyValueSub: string): string => {
    let addition = '';
    if (isNullValueFilterPartType(filter)) {
        if ( filter.predicate === ExpressionFunctionType.EXISTS ) {
            addition =  `attribute_exists( ${keyNameSub} )`
        }
    } else {
        if (isComparisonType(filter.predicate)) {
            addition = `${keyNameSub} ${filter.predicate} ${keyValueSub}`
        } else if (isExpressionFunctionType(filter.predicate)) {
            addition = `${filter.predicate} ( ${keyNameSub}, ${keyValueSub})`
        }
    }
   
    return addition
}

export type DynIndexMap = { IndexName: string | undefined, hashKey: string, sortKey: string, hashKeyValue: string | undefined}

export const getQueryBody =  (  {  
   contextObject, adjacencyType, ExclusiveStartKey, limit = 200, filterGroups, objectTypeId

  }: GetQueryBodyRequest2 ): DocumentClient.QueryInput => {

    const keyMap: MapLike<DynIndexMap> = {
        [AdjacentType.PARENT] : { IndexName: dyn.gsiName, hashKey: dyn.sKey, sortKey: dyn.pKey, hashKeyValue: contextObject?.sk },
        [AdjacentType.CHILD] : { IndexName: undefined, hashKey: dyn.pKey, sortKey: dyn.sKey, hashKeyValue: contextObject?.pk},
        [AdjacentType.SUBJECT]:  { IndexName: dyn.typesiName, hashKey: dyn.typesiPrimaryKey, sortKey: dyn.typesiSortKey, hashKeyValue: objectTypeId},
        default: { IndexName: undefined, hashKey: dyn.pKey, sortKey: dyn.sKey, hashKeyValue: contextObject?.pk},

    }
 
    const { IndexName, hashKey, sortKey, hashKeyValue } = keyMap[adjacencyType]

    const hashKeyNameSub: string = getKeyNameSub(hashKey);
    const hashKeyValueSub: string = getKeyValueSub(hashKey);

    const params: QueryInputizerProps = {
      TableName: dyn.tableName,
      IndexName,
      ExclusiveStartKey,
      ExpressionAttributeValues: {
        [hashKeyValueSub]: hashKeyValue
      },
      Limit: limit,
      ExpressionAttributeNames: {
        [hashKeyNameSub]: hashKey,
      },
      KeyConditionExpression: `${hashKeyNameSub} = ${hashKeyValueSub}`,
      FilterExpression: undefined
    }

    if ( filterGroups ) {
        filterGroups.forEach( (filterGroup, fgIdx) => {
            let filterGroupKeyExpression = ''
            let filterGroupFilterExpression = ''
            filterGroup.filters.forEach( (filter, flIdx) => {
                const { dotAccessParts, keyNameSubs, predicateKeyName, keyValueSub } = getFilterKeySubs(fgIdx, filter.key);
                const filterAddition = getFilterAddition(predicateKeyName, filter, keyValueSub);
                keyNameSubs.forEach( (keyNameSub, i) => params.ExpressionAttributeNames[`${keyNameSub}`] = dotAccessParts[i] )
                params.ExpressionAttributeValues[`${keyValueSub}`] = filter.value;
                
                if ( filter.key === sortKey ) {
                    filterGroupKeyExpression = filterGroupKeyExpression ? filterGroupKeyExpression + ` ${filterGroup.setOperation} ${filterAddition}` : ` ${filterAddition} `
                } else { 
                    filterGroupFilterExpression = filterGroupFilterExpression ? filterGroupFilterExpression + ` ${filterGroup.setOperation} ${filterAddition}` : ` ${filterAddition} `
                }

            })
            if ( filterGroupKeyExpression ) {
                params.KeyConditionExpression = `${params.KeyConditionExpression} ${filterGroup.setOperation} ${filterGroupKeyExpression} `
            }
            if ( filterGroupFilterExpression ) {
                params.FilterExpression =  params.FilterExpression ? `${params.FilterExpression} ${filterGroup.setOperation} ( ${filterGroupFilterExpression} ) ` : ` ${filterGroupFilterExpression} `
            }
        })
    }
    return params;
}

