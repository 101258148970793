import { ObjectHistory, ValidBusinessObject, ValidModelType } from '@iotv/datamodel';
import { AppValueTypes, SortOrder } from '@iotv/iotv-v3-types';
import { IconButton, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { DownloadButton } from '../../../../components/common/ViewObjectDownloadButton';
// import { Listerlizer } from '../factories/Listilizer';
// import { SimpleExport } from 'io/simpleExport';
import { IOType, SimpleIO } from '../../../../components/io/simpleIO';
import { getNiceDate } from '../../../../data/TypeHelpers';
import { AdjacentType, ListerlizerProps, ValidCustomerObject, ViewDefinition, ViewKeyDefinitionType, ViewObject } from '../../../../types/AppTypes';
import { gapiIsPresent } from '../../../../util/Scripts/isPresent';
import styles from '../../cosmetics/sharedCardStyles';
import { Listerlizer } from './Listilizer';

const debug = process.env.REACT_APP_DEBUG && false;

const localStyle = {
  table: {},
  limitCardWidth: {
    scroll: true
  }
};
const useStyles = makeStyles((theme) => ({ ...localStyle, ...styles(theme)}));


type ObjectHistoryTableCardProps = Omit<ListerlizerProps, 'adjacentFilter' | 'selectorConfigParams' | 'sortKeyIn' | 'maxItems'> & {
    objectHistory: ObjectHistory | undefined
    filterMetricKeys? : string[];
    userSelectedGroup?: string
    title?: string,
    contextCustomer: ValidCustomerObject
    return2TankCard: () => void
}

const componentClassName = 'DataPage'

export default function DataPage( props: ObjectHistoryTableCardProps ) {
  const classes = useStyles();
  const parentHasRendered = () => document.querySelector(`.${componentClassName}`) !== null;

  const { viewObject: viewObjectIn, objectHistory, contextCustomer,  userSelectedGroup, filterMetricKeys, transactionFunctions, userFunctions, history, title } = props

  // debug &&
   console.log('DataPage', props)

  const historyStore = objectHistory?.recentStateHistory ?? objectHistory?.dailyStateHistory
  const historyStoreLastTimestamp = historyStore?.lastTimestamp(); 
  debug && console.log('historyStoreLastTimestamp', historyStoreLastTimestamp)
  useEffect( () => {
    debug && console.log('re rendering on changed matchedPrimary')
  }, [viewObjectIn?.matchedPrimary, historyStoreLastTimestamp])



  const stateSnapshots = historyStore ? historyStore.getStateSnapshotArray(SortOrder.DESCENDING) : [];
  const type = `${viewObjectIn.matchedPrimary?.type ?? 'Unkown'}_Metric`;
  const metricItems: ValidBusinessObject[] = stateSnapshots.map( ( stateSnapshot, i ) => {
    const { state: {...atts}, timestamp} = stateSnapshot

    const id = `${viewObjectIn.matchedPrimary?.id ?? 'unknownId'}_${i}_${timestamp}`;
    const pk = viewObjectIn.matchedPrimary?.sk ?? 'unknownParentSk';
    const sk = `${type}:${id}`

    return {
      type, sk, pk, id, ...atts, timestamp: timestamp ? getNiceDate(timestamp ) : undefined
    } as ValidBusinessObject
  })

  debug && console.log('DataPage metrics', metricItems)
  const viewObject: ViewObject = {
    matchedPrimary: viewObjectIn.matchedPrimary, matchedRelatedByPk: [], matchedRelatedBySk: metricItems,
  }

  debug && console.log('DataPage assinged', viewObjectIn.matchedRelatedBySk)
  
  const filterKeysToViewDefinition = (filterMetricKeys: string[] | undefined) => {
    const viewDefinition: ViewDefinition = {};
    filterMetricKeys?.forEach( (key) => {
      const viewDefinitionKey: ViewKeyDefinitionType = {
        key,
        editable: false,
        label: key.toUpperCase(),
        precision: undefined,
        stateFn: undefined,
        type: AppValueTypes.NUMBER,
        unit: undefined,
        validationRx: undefined,
        enumKV: undefined,
        failsValidationText: undefined
      };
      viewDefinition[key] = viewDefinitionKey
    })
    return viewDefinition;
  }

  const filterKeysByTypeToViewDefinition = (filterMetricKeys: string[] | undefined, arrayMap: { [k: string ]: Array<any>} | undefined) => {
    const keys = arrayMap ? filterMetricKeys?.filter( ( key ) => arrayMap[key] && arrayMap[key].every( (item) => item === null || typeof item !== 'object')) : []
    keys?.unshift('timestamp')
    return filterKeysToViewDefinition(keys)
  }

  const viewDefinition = filterMetricKeys ? filterKeysToViewDefinition(filterMetricKeys) : filterKeysByTypeToViewDefinition(historyStore?.keys(), historyStore);
  const adjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    objectType: type as ValidModelType
  };
  const pk = viewObjectIn.matchedPrimary?.sk ?? 'unknownParentSk';
  const downloadButton = <DownloadButton { ...{ key: `${pk}_objectHistoryCard_DownloadButton` ,  viewObject, viewDefinition, adjacentFilter }}></DownloadButton>

  const listerlizerProps: ListerlizerProps = {
    adjacentFilter,
    transactionFunctions,
    userFunctions,
    history,
    viewObject,
    contextObject: undefined,
    maxItems: 20,
    sortKeyIn: 'name',
    selectorConfigParams: {
      enabled: false,
      multipleSelect: false,
      controls: { add: false, find: false}
    },
    viewDefinition,
    // injectedComponents: downloadButton ? [downloadButton] : [],
  }

  debug && console.log('ObjectShadowHistoryStateCard matchedBySk', listerlizerProps.viewObject.matchedRelatedBySk)

  const returnTankCard=()=>{
console.log('data page returnTankCard');

    // props.returnTankCard()
  }

  return (
    <Card >
        {/* <CardHeader
        title = {title ?? 'Metrics History'}
      ></CardHeader> */}
       <CardContent className={classes.limitCardWidth}>
       <IconButton aria-label="delete" size="small" 
       onClick={() => {  props.return2TankCard() }} 
       >
        <ArrowBack fontSize="inherit" />
      </IconButton>
       <DownloadButton { ...{ key: `${pk}_objectHistoryCard_DownloadButton` ,  viewObject, viewDefinition, adjacentFilter }}></DownloadButton>

       {viewObject && gapiIsPresent() ? <SimpleIO 
          {...
            { 
              ioType: IOType.EXPORT, userSelectedGroup,
              contextCustomer: contextCustomer as ValidBusinessObject, viewDefinition, vobs: metricItems, userFunctions,
              parentHasRendered, setVBOsInParent: ( something: any) => {}, transactionFunctions
            }
          }/> : <Typography>Waiting for GAPI</Typography>}

        <Listerlizer {...{ key: `${objectHistory?.sk}_recentStateHistoryTable`,...listerlizerProps}}></Listerlizer>
      </CardContent>
   
    </Card>
  );
}