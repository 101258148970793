import { AppValueTypes } from '@iotv/iotv-v3-types';
import React from 'react';
import type { MapLike, ObjectListCardProps, ViewDefinition } from '../../types/AppTypes';
import { UserTransactionType } from '../../types/AppTypes';
import ListCard from '../cards/ListCard';



const debug = process.env.REACT_APP_DEBUG && false;



export default function ObjectListCard( props: ObjectListCardProps ) {

  const {searchParams, contextObject, userGroupRoles,  userFunctions, adjacentFilter, listConfigParams } = props

  debug && console.log('Object List Card userGroupRoles', userGroupRoles)

  const actions: UserTransactionType[] = [UserTransactionType.UPDATE]

  const viewDefinition: ViewDefinition = {
    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
    description: { key: 'description', label: 'About', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined},
  }

  const others: MapLike = listConfigParams;
  const maxItems = 20
  const { viewObject, transactionFunctions, history } = props

  debug && console.log('ViewObject in ObjectListCard', viewObject.matchedRelatedBySk)

  return <ListCard { ...{viewObject, userGroupRoles, contextObject,  viewDefinition, actions, adjacentFilter, transactionFunctions, userFunctions, maxItems, searchParams, history, others} }></ListCard>
}