// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import { ThingShadowState } from '@iotv/datamodel';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ValidCustomerObject, ViewKeyDefinitionType } from '../../../../types/AppTypes';
import ObjectHistoryTableCard from './DataCard';

// import ObjectHistoryTableCard from '../generic/ObjectHistoryTableCard';

const debug = process.env.REACT_APP_DEBUG && false;

type ObjectShadowStateCardProps = {
  matchedPrimary: ThingShadowState;
  viewKeyDefinition: ViewKeyDefinitionType;
};

type Order = 'asc' | 'desc';
type AmplifyPubSubUnsubscribeType = () => any;
const localStyle = {};
// const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  banner: {
    padding: '0px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      // outline: '2px auto rgba(19,124,189,.6)',
      // outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

export default function TankDataPage(props: ObjectShadowStateCardProps | any) {
  const { contextCustomer, viewObject, objectHistory, userFunctions, transactionFunctions, searchParams, actions, history, viewDefinition } = props;
  console.log({ contextCustomer, viewObject, objectHistory, userFunctions, transactionFunctions, searchParams, actions, history, viewDefinition });

  return (
    <div>
      {objectHistory && (
        <Grid item xs={12}>
          <ObjectHistoryTableCard
            {...{
              contextCustomer: contextCustomer as ValidCustomerObject,
              viewObject,
              contextObject: undefined,
              objectHistory,
              userFunctions,
              transactionFunctions,
              searchParams,
              history,
              actions,
              viewDefinition,
              filterMetricKeys: undefined,
              return2TankCard: props.returnTankCard,
              title: `${viewObject.matchedPrimary.name}'s history`,
            }}
          ></ObjectHistoryTableCard>
        </Grid>
      )}
    </div>
  );
}
