import { CardActions, FormControl, FormControlLabel, FormGroup, Paper, Switch, TableContainer } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { camelToDisplay } from '@iotv/datamodel-core';
import styles from '../../cosmetics/sharedCardStyles';
import { ListCardProps, SelectorConfigParams, ValidBusinessObject } from '../../types/AppTypes';
import { Listerlizer } from '../factories/Listilizer';
const useStyles = makeStyles((theme) => (styles(theme)));

const debug = process.env.REACT_APP_DEBUG && false;

export default function ListCard(props: ListCardProps) {

  const classes = useStyles();

  debug && console.log('List Card has usergrouproles', props.userGroupRoles)

  const { transactionFunctions, userGroupRoles, userFunctions, viewObject, contextObject: contextObjectIn, adjacentFilter, viewDefinition, maxItems, searchParams, history, others } = props

  const nameLabel = camelToDisplay(others?.label || adjacentFilter.objectType);

  const sortKeyIn = Object.keys(viewDefinition)[0];

  useEffect(() => {
    setContextObject(contextObjectIn)
  }, [contextObjectIn?.sk, userGroupRoles?.groups[0]])

  const [contextObject, setContextObject] = React.useState<ValidBusinessObject | undefined>(contextObjectIn)

  const selectorConfigParams: SelectorConfigParams = {
    enabled: true,
    multipleSelect: true,
    controls: {
      add: true,
      find: true,
    }

  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={`${nameLabel}`}
      ></CardHeader>
      <CardContent>
      { /*
        userGroupRoles?.groups.includes('HyperUser') && <FormGroup>
          <FormControl>
            <FormControlLabel
              {...{
                control: <Switch
                  {...{ value: contextObject !== undefined, onChange: () => setContextObject(contextObject ? undefined : contextCustomer) }}
                ></Switch>,
                label: `HyperUser only context ${contextObject ? 'customer' : 'nothing'}`
              }}
            />
          </FormControl>
        </FormGroup>
            */ }

        <TableContainer component={Paper} {...{ style: { overflowY: 'visible' } }}>

          <Listerlizer {...{ viewObject, contextObject, adjacentFilter, maxItems, transactionFunctions, userFunctions, viewDefinition, searchParams, sortKeyIn, history, selectorConfigParams, style: { overflowY: 'visible' } }}></Listerlizer>
        </TableContainer>
      </CardContent>
      <CardActions disableSpacing>
        <FormGroup row>

        </FormGroup>


      </CardActions>

    </Card>
  );
}
