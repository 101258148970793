import {
  AdjacentType,
  CustomerAccountType,
  NodeEdgeMapper,
  PathDirection,
  ValidBusinessObject,
  ValidBusinessObjectList,
  ValidModelObject,
} from '@iotv/datamodel-core';
import { getAdjacent2Wrapper } from '../../../../../data/daoFunctions/daoFunctions';
import AppDao from '../../../../../data/AppDao';
import { getCustomerHierarchy } from '../../../../../data/RoleQueries'; //'src/data/RoleQueries';
import { AttributeMap } from 'aws-sdk/clients/dynamodb';

export const getCustomerTree = async (context: ValidBusinessObject): Promise<NodeEdgeMapper> => {
  let mapper = await getCustomerHierarchy(context as ValidModelObject<'Customer'>);
  const nodeEdgeMapper = new NodeEdgeMapper();
  if (mapper.err == null && mapper.data != null) {
    const filterHierarchNodes: ValidBusinessObjectList = mapper.data.nodes.filter(
      (item: AttributeMap) => item.accountType !== CustomerAccountType.Z
    ) as ValidBusinessObjectList;
    const filterHierarchEdges: ValidBusinessObjectList = mapper.data.edges.filter(
      (item: AttributeMap) => item.accountType !== CustomerAccountType.Z
    ) as ValidBusinessObjectList;
    nodeEdgeMapper.loadItems(filterHierarchNodes);
    nodeEdgeMapper.loadItems(filterHierarchEdges);
  }
  return nodeEdgeMapper;
};

export const getAssignableCustomers = async (context: ValidBusinessObject): Promise<ValidBusinessObjectList> => {
  let assignable: ValidBusinessObjectList = [];
  let check: ValidBusinessObjectList = [context];

  let nodeEdgeMapper = await getCustomerTree(context); //getCustomerHierarchy(context as ValidModelObject<'Customer'>);
  while (check.length > 0) {
    let current = check[0];
    const res = nodeEdgeMapper.getChildNodes(current) as ValidBusinessObjectList;
    if (res) {
      // check if their children are all Y type
      if (res.length > 0 && res.every((cont) => cont.accountType === CustomerAccountType.Y)) {
        assignable.push(current);
      }
      res.forEach((data) => {
        if (data.accountType === CustomerAccountType.X) {
          check.push(data);
        }
      });
    }

    check.shift();
  }
  return assignable;
};

export const getFreeCustomers = async (context: ValidBusinessObject): Promise<ValidBusinessObjectList> => {
  // get all appropriate customers
  let customers = await getAssignableCustomers(context);

  // return customers without a plan assigned
  let combined = await customers.map(async (possible) => {
    const res = await AppDao.getAdjacent(possible, 'DroughtManagementPlan', PathDirection.child);
    if (res.data !== null && res.data.length === 0) {
      return possible;
    }
    return [];
  });
  let choices = await Promise.all(combined);

  return choices.flat();
};

export const getAssignableCustomerPlans = async (context: ValidBusinessObject): Promise<ValidBusinessObjectList> => {
  // get all appropriate customers
  let assignable = await getAssignableCustomers(context);

  // get plans made
  let combined = await assignable.map(async (possible) => {
    const res = await AppDao.getAdjacent(possible, 'DroughtManagementPlan', PathDirection.child);
    return res.data || [];
  });
  let promised = await Promise.all(combined);

  return promised.flat();
};
