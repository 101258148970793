import React, { ComponentType } from 'react';
import {  Redirect, useHistory } from 'react-router-dom';
import { ConnectedComponent } from 'react-redux';


const debug = process.env.REACT_APP_DEBUG && false;

type PrivateRouteParams = {
  Komponent: React.ComponentClass<any> | 
  ConnectedComponent<
    ComponentType<
      Pick<any, string | number | symbol> | any
      >
      , any
    > 

    , isAuthenticated?: boolean, children: any, computedMatch?: any, path?: string, exact?: any, location?: string, match?: { params: any},  appRef?: React.RefObject<HTMLDivElement>    
  }

const PrivateRoute = ({Komponent, isAuthenticated, match, ...rest}: PrivateRouteParams )=> {

  const history = useHistory();
	debug && console.log('Private rout has auth is ', { isAuthenticated} )
  return (
    isAuthenticated 
    ? 
    <Komponent { ...{ ...rest, match: rest.computedMatch,   history} }></Komponent>
    :
    (<Redirect to={{pathname: '/LOGIN', state: {from: rest.location}}}/>)
  );
}


export default PrivateRoute;