import {
  ButtonGroup,
  Grid,
  GridSize,
  IconButton,
  Paper,
  withStyles,
} from "@material-ui/core";
import { FastForward } from "@material-ui/icons";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import queryString from "query-string";
import React, { useEffect } from "react";
import styles from "../cosmetics/sharedViewStyles";
import {
  ObjectViewType,
  SearchParamsType,
  ThingsViewProps,
  ThingViewOptionals,
  UserTransactionType,
  ValidCustomerObject,
  ViewObject,
} from "../types/AppTypes";
import AddObject from "./menuItems/AddObject";
import SingleRowSwitch from "./menuItems/SingleRowSwitch";
import { NavSections } from "./navigation/NavSections";
import { getThingViewOfType } from "./thingViewArbitrator";
import config from "../config";
import { useHistory } from "react-router-dom";
import { MapLike } from "@iotv/iotv-v3-types";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

const redirectDebug = process.env.REACT_APP_DEBUG && false;
const maxItems = 20;

const getGridSettings = (projectCode: string) => {
  const key = projectCode.substring(0, 4);

  const sizeMap: MapLike<{
    xs?: boolean | GridSize | undefined;
    sm?: boolean | GridSize | undefined;
    md?: boolean | GridSize | undefined;
    lg?: boolean | GridSize | undefined;
    xl?: boolean | GridSize | undefined;
  }> = {
    v015: { xs: 12 },
    v017: { xs: 12, sm: 6, md: 4, lg: 2 },
    c021: { xs: 12, sm: 6, md: 4, lg: 2 },
    v016: { xs: 12, sm: 6, md: 4, lg: 3 },
    default: { xs: 12 },
  };

  return sizeMap[key] ?? sizeMap.default;
};

function ThingsViews(props: ThingsViewProps): JSX.Element {
  const LargerThanMobile = useMediaQuery('(min-width:600px)');

  redirectDebug && console.log('ThingsViewsLargerThanMobile', LargerThanMobile)
  const [spacing, _setSpacing] = React.useState(2);
  const [slice, setSlice] = React.useState([0, maxItems]);
  const {
    classes,
    viewObjects,
    childFirstGeofences,
    transactionFunctions,
    userFunctions,
    match,
    user,
    userGroupRoles,
    userSelectedGroup,
    contextCustomer,
    history,
    redirect,
    useSpecializedViews,
  } = props;

  redirectDebug && console.log('group role', userGroupRoles)

  redirectDebug && console.log("Things view got match", { match, redirect });

  redirectDebug && console.log("thingsview object", viewObjects);

  if (redirect && match.url.startsWith(`/${NavSections.THINGSVIEW}`)) {
    redirectDebug &&
      console.log("Cancelling redirect with ", { match, redirect });
    userFunctions.cancelRedirect(redirect);
  }

  const routerHistory = useHistory();

  if (viewObjects.length === 1 && viewObjects[0].matchedPrimary) {
    const { type, id } = viewObjects[0].matchedPrimary;
    const singleObjectRedirectUrl = `/${NavSections.THINGVIEW}/${type}/${id}`;
    routerHistory.push(singleObjectRedirectUrl);
  }

  const userCan = (action: UserTransactionType, type?: string) =>
    actions.includes(action) && (!type || !["Alert"].includes(type));

  const rewind = () => {
    setSlice([slice[0] - maxItems, slice[1] - maxItems]);
  };

  const fastForward = () => {
    setSlice([slice[0] + maxItems, slice[1] + maxItems]);
  };

  const searchParams: SearchParamsType | undefined = history.location?.search
    ? (queryString.parse(history.location.search) as SearchParamsType)
    : undefined;
  const actions: UserTransactionType[] = [UserTransactionType.CREATE_AND_LINK];

  const [checked, setChecked] = useState<boolean>(false);

  const getThingViewRow = (viewObject: ViewObject, i: number) => {
    const optionals: ThingViewOptionals = {
      childFirstGeofences,
      useSpecializedViews,

    };


    return (
      <Grid key={i} item {...{ ...getGridSettings(config.app.appCode) }}>
        <Grid container spacing={spacing as any}>
          <Grid key={`item_${i}`} item className={classes.item}>
            {getThingViewOfType({
              viewObject,
              contextCustomer,
              transactionFunctions,
              userFunctions,
              objectViewType: ObjectViewType.ListItem,
              history,
              searchParams,
              user,
              userGroupRoles,
              optionals,
              LargerThanMobile
            }) || (
              <Paper className={classes.paper}>
                {JSON.stringify(viewObject, null, 1)}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
        <Grid key="create" item xs={12}>
          {
            match.params.type &&
              user && contextCustomer &&
              userCan(UserTransactionType.CREATE_AND_LINK, match.params.type) &&
              ![ "v017"].includes(config.app.appCode) &&
              userSelectedGroup !== "User" && // should be user selected groupRole !== "User"
              (
                <AddObject { ...{
                  classes,
                  type: match.params.type,
                  label: `Add ${ match.params.type} to ${ contextCustomer.name }`,
                  addFunction: () =>
                    userFunctions.draftNewObject(
                      contextCustomer,
                      match.params.type as string,
                      history
                    )
                  }
                }
                  
                ></AddObject>
              )
            //  && <SingleRowSwitch checked={checked} onChange={(e: any) => setChecked(!checked)} />
          }
          {match.params.type === "Horse" && (
            <SingleRowSwitch
              checked={checked}
              onChange={(e: any) => setChecked(!checked)}
            />
          )}
          {viewObjects.length > maxItems && (
            <ButtonGroup>
              <IconButton
                aria-label="rwd"
                onClick={() => rewind()}
                disabled={slice[0] < 0}
              >
                <FastRewindIcon />
              </IconButton>
              <IconButton
                aria-label="ff"
                onClick={() => fastForward()}
                disabled={slice[1] >= viewObjects.length}
              >
                <FastForward />
              </IconButton>
            </ButtonGroup>
          )}
        </Grid>
        {viewObjects
          .filter((viewObject, i) => i >= slice[0] && i <= slice[1])
          .map((viewObject, i) => getThingViewRow(viewObject, i))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ThingsViews);
