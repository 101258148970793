import { useState, useEffect } from "react";
import { Table, TableCell, TableHead, TableRow, Typography, Grid, TableBody } from '@mui/material';
import { ViewDefinition, AreaManagementMode, ValidBusinessObject } from "../../../../../types/AppTypes";
import { AppValueTypes, MapLike } from "@iotv/iotv-v3-types";
import { getContainedPopulatedKeys, camelToDisplay } from '@iotv/datamodel-core';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { mapStyles } from '../../../../../cosmetics/MapCardStyles';
import styles from '../../../../../cosmetics/sharedViewStyles';

const debug = process.env.REACT_APP_DEBUG && false;

export type TableComponentProps = {
    objects: MapLike<any>[],
    keys?: string[],
    showNestedObjects?: boolean,
    title?: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setMode: React.Dispatch<React.SetStateAction<AreaManagementMode>>;
    setEditObject: React.Dispatch<React.SetStateAction<ValidBusinessObject | undefined>>;
    originalObj: MapLike<any>[]
}

export const areaTableViewDefinition: ViewDefinition = {
    name: { key: 'name', type: AppValueTypes.STRING, editable: true, label: 'Name' },
}

export const placeTableViewDefinition = {
    pk: { key: 'pk', type: AppValueTypes.STRING, editable: false, label: 'pk', show: false },
    name: { key: 'name', type: AppValueTypes.STRING, editable: true, label: 'Name', show: true },
    lat: { key: 'lat', type: AppValueTypes.NUMBER, editable: true, label: 'Lat', show: true },
    lng: { key: 'lng', type: AppValueTypes.NUMBER, editable: true, label: 'Lon', show: true }
}

const useStyles = makeStyles((theme) => {
    return {
        ...mapStyles(theme),
        ...styles(theme)
    }
});

export function TableComponent({ title, objects, keys, showNestedObjects = false, setOpen, setMode, setEditObject, originalObj }: TableComponentProps) {
    debug && console.log('objects', objects)

    const componentClassName = 'TableComponent';
    const localClasses = useStyles();

    const pluckedKeys = keys ?? getContainedPopulatedKeys(objects)

    return (
        <div {...{ className: clsx(localClasses.controlPanel, componentClassName), style: { left: '40vw' } }}>
            <Typography>{title ?? 'Table'}</Typography>
            <Grid container>
                <Grid item {...{ xs: 12 }}>
                    <Table 
                    // {...{ size: 'small' }}
                    >
                        <TableHead>
                            <TableRow {...{ key: `r_head` }}>
                                {pluckedKeys.map((k: string, i) => <TableCell {...{ key: `c_${i}` }}>{camelToDisplay(k)}</TableCell>)}
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {objects.map((object, i) =>
                                <TableRow {...{ key: `r_${i}` }} onDoubleClick={() => { 
                                    debug && console.log('ye bi ye bi', object) 
                                    setOpen(true)
                                    
                                    setMode(AreaManagementMode.EDIT)

                                    const tempObj = originalObj.filter((obj) => obj.pk === object.pk)

                                    if (tempObj.length > 0) {
                                        setEditObject(tempObj[0] as ValidBusinessObject)
                                    }

                                    
                                }}>
                                    {pluckedKeys.map((k: string, i) => <TableCell {...{ key: `c_${i}` }}>{!(object[k] instanceof Object) ? object[k] : showNestedObjects ? JSON.stringify(object[k], null, 1) : '[object]'}</TableCell>)}
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>

    )
}