import { getImageUrl, listObjectsAndUrls } from '../../../data/aws/s3/UserBlobs';
import { ObjectHistory, PathDirection, QueryTypes, ValidModelObject } from '@iotv/datamodel';
import { AppValueTypes, TraverserQueryPathBody } from '@iotv/iotv-v3-types';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Box,
  Card,
  Divider,
  CardContent,
  Avatar,
  CardHeader,
  Tab,
  Tabs,
  makeStyles,
  withStyles,
} from '@material-ui/core';
// import { APITypes } from '@iotv/iotv-v3-types';
import React, { useEffect } from 'react';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { useObjectHistory } from '../../../hooks/useObjectHistory';
import type { AdjacentFilter, GetAdjacentResponse, ObjectCardProps, SearchParamsType, ViewDefinition } from '../../../types/AppTypes';
import { AdjacentType, UserTransactionType, ValidBusinessObject } from '../../../types/AppTypes';
import TankDetailCard from './TankDetailsCard';
import TankFillGraphCard from './TankFillGraphCard';
import TankRainDataGraphCard from './TankRainDataGraphCard';
import TankUsageGraphCard from './TankUsageGraphCard';
import TankRainfallGraphCard from './TankRainfallGraphCard';

const googleChartUrl = config.google.chartApiUri;
const debug = process.env.REACT_APP_DEBUG && false;

// const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));

// const useStyles = makeStyles((theme) => styles(theme));
const useStyles = makeStyles((theme) => {
  return {
    ...styles(theme),
    root: {
      // flexGrow: 1,
      // // backgroundColor: theme.palette.background.paper,
      // position: "fixed",
      // bottom: "0"
      // , width: "100%"
    },
    inkBar: {
      backgroundColor: 'yellow',
    },
    tabs: {
      backgroundColor: 'red',
    },
    tab: {
      flexGrow: 1,
      // backgroundColor: theme.palette.background.paper,
      //  backgroundColor: '#2123d8',
      display: 'block',
      position: 'fixed',
      bottom: '0',
      // color: '#44bcd8',

      // width: "auto",
      // marginLeft: "0",
      // marginRight:"0"
      paddingLeft: '0',
      paddingRight: '0',
      width: '100%',
    },
    indicator: {},

    bottomTabStyle: {
      bottom: '0',
      // position: "fixed",
    },
    dividerColor: {},
  };
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function allyProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component='a'
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const HeaderTextTypography = withStyles({
  root: {
    color: '#44bcd8',
  },
})(Typography);
export default function TankCard(props: ObjectCardProps & { user?: ValidBusinessObject; searchParams?: SearchParamsType }, filterGraphKeys?: string[]) {
  debug && console.log('C021 Tank card got props', props);
  const { viewObject, transactionFunctions, userFunctions, searchParams, history, optionals, user, contextCustomer } = props;

  debug && console.log('Tank card got largeObject via viewObject', viewObject.largeObject !== undefined);

  const classes = useStyles();
  // if (props.viewObject.largeObject) console.log('props stringify', JSON.stringify(props.viewObject.largeObject, null, 1))
  // else console.log(' Large object not present')

  const [expanded, setExpanded] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [editView, setEditView] = React.useState(false);
  const [addView, setAddView] = React.useState(false);
  const [userHouseList, setUserHouseList] = React.useState<any>([]);
  const [isRainSensor, setIsRainSensor] = React.useState(false);
  const [objectHistory, setObjectHistory] = useObjectHistory(viewObject.matchedPrimary);

  const [imageUrl, setImageUrl] = React.useState<string>('');

  useEffect(() => {
    async function effectUrl() {
      const objectOrString = await getImageUrl(viewObject?.matchedPrimary);
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url);
        setImageUrl(url);
      }
    }

    effectUrl();
  }, [viewObject?.matchedPrimary]);

  useEffect(() => {
    setObjectHistory(undefined);
    async function effectUrl() {
      getImageUrl(props.viewObject.matchedPrimary).then((objectOrString) => {
        if (typeof objectOrString === 'string') {
          const url = objectOrString;
          debug && console.log('Use Effect has url', url);
          setImageUrl(url);
        }
      });
    }
    props.viewObject.matchedPrimary && effectUrl();
  }, [props.viewObject.matchedPrimary?.sk]);

  const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  debug && console.log('Tank Card objectHistory', objectHistory);

  useEffect(() => {}, [objectHistory]);

  React.useEffect(() => {
    async function getAdjacent(object: ValidBusinessObject, type: string, direction: PathDirection): Promise<GetAdjacentResponse> {
      debug && console.log('DAO TRANSACTION: Get Adjacent', { object, type, direction });
      const queryBody: TraverserQueryPathBody = {
        type: QueryTypes.simplePath,
        simpleTypePath: [{ type, direction }],
        normalize: false,
        items: [object], // CHILD should be from Datastore.pathDirection
      };
      debug && console.log('Get Adjacent query', queryBody);

      const path = '/query/';
      const { err, data } = await ApiGateway.post(path, queryBody);
      debug && console.log('get adjacent result', { err, data });
      return { err, data };
    }
    getAdjacent(contextCustomer as ValidBusinessObject, 'House', PathDirection.child).then(({ err, data }) => {
      debug && console.log('house list:', data);
      if (err == null && data != null) {
        var house_list: any = [];
        for (let i = 1; i < data.length; i++) {
          if (data[i].type === 'House') {
            let house: { id: string; label: string; house: any } = { id: '', label: '', house: {} };
            house.id = data[i].id;
            house.label = data[i].name;
            house.house = data[i];
            house_list.push(house);
            setUserHouseList(house_list);
          }
        }
      }
    });
    setIsRainSensor(viewObject.matchedPrimary?.isRainSensor);
  }, [props]);

  debug && console.log('userHouseList', userHouseList);
  const houseAdjacentFilter: AdjacentFilter = {
    adjacentType: AdjacentType.CHILD,
    edgeFilter: undefined,
    objectType: 'House',
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const listConfigParams = {
    label: 'House',
  };

  debug && console.log('tank props', props);
  var action: any;
  if (searchParams !== undefined) {
    action = searchParams.action;
  } else {
    action = 'X';
  }

  useEffect(() => {
    if (viewObject.matchedPrimary?.type === 'Tank' && !viewObject.largeObject) {
      debug && console.log(`not getting largeObject on ${!viewObject.matchedPrimary ? 'first' : 'matchedPrimary'}`);
      // transactionFunctions.getLargeObject(viewObject.matchedPrimary)
    }
    debug && console.log(`useEffect on  ${!viewObject.matchedPrimary ? 'first' : 'matchedPrimary'}`, viewObject);
    setIsRainSensor(viewObject.matchedPrimary?.isRainSensor);
  }, [viewObject.matchedPrimary?.sk]);

  // useEffect(() => {
  //   if (viewObject.matchedPrimary?.type === 'Tank' && !viewObject.largeObject) {
  //     debug && console.log(`getting largeObject on viewObject`)
  //     transactionFunctions.getLargeObject(viewObject.matchedPrimary)
  //   }
  // }, [viewObject]);
  debug && console.log(`viewObject.largeObject`, viewObject.largeObject);

  if (transactionFunctions === undefined) {
    debug && console.log('STOP TransactionFunctions is undediend');
  }

  debug && console.log(`Tank has largeObject populated? ${viewObject.largeObject !== undefined}`);

  let viewDefinition: ViewDefinition = {
    name: {
      key: 'name',
      label: 'Name',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    description: {
      key: 'description',
      label: 'About',
      type: AppValueTypes.STRING,
      editable: true,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
    type: {
      key: 'type',
      label: 'Type',
      type: AppValueTypes.STRING,
      editable: false,
      unit: undefined,
      precision: undefined,
      stateFn: undefined,
      validationRx: undefined,
    },
  };

  debug && console.log('Required to render Detail Card: ', { user, contextCustomer, userHouseList });
  return (
    <Card className={classes.root} {...{ style: { marginTop: '2rem' } }}>
      <CardHeader {...{ title: viewObject.matchedPrimary?.name ?? 'Tank' }} />
      {action !== undefined && action.localeCompare(UserTransactionType.CREATE_AND_LINK) !== 0 && (
        <Box justifyContent='flex-end' alignItems='flex-end'>
          <div className={classes.root}>
            <Tabs //position="static"
              value={tabValue}
              onChange={handleTabChange}
              aria-label='full width tabs example'
              // backgroundColor ='#'
              classes={{
                indicator: classes.indicator,
                // tab: classes.tab
              }}
              variant='fullWidth'
            >
              <LinkTab label='Details' {...allyProps(0)} />
              <Divider orientation='vertical' flexItem />
              <LinkTab label='Fill level graph' {...allyProps(1)} />
              <Divider orientation='vertical' flexItem />
              <LinkTab label='Usage graph' {...allyProps(2)} />
              <Divider orientation='vertical' flexItem />
              <LinkTab label='Rainfall Graph' {...allyProps(8)} />
              <Divider orientation='vertical' flexItem />
              <LinkTab label='Image & Notes' {...allyProps(6)} />
              {/* {isRainSensor && <Divider orientation='vertical' flexItem />}
              {isRainSensor && <LinkTab label='Rainfall Graph' {...allyProps(2)} />} */}


              {/* 
<Divider orientation="vertical" flexItem style={{ backgroundColor: '#a6a6a6 ' }} />
<LinkTab label="Rain Data"   {...a11yProps(2)} /> */}
            </Tabs>
          </div>
        </Box>
      )}

      {tabValue === 0 && (
        <>
          <Card className={classes.root}>
            <CardHeader>
              <HeaderTextTypography variant='subtitle1' align='center'>
                {viewObject.matchedPrimary?.name}
              </HeaderTextTypography>
            </CardHeader>

            <CardContent>
              {viewObject.matchedPrimary && user && contextCustomer && userHouseList && (
                <TankDetailCard {...{ ...props, objectHistory, user, contextCustomer, userHouseList }} />
              )}
            </CardContent>
          </Card>
        </>
      )}

      {tabValue === 2 && viewObject.matchedPrimary && (
        <Card className={classes.root}>
          <CardContent>
            <TankFillGraphCard {...{ matchedPrimary: viewObject.matchedPrimary as ValidModelObject<'Tank'>, objectHistory }} />
            {/* {historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys,'current_filled_volume')} */}
          </CardContent>
        </Card>
      )}
      {tabValue === 4 && viewObject.matchedPrimary && (
        <Card className={classes.root}>
          <CardContent>
            <TankUsageGraphCard {...{ matchedPrimary: viewObject.matchedPrimary as ValidModelObject<'Tank'>, objectHistory }} />
            {/* {historyObject && googleChartsScript && getGraph(historyObject, filterGraphKeys, 'volume_used_yesterday')} */}
          </CardContent>
        </Card>
      )}
      
      {tabValue === 6 && <TankRainfallGraphCard {...{matchedPrimary: viewObject.matchedPrimary as ValidModelObject<'Tank'>, objectHistory}} />}



      {tabValue === 8 && (
        <Card className={classes.root}>
          <CardContent>
            <Grid direction='row' container item>
              <Grid item {...{ xs: 12, sm: 3 }}>
                <h4>Image</h4>
                <Avatar style={{ width: '20vw', height: '20vw' }} aria-label='recipe' src={imageUrl}>
                  {viewObject.matchedPrimary
                    ? viewObject.matchedPrimary.name
                      ? (viewObject.matchedPrimary.name as string).substring(0, 1).toUpperCase()
                      : `${viewObject.matchedPrimary.type} ?`
                    : '??'}
                </Avatar>
              </Grid>
              <Grid item {...{ xs: 12, sm: 9 }}>
                <h4>Notes</h4>
                {viewObject?.matchedPrimary?.notes || 'No Notes'}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Card>
  );
}
