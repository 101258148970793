import { AdjacentType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import AppDao from '../../../../../../data/AppDao';

export const getCustomerRecipiants = async (customer: ValidBusinessObject): Promise<ValidBusinessObjectList> => {
  const res = await AppDao.queryByGraph({
    contextObjects: [customer],
    path: [{ objectTypeId: 'Recipient', adjacencyType: AdjacentType.CHILD }],
  });
  if (res.err === null && res.data !== null) {
    return (res.data.getChildNodes(customer) as ValidBusinessObjectList) || [];
  } else {
    return [];
  }
};

export const recipientsLabel = (listing: ValidBusinessObjectList | undefined): string => {
  return listing === undefined ? 'Finding' : listing.length === 0 ? 'No Recipients' : 'Select Recipients';
};
