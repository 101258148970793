import { connect } from 'react-redux'
import ThingView from '../components/ThingView'
import { RootState } from '../reducers'
import { AnyAction, bindActionCreators } from 'redux';
import { UserFunctions, TransactionFunctions } from '../actions/AppActions';
import { ThunkDispatch } from 'redux-thunk';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ValidBusinessObject } from '@iotv/datamodel';
import { constructItem, filterByTypeIds, flattenToArray} from '../data/daoFunctions/stateFns';
import { UserGroupsRolesType, ViewObject } from '../types/AppTypes';
import { GeofenceType } from '@iotv/datamodel';


type NormalizedData = undefined | {[sk: string]:  {[pk: string]: ValidBusinessObject | undefined}};
const debug = process.env.REACT_APP_DEBUG && false;
const normalDataDebug = process.env.REACT_APP_DEBUG && false;

const filter = (normalData: NormalizedData, match: {params: {type: string, id: string}}) => {
  
  const sk = `${match.params.type}:${match.params.id}`
  normalDataDebug && console.log('Normal Data in ThingViewContainer', normalData)
  return constructItem(normalData, sk);
}

const mapStateToProps = (state: RootState, ownProps: any) => {
    debug && console.log('map state to props', state.AppReducer.userGroupRoles)
    const viewObject: ViewObject = filter(state.AppReducer.normalData, ownProps.match);
    const largeObjectKey = viewObject.matchedPrimary?.sk
    const largeObject = largeObjectKey ? state.AppReducer.largeObjects[largeObjectKey] : undefined
    if ( largeObject ) viewObject.largeObject = largeObject
    debug && console.log('matchedPrimary', viewObject.matchedPrimary && JSON.stringify(viewObject.matchedPrimary, null, 1) );
    debug && console.log('matchedRelatedBySK', viewObject.matchedRelatedBySk)
    debug && console.log('ViewObject.matchedPrimary reallY?', (viewObject.matchedPrimary))
    const typeFilter = ['Geofence'];
    const childFirstGeofences: (ValidBusinessObject & GeofenceType)[] = flattenToArray(filterByTypeIds(state.AppReducer.normalData, typeFilter)) as (ValidBusinessObject & GeofenceType)[];
 
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer?.user,
    contextCustomer: state.AppReducer.contextCustomer,
    viewObject,
    redirect: state.AppReducer.redirect,
    useSpecializedViews: state.AppReducer.useSpecializedViews,
    childFirstGeofences,
    largeObject,
    history: ownProps.history
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
		 debug && console.log('ThingView  requested init', ownProps)
    },
    userFunctions: bindActionCreators(UserFunctions, dispatch),
    transactionFunctions: bindActionCreators(TransactionFunctions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThingView)