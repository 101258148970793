// import { Theme } from '@material-ui/core/styles';
// import { red } from '@material-ui/core/colors';

// const styles = (theme: Theme) => ({
//   root: {

//   },
//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//   },
//   expand: {
//     transform: 'rotate(0deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: 'rotate(180deg)',
//   },
//   avatar: {
//     backgroundColor: red[500],
//     height: '10vh',
//     width: '10vh'
//   },
//   title: {
//     fontSize: 'xl'
//   },
//   subCard: {
//     // width: '45%'
//   },
//   table: {

//   },
//   button: {
//     margin: theme.spacing(1),
//   },
//   tableCellNoPadding: {
//     padding: 'unset'
//   },
//   editFormControlFullWidth: {
//     width: '100%'
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   LineChartSlider: {

//   }
// });

// export default styles


import { Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const styles = (theme: Theme) => ({
  root: {},

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
    height: "10vh",
    width: "10vh",
  },
  title: {
    fontSize: "xl",
  },
  subCard: {
    // padding: 1
    margin: theme.spacing(3),
  },
  table: {},
  networkTable: {
    padding: 10,
    // backgroundColor:'white'
    // backgroundColor:'black'
    // backgroundColor: "#ffff"
  },
  button: {
    margin: theme.spacing(1),
  },
  tableCellNoPadding: {
    padding: "unset",
  },
  editFormControlFullWidth: {
    width: "100%",
  },
  networkMessageCell: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: '8pt',

    // minHeight: 0,
    // minWidth: 0,
  },
  networkMessageButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: '8pt',
    minHeight: 0,
    minWidth: 0,
    // width:'3%'
  //  transform: 'rotate(-45deg)',
  },
  messageCell: {
    // whiteSpace: "nowrap",
    // paddingTop: 0,
    // paddingBottom: 0
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  LineChartSlider: {},
  boxTab: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: theme.spacing(12),
  },
  waterHealthTabA: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
    // color: "#44bcd8"
    backgroundColor: "#99ff99   "
    // height: theme.spacing(12),
  },
  waterHealthTabB: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
    // color: "#44bcd8"
    backgroundColor: "#ffff66   "
    // height: theme.spacing(12),
  },
  waterHealthTabC: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
    // color: "#44bcd8"
    backgroundColor: "#ffcc99   "
    // height: theme.spacing(12),
  },
  waterHealthTabD: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
    // color: "#44bcd8"
    backgroundColor: "#ff8080"
    // height: theme.spacing(12),
  },
  waterHealthTabE: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
    color: "#fff",
    backgroundColor: "#566573"
    // height: theme.spacing(12),
  },
  waterHealthTab: {
    width: "100%",
    marginBottom: theme.spacing(1),
    height: "100%",
  },
  cardcontent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  dividerColor: {
    backgroundColor: "#44bcd8",
  },
  waterAvailabilityA: {
    backgroundColor: "#44bcd8"
  },
  displayNone: {
    display: 'none'
  },
  photoGalleryPhoto: {

    minHeight: '100px',
    maxWidth: '100px',

  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  photoGridFavIcon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  photoGridFavIconDefault: {
    color: 'rgba(255, 0, 0, 0.54)',
  },
  mapC021TankGroupCard: {
    // width: '100%',
    // height: '100%'
    width: '100vw',
    height: '90vh'
  },
  tabIndicator: {
    top: "0px",
    backgroundColor: '#44bcd8'
  },
  bottomTab: {
    // color: '#44bcd8',
   // backgroundColor:'#fff'
    // position: 'fixed',
    bottom: '0',
    width: '95%',
  },
  c021GITCard: {
    marginBottom: '30px'
  },
  c021GITIndividualCard: {
    marginBottom: '5px'
  }
});

export default styles;

