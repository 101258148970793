import { Assembler, PathDirection, PossibleBusinessObject, ValidBusinessObject } from '@iotv/datamodel-core';
import { createAndLink, CreateAndLinkRequest } from '../../../data/daoFunctions/daoFunctions';

/**
 * Adds an object to the database. When complete, go to the file
 * @param newObject Object to be added
 * @param parent Parent of the new object
 * @param edgeType string type from `edgeTypeMatrix`
 * @returns The new object, if valid
 */
export const addObjectToDB = async (
  newObject: PossibleBusinessObject,
  parent: ValidBusinessObject,
  edgeType: string = ''
): Promise<ValidBusinessObject | undefined> => {
  const instance: ValidBusinessObject | undefined = Assembler.getInstance(newObject);
  if (instance) {
    const params: CreateAndLinkRequest = {
      existingObject: parent,
      newObject: instance,
      edgeTypeId: edgeType,
      direction: PathDirection.child,
    };
    const linkRes = await createAndLink(params);
    console.log('linkRes', linkRes);

    const newObj = linkRes.data?.[1];
    return newObj;
  }
  return undefined;
};
