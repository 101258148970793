import { safeJSON } from '../../util/AppData/safeJSON';
import type { LocalStorageType } from './localStorageType';

const debug = process.env.REACT_APP_DEBUG && false;

const  get =  <N extends keyof LocalStorageType>( navSection:  N, attributeKey: keyof LocalStorageType[N]  ) => {
    const sectionStr = localStorage.getItem( navSection );
    const objectAtSection = safeJSON.parse(sectionStr) 
    debug && console.log(  `got section ${navSection}, attributeKey ${String(attributeKey)} as`, objectAtSection )

    return objectAtSection && objectAtSection[attributeKey as string]
}



const set = <N extends keyof LocalStorageType, A extends keyof LocalStorageType[N], V extends  LocalStorageType[N][A] >( navSection:  N, attributeKey: A, value: V ) => {
    const existingSectionStr = localStorage.getItem( navSection );
    const objectAtSection = safeJSON.parse(existingSectionStr) 
    const newValue = { ...objectAtSection, [attributeKey]: value};
    const sectionStr = JSON.stringify(newValue);
    debug && console.log(  `save section ${navSection}, attributeKey ${String(attributeKey)}, value ${newValue} as`, newValue )
    localStorage.setItem( navSection, sectionStr )



}

export const AppLocalStorage = {
   get, set
}