import { Gateway, GeoPoint, MapLike, ValidBusinessObject, ValidBusinessObjectList, ValidModelObject, ValidModelObjects } from '@iotv/datamodel';
import { CustomerAccountType, flattenObject, NodeEdgeMapper } from '@iotv/datamodel-core';
import { DatastoreObjectType, ErrData, GetGetGraphTraversalRequest, GetGetGraphTraversalResponse, MetricStatsType, TypeHierarchyRequest, TypeHierarchyResponse } from '@iotv/iotv-v3-types';
import { makeStyles, Paper, withStyles } from '@material-ui/core';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TreeView } from '@mui/lab';
import { Button, Card, Grid } from '@mui/material';
import { AttributeMap, DocumentClient } from 'aws-sdk/clients/dynamodb';
import React, { useEffect, useState } from 'react';
import { mapStyles } from '../../cosmetics/MapCardStyles';
import styles from '../../cosmetics/sharedViewStyles';
import ApiGateway from '../../data/aws/api-gateway/ApiGateway';
import { getAdjacent2Wrapper } from '../../data/daoFunctions/daoFunctions';
import { AppLocalStorage } from '../../data/LocalStorage/AppLocalStorage';
import { AdjacentType, AreaManagementMode, DispatchFunctionsType, MapCardProps, NormalizedData, UserFunctions, UserTransactionType } from '../../types/AppTypes';
import { GoogleMap, GoogleMapPropsV2 } from '../geo/v2';
import AddArea from '../geo/v2/AddArea';
import AddPlace from '../geo/v2/AddPlace';
import AddPlaceType from '../geo/v2/AddPlaceType';
import AddStyler from '../geo/v2/AddStyler';
import { TableComponent } from '../geo/v2/components/table/TableComponent';
import { generateTree } from '../geo/v2/components/tree/generateTree';
import { DeveMapInfo } from '../geo/v2/DevMapInfo';
import { useBoundsChange } from '../geo/v2/hooks/useBoundsChange';
import { addDrawingLayer } from '../geo/v2/mapfns/addDrawingLayer';
import { addDramToDataLayer, markerHash, placeHash, polygonHash } from '../geo/v2/mapfns/addDrawToDatalayer';
import { addHTMLOverlay } from '../geo/v2/mapfns/addHTMLOverlay';
import { perc2color } from '../geo/v2/mapfns/applyStyle';
import { getGatewaysByBounds } from '../geo/v2/mapfns/getGateways';
import { getS2PointsByBounds } from '../geo/v2/mapfns/getS2Points';
import { addBoundsChangeIdleListener, addBoundsChangeZoomListener } from '../geo/v2/mapfns/Listeners/boundsChange';
import { useGoogleMyMapsKMLLayer } from '../geo/v2/mapfns/useKMLLayer';
import MyIcons from '../geo/v2/MyIcons';
import { PointInfoTable } from '../geo/v2/PointInfoTable';
import { NavSections } from '../navigation/NavSections';
import { CollapsibleDraggable } from '../utils/CollapsibleDraggable';
import { addGatewayToPointOverlay } from './displayFns/addGatewayToPointOverlay';

const debug = process.env.REACT_APP_DEBUG && false;

const KM = 1000;

const useStyles = makeStyles((theme) => {
  return {
    ...mapStyles(theme),
    ...styles(theme)
  }
});



export type SurveyViewProps = MapCardProps & {
  classes: any, normalData?: NormalizedData, googleMapScriptLoaded?: boolean, userFunctions: UserFunctions
  , userSelectedGroup: string | undefined, contextCustomer: (ValidBusinessObject & { type: 'Customer' }) | undefined, history: History, transactionFunctions: DispatchFunctionsType, actions: UserTransactionType[]
}

export const convertLocationToPolygon = (resData: DocumentClient.ItemList | undefined, polygons: any[], setPolygons: React.Dispatch<React.SetStateAction<{ [k: string]: google.maps.Polygon; }[]>>,) => {
  //list of areas, one area one polygon
  //list of points -> coordinates

  // const temppolygons: { [k: string]: google.maps.Polygon }[] = [];
  const temppolygons: any[] = [];
  resData?.forEach((area) => {
    if (area.locations && area.locations.length > 0) {
      let coordinates = [];
      for (var i = 0; i < area.locations.length; i++) {
        let coordinate = [area.locations[i].lng, area.locations[i].lat];
        coordinates.push(coordinate);
      }
      if (area.locations.length > 0) {
        coordinates.push([area.lastPoint.lng, area.lastPoint.lat])
      }

      debug && console.log('coordinates', area.pk, coordinates)
      // const polygon = new google.maps.Polygon({
      //   paths: coordinates,
      //   strokeColor: "#FF0000",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: area.color ?? '#FF0000',
      //   fillOpacity: area.opacity ?? 0.35,

      // });
      const polygon = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              pk: area.pk,
              fillColor: area.color ?? '#FF0000',
              fillOpacity: area.opacity ?? 0.35,
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              type: 'Area'
            },
            geometry: {
              type: "Polygon",
              coordinates: [coordinates],
            },
          }
        ]

      }

      temppolygons.push(polygon)
    }

  })
  debug && console.log('polygons', temppolygons)
  polygons = polygons.concat(temppolygons);
  debug && console.log('concat', polygons)
  setPolygons(polygons)



}

export const convertPlacesToMarkers = (data: any[], markers: any[], setMarkers: React.Dispatch<React.SetStateAction<any[]>>) => {
  debug && console.log('convertPlacesToMarkers', markers)
  const tempMarkers: any[] = [];
  data.forEach((val) => {
    debug && console.log('converting place', val)
    if (val.location) {

      const mark = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              pk: val.pk,
              color: val.color,
              type: 'Place',
              icon: val.icon
            },
            geometry: {
              type: "Point",
              coordinates: [val.location.lng, val.location.lat],
            },

          },
        ],
      }

      tempMarkers.push(mark);
    }
  })


  markers = markers.concat(tempMarkers)
  debug && console.log('new converted markers', markers)

  setMarkers(markers);

  return markers;
}

export const updateStateList = (state: ValidBusinessObject[] | undefined, obj: ValidBusinessObject | undefined, newObj: ValidBusinessObject, valueSetter: React.Dispatch<React.SetStateAction<ValidBusinessObject[] | undefined>>) => {
  var foundIndex = state?.findIndex((val) => val.pk === obj?.pk)
  if (foundIndex !== undefined && state) {
    state[foundIndex] = newObj;

    valueSetter(state)
  }
}



// get area hierarchy
// list of areas, each area call getCustomerHierarchy

const userViaCustomersTypeRoleQuery = async function (area: ValidBusinessObject[], customerRelatedObjectTypeId?: string, normalizedData?: NormalizedData) {

  const path = '/graphQuery';

  const request: GetGetGraphTraversalRequest = {
    contextObjects: area,
    path: [
      {
        objectTypeId: 'Area', adjacencyType: AdjacentType.CHILD, edgeTypeId: undefined,
      },
      {
        objectTypeId: 'Place', adjacencyType: AdjacentType.CHILD, edgeTypeId: undefined,
      },
    ]
  }

  const responseBody: ErrData<GetGetGraphTraversalResponse> = await ApiGateway.post(path, request) as ErrData<GetGetGraphTraversalResponse>;

  return responseBody;

  // debug && console.log('userViaCustomersTypeRoleQuery Res', responseBody);

  // if (responseBody.data) {
  //   const { nodes, edges } = responseBody.data.hierarchy as TypeHierarchyResponse
  //   // return [nodes, edges];
  //   // [...nodes, ...edges].forEach((item) => addToNormalData(item as PossibleBusinessObject, normalizedData))
  // } else {
  //   console.log(`Err in userViaCustomersTypeRoleQuery ${responseBody.err?.message}`)
  // }

  // return normalizedData;
  // return undefined
}




function SurveyViewBase(props: SurveyViewProps): JSX.Element {
  const localClasses = useStyles();
  const { classes, geoImplementingObject, normalData = {}, viewObjects, polygons, locatableAddress, userSelectedGroup, googleMapScriptLoaded, transactionFunctions, contextCustomer, userFunctions, actions, history } = props;

  debug && console.log('Survery view got', props)
  debug && console.log('Survery view  Editable', props.editable, geoImplementingObject)

  const [map, setMap] = React.useState<google.maps.Map<Element> | undefined>(undefined);


  const [boundsHash, setBoundsHash] = useBoundsChange(map)

  const [gateways, setGateways] = React.useState<Gateway[]>([])
  const [s2Points, setS2Points] = React.useState<GeoPoint[]>([])

  const [loadedFeatures, setLoadedFeatures] = React.useState<boolean>(false);



  useEffect(() => {
    debug && console.log('used effect on SurveryView gateways or points', [gateways.length, s2Points.length]);
    if (map && gateways.length > 0 && s2Points.length > 0) {
      debug && console.log('getting addGatewayToPointOverlay')
      addGatewayToPointOverlay(map, gateways, s2Points)
      setLoadedFeatures(true)
    } else {
      debug && console.log('Did not add lines', { map, gwLength: gateways.length, s2PointLenght: s2Points.length })
    }
  }, [gateways.length, s2Points.length])

  const [areas, setAreas] = useState<ValidBusinessObject[] | undefined>(undefined);
  const [places, setPlaces] = useState<ValidBusinessObjectList | undefined>(undefined);
  const [editObject, setEditObject] = useState<ValidBusinessObject | undefined>(undefined);
  const [placeStyler, setPlaceStyler] = useState<ValidBusinessObject[] | undefined>(undefined);
  const [areaStyler, setAreaStyler] = useState<ValidBusinessObject[] | undefined>(undefined);
  const [Apolygons, setPolygons] = useState<{ [k: string]: google.maps.Polygon }[]>([]);

  const [markers, setMarkers] = useState<any[]>([]);

  const [open, setOpen] = React.useState<boolean>(false);
  const [place, setPlaceOpen] = React.useState<boolean>(false);
  const [styler, setStylerOpen] = React.useState<boolean>(false);
  const [iconOpen, setIconOpen] = React.useState<boolean>(false);

  const [mode, setMode] = React.useState<AreaManagementMode>(AreaManagementMode.ADD);

  const [placeType, setPlaceTypeOpen] = React.useState<boolean>(false);
  const [placeTypes, setPlaceTypes] = React.useState<ValidBusinessObject[] | undefined>(undefined);

  const [addMarkers, setAddMarkers] = React.useState<any[]>([]);
  const [addAreas, setAddAreas] = React.useState<any[]>([]);

  const [refresh, setRefresh] = React.useState<number>(0);

  // tree item
  const [nodeEdgeMapper, setNodeEdgeMapper] = useState<NodeEdgeMapper | undefined>(undefined)
  const [nodesToDisplay, setNodesToDisplay] = useState<ValidBusinessObjectList>([])
  const [selectedParentSks, setSelectedParentSks] = useState<string[]>([]) // strings to match nodeIds of TreeView


  const markerhash = markerHash(addMarkers);
  const polygonhash = polygonHash(addAreas);

  const placeshash = places && placeHash(places);



  const getCustomerHierarchy = async (contextCustomer: ValidModelObject<'Customer'> | undefined) => {
    let res: ErrData<TypeHierarchyResponse> = { err: null, data: null }
    if (contextCustomer) {
      const queryBody: TypeHierarchyRequest = {
        apexNode: contextCustomer,
        edgeParams: {
          objectTypeId: 'Customer'
        },
        maxIterations: 10
      };

      const path = '/typeHierarchy/';
      res = await ApiGateway.post(path, queryBody);
    }

    return res;
  }

  useEffect(() => {
    // check if the customer is the lowest level
    debug && console.log('area tree', areas)
    if (areas) {
      userViaCustomersTypeRoleQuery(areas).then(({ err, data }) => {
        debug && console.log('userViaCustomersTypeRoleQuery', data)

        if (err == null && data != null) {
          const nodeEdgeMapper = new NodeEdgeMapper()
          const filterHierarchNodes: ValidBusinessObjectList = data?.hierarchy.nodes.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
          const filterHierarchEdges: ValidBusinessObjectList = data?.hierarchy.edges.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
          nodeEdgeMapper.loadItems(filterHierarchNodes)
          nodeEdgeMapper.loadItems(filterHierarchEdges)
          debug && console.log('CommunitiesView Hierarchy', { nodeEdgeMapper })
          setNodeEdgeMapper(nodeEdgeMapper)
          setNodesToDisplay(nodeEdgeMapper.getDecendents([contextCustomer as DatastoreObjectType]) as ValidBusinessObjectList)
        }

      })
    }

    const treeCompo = (
      <TreeView {...{
        // defaultExpanded: [contextCustomer.sk], //TDOD
        defaultCollapseIcon: <  CheckBoxIcon />,
        defaultExpandIcon: < CheckBoxOutlineBlankIcon />,
        // onNodeToggle: handleChange,
        expanded: selectedParentSks
      }}

      >
        {nodeEdgeMapper && areas && generateTree(areas, nodeEdgeMapper)}
      </TreeView>


    )
    debug && console.log('treeCompo', treeCompo)

    // getCustomerHierarchy(contextCustomer as ValidModelObject<'Customer'>).then(({ err, data }) => {
    //   if (err == null && data != null) {
    //     const nodeEdgeMapper = new NodeEdgeMapper()
    //     const filterHierarchNodes: ValidBusinessObjectList = data.nodes.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
    //     const filterHierarchEdges: ValidBusinessObjectList = data.edges.filter((item: AttributeMap) => (item.accountType !== CustomerAccountType.Z)) as ValidBusinessObjectList
    //     nodeEdgeMapper.loadItems(filterHierarchNodes)
    //     nodeEdgeMapper.loadItems(filterHierarchEdges)
    //     debug && console.log('CommunitiesView Hierarchy', { nodeEdgeMapper })
    //     setNodeEdgeMapper(nodeEdgeMapper)
    //     setNodesToDisplay(nodeEdgeMapper.getDecendents([contextCustomer as DatastoreObjectType]) as ValidBusinessObjectList)
    //   }
    // }
    // )


  }, [areas?.length])


  useEffect(() => {
    if (contextCustomer) {

      getAdjacent2Wrapper({ scopeDefinitingContextObject: (contextCustomer as ValidBusinessObject), objectTypeId: 'Area', adjacencyType: AdjacentType.CHILD, includeEdges: false }).then((res) => {
        debug && console.log('res.data', res.data);

        setAreas(res.data?.Items?.filter((item) => item.type === "Area") as ValidBusinessObject[])

        debug && console.log('!!!!', areas)
        convertLocationToPolygon(res.data?.Items, Apolygons, setPolygons)

      })
    }


  }, [contextCustomer])




  useEffect(() => {
    if (contextCustomer) {
      getAdjacent2Wrapper({ scopeDefinitingContextObject: (contextCustomer as ValidBusinessObject), objectTypeId: 'Place', adjacencyType: AdjacentType.CHILD, includeEdges: false }).then((res) => {
        debug && console.log('oh my god', res.data?.Items)


        if (res.data?.Items) {
          const list = res.data.Items.filter((val) => val.type === "Place");
          debug && console.log('places', list)
          setPlaces(list as ValidBusinessObject[])
          convertPlacesToMarkers(res.data?.Items, markers, setMarkers)
        }

      })
    }
  }, [contextCustomer])

  const clearMarkersOnMap = () => {
    if (map) {
      map.data.forEach((feature) => {
        const type = feature.getProperty('type');

        if (type === 'Place') {
          map.data.remove(feature)
        }

      })
    }
  }

  const generateMarkers = (markers: any[]) => {
    if (map) {
      // map.data.forEach((feature) => {
      //   const type = feature.getProperty('type');

      //   if (type === 'Place') {
      //     map.data.remove(feature)
      //   }

      // })
      clearMarkersOnMap();
      debug && console.log('new markers!!!!!', markers.length, places?.length)

      markers.map((marker) => {
        debug && console.log('details new marker', marker)
        map.data.addGeoJson(marker)
      })

      map.data.addListener("dblclick", (event) => {
        const type = event.feature.getProperty('type')
        const pk = event.feature.getProperty('pk');
        debug && console.log("double click on markers", type, pk)
        if (type === "Place") {
          setMode(AreaManagementMode.EDIT)
          const tempPlace = places?.filter((val) => val.pk === pk)
          if (tempPlace && tempPlace?.length > 0) {
            setEditObject(tempPlace[0]);
          }

          debug && console.log("Double Click on place", tempPlace);

          setPlaceOpen(true);
        }
      })

      map.data.forEach((feature) => {
        const type = feature.getProperty('type');

        if (type === 'Place') {
          const color = feature.getProperty('color');
          // const opacity = feature.getProperty('opacity');
          const icon = feature.getProperty('icon');

          debug && console.log('feature', icon)

          map.data.overrideStyle(feature, {
            icon: icon
          })
        }
      })
    }
  }

  useEffect(() => {
    debug && console.log('detect places changed', placeshash, places)
    clearMarkersOnMap();
    // regenerate markers
    var newMarkers = places && convertPlacesToMarkers(places, [], setMarkers)
    debug && console.log('places changed new markers', newMarkers)
    newMarkers && generateMarkers(newMarkers);
  }, [placeshash])



  useEffect(() => {
    generateMarkers(markers)

  }, [markers.length])

  // useEffect(() => {
  //   debug && console.log('placeshash', placeshash)
  //   generateMarkers()
  // }, [placeshash])

  useEffect(() => {
    if (map) {

      map.data.forEach((feature) => {
        debug && console.log('area feature', feature)
        const type = feature.getProperty('type');
        if (type === 'Area') {
          map.data.remove(feature)
        }

      })

      debug && console.log('new areas!!!!!', areas?.length, Apolygons?.length)

      Apolygons.map((polygon) =>
        map.data.addGeoJson(polygon)
      )

      map.data.forEach((feature) => {
        const type = feature.getProperty('type');

        if (type === 'Area') {
          const color = feature.getProperty('color');
          const opacity = feature.getProperty('opacity');

          map.data.overrideStyle(feature, {
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color ?? '#FF0000',
            fillOpacity: opacity ?? 0.35,
          })
        }
      })

      map.data.addListener("dblclick", (event) => {
        const type = event.feature.getProperty('type')
        const pk = event.feature.getProperty('pk');
        debug && console.log("double click on polygons", type, pk)
        if (type === "Area") {
          setMode(AreaManagementMode.EDIT)

          const tempArea = areas?.filter((area) => area.pk === pk)
          debug && console.log("double click on polygon", tempArea)

          if (tempArea && tempArea?.length > 0) {
            setEditObject(tempArea[0]);
          }

          setOpen(true)
        }
      })

    }

    // if (dataLayer) {
    //   //remove all features on datalayer
    //   dataLayer.forEach((feature) => dataLayer.remove(feature))
    // }
  }, [Apolygons.length])

  useEffect(() => {
    getAdjacent2Wrapper({ scopeDefinitingContextObject: (contextCustomer as ValidBusinessObject), objectTypeId: 'PlaceStyler', adjacencyType: AdjacentType.CHILD, includeEdges: false }).then((res) => {
      debug && console.log('place styler res.data', res.data);
      setPlaceStyler(res.data?.Items as ValidBusinessObject[])
    })
  }, [contextCustomer])

  useEffect(() => {
    getAdjacent2Wrapper({ scopeDefinitingContextObject: (contextCustomer as ValidBusinessObject), objectTypeId: 'AreaStyler', adjacencyType: AdjacentType.CHILD, includeEdges: false }).then((res) => {
      debug && console.log('area styler res.data', res.data);
      setAreaStyler(res.data?.Items as ValidBusinessObject[])
    })

    getAdjacent2Wrapper({ scopeDefinitingContextObject: (contextCustomer as ValidBusinessObject), objectTypeId: 'PlaceType', adjacencyType: AdjacentType.CHILD, includeEdges: false }).then((res) => {
      debug && console.log('place types res.data', res.data);
      setPlaceTypes(res.data?.Items as ValidBusinessObject[])
    })
  }, [contextCustomer])


  const [rolloverOverlay, _setRolloverOverlay] = React.useState<google.maps.OverlayView | undefined>(undefined);
  const rolloverOverlayRef = React.useRef<google.maps.OverlayView | undefined>(rolloverOverlay);
  const setRolloverOverlay = (rolloverLayer: google.maps.OverlayView | undefined) => {
    rolloverOverlayRef.current = rolloverLayer;
    _setRolloverOverlay(rolloverLayer);
  };

  const [kmlFileLinks, setKmlFileLinks] = React.useState<ValidModelObjects<'KMLFileLink'>>([]);
  const kmlFileLinkHash = kmlFileLinks.map((file) => file.id).join(':')
  const features = { myMapKMLs: kmlFileLinks }
  useGoogleMyMapsKMLLayer(map, features)


  useEffect(() => {
    // remove all features in the drawing layer

    debug && console.log('addMarkers', addMarkers, markerhash)
    if (addMarkers && addMarkers.length > 0) {
      setMode(AreaManagementMode.ADD);
      setPlaceOpen(true);
    }
  }, [markerhash])

  useEffect(() => {
    // remove all features in the drawing layer

    debug && console.log('hurry, new polygons!', addAreas, polygonhash)
    if (addAreas && addAreas.length > 0) {
      setMode(AreaManagementMode.ADD);
      setOpen(true);
    }
  }, [polygonhash])

  useEffect(() => {
    const showDrawingLayer = true

    if (map && showDrawingLayer) {
      const { drawingManager, dataLayer } = addDrawingLayer(map)

      addDramToDataLayer(dataLayer, drawingManager, addMarkers, setAddMarkers, addAreas, setAddAreas, map)



      debug && console.log("dataLayer", dataLayer)
    } else {
      // map.data.setDrawingMode('Polygon')
    }
    // set bounds change listener

    debug && console.log('use effect of map to load style');
    map?.data.setStyle(function (feature) {
      let style: MapLike<any> = { strokeWeight: 0 }

      const featureStyle: MapLike<MetricStatsType> = feature.getProperty('style');
      if (featureStyle) {
        style = { ...style, ...featureStyle }
      }

      // const statistics = feature.getProperty('statistics');
      // debug && console.log(`Feature ${feature.getId()} stats`, statistics)

      const dataCount = feature.getProperty('dataCount')
      if (feature.getProperty('dataCount')) {
        const color = perc2color(dataCount / 0.25)
        style = {
          ...style,
          fillColor: color,
          strokeColor: color,
          zIndex: 10
        }

      }

      return style
    });
  }, [map])


  const loadS2Data = (event: any) => {
    if (map) {

      map.overlayMapTypes.clear()

      Promise.all([
        getS2PointsByBounds(map, setS2Points),
        getGatewaysByBounds(map, setGateways),
        //getNamedPointsByBounds(map)
      ])
    }
  }

  useEffect(() => {
    debug && console.log('Main useEffect')
    if (map) {
      const boundsLatLng = {
        "ne": {
          "lat": -36.90495693699118,
          "lng": 175.02948435287857
        },
        "sw": {
          "lat": -36.91020718282494,
          "lng": 175.02027364712143
        }
      }

      const ne: google.maps.LatLng = new google.maps.LatLng(boundsLatLng.ne);
      const sw: google.maps.LatLng = new google.maps.LatLng(boundsLatLng.sw);

      const lastViewedBounds = AppLocalStorage.get(NavSections.SURVEYVIEW, 'lastViewedBounds')

      const bounds = lastViewedBounds ?? new google.maps.LatLngBounds(sw, ne);
      map.fitBounds(bounds)

      const getRolloverOverylay = () => {
        debug && console.log('getting rolloever overlay', rolloverOverlay)
        return rolloverOverlay
      }

      AppLocalStorage.get(NavSections.SURVEYVIEW, 'lastViewedBounds')

      addBoundsChangeIdleListener(map, loadS2Data)
      addBoundsChangeZoomListener(map, loadS2Data)


      //set feature roll over
      map.data.addListener('mouseover', function (event: { feature: google.maps.Data.Feature }) {

        const stats = event.feature.getProperty('statistics');

        if (stats) {
          const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
          event.feature.getGeometry().forEachLatLng((latLng) => bounds.extend(latLng))
          const overlay = addHTMLOverlay(map, PointInfoTable({ feature: event.feature }), bounds)
          debug && console.log('setting overlay to', overlay)
          setRolloverOverlay(overlay)
          debug && console.log('sett overlay to', rolloverOverlayRef)
        }
      });


      map.data.addListener('mouseout', () => {
        const rolloverOverlay = rolloverOverlayRef;
        debug && console.log('mouseout fired', rolloverOverlay)
        if (rolloverOverlay) {
          const overlayMap = rolloverOverlayRef.current?.getMap();
          if (overlayMap) {

            debug && console.log('Got Map for overlay', overlayMap)
            rolloverOverlayRef.current?.setMap(null)

          } else {
            debug && console.log('did not got Map for overlay')
          }

        }

      })

      /* HTML Overlay*/
      // const overlayElement = React.createElement(CircularProgress)
      // addHTMLOverlay(map, overlayElement, bounds)


      /* possibly use seperate data layer 
     
      const dataLayer = new google.maps.Data();
      dataLayer.addGeoJson(geoJsonFrag);
      dataLayer.setMap(map);
      dataLayer.setMap(null)
      */


      /* early attempt to put circular bounds s2 cells
      const geoJsonFrag = getS2CellGeoJsonForMap(map)

      if (geoJsonFrag) {
          map.data.toGeoJson((possibleFeatureCollection: object) => {
          const featureCollection = possibleFeatureCollection as FeatureCollection;
          if (featureCollection.features) {
            const mutatedFeatures = [ ...featureCollection.features, ...geoJsonFrag]
            const mutatedGeoJson = { ...featureCollection, features: mutatedFeatures }
            map.data.addGeoJson(mutatedGeoJson);
           }
        });
      } */

    }

  }, [map, /*kmlFileLinkHash*/])


  const mapProps: GoogleMapPropsV2 = { setMapInParent: setMap, controls: [<Button>Add Area</Button>] }




  return <>
    <Card>
      <Grid container>
        <Grid item xs={1} sx={{ minHeight: '100vh' }}>
          <Button onClick={() => {
            setOpen(true)
            setMode(AreaManagementMode.ADD)
            setEditObject(undefined)
          }}>Add Area</Button>
          <Button onClick={() => {
            setPlaceOpen(true)
            setMode(AreaManagementMode.ADD)
            setEditObject(undefined)
          }}>Add Place</Button>
          <Button onClick={() => {
            setStylerOpen(true)
            setMode(AreaManagementMode.ADD)
          }}>Add Styler</Button>
          <Button onClick={() => {
            setPlaceTypeOpen(true)
            setMode(AreaManagementMode.ADD)
          }}>Add PlaceType</Button>
          <Button onClick={() => {
            setIconOpen(true)
            setMode(AreaManagementMode.ADD)
            setEditObject(undefined)
          }}>My Icons</Button>
        </Grid>
        {/* <Grid item sx={{ width: '300px', height: '100px' }}> */}
        <Grid item xs={11} sx={{ minHeight: '100vh' }}>

          {googleMapScriptLoaded && <GoogleMap {...mapProps} ></GoogleMap>}
          {/* { map && transactionFunctions && <CollapsibleDraggable  ><SurveyTools {...{ ...props, transactionFunctions, userSelectedGroup, setKmlFileLinks, map }}></SurveyTools></CollapsibleDraggable>} */}
          {map && transactionFunctions && <CollapsibleDraggable  ><DeveMapInfo {...{ ...props, map }}></DeveMapInfo></CollapsibleDraggable>}

          {open && <AddArea {...{ setOpen, open, transactionFunctions, contextCustomer, areas, setAreas, areaStyler, mode, area: editObject, addAreas, polygons: Apolygons, setPolygons, setAddAreas }} />}
          {place && <AddPlace {...{ setOpen: setPlaceOpen, open: place, transactionFunctions, contextCustomer, areas, placeStyler, mode, place: editObject, placeTypes, addMarkers, setAddMarkers, markers, setMarkers, places, setPlaces }} />}
          {styler && <AddStyler{...{ setOpen: setStylerOpen, open: styler, transactionFunctions, contextCustomer, areas, userFunctions, actions, history, mode, styler: editObject, areaStyler, placeStyler, setAreaStyler, setPlaceStyler }} />}
          {placeType && <AddPlaceType{...{ setOpen: setPlaceTypeOpen, open: placeType, mode, stylers: placeStyler, contextCustomer, transactionFunctions, placetype: editObject, placeTypes, setPlaceTypes }} />}
          {iconOpen && <MyIcons {...{ setOpen: setIconOpen, open: iconOpen, mode }} />}

          {map && places && <CollapsibleDraggable><TableComponent {...{ title: 'Place', objects: places.map((place) => flattenObject(place)), keys: ['name', 'lat', 'lng'], setOpen: setPlaceOpen, setMode, setEditObject, originalObj: places }}></TableComponent></CollapsibleDraggable>}
          {map && areaStyler && <CollapsibleDraggable><TableComponent {...{ title: 'Area Styler', objects: areaStyler.map((place) => flattenObject(place)), keys: ['name', 'description'], setOpen: setStylerOpen, setMode, setEditObject, originalObj: areaStyler }}></TableComponent></CollapsibleDraggable>}
          {map && placeStyler && <CollapsibleDraggable><TableComponent {...{ title: 'Place Styler', objects: placeStyler.map((place) => flattenObject(place)), keys: ['name', 'description'], setOpen: setStylerOpen, setMode, setEditObject, originalObj: placeStyler }}></TableComponent></CollapsibleDraggable>}
          {map && placeTypes && <CollapsibleDraggable><TableComponent {...{ title: 'Place Types', objects: placeTypes.map((place) => flattenObject(place)), keys: ['name'], setOpen: setPlaceTypeOpen, setMode, setEditObject, originalObj: placeTypes }}></TableComponent></CollapsibleDraggable>}
          {map && <CollapsibleDraggable>
            <Paper>
              <TreeView {...{
                // defaultExpanded: [contextCustomer.sk], //TDOD
                defaultCollapseIcon: <  CheckBoxIcon />,
                defaultExpandIcon: < CheckBoxOutlineBlankIcon />,
                // onNodeToggle: handleChange,
                expanded: selectedParentSks
              }}

              >
                {nodeEdgeMapper && areas && generateTree(nodeEdgeMapper.getApexNodes() as ValidBusinessObjectList, nodeEdgeMapper)}
              </TreeView>
            </Paper>

          </CollapsibleDraggable>}
        </Grid>
      </Grid>
    </Card>

  </>;

}

const SurveyView = withStyles(styles)(SurveyViewBase)

export { SurveyView };

