import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import Assembler from '../../data/Assembler';
import { indexKeyToTypeAndId } from '../../data/TypeHelpers';
import { ComponentMode, ObjectListItemCardProps, UserTransactionType, ViewObject } from '../../types/AppTypes';
import { getViewDefinitionFromUserAttributes } from '../factories/AttributeDefinitions';
import { ObjectTableRowizer } from '../factories/ObjectTableRowizer';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';


const debug = process.env.REACT_APP_DEBUG && false;




export default function ObjectListSingleLineItem( {  viewObject,  transactionFunctions, mode }: ObjectListItemCardProps & { mode: ComponentMode} ) {
  debug && console.log('Object card got props', {  viewObject,  transactionFunctions })




const skAsTypeId = viewObject.matchedPrimary && indexKeyToTypeAndId(viewObject.matchedPrimary.sk);

  const modelInstance = viewObject.matchedPrimary ? Assembler.getInstance(viewObject.matchedPrimary) : undefined;
  const viewDefinition = modelInstance ? getViewDefinitionFromUserAttributes(modelInstance.getUserAttributes()) : {};
  const actions: UserTransactionType[] = [UserTransactionType.UPDATE, UserTransactionType.CREATE_AND_LINK, UserTransactionType.DELETE]

  debug && console.log('ObjectTableRow has', { modelInstance, viewDefinition})

  return ( 
    viewObject.matchedPrimary ?
      


            <ObjectTableRowizer { ...{ viewDefinition, viewObject, actions, transactionFunctions, mode}}></ObjectTableRowizer>

        
       : null
 
  );
}