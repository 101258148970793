import { NodeEdgeMapper, ValidBusinessObjectList, ValidModelObject } from '@iotv/datamodel-core';
import { Avatar, Box, Button, Card, CardMedia, Chip, Divider, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppLocalStorage } from '../../../data/LocalStorage/AppLocalStorage';
import { TransactionFunctions, UserFunctions } from '../../../actions/AppActions';
import { DialogWrapper } from '../../../components/common/DiaglogWrapper';
import { NestedCustomerSelector } from '../../../components/common/NestedCustomerSelector';
import { NavSections } from '../../../components/navigation/NavSections';
import { getImageUrl } from '../../../data/aws/s3/UserBlobs';
import { UserGroupsRolesType, ValidCustomerObject } from '../../../types/AppTypes';
import { c021HeaderStyles } from '../cosmetics/c021HeaderStyles';
import IoTWaterLogo from '../images/IoTW_logo.png';
import { WeatherCard } from './ReducedWeatherCard';

const debug = process.env.REACT_APP_DEBUG && false;

// const debug = process.env.REACT_APP_DEBUG && false;

interface HeaderProps extends WithStyles<typeof c021HeaderStyles> {
  onDrawerToggle: () => void;
  logout: Function;
  userFunctions: typeof UserFunctions;
  transactionFunctions: typeof TransactionFunctions;
  user: any;
  userGroupRoles: UserGroupsRolesType;
  userSelectedGroup: string | undefined;
  pinger: boolean;
  userCustomers: ValidBusinessObjectList;
  contextCustomer: ValidModelObject<'Customer'> | undefined;
}

function C021Header(props: HeaderProps) {
  const {
    classes,
    onDrawerToggle,
    user,
    userGroupRoles,
    userSelectedGroup,
    userFunctions,
    logout,
    transactionFunctions,
    pinger,
    userCustomers: userCustomersIn,
    contextCustomer,
  } = props;
  debug && console.log('user customers', userCustomersIn);
  const getDefaultGroupIdx = () => {
    const matchedPreferredIdx =
      userGroupRoles.preferredRole !== undefined ? userGroupRoles.groups.findIndex((group) => userGroupRoles.preferredRole?.includes(group)) : 0;
    return matchedPreferredIdx;
  };

  const userCustomers = userCustomersIn.sort((c1, c2) => (c1.name < c2.name ? -1 : 1));

  const userSelectedGroupIdx = userGroupRoles.groups.findIndex((group) => group === userSelectedGroup);
  const appSelectedIndx = userSelectedGroupIdx > -1 ? userSelectedGroupIdx : getDefaultGroupIdx();
  debug && console.log('userSelectedGroup', userSelectedGroup);
  debug && console.log('appSelectedIndx', appSelectedIndx);
  debug && console.log('userGroupRoles', userGroupRoles);
  // may have to change below if cognito:groups not in precedence order
  const [userRoleIdx, setUserRoleIdx] = React.useState(appSelectedIndx);
  const routerHistory = useHistory();
  const [nodeEdgeMapper, setNodeEdgeMapper] = React.useState<NodeEdgeMapper | undefined>(undefined);
  const [customerSelect, setCustomerSelect] = React.useState<boolean>(false);

  const [heldCustomer, setHeldCustomer] = React.useState<ValidCustomerObject>();

  const holdCustomer = (contextCustomer: ValidCustomerObject) => {
    setHeldCustomer(contextCustomer);
  };

  const changeCustomer = () => {
    userFunctions.changeAppState({ contextCustomer: heldCustomer });
    heldCustomer && AppLocalStorage.set(NavSections.HOME, 'lastContextCustomerSk', heldCustomer.sk);
  };

  useEffect(() => {
    if (userCustomers) {
      const nodeEdgeMapper = new NodeEdgeMapper();
      /* currently the secondary query to put admisterable customers only returns edges */
      const temporaryShimPrimaries = userCustomers
        .map((c) => {
          if (c.pk !== c.sk && !userCustomers.find((uc) => uc.sk === c.sk && uc.pk === c.sk)) {
            return { ...c, pk: c.sk };
          } else return undefined;
        })
        .filter((c) => c) as ValidBusinessObjectList;
      nodeEdgeMapper.loadItems(userCustomers);
      nodeEdgeMapper.loadItems(temporaryShimPrimaries);
      setNodeEdgeMapper(nodeEdgeMapper);
    }
  }, [userCustomers?.length]);

  const nodeEdgesHash = Object.keys(nodeEdgeMapper?.skMapped ?? { noNodes: true }).join('');

  useEffect(() => {
    debug && console.log('CommunitiesView usedEffect on nodeEdgeHash', nodeEdgesHash);
  }, [nodeEdgesHash]);

  useEffect(() => {
    if (nodeEdgeMapper) {
      debug && console.log('CommunitiesView usedEffect on skMapeed', Object.keys(nodeEdgeMapper.skMapped).length);
    }
  }, [nodeEdgeMapper?.skMapped]);

  useEffect(() => {
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.user);
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url);
        setImageUrl(url);
      }
      setUserRoleIdx(appSelectedIndx);
    }

    effectUrl();
  }, [props.user, appSelectedIndx]);

  const [imageUrl, setImageUrl] = React.useState('');

  const changeUserRole = (idx: number) => {
    const userSelectedGroup = userGroupRoles.groups[idx];
    debug && console.log('requestiong userRole', userSelectedGroup);
    transactionFunctions.changeUserRole(user, userGroupRoles, userSelectedGroup);
    // and then change the role
    debug && console.log('set userRoleIdx', idx);
  };

  const openCustomerSelect = () => {
    debug && console.log(`open customer select`);
    setCustomerSelect(true);
  };

  const getRoleSelectOptions = () => {
    debug && console.log('getRoleSelectOptions', props.userGroupRoles);
    const elements = props.userGroupRoles.groups.map((roleName, i) => {
      switch (roleName) {
        case 'tankOwner':
          return (
            <MenuItem key={`${i}`} value={i}>
              Tank Owner{' '}
            </MenuItem>
          );
        case 'User':
          return (
            <MenuItem key={`${i}`} value={i}>
              User{' '}
            </MenuItem>
          );
        case 'communityWaterManager':
          return (
            <MenuItem key={`${i}`} value={i}>
              Community Water Manager
            </MenuItem>
          );

        default:
          return (
            <MenuItem key={`${i}`} value={i}>
              {roleName}
            </MenuItem>
          );
      }
    });
    debug && console.log('Role selection options', elements);
    return elements;
  };

  return (
    <React.Fragment>
      <Box boxShadow={6}>
        <AppBar className={classes.secondaryBar} position='sticky'>
          <Toolbar>
            <Grid container alignItems='center'>
              <Grid item>
                <IconButton
                  // color="inherit"
                  aria-label='open drawer'
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              {/* <Grid item> */}
              <Box flexGrow={1}>
                <Box display='flex' alignItems='center'>
                  <Card className={classes.logoRoot} raised={false} elevation={0} onClick={() => routerHistory.push(`/${NavSections.THINGSVIEW}/Tank`)}>
                    <CardMedia
                      className={classes.ewbLogo}
                      image='https://d3n8a8pro7vhmx.cloudfront.net/ewbnz/sites/8/meta_images/original/EWB_Logo_Standard_CMYK-t-01.png?1505526717'
                      title='Logo'
                    />
                    <Divider orientation='vertical' flexItem />
                    <CardMedia className={classes.iotWaterLogo} image={IoTWaterLogo} title='Logo' />
                    <div className={classes.tempLogo}> </div>
                  </Card>
                </Box>
              </Box>
              {/* </Grid> */}

              {contextCustomer && (
                <Box flexGrow={1} sx={{ color: 'black', display: 'inline-flex' }}>
                  <Box sx={{ color: 'black', textAlign: 'right', fontSize: '120%', paddingRight: '5px' }}>
                    <strong>Forecast:</strong>
                    <br />
                    {/* <strong>Actual Rainfall: </strong> 15mm replace with actual rainfall later */}
                  </Box>
                  <WeatherCard customerIn={contextCustomer} />
                </Box>
              )}

              <Grid item>
                {props.userGroupRoles.groups?.length > 1 && (
                  <Select value={userRoleIdx} onChange={(e) => changeUserRole(e.target.value as number)}>
                    {getRoleSelectOptions()}
                  </Select>
                )}
              </Grid>
              {/* {props !== undefined && props.user !== undefined ? (
                <Grid item>
                  <Button disabled={true} {...{ onClick: () => routerHistory.push(`/${NavSections.USERVIEW}`) }}>
                    <Avatar>
                      {' '}
                      {props.user !== undefined &&
                        props.user?.name !== undefined &&
                        props.user.name
                          .split(' ')
                          .map((n: string) => n[0])
                          .join('')}
                    </Avatar>
                  </Button>
                </Grid>
              ) : null} */}

              {nodeEdgeMapper && (
                <Grid>
                  <Chip
                    {...{
                      clickable: true,
                      icon: <SettingsApplicationsIcon />,
                      color: 'primary',
                      label: contextCustomer?.name ?? 'waiting',
                      onClick: openCustomerSelect,
                    }}
                  ></Chip>
                </Grid>
              )}

              <Grid item>
                <Tooltip title='Log Out'>
                  <span>
                    <IconButton className={classes.menuButton} onClick={() => logout()}>
                      <ExitToAppIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      {nodeEdgeMapper && (
        <DialogWrapper
          {...{
            open: customerSelect,
            setClosed: (save: boolean) => {
              console.log('Close');
              save && changeCustomer();
              setCustomerSelect(false);
            },
            header: `Switch customer from ${contextCustomer?.name}`,

            wrappedElement: <NestedCustomerSelector {...{ nodeEdgeMapper, setHoldContextCustomerFn: holdCustomer }}></NestedCustomerSelector>,
          }}
        ></DialogWrapper>
      )}
    </React.Fragment>
  );
}

export default withStyles(c021HeaderStyles)(C021Header);
