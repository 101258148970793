import { DatastoreObjectType, ErrData, ValidBusinessObjectList } from "@iotv/datamodel";
import { Radio } from "@material-ui/core";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import React, { useEffect, useReducer } from "react";
import { ListerlizerRowControlFnProps } from "../types/AppTypes";
import { DocumentClientQueryHookOutputV1 } from "../types/LabTypes";

const debug = process.env.REACT_APP_DEBUG && false;



export function useDocumentClientQuery<T>(asyncDocumentClientQuery: (setterFn: (result: ErrData<DocumentClient.QueryOutput>) => void, params: T) => void, queryParams: T | undefined, dependencies: any[]): DocumentClientQueryHookOutputV1<T> {

  const [updates, forceUpdate] = useReducer(x => { debug && console.log(`force update`, x + 1); return x + 1 }, 0);
  const [limit, setLimit] = React.useState<number | undefined>(undefined)

  const [items, setItems]: [DocumentClient.AttributeMap[], React.Dispatch<React.SetStateAction<DocumentClient.AttributeMap[]>>] = React.useState<DocumentClient.AttributeMap[]>([]);
  const [LastEvaluatedKeys, setLastEvaluatedKeys]: [DocumentClient.Key[], React.Dispatch<React.SetStateAction<DocumentClient.Key[]>>] = React.useState<DocumentClient.Key[]>([]);
  const [ExclusiveStartKey, setExclusiveStartKey]: [DocumentClient.Key | undefined, React.Dispatch<React.SetStateAction<DocumentClient.Key | undefined>>] = React.useState<DocumentClient.Key | undefined>(undefined);
  const [selectedObjects, setSelectedObjects] = React.useState<DatastoreObjectType[]>([])

  useEffect(() => {
    debug && console.log('useObjecuseDocumentClientQuery effect because', { ...dependencies, updates, limit, ExclusiveStartKey })
    if (queryParams ) {
      const setterFn = (result: ErrData<DocumentClient.QueryOutput>) => {
        debug && console.log('setter fn was  called with', result)
        if (result.data) {
          const data = result.data;
          if (data.Items) {
            setItems(data.Items)
          };
          if (data.LastEvaluatedKey) {
            const keys = [...LastEvaluatedKeys];
            keys.push(data.LastEvaluatedKey);
            setLastEvaluatedKeys(keys)
          }
        } else if ( result.err ) {
          console.log('useDocumentClientQuery has err', result.err.message)
        }
      }
      const mutatedParams: T = { ...queryParams, ExclusiveStartKey, Limit: limit }
      debug && console.log('useObjecuseDocumentClientQuery calling with mutatedParams ', { asyncDocumentClientQuery, setterFn, mutatedParams })
      asyncDocumentClientQuery(setterFn, mutatedParams)
    }


  }, [ updates, limit, ExclusiveStartKey, dependencies[0], dependencies[1], dependencies[2], dependencies[3] ])



  const isInSelectedObjects = (vob: DatastoreObjectType) => selectedObjects.find((item) => item.sk === vob.sk);
  const addToSelectedObject = (vob: DatastoreObjectType) => {
    if (!isInSelectedObjects(vob)) {
      setSelectedObjects([...selectedObjects, vob])
    }
  }
  const removeFromSelectedObject = (vob: DatastoreObjectType) => {
    const idx = selectedObjects.findIndex((item) => item.sk === vob.sk);
    if (idx > -1) {
      const mutatedSelectedObjects = [...selectedObjects]
      mutatedSelectedObjects.splice(idx, 1)
      setSelectedObjects(mutatedSelectedObjects)
    }

  }

  const getSelector = (listRowProps: ListerlizerRowControlFnProps): JSX.Element => {
    const isSelectedObject = isInSelectedObjects(listRowProps.rowObject) !== undefined;
    const radio: JSX.Element = React.createElement(Radio, ({ key: `${listRowProps.rowObject.sk}_select`, size: 'small', checked: isSelectedObject }), null)
    const wrapper: JSX.Element = React.createElement('div', ({
      key: `${listRowProps.rowObject.sk}_selectWrapper`,

      onClick: () => isSelectedObject ? removeFromSelectedObject(listRowProps.rowObject) : addToSelectedObject(listRowProps.rowObject)
    })
      , radio
    )

    return wrapper;
  }

  return {
    querySetterGetters: [
      [items, setItems],
      [limit, setLimit],
      [LastEvaluatedKeys, setLastEvaluatedKeys],
      [ExclusiveStartKey, setExclusiveStartKey]
    ],
    query: asyncDocumentClientQuery,
    selectionControls: [selectedObjects, setSelectedObjects, getSelector],
    forceUpdate, updates
  }


}