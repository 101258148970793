import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Indication } from '../DMPExtrasV2';
import IndicationModal from './DroughtPlanModals/IndicationModal';
import { NoneMade } from './ManagementGenerics';

interface IndicationPageProps {
  contextCustomer: ValidBusinessObject;
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const IndicationPage: React.FC<IndicationPageProps> = (props: IndicationPageProps) => {
  let { contextCustomer, index, source, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [indication, setIndication] = useState<Indication[]>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setIndication(source.stage[index].indication || []);
  }, [index, source]);

  const addNewIndication = useCallback(() => {
    setCreate(true);
  }, []);

  const cancel = useCallback(() => {
    setCreate(false);
    setPosition(undefined);
  }, []);

  const editIndication = (pos: number) => {
    setPosition(pos);
    addNewIndication();
  };

  const update = (newMessage: Indication | undefined, pos: number | undefined) => {
    if (newMessage === undefined) {
      if (pos !== undefined) {
        indication.splice(pos, 1);
      }
    } else if (setSource !== undefined) {
      if (pos === undefined) {
        indication!.push(newMessage);
      } else {
        indication[pos] = newMessage;
      }

      let newStages = source.stage;
      newStages[index].indication = indication;
      setSource({ ...source, stage: newStages });
      cancel();
    }
  };

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          {setSource && (
            <Button variant='contained' onClick={addNewIndication}>
              <Add />
              <b>Indication</b>
            </Button>
          )}
          {indication && indication.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>
                      <b>Message</b>
                    </TableCell>
                    <TableCell width={'100px'} align='center' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {indication.map((indication, i) => {
                    return (
                      <TableRow>
                        <TableCell align='left'>{indication.message}</TableCell>
                        <TableCell align='center'>
                          <Button variant='contained' onClick={() => editIndication(i)}>
                            <Edit />
                            <b>Edit</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Indication'} add={addNewIndication} />
          )}
        </Stack>
      </div>
      {create && <IndicationModal close={cancel} update={update} index={position} existing={indication} />}
    </>
  );
};

export default IndicationPage;
