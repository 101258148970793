import { NotificationDeliveryConfig, DeliveryType, ValidModelObjects, ValidModelObject, Severity, Milliseconds, AdjacentType } from "@iotv/datamodel";
import { Grid, FormControl, TextField, Button, FormGroup, FormLabel, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { MultipleObjectAutoComplete } from "../generic/ObjectAutoComplete";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { EditableList } from "../generic/EditableList";
import { defaultSelectVal, defaultSelectVals } from "../utils/DefaultValues";
import { useStyles } from "../../cosmetics/InputStyles"
import { ValidBusinessObject } from "@iotv/iotv-v3-types";

const debug = process.env.REACT_APP_DEBUG && false;

export type DelvierOptionsProp = {
    contextObject: ValidBusinessObject | undefined, contextUser: ValidModelObject<'User'>,
    deliveryOptions: NotificationDeliveryConfig[],
    setDeliveryOptions: (deliveryOptions: NotificationDeliveryConfig[]) => void,
    style?: React.CSSProperties
}

export const DeliveryOptions = ({ contextObject, contextUser, deliveryOptions, setDeliveryOptions, style }: DelvierOptionsProp) => {

    const classes = useStyles()
    const handleAdd = () => {
        const newDeliveryOption: NotificationDeliveryConfig = {
            deliveryType: DeliveryType.EMAIL, destinations: [], recipients: [], messageContent: undefined, messageTitle: undefined,

        }
        setDeliveryOptions([...deliveryOptions, newDeliveryOption])
    }

    const handleRemove = (i: number) => {
        const mutant = [...deliveryOptions];
        mutant.splice(i, 1)
        setDeliveryOptions(mutant)
    }

    const handleToggleEscalation = (i: number) => {
        const mutant = [...deliveryOptions];
        const deliveryOption = mutant[i];
         if ( deliveryOption.escalation ) {
             deliveryOption.escalation = undefined
         } else {
             deliveryOption.escalation = {
                 afterDurationMs: undefined,
                 atSeverity: undefined
             }
         }
        setDeliveryOptions(mutant)
    }

    const destinationsHash = deliveryOptions.map((dop) => dop.destinations.join(':')).join(':')
    useEffect(() => {
        debug && console.log('Delivery options used effect on changed destinationsHash')
    }, [destinationsHash])

    useEffect( () => {
        debug && console.log('Delivery options used effect on changed contextObject')
    }, [contextObject?.sk])

    return <Grid container>
        {deliveryOptions.map((deliveryOption, i) => {
            const key = `${i}_${deliveryOption.destinations.join()}`;
            const deliveryType: DeliveryType = deliveryOption.deliveryType
            const setDeliveryType = (deliveryType: DeliveryType) => {
                const newDeliveryOptions = [...deliveryOptions]
                newDeliveryOptions[i].deliveryType = deliveryType
                setDeliveryOptions(newDeliveryOptions)
            }
            const currentEntities = deliveryOption.recipients;
            const setCurrentEntities = (vobs: ValidModelObjects<'User' | 'Customer'>) => {
                const newDeliveryOptions = [...deliveryOptions]
                //.filter( (vob) => !currentEntities.find( (item) => item.sk === vob.sk))
                const update = vobs.map(({ pk, sk, type, id, name, email, phone_number, username }) => ({ pk, sk, type, id, name, email, phone_number, username }));
                newDeliveryOptions[i].recipients = update
                setDeliveryOptions(newDeliveryOptions)
            }
            const setDestinations = (destinations: string[]) => {
                const newDeliveryOptions = [...deliveryOptions]
                newDeliveryOptions[i].destinations = destinations
                console.log('New Destination', destinations)
                setDeliveryOptions(newDeliveryOptions)
            }

            const mutateDeliveryOption = ( deliveryOption: NotificationDeliveryConfig, i: number ) => {
                const newDeliveryOptions = [...deliveryOptions]
                newDeliveryOptions[i] = deliveryOption
                setDeliveryOptions(newDeliveryOptions)
            }

            return <>
                <Grid container {...{ key, style: { borderBottomStyle: 'dashed', borderBottomColor: 'gray', borderBottomWidth: '1px' } }}>
                    <Grid item {...{ key: 'DeliveryType', xs: 4 }}>
                        {/* <FormLabel component = {'legend'}>Delivery Type</FormLabel> */}
                        <Grid item>
                            <FormControl {...{ key: 'B', }}>
                                <Autocomplete {...{
                                    freeSolo: false, id: 'selectDeliveryType', style, size: 'small', variant: "outlined", options: Object.values(DeliveryType) as string[], multiple: false, filterSelectedOptions: true,
                                    getOptionLabel: (v: string) => v, value: deliveryType,
                                    onChange: (e, v: DeliveryType | string | null) => {
                                        setDeliveryType(v as DeliveryType)
                                    },
                                    renderInput: (params) => <TextField {...{
                                        ...params, label: "Delivery Type",
                                    }} />
                                }} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl  {...{ key: 'Title', }}>
                                <TextField {...{
                                    key: 'title', label: 'Title', value: deliveryOption.messageTitle ?? ' ', onChange: (e) => {
                                        const newDeliveryOptions = [...deliveryOptions];
                                        newDeliveryOptions[i].messageTitle = e.target.value;
                                        setDeliveryOptions(newDeliveryOptions);
                                    }
                                }} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl  {...{ key: 'Content', }}>
                                <TextField {...{
                                    key: 'content', label: 'Content', value: deliveryOption.messageContent ?? ' ', multiline: true, rows: 2, rowsMax: 10, onChange: (e) => {
                                        const newDeliveryOptions = [...deliveryOptions];
                                        newDeliveryOptions[i].messageContent = e.target.value;
                                        setDeliveryOptions(newDeliveryOptions)
                                    }
                                }}
                                />
                            </FormControl></Grid>




                    </Grid>
                    <Grid item {...{ key: 'UserAndDesitnations', xs: 4 }}>

                        {/* <FormLabel component="legend">Assign Recipients</FormLabel> */}
                        <Grid item >
                            <MultipleObjectAutoComplete {...{ key: 'User', contextObject, contextUser, objectTypeId: 'User', adjacencyType: AdjacentType.CHILD, currentEntities, setCurrentEntities, label: 'Recipients' }}></MultipleObjectAutoComplete>

                        </Grid>
                        <Grid item >
                            <FormControl {...{ key: 'Destinations', }}>
                                <Typography {...{ variant: 'caption', style: { marginTop: '0.5rem' } }}>Destinations</Typography>
                                {<EditableList {...{ setCurrentList: (items: string[]) => setDestinations(items), currentList: deliveryOption.destinations, typeConstructor: () => '' }}></EditableList>}
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid item {...{ key: 'Escalation', xs: 4 }}>
                        <Grid item >
                            <FormControl {...{ key: 'Escalation', }}>
                                <Typography {...{ variant: 'caption', style: { marginTop: '0.5rem' } }}>Escalation</Typography>
                                { deliveryOption.escalation ? <>
                                    <Grid item>
                                       <TextField { ...{ 
                                            classes:  { root: classes.root},
                                           //InputLabelProps: { shrink: true },
                                           value: typeof deliveryOption.escalation.afterDurationMs === 'number' ? deliveryOption.escalation.afterDurationMs / Milliseconds.HOUR : undefined ,
                                           variant: 'standard', label: 'After hrs',
                                           type: 'Number',
                                           onChange: (e) => {
                                            const  v: number  = parseInt(e.target.value)
                                            if ( !isNaN(v) ||  e.target.value === undefined ) {
                                                const newDeliveryOption = { ...deliveryOption }
                                                const afterDurationMs: number | undefined = e.target.value ? v * Milliseconds.HOUR : undefined;
                                                newDeliveryOption.escalation = { ...newDeliveryOption.escalation, afterDurationMs}
                                                mutateDeliveryOption( newDeliveryOption, i)
                                            }
    
                                        },
                                           }}></TextField>
                                       <Autocomplete {...{
                                    freeSolo: false, id: 'selectEscalateOnSeverity', style, size: 'small', variant: "outlined", options: defaultSelectVals( Object.values(Severity) ) as string[], multiple: false, filterSelectedOptions: true,
                                    getOptionLabel: (v: string) => v, value: deliveryOption.escalation.atSeverity ?? defaultSelectVal,
                                    onChange: (e, v: Severity | string | null) => {
                                        if ( v !== defaultSelectVal ) {
                                            const newDeliveryOption = { ...deliveryOption }
                                            newDeliveryOption.escalation = { ...newDeliveryOption.escalation, atSeverity: v as Severity}
                                            mutateDeliveryOption( newDeliveryOption, i)
                                        }

                                    },
                                    renderInput: (params) => <TextField {...{
                                        ...params, label: "At Severity",
                                    }} />
                                }} />
                                    <Button {...{ style: { float: 'right' }, size: 'small', startIcon: <AddIcon />, variant: 'outlined', color: 'primary', onClick: () => handleToggleEscalation(i) }}>Disable Escalation</Button>
                                    </Grid>
                                
                                </> :  <Button {...{ style: { float: 'right' }, size: 'small', startIcon: <AddIcon />, variant: 'outlined', color: 'primary', onClick: () => handleToggleEscalation(i) }}>Enable Escalation</Button>}
                                {/* <EditableList {...{ setCurrentList: (items: string[]) => setDestinations(items), currentList: deliveryOption.destinations, typeConstructor: () => '' }}></EditableList> */}
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid item {...{ xs: 12 }} >
                        <Button {...{ style: { float: 'right' }, size: 'small', startIcon: <DeleteIcon />, variant: 'outlined', color: 'primary', onClick: () => handleRemove(i) }}>Remove Delivery Detail</Button>
                    </Grid>
                </Grid>

            </>
        })}
        <Button {...{ size: 'small', startIcon: <AddIcon />, variant: 'outlined', color: 'primary', onClick: handleAdd }}>Add Delivery Detail</Button>

    </Grid>

}