import { Auth } from '@aws-amplify/auth';
import config from '@iotv/config';
import { ErrData, ErrDataPromise, ValidBusinessObject } from "@iotv/datamodel";
import { MapLike, unzipMappedBuffer } from "@iotv/datamodel-core";
import S3, { GetObjectOutput, GetObjectRequest, PutObjectOutput, PutObjectRequest } from "aws-sdk/clients/s3";


const debug = !process.env.AWS_SESSION_TOKEN && true;


export type PutObjectToS3Request = {object: any, Key: string, bucketIdentifier: string, Expires?: Date, zip?: boolean }


const unzipMappedBufferWrapper = async ( buffer: Buffer, encoding?: BufferEncoding ): Promise<MapLike<any> | null> => {
    const unZipRes = await unzipMappedBuffer( buffer , encoding )
    if ( unZipRes.err ) {
        console.log(`Err unzipping (abdriged) `, buffer.toString().substring(0,100), unZipRes.err.message )
    }

    return unZipRes.data 

}

const s3GetWithCors = async (Key: string, bucketIdentifier: string):  ErrDataPromise<GetObjectOutput> => {
    const credentials = await Auth.currentCredentials();
    const options: S3.ClientConfiguration = {
        region: config.aws.default_region,
        credentials: Auth.essentialCredentials(credentials)
    };
    const s3 = new S3( options );

    console.log( 'Options for s3', options)

    const params: GetObjectRequest = {
        Bucket: bucketIdentifier,
        Key

    };
    return new Promise( (resolve, reject) => {
        s3.getObject(params, (err, data) => resolve({err, data}))
    })
}

export const s3PutWithCors = async (Key: string, bucketIdentifier: string, object: any, Expires?: Date, Metadata?: S3.Metadata):  ErrDataPromise<PutObjectOutput> => {
    const credentials = await Auth.currentCredentials();
    const options: S3.ClientConfiguration = {
        region: config.aws.default_region,
        credentials: Auth.essentialCredentials(credentials)
    };
    const s3 = new S3( options );

    console.log( 'Options for s3', {options, Key, bucketIdentifier, object})

    const params: PutObjectRequest = {
        Bucket: bucketIdentifier,
        Key,
        Body: JSON.stringify(object),
        Expires, Metadata

    };
    const res: ErrData<PutObjectOutput> = await new Promise( (resolve, reject) => {
        s3.putObject(params, (err, data) => resolve({err, data}))
    })

    console.log('res in s3PutWithCors', res)
    return res
}




export type GetObjectFromS3Request = { Key: string, bucketIdentifier: string}

export const getObjectFromS3 = async <T = ValidBusinessObject>( { Key, bucketIdentifier }: GetObjectFromS3Request ): ErrDataPromise<T> => {
    let res: ErrData<T> = { err: null, data: null }
    let existingObject: T | null = null;

        const exisitingObjectResponse = await s3GetWithCors( Key, bucketIdentifier );

        if ( exisitingObjectResponse.data?.Body ) {
            const zipped = exisitingObjectResponse.data.ContentType === 'application/zip'
            existingObject = await s3BodyToObject(exisitingObjectResponse.data.Body as Buffer, zipped );
            if (existingObject) res.data = existingObject;
            debug && console.log('S3 getObjectFromS3 existingObject', { existingObject })
        } else {
            res.err = exisitingObjectResponse.err;
        }


    return res;
} 

export const s3BodyToObject = async ( body: Buffer, zipped: boolean = false  ) => zipped ? await unzipMappedBufferWrapper( body ) : JSON.parse( body.toString('utf-8') )