import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './DroughtModal.module.css';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CSS from 'csstype';

const modalRoot = document.querySelector('#modal-root');

interface StageNameModal {
    close: () => void;
    source: ValidBusinessObject;
}

const EditStageNameModal: React.FC<StageNameModal> = (props: StageNameModal) => {
    let { close, source } = props;

    console.log("histroy", source)

    const [name, setName] = useState<string>('');
    console.log('getthename', source)
    if (modalRoot === null) {
        console.log('Root for modal is not working.');
        return null;
    }

    function createData(
        changedata: string,
        name: string,
        beforechange: string,
        afterchange: string,
        changereason: string
    ) {
        return { changedata, name, beforechange, afterchange, changereason };
    }

    type rowdata = {
        changedata: string,
        name: string,
        beforechange: string,
        afterchange: string,
        changereason: string,
    };

    var rows: rowdata[] = [];

    type DMPHistory = {
        name: string,
        beforechange: string,
        afterchange: string,
        time: string
        changereason: string
    };

    source.stagechangehistory?.map((stage: DMPHistory) => (
        rows.unshift(createData(stage.time.split('GMT')[0], stage.name, stage.beforechange, stage.afterchange, stage.changereason ? stage.changereason : "No reason"))
    ))

    return ReactDOM.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.form}>
                <Card>
                    <h1 >{source.name + " stage change history"}</h1>
                    <div style={{ maxHeight: 400, overflow: 'auto' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Stage change time</TableCell>
                                        <TableCell align="right">Name</TableCell>
                                        <TableCell align="right">From stage</TableCell>
                                        <TableCell align="right">To stage</TableCell>
                                        <TableCell align="right">Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.changedata}
                                            </TableCell>
                                            <TableCell align="right">{row.name}</TableCell>
                                            <TableCell align="right">{row.beforechange}</TableCell>
                                            <TableCell align="right">{row.afterchange}</TableCell>
                                            <TableCell align="right">{row.changereason}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <Box m={2}>
                        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                            <Button variant='contained' onClick={close}>
                                Exit
                            </Button>
                        </Stack>
                    </Box>
                </Card>
            </div>
        </div>,
        modalRoot
    );
};

export default EditStageNameModal;
