import { HistoryStore, ObjectHistory, ValidModelObject } from '@iotv/datamodel';
import { HistoryObjectType } from '@iotv/iotv-v3-types';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Tank } from '@v017/datamodel';
import React, { useState } from 'react';
import config from '../../../config';
import styles from '../../../cosmetics/sharedCardStyles';
import { LineChartOptionsMandatory, LineChartXType } from '../../../types/AppTypes';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { RangeableLineChart } from './charts/RangeableLineGraph';
import { SummarizeTwoTone } from '@mui/icons-material';
import { convertHistoryObjectToDataTable } from '../data/TypeHelpers';
import { RangeableBarGraph } from './charts/RangeableBarGraph';
import BarChart from './charts/BarChart';

const googleChartUrl = config.google.chartApiUri;

const debug = process.env.REACT_APP_DEBUG && false;

type TankRainfallGraphCardProps = {
  matchedPrimary: ValidModelObject<'Tank'>;
  filterGraphKeys?: string[];
  objectHistory: ObjectHistory | undefined;
};

const localStyle = {
  media: {
    paddingTop: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  bottomCard: {
    marginTop: '10px',
    alignItems: 'flex-end',
  },
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));

export default function TankRainfallGraphCard(props: TankRainfallGraphCardProps) {
  const classes = useStyles();
  const { objectHistory, filterGraphKeys, matchedPrimary } = props;
  console.log('TankUsageGraphCard from v017 temporary');

  const tank = new Tank(matchedPrimary);
  const totalVolume = tank._totalVolume;

  const historyObject = objectHistory?.recentStateHistory;

  const [duration, setDuration] = React.useState(1);
  const [selectedHistoryObject, setselectedHistoryObject] = React.useState<HistoryStore | undefined>(historyObject);
  const [selectedRainGraph, setSelectedRainGraph] = useState<string | undefined>('volume_collected_period'); // React.useState('usage_in_day')

  const googleChartsScript = document.querySelector(`[src="${googleChartUrl}"]`);

  const getGraph = (historyObject: HistoryObjectType, filterKeys?: string[], graphKey?: string) => {
    let fromDate = new Date().valueOf();
    let sum = 0;
    const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
    const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;
    const MILLISECONDS_IN_MONTH = MILLISECONDS_IN_DAY * 30;
    // fromDate.setDate(fromDate.getDay() - duration);
    const unixFromDate = fromDate - duration * MILLISECONDS_IN_DAY;
    let lowIdx = historyObject.timestamp.findIndex((ts) => ts > unixFromDate);
    let maxIdx = historyObject.timestamp.length;
    const wholeKeys = Object.keys(historyObject);
    let historyCopy = { ...historyObject };
    let indicies: number[] = [];
    let base: number[] = [];
    if (graphKey) {
      const rainfall = (historyObject[graphKey] ?? []) as number[];

      switch (duration) {
        case 1:
          // For Today: a horizontal timeline with 24 hours on x axis with rainfall in mm in each hour on y axis
          for (let i = 0; i < 24; i++) {
            base.push(unixFromDate + i * MILLISECONDS_IN_HOUR);
            indicies.push(historyObject.timestamp.findIndex((ts) => ts > base[i]));
          }
          indicies = [...new Set(indicies)].filter((value) => value !== -1);
          break;
        case 7:
          // For last 7 days: each day of week on x axis with mm rainfall each day on y axis
          for (let i = 0; i < 7; i++) {
            base.push(unixFromDate + i * MILLISECONDS_IN_DAY);
            indicies.push(historyObject.timestamp.findIndex((ts) => ts > base[i]));
          }
          indicies = [...new Set(indicies)];
          break;
        case 30:
          // For last 30 days: each day's on x axis (show date for every Monday only) and mm rainfall on y axis, total for the 30 days on far right of x axis
          for (let i = 0; i < 30; i++) {
            base.push(unixFromDate + i * MILLISECONDS_IN_DAY);
            indicies.push(historyObject.timestamp.findIndex((ts) => ts > base[i]));
          }
          indicies = [...new Set(indicies)];
          break;
        case 365:
          // For last year:  each month on x axis (current month on right ie months in reverse order from R-L) with total rainfall for month on y axis
          for (let i = 0; i < 12; i++) {
            base.push(unixFromDate + i * MILLISECONDS_IN_MONTH);

            indicies.push(historyObject.timestamp.findIndex((ts) => ts > base[i]));
          }
          indicies = [...new Set(indicies)];
          break;
      }
      let summation = [];
      for (let i = 0; i < indicies.length; i++) {
        if (indicies.length > i + 1) {
          summation.push(
            rainfall.slice(indicies[i], indicies[i + 1]).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0)
          );
        } else {
          summation.push(
            rainfall.slice(indicies[i]).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0)
          );
        }
      }

      let plains = base.slice(0, base.length - indicies.length);
      let vol: number[] = Array(plains.length).fill(0);
      historyCopy.timestamp = plains.concat(indicies.map((pos) => historyObject.timestamp[pos]));
      historyCopy[graphKey] = vol.concat(summation);
      // cummulative method
      // let cummulative = rainfall.map(((sum = 0), (n) => (sum += n)));
      // historyCopy[graphKey] = cummulative.map((val) => val - cummulative[lowIdx]);
    }

    const keys = wholeKeys.filter((key) => key === graphKey);
    const xType: LineChartXType = LineChartXType.TIMESERIES;

    const chartProps = {
      options: { title: 'Volume Collected (mm)', height: 300, legend: 'none' } as google.visualization.BarChartOptions,
      data: convertHistoryObjectToDataTable(historyCopy, keys, xType, [0, base.length]),
      name: 'Volume Collected (mm)',
    };

    const rows = keys
      .map((key) => {
        return (
          historyCopy[key] && (
            <BarChart {...chartProps}> </BarChart>
            // <RangeableBarGraph {...{ historyObject: historyCopy, xType, classes, keys: [key], lowIdx, maxIdx }}></RangeableBarGraph> // for cummulative
          )
        );
      })
      .filter((element) => element);
    return rows;
  };

  const handleViewSelector = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedRainGraph(event.target.value as string);
  };

  const handlerDurationChange = (event: any) => {
    setDuration(parseInt(event.target.value));
  };

  return (
    <div>
      {selectedHistoryObject ? (
        googleChartsScript && getGraph(selectedHistoryObject, filterGraphKeys, selectedRainGraph)
      ) : (
        <div>{`No selectedHistoryObject`}</div>
      )}

      <div className={classes.bottomCard}>
        <Grid container>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='center' bgcolor='background.paper'>
              <FormControl className={classes.formControl}>
                <Select value={selectedRainGraph} defaultValue={30} onChange={handleViewSelector} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value={'volume_collected_period'}>Rainfall in period (mm)</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='center' bgcolor='background.paper'>
              <Grid component='label' container alignItems='center' spacing={1}>
                <Grid item>
                  <RadioGroup row aria-label='position' name='position' defaultValue='top' value={duration} onChange={handlerDurationChange}>
                    <FormControlLabel value={1} control={<Radio color='primary' />} label='1 day' />
                    <FormControlLabel value={7} control={<Radio color='primary' />} label='7 days' />

                    <FormControlLabel value={30} control={<Radio color='primary' />} label='1 month' />
                    <FormControlLabel value={365} control={<Radio color='primary' />} label='1 year' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
