import { MenuItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TreeItem } from '@material-ui/lab';
import React, { CSSProperties } from 'react';
import { treeCosmeticsByType } from '../../../cosmetics/tree/treeCosmeticsByType';
import { AddObjectItemTypeChooserProps, ComponentMode, ValidTypeLinkage } from '../../../types/AppTypes';
import { StyledTreeItemConfig } from '../../../types/ComponentProps';
import { useTreeItemStyles } from './treeStyle copy';


const debug = process.env.REACT_APP_DEBUG && false;


type ValidModes = ComponentMode.VIEW | ComponentMode.EDIT

export const AddItemTypeChooser = (props: AddObjectItemTypeChooserProps) => {


    const { validTypes, nodeId, handleIconClick, selectedValidTypeLinkage, setSelectedValidTypeLinkage, ...other } = props;

    debug && console.log('AddItemTypeChooser gets', props)



    const handleChange =  (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTypeLinkage = JSON.parse(event.target.value);
        debug && console.log('AddItemTypeChooser handle change gets', newTypeLinkage)
        setSelectedValidTypeLinkage(newTypeLinkage);

    }


    // const handleDelete = (e: React.MouseEvent<Element, MouseEvent>) => { };

    const treeItemProps: StyledTreeItemConfig = treeCosmeticsByType();
    const { color, bgColor } = treeItemProps;
    const classes = useTreeItemStyles();

    return (
        <TreeItem
            {...{

                nodeId,
                style: {
                    '--tree-view-color': color,
                    '--tree-view-bg-color': bgColor,
                } as CSSProperties,
                // onLabelClick: handleIconClick
            }}

            label={
                <div className={classes.labelRoot}>
                    <AddIcon color="inherit" className={classes.labelIcon} onClick = { handleIconClick} />

                    <TextField {...{
                        variant: 'standard', size: 'small', className: classes.textField,
                        select: true,
                        InputProps: {
                            classes: {
                                input: classes.smaller,
                            },
                        },
                        InputLabelProps: {
                            classes: {
                                root: classes.smaller
                            },
                            shrink: true
                        },          
                        onChange: handleChange,
                        SelectProps: {
                        //   native: true,
                        },

                           
                        value: JSON.stringify(selectedValidTypeLinkage)

                    }
                    }>
                        {validTypes.map((option, i) => (
                            <MenuItem { ...{
                                key: `${option.objectTypeId}_${option.adjacencyType.valueOf()}`,
                                value: JSON.stringify(option)
                            }}>
                                {option.objectTypeId}
                            </MenuItem>
                        ))}
                    </TextField>



                </div>
            }



            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}



            {...other}
        />
    );
}
    
