import { ValidBusinessObject } from "@iotv/iotv-v3-types";
import { SvgIconProps, TextField } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import TreeItem from '@material-ui/lab/TreeItem';
import ClearIcon from '@material-ui/icons/Clear';
import { CSSProperties } from "@material-ui/styles";
import React, { useEffect } from "react";
import { StyledTreeItemProps } from "../../../types/ComponentProps";
import { useTreeItemStyles } from "./treeStyle copy";
import { treeCosmeticsByType } from "../../../cosmetics/tree/treeCosmeticsByType";
import { AdjacentType } from "@iotv/iotv-v3-types";


const debug = process.env.REACT_APP_DEBUG && false;

  


export const NewObjectItemEdit = (props: Omit<StyledTreeItemProps, 'handleEndIconClick'>  & { contextObject: ValidBusinessObject, newObject: ValidBusinessObject | undefined
  , setNewObject: (vob: ValidBusinessObject) => void,  handleSave: ( newObject: ValidBusinessObject | undefined ) => void, setViewMode: () => void } ) => {
  const classes = useTreeItemStyles();
  const { nodeId, labelText, labelInfo, labelIcon, color, bgColor, handleIconClick, handleSave, mode, contextObject,  newObject, setNewObject, setViewMode,  ...other } = props;

      useEffect(() => {
      debug && console.log('NewObjectItemEdit used effect on mode change')
    }, [ mode  ])


    const [ LabelIcon, setLabelIcon ] = React.useState<React.ElementType<SvgIconProps<"svg", {}>>>(labelIcon)

    useEffect( () => {
      const cosmetics =  treeCosmeticsByType( newObject );
      setLabelIcon(cosmetics.labelIcon)
    }, [ newObject?.type ])

  return (
    <TreeItem
      { ...{ 

        nodeId,
          style: {
              '--tree-view-color': color,
              '--tree-view-bg-color': bgColor,
            } as CSSProperties,
            onLabelClick: handleIconClick
      }}

      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />

            <TextField { ...{
                variant: 'standard', size: 'small', className: classes.textField,
                InputProps: {
                  classes: {
                    input: classes.smaller,
                  },
                },
                InputLabelProps: {
                  classes: {
                    root: classes.smaller
                  },
                  shrink: true
                },
                onChange : 
                  (event: React.ChangeEvent<HTMLInputElement>) => { 
                      const mutatedOb = { ...newObject } as ValidBusinessObject
                      mutatedOb.name = event.target.value;
                      setNewObject(mutatedOb)
                  },
                value:  newObject?.name 
              
              }
            }></TextField>

            { newObject?.name && newObject.name.length > 0} {
              <SaveIcon { ...{
                onClick: () => handleSave( newObject )
              }}></SaveIcon>
            }
            <ClearIcon { ...{
              onClick: () => setViewMode()
            }}></ClearIcon>

        </div>
      }

     
     
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      


      {...other}
    />
  );
}
