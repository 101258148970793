import { CustomerAccountType, PossibleBusinessObject } from '@iotv/datamodel-core';
import C021TankUseType from '@c021/datamodel';

export interface CommunityProps {
  name: string;
  livelihood: string;
  numOfHouseholds: number;
  area: number | undefined;
  averageOccupant: number;
  population: number | undefined;
}

export const createCommunityZombie = (props: CommunityProps): PossibleBusinessObject => {
  const { livelihood, area, averageOccupant, numOfHouseholds, population, name } = props;
  return {
    livelihood: livelihood,
    area: area,
    householdAverageOccupants: averageOccupant,
    numHouseholds: numOfHouseholds,
    numTankUsers: population,
    name: name,
    accountType: CustomerAccountType.Y,
    type: 'Customer',
  };
};
