import generatedConfig from '@iotv/config';
import { NavSections } from './components/navigation/NavSections';
// import { NavSections as c021NavSections } from './components/navigation/NavSections';
import { version } from '../package.json';
import { MapLike } from '@iotv/iotv-v3-types';
import { c021NavSections } from './venture/c021/navigation/c021_NavSections';

const getInitialRedirect = (projectCode: string) => {
  const initialRedirectMap: MapLike<any> = {
    c021: `${c021NavSections.COMMUNITYVIEW}`,
    i001: `${NavSections.THINGSVIEW}/Tiger`,
    i301: `${NavSections.THINGSVIEW}/Irrigator`,
    v001: `${NavSections.THINGSVIEW}/House`,
    v015: `${NavSections.THINGSVIEW}/Horse`,
    v016: `${NavSections.THINGSVIEW}/Silo`,
    v017: `${NavSections.THINGSVIEW}/Tank`,
    v020: `${NavSections.THINGSVIEW}/Farm`,
    default: `${NavSections.THINGSVIEW}`,
  };

  return initialRedirectMap[projectCode.substr(0, 4)] ?? initialRedirectMap.default;
};

const config = {
  app: {
    version,
    stage: generatedConfig.stage,
    ...generatedConfig.app,

    initialRedirect: getInitialRedirect(generatedConfig.app.appCode),
    defaultPreferences: {
      uiTheme: 'light',
    },
    geo: {
      initialCenter: {
        lat: 37.2431,
        lng: -115.793,
      },
      initialZoom: 6,
    },
  },
  aws: {
    ...generatedConfig.aws,
    amplify: {
      logLevel: 'INFO',
    },
  },
  google: {
    mapsApiUri: `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyDLx6yzrlmjQTQHnV9rKzqZS6k-qhsZE6M'}&v=quarterly&libraries=geometry,drawing`,
    chartApiUri: 'https://www.gstatic.com/charts/loader.js',
    apiUri: 'https://apis.google.com/js/client.js',
    ...generatedConfig.google,
  },

  locale: 'en-NZ', // TODO get into state from https://ipapi.co/json/
};

export default config;
