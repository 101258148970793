import { Button, CircularProgress, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoCamera } from '@material-ui/icons';
import React from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import { put } from '../../data/aws/s3/UserBlobs';
import { FileUploadCardProps } from '../../types/AppTypes';


const useStyles = makeStyles((theme) => (styles(theme)));

const debug = process.env.REACT_APP_DEBUG && false;


export default function FileSelectCard( props: FileUploadCardProps ) {

  const classes = useStyles();

  const [ uploadProgress, setUploadProgress ] = React.useState(0);

  debug && console.log('Details Card has props', props)

 

  const { matchedPrimary, transactionFunctions, title, parentHooks } = props

  debug && console.log('User Details Card has saveObject', transactionFunctions?.saveObject)

  const nameLabel = title || `${matchedPrimary.name} facts` || `New ${matchedPrimary.type}`

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>)  => {
     debug && console.log(e.target.files);
    const fileList = e.target.files;
    const directoryString = `${matchedPrimary.sk}/`
    if (fileList) {
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const file = fileList[i];
       const progressCallback = (progressEvent : ProgressEvent) => {

         const { loaded, total } = progressEvent;
         setUploadProgress(loaded / total * 100);
         if ( loaded === total ) {
           setTimeout( () => setUploadProgress(0), 1000)
         }
       }
       const uploadRes = await put(directoryString, file, progressCallback)
        debug && console.log('uploadRes', uploadRes);
        if (typeof parentHooks?.addKey === 'function' ) {
          parentHooks.addKey(uploadRes)
        }
      }
    }

  }


  return (
    <Card className={classes.root}>
      <CardHeader
        title = {`${nameLabel}`}
      ></CardHeader>
       <CardContent>
       <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.displayNone}
          id="contained-button-file"
          multiple
          type="file"
          onChange = {( e ) => handleFileUpload(e) }
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
        <input accept="image/*" className={classes.displayNone} id="icon-button-file" type="file" />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      { uploadProgress > 0 && <CircularProgress variant="static" value={uploadProgress} /> }
      </div>
      </CardContent>
    </Card>
  );
}