import React, { Component, ReactComponentElement } from 'react'
import { NavLink } from 'react-router-dom'

type FilterLinkParams = { filter: string, children: Component<{}, {}, any> | string | ReactComponentElement<any, any>}

const FilterLink = ({ filter, children }: FilterLinkParams) => (
  <NavLink
    exact
    to={filter === 'SHOW_ALL' ? '/' : `/${filter}`}
    activeStyle={{
      textDecoration: 'none',
      color: 'white'
    }}
    style={{
      textDecoration: 'none',
      color: 'white'
    }}
  >
    {children}
  </NavLink>
)

export default FilterLink