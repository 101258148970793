import { ValidBusinessObject } from '@iotv/datamodel-core';
import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DMPTrigger, triggerOptions } from '../DMPExtrasV2';
import ConditionTriggersModal, { makeConditionTriggerDescription } from './DroughtPlanModals/ConditionTriggersModal';
import GeneralTriggersModal from './DroughtPlanModals/GeneralTriggersModal';
import StatusTriggersModal, { makeStatusTriggerDescription } from './DroughtPlanModals/StatusTriggersModal';
import { NoneMade } from './ManagementGenerics';

interface TriggersPageProps {
  index: number;
  source: ValidBusinessObject;
  setSource?: Dispatch<SetStateAction<ValidBusinessObject>>;
}

const TriggersPage: React.FC<TriggersPageProps> = (props: TriggersPageProps) => {
  let { index, source, setSource } = props;
  let [create, setCreate] = useState<boolean>(false);
  let [actionType, setActionType] = useState<string>('');
  let [triggers, setTriggers] = useState<DMPTrigger[]>([]);
  let [position, setPosition] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTriggers(source.stage[index].stageTriggers);
  }, [index, source]);

  const addNewTrigger = (type: string) => {
    setCreate(true);
    setActionType(type);
  };

  const editTrigger = (pos: number, type: string) => {
    setPosition(pos);
    addNewTrigger(type);
  };

  const cancel = useCallback(() => {
    setCreate(false);
    setActionType('');
    setPosition(undefined);
  }, []);

  const update = (newTrigger: DMPTrigger | undefined, pos: number | undefined) => {
    if (newTrigger === undefined) {
      if (pos !== undefined) {
        triggers.splice(pos, 1);
      }
    } else if (setSource !== undefined) {
      if (pos === undefined) {
        triggers!.push(newTrigger);
      } else {
        triggers[pos] = newTrigger;
      }

      let newStages = source.stage;
      newStages[index].triggers = triggers;
      setSource({ ...source, stage: newStages });
      cancel();
    }
  };

  return (
    <>
      <div>
        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
            {setSource &&
              triggerOptions.map((option) => {
                if (index === 0 && option === 'Condition') {
                  return <></>;
                }
                return (
                  <Button variant='contained' onClick={() => addNewTrigger(option)}>
                    <Add />
                    <b>{option} Trigger</b>
                  </Button>
                );
              })}
          </Stack>
          {triggers && triggers.length > 0 ? (
            <Box sx={{ minHeight: '300px', width: '100%', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={'100px'} align='left'>
                      <b>Type</b>
                    </TableCell>
                    <TableCell align='left'>
                      <b>Trigger</b>
                    </TableCell>
                    <TableCell width={'100px'} align='center'>
                      <b>Activated</b>
                    </TableCell>
                    <TableCell width={'100px'} align='left' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {triggers.map((trigger, i) => {
                    return (
                      <TableRow>
                        <TableCell align='left'>{trigger.type}</TableCell>
                        <TableCell align='left'>
                          {trigger.type === 'Condition' &&
                            trigger.timeframe &&
                            makeConditionTriggerDescription(trigger.triggerDescription, trigger.metric, trigger.timeframe)}
                          {trigger.type === 'Status' &&
                            trigger.timeframe &&
                            makeStatusTriggerDescription(trigger.triggerDescription, trigger.metric, trigger.timeframe, index === 0, trigger.unit)}
                          {trigger.type === 'General' && trigger.triggerDescription}
                        </TableCell>
                        <TableCell align='center'>{trigger.type === 'General' ? 'N/A' : <Checkbox disabled checked={trigger.triggerState} />}</TableCell>
                        <TableCell align='center'>
                          <Button variant='contained' onClick={() => editTrigger(i, trigger.type)}>
                            <Edit />
                            <b>Edit</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <NoneMade text={'Trigger'} />
          )}
        </Stack>
      </div>
      {create && actionType === 'Condition' && index !== 0 && <ConditionTriggersModal close={cancel} update={update} index={position} existing={triggers} />}
      {create && actionType === 'Status' && <StatusTriggersModal close={cancel} update={update} index={position} existing={triggers} isFirst={index === 0} />}
      {create && actionType === 'General' && <GeneralTriggersModal close={cancel} update={update} index={position} existing={triggers} isFirst={index === 0} />}
    </>
  );
};

export default TriggersPage;
