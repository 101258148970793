import React, { useEffect, useState } from 'react';

import { Button, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { useStyles } from '../cosmetics/communityStyles';
import { AdjacentType, PathDirection, QueryTypes, TraverserQueryPathBody, ValidBusinessObject } from '@iotv/iotv-v3-types';
import { Community, Tank } from '@c021/datamodel';
import { useHistory } from 'react-router-dom';
import { getZombieInstanceFromPrimaryKey, ObjectHistory } from '@iotv/datamodel-core';
import { getAdjacent2Wrapper, getOne, getParents } from '../../../data/daoFunctions/daoFunctions';
import ApiGateway from '../../../data/aws/api-gateway/ApiGateway';
import { GridColDef, GridCellParams, DataGrid } from '@mui/x-data-grid';

type CustomerTankPair = {
  id: string;
  customer: ValidBusinessObject;
  tank: Tank;
  tankCommunity: Community;
  tankHistory: ObjectHistory;
};

export const TanksInCommunityView = (props: any) => {
  const styles = useStyles();
  const history = useHistory();

  const [filter, setFilter] = useState('');
  const [customerTankPairs, setCustomerTankPairs] = useState<CustomerTankPair[]>([]);
  const [pairsToDisplay, setPairsToDisplay] = useState<CustomerTankPair[]>([]);

  useEffect(() => {
    const filteredPairs = filter ? customerTankPairs.filter((pair) => pair.tank.tank_type === filter) : customerTankPairs;
    setPairsToDisplay(filteredPairs);
  }, [filter]);

  useEffect(() => {
    fetchCommunity();
  }, []);

  const fetchCommunity = async () => {
    const communityID = history.location.pathname.replace('/COMMUNITYVIEW/', '').replace('/tanks', '');

    const zombie = getZombieInstanceFromPrimaryKey(`Customer:${communityID}`) as ValidBusinessObject;
    console.log('zombs', zombie);

    const community = await getOne(zombie);
    console.log(community);

    if (community.data) {
      const tanks = await fetchCommunityTanks(community.data as Community);

      const pairs: CustomerTankPair[] = [];
      let id = 0;
      if (tanks) {
        const customerTankPairs = await Promise.all(
          tanks.map(
            (tank: ValidBusinessObject): Promise<CustomerTankPair> =>
              new Promise(async (resolve, reject) => {
                const customer = await getParents(tank as ValidBusinessObject, 'Customer');
                // console.log(customer.data)
                if (customer.data?.Items) {
                  const tankCommunity = await getParents(customer.data?.Items[0] as ValidBusinessObject, 'Customer');
                  const tankHistory = await getAdjacent2Wrapper({
                    adjacencyType: AdjacentType.CHILD,
                    objectTypeId: 'ObjectHistory',
                    scopeDefinitingContextObject: tank as ValidBusinessObject,
                    includeEdges: true,
                  });

                  if (tankCommunity.data?.Items && tankHistory.data?.Items) {
                    const pair = {
                      id: tank.id,
                      customer: customer.data.Items[0] as ValidBusinessObject,
                      tank: tank as Tank,
                      tankCommunity: tankCommunity.data.Items[0] as Community,
                      tankHistory: tankHistory.data.Items[0] as ObjectHistory,
                    };

                    resolve(pair);
                  }
                }
              })
          )
        );

        setCustomerTankPairs([...customerTankPairs]);
        setPairsToDisplay([...customerTankPairs]);
      }
    }
  };

  const fetchCommunityTanks = async (communityObject: Community): Promise<ValidBusinessObject[]> => {
    const simpleTypePath = [
      { type: 'Customer', direction: PathDirection.child },
      { type: 'Tank', direction: PathDirection.child },
    ];

    let tankItems: ValidBusinessObject[] = [];

    const queryBody: TraverserQueryPathBody = {
      type: QueryTypes.directedPath,
      simpleTypePath: simpleTypePath,
      normalize: false,

      items: [communityObject], // CHILD should be from Datastore.pathDirection
    };

    const path = '/query/';
    const { err, data } = await ApiGateway.post(path, queryBody);

    if (err == null && data != null) {
      const tanks = data.filter((item: ValidBusinessObject) => item.type === 'Tank');

      const tanksAndNullRes = await Promise.all(
        tanks.map(async (tank: ValidBusinessObject): Promise<ValidBusinessObject | null> => {
          const tankItem = await getOne(tank);
          return tankItem.data;
        })
      );

      console.log(tanksAndNullRes);
      tanksAndNullRes.forEach((item) => {
        if (item) {
          tankItems.push(item as ValidBusinessObject);
        }
      });
    }
    return tankItems;
  };

  const columns: GridColDef[] = [
    {
      field: 'customer',
      headerName: 'Customer (Name)',
      flex: 1,
      minWidth: 170,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.customer?.name ? params.row.customer.name : 'No customer attached'}</>;
      },
    },
    {
      field: 'tankType',
      headerName: 'Tank Type',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.tank_type}</>;
      },
    },
    {
      field: 'community',
      headerName: 'Community',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tankCommunity.name}</>;
      },
    },
    {
      field: 'tankName',
      headerName: 'Tank Name',
      flex: 3,
      // minWidth : 500,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.name}</>;
      },
    },
    {
      field: 'fillLevel',
      headerName: 'Fill Level',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        const fillPercentage = Math.round(params.row.tank.current_filled_factor * 100);
        let colour = '';

        if (fillPercentage > 75 && fillPercentage <= 100) {
          colour = '#99ff99';
        } else if (fillPercentage > 50 && fillPercentage <= 75) {
          colour = '#ffff66';
        } else if (fillPercentage > 25 && fillPercentage <= 50) {
          colour = '#ffcc99';
        } else {
          colour = '#ff8080';
        }

        return <div style={{ backgroundColor: colour, width: '100%' }}>{typeof fillPercentage === 'number' ? `${fillPercentage}%` : 'Calculating...'}</div>;
      },
    },
    {
      field: 'runOutDate',
      headerName: 'Run Out Date',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.predicted_run_out_date}</>;
      },
    },
    {
      field: 'daysLeft',
      headerName: 'Days Left',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.days_to_empty}</>;
      },
    },
    {
      field: 'numTankUsers',
      headerName: '# tank users',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{params.row.tank.no_of_users}</>;
      },
    },
    {
      field: 'yesterdaysUsage',
      headerName: 'Yesterdays Usage',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        return <>{Math.round(params.row.tank.volume_used_yesterday)} L</>;
      },
    },
    // { TO be included in later release
    //     field : 'avgDailyUsage',
    //     headerName : 'Average Daily Usage (at start of stage)',
    //     flex : 1,
    //     minWidth : 305,
    //     headerAlign: 'center',
    //     align : 'center',
    //     type : 'number',
    // },
    {
      field: 'currentAvgDailyUsage',
      headerName: 'Current Average Daily Usage',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        // const dailyUsageHistory = params.row.tankHistory.dailyStateHistory.usage_in_day_assumed_hourly

        console.log('Render Cell for averageDailyUsage', params.row.tank);

        return <>{params.row.tank.averageDailyUsage ? `${Math.round(params.row.tank.averageDailyUsage)} L` : 'Calculating...'}</>;
      },
    },
    {
      field: 'currentAvgDailyUsagePerPerson',
      headerName: 'Current Average Daily Usage (per occupant)',
      flex: 1,
      minWidth: 400,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        // const dailyUsageHistory = params.row.tankHistory.dailyStateHistory.usage_in_day_assumed_hourly

        const dailyUsageHistory = params.row.tankHistory?.dailyStateHistory?.usage_in_day;

        let averageUsage = 0;

        if (dailyUsageHistory) {
          if (dailyUsageHistory.length >= 14) {
            let pastTwoWeeks = dailyUsageHistory.slice(0, 13);
            averageUsage =
              pastTwoWeeks.reduce((acc: number, curr: number) => {
                return (acc += curr);
              }, 0) / 14;
          } else {
            averageUsage =
              dailyUsageHistory.reduce((acc: number, curr: number) => {
                return (acc += curr);
              }, 0) / dailyUsageHistory.length;
          }
        }

        return <>{dailyUsageHistory && averageUsage !== Infinity ? `${Math.round(averageUsage / params.row.tank.no_of_users)} L` : 'Calculating...'}</>;
      },
    },
    // {
    //     field : 'changeInDailyUsage',
    //     headerName : 'Change in Daily Usage',
    //     flex : 1,
    //     minWidth : 210,
    //     headerAlign: 'center',
    //     align : 'center',
    //     type : 'number'
    // },
  ];

  return (
    <div>
      <Grid container className={styles.container} spacing={2}>
        <Grid item>
          <Typography variant='h5' component='h5'>
            Tanks In Community{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('Community')} color='primary'>
            Community Tanks
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('Household')} color='primary'>
            Household Tanks
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => setFilter('')} color='primary'>
            All Tanks
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <DataGrid
              onRowClick={(e) => history.push(`/THINGVIEW/Tank/${e.row.id}`)}
              disableSelectionOnClick={true}
              disableColumnMenu={true}
              autoHeight={true}
              rows={pairsToDisplay}
              columns={columns}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
