import { Button, Card, CardContent, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { DownLinkControlObjectType, Payload } from '@v015/datamodel';
import React, { useEffect } from "react";
import { DeviceOptions, DeviceOptionsMap, DownLinkSetOptions } from "../../../../../../../v015-2/datamodel/target/v015-2/datamodel/src/payload";
import {  } from "@v015/datamodel";
import { camelToDisplay, getObjectHash, objectsMatch } from "@iotv/datamodel-core";
import { MapLike, verify } from "@iotv/iotv-v3-types";
import { ConfigTabProps } from "../../types";

const debug = process.env.REACT_APP_DEBUG && false;

type V015UplinkPayloadInstance = Payload.UplinkPayloadInstance

export type DownlinkConfigCardProps<T extends MapLike<any>> = Pick<ConfigTabProps<T>, 'selectedDevices' | 'deviceConfig' | 'handleSendTransactionButtonClick' | 'setReceivedDeviceControlTransactions'>

type DeviceConfigControlAtts = 'downlinkSetOptions'

type DownlinkConfigControlObjectType = Pick<DownLinkControlObjectType, DeviceConfigControlAtts>

export const DeviceOptionsCard = ({ deviceConfig, handleSendTransactionButtonClick, selectedDevices, setReceivedDeviceControlTransactions }: DownlinkConfigCardProps<V015UplinkPayloadInstance>) => {


  const deviceConfigHash = getObjectHash(deviceConfig)

    useEffect( () => {

      const reported = deviceConfig?.reported?.optionsValues
      debug && console.log('DeviceOptionsCard used effect on ', {deviceConfig, reported})
      setReportedSettings( reported )
      setDeviceOptionsMap( reported )

  },[ deviceConfigHash ])


    const [ reportedSettings,  setReportedSettings ] = React.useState<  DownLinkControlObjectType['downlinkSetOptions'] | undefined >( undefined  )
    const [ deviceOptionsMap, setDeviceOptionsMap ] = React.useState<DeviceOptionsMap | undefined >( undefined)



//    const handleDownlinkReqOptions = () => handleSendTransactionButtonClick(desiredPayload);
    const handleDownlinkSetOptions = ( optionsKey: keyof DeviceOptionsMap, value: boolean ) => {
      setDeviceOptionsMap( { ...deviceOptionsMap, [optionsKey]: value } as DeviceOptionsMap )
    }

    const handleSend = () => {
      if ( deviceOptionsMap ) {
        handleSendTransactionButtonClick({
         
        })
      }
    }
     


    const handleDownlinkReqOptions = () => handleSendTransactionButtonClick(
      {
        
      })

    return <Card>
            <CardContent>
              { reportedSettings &&  <TableContainer {...{ style: { paddingBottom: "0.5em" } }}>
                <Table>
                <TableBody>
                  { deviceOptionsMap && Object.entries(deviceOptionsMap).map( ([ k, v ]) => 
                  <TableRow>
                  <TableCell>
                   { k }
                  </TableCell>

                  <TableCell>
                    <FormControl component="fieldset">
                      <RadioGroup { ...{
                        row: true,
                        name: "controlled-radio-buttons-group",
                        defaultValue: v.toString(),
                        value: v.toString(),
                        onChange: (event: React.ChangeEvent<HTMLInputElement> ) => {
                          // const clone = desiredPayload;
                          // clone.downlinkSetOptions.option = (event.target as HTMLInputElement).value === "true";
                          // setDesiredPayload(clone);
                         handleDownlinkSetOptions( k as keyof DeviceOptionsMap, (event.target.value === "true"))
                        }
                      }}

                        
                       
                        
                       
                      >
                        <FormControlLabel
                          value={"true"}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                ) }


                  <TableRow>
                    <TableCell>
                    <Button { ...{
                      variant: "contained",
                      color: "primary",
                      disabled: selectedDevices.length === 0  || objectsMatch( reportedSettings, deviceOptionsMap ?? {} ),
                      onClick: handleSend 
                    }}

                      
                    >
                      Send
                    </Button>
                    </TableCell>
                    
                  </TableRow>
                </TableBody>
                </Table>
              </TableContainer> }
             
              <TableContainer>
                <Table>
                  <TableRow>
                  <TableCell>
                    <Button
                      variant={"contained"} 
                      color={"primary"}
                      disabled={selectedDevices.length === 0}
                      onClick={ handleDownlinkReqOptions }
                    >
                      Request Options
                    </Button>
                    </TableCell>
                  </TableRow>

                </Table>
              </TableContainer>
            </CardContent>
          </Card>
    } 
