import {
  Box,
  Button,
  Card,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DMPTrigger, statusOptions } from '../../DMPExtrasV2';
import styles from './DroughtModal.module.css';
const modalRoot = document.querySelector('#modal-root');

interface statusTriggersModalProp {
  close: () => void;
  update: (input: DMPTrigger | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: DMPTrigger[];
  isFirst: boolean;
}

export const makeStatusTriggerDescription = (
  statusTrigger: string | undefined,
  inputThreshold: number | undefined,
  inputDuration: number,
  isFirst: boolean,
  unit: string = 'Litres'
): string => {
  return `${statusTrigger || '?'} is ${isFirst ? 'greater' : 'less'} than ${inputThreshold || '?'} ${unit === 'Litres' ? 'L' : '%'} for more than ${
    inputDuration || '?'
  } days`;
};

const StatusTriggersModal: React.FC<statusTriggersModalProp> = (props: statusTriggersModalProp) => {
  let { close, update, index, existing, isFirst } = props;

  let [valid, setValid] = useState<boolean>(false);
  let [statusTrigger, setStatusTrigger] = useState<string>('');
  let [inputThreshold, setInputThreshold] = useState<number>(0);
  let [inputDuration, setInputDuration] = useState<number>(0);

  let [description, setDescription] = useState<string>('');
  let [unit, setUnit] = useState<string>('Litres');

  useEffect(() => {
    setDescription(makeStatusTriggerDescription(statusTrigger, inputThreshold, inputDuration, isFirst, unit));

    setValid(statusTrigger !== '' && inputThreshold > 0 && inputDuration > 0);
  }, [statusTrigger, inputThreshold, inputDuration, unit]);

  useEffect(() => {
    if (index !== undefined && existing.length > index) {
      let source = existing[index];
      setStatusTrigger(source.triggerDescription);
      source.metric && setInputThreshold(source.metric);
      source.timeframe && setInputDuration(source.timeframe);
    }
  }, [index, existing]);

  const addStage = (triggerDescription: string, metric: number, timeframe: number, pos: number | undefined, unit: string) => {
    update({ type: 'Status', triggerDescription, metric, timeframe, triggerState: false, unit }, pos);
    close();
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    close();
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.form}>
        <Card>
          <Box m={2}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h5' component='h5'>
                Create a Status Trigger
              </Typography>
              <TextField
                // disabled={}}
                label={'Select Status Trigger'}
                select
                variant='outlined'
                fullWidth
                onChange={(e) => setStatusTrigger(e.target.value)}
                value={statusTrigger || ''}
                size='small'
              >
                {statusOptions.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
              <div className={styles.full}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <i>Input threshold: </i>{' '}
                    <TextField
                      value={inputThreshold !== 0 ? inputThreshold : ''}
                      variant='outlined'
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>is {isFirst ? 'greater' : 'less'} than</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        inputMode: 'numeric',
                        shrink: true,
                      }}
                      style={{ width: '200px' }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (value < 0) {
                          value = 0;
                        }
                        setInputThreshold(value);
                      }}
                    />
                  </Stack>
                  <RadioGroup
                    {...{
                      row: true,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => setUnit(value),
                      value: unit,
                      name: 'radio-buttons-group',
                    }}
                  >
                    <FormControlLabel value='Litres' control={<Radio />} label='Litres' />
                    <FormControlLabel value='Percent' control={<Radio />} label='Percent' />
                  </RadioGroup>

                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <i>Input Duration: </i>
                    <TextField
                      value={inputDuration !== 0 ? inputDuration : ''}
                      variant='outlined'
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>for more than</InputAdornment>,
                        endAdornment: <InputAdornment position='start'>days</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        inputMode: 'numeric',
                        shrink: true,
                      }}
                      style={{ width: '240px' }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (value < 0) {
                          value = 0;
                        }
                        setInputDuration(value);
                      }}
                    />
                  </Stack>
                </Stack>
              </div>
              <TextField
                disabled
                label={'Status Description'}
                value={description}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                minRows={1}
                multiline
                fullWidth
                style={{ width: '100%' }}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </Box>
          <Box m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button
                variant='contained'
                color='error'
                disabled={index === undefined}
                onClick={() => {
                  index !== undefined && deleteItem(index);
                }}
              >
                Delete
              </Button>
              <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                <Button variant='contained' onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={!valid}
                  onClick={() => {
                    addStage(statusTrigger, inputThreshold, inputDuration, index, unit);
                  }}
                >
                  {index === undefined ? 'Create' : 'Update'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </div>
    </div>,
    modalRoot
  );
};

export default StatusTriggersModal;
