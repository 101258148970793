import { makeStyles } from "@material-ui/core";

export const useStylesTank = makeStyles((theme) => ({
    root: {
      //flexGrow: 0,
      margin: theme.spacing(1, 0),
    },
    house: {
      //display: "flex",
      margin: theme.spacing(1, 0)
    },
    spaceHouse: {
      marginLeft: "10",
    },
  
    actionItem: {
      // These styles are applied to the root element when
      // when selected. This changes the color of both the
      // icon and label text.
      "&$selected": {
        color: "#ffc107"
      }
    },
    selectDays: {
      //width: 500
    },
    selected: {
      color: '#ffc107'
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      //maxWidth: 400,
      maxHeight: 400,
    },
    editTank: {
      marginLeft: "auto",
      marginRight: -12,
      marginTop: 10,
      margin: theme.spacing(1),
    },
    image: {
      width: 120,
      height: 120,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    toggleContainer: {
      margin: theme.spacing(2, 0),
    },
  }));
  
  export const paperStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        // width: 100%,
        margin: "10px", 
        // margin-top: "20px"
      }
    },
    indicator: {
      backgroundColor: "#ffc107"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    weather: {
      margin: theme.spacing(2, 0),
      //color: theme.palette.text.secondary,
      [theme.breakpoints.down("sm")]: {
        //width: "200px",
        fontSize: 9
      }
    },
  
    card: {
      height: "120px",
      alignItems: "center",
      textAlign: "center",
      fontSize: "105%",     
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    value: {
      fontSize: "105%",
      textAlign: "center"
    },

    rain: {
      maxWidth: 50,
      maxHeight: 50,
      [theme.breakpoints.down("sm")]: {
        width: "20px",
        height: "20px",
        fontSize: 11
      }
      //background : '#FFFFFF'
    },
    precentage: {
      [theme.breakpoints.down("sm")]: {
        width: "90px"
      }
    },
  }));
  
  