import { Grid, Typography, FormControl, TextField, Card, CardContent, Radio, RadioGroup, FormControlLabel, Select, InputLabel, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Area } from '../AddArea';
import { ValidBusinessObject } from '@iotv/datamodel';
import { SelectChangeEvent } from '@mui/material/Select';
import { AreaStylerComponent } from './CommonStyle';

export interface StyleInFormProps {
  areaStyler: ValidBusinessObject[] | undefined;
  values: Area;
  setValues: React.Dispatch<React.SetStateAction<Area>>,
  selectedStyler: ValidBusinessObject | undefined,
  setSelectedStyler: React.Dispatch<React.SetStateAction<ValidBusinessObject | undefined>>,
  handleChange: Function
}

export default function StyleInForm({ areaStyler, values, setValues, setSelectedStyler, handleChange, selectedStyler }: StyleInFormProps) {
  const [radioValue, setRadioValue] = useState('manually');


  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  return (
    <Card>
      <CardContent>
        <FormControl>
          {/* <FormLabel id="demo-row-radio-buttons-group-label">Style</FormLabel> */}
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="manually" control={<Radio />} label="Manually Enter" />
            <FormControlLabel value="styler" control={<Radio />} label="Select Styler" />

          </RadioGroup>
        </FormControl>
        <Grid container>
          <Grid item>
            <Typography sx={{ marginTop: '25px' }}>Style</Typography>
          </Grid>
          {radioValue === 'manually' ? (<>
            {/* <Grid item>
              <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                <TextField id="color" label="color" variant="standard" size="small" onChange={handleChange('color')} />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                <TextField id="opacity" label="opacity" variant="standard" size="small" onChange={handleChange('opacity')} />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
                <TextField id="lineWidth" label="line width" variant="standard" size="small" onChange={handleChange('lineWidth')} />
              </FormControl>
            </Grid> */}
            {AreaStylerComponent(values, handleChange)}
          </>) : (<>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">Styler</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedStyler ? selectedStyler.pk : ''}
                onChange={(event: SelectChangeEvent) => {
                  const pk = event.target.value;
                  setSelectedStyler(areaStyler?.filter((val) => val.pk === pk)[0]);
                }}
                label="Styler"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {areaStyler?.map((val) => (
                  <MenuItem value={val.pk}>
                    {val.name}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>)}


        </Grid>
      </CardContent>

    </Card>

  )

}