import { deviceEnabledTypes, GeofencePointsType, GeofenceType, ValidBusinessObject } from '@iotv/datamodel';
import { makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { mapStyles } from '../../../../cosmetics/MapCardStyles';
import styles from '../../../../cosmetics/sharedViewStyles';
import { constructAllItems } from '../../../../data/daoFunctions/stateFns';
import { MapCardProps, MapLikeT, NormalizedData, ViewObject } from '../../../../types/AppTypes';
import GoogleMap from './GoogleMap';

const debug = process.env.REACT_APP_DEBUG && false;

interface MatchClasses { theme: any };
const useStyles = makeStyles((theme) => {
  return {
      ...mapStyles(theme),
  }
});


function MapView( props:  MapCardProps & { classes: any, normalData?: NormalizedData }  ): JSX.Element {

    // const localClasses = useStyles();
    const { classes, geoImplementingObject, normalData = {}, viewObjects, polygons, locatableAddress} = props;

    const [ editedGeofences, setEditedGeofences ] = React.useState<GeofenceType[]>([])

    debug && console.log('Map view got', props)

    const geofenceViewObjects = constructAllItems(normalData, ['Geofence']);
    debug && console.log('Map view geofences', geofenceViewObjects)

    const allPolygons:  MapLikeT<GeofencePointsType[]> = polygons ?? {};
    geofenceViewObjects.forEach( (viewObject) => {
      const points = (viewObject.matchedPrimary as ValidBusinessObject & {points?: GeofencePointsType[]} ).points;
      if (viewObject.matchedPrimary?.sk && points) {
        allPolygons[viewObject.matchedPrimary.sk] = points
      }
    }) ;
    
    const extraViewObjects = constructAllItems(normalData, Object.keys(deviceEnabledTypes))


    let allViewObjects: ViewObject[] = [];
    if (viewObjects) allViewObjects = allViewObjects.concat(viewObjects);
    if (extraViewObjects) allViewObjects = allViewObjects.concat(extraViewObjects);

    let parentCardFns = undefined;
    if ( props.editable && geoImplementingObject && editedGeofences ) {
      parentCardFns = {
        setEditedGeofences: (geofences: GeofenceType[]) => { 
          setEditedGeofences( geofences )
        }
      }
    } 
    return ( 
       <GoogleMap { ...{...props, polygons: allPolygons, viewObjects: allViewObjects, locatableAddress, parentCardFns }} ></GoogleMap>
    );
    
}    

export default withStyles(styles)(MapView)