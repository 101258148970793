import React, { Component } from 'react'
import { LineChartOptionsMandatory } from '../../types/AppTypes';


const debug = process.env.REACT_APP_DEBUG && false;

type Props = {
  data: google.visualization.DataTable
  options: google.visualization.LineChartOptions & LineChartOptionsMandatory
  name: string
};

type State = {

  
};

export default class GoogleLineChart extends Component<Props, State>  {
  ref: string  | React.RefObject<HTMLDivElement> = React.createRef()

  chart: google.visualization.LineChart | undefined = undefined;

  render() {
    const {id, title, height} = this.props.options;
    return <div { ...{id, title, style: {height}, ref: this.ref } } ></div>
  }

  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    debug && console.log('Graph updating', this.props.data.getNumberOfRows())
    this.drawChart();
  }

  drawChart() {

    const target = (this.ref as React.RefObject<HTMLDivElement>).current

    if (target) {
      debug && console.log('first data', this.props.data);
      if ( !(this.chart)) {
        this.chart = new google.visualization.LineChart(
          target
        );
      }
     
      debug && console.log('Drawing chart', this.props.data.getNumberOfRows())
      this.chart.draw(this.props.data, this.props.options);
      
    } else {
       debug && console.log('Graph did not update')
    }
 
  }
};