import { ThingShadowState, ValidBusinessObject } from '@iotv/datamodel';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styles from '../../../../cosmetics/sharedCardStyles';
import { UserTransactionType, ViewKeyDefinitionType } from '../../../../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

type ObjectShadowStateCardProps = {
  matchedPrimary: ThingShadowState,
  viewKeyDefinition: ViewKeyDefinitionType,
}


const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "100%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
  // paperTab: {
  //   // padding:  spacing(2),
  //   textAlign: 'center',
  //   // color: palette.text.secondary,
  // }, 
  bottomCard: {
    // display: 'flex',
    // flex: 1,
    marginTop: '10px',
    alignItems: 'flex-end'
  },
  // boxTab: {
  //   margin: 1,
  //   width: '100%',
  //   height: '100%',
  // }
  removePadding: {
    padding: "0px"
  },
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));



export default function TankEditDetailsCard(props: ObjectShadowStateCardProps | any) {
  const classes = useStyles();
  const [tankObject, setTankObject] = React.useState(props.viewObject.matchedPrimary)
  const [tankTotalVolume, setTankTotalVolume] = React.useState(0)

  const actions: UserTransactionType[] = [UserTransactionType.UPDATE, UserTransactionType.CREATE_AND_LINK, UserTransactionType.DELETE]

  const { matchedPrimary, searchParams, transactionFunctions, viewKeyDefinition, parentObjects, viewObject } = props

  debug && console.log('TankEditDetailsCard props', props)
  // debug && console.log('TankEditDetailsCard viewKeyDefinition', viewKeyDefinition)
  const latestPayload = viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.find((item: ValidBusinessObject) => item.type === 'ThingShadowState'); //assuming right order from db query
  const tss = props?.viewObject?.matchedPrimary as ThingShadowState;
  debug && console.log('TankEditDetailsCard tss:', tss)

  React.useEffect(() => {
    setTankObject(tss)
    setTankTotalVolume(getCylinderVolume(tss.diameter, tss.height))
  }, [props]);

  const getCylinderVolume = (diameter: number, height: number): number => {
    const calculatedTotalVolume = Math.PI * Math.pow((diameter / 2), 2) * height * 1000
    return calculatedTotalVolume
  }

  const thingShadowStates: ThingShadowState[] = viewObject?.matchedRelatedBySk ? viewObject?.matchedRelatedBySk.filter((item: ValidBusinessObject) => item.type === 'ThingShadowState').map((item: ValidBusinessObject) => new ThingShadowState(item)) : [];

  debug && console.log(' Details Card has saveObject', transactionFunctions?.saveObject)
  const userCan = (action: UserTransactionType) => actions.includes(action);

  const saveThisObject = () => {
    const action = searchParams?.action || UserTransactionType.UPDATE;
    switch (action) {
      case UserTransactionType.CREATE_AND_LINK: return searchParams?.contextObjectSK && transactionFunctions.saveAndLinkObject(searchParams.contextObjectSK, tankObject);
      default: {
        props.closeTankEdit()
        return transactionFunctions.saveObject(tankObject);
      }
    }

  }

  const updateKV = (k: string, v: any) => {
    const updateTankObject = { ...tankObject, [k]: v };
    if (k == 'diameter' || k == 'height') {
      setTankTotalVolume(getCylinderVolume(updateTankObject.diameter, updateTankObject.height))
    }
    setTankObject(updateTankObject)
  }

  return (
    <div>
      <Typography variant="subtitle1" align="center">Edit Tank Details</Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Tank Name:"
              onChange={(e) => updateKV('name', e.target.value)}
              defaultValue={viewObject?.matchedPrimary?.name}
            // value={viewObject?.matchedPrimary?.name} 
            /></FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="House:"
              // defaultValue="  "
              value={matchedPrimary?.name}
            /></FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Tank Manufacturer:</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              // value={age}
              // onChange={handleChange}
              autoWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>

          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Tank Model:</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              // value={age}
              // onChange={handleChange}
              autoWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>

          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Tank Material:</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              // value={age}
              // onChange={handleChange}
              autoWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>

          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Tank Height:"
              // label="Tank Height (mm):"
              onChange={(e) => updateKV('height',  e.target.value)}
              defaultValue={Math.round(tss?.height)}
            // value={Math.round(tss?.height)}
            // InputProps={{
            //   readOnly: true,
            // }}
            /></FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Tank Diameter:"
              // label="Tank Diameter (mm):"
              onChange={(e) => updateKV('diameter', e.target.value)}
              defaultValue={Math.round(tss?.diameter)}
            // InputProps={{
            //   readOnly: true,
            // }}
            /></FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Tank Total Volume (lts):"
              // value={tankTotalVolume}
              // defaultValue={tankTotalVolume}
              value={Math.round(tankTotalVolume)}
              // value={matchedPrimary?.name}
              InputProps={{
                readOnly: true,
              }}
            /></FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Tank Use:</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              // value={age}
              // onChange={handleChange}
              autoWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Number of tank users:"
              defaultValue="  "
              value={matchedPrimary?.name}
              InputProps={{
                readOnly: true,
              }}
            /></FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ minWidth: '100%' }}>
            <TextField
              id="standard-read-only-input"
              label="Linked Device Id:"
              defaultValue="  "
              // value={props?.matchedRelatedBySk?[0].payload.reported.meta.deviceid}
              value={latestPayload?.payload?.state?.reported.meta.deviceId}
              InputProps={{
                readOnly: true,
              }}
            /></FormControl>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // startIcon={<DeleteIcon />}

            fullWidth={true}
            onClick={() => {
              props.closeTankEdit()
            }}
          >
            Cancel
      </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth={true}
            onClick={() => saveThisObject()}
          >
            Save
      </Button>
        </Grid>
      </Grid>

    </div>
  );
}