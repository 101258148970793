import { Assembler, BusinessObjectAssembler, Customer, CustomerProps, DatastoreObjectType, Device, DeviceProps, DeviceProvisioningRecordType, getZombieInstanceFromPrimaryKey, roundDecimals, ValidBusinessObject, ValidBusinessObjectList, ValidModelType } from '@iotv/datamodel';
import { AdjacentType, AppValueTypes } from '@iotv/iotv-v3-types';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid/';
import styles from '../../../../cosmetics/sharedViewStyles';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';
import { getNiceDate, getPseudoVBO } from '../../../../data/TypeHelpers';
import { ComponentMode, CustomerDeviceViewProps, DeleteDeviceRequest, DevicePoolType, GetDeviceRequest, ListerlizerProps, MessageOutputType, SearchParamsType, TabilizerProps, Unit, UpdateDeviceRequest, UserTransactionType, ViewDefinition } from '../../../../types/AppTypes';
import { getQueryParamsFromHistory } from '../../../../util/Routing/queryParams';
import { getViewDefinitionFromUserAttributes } from '../../../factories/AttributeDefinitions';
import { Listerlizer } from '../../../factories/Listilizer';
import { Tabilizer } from '../../../factories/Tabilizer';
import { NavSections } from '../../../navigation/NavSections';
import { NavBreadcrumbs } from '../../../utils/Breadcrumbs';
import { getCustomerHierachyQuery, getCustomerQuery } from '../customerQueries';
import { getDeviceQuery } from './deviceQueries';

const debug = process.env.REACT_APP_DEBUG && false;

const apiRef = 'SystemManagement';
const componentClassName = 'CustomerDeviceView'
const parentHasRendered = () => (document.querySelector(`.${componentClassName}`))
const assembler = new BusinessObjectAssembler();

const deviceTemplate = { type: 'Device' }


function CustomerDeviceView(props: CustomerDeviceViewProps): JSX.Element {
  //debug && console.log('User Management View got', props)

  const contextUser = props.user;
  const searchParams = getQueryParamsFromHistory<SearchParamsType>(props.history)
  const [parentCustomers, setParentCustomers] = React.useState<ValidBusinessObjectList>([]);
  const [deviceInCreation, setDeviceInCreation] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined)
  const [contextCustomer, setContextCustomer] = React.useState<Partial<ValidBusinessObject> | undefined>(undefined);
  const contextCustomerZombie = searchParams?.contextObjectSK ? getZombieInstanceFromPrimaryKey(searchParams.contextObjectSK) : undefined;
  const [originalVersion, setOriginalVersion] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [selectedDevice, setSelectedDevice] = React.useState<ValidBusinessObject | undefined>(undefined)
  const [editMode, setEditMode] = React.useState(false)
  const [hasValidationErrors, setHasValidationErrors] = React.useState(true);

  const { userFunctions, history, transactionFunctions, match: { params: { action } } } = props;
  const routerHistory = useHistory();


  const requestedDeviceSk: string | undefined = props.match.params.id
  const contextCustomerSk: string | undefined = searchParams?.contextObjectSK;

  const displayMessage = (content: string, type: MessageOutputType) => {
    userFunctions.setUserMessage({ id: uuidv4(), content, label: content, type })
  }

  const cancelRedirectUrl = queryString.stringifyUrl({
    url: `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomerSk}`,
    query: {
      tabName: 'Devices'
    }
  })

  useEffect(() => {
    debug && console.log('used effect to init context customer', { contextCustomerSk, action })
    if (contextCustomerSk) {

      getCustomerQuery( ({ err, data }) => {
        if ( data ) {
          setContextCustomer(data);
          
        } else {
          displayMessage( err.message, 'MessageBar' )
        }

      }, props.user, contextCustomerSk);
    }
  }, [contextCustomerSk]);

  useEffect(() => {
    debug && console.log('used effect to init Device')
    if (action === UserTransactionType.CREATE) {
      createNewDeviceProcess();
      getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomerZombie);
    } else {
      getDeviceQueryWrapper()
      //  getCustomerHierachyQuery(setParentCustomers, props.user, contextCustomer);
    }
  }, [action, props.user, requestedDeviceSk, contextCustomer?.sk]);

  useEffect(() => {
    if (contextCustomer) {
      debug && console.log('used effect to get parent hierarchy')
      const updateFn = (customerList: ValidBusinessObjectList) => {
        // const thisContextCustomer = customerList.find( (item) => item.sk === contextCustomer.sk);
        // if (thisContextCustomer) Object.assign(contextCustomer, thisContextCustomer);
        setParentCustomers(customerList)
      }
      if (contextCustomer.sk) {
        getCustomerHierachyQuery(updateFn, props.user, contextCustomer as DatastoreObjectType & ValidBusinessObject);
      }
    }
  }, [contextCustomer?.sk]);

  // useEffect(() => {

  // }, [editMode])

  const getDeviceQueryWrapper = () => {
    if (props.user?.type === 'User' && contextCustomer?.type === 'Customer' && requestedDeviceSk) {
      const customerClassInstance = new Customer(contextCustomer as CustomerProps)
      debug && console.log(`${customerClassInstance.name} can have inventory? ${customerClassInstance.canHaveDeviceInventory()}`)
      const requestedDevice = getZombieInstanceFromPrimaryKey(requestedDeviceSk) as ValidBusinessObject & { type: 'Device' };
      const contextRequest: GetDeviceRequest = {
        contextUser: props.user as ValidBusinessObject & { type: 'User' },
        contextCustomer: contextCustomer as ValidBusinessObject & { type: 'Customer' },
        device: requestedDevice,
        devicePoolType: customerClassInstance.canHaveDeviceInventory() ? DevicePoolType.INVENTORY : DevicePoolType.ALLOCATION
      }
      debug && console.log('getDeviceQueryWrapper query params', contextRequest)
      getDeviceQuery((device) => { setSelectedDevice(device); setOriginalVersion(device) }, contextRequest)
    } else {
      debug && console.log('did not run list customer query', { selectedDevice, contextCustomer, requestedDeviceSk })
    }
  }

  const updateToDatabase = async (device: ValidBusinessObject | undefined) => {

    if (device && contextCustomer && props.user?.type === 'User') {

      const updateRequest: UpdateDeviceRequest = { contextUser, contextCustomer, device: selectedDevice, devicePoolType: DevicePoolType.INVENTORY } as UpdateDeviceRequest
      const response = await ApiGateway.post('/system/Device/updateDevice', updateRequest, apiRef);
      debug && console.log('Update response', { updateRequest, response })
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else if (requestedDeviceSk) {
        getDeviceQueryWrapper();

        displayMessage(`Updated ${device?.name} in datastore`, 'SnackBar')
      }
    }
  }

  const removeFromDatabase = async (device: ValidBusinessObject | undefined) => {
    if (device && contextCustomer) {
      const response = await ApiGateway.post('/system/Device/deleteDevice', { contextUser, contextCustomer, device: selectedDevice, devicePoolType: DevicePoolType.INVENTORY } as DeleteDeviceRequest, apiRef);
      debug && console.log('Add response', response)
      if (response.err) {
        displayMessage(response.err.message, 'MessageBar')
      } else if (requestedDeviceSk) {
        routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomer.sk}?tabName=${'Devices'}`);
        displayMessage(`Removed ${device?.name} from datastore`, 'SnackBar')
      }
    }
  }


  const addToDeviceInventory = async () => {
    if (deviceInCreation && contextCustomer) {
      const customerClassInstance = new Customer(contextCustomer as CustomerProps)
      if (customerClassInstance.canHaveDeviceInventory()) {
        const response = await ApiGateway.post('/system/Device/addToInventory', { contextUser: props.user, device: deviceInCreation, contextCustomer }, apiRef);
        debug && console.log('Add response', response)
        if (response.err) {
          displayMessage(response.err.message, 'MessageBar')
        } else {
          routerHistory.push(`/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/Customer/${contextCustomer.sk}?tabName=${'Devices'}`);
          displayMessage(`Added ${deviceInCreation.name} to database`, 'SnackBar')
          setDeviceInCreation(undefined);
        }
      } else {
        displayMessage(`${customerClassInstance.name || 'Customer'} is not allowed device inventory`, 'MessageBar')
      }

    } else {
      displayMessage('No inventory customer or new device', 'MessageBar')
    }
  }

  const isEdited = () => {
    const edited = selectedDevice && originalVersion && !Object.entries(selectedDevice).filter(([k, v1]) => typeof v1 !== 'object').every((([k, v1]) => v1 === originalVersion[k]));
    // debug && console.log('Is Edited', { edited, selectedCustomer, originalVersion})
    // note still needs to equate "" to undefined really
    return edited;
  }

  const wasEdited = isEdited()
  const createNewDeviceProcess = () => {
    setSelectedDevice(undefined);
    setDeviceInCreation( Assembler.getInstance( { ...deviceTemplate } ) );
  }

  const proxyParent = contextCustomer ?? assembler.getInstanceFromPrimaryKey('Customer:1')
  const itemToUrlFn = (item: ValidBusinessObject) => `/${NavSections.CUSTOMER_MANAGEMENT_VIEW}/${UserTransactionType.UPDATE}/${item.type}/${item.sk}?tabName=${searchParams?.tabName}`
  const deviceClassInstance = selectedDevice || deviceInCreation ? new Device(selectedDevice as Partial<DeviceProps> || deviceInCreation as Partial<DeviceProps>) : undefined
  type DeviceRequestKeys = keyof DeviceProps
  const deviceTableViewDefinition: ViewDefinition & { [key in DeviceRequestKeys]?: any } = deviceClassInstance ? {
    ...getViewDefinitionFromUserAttributes(deviceClassInstance.getUserAttributes()),
    lastSendPeriod: { key: 'lastSendPeriod', label: 'Messge Period Actual', type: AppValueTypes.NUMBER, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined, valueGetter: ( v: number) =>  typeof v === 'number' ? `${ roundDecimals( v/60000, 0 )} min` : 'Not set' },

    name: { key: 'name', label: 'Name', type: AppValueTypes.STRING, editable: true, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
 
  } : {}


  const deviceTableProps: TabilizerProps = {
    ...props,
    matchedPrimary: (selectedDevice ?? deviceInCreation ?? proxyParent) as ValidBusinessObject,
    actions: [action as UserTransactionType],
    viewDefinition: deviceTableViewDefinition,
    
    functionOverrides: {
      updateParentComponentItem: (item: ValidBusinessObject) => {
        if (selectedDevice) setSelectedDevice(item);
        else if (deviceInCreation) setDeviceInCreation(item)
      },
      mode:  (deviceInCreation || editMode) ? ComponentMode.EDIT : ComponentMode.VIEW,
      hasValidationErrors: () => {

        if (parentHasRendered()) {
          const hasErrors = (document.querySelector('.Mui-error') !== null);
          setHasValidationErrors(hasErrors)
        } else {
          debug && console.log('parent not rendered')
        }
      }
    },


    injectedComponents: action === UserTransactionType.CREATE
      ? [
        <ButtonGroup key='addDeviceBG'>
          <Button
            size="small"
            onClick={() => addToDeviceInventory()}
            disabled={hasValidationErrors}
            startIcon={<GroupAddIcon />}
            variant="outlined"
            color="primary"
          >
            Add to Inventory
            </Button>
          <Button
            size="small"
            onClick={() => createNewDeviceProcess()}
            disabled={deviceInCreation && (Object.values(deviceInCreation).find((val) => val !== 'Device' && val !== undefined)) === undefined}
            startIcon={<ClearIcon />}
            variant="outlined"
            color="primary"
          >
            Clear
            </Button>
          <Button onClick={() => { setDeviceInCreation(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
            size="small"
            startIcon={<CancelIcon />}
            variant="outlined"

          >
          </Button>
        </ButtonGroup>
      ]
      : [
        <ButtonGroup key='newUserBG' disabled={editMode}>

        </ButtonGroup>,
        <ButtonGroup key='editUserBG'>
          <Button
            size="small"
            onClick={() => removeFromDatabase(selectedDevice)}
            disabled={!selectedDevice}
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="primary"
          >
            Delete
            </Button>
          <Button
            size="small"
            onClick={() => { debug && console.log( 'BEfore click edit mode was', editMode); setEditMode( !editMode ) }}
            disabled= {!selectedDevice || editMode}
            startIcon={<EditIcon />}
            variant="outlined"
            color="primary"
          >
            Edit
            </Button>

          {selectedDevice && <Button
            size="small"
            onClick={() => { updateToDatabase(selectedDevice) }}
            disabled={!editMode || wasEdited !== true}
            startIcon={<SaveIcon />}
            variant="outlined"
            color="primary"
          >
            Save
            </Button>}
          <Button
            size="small"
            onClick={() => { setSelectedDevice(undefined); setEditMode(false); routerHistory.push(cancelRedirectUrl) }}
            disabled={!editMode && deviceInCreation && (Object.values(deviceInCreation).find((val) => val !== 'User' && val !== undefined))}
            startIcon={<CancelIcon />}
            variant="outlined"
          >
          </Button>
        </ButtonGroup>
      ]
  }

  debug && console.log('Customer Device View state ', { selectedDevice, deviceInCreation, contextCustomer, requestedUserSk: requestedDeviceSk })

  const pseudoTypeId = 'ProvisioningRecord';
  const deviceProvisioningRecordListProps: ListerlizerProps = {
    ...props,
    autoQuery: false,
    adjacentFilter: {
      adjacentType: AdjacentType.CHILD,
      objectType: pseudoTypeId as ValidModelType,
    },
    viewDefinition: {
      provisionDate: { key: 'provisionDate', label: 'Date', type: AppValueTypes.STRING, editable: false, unit: Unit.Date, precision: undefined, stateFn: undefined, validationRx: undefined },
      inventoryCustomer: { key: 'inventoryCustomer', label: 'Assignor Customer', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined },
      allocationCustomer: { key: 'allocationCustomer', label: 'Assignee Customer', type: AppValueTypes.STRING, editable: false, unit: undefined, precision: undefined, stateFn: undefined, validationRx: undefined }

    },
    viewObject: {
      matchedPrimary: selectedDevice, matchedRelatedByPk: [], matchedRelatedBySk: selectedDevice?.provisioning instanceof Array
        ? selectedDevice.provisioning.map((item: DeviceProvisioningRecordType) =>
          getPseudoVBO({
            type: pseudoTypeId, name: `PR_${item.provisionDate}`, id: `PR_${item.provisionDate}`, ...item,
            inventoryCustomer: (item.inventoryCustomer as ValidBusinessObject)?.name,
            allocationCustomer: (item.allocationCustomer as ValidBusinessObject)?.name,
            provisioningTime: item.provisionDate,
            provisionDate: item.provisionDate ? getNiceDate(item.provisionDate) : undefined
          })) : undefined
    },
    maxItems: 50,
    sortKeyIn: 'provisionDate',
    contextObject: undefined,
    selectorConfigParams: {
      controls: { add: false, find: false },
      enabled: false,
      multipleSelect: false
    },
    optionals: {

    },
    injectedComponents: []
  }

  return (<>
    {((action === UserTransactionType.UPDATE && selectedDevice) || action === UserTransactionType.CREATE) && <>
      { contextCustomer && <NavBreadcrumbs {...{ items: parentCustomers ?? [contextCustomer], itemToUrlFn, current: selectedDevice  }}></NavBreadcrumbs>}

      <Grid className={componentClassName} container spacing={3} >
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h4" gutterBottom>
                {selectedDevice?.eui ?? 'Device'}
              </Typography>
              {(selectedDevice || deviceInCreation) && <Tabilizer key={'userInputTable'} {...{ ...deviceTableProps }}></Tabilizer>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h4" gutterBottom>
                {selectedDevice?.provisioning && 'Provisioning History'}
              </Typography>
              <Listerlizer {...{ ...deviceProvisioningRecordListProps }}></Listerlizer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>}</>
  );

}



export default withStyles(styles)(CustomerDeviceView)