import { ErrData, GeoPoint, HistoryStore, IGeoJsonPoint, Position } from '@iotv/datamodel';
import { FeatureCollection } from '@turf/helpers';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { Feature, GeoJsonProperties, Point } from 'geojson';
import config from '../../../../config';
import AppDao from '../../../../data/AppDao';
import { bounsdCellLatLngsToCoveringCells, googleBoundsToCellLatLngs } from './conversion';

const debug = process.env.REACT_APP_DEBUG && false;
const dyn = config.aws.dynamoDB;

export const setGeoJsonPropertiesFromPointAtts = ( point: GeoPoint,  geoJson: Feature<Point, GeoJsonProperties>) => {
    const statistics = Object.fromEntries( Object.entries( point.gatewayStatistics ).map( ([ gwId, gatewaysStatitistics ]) => {
        let output: any = {}
        const historyStore = new HistoryStore(gatewaysStatitistics)
        const gwStats = historyStore.getStatistics();
        const gwRssiStats = gwStats.rssi;
        if ( gwRssiStats ) {
           output =  [ gwId, { count: gwRssiStats.count, sdSample: gwRssiStats.sdPop, mean: gwRssiStats.mean}]
        } else {
            output = [ gwId, {}]
        }
        return output
    }) )

    const newProps = {
        dataCount: point.dataCount,
        statistics,
    };
    geoJson.properties = geoJson.properties ? { ...geoJson.properties, ...newProps } : newProps;
    return geoJson;
}

const getQueryParams = (token: string): DocumentClient.QueryInput => {
    const query: DocumentClient.QueryInput = {
        TableName: dyn.tableName,
        IndexName: dyn.dynamicKeyGSIName,
        ExclusiveStartKey: undefined,
        ExpressionAttributeNames: {
            '#type': dyn.dpKey,
            '#sk': dyn.dsKey,
            '#dc': 'dataCount'
        },
        ExpressionAttributeValues: {
            ':type': 'Point',
            ':sk': `${token}`,
            ':dc': 0
        },
        KeyConditionExpression: '#type = :type and begins_with(#sk, :sk)',
        FilterExpression: '#dc > :dc'



    }
    debug && console.log('S2 Points Query', query)
    return query
}


export const getS2PointsByBounds = async ( map: google.maps.Map<Element>,  setS2Points: (s2Points: GeoPoint[]) => void ) => {
    const bounds = map.getBounds() ?? new google.maps.LatLngBounds()
    const boundsLatLng: { ne: Position, sw: Position } | undefined = googleBoundsToCellLatLngs( bounds ) 

    const boundsCoveringCells = boundsLatLng ? bounsdCellLatLngsToCoveringCells({  boundsLatLng, maxCells: 4, maxLevel: 10}) : []

    /* show refernence cells on map*/
    //boundsCoveringCells.forEach( ( cellId ) => map.data.addGeoJson(new S2Cell(cellId).toGEOJSON()))

    const cellTokens = boundsCoveringCells.map( ( cellId ) => cellId.toToken()).sort().map( (token) => token.substr(0, token.length - 1)).reduce( (acc, cur ) => {
        if ( !acc.includes(cur )) {
            acc.push(cur)
        }
        return acc
    }, [] as string[]) ;

    const cellTokenHash = cellTokens.join('');

    debug && console.log('Bounds cell tokens', cellTokenHash);



    const setterFn = async ( response: ErrData<DocumentClient.QueryOutput> ) => {
        debug && console.log('S2 Response', response);
        if ( response.data?.Items) {
            const points: GeoPoint[] = response.data.Items.map( (item) => new GeoPoint(item as IGeoJsonPoint)) ;
            setS2Points(points)
            const geoJson = points.map( (point) => { 

                return point.geoJson ? setGeoJsonPropertiesFromPointAtts(point as GeoPoint, point.geoJson) : point;
            }).filter( ( geoJson ) => geoJson)  as Feature<Point, GeoJsonProperties>[]
            debug && console.log('geoJson to add', geoJson);
            debug && console.log('map', map)
            if ( map.data) {
               // map.data.addGeoJson(geoJson , {                })
               const featureCollection: FeatureCollection = {
                features: geoJson,
                type: 'FeatureCollection'
               }
               debug && console.log('add s2Points to map.data', featureCollection)
              //TODO this doesn't appear to be async but doesn't render without. plus errors if exist are captured with async
               const addRes = await map.data.addGeoJson(featureCollection);
               debug && console.log('added Feature to map.data', addRes)
            //    applyPolygoStyle(map);

            } else {
                debug && console.log('No map.data')
            }
            debug && console.log('add geoJson to map data ', geoJson);
        } else {
            debug && console.log('No Items')
        }
        debug && console.log('exit setterFn')
    };
    
    cellTokens.forEach( async (token: string) => {
        const query = getQueryParams(token)
        await AppDao.getMainTableQueryResults(setterFn, query);
       
    } )

    

   

}