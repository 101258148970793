import { deviceEnabledTypes, GeofencePointsType, getAllEdgeDefinitionTypes, IsLocatableByPosition, isValidModelTypeId, MapLike, ObjectHistory, PathDirection, ThingShadowState, UserAttributeDef, UserAttributeDefinitionType, ValidBusinessObject, ValidModelType } from '@iotv/datamodel';
import { ModelClassNames, ModelCommon, UserAttributeDefs } from '@iotv/datamodel-core';
import { ObjectHistoryFilterRequest, setAContainsSomeB, StateSnapshot } from '@iotv/iotv-v3-types';
import { Badge, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Switch, Tab, Tabs } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import styles from '../../cosmetics/sharedCardStyles';
import Assembler from '../../data/Assembler';
import { getImageUrl } from '../../data/aws/s3/UserBlobs';
import { AppLocalStorage } from '../../data/LocalStorage/AppLocalStorage';
import { extractPolylineFromHistoryStore, getLocatableAddress, isDeviceEnabledType, thingShadowStateToStateSnapshot } from '../../data/TypeHelpers';
import { useObjectHistory } from '../../hooks/useObjectHistory';
import { useTabHandler } from '../../hooks/useTabHandler';
import { AdjacentFilter, AdjacentType, KeyMappedComponentFunctions, KeyMappedComponents, MapCardPropsOptionals, ObjectCardProps, PossibleBusinessObject, UserTransactionType, ValidCustomerObject, ViewDefinition, ViewObject } from '../../types/AppTypes';
import { extractGeoDataFromViewObject } from '../../util/geo/GeoDataHelpers';
import ObservationCard from '../cards/Observation/ObservationCard';
import PhotosCard from '../cards/PhotosCard';
import { IncidentListCard } from '../common/IncidentListCard';
import { SetObjectHistoryFilterWidget } from '../common/SetObjectHistoryFilterWidget';
import { SlaveTab } from '../factories/SlaveTabV2';
import MapCard from '../geo/MapCard';
import NavLink from '../navigation/NavLink';
import { NavSections } from '../navigation/NavSections';
import { SimpleObjectLink } from '../navigation/simpleObjectLink';
import ObjectListCard from './ObjecListCard';
import ObjectDetailCard from './ObjectDetailCard';
import ObjectGraphCard from './ObjectGraphCard';
import ObjectHistoryCard from './ObjectHistoryCard';
import ObjectHistoryTableCard from './ObjectHistoryTableCard';
import ObjectShadowStateCard from './ObjectShadowStateCard';
import { ObjectDataPeriodizationCard } from './ObjectDataPeriodizationCard'
import { ObjectDataAccumulationCard } from './ObjectDataAccumulationCard';

const debug = process.env.REACT_APP_DEBUG && false;

const useStyles = makeStyles((theme) => styles(theme));

export default function ObjectCard(props: ObjectCardProps) {
  // debug && console.log('Object card got props', JSON.stringify(props.viewObject, null, 1))
  debug && console.log('Object card got userSelectedGroup', props.userSelectedGroup)
  const classes = useStyles();
  const [expanded, _setExpanded] = React.useState(false);

  const { viewObject, userGroupRoles, userSelectedGroup, contextCustomer, user, transactionFunctions, userFunctions, searchParams, history }: ObjectCardProps = props;
  const contextObjectMap: MapLike<ValidBusinessObject | undefined> = {
    nothing: undefined,
    user: user,
    customer: contextCustomer
  }

  const defaultObjectHistoryFilter: ObjectHistoryFilterRequest = { 
    recentStateHistory: {
      lastN: 1000, sampleRate: 1
    }
  }

  const [contextObject, setContextObject] = React.useState<ValidBusinessObject | undefined>(contextObjectMap[AppLocalStorage.get(NavSections.THINGVIEW, 'contextObjectKey')] ?? contextCustomer)

 
  debug && console.log(`${viewObject.matchedPrimary?.type} is deviceEnabledType ${viewObject.matchedPrimary ? isDeviceEnabledType(viewObject.matchedPrimary) : false}`, deviceEnabledTypes)

  useEffect(() => {
    if (viewObject.matchedPrimary?.type === 'Tank' && viewObject.largeObject === undefined) {
      transactionFunctions.getLargeObject(viewObject.matchedPrimary)
    }
  }, []);

  useEffect(() => {
    console.log('Used effect on userSelectedGroup', userSelectedGroup)
  }, [userSelectedGroup])

  const [objectHistory, setObjectHistory,  objectHistoryFilter, setObjectHistoryFilter ] = useObjectHistory(viewObject.matchedPrimary, defaultObjectHistoryFilter) 
  // const [[incidentHistory, setIncidentHistory], [ExclusiveStartKey, setExclusiveStartKey]] = useIncidentHistory(viewObject.matchedPrimary)

  useEffect(() => {
    setObjectHistory(undefined)
    async function effectUrl() {
      const objectOrString = await getImageUrl(props.viewObject.matchedPrimary)
      if (typeof objectOrString === 'string') {
        const url = objectOrString;
        debug && console.log('Use Effect has url', url)
        setImageUrl(url)
      }

    }
    props.viewObject.matchedPrimary && effectUrl()
  }, [props.viewObject.matchedPrimary?.sk]);

  const [useEdgeMatrix, setUseEdgeMatrix] = React.useState<boolean>(userGroupRoles?.groups.includes('HyperUser') ? true : false)
  useEffect(() => { }, [useEdgeMatrix, contextObject?.sk])

  const { mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst }:
    { mapViewObject: ViewObject | undefined, lastestThingStateShadow: ThingShadowState | undefined, track: GeofencePointsType[], position: GeofencePointsType | undefined, thingStatesMostRecentFirst: ThingShadowState[] }
    = isDeviceEnabledType(viewObject.matchedPrimary) ? extractGeoDataFromViewObject(viewObject) : { mapViewObject: undefined, lastestThingStateShadow: undefined, track: [], position: undefined, thingStatesMostRecentFirst: [] }

  debug && console.log('ObjectCard geo', mapViewObject, lastestThingStateShadow, track, position, thingStatesMostRecentFirst)

  useEffect(() => {
    debug && console.log(`Object card use effect on lastestThingStateShadow at ${new Date()}`, lastestThingStateShadow)
    if (lastestThingStateShadow && objectHistory?.recentStateHistory) {
      const filterKeys = objectHistory.recentStateHistory.keys();
      const snapshot: StateSnapshot<any> = thingShadowStateToStateSnapshot(lastestThingStateShadow, filterKeys)
      const objectHistoryClone = new ObjectHistory(objectHistory)
      objectHistoryClone.recentStateHistory?.push(snapshot)
      setObjectHistory(objectHistoryClone)
    }
  }, [lastestThingStateShadow?.sk])

  const thingShadowStates = viewObject.matchedRelatedBySk ? viewObject.matchedRelatedBySk.filter((item) => item.type === 'ThingShadowState') : []
  debug && console.log('thingShadowStates', thingShadowStates)

  const [imageUrl, setImageUrl] = React.useState('')

  const validRelatedTypes = ['Array', 'RelatedType'] as UserAttributeDefinitionType[];
  const isValidRelatedType = (attKey: string, attDef: UserAttributeDef) => /* Assembler.getClass(attDef?.objectTypeId ?? attKey)  && */ attDef && validRelatedTypes.includes(attDef.type);

  const getListsForObject = (possibleBusinessObject: PossibleBusinessObject) => {
    const object = Assembler.getInstance(possibleBusinessObject) as ModelCommon | undefined;
    let elements: JSX.Element[] = [];
    if (object) {
      const edgeDefinitions = useEdgeMatrix ? getAllEdgeDefinitionTypes(possibleBusinessObject.type as ModelClassNames) : {}
      const classDerivedDefinitions = object.getUserAttributes()
      const userAttributes: UserAttributeDefs = edgeDefinitions; 
      Object.entries(classDerivedDefinitions).forEach( ( [k, classDerivedDefinition ] ) => {
        if ( classDerivedDefinition ) {
          if ( !classDerivedDefinition.edgeTypeId || !(Object.values( userAttributes ).find( (userAttribute ) => userAttribute?.edgeTypeId === classDerivedDefinition.edgeTypeId )) ) {
            userAttributes[k] =  classDerivedDefinition
          } 
        }
        
      })
      // this is to prevent double up duplicated edgeTypeIds eg the implementation of conditional on Customer
      debug && console.log('combined userAttributes for lists', userAttributes )
      elements = Object.entries(userAttributes).map((([k, attDef]) => getListForObjectAttribute(object, k, attDef as UserAttributeDef))).filter((element) => !!element) as JSX.Element[];
    } else {
      console.log(`WARN: ${possibleBusinessObject.sk} is not a vob`, possibleBusinessObject)
    }
    return elements
  }

  const getListForObjectAttribute = (contectObject: PossibleBusinessObject, attKey: string, attDef: UserAttributeDef) => {
    let element = null;
    //debug && console.log(`about to creatre Lists for ${contectObject.type}:${attKey}:${attDef?.edgeTypeId ?? 'no edgeId'}:${attDef?.direction ?? 'no direction'}` )
    if (attKey && isValidRelatedType(attKey, attDef)) {
      const adjacentType: AdjacentType = attDef?.direction === PathDirection.parent ? AdjacentType.PARENT : AdjacentType.CHILD;
      let objectType: ValidModelType | undefined;
      let otherObjectType: string | undefined;
      const derivedTypeId = attDef?.objectTypeId ?? attKey;

      if (!isValidModelTypeId(derivedTypeId)) {
        console.log(`WARN: ${derivedTypeId} is not a valid model typeId`)
      }

      switch (adjacentType) {
        case AdjacentType.PARENT:
          objectType = derivedTypeId as ValidModelType
          otherObjectType = contectObject.type
          break;

        default: objectType = derivedTypeId as ValidModelType
          break;
      }

      const adjacentFilter: AdjacentFilter = {
        adjacentType,
        edgeFilter: undefined,
        objectType,
        otherObjectType,
      }

      if (attDef?.edgeTypeId) {
        adjacentFilter.edgeFilter = {
          edgeTypeId: attDef.edgeTypeId
        }
      }

      debug && console.log(`created adjacemtFilter for ${contectObject.type}:${attKey}:${attDef?.edgeTypeId ?? 'no edgeId'}:${attDef?.direction ?? 'no direction'}`, adjacentFilter)

      const listConfigParams = {
        label: typeof attDef?.label === 'string' ? attDef.label : `${attKey}s`
      }

      element = <Grid item xs={6} key={`${attKey}`}>
        <ObjectListCard {...{ viewObject, userGroupRoles, contextObject, transactionFunctions, userFunctions, searchParams, history, adjacentFilter, listConfigParams }} ></ObjectListCard>
      </Grid>

    } else {
      //debug && console.log(`invalid related type`, { contectObject, attKey, attDef, objectKeys: Object.keys(contectObject.getUserAttributes()) })
    }

    return element;
  }

  const locatableAddress = getLocatableAddress(viewObject.matchedPrimary);
  const hasAlert = () => true;
  const badgeCount = () => 0;
  const getPosition = (polylines: GeofencePointsType[]): GeofencePointsType | undefined => {
    let derivedPosition: GeofencePointsType | undefined = undefined;
    if (viewObject.matchedPrimary && IsLocatableByPosition(viewObject.matchedPrimary)) {
      derivedPosition = viewObject.matchedPrimary.getLatLon() ?? viewObject.matchedPrimary?.coordinates;
    } else {
      debug && console.log('not locatable by position', viewObject.matchedPrimary)
      derivedPosition = viewObject.matchedPrimary?.coordinates ?? position
    }

    if (polyline.length > 0) {
      const last = polyline.length - 1;
      const sample = polyline[last];
      derivedPosition = { lat: sample.lat, lng: sample.lng }
    }

    debug && console.log('derived position as', derivedPosition)
    return derivedPosition;
  };

  const actions: UserTransactionType[] = [
    UserTransactionType.CREATE
  ]

  const viewDefinition: ViewDefinition = {
  }

  const mapCardPropsOptionals: MapCardPropsOptionals = {
    zoom: 16,
    styles: {
      mapCardObjectCard: {
        height: '40vw',
        width: '40vw',
      }
    }
  }

  const polyline: GeofencePointsType[] = objectHistory?.recentStateHistory ? extractPolylineFromHistoryStore(objectHistory.recentStateHistory, viewObject.matchedPrimary?.sk ?? 'unknonw') : []

  const polylines = { [viewObject.matchedPrimary?.sk ?? 'Unkownn']: polyline }

  const GP = Grid

  const tabsToDisplay: KeyMappedComponentFunctions = {
    Details: () => (viewObject.matchedPrimary) ? <GP container { ...{ key: 'DetailsDiv'}}>
      <Grid item xs={12} lg={6} { ...{ key: 'DetailsGrid'}}>
        <ObjectDetailCard {...{ matchedPrimary: viewObject.matchedPrimary, transactionFunctions, searchParams, history }}></ObjectDetailCard>
      </Grid>
      {thingStatesMostRecentFirst.length > 0 && isDeviceEnabledType(viewObject.matchedPrimary) && <Grid item xs={12} lg={6} { ...{ key: 'ShadowGrid'} }>
        <ObjectShadowStateCard {...{ className: classes.subCard, matchedPrimary: thingStatesMostRecentFirst[0], parentObjects: [viewObject.matchedRelatedByPk], title: 'Latest Metrics' }} ></ObjectShadowStateCard>
      </Grid>}
    </GP> : null,

    Links: () => (viewObject.matchedPrimary) ? <GP container { ...{ key: 'LinksDiv'}}>{getListsForObject(viewObject.matchedPrimary)} </GP> : null,

    History: () => (viewObject.matchedPrimary && objectHistory && contextCustomer) ? <GP container { ...{ key: 'HistoryDiv'}}>
      <Grid item xs={12} { ...{ key: 'HistoryGrid'} }>
      {  <SetObjectHistoryFilterWidget { ...{ historyStoreKeyKeys: objectHistory.keys(), objectHistoryFilter, setObjectHistoryFilter }}></SetObjectHistoryFilterWidget>} 

        <ObjectHistoryCard {...{
          classes, matchedPrimary: viewObject.matchedPrimary, objectHistory, objectHistoryFilter, setObjectHistoryFilter, objectHistoryConfig: {
            dailyStateHistory: true, recentStateHistory: true
          }
        }} ></ObjectHistoryCard>
      </Grid>
      <Grid item xs={12} { ...{ key: 'HistoryTableGrid'} }>
        <ObjectHistoryTableCard {...{
          contextCustomer: contextCustomer as ValidCustomerObject,
          viewObject, contextObject: undefined, objectHistory
          , userFunctions, transactionFunctions, searchParams, history
          , actions, viewDefinition, filterMetricKeys: undefined
          , title: `${viewObject.matchedPrimary.name}'s history`
          , userSelectedGroup
        }} ></ObjectHistoryTableCard>
      </Grid> </GP> : null,
    
    Observations: () => (viewObject.matchedPrimary && isDeviceEnabledType(viewObject.matchedPrimary) && objectHistory && contextCustomer) ? <GP container { ...{ key: 'ObservationsDiv'}}>
    <Grid item xs={12} { ...{ key: 'ObservationsGrid'} }>
      <ObservationCard {...{ key: 'ObservationsCard',
        contextCustomer: contextCustomer as ValidCustomerObject,
        viewObject, contextObject: undefined, objectHistory
        , userFunctions, transactionFunctions, searchParams, history
        , actions, viewDefinition, filterMetricKeys: undefined
        , title: `${viewObject.matchedPrimary.name}'s observations`
        , userSelectedGroup
      }} ></ObservationCard>
    </Grid> </GP> : null,

    Charts: () => (objectHistory?.dailyStateHistory || viewObject.matchedPrimary?.dailyStateHistory) ? <GP container { ...{ key: 'ChartsDiv'}}>
      <Grid item xs={12} lg={6} { ...{ key: 'ChartsGrid'} }>
        <ObjectGraphCard {...{ historyObject: objectHistory?.dailyStateHistory ?? viewObject.matchedPrimary?.dailyStateHistory, classes, title: 'Daily State History' }} ></ObjectGraphCard>
      </Grid>
      <Grid item xs={12} lg={6} { ...{ key: 'GraphGrid'} }>
        <ObjectGraphCard {...{ historyObject: objectHistory?.recentStateHistory ?? viewObject.matchedPrimary?.recentStateHistory, classes, title: 'Recent State History' }} ></ObjectGraphCard>
      </Grid>
      </GP> : null,

    Geo: () =>  <GP container><Grid item xs={12} lg={6} { ...{ key: 'GeoGrid'} }>
      <MapCard {...{ position: getPosition(polyline), editable: false, locatableAddress, viewObjects: [viewObject], polylines, optionals: mapCardPropsOptionals }} ></MapCard>
    </Grid></GP>,

    Incidents: () => (isDeviceEnabledType(viewObject.matchedPrimary) || setAContainsSomeB( [ viewObject.matchedPrimary?.type ?? 'ignore' ], ['Device', 'Gateway'])) ? <GP container><Grid item xs={12} lg={6} { ...{ key: 'IncidentsGrid'} }>
      <IncidentListCard {...{ transactionFunctions, userGroupRoles, userFunctions, viewObject, contextCustomer, searchParams, history }}></IncidentListCard>
    </Grid></GP> : null,

    Media: () => (viewObject.matchedPrimary && isDeviceEnabledType(viewObject.matchedPrimary)) ? <GP><Grid item xs={12}  { ...{ key: 'PhotosGrid'} }>
      <PhotosCard {...{ matchedPrimary: viewObject.matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions, viewDefinition, title: `${viewObject.matchedPrimary.name}'s photos` }} ></PhotosCard>
    </Grid></GP> : null,
        Normals: () => (viewObject.matchedPrimary && isDeviceEnabledType(viewObject.matchedPrimary)) ? <GP><Grid item xs={12}  { ...{ key: 'NormalsAndPeriodization'} }>
        <ObjectDataAccumulationCard  {...{ contextCustomer, user, userGroupRoles, userSelectedGroup, viewObject,  matchedPrimary: viewObject.matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions, viewDefinition, title: `${viewObject.matchedPrimary.name}'s Normals etc` }} ></ObjectDataAccumulationCard>
      </Grid></GP> : null,
              Periodization: () => (viewObject.matchedPrimary && isDeviceEnabledType(viewObject.matchedPrimary)) ? <GP><Grid item xs={12}  { ...{ key: 'NormalsAndPeriodization'} }>
              <ObjectDataPeriodizationCard  {...{ contextCustomer, user, userGroupRoles, userSelectedGroup, viewObject,  matchedPrimary: viewObject.matchedPrimary, userFunctions, transactionFunctions, searchParams, history, actions, viewDefinition, title: `${viewObject.matchedPrimary.name}'s Normals etc` }} ></ObjectDataPeriodizationCard>
            </Grid></GP> : null
  }

  const { state: [[tabValue, setTabValue]], handlers: { handleTabChange } } = useTabHandler( tabsToDisplay)

  return (
    <Card className={classes.root}>
      <CardHeader { ...{
        avatar: <NavLink filter={`${NavSections.THINGVIEW}/${viewObject.matchedPrimary?.type}/${viewObject.matchedPrimary?.id}`} children={
            <Badge badgeContent={badgeCount()} color="primary" invisible={!(hasAlert())}>
              <Avatar aria-label="recipe" className={classes.avatar} src={imageUrl} >
                {viewObject.matchedPrimary ? viewObject.matchedPrimary.name ? (viewObject.matchedPrimary.name as string).substring(0, 1).toUpperCase() : `${viewObject.matchedPrimary.type} ?` : '??'}
              </Avatar>
            </Badge>
          }></NavLink>,
          titleTypographyProps: {
            variant: 'h2'
          },
          title: viewObject.matchedPrimary?.name,
          subheade: viewObject.matchedPrimary?.breed
      } }
      />
      <CardContent>
        {userGroupRoles?.groups.includes('HyperUser') && <FormControlLabel {...{
          control: <Switch {...{
            checked: useEdgeMatrix, onChange: () => { setUseEdgeMatrix(!useEdgeMatrix) }
          }}></Switch>,
          label: useEdgeMatrix ? 'Using edge matrix' : 'Using class defined edges'
        }} />}
        {setAContainsSomeB(userGroupRoles?.groups ?? [], ['HyperUser', 'SystemManagement']) && <FormGroup>

          <FormControl component="fieldset">
            <FormLabel component="legend">Search context</FormLabel>
            <RadioGroup {...{
              row: true,
              name: "contextRadioGroup",
              value: Object.entries(contextObjectMap).find(([k, v]) => v === contextObject)?.[0] as string,
              onChange: (e) => {
                const contextMapKey = e.target.value as 'nothing' | 'user' | 'customer'
                contextMapKey && AppLocalStorage.set(NavSections.THINGVIEW, 'contextObjectKey', contextMapKey)
                setContextObject(contextObjectMap[contextMapKey])

              }
            }}
              aria-label="gender"

            >
              {userSelectedGroup === 'HyperUser' && <FormControlLabel value="nothing" control={<Radio />} label="Nothing" />}
              <FormControlLabel {...{ value: "user", control: <Radio />, label: user?.name }} />
              <FormControlLabel {...{ value: "customer", control: <Radio />, label: contextCustomer?.name }} />
            </RadioGroup>
          </FormControl>
        </FormGroup>
        }
        <Tabs {...{ value: tabValue, onChange: handleTabChange, variant: 'scrollable' }} >
          {Object.entries(tabsToDisplay).filter(([tabName, element], i) => element !== null ).map(([tabName, element], i) => <Tab  {...{ key: `tab_${i}`, label: tabName, disabled: false }} />)}
        </Tabs>

        {Object.values(tabsToDisplay).filter( (c) => c !== null ).map((component, index) => <SlaveTab {...{ key: `tab${index}`, orderedComponents: [ component ], index, value: tabValue }}></SlaveTab>)}


        <Typography variant="body2" color="textSecondary" component="p">

          {viewObject.matchedPrimary?.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Card className={classes.subCard} key={`${viewObject}_parents`} >{viewObject.matchedRelatedByPk && viewObject.matchedRelatedByPk.map((object, i) => <SimpleObjectLink key={`${viewObject}_parents_${i}`} {...{ object, adjacentType: AdjacentType.PARENT }}></SimpleObjectLink>)} </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.subCard} key={`${viewObject}_children`} >{viewObject.matchedRelatedBySk && viewObject.matchedRelatedBySk.map((object, i) => <SimpleObjectLink key={`${viewObject}_children_${i}`} {...{ object, adjacentType: AdjacentType.CHILD }}></SimpleObjectLink>)} </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}