import { String } from 'aws-sdk/clients/apigateway';
import ApiGateway from '../../../../data/aws/api-gateway/ApiGateway';

const debug = process.env.REACT_APP_DEBUG && false;
const apiRef = 'SystemManagement';
export const listAllGroupsQuery = async (fn: (params: any) => void) => {
    try {
        const response = await ApiGateway.post('/system/Group/list', {}, apiRef);
        debug && console.log('CG query results', response)
        if (response.data) {
            fn(response.data)
        }
    } catch (e) {
        debug && console.log('Error in CG query', e)
    }
}

export const listGroupsForUser = async (fn: (params: any) => void, Username: String) => {
    try {
        const response = await ApiGateway.post('/system/User/listGroups', { Username }, apiRef);
        debug && console.log('CG listGroupsForUser results', response)
        if (response.data) {
            fn(response.data)
        }
    } catch (e) {
        debug && console.log('Error in CG listGroupsForUser', e)
    }
}

export const addUserToGroup = async (fn: Function, username: String, GroupName: string) => {
    try {
        const response = await ApiGateway.post('/system/User/addToGroup', { username, GroupName }, apiRef);
        debug && console.log('CG addToGroup results', response)
        if (response.data) {
            fn(response.data)
        } else {
            debug && console.log('CG addUserToGroup res err', response)
        }
    } catch (e) {
        debug && console.log('Error in CG listGroupsForUser', e)
    }
}

export const removeUserFromGroup = async (fn: Function, username: String, GroupName: string) => {

    try {
        const response = await ApiGateway.post('/system/User/removeFromGroup', { username, GroupName }, apiRef);
        debug && console.log('CG removeFromGroup results', response)
        if (response.data) {
            fn(response.data)
        } else {
            debug && console.log('CG removeFromGroup res err', response)
        }
    } catch (e) {
        debug && console.log('Error in CG removeFromGroup', e)
    }
}