import { AdjacentType, ValidBusinessObject, ValidBusinessObjectList } from '@iotv/datamodel-core';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AppDao from '../../../../../../../data/AppDao';
import RecipientModal from '../../../../Recipient/RecipientModal';
import { preBuiltStageMessage, StageMessage } from '../../DMPExtrasV2';
import { getCustomerRecipiants, recipientsLabel } from '../RecipientsSupport';
import styles from './DroughtModal.module.css';

const modalRoot = document.querySelector('#modal-root');

interface stageModalProps {
  close: () => void;
  contextCustomer: ValidBusinessObject;
  stage: number;
  update: (input: StageMessage | undefined, position: number | undefined) => void;
  index: number | undefined;
  existing: StageMessage[];
}

export const formatToDescription = (message: string[], stage: number): string => {
  const staticDescription = `ISLAND-WIDE DROUGHT MANAGEMENT ALERT: From 11:59 pm tonight, the whole of the island moves to Drought Stage ${stage}.\n\nPlease follow the guidelines and use water wisely.\n\nRemember:\n`;
  let rememberDescription = message.join('\n\t·');

  return `${staticDescription}\t·${rememberDescription}`;
};

export const renderIdList = (idList: string[], choice: ValidBusinessObjectList): string => {
  let objList: string[] = idList.map((id) => {
    return choice.find((option) => option.id === id)?.name;
  });
  return objList.filter((elm) => elm).join(', ');
};

const StageModal: React.FC<stageModalProps> = (props: stageModalProps) => {
  let { close, contextCustomer, stage, update, index, existing } = props;

  const cutBack = 'Cut back on daily water use';

  let [recipients, setRecipients] = useState<ValidBusinessObjectList>();
  let [recipientModal, setRecipientModal] = useState<boolean>(false);
  let [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  let [dailyWater, setDailyWater] = useState<number>(0);
  let [preBuiltSelected, setPreBuiltSelected] = useState<string[]>([]);
  let [droughtStageAlert, setDroughtStageAlert] = useState<string>('');

  let [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (index !== undefined && existing.length > index && recipients !== undefined) {
      let source = existing[index];
      setSelectedRecipients(source.recipientIDs);
      let messageChoice = source.messages;

      // find string with cutback
      let cutbackPos = messageChoice.findIndex((messages) => messages.includes(cutBack));
      if (cutbackPos !== null && cutbackPos !== -1) {
        if (messageChoice[cutbackPos] !== cutBack) {
          // get number
          let numInStringArr = messageChoice[cutbackPos].match(/\d+/);
          numInStringArr?.length === 1 && setDailyWater(parseInt(numInStringArr[0]));
        }
        messageChoice[cutbackPos] = cutBack;
      }

      setPreBuiltSelected(messageChoice);
    }
  }, [index, existing, recipients]);

  useEffect(() => {
    if (contextCustomer) {
      updateRecipients();
    }
  }, [contextCustomer]);

  useEffect(() => {
    setValid(selectedRecipients.length > 0 && preBuiltSelected.length !== 0);
  }, [selectedRecipients, preBuiltSelected]);

  useEffect(() => {
    setDroughtStageAlert(formatToDescription(constructMessageArray(), stage));
  }, [preBuiltSelected, dailyWater]);

  const updateRecipients = async () => {
    setRecipients(await getCustomerRecipiants(contextCustomer));
  };

  const constructMessageArray = (): string[] => {
    let localPreBuild = [...preBuiltSelected];
    let index = localPreBuild.indexOf(cutBack);
    if (localPreBuild.indexOf(cutBack) > -1 && dailyWater > 0) {
      localPreBuild[index] = localPreBuild[index] + ` by ${dailyWater} %`;
    }
    return localPreBuild;
  };

  const updateRecipiantList = async (customer: ValidBusinessObject) => {
    const res = await AppDao.queryByGraph({
      contextObjects: [customer],
      path: [{ objectTypeId: 'Recipient', adjacencyType: AdjacentType.CHILD }],
    });
    if (res.err === null && res.data !== null) {
      setRecipients((res.data.getChildNodes(customer) as ValidBusinessObjectList) || []);
    }
  };

  const updateList = async () => {
    updateRecipiantList(contextCustomer);
    cancelAddRecipient();
    updateRecipients();
  };

  const buttonSelected = (option: string) => {
    if (preBuiltSelected.includes(option)) {
      setPreBuiltSelected([...preBuiltSelected].filter((pre) => pre !== option));
    } else {
      setPreBuiltSelected([...preBuiltSelected, option]);
    }
  };

  const create = (recipientIDs: string[], messages: string[], pos: number | undefined) => {
    update({ recipientIDs, messages }, pos);
  };

  const deleteItem = (pos: number) => {
    update(undefined, pos);
    close();
  };

  const addRecipient = useCallback(() => {
    setRecipientModal(true);
  }, []);

  const cancelAddRecipient = useCallback(() => {
    setRecipientModal(false);
  }, []);

  if (modalRoot === null) {
    console.log('Root for modal is not working.');
    // showScreen(false);
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className={styles.modalContainer}>
        <div className={styles.form}>
          <Card>
            <Box m={2}>
              <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
                <Typography variant='h5' component='h5'>
                  Create Stage Transition Message
                </Typography>

                <div className={styles.full}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id='recipientLabel'>{recipientsLabel(recipients)}</InputLabel>

                      <Select
                        multiple
                        fullWidth
                        labelId={recipients === undefined ? 'Loading' : recipients.length === 0 ? 'No Recipients' : 'recipientLabel'}
                        // disabled={!(recipients.length > 0)}
                        disabled={recipients === undefined || recipients.length === 0}
                        input={<OutlinedInput label={recipientsLabel(recipients)} />}
                        onChange={(e) => {
                          let choice = e.target.value;
                          if (typeof choice === 'string') {
                            setSelectedRecipients([choice]);
                          } else {
                            setSelectedRecipients(choice);
                          }
                        }}
                        value={selectedRecipients || []}
                        size='small'
                        renderValue={() => <>{recipients && renderIdList(selectedRecipients, recipients)}</>}
                      >
                        {recipients?.map((contact) => (
                          <MenuItem key={contact.id} value={contact.id}>
                            <Checkbox checked={selectedRecipients.includes(contact.id)} />
                            <ListItemText primary={contact.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button fullWidth variant='contained' onClick={addRecipient}>
                      <Add /> Recipients
                    </Button>
                  </Stack>
                </div>
                <div className={styles.full}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <Button
                      style={{ justifyContent: 'flex-start' }}
                      onClick={() => buttonSelected(cutBack)}
                      variant={preBuiltSelected.includes(cutBack) ? 'contained' : 'outlined'}
                    >
                      {cutBack}
                    </Button>
                    <TextField
                      value={dailyWater !== 0 ? dailyWater : ''}
                      variant='outlined'
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>by</InputAdornment>,
                        endAdornment: <InputAdornment position='start'>%</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        inputMode: 'numeric',
                        shrink: true,
                      }}
                      style={{ width: '180px' }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (value > 100) {
                          value = 100;
                        } else if (value < 0) {
                          value = 0;
                        }
                        setDailyWater(value);
                      }}
                    />
                  </Stack>
                </div>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  {preBuiltStageMessage.map((pre) => {
                    return (
                      <Button
                        style={{ justifyContent: 'flex-start' }}
                        onClick={() => buttonSelected(pre)}
                        variant={preBuiltSelected.includes(pre) ? 'contained' : 'outlined'}
                      >
                        {pre}
                      </Button>
                    );
                  })}
                </Stack>
                <TextField
                  label={`Drought Stage Alert`}
                  value={droughtStageAlert}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  minRows={9}
                  multiline
                  fullWidth
                  style={{ width: '100%' }}
                  onChange={(e) => setDroughtStageAlert(e.target.value)}
                />
              </Stack>
            </Box>
            <Box m={2}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Button
                  variant='contained'
                  color='error'
                  disabled={index === undefined}
                  onClick={() => {
                    index !== undefined && deleteItem(index);
                  }}
                >
                  Delete
                </Button>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                  <Button variant='contained' onClick={close}>
                    Cancel
                  </Button>
                  <Button variant='contained' disabled={!valid} onClick={() => create(selectedRecipients, constructMessageArray(), index)}>
                    {index === undefined ? 'Create' : 'Update'}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Card>
        </div>
      </div>
      {recipientModal && <RecipientModal close={cancelAddRecipient} parent={contextCustomer} update={updateList} />}
    </>,
    modalRoot
  );
};

export default StageModal;
