import { deviceEnabledTypes, GeofencePointsType, GeofenceType, ValidBusinessObject } from '@iotv/datamodel';
import { Button, ButtonGroup, makeStyles, Slider, SliderTypeMap, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { mapStyles } from '../../cosmetics/MapCardStyles';
import styles from '../../cosmetics/sharedViewStyles';
import { constructAllItems } from '../../data/daoFunctions/stateFns';
import { getNiceDate } from '../../data/TypeHelpers';
import { MapCardProps, MapLikeT, NormalizedData, ViewObject } from '../../types/AppTypes';
import { mapiIsPresent } from '../../util/Scripts/isPresent';
import GoogleMap from './GoogleMap';

const debug = process.env.REACT_APP_DEBUG && false;



const useStyles = makeStyles((theme) => {
  return {
      ...mapStyles(theme),
  }
});


function MapView( props:  MapCardProps & { classes: any, normalData?: NormalizedData, googleMapScriptLoaded?: boolean }  ): JSX.Element {
    const localClasses = useStyles();
    const { classes, geoImplementingObject, normalData = {}, viewObjects, polygons, locatableAddress, googleMapScriptLoaded } = props;
    const polylinesMap = props.polylines
    const polylineKeys = polylinesMap && Object.keys(polylinesMap)
    const polylineMapFirstKey = polylineKeys && polylineKeys.length > 0 && polylineKeys[0];
    const polylinePoints = polylineMapFirstKey && polylinesMap ? polylinesMap[polylineMapFirstKey] : [];
    const maxIdx = polylinePoints.length -1 
    const initialIdxRange = [Math.max(0, maxIdx- Math.floor(polylinePoints.length / 10)), maxIdx]
    const [slice, setSlice] = React.useState(initialIdxRange as number | number[]);
    const firstViewObject = viewObjects instanceof Array ? viewObjects.length > 0 ? viewObjects[0] : undefined : undefined;


    debug && console.log('Polylines', {
       length: polylinePoints.length,  polylinesMap, polylineKeys, polylineMapFirstKey, polylinePoints, maxIdx, initialIdxRange
      }  
    )
  
    useEffect(() => {
      if (slice instanceof Array ) {
        if ( !(slice[0] === initialIdxRange[0] && slice[1] === initialIdxRange[1])) {
          const sliceLength = slice[1] - slice[0] + 1;  
          const diff = 1 ;
          debug && console.log('Need to update slice ends', { polyLength: polylinePoints.length, incoming: polylinePoints, slice, sliceLength, diff})
          setSlice(initialIdxRange)
        }
      }
    }, [  polylinePoints.length ])

    useEffect(() => {

    }, [ viewObjects?.length, firstViewObject?.matchedPrimary?.sk, googleMapScriptLoaded ])



    const [ editedGeofences, setEditedGeofences ] = React.useState<GeofenceType[]>([])
    debug && slice instanceof Array && console.log(`length: ${ polylinePoints.length} start: ${slice[0]} end: ${slice[1]} sliceLength: ${slice[1] - slice[0]} `)

    debug && console.log('Map view got', props)

    const geofenceViewObjects = constructAllItems(normalData, ['Geofence']);
    debug && console.log('Map view geofences', geofenceViewObjects)

    const allPolygons:  MapLikeT<GeofencePointsType[]> = polygons ?? {};
    geofenceViewObjects.forEach( (viewObject) => {
      const points = (viewObject.matchedPrimary as ValidBusinessObject & {points?: GeofencePointsType[]} ).points;
      if (viewObject.matchedPrimary?.sk && points) {
        allPolygons[viewObject.matchedPrimary.sk] = points
      }
    }) ;
    
    const extraViewObjects = constructAllItems(normalData, Object.keys(deviceEnabledTypes))


    let allViewObjects: ViewObject[] = [];
    if (viewObjects) allViewObjects = allViewObjects.concat(viewObjects);
    if (extraViewObjects) allViewObjects = allViewObjects.concat(extraViewObjects);

    let parentCardFns = undefined;
    if ( props.editable && geoImplementingObject && editedGeofences ) {
      parentCardFns = {
        setEditedGeofences: (geofences: GeofenceType[]) => {
          const providedGeofences = props.geofences
          const geofenceIds = providedGeofences ? Object.keys(providedGeofences) : []
           debug && console.log('got setEditedGeofences geofence', {geofenceIds, geofences} )
          setEditedGeofences( geofences )
        }
      }
    }
    
    const handleSaveClick = () => {
        if ( geoImplementingObject ) {

          debug && console.log('Geofence save handle click', {geoImplementingObject, editedGeofences})
          editedGeofences.forEach( (geofence) => {
            const mutatedGeofence = {...geoImplementingObject, points: geofence.points }
            debug && console.log('mustated Geofence', mutatedGeofence);
            props.transactionFunctions?.saveObject( mutatedGeofence );
          })
        }
        
    }

    const handleClearGeofence = () => {
      if ( geoImplementingObject ) {
        props.transactionFunctions?.saveObject( {...geoImplementingObject, points: [] })
      }
  }


  debug && console.log('MapView Editable', props.editable, geoImplementingObject)


    const filteredPolylines: MapLikeT<GeofencePointsType[]> = {}; 
    const [startIdx, endIdx] = slice as [number, number]
    polylinesMap  && Object.entries( polylinesMap ).forEach( ([ key, polyline ]) => {
      filteredPolylines[key] = polyline.filter( (point, i) => i >= startIdx  &&  i <= endIdx )
    })
   
    debug && console.log('filtered Polylines', polylineMapFirstKey && filteredPolylines[polylineMapFirstKey].length)

    const maxLabels = 10
    const radix = Math.max(4, Math.round(polylinePoints.length / maxLabels ))
    const marks = polylinesMap && polylineMapFirstKey ? polylinesMap[polylineMapFirstKey].map( ( geofencePoint, i)=> {
      return {
        value: i,
        label: geofencePoint.timestamp ? getNiceDate(geofencePoint.timestamp, { weekday: 'short'}) : i.toString()
      }
    }).filter( (_geofencePoint, i) => i % radix === 0) : []

    /*
    const valuetext = (idx: number, idx2: any) => {
        
      const label = polylinesMap && polylineMapFirstKey && polylinesMap[polylineMapFirstKey][idx].timestamp
      //debug && console.log('value text has', {idx, idx2, label})
      return label;
    }
    */
  
    const sliderProps = {
      max: maxIdx,
      min: 0,
      value: slice,
      onChange: (_event: any, newValue: number | number[]) =>  setSlice(newValue),
     
      'aria-labelledby': 'range-slider',
      marks: marks,
      valueLabelDisplay: "off" as "auto" | "on" | "off" | undefined
    
      
  }

    return (

            <div  className = { localClasses.mapViewObjectCard}>
                    { mapiIsPresent() && <GoogleMap { ...{...props, polylines: filteredPolylines, polygons: allPolygons, viewObjects: allViewObjects, locatableAddress, parentCardFns }} ></GoogleMap> }
                    {props.editable && geoImplementingObject && <ButtonGroup>
                        <Button onClick = { () => handleSaveClick()} disabled = { editedGeofences.length === 0}>
                          save
                        </Button>
                        <Button onClick = { () => handleClearGeofence()} disabled = { editedGeofences.length === 0}>
                          clear
                        </Button>
                      </ButtonGroup>
                    }
                    { props.polylines && 
                      <Slider className = {classes.LineChartSlider} {...{...sliderProps}}></Slider>
                    } 
            </div>

    );
    
}

// export default withStyles(styles)(LoginTab);

export default withStyles(styles)(MapView)
