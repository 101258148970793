import { Dispatch } from 'redux';  
import { ACTIONS } from './actionTypes';

const debug = process.env.REACT_APP_DEBUG && false;


export function getUserGeolocation()  {
  
  return async ( dispatch: Dispatch )  => {
      dispatch({ type: ACTIONS.GET_USER_GEOLOCATION })
      const locationRes = await new Promise( (resolve, reject) => {
         debug && console.log('getting position')
        navigator.geolocation.getCurrentPosition( 
        position => { console.log('data in getCurrentPosition', position); resolve(position.coords) },
        err => { console.log('error in getCurrentPosition', err); reject(err) }
        )
      });
      dispatch(
        {
          type: ACTIONS.RECEIVE_USER_GEOLOCATION,
          position: locationRes
        }
      ) 
}
  
 
} 

export const position = { lat: 1, lng: 2}

