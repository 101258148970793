import { BusinessObjectAssembler, ValidBusinessObject } from "@iotv/datamodel";
import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import { UserAndRoles, UserGroupsRolesType } from "../types/AppTypes";

const debug = process.env.REACT_APP_DEBUG && false;

const assembler = new BusinessObjectAssembler();

export const getUserGroupsFromSession = (session: CognitoUserSession | null) => {
    let groups: string[] = [];
    let roles: string[] = [];
    let preferredRole: string | undefined = undefined
    const accessToken = session?.getAccessToken();
    if ( accessToken?.payload['cognito:groups'] ) {
      groups = accessToken.payload['cognito:groups']
    };
    if ( accessToken?.payload['cognito:roles'] ) {
      roles = accessToken.payload['cognito:roles']
    };
    if ( accessToken?.payload['cognito:preferred_role'] ) {
      preferredRole = accessToken.payload['cognito:preferred_role']
    } else {
      // preferredRole =  accessToken?.payload['cognito:groups'] instanceof Array ? accessToken?.payload['cognito:groups'][0] : undefined
      // debug && console.log(`getUserGroupsFromSession has no 'cognito:preferred_role (as expected) so using last group ${preferredRole}`, accessToken?.payload)
    };
    return { groups, roles, preferredRole }
  }

export const getUserGroups = (cognitoUser?: CognitoUser) => {
    let userGroupRoles: UserGroupsRolesType = {
      groups: [],
      roles: [],
      preferredRole: undefined
    }
    if ( cognitoUser  && cognitoUser.getSignInUserSession ) {
      const session = cognitoUser.getSignInUserSession()
      Object.assign( userGroupRoles,  getUserGroupsFromSession(session) )
    }
    return userGroupRoles;
  }


  export  const getAppUserFromSession = (userSession: CognitoUserSession) => {
    const accessToken = userSession?.getAccessToken();
    const userId = accessToken?.payload.sub;
    let user = undefined;
    if (userId) { 
      user = assembler.getInstanceFromPrimaryKey(`User:${userId}`) as ValidBusinessObject 
    } else {
      console.log(`No user from accessToken?.payload `,accessToken?.payload )
    }
    return user
  }

 export  const getUserSessionDetails = ({ cognitoUser, userGroupRoles }: UserAndRoles) => {
    debug && console.log('init App', cognitoUser);
    const userSession = cognitoUser.getSignInUserSession();
    let user = userSession ? getAppUserFromSession(userSession) : undefined;
    return {user, userGroupRoles};
   }