import { ThingShadowState, WeatherFormat } from '@iotv/datamodel';
// import Avatar from '@material-ui/core/Avatar/Avatar';
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { getValueLabel } from '../data/TypeHelpers';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import styles from '../../../cosmetics/sharedCardStyles';
import { getDaysForRain } from '../../../util/common/Common';

const debug = process.env.REACT_APP_DEBUG && false;

type ObjectShadowStateCardProps = {
  matchedPrimary: ThingShadowState;
}
const localStyle = {
  media: {
    // width: '45%',
    // height:'45%',
    paddingTop: "50%",
    marginRight: "auto",
    marginLeft: "auto"
    // textAlign :'center'
  },
};
const useStyles = makeStyles((theme) => ({ ...styles(theme), ...localStyle }));



export default function TankRainCard(props: ObjectShadowStateCardProps | any) {
  const classes = useStyles();
  const [nextRainTopMessage, setNextRainTopMessage] = React.useState('');
  const [nextRainBottomMessage, setNextRainBottomMessage] = React.useState('Data not Available');
  const [rainIcon, setRainIcon] = React.useState('http://openweathermap.org/img/wn/09d.png')
  const [thing, setThing] = React.useState<WeatherFormat[]>([])
  // const [rainIcon, setRainIcon] = React.useState('/RainCloud.png')

  const thingSS = props.thingSS
  // const latestPayload = props.latestPayload
  const latestPayload = thingSS[0]?.payload.state.calculated?thingSS[0]: thingSS[1]
  debug && console.log('TankRainCard props latestPayload', latestPayload?.payload.state.calculated?.weather_data)


 debug && console.log('rain card ',props)
  // const tss = latestPayload as ThingShadowState;

  React.useEffect(() => {
    // let currentUnixTime = Math.floor((+ new Date()) / 1000)
    const parentObjects = props.parentObjects[0]

    if (parentObjects[0]){
      let weatherForecast = parentObjects[0].weather_data
      if (weatherForecast?.daily) {
        setThing(weatherForecast.daily)
        let nextRainDayResult = getDaysForRain(weatherForecast.daily)
        debug && console.log('nextRainDayResult:', nextRainDayResult)
        if (nextRainDayResult) {
          setNextRainTopMessage(nextRainDayResult.topMessage)
          setNextRainBottomMessage(nextRainDayResult.bottomMessage)
          setRainIcon(nextRainDayResult?.rainIcon)
        } else {
          setNextRainTopMessage('')
          setNextRainBottomMessage('More than 7 days until forecast rain')
        }
      }
    }
  }, []);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <img src={rainIcon} />
        </Grid>
        <Grid item xs={9}>
          <Grid container direction="column" >
            <Grid item xs={12}>
              <Box
                display="flex"
                width='100%' height='100%'
                alignItems="center"
                justifyContent="center"
                textAlign='center'
              >
                <Typography variant="h6" component="h2">
                  {nextRainTopMessage}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                width='100%' height='50%'
                alignItems="center"
                justifyContent="center"
                textAlign='center'
              >
                {nextRainBottomMessage}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}