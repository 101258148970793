import { deviceEnabledTypes } from '@iotv/datamodel';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { MapView } from '../components/geo/v2';
import { filterByTypeIds } from '../data/daoFunctions/stateFns';
import { RootState } from '../reducers';
import { UserGroupsRolesType } from '../types/AppTypes';

const debug = process.env.REACT_APP_DEBUG && false;

const mapStateToProps = (state: RootState, ownProps: any) => {
    debug && console.log('MapViewContainer own props', ownProps)
    const deviceEnabledTypeKeys = Object.keys(deviceEnabledTypes);
    const typeFilter = ['Geofence', ...deviceEnabledTypeKeys];
  return {
    isAuthenticated: state.AccessReducer.isAuthenticated,
    cognitoUser: state.AccessReducer.cognitoUser,
    userGroupRoles: state.AppReducer.userGroupRoles,
    userSelectedGroup: state.AppReducer.userSelectedGroup,
    user: state.AppReducer?.user,
    normalData: filterByTypeIds(state.AppReducer.normalData, typeFilter), typeFilter,
 
  
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any) => {
  return {
    init: (cognitoUser: CognitoUser, userGroupRoles: UserGroupsRolesType) => {
	     debug && console.log('Map View requested init', ownProps)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapView)
