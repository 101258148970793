import { ACTIONS } from './actionTypes'
import AppDao from '../data/AppDao'
import { Dispatch } from 'redux';  
import { v4 as uuidv4 } from 'uuid/'
import { BusinessObjectAssembler, ValidModelType } from '@iotv/datamodel';
import {  ErrData, MapLike, ValidBusinessObjectList,  } from '@iotv/iotv-v3-types';
import { ValidBusinessObject } from '@iotv/datamodel';
import { TransactionResponse, TransactionFunctionsType, IUserMessageType, ObjectAPIError, DeleteObjectRespose, UnlinkResponse, UserChangeableState, UserGroupsRolesType, RoleArbitrationInstanceType, AdjacentFilter, AdjacentType } from '../types/AppTypes';
import { PathDirection } from '@iotv/datamodel';
import { getRoleArbitrationInstanceAndCurrentGroup, getRoleArbitrationInstanceType } from '../roleArbitration';
import { getAdjacent2Wrapper } from '../data/daoFunctions/daoFunctions';


const debug = process.env.REACT_APP_DEBUG && false;
const queryDebug = process.env.REACT_APP_DEBUG && false;

const assembler = new BusinessObjectAssembler();

const initializeData = async (user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, requestedRoleArbitrationInstance: RoleArbitrationInstanceType | undefined, dispatch: Dispatch) => {
  let roleArbitrationInstance: RoleArbitrationInstanceType | undefined = undefined;
  let currentGroup: string | undefined = undefined
  if ( !(requestedRoleArbitrationInstance) ) {
     const derivedRoleAndGroup = getRoleArbitrationInstanceAndCurrentGroup(userGroupRoles);
     roleArbitrationInstance = derivedRoleAndGroup.roleArbitrationInstance;
     currentGroup = derivedRoleAndGroup.currentGroup
  } else {
    roleArbitrationInstance = requestedRoleArbitrationInstance 
    currentGroup = requestedRoleArbitrationInstance.groupName
  }


 
  return AppDao.updateStateFromApiGateway(user, userGroupRoles, roleArbitrationInstance, currentGroup) 
  .then( (normalData) => {
    dispatch(receiveInit(normalData))
    return { err: null, data: normalData }
  })
  .catch( (e) => {
    debug && console.log('Err in initializeData', e);
    return { err: e, data: null}
  });
}

const changeUserRole = (user: ValidBusinessObject | undefined, userGroupRoles: UserGroupsRolesType, userSelectedGroup: string) =>  {
  const newRoleArbitrationInstance = getRoleArbitrationInstanceType( userGroupRoles, userSelectedGroup );

  return async (dispatch: Dispatch) => {
    dispatch({
      type: ACTIONS.REQUEST_CHANGE_USER_ROLE,
    });
    return initializeData(user, userGroupRoles, newRoleArbitrationInstance, dispatch)
      .then( ( res ) => {
         debug && console.log('Change Role returned void')
        const errData: TransactionResponse< ObjectAPIError, RoleArbitrationInstanceType > = { err: null, data: null};
        if (res.err ) {
           debug && console.log('Change Role Error', res)
          
        } else {
          const roleArbitrationInstance = getRoleArbitrationInstanceType(userGroupRoles, userSelectedGroup);
          if (roleArbitrationInstance) errData.data = roleArbitrationInstance;
        }

        dispatch({ type: ACTIONS.RECEIVE_CHANGE_USER_ROLE_RESULT, value: errData }) 
      })
  }
}

  function changeAppState( changeableState: UserChangeableState) {
     debug && console.log('Got State Change Request', changeableState)
    return {
      type: ACTIONS.CHANGE_APP_STATE,
      value: changeableState
    }
  }

  function addObjectsToState( validBusinessObjects: ValidBusinessObjectList) {
    debug && console.log('Got addObjectsToState Request', validBusinessObjects)
   return {
     type: ACTIONS.REQUEST_ADD_OBJECTS_TO_STATE,
     value: validBusinessObjects
   }
 }

  function setUserMessage( message: IUserMessageType ) {
    return {
      type: ACTIONS.ERROR,
      value: message
    }
  }

  function dismissUserMessage( message: IUserMessageType ) {
    return {
      type: ACTIONS.DISMISS_USER_MESSAGE,
      value: message
    }
  }

  function cancelRedirect(url: string) {
    return {
      type: ACTIONS.CANCEL_REDIRECT_AFTER_SUCCESS,
    }
  }

  const requestUploadFile = ( directoryString: string, file: File ) =>  {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.REQUEST_UPLOAD_FILE,
      });
      return AppDao.uploadToS3(directoryString, file)
        .then( ( res: ErrData<string> ) => {
           debug && console.log('Save Object returned', res)
          const errData: TransactionResponse< ObjectAPIError, string > = { err: null, data: res.data};
          if (res.err ) {
             debug && console.log('Upload Error', res)
          }
          dispatch({ type: ACTIONS.RECEIVE_FILEUPLOAD_RESULT, value: errData}) 
        })
    }
  }

  function receiveInit(normalData: any) {
    return {
      type: ACTIONS.RECEIVE_INIT,
      normalData
    }
  }

  function saveObject(object: ValidBusinessObject) {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.SAVE_OBJECT,
      });
      return AppDao.save(object)
        .then( ( res ) => {
           debug && console.log('Save Object returned', res)
          const errData: TransactionResponse< ObjectAPIError, ValidBusinessObject | undefined > = { err: null, data: res.data};
          if (res.err ) {
            errData.err = { object, errors: res.err, id: uuidv4() }
          }
          dispatch({ type: ACTIONS.RECEIVE_SAVE_TRANSACTION_RESULT, value: errData}) 
        })
    }
  }


  function saveAndLinkObject(existingObjectSK: string, newObject: ValidBusinessObject, adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike<any>) {
    const existingObject = assembler.getInstanceFromPrimaryKey(existingObjectSK);
    const edgeTypeId = adjacentFilter.edgeFilter?.edgeTypeId
    if (newObject.pk === existingObjectSK) newObject.pk = newObject.sk; //
    debug && console.log('new Object in Link', { newObject, existingObjectSK })
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.SAVE_OBJECT,
      });
      return AppDao.save(newObject)
        .then( ( saveResult ) => {
          debug && console.log('Save New Object returned', saveResult);
          const errData: TransactionResponse< ObjectAPIError, ValidBusinessObject | undefined> = { err: null, data: saveResult.data};
          if (saveResult.err ) {
            errData.err = { object: newObject, errors: saveResult.err, id: uuidv4() }
          }
          dispatch({ type: ACTIONS.RECEIVE_SAVE_TRANSACTION_RESULT, value: errData });
          if (errData.err) {
           return errData;
          } else if ( errData.data ) {
            const returnedNewObject = errData.data;
            const [ parentObject, childObject] = adjacentFilter.adjacentType === AdjacentType.PARENT ?  [ returnedNewObject, existingObject ] : [ existingObject, returnedNewObject ];

            return  AppDao.link( parentObject, childObject, edgeTypeId, edgeAttributes );
          } else {
            debug && console.log('System did not return data, does the api datamodel include this type?');
            return errData
          }
        }
        
        ).then( ( linkRes ) => {
          dispatch({ type: ACTIONS.RECEIVE_SAVE_AND_LINK_TRANSACTION_RESULT, value: linkRes });
        })
    }
  }

  const linkObjectsToPrimary = ( contextObject: ValidBusinessObject | undefined, selectedObjects: ValidBusinessObject[], adjacentFilter: AdjacentFilter, edgeAttributes?: MapLike<any>) => {
    const edgeTypeId = adjacentFilter.edgeFilter?.edgeTypeId
   
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.REQUEST_LINK_OBJECTS,
      });
      return  Promise.all( selectedObjects.map( (selectedObject) => {
        const [ parentObject, childObject] = adjacentFilter.adjacentType === AdjacentType.PARENT ?  [ selectedObject, contextObject ] : [ contextObject, selectedObject ];
        return AppDao.link( parentObject, childObject, edgeTypeId, edgeAttributes );
      }))
      .then( (repsonses) => {
        debug && console.log('Link Outcomes', repsonses);
        repsonses.forEach( (response, i) => {
          dispatch({ type: ACTIONS.RECEIVE_SAVE_AND_LINK_TRANSACTION_RESULT, value: response })
        });
    });;
    
    }
  }

  function deleteObject(object: ValidBusinessObject, history: History) {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.DELETE_OBJECT  
      });
      return AppDao.deleteObject(object)
        .then( ( res ) => {
          let errData: DeleteObjectRespose = { err: null, data: null };
           debug && console.log('Delete Object returned', res)
          const deleteRes: ErrData<ValidBusinessObject>= res;
          if ( !(deleteRes.err)) {
            errData.data = { object, history: history as History & {push: Function} };
          }
          dispatch({ type: ACTIONS.RECEIVE_DELETE_TRANSACTION_RESULT, value: errData}) 
        })
    }
  }

  function draftNewObject(matchedPrimary: ValidBusinessObject, type: string, history: History) {
    debug && console.log('we want to draft a new ', type);
    const assembler = new BusinessObjectAssembler();
    const newObject = assembler.getInstance( {type});
    const action = newObject ? {
      type: ACTIONS.REQUEST_NEW_DRAFT_OBJECT,
      matchedPrimary,
      newObject,
      history
    } : {
      type: ACTIONS.ERROR,
    }
    debug && console.log("returning", action)
    return action;
  }

  function newListObject(matchedPrimary: ValidBusinessObject, adjacentFilter: AdjacentFilter, history: History) {
    debug && console.log('we want to add a new ', adjacentFilter);
    const assembler = new BusinessObjectAssembler();
    let edgeObjectPk: string, newObjectType: ValidModelType, newObject: ValidBusinessObject | undefined = undefined, newEdgeObject: ValidBusinessObject | undefined = undefined
    try {
      switch (adjacentFilter.adjacentType) {
        case AdjacentType.PARENT: 
          newObjectType =  adjacentFilter.objectType as ValidModelType // adjacentFilter.otherObjectType ?? 'this will fail'; //adjacentFilter.otherObjectType ?? 'unknown';
          newObject = assembler.getInstance( {type: newObjectType}) as ValidBusinessObject;
          edgeObjectPk = newObject.sk
          newEdgeObject = assembler.getInstance( {...matchedPrimary, pk: edgeObjectPk } ) as ValidBusinessObject;
          break;
        default:
          newObjectType = adjacentFilter.objectType as ValidModelType;
          newObject = assembler.getInstance( {type: newObjectType}) as ValidBusinessObject;
          edgeObjectPk = matchedPrimary.sk
          newEdgeObject = assembler.getInstance( {...newObject, pk: edgeObjectPk } ) as ValidBusinessObject;
        break;
      }
    } catch ( e: any ) {
      console.log('Error', e.message);
      console.log(`WARN: newListObject failed with`, {matchedPrimary, adjacentFilter })
    }
   

    
    if (newEdgeObject && adjacentFilter.edgeFilter?.edgeTypeId ) newEdgeObject.edgeTypeId = adjacentFilter.edgeFilter.edgeTypeId 
    const action = newObject ? {
      type: ACTIONS.REQUEST_NEW_LIST_OBJECT,
      matchedPrimary,
      newObject,
      newEdgeObject,
      history
    } : {
      type: ACTIONS.ERROR,
      value: {
        id: 'listError',
        message: 'Did not create new list object'
      }
    }
    debug && console.log("returning", action)
    return action;
  }

  function searchByType(contextObject: ValidBusinessObject, includeContextLinkedItems: boolean, typeId: string, lastEvaluatedKey: string | undefined) {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.REQUEST_SEARCH_BY_TYPE 
      });
      return AppDao.searchByType(contextObject, includeContextLinkedItems, typeId, lastEvaluatedKey)
        .then(
          ({err, data}) => {
            debug && console.log('Search By Type returned', data)
            if (err) {
                debug && console.log('Error returned by search by type', err);
            } else { 
                const responseAction = {
                    type: ACTIONS.RECEIVE_SEARCH_BY_TYPE_RESULT,
                    value: {err, data}
                };
                dispatch(responseAction) 
            }
        })

    }
  }

  function unlink( parentObject: ValidBusinessObject, childObject: ValidBusinessObject, edgeTypeId?: string, edgeAttributes?: MapLike<any>) {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ACTIONS.REQUEST_UNLINK_OBJECTS
      });
      return AppDao.unlink(parentObject, childObject, edgeTypeId, edgeAttributes )
        .then(
          (res) => {
            const unlinkResponse: UnlinkResponse = res;
            
            debug && console.log('Unline  returned', unlinkResponse)
            if (unlinkResponse.err) {
                debug && console.log('Error returned by unlink', unlinkResponse.err);
            } else { 
                const responseAction = {
                    type: ACTIONS.RECEIVE_UNLINK_OBJECTS_RESULT,
                    value: unlinkResponse
                };
                dispatch(responseAction) 
            }
        })

    }
  }

  function getAdjacent(object: ValidBusinessObject, type: string, direction: PathDirection, edgeTypeId?: string) {
    return async (dispatch: Dispatch) => {
      dispatch(
        {type: ACTIONS.REQUEST_GET_ADJACENTS}
      ); 

      const adjacencyType: AdjacentType = direction === PathDirection.child ? AdjacentType.CHILD : AdjacentType.PARENT

      // orig AppDao.getAdjacent(object, type, direction, edgeTypeId)
      return getAdjacent2Wrapper({ scopeDefinitingContextObject: object, objectTypeId: type, adjacencyType, edgeTypeId, includeEdges: true}).then(
        ( {err,  data} ) =>  {
            const responseAction = {
                type: ACTIONS.RECEIVE_GET_ADJACENT_RESULT,
                value: {err, data: data?.Items ?? []}
            };

              ( queryDebug || debug ) && console.log('results from parent query with params', {object, type, direction, edgeTypeId})
              debug && console.log(responseAction)

            dispatch(responseAction) 
        }
      );
    };
  }

  function getLargeObject(object: ValidBusinessObject) {
    return async (dispatch: Dispatch) => {
      dispatch(
        {type: ACTIONS.REQUEST_GET_LARGE_OBJECT}
      ); 
      return  AppDao.getLargeObject(object).then(
        ( {err,  data}: ErrData<ValidBusinessObject> ) =>  {
            const responseAction = {
                type: ACTIONS.RECEIVE_GET_LARGE_OBJECT,
                value: {err, data}
            };
            dispatch(responseAction) 
        }
      );
    };
  }


  export const TransactionFunctions: TransactionFunctionsType = {
    saveObject,
    saveAndLinkObject,
    deleteObject,
    searchByType,
    unlink,
    linkObjectsToPrimary,
    getAdjacent,
    getLargeObject,
    requestUploadFile,
    changeUserRole

  }

export  const UserFunctions = {
    setUserMessage,
    dismissUserMessage,
    cancelRedirect,
    draftNewObject,
    newListObject,
    changeAppState,
    addObjectsToState,

   
  }

  



