import React, { useEffect } from 'react';
import { convertHistoryObjectToDataTable } from '../../data/TypeHelpers';
// import { convertHistoryObjectToDataTable, getValueLabel } from '../../../data/TypeHelpers';
import { LineChartOptionsMandatory, LineChartXType } from '../../../../types/AppTypes';
import { HistoryObjectType } from '@iotv/iotv-v3-types'
import LineChart from '../../../../dataVisualization/Google/LineChart';
import config from '../../../../config';
import { count } from 'console';
import BarChart from './BarChart';

const debug = process.env.REACT_APP_DEBUG && false;

type RangeableBarGraphProps = {
  historyObject: HistoryObjectType, keys: string[], xType: LineChartXType, classes: any, lowIdx: number, maxIdx: number
}

// export const RangeableBarGraph = (props: RangeableBarGraphProps) => {
export const RangeableBarGraph = (props: any) => {
  const { historyObject, keys, xType, classes } = props;
  const maxIdx = historyObject.index.length - 1;
  let lowIdx = props.lowIdx
  // const initialIdxRange = [0, maxIdx];

  debug && console.log("props:", props)
  debug && console.log({ lowIdx, maxIdx })
  const initialIdxRange = [props.lowIdx, props.maxIdx];

  useEffect(() => {

  }, [historyObject.index.length]);


  const getGraphTitle = () => {
    // debug &&  console.log('keuyo',keys[0])
    switch (keys[0]) {
      case "current_filled_volume":
        return "Tank Fill Volume"
      case "current_filled_percentage":
        return "Tank Fill Percentage"
      case "usage_in_day":
        return 'Daily Usage'
      case "usage_in_day_percentage":
        return 'Daily Usage Percentage'
      case "formattedCFV":
        return 'Fill Level'
      case "formattedCFVPercentage":
        return 'Fill Level'
      case "formattedGroupCFV":
        return 'Community Fill Volume'
      case "formattedGroupCFVPercentage":
        return 'Community Fill Percentage'
      case "community_usage_in_day":
        return 'Community Daily Usage'
      case "formattedDUGroupPercentage":
        return 'Community Daily Usage Percentage'

      case "community_rainfall_collection":
        return 'Community Rainfall Collection'
      case "potential_collection_rain_period":
        return 'Rainfall on roof (L)'

      case "actualCollectedVolume":
        return 'Rain collected in tank (L)'

      case "periodicCount":
        return 'Rainfall in period (mm)'

      case "lifetimeCount":
        return 'Total rainfall since device on (mm)'

      default:
        return keys.join('_')
    }
  }
  // & LineChartOptionsMandatory 
  // const options: google.visualization.LineChartOptions = {
  const options: google.visualization.BarChartOptions = {
    // id: getGraphTitle(),
    title: getGraphTitle(),
    height: 300,
    legend: 'none'
  }


  const getKeysArray = () => {
    let keysArray = []
    const title = getGraphTitle()
    keysArray.push(title)
    debug && console.log('keysArray', keysArray)
    return keysArray
  }
  const keys2 = getKeysArray()
  debug && console.log({ keys, keys2 })
  debug && console.log({ historyObject, keys, xType, initialIdxRange });

  const data: google.visualization.DataTable = convertHistoryObjectToDataTable(historyObject, keys, xType, initialIdxRange);
  const chartProps = {
    options,
    data,
    name: getGraphTitle()
  }


  debug && console.log({ keys, chartProps })
  debug && console.log('keys:', keys[0])
  return <BarChart {...chartProps}> </BarChart>
}
