import { MapLike } from '@iotv/datamodel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import React from 'react';
import { mapStyles } from '../../cosmetics/MapCardStyles';
import styles from '../../cosmetics/sharedCardStyles';
import { MapCardProps } from '../../types/AppTypes';
import MapView from './MapView';






export default function MapCard( props: MapCardProps ) {
  const stylesIn: MapLike<any> = props.optionals?.styles ?? {}
  const useStyles = makeStyles((theme) => ( {...mapStyles(theme), ...styles(theme), ...stylesIn }));
  const classes = useStyles();

  const { position, timestamp, title }: MapCardProps = props;
 
  const getTitle = () => {
    let titleOut = '';
    if (title !== undefined ) titleOut = title;
    else { 
      if (position) titleOut = `${position.lat} ${position.lng}`;
      if (timestamp) titleOut +=  new Date(parseInt(timestamp) * 1000).toLocaleDateString();
    }
    return titleOut
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <MyLocationIcon></MyLocationIcon>
        }
        title= {getTitle()}
        subheader={ timestamp && new Date(parseInt(timestamp) * 1000).toLocaleDateString()}
      >
       
      </CardHeader>
  
      <CardContent className = { classes.mapCardObjectCard}>
        <MapView { ...props }></MapView>
      </CardContent>
    </Card>
  );
}