import { Grid, Card, CardContent, Button, TextField } from '@mui/material';
import { ValidBusinessObject } from '@iotv/datamodel';
import { Location } from '@iotv/datamodel-core';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Area } from '../AddArea';


const debug = process.env.REACT_APP_DEBUG && false;

export type PointInAreaProps = {
    values: Area,
    setValues: React.Dispatch<React.SetStateAction<Area>>,
}

export default function PointInArea({ values, setValues }: PointInAreaProps) {
    //! The lat and lng must be valid before they can be saved
    const [latText, setLatText] = useState('');
    const [lngText, setLngText] = useState('');

    useEffect(() => { }, [values.locations.length]);

    const defaultPoint = { id: v4(), lat: -36.8509, lng: 174.7645 };
    const addPoint = () => {
        // setValues({...values, locations: values.locations.push(defaultPoint)});
        values.locations.push(defaultPoint)
        setValues({ ...values, locations: values.locations });
        debug && console.log('locations', values)
    }

    const removePoint = (id: string) => {
        const newLoc = values.locations.filter((loc) => loc.id !== id);
        setValues({ ...values, locations: newLoc });
        debug && console.log('newLoc', newLoc)
    }

    const locationOnChange = (id: string, label: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        debug && console.log('event', event.target.value)

        values.locations.map((loc) => {
            if (loc.id === id) {
                label === 'lat' ? (loc.lat = event.target.value === '-' ? event.target.value : parseFloat(event.target.value)) : (loc.lng = event.target.value === '-' ? event.target.value : parseFloat(event.target.value));
                if (label === 'lat') {
                    Math.abs(loc.lat as number) <= 90 ? setLatText('') : setLatText('Invalid lat');
                    debug && console.log('error message', latText)
                } else if (label === 'lng') {
                    Math.abs(loc.lng as number) <= 180 ? setLngText('') : setLngText('Invalid lon');
                }
                debug && console.log('loc', loc)
                return loc;
            }
        })
        setValues({ ...values, locations: values.locations });
        debug && console.log('changeLocations', values.locations)


    }

    //hide the last point in the locations, the last point must be the same as the first point

    //If location.length 
    return (
        <Grid container>
            <Card>
                <CardContent>
                    {values.locations.map((value) => (
                        <Grid container>
                            <TextField required id="standard-basic" variant="standard" label="lat"
                                value={value.lat}
                                type="number"
                                error={latText.length > 0 ? true : false} helperText={latText}
                                onChange={(event) => locationOnChange(value.id, 'lat', event)}
                            />
                            <TextField required id="standard-basic" variant="standard" label="lon" value={value.lng} type="number" error={lngText.length > 0 ? true : false} helperText={lngText} onChange={(event) => locationOnChange(value.id, 'lng', event)} />
                            <Button onClick={() => removePoint(value.id)}>Delete</Button>
                        </Grid>
                    ))}
                    <Button onClick={addPoint}>Add Point</Button>
                </CardContent>
            </Card>
        </Grid>
    )

}